import React from 'react';
import { useIntl } from 'react-intl';
import { Button, ButtonProps } from 'semantic-ui-react';
import { commonMessages } from '../../../constants';
import { FilterOptions } from '../../../state/models';

interface OwnProps {
    filters: FilterOptions | undefined;
}

export type FilterPopupButtonProps =
    & ButtonProps
    & OwnProps;

export const FilterPopupButton: React.FC<FilterPopupButtonProps> = (props) => {
    const { formatMessage } = useIntl();
    const { filters, ...buttonProps } = props;

    const hasAgentFilters = (filters?.selectedAgentsFilter || [])?.length > 0;
    const hasStatusFilters = (filters?.selectedServiceCallStatusFilter || [])?.length > 0;
    const hasFilters = hasAgentFilters || hasStatusFilters;

    return (
        <Button 
            primary
            positive={hasFilters}
            content={formatMessage(commonMessages.filter)} 
            icon="filter" 
            {...buttonProps}
        />
    );
};