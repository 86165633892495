import { ResetPasswordEdit } from "modules/account/models";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { defineMessages, useIntl } from "react-intl";
import { Form, Segment } from "semantic-ui-react";
import { ResetPasswordValidationSummary } from "./ResetPasswordValidationSummary";

interface OwnProps {
    loading?: boolean;
    onResetPassword: (data: ResetPasswordEdit) => void;
}

export type ResetPasswordFormProps =
    & OwnProps;

const m = defineMessages({
    passwordPlaceholder: { id: 'ResetPasswordForm.passwordPlaceholder', defaultMessage: 'New password'},
    passwordRequiredValidation: { id: 'ResetPasswordForm.passwordRequiredValidation', defaultMessage: 'Please enter your new password.' },
    passwordMinimumLengthValidation: { id: 'ResetPasswordForm.passwordMinimumLengthValidation', defaultMessage: 'Your new password must be at least 8 characters long.' },
    passwordSpecialCharacterValidation: { id: 'ResetPasswordForm.passwordSpecialCharacterValidation', defaultMessage: 'Your new password must contain at least one special character (e.g. -+_!@#$%^&*.,?()[]).'},
    passwordConfirmationPlaceholder: { id: 'ResetPasswordForm.passwordConfirmationPlaceholder', defaultMessage: 'New password confirmation' },
    passwordConfirmationRequiredValidation: { id: 'ResetPasswordForm.passwordConfirmationRequiredValidation', defaultMessage: 'Please reenter your password in the confirmation field.' },
    passwordConfirmationDoesNotMatchValidation: { id: 'ResetPasswordForm.passwordConfirmationDoesNotMatchValidation', defaultMessage: 'The password confirmation does not match the password.' },
    resetPasswordButton: { id: 'ResetPasswordForm.resetPasswordButton', defaultMessage: 'Reset your password' },
});

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = (props) => {
    const { formatMessage } = useIntl();
    const { control, formState: { errors }, setValue, getValues, handleSubmit } = useForm<ResetPasswordEdit>();

    const doesPasswordConfirmationMatchesPassword = (value: string) => value !== getValues("password") 
        ? formatMessage(m.passwordConfirmationDoesNotMatchValidation) 
        : undefined;

    const doesPasswordContainsAtLeastOneSpecialCharacter = (value: string) => !/[-+_!@#$%^&*.,?()\[\]]+/.test(value)
        ? formatMessage(m.passwordSpecialCharacterValidation)
        : undefined;

    return (
        <>
            <Segment raised style={{ width: '100%' }}>
                <Form size="large">
                    <Controller 
                        name={'password'}
                        control={control}
                        rules={{ 
                            required: formatMessage(m.passwordRequiredValidation),
                            minLength: { value: 8, message: formatMessage(m.passwordMinimumLengthValidation) },
                            validate: {
                                specialCharacter: doesPasswordContainsAtLeastOneSpecialCharacter,
                            }
                        }}
                        render={(props) => (
                            <Form.Input 
                                error={errors.password != null}
                                type="password"
                                icon="lock" 
                                iconPosition="left" 
                                placeholder={formatMessage(m.passwordPlaceholder)}
                                onChange={(e, data) => setValue('password', data.value)}
                            />
                        )}
                    />

                    <Controller 
                        name={'passwordConfirmation'}
                        control={control}
                        rules={{ 
                            required: formatMessage(m.passwordConfirmationRequiredValidation),
                            validate: {
                                passwordConfirmation: doesPasswordConfirmationMatchesPassword
                            }
                        }}
                        render={(props) => (
                            <Form.Input 
                                error={errors.passwordConfirmation != null}
                                type="password"
                                icon="lock" 
                                iconPosition="left" 
                                placeholder={formatMessage(m.passwordConfirmationPlaceholder)}
                                onChange={(e, data) => setValue('passwordConfirmation', data.value)}
                            />
                        )}
                    />

                    <Form.Button 
                        fluid 
                        disabled={props.loading}
                        loading={props.loading}
                        size="large" 
                        color="teal" 
                        content={formatMessage(m.resetPasswordButton)} 
                        onClick={handleSubmit(props.onResetPassword)}
                    />
                </Form>
            </Segment>

            <ResetPasswordValidationSummary errors={errors} style={{ width: '100%' }} />
        </>
    );
};