import { CalendarBreak } from '../state/models';
import { get, httpDelete, httpDeleteVoid, post } from '../utils/api';

const endpoint = `/api/calendar`;

export class CalendarService {    
    public getLocks(startDate: Date, endDate: Date, forUserId?: string): Promise<CalendarBreak[]> {
        if (forUserId) {
            return get<CalendarBreak[]>(`${endpoint}/locks/${startDate.toISOString()}/${endDate.toISOString()}/${forUserId}`);
        }

        return get<CalendarBreak[]>(`${endpoint}/locks/${startDate.toISOString()}/${endDate.toISOString()}`);
    }

    public lock(forDate: Date, forUserId: string | undefined, reason: string): Promise<CalendarBreak> {
        if (forUserId) {
            return post<CalendarBreak>(`${endpoint}/lock/${forDate.toISOString()}/${forUserId}`, reason);
        }

        return post<CalendarBreak>(`${endpoint}/lock/${forDate.toISOString()}`, reason);
    }

    public unlock(forDate: Date): Promise<void> {
        return httpDeleteVoid(`${endpoint}/lock/${forDate.toISOString()}`);
    }
}