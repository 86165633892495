import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Segment, Icon, Form, Progress, Input, Button } from 'semantic-ui-react';
import { SmallDeleteButton } from '.';

export interface UploadFileButtonProps {
    onFilesUpload: (files: FileList) => void;

    isUploading?: boolean;
    allowMultiple?: boolean;
    acceptedFileTypes?: string;

    uploadButtonText?: string;
    uploadInstructions?: string;
}

const m = defineMessages({
    defaultUploadButtonText: { id: 'UploadFileButton.upload_button_text', defaultMessage: 'Upload' },
});

class UploadFileButton extends React.Component<UploadFileButtonProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const uploadButtonText = this.props.uploadButtonText || formatMessage(m.defaultUploadButtonText);
        const buttonClassName = this.props.isUploading
            ? 'ui positive loading button'
            : 'ui primary button';

        return (
            <label className={buttonClassName}>
                <Icon name="cloud upload" />{uploadButtonText}
                <input
                    type="file"
                    accept={this.props.acceptedFileTypes || '*'}
                    style={{ display: 'none' }}
                    multiple={this.props.allowMultiple != null ? this.props.allowMultiple : true}
                    onChange={this.handleFilesChange}
                />
            </label>
        );
    }

    private handleFilesChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { files } = e.currentTarget;

        if (files) {
            this.props.onFilesUpload(files);
        }
    }
}

const intlComponent = injectIntl(UploadFileButton);
export { intlComponent as UploadFileButton };