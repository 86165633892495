export * from './edits';
export * from './filters';
export * from './reporting';

export * from './Address';
export * from './Bill';
export * from './BillDetails';
export * from './BomItem';
export * from './CalendarBreak';
export * from './Client';
export * from './ClientClaims';
export * from './ClientContact';
export * from './ClientEmail';
export * from './Customer';
export * from './DatedValue';
export * from './Defect';
export * from './FactoryReport';
export * from './FilterOptions';
export * from './Item';
export * from './LabeledValue';
export * from './LegacyServiceCall';
export * from './Media';
export * from './Notification';
export * from './OrderInformation';
export * from './PagedResult';
export * from './PagedServiceCall';
export * from './PairedValue';
export * from './ProductionComponent';
export * from './ProductionOrder';
export * from './ProductModel';
export * from './ProductType';
export * from './QualityRatio';
export * from './Quantity';
export * from './Ratio';
export * from './ReportingResultRange';
export * from './ReturnForCredit';
export * from './SearchResults';
export * from './SelectableDefectiveItem';
export * from './ServiceCall';
export * from './ServiceCallAttachment';
export * from './ServiceCallDefect';
export * from './ServiceCallDefectiveItem';
export * from './ServiceCallFactoryRepair';
export * from './ServiceCallFeesEstimate';
export * from './ServiceCallInspection';
export * from './ServiceCallNote';
export * from './ServiceCallStatus';
export * from './ServiceCallUpdate';
export * from './ServiceReport';
export * from './SortOptions';
export * from './Statistics';
export * from './Subcontractor';
export * from './SubcontractorBill';
export * from './SubcontractorRepair';
export * from './TechnicianRepair';
export * from './TechnicianRepairRequest';
export * from './TechnicianReport';
export * from './TreeNode';
export * from './User';

export enum NotificationType {
    standard,
    information,
    success,
    warning,
    error,
}

export enum ActivityType {
    unknown,
    assignedToAgent,
    assignedToInspector,
    assignedToSubcontractor,
    serviceCallCreated,
    serviceCallLinked,
    serviceCallClosed,
    serviceCallReopened,
    inspectionScheduled,
    inspectionPerformed,
    technicianScheduled,
    repairedByTechnician,
    repairedBySubcontractor,
    repairedByClientSubcontractor,
    factoryRepairsRequested,
    factoryRepairsReceived,
    repairedAtFactory,
    shipmentSent,
    accountingTransactionPerformed,
    partsOrdered,
    partsReplaced,
    feesApprovalRequested,
    feesApprovedByClient,
    repairsCancelled,
    noteAdded,
    creditReturnRequested,
    creditReturnReceived,
    subcontractorScheduled,
    technicianRescheduled,
}

export enum AccountingTransactionType {
    none,
    billed,
    credited
}

export enum ItemType {
    unknown = 0,
    part,
    template,
    cover,
    finishedProduct,
    assembled,
}

export enum ItemAcquisitionType {
    unknown = 0,
    purchase,
    manufacture,
}

export enum ItemStatus {
    unknown = 0,
    active,
    phasingOut,
    discontinued
}

export enum AppointmentTimeRange {
    unspecified = 0,
    morning,
    afternoon,
}

export enum ServiceCallReportType {
    serviceCallDetails,
}