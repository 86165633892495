export enum ActionTypeKeys {
    LOAD_FOR_TECHNICIANS_REQUEST = 'APPOINTMENTS_LOAD_FOR_TECHNICIANS_REQUEST',
    LOAD_FOR_TECHNICIANS_SUCCESS = 'APPOINTMENTS_LOAD_FOR_TECHNICIANS_SUCCESS',
    LOAD_FOR_TECHNICIANS_FAILURE = 'APPOINTMENTS_LOAD_FOR_TECHNICIANS_FAILURE',
    LOAD_BY_ID_REQUEST = 'LOAD_BY_ID_REQUEST',
    LOAD_BY_ID_SUCCESS = 'LOAD_BY_ID_SUCCESS',
    LOAD_BY_ID_FAILURE = 'LOAD_BY_ID_FAILURE',
    LOAD_BY_SERVICE_CALL_REQUEST = 'LOAD_BY_SERVICE_CALL_REQUEST',
    LOAD_BY_SERVICE_CALL_SUCCESS = 'LOAD_BY_SERVICE_CALL_SUCCESS',
    LOAD_BY_SERVICE_CALL_FAILURE = 'LOAD_BY_SERVICE_CALL_FAILURE',
    LOAD_MINE_REQUEST = 'APPOINTMENTS_LOAD_MINE_REQUEST',
    LOAD_MINE_SUCCESS = 'APPOINTMENTS_LOAD_MINE_SUCCESS',
    LOAD_MINE_FAILURE = 'APPOINTMENTS_LOAD_MINE_FAILURE',
    LOAD_PENDING_REQUEST = 'APPOINTMENTS_LOAD_PENDING_REQUEST',
    LOAD_PENDING_SUCCESS = 'APPOINTMENTS_LOAD_PENDING_SUCCESS',
    LOAD_PENDING_FAILURE = 'APPOINTMENTS_LOAD_PENDING_FAILURE',
    LOAD_TODAY_REQUEST = 'APPOINTMENTS_LOAD_TODAY_REQUEST',
    LOAD_TODAY_SUCCESS = 'APPOINTMENTS_LOAD_TODAY_SUCCESS',
    LOAD_TODAY_FAILURE = 'APPOINTMENTS_LOAD_TODAY_FAILURE',
    LOAD_THIS_WEEK_REQUEST = 'APPOINTMENTS_LOAD_THIS_WEEK_REQUEST',
    LOAD_THIS_WEEK_SUCCESS = 'APPOINTMENTS_LOAD_THIS_WEEK_SUCCESS',
    LOAD_THIS_WEEK_FAILURE = 'APPOINTMENTS_LOAD_THIS_WEEK_FAILURE',
    LOAD_COMPLETED_REQUEST = 'APPOINTMENTS_LOAD_COMPLETED_REQUEST',
    LOAD_COMPLETED_SUCCESS = 'APPOINTMENTS_LOAD_COMPLETED_SUCCESS',
    LOAD_COMPLETED_FAILURE = 'APPOINTMENTS_LOAD_COMPLETED_FAILURE',
    LOAD_ALL_MINE_REQUEST = 'APPOINTMENTS_LOAD_ALL_MINE_REQUEST',
    LOAD_ALL_MINE_SUCCESS = 'APPOINTMENTS_LOAD_ALL_MINE_SUCCESS',
    LOAD_ALL_MINE_FAILURE = 'APPOINTMENTS_LOAD_ALL_MINE_FAILURE',
    SET_CALENDAR_START_DATE = 'APPOINTMENTS_SET_CALENDAR_START_DATE',
}