import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Icon, SemanticICONS, Popup, SemanticCOLORS } from 'semantic-ui-react';

interface PercentVarianceOwnProps {
    currentValue: number;
    previousValue: number;
    isIncreaseGood?: boolean;
    style?: React.CSSProperties;
}

export type PercentVarianceProps =
    & PercentVarianceOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    increaseTooltip: { id: 'PercentVariance.increase_tooltip', defaultMessage: 'An increase of {percentage} compared to the previous period ({previousValue}).' },
    decreaseTooltip: { id: 'PercentVariance.decrease_tooltip', defaultMessage: 'A decrease of {percentage} compared to the previous period ({previousValue}).'}
});

class PercentVariance extends React.Component<PercentVarianceProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;

        const difference = this.props.currentValue - this.props.previousValue;
        const variance = this.props.previousValue > 0 ? Math.abs((difference / this.props.previousValue)) : undefined;
        const hasVariance = variance != null;
        const hasCurrentValueIncreased = difference > 0;

        const percentageIcon: SemanticICONS = hasCurrentValueIncreased ? 'caret up' : 'caret down';
        const percentageLabel = `${Math.round((variance || 0) * 100)}%`;
        const percentageTooltip = formatMessage(hasCurrentValueIncreased ? m.increaseTooltip : m.decreaseTooltip, { percentage: percentageLabel, previousValue: Math.round(this.props.previousValue) });
        const percentageIconColor: SemanticCOLORS = (hasCurrentValueIncreased && this.props.isIncreaseGood) || (!hasCurrentValueIncreased && !this.props.isIncreaseGood)
            ? 'green'
            : 'red';

        return (
            <React.Fragment>
                {hasVariance &&
                    <Popup trigger={this.renderPercentVariance(percentageIcon, percentageIconColor, percentageLabel)}>
                        {percentageTooltip}
                    </Popup>
                }

                {!hasVariance &&
                    <React.Fragment>
                        {this.renderEmptyPercentVariance()}
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }

    private renderPercentVariance(icon: SemanticICONS, iconColor: SemanticCOLORS, label: string) {
        return (
            <span style={{color: '#767676', ...this.props.style}}>
                <Icon name={icon} color={iconColor} size="large" fitted={true} />
                <span style={{fontSize: '16px', fontWeight: 'bold'}}>
                    {label}
                </span>
            </span>
        );
    }

    private renderEmptyPercentVariance() {
        return (
            <p style={{ color: '#767676', ...this.props.style }}>
                <span style={{fontSize: '16px', fontWeight: 'bold'}}>
                    &mdash;
                </span>
            </p>
        );
    }
}

const connectedComponent = injectIntl(PercentVariance);
export { connectedComponent as PercentVariance };