export enum NotificationType {
    unknown = 0,
    factoryRepairCompleted = 1,
    partRequired = 2,
    inspectionCompleted = 3,
    serviceCallUpdatedByClient = 4,
    technicianRepairCompleted = 5,
    serviceCallUpdatedByJaymar = 6,
    serviceCallUpdatedBySubcontractor = 7,
}

export interface Notification {
    id: number;
    createdOn: Date;
    createdBy: string;
    type: NotificationType;
    targetUserId: string;
    readOn: Date | undefined;
    referenceId: number | undefined;
}