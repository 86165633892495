import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { ServiceCallView } from "containers/reporting/types";
import { Dropdown } from "semantic-ui-react";

const m = defineMessages({
  reportedDefectiveItemsByItem: {
    id: "ServiceCallViewSelector.ReportedDefectiveItemsByItem",
    defaultMessage: "Reported defective items by Item",
  },
  reportedDefectiveItemsByCover: {
    id: "ServiceCallViewSelector.ReportedDefectiveItemsByCover",
    defaultMessage: "Reported defective items by cover",
  },
  reportedDefectiveItemsByProductType: {
    id: "ServiceCallViewSelector.ReportedDefectiveItemsByProductType",
    defaultMessage: "Reported defective items by product type",
  },
  reportedDefectiveItemsByWeeksSinceShipping: {
    id: "ServiceCallViewSelector.ReportedDefectiveItemsByWeeksSinceShipping",
    defaultMessage: "Reported defective items by weeks since shipping",
  },
  reportedDefectiveItemsByWeeksSinceBilling: {
    id: "ServiceCallViewSelector.ReportedDefectiveItemsByWeeksSinceBilling",
    defaultMessage: "Reported defective items by weeks since billing",
  },
  reportedDefectiveItemsByDefectType: {
    id: "ServiceCallViewSelector.ReportedDefectiveItemsByDefectType",
    defaultMessage: "Reported defective items by defect type",
  },
  reportedDefectiveItemsByDefectCategory: {
    id: "ServiceCallViewSelector.ReportedDefectiveItemsByDefectCategory",
    defaultMessage: "Reported defective items by defect category",
  },
  reportedDefectiveItemsByDefectCause: {
    id: "ServiceCallViewSelector.ReportedDefectiveItemsByDefectCause",
    defaultMessage: "Reported defective items by defect cause",
  },
  problems: {
    id: "ServiceCallViewSelector.Problems",
    defaultMessage: "Problem descriptions",
  },
  inspectionReportDescriptions: {
    id: "ServiceCallViewSelector.InspectionReportDescriptions",
    defaultMessage: "Inspection report descriptions",
  },
  technicianReportDescriptions: {
    id: "ServiceCallViewSelector.TechnicianReportDescriptions",
    defaultMessage: "Technician report descriptions",
  },
  factoryReportDescriptions: {
    id: "ServiceCallViewSelector.FactoryReportDescriptions",
    defaultMessage: "Factory report descriptions",
  },
});

interface ViewSelectorProps {
  selectedView: ServiceCallView;
  onChange: (newSelectedView: ServiceCallView) => void;
}

export const ViewSelector: React.FC<ViewSelectorProps> = ({ selectedView, onChange }) => {
  const { formatMessage } = useIntl();

  const views = [
    {
      view: ServiceCallView.ReportedDefectiveItemsByItem,
      name: formatMessage(m.reportedDefectiveItemsByItem),
    },
    {
      view: ServiceCallView.ReportedDefectiveItemsByCover,
      name: formatMessage(m.reportedDefectiveItemsByCover),
    },
    {
      view: ServiceCallView.ReportedDefectiveItemsByProductType,
      name: formatMessage(m.reportedDefectiveItemsByProductType),
    },
    {
      view: ServiceCallView.ReportedDefectiveItemsByWeeksSinceShipping,
      name: formatMessage(m.reportedDefectiveItemsByWeeksSinceShipping),
    },
    {
      view: ServiceCallView.ReportedDefectiveItemsByWeeksSinceBilling,
      name: formatMessage(m.reportedDefectiveItemsByWeeksSinceBilling),
    },
    {
      view: ServiceCallView.ReportedDefectiveItemsByDefectType,
      name: formatMessage(m.reportedDefectiveItemsByDefectType),
    },
    {
      view: ServiceCallView.ReportedDefectiveItemsByDefectCategory,
      name: formatMessage(m.reportedDefectiveItemsByDefectCategory),
    },
    {
      view: ServiceCallView.ReportedDefectiveItemsByDefectCause,
      name: formatMessage(m.reportedDefectiveItemsByDefectCause),
    },
    {
      view: ServiceCallView.Problems,
      name: formatMessage(m.problems),
    },
    {
      view: ServiceCallView.InspectionReportDescriptions,
      name: formatMessage(m.inspectionReportDescriptions),
    },
    {
      view: ServiceCallView.TechnicianReportDescriptions,
      name: formatMessage(m.technicianReportDescriptions),
    },
    {
      view: ServiceCallView.FactoryReportDescriptions,
      name: formatMessage(m.factoryReportDescriptions),
    },
  ];

  const options = views.map((view) => ({
    key: view.view,
    value: view.view,
    text: view.name,
  }));

  return (
    <Dropdown
      inline
      options={options}
      value={selectedView}
      onChange={(event, { value }) => onChange(value as ServiceCallView)}
    />
  );
};
