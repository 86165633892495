import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Image, Label, SemanticSIZES, Icon, Popup } from 'semantic-ui-react';
import { Media } from '../../../state/models';

export interface PictureThumbnailProps {
    picture: Media;
    thumbnailSize?: SemanticSIZES;
    disabled?: boolean;
    onClick?: (picture: Media) => void;
}

class PictureThumbnail extends React.Component<PictureThumbnailProps & WrappedComponentProps, {}> {
    public render() {
        const imageSize = this.props.thumbnailSize || 'medium';
        const imageLabel = (
            <Label corner="right" color="teal" style={{ cursor: 'pointer' }} size={imageSize}>
                <Icon name="magnify" style={{ cursor: 'pointer' }} />
            </Label>
        );

        const imageDate = this.props.picture.createdOn && (
            <span style={{ textAlign: 'center', fontSize: '14px', color: 'rgba(0,0,0,.4)', marginBottom: '0.5em' }}>
                {moment.utc(this.props.picture.createdOn).local().fromNow()}
            </span>
        );

        return this.props.disabled
            ? (
                <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
                    <Image bordered={true} disabled={true} size={imageSize} label={imageLabel} src={this.props.picture.uri} style={{ marginBottom: 0 }} />
                    <Popup
                        trigger={imageDate}
                        content={moment.utc(this.props.picture.createdOn).format('LL')}
                        position="bottom center"
                    />
                </div>
            )
            : (
                <a onClick={this.handleShowPicturePreview} style={{ cursor: 'pointer', display: 'inline-flex', flexDirection: 'column' }}>
                    <Image bordered={true} size={imageSize} label={imageLabel} src={this.props.picture.uri} style={{ marginBottom: 0 }} />
                    <Popup
                        trigger={imageDate}
                        content={moment.utc(this.props.picture.createdOn).format('LL')}
                        position="bottom center"
                    />
                </a>
            );
    }

    private handleShowPicturePreview = () => {
        if (this.props.onClick) {
            this.props.onClick(this.props.picture);
        }
    }
}

const connectedComponent = injectIntl(PictureThumbnail);
export { connectedComponent as PictureThumbnail };