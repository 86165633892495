import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { QualityControlImageService } from "services/QualityControlImageService";
import * as globalNotification from "../../../../state/ducks/global-notification";
import { Media, MediaType } from "state/models";
import { QualityControlImageInfo } from "modules/orders/types";

const qualityControlImageService = new QualityControlImageService();

export const useQualityControlImages = (productionItemId: number | undefined) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const [qualityControlImageInfos, setQualityControlImageInfos] = useState<QualityControlImageInfo[]>([]);

  useEffect(() => {
    if (!productionItemId) {
      setIsLoading(false);
      setQualityControlImageInfos([]);
      return;
    }

    setIsLoading(true);

    qualityControlImageService
      .getNamesByProductionItemId(productionItemId)
      .then((response) => response.json() as QualityControlImageInfo[])
      .then((imageInfos) => {
        setQualityControlImageInfos(imageInfos || []);
      })
      .catch((error) => {
        dispatch(
          globalNotification.actionCreators.showErrorNotification(
            "Erreur",
            `Une erreur est survenue en tentant de récupérer les images prises lors du contrôle de qualité (${error}).`
          )
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, productionItemId]);

  const medias: Media[] = qualityControlImageInfos.map((imageInfo, index) => ({
    id: index,
    uri: `/api/quality-control-images/${imageInfo.fileName}`,
    type: MediaType.image,
  }));

  return { medias, isLoading };
}