import * as React from 'react';
import { useState } from 'react';
import { defineMessages, injectIntl, useIntl, WrappedComponentProps } from 'react-intl';
import { Card, SemanticICONS, Button, List, CardProps } from 'semantic-ui-react';
import { useResponsive } from 'utils/responsive';
import { commonMessages } from '../../../constants';

export enum RepairType {
    none,
    factory,
    jaymarTechnician,
    subcontractor,
    returnForCredit
}

const m = defineMessages({
    title: { id: 'RepairTypeCard.title', defaultMessage: 'Type of repairs' },
    subtitle: { id: 'RepairTypeCard.subtitle', defaultMessage: 'Select the type of repairs to fix the defect.' },
    factoryRepairs: { id: 'RepairTypeCard.factory_repairs', defaultMessage: 'Factory repairs' },
    jaymarTechnicianRepairs: { id: 'RepairTypeCard.jaymar_technician_repairs', defaultMessage: 'Jaymar technician repairs' },
    subcontractorRepairs: { id: 'RepairTypeCard.subcontractor_repairs', defaultMessage: 'Subcontractor repairs' },
    returnForCredit: { id: 'RepairTypeCard.return_for_credit', defaultMessage: 'Return for credit', }
});

interface OwnProps {
    selectedRepairType: RepairType;
    isCancelling: boolean;
    isReadOnly?: boolean;
    onRepairTypeSelected: (repairType: RepairType) => void;
    onRepairTypeCanceled: (repairType: RepairType) => void;
}

export type RepairTypeCardProps =
    & OwnProps
    & CardProps;

export const RepairTypeCard: React.FC<RepairTypeCardProps> = (props) => {
    const { formatMessage } = useIntl();
    const { isLargeMonitor, isWidescreenMonitor } = useResponsive();

    const renderRepairTypeItem = (repairType: RepairType, icon: SemanticICONS, title: string) => {
        const isSelected = props.selectedRepairType === repairType;
        const isDisabled = props.isReadOnly;
        const isCancelButtonVisible = props.isReadOnly && isSelected;
        const isLargeResolution = isLargeMonitor || isWidescreenMonitor;

        return (
            <List.Item 
                active={isSelected} 
                disabled={isDisabled && !isSelected} 
                onClick={(event, data) => props.onRepairTypeSelected(repairType)}
            >
                {isCancelButtonVisible && isLargeResolution &&
                    <List.Content floated="right">
                        <Button 
                            negative={true}
                            disabled={props.isCancelling}
                            loading={props.isCancelling}
                            content={formatMessage(commonMessages.cancel)} 
                            onClick={() => props.onRepairTypeCanceled(repairType)} 
                        />
                    </List.Content>
                }
                <List.Icon name={icon} size="large" />
                <List.Content>
                    <List.Header>{title}</List.Header>
                </List.Content>

                {isCancelButtonVisible && !isLargeResolution &&
                    <List.Content>
                        <div style={{marginTop: 10}}>
                            <Button 
                                fluid={true} 
                                negative={true} 
                                disabled={props.isCancelling}
                                loading={props.isCancelling}
                                content={formatMessage(commonMessages.cancel)} 
                                onClick={() => props.onRepairTypeCanceled(repairType)} 
                            />
                        </div>
                    </List.Content>
                }
            </List.Item>
        );
    }

    return (
        <Card fluid={true} color="blue" style={{height: '100%'}}>
            <Card.Content style={{flexGrow: 0}}>
                <Card.Header>{formatMessage(m.title)}</Card.Header>
                <Card.Meta>{formatMessage(m.subtitle)}</Card.Meta>
            </Card.Content>
            <Card.Content>
                <List divided={true} relaxed="very" selection={true}>
                    {renderRepairTypeItem(RepairType.factory, 'factory', formatMessage(m.factoryRepairs))}
                    {renderRepairTypeItem(RepairType.jaymarTechnician, 'wrench', formatMessage(m.jaymarTechnicianRepairs))}
                    {renderRepairTypeItem(RepairType.subcontractor, 'id card outline', formatMessage(m.subcontractorRepairs))}
                    {renderRepairTypeItem(RepairType.returnForCredit, 'undo', formatMessage(m.returnForCredit))}
                </List>
            </Card.Content>
        </Card>
    );
}