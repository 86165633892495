import { ThunkAction } from 'redux-thunk';

import { ApplicationState } from '..';
import { InspectionsService } from '../../../services';
import { AppointmentTimeRange, ServiceCallInspection } from '../../models';
import * as globalNotification from '../global-notification';
import { ActionTypeKeys } from './types';
import { AppThunk } from '../../store';

export type ActionTypes =
    | LoadUpcomingRequest
    | LoadUpcomingSuccess
    | LoadUpcomingFailure
    | LoadBetweenRequest
    | LoadBetweenSuccess
    | LoadBetweenFailure
    | LoadUnscheduledRequest
    | LoadUnscheduledSuccess
    | LoadUnscheduledFailure
    | LoadCompletedRequest
    | LoadCompletedSuccess
    | LoadCompletedFailure
    | LoadByIdRequest
    | LoadByIdSuccess
    | LoadByIdFailure
    | ScheduleRequest
    | ScheduleSuccess
    | ScheduleFailure
    | SaveReportRequest
    | SaveReportSuccess
    | SaveReportFailure
    | CompleteRequest
    | CompleteSuccess
    | CompleteFailure
    | CloseRequest
    | CloseSuccess
    | CloseFailure
    | SetCalendarStartDate
    | { type: '' };

export interface LoadUpcomingRequest { type: ActionTypeKeys.LOAD_UPCOMING_REQUEST; }
export interface LoadUpcomingSuccess { type: ActionTypeKeys.LOAD_UPCOMING_SUCCESS; payload: ServiceCallInspection[]; }
export interface LoadUpcomingFailure { type: ActionTypeKeys.LOAD_UPCOMING_FAILURE; payload: string; }

export interface LoadBetweenRequest { type: ActionTypeKeys.LOAD_BETWEEN_REQUEST; payload: string; }
export interface LoadBetweenSuccess { type: ActionTypeKeys.LOAD_BETWEEN_SUCCESS; payload: { startDate: string, inspections: ServiceCallInspection[] }; }
export interface LoadBetweenFailure { type: ActionTypeKeys.LOAD_BETWEEN_FAILURE; payload: { startDate: string, error: string }; }

export interface LoadUnscheduledRequest { type: ActionTypeKeys.LOAD_UNSCHEDULED_REQUEST; }
export interface LoadUnscheduledSuccess { type: ActionTypeKeys.LOAD_UNSCHEDULED_SUCCESS; payload: ServiceCallInspection[]; }
export interface LoadUnscheduledFailure { type: ActionTypeKeys.LOAD_UNSCHEDULED_FAILURE; payload: string; }

export interface LoadCompletedRequest { type: ActionTypeKeys.LOAD_COMPLETED_REQUEST; }
export interface LoadCompletedSuccess { type: ActionTypeKeys.LOAD_COMPLETED_SUCCESS; payload: ServiceCallInspection[]; }
export interface LoadCompletedFailure { type: ActionTypeKeys.LOAD_COMPLETED_FAILURE; payload: string; }

export interface LoadByIdRequest { type: ActionTypeKeys.LOAD_BY_ID_REQUEST; payload: number; }
export interface LoadByIdSuccess { type: ActionTypeKeys.LOAD_BY_ID_SUCCESS; payload: ServiceCallInspection; }
export interface LoadByIdFailure { type: ActionTypeKeys.LOAD_BY_ID_FAILURE; payload: { inspectionId: number, error: string }; }

export interface ScheduleRequest { type: ActionTypeKeys.SCHEDULE_REQUEST; }
export interface ScheduleSuccess { type: ActionTypeKeys.SCHEDULE_SUCCESS; payload: ServiceCallInspection; }
export interface ScheduleFailure { type: ActionTypeKeys.SCHEDULE_FAILURE; payload: string; }

export interface SaveReportRequest { type: ActionTypeKeys.SAVE_REPORT_REQUEST; }
export interface SaveReportSuccess { type: ActionTypeKeys.SAVE_REPORT_SUCCESS; payload: ServiceCallInspection; }
export interface SaveReportFailure { type: ActionTypeKeys.SAVE_REPORT_FAILURE; payload: string; }

export interface CompleteRequest { type: ActionTypeKeys.COMPLETE_REQUEST; }
export interface CompleteSuccess { type: ActionTypeKeys.COMPLETE_SUCCESS; payload: ServiceCallInspection; }
export interface CompleteFailure { type: ActionTypeKeys.COMPLETE_FAILURE; payload: string; }

export interface CloseRequest { type: ActionTypeKeys.CLOSE_REQUEST; }
export interface CloseSuccess { type: ActionTypeKeys.CLOSE_SUCCESS; payload: ServiceCallInspection; }
export interface CloseFailure { type: ActionTypeKeys.CLOSE_FAILURE; payload: string; }

export interface SetCalendarStartDate { type: ActionTypeKeys.SET_CALENDAR_START_DATE; payload: Date; }

const service = new InspectionsService();

export const actionCreators = {
    loadById: (inspectionId: number): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_BY_ID_REQUEST });

            service.getById(inspectionId)
                .then(response => response.json() as ServiceCallInspection)
                .then(inspection => {
                    dispatch({ type: ActionTypeKeys.LOAD_BY_ID_SUCCESS, payload: inspection });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_BY_ID_FAILURE });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de récupérer l'inspection avec l'identifiant '${inspectionId}' (${error}).`)
                    );
                });
        };
    },

    loadBetween: (startDate: Date, endDate: Date): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_BETWEEN_REQUEST, payload: startDate.toISOString() });

            service.getBetween(startDate, endDate)
                .then(response => response.json() as ServiceCallInspection[])
                .then(inspections => {
                    dispatch({ type: ActionTypeKeys.LOAD_BETWEEN_SUCCESS, payload: { startDate: startDate.toISOString(), inspections: inspections } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_BETWEEN_FAILURE });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de récupérer les inspections entre les dates ${startDate.toISOString()} et ${endDate.toISOString()} (${error}).`)
                    );
                });
        };
    },

    loadUpcoming: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_UPCOMING_REQUEST });

            service.getUpcoming()
                .then(response => response.json() as ServiceCallInspection[])
                .then(inspections => {
                    dispatch({ type: ActionTypeKeys.LOAD_UPCOMING_SUCCESS, payload: inspections });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_UPCOMING_FAILURE });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de récupérer les inspections à venir (${error}).`)
                    );
                });
        };
    },

    loadUnscheduled: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_UNSCHEDULED_REQUEST });

            service.getUnscheduled()
                .then(response => response.json() as ServiceCallInspection[])
                .then(inspections => {
                    dispatch({ type: ActionTypeKeys.LOAD_UNSCHEDULED_SUCCESS, payload: inspections });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_UNSCHEDULED_FAILURE });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de récupérer les inspections à planifier (${error}).`)
                    );
                });
        };
    },

    loadCompleted: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_COMPLETED_REQUEST });

            service.getCompleted()
                .then(response => response.json() as ServiceCallInspection[])
                .then(inspections => {
                    dispatch({ type: ActionTypeKeys.LOAD_COMPLETED_SUCCESS, payload: inspections });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_COMPLETED_FAILURE });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de récupérer les inspections complétées (${error}).`)
                    );
                });
        };
    },

    schedule: (inspectionId: number, scheduledForDate?: Date, scheduledForTime?: AppointmentTimeRange): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.SCHEDULE_REQUEST });

            service.schedule(inspectionId, scheduledForDate, scheduledForTime)
                .then(response => response.json() as ServiceCallInspection)
                .then(inspection => {
                    dispatch({ type: ActionTypeKeys.SCHEDULE_SUCCESS, payload: inspection });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.SCHEDULE_FAILURE });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de planifier un rendez-vous pour l'inspection '${inspectionId}' (${error}).`)
                    );
                });
        };
    },

    saveReport: (inspectionId: number, report: string): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.SAVE_REPORT_REQUEST });

            service.saveReport(inspectionId, report)
                .then(response => response.json() as ServiceCallInspection)
                .then(inspection => {
                    dispatch({ type: ActionTypeKeys.SAVE_REPORT_SUCCESS, payload: inspection });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.SAVE_REPORT_FAILURE });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de sauvegarder le rapport pour l'inspection '${inspectionId}' (${error}).`)
                    );
                });
        };
    },

    complete: (inspectionId: number): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.COMPLETE_REQUEST });

            service.complete(inspectionId)
                .then(response => response.json() as ServiceCallInspection)
                .then(inspection => {
                    dispatch({ type: ActionTypeKeys.COMPLETE_SUCCESS, payload: inspection });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.COMPLETE_FAILURE });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de compléter le rapport pour l'inspection '${inspectionId}' (${error}).`)
                    );
                });
        };
    },

    close: (inspectionId: number): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.CLOSE_REQUEST });

            service.close(inspectionId)
                .then(response => response.json() as ServiceCallInspection)
                .then(inspection => {
                    dispatch({ type: ActionTypeKeys.CLOSE_SUCCESS, payload: inspection });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.CLOSE_FAILURE });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de compléter le rapport pour l'inspection '${inspectionId}' (${error}).`)
                    );
                });
        };
    },

    setCalendarStartDate: (startDate: Date) => {
        return { type: ActionTypeKeys.SET_CALENDAR_START_DATE, payload: startDate };
    }
};