import moment from 'moment';
import DatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Form } from 'semantic-ui-react';
import { AppointmentDateFormValues } from './AppointementDateForm';

export const AppointementDateInputField = () => {
    const { formatMessage } = useIntl();

    const { control } = useFormContext<AppointmentDateFormValues>();

    return (
        <Form.Field>
            <Controller
                control={control}
                name="appointmentDate"
                render={({field: {value, onChange}}) => (
                    <>
                        <DatePicker
                            selected={
                                value != null
                                    ? moment(value).toDate()
                                    : undefined
                            }
                            dateFormat="YYYY/MM/DD"
                            fixedHeight={true}
                            placeholderText={formatMessage({
                                id: 'SubcontractorRepairPage.AppointementDateForm.Placeholder',
                                defaultMessage: 'Appointment date',
                            })}
                            value={
                                value != null
                                    ? moment(value).format('YYYY/MM/DD')
                                    : undefined
                            }
                            onChange={(date) =>
                                onChange((date as Date | null) || undefined)
                            }
                        />
                    </>
                )}
            />
        </Form.Field>
    );
};
