import { defineMessages } from 'react-intl';
import { Address } from '.';
import { emptyAddress } from './Address';

export interface Customer {
    id: number;
    name: string;
    address: Address;
    email: string;
    mainPhoneNumber: string;
    normalizedMainPhoneNumber: number | undefined;
    otherPhoneNumber: string;
    normalizedOtherPhoneNumber: number | undefined;
}

export const emptyCustomer: Customer = {
    id: 0,
    name: '',
    address: emptyAddress,
    mainPhoneNumber: '',
    email: '',
    normalizedMainPhoneNumber: undefined,
    otherPhoneNumber: '',
    normalizedOtherPhoneNumber: undefined
};