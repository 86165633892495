import { Client } from "../../state/models";
import { AppThunk } from "../../state/store";
import * as api from './api';

export const LOAD_BY_ID_REQUEST = 'CLIENTS_LOAD_BY_ID_REQUEST';
export const LOAD_BY_ID_SUCCESS = 'CLIENTS_LOAD_BY_ID_SUCCESS';
export const LOAD_BY_ID_FAILURE = 'CLIENTS_LOAD_BY_ID_FAILURE';

export interface LoadByIdRequestAction { type: typeof LOAD_BY_ID_REQUEST; payload: { clientId: string }; }
export interface LoadByIdSuccessAction { type: typeof LOAD_BY_ID_SUCCESS; payload: { client: Client }; }
export interface LoadByIdFailureAction { type: typeof LOAD_BY_ID_FAILURE; payload: { clientId: string, error: Error }; }

export type ClientsActionTypes =
    | LoadByIdRequestAction | LoadByIdSuccessAction | LoadByIdFailureAction
    | { type: '' };

export const actionCreators = {
    loadById: (clientId: string): AppThunk => {
        return (dispatch) => {
            dispatch({ type: LOAD_BY_ID_REQUEST, payload: { clientId } });

            api.loadById(clientId)
                .then(client => dispatch({ type: LOAD_BY_ID_SUCCESS, payload: { client }}))
                .catch(error => dispatch({ type: LOAD_BY_ID_FAILURE, payload: { clientId, error }}));
        };
    }
}