import * as React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import { countriesMessages } from '../../constants';
import { Address } from '../../state/models';
import { StringHelper } from '../../state/utils';

interface AddressInformationOwnProps {
    address: Address;
    canNavigateTo?: boolean;
}

export type AddressInformationProps =
    & AddressInformationOwnProps
    & WrappedComponentProps;

class AddressInformation extends React.Component<AddressInformationProps, {}> {
    public render() {
        return this.props.canNavigateTo
            ? this.renderAddressContentWithLink()
            : this.renderAddressContent();
    }

    private renderAddressContentWithLink() {
        return (
            <a target="_blank" rel="noopener noreferrer" href={this.getGoogleMapsAddress()}>
                {this.renderAddressContent()}
            </a>
        );
    }

    private renderAddressContent() {
        const { formatMessage } = this.props.intl;
        const { address } = this.props;
        const hasAddress = StringHelper.hasValue(address.address1)
            || StringHelper.hasValue(address.address2)
            || StringHelper.hasValue(address.provinceCode)
            || StringHelper.hasValue(address.countryCode)
            || StringHelper.hasValue(address.postalCode);

        if (hasAddress) {
            return (
                <div>
                    {address.address1}
                    <span style={{ display: 'block' }}>{address.address2}</span>
                    <span style={{ display: 'block' }}>
                        {address.city && <span>{address.city}</span>}
                        {address.provinceCode && <span style={{ textTransform: 'uppercase', marginLeft: 5 }}>({address.provinceCode})</span>}
                        {address.countryCode && <span style={{ marginLeft: 5 }}>{countriesMessages[address.countryCode] != null ? formatMessage(countriesMessages[address.countryCode]) : ''}</span>}
                    </span>
                    {address.postalCode && <span style={{ textTransform: 'uppercase' }}>{address.postalCode}</span>}
                </div>
            );
        }

        return (null);
    }

    private getGoogleMapsAddress() {
        const { formatMessage } = this.props.intl;
        const { address } = this.props;

        const destination = `${address.address1}%2C${address.address2 || ''}%2C${address.city || ''}%2C${address.provinceCode || ''}%2C${address.postalCode || ''}`;
        return `https://www.google.com/maps/dir/?api=1&destination=${destination}&travelmode=driving`;
    }
}

const connectedComponent = injectIntl(AddressInformation);
export { connectedComponent as AddressInformation };