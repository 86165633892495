import moment from 'moment';
import React from 'react';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { Grid } from 'semantic-ui-react';

import { InformationMessage, LoadingDimmer } from '../../../components/common';
import {
    FactoryRepairsCard,
    JaymarTechnicianRepairsCard,
    RepairType,
    RepairTypeCard,
    ReturnForCreditCard,
    SubcontractorRepairsCard,
} from '../../../components/service-calls/repairs';
import * as SubcontractorsActions from '../../../modules/administration/subcontractors/actions';
import { getAllSubcontractors } from '../../../modules/administration/subcontractors/selectors';
import { ApplicationState } from '../../../state/ducks';
import * as AppointmentsActions from '../../../state/ducks/appointments';
import { getAllAppointments, getAppointmentsState } from '../../../state/ducks/appointments/selectors';
import * as CalendarActions from '../../../state/ducks/calendar';
import { getBreaksBetween } from '../../../state/ducks/calendar/selectors';
import * as FactoryRepairsActions from '../../../state/ducks/factory-repairs';
import { getFactoryRepairsByStartDate } from '../../../state/ducks/factory-repairs/selectors';
import * as ServiceCallsActions from '../../../state/ducks/service-calls';
import { getCurrentCreditReturn, getCurrentFactoryRepairs, getCurrentSubcontractorRepairs, getCurrentTechnicianRepairs, getServiceCallState, getSubcontractorRepairs, getTechnicianRepairs } from '../../../state/ducks/service-calls';
import { ServiceCallState } from '../../../state/ducks/service-calls/reducers';
import * as UiActions from '../../../state/ducks/ui';
import * as UsersActions from '../../../state/ducks/users';
import { CalendarBreak, ReturnForCredit, ServiceCallFactoryRepair, Subcontractor, SubcontractorRepair, SubcontractorRepairWarrantyStatus, TechnicianAppointment, TechnicianRepair, User } from '../../../state/models';

interface ServiceCallRepairStepActions {
    appointmentsActions: typeof AppointmentsActions.actionCreators;
    calendarActions: typeof CalendarActions.actionCreators;
    factoryRepairsActions: typeof FactoryRepairsActions.actionCreators;
    uiActions: typeof UiActions.actionCreators;
    serviceCallsActions: typeof ServiceCallsActions.actionCreators;
    subcontractorsActions: typeof SubcontractorsActions.actionCreators;
    usersActions: typeof UsersActions.actionCreators;
}

interface ServiceCallRepairStepState {
    selectedRepairType: RepairType;
    previousSelectedRepairType: RepairType;
}

interface ServiceCallRepairStepOwnProps {
    serviceCallState: ServiceCallState;
    technicians: User[];
    techniciansAppointments: TechnicianRepair[];
    areTechniciansAppointmentsLoading: boolean;
    areTechniciansAppointmentsSaving: boolean;
    lockedDates: CalendarBreak[];
    selectedCalendarDate: Date;
    factoryRepairs: ServiceCallFactoryRepair[];
    areFactoryRepairsLoading: boolean;
    isExecutingRepairsStep: boolean;
    factoryRepairsCalendarDate: Date;
    currentFactoryRepair: ServiceCallFactoryRepair | undefined;
    subcontractors: Subcontractor[];
    subcontractorRepairs: SubcontractorRepair[];
    currentSubcontractorRepair: SubcontractorRepair | undefined;
    currentTechnicianRepair: TechnicianRepair | undefined;
    technicianRepairs: TechnicianRepair[];
    currentCreditReturn: ReturnForCredit | undefined;
}

export type ServiceCallRepairStepProps =
    & ServiceCallRepairStepOwnProps
    & ServiceCallRepairStepActions
    & RouteComponentProps<{ serviceCallId: string }>;

type IntlServiceCallRepairStepProps = ServiceCallRepairStepProps & WrappedComponentProps;

const m = defineMessages({
    awaitingFactoryRepairsFurnitureTitle: { id: 'ServiceCallInspectStep.awaiting_factory_repairs_furniture_title', defaultMessage: 'Awaiting for furniture' },
    awaitingFactoryRepairsTitle: { id: 'ServiceCallInspectStep.awaiting_factory_repairs_title', defaultMessage: 'Pending factory repairs' },
    awaitingFactoryRepairsFurnitureMessage: { id: 'ServiceCallInspectStep.awaiting_factory_repairs_furniture_message', defaultMessage: 'This service call is awaiting the reception of the furniture at Jaymar\'s factory.' },
    awaitingFactoryRepairsMessage: { id: 'ServiceCallInspectStep.awaiting_factory_repairs_message', defaultMessage: 'This service call is awaiting for the factory repairs from Jaymar\'s technicians.' },
});

class ServiceCallRepairStep extends React.Component<IntlServiceCallRepairStepProps, ServiceCallRepairStepState> {
    public constructor(props: IntlServiceCallRepairStepProps) {
        super(props);

        this.state = {
            selectedRepairType: this.getCurrentRepairType(props.currentFactoryRepair, props.currentSubcontractorRepair, props.currentTechnicianRepair, props.currentCreditReturn),
            previousSelectedRepairType: this.getCurrentRepairType(props.currentFactoryRepair, props.currentSubcontractorRepair, props.currentTechnicianRepair, props.currentCreditReturn),
        };
    }

    public componentWillReceiveProps(props: ServiceCallRepairStepProps & WrappedComponentProps) {
        const repairType = this.getCurrentRepairType(props.currentFactoryRepair, props.currentSubcontractorRepair, props.currentTechnicianRepair, props.currentCreditReturn);

        this.setState((current) => ({
            selectedRepairType: repairType === RepairType.none ? current.selectedRepairType : repairType,
            previousSelectedRepairType: repairType === RepairType.none ? current.previousSelectedRepairType : repairType
        }));
    }

    public componentDidMount() {
        const startDate = moment(this.props.selectedCalendarDate).startOf('month').startOf('week').startOf('day').toDate();
        const endDate = moment(this.props.selectedCalendarDate).endOf('month').endOf('week').startOf('day').toDate();
        const factoryStartDate = moment(this.props.factoryRepairsCalendarDate).startOf('month').startOf('week').startOf('day').toDate();
        const factoryEndDate = moment(this.props.factoryRepairsCalendarDate).endOf('month').endOf('week').startOf('day').toDate();

        this.props.uiActions.changeAgentStep('repair');
        this.props.appointmentsActions.loadForTechnicians(startDate, endDate);
        this.props.calendarActions.loadLocks(startDate, endDate);
        this.props.subcontractorsActions.loadAll();
        this.props.factoryRepairsActions.loadBetween(factoryStartDate, factoryEndDate);
        this.props.usersActions.loadJaymarTechnicians();
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const isLoaded = this.props.serviceCallState != null && this.props.serviceCallState.details != null;
        const hasUpcomingFactoryRepairs = this.props.currentFactoryRepair != null;
        const hasUpcomingSubcontractorRepairs = this.props.currentSubcontractorRepair != null;
        const hasPendingRepairs = this.getCurrentRepairType(this.props.currentFactoryRepair, this.props.currentSubcontractorRepair, this.props.currentTechnicianRepair, this.props.currentCreditReturn) !== RepairType.none;
        const isAwaitingFurnitureReception = this.props.currentFactoryRepair ? this.props.currentFactoryRepair.receivedOn == null : false;

        const isReadOnly = hasPendingRepairs || this.props.serviceCallState == null || this.props.serviceCallState.isLoading || this.props.serviceCallState.isClosed;

        return (
            <div>
                <LoadingDimmer active={!isLoaded} />

                {hasUpcomingFactoryRepairs &&
                    <InformationMessage
                        header={isAwaitingFurnitureReception
                            ? formatMessage(m.awaitingFactoryRepairsFurnitureTitle)
                            : formatMessage(m.awaitingFactoryRepairsTitle)
                        }
                        content={isAwaitingFurnitureReception
                            ? formatMessage(m.awaitingFactoryRepairsFurnitureMessage)
                            : formatMessage(m.awaitingFactoryRepairsMessage)
                        }
                    />
                }

                <Grid columns="equal" stackable={true}>
                    <Grid.Row>
                        <Grid.Column>
                            <RepairTypeCard
                                selectedRepairType={this.state.previousSelectedRepairType}
                                onRepairTypeSelected={this.confirmRepairTypeSelection}
                                onRepairTypeCanceled={this.cancelCurrentRepairType}
                                isCancelling={this.props.serviceCallState != null ? this.props.serviceCallState.isCancellingCurrentRepairs : false}
                                isReadOnly={isReadOnly}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            {this.state.selectedRepairType !== RepairType.none &&
                                this.renderSelectedRepairType()
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }

    private renderSelectedRepairType() {
        switch (this.state.selectedRepairType) {
            case RepairType.factory:
                return (
                    <FactoryRepairsCard
                        serviceCallId={this.props.serviceCallState.details.id}
                        clientEmails={this.props.serviceCallState.details.client ? this.props.serviceCallState.details.client.emails.map(x => x.email) : []}
                        clientDefaultLanguage={this.props.serviceCallState.details.client ? this.props.serviceCallState.details.client.preferredLanguage : undefined}
                        lockedDates={this.props.lockedDates}
                        factoryRepairs={this.props.factoryRepairs}
                        areFactoryRepairsLoading={this.props.areFactoryRepairsLoading}
                        isExecutingStep={this.props.isExecutingRepairsStep}
                        currentFactoryRepair={this.props.currentFactoryRepair}
                        onSendNotification={this.sendFactoryRepairsNotification}
                        onChangeCalendarDate={this.changeFactoryCalendarStartDate}
                        onScheduleRepairs={this.scheduleRepairs}
                        onConfirmReception={this.confirmFurnitureReception}
                        onConfirmRepairs={this.confirmFurnitureRepairs}
                        onRepairClicked={(repair) => { }}
                    />
                );

            case RepairType.jaymarTechnician:
                return (
                    <JaymarTechnicianRepairsCard
                        technicians={this.props.technicians}
                        techniciansAppointments={this.props.techniciansAppointments}
                        lockedDates={this.props.lockedDates}
                        areTechniciansAppointmentsLoading={this.props.areTechniciansAppointmentsLoading}
                        areTechniciansAppointmentsSaving={this.props.areTechniciansAppointmentsSaving}
                        isExecutingRepairStep={this.props.isExecutingRepairsStep}
                        onAssign={this.assignTechnician}
                        onSaveAppointments={this.saveAppointmentsForTechnician}
                        currentRepair={this.props.currentTechnicianRepair}
                        scheduledRepairs={this.props.technicianRepairs}
                        onConfirmRepairs={this.confirmTechnicianRepairs}
                        onChangeCalendarDate={this.changeCalendarStartDate}
                        onServiceCallClicked={this.navigateToServiceCall}
                    />
                );

            case RepairType.subcontractor:
                return (
                    <SubcontractorRepairsCard
                        serviceCallId={this.props.serviceCallState.details.id}
                        subcontractors={this.props.subcontractors}
                        currentRepair={this.props.currentSubcontractorRepair}
                        isExecutingStep={this.props.isExecutingRepairsStep}
                        onAssign={this.assignSubcontractor}
                        onAddBill={this.addSubcontractorBill}
                        onChangeAppointmentDate={this.changeAppointmentDate}
                        onChangeWarrantyStatus={this.changeWarrantyStatus}
                    />
                );

            case RepairType.returnForCredit:
                return (
                    <ReturnForCreditCard
                        serviceCallId={this.props.serviceCallState.details.id}
                        clientEmails={this.props.serviceCallState.details.client ? this.props.serviceCallState.details.client.emails.map(x => x.email) : []}
                        clientDefaultLanguage={this.props.serviceCallState.details.client ? this.props.serviceCallState.details.client.preferredLanguage : undefined}
                        returnForCredit={this.props.currentCreditReturn}
                        isExecutingStep={this.props.isExecutingRepairsStep}
                        onSendNotification={this.sendReturnForCreditNotification}
                        onConfirmReception={this.confirmReturnForCreditFurnitureReception}
                        onAddCredit={this.addCredit}
                    />
                );

            default:
                return (null);
        }
    }

    private navigateToServiceCall = (appointment: TechnicianRepair) => {
        this.props.history.push(`/service-calls/${appointment.serviceCallId}/details`);
    }

    private sendFactoryRepairsNotification = (email: string | undefined, locale: string | undefined) => {
        const serviceCallId = Number(this.props.match.params.serviceCallId);
        this.props.serviceCallsActions.createFactoryRepairsRequest(serviceCallId, email, locale);
    }

    private scheduleRepairs = (date: Date) => {
        if (this.props.currentFactoryRepair) {
            const serviceCallId = Number(this.props.match.params.serviceCallId);
            const factoryRepairsId = this.props.currentFactoryRepair.id;
            this.props.serviceCallsActions.scheduleFactoryRepairs(serviceCallId, factoryRepairsId, date);
        }
    }

    private confirmFurnitureReception = () => {
        if (this.props.currentFactoryRepair) {
            const serviceCallId = Number(this.props.match.params.serviceCallId);
            const factoryRepairsId = this.props.currentFactoryRepair.id;
            this.props.serviceCallsActions.confirmFurnitureReception(serviceCallId, factoryRepairsId);
        }
    }

    private confirmFurnitureRepairs = () => {
        if (this.props.currentFactoryRepair) {
            const serviceCallId = Number(this.props.match.params.serviceCallId);
            const factoryRepairsId = this.props.currentFactoryRepair.id;
            this.props.serviceCallsActions.confirmFactoryRepairsCompletion(serviceCallId, factoryRepairsId);
        }
    }

    private assignSubcontractor = (subcontractorId: number | undefined, otherSubcontractorName: string | undefined, email: string | undefined, locale: string | undefined) => {
        const serviceCallId = Number(this.props.match.params.serviceCallId);
        this.props.serviceCallsActions.createSubcontractorRepairsRequest(serviceCallId, subcontractorId, otherSubcontractorName, email, locale);
    }

    private addSubcontractorBill = (repairFees: number, shippingFees: number, currencyCode: string, referenceNumber: string, invoice?: File) => {
        if (this.props.currentSubcontractorRepair) {
            const serviceCallId = Number(this.props.match.params.serviceCallId);
            const subcontractorRepairId = this.props.currentSubcontractorRepair.id;

            this.props.serviceCallsActions.addSubcontractorBill(serviceCallId, subcontractorRepairId, repairFees, shippingFees, currencyCode, referenceNumber, invoice);
        }
    }

    private changeWarrantyStatus = (warrantyStatus: SubcontractorRepairWarrantyStatus ) => {
        if (this.props.currentSubcontractorRepair != null) {
            const serviceCallId = Number(this.props.match.params.serviceCallId);
            const subcontractorRepairId = this.props.currentSubcontractorRepair.id;

            this.props.serviceCallsActions.setSubcontractorRepairWarrantyStatus(serviceCallId, subcontractorRepairId, warrantyStatus);
        }
    }

    private changeAppointmentDate = (appointmentDate: Date | undefined) => {
        if (this.props.currentSubcontractorRepair != null) {
            const serviceCallId = Number(this.props.match.params.serviceCallId);
            const subcontractorRepairId = this.props.currentSubcontractorRepair.id;

            this.props.serviceCallsActions.setSubcontractorAppointmentDate(serviceCallId, subcontractorRepairId, appointmentDate);
        }
    }

    private assignTechnician = (technicianId: string) => {
        const serviceCallId = Number(this.props.match.params.serviceCallId);
        this.props.serviceCallsActions.createTechnicianRepairsRequest(serviceCallId, technicianId);
    }

    private saveAppointmentsForTechnician = (appointments: TechnicianAppointment[]) => {
        const { currentTechnicianRepair } = this.props;
        const serviceCallId = Number(this.props.match.params.serviceCallId);

        if (currentTechnicianRepair) {
            this.props.serviceCallsActions.saveTechnicianAppointments(
                serviceCallId,
                currentTechnicianRepair.id,
                appointments
            );
        }
    }

    private confirmTechnicianRepairs = () => {
        const { currentTechnicianRepair } = this.props;
        const serviceCallId = Number(this.props.match.params.serviceCallId);

        if (currentTechnicianRepair) {
            this.props.serviceCallsActions.confirmTechnicianRepairsCompletion(serviceCallId, currentTechnicianRepair.id);
        }
    }

    private sendReturnForCreditNotification = (email: string | undefined, locale: string | undefined) => {
        const serviceCallId = Number(this.props.match.params.serviceCallId);
        this.props.serviceCallsActions.createCreditReturnRequest(serviceCallId, email, locale);
    }

    private confirmReturnForCreditFurnitureReception = () => {
        if (this.props.currentCreditReturn) {
            const serviceCallId = Number(this.props.match.params.serviceCallId);
            const creditReturnId = this.props.currentCreditReturn.id;
            this.props.serviceCallsActions.confirmFurnitureReceptionForCreditReturn(serviceCallId, creditReturnId);
        }
    }

    private addCredit = (amountCredited: number, shippingFeesCredited: number, currencyCode: string) => {
        if (this.props.currentCreditReturn) {
            const serviceCallId = Number(this.props.match.params.serviceCallId);
            const creditReturnId = this.props.currentCreditReturn.id;

            this.props.serviceCallsActions.addCreditForCreditReturn(serviceCallId, creditReturnId, amountCredited, shippingFeesCredited, currencyCode);
        }
    }

    private changeCalendarStartDate = (date: moment.Moment) => {
        const startDate = date.clone().startOf('month').startOf('week').startOf('day').toDate();
        const endDate = date.clone().endOf('month').endOf('week').startOf('day').toDate();

        this.props.appointmentsActions.setCalendarStartDate(date.toDate());
        this.props.appointmentsActions.loadForTechnicians(startDate, endDate);
        this.props.calendarActions.loadLocks(startDate, endDate);
    }

    private changeFactoryCalendarStartDate = (date: Date) => {
        const startDate = moment(date).startOf('month').startOf('week').startOf('day').toDate();
        const endDate = moment(date).endOf('month').endOf('week').startOf('day').toDate();

        this.props.factoryRepairsActions.setCalendarStartDate(date);
        this.props.factoryRepairsActions.loadBetween(startDate, endDate);
        this.props.calendarActions.loadLocks(startDate, endDate);
    }

    private confirmRepairTypeSelection = (repairType: RepairType) => {
        this.setState({
            selectedRepairType: repairType,
            previousSelectedRepairType: repairType
        });
    }

    private cancelCurrentRepairType = (repairType: RepairType) => {
        const serviceCallId = Number(this.props.match.params.serviceCallId);
        this.props.serviceCallsActions.cancelCurrentRepairs(serviceCallId, repairType);
    }

    private getCurrentRepairType(
        currentFactoryRepair: ServiceCallFactoryRepair | undefined,
        currentSubcontractorRepair: SubcontractorRepair | undefined,
        currentTechnicianRepair: TechnicianRepair | undefined,
        currentCreditReturn: ReturnForCredit | undefined): RepairType {

        const hasUpcomingFactoryRepairs = currentFactoryRepair != null;
        if (hasUpcomingFactoryRepairs) {
            return RepairType.factory;
        }

        const hasUpcomingTechnicianRepair = currentTechnicianRepair != null;
        if (hasUpcomingTechnicianRepair) {
            return RepairType.jaymarTechnician;
        }

        const hasUpcomingSubcontractorRepairs = currentSubcontractorRepair != null;
        if (hasUpcomingSubcontractorRepairs) {
            return RepairType.subcontractor;
        }

        const hasUpcomingCreditReturn = currentCreditReturn != null;
        if (hasUpcomingCreditReturn) {
            return RepairType.returnForCredit;
        }

        return RepairType.none;
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: ServiceCallRepairStepProps): ServiceCallRepairStepOwnProps => {
    const serviceCallId = Number(ownProps.match.params.serviceCallId);
    const selectedCalendarDate = moment(state.appointments.calendarStartDate).startOf('month').startOf('week').startOf('day').toDate();
    const appointmentsState = getAppointmentsState(state, selectedCalendarDate);
    const factoryRepairsCalendarDate = moment(state.factoryRepairs.calendarStartDate).startOf('month').startOf('week').startOf('day').toDate();
    const factoryRepairsState = getFactoryRepairsByStartDate(state, factoryRepairsCalendarDate);
    const startDate = selectedCalendarDate;
    const endDate = moment(state.appointments.calendarStartDate).endOf('month').endOf('week').startOf('day').toDate();
    const serviceCallState = getServiceCallState(state, serviceCallId);

    return {
        serviceCallState: serviceCallState,
        technicians: state.users.technicians,
        techniciansAppointments: getAllAppointments(state, selectedCalendarDate),
        areTechniciansAppointmentsLoading: appointmentsState ? appointmentsState.isLoading : false,
        areTechniciansAppointmentsSaving: serviceCallState ? serviceCallState.isSavingTechnicianRepairs : false,
        lockedDates: getBreaksBetween(state, startDate, endDate),
        selectedCalendarDate: state.appointments.calendarStartDate,
        factoryRepairs: factoryRepairsState.repairs,
        factoryRepairsCalendarDate: factoryRepairsCalendarDate,
        areFactoryRepairsLoading: factoryRepairsState.isLoading,
        isExecutingRepairsStep: serviceCallState != null ? serviceCallState.isExecutingRepairStep : false,
        currentFactoryRepair: getCurrentFactoryRepairs(state, serviceCallId),
        subcontractors: getAllSubcontractors(state),
        subcontractorRepairs: getSubcontractorRepairs(state, serviceCallId),
        currentSubcontractorRepair: getCurrentSubcontractorRepairs(state, serviceCallId),
        currentTechnicianRepair: getCurrentTechnicianRepairs(state, serviceCallId),
        technicianRepairs: getTechnicianRepairs(state, serviceCallId),
        currentCreditReturn: getCurrentCreditReturn(state, serviceCallId),
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ServiceCallRepairStepActions => {
    return {
        appointmentsActions: bindActionCreators(AppointmentsActions.actionCreators, dispatch),
        calendarActions: bindActionCreators(CalendarActions.actionCreators, dispatch),
        factoryRepairsActions: bindActionCreators(FactoryRepairsActions.actionCreators, dispatch),
        uiActions: bindActionCreators(UiActions.actionCreators, dispatch),
        serviceCallsActions: bindActionCreators(ServiceCallsActions.actionCreators, dispatch),
        subcontractorsActions: bindActionCreators(SubcontractorsActions.actionCreators, dispatch),
        usersActions: bindActionCreators(UsersActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(ServiceCallRepairStep);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as ServiceCallRepairStep };

