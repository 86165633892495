import React from 'react';
import { Segment } from 'semantic-ui-react';
import { UploadFileInput, UploadFileInputProps } from './UploadFileInput';
import { UploadFileProgress } from './UploadFileProgress';
import styles from './UploadFileControl.module.css';

export const UploadFileControl: React.FC<UploadFileInputProps> = (props) => {
    const classNames = `${styles.container} ${props.error ? styles.error : ''}`;

    return (
        <Segment className={classNames} textAlign="center">
            {!props.isUploading && <UploadFileInput {...props} />}
            {props.isUploading && <UploadFileProgress />}
        </Segment>
    );
};