import { GroupedDefectiveRatio } from "containers/reporting/types";
import React, { useEffect, useMemo, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { DefectiveRatiosService } from "services/DefectiveRatiosService";
import { getReportingDateRange } from "state/models";
import * as globalNotification from "../../../../state/ducks/global-notification";
import { DefectiveRatiosTable } from "../DefectiveRatiosTable";
import { useDetailedStatisticsContext } from "../DetailedStatisticsProvider";

const m = defineMessages({
  groupType: { id: "DefectiveRatiosByItem.GroupType", defaultMessage: "Item" },
});

const detailedStatisticsService = new DefectiveRatiosService();

export const DefectiveRatiosByItem = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  
  const {
    defectiveRatios: { filters },
  } = useDetailedStatisticsContext();

  const [isLoading, setIsLoading] = useState(false);

  const [defectiveRatios, setDefectiveRatios] = useState<GroupedDefectiveRatio[]>([]);

  const { startDate, endDate } = useMemo(
    () => getReportingDateRange(filters.dateFilter),
    [filters.dateFilter]
  );

  useEffect(() => {
    setIsLoading(true);

    detailedStatisticsService
      .getDefectiveRatiosByItem({ startDate, endDate })
      .then((response) => response.json() as GroupedDefectiveRatio[])
      .then((fetchedDefectiveRatios) => {
        setDefectiveRatios(fetchedDefectiveRatios);
      })
      .catch((error) => {
        dispatch(
          globalNotification.actionCreators.showErrorNotification(
            "Erreur",
            `Une erreur est survenue en tentant de récupérer les ratios de défectuosité par item (${error}).`
          )
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, endDate, startDate]);

  return (
    <DefectiveRatiosTable
      groupedDefectiveRatios={defectiveRatios}
      groupType={formatMessage(m.groupType)}
      isLoading={isLoading}
    />
  );
};
