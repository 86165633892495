import { ThunkAction } from 'redux-thunk';
import { ServiceCallNote, NoteVisibility } from '../../state/models';
import { ApplicationState } from '../../state/ducks';
import * as globalNotification from '../../state/ducks/global-notification';
import * as api from './api';
import { AppThunk } from '../../state/store';

export enum ActionTypeKeys {
    ADD_NOTE_REQUEST = 'NOTES_ADD_NOTE_REQUEST',
    ADD_NOTE_SUCCESS = 'NOTES_ADD_NOTE_SUCCESS',
    ADD_NOTE_FAILURE = 'NOTES_ADD_NOTE_FAILURE',

    CHANGE_VISIBILITY_REQUEST = 'NOTES_CHANGE_VISIBILITY_REQUEST',
    CHANGE_VISIBILITY_SUCCESS = 'NOTES_CHANGE_VISIBILITY_SUCCESS',
    CHANGE_VISIBILITY_FAILURE = 'NOTES_CHANGE_VISIBILITY_FAILURE',

    DELETE_NOTE_REQUEST = 'NOTES_DELETE_NOTE_REQUEST',
    DELETE_NOTE_SUCCESS = 'NOTES_DELETE_NOTE_SUCCESS',
    DELETE_NOTE_FAILURE = 'NOTES_DELETE_NOTE_FAILURE',

    LOAD_NOTES_REQUEST = 'NOTES_LOAD_NOTES_REQUEST',
    LOAD_NOTES_SUCCESS = 'NOTES_LOAD_NOTES_SUCCESS',
    LOAD_NOTES_FAILURE = 'NOTES_LOAD_NOTES_FAILURE',
}

export interface AddNoteRequest { type: ActionTypeKeys.ADD_NOTE_REQUEST; payload: { serviceCallId: number, noteContent: string }; }
export interface AddNoteSuccess { type: ActionTypeKeys.ADD_NOTE_SUCCESS; payload: { serviceCallId: number, note: ServiceCallNote }; }
export interface AddNoteFailure { type: ActionTypeKeys.ADD_NOTE_FAILURE; payload: { error: Error }; }

export interface ChangeVisibilityRequest { type: ActionTypeKeys.CHANGE_VISIBILITY_REQUEST; payload: { serviceCallId: number, noteId: number, visibility: NoteVisibility }; }
export interface ChangeVisibilitySuccess { type: ActionTypeKeys.CHANGE_VISIBILITY_SUCCESS; payload: { note: ServiceCallNote }; }
export interface ChangeVisibilityFailure { type: ActionTypeKeys.CHANGE_VISIBILITY_FAILURE; payload: { error: Error }; }

export interface DeleteNoteRequest { type: ActionTypeKeys.DELETE_NOTE_REQUEST; payload: { serviceCallId: number, noteId: number }; }
export interface DeleteNoteSuccess { type: ActionTypeKeys.DELETE_NOTE_SUCCESS; payload: { serviceCallId: number, noteId: number }; }
export interface DeleteNoteFailure { type: ActionTypeKeys.DELETE_NOTE_FAILURE; payload: { error: Error }; }

export interface LoadNotesRequest { type: ActionTypeKeys.LOAD_NOTES_REQUEST; payload: { serviceCallId: number }; }
export interface LoadNotesSuccess { type: ActionTypeKeys.LOAD_NOTES_SUCCESS; payload: { serviceCallId: number, notes: ServiceCallNote[] }; }
export interface LoadNotesFailure { type: ActionTypeKeys.LOAD_NOTES_FAILURE; payload: { error: Error }; }

export type ActionTypes =
    | AddNoteRequest | AddNoteSuccess | AddNoteFailure
    | ChangeVisibilityRequest | ChangeVisibilitySuccess | ChangeVisibilityFailure
    | DeleteNoteRequest | DeleteNoteSuccess | DeleteNoteFailure
    | LoadNotesRequest | LoadNotesSuccess | LoadNotesFailure
    | { type: '' };

export const actionCreators = {
    addNote: (serviceCallId: number, note: string, visibility: NoteVisibility, onNoteAdded?: () => void): AppThunk => {
        return (dispatch) => {
            dispatch({ type: ActionTypeKeys.ADD_NOTE_REQUEST, payload: { serviceCallId: serviceCallId } });

            api.addNote(serviceCallId, note, visibility)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.ADD_NOTE_SUCCESS, payload: { serviceCallId: serviceCallId, note: result } });

                    if (onNoteAdded) {
                        onNoteAdded();
                    }
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.ADD_NOTE_FAILURE, payload: { error } });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant d'ajouter une note interne à l'appel de service (${error}).`)
                    );
                });
        };
    },

    deleteNote: (serviceCallId: number, noteId: number): AppThunk => {
        return (dispatch) => {
            dispatch({ type: ActionTypeKeys.DELETE_NOTE_REQUEST, payload: { serviceCallId: serviceCallId, noteId: noteId } });

            api.deleteNote(serviceCallId, noteId)
                .then(_result => {
                    dispatch({ type: ActionTypeKeys.DELETE_NOTE_SUCCESS, payload: { serviceCallId: serviceCallId, noteId: noteId } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.DELETE_NOTE_FAILURE, payload: { error } });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de supprimer une note interne à l'appel de service (${error}).`)
                    );
                });
        };
    },

    changeVisibility: (serviceCallId: number, noteId: number, visibility: NoteVisibility): AppThunk => {
        return (dispatch) => {
            dispatch({ type: ActionTypeKeys.CHANGE_VISIBILITY_REQUEST, payload: { serviceCallId, noteId, visibility } });

            api.changeNoteVisibility(serviceCallId, noteId, visibility)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.CHANGE_VISIBILITY_SUCCESS, payload: { note: result } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.CHANGE_VISIBILITY_FAILURE, payload: { error } });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de changer la visibilité d'une note (${error}).`)
                    );
                });
        };
    },

    loadServiceCallNotes: (serviceCallId: number): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_NOTES_REQUEST, payload: { serviceCallId } });

            api.loadNotes(serviceCallId)
                .then(notes => {
                    dispatch({ type: ActionTypeKeys.LOAD_NOTES_SUCCESS, payload: { serviceCallId, notes } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_NOTES_FAILURE, payload: { error } });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de récupérer les notes associées à l'appel de service (${error}).`)
                    );
                });
        };
    },
};