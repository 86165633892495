import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { ServiceCallFactoryRepair } from '../../../state/models';
import { CalendarEventLabel } from '../../calendar/CalendarEventLabel';
import { Popup, LabelProps } from 'semantic-ui-react';
import { FactoryRepairQuickOverview } from '../../quick-overviews';
import { FactoryRepairStatus } from './FactoryRepairStatusLabel';

interface FactoryCalendarEventOwnProps {
    factoryRepair: ServiceCallFactoryRepair;
    onClick: (factoryRepair: ServiceCallFactoryRepair) => void;
}

export type FactoryCalendarEventProps =
    & FactoryCalendarEventOwnProps
    & WrappedComponentProps;

const m = defineMessages({
});

class FactoryCalendarEvent extends React.Component<FactoryCalendarEventProps, {}> {
    public render() {
        const { factoryRepair } = this.props;

        const trigger = (
            <CalendarEventLabel
                style={{ cursor: 'pointer' }}
                content={factoryRepair.serviceCallId}
                {...this.getLabelProps(factoryRepair)}
            />
        );

        return (
            <Popup
                trigger={trigger}
                content={<FactoryRepairQuickOverview repair={factoryRepair} onServiceCallClicked={this.props.onClick} />}
                on="click"
            />
        );
    }

    private getStatus(repair: ServiceCallFactoryRepair): FactoryRepairStatus {
        if (repair == null) {
            return FactoryRepairStatus.unknown;
        }

        if (repair.receivedOn == null) {
            return FactoryRepairStatus.awaitingShipment;
        }

        if (repair.completedRepairsOn == null) {
            return FactoryRepairStatus.awaitingRepairs;
        }

        return FactoryRepairStatus.repaired;
    }

    private getLabelProps(repair: ServiceCallFactoryRepair): LabelProps {
        const status = this.getStatus(repair);

        switch (status) {
            case FactoryRepairStatus.awaitingShipment:
                return {
                    color: 'orange',
                    icon: 'truck'
                };

            case FactoryRepairStatus.awaitingRepairs:
                return {
                    color: 'teal',
                    icon: 'wrench'
                };

            case FactoryRepairStatus.repaired:
                return {
                    color: 'green',
                    icon: 'check'
                };

            default:
                return {
                    color: 'grey',
                    icon: 'question'
                };
        }
    }
}

const connectedComponent = injectIntl(FactoryCalendarEvent);
export { connectedComponent as FactoryCalendarEvent };