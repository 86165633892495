import BaseService from "./baseService";
import queryString from "query-string";

export interface DefectiveRatioQueryFilters {
  startDate: Date;
  endDate: Date;
}

export class DefectiveRatiosService extends BaseService {
  constructor() {
    super("api/reporting/defective-ratios");
  }

  getFilterQueryString(filters: DefectiveRatioQueryFilters) {
      return queryString.stringify({
        ...filters,
        startDate: filters.startDate.toISOString(),
        endDate: filters.endDate.toISOString(),
      });
  }

  getDefectiveRatiosByItem(filters: DefectiveRatioQueryFilters): Promise<any> {
    return this.get(
      `/by-item?${this.getFilterQueryString(filters)}`
    );
  }

  getDefectiveRatiosByCover(filters: DefectiveRatioQueryFilters): Promise<any> {
    return this.get(`/by-cover?${this.getFilterQueryString(filters)}`);
  }

  getDefectiveRatiosByProvince(filters: DefectiveRatioQueryFilters): Promise<any> {
    return this.get(`/by-province?${this.getFilterQueryString(filters)}`);
  }

  getDefectiveRatiosByClient(filters: DefectiveRatioQueryFilters): Promise<any> {
    return this.get(`/by-client?${this.getFilterQueryString(filters)}`);
  }

  getDefectiveRatiosByBillingMonth(filters: DefectiveRatioQueryFilters): Promise<any> {
    return this.get(`/by-billing-month?${this.getFilterQueryString(filters)}`);
  }

  getDefectiveRatiosByProductType(filters: DefectiveRatioQueryFilters): Promise<any> {
    return this.get(`/by-product-type?${this.getFilterQueryString(filters)}`);
  }
}
