import { useIntl } from 'react-intl';
import { ListHeader, ListItem } from 'semantic-ui-react';
import { useSubcontractorRepairDetailsContext } from '../SubcontractorRepairDetailsProvider';
import { InvoiceLink } from './InvoiceLink';

export const InvoiceField = () => {
    const { formatMessage } = useIntl();
    const { repairDetails } = useSubcontractorRepairDetailsContext();

    if (!repairDetails) return null;

    return (
        <ListItem>
            <ListHeader>
                {formatMessage({
                    id: 'SubcontractorRepairPage.BillDetails.Invoice',
                    defaultMessage: 'Invoice',
                })}
            </ListHeader>

            {repairDetails.receiptUri ? (
                <InvoiceLink contentType={repairDetails.receiptContentType} />
            ) : (
                formatMessage({
                    id: 'SubcontractorRepairPage.BillDetails.NoInvoice',
                    defaultMessage: 'No invoice',
                })
            )}
        </ListItem>
    );
};
