import { ApplicationState } from '../../../state/ducks';
import { User, UserAccountType } from '../../../state/models';
import { UsersFilters } from './types';

export const getUserById = (state: ApplicationState, id: string): User | undefined => state.administration.users.byId[id];

export const getAllClients = (state: ApplicationState): User[] => {
    let clients = state.administration.users.allIds
        .map(x => getUserById(state, x))
        .filter(x => x != null && x.accountType === UserAccountType.client);

    const areDisabledUsersHidden = getUsersFilters(state).areDisabledAccountsHidden;
    if (areDisabledUsersHidden) {
        clients = clients.filter(x =>  !x?.isDisabled);
    }

    return clients as User[];
};

export const getAllEmployees = (state: ApplicationState): User[] => {
    let employees = state.administration.users.allIds
        .map(x => getUserById(state, x))
        .filter(x => x != null && x.accountType !== UserAccountType.client);

    const areDisabledUsersHidden = getUsersFilters(state).areDisabledAccountsHidden;
    if (areDisabledUsersHidden) {
        employees = employees.filter(x =>  !x?.isDisabled);
    }

    return employees as User[];
};

export const getAllLoadingUserIds = (state: ApplicationState): string[] => state.administration.users.loadingIds;
export const isUserLoading = (state: ApplicationState, userId: string): boolean => getAllLoadingUserIds(state).find(x => x === userId) != null;

export const getUsersFilters = (state: ApplicationState): UsersFilters => state.administration.users.filters;