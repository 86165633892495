import { Reducer } from 'redux';
import { ActionTypes } from './actions';
import { ActionTypeKeys } from './types';
import { User, UserAccountType } from '../../models';

export interface UsersState {
    isLoading: boolean;
    users: User[];
    customerServiceAgents: User[];
    areCustomerServiceAgentsLoading: boolean;
    representatives: User[];
    areRepresentativesLoading: boolean;
    technicians: User[];
    areTechniciansLoading: boolean;
    factoryTechnicians: User[];
    areFactoryTechniciansLoading: boolean;
}

const initialState: UsersState = {
    isLoading: false,
    users: [],
    customerServiceAgents: [],
    areCustomerServiceAgentsLoading: false,
    representatives: [],
    areRepresentativesLoading: false,
    technicians: [],
    areTechniciansLoading: false,
    factoryTechnicians: [],
    areFactoryTechniciansLoading: false
};

export const userReducer: Reducer<UsersState> = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case ActionTypeKeys.LOAD_AGENTS_REQUEST:
            return { ...state, areCustomerServiceAgentsLoading: true };

        case ActionTypeKeys.LOAD_AGENTS_SUCCESS:
            return {
                ...state,
                areCustomerServiceAgentsLoading: false,
                customerServiceAgents: action.payload
            };

        case ActionTypeKeys.LOAD_AGENTS_FAILURE:
            return { ...state, areCustomerServiceAgentsLoading: false };

        case ActionTypeKeys.LOAD_REPRESENTATIVES_REQUEST:
            return { ...state, areRepresentativesLoading: true };

        case ActionTypeKeys.LOAD_REPRESENTATIVES_SUCCESS:
            return {
                ...state,
                areRepresentativesLoading: false,
                representatives: action.payload
            };

        case ActionTypeKeys.LOAD_REPRESENTATIVES_FAILURE:
            return { ...state, areRepresentativesLoading: false };

        case ActionTypeKeys.LOAD_TECHNICIANS_REQUEST:
            return { ...state, areTechniciansLoading: true };

        case ActionTypeKeys.LOAD_TECHNICIANS_SUCCESS:
            return {
                ...state,
                areTechniciansLoading: false,
                technicians: action.payload
            };

        case ActionTypeKeys.LOAD_TECHNICIANS_FAILURE:
            return { ...state, areTechniciansLoading: false };

        case ActionTypeKeys.LOAD_FACTORY_TECHNICIANS_REQUEST:
            return { ...state, areFactoryTechniciansLoading: true };

        case ActionTypeKeys.LOAD_FACTORY_TECHNICIANS_SUCCESS:
            return {
                ...state,
                areFactoryTechniciansLoading: false,
                factoryTechnicians: action.payload
            };

        case ActionTypeKeys.LOAD_FACTORY_TECHNICIANS_FAILURE:
            return { ...state, areFactoryTechniciansLoading: false };

        default:
            return state;
    }
};

export default userReducer;