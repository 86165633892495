import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Button, ButtonProps, Divider, Step, StepProps } from 'semantic-ui-react';
import { commonMessages } from '../../constants';

export interface StepChangingEvent {
    cancel: boolean;
}

export interface MultiStepFormProps {
    currentStep: StepProps;
    steps: StepProps[];
    size?: 'mini' | 'tiny' | 'small' | 'large' | 'big' | 'huge' | 'massive';
    children?: React.ReactNode;
    isSaving?: boolean;
    onNextStep?: (e: StepChangingEvent) => void;
    onStepChange?: (step: StepProps) => void;
    onActiveStepClick?: () => void;
    onStepsComplete?: () => void;
}

class MultiStepForm extends React.Component<MultiStepFormProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const currentStepIndex = this.props.currentStep.id as number;
        const currentStepIndexInArray = this.props.steps.findIndex(x => x.id === this.props.currentStep.id);

        const formattedSteps = this.props.steps.map((value) => {
            const stepIndex = value.id as number;
            value.key = stepIndex;
            value.active = stepIndex === currentStepIndex;
            value.onClick = this.handleStepClick;
            value.completed = stepIndex < currentStepIndex;
            value.disabled = stepIndex > currentStepIndex;
            return value;
        });

        return (
            <div>
                <Step.Group unstackable={true} items={formattedSteps} widths={5} size={this.props.size} />
                <Divider />

                {this.props.children}
                <Divider section={true} />

                <div style={{ textAlign: 'right' }}>
                    {currentStepIndexInArray > 0
                        && <Button content={formatMessage(commonMessages.back)} onClick={this.handleBackClick} />}
                    {currentStepIndexInArray < this.props.steps.length - 1
                        && <Button primary={true} content={formatMessage(commonMessages.next)} onClick={this.handleNextClick} />}
                    {currentStepIndexInArray >= this.props.steps.length - 1
                        && <Button primary={true} loading={this.props.isSaving} disabled={this.props.isSaving} content={formatMessage(commonMessages.create)} onClick={this.handleSaveClick} />}
                </div>
            </div>
        );
    }

    private handleBackClick = (_event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
        const currentStepIndex = this.props.steps.findIndex(x => x.id === this.props.currentStep.id);
        if (currentStepIndex > 0) {
            const newStep = this.props.steps[currentStepIndex - 1];
            this.setState((current) => ({ ...current, currentStep: newStep }));

            if (this.props.onStepChange) {
                this.props.onStepChange(newStep);
            }
        }
    }

    private handleNextClick = (_event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
        const currentStepIndex = this.props.steps.findIndex(x => x.id === this.props.currentStep.id);
        if (currentStepIndex < this.props.steps.length - 1) {
            const newStep = this.props.steps[currentStepIndex + 1];
            var e: StepChangingEvent = { cancel: false };

            if (this.props.onNextStep) {
                this.props.onNextStep(e);
            }

            if (!e.cancel) {
                if (this.props.onStepChange) {
                    this.props.onStepChange(newStep);
                }
            }
        }
    }

    private handleSaveClick = (_event: React.MouseEvent<HTMLButtonElement>, _data: ButtonProps) => {
        if (this.props.onStepsComplete) {
            this.props.onStepsComplete();
        }
    }

    private handleStepClick = (_event: React.MouseEvent<HTMLAnchorElement>, data: StepProps) => {
        if (data.active) {
            if (this.props.onActiveStepClick) {
                this.props.onActiveStepClick();
            }
        } else {
            if (this.props.onStepChange) {
                this.props.onStepChange(data);
            }
        }
    }
}

const connectedComponent = injectIntl(MultiStepForm);
export { connectedComponent as MultiStepForm };