import { OverdueServiceCallsPage } from 'containers/after-sales/OverdueServiceCallsPage';
import { DetailedStatisticsPage } from 'containers/reporting';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SplashScreen } from '../components/app';
import { App, UnauthorizedPage } from '../containers';
import { ManageCalendarPage } from '../containers/administration';
import {
    AllTechnicianAppointmentsPage,
    DashboardPage,
    MyServiceCallsPage,
    NotificationsPage,
    ServiceCallAdvancedSearchResultPage,
    ServiceCallAgentPage,
    ServiceCallAssignStep,
    ServiceCallCloseStep,
    ServiceCallDetailsPage,
    ServiceCallInspectStep,
    ServiceCallRepairStep,
    ServiceCallsCustomPage,
    ServiceCallSearchResultPage,
    ServiceCallsHistoryPage,
    ServiceCallsPage,
    ServiceCallTechnicianPage,
    StaleServiceCallsPage,
    StalledUnassignedServiceCallsPage,
    TechnicianAppointmentsPage,
    TechnicianCalendarPage,
    TechnicianCompleteStep,
    TechnicianHistoryPage,
    TechnicianPrepareStep,
    TechnicianRepairStep,
    ViewServiceCallPage
} from '../containers/after-sales';
import { TechniciansCalendarPage } from '../containers/after-sales/agent';
import { ServiceCallApprovePage } from '../containers/after-sales/agent/ServiceCallApprovePage';
import { AllFactoryRepairsPage, FactoryCalendarPage, FactoryCompleteStep, FactoryHistoryPage, FactoryPrepareStep, FactoryRepairsPage, FactoryRepairStep, ServiceCallFactoryPage } from '../containers/after-sales/factory';
import { InspectionInspectStep, InspectionScheduleStep, RepresentativeAppointmentsPage, RepresentativeCalendarPage, RepresentativeHistoryPage, RepresentativeUnscheduledInspectionsPage, ServiceCallInspectionPage } from '../containers/after-sales/representative';
import { SubcontractorRepairPage } from '../containers/after-sales/subContractor/views/SubcontractorRepairPage';
import { StatisticsPage, TrendsPage } from '../containers/reporting';
import { ClientsAdoptionPage } from '../containers/reporting/ClientsAdoptionPage';
import { ConfirmPasswordResetPage, ForgotPasswordPage, LoginPage, LogoutPage, ResetPasswordPage } from '../modules/account';
import { UserProfilePage } from '../modules/account/views/UserProfilePage';
import { ManageDefectsPage } from '../modules/administration/defects/views';
import { CreateSubcontractorPage, ManageSubcontractorsPage, ModifySubcontractorPage } from '../modules/administration/subcontractors/views';
import { CreateClientPage, CreateEmployeePage, ManageClientsPage, ManageEmployeesPage, ModifyClientPage, ModifyEmployeePage } from '../modules/administration/users/views';
import { SubcontractorsBillingPage } from '../modules/customer-service/reporting/views/SubcontractorsBillingPage';
import { OrderItemsTab, OrderPage, OrderSummaryTab } from '../modules/orders';
import { OrderFollowUpsTab } from '../modules/orders/views/OrderFollowUpsTab';
import { CreateServiceCallPage } from '../modules/service-calls/views/CreateServiceCallPage';
import { agents, authenticatedUsers, employees, factoryTechnicians, representatives, technicians, UserAccountType } from '../state/models';
import { AnonymousRoute } from './AnonymousRoute';
import { AuthenticatedRoute } from './AuthenticatedRoute';

const administrator: UserAccountType[] = [
    UserAccountType.administrator
];

export const routes = (
    <SplashScreen>
        <Switch>
            <AnonymousRoute exact path="/account/login" component={LoginPage} />
            <AnonymousRoute exact path="/account/logout" component={LogoutPage} />
            <AnonymousRoute exact path="/account/forgot-password" component={ForgotPasswordPage} />
            <AnonymousRoute exact path="/account/forgot-password/confirm" component={ConfirmPasswordResetPage} />
            <AnonymousRoute exact path="/account/forgot-password/reset" component={ResetPasswordPage} />

            <AnonymousRoute exact path="/sub-contractor/repair" component={SubcontractorRepairPage} />

            <App>
                <AuthenticatedRoute authorizedRoles={authenticatedUsers} exact={true} path="/" component={DashboardPage} />
                <Route path="/unauthorized" component={UnauthorizedPage} />

                <AuthenticatedRoute authorizedRoles={administrator} exact={true} path="/administration/subcontractors" component={ManageSubcontractorsPage} />
                <Switch>
                    <AuthenticatedRoute authorizedRoles={administrator} exact={true} path="/administration/subcontractors/create" component={CreateSubcontractorPage} />
                    <AuthenticatedRoute authorizedRoles={administrator} exact={true} path="/administration/subcontractors/:subcontractorId" component={ModifySubcontractorPage} />
                </Switch>

                <AuthenticatedRoute authorizedRoles={administrator} exact path="/administration/defects" component={ManageDefectsPage} />


                <Switch>
                    <AuthenticatedRoute authorizedRoles={administrator} exact path="/administration/clients" component={ManageClientsPage} />
                    <AuthenticatedRoute authorizedRoles={administrator} exact path="/administration/clients/create" component={CreateClientPage} />
                    <AuthenticatedRoute authorizedRoles={administrator} exact path="/administration/clients/:userId" component={ModifyClientPage} />
                    <AuthenticatedRoute authorizedRoles={administrator} exact path="/administration/employees" component={ManageEmployeesPage} />
                    <AuthenticatedRoute authorizedRoles={administrator} exact path="/administration/employees/create" component={CreateEmployeePage} />
                    <AuthenticatedRoute authorizedRoles={administrator} exact path="/administration/employees/:userId" component={ModifyEmployeePage} />
                </Switch>

                <AuthenticatedRoute authorizedRoles={agents} exact={true} path="/administration/calendar" component={ManageCalendarPage} />
                <AuthenticatedRoute authorizedRoles={authenticatedUsers} path="/user-profile" component={UserProfilePage} />
                <AuthenticatedRoute authorizedRoles={authenticatedUsers} path="/notifications" component={NotificationsPage} />
                <Route exact path="/client/service-calls/create"><Redirect to="/service-calls/create/order" /></Route>

                <Switch>
                    <AuthenticatedRoute authorizedRoles={authenticatedUsers} path="/service-calls/create" component={CreateServiceCallPage} />
                    <AuthenticatedRoute authorizedRoles={authenticatedUsers} path="/service-calls/mine/:page?" component={MyServiceCallsPage} />
                    <AuthenticatedRoute authorizedRoles={agents} path="/service-calls/stale/:page?" component={StaleServiceCallsPage} />
                    <AuthenticatedRoute authorizedRoles={authenticatedUsers} path="/service-calls/overdue/:page?" component={OverdueServiceCallsPage} />
                    <AuthenticatedRoute authorizedRoles={authenticatedUsers} path="/service-calls/stalled-unassigned/:page?" component={StalledUnassignedServiceCallsPage} />
                    <AuthenticatedRoute authorizedRoles={authenticatedUsers} path="/service-calls/current/:page?" component={ServiceCallsPage} />
                    <AuthenticatedRoute authorizedRoles={authenticatedUsers} path="/service-calls/custom/:page?" component={ServiceCallsCustomPage} />
                    <AuthenticatedRoute authorizedRoles={authenticatedUsers} path="/service-calls/history/:page?" component={ServiceCallsHistoryPage} />
                    <AuthenticatedRoute authorizedRoles={authenticatedUsers} path="/service-calls/search/:searchTerm/:page?" component={ServiceCallSearchResultPage} />
                    <AuthenticatedRoute authorizedRoles={authenticatedUsers} path="/service-calls/advanced-search/:page?" component={ServiceCallAdvancedSearchResultPage} />
                    <AuthenticatedRoute authorizedRoles={authenticatedUsers} path="/service-calls/:serviceCallId" component={ViewServiceCallPage} />
                </Switch>

                <AuthenticatedRoute authorizedRoles={authenticatedUsers} path="/service-calls/:serviceCallId/details" component={ServiceCallDetailsPage} />
                <AuthenticatedRoute authorizedRoles={agents} path="/service-calls/:serviceCallId/agent" component={ServiceCallAgentPage} />
                <AuthenticatedRoute authorizedRoles={agents} path="/service-calls/:serviceCallId/agent/assign" component={ServiceCallAssignStep} />
                <AuthenticatedRoute authorizedRoles={agents} path="/service-calls/:serviceCallId/agent/approve" component={ServiceCallApprovePage} />
                <AuthenticatedRoute authorizedRoles={agents} path="/service-calls/:serviceCallId/agent/inspect" component={ServiceCallInspectStep} />
                <AuthenticatedRoute authorizedRoles={agents} path="/service-calls/:serviceCallId/agent/repair" component={ServiceCallRepairStep} />
                <AuthenticatedRoute authorizedRoles={agents} path="/service-calls/:serviceCallId/agent/close" component={ServiceCallCloseStep} />
                <AuthenticatedRoute authorizedRoles={technicians} path="/service-calls/:serviceCallId/technician" component={ServiceCallTechnicianPage} />
                <AuthenticatedRoute authorizedRoles={technicians} path="/service-calls/:serviceCallId/technician/prepare" component={TechnicianPrepareStep} />
                <AuthenticatedRoute authorizedRoles={technicians} path="/service-calls/:serviceCallId/technician/repair" component={TechnicianRepairStep} />
                <AuthenticatedRoute authorizedRoles={technicians} path="/service-calls/:serviceCallId/technician/complete" component={TechnicianCompleteStep} />

                <Switch>
                    <AuthenticatedRoute authorizedRoles={technicians} path="/service-calls/:serviceCallId/technician-repairs/all" component={AllTechnicianAppointmentsPage} />
                    <AuthenticatedRoute authorizedRoles={technicians} path="/service-calls/:serviceCallId/technician-repairs/:repairId" component={ServiceCallTechnicianPage} />
                </Switch>

                <AuthenticatedRoute authorizedRoles={technicians} path="/service-calls/:serviceCallId/technician-repairs/:repairId/prepare" component={TechnicianPrepareStep} />
                <AuthenticatedRoute authorizedRoles={technicians} path="/service-calls/:serviceCallId/technician-repairs/:repairId/repair" component={TechnicianRepairStep} />
                <AuthenticatedRoute authorizedRoles={technicians} path="/service-calls/:serviceCallId/technician-repairs/:repairId/complete" component={TechnicianCompleteStep} />

                <AuthenticatedRoute authorizedRoles={authenticatedUsers} path="/technicians/calendar" component={TechniciansCalendarPage} />
                <AuthenticatedRoute authorizedRoles={technicians} path="/technicians/my-appointments" component={TechnicianAppointmentsPage} />
                <AuthenticatedRoute authorizedRoles={technicians} path="/technicians/my-calendar/:date?" component={TechnicianCalendarPage} />
                <AuthenticatedRoute authorizedRoles={technicians} path="/technicians/history" component={TechnicianHistoryPage} />

                <Switch>
                    <AuthenticatedRoute authorizedRoles={factoryTechnicians} path="/service-calls/:serviceCallId/factory-repairs/all" component={AllFactoryRepairsPage} />
                    <AuthenticatedRoute authorizedRoles={factoryTechnicians} path="/service-calls/:serviceCallId/factory-repairs/:repairId" component={ServiceCallFactoryPage} />
                </Switch>

                <AuthenticatedRoute authorizedRoles={factoryTechnicians} path="/service-calls/:serviceCallId/factory-repairs/:repairId/prepare" component={FactoryPrepareStep} />
                <AuthenticatedRoute authorizedRoles={factoryTechnicians} path="/service-calls/:serviceCallId/factory-repairs/:repairId/repair" component={FactoryRepairStep} />
                <AuthenticatedRoute authorizedRoles={factoryTechnicians} path="/service-calls/:serviceCallId/factory-repairs/:repairId/complete" component={FactoryCompleteStep} />

                <AuthenticatedRoute authorizedRoles={factoryTechnicians} path="/factory/my-repairs" component={FactoryRepairsPage} />
                <AuthenticatedRoute authorizedRoles={authenticatedUsers} path="/factory/my-calendar/:date?" component={FactoryCalendarPage} />
                <AuthenticatedRoute authorizedRoles={factoryTechnicians} path="/factory/history" component={FactoryHistoryPage} />

                <AuthenticatedRoute authorizedRoles={authenticatedUsers} path="/service-calls/:serviceCallId/inspections/:inspectionId" component={ServiceCallInspectionPage} />
                <AuthenticatedRoute authorizedRoles={authenticatedUsers} path="/service-calls/:serviceCallId/inspections/:inspectionId/review" component={ServiceCallDetailsPage} />
                <AuthenticatedRoute authorizedRoles={authenticatedUsers} path="/service-calls/:serviceCallId/inspections/:inspectionId/schedule" component={InspectionScheduleStep} />
                <AuthenticatedRoute authorizedRoles={authenticatedUsers} path="/service-calls/:serviceCallId/inspections/:inspectionId/inspect" component={InspectionInspectStep} />

                <AuthenticatedRoute authorizedRoles={employees} path="/orders/:orderId" component={OrderPage} />
                <AuthenticatedRoute authorizedRoles={employees} path="/orders/:orderId/items/:selectedItemId?" component={OrderItemsTab} />
                <AuthenticatedRoute authorizedRoles={employees} path="/orders/:orderId/summary" component={OrderSummaryTab} />
                <AuthenticatedRoute authorizedRoles={employees} path="/orders/:orderId/follow-ups" component={OrderFollowUpsTab} />

                <AuthenticatedRoute authorizedRoles={representatives} path="/representative/unscheduled-inspections" component={RepresentativeUnscheduledInspectionsPage} />
                <AuthenticatedRoute authorizedRoles={representatives} path="/representative/my-appointments" component={RepresentativeAppointmentsPage} />
                <AuthenticatedRoute authorizedRoles={representatives} path="/representative/my-calendar" component={RepresentativeCalendarPage} />
                <AuthenticatedRoute authorizedRoles={representatives} path="/representative/history" component={RepresentativeHistoryPage} />

                <AuthenticatedRoute authorizedRoles={administrator} path="/reporting/clients-adoption" component={ClientsAdoptionPage} />
                <AuthenticatedRoute authorizedRoles={administrator} path="/reporting/statistics" component={StatisticsPage} />
                <AuthenticatedRoute authorizedRoles={administrator} path="/reporting/detailed-statistics" component={DetailedStatisticsPage} />
                <AuthenticatedRoute authorizedRoles={administrator} path="/reporting/trends" component={TrendsPage} />
                <AuthenticatedRoute authorizedRoles={agents} path="/reporting/subcontractors-billing" component={SubcontractorsBillingPage} />
            </App>
        </Switch>
    </SplashScreen>
);