import { defineMessages, useIntl } from 'react-intl';
import { List } from 'semantic-ui-react';
import { Address } from '../../state/models';
import { FormatHelper } from '../../state/utils';
import { AddressInformation, EmptyContentSegment } from '../common';

const m = defineMessages({
    emptyCustomerTitle: {
        id: 'ContactCustomerTab.emptyCustomerTitle',
        defaultMessage: 'No customer information',
    },
    emptyCustomerDescription: {
        id: 'ContactCustomerTab.emptyCustomerDescription',
        defaultMessage: "There's no customer associated to this service call",
    },
});

interface CustomerInformationProps {
    customer?: {
        name?: string | null;
        address?: Address;
        mainPhoneNumber?: string | null;
        otherPhoneNumber?: string | null;
        email?: string | null;
    };
}

export const CustomerInformation = ({ customer }: CustomerInformationProps) => {
    const { formatMessage } = useIntl();

    const hasCustomerInformation =
        customer &&
        (customer.name ||
            customer.email ||
            customer.mainPhoneNumber ||
            customer.otherPhoneNumber ||
            customer.address?.address1);

    if (!hasCustomerInformation) {
        <EmptyContentSegment
            basic={true}
            title={formatMessage(m.emptyCustomerTitle)}
            description={formatMessage(m.emptyCustomerDescription)}
        />;
    }

    return (
        <List>
            {customer?.name && (
                <List.Item>
                    <List.Icon name="user" />
                    <List.Content>{customer!.name}</List.Content>
                </List.Item>
            )}

            {customer?.email && (
                <List.Item>
                    <List.Icon name="mail" />
                    <List.Content>
                        <a href={`mailto:${customer.email}`}>
                            {customer.email}
                        </a>
                    </List.Content>
                </List.Item>
            )}

            {customer?.mainPhoneNumber && (
                <List.Item>
                    <List.Icon name="phone" />
                    <List.Content>
                        <a href={`tel:${customer.mainPhoneNumber}`}>
                            {FormatHelper.formatPhoneNumber(
                                customer.mainPhoneNumber
                            )}
                        </a>
                    </List.Content>
                </List.Item>
            )}

            {customer?.otherPhoneNumber && (
                <List.Item>
                    <List.Icon name="phone" />
                    <List.Content>
                        <a href={`tel:${customer.otherPhoneNumber}`}>
                            {FormatHelper.formatPhoneNumber(
                                customer.otherPhoneNumber
                            )}
                        </a>
                    </List.Content>
                </List.Item>
            )}

            {customer?.address?.address1 && (
                <List.Item>
                    <List.Icon name="marker" />
                    <List.Content>
                        {customer && customer.address && (
                            <AddressInformation
                                address={customer.address}
                                canNavigateTo={true}
                            />
                        )}
                    </List.Content>
                </List.Item>
            )}
        </List>
    );
};
