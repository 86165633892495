import moment from 'moment';
import React from 'react';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { Card, List } from 'semantic-ui-react';

import { DateHelper, UserHelper } from '../../../helpers';
import { ServiceCall, TechnicianRepair } from '../../../state/models';
import { FormatHelper } from '../../../state/utils';
import { AddressInformation, EmptyContentSegment, LoadingDimmer } from '../../common';

interface AppointmentInformationCardOwnProps {
    serviceCall: ServiceCall | undefined;
    appointment: TechnicianRepair | undefined;
    isLoading: boolean;
}

export type AppointmentInformationCardProps =
    & AppointmentInformationCardOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'AppointmentInformationCard.title', defaultMessage: 'Appointment information' },
    subtitle: { id: 'AppointmentInformationCard.subtitle', defaultMessage: 'View information about the upcoming appointment' },
    customerServiceLabel: { id: 'AppointmentInformationCard.customer_service_label', defaultMessage: 'Customer service' },
    appointmentLabel: { id: 'AppointmentInformationCard.appointment_label', defaultMessage: 'Appointment' },
    customerLabel: { id: 'AppointmentInformationCard.customer_label', defaultMessage: 'Customer' },
    addressLabel: { id: 'AppointmentInformationCard.address_label', defaultMessage: 'Address' },
    noAppointmentDate: { id: 'AppointmentInformationCard.no_appointment_date', defaultMessage: 'No date specified' },
    noAppointmentScheduledTitle: { id: 'AppointmentInformationCard.no_appointment_scheduled_title', defaultMessage: 'No appointment scheduled' },
    noAppointmentScheduledDescription: { id: 'AppointmentInformationCard.no_appointment_scheduled_description', defaultMessage: 'No appointment was scheduled for this service call.' },
    primaryPhoneNumberLabel: { id: 'AppointmentInformationCard.primary_phone_number_label', defaultMessage: 'Main phone number' },
    secondaryPhoneNumberLabel: { id: 'AppointmentInformationCard.secondary_phone_number_label', defaultMessage: 'Other phone number' }
});

class AppointmentInformationCard extends React.Component<AppointmentInformationCardProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const hasLoaded = !this.props.isLoading && this.props.serviceCall != null;
        const hasAppointment = hasLoaded && this.props.appointment != null;

        return (
            <Card fluid={true} color="teal" style={{ height: '100%' }}>
                <Card.Content style={{ flexGrow: 0 }}>
                    <Card.Header content={formatMessage(m.title)} />
                    <Card.Meta content={formatMessage(m.subtitle)} />
                </Card.Content>
                <Card.Content>
                    <LoadingDimmer active={this.props.isLoading} />
                    {hasLoaded && hasAppointment &&
                        this.renderAppointment()
                    }
                    {hasLoaded && !hasAppointment &&
                        this.renderEmptyAppointment()
                    }
                </Card.Content>
            </Card>
        );
    }

    private renderAppointment() {
        const { formatMessage } = this.props.intl;
        const { serviceCall } = this.props;

        if (serviceCall == null) {
            return (null);
        }

        return (
            <List style={{ marginTop: 0 }}>
                {serviceCall.assignedTo &&
                    <List.Item icon="doctor" header={formatMessage(m.customerServiceLabel)} content={UserHelper.getDisplayName(serviceCall.assignedTo)} />
                }
                <List.Item>
                    <List.Icon name="calendar" />
                    <List.Content>
                        <List.Header>{formatMessage(m.appointmentLabel)}</List.Header>
                        <List.Description>
                            {this.renderAppointmentDate()}
                        </List.Description>
                    </List.Content>
                </List.Item>
                {serviceCall.customer &&
                    <List.Item icon="user" header={formatMessage(m.customerLabel)} content={serviceCall.customer.name} />
                }
                <List.Item>
                    <List.Icon name="marker" />
                    <List.Content>
                        <List.Header content={formatMessage(m.addressLabel)} />
                        <List.Description>
                            {this.props.serviceCall && this.props.serviceCall.customer && this.props.serviceCall.customer.address &&
                                <AddressInformation
                                    address={this.props.serviceCall.customer.address}
                                    canNavigateTo={true}
                                />
                            }
                        </List.Description>
                    </List.Content>
                </List.Item>
                {serviceCall.customer != null &&
                    <React.Fragment>
                        {this.renderPhoneNumber(formatMessage(m.primaryPhoneNumberLabel), serviceCall.customer.mainPhoneNumber)}
                        {this.renderPhoneNumber(formatMessage(m.secondaryPhoneNumberLabel), serviceCall.customer.otherPhoneNumber)}
                    </React.Fragment>
                }
            </List>
        );
    }

    private renderEmptyAppointment() {
        const { formatMessage } = this.props.intl;

        return (
            <EmptyContentSegment
                basic={true}
                title={formatMessage(m.noAppointmentScheduledTitle)}
                description={formatMessage(m.noAppointmentScheduledDescription)}
            />
        );
    }

    private renderAppointmentDate() {
        const { formatMessage } = this.props.intl;

        const appointmentTime = this.props.appointment && this.props.appointment.scheduledForTimeRange
            ? formatMessage(DateHelper.getTimeRangeDescription(this.props.appointment.scheduledForTimeRange)).toLowerCase()
            : '';

        const appointmentDate = this.props.appointment && this.props.appointment.scheduledForDate
            ? moment.utc(this.props.appointment.scheduledForDate).format(`dddd, LL, [${appointmentTime}]`)
            : formatMessage(m.noAppointmentDate);

        return (
            <span>{appointmentDate}</span>
        );
    }

    private renderPhoneNumber(label: string, value?: string) {
        return value
            ? (
                <List.Item>
                    <List.Icon name="phone" />
                    <List.Content>
                        <List.Header content={label} />
                        <List.Description>
                            <a href={`tel:${value}`}>
                                {FormatHelper.formatPhoneNumber(value)}
                            </a>
                        </List.Description>
                    </List.Content>
                </List.Item>
            )
            : (null);
    }
}

const connectedComponent = injectIntl(AppointmentInformationCard);
export { connectedComponent as AppointmentInformationCard };