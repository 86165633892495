import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedMessage } from 'react-intl';
import { Feed, Popup } from 'semantic-ui-react';

import { LinkedServiceCallUpdate } from '../../../state/models';

export interface LinkedServiceCallActivityProps {
    activity: LinkedServiceCallUpdate;
}

const m = defineMessages({
    title: { id: 'ServiceCallRecentActivity.related_service_call_created', defaultMessage: 'The service call #{serviceCallLink} was created and linked to this order.' },
    showDetails: { id: 'LinkedServiceCallActivity.show_details', defaultMessage: 'Show details' }
});

class LinkedServiceCallActivity extends React.Component<LinkedServiceCallActivityProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { activity } = this.props;
        const summaryValues = { 'serviceCallLink': (<a href={`/service-calls/${activity.otherServiceCallId}/details`}>{activity.otherServiceCallId}</a>) };

        return (
            <Feed.Event className="meta" key={activity.id}>
                <Feed.Label icon="chain" />
                <Feed.Content>
                    <Popup
                        trigger={<Feed.Date content={moment.utc(activity.createdOn).fromNow()} />}
                        content={moment.utc(activity.createdOn).format('LL')}
                    />

                    <Feed.Summary>
                        <FormattedMessage {...m.title} values={summaryValues} />
                    </Feed.Summary>
                </Feed.Content>
            </Feed.Event>
        );
    }
}

const connectedComponent = injectIntl(LinkedServiceCallActivity);
export { connectedComponent as LinkedServiceCallActivity };