import { defineMessages } from 'react-intl';
import { IndexAccessibleMessages } from '.';

export const commonMessages = defineMessages({
    save: { id: 'common.save', defaultMessage: 'Save' },
    cancel: { id: 'common.cancel', defaultMessage: 'Cancel' },
    next: { id: 'common.next', defaultMessage: 'Next' },
    back: { id: 'common.back', defaultMessage: 'Back' },
    delete: { id: 'common.delete', defaultMessage: 'Delete' },
    modify: { id: 'common.modify', defaultMessage: 'Modify' },
    apply: { id: 'common.apply', defaultMessage: 'Apply' },
    create: { id: 'common.create', defaultMessage: 'Create' },
    created: { id: 'common.created', defaultMessage: 'Created' },
    loading: { id: 'common.loading', defaultMessage: 'Loading...' },
    saving: { id: 'common.saving', defaultMessage: 'Saving...' },
    confirm: { id: 'common.confirm', defaultMessage: 'Confirm' },
    confirmed: { id: 'common.confirmed', defaultMessage: 'Confirmed' },
    notify: { id: 'common.notify', defaultMessage: 'Notify' },
    notified: { id: 'common.notified', defaultMessage: 'Notified' },
    or: { id: 'common.or', defaultMessage: 'Or' },
    clear: { id: 'common.clear', defaultMessage: 'Clear' },
    add: { id: 'common.add', defaultMessage: 'Add' },
    added: { id: 'common.added', defaultMessage: 'Added' },
    filter: { id: 'common.filter', defaultMessage: 'Filter' },
    select: { id: 'common.select', defaultMessage: 'Select' },
    selected: { id: 'common.selected', defaultMessage: 'Selected' },
    search: { id: 'common.search', defaultMessage: 'Search' },
    view: { id: 'common.view', defaultMessage: 'View' },
    unknown: { id: 'common.unknown', defaultMessage: 'Unknown' },
    schedule: { id: 'common.schedule', defaultMessage: 'Schedule' },
    scheduled: { id: 'common.scheduled', defaultMessage: 'Scheduled' },
    complete: { id: 'common.complete', defaultMessage: 'Complete' },
    notApplicableAbbreviation: { id: 'common.notApplicable', defaultMessage: 'N/A' },
    notSpecified: { id: 'common.notSpecified', defaultMessage: 'Not specified' },
    total: { id: 'common.total', defaultMessage: 'Total' },
});

export const userMessages = defineMessages({
    employee: { id: 'user.employee', defaultMessage: 'Employee' },
    client: { id: 'user.client', defaultMessage: 'Client' },
    administrator: { id: 'user.administrator', defaultMessage: 'Administrator' },
    email: { id: 'user.email', defaultMessage: 'Email' },
    password: { id: 'user.password', defaultMessage: 'Password' },
    passwordConfirmation: { id: 'user.password_confirmation', defaultMessage: 'Password confirmation' },
    firstName: { id: 'user.first_name', defaultMessage: 'First name' },
    lastName: { id: 'user.last_name', defaultMessage: 'Last name' }
});

export const userRoleMessages = defineMessages({
    unknown: { id: 'user-role.unknown', defaultMessage: 'Unknown' },
    client: { id: 'user-role.client', defaultMessage: 'Client' },
    customerServiceAgent: { id: 'user-role.customer_service_agent', defaultMessage: 'Customer Service Agent' },
    administrator: { id: 'user-role.administrator', defaultMessage: 'Administrator' },
    jaymarRepresentative: { id: 'user-role.jaymar_representative', defaultMessage: 'Jaymar Representative' },
    jaymarTechnician: { id: 'user-role.jaymar_technician', defaultMessage: 'Jaymar Technician' },
    factoryTechnician: { id: 'user-role.factory_technician', defaultMessage: 'Factory Technician' }
});

export const orderTypeMessages = defineMessages({
    sale: { id: 'orderTypeMessages.saleOrder', defaultMessage: 'Sale' },
    stock: { id: 'orderTypeMessages.stockOrder', defaultMessage: 'Stock' },
    forProduction: { id: 'orderTypeMessages.forProductionOrder', defaultMessage: 'For production' },
    freeOfCharge: { id: 'orderTypeMessages.freeOfChargeOrder', defaultMessage: 'Free of charge' },
    interCompany: { id: 'orderTypeMessages.interCompanyOrder', defaultMessage: 'Inter-company' },
    diret: { id: 'orderTypeMessages.directOrder', defaultMessage: 'Direct' },
});

export const orderStatusMessages = defineMessages({
    creating: { id: 'orderStatusMessages.creating', defaultMessage: 'Creating' },
    verified: { id: 'orderStatusMessages.verified', defaultMessage: 'Verified' },
    inProduction: { id: 'orderStatusMessages.inProduction', defaultMessage: 'In production' },
    partiallyCompleted: { id: 'orderStatusMessages.partiallyCompleted', defaultMessage: 'Partially completed' },
    completed: { id: 'orderStatusMessage.completed', defaultMessage: 'Completed' },
    partiallyLoaded: { id: 'orderStatusMessages.partiallyLoaded', defaultMessage: 'Partially loaded' },
    loaded: { id: 'orderStatusMessages.loaded', defaultMessage: 'Loaded' },
    shipped: { id: 'orderStatusMessages.shipped', defaultMessage: 'Shipped' },
    billed: { id: 'orderStatusMessages.billed', defaultMessage: 'Billed' },
    cancelled: { id: 'orderStatusMessages.cancelled', defaultMessage: 'Cancelled' }
});

export const serviceCallMessages = defineMessages({
    unknown: { id: 'service-call.unknown', defaultMessage: 'Unknown' },
    new: { id: 'service-call.new', defaultMessage: 'New' },
    open: { id: 'service-call.open', defaultMessage: 'Open' },
    inspection: { id: 'service-call.inspection', defaultMessage: 'Inspection' },
    inspected: { id: 'service-call.inspected', defaultMessage: 'Inspected' },
    repairs: { id: 'service-call.repairs', defaultMessage: 'Repairs' },
    repairsInFactory: { id: 'service-call.repairs_in_factory', defaultMessage: 'Factory Repairs' },
    repairsByTechnician: { id: 'service-call.repairs_by_technician', defaultMessage: 'Technician Repairs' },
    repairsBySubcontractor: { id: 'service-call.repairs_by_subcontractor', defaultMessage: 'Subcontractor Repairs' },
    repaired: { id: 'service-call.repaired', defaultMessage: 'Repaired' },
    shipped: { id: 'service-call.shipped', defaultMessage: 'Shipped' },
    pending: { id: 'service-call.pending', defaultMessage: 'Pending' },
    closed: { id: 'service-call.closed', defaultMessage: 'Closed' },
    draft: { id: 'service-call.draft', defaultMessage: 'Draft' },
});

export const itemTypeMessages = defineMessages({
    product: { id: 'item-type.product', defaultMessage: 'Product' },
    assembled: { id: 'item-type.assembled', defaultMessage: 'Assembled' },
    template: { id: 'item-type.template', defaultMessage: 'Template' },
    parts: { id: 'item-type.parts', defaultMessage: 'Parts' }
});

export const timeRangeMessages = defineMessages({
    morning: { id: 'time-range.morning', defaultMessage: 'In the morning' },
    afternoon: { id: 'time-range.afternoon', defaultMessage: 'In the afternoon' },
    allDay: { id: 'time-range.all_day', defaultMessage: 'Before the end of the day' },
    am: { id: 'time-range.am', defaultMessage: 'AM' },
    pm: { id: 'time-range.pm', defaultMessage: 'PM' },
    notApplicable: { id: 'time-range.not-applicable', defaultMessage: 'N/A' }
});

export const unitMessages = defineMessages({
    bl: { id: 'unit.barrel', defaultMessage: 'Barrel' },
    bx: { id: 'unit.box', defaultMessage: 'Box' },
    c: { id: 'unit.hundred', defaultMessage: 'Hundred' },
    ca: { id: 'unit.case', defaultMessage: 'Case' },
    cf: { id: 'unit.cubic_feet', defaultMessage: 'Cubic feet' },
    cm: { id: 'unit.centimeter', defaultMessage: 'Centimeter' },
    ea: { id: 'unit.each', defaultMessage: 'Each' },
    ft: { id: 'unit.foot', defaultMessage: 'Foot' },
    gr: { id: 'unit.gram', defaultMessage: 'Gram' },
    in: { id: 'unit.inch', defaultMessage: 'Inch' },
    kg: { id: 'unit.kilogram', defaultMessage: 'Kilogram' },
    km: { id: 'unit.kilometre', defaultMessage: 'Kilometre' },
    l: { id: 'unit.litre', defaultMessage: 'Litre' },
    lb: { id: 'unit.pound', defaultMessage: 'Pound' },
    m: { id: 'unit.metre', defaultMessage: 'Metre' },
    pk: { id: 'unit.packaging', defaultMessage: 'Packaging' },
    pp: { id: 'unit.board_foot', defaultMessage: 'Board foot' },
    pr: { id: 'unit.pair', defaultMessage: 'Pair' },
    rl: { id: 'unit.roll', defaultMessage: 'Roll' },
    sf: { id: 'unit.square_foot', defaultMessage: 'Square foot' },
    sh: { id: 'unit.sheet', defaultMessage: 'Sheet' },
    si: { id: 'unit.square_inch', defaultMessage: 'Square inch' },
    sm: { id: 'unit.square_metre', defaultMessage: 'Square metre' },
    st: { id: 'unit.kit', defaultMessage: 'Kit' },
    th: { id: 'unit.thousand', defaultMessage: 'Thousand' },
    yd: { id: 'unit.yard', defaultMessage: 'Yard' }
}) as IndexAccessibleMessages;

export const defectCategoryMessages = defineMessages({
    framesStringsStraps: { id: 'defect-category.frames-springs-straps', defaultMessage: 'Frames, springs and straps' },
    recliningFurnitureMechanisms: { id: 'defect-category.reclining-furniture-mechanisms', defaultMessage: 'Reclining furniture mechanisms' },
    bedWithStorageMechanisms: { id: 'defect-category.bed-with-storage-mechanisms', defaultMessage: 'Bed with storage mechanisms' },
    sofaBedMechanisms: { id: 'defect-category.sofa-bed-mechanisms', defaultMessage: 'Sofa bed mechanisms' },
    headrestMechanisms: { id: 'defect-category.headrest-mechanisms', defaultMessage: 'Headrest mechanisms' },
    leathers: { id: 'defect-category.leathers', defaultMessage: 'Leathers' },
    fabrics: { id: 'defect-category.fabrics', defaultMessage: 'Fabrics' },
    electronicComponents: { id: 'defect-category.electronic-components', defaultMessage: 'Electronic components' },
    sofaBedMattresses: { id: 'defect-category.sofa-bed-mattresses', defaultMessage: 'Sofa bed mattresses' },
    foamSeats: { id: 'defect-category.foam-seats', defaultMessage: 'Foam seats' },
    paddedFiberComponents: { id: 'defect-category.padded-fiber-components', defaultMessage: 'Padded fiber components' },
    featherSeats: { id: 'defect-category.feather-seats', defaultMessage: 'Feather seats' },
    accessoriesAndParts: { id: 'defect-category.accessories-and-parts', defaultMessage: 'Accessories and parts' },
    legs: { id: 'defect-category.legs', defaultMessage: 'Legs' },
    furniture: { id: 'defect-category.furniture', defaultMessage: 'Furniture' },
    credit: { id: 'defect-category.credit', defaultMessage: 'Credit' },
    orderOutOfWarranty: { id: 'defect-category.order-out-of-warranty', defaultMessage: 'Order out of warranty' },
}) as IndexAccessibleMessages;

export const defectTypeMessages = defineMessages({
    loosseness: { id: 'defect-type.loosseness', defaultMessage: 'Looseness' },
    noiseCrackling: { id: 'defect-type.noise-crackling', defaultMessage: 'Noise, crackling' },
    level: { id: 'defect-type.level', defaultMessage: 'Level' },
    spacingBetweenBackrestAndArm: { id: 'defect-type.spacing-between-backrest-and-arm', defaultMessage: 'Spacing between backrest and arm' },
    spacingBetweenBackrests: { id: 'defect-type.spacing-between-backrests', defaultMessage: 'Spacing between backrests' },
    spacingBetweenSeats: { id: 'defect-type.spacing-between-seats', defaultMessage: 'Spacing between seats' },
    brokenStructure: { id: 'defect-type.broken-structure', defaultMessage: 'Broken structure' },
    smashedArmrests: { id: 'defect-type.smashed-armrests', defaultMessage: 'Smashed armrests' },
    unevenBackrests: { id: 'defect-type.uneven-backrests', defaultMessage: 'Uneven backrests' },
    brokenBedSupportSystem: { id: 'defect-type.broken-bed-support-system', defaultMessage: 'Broken bed support system' },
    noisyOpeningMechanism: { id: 'defect-type.noisy-opening-mechanism', defaultMessage: 'Noisy opening mechanism' },
    brokenOpeningMechanism: { id: 'defect-type.broken-opening-mechanism', defaultMessage: 'Broken opening mechanism' },
    mechanismOpensAndClosesByItself: { id: 'defect-type.mechanism-opens-and-closes-by-itself', defaultMessage: 'Mechanism opens and closes by itself' },
    noisyRocker: { id: 'defect-type.noisy-rocker', defaultMessage: 'Noisy rocker' },
    brokenRocker: { id: 'defect-type.broken-rocker', defaultMessage: 'Broken rocker' },
    noisySwivel: { id: 'defect-type.noisy-swivel', defaultMessage: 'Noisy swivel' },
    brokenSwivel: { id: 'defect-type.broken-swivel', defaultMessage: 'Broken swivel' },
    differentialLegHeight: { id: 'defect-type.differential-leg-height', defaultMessage: 'Different leg heights' },
    rocker: { id: 'defect-type.rocker', defaultMessage: 'Rocker' },
    brokenSupportSystem: { id: 'defect-type.broken-support-system', defaultMessage: 'Broken support system' },
    brokenDrawer: { id: 'defect-type.broken-drawer', defaultMessage: 'Broken drawer' },
    noisy: { id: 'defect-type.noisy', defaultMessage: 'Noisy' },
    brokenCylinder: { id: 'defect-type.broken-cylinder', defaultMessage: 'Broken cylinder' },
    scorchMarks: { id: 'defect-type.scorch-marks', defaultMessage: 'Scorch marks' },
    cuts: { id: 'defect-type.cuts', defaultMessage: 'Cuts' },
    scratches: { id: 'defect-type.scratches', defaultMessage: 'Scratches' },
    discoloration: { id: 'defect-type.discoloration', defaultMessage: 'Discoloration' },
    crookedStitches: { id: 'defect-type.crooked-stitches', defaultMessage: 'Crooked stitches' },
    openedStitches: { id: 'defect-type.opened-stitches', defaultMessage: 'Opened stitches' },
    foldsAbnormally: { id: 'defect-type.folds-abnormally', defaultMessage: 'Folds abnormally' },
    peels: { id: 'defect-type.peels', defaultMessage: 'Peels' },
    cinde: { id: 'defect-type.cinde', defaultMessage: 'Cinde' },
    stretches: { id: 'defect-type.stretches', defaultMessage: 'Stretches' },
    smears: { id: 'defect-type.smears', defaultMessage: 'Smears' },
    overallColoring: { id: 'defect-type.overall-coloring', defaultMessage: 'Overall coloring' },
    stitchesColoring: { id: 'defect-type.stitches-coloring', defaultMessage: 'Stitches coloring' },
    punctures: { id: 'defect-type.punctures', defaultMessage: 'Punctures' },
    wrongSpecifications: { id: 'defect-type.wrong-specifications', defaultMessage: 'Wrong specifications' },
    tears: { id: 'defect-type.tears', defaultMessage: 'Tears' },
    pilling: { id: 'defect-type.pilling', defaultMessage: 'Pilling' },
    shrinkage: { id: 'defect-type.shrinkage', defaultMessage: 'Shrinkage' },
    fiberMigration: { id: 'defect-type.fiber-migration', defaultMessage: 'Fiber migration' },
    squashing: { id: 'defect-type.squashing', defaultMessage: 'Squashing' },
    stretchedThread: { id: 'defect-type.stretched-thread', defaultMessage: 'Stretched thread' },
    greatlyMisalignedPattern: { id: 'defect-type.greatly-misaligned-pattern', defaultMessage: 'Greatly misaligned pattern' },
    zipperReleased: { id: 'defect-type.zipper-released', defaultMessage: 'Zipper released' },
    defectiveTouchControls: { id: 'defect-type.defective-touch-controls', defaultMessage: 'Defective touch controls' },
    defectiveMotor: { id: 'defect-type.defective-motor', defaultMessage: 'Defective motor' },
    softening: { id: 'defect-type.softening', defaultMessage: 'Softening' },
    sagging: { id: 'defect-type.sagging', defaultMessage: 'Sagging' },
    tooSoft: { id: 'defect-type.too-soft', defaultMessage: 'Too soft' },
    tooFirm: { id: 'defect-type.too-firm', defaultMessage: 'Too firm' },
    missingFeathers: { id: 'defect-type.missing-feathers', defaultMessage: 'Missing feathers' },
    feathersAreComingOut: { id: 'defect-type.feathers-are-coming-out', defaultMessage: 'Feathers are coming out' },
    defect: { id: 'defect-type.defect', defaultMessage: 'Defect' },
    missing: { id: 'defect-type.missing', defaultMessage: 'Missing' },
    overallSatisfaction: { id: 'defect-type.overall-satisfaction', defaultMessage: 'Overall satisfaction' },
    floorReturn: { id: 'defect-type.floor-return', defaultMessage: 'Floor return' },
    notApplicable: { id: 'defect-type.not-applicable', defaultMessage: 'Not applicable' },
    fourButtonsIlluminatedOnSwitch: { id: 'defect-type.four-buttons-illuminated-on-switch', defaultMessage: 'Four (4) buttons illuminated on switch' },
    obstructedByPadding: { id: 'defect-type.obstructed-by-padding', defaultMessage: 'Obstructed by padding' },
    unevenSeats: { id: 'defect-type.uneven-seats', defaultMessage: 'Uneven seats' },
    buttonWithUsbPort: { id: 'defect-type.button-with-usb-port', defaultMessage: 'Button with USB port' },
}) as IndexAccessibleMessages;

export const defectCauseMessages = defineMessages({
    wear: { id: 'defect-cause.wear', defaultMessage: 'Wear' },
    wearOnUConnector: { id: 'defect-cause.wear-on-u-connector', defaultMessage: 'Wear on the U connector' },
    legsNotInstalledProperly: { id: 'defect-cause.legs-not-installed-properly', defaultMessage: 'Legs not installed properly' },
    woodKnot: { id: 'defect-cause.wood-knot', defaultMessage: 'Wood knot' },
    backrestNotClippedProperly: { id: 'defect-cause.backrest-not-clipped-properly', defaultMessage: 'Backrest not clipped properly' },
    productionOrPadding: { id: 'defect-cause.production-or-padding', defaultMessage: 'Production or padding' },
    padding: { id: 'defect-cause.padding', defaultMessage: 'Padding' },
    rockerBlockerNotActivated: { id: 'defect-cause.rocker-blocker-not-activated', defaultMessage: 'Rocker blocker not activated' },
    brokenRodInTransport: { id: 'defect-cause.broken-rod-in-transport', defaultMessage: 'Broken rod in transport' },
    rodNotInstalledProperly: { id: 'defect-cause.rod-not-installed-properly', defaultMessage: 'Rod not installed properly' },
    looseComponents: { id: 'defect-cause.loose-components', defaultMessage: 'Loose components' },
    misuse: { id: 'defect-cause.misuse', defaultMessage: 'Misuse' },
    transport: { id: 'defect-cause.transport', defaultMessage: 'Transport' },
    animalClaws: { id: 'defect-cause.animal-claws', defaultMessage: 'Animal claws' },
    sunExposure: { id: 'defect-cause.sun-exposure', defaultMessage: 'Sun exposure' },
    heatExposure: { id: 'defect-cause.heat-exposure', defaultMessage: 'Heat exposure' },
    sebumFromScalp: { id: 'defect-cause.sebum-from-scalp', defaultMessage: 'Sebum from scalp' },
    nonRecommendedCleaningProcess: { id: 'defect-cause.non-recommended-cleaning-process', defaultMessage: 'Non recommended cleaning process' },
    appliedProducts: { id: 'defect-cause.applied-products', defaultMessage: 'Applied products' },
    production: { id: 'defect-cause.production', defaultMessage: 'Production' },
    rawMaterials: { id: 'defect-cause.raw-materials', defaultMessage: 'Raw materials' },
    orderInputFromStore: { id: 'defect-cause.order-input-from-store', defaultMessage: 'Order input from store' },
    orderInputFromJaymar: { id: 'defect-cause.order-input-from-jaymar', defaultMessage: 'Order input from Jaymar' },
    configuration: { id: 'defect-cause.configuration', defaultMessage: 'Configuration' },
    brokenWire: { id: 'defect-cause.broken-wire', defaultMessage: 'Broken wire' },
    motor: { id: 'defect-cause.motor', defaultMessage: 'Motor' },
    battery: { id: 'defect-cause.battery', defaultMessage: 'Battery' },
    transformer: { id: 'defect-cause.transformer', defaultMessage: 'Transformer' },
    shipping: { id: 'defect-cause.shipping', defaultMessage: 'Shipping' },
    assembly: { id: 'defect-cause.assembly', defaultMessage: 'Assembly' },
    notApplicable: { id: 'defect-cause.not-applicable', defaultMessage: 'Not applicable' },
    unknown: { id: 'defect-cause.unknown', defaultMessage: 'Unknown' },
    static: { id: 'defect-cause.static', defaultMessage: 'Static' },
    toBeDetermined: { id: 'defect-cause.to-be-determined', defaultMessage: 'To be determined' }
}) as IndexAccessibleMessages;
