// tslint:disable:no-any

import { ApiError } from 'utils/api';
import { ServiceError } from '.';

export class BaseService {
    private readonly baseConfiguration: RequestInit = {
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        },
    };

    constructor(private baseUrl: string) { }

    protected get(requestUrl?: string): Promise<any> {
        return this.fetchRequest(this.formatUrl(requestUrl), {
            ...this.baseConfiguration,
            method: 'GET'
        });
    }

    protected post(requestUrl: string, data: any) {
        return this.fetchRequest(this.formatUrl(requestUrl), {
            ...this.baseConfiguration,
            method: 'POST',
            body: this.formatBody(data)
        });
    }

    protected postFormData(requestUrl: string, formData: FormData) {
        return this.fetchRequest(this.formatUrl(requestUrl), {
            ...this.baseConfiguration,
            method: 'POST',
            body: formData,
            headers: undefined
        });
    }

    protected put(requestUrl: string, data: any) {
        return this.fetchRequest(this.formatUrl(requestUrl), {
            ...this.baseConfiguration,
            method: 'PUT',
            body: this.formatBody(data)
        });
    }

    protected delete(requestUrl: string, data: any) {
        return this.fetchRequest(this.formatUrl(requestUrl), {
            ...this.baseConfiguration,
            method: 'DELETE',
            body: this.formatBody(data)
        });
    }

    private fetchRequest(requestUrl: string, requestConfiguration?: RequestInit): Promise<any> {
        return fetch(requestUrl, requestConfiguration)
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return Promise.resolve(response);
                } else {
                    return response.json()
                        .then((errorData: ServiceError) => {
                            let error = new ApiError(response.status, errorData.userMessage);
                            return Promise.reject(error);
                        })
                        .catch(_ => {
                            // Response did not contain a JSON serialized error
                            let error = new ApiError(response.status, response.statusText);
                            return Promise.reject(error);
                        });
                }
            });
    }

    private formatUrl(requestUrl?: string) {
        return requestUrl ? this.baseUrl + requestUrl : this.baseUrl;
    }

    private formatBody(data?: any) {
        return data != null ? JSON.stringify(data) : '';
    }
}

export default BaseService;