export enum ActionTypeKeys {
    CREATE_REQUEST = 'USER_CREATE_REQUEST',
    CREATE_SUCCESS = 'USER_CREATE_SUCCESS',
    CREATE_FAILURE = 'USER_CREATE_FAILURE',

    DELETE_REQUEST = 'USER_DELETE_REQUEST',
    DELETE_SUCCESS = 'USER_DELETE_SUCCESS',
    DELETE_FAILURE = 'USER_DELETE_FAILURE',

    UPDATE_REQUEST = 'USER_UPDATE_REQUEST',
    UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS',
    UPDATE_FAILURE = 'USER_UPDATE_FAILURE',

    LOAD_ALL_REQUEST = 'USERS_LOAD_REQUEST',
    LOAD_ALL_SUCCESS = 'USERS_LOAD_SUCCESS',
    LOAD_ALL_FAILURE = 'USERS_LOAD_FAILURE',

    LOAD_AGENTS_REQUEST = 'USERS_LOAD_AGENTS_REQUEST',
    LOAD_AGENTS_SUCCESS = 'USERS_LOAD_AGENTS_SUCCESS',
    LOAD_AGENTS_FAILURE = 'USERS_LOAD_AGENTS_FAILURE',

    LOAD_REPRESENTATIVES_REQUEST = 'USERS_LOAD_REPRESENTATIVES_REQUEST',
    LOAD_REPRESENTATIVES_SUCCESS = 'USERS_LOAD_REPRESENTATIVES_SUCCESS',
    LOAD_REPRESENTATIVES_FAILURE = 'USERS_LOAD_REPRESENTATIVES_FAILURE',

    LOAD_TECHNICIANS_REQUEST = 'USERS_LOAD_TECHNICIANS_REQUEST',
    LOAD_TECHNICIANS_SUCCESS = 'USERS_LOAD_TECHNICIANS_SUCCESS',
    LOAD_TECHNICIANS_FAILURE = 'USERS_LOAD_TECHNICIANS_FAILURE',

    LOAD_FACTORY_TECHNICIANS_REQUEST = 'USERS_LOAD_FACTORY_TECHNICIANS_REQUEST',
    LOAD_FACTORY_TECHNICIANS_SUCCESS = 'USERS_LOAD_FACTORY_TECHNICIANS_SUCCESS',
    LOAD_FACTORY_TECHNICIANS_FAILURE = 'USERS_LOAD_FACTORY_TECHNICIANS_FAILURE',
}