import BaseService from './baseService';

export class AppointmentService extends BaseService {
    constructor() {
        super('api/appointments');
    }
    
    public getById(id: number) {
        return this.get(`/${id}`);
    }

    public getByServiceCall(id: number) {
        return this.get(`/by-service-call/${id}`);
    }

    public getForTechnicians(startDate: Date, endDate: Date) {
        return this.get(`/technicians/${startDate.toISOString()}/${endDate.toISOString()}`);
    }

    public getPending() {
        return this.get('/mine');
    }

    public getTodays() {
        return this.get('/today');
    }

    public getThisWeeks() {
        return this.get('/this-week');
    }

    public getCompleted() {
        return this.get('/completed');
    }

    public getMine(startDate: Date, endDate: Date) {
        return this.get(`/mine/${startDate.toISOString()}/${endDate.toISOString()}`);
    }
}