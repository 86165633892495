import { ApplicationState } from '..';
import { TechnicianRepair } from '../../models';
import { TechniciansAppointmentsState, TechnicianAppointmentState } from './reducers';

export function getAllAppointments(state: ApplicationState, startDate: Date): TechnicianRepair[] {
    const appointmentsState = state.appointments.allAppointments[startDate.toISOString()];

    if (appointmentsState && !appointmentsState.isLoading) {
        return appointmentsState.appointments || [];
    }

    return [];
}

export function getMyAppointments(state: ApplicationState, startDate: Date): TechnicianRepair[] {
    const appointmentsState = state.appointments.myAppointments[startDate.toISOString()];

    if (appointmentsState && !appointmentsState.isLoading) {
        return appointmentsState.appointments || [];
    }

    return [];
}

export function getAppointmentById(state: ApplicationState, id: number): TechnicianAppointmentState {
    return state.appointments.appointmentsById[id] 
        || {
            appointment: undefined,
            isLoading: true
        };
}

export function getAppointmentsByServiceCall(state: ApplicationState, serviceCallId: number): TechniciansAppointmentsState {
    return state.appointments.appointmentsByServiceCall[serviceCallId] 
        || {
            appointments: [],
            isLoading: true
        };
}

export function getAllMyAppointments(state: ApplicationState): TechnicianRepair[] {
    const appointmentsState = state.appointments.allMyAppointments;

    if (appointmentsState && !appointmentsState.isLoading) {
        return appointmentsState.appointments || [];
    }

    return [];
}

export function getPendingAppointments(state: ApplicationState): TechnicianRepair[] {
    const appointmentsState = state.appointments.pendingAppointments;

    if (appointmentsState && !appointmentsState.isLoading) {
        return appointmentsState.appointments || [];
    }

    return [];
}

export function getCompletedAppointments(state: ApplicationState): TechnicianRepair[] {
    const appointmentsState = state.appointments.completedAppointments;

    if (appointmentsState && !appointmentsState.isLoading) {
        return appointmentsState.appointments || [];
    }

    return [];
}

export function getTodaysAppointments(state: ApplicationState): TechnicianRepair[] {
    const appointmentsState = state.appointments.todaysAppointments;

    if (appointmentsState && !appointmentsState.isLoading) {
        return appointmentsState.appointments || [];
    }

    return [];
}

export function getThisWeeksAppointments(state: ApplicationState): TechnicianRepair[] {
    const appointmentsState = state.appointments.thisWeeksAppointments;

    if (appointmentsState && !appointmentsState.isLoading) {
        return appointmentsState.appointments || [];
    }

    return [];
}

export function getAppointmentsState(state: ApplicationState, startDate: Date): TechniciansAppointmentsState {
    const appointmentsState = state.appointments.allAppointments[startDate.toISOString()];

    return appointmentsState;
}

export const getAppointmentsCalendarStartDate = (state: ApplicationState): Date => state.appointments.calendarStartDate;
export const areAppointmentsLoading = (state: ApplicationState, startDate: Date): boolean => getAppointmentsState(state, startDate)?.isLoading || false;