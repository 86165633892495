export enum ActionTypeKeys {
    UPDATE_PROFILE_REQUEST = 'CURRENT_USER_UPDATE_PROFILE_REQUEST',
    UPDATE_PROFILE_SUCCESS = 'CURRENT_USER_UPDATE_PROFILE_SUCCESS',
    UPDATE_PROFILE_FAILURE = 'CURRENT_USER_UPDATE_PROFILE_FAILURE',

    LOAD_PROFILE_REQUEST = 'CURRENT_USER_LOAD_PROFILE_REQUEST',
    LOAD_PROFILE_SUCCESS = 'CURRENT_USER_LOAD_PROFILE_SUCCESS',
    LOAD_PROFILE_FAILURE = 'CURRENT_USER_LOAD_PROFILE_FAILURE',

    LOAD_API_REGISTRATION_REQUEST = 'CURRENT_USER_LOAD_API_REGISTRATION_REQUEST',
    LOAD_API_REGISTRATION_SUCCESS = 'CURRENT_USER_LOAD_API_REGISTRATION_SUCCESS',
    LOAD_API_REGISTRATION_FAILURE = 'CURRENT_USER_LOAD_API_REGISTRATION_FAILURE',

    REGISTER_API_REQUEST = 'CURRENT_USER_REGISTER_API_REQUEST',
    REGISTER_API_SUCCESS = 'CURRENT_USER_REGISTER_API_SUCCESS',
    REGISTER_API_FAILURE = 'CURRENT_USER_REGISTER_API_FAILURE',

    REVOKE_API_REQUEST = 'CURRENT_USER_REVOKE_API_REQUEST',
    REVOKE_API_SUCCESS = 'CURRENT_USER_REVOKE_API_SUCCESS',
    REVOKE_API_FAILURE = 'CURRENT_USER_REVOKE_API_FAILURE',

    UPDATE_API_WEBHOOK_REQUEST = 'CURRENT_USER_UPDATE_API_WEBHOOK_REQUEST',
    UPDATE_API_WEBHOOK_SUCCESS = 'CURRENT_USER_UPDATE_API_WEBHOOK_SUCCESS',
    UPDATE_API_WEBHOOK_FAILURE = 'CURRENT_USER_UPDATE_API_WEBHOOK_FAILURE',

    LOAD_API_WEBHOOKS_REQUEST = 'CURRENT_USER_LOAD_API_WEBHOOKS_REQUEST',
    LOAD_API_WEBHOOKS_SUCCESS = 'CURRENT_USER_LOAD_API_WEBHOOKS_SUCCESS',
    LOAD_API_WEBHOOKS_FAILURE = 'CURRENT_USER_LOAD_API_WEBHOOKS_FAILURE',
}

export interface ApiRegistration {
    id: number;
    createdOn: string;
    apiKey: string;
}

export interface ApiWebhook {
    id: number;
    type: WebhookType;
    url: string;
}

export enum WebhookType {
    undefined,
    serviceCallUpdated,
}