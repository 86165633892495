import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedMessage } from 'react-intl';
import { ServiceCallFactoryRepair, ServiceCallDefect } from '../../state/models';
import { List } from 'semantic-ui-react';
import { defectTypeMessages, defectCategoryMessages, commonMessages } from '../../constants';
import { UserHelper } from '../../helpers';

interface FactoryRepairQuickOverviewOwnProps {
    repair: ServiceCallFactoryRepair;
    onServiceCallClicked: (repair: ServiceCallFactoryRepair) => void;
}

export type FactoryRepairQuickOverviewProps =
    & FactoryRepairQuickOverviewOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'FactoryRepairQuickOverview.title', defaultMessage: 'Service call #{serviceCallLink}' },
    receivedOn: { id: 'FactoryRepairQuickOverview.received_on', defaultMessage: 'Received on {date}' },
    completedOn: { id: 'FactoryRepairQuickOverview.completed_on', defaultMessage: 'Completed on {date}' },
    scheduledFor: { id: 'FactoryRepairQuickOverview.scheduled_for', defaultMessage: 'Scheduled for {date}' }
});

class FactoryRepairQuickOverview extends React.Component<FactoryRepairQuickOverviewProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const repair = this.props.repair;
        const { serviceCall } = this.props.repair;
        const serviceCallLink = { 'serviceCallLink': (<a style={{ cursor: 'pointer' }} onClick={() => this.props.onServiceCallClicked(this.props.repair)}>{serviceCall.id}</a>) };

        const hasAgentAssigned = repair.serviceCall && repair.serviceCall.assignedTo;
        const agentAssigned = hasAgentAssigned
            ? UserHelper.getDisplayName(repair.serviceCall.assignedTo)
            : formatMessage(commonMessages.unknown);

        const hasDefects = repair.serviceCall && repair.serviceCall.defects && repair.serviceCall.defects.length > 0;

        return (
            <div>
                <h3><FormattedMessage {...m.title} values={serviceCallLink} /></h3>
                <List>
                    {hasAgentAssigned &&
                        <List.Item>
                            <List.Icon name="doctor" />
                            <List.Content>{agentAssigned}</List.Content>
                        </List.Item>
                    }

                    {repair.scheduledFor &&
                        <List.Item>
                            <List.Icon name="calendar" />
                            <List.Content>
                                {formatMessage(m.scheduledFor, { date: moment.utc(repair.scheduledFor).format('LL') })}
                            </List.Content>
                        </List.Item>
                    }

                    {repair.receivedOn &&
                        <List.Item>
                            <List.Icon name="truck" />
                            <List.Content>
                                {formatMessage(m.receivedOn, { date: moment.utc(repair.receivedOn).format('LL') })}
                            </List.Content>
                        </List.Item>
                    }

                    {repair.completedRepairsOn &&
                        <List.Item>
                            <List.Icon name="wrench" />
                            <List.Content>
                                {formatMessage(m.completedOn, { date: moment.utc(repair.completedRepairsOn).format('LL') })}
                            </List.Content>
                        </List.Item>
                    }

                    {hasDefects &&
                        this.renderDefects(repair.serviceCall.defects)
                    }
                </List>
            </div>
        );
    }

    private renderDefects(defects: ServiceCallDefect[]) {
        const { formatMessage } = this.props.intl;

        if (defects && defects.length > 0) {
            const defect = defects[0].defect;

            const typeMessages = Object.keys(defectTypeMessages).map(key => defectTypeMessages[key]);
            const defectTypeMessage = typeMessages.find(x => x.id === defect.defectType.title);
            const defectType = defectTypeMessage ? formatMessage(defectTypeMessage) : defect.defectType.title;

            const categoryMessages = Object.keys(defectCategoryMessages).map(key => defectCategoryMessages[key]);
            const defectCategoryMessage = categoryMessages.find(x => x.id === defect.defectCategory.title);
            const defectCategory = defectCategoryMessage ? formatMessage(defectCategoryMessage) : defect.defectCategory.title;

            return (
                <List.Item>
                    <List.Icon name="medkit" />
                    <List.Content>
                        <p style={{ marginBottom: 0 }}>{defectType}</p>
                        <p style={{ color: 'rgba(0,0,0,0.4)' }}>{defectCategory}</p>
                    </List.Content>
                </List.Item>
            );
        }

        return (null);
    }
}

const connectedComponent = injectIntl(FactoryRepairQuickOverview);
export { connectedComponent as FactoryRepairQuickOverview };