import React from 'react';
import { Popup, Icon, PopupProps, IconProps } from 'semantic-ui-react';

interface OwnProps {
    content?: string;
}

export type TooltipIconProps =
    & IconProps
    & OwnProps;

export const TooltipIcon: React.FC<TooltipIconProps> = (props) => {
    const { children, content, ...iconProps } = props;

    return (
        <Popup trigger={<Icon name="question circle outline" {...iconProps} />}>
            {children || content}
        </Popup>
    );
};