import _ from 'lodash';
import { SubcontractorsState, SubcontractorsDataState, SubcontractorsLoadingState } from './types';
import { Reducer, combineReducers } from 'redux';
import { SubcontractorsActionTypes, ActionTypeKeys } from './actions';

const initialState: SubcontractorsState = {
    data: {
        byId: {},
        allIds: []
    },
    loading: {
        all: false,
        ids: []
    }
};

export const dataReducer: Reducer<SubcontractorsDataState> = (state = initialState.data, action: SubcontractorsActionTypes) => {
    switch (action.type) {
        case ActionTypeKeys.LOAD_ALL_SUCCESS: {
            const byId = Object.assign({}, state.byId, ...action.payload.subcontractors.map(x => ({ [x.id]: x })));
            const allIds = action.payload.subcontractors.map(x => x.id);

            return {
                ...state,
                byId,
                allIds,
            };
        }

        case ActionTypeKeys.LOAD_BY_ID_SUCCESS: {
            return {
                ...state,
                byId: {
                    ...state,
                    [action.payload.subcontractorId]: action.payload.subcontractor
                }
            };
        }

        case ActionTypeKeys.DELETE_REQUEST: {
            return {
                ...state,
                allIds: state.allIds.filter(x => x !== action.payload.subcontractorId)
            };
        }

        case ActionTypeKeys.DELETE_FAILURE: {
            return {
                ...state,
                allIds: [...state.allIds, action.payload.subcontractorId]
            };
        }

        default:
            return state;
    }
};

export const loadingReducer: Reducer<SubcontractorsLoadingState> = (state = initialState.loading, action: SubcontractorsActionTypes) => {
    switch (action.type) {
        case ActionTypeKeys.LOAD_ALL_REQUEST: {
            return {
                ...state,
                all: true
            };
        }

        case ActionTypeKeys.LOAD_ALL_SUCCESS:
        case ActionTypeKeys.LOAD_ALL_FAILURE: {
            return {
                ...state,
                all: false
            };
        }

        case ActionTypeKeys.LOAD_BY_ID_REQUEST: {
            return {
                ...state,
                ids: _.union(state.ids, [action.payload.subcontractorId])
            };
        }

        case ActionTypeKeys.LOAD_BY_ID_SUCCESS:
        case ActionTypeKeys.LOAD_BY_ID_FAILURE: {
            return {
                ...state,
                ids: state.ids.filter(x => x !== action.payload.subcontractorId)
            };
        }

        default:
            return state;
    }
};

const subcontractorsReducer = combineReducers<SubcontractorsState>({
    data: dataReducer,
    loading: loadingReducer
});

export { subcontractorsReducer };