import moment from "moment";
import React from "react";
import { useIntl } from "react-intl";
import { Dropdown } from "semantic-ui-react";
import { NotificationHelper } from "../../../../../helpers";
import { Notification } from "../../../../../state/models";

interface OwnProps {
    notification: Notification;
    onRead: (notification: Notification) => void;
}

export type NotificationDropdownItemProps =
    & OwnProps;

export const NotificationDropdownItem: React.FC<NotificationDropdownItemProps> = (props) => {
    const { formatMessage } = useIntl();
    const { notification } = props;

    return (
        <Dropdown.Item
            onClick={() => props.onRead(props.notification)}
            style={{ whiteSpace: 'normal' }}
        >
            <p style={{ marginBottom: 0, fontWeight: 'bold' }}>{formatMessage(NotificationHelper.getDescription(notification), { referenceId: notification.referenceId || -1 })}</p>
            <span style={{ fontSize: 'smaller', color: 'rgba(0, 0, 0, 0.4)' }}>{moment.utc(notification.createdOn).fromNow()}</span>
        </Dropdown.Item>
    );
};