import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { Button, Divider, Form, Message } from "semantic-ui-react";
import { commonMessages } from "../../../../../constants";
import { User } from "../../../../../state/models";
import { UserProfileEdit } from "../../../models";
import { PasswordChangeSection } from "./PasswordChangeSection";
import { ProfilePropertiesSection } from "./ProfilePropertiesSection";

interface OwnProps {
    user: User | undefined;
    isSaving?: boolean;
    onSave: (user: UserProfileEdit) => void;
    onCancel: () => void;
}

export type UserProfileFormProps =
    & OwnProps;

export const UserProfileForm: React.FC<UserProfileFormProps> = (props) => {
    const { formatMessage } = useIntl();
    const formProps = useForm<UserProfileEdit>();
    const { setValue } = { ...formProps };

    const hasErrors = Object.keys(formProps.formState.errors).filter(x => (formProps.formState.errors as any)[x] != null).length > 0;
    const errorMessages = Object.keys(formProps.formState.errors).map(x => (formProps.formState.errors as any)[x]?.message);

    useEffect(() => {
        if (props.user != null) {
            setValue('firstName', props.user.firstName);
            setValue('lastName', props.user.lastName);
        }

    }, [props.user])

    return (
        <FormProvider {...formProps}>
            <Form error={hasErrors} style={{ maxWidth: '600px' }}>
                <Message error list={errorMessages} />

                <ProfilePropertiesSection />
                <Divider section />
                <PasswordChangeSection />

            </Form>

            <Divider section />
            <div style={{ textAlign: 'right' }}>
                <Button content={formatMessage(commonMessages.cancel)} onClick={props.onCancel} />
                <Button primary loading={props.isSaving} disabled={props.isSaving} content={formatMessage(commonMessages.save)} onClick={formProps.handleSubmit(props.onSave)} />
            </div>
        </FormProvider>
    );
};