import { initialDateFilter, DateFilter, isInitialDateFilter } from './DateFilter';
import { RegionFilter, initialRegionFilter, isInitialRegionFilter } from './RegionFilter';
import { ClientFilter, initialClientFilter, isInitialClientFilter } from './ClientFilter';

export interface StatisticsFilters {
    dateFilter: DateFilter;
    regionFilter: RegionFilter;
    clientFilter: ClientFilter;
}

export const initialStatisticsFilter: StatisticsFilters = {
    dateFilter: initialDateFilter,
    regionFilter: initialRegionFilter,
    clientFilter: initialClientFilter
};

export function isInitialStatisticsFilter(filter: StatisticsFilters) {
    return isInitialDateFilter(filter.dateFilter)
        && isInitialRegionFilter(filter.regionFilter)
        && isInitialClientFilter(filter.clientFilter);
}