import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { ApplicationState } from '../../../state/ducks';
import * as CustomerServiceActions from '../../../state/ducks/service-calls';
import * as FactoryRepairsActions from '../../../state/ducks/factory-repairs';
import * as UiActions from '../../../state/ducks/ui';
import { FactoryReportSummaryCard } from '../../../components/service-calls/factory';
import { FactoryReport, ServiceCall, ServiceCallFactoryRepair } from '../../../state/models';
import { getServiceCallState } from '../../../state/ducks/service-calls';
import { getFactoryReportByRepairId, getFactoryRepairById } from '../../../state/ducks/factory-repairs/selectors';
import { ServiceCallState } from '../../../state/ducks/service-calls/reducers';

interface FactoryCompleteStepActions {
    factoryRepairsActions: typeof FactoryRepairsActions.actionCreators;
    uiActions: typeof UiActions.actionCreators;
}

interface FactoryCompleteStepOwnProps {
    serviceCallState: ServiceCallState;
    report: FactoryReport;
    repair: ServiceCallFactoryRepair;
    isCompletingReport: boolean;
}

export type FactoryCompleteStepProps =
    & FactoryCompleteStepOwnProps
    & FactoryCompleteStepActions
    & RouteComponentProps<{ serviceCallId: string, repairId: string }>;

type IntlFactoryCompleteStepProps = FactoryCompleteStepProps
    & WrappedComponentProps;

class FactoryCompleteStep extends React.Component<IntlFactoryCompleteStepProps, {}> {
    public componentDidMount() {
        this.props.uiActions.changeFactoryStep('complete');
    }

    public render() {
        const isLoading = this.props.serviceCallState == null || this.props.serviceCallState.isLoading || this.props.report == null;
        const isComplete = this.props.repair != null && this.props.repair.completedRepairsOn != null;
        const serviceCall = this.props.serviceCallState ? this.props.serviceCallState.details : undefined;

        return (
            <React.Fragment>
                <FactoryReportSummaryCard
                    isLoading={isLoading}
                    isCompleting={this.props.isCompletingReport}
                    isComplete={isComplete}
                    serviceCall={serviceCall}
                    report={this.props.report}
                    onComplete={this.completeReport}
                />
            </React.Fragment>
        );
    }

    private completeReport = (report: FactoryReport) => {
        const { repairId } = this.props.match.params;
        this.props.factoryRepairsActions.completeReport(Number(repairId), () => {
            this.props.history.push('/factory/my-repairs');
        });
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: FactoryCompleteStepProps) => {
    const { serviceCallId, repairId } = ownProps.match.params;

    return {
        serviceCallState: getServiceCallState(state, Number(serviceCallId)),
        repair: getFactoryRepairById(state, Number(repairId)),
        report: getFactoryReportByRepairId(state, Number(repairId)),
        isCompletingReport: state.factoryRepairs.isCompletingReport
    };
};

const mapDispatchToProps = (dispatch: Dispatch): FactoryCompleteStepActions => {
    return {
        factoryRepairsActions: bindActionCreators(FactoryRepairsActions.actionCreators, dispatch),
        uiActions: bindActionCreators(UiActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(FactoryCompleteStep);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as FactoryCompleteStep };