import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Popup, Icon } from 'semantic-ui-react';

interface OwnProps {
    defectsCount: number;
}

export type DefectIconProps =
    & OwnProps
    & WrappedComponentProps;

const m = defineMessages({
    defectPopup: { id: 'OrderSummaryListItem.defectPopup', defaultMessage: 'A defect was identified on {count, plural, one {this product} other {{count} of these products}}.' }
});

class DefectIcon extends React.Component<DefectIconProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;

        if (this.props.defectsCount <= 0) {
            return (null);
        }

        return (
            <Popup flowing={true} trigger={<Icon size="small" name="medkit" color="red" circular={true} inverted={true} />}>
                {formatMessage(m.defectPopup, { count: this.props.defectsCount || 0 })}
            </Popup>
        );
    }
}

const intlComponent = injectIntl(DefectIcon);
export { intlComponent as DefectIcon };