import React, { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

interface OwnProps {
}

export type InternalLinkProps =
    & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
    & OwnProps;

export const InternalLink: React.FC<InternalLinkProps> = (props) => {
    const { children, style, ...buttonProps} = props;


    return (
        <button style={{ 
            background: 'none', 
            border: 'none', 
            padding: 0, 
            cursor: 'pointer', 
            color: '#4183c4', 
            lineHeight: '1.4285em',
            ...style 
        }} 
        {...buttonProps}>
            {children}
        </button>
    );
};
