import { CustomerEdit, ValidationResult } from '../../state/models';
import { CustomerInformation } from './CustomerInformation';
import { CustomerInformationForm } from './CustomerInformationForm';

interface ContactCustomerTabOwnProps {
    customer: CustomerEdit | undefined;
    validationResult: ValidationResult;
    isEditing: boolean;
    onChange: (customer: CustomerEdit) => void;
}

export const ContactCustomerTab = ({
    customer,
    validationResult,
    isEditing,
    onChange,
}: ContactCustomerTabOwnProps) => {
    return isEditing ? (
        <CustomerInformationForm
            customer={customer || new CustomerEdit()}
            hideHeader={true}
            onChange={onChange}
        />
    ) : (
        <div style={{ padding: '0 14px 14px 14px' }}>
            <CustomerInformation customer={customer} />
        </div>
    );
};
