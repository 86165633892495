import React, { useState } from "react";
import { List } from "semantic-ui-react";
import { Client } from "../../../state/models";
import { ClientListItem } from "./ClientListItem";
import { ClientSearch } from "../ClientSearch";

interface OwnProps {
    maxSelection?: number;
    values: Client[] | undefined;
    onChange: (clients: Client[]) => void;
}

export type ClientsPickerProps =
    & OwnProps;

export const ClientsPicker: React.FC<ClientsPickerProps> = (props) => {
    const [searchTerm, setSearchTerm] = useState("");

    const linkClientAccount = (searchResult: Client | undefined) => {
        const isAlreadySelected = searchResult != null && props.values?.find(client => client.id === searchResult.id) != null;
        if (!isAlreadySelected && searchResult != null) {
            const newSelectedClients = [...(props.values || []), searchResult];
            props.onChange(newSelectedClients);
        }
    }

    const unlinkClientAccount = (client: Client) => {
        const newSelectedClients = props.values?.filter(x => x.id !== client.id);
        props.onChange(newSelectedClients || []);
    }

    return (
        <>
            <ClientSearch 
                fluid
                disabled={props.maxSelection != null && (props.values?.length || 0) >= props.maxSelection}
                value={searchTerm}
                onSearchChange={(e, data) => setSearchTerm(data.value || '')}
                onResultSelected={linkClientAccount}
            />

            <List divided relaxed="very" verticalAlign="middle">
                {props.values?.map(client => (
                    <ClientListItem 
                        key={client.id} 
                        client={client} 
                        onDelete={() => unlinkClientAccount(client)}
                    />
                ))}
            </List>
        </>
    );
};