import { FormattedNumber, useIntl } from 'react-intl';
import { List, ListHeader, ListItem } from 'semantic-ui-react';
import { commonMessages } from '../../../../../constants';
import { useSubcontractorRepairDetailsContext } from '../SubcontractorRepairDetailsProvider';
import { InvoiceField } from './InvoiceField';

export const BillDetails = () => {
    const { formatMessage } = useIntl();
    const { repairDetails } = useSubcontractorRepairDetailsContext();

    if (!repairDetails) return null;

    return (
        <List relaxed>
            <InvoiceField />
            <ListItem>
                <ListHeader>
                    {formatMessage({
                        id: 'SubcontractorRepairPage.BillDetails.ReferenceNumber',
                        defaultMessage: 'Reference number',
                    })}
                </ListHeader>
                {repairDetails.referenceNumber ||
                    formatMessage(commonMessages.notSpecified)}
            </ListItem>
            <ListItem>
                <ListHeader>
                    {formatMessage({
                        id: 'SubcontractorRepairPage.BillDetails.RepairFees',
                        defaultMessage: 'Repair fees',
                    })}
                </ListHeader>
                <FormattedNumber
                    value={repairDetails.repairFees}
                    style="currency"
                    currency={repairDetails.currencyCode ?? undefined}
                />
            </ListItem>
            <ListItem>
                <ListHeader>
                    {formatMessage({
                        id: 'SubcontractorRepairPage.BillDetails.ShippingFees',
                        defaultMessage: 'Shipping fees',
                    })}
                </ListHeader>
                <FormattedNumber
                    value={repairDetails.shippingFees}
                    style="currency"
                    currency={repairDetails.currencyCode ?? undefined}
                />
            </ListItem>
            <ListItem>
                <ListHeader>{formatMessage(commonMessages.total)}</ListHeader>
                <FormattedNumber
                    value={
                        repairDetails.repairFees + repairDetails.shippingFees
                    }
                    style="currency"
                    currency={repairDetails.currencyCode ?? undefined}
                />
            </ListItem>
        </List>
    );
};
