import { TechnicianReport, TechnicianRepairsStatus } from '../models';
import { FormattedMessage, defineMessages, MessageDescriptor } from 'react-intl';
import { StringHelper } from '../utils';

const m = defineMessages({
    serviceReportRequired: { id: 'TechnicianReportValidator.service_report_required', defaultMessage: 'The service report is required.' },
    repairStatusRequired: { id: 'TechnicianReportValidator.repair_status_required', defaultMessage: 'The repairs status is required.' },
    labourTimeRequired: { id: 'TechnicianReportValidator.labour_time_required', defaultMessage: 'The labour time is required.' },
    clientSignatureRequired: { id: 'TechnicianReportValidator.client_signature_required', defaultMessage: 'The client signature is required.' }
});

export class TechnicianReportValidation {
    public isReportValid: boolean = true;
    public isRepairStatusValid: boolean = true;
    public isLabourTimeValid: boolean = true;
    public isClientSignatureValid: boolean = true;
    public errors: MessageDescriptor[] = [];

    public constructor(init?: Partial<TechnicianReportValidation>) {
        Object.assign(this, init);
    }

    public isValid(): boolean {
        return this.isReportValid 
            && this.isRepairStatusValid 
            && this.isLabourTimeValid 
            && this.isClientSignatureValid;
    }
}

export class TechnicianReportValidator {
    public static success(): TechnicianReportValidation {
        return new TechnicianReportValidation();
    }

    public static validate(report: TechnicianReport): TechnicianReportValidation {
        const repairValidation = this.validateRepairStep(report);
        const completeValidation = this.validateCompleteStep(report);

        return new TechnicianReportValidation({
            isReportValid: repairValidation.isReportValid && completeValidation.isReportValid,
            isRepairStatusValid: repairValidation.isRepairStatusValid && completeValidation.isRepairStatusValid,
            isLabourTimeValid: repairValidation.isLabourTimeValid && completeValidation.isLabourTimeValid,
            isClientSignatureValid: repairValidation.isClientSignatureValid && completeValidation.isClientSignatureValid,
            errors: [...repairValidation.errors, ...completeValidation.errors]
        });
    }

    public static validateRepairStep(report: TechnicianReport): TechnicianReportValidation {
        let validationSummary = this.success();

        if (!StringHelper.hasValue(report.report)) {
            validationSummary.isReportValid = false;
            validationSummary.errors.push(m.serviceReportRequired);
        }

        if (report.repairsStatus === TechnicianRepairsStatus.unknown) {
            validationSummary.isRepairStatusValid = false;
            validationSummary.errors.push(m.repairStatusRequired);
        }

        if (report.hoursTaken <= 0 && report.minutesTaken <= 0) {
            validationSummary.isLabourTimeValid = false;
            validationSummary.errors.push(m.labourTimeRequired);
        }

        return validationSummary;
    }

    public static validateCompleteStep(report: TechnicianReport): TechnicianReportValidation {
        let validationSummary = this.success();

        if (!StringHelper.hasValue(report.clientSignature)) {
            validationSummary.isClientSignatureValid = false;
            validationSummary.errors.push(m.clientSignatureRequired);
        }

        return validationSummary;
    }
}