import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Popup, List } from 'semantic-ui-react';

import { Client } from '../../state/models';
import { FormatHelper } from '../../state/utils';

export interface ClientQuickOverviewProps {
    client: Client;
}

const m = defineMessages({
    title: { id: 'ClientQuickOverview.title', defaultMessage: 'Quick overview' }
});

class ClientQuickOverview extends React.Component<ClientQuickOverviewProps & WrappedComponentProps, {}> {    
    public render() {
        const { formatMessage } = this.props.intl;
        const { client } = this.props;
        const popupLink = <a>{client.id} - {client.name}</a>;
        const popupContent = (
            <div>
                <h3>{formatMessage(m.title)}</h3>
                <List>
                    <List.Item>
                        <List.Icon name="building" />
                        <List.Content>{client.id} - {client.name}</List.Content>
                    </List.Item>

                    {client.phoneNumber && <List.Item icon="phone" content={FormatHelper.formatPhoneNumber(client.phoneNumber)} />}
                    {client.faxNumber && <List.Item icon="fax" content={FormatHelper.formatPhoneNumber(client.faxNumber)} />}

                    {client.address.address1 &&
                        <List.Item>
                            <List.Icon name="marker" />
                            <List.Content>
                                {FormatHelper.formatAddress(client.address.address1)}
                                <span style={{display: 'block'}}>{client.address.address2}</span>
                                <span style={{display: 'block'}}>
                                    {client.address.city} {client.address.provinceCode && <span>({client.address.provinceCode.trim()})</span>} {client.address.postalCode}</span>
                                <span style={{display: 'block'}}>{client.address.countryCode}</span>
                            </List.Content>
                        </List.Item>
                    }
                </List>
            </div>
        );

        return (
            <Popup on="click" trigger={popupLink} content={popupContent} style={{maxWidth: 350}} />
        );
    }
}

const connectedComponent = injectIntl(ClientQuickOverview);
export { connectedComponent as ClientQuickOverview };