import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedMessage, } from 'react-intl';
import { Feed, SemanticICONS, Popup, Icon, List } from 'semantic-ui-react';

import { ServiceCallUpdate, RepairedInFactoryUpdate, ServiceCallFactoryRepair } from '../../../state/models';
import { StringHelper } from '../../../state/utils';
import { FactoryReportModal } from '../reports';

export interface RepairInFactoryActivityProps {
    activity: ServiceCallUpdate;
    factoryRepair: ServiceCallFactoryRepair | undefined;
}

const m = defineMessages({
    summary: { id: 'RepairedInFactoryActivity.summary', defaultMessage: 'Jaymar completed the factory repairs on the furniture.' },
    receivedOnFormat: { id: 'RepairedInFactoryActivity.received_on_format', defaultMessage: 'Received the furniture on {date}' },
    completedRepairsOnFormat: { id: 'RepairedInFactoryActivity.completed_repairs_on_format', defaultMessage: 'Completed the repairs on {date}' },
    shippedOnFormat: { id: 'RepairedInFactoryActivity.shipped_on_format', defaultMessage: 'Shipped the furniture on {date}' },
    viewReport: { id: 'RepairedInFactoryActivity.view_report', defaultMessage: 'View report' }
});

class RepairedInFactoryActivity extends React.Component<RepairInFactoryActivityProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { activity, factoryRepair } = this.props;
        const details = activity as RepairedInFactoryUpdate;

        return (
            <Feed.Event key={activity.id}>
                <Feed.Label icon="factory" />
                <Feed.Content>
                    <Popup
                        trigger={<Feed.Date content={moment.utc(activity.createdOn).fromNow()} />}
                        content={moment.utc(activity.createdOn).format('LL')}
                    />

                    <Feed.Summary>
                        {formatMessage(m.summary)}
                    </Feed.Summary>

                    <Feed.Extra text={true}>
                        <List bulleted={true}>
                            {details.receivedOn && <List.Item content={formatMessage(m.receivedOnFormat, { date: moment.utc(details.receivedOn).format('LL') })} />}
                            {details.completedRepairsOn && <List.Item content={formatMessage(m.completedRepairsOnFormat, { date: moment.utc(details.completedRepairsOn).format('LL') })} />}
                            {details.shippedOn && <List.Item content={formatMessage(m.shippedOnFormat, { date: moment.utc(details.shippedOn).format('LL') })} />}
                        </List>

                        {StringHelper.hasValue(activity.note) &&
                            <p style={{ whiteSpace: 'pre-line' }}>{activity.note}</p>
                        }

                        {factoryRepair != null &&
                            <FactoryReportModal
                                trigger={<a>{formatMessage(m.viewReport)}</a>}
                                factoryRepair={factoryRepair}
                            />
                        }
                    </Feed.Extra>
                </Feed.Content>
            </Feed.Event>
        );
    }
}

const connectedComponent = injectIntl(RepairedInFactoryActivity);
export { connectedComponent as RepairedInFactoryActivity };