import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Popup } from 'semantic-ui-react';

interface OwnProps {
    currentSum: number;
    totalSum: number;
}

export type CumulativePercentageProps =
    & OwnProps
    & WrappedComponentProps;

const m = defineMessages({
    tooltip: { id: 'CumulativePercentage.tooltip', defaultMessage: 'The cumulative percentage of the current and previous lines ({current}) compared to the sum of all the lines ({total}).' }
});

class CumulativePercentage extends React.Component<CumulativePercentageProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const cumulativePercentage = this.props.totalSum !== 0 ? Math.round((this.props.currentSum / this.props.totalSum) * 100) : 0;

        return (
            <Popup trigger={<span style={{ marginLeft: '10px' }}>{cumulativePercentage}%</span>}>
                {formatMessage(m.tooltip, { current: this.props.currentSum, total: this.props.totalSum })}
            </Popup>
        );
    }
}

const intlComponent = injectIntl(CumulativePercentage);
export { intlComponent as CumulativePercentage };