export enum ActionTypeKeys {
    LOAD_REPAIR_REQUEST = 'FACTORY_LOAD_REPAIR_REQUEST',
    LOAD_REPAIR_SUCCESS = 'FACTORY_LOAD_REPAIR_SUCCESS',
    LOAD_REPAIR_FAILURE = 'FACTORY_LOAD_REPAIR_FAILURE',
    LOAD_PENDING_REPAIRS_REQUEST = 'FACTORY_LOAD_PENDING_REPAIRS_REQUEST',
    LOAD_PENDING_REPAIRS_SUCCESS = 'FACTORY_LOAD_PENDING_REPAIRS_SUCCESS',
    LOAD_PENDING_REPAIRS_FAILURE = 'FACTORY_LOAD_PENDING_REPAIRS_FAILURE',
    LOAD_COMPLETED_REPAIRS_REQUEST = 'FACTORY_LOAD_COMPLETED_REPAIRS_REQUEST',
    LOAD_COMPLETED_REPAIRS_SUCCESS = 'FACTORY_LOAD_COMPLETED_REPAIRS_SUCCESS',
    LOAD_COMPLETED_REPAIRS_FAILURE = 'FACTORY_LOAD_COMPLETED_REPAIRS_FAILURE',
    LOAD_REPAIRS_BETWEEN_REQUEST = 'FACTORY_LOAD_REPAIRS_BETWEEN_REQUEST',
    LOAD_REPAIRS_BETWEEN_SUCCESS = 'FACTORY_LOAD_REPAIRS_BETWEEN_SUCCESS',
    LOAD_REPAIRS_BETWEEN_FAILURE = 'FACTORY_LOAD_REPAIRS_BETWEEN_FAILURE',
    LOAD_BY_SERVICE_CALL_REQUEST = 'FACTORY_LOAD_BY_SERVICE_CALL_REQUEST',
    LOAD_BY_SERVICE_CALL_SUCCESS = 'FACTORY_LOAD_BY_SERVICE_CALL_SUCCESS',
    LOAD_BY_SERVICE_CALL_FAILURE = 'FACTORY_LOAD_BY_SERVICE_CALL_FAILURE',
    LOAD_REPAIRS_FOR_TODAY_REQUEST = 'FACTORY_LOAD_REPAIRS_FOR_TODAY_REQUEST',
    LOAD_REPAIRS_FOR_TODAY_SUCCESS = 'FACTORY_LOAD_REPAIRS_FOR_TODAY_SUCCESS',
    LOAD_REPAIRS_FOR_TODAY_FAILURE = 'FACTORY_LOAD_REPAIRS_FOR_TODAY_FAILURE',
    LOAD_REPAIRS_FOR_THIS_WEEK_REQUEST = 'FACTORY_LOAD_REPAIRS_FOR_THIS_WEEK_REQUEST',
    LOAD_REPAIRS_FOR_THIS_WEEK_SUCCESS = 'FACTORY_LOAD_REPAIRS_FOR_THIS_WEEK_SUCCESS',
    LOAD_REPAIRS_FOR_THIS_WEEK_FAILURE = 'FACTORY_LOAD_REPAIRS_FOR_THIS_WEEK_FAILURE',
    LOAD_REPORT_REQUEST = 'FACTORY_LOAD_REPORT_REQUEST',
    LOAD_REPORT_SUCCESS = 'FACTORY_LOAD_REPORT_SUCCESS',
    LOAD_REPORT_FAILURE = 'FACTORY_LOAD_REPORT_FAILURE',
    SAVE_REPORT_REQUEST = 'FACTORY_SAVE_REPORT_REQUEST',
    SAVE_REPORT_SUCCESS = 'FACTORY_SAVE_REPORT_SUCCESS',
    SAVE_REPORT_FAILURE = 'FACTORY_SAVE_REPORT_FAILURE',
    COMPLETE_REPORT_REQUEST = 'FACTORY_COMPLETE_REPORT_REQUEST',
    COMPLETE_REPORT_SUCCESS = 'FACTORY_COMPLETE_REPORT_SUCCESS',
    COMPLETE_REPORT_FAILURE = 'FACTORY_COMPLETE_REPORT_FAILURE',
    SCHEDULE_REPAIR_REQUEST = 'FACTORY_SCHEDULE_REPAIR_REQUEST',
    SCHEDULE_REPAIR_SUCCESS = 'FACTORY_SCHEDULE_REPAIR_SUCCESS',
    SCHEDULE_REPAIR_FAILURE = 'FACTORY_SCHEDULE_REPAIR_FAILURE',
    SET_CALENDAR_START_DATE = 'FACTORY_SET_CALENDAR_START_DATE'
}