import moment from 'moment';
import React from 'react';
import { defineMessages, FormattedMessage, WrappedComponentProps, injectIntl, MessageDescriptor } from 'react-intl';
import { Divider, Feed, SemanticICONS } from 'semantic-ui-react';

import { UserHelper } from '../../helpers';
import {
    AccountingUpdate,
    ActivityType,
    PartsReplacedUpdate,
    ServiceCall,
    ServiceCallUpdate,
    ShipmentSentUpdate,
    SubcontractorAssignedUpdate,
    SubcontractorUpdate,
} from '../../state/models';
import {
    InspectionCompletedUpdate,
    InspectionUpdate,
    JaymarTechnicianScheduledUpdate,
    JaymarTechnicianUpdate,
    LinkedServiceCallUpdate,
    NoteAddedUpdate,
    RepairedInFactoryUpdate,
    RepresentativeAssignedUpdate,
    SubcontractorScheduledUpdate,
} from '../../state/models/ServiceCallUpdate';
import { ArrayHelper } from '../../state/utils';
import { LoadingContainer, LoadingDimmer } from '../common';
import {
    AccountingActivity,
    InspectionScheduledActivity,
    PartsReplacedActivity,
    RepairedInFactoryActivity,
    ServiceCallCreatedActivity,
    ShipmentSentActivity,
    SubcontractorAssignedActivity,
} from './activities';
import { DefaultActivity } from './activities/DefaultActivity';
import { InspectionCompletedActivity } from './activities/InspectionCompletedActivity';
import { JaymarTechnicianActivity } from './activities/JaymarTechnicianActivity';
import { LinkedServiceCallActivity } from './activities/LinkedServiceCallActivity';
import { NoteAddedActivity } from './activities/NoteAddedActivity';
import { PartsOrderedActivity } from './activities/PartsOrderedActivity';
import { SubcontractorActivity } from './activities/SubcontractorActivity';
import { SubcontractorScheduledActivity } from './activities/SubcontractorScheduledActivity';
import { TechnicianRescheduledActivity } from './activities/TechnicianRescheduledActivity';
import { TechnicianScheduledActivity } from './activities/TechnicianScheduledActivity';

export interface ServiceCallRecentActivityProps {
    serviceCall: ServiceCall | undefined;
    areLinkedCallsDisplayed?: boolean;
    updates: ServiceCallUpdate[];
    isLoading?: boolean;
}

interface RecentActivityContent {
    icon: SemanticICONS;
    summaryMessage: MessageDescriptor;
    // tslint:disable-next-line:no-any
    summaryValues?: any;
    date?: Date;
    id?: number;
}

const m = defineMessages({
    unknownStatus: { id: 'ServiceCallRecentActivity.unknown_status', defaultMessage: 'The service call was updated.' },
    assignedToAgentStatus: { id: 'ServiceCallRecentActivity.assigned_status', defaultMessage: 'An after-sales agent was assigned to the call.' },
    closedStatus: { id: 'ServiceCallRecentActivity.closed_status', defaultMessage: 'The service call was marked as resolved.' },
    factoryRepairsRequested: { id: 'ServiceCallRecentActivity.factory_repairs_requested', defaultMessage: 'Jaymar requested the furniture to be returned to its warehouse.' },
    factoryRepairsReceived: { id: 'ServiceCallRecentActivity.factory_repairs_received', defaultMessage: 'Jaymar received the furniture at its warehouse.' },
    jaymarRepresentativeAssigned: { id: 'ServiceCallRecentActivity.jaymar_representative_assigned', defaultMessage: 'The Jaymar representative {name} was assigned to the service call.' },
    jaymarTechnicianScheduledStatus: { id: 'ServiceCallRecentActivity.jaymar_technician_scheduled_status', defaultMessage: 'A meeting was scheduled with a Jaymar technician to repair the furniture.' },
    partsReplaced: { id: 'ServiceCallRecentActivity.parts_replaced_status', defaultMessage: 'Some parts were replaced to repair the defect.' },
    repairsCancelled: { id: 'ServiceCallRecentActivity.repairs_cancelled', defaultMessage: 'Jaymar cancelled the previous request for repairs.' },
    creditReturnRequested: { id: 'ServiceCallRecentActivity.credit_return_requested', defaultMessage: 'Jaymar requested the furniture to be returned to its warehouse before applying the credit.' },
    creditReturnReceived: { id: 'ServiceCallRecentActivity.credit_return_received', defaultMessage: 'Jaymar received the furniture at its warehouse.' },
});

class ServiceCallRecentActivity extends React.Component<ServiceCallRecentActivityProps & WrappedComponentProps, {}> {
    readonly statusIconMap: Map<ActivityType, RecentActivityContent> = new Map<ActivityType, RecentActivityContent>([
        [ActivityType.unknown, { icon: 'pencil', summaryMessage: m.unknownStatus } as RecentActivityContent],
        [ActivityType.assignedToAgent, { icon: 'add user', summaryMessage: m.assignedToAgentStatus } as RecentActivityContent],
        [ActivityType.factoryRepairsRequested, { icon: 'reply', summaryMessage: m.factoryRepairsRequested } as RecentActivityContent],
        [ActivityType.factoryRepairsReceived, { icon: 'truck', summaryMessage: m.factoryRepairsReceived } as RecentActivityContent],
        [ActivityType.creditReturnRequested, { icon: 'reply', summaryMessage: m.creditReturnRequested } as RecentActivityContent],
        [ActivityType.creditReturnReceived, { icon: 'truck', summaryMessage: m.creditReturnReceived } as RecentActivityContent],
        [ActivityType.technicianScheduled, { icon: 'calendar', summaryMessage: m.jaymarTechnicianScheduledStatus } as RecentActivityContent],
        [ActivityType.repairedByTechnician, { icon: 'wrench', summaryMessage: m.jaymarTechnicianScheduledStatus } as RecentActivityContent],
        [ActivityType.serviceCallClosed, { icon: 'check', summaryMessage: m.closedStatus } as RecentActivityContent],
        [ActivityType.repairsCancelled, { icon: 'cancel', summaryMessage: m.repairsCancelled } as RecentActivityContent],
    ]);

    public render() {
        const { updates } = this.props;

        return (
            <LoadingContainer>
                <Feed>
                    <LoadingDimmer active={this.props.isLoading} />
                    {this.renderAllActivities(updates)}
                </Feed>
            </LoadingContainer>
        );
    }

    private renderAllActivities(activities: ServiceCallUpdate[]) {
        const keyGetter = (item: ServiceCallUpdate) => moment.utc(item.createdOn).year();
        const filteredActivities = activities.filter(activity => this.props.areLinkedCallsDisplayed || activity.serviceCallId === this.props.serviceCall?.id);
        const groupedActivities = ArrayHelper.groupBy(filteredActivities, keyGetter);

        let renderedActivities: JSX.Element[] = [];

        // Display the activities in year groups to make it easier to follow the activity feed
        groupedActivities.forEach((values, key) => {
            renderedActivities.push(<Divider key={key} horizontal={true}>{key}</Divider>);
            values.forEach(value => renderedActivities.push(this.renderActivity(value)));
        });

        return renderedActivities;
    }

    private renderActivity(activity: ServiceCallUpdate) {
        switch (activity.type) {
            case ActivityType.repairedAtFactory:
                const factoryActivity = activity as RepairedInFactoryUpdate;
                const factoryRepair = this.props.serviceCall ? this.props.serviceCall.factoryRepairs.find(x => x.id === factoryActivity.factoryRepairId) : undefined;
                return <RepairedInFactoryActivity key={activity.id} activity={activity} factoryRepair={factoryRepair} />;

            case ActivityType.serviceCallCreated:
                return <ServiceCallCreatedActivity key={activity.id} activity={activity} />;

            case ActivityType.serviceCallLinked:
                return <LinkedServiceCallActivity key={activity.id} activity={activity as LinkedServiceCallUpdate} />;

            case ActivityType.accountingTransactionPerformed:
                return <AccountingActivity key={activity.id} activity={activity as AccountingUpdate} />;

            case ActivityType.assignedToInspector:
                const assignedToInspectorActivity = activity as RepresentativeAssignedUpdate;
                const representativeName = assignedToInspectorActivity.representative ? UserHelper.getDisplayName(assignedToInspectorActivity.representative) : '';
                return <DefaultActivity key={activity.id} activity={activity} icon="add user" summaryMessage={m.jaymarRepresentativeAssigned} summaryValues={{ name: representativeName }} />;

            case ActivityType.inspectionScheduled:
                return <InspectionScheduledActivity key={activity.id} activity={activity as InspectionUpdate} />;

            case ActivityType.inspectionPerformed:
                const inspectionActivity = activity as InspectionCompletedUpdate;
                const inspection = this.props.serviceCall ? this.props.serviceCall.inspections.find(x => x.id === inspectionActivity.inspectionId) : undefined;
                return <InspectionCompletedActivity key={activity.id} activity={inspectionActivity} inspection={inspection} />;

            case ActivityType.technicianScheduled:
                return <TechnicianScheduledActivity key={activity.id} activity={activity as JaymarTechnicianScheduledUpdate} />;

            case ActivityType.technicianRescheduled:
                return <TechnicianRescheduledActivity key={activity.id} activity={activity as JaymarTechnicianScheduledUpdate} />;

            case ActivityType.subcontractorScheduled:
                return <SubcontractorScheduledActivity key={activity.id} activity={activity as SubcontractorScheduledUpdate} />;

            case ActivityType.repairedByTechnician:
                const technicianUpdate = activity as JaymarTechnicianUpdate;
                const technicianRepair = this.props.serviceCall ? this.props.serviceCall.technicianRepairs.find(x => x.id === technicianUpdate.technicianRepairId) : undefined;
                return <JaymarTechnicianActivity key={activity.id} activity={technicianUpdate} technicianRepair={technicianRepair} />;

            case ActivityType.assignedToSubcontractor:
                return <SubcontractorAssignedActivity key={activity.id} activity={activity as SubcontractorAssignedUpdate} />;

            case ActivityType.repairedByClientSubcontractor:
            case ActivityType.repairedBySubcontractor:
                return <SubcontractorActivity key={activity.id} activity={activity as SubcontractorUpdate} />;

            case ActivityType.partsOrdered:
                return <PartsOrderedActivity key={activity.id} activity={activity} />;

            case ActivityType.partsReplaced:
                return <PartsReplacedActivity key={activity.id} activity={activity as PartsReplacedUpdate} />;

            case ActivityType.shipmentSent:
                return <ShipmentSentActivity key={activity.id} activity={activity as ShipmentSentUpdate} />;

            case ActivityType.noteAdded:
                return <NoteAddedActivity key={activity.id} activity={activity as NoteAddedUpdate} />;

            default:
                var defaultContent = this.statusIconMap.get(activity.type);
                return defaultContent
                    ? <DefaultActivity key={activity.id} activity={activity} icon={defaultContent.icon} summaryMessage={defaultContent.summaryMessage} summaryValues={defaultContent.summaryValues} />
                    : <Feed.Event />;
        }
    }
}

const connectedComponent = injectIntl(ServiceCallRecentActivity);
export { connectedComponent as ServiceCallRecentActivity };