import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Button, SemanticSIZES } from 'semantic-ui-react';
import { AppointmentTimeRange } from '../../state/models';

interface TimeRangeButtonOwnProps {
    timeRange?: AppointmentTimeRange;
    size?: SemanticSIZES;
    disabled?: boolean;
    onChange: (timeRange?: AppointmentTimeRange) => void;
}

export type TimeRangeButtonProps =
    & TimeRangeButtonOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    morningAbbreviation: { id: 'TimeRangeButton.morning_abbreviation', defaultMessage: 'AM' },
    afternoonAbbreviation: { id: 'TimeRangeButton.afternoon_abbreviation', defaultMessage: 'PM' },
});

class TimeRangeButton extends React.Component<TimeRangeButtonProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <Button.Group toggle={true} size={this.props.size}>
                <Button
                    disabled={this.props.disabled}
                    active={this.props.timeRange === AppointmentTimeRange.morning} 
                    content={formatMessage(m.morningAbbreviation)}
                    onClick={(_event, _data) => this.handleTimeRangeChange(AppointmentTimeRange.morning)}
                />
                <Button
                    disabled={this.props.disabled}
                    active={this.props.timeRange === AppointmentTimeRange.afternoon} 
                    content={formatMessage(m.afternoonAbbreviation)}
                    onClick={(_event, _data) => this.handleTimeRangeChange(AppointmentTimeRange.afternoon)}
                />
            </Button.Group>
        );
    }

    private handleTimeRangeChange = (newTimeRange: AppointmentTimeRange) => {
        if (this.props.timeRange === newTimeRange) {
            this.props.onChange(undefined);
        } else {
            this.props.onChange(newTimeRange);
        }
    }
}

const connectedComponent = injectIntl(TimeRangeButton);
export { connectedComponent as TimeRangeButton };