import { GroupedDefectiveRatio } from "containers/reporting/types";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Table } from "semantic-ui-react";
import { SortDirection } from "state/models";

const m = defineMessages({
  itemSold: {
    id: "DefectiveRatiosTable.Headers.ItemSold",
    defaultMessage: "Sold items",
  },
  serviceCallCount: {
    id: "DefectiveRatiosTable.Headers.ServiceCallCount",
    defaultMessage: "Defective items",
  },
  defectivePercent: {
    id: "DefectiveRatiosTable.Headers.DefectivePercent",
    defaultMessage: "Defective %",
  },
});

interface TableHeadersProps {
  groupType: string;
  sortingColumn: keyof GroupedDefectiveRatio;
  sortingDirection: SortDirection;
  onColumnSorting: (columnName: keyof GroupedDefectiveRatio) => void;
}

export const TableHeaders: React.FC<TableHeadersProps> = ({
  groupType,
  sortingColumn,
  sortingDirection,
  onColumnSorting,
}) => {
  const { formatMessage } = useIntl();

  const direction = sortingDirection === SortDirection.Descending ? "descending" : "ascending";

  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell
          sorted={sortingColumn === "groupName" ? direction : undefined}
          onClick={() => onColumnSorting("groupName")}>
          {groupType}
        </Table.HeaderCell>

        <Table.HeaderCell
          collapsing
          sorted={sortingColumn === "soldItemCount" ? direction : undefined}
          onClick={() => onColumnSorting("soldItemCount")}
          textAlign='right'>
          {formatMessage(m.itemSold)}
        </Table.HeaderCell>

        <Table.HeaderCell
          collapsing
          sorted={sortingColumn === "serviceCallCount" ? direction : undefined}
          onClick={() => onColumnSorting("serviceCallCount")}
          textAlign='right'>
          {formatMessage(m.serviceCallCount)}
        </Table.HeaderCell>

        <Table.HeaderCell
          collapsing
          sorted={sortingColumn === "serviceCallPercent" ? direction : undefined}
          onClick={() => onColumnSorting("serviceCallPercent")}
          textAlign='right'>
          {formatMessage(m.defectivePercent)}
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};
