import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownItemProps, DropdownProps, StrictDropdownProps } from 'semantic-ui-react';
import { getCurrentLocale } from '../selectors';
import { actionCreators as I18nActions } from '../actions';

const dropdownOptions: DropdownItemProps[] = [
    { text: 'FR', value: 'fr' },
    { text: 'EN', value: 'en' }
];

export const LocaleSwitcher: React.FC<DropdownProps> = (props) => {
    const dispatch = useDispatch();
    const currentLocale = useSelector(getCurrentLocale);

    const handleLocaleChange = (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        dispatch(I18nActions.changeLocale(data.value as string));
    }

    return (
        <Dropdown
            item
            onChange={handleLocaleChange}
            value={currentLocale}
            options={dropdownOptions}
            {...props}
        />
    );
};