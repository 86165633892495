import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';
import { Label, LabelProps, SemanticCOLORS, SemanticICONS, Icon, IconProps, Popup } from 'semantic-ui-react';

import { ServiceCallStatus, ServiceCall } from '../../state/models';
import { serviceCallMessages as m } from '../../constants';

export interface ServiceCallStatusIconProps extends IconProps {
    status?: ServiceCallStatus;
}

interface LabelContent {
    value: MessageDescriptor;
    color: SemanticCOLORS;
    icon: SemanticICONS;
}

const unknownLabelContent: LabelContent = {
    value: m.unknown,
    color: 'red',
    icon: 'question'
};

// We can't initialize the map using the "Map<...>([iterable])" constructor, because it isn't supported on IE 11.
var statusMap: Map<ServiceCallStatus, LabelContent> = new Map<ServiceCallStatus, LabelContent>();
statusMap.set(ServiceCallStatus.unknown, { value: m.unknown, color: 'red', icon: 'question' });
statusMap.set(ServiceCallStatus.created, { value: m.new, color: 'violet', icon: 'phone' });
statusMap.set(ServiceCallStatus.assignedToAgent, { value: m.open, color: 'teal', icon: 'user' });
statusMap.set(ServiceCallStatus.awaitingInspection, { value: m.inspection, color: 'yellow', icon: 'eye' });
statusMap.set(ServiceCallStatus.inspected, { value: m.inspected, color: 'yellow', icon: 'eye' });
statusMap.set(ServiceCallStatus.awaitingFactoryRepairs, { value: m.repairs, color: 'orange', icon: 'factory' });
statusMap.set(ServiceCallStatus.awaitingSubcontractorRepairs, { value: m.repairs, color: 'orange', icon: 'wrench' });
statusMap.set(ServiceCallStatus.awaitingTechnicianRepairs, { value: m.repairs, color: 'orange', icon: 'wrench' });
statusMap.set(ServiceCallStatus.repaired, { value: m.repaired, color: 'blue', icon: 'wrench' });
statusMap.set(ServiceCallStatus.closed, { value: m.closed, color: 'green', icon: 'check' });
statusMap.set(ServiceCallStatus.draft, { value: m.draft, color: 'purple', icon: 'pencil' });

class ServiceCallStatusIcon extends React.Component<ServiceCallStatusIconProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { status } = this.props;
        const labelContent = statusMap.get(status || ServiceCallStatus.unknown) || unknownLabelContent;

        return (
            <Popup trigger={this.renderIcon()} content={formatMessage(labelContent.value)} />
        );
    }

    private renderIcon = () => {
        const { status, intl, ...iconProps } = this.props;
        const labelContent = statusMap.get(status || ServiceCallStatus.unknown) || unknownLabelContent;

        return (
            <Icon
                name={labelContent.icon}
                color={labelContent.color}
                circular={true}
                inverted={true}
                {...iconProps}
            />
        );
    }
}

const connectedComponent = injectIntl(ServiceCallStatusIcon);
export { connectedComponent as ServiceCallStatusIcon };