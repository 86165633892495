import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { EmptyContentSegment } from '../../common';

interface EmptyNotesSegmentOwnProps {
}

export type EmptyNotesSegmentProps =
    & EmptyNotesSegmentOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'EmptyNotesSegment.title', defaultMessage: 'No notes' },
    description: { id: 'EmptyNotesSegment.description', defaultMessage: 'There are no notes associated to this service call.' },
});

class EmptyNotesSegment extends React.Component<EmptyNotesSegmentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <EmptyContentSegment
                basic
                title={formatMessage(m.title)}
                description={formatMessage(m.description)}
            />
        );
    }
}

const connectedComponent = injectIntl(EmptyNotesSegment);
export { connectedComponent as EmptyNotesSegment };