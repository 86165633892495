import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Icon, Message, MessageProps } from 'semantic-ui-react';

const m = defineMessages({
    errorTitle: { id: 'ResetPasswordGenericError.errorTitle', defaultMessage: 'Failed to reinitialize your password' },
    errorMessage: { id: 'ResetPasswordGenericError.errorMessage', defaultMessage: 'An error occurred while resetting your password. Please try again later or contact Jaymar\'s customer service at 1-888-529-6271, extension 2696.' }
});

export const ResetPasswordGenericError: React.FC<MessageProps> = (props) => {
    const { formatMessage } = useIntl();

    return (
        <Message icon error {...props}>
            <Icon name="times circle" />
            <Message.Content>
                <Message.Header>{formatMessage(m.errorTitle)}</Message.Header>
                {formatMessage(m.errorMessage)}
            </Message.Content>
        </Message>
    );
};