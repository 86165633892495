import { ApplicationState } from '..';
import { ProductionComponent, ProductionOrder, ProductionComponentTree } from '../../models';
import { ProductionOrderState, SelectedProductionComponentState } from './reducers';
import { getServiceCallState } from '../service-calls';

export const getProductionOrdersByOrderId = (state: ApplicationState, orderId: number): ProductionOrder[] => state.productionOrders.byOrderId[orderId] || [];

export function getProductionOrdersState(state: ApplicationState, serviceCallId: number): ProductionOrderState {
    return state.productionOrders.byServiceCall[serviceCallId];
}

export function getSelectedComponents(state: ApplicationState, serviceCallId: number): SelectedProductionComponentState[] {
    const productionOrdersState = getProductionOrdersState(state, serviceCallId);

    if (productionOrdersState && !productionOrdersState.isLoading) {
        const selectedComponents = Object.keys(productionOrdersState.selectedComponents)
            .map(x => productionOrdersState.selectedComponents[Number(x)]);

        return selectedComponents;
    }

    return [];
}

export function getProductionOrdersFromDefectiveItems(state: ApplicationState, serviceCallId: number): ProductionOrder[] {
    const serviceCallState = getServiceCallState(state, serviceCallId);
    const isServiceCallLoaded = serviceCallState && serviceCallState.details && !serviceCallState.isLoading;

    if (isServiceCallLoaded) {
        const defectiveItemsId = serviceCallState.details.defectiveItems
            .filter(x => x.itemId != null)
            .map(x => x.itemId || '');

        const productionOrdersState = getProductionOrdersState(state, serviceCallId);
        const productionOrders = productionOrdersState && !productionOrdersState.isLoading
            ? productionOrdersState.productionOrders
            : [];

        return productionOrders.filter(x => defectiveItemsId.some(itemId => x.product.item.id === itemId));
    }

    return [];
}

export function getProductionOrdersComponentsTree(state: ApplicationState, serviceCallId: number): ProductionComponentTree {
    const productionOrdersState = getProductionOrdersState(state, serviceCallId);

    return productionOrdersState && !productionOrdersState.isLoading
        ? productionOrdersState.productionComponentTree || {}
        : {};
}