import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Divider, DividerProps } from 'semantic-ui-react';

import { commonMessages } from '../../constants';

export interface OrDividerProps extends DividerProps { }

class OrDivider extends React.Component<OrDividerProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { intl, ...dividerProps } = this.props;

        return (
            <Divider horizontal={true} section={true} {...dividerProps}>
                {formatMessage(commonMessages.or)}
            </Divider>
        );
    }
}

const intlComponent = injectIntl(OrDivider);
export { intlComponent as OrDivider };