import { Reducer } from 'redux';
import { ActionTypes } from './actions';
import { ActionTypeKeys } from './types';
import { ServiceCallFactoryRepair, FactoryReport } from '../../models';

export interface FactoryRepairsState {
    arePendingRepairsLoading: boolean;
    pendingRepairs: ServiceCallFactoryRepair[];
    areCompletedRepairsLoading: boolean;
    completedRepairs: ServiceCallFactoryRepair[];
    todaysRepairs: FactoryRepairsLoadingState;
    thisWeeksRepairs: FactoryRepairsLoadingState;
    repairsByServiceCall: {
        [serviceCallId: number]: FactoryRepairsLoadingState;
    };
    repairsByStartDate: {
        [startDate: string]: FactoryRepairsLoadingState;
    };
    repairsById: {
        [repairId: number]: ServiceCallFactoryRepair
    };
    reportsByRepairId: {
        [repairId: number]: FactoryReport;
    };
    isCompletingReport: boolean;
    calendarStartDate: Date;
}

export interface FactoryRepairsLoadingState {
    repairs: ServiceCallFactoryRepair[];
    isLoading: boolean;
}

const initialState: FactoryRepairsState = {
    arePendingRepairsLoading: false,
    pendingRepairs: [],
    areCompletedRepairsLoading: false,
    completedRepairs: [],
    todaysRepairs: {
        repairs: [],
        isLoading: false
    },
    thisWeeksRepairs: {
        repairs: [],
        isLoading: false
    },
    repairsByServiceCall: {},
    repairsByStartDate: {},
    repairsById: {},
    reportsByRepairId: {},
    isCompletingReport: false,
    calendarStartDate: new Date()
};

export const factoryRepairsReducer: Reducer<FactoryRepairsState> = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case ActionTypeKeys.LOAD_REPAIR_SUCCESS:
            return {
                ...state,
                repairsById: {
                    ...state.repairsById,
                    [action.payload.id]: action.payload
                }
            };

        case ActionTypeKeys.LOAD_PENDING_REPAIRS_REQUEST:
            return {
                ...state,
                arePendingRepairsLoading: true
            };

        case ActionTypeKeys.LOAD_PENDING_REPAIRS_SUCCESS:
            return {
                ...state,
                arePendingRepairsLoading: false,
                pendingRepairs: action.payload
            };

        case ActionTypeKeys.LOAD_PENDING_REPAIRS_FAILURE:
            return {
                ...state,
                arePendingRepairsLoading: false
            };

        case ActionTypeKeys.LOAD_COMPLETED_REPAIRS_REQUEST:
            return {
                ...state,
                areCompletedRepairsLoading: true
            };

        case ActionTypeKeys.LOAD_COMPLETED_REPAIRS_SUCCESS:
            return {
                ...state,
                areCompletedRepairsLoading: false,
                completedRepairs: action.payload
            };

        case ActionTypeKeys.LOAD_COMPLETED_REPAIRS_FAILURE:
            return {
                ...state,
                areCompletedRepairsLoading: false
            };

        case ActionTypeKeys.LOAD_REPAIRS_BETWEEN_REQUEST:
            return {
                ...state,
                repairsByStartDate: {
                    ...state.repairsByStartDate,
                    [action.payload.toISOString()]: {
                        ...state.repairsByStartDate[action.payload.toISOString()],
                        isLoading: true
                    }
                }
            };

        case ActionTypeKeys.LOAD_REPAIRS_BETWEEN_SUCCESS:
            return {
                ...state,
                repairsByStartDate: {
                    ...state.repairsByStartDate,
                    [action.payload.startDate.toISOString()]: {
                        ...state.repairsByStartDate[action.payload.startDate.toISOString()],
                        repairs: action.payload.repairs,
                        isLoading: false
                    }
                }
            };

        case ActionTypeKeys.LOAD_REPAIRS_BETWEEN_FAILURE:
            return {
                ...state,
                repairsByStartDate: {
                    ...state.repairsByStartDate,
                    [action.payload.startDate.toISOString()]: {
                        ...state.repairsByStartDate[action.payload.startDate.toISOString()],
                        isLoading: false
                    }
                }
            };

        case ActionTypeKeys.LOAD_REPAIRS_FOR_TODAY_REQUEST:
            return {
                ...state,
                todaysRepairs: {
                    ...state.todaysRepairs,
                    isLoading: true
                }
            };

        case ActionTypeKeys.LOAD_REPAIRS_FOR_TODAY_SUCCESS:
            return {
                ...state,
                todaysRepairs: {
                    ...state.todaysRepairs,
                    repairs: action.payload,
                    isLoading: false
                }
            };

        case ActionTypeKeys.LOAD_REPAIRS_FOR_TODAY_FAILURE:
            return {
                ...state,
                todaysRepairs: {
                    ...state.todaysRepairs,
                    isLoading: false
                }
            };

        case ActionTypeKeys.LOAD_REPAIRS_FOR_THIS_WEEK_REQUEST:
            return {
                ...state,
                thisWeeksRepairs: {
                    ...state.thisWeeksRepairs,
                    isLoading: true
                }
            };

        case ActionTypeKeys.LOAD_REPAIRS_FOR_THIS_WEEK_SUCCESS:
            return {
                ...state,
                thisWeeksRepairs: {
                    ...state.thisWeeksRepairs,
                    repairs: action.payload,
                    isLoading: false
                }
            };

        case ActionTypeKeys.LOAD_REPAIRS_FOR_THIS_WEEK_FAILURE:
            return {
                ...state,
                thisWeeksRepairs: {
                    ...state.thisWeeksRepairs,
                    isLoading: false
                }
            };

        case ActionTypeKeys.LOAD_COMPLETED_REPAIRS_SUCCESS:
            return {
                ...state,
                areCompletedRepairsLoading: false,
                completedRepairs: action.payload
            };

        case ActionTypeKeys.LOAD_COMPLETED_REPAIRS_FAILURE:
            return {
                ...state,
                areCompletedRepairsLoading: false
            };

        case ActionTypeKeys.LOAD_BY_SERVICE_CALL_REQUEST:
            return {
                ...state,
                repairsByServiceCall: {
                    ...state.repairsByServiceCall,
                    [action.payload]: {
                        ...state.repairsByServiceCall[action.payload],
                        isLoading: true
                    }
                }
            };

        case ActionTypeKeys.LOAD_BY_SERVICE_CALL_SUCCESS:
            return {
                ...state,
                repairsByServiceCall: {
                    ...state.repairsByServiceCall,
                    [action.payload.serviceCallId]: {
                        ...state.repairsByServiceCall[action.payload.serviceCallId],
                        repairs: action.payload.repairs,
                        isLoading: false
                    }
                }
            };

        case ActionTypeKeys.LOAD_BY_SERVICE_CALL_FAILURE:
            return {
                ...state,
                repairsByServiceCall: {
                    ...state.repairsByServiceCall,
                    [action.payload.serviceCallId]: {
                        ...state.repairsByServiceCall[action.payload.serviceCallId],
                        isLoading: false
                    }
                }
            };

        case ActionTypeKeys.LOAD_REPORT_SUCCESS:
            return {
                ...state,
                reportsByRepairId: {
                    ...state.reportsByRepairId,
                    [action.payload.factoryRepairId]: action.payload
                }
            };

        case ActionTypeKeys.SAVE_REPORT_SUCCESS:
            return {
                ...state,
                reportsByRepairId: {
                    ...state.reportsByRepairId,
                    [action.payload.factoryRepairId]: action.payload
                }
            };

        case ActionTypeKeys.COMPLETE_REPORT_REQUEST:
            return {
                ...state,
                isCompletingReport: true
            };

        case ActionTypeKeys.COMPLETE_REPORT_SUCCESS:
            const pendingRepairsWithoutCompleted = state.pendingRepairs.filter(x => x.id !== action.payload);

            return {
                ...state,
                pendingRepairs: pendingRepairsWithoutCompleted,
                isCompletingReport: false
            };

        case ActionTypeKeys.COMPLETE_REPORT_FAILURE:
            return {
                ...state,
                isCompletingReport: false
            };

        case ActionTypeKeys.SCHEDULE_REPAIR_SUCCESS:
            return {
                ...state,
                repairsById: {
                    ...state.repairsById[action.payload.repairId],
                    [action.payload.repairId]: action.payload.repairs
                }
            };

        case ActionTypeKeys.SET_CALENDAR_START_DATE:
            return {
                ...state,
                calendarStartDate: action.payload
            };

        default:
            return state;
    }
}

export default factoryRepairsReducer;