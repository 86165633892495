import * as React from "react";
import { Button, Icon, SemanticICONS, SemanticCOLORS } from "semantic-ui-react";

const buttonContentStyle = {
    flex: "1 0 auto",
    width: "100%"
}

const buttonFooterStyle = {
    flex: "0 1 auto",
    width: "100%",
    margin: "15px 0 0 0"
}

export interface NavigationButtonProps {
    iconName?: SemanticICONS,
    iconColor?: SemanticCOLORS,
    title: string,
    description: string,
    buttonText: string,
    disabled?: boolean,
    onClick?: () => void
}

export class NavigationButton extends React.Component<NavigationButtonProps & React.HTMLAttributes<Button>, {}> {
    public render() {
        return (
            <Button
                basic
                style={this.props.style}
                disabled={this.props.disabled}
                onClick={this.props.onClick}
                className="o-navigation-button">
                <div style={buttonContentStyle}>
                    <Icon
                        className="o-navigation-button__icon"
                        name={this.props.iconName}
                        color={this.props.iconColor}
                        size="huge"
                        style={{ margin: "0" }} />

                    <h3 className="o-navigation-button__title" style={{ minHeight: 46 }}>{this.props.title}</h3>
                    <p className="o-navigation-button__description">{this.props.description}</p>
                </div>

                <div style={buttonFooterStyle}>
                    <Button as="a" primary fluid>{this.props.buttonText}</Button>
                </div>
            </Button>
        );
    }
}

export default NavigationButton;