import React from "react";
import { defineMessages, useIntl } from "react-intl";
import moment from "moment";
import { getReportingDateRange } from "state/models";
import { DefectiveRatioGroup } from "containers/reporting/types";
import { Header } from "semantic-ui-react";
import { DetailedStatisticsSalesFiltersPopup } from "../DetailedStatisticsSalesFiltersPopup";
import { DefectiveRatiosGroupTypeSelector } from "../DefectiveRatiosGroupTypeSelector";
import { DefectiveRatiosByItem } from "../DefectiveRatiosByItem";
import { DefectiveRatiosByCover } from "../DefectiveRatiosByCover";
import { DefectiveRatiosByProvince } from "../DefectiveRatiosByProvince";
import { DefectiveRatiosByClient } from "../DefectiveRatiosByClient";
import { DefectiveRatiosByBillingMonth } from "../DefectiveRatiosByBillingMonth";
import { DefectiveRatiosByProductType } from "../DefectiveRatiosByProductType";
import { useDetailedStatisticsContext } from "../DetailedStatisticsProvider";

const messages = defineMessages({
  tableTitle: {
    id: "DetailedStatisticsPage.table_title",
    defaultMessage: "Items sold from {start} to {end} declared defective",
  },
});

export const DefectiveRatiosSection = () => {
  const { formatMessage } = useIntl();

  const {
    defectiveRatios: { filters, changeFilters, selectedGroupType },
  } = useDetailedStatisticsContext();

  const { startDate, endDate } = getReportingDateRange(filters.dateFilter);

  const componentByGroupType = {
    [DefectiveRatioGroup.ByItem]: () => <DefectiveRatiosByItem />,
    [DefectiveRatioGroup.ByCover]: () => <DefectiveRatiosByCover />,
    [DefectiveRatioGroup.ByProvince]: () => <DefectiveRatiosByProvince />,
    [DefectiveRatioGroup.ByClient]: () => <DefectiveRatiosByClient />,
    [DefectiveRatioGroup.ByBillingMonth]: () => <DefectiveRatiosByBillingMonth />,
    [DefectiveRatioGroup.ByProductType]: () => <DefectiveRatiosByProductType />,  
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Header style={{ margin: 0 }}>
          {formatMessage(messages.tableTitle, {
            start: moment(startDate).format("LL"),
            end: moment(endDate).format("LL"),
          })}
        </Header>
        <DetailedStatisticsSalesFiltersPopup filters={filters} onApply={changeFilters} />
      </div>
      <DefectiveRatiosGroupTypeSelector />
      {componentByGroupType[selectedGroupType] && componentByGroupType[selectedGroupType]()}
    </>
  );
};
