import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';
import { SemanticICONS, Icon, IconProps } from 'semantic-ui-react';
import { ItemType } from '../../state/models';
import { itemTypeMessages } from '../../constants';

export interface ItemTypeIconProps extends IconProps {
    itemType: ItemType;
}

interface ItemTypeContent {
    icon: SemanticICONS;
    description: MessageDescriptor;
}

class ItemTypeIcon extends React.Component<ItemTypeIconProps & WrappedComponentProps, {}> {
    readonly iconMap: Map<ItemType, ItemTypeContent> = new Map<ItemType, ItemTypeContent>([
        [ItemType.finishedProduct, { icon: 'archive', description: itemTypeMessages.product }],
        [ItemType.assembled, { icon: 'cubes', description: itemTypeMessages.assembled }],
        [ItemType.template, { icon: 'cube', description: itemTypeMessages.template }],
        [ItemType.part, { icon: 'setting', description: itemTypeMessages.parts }],
    ]);

    public render() {
        const { formatMessage } = this.props.intl;
        const { itemType, intl, ...iconProps } = this.props;
        const itemTypeContent = this.iconMap.get(itemType);
        const itemTypeIcon = itemTypeContent ? itemTypeContent.icon : 'question';
        const itemTypeDescription = itemTypeContent ? formatMessage(itemTypeContent.description) : '';

        return (
            <Icon name={itemTypeIcon} {...iconProps} title={itemTypeDescription} />
        );
    }
}

const intlComponent = injectIntl(ItemTypeIcon);
export { intlComponent as ItemTypeIcon };