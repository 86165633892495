export enum TechnicianRepairsStatus {
    unknown,
    complete,
    incomplete,
    partsRequired
}

export interface TechnicianReport {
    report: string;
    repairsStatus: TechnicianRepairsStatus;
    hoursTaken: number;
    minutesTaken: number;
    clientSignature: string;
}