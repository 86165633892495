import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Form, Input, Message, InputOnChangeData } from 'semantic-ui-react';

import { ClientContactEdit, ValidationResult } from '../../state/models';
import { nameof } from '../../state/utils';

interface ContactClientFormOwnProps {
    client: ClientContactEdit;
    validationResult: ValidationResult;
    onChange: (fieldId: string, newValue: string) => void;
}

export type ContactClientFormProps =
    & ContactClientFormOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    nameLabel: { id: 'ContactClientForm.nameLabel', defaultMessage: 'Your name' },
    emailLabel: { id: 'ContactClientForm.emailLabel', defaultMessage: 'Your email' },
    phoneLabel: { id: 'ContactClientForm.phoneLabel', defaultMessage: 'Your phone number' }
});

class ContactClientForm extends React.Component<ContactClientFormProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { validationResult } = this.props;

        return (
            <Form error={!validationResult.isValid}>
                <Form.Field
                    required={true}
                    error={validationResult.isFieldInvalid(nameof<ClientContactEdit>('name'))}
                >
                    <label>{formatMessage(m.nameLabel)}</label>
                    <Input
                        id={nameof<ClientContactEdit>('name')}
                        value={this.props.client.name || ''}
                        onChange={this.handleChange}
                    />
                </Form.Field>
                <Form.Field
                    required={true}
                    error={validationResult.isFieldInvalid(nameof<ClientContactEdit>('email'))}
                >
                    <label>{formatMessage(m.emailLabel)}</label>
                    <Input
                        id={nameof<ClientContactEdit>('email')}
                        value={this.props.client.email || ''}
                        onChange={this.handleChange}
                    />
                </Form.Field>
                <Form.Field
                    required={true}
                    error={validationResult.isFieldInvalid(nameof<ClientContactEdit>('phoneNumber'))}
                >
                    <label>{formatMessage(m.phoneLabel)}</label>
                    <Input
                        id={nameof<ClientContactEdit>('phoneNumber')}
                        value={this.props.client.phoneNumber || ''}
                        onChange={this.handleChange}
                    />
                </Form.Field>

                <Message error={true} list={this.props.validationResult.localizeErrorMessages(formatMessage)} />
            </Form>
        );
    }

    private handleChange = (e: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) => {
        if (this.props.onChange) {
            this.props.onChange(e.currentTarget.id, data.value);
        }
    }
}

const connectedComponent = injectIntl(ContactClientForm);
export { connectedComponent as ContactClientForm };