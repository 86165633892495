import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { Button, Card, Dropdown, DropdownItemProps, Form, List } from 'semantic-ui-react';

import { AgentActionItem } from '..';
import { commonMessages } from '../../../constants';
import { DateHelper } from '../../../helpers';
import { AppointmentTimeRange, CalendarBreak, TechnicianAppointment, TechnicianRepair, User } from '../../../state/models';
import { StringHelper } from '../../../state/utils';
import { CalendarModal } from '../../calendar';
import { CalendarEvent } from '../../calendar/MonthlyCalendar';
import { TimeRangeButton } from '../../common';
import { TechnicianCalendarEvent } from '../technician';

export interface JaymarTechnicianRepairsCardProps {
    technicians: User[];
    techniciansAppointments: TechnicianRepair[];
    lockedDates: CalendarBreak[];
    areTechniciansAppointmentsLoading?: boolean;
    areTechniciansAppointmentsSaving?: boolean;
    isExecutingRepairStep?: boolean;
    currentRepair?: TechnicianRepair;
    scheduledRepairs: TechnicianRepair[];
    onAssign: (technicianId: string) => void;
    onSaveAppointments: (appointments: TechnicianAppointment[]) => void;
    onChangeCalendarDate: (date: moment.Moment) => void;
    onConfirmRepairs: () => void;
    onServiceCallClicked: (appointment: TechnicianRepair) => void;
}

interface JaymarTechnicianRepairsCardState {
    scheduledAppointments: TechnicianAppointment[];
    selectedTechnicianId: string;
    isCalendarModalOpen?: boolean;
    calendarModalAppointment?: TechnicianAppointment;
    calendarModalStartDate: moment.Moment;
}

const m = defineMessages({
    title: { id: 'JaymarTechnicianRepairsCard.title', defaultMessage: 'Repairs by a Jaymar technician' },
    subtitle: { id: 'JaymarTechnicianRepairsCard.subtitle', defaultMessage: 'Create an appointment to allow the Jaymar technician to repair the defects on-site.' },
    morningAbbreviation: { id: 'JaymarTechnicianRepairsCard.morning_abbreviation', defaultMessage: 'AM' },
    afternoonAbbreviation: { id: 'JaymarTechnicianRepairsCard.afternoon_abbreviation', defaultMessage: 'PM' },
    selectTechnicianPlaceholder: { id: 'JaymarTechnicianRepairsCard.select_technician_placeholder', defaultMessage: 'Choose a technician' },
    selectTechnicianStepTitle: { id: 'JaymarTechnicianRepairsCard.select_technician_step_title', defaultMessage: 'Select a technician' },
    selectTechnicianStepDescription: { id: 'JaymarTechnicianRepairsCard.select_technician_step_description', defaultMessage: 'Select the technician that will perform the repairs.' },
    createAppointmentStepTitle: { id: 'JaymarTechnicianRepairsCard.create_appointment_step_title', defaultMessage: 'Create an appointment' },
    createAppointmentStepDescription: { id: 'JaymarTechnicianRepairsCard.create_appointment_step_description', defaultMessage: 'Schedule an appointment with the technician to perform the repairs at the customer\'s address.' },
    appointmentDatePlaceholder: { id: 'JaymarTechnicianRepairsCard.appointment_date', defaultMessage: 'YYYY/MM/DD' },
    viewUpcomingAppointments: { id: 'JaymarTechnicianRepairsCard.view_upcoming_appointments', defaultMessage: 'View Upcoming Appointments' },
    confirmRepairsStepTitle: { id: 'JaymarTechnicianRepairsCard.confirm_repairs_step_title', defaultMessage: 'Close manually the repairs (optional)' },
    confirmRepairsStepDescription: { id: 'JaymarTechnicianRepairsCard.confirm_repairs_step_description', defaultMessage: 'This step will be performed automatically when the technician completes his repairs. If the technician does not have access to the platform, you can close the repairs manually here.' },
    confirmRepairsButton: { id: 'JaymarTechnicianRepairsCard.confirm_repairs_button', defaultMessage: 'Close the repairs' },
    calendarModalTitle: { id: 'JaymarTechnicianRepairsCard.calendar_modal_title', defaultMessage: 'Technicians appointments' },
    calendarModalSubtitle: { id: 'JaymarTechnicianRepairsCard.calendar_modal_subtitle', defaultMessage: 'View the upcoming technicians appointments or select a date for a new appointment.' },
});

class JaymarTechnicianRepairsCard extends React.Component<JaymarTechnicianRepairsCardProps & WrappedComponentProps, JaymarTechnicianRepairsCardState> {
    public constructor(props: JaymarTechnicianRepairsCardProps & WrappedComponentProps) {
        super(props);

        this.state = {
            scheduledAppointments: props.scheduledRepairs
                ? props.scheduledRepairs.map(x => ({
                    id: x.id,
                    scheduledForDate: x.scheduledForDate,
                    scheduledForTimeRange: x.scheduledForTimeRange
                }))
                : [],
            selectedTechnicianId: props.currentRepair ? props.currentRepair.technicianId : '',
            calendarModalStartDate: moment().startOf('day')
        };
    }

    public render() {
        const { formatMessage } = this.props.intl;

        const hasTechnicianAssigned = this.props.currentRepair != null;
        const hasAppointmentAssigned = this.props.currentRepair != null && this.props.currentRepair.scheduledForDate != null;

        return (
            <Card fluid={true} color="blue" style={{ height: '100%' }}>
                <Card.Content style={{ flexGrow: 0 }}>
                    <Card.Header>{formatMessage(m.title)}</Card.Header>
                    <Card.Meta>{formatMessage(m.subtitle)}</Card.Meta>
                </Card.Content>
                <Card.Content>
                    <List divided={true} relaxed="very">
                        {this.renderSelectStep(!hasTechnicianAssigned, hasTechnicianAssigned)}
                        {this.renderAppointmentsStep(hasTechnicianAssigned, hasAppointmentAssigned)}
                        {this.renderConfirmStep(hasAppointmentAssigned, false)}
                    </List>
                </Card.Content>
            </Card>
        );
    }

    private renderSelectStep(isEnabled: boolean, isCompleted: boolean) {
        const { formatMessage } = this.props.intl;

        const technicianOptions = this.props.technicians.map((x): DropdownItemProps => ({
            id: x.id,
            icon: 'wrench',
            text: `${x.firstName} ${x.lastName}`,
            value: x.id
        }));

        const isTechnicianSelected = StringHelper.hasValue(this.state.selectedTechnicianId);

        return (
            <AgentActionItem
                isEnabled={isEnabled}
                isCompleted={isCompleted}
                icon="dollar"
                title={formatMessage(m.selectTechnicianStepTitle)}
                description={formatMessage(m.selectTechnicianStepDescription)}
                completeButtonText={formatMessage(commonMessages.select)}
                onCompleteClick={() => this.props.onAssign(this.state.selectedTechnicianId)}
                isCompletedButtonDisabled={!isTechnicianSelected}
                isCompleteButtonDisabled={this.props.isExecutingRepairStep}
                isCompleteButtonLoading={this.props.isExecutingRepairStep}
                completedButtonText={formatMessage(commonMessages.selected)}
            >
                <Dropdown
                    search={true}
                    selection={true}
                    options={technicianOptions}
                    placeholder={formatMessage(m.selectTechnicianPlaceholder)}
                    fluid={true}
                    deburr={true}
                    onChange={(_event, data) => this.setState({ selectedTechnicianId: data.value as string })}
                    disabled={!isEnabled}
                    value={this.state.selectedTechnicianId}
                />
            </AgentActionItem>
        );
    }

    private renderAppointmentsStep(isEnabled: boolean, isCompleted: boolean) {
        const { formatMessage } = this.props.intl;
        const areControlsVisible = isCompleted || isEnabled;

        const calendarEvents: CalendarEvent[] = this.props.techniciansAppointments
            .filter(x => x.scheduledForDate != null)
            .map(x => ({
                on: moment(x.scheduledForDate).local().startOf('day').toDate(),
                element: <TechnicianCalendarEvent key={x.id} repair={x} onRepairClicked={this.props.onServiceCallClicked} />
            }));

        const scheduledRepairs = this.state.scheduledAppointments.map(x => this.renderAppointmentControls(x));

        return (
            <AgentActionItem
                isEnabled={isEnabled}
                isCompleted={isCompleted}
                icon="calendar"
                title={formatMessage(m.createAppointmentStepTitle)}
                description={formatMessage(m.createAppointmentStepDescription)}
                completeButtonText={formatMessage(commonMessages.create)}
                onCompleteClick={this.handleCreateAppointment}
                isCompleteButtonDisabled={this.state.scheduledAppointments.length <= 0 || this.props.areTechniciansAppointmentsSaving}
                isCompleteButtonLoading={this.props.areTechniciansAppointmentsSaving}
                isCompletedButtonDisabled={this.state.scheduledAppointments.length <= 0 || this.props.areTechniciansAppointmentsSaving}
                isCompletedButtonLoading={this.props.areTechniciansAppointmentsSaving}
                completedButtonText={formatMessage(commonMessages.save)}
                onCompletedClick={this.handleCreateAppointment}
            >
                {areControlsVisible &&
                    <Form>
                        {scheduledRepairs}
                        {this.renderAppointmentControls(undefined)}

                        <CalendarModal
                            open={this.state.isCalendarModalOpen}
                            forDate={this.state.calendarModalStartDate.toDate()}
                            forUserId={this.state.selectedTechnicianId}
                            events={calendarEvents}
                            lockedDates={this.props.lockedDates}
                            selectedDate={this.state.calendarModalAppointment ? this.state.calendarModalAppointment.scheduledForDate : undefined}
                            title={formatMessage(m.calendarModalTitle)}
                            subtitle={formatMessage(m.calendarModalSubtitle)}
                            canSelectDate={true}
                            closeIcon={true}
                            onMonthChanged={this.handleChangeAppointmentsMonth}
                            onDateSelected={this.handleDateSelectedFromModal}
                            onClose={() => this.setState({ isCalendarModalOpen: false })}
                            onCancel={() => this.setState({ isCalendarModalOpen: false })}
                        />
                    </Form>
                }
            </AgentActionItem>
        );
    }

    private renderAppointmentControls(appointment: TechnicianAppointment | undefined) {
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <Form.Group>
                    <Form.Field style={{ padding: 0 }}>
                        <DatePicker
                            selected={appointment && appointment.scheduledForDate ? new Date(appointment.scheduledForDate) : undefined}
                            dateFormat="YYYY/MM/DD"
                            filterDate={this.isNotLocked}
                            fixedHeight
                            minDate={new Date()}
                            placeholderText={formatMessage(m.appointmentDatePlaceholder)}
                            value={appointment && appointment.scheduledForDate ? moment(appointment.scheduledForDate).format('YYYY/MM/DD') : undefined}
                            onChange={(date) => this.changeAppointmentDate(appointment, date as (Date | null))}
                        />
                    </Form.Field>
                    <Form.Field style={{ padding: 0 }}>
                        <Button
                            icon="calendar"
                            onClick={() => this.showCalendarModal(appointment)}
                            style={{ marginLeft: '3.25px' }}
                        />
                    </Form.Field>
                    <Form.Field style={{ padding: 0 }}>
                        <TimeRangeButton
                            disabled={appointment == null || appointment.scheduledForDate == null}
                            timeRange={appointment ? appointment.scheduledForTimeRange : undefined}
                            onChange={(timeRange) => this.changeAppointmentTime(appointment, timeRange)}
                        />
                    </Form.Field>
                </Form.Group>
            </React.Fragment>
        );
    }

    private showCalendarModal = (appointment: TechnicianAppointment | undefined) => {
        this.handleChangeAppointmentsMonth(moment().startOf('day').startOf('month').toDate());

        this.setState({
            isCalendarModalOpen: true,
            calendarModalAppointment: appointment,
            calendarModalStartDate: moment().startOf('day')
        });
    }

    private changeAppointmentDate = (appointment: TechnicianAppointment | undefined, newDate: Date | null) => {
        if (appointment != null) {
            const appointments = this.state.scheduledAppointments.filter(x => x !== appointment);

            if (newDate != null) {
                appointments.push({ id: appointment.id, scheduledForDate: newDate, scheduledForTimeRange: appointment.scheduledForTimeRange });
            }

            this.setState({
                scheduledAppointments: appointments.sort((a, b) => DateHelper.sortAscending(a.scheduledForDate || new Date(), b.scheduledForDate || new Date()))
            });
        } else if (newDate != null) {
            const appointments = [...this.state.scheduledAppointments, { id: undefined, scheduledForDate: newDate, scheduledForTime: undefined }];

            this.setState({
                scheduledAppointments: appointments.sort((a, b) => DateHelper.sortAscending(a.scheduledForDate || new Date(), b.scheduledForDate || new Date()))
            });
        }
    }

    private changeAppointmentTime = (appointment: TechnicianAppointment | undefined, newTime: AppointmentTimeRange | undefined) => {
        if (appointment) {
            let appointments = this.state.scheduledAppointments.filter(x => x !== appointment);
            appointments.push({ id: appointment.id, scheduledForDate: appointment.scheduledForDate, scheduledForTimeRange: newTime });

            this.setState({
                scheduledAppointments: appointments.sort((a, b) => DateHelper.sortAscending(a.scheduledForDate || new Date(), b.scheduledForDate || new Date()))
            });
        }
    }

    private renderConfirmStep(isEnabled: boolean, isCompleted: boolean) {
        const { formatMessage } = this.props.intl;

        return (
            <AgentActionItem
                isEnabled={isEnabled}
                isCompleted={isCompleted}
                icon="check"
                title={formatMessage(m.confirmRepairsStepTitle)}
                description={formatMessage(m.confirmRepairsStepDescription)}
                completeButtonText={formatMessage(m.confirmRepairsButton)}
                onCompleteClick={this.props.onConfirmRepairs}
                completedButtonText={formatMessage(commonMessages.confirmed)}
            />
        );
    }

    private handleDateSelectedFromModal = (date: Date) => {
        this.changeAppointmentDate(this.state.calendarModalAppointment, date);
        this.setState({ isCalendarModalOpen: false });
    }

    private handleCreateAppointment = () => {
        if (this.state.scheduledAppointments.length > 0) {
            this.props.onSaveAppointments(this.state.scheduledAppointments);
        }
    }

    private handleChangeAppointmentsMonth = (date: Date) => {
        this.setState({ calendarModalStartDate: moment(date) });
        this.props.onChangeCalendarDate(moment(date));
    }

    private isNotLocked = (date: Date): boolean => {
        const dateMoment = moment(date);
        const weekday = dateMoment.isoWeekday();
        const isSunday = weekday === 7;
        const isLockedForEveryone = this.props.lockedDates.filter(x => dateMoment.isSame(x.forDate, 'day') && x.forUserId == null).length > 0;
        const isLockedForCurrentTechnician = this.props.lockedDates.filter(x => dateMoment.isSame(x.forDate, 'day') && x.forUserId === this.state.selectedTechnicianId).length > 0;

        return !isSunday && !isLockedForEveryone && !isLockedForCurrentTechnician;
    }
}

const intlComponent = injectIntl(JaymarTechnicianRepairsCard);
export { intlComponent as JaymarTechnicianRepairsCard };