import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { PageHeader } from '../../components/common';
import { ServiceCallList, ServiceCallTable } from '../../components/service-calls';
import { ServiceCall } from '../../state/models';
import { isCurrentUserAgent } from '../../state/ducks/current-user';
import { useResponsive } from '../../utils/responsive';
import { areSearchResultsLoading, getSearchResults } from '../../state/ducks/service-calls';
import { actionCreators as SearchActions } from '../../state/ducks/search/actions';

const m = defineMessages({
    pageTitle: { id: 'ServiceCallAdvancedSearchResultPage.page_title', defaultMessage: 'Search for service calls' },
    pageSubtitle: { id: 'ServiceCallAdvancedSearchResultPage.page_subtitle', defaultMessage: 'Perform a search for service calls using a specific set of criterias.' },
});

interface RouteProps {
    page?: string;
}

export const ServiceCallAdvancedSearchResultPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const { isMobile } = useResponsive();
    const { page } = useParams<RouteProps>();

    const currentPage = page != null ? Number(page) : undefined;
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const isAgent = useSelector(isCurrentUserAgent);
    const isLoading = useSelector(areSearchResultsLoading);
    const serviceCalls = useSelector(getSearchResults);

    useEffect(() => {
        dispatch(SearchActions.findServiceCallWithCriterias(location.search, currentPage))
    }, [dispatch, currentPage, location.search]);

    const navigateToDetailsPage = (serviceCall: ServiceCall) => history.push(`/service-calls/${serviceCall.id}/details`);
    const navigateToPageNumber = (page: number) => history.push(`/service-calls/advanced-search/${page}${location.search}`);

    const serviceCallsProps = {
        serviceCalls: serviceCalls,
        isLoading: isLoading,
        onViewDetails: navigateToDetailsPage,
        onPageChange: navigateToPageNumber,
    };

    return (
        <div>
            <PageHeader
                iconName="phone"
                title={formatMessage(m.pageTitle)}
                subtitle={formatMessage(m.pageSubtitle)}
            />

            {isMobile && <ServiceCallList {...serviceCallsProps} /> }
            {!isMobile && <ServiceCallTable showClient={isAgent} {...serviceCallsProps} /> }
        </div>
    );
};