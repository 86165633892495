import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../state/ducks';
import { RouteComponentProps } from 'react-router-dom';
import { Order } from '../../../state/models/Order';
import { getOrder, isLoadingOrder } from '../selectors';
import { OrderTable } from './components/OrderTable';
import { OrderDetail } from '../../../state/models/OrderDetail';
import { OrderItemView } from './components/OrderItemView';
import { EmptyPlaceholder } from '../../../components/common';
import { Divider } from 'semantic-ui-react';
import { ProductionOrder } from '../../../state/models';
import { getProductionOrdersByOrderId } from '../../../state/ducks/production-orders';

interface DispatchProps {
}

interface StoreProps {
    order: Order | undefined;
    productionOrders: ProductionOrder[];
    isLoading: boolean;
}

interface StateProps {
    selectedItem: OrderDetail | undefined;
}

export type OrderItemsTabProps =
    & StoreProps
    & DispatchProps
    & RouteComponentProps<{ orderId: string, selectedItemId?: string }>
    & WrappedComponentProps;

const m = defineMessages({
    noItemSelected: { id: 'OrderItemsTab.noItemSelected', defaultMessage: 'Select an item to view its details.' }
});

class OrderItemsTab extends React.Component<OrderItemsTabProps, StateProps> {
    public constructor(props: OrderItemsTabProps) {
        super(props);

        const selectedItemId = this.props.match.params.selectedItemId ? Number(this.props.match.params.selectedItemId) : undefined;
        this.state = { selectedItem: this.props.order ? this.props.order.orderDetails.find(x => x.id === selectedItemId) : undefined };
    }

    public componentDidUpdate(prevProps: OrderItemsTabProps) {
        if (prevProps.order == null && this.props.order != null) {
            const selectedItemId = this.props.match.params.selectedItemId ? Number(this.props.match.params.selectedItemId) : undefined;
            this.setState({ selectedItem: this.props.order ? this.props.order.orderDetails.find(x => x.id === selectedItemId) : undefined });
        }
    }

    public render() {
        const { formatMessage } = this.props.intl;
        if (this.props.order == null) {
            return (null);
        }

        return (
            <React.Fragment>
                <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                    <OrderTable
                        order={this.props.order}
                        defaultSelectedRow={this.state.selectedItem}
                        onSelectionChange={this.changeSelection}
                    />
                </div>

                <Divider />

                {this.state.selectedItem != null && <OrderItemView order={this.props.order} productionOrder={this.props.productionOrders.find(x => x.orderDetailId === this.state.selectedItem!.id)} item={this.state.selectedItem} />}
                {this.state.selectedItem == null && <EmptyPlaceholder title={formatMessage(m.noItemSelected)} icon="box" />}
            </React.Fragment>
        );
    }

    private changeSelection = (item: OrderDetail | undefined) => {
        this.setState({ selectedItem: item });
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: RouteComponentProps<{ orderId: string }>): StoreProps => {
    const orderId = Number(ownProps.match.params.orderId);

    return {
        order: getOrder(state, orderId),
        productionOrders: getProductionOrdersByOrderId(state, orderId),
        isLoading: isLoadingOrder(state, orderId)
    };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    return {};
};

const intlComponent = injectIntl(OrderItemsTab);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as OrderItemsTab };