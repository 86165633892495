export * from './ArrayHelper';
export * from './AttachmentHelper';
export * from './DebounceHelper';
export * from './FormatHelper';
export * from './StringHelper';
export * from './WarrantyHelper';

/**
 * A helper function to ensure type safety for 'stringly typed' property names.
 * It can be used like so: nameof<MyClass>('myPropertyOnMyClass')
 * @param name The name of the property.
 */
export const nameof = <T>(name: Extract<keyof T, string>): string => name;