import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Grid } from 'semantic-ui-react';

import { CommonCard, UploadFileControl, EmptyContentSegment, MediaGallery } from '../../common';
import { ServiceCallAttachment, Media, AttachmentType, MediaType } from '../../../state/models';

interface InspectionPhotoUploadCardOwnProps {
    medias: ServiceCallAttachment[];
    isUploading: boolean;
    deletingMediaIds: number[];

    onUploadMedia: (files: FileList) => void;
    onDeleteMedia: (attachmentId: number) => void;
}

export type InspectionPhotoUploadCardProps =
    & InspectionPhotoUploadCardOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'InspectionPhotoUploadCard.title', defaultMessage: 'Pictures of the furniture' },
    subtitle: { id: 'InspectionPhotoUploadCard.subtitle', defaultMessage: 'Take optional pictures of the furniture or the defects.'},
    emptyImageTitle: { id: 'InspectionPhotoUploadCard.empty_image_title', defaultMessage: 'No images or videos' },
    emptyImageDescription: { id: 'InspectionPhotoUploadCard.empty_image_description', defaultMessage: 'There are no images or videos associated to this service call.' },
});

class InspectionPhotoUploadCard extends React.Component<InspectionPhotoUploadCardProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <CommonCard
                title={formatMessage(m.title)}
                subtitle={formatMessage(m.subtitle)}
                color="teal"
            >
                {this.renderMediaAttachments()}
            </CommonCard>
        );
    }

    private renderMediaAttachments() {
        const { formatMessage } = this.props.intl;
        const medias: Media[] = this.props.medias
            ? this.props.medias.map(attachment => ({
                id: attachment.id,
                createdOn: attachment.createdOn,
                type: this.convertAttachmentType(attachment.type),
                isDisabled: this.props.deletingMediaIds 
                    ? this.props.deletingMediaIds.find(x => x === attachment.id) !== undefined
                    : false, 
                uri: `/api/service-calls/${attachment.serviceCallId}/attachments/${attachment.id}`
        }))
        : [];

        return (
            <Grid>
                <Grid.Column mobile={16} tablet={8} computer={10}>
                    {medias.length > 0 &&
                        <MediaGallery 
                            canEdit={true}
                            medias={medias} 
                            mediaSize="small" 
                            onDeleteMedia={this.props.onDeleteMedia}
                        />
                    }
                    {medias.length <= 0 &&
                        <EmptyContentSegment 
                            title={formatMessage(m.emptyImageTitle)} 
                            description={formatMessage(m.emptyImageDescription)}
                            basic={true} 
                        />
                    }
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={6}>
                    <UploadFileControl 
                        onFilesUpload={this.props.onUploadMedia} 
                        acceptedFileTypes="image/*, video/*"
                        isUploading={this.props.isUploading} 
                    />
                </Grid.Column>
            </Grid>
        );
    }

    private convertAttachmentType = (type: AttachmentType) => {
        if (type === AttachmentType.image) {
            return MediaType.image;
        } else if (type === AttachmentType.video) {
            return MediaType.video;
        }

        return MediaType.unknown;
    }
}

const connectedComponent = injectIntl(InspectionPhotoUploadCard);
export { connectedComponent as InspectionPhotoUploadCard };