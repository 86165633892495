import * as React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { InputProps } from 'semantic-ui-react';

interface OwnProps {
}

export type DatePickerWithoutKeyboardProps =
    & ReactDatePickerProps
    & OwnProps;

class DatePickerWithoutKeyboard extends React.Component<DatePickerWithoutKeyboardProps, {}> {
    public render() {
        // tslint:disable-next-line:no-any
        const InputWithoutKeyboard = React.forwardRef((props: InputProps, ref: any) => {
            return (
                <input
                    onClick={props.onClick}
                    value={props.value}
                    type="text"
                    readOnly={true} // blocks the keyboard on mobile devices
                    ref={ref}
                />
            );
        });

        return (
            <DatePicker
                customInput={<InputWithoutKeyboard />}
                {...this.props}
            />
        );
    }
}

export { DatePickerWithoutKeyboard };