import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Divider, Segment, Loader, Dimmer, Table, Button, Pagination, PaginationProps, Popup, Icon } from 'semantic-ui-react';
import { FilterOptions, SortOptions, ServiceCall, PagedResult, User, SortDirection } from '../../state/models';
import { NoServiceCalls, ServiceCallTableRow } from '.';
import { commonMessages } from '../../constants';
import { LoadingDimmer } from '../common';

export interface ServiceCallTableProps {
    serviceCalls: PagedResult<ServiceCall>;
    currentPage?: number;
    isLoading?: boolean;
    sortings?: SortOptions;
    showClient?: boolean;

    onViewDetails?: (serviceCall: ServiceCall) => void;
    onPageChange?: (newPage: number) => void;
    onSortData?: (sortBy: string, direction: SortDirection) => void;
}

interface ServiceCallTableState {
    sortBy?: string;
    direction?: SortDirection;
}

const m = defineMessages({
    customerHeader: { id: 'ServiceCallTable.customer_header', defaultMessage: 'Customer' },
    clientHeader: { id: 'ServiceCallTable.client_header', defaultMessage: 'Client' },
    statusHeader: { id: 'ServiceCallTable.status_header', defaultMessage: 'Status' },
    assignedToHeader: { id: 'ServiceCallTable.assigned_to_header', defaultMessage: 'Assigned to' },
    createdOnHeader: { id: 'ServiceCallTable.created_on_header', defaultMessage: 'Created' },
    lastUpdatedOnHeader: { id: 'ServiceCallTable.last_updated_on_header', defaultMessage: 'Last updated' },
});

class ServiceCallTable extends React.Component<ServiceCallTableProps & WrappedComponentProps, ServiceCallTableState> {
    public constructor(props: ServiceCallTableProps & WrappedComponentProps) {
        super(props);

        this.state = {
            sortBy: props.sortings ? props.sortings.sortBy : undefined,
            direction: props.sortings ? props.sortings.direction : undefined
        };
    }

    public componentWillReceiveProps(props: ServiceCallTableProps & WrappedComponentProps) {
        this.setState({
            sortBy: props.sortings ? props.sortings.sortBy : '',
            direction: props.sortings ? props.sortings.direction : SortDirection.Unspecified
        });
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const { serviceCalls } = this.props;
        const currentPage = this.props.currentPage || this.props.serviceCalls.pageIndex;

        const hasServiceCalls = serviceCalls && serviceCalls.results && serviceCalls.results.length > 0;
        const rows = hasServiceCalls
            ? serviceCalls.results.map(value => (
                <ServiceCallTableRow
                    key={value.id}
                    serviceCall={value}
                    onViewDetails={this.handleViewDetails}
                    showClient={this.props.showClient}
                />
            ))
            : <div />;

        return (
            <Segment basic={true}>
                <LoadingDimmer active={this.props.isLoading} />

                {hasServiceCalls &&
                    <div>
                        <Table basic="very">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell collapsing={true} />
                                    <Table.HeaderCell style={{ cursor: 'pointer' }} collapsing={true} onClick={() => this.handleSortData('servicecallNumber')}>#{this.renderSortIcon('servicecallNumber')}</Table.HeaderCell>
                                    <Table.HeaderCell style={{ cursor: 'pointer' }} onClick={() => this.handleSortData('customerName')}>{formatMessage(m.customerHeader)}{this.renderSortIcon('customerName')}</Table.HeaderCell>
                                    {this.props.showClient && <Table.HeaderCell style={{ cursor: 'pointer' }} onClick={() => this.handleSortData('clientName')}>{formatMessage(m.clientHeader)}{this.renderSortIcon('clientName')}</Table.HeaderCell>}
                                    <Table.HeaderCell style={{ cursor: 'pointer' }} onClick={() => this.handleSortData('assignedAgent')}>{formatMessage(m.assignedToHeader)}{this.renderSortIcon('assignedAgent')}</Table.HeaderCell>
                                    <Table.HeaderCell style={{ cursor: 'pointer' }} onClick={() => this.handleSortData('dateCreated')}>{formatMessage(m.createdOnHeader)}{this.renderSortIcon('dateCreated')}</Table.HeaderCell>
                                    <Table.HeaderCell style={{ cursor: 'pointer' }} onClick={() => this.handleSortData('lastUpdated')}>{formatMessage(m.lastUpdatedOnHeader)}{this.renderSortIcon('lastUpdated')}</Table.HeaderCell>
                                    <Table.HeaderCell collapsing={true} />
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {rows}
                            </Table.Body>
                        </Table>

                        {serviceCalls.pageCount > 1 &&
                            <div style={{ textAlign: 'center' }}>
                                <Pagination
                                    totalPages={serviceCalls.pageCount}
                                    ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                                    firstItem={null}
                                    lastItem={null}
                                    prevItem={{ content: <Icon name="angle left" />, icon: true }}
                                    nextItem={{ content: <Icon name="angle right" />, icon: true }}
                                    onPageChange={this.handlePageChange}
                                    activePage={currentPage}
                                />
                            </div>
                        }
                    </div>
                }

                {!hasServiceCalls &&
                    <NoServiceCalls />
                }
            </Segment>
        );
    }

    private renderSortIcon = (sortBy: string) => {
        if (this.state.sortBy === sortBy && this.state.direction === SortDirection.Ascending) {
            return <Icon name="caret up" />;
        } else if (this.state.sortBy === sortBy && this.state.direction === SortDirection.Descending) {
            return <Icon name="caret down" />;
        } else {
            return (null);
        }
    }

    private handlePageChange = (event: React.MouseEvent<HTMLAnchorElement>, data: PaginationProps) => {
        if (this.props.onPageChange) {
            this.props.onPageChange(data.activePage as number);
        }
    }

    private handleSortData = (sortBy: string) => {
        const previousSortBy = this.state.sortBy;
        let newDirection = previousSortBy === sortBy
            ? (this.state.direction === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending)
            : SortDirection.Ascending;

        this.setState({
            sortBy: sortBy,
            direction: newDirection
        });

        if (this.props.onSortData) {
            this.props.onSortData(sortBy, newDirection);
        }
    }

    private handleViewDetails = (serviceCall: ServiceCall) => {
        if (this.props.onViewDetails) {
            this.props.onViewDetails(serviceCall);
        }
    }
}

const connectedComponent = injectIntl(ServiceCallTable);
export { connectedComponent as ServiceCallTable };