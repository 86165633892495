import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Button, DropdownItemProps, DropdownProps, Card, CardProps, Form, Grid } from 'semantic-ui-react';
import { User } from '../../state/models';

export interface AssignAgentCardProps {
    currentUser: User | undefined;
    agents: User[];
    areAgentsLoading?: boolean;
    currentAgentAssigned?: User;
    isReadOnly?: boolean;
    onAssignAgent: (agentEmail: string) => void;
}

interface AssignAgentCardState {
    selectedAgentEmail?: string;
}

const m = defineMessages({
    title: { id: 'AssignToAgentCard.title', defaultMessage: 'Assign a customer service agent' },
    subtitle: { id: 'AssignToAgentCard.subtitle', defaultMessage: 'Choose the customer service agent that will be responsible for the service call follow ups.' },
    assignToMe: { id: 'AssignStep.assign_to_me', defaultMessage: 'Assign to me' },
    assignToAgent: { id: 'AssignStep.assign_to_agent', defaultMessage: 'Assign to agent' }
});

class AssignAgentCard extends React.Component<AssignAgentCardProps & WrappedComponentProps, AssignAgentCardState> {
    public constructor(props: AssignAgentCardProps & WrappedComponentProps) {
        super(props);

        this.state = {
            selectedAgentEmail: this.props.currentAgentAssigned ? this.props.currentAgentAssigned.email : undefined
        };
    }

    public componentWillReceiveProps(props: AssignAgentCardProps & WrappedComponentProps) {
        this.setState({
            selectedAgentEmail: this.props.currentAgentAssigned ? this.props.currentAgentAssigned.email : undefined
        });
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const currentAgent = this.props.agents.find(x => this.props.currentUser != null && x.id === this.props.currentUser.id);
        const agentDropdownOptions = this.props.agents.map((x): DropdownItemProps => ({
            id: x.id,
            icon: 'doctor',
            text: `${x.firstName} ${x.lastName}`,
            value: x.email
        }));

        return (
            <Card color="green" fluid={true}>
                <Card.Content>
                    <Card.Header content={formatMessage(m.title)} />
                    <Card.Meta content={formatMessage(m.subtitle)} />
                </Card.Content>
                <Card.Content>
                    <Form>
                        <Form.Dropdown
                            search={true}
                            selection={true}
                            disabled={this.props.isReadOnly}
                            options={agentDropdownOptions}
                            fluid={true}
                            onChange={this.handleAgentChange}
                            value={this.state.selectedAgentEmail}
                            loading={this.props.areAgentsLoading}
                        />
                        <Grid columns="equal">
                            <Grid.Column>
                                <Button
                                    fluid={true}
                                    content={formatMessage(m.assignToMe)}
                                    disabled={currentAgent == null}
                                    onClick={this.handleAssignToMeClick}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Button
                                    primary={true}
                                    fluid={true}
                                    content={formatMessage(m.assignToAgent)}
                                    disabled={!this.state.selectedAgentEmail || this.props.isReadOnly}
                                    onClick={this.handleAssignToAgentClick}
                                />
                            </Grid.Column>
                        </Grid>
                    </Form>
                </Card.Content>
            </Card>
        );
    }

    private handleAgentChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const agentEmail = data.value as string;

        this.setState({ selectedAgentEmail: agentEmail });
    }

    private handleAssignToMeClick = () => {
        const currentAgent = this.props.agents.find(x => this.props.currentUser != null && x.id === this.props.currentUser.id);
        if (currentAgent != null) {
            this.setState({ selectedAgentEmail: currentAgent.email });
            this.props.onAssignAgent(currentAgent.email);
        }
    }

    private handleAssignToAgentClick = () => {
        if (this.state.selectedAgentEmail) {
            this.props.onAssignAgent(this.state.selectedAgentEmail);
        }
    }
}

const intlComponent = injectIntl(AssignAgentCard);
export { intlComponent as AssignAgentCard };