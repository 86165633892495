import { Reducer } from 'redux';
import { ActionTypes } from './actions';
import { ActionTypeKeys } from './types';
import { NotificationType, Defect } from '../../models';

export interface DefectsState {
    isLoading: boolean;
    defects: Defect[];
}

const initialState: DefectsState = {
    isLoading: false,
    defects: []
};

export const defectReducer: Reducer<DefectsState> = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case ActionTypeKeys.DEFECTS_LOAD_REQUEST:
            return { ...state, isLoading: true };

        case ActionTypeKeys.DEFECTS_LOAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                defects: action.payload
            };

        case ActionTypeKeys.DEFECTS_LOAD_FAILURE:
            return { ...state, isLoading: false };

        default:
            return state;
    }
};

export default defectReducer;