import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { ApplicationState } from '../state/ducks';
import { UnauthorizedMessage } from '../components/common';

interface UnauthorizedPageActions {
}

interface UnauthorizedPageOwnProps {
}

export type UnauthorizedPageProps =
    & UnauthorizedPageOwnProps
    & UnauthorizedPageActions
    & RouteComponentProps<{}>
    & WrappedComponentProps;

class UnauthorizedPage extends React.Component<UnauthorizedPageProps, {}> {
    public render() {
        return (
            <UnauthorizedMessage />
        );
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: Dispatch): UnauthorizedPageActions => {
    return {
    };
};

const intlComponent = injectIntl(UnauthorizedPage);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as UnauthorizedPage };