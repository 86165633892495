import { Reducer } from 'redux';
import { ActionTypes } from './actions';
import { ActionTypeKeys } from './types';
import { ReportingResultVariation, StatisticsFilters, initialStatisticsFilter, LabeledValue, DatedValue, initialTrendsFilter, TrendsFilters, QualityRatio, DisplayOptions, initialDisplayOptions, PairedValue, ServiceCallStatus, ClientsAdoptionFilters, initialClientsAdoptionFilter, Ratio } from '../../models';
import { ActionTypeKeys as SearchActionTypeKeys } from '../search/types';

export interface ReportingState {
    statisticsFilters: StatisticsFilters;
    trendsFilters: TrendsFilters;
    trendsDisplay: DisplayOptions;
    clientsAdoptionFilters: ClientsAdoptionFilters;

    overdueCallCount: number;
    stalledUnassignedCallCount: number;
    callsCreated: ReportingResultVariation<number>;
    callsClosed: ReportingResultVariation<number>;
    callsActive: ReportingResultVariation<number>;
    averageResolutionTime: ReportingResultVariation<number>;
    problemsFrequency: ReportingResultVariation<LabeledValue<number>[]>;
    callsRepairsDistribution: ReportingResultVariation<LabeledValue<number>[]>;
    defectiveProductsFrequency: ReportingResultVariation<LabeledValue<number>[]>;
    defectiveCoversFrequency: ReportingResultVariation<LabeledValue<number>[]>;
    qualityRatios: DatedValue<QualityRatio>[];
    callsCreatedPerMonth: DatedValue<number>[];

    currentActiveCalls: number;
    activeCallsStatus: PairedValue<number, ServiceCallStatus>[];

    clientsAdoptionRatio: Ratio<number>;
    topClientAdopters: LabeledValue<number>[];

    isOverdueCallCountLoading: boolean;
    isStalledUnassignedCallCountLoading: boolean;
    areCallsCreatedLoading: boolean;
    areCallsClosedLoading: boolean;
    areCallsActiveLoading: boolean;
    isAverageResolutionTimeLoading: boolean;
    isProblemsFrequencyLoading: boolean;
    isCallsRepairsDistributionLoading: boolean;
    isDefectiveProductsFrequencyLoading: boolean;
    isDefectiveCoversFrequencyLoading: boolean;
    isQualityRatiosLoading: boolean;
    isCallsCreatedPerMonthLoading: boolean;

    isCurrentActiveCallsLoading: boolean;
    isActiveCallsStatusLoading: boolean;

    isClientsAdoptionRatioLoading: boolean;
    isTopClientAdoptersLoading: boolean;
}

const emptyNumberVariation: ReportingResultVariation<number> = {
    currentPeriod: 0,
    previousPeriod: 0
};

const emptyLabeledValuesVariation: ReportingResultVariation<LabeledValue<number>[]> = {
    currentPeriod: [],
    previousPeriod: []
};

const initialState: ReportingState = {
    statisticsFilters: initialStatisticsFilter,
    trendsFilters: initialTrendsFilter,
    trendsDisplay: initialDisplayOptions,
    clientsAdoptionFilters: initialClientsAdoptionFilter,

    overdueCallCount: 0,
    stalledUnassignedCallCount: 0,
    callsCreated: emptyNumberVariation,
    callsClosed: emptyNumberVariation,
    callsActive: emptyNumberVariation,
    averageResolutionTime: emptyNumberVariation,
    problemsFrequency: emptyLabeledValuesVariation,
    callsRepairsDistribution: emptyLabeledValuesVariation,
    defectiveProductsFrequency: emptyLabeledValuesVariation,
    defectiveCoversFrequency: emptyLabeledValuesVariation,
    qualityRatios: [],
    callsCreatedPerMonth: [],

    currentActiveCalls: 0,
    activeCallsStatus: [],

    clientsAdoptionRatio: { value: 0, total: 0 },
    topClientAdopters: [],

    isOverdueCallCountLoading: false,
    isStalledUnassignedCallCountLoading: false,
    areCallsCreatedLoading: false,
    areCallsClosedLoading: false,
    areCallsActiveLoading: false,
    isAverageResolutionTimeLoading: false,
    isProblemsFrequencyLoading: false,
    isCallsRepairsDistributionLoading: false,
    isDefectiveProductsFrequencyLoading: false,
    isDefectiveCoversFrequencyLoading: false,
    isQualityRatiosLoading: false,
    isCallsCreatedPerMonthLoading: false,

    isCurrentActiveCallsLoading: false,
    isActiveCallsStatusLoading: false,

    isClientsAdoptionRatioLoading: false,
    isTopClientAdoptersLoading: false
};

export const notificationsReducer: Reducer<ReportingState> = (state: ReportingState = initialState, action: ActionTypes) => {
    switch (action.type) {
        case ActionTypeKeys.LOAD_OVERDUE_CALL_COUNT_REQUEST:
            return {
                ...state,
                isOverdueCallCountLoading: true
            };

        case ActionTypeKeys.LOAD_OVERDUE_CALL_COUNT_SUCCESS:
            return {
                ...state,
                overdueCallCount: action.payload,
                isOverdueCallCountLoading: false,
            };

        case ActionTypeKeys.LOAD_OVERDUE_CALL_COUNT_FAILURE:
            return {
                ...state,
                isOverdueCallCountLoading: false
            };

        case ActionTypeKeys.LOAD_STALLED_UNASSIGNED_CALL_COUNT_REQUEST:
            return {
                ...state,
                isStalledUnassignedCallCountLoading: true
            };

        case ActionTypeKeys.LOAD_STALLED_UNASSIGNED_CALL_COUNT_SUCCESS:
            return {
                ...state,
                stalledUnassignedCallCount: action.payload,
                isStalledUnassignedCallCountLoading: false,
            };

        case ActionTypeKeys.LOAD_STALLED_UNASSIGNED_CALL_COUNT_FAILURE:
            return {
                ...state,
                isStalledUnassignedCallCountLoading: false
            };

        case ActionTypeKeys.LOAD_CALLS_CREATED_REQUEST:
            return {
                ...state,
                areCallsCreatedLoading: true
            };

        case ActionTypeKeys.LOAD_CALLS_CREATED_SUCCESS:
            return {
                ...state,
                callsCreated: action.payload,
                areCallsCreatedLoading: false,
            };

        case ActionTypeKeys.LOAD_CALLS_CREATED_FAILURE:
            return {
                ...state,
                areCallsCreatedLoading: false
            };

        case ActionTypeKeys.LOAD_CALLS_CLOSED_REQUEST:
            return {
                ...state,
                areCallsClosedLoading: true
            };

        case ActionTypeKeys.LOAD_CALLS_CLOSED_SUCCESS:
            return {
                ...state,
                callsClosed: action.payload,
                areCallsClosedLoading: false,
            };

        case ActionTypeKeys.LOAD_CALLS_CLOSED_FAILURE:
            return {
                ...state,
                areCallsClosedLoading: false
            };

        case ActionTypeKeys.LOAD_CALLS_ACTIVE_REQUEST:
            return {
                ...state,
                areCallsActiveLoading: true
            };

        case ActionTypeKeys.LOAD_CALLS_ACTIVE_SUCCESS:
            return {
                ...state,
                callsActive: action.payload,
                areCallsActiveLoading: false,
            };

        case ActionTypeKeys.LOAD_CALLS_ACTIVE_FAILURE:
            return {
                ...state,
                areCallsActiveLoading: false
            };

        case ActionTypeKeys.LOAD_AVERAGE_RESOLUTION_TIME_REQUEST:
            return {
                ...state,
                isAverageResolutionTimeLoading: true
            };

        case ActionTypeKeys.LOAD_AVERAGE_RESOLUTION_TIME_SUCCESS:
            return {
                ...state,
                averageResolutionTime: action.payload,
                isAverageResolutionTimeLoading: false,
            };

        case ActionTypeKeys.LOAD_AVERAGE_RESOLUTION_TIME_FAILURE:
            return {
                ...state,
                isAverageResolutionTimeLoading: false
            };

        case ActionTypeKeys.LOAD_CURRENT_ACTIVE_CALLS_REQUEST:
            return {
                ...state,
                isCurrentActiveCallsLoading: true
            };

        case ActionTypeKeys.LOAD_CURRENT_ACTIVE_CALLS_SUCCESS:
            return {
                ...state,
                currentActiveCalls: action.payload,
                isCurrentActiveCallsLoading: false
            };

        case ActionTypeKeys.LOAD_CURRENT_ACTIVE_CALLS_FAILURE:
            return {
                ...state,
                isCurrentActiveCallsLoading: false
            };

        case ActionTypeKeys.LOAD_PROBLEMS_FREQUENCY_REQUEST:
            return {
                ...state,
                isProblemsFrequencyLoading: true
            };

        case ActionTypeKeys.LOAD_PROBLEMS_FREQUENCY_SUCCESS:
            return {
                ...state,
                problemsFrequency: action.payload,
                isProblemsFrequencyLoading: false,
            };

        case ActionTypeKeys.LOAD_PROBLEMS_FREQUENCY_FAILURE:
            return {
                ...state,
                isProblemsFrequencyLoading: false
            };

        case ActionTypeKeys.LOAD_DEFECTIVE_PRODUCTS_FREQUENCY_REQUEST:
            return {
                ...state,
                isDefectiveProductsFrequencyLoading: true
            };

        case ActionTypeKeys.LOAD_DEFECTIVE_PRODUCTS_FREQUENCY_SUCCESS:
            return {
                ...state,
                defectiveProductsFrequency: action.payload,
                isDefectiveProductsFrequencyLoading: false,
            };

        case ActionTypeKeys.LOAD_DEFECTIVE_PRODUCTS_FREQUENCY_FAILURE:
            return {
                ...state,
                isDefectiveProductsFrequencyLoading: false
            };

        case ActionTypeKeys.LOAD_DEFECTIVE_COVERS_FREQUENCY_REQUEST:
            return {
                ...state,
                isDefectiveCoversFrequencyLoading: true
            };

        case ActionTypeKeys.LOAD_DEFECTIVE_COVERS_FREQUENCY_SUCCESS:
            return {
                ...state,
                defectiveCoversFrequency: action.payload,
                isDefectiveCoversFrequencyLoading: false,
            };

        case ActionTypeKeys.LOAD_DEFECTIVE_COVERS_FREQUENCY_FAILURE:
            return {
                ...state,
                isDefectiveCoversFrequencyLoading: false
            };

        case ActionTypeKeys.LOAD_CALLS_REPAIRS_DISTRIBUTION_REQUEST:
            return {
                ...state,
                isCallsRepairsDistributionLoading: true
            };

        case ActionTypeKeys.LOAD_CALLS_REPAIRS_DISTRIBUTION_SUCCESS:
            return {
                ...state,
                isCallsRepairsDistributionLoading: false,
                callsRepairsDistribution: action.payload
            };

        case ActionTypeKeys.LOAD_CALLS_REPAIRS_DISTRIBUTION_FAILURE:
            return {
                ...state,
                isCallsRepairsDistributionLoading: false
            };

        case ActionTypeKeys.LOAD_QUALITY_RATIOS_REQUEST:
            return {
                ...state,
                isQualityRatiosLoading: true
            };

        case ActionTypeKeys.LOAD_QUALITY_RATIOS_SUCCESS:
            return {
                ...state,
                qualityRatios: action.payload,
                isQualityRatiosLoading: false
            };

        case ActionTypeKeys.LOAD_QUALITY_RATIOS_FAILURE:
            return {
                ...state,
                isQualityRatiosLoading: false
            };

        case ActionTypeKeys.LOAD_CALLS_CREATED_PER_MONTH_REQUEST:
            return {
                ...state,
                isCallsCreatedPerMonthLoading: true
            };

        case ActionTypeKeys.LOAD_CALLS_CREATED_PER_MONTH_SUCCESS:
            return {
                ...state,
                isCallsCreatedPerMonthLoading: false,
                callsCreatedPerMonth: action.payload
            };

        case ActionTypeKeys.LOAD_CALLS_CREATED_PER_MONTH_FAILURE:
            return {
                ...state,
                isCallsCreatedPerMonthLoading: false
            };

        case ActionTypeKeys.LOAD_ACTIVE_CALLS_STATUS_REQUEST:
            return {
                ...state,
                isActiveCallsStatusLoading: true
            };

        case ActionTypeKeys.LOAD_ACTIVE_CALLS_STATUS_SUCCESS:
            return {
                ...state,
                activeCallsStatus: action.payload,
                isActiveCallsStatusLoading: false
            };

        case ActionTypeKeys.LOAD_ACTIVE_CALLS_STATUS_FAILURE:
            return {
                ...state,
                isActiveCallsStatusLoading: false
            };

        case ActionTypeKeys.LOAD_CLIENTS_ADOPTION_RATIO_REQUEST: {
            return {
                ...state,
                isClientsAdoptionRatioLoading: true
            };
        }

        case ActionTypeKeys.LOAD_CLIENTS_ADOPTION_RATIO_SUCCESS: {
            return {
                ...state,
                isClientsAdoptionRatioLoading: false,
                clientsAdoptionRatio: action.payload
            };
        }

        case ActionTypeKeys.LOAD_CLIENTS_ADOPTION_RATIO_FAILURE: {
            return {
                ...state,
                isClientsAdoptionRatioLoading: false
            };
        }

        case ActionTypeKeys.LOAD_TOP_CLIENT_ADOPTERS_REQUEST: {
            return {
                ...state,
                isTopClientAdoptersLoading: true
            };
        }

        case ActionTypeKeys.LOAD_TOP_CLIENT_ADOPTERS_SUCCESS: {
            return {
                ...state,
                isTopClientAdoptersLoading: false,
                topClientAdopters: action.payload
            };
        }

        case ActionTypeKeys.LOAD_TOP_CLIENT_ADOPTERS_FAILURE: {
            return {
                ...state,
                isTopClientAdoptersLoading: false
            };
        }

        case ActionTypeKeys.LOAD_COUNTRIES_FILTER_REQUEST:
            return {
                ...state,
                statisticsFilters: {
                    ...state.statisticsFilters,
                    regionFilter: {
                        ...state.statisticsFilters.regionFilter,
                        areCountryCodesLoading: true
                    }
                }
            };

        case ActionTypeKeys.LOAD_COUNTRIES_FILTER_SUCCESS:
            return {
                ...state,
                statisticsFilters: {
                    ...state.statisticsFilters,
                    regionFilter: {
                        ...state.statisticsFilters.regionFilter,
                        availableCountryCodes: action.payload,
                        areCountryCodesLoading: false
                    }
                }
            };

        case ActionTypeKeys.LOAD_COUNTRIES_FILTER_FAILURE:
            return {
                ...state,
                statisticsFilters: {
                    ...state.statisticsFilters,
                    regionFilter: {
                        ...state.statisticsFilters.regionFilter,
                        areCountryCodesLoading: false
                    }
                }
            };

        case ActionTypeKeys.LOAD_PROVINCES_FILTER_REQUEST:
            return {
                ...state,
                statisticsFilters: {
                    ...state.statisticsFilters,
                    regionFilter: {
                        ...state.statisticsFilters.regionFilter,
                        areProvinceCodesLoading: true
                    }
                }
            };

        case ActionTypeKeys.LOAD_PROVINCES_FILTER_SUCCESS:
            return {
                ...state,
                statisticsFilters: {
                    ...state.statisticsFilters,
                    regionFilter: {
                        ...state.statisticsFilters.regionFilter,
                        availableProvinceCodes: action.payload,
                        areProvinceCodesLoading: false
                    }
                }
            };

        case ActionTypeKeys.LOAD_PROVINCES_FILTER_FAILURE:
            return {
                ...state,
                statisticsFilters: {
                    ...state.statisticsFilters,
                    regionFilter: {
                        ...state.statisticsFilters.regionFilter,
                        areProvinceCodesLoading: false
                    }
                }
            };

        case SearchActionTypeKeys.CLIENTS_SEARCH_REQUEST:
            return {
                ...state,
                statisticsFilters: {
                    ...state.statisticsFilters,
                    clientFilter: {
                        ...state.statisticsFilters.clientFilter,
                        isSearching: true
                    }
                },
                trendsFilters: {
                    ...state.trendsFilters,
                    clientFilter: {
                        ...state.trendsFilters.clientFilter,
                        isSearching: true
                    }
                }
            };

        case SearchActionTypeKeys.CLIENTS_SEARCH_SUCCESS:
            return {
                ...state,
                statisticsFilters: {
                    ...state.statisticsFilters,
                    clientFilter: {
                        ...state.statisticsFilters.clientFilter,
                        clientsSearchResults: action.payload,
                        isSearching: false
                    }
                },
                trendsFilters: {
                    ...state.trendsFilters,
                    clientFilter: {
                        ...state.trendsFilters.clientFilter,
                        clientsSearchResults: action.payload,
                        isSearching: false
                    }
                }
            };

        case SearchActionTypeKeys.CLIENTS_SEARCH_FAILURE:
            return {
                ...state,
                statisticsFilters: {
                    ...state.statisticsFilters,
                    clientFilter: {
                        ...state.statisticsFilters.clientFilter,
                        isSearching: false
                    }
                },
                trendsFilters: {
                    ...state.trendsFilters,
                    clientFilter: {
                        ...state.trendsFilters.clientFilter,
                        isSearching: false
                    }
                }
            };

        case ActionTypeKeys.SET_STATISTICS_FILTERS:
            return {
                ...state,
                statisticsFilters: action.payload
            };

        case ActionTypeKeys.SET_TRENDS_FILTERS:
            return {
                ...state,
                trendsFilters: action.payload
            };

        case ActionTypeKeys.SET_TRENDS_DISPLAY:
            return {
                ...state,
                trendsDisplay: action.payload
            };

        case ActionTypeKeys.SET_CLIENTS_ADOPTION_FILTERS: {
            return {
                ...state,
                clientsAdoptionFilters: action.payload
            };
        }

        default:
            return state;
    }
};

export default notificationsReducer;