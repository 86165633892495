import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedMessage } from 'react-intl';
import { Button, List, Grid, Checkbox } from 'semantic-ui-react';
import { WarrantyStatusLabel } from '..';
import { WarrantyType, Defect } from '../../../state/models';
import { SmallConfirmDeleteButton } from '../../common';
import { defectTypeMessages, defectCategoryMessages, defectCauseMessages } from '../../../constants';

export interface DefectDetailsProps {
    defect: Defect;
    isReadOnly?: boolean;
    warrantyStartDate?: Date;
    onDelete?: (defect: Defect) => void;
}

const m = defineMessages({
    rootCauseHeader: { id: 'DefectDetails.root_cause_header', defaultMessage: 'Cause:' },
    categoryHeader: { id: 'DefectDetails.category_header', defaultMessage: 'Category:' },
    partsWarrantyHeader: { id: 'DefectDetails.parts_warranty_header', defaultMessage: 'Parts warranty' },
    labourWarrantyHeader: { id: 'DefectDetails.labour_warranty_header', defaultMessage: 'Labour warranty' },
    defectTypeFormat: { id: 'DefectDetails.defect_type_format', defaultMessage: '{defectCode} - {defectType}' },
    deleteConfirmationTitle: { id: 'DefectDetails.delete_confirmation_title', defaultMessage: 'Are you sure you?' },
    deleteConfirmationMessageFormat: { id: 'DefectDetails.delete_confirmation_message_format', defaultMessage: 'Are you sure you want to remove the defect #{defect} from this service call?'}
});

class DefectDetails extends React.Component<DefectDetailsProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { defect, warrantyStartDate } = this.props;

        const typeMessages = Object.keys(defectTypeMessages).map(key => defectTypeMessages[key]);
        const defectTypeMessage = typeMessages.find(x => x.id === defect.defectType.title);
        const defectType = defectTypeMessage ? formatMessage(defectTypeMessage) : defect.defectType.title;

        const categoryMessages = Object.keys(defectCategoryMessages).map(key => defectCategoryMessages[key]);
        const defectCategoryMessage = categoryMessages.find(x => x.id === defect.defectCategory.title);
        const defectCategory = defectCategoryMessage ? formatMessage(defectCategoryMessage) : defect.defectCategory.title;

        const causeMessages = Object.keys(defectCauseMessages).map(key => defectCauseMessages[key]);
        const defectCauseMessage = causeMessages.find(x => x.id === defect.title);
        const defectCause = defectCauseMessage ? formatMessage(defectCauseMessage) : defect.title;

        return (
            <div>
                <div style={{display: 'flex'}}>
                    <div style={{minWidth: 0}}>
                        <h5 style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', margin: 0}}>
                            {formatMessage(m.defectTypeFormat, {defectCode: defect.id, defectType: defectType})}
                        </h5>
                        <p className="meta" style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', margin: 0 }}>
                            <strong>{formatMessage(m.categoryHeader)}</strong> {defectCategory}
                        </p>
                        <p className="meta" style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            <strong>{formatMessage(m.rootCauseHeader)}</strong> {defectCause}
                        </p>
                    </div>
                    {this.props.onDelete && !this.props.isReadOnly &&
                        <div style={{flexGrow: 1, textAlign: 'right'}}>
                            <SmallConfirmDeleteButton 
                                style={{margin: '0 0 0 10px'}}
                                confirmationTitle={formatMessage(m.deleteConfirmationTitle)}
                                confirmationMessage={formatMessage(m.deleteConfirmationMessageFormat, { 'defect': this.props.defect.id })}
                                onClick={() => this.props.onDelete ? this.props.onDelete(this.props.defect) : (null)}
                            />
                        </div>
                    }
                </div>
                <Grid style={{marginTop: 0}}>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <strong style={{display: 'block'}}>{formatMessage(m.partsWarrantyHeader)}</strong>
                            <WarrantyStatusLabel 
                                warrantyType={defect.partsWarranty} 
                                warrantyStartDate={warrantyStartDate} 
                                style={{minWidth: 90}} 
                            />
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <strong style={{display: 'block'}}>{formatMessage(m.labourWarrantyHeader)}</strong>
                            <WarrantyStatusLabel 
                                warrantyType={defect.labourWarranty} 
                                warrantyStartDate={warrantyStartDate} 
                                style={{minWidth: 90}} 
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

const intlComponent = injectIntl(DefectDetails);
export { intlComponent as DefectDetails };