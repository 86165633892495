import * as React from 'react';

import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Card, List, TextArea, Form, Grid, Checkbox, Button, Divider } from 'semantic-ui-react';
import { TimeInput, LoadingDimmer, SignatureModal } from '../../common';
import { commonMessages } from '../../../constants';
import { TechnicianRepairsStatus, TechnicianReport, ServiceCall } from '../../../state/models';
import { DateHelper } from '../../../helpers';

interface ServiceReportSummaryCardOwnProps {
    isLoading?: boolean;
    serviceCall: ServiceCall;
    report: TechnicianReport | undefined;
    onSign: (signature: string) => void;
}

interface ServiceReportSummaryCardState {
    isSignatureModalOpen?: boolean;
    signatureData?: string;
}

export type ServiceReportSummaryCardProps =
    & ServiceReportSummaryCardOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'ServiceReportSummaryCard.title', defaultMessage: 'Service report summary' },
    subtitle: { id: 'ServiceReportSummaryCard.subtitle', defaultMessage: 'Review and sign the report summary with the client.' },
    problemSummary: { id: 'ServiceReportSummaryCard.problem_summary', defaultMessage: 'Problem summary' },
    workPerformed: { id: 'ServiceReportSummaryCard.work_performed', defaultMessage: 'Work performed' },
    clientSignature: { id: 'ServiceReportSummaryCard.client_signature', defaultMessage: 'Client or supervisor signature' },
    repairStatusLabel: { id: 'ServiceReportSummaryCard.repair_status_label', defaultMessage: 'Repair status' },
    complete: { id: 'ServiceReportSummaryCard.complete', defaultMessage: 'Complete' },
    incomplete: { id: 'ServiceReportSummaryCard.incomplete', defaultMessage: 'Incomplete' },
    partsRequired: { id: 'ServiceReportSummaryCard.parts_required', defaultMessage: 'Parts required' },
    labourTime: { id: 'ServiceReportSummaryCard.labour_time', defaultMessage: 'Labour time' }
});

class ServiceReportSummaryCard extends React.Component<ServiceReportSummaryCardProps, ServiceReportSummaryCardState> {
    public constructor(props: ServiceReportSummaryCardProps) {
        super(props);

        this.state = { 
            signatureData: props.report ? props.report.clientSignature : undefined
        };
    }

    public componentWillReceiveProps(props: ServiceReportSummaryCardProps) {
        this.setState({
            signatureData: props.report ? props.report.clientSignature : undefined
        });
    }

    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <Card fluid={true} color="teal" style={{height: '100%'}}>
                <Card.Content style={{flexGrow: 0}}>
                    <Card.Header content={formatMessage(m.title)} />
                    <Card.Meta content={formatMessage(m.subtitle)} />
                </Card.Content>
                <Card.Content>
                    <LoadingDimmer active={this.props.isLoading} />
                    {!this.props.isLoading &&
                        <div>
                            <h5>{formatMessage(m.problemSummary)}</h5>
                            {this.props.serviceCall.problemDescription}
                            <Divider />
                            
                            {this.props.report &&
                                <React.Fragment>
                                    <h5>{formatMessage(m.workPerformed)}</h5>
                                    <p>{this.props.report.report}</p>
                                    <Divider />

                                    <Grid columns={2} stackable={true}>
                                        <Grid.Column>
                                            <List>
                                                <List.Item 
                                                    icon="wrench" 
                                                    header={formatMessage(m.repairStatusLabel)}
                                                    content={this.getRepairStatusDescription(this.props.report.repairsStatus)}
                                                />

                                                <List.Item 
                                                    icon="clock" 
                                                    header={formatMessage(m.labourTime)}
                                                    content={DateHelper.getTimeDescription(this.props.report.hoursTaken, this.props.report.minutesTaken)}
                                                />
                                            </List>
                                        </Grid.Column>
                                        <Grid.Column textAlign="right">
                                            {this.state.signatureData && 
                                                <div style={{textAlign: 'right'}}>
                                                    <img src={this.state.signatureData} style={{width: 275 }} />
                                                </div>
                                            }
                                            <Button 
                                                primary={true} 
                                                icon="signup" 
                                                content={formatMessage(m.clientSignature)} 
                                                onClick={() => this.setState({ isSignatureModalOpen: true })} 
                                            />
                                            <SignatureModal
                                                isOpen={this.state.isSignatureModalOpen}
                                                onSign={this.handleSignature}
                                                onClose={() => this.setState({ isSignatureModalOpen: false })}
                                            />
                                        </Grid.Column>
                                    </Grid>
                                </React.Fragment>
                            }
                        </div>
                    }

                </Card.Content>
            </Card>
        );
    }

    private getRepairStatusDescription(repairStatus: TechnicianRepairsStatus): string {
        const { formatMessage } = this.props.intl;

        switch (repairStatus) {
            case TechnicianRepairsStatus.complete:
                return formatMessage(m.complete);

            case TechnicianRepairsStatus.incomplete:
                return formatMessage(m.incomplete);

            case TechnicianRepairsStatus.partsRequired:
                return formatMessage(m.partsRequired);

            default:
                return formatMessage(commonMessages.unknown);
        }
    }

    private handleSignature = (signatureBase64Data: string) => {
        this.setState({ 
            signatureData: signatureBase64Data,
            isSignatureModalOpen: false
        });

        this.props.onSign(signatureBase64Data);
    }
}

const connectedComponent = injectIntl(ServiceReportSummaryCard);
export { connectedComponent as ServiceReportSummaryCard };