import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { Tab, TabProps, SemanticICONS } from 'semantic-ui-react';

import { ApplicationState } from '../../../state/ducks';
import { PageHeader } from '../../../components/common';
import { ServiceCallTabs } from '../../../state/ducks/ui/reducers';
import { ExportDropdown } from '../../../containers/after-sales/agent';
import { getServiceCall } from '../../../state/ducks/service-calls';
import { getCurrentUserProfile } from '../../../state/ducks/current-user';
import { SecurityHelper } from '../../../helpers';
import { useResponsive } from '../../../utils/responsive';
import { getCurrentServiceCallTabId } from '../../../state/ducks/ui';
import { actionCreators as UiActions } from '../../../state/ducks/ui';
import { actionCreators as ServiceCallsActions } from '../../../state/ducks/service-calls';

const m = defineMessages({
    title: { id: 'ViewServiceCallPage.title', defaultMessage: 'Service call #{serviceCallId}' },
    subtitle: { id: 'ViewServiceCallPage.subtitle', defaultMessage: 'View the details of a service call and track its activity.' },
    callDetailsTab: { id: 'ViewServiceCallPage.call_details_tab', defaultMessage: 'Call details' },
    afterSalesServiceTab: { id: 'ViewServiceCallPage.after_sales_service_tab', defaultMessage: 'After-sales service' },
    technicianTab: { id: 'ViewServiceCallPage.technician_tab', defaultMessage: 'Technician' },
    factoryTab: { id: 'ViewServiceCallPage.factory_tab', defaultMessage: 'Factory repairs' },
    inspectionTab: { id: 'ViewServiceCallPage.inspection_tab', defaultMessage: 'Inspection' }
});

interface RouteProps {
    serviceCallId: string;
}

export const ViewServiceCallPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const { isMobile } = useResponsive();
    const { serviceCallId } = useParams<RouteProps>();
    const history = useHistory();
    const dispatch = useDispatch();

    const currentUser = useSelector(getCurrentUserProfile);
    const currentActiveTabId = useSelector(getCurrentServiceCallTabId);
    const serviceCall = useSelector((state: ApplicationState) => getServiceCall(state, Number(serviceCallId)));

    useEffect(() => {
        dispatch(ServiceCallsActions.loadDetails(Number(serviceCallId)));
    }, [dispatch, serviceCallId]);

    const createTabs = () => {
        let tabPanes = [];

        if (SecurityHelper.canViewTabs(currentUser)) {
            if (SecurityHelper.canViewDetailsTab(currentUser)) {
                tabPanes.push(createTab('details', 'phone', formatMessage(m.callDetailsTab)));
            }

            if (SecurityHelper.canViewAgentTab(currentUser)) {
                tabPanes.push(createTab('agent', 'doctor', formatMessage(m.afterSalesServiceTab)));
            }

            if (SecurityHelper.canViewTechnicianTab(currentUser)) {
                tabPanes.push(createTab('technician', 'wrench', formatMessage(m.technicianTab)));
            }

            if (SecurityHelper.canViewFactoryTab(currentUser)) {
                tabPanes.push(createTab('factory', 'factory', formatMessage(m.factoryTab)));
            }

            if (SecurityHelper.canViewInspectionTab(currentUser)) {
                tabPanes.push(createTab('inspection', 'eye', formatMessage(m.inspectionTab)));
            }
        }

        return tabPanes;
    }

    const createTab = (tab: ServiceCallTabs, icon: SemanticICONS, content: string) => {
        return {
            menuItem: {
                key: tab,
                index: tab,
                active: currentActiveTabId === tab,
                icon,
                content
            }
        };
    }

    const handleTabChange = (_event: React.MouseEvent<HTMLDivElement>, data: TabProps) => {
        if (data.activeIndex === 'details') {
            dispatch(UiActions.changeServiceCallTab('details'));
            history.push(`/service-calls/${serviceCallId}/details`);
        } else if (data.activeIndex === 'agent') {
            dispatch(UiActions.changeServiceCallTab('agent'));
            history.push(`/service-calls/${serviceCallId}/agent/assign`);
        } else if (data.activeIndex === 'technician') {
            dispatch(UiActions.changeServiceCallTab('technician'));
            history.push(`/service-calls/${serviceCallId}/technician-repairs/all`);
        } else if (data.activeIndex === 'factory') {
            dispatch(UiActions.changeServiceCallTab('factory'));
            history.push(`/service-calls/${serviceCallId}/factory-repairs/all`);
        }
    }

    const tabPanes = createTabs();

    return (
        <div>
            <PageHeader
                iconName="phone"
                title={formatMessage(m.title, { serviceCallId })}
                subtitle={formatMessage(m.subtitle)}
            />

            {SecurityHelper.canExportForms(currentUser) &&
                <div style={{ position: 'absolute', top: 8, right: 8 }}>
                    <ExportDropdown
                        serviceCall={serviceCall}
                        disabled={serviceCall == null}
                    />
                </div>
            }

            {SecurityHelper.canViewTabs(currentUser) && tabPanes.length > 1 &&
                <React.Fragment>
                    {!isMobile &&
                        <Tab
                            className="c-tab"
                            menu={{ secondary: true, pointing: true, className: 'c-tab__menu' }}
                            panes={tabPanes}
                            style={{ marginBottom: '1rem' }}
                            onTabChange={handleTabChange}
                        />
                    }

                    {isMobile &&
                        <Tab
                            className="c-tab"
                            menu={{ secondary: true, pointing: true, className: 'c-tab__menu c-tab__menu--mobile' }}
                            panes={tabPanes}
                            style={{ marginBottom: '1rem' }}
                            onTabChange={handleTabChange}
                        />
                    }
                </React.Fragment>
            }
        </div>
    );
};