import { PreferredLanguage, ValidationResult } from '../../../../../state/models';
import { nameof, StringHelper } from '../../../../../state/utils';
import { defineMessages } from 'react-intl';

const m = defineMessages({
    nameRequired: { id: 'SubcontractorEdit.nameRequired', defaultMessage: 'The subcontractor\'s name is required.' },
    invalidEmails: { id: 'SubcontractorEdit.invalidEmail', defaultMessage: 'Some emails are invalid.' }
});

export class SubcontractorEdit {
    public id: number = 0;
    public name: string | undefined;
    public region: string | undefined;
    public emails: string[] = [];
    public preferredLanguage: PreferredLanguage | undefined;

    private readonly emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    public constructor(init?: Partial<SubcontractorEdit>) {
        Object.assign(this, init);
    }

    public validate(): ValidationResult {
        let result = new ValidationResult();

        if (!StringHelper.hasValue(this.name)) {
            result.isValid = false;
            result.errorFields.push(nameof<SubcontractorEdit>('name'));
            result.errorMessages.push(m.nameRequired);
        }

        const invalidEmails = this.emails.map(x => !this.emailRegex.test(x) ? x : undefined).filter(x => x != null);
        if (invalidEmails.length > 0) {
            result.isValid = false;
            result.errorFields.push(nameof<SubcontractorEdit>('emails'));
            result.errorMessages.push(m.invalidEmails);
        }

        return result;
    }
}