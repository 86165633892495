import { defineMessages } from "@formatjs/intl";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { SectionHeader } from "../../../components/common";
import { OrderInformationForm } from "./components/OrderInformationForm";
import { Grid } from "semantic-ui-react";
import { Bill, Client } from "../../../state/models";
import { StepsNavigationDivider } from "./components/StepsNavigationDivider";
import { useHistory } from "react-router";
import { ServiceCallEdit } from "../models";
import * as billsApi from '../../bills/api';
import * as clientsApi from '../../clients/api';
import { FormValidationSummary } from "./components/FormValidationSummary";
import { ExistingServiceCallWarning } from "./components/ExistingServiceCallWarning";
import { BillCard } from "../../../components/cards";
import { useSelector } from "react-redux";
import { isCurrentUserAnEmployee } from "../../account/selectors";
import useApiErrorHandler from "../../../utils/ApiErrorHandler";

interface OwnProps {
}

export type FillOrderDetailsPageProps =
    & OwnProps;

const m = defineMessages({
    title: { id: 'OrderInformationForm.section_title', defaultMessage: 'Order information' },
    subtitle: { id: 'OrderInformationForm.section_subtitle', defaultMessage: 'Enter your Jaymar bill number to help us retrieve the information about your Jaymar product.' },
})

export const FillOrderDetailsPage: React.FC<FillOrderDetailsPageProps> = (props) => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const handleError = useApiErrorHandler();
    const { formState: { errors }, getValues, handleSubmit } = useFormContext<ServiceCallEdit>();

    const [billPreview, setBillPreview] = useState<Bill | undefined>(undefined);
    const [selectedBill, setSelectedBill] = useState<Bill | undefined>(undefined);
    const [isLoadingBill, setIsLoadingBill] = useState(false);
    const [selectedClient, setSelectedClient] = useState<Client | undefined>(undefined);
    const [isLoadingClient, setIsLoadingClient] = useState(false);
    const isJaymarEmployee = useSelector(isCurrentUserAnEmployee);

    const navigateToNextStep = isJaymarEmployee 
        ? () => history.push('/service-calls/create/customer')
        : () => history.push('/service-calls/create/client');

    const handleClientChange = (client: Client | undefined) => {
        setSelectedClient(client);
        setSelectedBill(undefined);
    }

    useEffect(() => {
        const billId = getValues('billId');
        if (billId != null) {
            setIsLoadingBill(true);

            billsApi.loadById(billId)
                .then(bill => {
                    setSelectedBill(bill);
                    setBillPreview(bill);
                })
                .catch((error) => handleError(error))
                .finally(() => setIsLoadingBill(false));
        }

        const clientId = getValues('clientId');
        if (isJaymarEmployee && clientId != null) {
            setIsLoadingClient(true);

            clientsApi.loadById(clientId)
                .then(client => setSelectedClient(client))
                .catch(error => handleError(error))
                .finally(() => setIsLoadingClient(false));
        }

    }, [getValues]);
    
    return (
        <>
            <SectionHeader title={formatMessage(m.title)} subtitle={formatMessage(m.subtitle)} />

            <Grid stackable columns={2}>
                <Grid.Column>
                    <FormValidationSummary errors={errors} style={{ maxWidth: '600px' }} />
                    <OrderInformationForm
                        selectedBill={selectedBill}
                        selectedClient={selectedBill?.client || selectedClient}
                        isLoadingBill={isLoadingBill}
                        onBillMouseOver={setBillPreview}
                        onBillSelected={setSelectedBill}
                        onClientSelected={handleClientChange}
                        style={{ maxWidth: '450px' }}
                    />
                </Grid.Column>

                <Grid.Column>
                    {billPreview != null &&
                        <>
                            <ExistingServiceCallWarning serviceCalls={billPreview.serviceCalls || []} />
                            <BillCard bill={billPreview} />
                        </>
                    }
                </Grid.Column>
            </Grid>

            <StepsNavigationDivider onNextStepClick={handleSubmit(navigateToNextStep)} />
        </>
    );
};