import { get, post, postVoid } from '../utils/api';
import { Notification } from '../state/models';

const endpoint = `/api/notifications`;

export class NotificationsService {
    public getUnread(): Promise<Notification[]> {
        return get<Notification[]>(`${endpoint}/unread`);
    }

    public getAll(): Promise<Notification[]> {
        return get<Notification[]>(endpoint);
    }

    public markAsRead(notificationId: number): Promise<void> {
        return postVoid(`${endpoint}/${notificationId}/read`, undefined);
    }

    public markAllAsRead(): Promise<void> {
        return postVoid(`${endpoint}/read-all`, undefined);
    }
}

export default NotificationsService;