import { ThunkAction } from 'redux-thunk';

import { ActionTypeKeys } from '../types';
import { ApplicationState } from '../..';
import * as globalNotification from '../../global-notification';
import { AgentWorkload, ActionsStatistics } from '../../../models';
import { StatisticsService } from '../../../../services/StatisticsService';
import { AppThunk } from '../../../store';

export type StatisticsActionTypes =
    | LoadAgentsWorkloadRequestAction | LoadAgentsWorkloadSuccessAction | LoadAgentsWorkloadFailureAction
    | LoadAgentNextActionsRequestAction | LoadAgentNextActionsSuccessAction | LoadAgentNextActionsFailureAction
    | LoadNewServiceCallsCountRequestAction | LoadNewServiceCallsCountSuccessAction | LoadNewServiceCallsCountFailureAction
    | { type: '' };

interface LoadAgentsWorkloadRequestAction { type: ActionTypeKeys.LOAD_AGENTS_WORKLOAD_REQUEST; }
interface LoadAgentsWorkloadSuccessAction { type: ActionTypeKeys.LOAD_AGENTS_WORKLOAD_SUCCESS; payload: AgentWorkload[]; }
interface LoadAgentsWorkloadFailureAction { type: ActionTypeKeys.LOAD_AGENTS_WORKLOAD_FAILURE; payload: string; }

interface LoadNewServiceCallsCountRequestAction { type: ActionTypeKeys.LOAD_NEW_COUNT_REQUEST; }
interface LoadNewServiceCallsCountSuccessAction { type: ActionTypeKeys.LOAD_NEW_COUNT_SUCCESS; payload: number; }
interface LoadNewServiceCallsCountFailureAction { type: ActionTypeKeys.LOAD_NEW_COUNT_FAILURE; payload: Error; }

interface LoadAgentNextActionsRequestAction { type: ActionTypeKeys.LOAD_AGENT_NEXT_ACTIONS_REQUEST; }
interface LoadAgentNextActionsSuccessAction { type: ActionTypeKeys.LOAD_AGENT_NEXT_ACTIONS_SUCCESS; payload: ActionsStatistics; }
interface LoadAgentNextActionsFailureAction { type: ActionTypeKeys.LOAD_AGENT_NEXT_ACTIONS_FAILURE; payload: string; }

const statisticsService = new StatisticsService();

export const statisticsActionCreators = {
    loadNewServiceCallsCount: (): AppThunk => {
        return (dispatch, getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_NEW_COUNT_REQUEST });

            statisticsService.getNew()
                .then(response => response.json() as number)
                .then(count => {
                    dispatch({ type: ActionTypeKeys.LOAD_NEW_COUNT_SUCCESS, payload: count });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_NEW_COUNT_FAILURE, payload: error });
                });
        };
    },

    loadAgentsWorkload: (): AppThunk => {
        return (dispatch, getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_AGENTS_WORKLOAD_REQUEST });

            statisticsService.getAgentsWorkload()
                .then(response => response.json() as AgentWorkload[])
                .then(workload => {
                    dispatch({ type: ActionTypeKeys.LOAD_AGENTS_WORKLOAD_SUCCESS, payload: workload });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_AGENTS_WORKLOAD_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de récupérer les statistiques sur la charge de travail (${error}).`)
                    );
                });
        };
    },

    loadAgentNextActions: (): AppThunk => {
        return (dispatch, getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_AGENT_NEXT_ACTIONS_REQUEST });

            statisticsService.getAgentNextActions()
                .then(response => response.json() as ActionsStatistics)
                .then(stats => {
                    dispatch({ type: ActionTypeKeys.LOAD_AGENT_NEXT_ACTIONS_SUCCESS, payload: stats });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_AGENT_NEXT_ACTIONS_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de récupérer les statistiques pour les prochaines actions de l'agent (${error}).`)
                    );
                });
        };
    },
}