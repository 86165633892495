import * as React from 'react';
import { InputProps, Input, InputOnChangeData } from 'semantic-ui-react';

export interface BasicNumericInputProps extends InputProps {
    value?: number;
    onNumberChange: (newValue: number) => void;
}

interface BasicNumericInputState {
    rawValue: string;
}

class BasicNumericInput extends React.Component<BasicNumericInputProps, BasicNumericInputState> {
    public constructor(props: BasicNumericInputProps) {
        super(props);

        this.state = { rawValue: String(props.value || '') };
    }

    public componentWillReceiveProps(props: BasicNumericInputProps) {
        this.setState({ rawValue: String(props.value || '') });
    }

    public render() {
        const { value, onNumberChange, intl, ...inputProps } = this.props;
        return (
            <Input
                {...inputProps}
                type="text" 
                pattern="\d*"
                disabled={this.props.disabled}
                value={this.state.rawValue} 
                onChange={this.handleChange}
                onBlur={this.confirmChange}
            />
        );
    }

    private handleChange = (_event: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) => {
        const newValue = Number(data.value);

        if (!isNaN(newValue)) {
            this.setState({ rawValue: data.value });
            this.props.onNumberChange(newValue);
        }
    }

    private confirmChange = () => {
        const newValue = Number(this.state.rawValue);

        if (!isNaN(newValue)) {
            this.props.onNumberChange(newValue);
        } else {
            this.setState({ rawValue: String(this.props.value || '') });
        }
    }
}

export { BasicNumericInput };