import React from 'react';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import { Card, Statistic } from 'semantic-ui-react';

export interface StatisticsCardProps {
    daysOpened: number;
    costOfCall: number;
}

const m = defineMessages({
    title: { id: 'StatisticsCard.title', defaultMessage: 'Service call statistics' },
    subtitle: { id: 'StatisticsCard.subtitle', defaultMessage: 'View a few statistics about the service call.' },
});

class StatisticsCard extends React.Component<StatisticsCardProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <Card fluid={true} color="teal" style={{ height: '100%' }}>
                <Card.Content style={{ flexGrow: 0 }}>
                    <Card.Header content={formatMessage(m.title)} />
                    <Card.Meta content={formatMessage(m.subtitle)} />
                </Card.Content>
                <Card.Content>
                    {/* <Statistic.Group size="tiny" widths={2}>
                        <Statistic value="3" label="jours ouverts" />
                        <Statistic value="380$" label="coût de l'appel" />
                    </Statistic.Group> */}
                </Card.Content>
            </Card>
        );
    }
}

const intlComponent = injectIntl(StatisticsCard);
export { intlComponent as StatisticsCard };