import React, { CSSProperties } from "react";
import { defineMessages, MessageDescriptor, useIntl } from "react-intl";
import { Dropdown, DropdownItemProps, DropdownProps } from "semantic-ui-react";
import { getStatusColor } from "..";
import { serviceCallMessages } from "../../../constants";
import { allServiceCallStatus, FilterOptions, ServiceCallStatus } from "../../../state/models";
import { FilterSection } from "./FilterSection";

interface OwnProps {
    filters: FilterOptions;
    onChange: (updatedFilters: Partial<FilterOptions>) => void;
    style?: CSSProperties;
}

export type ServiceCallStatusSectionProps =
    & OwnProps;

const statusMap: Map<ServiceCallStatus, MessageDescriptor> = new Map<ServiceCallStatus, MessageDescriptor>([
    [ServiceCallStatus.unknown, serviceCallMessages.unknown],
    [ServiceCallStatus.created, serviceCallMessages.new],
    [ServiceCallStatus.assignedToAgent, serviceCallMessages.open],
    [ServiceCallStatus.awaitingInspection, serviceCallMessages.inspection],
    [ServiceCallStatus.inspected, serviceCallMessages.inspected],
    [ServiceCallStatus.awaitingFactoryRepairs, serviceCallMessages.repairsInFactory],
    [ServiceCallStatus.awaitingSubcontractorRepairs, serviceCallMessages.repairsBySubcontractor],
    [ServiceCallStatus.awaitingTechnicianRepairs, serviceCallMessages.repairsByTechnician],
    [ServiceCallStatus.repaired, serviceCallMessages.repaired],
    [ServiceCallStatus.closed, serviceCallMessages.closed],
]);

const m = defineMessages({
    filterByStatus: { id: 'FilterPopup.filter_by_status', defaultMessage: 'Filter by status' },
    filterByStatusPlaceholder: { id: 'FilterPopup.filter_by_status_placeholder', defaultMessage: 'Select one or many status to filter' },
});

export const ServiceCallStatusSection: React.FC<ServiceCallStatusSectionProps> = (props) => {
    const { formatMessage } = useIntl();
    const statusDropdownOptions = allServiceCallStatus
        .filter(x => x !== ServiceCallStatus.closed)
        .map((x): DropdownItemProps => ({
            id: x,
            value: x,
            text: formatMessage(statusMap.get(x) || serviceCallMessages.unknown),
            label: {empty: true, circular: true, color: getStatusColor(x)}
        }));

    const changeStatus = (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => props.onChange(({ selectedServiceCallStatusFilter: data.value as ServiceCallStatus[] || [] }));
    const clearStatus = () => props.onChange(({ selectedServiceCallStatusFilter: [] }));

    return (
        <FilterSection title={formatMessage(m.filterByStatus)} onClear={clearStatus} style={props.style}>
            <Dropdown 
                fluid={true}
                options={statusDropdownOptions}
                selection={true}
                multiple={true}
                closeOnChange={true}
                placeholder={formatMessage(m.filterByStatusPlaceholder)}
                onChange={changeStatus}
                value={props.filters.selectedServiceCallStatusFilter}
            />
        </FilterSection>
    );
};