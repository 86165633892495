import { isCurrentUserASubcontractor } from 'modules/account/selectors';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Icon, List } from 'semantic-ui-react';
import { useSubcontractorRepairDetailsContext } from '../SubcontractorRepairDetailsProvider';
import { RepairMediaGallery } from './RepairMediaGallery';
import { UploadMediaModal } from './UploadMediaModal';

export const MediaSection = () => {
    const { formatMessage } = useIntl();

    const isSubcontractor = useSelector(isCurrentUserASubcontractor);
    const { repairDetails } = useSubcontractorRepairDetailsContext();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleAddMedia = () => {
        setIsModalOpen(true);
    };

    if (!repairDetails?.appointmentDate || !isSubcontractor) {
        return null;
    }

    return (
        <>
            <List.Item>
                <List.Content floated="right">
                    <Button
                        primary
                        fluid
                        content={formatMessage({
                            id: 'SubcontractorRepairPage.MediasSection.AddPictures',
                            defaultMessage: 'Add pictures',
                        })}
                        onClick={handleAddMedia}
                    />
                </List.Content>
                <Icon name="image" size="large" />
                <List.Content>
                    <List.Header as="h3" style={{ marginBottom: '1rem' }}>
                        {formatMessage({
                            id: 'SubcontractorRepairPage.MediasSection.Title',
                            defaultMessage: 'Pictures',
                        })}
                    </List.Header>
                    <RepairMediaGallery />
                </List.Content>
            </List.Item>
            <UploadMediaModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            />
        </>
    );
};
