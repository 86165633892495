import { User } from '.';

export enum AttachmentType {
    unknown,
    image,
    video,
    document,
    subcontractorBill,
    subcontractorRepairImage
}

export interface ServiceCallAttachment {
    id: number;
    serviceCallId: number;
    name: string;
    contentType: string;
    type: AttachmentType;
    createdBy: User;
    createdOn: Date;
    attachmentUri: string;
}