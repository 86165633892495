import _ from 'lodash';
import { User } from '../../../state/models';

export function searchUsers(users: User[], searchTerm: string) {
    const escapedSearchTerm = searchTerm.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
    const searchExpression = () => new RegExp(`(${escapedSearchTerm.trim()})`, 'gi');

    if (searchTerm == null || searchTerm === '') {
        return users;
    }

    const usersWithPropertiesMatchingSearch = users.filter(x => searchExpression().test(x.firstName) 
            || searchExpression().test(x.lastName) 
            || searchExpression().test(x.email) 
            || searchExpression().test(x.firstName + ' ' + x.lastName));

    const usersWithClientCodesMatchingSearch = users.filter(x => x.claims?.clientCodes?.find(clientCode => searchExpression().test(clientCode)) != null)

    return _.union(usersWithPropertiesMatchingSearch, usersWithClientCodesMatchingSearch);
}