import React, { useMemo } from "react";
import { Loader, Table } from "semantic-ui-react";
import deburr from "lodash/deburr";
import get from "lodash/get";
import orderBy from "lodash/orderBy";
import { GroupedDefectiveRatio } from "containers/reporting/types";
import { SortDirection } from "state/models";
import { TableHeaders } from "./TableHeaders";
import { useIntl } from "react-intl";
import { useDetailedStatisticsContext } from "../DetailedStatisticsProvider/DetailedStatisticProvider";
import { usePagination } from "containers/reporting/hooks/usePagination";
import { useColumnSorting } from "containers/reporting/hooks/useColumnSorting";

interface DefectiveRatiosTableProps {
  groupedDefectiveRatios: GroupedDefectiveRatio[];
  groupType: string;
  isLoading: boolean;
}

export const DefectiveRatiosTable: React.FC<DefectiveRatiosTableProps> = ({
  groupedDefectiveRatios: rows,
  groupType,
  isLoading,
}) => {
  const { formatNumber } = useIntl();

  const {
    defectiveRatios: { selectedGroupId, changeSelectedGroupId },
  } = useDetailedStatisticsContext();

  const { sortingColumn, sortingDirection, handleColumnSorting } =
    useColumnSorting<GroupedDefectiveRatio>("serviceCallPercent", SortDirection.Descending);

  const sortedRows = useMemo(
    () =>
      orderBy(
        rows,
        (row) => {
          const columnValue = get(row, sortingColumn);
          return typeof columnValue === "string" ? deburr(columnValue) : columnValue;
        },
        sortingDirection === SortDirection.Descending ? "desc" : "asc"
      ),
    [rows, sortingColumn, sortingDirection]
  );

  const { currentPageRows, Paginator } = usePagination(sortedRows);

  const selectGroup = (groupId: string | number) => {
    const newSelectedGroupId = selectedGroupId === groupId ? undefined : groupId;
    changeSelectedGroupId(newSelectedGroupId);
  };

  const loader = isLoading && (
    <Table.Row>
      <Table.Cell colSpan='4'>
        <div style={{ display: "flex", justifyContent: "center", margin: "1rem" }}>
          <Loader active inline />
        </div>
      </Table.Cell>
    </Table.Row>
  );

  const tableRows =
    !isLoading &&
    currentPageRows.map((row) => (
      <Table.Row
        key={row.groupId}
        onClick={() => selectGroup(row.groupId)}
        active={row.groupId === selectedGroupId}>
        <Table.Cell>{row.groupName}</Table.Cell>
        <Table.Cell textAlign='right' collapsing>
          {row.soldItemCount}
        </Table.Cell>
        <Table.Cell textAlign='right' collapsing>
          {row.serviceCallCount}
        </Table.Cell>
        <Table.Cell textAlign='right' collapsing>
          {formatNumber(row.serviceCallPercent / 100, {
            style: "percent",
            maximumFractionDigits: 1,
          })}
        </Table.Cell>
      </Table.Row>
    ));

  return (
    <>
      <Table sortable striped selectable size='small'>
        <TableHeaders
          groupType={groupType}
          sortingColumn={sortingColumn}
          sortingDirection={sortingDirection}
          onColumnSorting={handleColumnSorting}
        />
        <Table.Body>
          {loader}
          {tableRows}
        </Table.Body>
      </Table>
      {!isLoading && <Paginator />}
    </>
  );
};
