export interface Quantity {
    value: number;
    unit: string;
}

export const formatQuantity = (quantity: Quantity) => {
    if (!quantity) {
        return '';
    }

    if (!quantity.unit || quantity.unit === 'EA') {
        return quantity.value.toString();
    }

    return `${quantity.value} ${quantity.unit}`;
};