import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { defineMessages, useIntl } from "react-intl";
import { Button, Divider, Form, Message } from "semantic-ui-react";
import { commonMessages } from "../../../../../constants";
import { Client, User, UserAccountType } from "../../../../../state/models";
import { ClientEdit } from "../../models/ClientEdit";
import { AuthenticationPropertiesSection } from "./AuthenticationPropertiesSection";
import { ClientAccountsPropertiesSection } from "./ClientAccountsPropertiesSection";
import { ProfilePropertiesSection } from "./ProfilePropertiesSection";

interface OwnProps {
    user: User | undefined;
    clientAccounts: Client[];
    isLoading?: boolean;

    onCancel: () => void;
    onSave: (user: ClientEdit) => void;
    onDisable?: () => void;
}

export type ClientFormProps =
    & OwnProps;

const m = defineMessages({
    disableButton: { id: 'ClientForm.disable', defaultMessage: 'Deactivate' }
});

export const ClientForm: React.FC<ClientFormProps> = (props) => {
    const { formatMessage } = useIntl();
    const formProps = useForm<ClientEdit>();
    const { setValue } = formProps;
    const hasErrors = Object.keys(formProps.formState.errors).filter(x => (formProps.formState.errors as any)[x] != null).length > 0;
    const errorMessages = Object.keys(formProps.formState.errors).map(x => (formProps.formState.errors as any)[x]?.message);

    useEffect(() => {
        setValue('accountType', UserAccountType.client);

        if (props.user != null) {
            setValue('id', props.user.id);
            setValue('firstName', props.user.firstName);
            setValue('lastName', props.user.lastName);
            setValue('email', props.user.email);
            setValue('accountType', props.user.accountType);
            setValue('clientCodes', props.user.claims?.clientCodes);
        }
    }, [setValue, props.user]);

    return (
        <>
            <FormProvider {...formProps}>
                <Form loading={props.isLoading} error={hasErrors} style={{ maxWidth: 600 }}>
                    <Message error list={errorMessages} />

                    <ProfilePropertiesSection disabled={props.user?.isDisabled} />
                    <AuthenticationPropertiesSection disabled={props.user?.isDisabled} isNewUser={props.user == null} />
                    <ClientAccountsPropertiesSection 
                        disabled={props.user?.isDisabled} 
                        clientAccounts={props.clientAccounts}
                        areClientAccountsLoading={props.isLoading} 
                    />
                </Form>

                <Divider />
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ textAlign: 'left', flexGrow: 1}}>
                        {props.user 
                            && !props.user?.isDisabled 
                            && props.onDisable != null
                            && <Button negative content={formatMessage(m.disableButton)} onClick={props.onDisable} />}
                    </div>
                    <div style={{ textAlign: 'right' }}>
                        <Button content={props.user?.isDisabled ? formatMessage(commonMessages.back) : formatMessage(commonMessages.cancel)} onClick={props.onCancel} />
                        <Button primary disabled={props.user?.isDisabled || props.isLoading} content={formatMessage(commonMessages.save)} onClick={formProps.handleSubmit(props.onSave)} />
                    </div>
                </div>
            </FormProvider>
        </>
    );
};