import { Subcontractor, SubcontractorBill } from '.';

export enum SubcontractorRepairWarrantyStatus {
    Unknown = 0,
    OutOfWarranty = 1,
    UnderWarranty = 2
}
export interface SubcontractorRepair {
    id: number;
    serviceCallId: number;
    requestedOn: Date;
    subcontractorId?: number;
    subcontractor?: Subcontractor;
    otherSubcontractorName?: string;
    report: string | null;
    reportDate: string | null;
    subcontractorBill?: SubcontractorBill;
    appointmentDate?: Date;
    warrantyStatus: SubcontractorRepairWarrantyStatus;
}