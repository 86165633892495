import React from 'react';
import _ from 'lodash';

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps, defineMessages, MessageDescriptor } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

import * as ReportingActions from '../../state/ducks/reporting';
import * as SearchActions from '../../state/ducks/search';

import { ApplicationState } from '../../state/ducks';
import { PageHeader } from '../../components/common';
import { PercentVariance, StatisticsFiltersPopup, StatisticTile, NumberWithDelta, DataTable, DataRow, HorizontalBarGraph } from '../../components/reporting';
import { ReportingResultVariation, StatisticsFilters, getReportingDateRange, allCountries, LabeledValue } from '../../state/models';
import { defectTypeMessages, commonMessages } from '../../constants';
import { CumulativePercentage } from '../../components/reporting/CumulativePercentage';

interface StatisticsPageActions {
    reportingActions: typeof ReportingActions.actionCreators;
    searchActions: typeof SearchActions.actionCreators;
}

interface StatisticsPageOwnProps {
    filters: StatisticsFilters;
    overdueCallCount: number;
    stalledUnassignedCallCount: number;
    callsCreated: ReportingResultVariation<number>;
    callsClosed: ReportingResultVariation<number>;
    callsActive: ReportingResultVariation<number>;
    averageResolutionTime: ReportingResultVariation<number>;
    problemsFrequency: ReportingResultVariation<LabeledValue<number>[]>;
    callsRepairsDistribution: ReportingResultVariation<LabeledValue<number>[]>;
    defectiveProductsFrequency: ReportingResultVariation<LabeledValue<number>[]>;
    defectiveCoversFrequency: ReportingResultVariation<LabeledValue<number>[]>;
    isOverdueCallCountLoading: boolean;
    isStalledUnassignedCallCountLoading: boolean;
    areCallsCreatedLoading: boolean;
    areCallsClosedLoading: boolean;
    areCallsActiveLoading: boolean;    
    isAverageResolutionTimeLoading: boolean;
    isProblemsFrequencyLoading: boolean;
    isCallsRepairsDistributionLoading: boolean;
    isDefectiveProductsFrequencyLoading: boolean;
    isDefectiveCoversFrequencyLoading: boolean;
}

export type StatisticsPageProps =
    & StatisticsPageOwnProps
    & StatisticsPageActions
    & RouteComponentProps<{}>
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'StatisticsPage.title', defaultMessage: 'Statistics' },
    subtitle: { id: 'StatisticsPage.subtitle', defaultMessage: 'View some statistics to determine the health of the after-sales service.' },
    overdueCallCountLabel: { id: 'StatisticsPage.overdue_call_count_label', defaultMessage: 'Overdue service calls' },
    overdueCallCountTooltip: { id: 'StatisticsPage.overdue_call_count_tooltip', defaultMessage: 'The number of service calls open for 30 business days or more' },
    stalledUnassignedCallCountLabel: { id: 'StatisticsPage.unassigned_call_count_label', defaultMessage: 'Unassigned calls' },
    stalledUnassignedCallCountTooltip: { id: 'StatisticsPage.unassigned_call_count_tooltip', defaultMessage: 'The number of calls unassigned since 48 hours or more' },
    callsCreatedLabel: { id: 'StatisticsPage.calls_created_label', defaultMessage: 'Calls created' },
    callsCreatedTooltip: { id: 'StatisticsPage.calls_created_tooltip', defaultMessage: 'The number of calls that were created during the selected time frame.' },
    callsClosedLabel: { id: 'StatisticsPage.calls_closed_label', defaultMessage: 'Calls closed' },
    callsClosedTooltip: { id: 'StatisticsPage.calls_closed_tooltip', defaultMessage: 'The number of calls that were closed during the selected time frame.' },
    callsActiveLabel: { id: 'StatisticsPage.calls_active_label', defaultMessage: 'Calls active' },
    callsActiveTooltip: { id: 'StatisticsPage.calls_active_tooltip', defaultMessage: 'The number of calls that remained unresolved during the selected time frame.' },
    resolutionTimeLabel: { id: 'StatisticsPage.resolution_time_label', defaultMessage: 'Resolution time' },
    resolutionTimeUnit: { id: 'StatisticsPage.resolution_time_unit', defaultMessage: 'days' },
    resolutionTimeTooltip: { id: 'StatisticsPage.resolution_time_tooltip', defaultMessage: 'The average delay in days between when the call is created and when it is resolved.' },
    problemsFrequencyLabel: { id: 'StatisticsPage.problems_frequency_label', defaultMessage: 'Problems frequency' },
    problemsFrequencyTooltip: { id: 'StatisticsPage.problems_frequency_tooltip', defaultMessage: 'The most frequent problems for the service calls that were created during the selected time frame and the number of calls impacted.' },
    defectiveProductsFrequencyLabel: { id: 'StatisticsPage.defective_products_frequency_label', defaultMessage: 'Defective products' },
    defectiveProductsFrequencyTooltip: { id: 'StatisticsPage.defective_products_frequency_tooltip', defaultMessage: 'The products with the most number of problems for the service calls that were created during the selected time frame.' },
    defectiveCoversFrequencyLabel: { id: 'StatisticsPage.defective_covers_frequency_label', defaultMessage: 'Defective covers' },
    defectiveCoversFrequencyTooltip: { id: 'StatisticsPage.defective_covers_frequency_tooltip', defaultMessage: 'The covers with the most number of problems for the service calls that were created during the selected time frame.' },
    repairTypesDistributionLabel: { id: 'StatisticsPage.repair_types_distribution_label', defaultMessage: 'Repair types distribution' },
    repairTypesDistributionLegend: { id: 'StatisticsPage.repair_types_distribution_legend', defaultMessage: 'Service calls' },
    repairTypesDistributionTooltip: { id: 'StatisticsPage.repair_types_distribution_tooltip', defaultMessage: 'The number of service calls that were created during the selected time frame for each type of repairs.' },
    repairedExternally: { id: 'StatisticsPage.repaired_externally', defaultMessage: 'External' },
    repairedInFactory: { id: 'StatisticsPage.repaired_in_factory', defaultMessage: 'Factory' },
    other: { id: 'StatisticsPage.other', defaultMessage: 'Other' },
    partsOrdered: { id: 'StatisticsPage.parts_ordered', defaultMessage: 'Parts' },
    repairedLocally: { id: 'StatisticsPage.repaired_locally', defaultMessage: 'Local' },
    inspected: { id: 'StatisticsPage.inspected', defaultMessage: 'Inspected' },
    replaced: { id: 'StatisticsPage.replaced', defaultMessage: 'Replaced' },
    creditReturn: { id: 'StatisticsPage.credit_return', defaultMessage: 'Credit return' },
    repairedByTechnician: { id: 'StatisticsPage.repaired_by_technician', defaultMessage: 'On the road' },
    repairedBySubcontractor: { id: 'StatisticsPage.subcontractor', defaultMessage: 'Subcontractor' },
});

class StatisticsPage extends React.Component<StatisticsPageProps, {}> {
    private repairTypeMap: Map<string, MessageDescriptor> = new Map<string, MessageDescriptor>([
        ['RepairedExternally', m.repairedExternally],
        ['RepairedInFactory', m.repairedInFactory],
        ['Other', m.other],
        ['PartsOrdered', m.partsOrdered],
        ['RepairedLocally', m.repairedLocally],
        ['Inspected', m.inspected],
        ['Replaced', m.replaced],
        ['CreditReturn', m.creditReturn],
        ['RepairedByTechnician', m.repairedByTechnician],
        ['RepairedByClientSubcontractor', m.repairedBySubcontractor],
        ['RepairedBySubcontractor', m.repairedBySubcontractor],
        ['InspectionPerformed', m.inspected],
        ['RepairedAtFactory', m.repairedInFactory],
        ['CreditReturnReceived', m.creditReturn]
    ]);

    public componentDidMount() {
        this.loadStatistics(this.props.filters);
        this.props.reportingActions.loadCountriesFilter();
    }

    public render() {
        const { formatMessage } = this.props.intl;
        let cumulProblemsFrequency = 0;
        const totalProblemsFrequency = this.props.problemsFrequency.currentPeriod.map(x => x.value).reduce((total, x) => total + x, 0);

        const problemsFrequencyRows = this.props.problemsFrequency.currentPeriod.map((x): DataRow => {
            const previousValueObject = this.props.problemsFrequency.previousPeriod.find(y => y.label === x.label);
            const previousValue = previousValueObject ? previousValueObject.value : 0;

            const typeMessages = Object.keys(defectTypeMessages).map(key => defectTypeMessages[key]);
            const defectTypeMessage = typeMessages.find(y => y.id === x.label);
            const defectType = defectTypeMessage ? formatMessage(defectTypeMessage) : x.label;

            cumulProblemsFrequency += x.value;

            return {
                description: defectType,
                value: (
                    <React.Fragment>
                        <NumberWithDelta key={x.label} currentValue={x.value} previousValue={previousValue} />
                        <CumulativePercentage key={'cumul' + x.label} currentSum={cumulProblemsFrequency} totalSum={totalProblemsFrequency} />
                    </React.Fragment>
                )
            };
        });

        const defectiveProductsRows = this.props.defectiveProductsFrequency.currentPeriod.map((x): DataRow => {
            const previousValueObject = this.props.defectiveProductsFrequency.previousPeriod.find(y => y.label === x.label);
            const previousValue = previousValueObject ? previousValueObject.value : 0;

            return {
                description: x.label,
                value: <NumberWithDelta key={x.label} currentValue={x.value} previousValue={previousValue} />
            };
        });

        const defectiveCoversRows = this.props.defectiveCoversFrequency.currentPeriod.map((x): DataRow => {
            const previousValueObject = this.props.defectiveCoversFrequency.previousPeriod.find(y => y.label === x.label);
            const previousValue = previousValueObject ? previousValueObject.value : 0;

            return {
                description: x.label,
                value: <NumberWithDelta key={x.label} currentValue={x.value} previousValue={previousValue} />
            };
        });

        const repairsDistribution = this.props.callsRepairsDistribution.currentPeriod.map(x => ({
            label: formatMessage(this.repairTypeMap.get(x.label) || commonMessages.unknown),
            value: x.value
        }));

        const groupedRepairsDistribution = _(repairsDistribution)
            .groupBy(x => x.label)
            .map((value, key): LabeledValue<number> => ({
                label: key,
                value: _(value).sumBy(x => x.value)
            }))
            .orderBy(['value'], ['desc'])
            .value();

        return (
            <div id="statistics-page">
                <PageHeader
                    iconName="pie chart"
                    title={formatMessage(m.title)}
                    subtitle={formatMessage(m.subtitle)}
                />

                <div style={{ textAlign: 'right', marginBottom: '10px' }}>
                    <StatisticsFiltersPopup
                        filters={this.props.filters}
                        onApply={this.applyFilters}
                        onChangeCountry={this.loadProvinces}
                        onSearchForClient={this.searchClients}
                    />
                </div>

                <Grid>
                <Grid.Row>
                        <Grid.Column mobile={8} tablet={8} computer={5} largeScreen={4} widescreen={3}>
                            <StatisticTile
                                isLoading={this.props.isStalledUnassignedCallCountLoading}
                                value={this.props.stalledUnassignedCallCount}
                                label={formatMessage(m.stalledUnassignedCallCountLabel)}
                                labelTooltip={formatMessage(m.stalledUnassignedCallCountTooltip)}
                                color={this.props.stalledUnassignedCallCount ? 'red' : 'teal'}
                                onClick={this.navigateToStalledUnassignedCallsPage}
                            />                            
                        </Grid.Column>

                        <Grid.Column mobile={8} tablet={8} computer={5} largeScreen={4} widescreen={3}>
                            <StatisticTile
                                isLoading={this.props.isOverdueCallCountLoading}
                                value={this.props.overdueCallCount}
                                label={formatMessage(m.overdueCallCountLabel)}
                                labelTooltip={formatMessage(m.overdueCallCountTooltip)}
                                color={this.props.overdueCallCount ? 'red' : 'teal'}
                                onClick={this.navigateToOverdueCallsPage}
                            />                            
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column mobile={8} tablet={8} computer={5} largeScreen={4} widescreen={3}>
                            <StatisticTile
                                isLoading={this.props.areCallsCreatedLoading}
                                value={this.props.callsCreated.currentPeriod}
                                color="teal"
                                label={formatMessage(m.callsCreatedLabel)}
                                labelTooltip={formatMessage(m.callsCreatedTooltip)}
                            >
                                <PercentVariance
                                    currentValue={this.props.callsCreated.currentPeriod}
                                    previousValue={this.props.callsCreated.previousPeriod}
                                    isIncreaseGood={false}
                                />
                            </StatisticTile>
                        </Grid.Column>

                        <Grid.Column mobile={8} tablet={8} computer={5} largeScreen={4} widescreen={3}>
                            <StatisticTile
                                isLoading={this.props.areCallsClosedLoading}
                                value={this.props.callsClosed.currentPeriod}
                                label={formatMessage(m.callsClosedLabel)}
                                labelTooltip={formatMessage(m.callsClosedTooltip)}
                                color="teal"
                            >
                                <PercentVariance
                                    currentValue={this.props.callsClosed.currentPeriod}
                                    previousValue={this.props.callsClosed.previousPeriod}
                                    isIncreaseGood={true}
                                />
                            </StatisticTile>
                        </Grid.Column>

                        <Grid.Column mobile={8} tablet={8} computer={5} largeScreen={4} widescreen={3}>
                            <StatisticTile
                                isLoading={this.props.areCallsActiveLoading}
                                value={this.props.callsActive.currentPeriod}
                                label={formatMessage(m.callsActiveLabel)}
                                labelTooltip={formatMessage(m.callsActiveTooltip)}
                                color="teal"
                            >
                                <PercentVariance
                                    currentValue={this.props.callsActive.currentPeriod}
                                    previousValue={this.props.callsActive.previousPeriod}
                                    isIncreaseGood={false}
                                />
                            </StatisticTile>
                        </Grid.Column>

                        <Grid.Column mobile={8} tablet={8} computer={5} largeScreen={4} widescreen={3}>
                            <StatisticTile
                                isLoading={this.props.isAverageResolutionTimeLoading}
                                value={Math.round(this.props.averageResolutionTime.currentPeriod)}
                                unitLabel={formatMessage(m.resolutionTimeUnit)}
                                label={formatMessage(m.resolutionTimeLabel)}
                                labelTooltip={formatMessage(m.resolutionTimeTooltip)}
                                color="teal"
                            >
                                <PercentVariance
                                    currentValue={this.props.averageResolutionTime.currentPeriod}
                                    previousValue={this.props.averageResolutionTime.previousPeriod}
                                    isIncreaseGood={false}
                                />
                            </StatisticTile>
                        </Grid.Column>
                    </Grid.Row>
                    
                    <Grid.Row>
                        <Grid.Column mobile={16} tablet={16} computer={8} largeScreen={8} widescreen={8}>
                            <DataTable
                                label={formatMessage(m.problemsFrequencyLabel)}
                                labelTooltip={formatMessage(m.problemsFrequencyTooltip)}
                                isLoading={this.props.isProblemsFrequencyLoading}
                                rows={problemsFrequencyRows}
                            />
                        </Grid.Column>

                        <Grid.Column mobile={16} tablet={16} computer={8} largeScreen={8} widescreen={8}>
                            <HorizontalBarGraph
                                title={formatMessage(m.repairTypesDistributionLegend)}
                                label={formatMessage(m.repairTypesDistributionLabel)}
                                labelTooltip={formatMessage(m.repairTypesDistributionTooltip)}
                                labels={groupedRepairsDistribution.map(x => x.label)}
                                values={groupedRepairsDistribution.map(x => x.value)}
                                isLoading={this.props.isCallsRepairsDistributionLoading}
                            />
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column mobile={16} tablet={16} computer={8} largeScreen={8} widescreen={8}>
                            <DataTable
                                label={formatMessage(m.defectiveProductsFrequencyLabel)}
                                labelTooltip={formatMessage(m.defectiveProductsFrequencyTooltip)}
                                isLoading={this.props.isDefectiveProductsFrequencyLoading}
                                rows={defectiveProductsRows}
                            />
                        </Grid.Column>

                        <Grid.Column mobile={16} tablet={16} computer={8} largeScreen={8} widescreen={8}>
                            <DataTable
                                label={formatMessage(m.defectiveCoversFrequencyLabel)}
                                labelTooltip={formatMessage(m.defectiveCoversFrequencyTooltip)}
                                isLoading={this.props.isDefectiveCoversFrequencyLoading}
                                rows={defectiveCoversRows}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }

    private navigateToOverdueCallsPage = () => {
        this.props.history.push(`/service-calls/overdue`);
    }

    private navigateToStalledUnassignedCallsPage = () => {
        this.props.history.push(`/service-calls/stalled-unassigned`);
    }

    private applyFilters = (filters: StatisticsFilters) => {
        this.props.reportingActions.setStatisticsFilters(filters);
        this.loadStatistics(filters);
    }

    private loadProvinces = (countryCode: string) => {
        if (countryCode !== allCountries) {
            this.props.reportingActions.loadProvincesFilter(countryCode);
        }
    }

    private searchClients = (query: string) => {
        this.props.searchActions.findClient(query);
    }
    
    private loadStatistics(filters: StatisticsFilters) {
        const dateRange = getReportingDateRange(filters.dateFilter);
        
        this.props.reportingActions.loadOverdueCallCount();
        this.props.reportingActions.loadStalledUnassignedCallCount();
        this.props.reportingActions.loadCallsCreated(dateRange.startDate, dateRange.endDate, filters);
        this.props.reportingActions.loadCallsClosed(dateRange.startDate, dateRange.endDate, filters);
        this.props.reportingActions.loadCallsActive(dateRange.startDate, dateRange.endDate, filters);
        this.props.reportingActions.loadCallsRepairsDistribution(dateRange.startDate, dateRange.endDate, filters);
        this.props.reportingActions.loadAverageResolutionTime(dateRange.startDate, dateRange.endDate, filters);
        this.props.reportingActions.loadProblemsFrequency(dateRange.startDate, dateRange.endDate, filters);
        this.props.reportingActions.loadDefectiveProductsFrequency(dateRange.startDate, dateRange.endDate, filters);
        this.props.reportingActions.loadDefectiveCoversFrequency(dateRange.startDate, dateRange.endDate, filters);
    }
}

const mapStateToProps = (state: ApplicationState): StatisticsPageOwnProps => {
    return {
        filters: state.reporting.statisticsFilters,
        overdueCallCount: state.reporting.overdueCallCount,
        stalledUnassignedCallCount: state.reporting.stalledUnassignedCallCount,
        callsCreated: state.reporting.callsCreated,
        callsClosed: state.reporting.callsClosed,
        callsActive: state.reporting.callsActive,
        averageResolutionTime: state.reporting.averageResolutionTime,
        problemsFrequency: state.reporting.problemsFrequency,
        callsRepairsDistribution: state.reporting.callsRepairsDistribution,
        defectiveProductsFrequency: state.reporting.defectiveProductsFrequency,
        defectiveCoversFrequency: state.reporting.defectiveCoversFrequency,
        isOverdueCallCountLoading: state.reporting.isOverdueCallCountLoading,
        isStalledUnassignedCallCountLoading: state.reporting.isStalledUnassignedCallCountLoading,
        areCallsCreatedLoading: state.reporting.areCallsCreatedLoading,
        areCallsClosedLoading: state.reporting.areCallsClosedLoading,
        areCallsActiveLoading: state.reporting.areCallsActiveLoading,
        isAverageResolutionTimeLoading: state.reporting.isAverageResolutionTimeLoading,
        isProblemsFrequencyLoading: state.reporting.isProblemsFrequencyLoading,
        isCallsRepairsDistributionLoading: state.reporting.isCallsRepairsDistributionLoading,
        isDefectiveProductsFrequencyLoading: state.reporting.isDefectiveProductsFrequencyLoading,
        isDefectiveCoversFrequencyLoading: state.reporting.isDefectiveCoversFrequencyLoading
    };
};

const mapDispatchToProps = (dispatch: Dispatch): StatisticsPageActions => {
    return {
        reportingActions: bindActionCreators(ReportingActions.actionCreators, dispatch),
        searchActions: bindActionCreators(SearchActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(StatisticsPage);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as StatisticsPage };