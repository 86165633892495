import { Reducer } from 'redux';
import { ActionTypes } from './actions';
import { ActionTypeKeys, I18nState } from './types';

const initialState: I18nState = {
    locale: 'fr'
};

export const i18nReducer: Reducer<I18nState> = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case ActionTypeKeys.CHANGE_LOCALE: {
            return { 
                ...state, 
                locale: action.payload 
            };
        }

        default:
            return state;
    }
};

export default i18nReducer;