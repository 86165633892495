import moment from 'moment';

import { AppointmentTimeRange } from '../state/models';
import { BaseService } from './baseService';

export class InspectionsService extends BaseService {
    constructor() {
        super('api/inspections');
    }

    public getById(inspectionId: number): Promise<any> {
        return this.get(`/${inspectionId}`);
    }

    public getBetween(startDate: Date, endDate: Date): Promise<any> {
        return this.get(`/between/${startDate.toISOString()}/${endDate.toISOString()}`);
    }

    public getUpcoming(): Promise<any> {
        return this.get('/upcoming');
    }

    public getUnscheduled(): Promise<any> {
        return this.get('/unscheduled');
    }

    public getCompleted(): Promise<any> {
        return this.get('/completed');
    }

    public schedule(inspectionId: number, scheduledForDate?: Date, scheduledForTime?: AppointmentTimeRange) {
        // HACK: We're getting a string instead of a Date when setting the appointment time. Will need to investigate this
        // issue in more details later on. For the moment, the quick fix is to simply ensure we have a date object.
        const date = scheduledForDate ? moment(scheduledForDate).toDate() : null;
        return this.put(`/${inspectionId}/schedule/${date ? date.toISOString() : null}/${scheduledForTime || AppointmentTimeRange.unspecified}`, {});
    }

    public saveReport(inspectionId: number, report: string) {
        return this.put(`/${inspectionId}/save-report`, report);
    }

    public complete(inspectionId: number) {
        return this.put(`/${inspectionId}/complete`, {});
    }

    public close(inspectionId: number) {
        return this.put(`/${inspectionId}/close`, {});
    }
}

export default InspectionsService;