import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Feed, Popup, List } from 'semantic-ui-react';

import { SubcontractorScheduledUpdate } from '../../../state/models';

export interface SubcontractorScheduledActivityProps {
    activity: SubcontractorScheduledUpdate;
}

const m = defineMessages({
    summary: { id: 'SubcontractorScheduledActivity.summary', defaultMessage: 'A meeting is scheduled with the subcontractor {name} to repair the furniture.' },
    scheduledForFormat: { id: 'SubcontractorScheduledActivity.scheduledForFormat', defaultMessage: 'Scheduled for {date}' },
});

class SubcontractorScheduledActivity extends React.Component<SubcontractorScheduledActivityProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { activity } = this.props;

        if (activity == null || activity.repair == null) {
            return <Feed.Event />;
        }

        const subcontractorName = activity.repair.subcontractor ? activity.repair.subcontractor.name : activity.repair.otherSubcontractorName;

        return (
            <Feed.Event key={activity.id}>
                <Feed.Label icon="calendar" />
                <Feed.Content>
                    <Popup
                        trigger={<Feed.Date content={moment.utc(activity.createdOn).fromNow()} />}
                        content={moment.utc(activity.createdOn).format('LL')}
                    />

                    <Feed.Summary>
                        {formatMessage(m.summary, { name: subcontractorName })}
                    </Feed.Summary>

                    <Feed.Extra text={true}>
                        <List bulleted={true}>
                            {activity.repair.appointmentDate && <List.Item content={formatMessage(m.scheduledForFormat, { date: moment.utc(activity.repair.appointmentDate).format('LL') })} />}
                        </List>
                    </Feed.Extra>
                </Feed.Content>
            </Feed.Event>
        );
    }
}

const connectedComponent = injectIntl(SubcontractorScheduledActivity);
export { connectedComponent as SubcontractorScheduledActivity };