import moment from 'moment';
import React from 'react';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { Grid } from 'semantic-ui-react';

import { PageHeader, SectionHeader } from '../../../components/common';
import { StatisticTile } from '../../../components/reporting';
import { FactoryRepairsTable } from '../../../components/service-calls/factory';
import { ApplicationState } from '../../../state/ducks';
import * as FactoryRepairsActions from '../../../state/ducks/factory-repairs';
import { arePendingFactoryRepairsLoading, getPendingFactoryRepairs } from '../../../state/ducks/factory-repairs/selectors';
import { ServiceCallFactoryRepair } from '../../../state/models';

interface FactoryDashboardPageActions {
    factoryRepairsActions: typeof FactoryRepairsActions.actionCreators;
}

interface FactoryDashboardPageOwnProps {
    allRepairsCount: number;
    readyForRepairsCount: number;
    waitingForFurnitureCount: number;
    pendingRepairs: ServiceCallFactoryRepair[];
    hasMorePendingRepairs: boolean;
    pendingShipments: ServiceCallFactoryRepair[];
    hasMorePendingShipments: boolean;
    areRepairsLoading: boolean;
}

export type FactoryDashboardPageProps =
    & FactoryDashboardPageOwnProps
    & FactoryDashboardPageActions
    & RouteComponentProps<{}>
    & WrappedComponentProps;

const m = defineMessages({
    pageTitle: { id: 'FactoryDashboardPage.page_title', defaultMessage: 'Dashboard' },
    pageSubtitle: { id: 'FactoryDashboardPage.page_subtitle', defaultMessage: 'View a quick overview of the upcoming factory repairs.' },
    noRepairsTodayTitle: { id: 'FactoryDashboardPage.no_repairs_today_title', defaultMessage: 'No repairs scheduled' },
    noRepairsTodaySubtitle: { id: 'FactoryDashboardPage.no_repairs_today_subtitle', defaultMessage: 'There are no factory repairs scheduled for today.' },
    noRepairsThisWeekTitle: { id: 'FactoryDashboardPage.no_repairs_this_week_title', defaultMessage: 'No repairs scheduled' },
    noRepairsThisWeekSubtitle: { id: 'FactoryDashboardPage.no_repairs_this_week_subtitle', defaultMessage: 'There are no factory repairs scheduled for this week.' },
    pendingRepairs: { id: 'FactoryDashboardPage.pending_repairs', defaultMessage: 'Ready for repairs' },
    pendingFurnitureReception: { id: 'FactoryDashboardPage.pending_furniture_reception', defaultMessage: 'Waiting for furniture reception' },
    viewAllRepairs: { id: 'FactoryDashboardPage.view_all_repairs', defaultMessage: 'View all pending repairs' },
    quickOverviewTitle: { id: 'FactoryDashboardPage.quick_overview_title', defaultMessage: 'Quick overview in numbers' },
    totalRepairsLabel: { id: 'FactoryDashboardPage.total_repairs_label', defaultMessage: 'Total repairs' },
    readyForRepairsLabel: { id: 'FactoryDashboardPage.ready_for_repairs_label', defaultMessage: 'Ready for repairs' },
    waitingForFurnitureLabel: { id: 'FactoryDashboardPage.waiting_for_furniture_label', defaultMessage: 'Waiting for furniture' }
});

class FactoryDashboardPage extends React.Component<FactoryDashboardPageProps, {}> {
    public constructor(props: FactoryDashboardPageProps) {
        super(props);

        this.props.factoryRepairsActions.loadPendingRepairs();
    }

    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <PageHeader
                    iconName="home"
                    title={formatMessage(m.pageTitle)}
                    subtitle={formatMessage(m.pageSubtitle)}
                />
                <SectionHeader title={formatMessage(m.quickOverviewTitle)} />
                <Grid>
                    <Grid.Column mobile={8} tablet={4} computer={4} largeScreen={3} widescreen={2}>
                        <StatisticTile
                            isLoading={this.props.areRepairsLoading}
                            color="blue"
                            value={this.props.allRepairsCount}
                            label={formatMessage(m.totalRepairsLabel)}
                            onClick={() => this.navigateToPendingRepairsPage()}
                        />
                    </Grid.Column>
                    <Grid.Column mobile={8} tablet={4} computer={4} largeScreen={3} widescreen={2}>
                        <StatisticTile
                            isLoading={this.props.areRepairsLoading}
                            color="teal"
                            value={this.props.readyForRepairsCount}
                            label={formatMessage(m.readyForRepairsLabel)}
                            onClick={() => this.navigateToPendingRepairsPage()}
                        />
                    </Grid.Column>
                    <Grid.Column mobile={8} tablet={4} computer={4} largeScreen={3} widescreen={2}>
                        <StatisticTile
                            isLoading={this.props.areRepairsLoading}
                            color="orange"
                            value={this.props.waitingForFurnitureCount}
                            label={formatMessage(m.waitingForFurnitureLabel)}
                            onClick={() => this.navigateToPendingRepairsPage()}
                        />
                    </Grid.Column>
                    <Grid.Row>
                        <Grid.Column mobile={16} tablet={16} widescreen={8} largeScreen={8}>
                            <SectionHeader title={formatMessage(m.pendingRepairs)} />
                            <FactoryRepairsTable
                                repairs={this.props.pendingRepairs}
                                isLoading={this.props.areRepairsLoading}
                                emptyTitle={formatMessage(m.noRepairsTodayTitle)}
                                emptySubtitle={formatMessage(m.noRepairsTodaySubtitle)}
                                onViewDetails={this.navigateToFactoryPage}
                                isAgentHeaderHidden={true}
                                isRequestedHeaderHidden={true}
                                isCompletedHeaderHidden={true}
                                isViewButtonHidden={true}
                            />
                            {this.props.hasMorePendingRepairs &&
                                <div style={{ textAlign: 'right' }}>
                                    <Link to="/factory/my-repairs">{formatMessage(m.viewAllRepairs)}</Link>
                                </div>
                            }
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} widescreen={8} largeScreen={8}>
                            <SectionHeader title={formatMessage(m.pendingFurnitureReception)} />
                            <FactoryRepairsTable
                                repairs={this.props.pendingShipments}
                                isLoading={this.props.areRepairsLoading}
                                emptyTitle={formatMessage(m.noRepairsThisWeekTitle)}
                                emptySubtitle={formatMessage(m.noRepairsThisWeekSubtitle)}
                                onViewDetails={this.navigateToFactoryPage}
                                isAgentHeaderHidden={true}
                                isReceivedHeaderHidden={true}
                                isCompletedHeaderHidden={true}
                                isViewButtonHidden={true}
                            />
                            {this.props.hasMorePendingShipments &&
                                <div style={{ textAlign: 'right' }}>
                                    <Link to="/factory/my-repairs">{formatMessage(m.viewAllRepairs)}</Link>
                                </div>
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </React.Fragment>
        );
    }

    private navigateToFactoryPage = (repairs: ServiceCallFactoryRepair) => {
        this.props.history.push(`/service-calls/${repairs.serviceCallId}/factory-repairs/${repairs.id}/prepare`);
    }

    private navigateToPendingRepairsPage = () => {
        this.props.history.push('/factory/my-repairs');
    }
}

const mapStateToProps = (state: ApplicationState) => {
    const allPendingRepairs = getPendingFactoryRepairs(state);
    const readyForRepairs = allPendingRepairs.filter(x => x.receivedOn != null).sort(sortPendingRepairs);
    const waitingForFurniture = allPendingRepairs.filter(x => x.receivedOn == null).sort(sortByRequestedOn);

    return {
        allRepairsCount: allPendingRepairs.length,
        readyForRepairsCount: readyForRepairs.length,
        waitingForFurnitureCount: waitingForFurniture.length,
        pendingRepairs: readyForRepairs.slice(0, 10),
        hasMorePendingRepairs: readyForRepairs.length > 10,
        pendingShipments: waitingForFurniture.slice(0, 10),
        hasMorePendingShipments: waitingForFurniture.length > 10,
        areRepairsLoading: arePendingFactoryRepairsLoading(state)
    };
};

const mapDispatchToProps = (dispatch: Dispatch): FactoryDashboardPageActions => {
    return {
        factoryRepairsActions: bindActionCreators(FactoryRepairsActions.actionCreators, dispatch)
    };
};

function sortPendingRepairs(a: ServiceCallFactoryRepair, b: ServiceCallFactoryRepair): number {
    const aMoment = moment(a.scheduledFor || a.receivedOn);
    const bMoment = moment(b.scheduledFor || b.receivedOn);

    if (aMoment.isBefore(bMoment)) {
        return -1;
    }

    if (aMoment.isAfter(bMoment)) {
        return 1;
    }

    return 0;
}

function sortByRequestedOn(a: ServiceCallFactoryRepair, b: ServiceCallFactoryRepair): number {
    const aMoment = moment(a.requestedOn);
    const bMoment = moment(b.requestedOn);

    if (aMoment.isBefore(bMoment)) {
        return -1;
    }

    if (aMoment.isAfter(bMoment)) {
        return 1;
    }

    return 0;
}

const intlComponent = injectIntl(FactoryDashboardPage);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as FactoryDashboardPage };