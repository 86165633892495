import { NavigationItem } from "../../components/layouts/PageLayout/NavigationMenu";
import {
    administrationMenuSection,
    afterSalesMenuSection,
    calendarMenuItem,
    calendarsMenuSection,
    createServiceCallMenuItem,
    currentServiceCallsMenuItem,
    dashboardMenuItem,
    factoryCalendarMenuItem,
    myServiceCallsMenuItem,
    overdueServiceCallsMenuItem,
    reportsMenuSection,
    serviceCallsHistoryMenuItem,
    staleServiceCallsMenuItem,
    stalledUnassignedServiceCallsMenuItem,
    subcontractorsBillingMenuItem,
    techniciansCalendarMenuItem,
} from "./common";

export const customerServiceAgentNavigationItems = (
    activeCallsCount: number,
    stalledUnassignedServiceCallsCount: number,
    overdueCallsCount: number,
): NavigationItem[] => [
        dashboardMenuItem(),
        {
            ...afterSalesMenuSection(),
            children: [
                createServiceCallMenuItem(),
                myServiceCallsMenuItem(),
                currentServiceCallsMenuItem(activeCallsCount || undefined),
                stalledUnassignedServiceCallsMenuItem(stalledUnassignedServiceCallsCount || undefined),
                overdueServiceCallsMenuItem(overdueCallsCount || undefined),
                staleServiceCallsMenuItem(),
                serviceCallsHistoryMenuItem(),
            ],
        },
        {
            ...calendarsMenuSection(),
            children: [techniciansCalendarMenuItem(), factoryCalendarMenuItem()],
        },
        {
            ...reportsMenuSection(),
            children: [subcontractorsBillingMenuItem()],
        },
        {
            ...administrationMenuSection(),
            children: [calendarMenuItem()],
        },
    ];
