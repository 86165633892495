import React, { useState } from "react";
import * as queryString from 'query-string';
import { defineMessages, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Accordion, Form, Menu } from "semantic-ui-react";
import { getUserSession } from "../../../../modules/account/selectors";
import { StringHelper } from "../../../../state/utils";
import { SearchServiceCallControl } from "../../../after-sales";
import { CompanyLogo } from "../../../common";
import { AdvancedSearchModal, AdvancedSearchQuery } from "../../../service-calls/AdvancedSearchModal";
import { NavigationItem } from ".";
import { NavigationSection } from "./NavigationSection";
import { NavigationLink } from "./NavigationLink";
import { navigationSections } from "../../../../routes/navigation-items/common";

const m = defineMessages({
    advancedSearch: { id: 'NavigationMenu.advanced_search', defaultMessage: 'Advanced search' }
});

interface OwnProps {
    items: NavigationItem[];
}

export type NavigationMenuProps =
    & OwnProps;

export const NavigationMenu: React.FC<NavigationMenuProps> = (props) => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const currentUser = useSelector(getUserSession)?.user;

    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
    const [activeSections, setActiveSections] = useState([navigationSections.afterSalesService]);

    const handleSearch = (searchTerm: string) => {
        if (StringHelper.hasValue(searchTerm)) {
            history.push(`/service-calls/search/${searchTerm}/`);
        }
    }

    const handleAdvancedSearch = (query: AdvancedSearchQuery) => {
        const searchParams = queryString.stringify(query);
        history.push(`/service-calls/advanced-search?${searchParams}`);
        closeSearchModal();
    }

    const handleSectionClick = (index: string) => {
        const isAlreadyExpanded = activeSections.filter(x => x === index).length > 0;
        const updatedActiveSections = isAlreadyExpanded
            ? activeSections.filter(x => x !== index)
            : activeSections.concat(index);

        setActiveSections(updatedActiveSections);
    }

    const renderMenuItem = (item: NavigationItem): React.ReactNode => {
        if (item.children && item.children.length > 0) {
            const renderedChildren = item.children.map(renderMenuItem);

            return (
                <NavigationSection
                    key={item.key}
                    index={item.key}
                    title={formatMessage(item.title)}
                    icon={item.icon}
                    isActive={activeSections.filter(x => x === item.key).length > 0}
                    onClick={handleSectionClick}
                >
                    {renderedChildren}
                </NavigationSection>
            );
        }

        return (
            <NavigationLink
                key={item.key}
                exact={item.exact}
                to={item.to || ''}
                icon={item.icon}
                title={formatMessage(item.title)} 
                labelColor={item.labelColor}
                labelContent={item.labelContent}
            />
        );
    }

    const openSearchModal = () => setIsSearchModalOpen(true);
    const closeSearchModal = () => setIsSearchModalOpen(false);

    return (
        <div>
            <Accordion as={Menu} vertical={true} fixed="left" size="large" className="c-navigation-menu">
                <Menu.Item>
                    <CompanyLogo centered size="small" />
                </Menu.Item>
                <Menu.Item>
                    <Form>
                        <Form.Field>
                            <SearchServiceCallControl onSearch={handleSearch} />
                            <a style={{display: 'block', textAlign: 'right', cursor: 'pointer', marginTop: 5}} onClick={openSearchModal}>
                                {formatMessage(m.advancedSearch)}
                            </a>
                            <AdvancedSearchModal 
                                currentUser={currentUser}
                                size="tiny"
                                onSearch={handleAdvancedSearch}
                                onCancel={closeSearchModal}
                                onClose={closeSearchModal}
                                open={isSearchModalOpen}
                            />
                        </Form.Field>
                    </Form>
                </Menu.Item>

                {props.items.map(renderMenuItem)}
            </Accordion>
        </div>
    );
};