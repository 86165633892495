import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { actionCreators as InspectionsActions } from '../../../state/ducks/inspections';

import { PageHeader } from '../../../components/common';
import { InspectionTable } from '../../../components/service-calls/inspection';
import { ServiceCallInspection } from '../../../state/models';
import { getUpcomingInspections, areUpcomingInspectionsLoading } from '../../../state/ducks/inspections/selectors';
import { useResponsive } from '../../../utils/responsive';

const m = defineMessages({
    title: { id: 'RepresentativeAppointmentsPage.title', defaultMessage: 'Upcoming appointments' },
    subtitle: { id: 'RepresentativeAppointmentsPage.subtitle', defaultMessage: 'View your upcoming appointments for the service call inspections.' },
    noUpcomingInspectionsTitle: { id: 'RepresentativeAppointmentsPage.no_scheduled_inspections_title', defaultMessage: 'No upcoming inspections' },
    noUpcomingInspectionsSubtitle: { id: 'RepresentativeAppointmentsPage.no_upcoming_inspections_subtitle', defaultMessage: 'There are no upcoming inspections scheduled.' },
});

export const RepresentativeAppointmentsPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const { isMobile } = useResponsive();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(InspectionsActions.loadUpcoming());
    }, [dispatch]);

    const inspections = useSelector(getUpcomingInspections);
    const isLoading = useSelector(areUpcomingInspectionsLoading);

    const navigateToInspectionPage = (inspection: ServiceCallInspection) => {
        history.push(`/service-calls/${inspection.serviceCallId}/inspections/${inspection.id}/inspect`);
    }

    const commonProps = {
        inspections: inspections,
        isLoading: isLoading,
        emptyTitle: formatMessage(m.noUpcomingInspectionsTitle),
        emptySubtitle: formatMessage(m.noUpcomingInspectionsTitle),
        isRequestedOnHidden: true,
        isCompletedOnHidden: true,
        onViewDetails: navigateToInspectionPage
    };

    return (
        <React.Fragment>
            <PageHeader
                iconName="car"
                title={formatMessage(m.title)}
                subtitle={formatMessage(m.subtitle)}
            />

            {isMobile && <InspectionTable {...commonProps} isViewButtonHidden={true} /> }
            {!isMobile && <InspectionTable {...commonProps} /> }
        </React.Fragment>
    );
};