import { defineMessages } from "@formatjs/intl";
import React from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router";
import { Menu, Tab, TabProps } from "semantic-ui-react";

interface OwnProps {
}

export type UsersNavigationTabProps =
    & OwnProps;

const m = defineMessages({
    clientsTabTitle: { id: 'ManageUsersPage.clientsTabTitle', defaultMessage: 'Clients' },
    employeesTabTitle: { id: 'ManageUsersPage.employeesTabTitle', defaultMessage: 'Employees' },
});

const tabIndexMax = new Map<number, string>([
    [0, "clients"],
    [1, "employees"],
    [2, "disabled-accounts"]
]);

export const UsersNavigationTab: React.FC<UsersNavigationTabProps> = (props) => {
    const { formatMessage } = useIntl();
    const location = useLocation();
    const history = useHistory();

    const createTabMenuItem = (index: number, id: string, title: string) => (
        <Menu.Item key={id} index={index} active={location.pathname.endsWith(id)}>
            {title}
        </Menu.Item>
    );

    const changeTab = (event: React.MouseEvent<HTMLDivElement>, data: TabProps) => {
        history.push(`/administration/${tabIndexMax.get(Number(data.activeIndex)) || ''}`);
    }

    return (
        <Tab
            menu={{ secondary: true, pointing: true }}
            activeIndex={location.hash}
            panes={[
                { menuItem: createTabMenuItem(0, tabIndexMax.get(0) || '', formatMessage(m.clientsTabTitle)) },
                { menuItem: createTabMenuItem(1, tabIndexMax.get(1) || '', formatMessage(m.employeesTabTitle)) },
            ]}
            onTabChange={changeTab}
        />
    );
};