import { Reducer } from 'redux';
import { NotesState } from './types';
import { ActionTypes, ActionTypeKeys } from './actions';
import _ from 'lodash';

const initialState: NotesState = {
    byId: {},
    byServiceCallId: {},
    allIds: [],
    isLoading: false,
    isSaving: false,
};

const notesReducer: Reducer<NotesState> = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case ActionTypeKeys.ADD_NOTE_REQUEST: {
            return {
                ...state,
                isSaving: true
            };
        }

        case ActionTypeKeys.ADD_NOTE_SUCCESS: {
            const note = ({ [action.payload.note.id]: action.payload.note });
            return {
                ...state,
                byId: _.merge({}, state.byId, note),
                byServiceCallId: {
                    ...state.byServiceCallId,
                    [action.payload.serviceCallId]: _.union([action.payload.note.id], state.byServiceCallId[action.payload.serviceCallId])
                },
                allIds: _.union(state.allIds, [action.payload.note.id]),
                isSaving: false
            };
        }

        case ActionTypeKeys.ADD_NOTE_FAILURE: {
            return {
                ...state,
                isSaving: false
            };
        }

        case ActionTypeKeys.CHANGE_VISIBILITY_REQUEST: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.noteId]: {
                        ...state.byId[action.payload.noteId],
                        visibility: action.payload.visibility
                    }
                }
            };
        }

        case ActionTypeKeys.DELETE_NOTE_REQUEST: {
            return {
                ...state,
                allIds: state.allIds.filter(x => x !== action.payload.noteId),
                byServiceCallId: {
                    ...state.byServiceCallId,
                    [action.payload.serviceCallId]: state.byServiceCallId[action.payload.serviceCallId].filter(x => x !== action.payload.noteId)
                }
            };
        }

        case ActionTypeKeys.LOAD_NOTES_REQUEST: {
            return {
                ...state,
                isLoading: true
            };
        }

        case ActionTypeKeys.LOAD_NOTES_SUCCESS: {
            const notes = Object.assign({}, ...action.payload.notes.map(x => ({ [x.id]: x })));
            const noteIds = action.payload.notes.map(x => x.id);

            return {
                ...state,
                byId: _.merge({}, state.byId, notes),
                byServiceCallId: {
                    ...state.byServiceCallId,
                    [action.payload.serviceCallId]: noteIds
                },
                allIds: _.union(state.allIds, noteIds),
                isLoading: false
            };
        }

        case ActionTypeKeys.LOAD_NOTES_FAILURE: {
            return {
                ...state,
                isLoading: false
            };
        }

        default:
            return state;
    }
};

export { notesReducer };