import { Client } from '../state/models';
import { BaseService } from './baseService';

export class ClientsService extends BaseService {
    constructor() {
        super('api/clients');
    }

    public findClient(searchTerm: string, timestamp?: number): Promise<any> {
        if (timestamp) {
            return this.get(`/find/${searchTerm}?timestamp=${timestamp}`);
        }

        return this.get(`/find/${searchTerm}`);
    }
}

export default ClientsService;