import _ from "lodash";
import { Reducer, combineReducers } from 'redux';
import { ClientsActionTypes, LOAD_BY_ID_FAILURE, LOAD_BY_ID_REQUEST, LOAD_BY_ID_SUCCESS } from "./actions";
import { ClientsState } from "./types";

const initialState: ClientsState = {
    byId: {},
    allIds: [],
    loadingIds: [],
};

export const clientsReducer: Reducer<ClientsState> = (state = initialState, action: ClientsActionTypes) => {
    switch (action.type) {
        case LOAD_BY_ID_REQUEST: {
            return {
                ...state,
                loadingIds: _.union(state.loadingIds, [action.payload.clientId])
            };
        }

        case LOAD_BY_ID_SUCCESS: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.client.id]: action.payload.client
                },
                allIds: _.union(state.allIds, [action.payload.client.id]),
                loadingIds: state.loadingIds.filter(x => x !== action.payload.client.id)
            };
        }

        case LOAD_BY_ID_FAILURE: {
            return {
                ...state,
                loadingIds: state.loadingIds.filter(x => x !== action.payload.clientId)
            };
        }

        default:
            return state;
    }
};