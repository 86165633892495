import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { ApplicationState } from '../../../../state/ducks';
import { Subcontractor, ValidationResult } from '../../../../state/models';
import { getSubcontractorById, isSubcontractorLoading } from '../selectors';
import * as SubcontractorsActions from '../actions';
import { PageHeader, LoadingContainer, LoadingDimmer } from '../../../../components/common';
import { Container, Form, Input, Divider, Button } from 'semantic-ui-react';
import { SubcontractorForm } from './components/SubcontractorForm';
import { SubcontractorEdit } from './models';
import { commonMessages } from '../../../../constants';

interface DispatchProps {
    subcontractorsActions: typeof SubcontractorsActions.actionCreators;
}

interface StoreProps {
    subcontractor: Subcontractor | undefined;
    isLoading: boolean;
}

interface StateProps {
    subcontractor: SubcontractorEdit | undefined;
    validationResult: ValidationResult | undefined;
}

export type ModifySubcontractorPageProps =
    & StoreProps
    & DispatchProps
    & RouteComponentProps<{ subcontractorId: string }>
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'ModifySubcontractorPage.title', defaultMessage: '{name}' },
    subtitle: { id: 'ModifySubcontractorPage.subtitle', defaultMessage: 'Modify the properties of the subcontractor.' },
});

class ModifySubcontractorPage extends React.Component<ModifySubcontractorPageProps, StateProps> {
    public constructor(props: ModifySubcontractorPageProps) {
        super(props);

        this.state = {
            subcontractor: props.subcontractor ? new SubcontractorEdit(props.subcontractor) : undefined,
            validationResult: undefined
        };
    }

    public componentDidUpdate(prevProps: ModifySubcontractorPageProps) {
        if (prevProps.subcontractor == null && this.props.subcontractor != null) {
            this.setState({ subcontractor: new SubcontractorEdit(this.props.subcontractor) });
        }
    }

    public componentDidMount() {
        const subcontractorId = Number(this.props.match.params.subcontractorId);

        if (this.props.subcontractor == null && !this.props.isLoading) {
            this.props.subcontractorsActions.loadById(subcontractorId);
        }
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const subcontractorName = this.props.subcontractor ? this.props.subcontractor.name : '';

        return (
            <React.Fragment>
                <PageHeader iconName="id card" title={formatMessage(m.title, { name: subcontractorName })} subtitle={formatMessage(m.subtitle)} />
                <LoadingContainer>
                    <LoadingDimmer active={this.props.isLoading} />

                    {this.state.subcontractor &&
                        <SubcontractorForm
                            subcontractor={this.state.subcontractor}
                            validationResult={this.state.validationResult}
                            onChange={this.changeSubcontractor}
                        />
                    }

                    <Divider section={true} />
                    <div style={{ textAlign: 'right' }}>
                        <Button content={formatMessage(commonMessages.cancel)} onClick={this.cancelChanges} />
                        <Button primary={true} content={formatMessage(commonMessages.save)} onClick={this.saveChanges} />
                    </div>
                </LoadingContainer>
            </React.Fragment>
        );
    }

    private changeSubcontractor = (fieldId: string, fieldValue: string) => {
        this.setState((current) => ({
            subcontractor: new SubcontractorEdit({
                ...current.subcontractor,
                [fieldId]: fieldValue
            })
        }));
    }

    private cancelChanges = () => {
        this.props.history.push(`/administration/subcontractors`);
    }

    private saveChanges = () => {
        const subcontractorId = Number(this.props.match.params.subcontractorId);

        if (this.state.subcontractor) {
            const validationResult = this.state.subcontractor.validate();
            this.setState({ validationResult });

            if (validationResult.isValid) {
                this.props.subcontractorsActions.update(subcontractorId, this.state.subcontractor);
                this.props.history.push(`/administration/subcontractors`);
            }
        }
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: RouteComponentProps<{ subcontractorId: string }>): StoreProps => {
    const subcontractorId = Number(ownProps.match.params.subcontractorId);

    return {
        subcontractor: getSubcontractorById(state, subcontractorId),
        isLoading: isSubcontractorLoading(state, subcontractorId)
    };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    return {
        subcontractorsActions: bindActionCreators(SubcontractorsActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(ModifySubcontractorPage);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as ModifySubcontractorPage };