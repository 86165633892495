import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { List, Popup, PopupProps } from "semantic-ui-react";

const m = defineMessages({
    lengthRequirements: { id: 'PasswordInput.length_requirements', defaultMessage: 'Password must contain at least 8 characters.' },
    nonAlphaNumericRequirements: { id: 'PasswordInput.non_alphaNumeric_requirements', defaultMessage: 'Password must contain at least one special character (e.g. !@#$%^&*).' }
});

export const PasswordRequirementsPopup: React.FC<PopupProps> = (props) => {
    const { formatMessage } = useIntl();

    return (
        <Popup
            on="focus"
            flowing
            position="top left"
            {...props}
        >
            <List bulleted>
                <List.Item>{formatMessage(m.lengthRequirements)}</List.Item>
                <List.Item>{formatMessage(m.nonAlphaNumericRequirements)}</List.Item>
            </List>
        </Popup>
    );
};