import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Header, Icon, List } from 'semantic-ui-react';
import { commonMessages } from '../../../../../constants';
import { useSubcontractorRepairDetailsContext } from '../SubcontractorRepairDetailsProvider';
import { ReportModal } from './ReportModal';

export const ReportSection = () => {
    const { formatMessage } = useIntl();

    const { repairDetails } = useSubcontractorRepairDetailsContext();
    const hasReport = repairDetails?.report;

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleEditReport = () => {
        setIsModalOpen(true);
    };

    if (!repairDetails?.appointmentDate) {
        return null;
    }

    return (
        <>
            <List.Item>
                <List.Content floated="right">
                    {hasReport ? (
                        <Button
                            positive
                            fluid
                            icon="check"
                            content={formatMessage(commonMessages.modify)}
                            onClick={handleEditReport}
                        />
                    ) : (
                        <Button
                            primary
                            fluid
                            content={formatMessage({
                                id: 'SubcontractorRepairPage.ReportSection.EnterYourReport',
                                defaultMessage: 'Enter your report',
                            })}
                            onClick={handleEditReport}
                        />
                    )}
                </List.Content>
                <Icon name="file alternate" size="large" />
                <List.Content>
                    <List.Header as="h3" style={{ marginBottom: '1rem' }}>
                        {formatMessage({
                            id: 'SubcontractorRepairPage.ReportSection.Title',
                            defaultMessage: 'Repair report',
                        })}
                    </List.Header>
                    <Header as="h4">
                        {hasReport
                            ? <pre>{repairDetails.report}</pre>
                            : formatMessage({
                                id: 'SubcontractorRepairPage.ReportSection.NoReport',
                                defaultMessage: 'No report',
                            })}
                    </Header>
                </List.Content>
            </List.Item>
            <ReportModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            />
        </>
    );
};
