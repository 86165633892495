import React from 'react';
import { useIntl } from 'react-intl';
import { Dropdown, DropdownProps, DropdownItemProps } from 'semantic-ui-react';
import { countries } from '../../constants';

interface OwnProps {
}

export type CountriesDropdownProps =
    & DropdownProps
    & OwnProps;

export const CountriesDropdown: React.FC<CountriesDropdownProps> = (props) => {
    const { formatMessage } = useIntl();
    const countriesOptions: DropdownItemProps[] = countries.map(country => ({
        key: country.key,
        text: formatMessage(country.intlMessage),
        value: country.value,
        flag: country.flag,
    }));

    return (
        <Dropdown
            search
            selection
            lazyLoad
            options={countriesOptions}
            {...props}
        />
    );
};