import * as React from 'react';
import { Button, ButtonProps } from 'semantic-ui-react';

export interface SmallCancelButtonProps extends ButtonProps {
    onClick?: () => void;
}

export class SmallCancelButton extends React.Component<SmallCancelButtonProps, {}> {
    public render() {
        const { onClick, ...buttonProps } = this.props;

        return (
            <Button 
                basic={true} 
                icon="cancel"
                onClick={this.props.onClick} 
                {...buttonProps} 
            />
        );
    }
}

export default SmallCancelButton;