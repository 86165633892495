import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { ServiceCallFactoryRepair } from '../../../state/models';
import { Label, SemanticCOLORS, LabelProps, Popup } from 'semantic-ui-react';
import { commonMessages } from '../../../constants';

interface FactoryRepairStatusLabelOwnProps {
    repair: ServiceCallFactoryRepair;
}

export type FactoryRepairStatusLabelProps =
    & FactoryRepairStatusLabelOwnProps
    & WrappedComponentProps;

export enum FactoryRepairStatus {
    unknown,
    awaitingShipment,
    awaitingRepairs,
    repaired
}

const m = defineMessages({
    awatingShipment: { id: 'FactoryRepairStatusLabel.awaiting_shipment', defaultMessage: 'Waiting for the furniture shipment' },
    readyForRepairs: { id: 'FactoryRepairStatusLabel.awaiting_repairs', defaultMessage: 'Ready for repairs' },
    repaired: { id: 'FactoryRepairStatusLabel.repaired', defaultMessage: 'Repairs completed' },
});

class FactoryRepairStatusLabel extends React.Component<FactoryRepairStatusLabelProps, {}> {
    public render() {
        return (
            <Popup
                content={this.getLabelTooltip(this.props.repair)}
                trigger={this.renderLabel()}
            />
        );
    }

    private renderLabel() {
        return (
            <Label
                className="c-icon-label"
                {...this.getLabelProps(this.props.repair)}
                circular={true} 
            />
        );
    }

    private getStatus(repair: ServiceCallFactoryRepair): FactoryRepairStatus {
        if (this.props.repair == null) {
            return FactoryRepairStatus.unknown;
        }

        if (this.props.repair.receivedOn == null) {
            return FactoryRepairStatus.awaitingShipment;
        }

        if (this.props.repair.completedRepairsOn == null) {
            return FactoryRepairStatus.awaitingRepairs;
        }

        return FactoryRepairStatus.repaired;
    }

    private getLabelTooltip(repair: ServiceCallFactoryRepair): string {
        const { formatMessage } = this.props.intl;
        const status = this.getStatus(repair);

        switch (status) {
            case FactoryRepairStatus.awaitingShipment:
                return formatMessage(m.awatingShipment);

            case FactoryRepairStatus.awaitingRepairs:
                return formatMessage(m.readyForRepairs);

            case FactoryRepairStatus.repaired:
                return formatMessage(m.repaired);

            default:
                return formatMessage(commonMessages.unknown);
        }
    }

    private getLabelProps(repair: ServiceCallFactoryRepair): LabelProps {
        const status = this.getStatus(repair);

        switch (status) {
            case FactoryRepairStatus.awaitingShipment:
                return {
                    color: 'orange',
                    icon: 'truck'
                };

            case FactoryRepairStatus.awaitingRepairs:
                return {
                    color: 'teal',
                    icon: 'wrench'
                };

            case FactoryRepairStatus.repaired:
                return {
                    color: 'green',
                    icon: 'check'
                };

            default:
                return {
                    color: 'grey',
                    icon: 'question'
                };
        }
    }
}

const connectedComponent = injectIntl(FactoryRepairStatusLabel);
export { connectedComponent as FactoryRepairStatusLabel };