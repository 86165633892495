import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { Header, Icon, Table } from 'semantic-ui-react';

import { UserHelper } from '../../helpers';
import { CalendarBreak } from '../../state/models';
import { LoadingContainer, LoadingDimmer } from '../common';
import { CalendarControls } from './CalendarControls';
import { CalendarDayCell } from './CalendarDayCell';

interface MobileCalendarOwnProps {
    forDate: Date;
    events: MobileCalendarEvent[];
    lockedDates: CalendarBreak[];
    isLoading?: boolean;
    canSelectDate?: boolean;
    selectedDate?: Date;
    forUserId?: string;

    onMonthChanged: (date: Date) => void;
    onDateSelected?: (date?: Date) => void;
}

interface MobileCalendarState {
    selectedDate: Date | undefined;
}

export interface MobileCalendarEvent {
    on: Date;
    element: JSX.Element;
}

export type MobileMonthlyCalendarProps =
    & MobileCalendarOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    locked: { id: 'MobileCalendar.locked', defaultMessage: 'Locked' },
});

class MobileMonthlyCalendar extends React.Component<MobileMonthlyCalendarProps, MobileCalendarState> {
    public constructor(props: MobileMonthlyCalendarProps) {
        super(props);

        this.state = { selectedDate: props.selectedDate };
    }

    public componentWillReceiveProps(props: MobileMonthlyCalendarProps) {
        this.setState({ selectedDate: props.selectedDate });
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const firstDayOfMonth = moment(this.props.forDate).clone().startOf('month');
        const firstDayOfWeek = firstDayOfMonth.clone().startOf('isoWeek');

        const dayRows = _.range(5).map(x => (
            this.renderRow('row' + x, firstDayOfWeek.clone().add(x, 'weeks').toDate())
        ));

        return (
            <React.Fragment>
                <CalendarControls
                    for={this.props.forDate}
                    onPreviousMonth={() => this.props.onMonthChanged(firstDayOfMonth.clone().add(-1, 'month').toDate())}
                    onNextMonth={() => this.props.onMonthChanged(firstDayOfMonth.clone().add(1, 'month').toDate())}
                />

                <LoadingContainer>
                    <LoadingDimmer active={this.props.isLoading} style={{ verticalAlign: 'top' }} />

                    <Table className="c-calendar c-calendar--mobile" textAlign="center" stackable={true} celled={true} fixed={true}>
                        <Table.Body>
                            {dayRows}
                        </Table.Body>
                    </Table>
                </LoadingContainer>
            </React.Fragment>
        );
    }

    private renderRow(key: string, forDate: Date): JSX.Element {
        const { formatMessage } = this.props.intl;
        const days = _.range(6).map(x => {
            const dayMoment = moment(forDate).clone().add(x, 'days');
            const isPastDate = dayMoment.isBefore(moment(), 'day');
            const locks = this.props.lockedDates.filter(y => dayMoment.isSame(y.forDate, 'day'));
            const isLockedForEveryone = this.props.lockedDates.filter(y => dayMoment.isSame(y.forDate, 'day') && y.forUserId == null).length > 0;
            const isLockedForUser = this.props.lockedDates.filter(y => dayMoment.isSame(y.forDate, 'day') && y.forUserId === this.props.forUserId).length > 0;
            const isLocked = isLockedForEveryone || isLockedForUser;

            const events = this.props.events
                .filter(y => dayMoment.isSame(y.on, 'day'))
                .map(y => y.element);

            const hasEvents = events.length > 0;

            return (
                <CalendarDayCell
                    key={dayMoment.toISOString()}
                    selectable={this.props.canSelectDate && !isLockedForEveryone}
                    disabled={isPastDate}
                    locked={isLocked}
                    selected={this.state.selectedDate ? dayMoment.isSame(this.state.selectedDate, 'day') : false}
                    calendarBreak={locks && locks.length > 0 ? locks[0] : undefined}
                    day={dayMoment.toDate()}
                    style={{ height: '144px', position: 'relative' }}
                    onClick={() => this.handleDayClicked(dayMoment.toDate())}
                >
                    {hasEvents &&
                        <React.Fragment>{events}</React.Fragment>
                    }

                    {isLocked && !hasEvents &&
                        <Header className="c-calendar__lock" as="h4" icon={true} style={{ color: 'rgba(0,0,0,.25)', marginTop: '18px' }}>
                            <Icon name="lock" />
                            {locks[0].forUser != null
                                ? UserHelper.getDisplayName(locks[0].forUser)
                                : formatMessage(m.locked)}
                        </Header>
                    }
                </CalendarDayCell>
            );
        });

        return (
            <Table.Row key={key} verticalAlign="top">
                {days}
            </Table.Row>
        );
    }

    private handleDayClicked = (date: Date) => {
        const isLockedForEveryone = this.props.lockedDates.filter(y => moment(date).isSame(y.forDate, 'day') && y.forUserId == null).length > 0;
        const isLockedForUser = this.props.lockedDates.filter(y => moment(date).isSame(y.forDate, 'day') && y.forUserId === this.props.forUserId).length > 0;

        if (this.props.canSelectDate && !isLockedForEveryone && !isLockedForUser) {
            const isAlreadySelected = this.state.selectedDate
                ? moment(this.state.selectedDate).isSame(date, 'day')
                : false;

            this.setState({ selectedDate: isAlreadySelected ? undefined : date });

            if (this.props.onDateSelected) {
                this.props.onDateSelected(isAlreadySelected ? undefined : date);
            }
        }
    }
}

const connectedComponent = injectIntl(MobileMonthlyCalendar);
export { connectedComponent as MobileCalendar };