import React from 'react';
import moment from 'moment';

import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Modal, List } from 'semantic-ui-react';
import { ServiceCallFactoryRepair } from '../../../state/models';
import { DateHelper, UserHelper } from '../../../helpers';

interface FactoryReportModalOwnProps {
    trigger: React.ReactNode;
    factoryRepair: ServiceCallFactoryRepair;
}

export type FactoryReportModalProps =
    & FactoryReportModalOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'FactoryReportModal.title', defaultMessage: 'Factory report' },
    completedOn: { id: 'FactoryReportModal.completed_on', defaultMessage: 'Completed on' },
    labourTime: { id: 'FactoryReportModal.labour_time', defaultMessage: 'Labour time' },
    report: { id: 'FactoryReportModal.report', defaultMessage: 'Report details' },
    repairedBy: { id: 'FactoryReportModal.repaired_by', defaultMessage: 'Repaired by' }
});

class FactoryReportModal extends React.Component<FactoryReportModalProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const factoryReport = this.props.factoryRepair != null && this.props.factoryRepair.factoryReport != null
            ? this.props.factoryRepair.factoryReport
            : undefined;

        return (
            <Modal trigger={this.props.trigger} closeIcon={true} size="fullscreen">
                <Modal.Header>{formatMessage(m.title)}</Modal.Header>
                <Modal.Content scrolling={true}>
                    <List relaxed={true}>
                        {factoryReport &&
                            <List.Item>
                                <List.Icon name="user" size="large" verticalAlign="top" />
                                <List.Content>
                                    <List.Header content={formatMessage(m.repairedBy)} />
                                    <List.Description>{UserHelper.getDisplayName(factoryReport.createdBy)}</List.Description>
                                </List.Content>
                            </List.Item>
                        }
                        <List.Item>
                            <List.Icon name="calendar check" size="large" verticalAlign="top" />
                            <List.Content>
                                <List.Header content={formatMessage(m.completedOn)} />
                                <List.Description>{moment.utc(this.props.factoryRepair.completedRepairsOn).format('LL')}</List.Description>
                            </List.Content>
                        </List.Item>

                        {factoryReport &&
                            <React.Fragment>
                                <List.Item>
                                    <List.Icon name="clock" size="large" verticalAlign="top" />
                                    <List.Content>
                                        <List.Header content={formatMessage(m.labourTime)} />
                                        <List.Description>
                                            {DateHelper.getTimeDescription(factoryReport.hoursTaken, factoryReport.minutesTaken)}
                                        </List.Description>
                                    </List.Content>
                                </List.Item>

                                <List.Item>
                                    <List.Icon name="pencil" size="large" verticalAlign="top" />
                                    <List.Content>
                                        <List.Header content={formatMessage(m.report)} />
                                        <List.Description>
                                            <p style={{ whiteSpace: 'pre-line' }}>
                                                {factoryReport.report}
                                            </p>
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                            </React.Fragment>
                        }
                    </List>
                </Modal.Content>
            </Modal>
        );
    }
}

const connectedComponent = injectIntl(FactoryReportModal);
export { connectedComponent as FactoryReportModal };