import React, { useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { PageHeader } from "../../../../components/common";
import { ApplicationState } from "../../../../state/ducks";
import { getUserById, isUserLoading } from "../selectors";
import { actionCreators as UsersActions } from '../actions';
import { UserEdit } from "../models";
import { UserAccountDisabledWarning } from "./components/UserAccountDisabledWarning";
import { ClientForm } from "./components/ClientForm";
import { ClientEdit } from "../models/ClientEdit";
import { Client } from "../../../../state/models";
import * as api from '../api';
import useApiErrorHandler from "../../../../utils/ApiErrorHandler";

interface RouteProps {
    userId: string;
}

const m = defineMessages({
    title: { id: 'ModifyClientPage.title', defaultMessage: 'Modify a client profile' },
    subtitle: { id: 'ModifyClientPage.subtitle', defaultMessage: 'Modify the settings and properties of a client account.' },
});

export const ModifyClientPage: React.FC = (props) => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams<RouteProps>();
    const handleError = useApiErrorHandler();

    const [clientAccounts, setClientAccounts] = useState<Client[]>([]);
    const [areClientAccountsLoading, setAreClientAccountsLoading] = useState(false);

    const user = useSelector((state: ApplicationState) => getUserById(state, params.userId));
    const isLoading = useSelector((state: ApplicationState) => isUserLoading(state, params.userId));

    const reactivateClientAccount = () => dispatch(UsersActions.enable(user?.id || ''));
    const deactivateClientAccount = () => dispatch(UsersActions.disable(user?.id || ''));
    const updateClient = (user: ClientEdit) => {
        dispatch(UsersActions.updateClientAccount(user));
        navigateToClientsPage();
    }

    useEffect(() => {
        dispatch(UsersActions.loadById(params.userId));

        setAreClientAccountsLoading(true);

        api.loadLinkedAccounts(params.userId)
            .then(result => setClientAccounts(result))
            .catch((error) => handleError(error))
            .finally(() => setAreClientAccountsLoading(false));

    }, [dispatch, params.userId]);

    const navigateToClientsPage = () => history.push(`/administration/clients`);

    return (
        <>
            <PageHeader iconName="user" title={formatMessage(m.title)} subtitle={formatMessage(m.subtitle)} />
            {user?.isDisabled && <UserAccountDisabledWarning onReactivate={reactivateClientAccount} />}

            <ClientForm
                user={user}
                clientAccounts={clientAccounts}
                isLoading={isLoading || areClientAccountsLoading}
                onSave={updateClient}
                onCancel={navigateToClientsPage}
                onDisable={deactivateClientAccount}
            />
        </>
    );
};