import { useContext } from 'react';
import {
    SubcontractorRepairDetailsContext,
    SubcontractorRepairDetailsContextType,
} from './SubcontractorRepairDetailsProvider';

export const useSubcontractorRepairDetailsContext =
    (): SubcontractorRepairDetailsContextType =>
        useContext(
            SubcontractorRepairDetailsContext
        ) as SubcontractorRepairDetailsContextType;
