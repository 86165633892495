import React, { useEffect, useMemo, useState } from "react";
import drop from "lodash/drop";
import take from "lodash/take";
import { Pagination } from "semantic-ui-react";

const defaultPageSize = 10;

export const usePagination = <RowType,>(
  rows: RowType[],
  startingPage: number = 1,
  pageSize: number = defaultPageSize
) => {
  const [selectedPage, setPage] = useState(startingPage);
  const pageCount = Math.ceil(rows.length / Math.max(pageSize, 1));
  const currentPage = selectedPage > pageCount ? pageCount : selectedPage;

  useEffect(() => {
    setPage(1);
  }, [rows]);

  const currentPageRows = useMemo(
    () => take(drop(rows, (currentPage - 1) * pageSize), pageSize),
    [currentPage, pageSize, rows]
  );

  const Paginator = React.useMemo(() => {
    return () => {
      return (
        <Pagination
          totalPages={pageCount}
          activePage={currentPage}
          onPageChange={(event, { activePage }) => setPage(activePage as number)}
        />
      );
    };    
  }, [currentPage, pageCount]);

  return { pageCount, currentPage, currentPageRows, setPage, Paginator };
};
