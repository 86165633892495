import React from 'react';
import _ from 'lodash';

import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Form, Dropdown, DropdownItemProps, DropdownOnSearchChangeData, DropdownProps } from 'semantic-ui-react';

import { FilterSection } from '../common';
import { ClientFilter, initialClientFilter } from '../../state/models/filters/ClientFilter';
import { commonMessages } from '../../constants';
import { StringHelper } from '../../state/utils';

interface FilterByClientSectionOwnProps {
    filter: ClientFilter;
    onChange: (filter: ClientFilter) => void;
    onSearch: (query: string) => void;
}

export type FilterByClientSectionProps =
    & FilterByClientSectionOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'FilterByClientSection.title', defaultMessage: 'Filter by client' },
    titleTooltip: { id: 'FilterByClientSection.title_tooltip', defaultMessage: 'You can search for a client by typing its name or its identifier.' },
    allClients: { id: 'FilterByClientSection.all_clients', defaultMessage: 'All clients' }
});

class FilterByClientSection extends React.Component<FilterByClientSectionProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const allClientsOption: DropdownItemProps = { key: 'ALL', value: 'ALL', text: formatMessage(m.allClients), selected: this.props.filter.clientCode == null };
        const dropdownOptions = this.props.filter.clientsSearchResults.map((x): DropdownItemProps => {
            return {
                key: x.id,
                value: x.id,
                label: x.id,
                text: x.name || formatMessage(commonMessages.unknown)
            };
        });

        return (
            <FilterSection
                title={formatMessage(m.title)}
                titleTooltip={formatMessage(m.titleTooltip)}
                onClear={this.clearSection}
            >
                <Form>
                    <Form.Field>
                        <Dropdown
                            fluid={true}
                            selection={true}
                            search={this.searchTextAndValue}
                            loading={this.props.filter.isSearching}
                            options={[allClientsOption, ...dropdownOptions]}
                            noResultsMessage={this.props.filter.isSearching ? formatMessage(commonMessages.loading) : ''}
                            value={this.props.filter.clientCode}
                            onChange={this.selectClient}
                            onSearchChange={this.searchClient}
                        />
                    </Form.Field>
                </Form>
            </FilterSection>
        );
    }

    private searchTextAndValue = (options: Array<DropdownItemProps>, query: string): Array<DropdownItemProps> => {
        // The default dropdown search function only searches on the 'text' property. Since we want to perform
        // a search on both the 'text' and 'value' properties, we need to create a custom search function. This
        // function performs a case insensitive search on these two fields.
        const regex = RegExp(_.escapeRegExp(query), 'i');
        return options.filter(x => regex.test(x.text as string) || regex.test(x.value as string));
    }

    private selectClient = (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        this.props.onChange({
            ...this.props.filter,
            clientCode: data.value as string
        });
    }

    private clearSection = () => {
        this.props.onChange(initialClientFilter);
    }

    // tslint:disable-next-line:member-ordering
    private searchClient = _.debounce(
        (_event: React.SyntheticEvent<HTMLElement>, data: DropdownOnSearchChangeData) => {
            if (StringHelper.hasValue(data.searchQuery)) {
                this.props.onSearch(data.searchQuery);
            }
        },
        500);
}

const connectedComponent = injectIntl(FilterByClientSection);
export { connectedComponent as FilterByClientSection };