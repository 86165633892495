import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';
import { Client, Bill } from '.';

const m = defineMessages({
    billNumberRequired: { id: 'OrderInformation.bill_number_required', defaultMessage: 'The Jaymar bill number is required.' },
});

export class OrderInformation {
    public billNumber?: string;
    public bill?: Bill;
    public client?: Client;
    public referenceNumber?: string;
    public clientContactName?: string;
    public isBillNumberRequired?: boolean;

    public constructor(init?: Partial<OrderInformation>) {
        Object.assign(this, init);
    }

    public validate(): OrderInformationValidationResults {
        let validationSummary: MessageDescriptor[] = [];
        let isInvalid = false;

        if (this.isBillNumberRequired == null || this.isBillNumberRequired) {
            const isBillNumberInvalid = this.billNumber === undefined || this.billNumber === '';
            
            if (isBillNumberInvalid) {
                validationSummary.push(m.billNumberRequired);
            } 

            isInvalid = isBillNumberInvalid;
        }

        return new OrderInformationValidationResults({
            isInvalid,
            isBillNumberInvalid: isInvalid,
            validationSummary
        });
    }
}

export class OrderInformationValidationResults {
    public static readonly Empty = new OrderInformationValidationResults();
    
    public isInvalid: boolean = false;
    public isBillNumberInvalid: boolean = false;
    public validationSummary: MessageDescriptor[] = [];

    public constructor(init?: Partial<OrderInformationValidationResults>) {
        Object.assign(this, init);
    }
}