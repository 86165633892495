import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedMessage } from 'react-intl';
import { List } from 'semantic-ui-react';

import { ServiceCallInspection, AppointmentTimeRange } from '../../state/models';
import { UserHelper, DateHelper } from '../../helpers';
import { commonMessages, countriesMessages } from '../../constants';
import { StringHelper, FormatHelper } from '../../state/utils';

interface InspectionQuickOverviewOwnProps {
    inspection: ServiceCallInspection;
    onViewDetailsClick: (inspection: ServiceCallInspection) => void;
}

export type InspectionQuickOverviewProps =
    & InspectionQuickOverviewOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'InspectionQuickOverview.title', defaultMessage: 'Service call #{serviceCallLink}' },
    scheduledFor: { id: 'InspectionQuickOverview.scheduled_for', defaultMessage: 'Scheduled for {date}' },
    completedOn: { id: 'InspectionQuickOverview.completed_on', defaultMessage: 'Completed on {date}' },
});

class InspectionQuickOverview extends React.Component<InspectionQuickOverviewProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { inspection } = this.props;
        const { serviceCall } = this.props.inspection;
        const customer = serviceCall ? serviceCall.customer : undefined;
        const serviceCallLink = { 'serviceCallLink': (<a style={{ cursor: 'pointer' }} onClick={() => this.props.onViewDetailsClick(this.props.inspection)}>{serviceCall.id}</a>) };

        const hasAgentAssigned = serviceCall && serviceCall.assignedTo;
        const agentAssigned = hasAgentAssigned
            ? UserHelper.getDisplayName(serviceCall.assignedTo)
            : formatMessage(commonMessages.unknown);

        return (
            <div>
                <h3><FormattedMessage {...m.title} values={serviceCallLink} /></h3>
                <List>
                    {inspection.scheduledForDate &&
                        <List.Item>
                            <List.Icon name="calendar" />
                            <List.Content>
                                {formatMessage(m.scheduledFor, { date: moment.utc(inspection.scheduledForDate).format('LL') })}<br />
                                {formatMessage(DateHelper.getTimeRangeDescription(inspection.scheduledForTimeRange || AppointmentTimeRange.unspecified))}
                            </List.Content>
                        </List.Item>
                    }

                    {inspection.completedOn &&
                        <List.Item>
                            <List.Icon name="calendar check" />
                            <List.Content>{formatMessage(m.completedOn, { date: moment.utc(inspection.completedOn).format('LL') })}</List.Content>
                        </List.Item>
                    }

                    {hasAgentAssigned &&
                        <List.Item>
                            <List.Icon name="doctor" />
                            <List.Content>{agentAssigned}</List.Content>
                        </List.Item>
                    }

                    {customer && StringHelper.hasValue(customer.name) &&
                        <List.Item icon="user" content={customer.name} />
                    }
                    {customer && StringHelper.hasValue(customer.mainPhoneNumber) &&
                        <List.Item icon="phone" content={customer.mainPhoneNumber} />
                    }
                    {customer && StringHelper.hasValue(customer.otherPhoneNumber) &&
                        <List.Item icon="phone" content={customer.otherPhoneNumber} />
                    }
                    {customer && customer.address &&
                        <List.Item>
                            <List.Icon name="marker" />
                            <List.Content>
                                {FormatHelper.formatAddress(customer.address.address1 || '')}
                                <span style={{ display: 'block' }}>{customer.address.address2 || ''}</span>
                                <span style={{ display: 'block' }}>
                                    {customer.address.city || ''} {customer.address.provinceCode && <span>({customer.address.provinceCode.trim().toUpperCase()})</span>}</span>
                                <span style={{ display: 'block' }}>{customer.address.postalCode && customer.address.postalCode.toUpperCase()}</span>
                                <span style={{ display: 'block' }}>{customer.address.countryCode && formatMessage(countriesMessages[customer.address.countryCode])}</span>
                            </List.Content>
                        </List.Item>
                    }
                </List>
            </div>
        );
    }
}

const connectedComponent = injectIntl(InspectionQuickOverview);
export { connectedComponent as InspectionQuickOverview };