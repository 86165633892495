import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { PageHeader, SectionHeader, EmptyContentSegment } from '../../../components/common';
import { MobileNavigationButton } from '../../../components/layouts/PageLayout/NavigationMenu/MobileNavigationButton';
import { NavigationButton } from '../../../components/layouts/PageLayout/NavigationMenu/NavigationButton';
import { Segment, Button } from 'semantic-ui-react';
import { ServiceCall } from '../../../state/models';
import { getRecentServiceCalls, areRecentServiceCallsLoading } from '../../../state/ducks/service-calls';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as ServiceCallsActions } from '../../../state/ducks/service-calls';
import { RecentServiceCallsTable } from '../../../components/service-calls/RecentServiceCallsTable';
import { useResponsive } from 'utils/responsive';

const buttonStyle = {
    maxWidth: 250,
    margin: '0 14px 0 0',
    boxShadow: '0 0 0 1px rgba(34, 36, 38, .15) inset'
};

const mobileButtonStyle = {
    width: '100%',
    margin: '0 0 14px 0',
    boxShadow: '0 0 0 1px rgba(34, 36, 38, .15) inset'
};

export const m = defineMessages({
    pageTitle: { id: 'ClientDashboardPage.page_title', defaultMessage: 'Dashboard' },
    pageSubtitle: { id: 'ClientDashboardPage.page_subtitle', defaultMessage: 'See a quick overview of your service calls.' },
    createButtonTitle: { id: 'ClientDashboardPage.create_button_title', defaultMessage: 'Create a new service call' },
    createButtonDescription: { id: 'ClientDashboardPage.create_button_description', defaultMessage: 'Create a service call to notify our customer service about a problem with your Jaymar product.' },
    createButtonActionText: { id: 'ClientDashboardPage.create_button_action_text', defaultMessage: 'Create' },
    createButtonFromPdfTitle: { id: 'ClientDashboardPage.create_from_pdf_button_title', defaultMessage: 'Create from a PDF document' },
    createButtonFromPdfDescription: { id: 'ClientDashboardPage.create_from_pdf_button_description', defaultMessage: 'Create a service call from a PDF document generated by a third-party tool, such as Meublex.' },
    createButtonFromPdfActionText: { id: 'ClientDashboardPage.create_from_pdf_button_action_text', defaultMessage: 'Create from PDF' },
    recentServiceCallsTitle: { id: 'ClientDashboardPage.recent_service_calls_title', defaultMessage: 'Recent service calls' },
    recentServiceCallsEmptyMessageTitle: { id: 'ClientDashboardPage.recent_service_calls_empty_message_title', defaultMessage: 'No service calls' },
    recentServiceCallsEmptyMessageDescription: { id: 'ClientDashboardPage.recent_service_calls_empty_message_description', defaultMessage: 'Your recent and ongoing service calls will be displayed here.' },
});

export const ClientDashboardPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const { isMobile } = useResponsive();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(ServiceCallsActions.loadOpenedServiceCalls(1, undefined, undefined));
    }, [dispatch]);
    
    const isLoading = useSelector(areRecentServiceCallsLoading);
    const recentServiceCalls = useSelector(getRecentServiceCalls);
    const hasRecentServiceCalls = recentServiceCalls.length > 0;

    const navigateToServiceCallPage = (serviceCall: ServiceCall) => history.push(`/service-calls/${serviceCall.id}/details`);
    const onCreateServiceCall = () => history.push('/client/service-calls/create');

    return (
        <div>
            <PageHeader
                iconName="home"
                title={formatMessage(m.pageTitle)}
                subtitle={formatMessage(m.pageSubtitle)} 
            />

            {!isMobile &&
                <Button.Group widths="8">
                    <NavigationButton
                        style={buttonStyle}
                        iconName="phone"
                        title={formatMessage(m.createButtonTitle)}
                        description={formatMessage(m.createButtonDescription)}
                        buttonText={formatMessage(m.createButtonActionText)}
                        onClick={onCreateServiceCall} 
                    />

                    <NavigationButton
                        disabled={true}
                        style={buttonStyle}
                        iconName="file pdf outline"
                        iconColor="red"
                        title={formatMessage(m.createButtonFromPdfTitle)}
                        description={formatMessage(m.createButtonFromPdfDescription)}
                        buttonText={formatMessage(m.createButtonFromPdfActionText)}
                    />
                </Button.Group>
            }

            {isMobile &&
                <Button.Group vertical={true} widths={16}>
                    <MobileNavigationButton
                        style={mobileButtonStyle}
                        iconName="phone"
                        title={formatMessage(m.createButtonTitle)}
                        description={formatMessage(m.createButtonDescription)}
                        buttonText={formatMessage(m.createButtonActionText)}
                        onClick={onCreateServiceCall} 
                    />

                    <MobileNavigationButton
                        disabled={true}
                        style={mobileButtonStyle}
                        iconName="file pdf outline"
                        iconColor="red"
                        title={formatMessage(m.createButtonFromPdfTitle)}
                        description={formatMessage(m.createButtonFromPdfDescription)}
                        buttonText={formatMessage(m.createButtonFromPdfActionText)}
                    />
                </Button.Group>
            }

            <SectionHeader title={formatMessage(m.recentServiceCallsTitle)} />
            {hasRecentServiceCalls &&
                <Segment>
                    <RecentServiceCallsTable 
                        serviceCalls={recentServiceCalls}
                        onClick={navigateToServiceCallPage}
                    />
                </Segment>
            }
            {!hasRecentServiceCalls &&
                <EmptyContentSegment
                    title={formatMessage(m.recentServiceCallsEmptyMessageTitle)}
                    description={formatMessage(m.recentServiceCallsEmptyMessageDescription)}
                    loading={isLoading}
                />
            }
        </div>
    );
};