import React from "react";
import { useHistory } from "react-router";
import { Menu } from 'semantic-ui-react';
import { NotificationMenuTrigger } from "./NotificationMenuTrigger";

export const MobileNotificationMenuItem: React.FC = () => {
    const history = useHistory();
    const viewAllNotifications = () => history.push('/notifications');

    return (
        <Menu.Item onClick={viewAllNotifications}>
            <NotificationMenuTrigger />
        </Menu.Item>
    );
};