import { defineMessages } from "@formatjs/intl";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { SectionHeader } from "../../../components/common";
import { commonMessages } from "../../../constants";
import { ServiceCallEdit } from "../models";
import { FormValidationSummary } from "./components/FormValidationSummary";
import { ProblemInformationForm } from "./components/ProblemInformationForm";
import { StepsNavigationDivider } from "./components/StepsNavigationDivider";
import * as api from '../api';
import useApiErrorHandler from "../../../utils/ApiErrorHandler";

interface OwnProps {
}

export type FillProblemDetailsPageProps =
    & OwnProps;

const m = defineMessages({
    title: { id: 'ProblemInformationForm.problem_section_title', defaultMessage: 'Problem information' },
    subtitle: { id: 'ProblemInformationForm.problem_section_subtitle', defaultMessage: 'Describe in a few words the problem with your Jaymar product. You can also attach pictures of the affected parts.' },
})

export const FillProblemDetailsPage: React.FC<FillProblemDetailsPageProps> = (props) => {
    const { formatMessage } = useIntl();
    const { formState: { errors }, handleSubmit } = useFormContext<ServiceCallEdit>();
    const history = useHistory();
    const handleError = useApiErrorHandler();

    const [isCreating, setIsCreating] = useState(false);

    const navigateToPreviousStep = () => history.push('/service-calls/create/customer');
    const createServiceCall = (data: ServiceCallEdit) => {
        setIsCreating(true);

        api.create(data)
            .then(() => history.push(`/service-calls/current`))
            .catch((error) => handleError(error))
            .finally(() => setIsCreating(false));
    }

    return (
        <>
            <SectionHeader title={formatMessage(m.title)} subtitle={formatMessage(m.subtitle)} />

            <FormValidationSummary errors={errors} style={{ maxWidth: '600px' }} />
            <ProblemInformationForm />

            <StepsNavigationDivider 
                onPreviousStepClick={navigateToPreviousStep} 
                onNextStepClick={handleSubmit(createServiceCall)} 
                nextStepContent={formatMessage(commonMessages.create)}
                loading={isCreating}
            />
        </>
    );
};