import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, SemanticICONS, Icon, Label, SemanticCOLORS } from 'semantic-ui-react';

export interface NavigationLinkProps {
    title: string;
    to: string;
    exact?: boolean;
    icon?: SemanticICONS;
    labelColor?: SemanticCOLORS;
    labelContent?: React.ReactNode;
}

class NavigationLink extends React.Component<NavigationLinkProps, {}> {
    public render() {
        return (
            <React.Fragment>
                {this.props.labelContent && 
                    <Label 
                        color={this.props.labelColor}
                        className="c-navigation-menu__label" 
                        content={this.props.labelContent} 
                        style={{float: 'right'}}
                        size="small"
                    />
                }
                
                <Menu.Item as={NavLink} exact={this.props.exact} to={this.props.to}>
                    <span>
                        {this.props.icon && <Icon name={this.props.icon} />}
                        {this.props.title}
                    </span>
                </Menu.Item>
            </React.Fragment>
        );
    }
}

export { NavigationLink };