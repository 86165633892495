import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllClients, getAllLoadingUserIds } from '../selectors';
import { UsersSearchControl } from "./components/UsersSearchControl";
import { User } from "../../../../state/models";
import { searchUsers } from "../utils";
import { Button } from "semantic-ui-react";
import { defineMessages, useIntl } from "react-intl";
import { PageHeader } from "../../../../components/common";
import { UsersNavigationTab } from "./components/UsersNavigationTab";
import { useHistory } from "react-router";
import { actionCreators as UsersActions } from '../actions';
import { UsersFilters } from "../types";
import { UsersFilterPopup } from "./components/UsersFilterPopup";
import { commonMessages } from "../../../../constants";
import { ClientsTable } from "./components/ClientsTable";

const m = defineMessages({
    title: { id: 'ManageClientsPage.title', defaultMessage: 'Manage clients' },
    subtitle: { id: 'ManageClientsPage.subtitle', defaultMessage: 'Manage the clients that have access to the after-sales platform.' },
    clientsCount: { id: 'ManageClientsPage.clientsCount', defaultMessage: '{count} clients' },
    addClientButton: { id: 'ManageClientsPage.addClientButton', defaultMessage: 'Add a client' },
    searchClientPlaceholder: { id: 'ManageClientsPage.searchClientPlaceholder', defaultMessage: 'Search for a client' }
});

export const ManageClientsPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const allClients = useSelector(getAllClients);
    const loadingIds = useSelector(getAllLoadingUserIds);
    const [displayedClients, setDisplayedClients] = useState<User[]>([]);

    useEffect(() => {
        setDisplayedClients(allClients);
    }, [allClients]);

    useEffect(() => {
        dispatch(UsersActions.loadAll());
    }, [dispatch]);

    const searchClient = (searchTerm: string) => {
        const searchResults = searchUsers(allClients, searchTerm);
        setDisplayedClients(searchResults);
    }

    const clearSearchResults = () => setDisplayedClients(allClients);
    const navigateToDetailsPage = (user: User) => history.push(`/administration/clients/${user.id}`);
    const navigateToCreatePage = () => history.push(`/administration/clients/create`);
    const applyFilters = (filters: UsersFilters) => dispatch(UsersActions.setFilters(filters));

    return (
        <>
            <PageHeader iconName="users" title={formatMessage(m.title)} subtitle={formatMessage(m.subtitle)} />
            <UsersNavigationTab />

            <UsersSearchControl 
                totalUsersCount={allClients.length}
                totalUsersMessage={m.clientsCount}
                searchResultsCount={displayedClients.length}
                searchPlaceholder={formatMessage(m.searchClientPlaceholder)}
                onChange={searchClient}
                onClear={clearSearchResults}
                style={{ marginTop: '1em' }}
            >
                <UsersFilterPopup 
                    trigger={<Button icon="filter" content={formatMessage(commonMessages.filter)} style={{ marginRight: '1em' }} />}
                    onApply={applyFilters} 
                />
                <Button primary content={formatMessage(m.addClientButton)} onClick={navigateToCreatePage} />
            </UsersSearchControl>

            <ClientsTable 
                users={displayedClients} 
                loadingUserIds={loadingIds}
                onClick={navigateToDetailsPage}
            />
        </>
    );
};