import moment from 'moment';

export enum DateFilterType {
    today,
    thisWeek,
    lastMonth,
    thisMonth,
    thisYear,
    pastFiveYears,
    custom,
    previousYear,
}

export interface DateFilter {
    type: DateFilterType;
    customStartDate: Date;
    customEndDate: Date;
}

export interface DateRange {
    startDate: Date;
    endDate: Date;
}

export const initialDateFilter: DateFilter = {
    type: DateFilterType.thisMonth,
    customStartDate: moment().startOf('year').startOf('day').toDate(),
    customEndDate: moment().toDate()
};

export function isInitialDateFilter(filter: DateFilter) {
    return filter.type === initialDateFilter.type;
}

export function getReportingDateRange(dateFilter: DateFilter): DateRange {
    switch (dateFilter.type) {
        case DateFilterType.today:
            return {
                startDate: moment().startOf('day').toDate(),
                endDate: moment().endOf('day').toDate()
            };

        case DateFilterType.thisWeek:
            return {
                startDate: moment().startOf('week').startOf('day').toDate(),
                endDate: moment().endOf('week').endOf('day').toDate()
            };

        case DateFilterType.lastMonth:
            return {
                startDate: moment().startOf('month').add(-1, 'day').startOf('month').toDate(),
                endDate: moment().startOf('month').add(-1, 'day').toDate()
            };

        case DateFilterType.thisMonth:
            return {
                startDate: moment().startOf('month').startOf('day').toDate(),
                endDate: moment().endOf('day').toDate()
            };

        case DateFilterType.thisYear:
            return {
                startDate: moment().startOf('year').startOf('day').toDate(),
                endDate: moment().endOf('day').toDate()
            };

        case DateFilterType.pastFiveYears:
            return {
                startDate: moment().add(-5, 'year').startOf('month').toDate(),
                endDate: moment().add(-1, 'month').endOf('month').toDate()
            };
        case DateFilterType.previousYear:
            return {
                startDate: moment().add(-1, 'year').startOf('year').toDate(),
                endDate: moment().add(-1, 'year').endOf('year').toDate()
            };

        default:
            return {
                startDate: dateFilter.customStartDate,
                endDate: dateFilter.customEndDate
            };
    }
}