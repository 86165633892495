import { get, httpDelete, httpDeleteVoid, post } from '../../../utils/api';
import { Client, User } from '../../../state/models';
import { UserEdit } from './models';
import { ClientEdit } from './models/ClientEdit';

const apiEndpoint = '/api/users';

export async function loadAll(): Promise<User[]> {
    return get<User[]>(`${apiEndpoint}`);
}

export async function loadById(userId: string): Promise<User> {
    return get<User>(`${apiEndpoint}/${userId}`);
}

export async function deleteUser(user: User): Promise<void> {
    return httpDeleteVoid(`${apiEndpoint}/${user.id}`);
}

export async function updateUser(user: UserEdit): Promise<User> {
    return post<User>(`${apiEndpoint}/${user.id}`, user);
}

export async function updateClientAccount(client: ClientEdit): Promise<User> {
    return post<User>(`${apiEndpoint}/clients/${client.id}`, client);
}

export async function loadLinkedAccounts(userId: string): Promise<Client[]> {
    return get<Client[]>(`${apiEndpoint}/clients/${userId}/linked`);
}

export async function createClientAccount(client: ClientEdit): Promise<User> {
    return post<User>(`${apiEndpoint}/clients`, client);
}

export async function createEmployee(user: UserEdit): Promise<User> {
    return post<User>(`${apiEndpoint}/employees`, user);
}

export async function disableUser(userId: string): Promise<User> {
    return post<User>(`${apiEndpoint}/${userId}/disable`, null);
}

export async function enableUser(userId: string): Promise<User> {
    return post<User>(`${apiEndpoint}/${userId}/enable`, null);
}