import { defineMessages, formatMessage } from "@formatjs/intl";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Checkbox, CheckboxProps, Popup, PopupProps } from "semantic-ui-react";
import { FilterPopup, FilterSection } from "../../../../../components/common";
import { getUsersFilters } from "../../selectors";
import { UsersFilters } from "../../types";

interface OwnProps {
    onApply: (filters: UsersFilters) => void;
}

export type UsersFilterPopupProps =
    & PopupProps
    & OwnProps;

const m = defineMessages({
    statusSectionTitle: { id: 'UsersFilterPopup.statusSectionTitle', defaultMessage: 'Filter by status' },
    hideDisabledAccounts: { id: 'UsersFilterPopup.hideDisabledAccounts', defaultMessage: 'Hide disabled accounts' },
});

export const UsersFilterPopup: React.FC<UsersFilterPopupProps> = (props) => {
    const { formatMessage } = useIntl();
    const { onApply, ...popupProps } = props;

    const usersFilters = useSelector(getUsersFilters);
    const [pendingFilters, setPendingFilters] = useState<UsersFilters>(usersFilters);

    useEffect(() => {
        setPendingFilters(usersFilters);
    }, [usersFilters]);

    const resetPopupState = () => setPendingFilters(usersFilters);
    const resetStatus = () => setPendingFilters({ ...pendingFilters, areDisabledAccountsHidden: true });
    const applyFilters = () => props.onApply(pendingFilters);
    const handleStatusChange = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        setPendingFilters({ ...pendingFilters, areDisabledAccountsHidden: data.checked != null ? data.checked : true });
    }

    return (
        <FilterPopup onApply={applyFilters} onClose={resetPopupState} {...popupProps}>
            <FilterSection title={formatMessage(m.statusSectionTitle)} onClear={resetStatus}>
                <Checkbox 
                    toggle
                    checked={pendingFilters.areDisabledAccountsHidden} 
                    label={formatMessage(m.hideDisabledAccounts)}
                    onChange={handleStatusChange}
                />
            </FilterSection>
        </FilterPopup>
    );
};