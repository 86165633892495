import * as React from 'react';
import { Header } from 'semantic-ui-react';

export interface SectionHeaderProperties {
    title: string;
    subtitle?: string;
}

export class SectionHeader extends React.Component<SectionHeaderProperties, {}> {
    public render() {
        return (
            <Header>
                <Header.Content>{this.props.title}</Header.Content>
                {this.props.subtitle && <Header.Subheader>{this.props.subtitle}</Header.Subheader>}
            </Header>
        );
    }
}

export default SectionHeader;