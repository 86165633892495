import * as React from 'react';
import { Divider, Header, Icon, SemanticICONS } from 'semantic-ui-react';

export interface PageHeaderProperties {
    iconName?: SemanticICONS;
    title: string;
    subtitle?: string;
}

export class PageHeader extends React.Component<PageHeaderProperties, {}> {
    public render() {
        return (
            <div>
                <Header as="h2">
                    <Icon name={this.props.iconName} />
                    <Header.Content>
                        {this.props.title}
                            <Header.Subheader>
                                {this.props.subtitle}
                            </Header.Subheader>
                    </Header.Content>
                </Header>

                <Divider />
            </div>
        );
    }
}

export default PageHeader;