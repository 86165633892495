import React from 'react';
import _ from 'lodash';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { ModalProps, Header, Modal, Button, Form, Input, Dropdown, DropdownItemProps, DropdownProps, List, Icon, Message } from 'semantic-ui-react';

import { commonMessages } from '../../constants';
import { ButtonWithOptions, TooltipIcon } from '../common';
import { ValidationResult, SendEmailEdit, PreferredLanguage, getCultureCode } from '../../state/models';
import { nameof } from '../../state/utils';
import { LanguageDropdown } from '../common/LanguageDropdown';

interface SendEmailModalOwnProps extends ModalProps {
    defaultEmails?: string[];
    defaultLanguage?: PreferredLanguage;
    areDefaultEmailsLoading?: boolean;
    previewUri?: string;
    attachments?: EmailAttachment[];
    title?: string;
    subtitle?: string;

    onCancel: () => void;
    onSend: (email: string, locale: string) => void;
    onSendManually: () => void;
}

interface SendEmailModalState {
    recipientEmail: string;
    selectedLanguage: PreferredLanguage;
    emailDropdownOptions: DropdownItemProps[];
    validationResult: ValidationResult;
}

export type SendEmailModalProps =
    & SendEmailModalOwnProps
    & WrappedComponentProps;

export interface EmailAttachment {
    filename: string;
    previewUri: string;
    description: string;
}

const m = defineMessages({
    addNewEmailLabel: { id: 'SendEmailModal.add_new_email_label', defaultMessage: 'Add ' },
    enterEmailAddress: { id: 'SendEmailModal.enter_email_address', defaultMessage: 'Enter the recipient\'s email address' },
    recipientEmailLabel: { id: 'SendEmailModal.recipient_email_label', defaultMessage: 'Recipient\'s email' },
    recipientEmailTooltip: { id: 'SendEmailModal.recipient_email_tooltip', defaultMessage: 'You can select an existing email address or enter a new one' },
    languageLabel: { id: 'SendEmailModal.language_label', defaultMessage: 'Language' },
    previewEmailButton: { id: 'SendEmailModal.preview_email_button', defaultMessage: 'Preview' },
    sendEmailButton: { id: 'SendEmailModal.send_email_button', defaultMessage: 'Send' },
    sendEmailManually: { id: 'SendEmailModal.send_email_manually', defaultMessage: 'Send the email manually' },
    attachmentsLabel: { id: 'SendEmailModal.attachments_label', defaultMessage: 'Attachments' }
});

class SendEmailModal extends React.Component<SendEmailModalProps, SendEmailModalState> {
    public constructor(props: SendEmailModalProps) {
        super(props);

        this.state = {
            recipientEmail: this.props.defaultEmails != null && this.props.defaultEmails.length > 0 ? this.props.defaultEmails[0] : '',
            selectedLanguage: props.defaultLanguage || PreferredLanguage.french,
            emailDropdownOptions: this.props.defaultEmails != null ? this.props.defaultEmails.map(x => ({ text: x, value: x })) : [],
            validationResult: ValidationResult.empty()
        };
    }

    public componentDidUpdate(prevProps: SendEmailModalProps) {
        const hasDefaultEmailsChanged = !_.isEqual(prevProps.defaultEmails, this.props.defaultEmails);
        if (hasDefaultEmailsChanged) {
            this.setState({
                recipientEmail: this.props.defaultEmails != null && this.props.defaultEmails.length > 0 ? this.props.defaultEmails[0] : '',
                emailDropdownOptions: this.props.defaultEmails != null ? this.props.defaultEmails.map(x => ({ text: x, value: x })) : []
            });
        }

        const hasDefaultLanguageChanged = !_.isEqual(prevProps.defaultLanguage, this.props.defaultLanguage);
        if (hasDefaultLanguageChanged) {
            this.setState({
                selectedLanguage: this.props.defaultLanguage || PreferredLanguage.french,
            });
        }
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const {
            intl,
            defaultEmails,
            areDefaultEmailsLoading,
            previewUri,
            attachments,
            title,
            subtitle,
            onCancel,
            onSend,
            onSendManually,
            ...modalProps
        } = this.props;

        const localizedErrorMessages = this.state.validationResult.errorMessages.map(value => formatMessage(value));

        return (
            <Modal {...modalProps}>
                <Modal.Header>
                    <Header
                        content={this.props.title}
                        subheader={this.props.subtitle}
                    />
                </Modal.Header>
                <Modal.Content>
                    <Form error={!this.state.validationResult.isValid}>
                        <Form.Field error={this.state.validationResult.isFieldInvalid(nameof<SendEmailEdit>('recipientEmail'))}>
                            <label>
                                {formatMessage(m.recipientEmailLabel)}
                                <TooltipIcon content={formatMessage(m.recipientEmailTooltip)} />
                            </label>
                            <Dropdown
                                fluid={true}
                                selection={true}
                                search={true}
                                allowAdditions={true}
                                loading={this.props.areDefaultEmailsLoading}
                                additionLabel={formatMessage(m.addNewEmailLabel)}
                                noResultsMessage={formatMessage(m.enterEmailAddress)}
                                selectedLabel={this.props.defaultEmails ? this.props.defaultEmails[0] : undefined}
                                value={this.state.recipientEmail}
                                onSearchChange={this.resetValidation}
                                onChange={this.changeRecipient}
                                options={this.state.emailDropdownOptions}
                            />
                        </Form.Field>
                        <Form.Field error={this.state.validationResult.isFieldInvalid(nameof<SendEmailEdit>('emailLocale'))}>
                            <label>{formatMessage(m.languageLabel)}</label>
                            <LanguageDropdown
                                fluid={true}
                                selection={true}
                                value={this.state.selectedLanguage}
                                onChange={(_event, data) => this.setState({ selectedLanguage: data.value as PreferredLanguage })}
                            />
                        </Form.Field>
                        {this.props.attachmentsPreviewUris != null && this.props.attachmentsPreviewUris.length > 0 &&
                            <Form.Field>
                                <label>{formatMessage(m.attachmentsLabel)}</label>
                                <List selection={true}>
                                    {this.props.attachmentsPreviewUris.map(this.renderAttachmentPreviewLink)}
                                </List>
                            </Form.Field>
                        }

                        <Message error={true} list={localizedErrorMessages} />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button floated="left" content={formatMessage(commonMessages.cancel)} onClick={this.props.onCancel} />
                    <Button as="a" target="_blank" href={`${this.props.previewUri}?culture=${this.state.selectedLanguage}`} primary={true} basic={true} content={formatMessage(m.previewEmailButton)} />
                    <ButtonWithOptions
                        color="blue"
                        content={formatMessage(m.sendEmailButton)}
                        onClick={this.sendEmail}
                        options={[{ text: formatMessage(m.sendEmailManually), onClick: this.props.onSendManually }]}
                    />
                </Modal.Actions>
            </Modal>
        );
    }

    private renderAttachmentPreviewLink = (attachment: EmailAttachment) => {
        const cultureCode = getCultureCode(this.state.selectedLanguage);

        return (
            <List.Item key={attachment.previewUri}>
                <List.Content floated="left">
                    <a href={`${attachment.previewUri}?culture=${cultureCode}`} target="_blank">
                        <Icon
                            name="file pdf outline"
                            size="big"
                            color="red"
                        />
                    </a>
                </List.Content>
                <List.Content as="a" href={`${attachment.previewUri}?culture=${cultureCode}`} target="_blank" verticalAlign="middle">
                    <List.Header style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{attachment.filename}</List.Header>
                    <List.Description>{attachment.description}</List.Description>
                </List.Content>

            </List.Item>
        );
    }

    private changeRecipient = (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps): void => {
        const recipientEmail = data.value as string;

        if (!this.state.emailDropdownOptions.find(x => x.value === recipientEmail)) {
            this.setState((current) => ({
                emailDropdownOptions: [...current.emailDropdownOptions, { text: recipientEmail, value: recipientEmail }]
            }));
        }

        this.setState({ recipientEmail: recipientEmail });
    }

    private resetValidation = () => {
        this.setState({
            validationResult: ValidationResult.empty()
        });
    }

    private sendEmail = () => {
        const edit = new SendEmailEdit({
            recipientEmail: this.state.recipientEmail,
            emailLocale: getCultureCode(this.state.selectedLanguage)
        });

        const validationResult = edit.validate();
        this.setState({ validationResult: validationResult });

        if (validationResult.isValid) {
            this.props.onSend(edit.recipientEmail!, edit.emailLocale!);
        }
    }
}

const connectedComponent = injectIntl(SendEmailModal);
export { connectedComponent as SendEmailModal };