import { ThunkAction } from 'redux-thunk';

import { ActionTypeKeys } from './types';
import { ApplicationState } from '..';
import { ServiceCallFactoryRepair, FactoryReport } from '../../models';

import * as globalNotification from '../global-notification';
import { FactoryRepairsService } from '../../../services';
import { AppThunk } from '../../store';

export type ActionTypes =
    | LoadRepairRequest
    | LoadRepairSuccess
    | LoadRepairFailure
    | LoadPendingRepairsRequest
    | LoadPendingRepairsSuccess
    | LoadPendingRepairsFailure
    | LoadCompletedRepairsRequest
    | LoadCompletedRepairsSuccess
    | LoadCompletedRepairsFailure
    | LoadRepairsBetweenRequest
    | LoadRepairsBetweenSuccess
    | LoadRepairsBetweenFailure
    | LoadRepairsForTodayRequest
    | LoadRepairsForTodaySuccess
    | LoadRepairsForTodayFailure
    | LoadRepairsForThisWeekRequest
    | LoadRepairsForThisWeekSuccess
    | LoadRepairsForThisWeekFailure
    | LoadByServiceCallRequest
    | LoadByServiceCallSuccess
    | LoadByServiceCallFailure
    | LoadReportRequest
    | LoadReportSuccess
    | LoadReportFailure
    | SaveReportRequest
    | SaveReportSuccess
    | SaveReportFailure
    | CompleteReportRequest
    | CompleteReportSuccess
    | CompleteReportFailure
    | ScheduleRepairRequest
    | ScheduleRepairSuccess
    | ScheduleRepairFailure
    | SetCalendarStartDate
    | { type: '' };

export interface LoadRepairRequest { type: ActionTypeKeys.LOAD_REPAIR_REQUEST; }
export interface LoadRepairSuccess { type: ActionTypeKeys.LOAD_REPAIR_SUCCESS; payload: ServiceCallFactoryRepair; }
export interface LoadRepairFailure { type: ActionTypeKeys.LOAD_REPAIR_FAILURE; payload: string; }
export interface LoadPendingRepairsRequest { type: ActionTypeKeys.LOAD_PENDING_REPAIRS_REQUEST; }
export interface LoadPendingRepairsSuccess { type: ActionTypeKeys.LOAD_PENDING_REPAIRS_SUCCESS; payload: ServiceCallFactoryRepair[]; }
export interface LoadPendingRepairsFailure { type: ActionTypeKeys.LOAD_PENDING_REPAIRS_FAILURE; payload: string; }
export interface LoadCompletedRepairsRequest { type: ActionTypeKeys.LOAD_COMPLETED_REPAIRS_REQUEST; }
export interface LoadCompletedRepairsSuccess { type: ActionTypeKeys.LOAD_COMPLETED_REPAIRS_SUCCESS; payload: ServiceCallFactoryRepair[]; }
export interface LoadCompletedRepairsFailure { type: ActionTypeKeys.LOAD_COMPLETED_REPAIRS_FAILURE; payload: string; }
export interface LoadRepairsBetweenRequest { type: ActionTypeKeys.LOAD_REPAIRS_BETWEEN_REQUEST; payload: Date; }
export interface LoadRepairsBetweenSuccess { type: ActionTypeKeys.LOAD_REPAIRS_BETWEEN_SUCCESS; payload: { startDate: Date, repairs: ServiceCallFactoryRepair[] }; }
export interface LoadRepairsBetweenFailure { type: ActionTypeKeys.LOAD_REPAIRS_BETWEEN_FAILURE; payload: { startDate: Date, error: string }; }
export interface LoadRepairsForTodayRequest { type: ActionTypeKeys.LOAD_REPAIRS_FOR_TODAY_REQUEST; }
export interface LoadRepairsForTodaySuccess { type: ActionTypeKeys.LOAD_REPAIRS_FOR_TODAY_SUCCESS; payload: ServiceCallFactoryRepair[]; }
export interface LoadRepairsForTodayFailure { type: ActionTypeKeys.LOAD_REPAIRS_FOR_TODAY_FAILURE; payload: string; }
export interface LoadRepairsForThisWeekRequest { type: ActionTypeKeys.LOAD_REPAIRS_FOR_THIS_WEEK_REQUEST; }
export interface LoadRepairsForThisWeekSuccess { type: ActionTypeKeys.LOAD_REPAIRS_FOR_THIS_WEEK_SUCCESS; payload: ServiceCallFactoryRepair[]; }
export interface LoadRepairsForThisWeekFailure { type: ActionTypeKeys.LOAD_REPAIRS_FOR_THIS_WEEK_FAILURE; payload: string; }
export interface LoadByServiceCallRequest { type: ActionTypeKeys.LOAD_BY_SERVICE_CALL_REQUEST; payload: number; }
export interface LoadByServiceCallSuccess { type: ActionTypeKeys.LOAD_BY_SERVICE_CALL_SUCCESS; payload: { serviceCallId: number, repairs: ServiceCallFactoryRepair[] }; }
export interface LoadByServiceCallFailure { type: ActionTypeKeys.LOAD_BY_SERVICE_CALL_FAILURE; payload: { serviceCallId: number, error: string }; }
export interface LoadReportRequest { type: ActionTypeKeys.LOAD_REPORT_REQUEST; }
export interface LoadReportSuccess { type: ActionTypeKeys.LOAD_REPORT_SUCCESS; payload: FactoryReport; }
export interface LoadReportFailure { type: ActionTypeKeys.LOAD_REPORT_FAILURE; payload: string; }
export interface SaveReportRequest { type: ActionTypeKeys.SAVE_REPORT_REQUEST; }
export interface SaveReportSuccess { type: ActionTypeKeys.SAVE_REPORT_SUCCESS; payload: FactoryReport; }
export interface SaveReportFailure { type: ActionTypeKeys.SAVE_REPORT_FAILURE; payload: string; }
export interface CompleteReportRequest { type: ActionTypeKeys.COMPLETE_REPORT_REQUEST; }
export interface CompleteReportSuccess { type: ActionTypeKeys.COMPLETE_REPORT_SUCCESS; payload: number; }
export interface CompleteReportFailure { type: ActionTypeKeys.COMPLETE_REPORT_FAILURE; payload: string; }
export interface ScheduleRepairRequest { type: ActionTypeKeys.SCHEDULE_REPAIR_REQUEST; payload: { repairId: number }; }
export interface ScheduleRepairSuccess { type: ActionTypeKeys.SCHEDULE_REPAIR_SUCCESS; payload: { repairId: number, repairs: ServiceCallFactoryRepair }; }
export interface ScheduleRepairFailure { type: ActionTypeKeys.SCHEDULE_REPAIR_FAILURE; payload: { repairId: number, error: string }; }
export interface SetCalendarStartDate { type: ActionTypeKeys.SET_CALENDAR_START_DATE; payload: Date; }

const service = new FactoryRepairsService();

export const actionCreators = {
    loadRepair: (repairId: number): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_REPAIR_REQUEST });

            service.getById(repairId)
                .then(response => response.json() as ServiceCallFactoryRepair)
                .then(repair => {
                    dispatch({ type: ActionTypeKeys.LOAD_REPAIR_SUCCESS, payload: repair });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_REPAIR_FAILURE });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de récupérer la réparation en usine '${repairId}' (${error}).`)
                    );
                });
        };
    },

    loadPendingRepairs: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_PENDING_REPAIRS_REQUEST });

            service.getPendingRepairs()
                .then(response => response.json() as ServiceCallFactoryRepair[])
                .then(repairs => {
                    dispatch({ type: ActionTypeKeys.LOAD_PENDING_REPAIRS_SUCCESS, payload: repairs });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_PENDING_REPAIRS_FAILURE });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de récupérer les réparations en usine en attente (${error}).`)
                    );
                });
        };
    },

    loadCompletedRepairs: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_COMPLETED_REPAIRS_REQUEST });

            service.getCompletedRepairs()
                .then(response => response.json() as ServiceCallFactoryRepair[])
                .then(repairs => {
                    dispatch({ type: ActionTypeKeys.LOAD_COMPLETED_REPAIRS_SUCCESS, payload: repairs });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_COMPLETED_REPAIRS_FAILURE });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de récupérer les réparations en usine complétées (${error}).`)
                    );
                });
        };
    },

    loadByServiceCall: (serviceCallId: number): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_BY_SERVICE_CALL_REQUEST, payload: serviceCallId });

            service.getByServiceCall(serviceCallId)
                .then(response => response.json() as ServiceCallFactoryRepair[])
                .then(repairs => {
                    dispatch({ type: ActionTypeKeys.LOAD_BY_SERVICE_CALL_SUCCESS, payload: { serviceCallId, repairs } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_BY_SERVICE_CALL_FAILURE, payload: { serviceCallId, error } });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de récupérer les réparations en lien avec l'appel '${serviceCallId}' (${error}).`)
                    );
                });
        };
    },

    loadBetween: (startDate: Date, endDate: Date): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_REPAIRS_BETWEEN_REQUEST, payload: startDate });

            service.getScheduledBetween(startDate, endDate)
                .then(response => response.json() as ServiceCallFactoryRepair[])
                .then(repairs => {
                    dispatch({ type: ActionTypeKeys.LOAD_REPAIRS_BETWEEN_SUCCESS, payload: { startDate, repairs } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_REPAIRS_BETWEEN_FAILURE, payload: { startDate, error } });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de récupérer les réparations en date du '${startDate.toISOString()}' (${error}).`)
                    );
                });
        };
    },

    loadScheduledForToday: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_REPAIRS_FOR_TODAY_REQUEST });

            service.getScheduledForToday()
                .then(response => response.json() as ServiceCallFactoryRepair[])
                .then(repairs => {
                    dispatch({ type: ActionTypeKeys.LOAD_REPAIRS_FOR_TODAY_SUCCESS, payload: repairs });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_REPAIRS_FOR_TODAY_FAILURE });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de récupérer les réparations en usine cédulées pour aujourd'hui (${error}).`)
                    );
                });
        };
    },

    loadScheduledForThisWeek: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_REPAIRS_FOR_THIS_WEEK_REQUEST });

            service.getScheduledForThisWeek()
                .then(response => response.json() as ServiceCallFactoryRepair[])
                .then(repairs => {
                    dispatch({ type: ActionTypeKeys.LOAD_REPAIRS_FOR_THIS_WEEK_SUCCESS, payload: repairs });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_REPAIRS_FOR_THIS_WEEK_FAILURE });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de récupérer les réparations en usine cédulées pour cette semaine (${error}).`)
                    );
                });
        };
    },

    loadReport: (repairId: number): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_REPORT_REQUEST });

            service.getReport(repairId)
                .then(response => response.json() as FactoryReport)
                .then(report => {
                    dispatch({ type: ActionTypeKeys.LOAD_REPORT_SUCCESS, payload: report });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_REPORT_FAILURE });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de récupérer le rapport associé à la réparation en usine '${repairId}' (${error}).`)
                    );
                });
        };
    },

    saveReport: (repairId: number, report: FactoryReport): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.SAVE_REPORT_REQUEST });

            service.saveReport(repairId, report)
                .then(response => response.json() as FactoryReport)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.SAVE_REPORT_SUCCESS, payload: result });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.SAVE_REPORT_FAILURE });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de sauvegarder le rapport associé à la réparation en usine '${repairId}' (${error}).`)
                    );
                });
        };
    },

    completeReport: (repairId: number, onComplete?: () => void): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.COMPLETE_REPORT_REQUEST });

            service.completeReport(repairId)
                .then(response => {
                    dispatch({ type: ActionTypeKeys.COMPLETE_REPORT_SUCCESS, payload: repairId });

                    if (onComplete) {
                        onComplete();
                    }
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.COMPLETE_REPORT_FAILURE });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de compléter le rapport associé à la réparation en usine '${repairId}' (${error}).`)
                    );
                });
        };
    },

    scheduleRepair: (repairId: number, scheduledFor: Date): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.SCHEDULE_REPAIR_REQUEST, payload: repairId });

            service.scheduleRepair(repairId, scheduledFor)
                .then(response => response.json() as ServiceCallFactoryRepair)
                .then(repair => {
                    dispatch({ type: ActionTypeKeys.SCHEDULE_REPAIR_SUCCESS, payload: repair });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.SCHEDULE_REPAIR_FAILURE });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de céduler la réparation en usine '${repairId}' (${error}).`)
                    );
                });
        };
    },

    setCalendarStartDate: (startDate: Date) => {
        return { type: ActionTypeKeys.SET_CALENDAR_START_DATE, payload: startDate };
    }
};