import React from 'react';
import moment from 'moment';

import { injectIntl, WrappedComponentProps, defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';
import { Feed, Icon, Popup, DropdownItemProps, Dropdown, DropdownProps } from 'semantic-ui-react';

import { UserHelper } from '../../../helpers';
import { ServiceCallNote, NoteVisibility } from '../../../state/models';
import { SmallConfirmDeleteButton } from '../../common';
import { commonMessages } from '../../../constants';

interface NoteAttachmentOwnProps {
    note: ServiceCallNote;
    canDelete?: boolean;
    onDelete?: (note: ServiceCallNote) => void;
    showVisibility?: boolean;
    canChangeVisibility?: boolean;
    onChangeVisibility?: (visibiliy: NoteVisibility) => void;
}

export type NoteAttachmentProps =
    & NoteAttachmentOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    userAddedNote: { id: 'ProblemDescriptionCard.user_added_note', defaultMessage: '{name} added a note' },
    confirmDeleteTitle: { id: 'ProblemDescriptionCard.confirm_delete_title', defaultMessage: 'Delete this note?' },
    confirmDeleteMessage: { id: 'ProblemDescriptionCard.confirm_delete_message', defaultMessage: 'Are you sure you want to delete this note?' },
    noteVisibility: { id: 'ProblemDescriptionCard.noteVisibility', defaultMessage: 'This note is displayed' },
    noteVisibilityInternal: { id: 'ProblemDescriptionCard.noteVisibilityInternal', defaultMessage: 'internally' },
    noteVisibilityPublic: { id: 'ProblemDescriptionCard.noteVisibilityPublic', defaultMessage: 'publicly' }
});

class NoteAttachment extends React.Component<NoteAttachmentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { note } = this.props;
    
        const noteBackground = this.props.note.visibility === NoteVisibility.internal ? '#FFF9E5' : '';
        const noteBorder = this.props.note.visibility === NoteVisibility.internal ? '1px solid #EFE5B9' : '1px solid white';

        return (
            <Feed.Event key={note.id}>
                <Feed.Label>
                    <Icon name="user circle" />
                </Feed.Label>
                <Feed.Content style={{ minHeight: '130px', padding: '10px', background: noteBackground, border: noteBorder }}>
                    <Popup
                        trigger={<Feed.Date content={moment.utc(note.createdOn).fromNow()} />}
                        content={moment.utc(note.createdOn).format('LL')}
                    />
                    <Feed.Summary>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ flexGrow: 1 }}>
                                {formatMessage(m.userAddedNote, { name: UserHelper.getDisplayName(note.createdBy) })}
                                {this.props.showVisibility && this.renderNoteVisibility()}
                            </div>

                            {this.props.canDelete && this.props.onDelete &&
                                <SmallConfirmDeleteButton
                                    basic={false}
                                    confirmationTitle={formatMessage(m.confirmDeleteTitle)}
                                    confirmationMessage={formatMessage(m.confirmDeleteMessage)}
                                    onClick={this.deleteNote}
                                />
                            }
                        </div>
                    </Feed.Summary>
                    <Feed.Extra text={true}>
                        <pre style={{ fontFamily: 'Lato, "Helvetica Neue", Arial, Helvetica, sans-serif', whiteSpace: 'pre-wrap' }}>
                            {note.content}
                        </pre>
                    </Feed.Extra>
                </Feed.Content>
            </Feed.Event>
        );
    }

    private deleteNote = () => {
        if (this.props.canDelete && this.props.onDelete) {
            this.props.onDelete(this.props.note);
        }
    }

    private renderNoteVisibility = () => {
        const { formatMessage } = this.props.intl;
        const { note } = this.props;
        const visibilityDescription = new Map<NoteVisibility, MessageDescriptor>([
            [NoteVisibility.internal, m.noteVisibilityInternal],
            [NoteVisibility.public, m.noteVisibilityPublic],
        ]);

        const visibilityOptions: DropdownItemProps[] = [
            { text: formatMessage(m.noteVisibilityInternal), value: NoteVisibility.internal },
            { text: formatMessage(m.noteVisibilityPublic), value: NoteVisibility.public },
        ];

        return (
            <div>
                {this.props.canChangeVisibility &&
                    <React.Fragment>
                        <span style={{ fontWeight: 'normal' }}>{formatMessage(m.noteVisibility)}</span> <Dropdown inline={true} options={visibilityOptions} value={note.visibility} onChange={this.changeNoteVisibility} />
                    </React.Fragment>
                }
                {!this.props.canChangeVisibility &&
                    <React.Fragment>
                        <span style={{ fontWeight: 'normal' }}>{formatMessage(m.noteVisibility)} {formatMessage(visibilityDescription.get(note.visibility) || commonMessages.unknown)}</span>
                    </React.Fragment>
                }
            </div>
        );
    }

    private changeNoteVisibility = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const newVisibility = data.value as NoteVisibility;
        if (this.props.onChangeVisibility) {
            this.props.onChangeVisibility(newVisibility);
        }
    }
}

const connectedComponent = injectIntl(NoteAttachment);
export { connectedComponent as NoteAttachment };