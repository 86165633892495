export interface Media {
    id: number;
    uri: string;
    createdOn?: Date;
    isDisabled?: boolean;
    type: MediaType;
}

export enum MediaType {
    unknown,
    video,
    image
}