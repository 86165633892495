import { defineMessages, MessageDescriptor } from 'react-intl';

import { commonMessages } from '../constants';
import { Notification } from '../state/models';
import { NotificationType } from '../state/models/Notification';

const m = defineMessages({
    factoryRepairCompleted: { id: 'NotificationHelper.factory_repair_completed', defaultMessage: 'The factory repair for the service call {referenceId} has been completed.' },
    partRequired: { id: 'NotificationHelper.part_required', defaultMessage: 'Parts are required to perform the repair for the service call {referenceId}.' },
    inspectionCompleted: { id: 'NotificationHelper.inspection_completed', defaultMessage: 'The inspection for the service call {referenceId} has been completed.' },
    serviceCallUpdatedByClient: { id: 'NotificationHelper.service_call_updated_by_client', defaultMessage: 'A client has made modifications to the service call {referenceId}.' },
    technicianRepairCompleted: { id: 'NotificationHelper.technician_repair_completed', defaultMessage: 'The technician repairs for the service call {referenceId} has been completed.' },
    serviceCallUpdatedByJaymar: { id: 'NotificationHelper.service_call_updated_by_Jaymar', defaultMessage: 'A Jaymar employee has made modifications to the service call {referenceId}.' },
    serviceCallUpdatedBySubcontractor: { id: 'NotificationHelper.service_call_updated_by_Subcontractor', defaultMessage: 'A subcontractor has made modifications to the service call {referenceId}.' },
});

export class NotificationHelper {
    private static notificationMap: Map<NotificationType, MessageDescriptor> = new Map<NotificationType, MessageDescriptor>([
        [NotificationType.unknown, commonMessages.unknown],
        [NotificationType.factoryRepairCompleted, m.factoryRepairCompleted],
        [NotificationType.partRequired, m.partRequired],
        [NotificationType.inspectionCompleted, m.inspectionCompleted],
        [NotificationType.serviceCallUpdatedByClient, m.serviceCallUpdatedByClient],
        [NotificationType.technicianRepairCompleted, m.technicianRepairCompleted],
        [NotificationType.serviceCallUpdatedByJaymar, m.serviceCallUpdatedByJaymar],
        [NotificationType.serviceCallUpdatedBySubcontractor, m.serviceCallUpdatedBySubcontractor]
    ]);

    public static getDescription(notification: Notification): MessageDescriptor {
        return this.notificationMap.get(notification.type) || commonMessages.unknown;
    }
}