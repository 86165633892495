import _ from "lodash";
import React, { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Form, Icon, Message } from "semantic-ui-react";
import styles from './UploadFileInput.module.css';

interface OwnProps {
    required?: boolean;
    error?: boolean;
    isUploading?: boolean;
    allowMultiple?: boolean;
    acceptedFileTypes?: string;
    maximumFileSizeInBytes?: number;

    uploadButtonText?: string;
    uploadInstructions?: string;

    onFilesUpload: (files: FileList) => void;
}

export type UploadFileInputProps =
    & OwnProps;

const m = defineMessages({
    defaultUploadInstructions: { id: 'UploadFileControl.upload_instructions', defaultMessage: 'Select one or more files from your device.' },
    defaultUploadButtonText: { id: 'UploadFileControl.upload_button_text', defaultMessage: 'Upload files' },
    maximumFilesSizeReached: { id: 'UploadFileControl.maximumFilesSizeReached', defaultMessage: 'The uploaded files cannot be bigger than {maximumFilesSize} MB.' },
});

export const UploadFileInput: React.FC<UploadFileInputProps> = (props) => {
    const { formatMessage } = useIntl();
    const [hasMaximumFileSizeError, setHasMaximumFileSizeError] = useState(false);
    const uploadInstructions = props.uploadInstructions || formatMessage(m.defaultUploadInstructions);
    const uploadButtonText = props.uploadButtonText || formatMessage(m.defaultUploadButtonText);

    const handleFilesChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { files } = e.currentTarget;

        if (files) {
            const totalFilesSize = _.sumBy(files, file => file.size);

            if (props.maximumFileSizeInBytes == null || totalFilesSize <= props.maximumFileSizeInBytes) {
                setHasMaximumFileSizeError(false);
                props.onFilesUpload(files);
            } else {
                setHasMaximumFileSizeError(true);
            }
        }
    }

    return (
        <div>
            {hasMaximumFileSizeError && 
                <Message 
                    error 
                    content={formatMessage(m.maximumFilesSizeReached, { maximumFilesSize: (props.maximumFileSizeInBytes || 0) / 1000000 })}
                />
            }
            <Icon name="cloud upload" color="grey" size="huge" style={{ width: '100%' }} />
            <p>
                {uploadInstructions}
                {props.required && <span className={styles.required}>*</span>}
            </p>
            <Form>
                <label className="ui button primary">
                    {uploadButtonText}

                    <input
                        type="file"
                        accept={props.acceptedFileTypes || '*'}
                        style={{ display: 'none' }}
                        multiple={props.allowMultiple || true}
                        onChange={handleFilesChange}
                    />
                </label>
            </Form>
        </div>
    );
};