import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedMessage, FormattedNumber } from 'react-intl';
import { Feed, SemanticICONS, Popup, Icon, List } from 'semantic-ui-react';

import { ServiceCallUpdate, AccountingUpdate, PartsOrderedUpdate } from '../../../state/models';
import { StringHelper } from '../../../state/utils';

export interface PartsOrderedActivityProps {
    activity: ServiceCallUpdate;
}

const m = defineMessages({
    partsOrdered: { id: 'PartsOrderedActivity.parts_ordered_status', defaultMessage: 'Some parts were ordered to repair the defect.' },
    partsCostFormat: { id: 'PartsOrderedActivity.parts_cost_format', defaultMessage: 'The parts cost {amount}' },
    shippingCostFormat: { id: 'PartsOrderedActivity.shipping_cost_format', defaultMessage: 'The shipping cost {amount}' }
});

class PartsOrderedActivity extends React.Component<PartsOrderedActivityProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { activity } = this.props;
        const details = activity as PartsOrderedUpdate;

        const partsCost = <FormattedNumber value={details.partsCost} style="currency" currency={details.currencyCode} />;
        const partsCostDescription = <FormattedMessage {...m.partsCostFormat} values={{ 'amount': partsCost }} />;
        const shippingCost = <FormattedNumber value={details.shippingCost} style="currency" currency={details.currencyCode} />;
        const shippingCostDescription = <FormattedMessage {...m.shippingCostFormat} values={{ 'amount': shippingCost }} />;

        return (
            <Feed.Event key={activity.id}>
                <Feed.Label icon="cogs" />
                <Feed.Content>
                    <Popup
                        trigger={<Feed.Date content={moment.utc(activity.createdOn).fromNow()} />}
                        content={moment.utc(activity.createdOn).format('LL')}
                    />

                    <Feed.Summary>
                        <FormattedMessage {...m.partsOrdered} />
                    </Feed.Summary>

                    <Feed.Extra text={true}>
                        <List bulleted={true}>
                            {StringHelper.hasValue(details.partsDescription) && <List.Item content={details.partsDescription} />}
                            {details.partsCost > 0 && <List.Item content={partsCostDescription} />}
                            {details.shippingCost > 0 && <List.Item content={shippingCostDescription} />}
                        </List>

                        {StringHelper.hasValue(activity.note) &&
                            <p style={{ whiteSpace: 'pre-line' }}>{activity.note}</p>
                        }
                    </Feed.Extra>
                </Feed.Content>
            </Feed.Event>
        );
    }
}

const connectedComponent = injectIntl(PartsOrderedActivity);
export { connectedComponent as PartsOrderedActivity };