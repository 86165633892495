import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';
import { Dropdown, DropdownItemProps, SemanticICONS, DropdownProps } from 'semantic-ui-react';
import { ProductionComponent, ItemType } from '../../../state/models';
import { itemTypeMessages } from '../../../constants';

export interface ProductionComponentsDropdownProps extends DropdownProps {
    products: ProductionComponent[];
    onSelectProductionComponent: (productionComponentId: number) => void;
    onDeselectProductionComponent: (productionComponentId: number) => void;
}

interface ItemTypeContent {
    icon: SemanticICONS;
    description: MessageDescriptor;
}

interface ProductionComponentsDropdownState {
    selectedComponentsId: number[];
}

class ProductionComponentsDropdown extends React.Component<ProductionComponentsDropdownProps & WrappedComponentProps, ProductionComponentsDropdownState> {
    readonly iconMap: Map<ItemType, ItemTypeContent> = new Map<ItemType, ItemTypeContent>([
        [ItemType.finishedProduct, { icon: 'archive', description: itemTypeMessages.product }],
        [ItemType.assembled, { icon: 'cubes', description: itemTypeMessages.assembled }],
        [ItemType.template, { icon: 'cube', description: itemTypeMessages.template }],
        [ItemType.part, { icon: 'setting', description: itemTypeMessages.parts }],
    ]);

    public constructor(props: ProductionComponentsDropdownProps & WrappedComponentProps) {
        super(props);

        this.state = { selectedComponentsId: [] };
    }
    
    public render() {
        const { intl, products, onSelectProductionComponent, onDeselectProductionComponent, ...dropdownProps } = this.props;

        return (
            <Dropdown 
                multiple={true}
                selection={true}
                search={true}
                deburr={true}
                options={this.buildQuickSelectionOptions(this.props.products)}
                onChange={this.handleChange}
                {...dropdownProps}
            />
        );
    }

    private buildQuickSelectionOptions(components: ProductionComponent[], options?: Array<DropdownItemProps>): Array<DropdownItemProps> {
        const { formatMessage } = this.props.intl;

        options = options || [];

        if (components.length > 0) {
            components.forEach(x => {
                const itemTypeContent = this.iconMap.get(x.item.type);

                options = options || [];
                options.push({
                    key: x.id,
                    text: x.item.id + ' - ' + x.item.frenchDescription,
                    value: x.id,
                    icon: itemTypeContent ? itemTypeContent.icon : 'question',
                    description: itemTypeContent ? formatMessage(itemTypeContent.description) : ''
                });

                this.buildQuickSelectionOptions(x.children, options);
            });
        }

        return options;
    }

    private handleChange = (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const newSelectedComponentIds = data.value as number[];
        const componentsAdded = newSelectedComponentIds.filter(x => this.state.selectedComponentsId.indexOf(x) < 0);
        const componentsRemoved = this.state.selectedComponentsId.filter(x => newSelectedComponentIds.indexOf(x) < 0);
        
        if (componentsRemoved.length > 0) {
            componentsRemoved.forEach(x => this.props.onDeselectProductionComponent(x));
        }

        if (componentsAdded.length > 0) {
            componentsAdded.forEach(x => this.props.onSelectProductionComponent(x));
        }

        this.setState({ selectedComponentsId: newSelectedComponentIds });
    }
}

const intlComponent = injectIntl(ProductionComponentsDropdown);
export { intlComponent as ProductionComponentsDropdown };