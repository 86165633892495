import { useMutation, useQueries } from '@tanstack/react-query';
import { MediaGallery } from 'components/common';
import { useIntl } from 'react-intl';
import { Header } from 'semantic-ui-react';
import { AttachmentType, Media } from 'state/models';
import { AttachmentHelper } from 'state/utils';
import useApiErrorHandler from 'utils/ApiErrorHandler';
import {
    deleteSubcontractorRepairPicture,
    getSubcontractorRepairPicture
} from '../../api';
import { useSubcontractorRepairDetailsContext } from '../SubcontractorRepairDetailsProvider';

export const RepairMediaGallery = () => {
    const { formatMessage } = useIntl();
    const handleError = useApiErrorHandler();

    const { repairDetails, invalidateRepairDetails } =
        useSubcontractorRepairDetailsContext();

    const pictures = (repairDetails?.medias || []).filter(
        (x) => x.type === AttachmentType.subcontractorRepairImage
    );

    const hasPictures = pictures.length > 0;

    const pictureQueries = useQueries({
        queries: pictures.map((picture) => {
            return {
                queryKey: ['subcontractor_repair_picture', picture.id],
                queryFn: () =>
                    getSubcontractorRepairPicture(
                        picture.id,
                        picture.contentType
                    ).then((blob) => ({
                        id: picture.id,
                        createdOn: picture.createdOn,
                        type: AttachmentHelper.toMediaType(picture.type),
                        uri: blob ? URL.createObjectURL(blob) : '',
                    })),
                refetchOnWindowFocus: false,
            };
        }),
    });

    const medias: Media[] = pictureQueries
        .map((query) => query.data as Media)
        .filter((x) => x != null);

    const { mutate: deleteMedia } = useMutation({
        mutationFn: (attachmentId: number) => {
            return deleteSubcontractorRepairPicture(attachmentId);
        },
        onSuccess: () => {
            invalidateRepairDetails();
        },
        onError: (error) => handleError(error),
    });

    return hasPictures ? (
        <MediaGallery medias={medias} canEdit onDeleteMedia={deleteMedia} />
    ) : (
        <Header as="h4">
            {formatMessage({
                id: 'SubcontractorRepairPage.RepairMediaGallery.NoPictures',
                defaultMessage: 'No pictures',
            })}
        </Header>
    );
};
