import { User } from 'state/models';
import { get, getVoid, post, postVoid } from '../../utils/api';
import { LoginEdit, ResetPasswordEdit, UserProfileEdit } from './models';
import { UserSession } from './types';

const accountEndpoint = `/api/account`;
const userProfileEndpoint = `/api/current-user`;

export async function login(credentials: LoginEdit): Promise<void> {
    return postVoid(`${accountEndpoint}/login`, credentials);
}

export async function logout(): Promise<void> {
    return getVoid(`${accountEndpoint}/logout`);
}

export async function loadUserSession(): Promise<UserSession> {
    return get<UserSession>(`${accountEndpoint}/session`);
}

export async function requestPasswordReset(email: string): Promise<void> {
    return postVoid(`${accountEndpoint}/request-password-reset`, { email });
}

export async function resetPassword(data: ResetPasswordEdit): Promise<void> {
    return postVoid(`${accountEndpoint}/reset-password`, data);
}

export async function updateProfile(profile: UserProfileEdit): Promise<User> {
    return post<User>(`${userProfileEndpoint}`, profile);
}