import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, Input, InputOnChangeData, Label } from 'semantic-ui-react';
import { unitMessages } from '../../constants';

export interface NumericInputProps {
    value: number;
    unit?: string;
    minimumValue?: number;
    maximumValue?: number;
    disabled?: boolean;
    onChange: (newValue: number) => void;
}

interface NumericInputState {
    rawValue: string;
}

class NumericInput extends React.Component<NumericInputProps & WrappedComponentProps, NumericInputState> {
    public constructor(props: NumericInputProps & WrappedComponentProps) {
        super(props);

        this.state = { rawValue: String(props.value) };
    }

    public componentWillReceiveProps(props: NumericInputProps & WrappedComponentProps) {
        this.setState({ rawValue: String(props.value) });
    }

    public render() {
        const unitDescriptionMessage = this.props.unit ? unitMessages[this.props.unit.toLowerCase()] : undefined;
        const unitDescription = unitDescriptionMessage ? this.props.intl.formatMessage(unitDescriptionMessage) : '';
        const unitLabel = this.props.unit && this.props.unit !== 'EA' ? <Label basic={true} content={unitDescription.toLowerCase()} /> : undefined;
        const unitLabelPosition = unitLabel != null ? 'right' : undefined;

        const maximumValue = this.props.maximumValue || 99;
        const isIncrementDisabled = this.props.disabled || this.props.value >= maximumValue;
        const minimumValue = this.props.minimumValue || 0;
        const isDecrementDisabled = this.props.disabled || this.props.value <= minimumValue;

        return (
            <div className="c-numeric-input" style={{display: 'inline-block'}}>
                <Button icon="minus" size="mini" disabled={isDecrementDisabled} onClick={this.handleDecrement} />
                <Input
                    className="c-numeric-input__input-container"
                    type="text" 
                    size="mini" 
                    pattern="\d*\.?\d*"
                    disabled={this.props.disabled}
                    label={unitLabel}
                    labelPosition={unitLabelPosition}
                    style={{marginRight: 5}} 
                    value={this.state.rawValue} 
                    onChange={this.handleChange}
                    onBlur={this.confirmChange}
                />
                <Button icon="plus" size="mini" disabled={isIncrementDisabled} onClick={this.handleIncrement} />
            </div>
        );
    }

    private handleChange = (event: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) => {
        this.setState({ rawValue: data.value });

        if (!data.value.endsWith('.')) {
            const maximumValue = this.props.maximumValue || 99;
            const newValue = Number(data.value);

            if (!isNaN(newValue)) {
                this.props.onChange(Math.min(newValue, maximumValue));
            }
        }
    }

    private confirmChange = () => {
        const maximumValue = this.props.maximumValue || 99;
        const newValue = Number(this.state.rawValue);

        if (!isNaN(newValue)) {
            this.props.onChange(Math.min(newValue, maximumValue));
        } else {
            this.setState({ rawValue: String(this.props.value) });
        }
    }

    private handleIncrement = () => {
        const maximumValue = this.props.maximumValue || 99;
        const newValue = this.props.value + 1;

        this.props.onChange(Math.min(newValue, maximumValue));
    }

    private handleDecrement = () => {
        const minimumValue = this.props.minimumValue || 0;
        const newValue = this.props.value - 1;

        if (newValue >= minimumValue) {
            this.props.onChange(Math.max(newValue, minimumValue));
        }
    }
}

const intlComponent = injectIntl(NumericInput);
export { intlComponent as NumericInput };