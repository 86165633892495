// tslint:disable:no-any
export function debounce(fn: any, delay: number, onSearchDebouncing?: (args?: any) => void, onSearchDebounced?: (args?: any) => void) {
    var timer: any = null;

    return function (this: any) {
        var context: any = this;
        var args: any = arguments;

        clearTimeout(timer);

        if (onSearchDebouncing) {
            onSearchDebouncing(args);
        }

        timer = setTimeout(
            function () {
                if (onSearchDebounced) {
                    onSearchDebounced(args);
                }

                fn.apply(context, args);
            },
            delay);
    };
}