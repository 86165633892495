import { ResetPasswordEdit } from "../../../models";
import React from "react";
import { FieldErrors } from "react-hook-form";
import { Message, MessageProps } from 'semantic-ui-react';

interface OwnProps {
    errors: FieldErrors<ResetPasswordEdit>;
}

export type ResetPasswordValidationSummaryProps =
    & MessageProps
    & OwnProps;

export const ResetPasswordValidationSummary: React.FC<ResetPasswordValidationSummaryProps> = (props) => {
    const { errors, ...messageProps } = props;

    const errorMessages = [
        props.errors.password?.message,
        props.errors.passwordConfirmation?.message,
    ].filter(x => x != null);

    if (errorMessages.length <= 0) {
        return <></>;
    }

    return (
        <Message error list={errorMessages} {...messageProps} />
    );
};