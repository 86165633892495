import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';
import { Label, LabelProps, SemanticCOLORS } from 'semantic-ui-react';

import { ServiceCallStatus, ServiceCall } from '../../state/models';
import { serviceCallMessages as m } from '../../constants';

export interface ServiceCallStatusLabelProps extends LabelProps {
    status?: ServiceCallStatus;
}

interface LabelContent {
    value: MessageDescriptor;
    color: SemanticCOLORS;
}

// We can't initialize the map using the "Map<...>([iterable])" constructor, because it isn't supported on IE 11.
var statusMap: Map<ServiceCallStatus, LabelContent> = new Map<ServiceCallStatus, LabelContent>();
statusMap.set(ServiceCallStatus.unknown, { value: m.unknown, color: 'red' });
statusMap.set(ServiceCallStatus.created, { value: m.new, color: 'violet' });
statusMap.set(ServiceCallStatus.assignedToAgent, { value: m.open, color: 'teal' });
statusMap.set(ServiceCallStatus.awaitingInspection, { value: m.inspection, color: 'yellow' });
statusMap.set(ServiceCallStatus.inspected, { value: m.inspected, color: 'yellow' });
statusMap.set(ServiceCallStatus.awaitingFactoryRepairs, { value: m.repairs, color: 'orange' });
statusMap.set(ServiceCallStatus.awaitingSubcontractorRepairs, { value: m.repairs, color: 'orange' });
statusMap.set(ServiceCallStatus.awaitingTechnicianRepairs, { value: m.repairs, color: 'orange' });
statusMap.set(ServiceCallStatus.repaired, { value: m.repaired, color: 'blue' });
statusMap.set(ServiceCallStatus.closed, { value: m.closed, color: 'green' });
statusMap.set(ServiceCallStatus.draft, { value: m.draft, color: 'violet' });

export function getStatusColor(status?: ServiceCallStatus): SemanticCOLORS {
    const labelContent = statusMap.get(status || ServiceCallStatus.unknown);

    if (labelContent) {
        return labelContent.color;
    }

    return 'red';
}

export function getStatusDescription(status?: ServiceCallStatus): MessageDescriptor {
    const labelContent = statusMap.get(status || ServiceCallStatus.unknown);

    if (labelContent) {
        return labelContent.value;
    }

    return m.unknown;
}

class ServiceCallStatusLabel extends React.Component<ServiceCallStatusLabelProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { status, intl, ...labelProps } = this.props;

        const statusDescription = formatMessage(getStatusDescription(this.props.status));
        const statusColor = getStatusColor(this.props.status);
        const isDraft = status === ServiceCallStatus.draft;

        return (
            <Label
                {...labelProps}
                basic={isDraft}
                content={statusDescription}
                color={statusColor}
            />
        );
    }
}

const connectedComponent = injectIntl(ServiceCallStatusLabel);
export { connectedComponent as ServiceCallStatusLabel };