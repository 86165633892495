import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Card, Input, Form, Divider, Label, Popup, List, Dropdown, DropdownItemProps, DropdownProps, Button } from 'semantic-ui-react';
import { Defect, DefectCategory, DefectType, WarrantyType } from '../../../state/models';
import { commonMessages, defectCategoryMessages, defectTypeMessages, defectCauseMessages } from '../../../constants';
import { WarrantyStatusLabel } from '..';

export interface SelectDefectFormProps {
    allDefects: Defect[];
    selectedDefect?: Defect;

    onDefectSelected?: (defect: Defect) => void;
    onDefectCleared?: () => void;
}

interface SelectDefectFormState {
    allDefects: DropdownItemProps[];
    allCategories: DropdownItemProps[];
    typesByCategory: DropdownItemProps[];
    defectsByCategoryAndType: DropdownItemProps[];
    selectedCategoryId?: number;
    selectedTypeId?: number;
    selectedDefectId?: number;
    selectedDefect?: Defect;
}

const m = defineMessages({
    defectResult: { id: 'SelectDefectForm.defect_result', defaultMessage: '{id} - {problem} - {defect}'},
    quickSelection: { id: 'SelectDefectForm.quick_selection', defaultMessage: 'Quick selection' },
    category: { id: 'SelectDefectForm.category', defaultMessage: 'Category' },
    problem: { id: 'SelectDefectForm.problem', defaultMessage: 'Problem' },
    rootCause: { id: 'SelectDefectForm.root_cause', defaultMessage: 'Root cause' }
});

class SelectDefectForm extends React.Component<SelectDefectFormProps & WrappedComponentProps, SelectDefectFormState> {
    public constructor(props: SelectDefectFormProps & WrappedComponentProps) {
        super(props);

        if (props) {
            const { allDefects, selectedDefect } = props;

            this.state = {
                allDefects: this.initializeDefectsQuickSelection(allDefects),
                selectedCategoryId: selectedDefect ? selectedDefect.defectCategory.id : undefined,
                allCategories: this.initializeCategories(allDefects),
                selectedTypeId: selectedDefect ? selectedDefect.defectType.id : undefined,
                typesByCategory: selectedDefect ? this.initializeTypes(allDefects, selectedDefect.defectCategory.id) : [],
                selectedDefectId: selectedDefect ? selectedDefect.id : undefined,
                defectsByCategoryAndType: selectedDefect ? this.initializeDefects(allDefects, selectedDefect.defectCategory.id, selectedDefect.defectType.id) : [],
                selectedDefect
            };
        }
    }

    public componentWillReceiveProps(props?: SelectDefectFormProps & WrappedComponentProps) {
        if (props) {
            const { allDefects, selectedDefect } = props;

            this.setState((current) => ({
                allDefects: this.initializeDefectsQuickSelection(allDefects),
                allCategories: this.initializeCategories(allDefects),
                typesByCategory: selectedDefect ? this.initializeTypes(allDefects, selectedDefect.defectCategory.id) : [],
                defectsByCategoryAndType: selectedDefect ? this.initializeDefects(allDefects, selectedDefect.defectCategory.id, selectedDefect.defectType.id) : [],
                selectedDefect
            }));
        }
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const { selectedDefect } = this.state;

        return (
            <Form>
                <Form.Dropdown 
                    label={formatMessage(m.quickSelection)}
                    value={this.state.selectedDefectId}
                    options={this.state.allDefects} 
                    onChange={this.handleQuickSelectionChange}
                    search={true} 
                    selection={true} 
                    deburr={true}
                />

                <div style={{textAlign: 'right'}}>
                    <a onClick={this.clearSelection}>{formatMessage(commonMessages.clear)}</a>
                </div>

                <Divider horizontal={true}>{formatMessage(commonMessages.or)}</Divider>

                <Form.Dropdown 
                    label={formatMessage(m.category)}
                    value={this.state.selectedCategoryId}
                    options={this.state.allCategories}
                    onChange={this.handleCategoryChange}
                    fluid={true}
                    search={true}
                    selection={true}
                    deburr={true}
                    disabled={this.state.allCategories.length <= 0}
                />

                <Form.Dropdown 
                    label={formatMessage(m.problem)}
                    value={this.state.selectedTypeId}
                    options={this.state.typesByCategory}
                    onChange={this.handleTypeChange}
                    fluid={true}
                    search={true}
                    selection={true}
                    deburr={true}
                    disabled={this.state.typesByCategory.length <= 0}
                />

                <Form.Dropdown 
                    label={formatMessage(m.rootCause)}
                    value={this.state.selectedDefectId}
                    options={this.state.defectsByCategoryAndType}
                    onChange={this.handleDefectChange}
                    fluid={true} 
                    search={true} 
                    selection={true}
                    deburr={true}
                    disabled={this.state.defectsByCategoryAndType.length <= 0}
                />
            </Form>
        );
    }

    private handleQuickSelectionChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const selectedDefectId = data.value as number;
        const selectedDefect = this.selectDefect(selectedDefectId);

        if (selectedDefect) {
            const selectedCategoryId = selectedDefect.defectCategory.id;
            const selectedTypeId = selectedDefect.defectType.id;
            const availableTypes = this.initializeTypes(this.props.allDefects, selectedCategoryId);
            const availableDefects = this.initializeDefects(this.props.allDefects, selectedCategoryId, selectedTypeId);

            this.setState((current) => ({
                selectedCategoryId: selectedCategoryId,
                selectedTypeId: selectedTypeId,
                typesByCategory: availableTypes,
                selectedDefectId: selectedDefectId,
                selectedDefect: this.props.allDefects.find(x => x.id === selectedDefectId),
                defectsByCategoryAndType: availableDefects
            }));
        }
    }

    private handleCategoryChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const selectedCategoryId = data.value as number;

        if (selectedCategoryId !== this.state.selectedCategoryId) {
            const availableTypes = this.initializeTypes(this.props.allDefects, selectedCategoryId);
            const selectedTypeId = availableTypes.length === 1 ? availableTypes[0].value as number : undefined;
            const availableDefects = selectedTypeId ? this.initializeDefects(this.props.allDefects, selectedCategoryId, selectedTypeId) : [];
            const selectedDefectId = availableDefects.length === 1 ? availableDefects[0].value as number : undefined;
            const selectedDefect = selectedDefectId ? this.selectDefect(selectedDefectId) : undefined;

            this.setState((current) => ({
                selectedCategoryId: selectedCategoryId,
                selectedTypeId: selectedTypeId,
                typesByCategory: availableTypes,
                selectedDefectId: selectedDefectId,
                selectedDefect: selectedDefect,
                defectsByCategoryAndType: availableDefects
            }));
        }
    }

    private handleTypeChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const { formatMessage } = this.props.intl;
        const selectedTypeId = data.value as number;

        if (selectedTypeId !== this.state.selectedTypeId) {
            const availableDefects = this.initializeDefects(this.props.allDefects, this.state.selectedCategoryId, selectedTypeId);
            const toBeDeterminedCause = availableDefects.find(x => x.text === formatMessage(defectCauseMessages.toBeDetermined));
            const toBeDeterminedCauseId = toBeDeterminedCause ? toBeDeterminedCause.value as number : undefined;

            const selectedDefectId = availableDefects.length === 1 ? availableDefects[0].value as number : toBeDeterminedCauseId;
            const selectedDefect = selectedDefectId ? this.selectDefect(selectedDefectId) : undefined;

            this.setState({
                selectedTypeId: selectedTypeId,
                selectedDefectId: selectedDefectId,
                selectedDefect: selectedDefect,
                defectsByCategoryAndType: availableDefects
            });
        }
    }

    private handleDefectChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const selectedDefectId = data.value as number;
        const selectedDefect = this.selectDefect(selectedDefectId);

        this.setState({
            selectedDefectId: selectedDefectId,
            selectedDefect: selectedDefect
        });
    }

    private selectDefect(defectId: number): Defect | undefined {
        const defect = this.props.allDefects.find(x => x.id === defectId);

        if (defect) {
            this.setState({
                selectedDefect: defect
            });

            if (this.props.onDefectSelected) {
                this.props.onDefectSelected(defect);
            }
        }

        return defect;
    }

    private clearSelection = () => {
        this.setState({
            selectedCategoryId: undefined,
            selectedTypeId: undefined,
            selectedDefectId: undefined,
            selectedDefect: undefined,
        });

        if (this.props.onDefectCleared) {
            this.props.onDefectCleared();
        }
    }

    private initializeCategories(defects: Defect[]): DropdownItemProps[] {
        const { formatMessage } = this.props.intl;
        const categoryMessages = Object.keys(defectCategoryMessages).map(key => defectCategoryMessages[key]);

        const categories = defects
            .map((x): string => x.defectCategory.title)
            .filter((value, index, self) => self.indexOf(value) === index)
            .map((x): DropdownItemProps => {
                const formattedCategory = categoryMessages.find(y => y.id === x);
                const targetCategory = defects.map(y => y.defectCategory).find(y => y.title === x);
                const id = targetCategory ? targetCategory.id : 0;

                return {
                    key: id,
                    value: id,
                    text: formattedCategory ? formatMessage(formattedCategory) : x
                };
            })
            .sort(this.sortByText);

        return categories;
    }

    private initializeTypes(defects: Defect[], categoryId?: number): DropdownItemProps[] {
        const { formatMessage } = this.props.intl;
        const typeMessages = Object.keys(defectTypeMessages).map(key => defectTypeMessages[key]);

        const types = defects
            .filter(x => categoryId ? x.defectCategory.id === categoryId : true)
            .map(x => x.defectType.title)
            .filter((value, index, self) => self.indexOf(value) === index)
            .map((x): DropdownItemProps => {
                const formattedType = typeMessages.find(y => y.id === x);
                const targetType = defects
                    .filter(y => categoryId ? y.defectCategory.id === categoryId : true)
                    .map(y => y.defectType)
                    .find(y => y.title === x);

                const id = targetType ? targetType.id : 0;

                return {
                    key: id,
                    value: id,
                    text: formattedType ? formatMessage(formattedType) : x
                };
            })
            .sort(this.sortByText);

        return types;
    }

    private initializeDefectsQuickSelection(defects: Defect[]): DropdownItemProps[] {
        const { formatMessage } = this.props.intl;
        const categoryMessages = Object.keys(defectCategoryMessages).map(key => defectCategoryMessages[key]);
        const typeMessages = Object.keys(defectTypeMessages).map(key => defectTypeMessages[key]);
        const causeMessages = Object.keys(defectCauseMessages).map(key => defectCauseMessages[key]);

        return this.props.allDefects
            .map((x: Defect): DropdownItemProps => {
                const formattedCategory = categoryMessages.find(y => y.id === x.defectCategory.title);
                const formattedType = typeMessages.find(y => y.id === x.defectType.title);
                const formattedCause = causeMessages.find(y => y.id === x.title);

                return {
                    key: x.id,
                    value: x.id,
                    text: formatMessage(m.defectResult, { 
                        id: x.id, 
                        problem: formattedType ? formatMessage(formattedType) : x.defectType.title, 
                        defect: formattedCause ? formatMessage(formattedCause) : x.title
                    }),
                    description: formattedCategory ? formatMessage(formattedCategory) : x.defectCategory.title
                };
            });
    }

    private initializeDefects(defects: Defect[], categoryId?: number, typeId?: number): DropdownItemProps[] {
        const { formatMessage } = this.props.intl;
        const causeMessages = Object.keys(defectCauseMessages).map(key => defectCauseMessages[key]);

        const availableDefects = this.props.allDefects
            .filter(x => categoryId ? x.defectCategory.id === categoryId : true)
            .filter(x => typeId ? x.defectType.id === typeId : true)
            .map((x: Defect): DropdownItemProps => {
                const formattedCause = causeMessages.find(y => y.id === x.title);

                return {
                    key: x.id,
                    value: x.id,
                    text: formattedCause ? formatMessage(formattedCause) : x.title
                };
            });

        return availableDefects;
    }

    private sortByText(a: DropdownItemProps, b: DropdownItemProps) {
        const aValue = a.text as string || '';
        const bValue = b.text as string || '';

        return aValue.localeCompare(bValue);
    }
}

const connectedComponent = injectIntl(SelectDefectForm);
export { connectedComponent as SelectDefectForm };