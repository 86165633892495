import * as React from 'react';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { Card, Form, Grid, TextArea, TextAreaProps } from 'semantic-ui-react';

import { FactoryReport } from '../../../state/models';
import { debounce } from '../../../state/utils';
import { LoadingDimmer, TimeInput } from '../../common';

interface FactoryReportCardOwnProps {
    isLoading: boolean;
    isDisabled: boolean;
    factoryReport?: FactoryReport;
    onSave: (factoryReport: FactoryReport) => void;
}

interface FactoryReportCardState {
    factoryReport: FactoryReport;
}

export type FactoryReportCardProps =
    & FactoryReportCardOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'FactoryReportCard.title', defaultMessage: 'Service report' },
    subtitle: { id: 'FactoryReportCard.subtitle', defaultMessage: 'Write the details about the work that was performed.' },
    labourTime: { id: 'FactoryReportCard.labour_time', defaultMessage: 'Labour time' }
});

class FactoryReportCard extends React.Component<FactoryReportCardProps, FactoryReportCardState> {
    public constructor(props: FactoryReportCardProps) {
        super(props);

        this.state = {
            factoryReport: props.factoryReport || {
                report: '',
                hoursTaken: 0,
                minutesTaken: 0,
                factoryRepairId: 0,
                createdBy: undefined
            }
        };
    }

    public componentWillReceiveProps(props: FactoryReportCardProps) {
        if ((props.factoryReport != null && this.props.factoryReport == null)
            || (props.factoryReport != null && this.props.factoryReport != null && props.factoryReport.report !== '' && this.props.factoryReport.report === '')) {
            this.setState({ factoryReport: props.factoryReport });
        }
    }

    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <Card fluid={true} color="teal" style={{ height: '100%' }}>
                <Card.Content style={{ flexGrow: 0 }}>
                    <Card.Header content={formatMessage(m.title)} />
                    <Card.Meta content={formatMessage(m.subtitle)} />
                </Card.Content>
                <Card.Content>
                    <LoadingDimmer active={this.props.isLoading} />
                    <Grid stackable={true}>
                        <Grid.Row>
                            <Grid.Column width={11}>
                                <Form>
                                    <Form.Field disabled={this.props.isDisabled}>
                                        <TextArea
                                            rows={10}
                                            onChange={this.handleReportChange}
                                            value={this.state.factoryReport.report || ''}
                                        />
                                    </Form.Field>
                                </Form>
                            </Grid.Column>

                            <Grid.Column width={5}>
                                <Form>
                                    <Form.Field disabled={this.props.isDisabled}>
                                        <label>{formatMessage(m.labourTime)}</label>
                                        <TimeInput
                                            hours={this.state.factoryReport.hoursTaken}
                                            minutes={this.state.factoryReport.minutesTaken}
                                            onChange={this.handleTimeChange}
                                        />
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Card.Content>
            </Card>
        );
    }

    private handleReportChange = (_event: React.FormEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
        this.setState(
            (current) => ({
                factoryReport: {
                    ...current.factoryReport,
                    report: data.value as string || ''
                }
            }),
            () => this.saveChanges(this.state.factoryReport));
    }

    private handleTimeChange = (hours: number, minutes: number) => {
        this.setState(
            (current) => ({
                factoryReport: {
                    ...current.factoryReport,
                    hoursTaken: hours,
                    minutesTaken: minutes
                }
            }),
            () => this.saveChanges(this.state.factoryReport));
    }

    // tslint:disable-next-line:member-ordering
    private saveChanges: (report: FactoryReport) => void =
        debounce((report: FactoryReport) => this.props.onSave(report), 1000);
}

const connectedComponent = injectIntl(FactoryReportCard);
export { connectedComponent as FactoryReportCard };