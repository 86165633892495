import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Icon, List } from 'semantic-ui-react';
import { commonMessages } from '../../../../../constants';
import { useSubcontractorRepairDetailsContext } from '../SubcontractorRepairDetailsProvider';
import { BillDetails } from './BillDetails';
import { SubcontractorBillModal } from './SubcontractorBillModal';

export const SubContractorBillSection = () => {
    const { formatMessage } = useIntl();

    const { repairDetails, hasBillDetails } = useSubcontractorRepairDetailsContext();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleEditBillDetailsRequest = () => {
        setIsModalOpen(true);
    };

    if (!repairDetails?.appointmentDate) {
        return null;
    }

    return (
        <>
            <List.Item>
                <List.Content floated="right">
                    {hasBillDetails ? (
                        <Button
                            positive
                            fluid
                            icon="check"
                            content={formatMessage(commonMessages.modify)}
                            onClick={handleEditBillDetailsRequest}
                        />
                    ) : (
                        <Button
                            primary
                            fluid
                            content={formatMessage({
                                id: 'SubcontractorRepairPage.SubContractorBillSection.AddInvoice',
                                defaultMessage: 'Add your invoice',
                            })}
                            onClick={handleEditBillDetailsRequest}
                        />
                    )}
                </List.Content>
                <Icon name="dollar" size="large" />
                <List.Content>
                    <List.Header as="h3" style={{ marginBottom: '1rem' }}>
                        {formatMessage({
                            id: 'SubcontractorRepairPage.SubContractorBillSection.Title',
                            defaultMessage: 'Invoice',
                        })}
                    </List.Header>
                    {hasBillDetails && <BillDetails />}
                </List.Content>
            </List.Item>
            <SubcontractorBillModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            />
        </>
    );
};
