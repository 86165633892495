import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import { ApplicationState } from '../../../state/ducks';
import { actionCreators as UiActions } from '../../../state/ducks/ui';
import { actionCreators as FactoryRepairsActions } from '../../../state/ducks/factory-repairs';
import { FactoryRepairsTable } from '../../../components/service-calls/factory';
import { ServiceCallFactoryRepair } from '../../../state/models';
import { areFactoryRepairsByServiceCallLoading, getFactoryRepairsByServiceCall } from '../../../state/ducks/factory-repairs/selectors';
import { FactoryRepairsList } from '../../../components/service-calls/factory/FactoryRepairsList';
import { useResponsive } from '../../../utils/responsive';

const m = defineMessages({
    noRepairsTitle: { id: 'AllFactoryRepairsPage.no_repairs_title', defaultMessage: 'No repairs' },
    noRepairsSubtitle: { id: 'AllFactoryRepairsPage.no_repairs_subtitle', defaultMessage: 'There are no factory repairs associated to this service call.' }
});

interface RouteProps {
    serviceCallId: string;
}

export const AllFactoryRepairsPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const { isMobile } = useResponsive();
    const { serviceCallId } = useParams<RouteProps>();

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(UiActions.changeServiceCallTab('factory'));
        dispatch(FactoryRepairsActions.loadByServiceCall(Number(serviceCallId)));
    }, [dispatch, serviceCallId]);

    const factoryRepairs = useSelector((state: ApplicationState) => getFactoryRepairsByServiceCall(state, Number(serviceCallId)));
    const isLoading = useSelector((state: ApplicationState) => areFactoryRepairsByServiceCallLoading(state, Number(serviceCallId)));

    const navigateToFactoryPage = (repairs: ServiceCallFactoryRepair) => 
        history.push(`/service-calls/${repairs.serviceCallId}/factory-repairs/${repairs.id}/prepare`);

    return (
        <React.Fragment>
            {!isMobile &&
                <FactoryRepairsTable
                    repairs={factoryRepairs}
                    isLoading={isLoading}
                    emptyTitle={formatMessage(m.noRepairsTitle)}
                    emptySubtitle={formatMessage(m.noRepairsSubtitle)}
                    onViewDetails={navigateToFactoryPage}
                    isRequestedHeaderHidden={true}
                />
            }

            {isMobile &&
                <FactoryRepairsList
                    repairs={factoryRepairs}
                    isLoading={isLoading}
                    emptyTitle={formatMessage(m.noRepairsTitle)}
                    emptySubtitle={formatMessage(m.noRepairsSubtitle)}
                    onViewDetails={navigateToFactoryPage}
                />
            }
        </React.Fragment>
    );
};