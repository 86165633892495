export enum ActionTypeKeys {
    LOAD_ALL_REQUEST = 'LOAD_ALL_NOTIFICATIONS_REQUEST',
    LOAD_ALL_SUCCESS = 'LOAD_ALL_NOTIFICATIONS_SUCCESS',
    LOAD_ALL_FAILURE = 'LOAD_ALL_NOTIFICATIONS_FAILURE',

    LOAD_UNREAD_REQUEST = 'LOAD_ALL_UNREA_REQUEST',
    LOAD_UNREAD_SUCCESS = 'LOAD_ALL_UNREA_SUCCESS',
    LOAD_UNREAD_FAILURE = 'LOAD_ALL_UNREA_FAILURE',

    READ_REQUEST = 'READ_NOTIFICATION_REQUEST',
    READ_SUCCESS = 'READ_NOTIFICATION_SUCCESS',
    READ_FAILURE = 'READ_NOTIFICATION_FAILURE',

    READ_ALL_REQUEST = 'READ_ALL_NOTIFICATION_REQUEST',
    READ_ALL_SUCCESS = 'READ_ALL_NOTIFICATION_SUCCESS',
    READ_ALL_FAILURE = 'READ_ALL_NOTIFICATION_FAILURE',
}