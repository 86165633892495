import * as React from 'react';
import { ServiceCall } from '../../../state/models';
import { Table } from 'semantic-ui-react';
import { ServiceCallStatusLabel } from '..';
import { CustomerBillSummary } from '../CustomerBillSummary';
import { DateFromNow } from '../../common/DateFromNow';

interface OwnProps {
    serviceCall: ServiceCall;
    onClick?: () => void;
}

export type RecentServiceCallsProps =
    & OwnProps;

export class RecentServiceCallRow extends React.Component<RecentServiceCallsProps> {
    public render() {
        const { serviceCall } = this.props;
        
        return (
            <Table.Row onClick={this.props.onClick}>
                <Table.Cell><ServiceCallStatusLabel status={serviceCall.status} /></Table.Cell>
                <Table.Cell>{serviceCall.id}</Table.Cell>
                <Table.Cell><CustomerBillSummary serviceCall={serviceCall} /></Table.Cell>
                <Table.Cell><DateFromNow date={serviceCall.updatedOn} /></Table.Cell>
            </Table.Row>
        );
    }
}