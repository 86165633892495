import { CompanyLogo } from "components/common";
import { CenteredPageLayout } from "components/layouts";
import React from "react";
import { Header } from "semantic-ui-react";
import { LocaleSwitcher } from "../../../../i18n/components";
import styles from './AccountPageLayout.module.css';

interface OwnProps {
    title: string;
}

export type AccountPageLayoutProps =
    & OwnProps;

export const AccountPageLayout: React.FC<AccountPageLayoutProps> = (props) => {
    return (
        <CenteredPageLayout>
            <div className={styles.container}>
                <CompanyLogo />
                <Header as="h2" color="teal">{props.title}</Header>

                {props.children}
            </div>

            <LocaleSwitcher style={{ marginTop: '1rem'}} />
        </CenteredPageLayout>
    );
};