import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../state/ducks';

interface DispatchProps {
}

interface StoreProps {
}

export type OrderSummaryTabProps =
    & StoreProps
    & DispatchProps
    & WrappedComponentProps;

const m = defineMessages({
});

class OrderSummaryTab extends React.Component<OrderSummaryTabProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment />
        );
    }
}

const mapStateToProps = (state: ApplicationState): StoreProps => {
    return {};
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    return {};
};

const intlComponent = injectIntl(OrderSummaryTab);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as OrderSummaryTab };