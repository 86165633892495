import { Reducer } from 'redux';
import { AccountState } from './types';
import { ActionTypeKeys, ActionTypes } from './actions';
import { ActionTypes as CurrentUserActionTypes } from '../../state/ducks/current-user';
import { ActionTypeKeys as CurrentUserActionTypeKeys } from '../../state/ducks/current-user/types';
import _ from 'lodash';
import { ApiError } from 'utils/api';

const initialState: AccountState = {
    isAuthenticated: false,
    isAuthenticating: false,
    isSessionLoading: false,
    hasFailedAuthentication: false,
    userSession: undefined,
};

const accountReducer: Reducer<AccountState> = (state = initialState, action: ActionTypes | CurrentUserActionTypes) => {
    if (action.type !== ActionTypeKeys.LOGIN_FAILURE
        && isUserUnauthorized(action)) {
        return { ...state, isAuthenticated:  false };
    }

    switch (action.type) {
        case ActionTypeKeys.LOGIN_REQUEST: {
            return {
                ...state,
                isAuthenticating: true,
                hasFailedAuthentication: false,
            };
        }

        case ActionTypeKeys.LOGIN_SUCCESS: {
            return {
                ...state,
                isAuthenticated: true,
                isAuthenticating: false,
                hasFailedAuthentication: false,
            };
        }

        case ActionTypeKeys.LOGIN_FAILURE: {
            return {
                ...state,
                isAuthenticated: false,
                isAuthenticating: false,
                hasFailedAuthentication: true,
            };
        }

        case ActionTypeKeys.LOGOUT_REQUEST: {
            return {
                ...state,
                isAuthenticated: false,
                userSession: undefined,
            };
        }

        case ActionTypeKeys.LOAD_USER_SESSION_REQUEST: {
            return {
                ...state,
                isSessionLoading: true,
            }
        }

        case ActionTypeKeys.LOAD_USER_SESSION_SUCCESS: {
            return {
                ...state,
                isAuthenticated: true,
                isSessionLoading: false,
                userSession: action.payload.session,
            };
        }

        case CurrentUserActionTypeKeys.UPDATE_PROFILE_SUCCESS: {
            return {
                ...state,
                userSession: {
                    ...state.userSession,
                    user: action.payload,
                    claims: state.userSession?.claims || {
                        surname: '',
                        givenName: '',
                        clientCodes: [],
                        role: '',
                    }
                }
            };
        }

        case ActionTypeKeys.LOAD_USER_SESSION_FAILURE: {
            return {
                ...state,
                isAuthenticated: false,
                isSessionLoading: false
            }
        }

        default:
            return state;
    }
};

const isUserUnauthorized = (action: ActionTypes | CurrentUserActionTypes): boolean => {
    const hasRequestFailed = (action as any).payload && (action as any).payload instanceof ApiError;

    if (hasRequestFailed) {
        const requestError = (action as any).payload as ApiError;
        return requestError.statusCode === 401;
    }

    return false;
}

export { accountReducer };