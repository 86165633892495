import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Dropdown, DropdownItemProps, StrictDropdownProps } from 'semantic-ui-react';
import { PreferredLanguage } from '../../state/models';

const m = defineMessages({
    french: { id: 'language.french', defaultMessage: 'French' },
    english: { id: 'language.english', defaultMessage: 'English' }
});

export const LanguageDropdown: React.FC<StrictDropdownProps> = (props) => {
    const { formatMessage } = useIntl();

    const languageOptions: Array<DropdownItemProps> = [
        { text: formatMessage(m.french), value: PreferredLanguage.french },
        { text: formatMessage(m.english), value: PreferredLanguage.english }
    ];

    return (
        <Dropdown
            fluid={true}
            selection={true}
            options={languageOptions}
            {...props}
        />
    );
};