import { BaseService } from './baseService';
import { FilterOptions, SortOptions, NoteVisibility } from '../state/models';
import { ServiceCallEdit } from '../state/models/edits/ServiceCallEdit';
import queryString from "query-string";

export class ServiceCallsService extends BaseService {
    constructor() {
        super('api/service-calls');
    }

    public createServiceCall(serviceCall: ServiceCallEdit): Promise<any> {
        return this.post('/', serviceCall);
    }

    public updateServiceCall(serviceCall: ServiceCallEdit): Promise<any> {
        return this.put('/', serviceCall);
    }

    public getOverdueServiceCalls(page?: number, sortings?: SortOptions): Promise<any> {
        const currentPage = page || 1;
        const sortOptionsQueryString = sortings ? `?${queryString.stringify(sortings)}` : '';        
        return this.get(`/overdue/${currentPage}${sortOptionsQueryString}`);         
    }

    public getStalledUnassignedServiceCalls(page?: number, sortings?: SortOptions): Promise<any> {
        const currentPage = page || 1;
        const sortOptionsQueryString = sortings ? `?${queryString.stringify(sortings)}` : '';        
        return this.get(`/stalled-unassigned/${currentPage}${sortOptionsQueryString}`);         
    }

    public getOpenedServiceCalls(page?: number, filters?: FilterOptions, sortings?: SortOptions): Promise<any> {
        const currentPage = page || 1;
        if (!filters && !sortings) {
            return this.get(`/opened/${currentPage}`);
        } else {
            return this.post(`/opened/${currentPage}/with-data-display`, {
                serviceCallFiltering: {
                    agents: filters ? filters.selectedAgentsFilter?.map(agent => agent.email) : undefined,
                    status: filters ? filters.selectedServiceCallStatusFilter : undefined
                },
                serviceCallSorting: {
                    sortBy: sortings ? sortings.sortBy : undefined,
                    direction: sortings ? sortings.direction : undefined
                }
            });
        }
    }

    public getMyServiceCalls(page?: number, filters?: FilterOptions, sortings?: SortOptions): Promise<any> {
        const currentPage = page || 1;
        if (!filters && !sortings) {
            return this.get(`/mine/${currentPage}`);
        } else {
            return this.post(`/mine/${currentPage}/with-data-display`, {
                serviceCallFiltering: {
                    agents: filters ? filters.selectedAgentsFilter?.map(agent => agent.email) : undefined,
                    status: filters ? filters.selectedServiceCallStatusFilter : undefined
                },
                serviceCallSorting: {
                    sortBy: sortings ? sortings.sortBy : undefined,
                    direction: sortings ? sortings.direction : undefined
                }
            });
        }
    }

    public getStaleServiceCalls(page?: number, sortings?: SortOptions): Promise<any> {
        const currentPage = page || 1;
        if (!sortings) {
            return this.get(`/stale/${currentPage}`);
        } else {
            return this.post(`/stale/${currentPage}/with-data-display`, {
                serviceCallFiltering: undefined,
                serviceCallSorting: {
                    sortBy: sortings ? sortings.sortBy : undefined,
                    direction: sortings ? sortings.direction : undefined
                }
            });
        }
    }

    public getHistory(page?: number, filters?: FilterOptions, sortings?: SortOptions): Promise<any> {
        const currentPage = page || 1;
        if (!filters && !sortings) {
            return this.get(`/history/${currentPage}`);
        } else {
            return this.post(`/history/${currentPage}/with-data-display`, {
                serviceCallFiltering: {
                    agents: filters ? filters.selectedAgentsFilter?.map(agent => agent.email) : undefined,
                    status: filters ? filters.selectedServiceCallStatusFilter : undefined
                },
                serviceCallSorting: {
                    sortBy: sortings ? sortings.sortBy : undefined,
                    direction: sortings ? sortings.direction : undefined
                }
            });
        }
    }

    public getDetails(serviceCallId: number): Promise<any> {
        return this.get(`/${serviceCallId}`);
    }

    public getActivities(serviceCallId: number): Promise<any> {
        return this.get(`/${serviceCallId}/activities`);
    }

    public uploadVideos(serviceCallId: number, attachments: File[]) {
        let formData = new FormData();
        attachments.forEach(attachment => {
            formData.append('attachments', attachment);
        });

        return this.postFormData(
            `/${serviceCallId}/attachments/videos`,
            formData
        );
    }

    public uploadPictures(serviceCallId: number, attachments: File[]) {
        let formData = new FormData();
        attachments.forEach(attachment => {
            formData.append('attachments', attachment);
        });

        return this.postFormData(
            `/${serviceCallId}/attachments/pictures`,
            formData
        );
    }

    public uploadDocuments(serviceCallId: number, attachments: File[]) {
        let formData = new FormData();
        attachments.forEach(attachment => {
            formData.append('attachments', attachment);
        });

        return this.postFormData(
            `/${serviceCallId}/attachments/documents`,
            formData
        );
    }

    public deleteAttachment(serviceCallId: number, attachmentId: number) {
        return this.delete(`/${serviceCallId}/attachments/${attachmentId}`, {});
    }

    public changeNoteVisbility(serviceCallId: number, noteId: number, visibility: NoteVisibility) {
        return this.post(`/${serviceCallId}/notes/${noteId}/visibility`, visibility);
    }

    public findServiceCall(searchTerm: string, page?: number): Promise<any> {
        const currentPage = page || 1;
        return this.get(`/find/${searchTerm}/${currentPage}`);
    }

    public findServiceCallWithCriterias(searchQuery: string, page?: number): Promise<any> {
        const currentPage = page || 1;
        return this.get(`/advanced-find/${currentPage}${searchQuery}`);
    }
}

export default ServiceCallsService;