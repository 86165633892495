import { ClientContactEdit, CustomerEdit, OrderEdit, ProblemEdit } from '.';
import { ServiceCall } from '../ServiceCall';
import { emptyAddress } from '../Address';

export class ServiceCallEdit {
    public id: number = 0;
    public order: OrderEdit = new OrderEdit();
    public client: ClientContactEdit = new ClientContactEdit();
    public customer: CustomerEdit = new CustomerEdit();
    public problem: ProblemEdit = new ProblemEdit();

    public static fromEntity(entity: ServiceCall): ServiceCallEdit {
        return new ServiceCallEdit({
            id: entity.id,
            order: new OrderEdit({
                clientId: entity.clientId,
                client: entity.client,
                billId: entity.billId,
                bill: entity.bill,
                referenceNumber: entity.referenceNumber,
                clientContactName: entity.clientContactName
            }),
            client: new ClientContactEdit({
                name: entity.clientContact ? entity.clientContact.name : '',
                email: entity.clientContact ? entity.clientContact.email : '',
                phoneNumber: entity.clientContact ? entity.clientContact.phoneNumber : '',
            }),
            customer: new CustomerEdit({
                name: entity.customer ? entity.customer.name : '',
                address: entity.customer ? entity.customer.address : emptyAddress,
                mainPhoneNumber: entity.customer ? entity.customer.mainPhoneNumber : '',
                otherPhoneNumber: entity.customer ? entity.customer.otherPhoneNumber : ''
            }),
            problem: new ProblemEdit({
                description: entity.problemDescription
            })
        });
    }

    public constructor(init?: Partial<ServiceCallEdit>) {
        Object.assign(this, init);
    }
}