import * as React from 'react';
import { Grid, Button, SemanticICONS, Icon } from 'semantic-ui-react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { commonMessages } from '../../constants';

export interface SubmitFormControlsProps {
    isSubmitting?: boolean;
    submitButtonText?: string;
    submitButtonIcon?: SemanticICONS;
    cancelButtonText?: string;
    cancelButtonIcon?: SemanticICONS;

    onSubmit?: () => void;
    onCancel?: () => void;
}

class SubmitFormControls extends React.Component<SubmitFormControlsProps & WrappedComponentProps, {}> {
    public render() {
        return (
            <Grid>
                <Grid.Column textAlign="right">
                    <Button
                        secondary={true}
                        onClick={this.props.onCancel}
                    >
                        {this.props.cancelButtonIcon && <Icon name={this.props.cancelButtonIcon} />}
                        {this.props.cancelButtonText || <FormattedMessage {...commonMessages.cancel} />}
                    </Button>
                    <Button
                        primary={true}
                        loading={this.props.isSubmitting}
                        onClick={this.props.onSubmit}
                    >
                        {this.props.submitButtonIcon && <Icon name={this.props.submitButtonIcon} />}
                        {this.props.submitButtonText || <FormattedMessage {...commonMessages.save} />}
                    </Button>
                </Grid.Column>
            </Grid>
        );
    }
}

const intlSubmitFormControls = injectIntl(SubmitFormControls);
export { intlSubmitFormControls as SubmitFormControls };