import moment from 'moment';
import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useResponsive } from '../../../utils/responsive';

import { PageHeader } from '../../../components/common';
import { FactoryRepairsList, FactoryRepairsTable } from '../../../components/service-calls/factory';
import { actionCreators as FactoryRepairsActions } from '../../../state/ducks/factory-repairs';
import { arePendingFactoryRepairsLoading, getPendingFactoryRepairs } from '../../../state/ducks/factory-repairs/selectors';
import { ServiceCallFactoryRepair } from '../../../state/models';

const m = defineMessages({
    pageTitle: { id: 'FactoryReapirsPage.page_title', defaultMessage: 'Pending repairs' },
    pageSubtitle: { id: 'FactoryReapirsPage.page_subtitle', defaultMessage: 'View all the pending factory repairs and their related service calls.' },
    noRepairsTitle: { id: 'FactoryReapirsPage.no_repairs_title', defaultMessage: 'No repairs' },
    noRepairsSubtitle: { id: 'FactoryReapirsPage.no_repairs_subtitle', defaultMessage: 'There are no pending factory repairs.' }
});

export const FactoryRepairsPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const { isMobile } = useResponsive();
    const dispatch = useDispatch();
    const history = useHistory();

    const sortPendingRepairs = (a: ServiceCallFactoryRepair, b: ServiceCallFactoryRepair): number => {
        const aMoment = moment(a.scheduledFor || a.receivedOn);
        const bMoment = moment(b.scheduledFor || b.receivedOn);

        if (aMoment.isBefore(bMoment)) {
            return -1;
        }

        if (aMoment.isAfter(bMoment)) {
            return 1;
        }

        return 0;
    }

    const factoryRepairs = useSelector(getPendingFactoryRepairs);
    const sortedFactoryRepairs = factoryRepairs.sort(sortPendingRepairs);
    const isLoading = useSelector(arePendingFactoryRepairsLoading);

    const navigateToFactoryPage = (repairs: ServiceCallFactoryRepair) => {
        history.push(`/service-calls/${repairs.serviceCallId}/factory-repairs/${repairs.id}/prepare`);
    }

    useEffect(() => {
        dispatch(FactoryRepairsActions.loadPendingRepairs());
    }, [dispatch]);

    return (
        <React.Fragment>
            <PageHeader
                iconName="factory"
                title={formatMessage(m.pageTitle)}
                subtitle={formatMessage(m.pageSubtitle)}
            />

            {!isMobile &&
                <FactoryRepairsTable
                    repairs={sortedFactoryRepairs}
                    isLoading={isLoading}
                    isCompletedHeaderHidden={true}
                    isRequestedHeaderHidden={true}
                    emptyTitle={formatMessage(m.noRepairsTitle)}
                    emptySubtitle={formatMessage(m.noRepairsSubtitle)}
                    onViewDetails={navigateToFactoryPage}
                />
            }

            {isMobile &&
                <FactoryRepairsList
                    repairs={sortedFactoryRepairs}
                    isLoading={isLoading}
                    emptyTitle={formatMessage(m.noRepairsTitle)}
                    emptySubtitle={formatMessage(m.noRepairsSubtitle)}
                    onViewDetails={navigateToFactoryPage}
                />
            }
        </React.Fragment>
    );
};