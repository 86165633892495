import { BaseService } from './baseService';
import { FactoryReport } from '../state/models';

export class FactoryRepairsService extends BaseService {
    constructor() {
        super('api/factory-repairs');
    }

    public getById(repairId: number): Promise<any> {
        return this.get(`/${repairId}`);
    }

    public getPendingRepairs(): Promise<any> {
        return this.get('/pending');
    }

    public getCompletedRepairs(): Promise<any> {
        return this.get('/completed');
    }

    public getScheduledBetween(startDate: Date, endDate: Date): Promise<any> {
        return this.get(`/between/${startDate.toISOString()}/${endDate.toISOString()}`);
    }

    public getByServiceCall(serviceCallId: number): Promise<any> {
        return this.get(`/by-service-call/${serviceCallId}`);
    }

    public getScheduledForToday(): Promise<any> {
        return this.get('/scheduled-for/today');
    }

    public getScheduledForThisWeek(): Promise<any> {
        return this.get('/scheduled-for/this-week');
    }

    public getReport(repairId: number): Promise<any> {
        return this.get(`/${repairId}/report`);
    }

    public saveReport(repairId: number, report: FactoryReport): Promise<any> {
        return this.put(`/${repairId}/report`, report);
    }

    public completeReport(repairId: number): Promise<any> {
        return this.post(`/${repairId}/report/complete`, {});
    }

    public scheduleRepair(repairId: number, scheduledFor: Date): Promise<any> {
        return this.post(`/${repairId}/schedule-for/${scheduledFor.toISOString()}`, {});
    }
}

export default FactoryRepairsService;