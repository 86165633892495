import React from "react";
import { useIntl } from "react-intl";
import { Button, Divider } from "semantic-ui-react";
import { commonMessages } from "../../../../constants";

interface OwnProps {
    onPreviousStepClick?: () => void;
    onNextStepClick?: () => void;
    nextStepContent?: string;
    loading?: boolean;
}

export type StepsNavigationDivider =
    & OwnProps;

export const StepsNavigationDivider: React.FC<StepsNavigationDivider> = (props) => {
    const { formatMessage } = useIntl();

    return (
        <>
            <Divider section />

            <div style={{ textAlign: 'right'}}>
                {props.onPreviousStepClick && 
                    <Button 
                        disabled={props.loading} 
                        content={formatMessage(commonMessages.back)} 
                        onClick={props.onPreviousStepClick} 
                    />
                }
                {props.onNextStepClick && 
                    <Button 
                        primary 
                        disabled={props.loading} 
                        loading={props.loading} 
                        content={props.nextStepContent || formatMessage(commonMessages.next)}
                        onClick={props.onNextStepClick}
                    />
                }
            </div>
        </>
    );
};