import { ValidationResult } from './ValidationResult';
import { defineMessages } from 'react-intl';
import { StringHelper, nameof } from '../../utils';

const m = defineMessages({
    recipientEmailRequired: { id: 'SendEmailEdit.recipient_email_required', defaultMessage: 'The recipient\'s email is required.' },
    recipientEmailInvalid: { id: 'SendEmailEdit.recipient_email_invalid', defaultMessage: 'The recipient\'s email is invalid.' },
    emailLocaleRequired: { id: 'SendEmailEdit.email_locale_required', defaultMessage: 'The email\'s locale is required.' }
});

export class SendEmailEdit {
    public recipientEmail?: string;
    public emailLocale?: string;

    public constructor(init?: Partial<SendEmailEdit>) {
        Object.assign(this, init);
    }

    public validate(): ValidationResult {
        let result = new ValidationResult();

        if (!StringHelper.hasValue(this.recipientEmail)) {
            result.isValid = false;
            result.errorFields.push(nameof<SendEmailEdit>('recipientEmail'));
            result.errorMessages.push(m.recipientEmailRequired);
        } else {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            var isEmailValid = re.test(this.recipientEmail!.toLowerCase());

            if (!isEmailValid) {
                result.isValid = false;
                result.errorFields.push(nameof<SendEmailEdit>('recipientEmail'));
                result.errorMessages.push(m.recipientEmailInvalid);
            }
        }

        if (!StringHelper.hasValue(this.emailLocale)) {
            result.isValid = false;
            result.errorFields.push(nameof<SendEmailEdit>('emailLocale'));
            result.errorMessages.push(m.emailLocaleRequired);
        }

        return result;
    }
}