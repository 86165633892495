import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Form, Input, Grid, InputOnChangeData, Message } from 'semantic-ui-react';

import { SectionHeader } from '../common';
import { ClientContactEdit } from '../../state/models/edits/ClientContactEdit';
import { ValidationResult } from '../../state/models';
import { nameof } from '../../state/utils';

interface ClientInformationStepOwnProps {
    client: ClientContactEdit;
    validationResult: ValidationResult;
    onChange: (fieldId: string, newValue: string) => void;
}

export type ClientInformationStepProps =
    & ClientInformationStepOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'ClientInformationStep.sectionTitle', defaultMessage: 'Your contact information' },
    subtitle: { id: 'ClientInformationStep.subtitle', defaultMessage: 'Please provide your information in case we need to contact you for additional information.' },
    nameLabel: { id: 'ClientInformationStep.nameLabel', defaultMessage: 'Your name' },
    emailLabel: { id: 'ClientInformationStep.emailLabel', defaultMessage: 'Your email' },
    phoneLabel: { id: 'ClientInformationStep.phoneLabel', defaultMessage: 'Your phone number' }
});

class ClientInformationStep extends React.Component<ClientInformationStepProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { validationResult } = this.props;

        return (
            <React.Fragment>
                <SectionHeader
                    title={formatMessage(m.title)}
                    subtitle={formatMessage(m.subtitle)}
                />

                <Grid columns={2} doubling={true}>
                    <Grid.Column>
                        <Form error={!validationResult.isValid}>
                            <Form.Field
                                required={true}
                                error={validationResult.isFieldInvalid(nameof<ClientContactEdit>('name'))}
                            >
                                <label>{formatMessage(m.nameLabel)}</label>
                                <Input
                                    id="name"
                                    value={this.props.client.name || ''}
                                    onChange={this.handleChange}
                                />
                            </Form.Field>
                            <Form.Field
                                required={true}
                                error={validationResult.isFieldInvalid(nameof<ClientContactEdit>('email'))}
                            >
                                <label>{formatMessage(m.emailLabel)}</label>
                                <Input
                                    id="email"
                                    value={this.props.client.email || ''}
                                    onChange={this.handleChange}
                                />
                            </Form.Field>
                            <Form.Field
                                required={true}
                                error={validationResult.isFieldInvalid(nameof<ClientContactEdit>('phoneNumber'))}
                            >
                                <label>{formatMessage(m.phoneLabel)}</label>
                                <Input
                                    id="phoneNumber"
                                    value={this.props.client.phoneNumber || ''}
                                    onChange={this.handleChange}
                                />
                            </Form.Field>
                            <Message error={true} list={this.props.validationResult.localizeErrorMessages(formatMessage)} />
                        </Form>
                    </Grid.Column>
                </Grid>
            </React.Fragment>
        );
    }

    private handleChange = (e: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) => {
        if (this.props.onChange) {
            this.props.onChange(e.currentTarget.id, data.value);
        }
    }
}

const connectedComponent = injectIntl(ClientInformationStep);
export { connectedComponent as ClientInformationStep };