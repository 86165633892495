import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { PageHeader } from '../../components/common';
import { FilterPopup, ServiceCallList, ServiceCallTable } from '../../components/service-calls';
import { Segment } from 'semantic-ui-react';
import { ServiceCall, FilterOptions, SortDirection } from '../../state/models';
import { isCurrentUserAgent } from '../../state/ducks/current-user';
import { useResponsive } from '../../utils/responsive';
import { areOpenedServiceCallsLoading, getOpenedServiceCalls, getOpenedServiceCallsFilters, getOpenedServiceCallsSortings } from '../../state/ducks/service-calls';
import { actionCreators as ServiceCallsActions } from '../../state/ducks/service-calls/actions';

const messages = defineMessages({
    pageTitle: { id: 'ServiceCallsPage.page_title', defaultMessage: 'Ongoing service calls' },
    pageSubtitle: { id: 'ServiceCallsPage.page_subtitle', defaultMessage: 'View the ongoing service calls and take actions to close them.' },
    createButton: { id: 'ServiceCallsPage.create_button', defaultMessage: 'Create New Call' },
});

interface RouteProps {
    page?: string;
}

export const ServiceCallsPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const { isMobile } = useResponsive();
    const { page } = useParams<RouteProps>();

    const currentPage = page != null ? Number(page) : undefined;
    const history = useHistory();
    const dispatch = useDispatch();

    const isAgent = useSelector(isCurrentUserAgent);
    const isLoading = useSelector(areOpenedServiceCallsLoading);
    const serviceCalls = useSelector(getOpenedServiceCalls);

    const sortings = useSelector(getOpenedServiceCallsSortings);
    const filters = useSelector(getOpenedServiceCallsFilters);

    useEffect(() => {
        dispatch(ServiceCallsActions.loadOpenedServiceCalls(currentPage, filters, sortings))
    }, [dispatch, currentPage, filters, sortings]);

    const navigateToDetailsPage = (serviceCall: ServiceCall) => history.push(`/service-calls/${serviceCall.id}/details`);
    const navigateToPageNumber = (page: number) => history.push(`/service-calls/current/${page}`);
    const navigateToFirstPage = () => navigateToPageNumber(1);

    const applySorting = (sortBy: string, direction: SortDirection) => {
        dispatch(ServiceCallsActions.applySortingOnOpened(sortBy, direction));

        if (currentPage !== 1) {
            navigateToFirstPage();
        }
    }

    const applyFilters = (filters: FilterOptions) => {
        dispatch(ServiceCallsActions.applyFiltersOnOpened(filters));

        if (currentPage !== 1) {
            navigateToFirstPage();
        }
    }

    const serviceCallsProps = {
        serviceCalls: serviceCalls,
        sortings: sortings,
        isLoading: isLoading,
        onViewDetails: navigateToDetailsPage,
        onPageChange: navigateToPageNumber,
        onSortData: applySorting
    };

    return (
        <div>
            <PageHeader
                iconName="phone"
                title={formatMessage(messages.pageTitle)}
                subtitle={formatMessage(messages.pageSubtitle)}
            />

            <Segment basic textAlign="right" style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}>
                <FilterPopup
                    loading={isLoading}
                    filters={filters}
                    onApply={applyFilters}
                />
            </Segment>

            {isMobile && <ServiceCallList {...serviceCallsProps} /> }
            {!isMobile && <ServiceCallTable showClient={isAgent} {...serviceCallsProps} /> }
        </div>
    );
};