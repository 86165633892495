import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Progress } from "semantic-ui-react";

interface OwnProps {
}

export type UploadFileProgressProps =
    & OwnProps;

const m = defineMessages({
    uploading: { id: 'UploadingFileControl.uploading', defaultMessage: 'Uploading...' }
});

export const UploadFileProgress: React.FC<UploadFileProgressProps> = (props) => {
    const { formatMessage } = useIntl();

    return (
        <Progress percent={100} color="green" active>
            {formatMessage(m.uploading)}
        </Progress>
    );
};