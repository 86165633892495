import React, { useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { actionCreators as InspectionsActions } from '../../../state/ducks/inspections';
import { actionCreators as CalendarActions } from '../../../state/ducks/calendar';

import { ApplicationState } from '../../../state/ducks';
import { PageHeader } from '../../../components/common';
import { RepresentativeCalendarEvent } from '../../../components/service-calls/inspection';
import { ServiceCallInspection } from '../../../state/models';
import { getInspectionsForCalendarDate, areInspectionsForCalendarDateLoading, getInspectionsCalendarStartDate } from '../../../state/ducks/inspections/selectors';
import { Calendar, MobileCalendar } from '../../../components/calendar';
import { CalendarEvent } from '../../../components/calendar/MonthlyCalendar';
import { getBreaksBetween } from '../../../state/ducks/calendar/selectors';
import { useResponsive } from '../../../utils/responsive';

const m = defineMessages({
    title: { id: 'RepresentativeCalendarPage.title', defaultMessage: 'Upcoming inspections' },
    subtitle: { id: 'RepresentativeCalendarPage.subtitle', defaultMessage: 'View your upcoming service call inspections.' }
});

export const RepresentativeCalendarPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const { isMobile } = useResponsive();
    const dispatch = useDispatch();
    const history = useHistory();

    const calendarDate = useSelector(getInspectionsCalendarStartDate);
    const dateMoment = moment(calendarDate);
    const startDate = dateMoment.clone().startOf('month').startOf('week').startOf('day').toDate();
    const endDate = dateMoment.clone().endOf('month').endOf('week').startOf('day').toDate();

    useEffect(() => {
        const startDate = moment(calendarDate).startOf('month').startOf('week').startOf('day').toDate();
        const endDate = moment(calendarDate).endOf('month').endOf('week').startOf('day').toDate();

        dispatch(InspectionsActions.loadBetween(startDate, endDate));
        dispatch(CalendarActions.loadLocks(startDate, endDate));
    }, [dispatch, calendarDate]);

    const changeCalendarMonth = (date: moment.Moment) => {
        dispatch(InspectionsActions.setCalendarStartDate(date.toDate()));
    }

    const navigateToInspection = (inspection: ServiceCallInspection) => {
        history.push(`/service-calls/${inspection.serviceCallId}/inspections/${inspection.id}/review`);
    }

    const inspections = useSelector((state: ApplicationState) => getInspectionsForCalendarDate(state, startDate));
    const isLoading = useSelector((state: ApplicationState) => areInspectionsForCalendarDateLoading(state, startDate));
    const lockedDates = useSelector((state: ApplicationState) => getBreaksBetween(state, startDate, endDate));

    const calendarEvents: CalendarEvent[] = inspections
        .filter(x => x.scheduledForDate != null)
        .map(x => ({
            on: moment(x.scheduledForDate).local().startOf('day').toDate(),
            element: <RepresentativeCalendarEvent key={x.id} inspection={x} onClick={navigateToInspection} />
        }));

    return (
        <React.Fragment>
            <PageHeader
                iconName="calendar check"
                title={formatMessage(m.title)}
                subtitle={formatMessage(m.subtitle)}
            />

            {!isMobile &&
                <Calendar
                    forDate={calendarDate}
                    events={calendarEvents}
                    lockedDates={lockedDates.filter(x => x.forUserId == null)}
                    isLoading={isLoading}
                    canSelectDate={false}
                    onMonthChanged={(date) => changeCalendarMonth(moment(date))}
                />
            }

            {isMobile &&
                <MobileCalendar
                    forDate={calendarDate}
                    events={calendarEvents}
                    lockedDates={lockedDates}
                    isLoading={isLoading}
                    canSelectDate={false}
                    onMonthChanged={(date) => changeCalendarMonth(moment(date))}
                />
            }
        </React.Fragment>

    );
};