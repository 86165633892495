import { useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { ServiceCallStatus } from '../../../../../state/models';
import { getSubcontractorRepair } from '../../api';
import { SubcontractorRepairDetails } from '../../types';
import { ServiceCallClosedMessage } from './ServiceCallClosedMessage';

export interface SubcontractorRepairDetailsContextType {
    repairDetails?: SubcontractorRepairDetails;
    hasBillDetails: boolean;
    invalidateRepairDetails: () => void;
}

export const SubcontractorRepairDetailsContext =
    React.createContext<SubcontractorRepairDetailsContextType | null>(null);

interface SubcontractorRepairDetailsProviderProps {
    children?: React.ReactNode;
}

export const SubcontractorRepairDetailsProvider = ({
    children,
}: SubcontractorRepairDetailsProviderProps) => {
    const queryClient = useQueryClient();

    const queryState = useQuery(
        ['subcontractorRepairDetails'],
        () => getSubcontractorRepair(),
        {
            refetchOnWindowFocus: false,
            retry: 0,
        }
    );

    const repairDetails = queryState.data;

    const hasBillDetails = Boolean(
        repairDetails?.receiptUri ||
            repairDetails?.repairFees ||
            repairDetails?.shippingFees ||
            repairDetails?.referenceNumber
    );

    const contextValue = {
        repairDetails,
        hasBillDetails,
        invalidateRepairDetails: () =>
            queryClient.invalidateQueries(['subcontractorRepairDetails']),
    };

    if (!queryState.isFetched) {
        return null;
    }

    if (
        queryState.isFetched &&
        (queryState.error ||
            !repairDetails ||
            repairDetails.status === ServiceCallStatus.closed)
    ) {
        return <ServiceCallClosedMessage />;
    }

    return (
        <SubcontractorRepairDetailsContext.Provider value={contextValue}>
            {children}
        </SubcontractorRepairDetailsContext.Provider>
    );
};
