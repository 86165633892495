import React from 'react';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { DropdownProps, DropdownItemProps, Dropdown } from 'semantic-ui-react';
import { UserAccountType } from '../../../../../state/models';
import { UserHelper } from '../../../../../helpers';


export const RolesDropdown: React.FC<DropdownProps> = (props) => {
    const { formatMessage } = useIntl();

    const makeOption = (role: UserAccountType): DropdownItemProps => ({ value: role, text: formatMessage(UserHelper.getRoleDescription(role)) });
    const roleOptions: DropdownItemProps[] = [
        makeOption(UserAccountType.customerServiceAgent),
        makeOption(UserAccountType.jaymarRepresentative),
        makeOption(UserAccountType.jaymarTechnician),
        makeOption(UserAccountType.factoryTechnician),
        makeOption(UserAccountType.administrator)
    ];

    return (
        <Dropdown
            selection
            options={_(roleOptions).sortBy(x => x.text).value()}
            {...props}
        />
    );
};