import { attachmentActionCreators, AttachmentActionTypes } from './attachmentActions';
import { customerServiceActionCreators, CustomerServiceActionTypes } from './customerServiceActions';
import { filteringActionCreators, FilteringActionTypes } from './filteringActions';
import { sortingActionCreators, SortingActionTypes } from './sortingActions';
import { statisticsActionCreators, StatisticsActionTypes } from './statisticsActions';
import { serviceCallActionCreators, ServiceCallActionTypes } from './serviceCallActions';

export type ActionTypes =
    | AttachmentActionTypes
    | CustomerServiceActionTypes
    | FilteringActionTypes
    | ServiceCallActionTypes
    | SortingActionTypes
    | StatisticsActionTypes
    | { type: '' };

export const actionCreators = {
    ...attachmentActionCreators,
    ...customerServiceActionCreators,
    ...filteringActionCreators,
    ...serviceCallActionCreators,
    ...sortingActionCreators,
    ...statisticsActionCreators,
};