import { defineMessages } from "@formatjs/intl";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { SectionHeader } from "../../../components/common";
import { isCurrentUserAnEmployee } from "../../account/selectors";
import { ServiceCallEdit } from "../models";
import { CustomerInformationForm } from "./components/CustomerInformationForm";
import { FormValidationSummary } from "./components/FormValidationSummary";
import { StepsNavigationDivider } from "./components/StepsNavigationDivider";

interface OwnProps {
}

export type FillCustomerDetailsPageProps =
    & OwnProps;

const m = defineMessages({
    title: { id: 'CustomerInformationForm.section_title', defaultMessage: 'Customer information' },
    subtitle: { id: 'CustomerInformationForm.section_subtitle', defaultMessage: 'Provide the contact information for the customer that is having the issue.' },
});

export const FillCustomerDetailsPage: React.FC<FillCustomerDetailsPageProps> = () => {
    const { formatMessage } = useIntl();
    const { formState: { errors }, handleSubmit } = useFormContext<ServiceCallEdit>();
    const history = useHistory();
    const isJaymarEmployee = useSelector(isCurrentUserAnEmployee);

    const navigateToPreviousStep = isJaymarEmployee
        ? () => history.push('/service-calls/create/order')
        : () => history.push('/service-calls/create/client');

    const navigateToNextStep = () => history.push('/service-calls/create/problem');

    return (
        <>
            <SectionHeader title={formatMessage(m.title)} subtitle={formatMessage(m.subtitle)} />

            <FormValidationSummary errors={errors} style={{ maxWidth: '600px' }} />
            <CustomerInformationForm style={{ maxWidth: '450px' }} />

            <StepsNavigationDivider onPreviousStepClick={navigateToPreviousStep} onNextStepClick={handleSubmit(navigateToNextStep)} />
        </>
    );
};