import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { ChartOptions } from 'chart.js';

import * as ReportingActions from '../../state/ducks/reporting';
import * as SearchActions from '../../state/ducks/search';

import { ApplicationState } from '../../state/ducks';
import { PageHeader } from '../../components/common';
import { LineGraph, DisplayOptionsPopup } from '../../components/reporting';
import { TrendsFiltersPopup } from '../../components/reporting/TrendsFiltersPopup';
import { TrendsFilters, allCountries, getReportingDateRange, DatedValue, QualityRatio, DisplayOptions, DisplayType } from '../../state/models';

interface TrendsPageActions {
    reportingActions: typeof ReportingActions.actionCreators;
    searchActions: typeof SearchActions.actionCreators;
}

interface TrendsPageOwnProps {
    filters: TrendsFilters;
    displayOptions: DisplayOptions;
    qualityRatios: DatedValue<QualityRatio>[];
    isQualityRatiosLoading: boolean;
    callsCreated: DatedValue<number>[];
    isCallsCreatedLoading: boolean;
}

export type TrendsPageProps =
    & TrendsPageOwnProps
    & TrendsPageActions
    & RouteComponentProps<{}>
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'TrendsPage.title', defaultMessage: 'Trends' },
    subtitle: { id: 'TrendsPage.subtitle', defaultMessage: 'A general overview of how the after-sales service is changing.' },
    qualityRatioTitle: { id: 'TrendsPage.quality_ratio_title', defaultMessage: 'Quality percentage' },
    callsCreatedTitle: { id: 'TrendsPage.calls_created_title', defaultMessage: 'Calls created' }
});

class TrendsPage extends React.Component<TrendsPageProps, {}> {
    public componentDidMount() {
        this.loadStatistics(this.props.filters);
        this.props.reportingActions.loadCountriesFilter();
    }

    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <PageHeader
                    iconName="chart line"
                    title={formatMessage(m.title)}
                    subtitle={formatMessage(m.subtitle)}
                />

                <div style={{ textAlign: 'right', marginBottom: '10px' }}>
                    <DisplayOptionsPopup
                        options={this.props.displayOptions}
                        onApply={this.applyDisplayOptions}
                    />
                    <TrendsFiltersPopup
                        filters={this.props.filters}
                        onApply={this.applyFilters}
                        onChangeCountry={this.loadProvinces}
                        onSearchForClient={this.searchClients}
                    />
                </div>

                <Grid>
                    <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={8} widescreen={8}>
                        {this.renderCallsCreatedGraph()}
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={8} widescreen={8}>
                        {this.renderQualityRatioGraph()}
                    </Grid.Column>
                </Grid>
            </React.Fragment>
        );
    }

    private renderCallsCreatedGraph() {
        const { formatMessage } = this.props.intl;

        const callsCreatedBasedOnDisplayOptions = this.props.displayOptions.displayType === DisplayType.perYear
            ? _(this.props.callsCreated).groupBy(x => moment(x.date).endOf('year').toDate())
            : _(this.props.callsCreated).groupBy(x => x.date);

        const callsCreated = callsCreatedBasedOnDisplayOptions
            .map((value, key): DatedValue<number> => ({
                date: moment(key).toDate(),
                value: _(value).sumBy(x => x.value),
            }))
            .orderBy(['date'], ['asc'])
            .value();

        const callsCreatedValues = callsCreated.map(x => x.value);
        const callsCreatedLabels = callsCreated.map(x => moment(x.date).format('MMMM YYYY'));

        return (
            <LineGraph
                isLoading={this.props.isCallsCreatedLoading}
                color="teal"
                title={formatMessage(m.callsCreatedTitle)}
                values={callsCreatedValues}
                labels={callsCreatedLabels}
            />
        );
    }

    private renderQualityRatioGraph() {
        const { formatMessage } = this.props.intl;
        const chartOptions: ChartOptions = {
            scales: {
                yAxes: [{
                    ticks: { max: 100 }
                }]
            }
        };

        const ratiosBasedOnDisplayOptions = this.props.displayOptions.displayType === DisplayType.perYear
            ? _(this.props.qualityRatios).groupBy(x => moment(x.date).endOf('year').toDate())
            : _(this.props.qualityRatios).groupBy(x => x.date);

        const qualityRatios = ratiosBasedOnDisplayOptions
            .map((ratio, key): DatedValue<QualityRatio> => ({
                date: moment(key).toDate(),
                value: {
                    shippedItems: _(ratio).sumBy(x => x.value.shippedItems),
                    defectiveItems: _(ratio).sumBy(x => x.value.defectiveItems)
                }
            }))
            .value();

        const qualityRatioValues = qualityRatios.map(x => _.round(this.calculateRatio(x.value) * 100, 1));
        const qualityRatioLabels = qualityRatios.map(x => moment(x.date).format('MMMM YYYY'));

        return (
            <LineGraph
                isLoading={this.props.isQualityRatiosLoading}
                color="teal"
                title={formatMessage(m.qualityRatioTitle)}
                values={qualityRatioValues}
                labels={qualityRatioLabels}
                options={chartOptions}
            />
        );
    }

    private applyFilters = (filters: TrendsFilters) => {
        this.props.reportingActions.setTrendsFilters(filters);
        this.loadStatistics(filters);
    }

    private applyDisplayOptions = (options: DisplayOptions) => {
        this.props.reportingActions.setTrendsDisplay(options);
    }

    private loadProvinces = (countryCode: string) => {
        if (countryCode !== allCountries) {
            this.props.reportingActions.loadProvincesFilter(countryCode);
        }
    }

    private searchClients = (query: string) => {
        this.props.searchActions.findClient(query);
    }

    private loadStatistics(filters: TrendsFilters) {
        const dateRange = getReportingDateRange(filters.dateFilter);

        this.props.reportingActions.loadQualityRatios(dateRange.startDate, dateRange.endDate, filters);
        this.props.reportingActions.loadCallsCreatedPerMonth(dateRange.startDate, dateRange.endDate, filters);
    }

    private calculateRatio = (ratio: QualityRatio): number => {
        return ratio.shippedItems > 0
            ? 1 - (ratio.defectiveItems / ratio.shippedItems)
            : 1;
    }
}

const mapStateToProps = (state: ApplicationState): TrendsPageOwnProps => {
    return {
        filters: state.reporting.trendsFilters,
        displayOptions: state.reporting.trendsDisplay,
        qualityRatios: state.reporting.qualityRatios,
        isQualityRatiosLoading: state.reporting.isQualityRatiosLoading,
        callsCreated: state.reporting.callsCreatedPerMonth,
        isCallsCreatedLoading: state.reporting.isCallsCreatedPerMonthLoading
    };
};

const mapDispatchToProps = (dispatch: Dispatch): TrendsPageActions => {
    return {
        reportingActions: bindActionCreators(ReportingActions.actionCreators, dispatch),
        searchActions: bindActionCreators(SearchActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(TrendsPage);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as TrendsPage };