import React, { CSSProperties } from "react";
import { useIntl } from "react-intl";
import { Divider } from "semantic-ui-react";
import { commonMessages } from "../../../constants";

interface OwnProps {
    title: string;
    onClear: () => void;
    style?: CSSProperties;
}

export type FilterSectionProps =
    & OwnProps;

export const FilterSection: React.FC<FilterSectionProps> = (props) => {
    const { formatMessage } = useIntl();

    return (
        <div style={props.style}>
            <div>
                <strong>{props.title}</strong>
                <a style={{float: 'right', cursor: 'pointer'}} onClick={props.onClear}>
                    {formatMessage(commonMessages.clear)}
                </a>
            </div>
            <Divider />
            
            {props.children}
        </div>
    );
};