import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { FactoryReportDescription } from "containers/reporting/types";
import {
  DefectiveItemQueryFilters,
  ServiceCallAnalysisService,
} from "services/ServiceCallAnalysisService";
import * as globalNotification from "../../../../state/ducks/global-notification";
import orderBy from "lodash/orderBy";
import { usePagination } from "containers/reporting/hooks/usePagination";
import { DescriptionRow } from "../DescriptionRow";

const serviceCallAnalysisService = new ServiceCallAnalysisService();

interface FactoryReportDescriptionsProps {
  filters: DefectiveItemQueryFilters;
}

export const FactoryReportDescriptions: React.FC<FactoryReportDescriptionsProps> = ({ filters }) => {  
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [factoryReportDescriptions, setFactoryReportDescriptions] = useState<FactoryReportDescription[]>([]);

  useEffect(() => {
    setIsLoading(true);

    serviceCallAnalysisService
      .getFactoryReportDescriptions(filters)
      .then((response) => response.json() as FactoryReportDescription[])
      .then((fetchedDescriptions) => {
        setFactoryReportDescriptions(fetchedDescriptions);
      })
      .catch((error) => {
        dispatch(
          globalNotification.actionCreators.showErrorNotification(
            "Erreur",
            `Une erreur est survenue en tentant de récupérer la description des rapports d'usine (${error}).`
          )
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, filters]);

  const sortedRows = useMemo(
    () =>
      orderBy(
        factoryReportDescriptions,
        (row) => row.serviceCallId,
        "asc"
      ),
    [factoryReportDescriptions]
  );

  const { currentPageRows, Paginator } = usePagination(sortedRows);

  return (
    <>
      {!isLoading &&
        currentPageRows.map((row) => (
          <DescriptionRow serviceCallId={row.serviceCallId} description={row.description} />
        ))}
      {!isLoading && <Paginator />}
    </>
  );
};
