import * as React from 'react';

import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Line } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';
import { Segment } from 'semantic-ui-react';

interface LineGraphOwnProps {
    title?: string;
    labels: string[];
    values: number[];
    isLoading?: boolean;
    color?: SemanticCOLORS;
    options?: ChartOptions;
}

export type LineGraphProps =
    & LineGraphOwnProps
    & WrappedComponentProps;

const m = defineMessages({
});

class LineGraph extends React.Component<LineGraphProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const chartData: ChartData = {
            datasets: [{
                label: this.props.title,
                fill: false,
                data: this.props.values,
                backgroundColor: 'rgba(0, 181, 173, 1)',
                borderColor: 'rgba(0, 181, 173, 1)',
                borderWidth: 1
            }],
            labels: this.props.labels,
        };

        const options: ChartOptions = this.props.options || {
            scales: {
                xAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
        };

        return (
            <Segment 
                className="c-statistic" 
                loading={this.props.isLoading} 
                color={this.props.color} 
                style={{minHeight: 110, height: '100%', textAlign: 'center'}}
            >
                <Line
                    data={chartData}
                    options={options}
                />
            </Segment>
        );
    }
}

const connectedComponent = injectIntl(LineGraph);
export { connectedComponent as LineGraph };