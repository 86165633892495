import { BaseService } from './baseService';
import { StatisticsFilters } from '../state/models';

export class ReportingService extends BaseService {
    public constructor() {
        super('api/reporting');
    }

    public loadOverdueCallCount(): Promise<any> {
        return this.get('/overdue-call-count');
    }

    public loadStalledUnassignedCallCount(): Promise<any> {
        return this.get('/stalled-unassigned-call-count');
    }

    public loadCallsCreated(startDate: Date, endDate: Date, filters: StatisticsFilters): Promise<any> {
        return this.getWithFilters(`/calls-created/between/${startDate.toISOString()}/${endDate.toISOString()}`, filters);
    }

    public loadCallsClosed(startDate: Date, endDate: Date, filters: StatisticsFilters): Promise<any> {
        return this.getWithFilters(`/calls-closed/between/${startDate.toISOString()}/${endDate.toISOString()}`, filters);
    }

    public loadCallsActive(startDate: Date, endDate: Date, filters: StatisticsFilters): Promise<any> {
        return this.getWithFilters(`/calls-active/between/${startDate.toISOString()}/${endDate.toISOString()}`, filters);
    }

    public loadAverageResolutionTime(startDate: Date, endDate: Date, filters: StatisticsFilters): Promise<any> {
        return this.getWithFilters(`/average-resolution-time/between/${startDate.toISOString()}/${endDate.toISOString()}`, filters);
    }

    public loadCallsRepairsDistribution(startDate: Date, endDate: Date, filters: StatisticsFilters): Promise<any> {
        return this.getWithFilters(`/calls-repairs-distribution/between/${startDate.toISOString()}/${endDate.toISOString()}`, filters);
    }

    public loadProblemsFrequency(startDate: Date, endDate: Date, filters: StatisticsFilters): Promise<any> {
        return this.getWithFilters(`/problems-frequency/between/${startDate.toISOString()}/${endDate.toISOString()}`, filters);
    }

    public loadDefectiveProductsFrequency(startDate: Date, endDate: Date, filters: StatisticsFilters): Promise<any> {
        return this.getWithFilters(`/defective-products-frequency/between/${startDate.toISOString()}/${endDate.toISOString()}`, filters);
    }

    public loadDefectiveCoversFrequency(startDate: Date, endDate: Date, filters: StatisticsFilters): Promise<any> {
        return this.getWithFilters(`/defective-covers-frequency/between/${startDate.toISOString()}/${endDate.toISOString()}`, filters);
    }

    public loadQualityRatios(startDate: Date, endDate: Date, filters: StatisticsFilters): Promise<any> {
        return this.getWithFilters(`/quality-ratios/between/${startDate.toISOString()}/${endDate.toISOString()}`, filters);
    }

    public loadCallsCreatedPerMonth(startDate: Date, endDate: Date, filters: StatisticsFilters): Promise<any> {
        return this.getWithFilters(`/calls-created-per-month/between/${startDate.toISOString()}/${endDate.toISOString()}`, filters);
    }

    public loadClientsAdoptionRatio(startDate: Date, endDate: Date): Promise<any> {
        return this.get(`/clients-adoption-ratio/between/${startDate.toISOString()}/${endDate.toISOString()}`);
    }

    public loadTopClientAdopters(startDate: Date, endDate: Date): Promise<any> {
        return this.get(`/top-client-adopters/between/${startDate.toISOString()}/${endDate.toISOString()}`);
    }

    public loadActiveCallsStatus(): Promise<any> {
        return this.get('/active-calls-status');
    }

    public loadCountriesFilter(): Promise<any> {
        return this.get(`/filters/countries`);
    }

    public loadProvincesFilter(countryCode: string): Promise<any> {
        return this.get(`/filters/countries/${countryCode}/provinces`);
    }

    private getWithFilters(url: string, filters: StatisticsFilters): Promise<any> {
        return this.get(url + this.getFiltersQueryString(filters));
    }

    private getFiltersQueryString(filters: StatisticsFilters): string {
        return `?countryCode=${filters.regionFilter.countryCode}`
            + `&provinceCode=${filters.regionFilter.provinceCode}`
            + `&clientCode=${filters.clientFilter.clientCode}`;
    }
}