export enum DisplayType {
    perMonth,
    perYear
}

export interface DisplayOptions {
    displayType: DisplayType;
}

export const initialDisplayOptions: DisplayOptions = {
    displayType: DisplayType.perMonth
};