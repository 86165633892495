import { BaseService } from './baseService';

export class UsersService extends BaseService {
    constructor() {
        super('api/users');
    }

    public loadCustomerServiceAgents(): Promise<any> {
        return this.get('/customer-service-agents');
    }

    public getJaymarRepresentatives(): Promise<any> {
        return this.get('/jaymar-representatives');
    }

    public getJaymarTechnicians(): Promise<any> {
        return this.get('/jaymar-technicians');
    }

    public getFactoryTechnicians(): Promise<any> {
        return this.get('/factory-technicians');
    }
}

export default UsersService;