import { ThunkAction } from 'redux-thunk';

import { ActionTypeKeys } from '../types';
import { ApplicationState } from '../..';
import * as globalNotification from '../../global-notification';
import { ServiceCallAttachment } from '../../../models';
import { ServiceCallsService } from '../../../../services';
import { AppThunk } from '../../../store';

export type AttachmentActionTypes =
    | UploadVideosRequestAction
    | UploadVideosSuccessAction
    | UploadVideosFailureAction
    | UploadPicturesRequestAction
    | UploadPicturesSuccessAction
    | UploadPicturesFailureAction
    | UploadDocumentsRequestAction
    | UploadDocumentsSuccessAction
    | UploadDocumentsFailureAction
    | DeleteServiceCallAttachmentRequestAction
    | DeleteServiceCallAttachmentSuccessAction
    | DeleteServiceCallAttachmentFailureAction
    | { type: '' };

interface UploadVideosRequestAction { type: ActionTypeKeys.UPLOAD_VIDEOS_REQUEST; payload: number; }
interface UploadVideosSuccessAction { type: ActionTypeKeys.UPLOAD_VIDEOS_SUCCESS; payload: { serviceCallId: number, attachments: ServiceCallAttachment[] }; }
interface UploadVideosFailureAction { type: ActionTypeKeys.UPLOAD_VIDEOS_FAILURE; payload: { serviceCallId: number, error: string }; }

interface UploadPicturesRequestAction { type: ActionTypeKeys.UPLOAD_PICTURES_REQUEST; payload: number; }
interface UploadPicturesSuccessAction { type: ActionTypeKeys.UPLOAD_PICTURES_SUCCESS; payload: { serviceCallId: number, attachments: ServiceCallAttachment[] }; }
interface UploadPicturesFailureAction { type: ActionTypeKeys.UPLOAD_PICTURES_FAILURE; payload: { serviceCallId: number, error: string }; }

interface UploadDocumentsRequestAction { type: ActionTypeKeys.UPLOAD_DOCUMENTS_REQUEST; payload: number; }
interface UploadDocumentsSuccessAction { type: ActionTypeKeys.UPLOAD_DOCUMENTS_SUCCESS; payload: { serviceCallId: number, attachments: ServiceCallAttachment[] }; }
interface UploadDocumentsFailureAction { type: ActionTypeKeys.UPLOAD_DOCUMENTS_FAILURE; payload: { serviceCallId: number, error: string }; }

interface DeleteServiceCallAttachmentRequestAction { type: ActionTypeKeys.DELETE_ATTACHMENT_REQUEST; payload: { serviceCallId: number, attachmentId: number }; }
interface DeleteServiceCallAttachmentSuccessAction { type: ActionTypeKeys.DELETE_ATTACHMENT_SUCCESS; payload: { serviceCallId: number, attachmentId: number }; }
interface DeleteServiceCallAttachmentFailureAction { type: ActionTypeKeys.DELETE_ATTACHMENT_FAILURE; payload: { serviceCallId: number, error: string, attachmentId: number }; }

const serviceCallService = new ServiceCallsService();

export const attachmentActionCreators = {
    uploadVideos: (serviceCallId: number, attachments: File[], onSuccess?: () => void): AppThunk => {
        return (dispatch, getState) => {
            dispatch({ type: ActionTypeKeys.UPLOAD_VIDEOS_REQUEST, payload: serviceCallId });

            serviceCallService.uploadVideos(serviceCallId, attachments)
                .then(response => response.json() as ServiceCallAttachment[])
                .then(attachmentUris => {
                    dispatch({ type: ActionTypeKeys.UPLOAD_VIDEOS_SUCCESS, payload: { serviceCallId: serviceCallId, attachments: attachmentUris } });

                    if (onSuccess) {
                        onSuccess();
                    }
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.UPLOAD_VIDEOS_FAILURE, payload: { serviceCallId: serviceCallId, error: error } });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant d'envoyer une vidéo (${error}).`)
                    );
                });
        };
    },

    uploadPictures: (serviceCallId: number, attachments: File[], onSuccess?: () => void): AppThunk => {
        return (dispatch, getState) => {
            dispatch({ type: ActionTypeKeys.UPLOAD_PICTURES_REQUEST, payload: serviceCallId });

            serviceCallService.uploadPictures(serviceCallId, attachments)
                .then(response => response.json() as ServiceCallAttachment[])
                .then(attachmentUris => {
                    dispatch({ type: ActionTypeKeys.UPLOAD_PICTURES_SUCCESS, payload: { serviceCallId: serviceCallId, attachments: attachmentUris } });

                    if (onSuccess) {
                        onSuccess();
                    }
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.UPLOAD_PICTURES_FAILURE, payload: { serviceCallId: serviceCallId, error: error } });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant d'envoyer une photo (${error}).`)
                    );
                });
        };
    },

    uploadDocuments: (serviceCallId: number, attachments: File[], onSuccess?: () => void): AppThunk => {
        return (dispatch, getState) => {
            dispatch({ type: ActionTypeKeys.UPLOAD_DOCUMENTS_REQUEST, payload: serviceCallId });

            serviceCallService.uploadDocuments(serviceCallId, attachments)
                .then(response => response.json() as ServiceCallAttachment[])
                .then(attachmentUris => {
                    dispatch({ type: ActionTypeKeys.UPLOAD_DOCUMENTS_SUCCESS, payload: { serviceCallId: serviceCallId, attachments: attachmentUris } });

                    if (onSuccess) {
                        onSuccess();
                    }
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.UPLOAD_DOCUMENTS_FAILURE, payload: { serviceCallId: serviceCallId, error: error } });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant d'envoyer un document (${error}).`)
                    );
                });
        };
    },

    deleteAttachment: (serviceCallId: number, attachmentId: number): AppThunk => {
        return (dispatch, getState) => {
            dispatch({ type: ActionTypeKeys.DELETE_ATTACHMENT_REQUEST, payload: { serviceCallId: serviceCallId, attachmentId: attachmentId } });

            serviceCallService.deleteAttachment(serviceCallId, attachmentId)
                // tslint:disable-next-line:no-any
                .then(response => response.json() as any)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.DELETE_ATTACHMENT_SUCCESS, payload: { serviceCallId: serviceCallId, attachmentId: attachmentId } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.DELETE_ATTACHMENT_FAILURE, payload: { serviceCallId: serviceCallId, error: error, attachmentId: attachmentId } });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de supprimer une pièce jointe (${error}).`)
                    );
                });
        };
    },
};