import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Table, Icon, Button } from 'semantic-ui-react';
import { Subcontractor } from '../../../../../state/models';
import { SmallEditButton, SmallConfirmDeleteButton } from '../../../../../components/common';

interface OwnProps {
    subcontractors: Subcontractor[];

    onModify: (subcontractor: Subcontractor) => void;
    onDelete: (subcontractor: Subcontractor) => void;
}

export type SubcontractorsTableProps =
    & OwnProps
    & WrappedComponentProps;

const m = defineMessages({
    nameHeader: { id: 'SubcontractorsTable.nameHeader', defaultMessage: 'Name' },
    regionHeader: { id: 'SubcontractorsTable.regionHeader', defaultMessage: 'Region' },
    emailHeader: { id: 'SubcontractorsTable.emailHeader', defaultMessage: 'Email' },
    deleteConfirmationTitle: { id: 'SubcontractorsTable.deleteConfirmationTitle', defaultMessage: 'Are you sure?' },
    deleteConfirmationMessage: { id: 'SubcontractorsTable.deleteConfirmationMessage', defaultMessage: 'Are you sure you want to delete the subcontrator \'{name}\'?' }
});

class SubcontractorsTable extends React.Component<SubcontractorsTableProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <Table basic="very" selectable={true} singleLine={true}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell collapsing={true} />
                        <Table.HeaderCell>{formatMessage(m.nameHeader)}</Table.HeaderCell>
                        <Table.HeaderCell>{formatMessage(m.regionHeader)}</Table.HeaderCell>
                        <Table.HeaderCell>{formatMessage(m.emailHeader)}</Table.HeaderCell>
                        <Table.HeaderCell />
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.props.subcontractors.map(x => this.renderRow(x))}
                </Table.Body>
            </Table>
        );
    }

    private renderRow = (subcontractor: Subcontractor): JSX.Element => {
        const { formatMessage } = this.props.intl;

        return (
            <Table.Row key={subcontractor.id} style={{ cursor: 'pointer' }}>
                <Table.Cell onClick={() => this.props.onModify(subcontractor)}><Icon name="id card" size="big" /></Table.Cell>
                <Table.Cell onClick={() => this.props.onModify(subcontractor)}>{subcontractor.name}</Table.Cell>
                <Table.Cell onClick={() => this.props.onModify(subcontractor)}>{subcontractor.region}</Table.Cell>
                <Table.Cell onClick={() => this.props.onModify(subcontractor)}>
                    {subcontractor.emails.length > 0 &&
                        <p>{subcontractor.emails[0]}</p>
                    }
                </Table.Cell>
                <Table.Cell textAlign="right">
                    <SmallConfirmDeleteButton
                        basic={false}
                        confirmationTitle={formatMessage(m.deleteConfirmationTitle)}
                        confirmationMessage={formatMessage(m.deleteConfirmationMessage, { name: subcontractor.name })}
                        onClick={() => { this.props.onDelete(subcontractor); }}
                    />
                    <SmallEditButton basic={false} onClick={() => this.props.onModify(subcontractor)} />
                </Table.Cell>
            </Table.Row>
        );
    }
}

const intlComponent = injectIntl(SubcontractorsTable);
export { intlComponent as SubcontractorsTable };