import * as React from 'react';
import { Segment, SegmentProps } from 'semantic-ui-react';

export interface NoContentSegmentProps extends SegmentProps {
    title: string;
    subtitle: string;
}

class NoContentSegment extends React.Component<NoContentSegmentProps, {}> {
    public render() {
        const { title, subtitle, ...segmentProps } = this.props;

        return (
            <Segment textAlign="center" {...segmentProps}>
                <h3>{this.props.title}</h3>
                {this.props.subtitle}
            </Segment>
        );
    }
}

export { NoContentSegment };