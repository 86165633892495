import { get, post, httpDelete } from '../../utils/api';
import { ServiceCallNote, NoteVisibility } from '../../state/models';

const notesEndpoint = (serviceCallId: number) => `/api/service-calls/${serviceCallId}/notes`;

export async function loadNotes(serviceCallId: number): Promise<ServiceCallNote[]> {
    return get<ServiceCallNote[]>(`${notesEndpoint(serviceCallId)}`);
}

export async function addNote(serviceCallId: number, content: string, visibility: NoteVisibility): Promise<ServiceCallNote> {
    return post(`${notesEndpoint(serviceCallId)}`, { content, visibility });
}

export async function changeNoteVisibility(serviceCallId: number, noteId: number, visibility: NoteVisibility): Promise<ServiceCallNote> {
    return post(`${notesEndpoint(serviceCallId)}/${noteId}/visibility`, visibility);
}

export async function deleteNote(serviceCallId: number, noteId: number): Promise<void> {
    return httpDelete(`${notesEndpoint(serviceCallId)}/${noteId}`);
}