import { NavigationItem } from "../../components/layouts/PageLayout/NavigationMenu";
import { afterSalesMenuSection, dashboardMenuItem, factoryAppointments, factoryCalendar, factoryHistory } from "./common";

export const factoryTechnicianNavigationItems = (): NavigationItem[] => [
    dashboardMenuItem(),
    {
        ...afterSalesMenuSection(),
        children: [
            factoryAppointments(),
            factoryCalendar(),
            factoryHistory(),
        ]
    },
];