import * as React from 'react';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Popup } from 'semantic-ui-react';

interface OwnProps {
    date: Date | undefined;
}

export type DateFromNowProps =
    & OwnProps;

export class DateFromNow extends React.Component<DateFromNowProps> {
    public render() {
        if (this.props.date == null) {
            return <React.Fragment />
        };

        return (
            <Popup 
                trigger={this.renderDateLabel()} 
                content={this.props.children || this.renderDateTooltip()} 
            />
        );
    }

    private renderDateLabel() {
        return (
            <span>{moment.utc(this.props.date).fromNow()}</span>
        );
    }

    private renderDateTooltip() {
        return moment.utc(this.props.date).format('LL');
    }
}