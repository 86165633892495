import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Message } from 'semantic-ui-react';

import { ApplicationState } from '../../../state/ducks';
import * as UiActions from '../../../state/ducks/ui';
import * as TechniciansActions from '../../../state/ducks/technicians';
import { ServiceReportCard } from '../../../components/service-calls';
import { TechnicianReport, TechnicianRepairsStatus } from '../../../state/models';
import { getTechnicianReport } from '../../../state/ducks/technicians/selectors';
import { StringHelper } from '../../../state/utils';
import { TechnicianReportValidation, TechnicianReportValidator } from '../../../state/validators';

interface TechnicianRepairStepActions {
    uiActions: typeof UiActions.actionCreators;
    techniciansActions: typeof TechniciansActions.actionCreators;
}

interface TechnicianRepairStepOwnProps {
    isReportLoading: boolean;
    report: TechnicianReport | undefined;
}

export type TechnicianRepairStepProps =
    & TechnicianRepairStepOwnProps
    & TechnicianRepairStepActions
    & RouteComponentProps<{ serviceCallId: string, repairId: string }>;

type IntlTechnicianRepairStepProps = TechnicianRepairStepProps & WrappedComponentProps;

const m = defineMessages({
    reportDisabledTitle: { id: 'TechnicianRepairStep.report_disabled_title', defaultMessage: 'Service report is in read-only mode' },
    reportDisabledMessage: { id: 'TechnicianRepairStep.report_disabled_message', defaultMessage: 'This service report cannot be modified because it has already been signed by the client.' },
});

class TechnicianRepairStep extends React.Component<IntlTechnicianRepairStepProps, {}> {
    public componentDidMount() {
        const { repairId } = this.props.match.params;
        this.props.uiActions.changeTechnicianStep('repair');
    }

    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <ServiceReportCard
                    isLoading={this.props.isReportLoading}
                    isDisabled={false}
                    technicianReport={this.props.report}
                    onSave={this.saveReport}
                    onValidate={this.validateReport}
                    onNext={this.goToNextStep}
                />
            </React.Fragment>
        );
    }

    private saveReport = (report: TechnicianReport): void => {
        const { repairId } = this.props.match.params;
        this.props.techniciansActions.saveReport(Number(repairId), report);
    }

    private validateReport = (report: TechnicianReport): TechnicianReportValidation => {
        return TechnicianReportValidator.validateRepairStep(report);
    }

    private goToNextStep = (report: TechnicianReport): TechnicianReportValidation => {
        const validationResult = this.validateReport(report);

        if (validationResult.isValid()) {
            this.props.history.push('complete');
        }

        return validationResult;
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: TechnicianRepairStepProps): TechnicianRepairStepOwnProps => {
    const { repairId } = ownProps.match.params;
    const reportState = getTechnicianReport(state, Number(repairId));

    return {
        isReportLoading: reportState == null || reportState.isLoading,
        report: reportState != null ? reportState.report : undefined
    };
};

const mapDispatchToProps = (dispatch: Dispatch): TechnicianRepairStepActions => {
    return {
        uiActions: bindActionCreators(UiActions.actionCreators, dispatch),
        techniciansActions: bindActionCreators(TechniciansActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(TechnicianRepairStep);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as TechnicianRepairStep };