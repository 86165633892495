import * as React from 'react';
import { Segment, SegmentProps } from 'semantic-ui-react';

export interface EmptyContentProps extends SegmentProps {
    title: string;
    description: string;
}

export class EmptyContentSegment extends React.Component<EmptyContentProps, {}> {
    public render() {
        const { title, description, ...segmentProps } = this.props;

        return (
            <Segment textAlign="center" style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}} {...segmentProps}>
                <h3>{this.props.title}</h3>
                {this.props.description}
            </Segment>
        );
    }
}

export default EmptyContentSegment;