import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Icon, Label, SemanticICONS } from 'semantic-ui-react';

import { ProductionComponent, ItemType, ItemAcquisitionType } from '../../../state/models';
import { ItemTypeIcon } from '../../items';
import { PurchaseLabel } from '.';

export interface ProductionComponentLabelProps {
    productionComponent: ProductionComponent;
}

const m = defineMessages({
    articlesRequiredFormat: { id: 'ProductionComponentLabel.articles_required_format', defaultMessage: '{count} required' }
});

class ProductionComponentLabel extends React.Component<ProductionComponentLabelProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { productionComponent } = this.props;

        return (
            <label style={{display: 'block', width: '100%' }}>
                <ItemTypeIcon itemType={productionComponent.item.type} />
                <div style={{marginLeft: 5, display: 'inline-block'}}>
                    <span className="meta" style={{marginRight: 5, color: 'rgba(0,0,0,0.4)'}}>
                        {productionComponent.item.id}
                    </span>
                    {productionComponent.item.frenchDescription}
                </div>

                <div style={{float: 'right' }}>
                        {productionComponent.quantityRequired.value > 1 &&
                            <Label 
                                horizontal={true} 
                                content={formatMessage(m.articlesRequiredFormat, { count: productionComponent.quantityRequired.value })} 
                                color="teal"
                            />
                        }
                        
                        {productionComponent.item.acquisitionType === ItemAcquisitionType.purchase &&
                            <PurchaseLabel style={{marginLeft: 5}} />
                        }
                    </div>
            </label>
        );
    }
}

const intlComponent = injectIntl(ProductionComponentLabel);
export { intlComponent as ProductionComponentLabel };