import { ThunkAction } from 'redux-thunk';
import { ActionTypeKeys } from '../types';
import { ApplicationState } from '../..';
import { SortOptions, SortDirection } from '../../../models';

export type SortingActionTypes =
    | ApplyOpenedServiceCallsSortingAction
    | ApplyServiceCallsHistorySortingAction
    | ApplyMyServiceCallsSortingAction
    | ApplyStaleServiceCallsSortingAction
    | ApplyCustomSortingAction
    | { type: '' };

interface ApplyOpenedServiceCallsSortingAction { type: ActionTypeKeys.APPLY_OPENED_SORTINGS; payload: { sortBy: string, direction: SortDirection }; }
interface ApplyServiceCallsHistorySortingAction { type: ActionTypeKeys.APPLY_HISTORY_SORTINGS; payload: { sortBy: string, direction: SortDirection }; }
interface ApplyMyServiceCallsSortingAction { type: ActionTypeKeys.APPLY_MINE_SORTINGS; payload: { sortBy: string, direction: SortDirection }; }
interface ApplyStaleServiceCallsSortingAction { type: ActionTypeKeys.APPLY_STALE_SORTINGS; payload: { sortBy: string, direction: SortDirection }; }
interface ApplyCustomSortingAction { type: ActionTypeKeys.APPLY_CUSTOM_SORTINGS; payload: { sortBy: string, direction: SortDirection }; }

export const sortingActionCreators = {
    applySortingOnOpened: (sortBy: string, direction: SortDirection) => {
        return { type: ActionTypeKeys.APPLY_OPENED_SORTINGS, payload: { sortBy, direction } };
    },

    applySortingOnHistory: (sortBy: string, direction: SortDirection) => {
        return { type: ActionTypeKeys.APPLY_HISTORY_SORTINGS, payload: { sortBy, direction } };
    },

    applySortingOnMine: (sortBy: string, direction: SortDirection) => {
        return { type: ActionTypeKeys.APPLY_MINE_SORTINGS, payload: { sortBy, direction } };
    },

    applySortingOnStale: (sortBy: string, direction: SortDirection) => {
        return { type: ActionTypeKeys.APPLY_STALE_SORTINGS, payload: { sortBy, direction } };
    },

    applySortingOnCustomView: (sortBy: string, direction: SortDirection) => {
        return { type: ActionTypeKeys.APPLY_CUSTOM_SORTINGS, payload: { sortBy, direction } };
    }

};