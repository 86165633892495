import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Image, Label, SemanticSIZES, Icon } from 'semantic-ui-react';
import { Media } from '../../../state/models';

export interface VideoThumbnailProps {
    video: Media;
    thumbnailSize?: SemanticSIZES;
    disabled?: boolean;
    onClick?: (video: Media) => void;
}

class VideoThumbnail extends React.Component<VideoThumbnailProps & WrappedComponentProps, {}> {
    public render() {
        const videoIcon = (
            <Icon name="video play" />
        );
        const videoSize = this.props.thumbnailSize || 'medium';

        return this.props.disabled
            ? <Image bordered={true} disabled={true} size={videoSize} />
            : (
                <a onClick={this.handleShowVideoPreview} style={{ cursor: 'pointer' }}>
                    <Image bordered={true} size={videoSize} src={require('../../../assets/images/play-button.png')} />
                </a>
            );
    }

    private handleShowVideoPreview = () => {
        if (this.props.onClick) {
            this.props.onClick(this.props.video);
        }
    }
}

const connectedComponent = injectIntl(VideoThumbnail);
export { connectedComponent as VideoThumbnail };