import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Form } from 'semantic-ui-react';
import { SubContractorBillFormValues } from '../SubContractorBillSection/SubcontractorBillModal';

export const ReferenceNumberInputField = () => {
    const { formatMessage } = useIntl();
    const { control, watch } = useFormContext<SubContractorBillFormValues>();
    const isEnabled = watch('isEnabled');

    return (
        <Controller control={control} name="referenceNumber" render={({field: { value, onChange }}) => (
            <Form.Input
                disabled={!isEnabled}
                label={formatMessage({
                    id: 'SubcontractorRepairPage.BillForm.ReferenceNumber',
                    defaultMessage: 'Reference number',
                })}
                value={value ?? ''}
                onChange={(event, data) => onChange(data.value)}
            />
        )}/>
    );
};
