import React, { useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';

import { bindActionCreators, Dispatch } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { injectIntl, WrappedComponentProps, defineMessages, useIntl } from 'react-intl';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { ApplicationState } from '../../../state/ducks';
import { actionCreators as AppointmentsActions } from '../../../state/ducks/appointments';
import { actionCreators as CalendarActions } from '../../../state/ducks/calendar';

import { PageHeader } from '../../../components/common';
import { AppointmentCalendar, TechnicianCalendarEvent } from '../../../components/service-calls';
import { TechnicianRepair, CalendarBreak } from '../../../state/models';
import { areAppointmentsLoading, getAllAppointments, getAppointmentsCalendarStartDate, getAppointmentsState } from '../../../state/ducks/appointments/selectors';
import { Calendar, CalendarEvent, MobileCalendar } from '../../../components/calendar';
import { getBreaksBetween } from '../../../state/ducks/calendar/selectors';
import { useResponsive } from '../../../utils/responsive';

const m = defineMessages({
    title: { id: 'TechniciansCalendarPage.title', defaultMessage: 'Technicians appointments' },
    subtitle: { id: 'TechniciansCalendarPage.subtitle', defaultMessage: 'View the upcoming appointments for the Jaymar technicians.' },
});

export const TechniciansCalendarPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const { isMobile } = useResponsive();
    const history = useHistory();
    const dispatch = useDispatch();

    const selectedCalendarDate = useSelector(getAppointmentsCalendarStartDate);
    const dateMoment = moment(selectedCalendarDate);
    const startDate = dateMoment.clone().startOf('month').startOf('week').startOf('day').toDate();
    const endDate = dateMoment.clone().endOf('month').endOf('week').startOf('day').toDate();

    const appointments = useSelector((state: ApplicationState) => getAllAppointments(state, startDate));
    const isLoading = useSelector((state: ApplicationState) => areAppointmentsLoading(state, startDate));
    const lockedDates = useSelector((state: ApplicationState) => getBreaksBetween(state, startDate, endDate));

    useEffect(() => {
        const startDate = moment(selectedCalendarDate).startOf('month').startOf('week').startOf('day').toDate();
        const endDate = moment(selectedCalendarDate).endOf('month').endOf('week').startOf('day').toDate();

        dispatch(AppointmentsActions.loadForTechnicians(startDate, endDate));
        dispatch(CalendarActions.loadLocks(startDate, endDate));
    }, [dispatch, selectedCalendarDate]);

    const navigateToServiceCall = (appointment: TechnicianRepair) => history.push(`/service-calls/${appointment.serviceCallId}/details`);
    const changeCalendarStartDate = (date: moment.Moment) => {
        const startDate = date.clone().startOf('month').startOf('week').startOf('day').toDate();
        const endDate = date.clone().endOf('month').endOf('week').startOf('day').toDate();

        dispatch(AppointmentsActions.setCalendarStartDate(date.toDate()));
        dispatch(AppointmentsActions.loadForTechnicians(startDate, endDate));
        dispatch(CalendarActions.loadLocks(startDate, endDate));
    }


    const calendarEvents: CalendarEvent[] = appointments
        .filter(x => x.scheduledForDate != null)
        .map(x => ({
            on: moment(x.scheduledForDate).local().startOf('day').toDate(),
            element: <TechnicianCalendarEvent key={x.id} repair={x} onRepairClicked={navigateToServiceCall} />
        }));

    return (
        <div>
            <PageHeader
                iconName="calendar"
                title={formatMessage(m.title)}
                subtitle={formatMessage(m.subtitle)}
            />

            {!isMobile &&
                <Calendar
                    forDate={selectedCalendarDate}
                    events={calendarEvents}
                    lockedDates={lockedDates}
                    isLoading={isLoading}
                    canSelectDate={false}
                    onMonthChanged={(date) => changeCalendarStartDate(moment(date))}
                />
            }

            {isMobile &&
                <MobileCalendar
                    forDate={selectedCalendarDate}
                    events={calendarEvents}
                    lockedDates={lockedDates}
                    isLoading={isLoading}
                    canSelectDate={false}
                    onMonthChanged={(date) => changeCalendarStartDate(moment(date))}
                />
            }
        </div>
    )
}

