import React from 'react';
import moment from 'moment';

import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Dropdown, DropdownItemProps, DropdownProps, Grid, Form } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { FilterSection } from '../common';
import { DateFilter, DateFilterType, initialDateFilter } from '../../state/models';
import { commonMessages } from '../../constants';

interface FilterByDateSectionOwnProps {
    filter: DateFilter;
    availableFilters?: DateFilterType[];
    defaultFilterType?: DateFilterType;
    onChange: (filter: DateFilter) => void;
}

interface FilterByDateSectionState {
    selectedFilterType: DateFilterType;
    customStartDate: Date | undefined;
    customEndDate: Date | undefined;
}

export type FilterByDateSectionProps =
    & FilterByDateSectionOwnProps
    & WrappedComponentProps;

const initialState: FilterByDateSectionState = {
    selectedFilterType: initialDateFilter.type,
    customStartDate: initialDateFilter.customStartDate,
    customEndDate: initialDateFilter.customEndDate
};

const allDateFilterTypes: DateFilterType[] = [
    DateFilterType.today,
    DateFilterType.thisWeek,
    DateFilterType.thisMonth,
    DateFilterType.thisYear,
    DateFilterType.previousYear,
    DateFilterType.pastFiveYears,
    DateFilterType.custom
];

const m = defineMessages({
    defaultTitle: { id: 'FilterByDateSection.title', defaultMessage: 'Filter by date' },
    today: { id: 'FilterByDateSection.today', defaultMessage: 'Today' },
    thisWeek: { id: 'FilterByDateSection.this_week', defaultMessage: 'This week' },
    thisMonth: { id: 'FilterByDateSection.this_month', defaultMessage: 'This month' },
    lastMonth: { id: 'FilterByDateSection.lastManth', defaultMessage: 'Last month' },
    thisYear: { id: 'FilterByDateSection.this_year', defaultMessage: 'This year' },
    previousYear: { id: 'FilterByDateSection.previous_year', defaultMessage: 'Previous year' },
    pastFiveYears: { id: 'FilterByDateSection.past_five_years', defaultMessage: 'Past five years' },
    custom: { id: 'FilterByDateSection.custom', defaultMessage: 'Between two dates' },
    datePlaceholder: { id: 'FilterByDateSection.date_placeholder', defaultMessage: 'YYYY/MM/DD' },
    startDateLabel: { id: 'FilterByDateSection.start_date_label', defaultMessage: 'From' },
    endDateLabel: { id: 'FilterByDateSection.end_date_label', defaultMessage: 'To' },
});

class FilterByDateSection extends React.Component<FilterByDateSectionProps, FilterByDateSectionState> {
    private optionsMap: Map<DateFilterType, DropdownItemProps>;

    public constructor(props: FilterByDateSectionProps) {
        super(props);

        this.state = {
            selectedFilterType: props.filter.type,
            customStartDate: props.filter.customStartDate,
            customEndDate: props.filter.customEndDate
        };

        const { formatMessage } = props.intl;

        this.optionsMap = new Map<DateFilterType, DropdownItemProps>([
            [DateFilterType.today, { text: formatMessage(m.today), value: DateFilterType.today, selected: this.state.selectedFilterType === DateFilterType.today }],
            [DateFilterType.thisWeek, { text: formatMessage(m.thisWeek), value: DateFilterType.thisWeek, selected: this.state.selectedFilterType === DateFilterType.thisWeek }],
            [DateFilterType.thisMonth, { text: formatMessage(m.thisMonth), value: DateFilterType.thisMonth, selected: this.state.selectedFilterType === DateFilterType.thisMonth }],
            [DateFilterType.lastMonth, { text: formatMessage(m.lastMonth), value: DateFilterType.lastMonth, selected: this.state.selectedFilterType === DateFilterType.lastMonth }],
            [DateFilterType.thisYear, { text: formatMessage(m.thisYear), value: DateFilterType.thisYear, selected: this.state.selectedFilterType === DateFilterType.thisYear }],
            [DateFilterType.previousYear, { text: formatMessage(m.previousYear), value: DateFilterType.previousYear, selected: this.state.selectedFilterType === DateFilterType.previousYear }],
            [DateFilterType.pastFiveYears, { text: formatMessage(m.pastFiveYears), value: DateFilterType.pastFiveYears, selected: this.state.selectedFilterType === DateFilterType.pastFiveYears }],
            [DateFilterType.custom, { text: formatMessage(m.custom), value: DateFilterType.custom, selected: this.state.selectedFilterType === DateFilterType.custom }]
        ]);
    }

    public componentWillReceiveProps(props: FilterByDateSectionProps) {
        this.setState({
            selectedFilterType: props.filter.type,
            customStartDate: props.filter.customStartDate,
            customEndDate: props.filter.customEndDate
        });
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const dropdownOptions: DropdownItemProps[] = (this.props.availableFilters || allDateFilterTypes).map(x => this.optionsMap.get(x)
            || { text: formatMessage(commonMessages.unknown), value: DateFilterType.custom });

        return (
            <FilterSection
                title={formatMessage(m.defaultTitle)}
                onClear={this.clearSection}
            >
                <Dropdown
                    fluid={true}
                    options={dropdownOptions}
                    selection={true}
                    multiple={false}
                    closeOnChange={true}
                    onChange={this.changeFilterType}
                    value={this.state.selectedFilterType}
                />

                {this.state.selectedFilterType === DateFilterType.custom &&
                    <Form style={{ marginTop: '10px' }} unstackable={true} widths="equal">
                        <Form.Group unstackable={true} widths="equal">
                            <Form.Field>
                                <label>{formatMessage(m.startDateLabel)}</label>
                                <DatePicker
                                    dateFormat="YYYY/MM/DD"
                                    onChange={this.changeStartDate}
                                    onMonthChange={this.changeStartDate}
                                    onYearChange={this.changeStartDate}
                                    maxDate={new Date()}
                                    fixedHeight={true}
                                    showMonthDropdown={true}
                                    showYearDropdown={true}
                                    scrollableYearDropdown={true}
                                    scrollableMonthYearDropdown={true}
                                    yearDropdownItemNumber={20}
                                    selected={this.state.customStartDate != null ? moment(this.state.customStartDate).toDate() : undefined}
                                    value={this.state.customStartDate ? moment(this.state.customStartDate).format('YYYY/MM/DD') : undefined}
                                    placeholderText={formatMessage(m.datePlaceholder)}
                                />
                            </Form.Field>

                            <Form.Field>
                                <label>{formatMessage(m.endDateLabel)}</label>
                                <DatePicker
                                    dateFormat="YYYY/MM/DD"
                                    onChange={this.changeEndDate}
                                    onMonthChange={this.changeEndDate}
                                    onYearChange={this.changeEndDate}
                                    minDate={this.state.customStartDate}
                                    maxDate={new Date()}
                                    fixedHeight={true}
                                    showMonthDropdown={true}
                                    showYearDropdown={true}
                                    scrollableYearDropdown={true}
                                    scrollableMonthYearDropdown={true}
                                    yearDropdownItemNumber={20}
                                    selected={this.state.customEndDate != null ? moment(this.state.customEndDate).toDate() : undefined}
                                    value={this.state.customEndDate ? moment(this.state.customEndDate).format('YYYY/MM/DD') : undefined}
                                    placeholderText={formatMessage(m.datePlaceholder)}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                }
            </FilterSection>
        );
    }

    private clearSection = () => {
        const resetFilterType = this.props.defaultFilterType || initialDateFilter.type;

        this.setState({
            selectedFilterType: resetFilterType,
            customStartDate: initialDateFilter.customStartDate,
            customEndDate: initialDateFilter.customEndDate
        });

        this.props.onChange({
            type: resetFilterType,
            customStartDate: initialDateFilter.customStartDate,
            customEndDate: initialDateFilter.customEndDate
        });
    }

    private changeStartDate = (date: Date | null) => {
        const customStartDate = date || undefined;
        const customEndDate = date == null || moment(date).isAfter(this.state.customEndDate) ? undefined : this.state.customEndDate;

        this.setState({
            customStartDate: customStartDate,
            customEndDate: customEndDate
        });

        this.props.onChange({
            type: this.state.selectedFilterType,
            customStartDate: customStartDate || initialDateFilter.customStartDate,
            customEndDate: customEndDate || initialDateFilter.customEndDate
        });
    }

    private changeEndDate = (date: Date | null) => {
        this.setState({ customEndDate: date || undefined });

        this.props.onChange({
            type: this.state.selectedFilterType,
            customStartDate: this.state.customStartDate || initialDateFilter.customStartDate,
            customEndDate: date || initialDateFilter.customEndDate
        });
    }

    private changeFilterType = (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const newType = data.value as DateFilterType;
        this.setState({ selectedFilterType: newType });

        this.props.onChange({
            type: newType,
            customStartDate: newType === DateFilterType.custom && this.state.customStartDate
                ? this.state.customStartDate
                : initialDateFilter.customStartDate,
            customEndDate: newType === DateFilterType.custom && this.state.customEndDate
                ? this.state.customEndDate
                : initialDateFilter.customEndDate
        });
    }
}

const connectedComponent = injectIntl(FilterByDateSection);
export { connectedComponent as FilterByDateSection };