import { Reducer } from 'redux';
import { ActionsStatistics, AgentWorkload, AttachmentType, PagedResult, PagedServiceCall, ServiceCall, ServiceCallStatus, ServiceCallUpdate, Subcontractor } from '../../models';
import { StringHelper } from '../../utils';
import { ActionTypes } from './actions';
import { ActionTypeKeys } from './types';

export interface ServiceCallState {
    details: ServiceCall;
    activities: ServiceCallUpdate[];
    isLoading: boolean;
    arePicturesUploading: boolean;
    areVideosUploading: boolean;
    areDocumentsUploading: boolean;
    areActivitiesLoading: boolean;
    isNoteSaving: boolean;
    isAttachmentDeleting: number[];
    isClosed: boolean;
    isChangingDefectiveItems: boolean;
    isCancellingCurrentRepairs: boolean;
    isExecutingRepairStep: boolean;
    isSavingTechnicianRepairs: boolean;
}

export interface ServiceCallsStatisticsState {
    new: number;
    isNewLoading: boolean;
    actions: ActionsStatistics;
    areActionsLoading: boolean;
    agentsWorkload: AgentWorkload[];
    isAgentsWorkloadLoading: boolean;
}

export interface ServiceCallsState {
    mine: PagedServiceCall;
    stale: PagedServiceCall;
    opened: PagedServiceCall;
    history: PagedServiceCall;
    custom: PagedServiceCall;
    statistics: ServiceCallsStatisticsState;

    serviceCalls: {
        [serviceCallId: number]: ServiceCallState;
    };

    isServiceCallCreating: boolean;
    isServiceCallClosing: boolean;
    isServiceCallReopening: boolean;
    isServiceCallCancelling: boolean;
    isServiceCallLinking: boolean;

    subcontractors: Subcontractor[];
    areSubcontractorsLoading: boolean;
}

export const initialState: ServiceCallsState = {
    mine: {
        results: PagedResult.empty<ServiceCall>(),
        filters: {},
        isLoading: false,
    },
    stale: {
        results: PagedResult.empty<ServiceCall>(),
        filters: {},
        isLoading: false,
    },
    opened: {
        results: PagedResult.empty<ServiceCall>(),
        filters: {},
        isLoading: false,
    },
    history: {
        results: PagedResult.empty<ServiceCall>(),
        filters: {},
        isLoading: false,
    },
    custom: {
        results: PagedResult.empty<ServiceCall>(),
        filters: {},
        isLoading: false,
    },

    statistics: {
        new: 0,
        isNewLoading: false,
        actions: {
            toAssign: 0,
            toInspect: 0,
            toApprove: 0,
            toRepair: 0,
            toClose: 0,
            awaitingFeesApproval: 0,
            awaitingAppointment: 0,
            awaitingFurniture: 0,
            awaitingRepairs: 0,
        },
        areActionsLoading: false,
        agentsWorkload: [],
        isAgentsWorkloadLoading: false
    },

    serviceCalls: {},
    isServiceCallCreating: false,
    isServiceCallClosing: false,
    isServiceCallReopening: false,
    isServiceCallLinking: false,
    isServiceCallCancelling: false,
    subcontractors: [],
    areSubcontractorsLoading: false
};

export const serviceCallReducer: Reducer<ServiceCallsState> = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case ActionTypeKeys.LOAD_MINE_REQUEST:
            return {
                ...state,
                mine: {
                    ...state.mine,
                    isLoading: true
                }
            };

        case ActionTypeKeys.LOAD_MINE_SUCCESS:
            return {
                ...state,
                mine: {
                    ...state.mine,
                    results: action.payload,
                    isLoading: false
                }
            };

        case ActionTypeKeys.LOAD_MINE_FAILURE:
            return {
                ...state,
                mine: {
                    ...state.mine,
                    isLoading: false
                }
            };

        case ActionTypeKeys.LOAD_STALE_REQUEST: {
            return {
                ...state,
                stale: {
                    ...state.stale,
                    isLoading: true
                }
            };
        }

        case ActionTypeKeys.LOAD_STALE_SUCCESS: {
            return {
                ...state,
                stale: {
                    ...state.stale,
                    results: action.payload,
                    isLoading: false
                }
            };
        }

        case ActionTypeKeys.LOAD_STALE_FAILURE: {
            return {
                ...state,
                stale: {
                    ...state.stale,
                    isLoading: false
                }
            };
        }

        case ActionTypeKeys.LOAD_OPENED_REQUEST:
            return {
                ...state,
                opened: {
                    ...state.opened,
                    isLoading: true
                }
            };

        case ActionTypeKeys.LOAD_OPENED_SUCCESS:
            return {
                ...state,
                opened: {
                    ...state.opened,
                    results: action.payload,
                    isLoading: false
                },
            };

        case ActionTypeKeys.LOAD_OPENED_FAILURE:
            return {
                ...state,
                opened: {
                    ...state.opened,
                    isLoading: false
                }
            };

        case ActionTypeKeys.LOAD_CUSTOM_REQUEST:
            return {
                ...state,
                custom: {
                    ...state.custom,
                    isLoading: true
                }
            };

        case ActionTypeKeys.LOAD_CUSTOM_SUCCESS:
            return {
                ...state,
                custom: {
                    ...state.custom,
                    results: action.payload,
                    isLoading: false
                },
            };

        case ActionTypeKeys.LOAD_CUSTOM_FAILURE:
            return {
                ...state,
                custom: {
                    ...state.custom,
                    isLoading: false
                }
            };

        case ActionTypeKeys.LOAD_NEW_COUNT_REQUEST:
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    isNewLoading: true
                }
            };

        case ActionTypeKeys.LOAD_NEW_COUNT_SUCCESS:
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    new: action.payload,
                    isNewLoading: false
                }
            };

        case ActionTypeKeys.LOAD_NEW_COUNT_FAILURE:
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    isNewLoading: false
                }
            };

        case ActionTypeKeys.LOAD_AGENTS_WORKLOAD_REQUEST:
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    isAgentsWorkloadLoading: true
                }
            };

        case ActionTypeKeys.LOAD_AGENTS_WORKLOAD_SUCCESS:
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    isAgentsWorkloadLoading: false,
                    agentsWorkload: action.payload
                }
            };

        case ActionTypeKeys.LOAD_AGENTS_WORKLOAD_FAILURE:
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    isAgentsWorkloadLoading: false
                }
            };

        case ActionTypeKeys.LOAD_AGENT_NEXT_ACTIONS_REQUEST:
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    areActionsLoading: true
                }
            };

        case ActionTypeKeys.LOAD_AGENT_NEXT_ACTIONS_SUCCESS:
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    areActionsLoading: false,
                    actions: action.payload
                }
            };

        case ActionTypeKeys.LOAD_AGENT_NEXT_ACTIONS_FAILURE:
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    areActionsLoading: false
                }
            };

        case ActionTypeKeys.LOAD_DETAILS_REQUEST:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload]: {
                        ...state.serviceCalls[action.payload],
                        isLoading: true
                    }
                }
            };

        case ActionTypeKeys.LOAD_DETAILS_SUCCESS:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.id]: {
                        ...state.serviceCalls[action.payload.id],
                        details: action.payload,
                        isLoading: false,
                        isClosed: action.payload.status === ServiceCallStatus.closed,
                    }
                }
            };

        case ActionTypeKeys.LOAD_DETAILS_FAILURE:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.id]: {
                        ...state.serviceCalls[action.payload.id],
                        isLoading: false
                    }
                }
            };

        case ActionTypeKeys.LOAD_HISTORY_REQUEST:
            return {
                ...state,
                history: {
                    ...state.history,
                    isLoading: true
                }
            };

        case ActionTypeKeys.LOAD_HISTORY_SUCCESS:
            return {
                ...state,
                history: {
                    ...state.history,
                    results: action.payload,
                    isLoading: false
                }
            };

        case ActionTypeKeys.LOAD_HISTORY_FAILURE:
            return {
                ...state,
                history: {
                    ...state.history,
                    isLoading: false
                }
            };

        case ActionTypeKeys.CREATE_REQUEST: {
            return {
                ...state,
                isServiceCallCreating: true
            };
        }

        case ActionTypeKeys.CREATE_SUCCESS:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.id]: {
                        details: action.payload,
                        activities: [],
                        areDocumentsUploading: false,
                        arePicturesUploading: false,
                        areVideosUploading: false,
                        areActivitiesLoading: false,
                        isNoteSaving: false,
                        isAttachmentDeleting: [],
                        isLoading: false,
                        isClosed: false,
                        isChangingDefectiveItems: false,
                        isCancellingCurrentRepairs: false,
                        isExecutingRepairStep: false,
                        isSavingTechnicianRepairs: false
                    }
                },
                statistics: {
                    ...state.statistics,
                    new: state.statistics.new + 1
                },
                isServiceCallCreating: false
            };

        case ActionTypeKeys.CREATE_FAILURE: {
            return {
                ...state,
                isServiceCallCreating: false
            };
        }

        case ActionTypeKeys.UPDATE_SUCCESS:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.id]: {
                        ...state.serviceCalls[action.payload.id],
                        details: action.payload,
                    }
                },
            };

        case ActionTypeKeys.UPLOAD_VIDEOS_REQUEST:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload]: {
                        ...state.serviceCalls[action.payload],
                        areVideosUploading: true
                    }
                }
            };

        case ActionTypeKeys.UPLOAD_VIDEOS_SUCCESS: {
            const serviceCall = state.serviceCalls[action.payload.serviceCallId].details;
            const videos = serviceCall.attachments;
            videos.push(...action.payload.attachments);

            const status = serviceCall.status === ServiceCallStatus.draft ? ServiceCallStatus.created : serviceCall.status;

            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        details: {
                            ...state.serviceCalls[action.payload.serviceCallId].details,
                            attachments: videos,
                            status: status
                        },
                        areVideosUploading: false
                    }
                }
            };
        }

        case ActionTypeKeys.UPLOAD_VIDEOS_FAILURE:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        areVideosUploading: false
                    }
                }
            };

        case ActionTypeKeys.UPLOAD_PICTURES_REQUEST:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload]: {
                        ...state.serviceCalls[action.payload],
                        arePicturesUploading: true
                    }
                }
            };

        case ActionTypeKeys.UPLOAD_PICTURES_SUCCESS: {
            const serviceCall = state.serviceCalls[action.payload.serviceCallId].details;
            const attachments = serviceCall.attachments;
            attachments.push(...action.payload.attachments);

            const status = serviceCall.status === ServiceCallStatus.draft ? ServiceCallStatus.created : serviceCall.status;

            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        details: {
                            ...state.serviceCalls[action.payload.serviceCallId].details,
                            attachments: attachments,
                            status: status
                        },
                        arePicturesUploading: false
                    }
                }
            };
        }

        case ActionTypeKeys.UPLOAD_PICTURES_FAILURE:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        arePicturesUploading: false
                    }
                }
            };

        case ActionTypeKeys.UPLOAD_DOCUMENTS_REQUEST:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload]: {
                        ...state.serviceCalls[action.payload],
                        areDocumentsUploading: true
                    }
                }
            };

        case ActionTypeKeys.UPLOAD_DOCUMENTS_SUCCESS:
            const attachmentsWithUploadedDocument = state.serviceCalls[action.payload.serviceCallId].details.attachments;
            attachmentsWithUploadedDocument.push(...action.payload.attachments);

            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        details: {
                            ...state.serviceCalls[action.payload.serviceCallId].details,
                            attachments: attachmentsWithUploadedDocument
                        },
                        areDocumentsUploading: false
                    }
                }
            };

        case ActionTypeKeys.UPLOAD_DOCUMENTS_FAILURE:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        areDocumentsUploading: false
                    }
                }
            };

        case ActionTypeKeys.DELETE_ATTACHMENT_REQUEST: {
            const attachmentsDeleting = state.serviceCalls[action.payload.serviceCallId].isAttachmentDeleting || [];

            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        isAttachmentDeleting: attachmentsDeleting.concat(action.payload.attachmentId)
                    }
                }
            };
        }

        case ActionTypeKeys.DELETE_ATTACHMENT_SUCCESS: {
            const serviceCall = state.serviceCalls[action.payload.serviceCallId].details;
            const attachments = serviceCall.attachments.filter(x => x.id !== action.payload.attachmentId);
            const hasPictures = attachments.filter(x => x.type === AttachmentType.image || x.type === AttachmentType.video).length > 0;

            const status = !hasPictures && serviceCall.status === ServiceCallStatus.created ? ServiceCallStatus.draft : serviceCall.status;

            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        details: {
                            ...state.serviceCalls[action.payload.serviceCallId].details,
                            attachments: attachments,
                            status: status
                        },
                        arePicturesUploading: false,
                        areVideosUploading: false,
                        isAttachmentDeleting: state.serviceCalls[action.payload.serviceCallId].isAttachmentDeleting.filter(x => x !== action.payload.attachmentId)
                    }
                }
            };
        }

        case ActionTypeKeys.DELETE_ATTACHMENT_FAILURE:

            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        isAttachmentDeleting: state.serviceCalls[action.payload.serviceCallId].isAttachmentDeleting.filter(x => x !== action.payload.attachmentId)
                    }
                }
            };

        case ActionTypeKeys.ASSIGN_AGENT_SUCCESS:
            const hadAgentSelectedPreviously = state.serviceCalls[action.payload.serviceCallId].details.assignedTo != null;
            const newServiceCallsCount = hadAgentSelectedPreviously ? state.statistics.new : state.statistics.new - 1;

            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    new: newServiceCallsCount
                },
                serviceCalls: {
                    ...state.serviceCalls,

                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        details: {
                            ...state.serviceCalls[action.payload.serviceCallId].details,
                            assignedTo: action.payload.agent
                        },
                    }
                },
            };

        case ActionTypeKeys.ASSIGN_REPRESENTATIVE_SUCCESS:
            const inspections = state.serviceCalls[action.payload.serviceCallId].details.inspections;
            inspections.push(action.payload.inspection);

            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        details: {
                            ...state.serviceCalls[action.payload.serviceCallId].details,
                            inspections: inspections
                        },
                    }
                }
            };

        case ActionTypeKeys.CONFIRM_INSPECTION_COMPLETION_SUCCESS:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        details: {
                            ...state.serviceCalls[action.payload.serviceCallId].details,
                            inspections: state.serviceCalls[action.payload.serviceCallId].details.inspections
                                .filter(x => x.id !== action.payload.inspection.id)
                                .concat(action.payload.inspection)
                        },
                    }
                }
            };

        case ActionTypeKeys.ADD_DEFECT_SUCCESS: {
            // We filter out the added defect to prevent duplicates
            const serviceCall = state.serviceCalls[action.payload.serviceCallId].details;
            let serviceCallDefects = serviceCall.defects.filter(x => x.defect.id !== action.payload.defect.id);
            serviceCallDefects.push({
                id: action.payload.defect.id,
                serviceCallId: action.payload.serviceCallId,
                defect: action.payload.defect
            });

            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        details: {
                            ...state.serviceCalls[action.payload.serviceCallId].details,
                            defects: serviceCallDefects
                        }
                    }
                }
            };
        }

        case ActionTypeKeys.DELETE_DEFECT_SUCCESS:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        details: {
                            ...state.serviceCalls[action.payload.serviceCallId].details,
                            defects: state.serviceCalls[action.payload.serviceCallId].details.defects.filter(x => x.defect.id !== action.payload.defect.id)
                        }
                    }
                }
            };

        case ActionTypeKeys.ADD_DEFECTIVE_ITEM_SUCCESS:
            let defectiveItems = state.serviceCalls[action.payload.serviceCallId].details.defectiveItems;
            if (action.payload.defectiveItem.itemQuantity > 0) {
                defectiveItems.push(action.payload.defectiveItem);
            } else {
                defectiveItems = defectiveItems.filter(x => x.id !== action.payload.defectiveItem.id);
            }

            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        details: {
                            ...state.serviceCalls[action.payload.serviceCallId].details,
                            defectiveItems: defectiveItems
                        }
                    }
                }
            };

        case ActionTypeKeys.CHANGE_DEFECTIVE_ITEMS_REQUEST:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload]: {
                        ...state.serviceCalls[action.payload],
                        isChangingDefectiveItems: true
                    }
                }
            };

        case ActionTypeKeys.CHANGE_DEFECTIVE_ITEMS_SUCCESS:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        details: {
                            ...state.serviceCalls[action.payload.serviceCallId].details,
                            defectiveItems: action.payload.defectiveItems
                        },
                        isChangingDefectiveItems: false
                    }
                }
            };

        case ActionTypeKeys.CHANGE_DEFECTIVE_ITEMS_FAILURE:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        isChangingDefectiveItems: false
                    }
                }
            };

        case ActionTypeKeys.DELETE_DEFECTIVE_ITEM_SUCCESS:
            let defectiveItemsWithoutDeleted = state.serviceCalls[action.payload.serviceCallId].details.defectiveItems.filter(x => StringHelper.trim(x.itemId) !== StringHelper.trim(action.payload.itemId));

            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        details: {
                            ...state.serviceCalls[action.payload.serviceCallId].details,
                            defectiveItems: defectiveItemsWithoutDeleted
                        }
                    }
                }
            };

        case ActionTypeKeys.UPDATE_REPAIR_FEES_ESTIMATE_SUCCESS:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        details: {
                            ...state.serviceCalls[action.payload.serviceCallId].details,
                            estimatedFees: action.payload.repairFeesEstimate
                        }
                    }
                }
            };

        case ActionTypeKeys.CANCEL_CURRENT_REPAIRS_REQUEST:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload]: {
                        ...state.serviceCalls[action.payload],
                        isCancellingCurrentRepairs: true
                    }
                }
            };

        case ActionTypeKeys.CANCEL_CURRENT_REPAIRS_SUCCESS:
            const factoryRepairsWithoutCurrent = state.serviceCalls[action.payload.serviceCallId].details.factoryRepairs.filter(x => x.completedRepairsOn != null);
            const technicianRepairsWithoutCurrent = state.serviceCalls[action.payload.serviceCallId].details.technicianRepairs.filter(x => x.completedOn != null);
            const subcontractorRepairsWithoutCurrent = state.serviceCalls[action.payload.serviceCallId].details.subcontractorRepairs.filter(x => x.subcontractorBill != null);
            const creditReturnsWithoutCurrent = state.serviceCalls[action.payload.serviceCallId].details.creditReturns.filter(x => x.creditedOn != null);

            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        isCancellingCurrentRepairs: false,
                        details: {
                            ...state.serviceCalls[action.payload.serviceCallId].details,
                            factoryRepairs: factoryRepairsWithoutCurrent,
                            technicianRepairs: technicianRepairsWithoutCurrent,
                            subcontractorRepairs: subcontractorRepairsWithoutCurrent,
                            creditReturns: creditReturnsWithoutCurrent
                        }
                    }
                }
            };

        case ActionTypeKeys.CANCEL_CURRENT_REPAIRS_FAILURE:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        isCancellingCurrentRepairs: false
                    }
                }
            };

        case ActionTypeKeys.CLOSE_REQUEST:
            return {
                ...state,
                isServiceCallClosing: true
            };

        case ActionTypeKeys.CLOSE_SUCCESS:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.id]: {
                        ...state.serviceCalls[action.payload.id],
                        details: action.payload
                    }
                },
                isServiceCallClosing: false
            };

        case ActionTypeKeys.REOPEN_REQUEST:
            return {
                ...state,
                isServiceCallReopening: true
            };

        case ActionTypeKeys.REOPEN_SUCCESS:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.id]: {
                        ...state.serviceCalls[action.payload.id],
                        details: action.payload
                    }
                },
                isServiceCallReopening: false
            };

        case ActionTypeKeys.CANCEL_REQUEST:
            return {
                ...state,
                isServiceCallCancelling: true
            };

        case ActionTypeKeys.CANCEL_SUCCESS:
        case ActionTypeKeys.CANCEL_FAILURE:
            return {
                ...state,
                isServiceCallCancelling: false
            };

        case ActionTypeKeys.LINK_NEW_REQUEST:
            return {
                ...state,
                isServiceCallLinking: true
            };

        case ActionTypeKeys.LINK_NEW_SUCCESS:
        case ActionTypeKeys.LINK_NEW_FAILURE:
            return {
                ...state,
                isServiceCallLinking: false
            };

        case ActionTypeKeys.APPLY_OPENED_FILTERS:
            return {
                ...state,
                opened: {
                    ...state.opened,
                    filters: action.payload
                }
            };

        case ActionTypeKeys.APPLY_HISTORY_FILTERS:
            return {
                ...state,
                history: {
                    ...state.history,
                    filters: action.payload
                }
            };

        case ActionTypeKeys.APPLY_MINE_FILTERS:
            return {
                ...state,
                mine: {
                    ...state.mine,
                    filters: action.payload
                }
            };

        case ActionTypeKeys.APPLY_CUSTOM_FILTERS:
            return {
                ...state,
                custom: {
                    ...state.custom,
                    filters: action.payload
                }
            };

        case ActionTypeKeys.APPLY_OPENED_SORTINGS:
            return {
                ...state,
                opened: {
                    ...state.opened,
                    sortings: {
                        ...state.opened.sortings,
                        sortBy: action.payload.sortBy,
                        direction: action.payload.direction
                    }
                }
            };

        case ActionTypeKeys.APPLY_HISTORY_SORTINGS:
            return {
                ...state,
                history: {
                    ...state.history,
                    sortings: {
                        ...state.history.sortings,
                        sortBy: action.payload.sortBy,
                        direction: action.payload.direction
                    }
                }
            };

        case ActionTypeKeys.APPLY_MINE_SORTINGS:
            return {
                ...state,
                mine: {
                    ...state.mine,
                    sortings: {
                        ...state.mine.sortings,
                        sortBy: action.payload.sortBy,
                        direction: action.payload.direction
                    }
                }
            };

        case ActionTypeKeys.APPLY_STALE_SORTINGS:
            return {
                ...state,
                stale: {
                    ...state.stale,
                    sortings: {
                        ...state.stale.sortings,
                        sortBy: action.payload.sortBy,
                        direction: action.payload.direction
                    }
                }
            };

        case ActionTypeKeys.APPLY_CUSTOM_SORTINGS:
            return {
                ...state,
                custom: {
                    ...state.custom,
                    sortings: {
                        ...state.custom.sortings,
                        sortBy: action.payload.sortBy,
                        direction: action.payload.direction
                    }
                }
            };

        case ActionTypeKeys.LOAD_SUBCONTRACTORS_REQUEST:
            return {
                ...state,
                areSubcontractorsLoading: true
            };

        case ActionTypeKeys.LOAD_SUBCONTRACTORS_SUCCESS:
            return {
                ...state,
                subcontractors: action.payload,
                areSubcontractorsLoading: false
            };

        case ActionTypeKeys.LOAD_SUBCONTRACTORS_FAILURE:
            return {
                ...state,
                areSubcontractorsLoading: false
            };

        case ActionTypeKeys.CREATE_SUBCONTRACTOR_REPAIRS_REQUEST:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        isExecutingRepairStep: true
                    }
                }
            };

        case ActionTypeKeys.CREATE_SUBCONTRACTOR_REPAIRS_SUCCESS:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        details: {
                            ...state.serviceCalls[action.payload.serviceCallId].details,
                            subcontractorRepairs: state.serviceCalls[action.payload.serviceCallId].details.subcontractorRepairs.concat(action.payload.subcontractorRepair)
                        },
                        isExecutingRepairStep: false
                    }
                }
            };

        case ActionTypeKeys.CREATE_SUBCONTRACTOR_REPAIRS_FAILURE:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        isExecutingRepairStep: false
                    }
                }
            };

        case ActionTypeKeys.SET_SUBCONTRACTOR_REPAIR_WARRANTY_STATUS_REQUEST: {
            return {
                ...state,
                serviceCalls: {
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        isExecutingRepairStep: true
                    }
                }
            };
        }

        case ActionTypeKeys.SET_SUBCONTRACTOR_REPAIR_WARRANTY_STATUS_SUCCESS: {
            let subcontractorRepairs = state.serviceCalls[action.payload.serviceCallId].details.subcontractorRepairs
                .filter(x => x.id !== action.payload.id)
                .concat(action.payload);

            return {
                ...state,
                serviceCalls: {
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        isExecutingRepairStep: false,
                        details: {
                            ...state.serviceCalls[action.payload.serviceCallId].details,
                            subcontractorRepairs: subcontractorRepairs,
                        }
                    }
                }
            };
        }

        case ActionTypeKeys.SET_SUBCONTRACTOR_REPAIR_WARRANTY_STATUS_FAILURE: {
            return {
                ...state,
                serviceCalls: {
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        isExecutingRepairStep: true
                    }
                }
            };
        }

        case ActionTypeKeys.SET_SUBCONTRACTOR_APPOINTMENT_DATE_REQUEST: {
            return {
                ...state,
                serviceCalls: {
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        isExecutingRepairStep: true
                    }
                }
            };
        }

        case ActionTypeKeys.SET_SUBCONTRACTOR_APPOINTMENT_DATE_SUCCESS: {
            let subcontractorRepairs = state.serviceCalls[action.payload.serviceCallId].details.subcontractorRepairs
                .filter(x => x.id !== action.payload.id)
                .concat(action.payload);

            return {
                ...state,
                serviceCalls: {
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        isExecutingRepairStep: false,
                        details: {
                            ...state.serviceCalls[action.payload.serviceCallId].details,
                            subcontractorRepairs: subcontractorRepairs,
                        }
                    }
                }
            };
        }

        case ActionTypeKeys.SET_SUBCONTRACTOR_APPOINTMENT_DATE_FAILURE: {
            return {
                ...state,
                serviceCalls: {
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        isExecutingRepairStep: true
                    }
                }
            };
        }

        case ActionTypeKeys.ADD_SUBCONTRACTOR_BILL_SUCCESS: {
            let subcontractorRepairs = state.serviceCalls[action.payload.serviceCallId].details.subcontractorRepairs;
            const subcontractorRepair = subcontractorRepairs.find(x => x.id === action.payload.subcontractorRepairId);
            if (subcontractorRepair) {
                subcontractorRepair.subcontractorBill = action.payload.bill;
                subcontractorRepairs = subcontractorRepairs.filter(x => x.id !== action.payload.subcontractorRepairId).concat(subcontractorRepair);
            }

            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        details: {
                            ...state.serviceCalls[action.payload.serviceCallId].details,
                            subcontractorRepairs: subcontractorRepairs
                        }
                    }
                }
            };
        }

        case ActionTypeKeys.CREATE_CREDIT_RETURN_REQUEST:
        case ActionTypeKeys.CONFIRM_CREDIT_RETURN_FURNITURE_RECEPTION_REQUEST:
        case ActionTypeKeys.ADD_CREDIT_RETURN_CREDIT_REQUEST:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        isExecutingRepairStep: true
                    }
                }
            };

        case ActionTypeKeys.CREATE_CREDIT_RETURN_SUCCESS:
        case ActionTypeKeys.CONFIRM_CREDIT_RETURN_FURNITURE_RECEPTION_SUCCESS:
        case ActionTypeKeys.ADD_CREDIT_RETURN_CREDIT_SUCCESS:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        details: {
                            ...state.serviceCalls[action.payload.serviceCallId].details,
                            creditReturns: state.serviceCalls[action.payload.serviceCallId].details.creditReturns
                                .filter(x => x.id !== action.payload.creditReturn.id)
                                .concat(action.payload.creditReturn)
                        },
                        isExecutingRepairStep: false
                    }
                }
            };

        case ActionTypeKeys.CREATE_FACTORY_REPAIRS_REQUEST:
        case ActionTypeKeys.SCHEDULE_FACTORY_REPAIRS_REQUEST:
        case ActionTypeKeys.CONFIRM_FURNITURE_RECEPTION_REQUEST:
        case ActionTypeKeys.CONFIRM_FACTORY_REPAIRS_COMPLETION_REQUEST:
        case ActionTypeKeys.CREATE_TECHNICIAN_REPAIRS_REQUEST:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        isExecutingRepairStep: true
                    }
                }
            };

        case ActionTypeKeys.CREATE_FACTORY_REPAIRS_SUCCESS:
        case ActionTypeKeys.SCHEDULE_FACTORY_REPAIRS_SUCCESS:
        case ActionTypeKeys.CONFIRM_FURNITURE_RECEPTION_SUCCESS:
        case ActionTypeKeys.CONFIRM_FACTORY_REPAIRS_COMPLETION_SUCCESS:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        details: {
                            ...state.serviceCalls[action.payload.serviceCallId].details,
                            factoryRepairs: state.serviceCalls[action.payload.serviceCallId].details.factoryRepairs
                                .filter(x => x.id !== action.payload.factoryRepair.id)
                                .concat(action.payload.factoryRepair)
                        },
                        isExecutingRepairStep: false
                    }
                }
            };

        case ActionTypeKeys.CREATE_FACTORY_REPAIRS_FAILURE:
        case ActionTypeKeys.SCHEDULE_FACTORY_REPAIRS_FAILURE:
        case ActionTypeKeys.CONFIRM_FURNITURE_RECEPTION_FAILURE:
        case ActionTypeKeys.CONFIRM_FACTORY_REPAIRS_COMPLETION_FAILURE:
        case ActionTypeKeys.CREATE_TECHNICIAN_REPAIRS_FAILURE:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        isExecutingRepairStep: false
                    }
                }
            };

        case ActionTypeKeys.CREATE_TECHNICIAN_REPAIRS_SUCCESS:
        case ActionTypeKeys.CONFIRM_TECHNICIAN_REPAIRS_COMPLETION_SUCCESS:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        details: {
                            ...state.serviceCalls[action.payload.serviceCallId].details,
                            technicianRepairs: state.serviceCalls[action.payload.serviceCallId].details.technicianRepairs
                                .filter(x => x.id !== action.payload.technicianRepair.id)
                                .concat(action.payload.technicianRepair)
                        },
                        isExecutingRepairStep: false
                    }
                }
            };

        case ActionTypeKeys.CREATE_TECHNICIAN_APPOINTMENT_REQUEST:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload]: {
                        ...state.serviceCalls[action.payload],
                        isSavingTechnicianRepairs: true
                    }
                }
            };

        case ActionTypeKeys.CREATE_TECHNICIAN_APPOINTMENT_SUCCESS:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        details: {
                            ...state.serviceCalls[action.payload.serviceCallId].details,
                            technicianRepairs: action.payload.technicianRepairs
                        },
                        isSavingTechnicianRepairs: false
                    }
                }
            };

        case ActionTypeKeys.CREATE_TECHNICIAN_APPOINTMENT_FAILURE:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.serviceCallId]: {
                        ...state.serviceCalls[action.payload.serviceCallId],
                        isSavingTechnicianRepairs: true
                    }
                }
            };

        case ActionTypeKeys.LOAD_ACTIVITIES_REQUEST:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.id]: {
                        ...state.serviceCalls[action.payload.id],
                        activities: [],
                        areActivitiesLoading: true
                    }
                }
            };

        case ActionTypeKeys.LOAD_ACTIVITIES_SUCCESS: {
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.id]: {
                        ...state.serviceCalls[action.payload.id],
                        activities: action.payload.activities,
                        areActivitiesLoading: false
                    }
                }
            };
        }

        case ActionTypeKeys.LOAD_ACTIVITIES_FAILURE:
            return {
                ...state,
                serviceCalls: {
                    ...state.serviceCalls,
                    [action.payload.id]: {
                        ...state.serviceCalls[action.payload.id],
                        areActivitiesLoading: false
                    }
                }
            };

        default:
            return state;
    }
};

export default serviceCallReducer;