import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Popup, Button, Divider, Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';

import { DisplayOptions, DisplayType } from '../../state/models';
import { commonMessages } from '../../constants';

interface DisplayOptionsPopupOwnProps {
    options: DisplayOptions;
    onApply: (options: DisplayOptions) => void;
}

interface DisplayOptionsPopupState {
    appliedOptions: DisplayOptions;
    pendingOptions: DisplayOptions;
    isOpen: boolean;
}

export type DisplayOptionsPopupProps =
    & DisplayOptionsPopupOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    displayButton: { id: 'DisplayOptions.display_button', defaultMessage: 'Display' },
    groupByYear: { id: 'DisplayOptions.group_by_year', defaultMessage: 'By year' },
    groupByMonth: { id: 'DisplayOptions.group_by_month', defaultMessage: 'By month' },
    groupingSection: { id: 'DisplayOptions.grouping_section', defaultMessage: 'Data grouping' }
});

class DisplayOptionsPopup extends React.Component<DisplayOptionsPopupProps, DisplayOptionsPopupState> {
    public constructor(props: DisplayOptionsPopupProps) {
        super(props);

        this.state = { 
            appliedOptions: props.options,
            pendingOptions: props.options,
            isOpen: false
        };
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const dropdownOptions: DropdownItemProps[] = [
            { text: formatMessage(m.groupByMonth), value: DisplayType.perMonth, selected: this.state.pendingOptions.displayType === DisplayType.perMonth },
            { text: formatMessage(m.groupByYear), value: DisplayType.perYear, selected: this.state.pendingOptions.displayType === DisplayType.perYear },
        ];

        return (
            <Popup 
                trigger={this.renderDefaultTrigger()}
                flowing={true}
                position="bottom right"
                on="click"
                open={this.state.isOpen}
                onOpen={this.openPopup}
                onClose={this.closePopup}
                style={{ width: '340px' }}
                closeOnDocumentClick={false}
            >
                <Popup.Content>
                    <div>
                        <strong>{formatMessage(m.groupingSection)}</strong>
                        <a style={{float: 'right', cursor: 'pointer'}} onClick={this.resetOptions}>
                            {formatMessage(commonMessages.clear)}
                        </a>
                    </div>
                    <Divider />
                    <Dropdown
                        fluid={true}
                        options={dropdownOptions}
                        selection={true}
                        multiple={false}
                        closeOnChange={true}
                        onChange={this.changeDisplayType}
                        value={this.state.pendingOptions.displayType}
                    />

                    <Divider />
                    <Button 
                        primary={true}
                        fluid={true} 
                        content={formatMessage(commonMessages.apply)}
                        onClick={this.applyOptions}
                    />
                </Popup.Content>
            </Popup>
        );
    }

    private changeDisplayType = (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        this.setState((current) => ({
            pendingOptions: {
                ...current.pendingOptions,
                displayType: data.value as DisplayType
            }
        }));
    }

    private renderDefaultTrigger() {
        const { formatMessage } = this.props.intl;

        return (
            <Button 
                content={formatMessage(m.displayButton)}
                icon="table"
            />
        );
    }
    private resetOptions = () => {
        this.setState((current) => ({ pendingOptions: current.appliedOptions }));
    }

    private applyOptions = () => {
        this.setState((current) => ({ appliedOptions: current.pendingOptions }));
        this.props.onApply(this.state.pendingOptions);
        this.closePopup();
    }

    private openPopup = () => {
        this.setState({ isOpen: true });
    }

    private closePopup = () => {
        this.setState({ isOpen: false });
    }
}

const connectedComponent = injectIntl(DisplayOptionsPopup);
export { connectedComponent as DisplayOptionsPopup };