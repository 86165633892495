import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Card, Checkbox, Grid, Button, List } from 'semantic-ui-react';
import { EmptyContentSegment } from '../../common';
import { Client, Customer } from '../../../state/models';

const m = defineMessages({
    title: { id: 'RequestApprovalCard.title', defaultMessage: 'Client or customer\'s approval' },
    subtitle: { id: 'RequestApprovalCard.subtitle', defaultMessage: 'Request the client\'s or customer\'s approval for the repair fees.' },
    approvalLabel: { id: 'RequestApprovalCard.approval_label', defaultMessage: 'The client or customer has approved the repair fees of {total}' },
    requestByPhoneHeader: { id: 'RequestApprovalCard.request_by_phone_header', defaultMessage: 'Approve the fees by phone' },
    requestByEmailHeader: { id: 'RequestApprovalCard.request_by_email_header', defaultMessage: 'Approve the fees by email' },
    notifyClientButton: { id: 'RequestApprovalCard.notify_client_button', defaultMessage: 'Notify Client' },
    notifyCustomerButton: { id: 'RequestApprovalCard.notify_customer_button', defaultMessage: 'Notify Customer' },
    approveFeesButton: { id: 'RequestApprovalCard.approve_fees_button', defaultMessage: 'Approve Repair Fees' },
    feesApprovedButton: { id: 'RequestApprovalCard.fees_approved_button', defaultMessage: 'Fees Approved' },
    noRepairFeesTitle: { id: 'RequestApprovalCard.no_repair_fees_title', defaultMessage: 'No approval required' },
    noRepairFeesDescription: { id: 'RequestApprovalCard.no_repair_fees_description', defaultMessage: 'There are no repair fees for this service call.'}
});

interface OwnProps {
    repairFees: number;
    currencyCode: string;
    client?: Client;
    customer?: Customer;
    areFeesApproved?: boolean;
    onApproveFees: (isApproved: boolean) => void;
}

export type RequestApprovalCardProps =
    & OwnProps;

export const RequestApprovalCard: React.FC<RequestApprovalCardProps> = (props) => {
    const { formatMessage, formatNumber } = useIntl();
    const hasRepairFees = props.repairFees > 0;

    const renderPhoneApprovalButtons = () => {
        return (
            <div>
                {!props.areFeesApproved && 
                    <Button 
                        primary
                        floated="right" 
                        content={formatMessage(m.approveFeesButton)}
                        onClick={() => props.onApproveFees(true)}
                        style={{marginTop: 20}} 
                    />
                }

                {props.areFeesApproved &&
                    <Button 
                        positive
                        disabled
                        floated="right" 
                        icon="check"
                        content={formatMessage(m.feesApprovedButton)}
                        style={{marginTop: 20}} 
                    />
                }
            </div>
        );
    }

    const renderPhoneApproval = () => {
        const intlRepairFees = formatNumber(props.repairFees, { style: 'currency', currency: props.currencyCode });

        return (
            <div>
                <h5>{formatMessage(m.requestByPhoneHeader)}</h5>
                <Grid columns="equal">
                    <Grid.Column>
                        {props.client &&
                            <List>
                                <List.Item icon="building" content={props.client.name} />
                                <List.Item icon="phone" content={props.client.phoneNumber} />
                                {props.client.faxNumber && 
                                    <List.Item icon="fax" content={props.client.faxNumber} />
                                }
                            </List>
                        }
                    </Grid.Column>
                    <Grid.Column>
                        {props.customer &&
                            <List>
                                <List.Item icon="user" content={props.customer.name} />
                                <List.Item icon="phone" content={props.customer.mainPhoneNumber} />
                                {props.customer.otherPhoneNumber && 
                                    <List.Item icon="phone" content={props.customer.otherPhoneNumber} />
                                }
                            </List>
                        }
                    </Grid.Column>
                </Grid>
                <div>
                    <Checkbox 
                        label={formatMessage(m.approvalLabel, { total: intlRepairFees })} 
                        style={{marginTop: 30}}
                        checked={props.areFeesApproved}
                        onChange={(event, data) => props.onApproveFees(data.checked || false)}
                    />
                </div>
                
                {renderPhoneApprovalButtons()}
            </div>
        );
    }

    return (
        <Card color="yellow" fluid={true} style={{height: '100%'}}>
            <Card.Content style={{flexGrow: 0}}>
                <Card.Header content={formatMessage(m.title)} />
                <Card.Meta content={formatMessage(m.subtitle)} />
            </Card.Content>
            <Card.Content>
                {hasRepairFees &&
                    <Grid columns="equal" stackable>
                        <Grid.Column>
                            {renderPhoneApproval()}
                        </Grid.Column>
                        <Grid.Column />
                    </Grid>
                }
                {!hasRepairFees &&
                    <EmptyContentSegment 
                        basic={true}
                        title={formatMessage(m.noRepairFeesTitle)} 
                        description={formatMessage(m.noRepairFeesDescription)} 
                    />
                }
            </Card.Content>
        </Card>
    );
};