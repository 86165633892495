import React from 'react';
import moment from 'moment';

import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Modal, List } from 'semantic-ui-react';
import { TechnicianRepair, TechnicianRepairsStatus } from '../../../state/models';
import { UserHelper, DateHelper } from '../../../helpers';
import { commonMessages } from '../../../constants';

interface TechnicianReportModalOwnProps {
    trigger: React.ReactNode;
    technicianRepair: TechnicianRepair;
}

export type TechnicianReportModalProps =
    & TechnicianReportModalOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'TechnicianReportModal.title', defaultMessage: 'Technician report' },
    completedOn: { id: 'TechnicianReportModal.completed_on', defaultMessage: 'Completed on' },
    repairedBy: { id: 'TechnicianReportModal.inspected_by', defaultMessage: 'Repaired by' },
    labourTime: { id: 'TechnicianReportModal.labour_time', defaultMessage: 'Labour time' },
    report: { id: 'TechnicianReportModal.report', defaultMessage: 'Report details' },
    clientSignature: { id: 'TechnicianReportModal.client_signature', defaultMessage: 'Client signature' },
    repairStatus: { id: 'TechnicianReportModal.repair_status', defaultMessage: 'Repair status' },
    complete: { id: 'TechnicianReportModal.complete', defaultMessage: 'Complete' },
    incomplete: { id: 'TechnicianReportModal.incomplete', defaultMessage: 'Incomplete' },
    partsRequired: { id: 'TechnicianReportModal.parts_required', defaultMessage: 'Parts required' },
});

class TechnicianReportModal extends React.Component<TechnicianReportModalProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const technicianReport = this.props.technicianRepair != null && this.props.technicianRepair.technicianReport != null
            ? this.props.technicianRepair.technicianReport
            : undefined;

        return (
            <Modal trigger={this.props.trigger} closeIcon={true} size="fullscreen">
                <Modal.Header>{formatMessage(m.title)}</Modal.Header>
                <Modal.Content scrolling={true}>
                    <List relaxed={true}>
                        <List.Item>
                            <List.Icon name="user" size="large" verticalAlign="top" />
                            <List.Content>
                                <List.Header content={formatMessage(m.repairedBy)} />
                                <List.Description>{UserHelper.getDisplayName(this.props.technicianRepair.technician)}</List.Description>
                            </List.Content>
                        </List.Item>

                        <List.Item>
                            <List.Icon name="calendar check" size="large" verticalAlign="top" />
                            <List.Content>
                                <List.Header content={formatMessage(m.completedOn)} />
                                <List.Description>{moment.utc(this.props.technicianRepair.completedOn).format('LL')}</List.Description>
                            </List.Content>
                        </List.Item>

                        {technicianReport &&
                            <React.Fragment>
                                <List.Item>
                                    <List.Icon name="clock" size="large" verticalAlign="top" />
                                    <List.Content>
                                        <List.Header content={formatMessage(m.labourTime)} />
                                        <List.Description>
                                            {DateHelper.getTimeDescription(technicianReport.hoursTaken, technicianReport.minutesTaken)}
                                        </List.Description>
                                    </List.Content>
                                </List.Item>

                                <List.Item>
                                    <List.Icon name="check square outline" size="large" verticalAlign="top" />
                                    <List.Content>
                                        <List.Header content={formatMessage(m.repairStatus)} />
                                        <List.Description>
                                            {this.getRepairStatusDescription(technicianReport.repairsStatus)}
                                        </List.Description>
                                    </List.Content>
                                </List.Item>

                                <List.Item>
                                    <List.Icon name="pencil" size="large" verticalAlign="top" />
                                    <List.Content>
                                        <List.Header content={formatMessage(m.report)} />
                                        <List.Description>
                                            <p style={{ whiteSpace: 'pre-line' }}>
                                                {technicianReport.report}
                                            </p>
                                        </List.Description>
                                    </List.Content>
                                </List.Item>

                                <List.Item>
                                    <List.Icon name="signup" size="large" verticalAlign="top" />
                                    <List.Content>
                                        <List.Header content={formatMessage(m.clientSignature)} />
                                        <List.Description>
                                            <img src={technicianReport.clientSignature} style={{ width: 275 }} />
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                            </React.Fragment>
                        }
                    </List>
                </Modal.Content>
            </Modal>
        );
    }

    private getRepairStatusDescription(repairStatus: TechnicianRepairsStatus): string {
        const { formatMessage } = this.props.intl;

        switch (repairStatus) {
            case TechnicianRepairsStatus.complete:
                return formatMessage(m.complete);

            case TechnicianRepairsStatus.incomplete:
                return formatMessage(m.incomplete);

            case TechnicianRepairsStatus.partsRequired:
                return formatMessage(m.partsRequired);

            default:
                return formatMessage(commonMessages.unknown);
        }
    }
}

const connectedComponent = injectIntl(TechnicianReportModal);
export { connectedComponent as TechnicianReportModal };