import _ from 'lodash';
import { Reducer } from 'redux';
import { ApiError } from 'utils/api';

import { User, UserAccountType } from '../../models';
import { ActionTypes } from './actions';
import { ActionTypeKeys, ApiRegistration, ApiWebhook } from './types';

export interface CurrentUserState {
    isLoading: boolean;
    isSaving: boolean;
    userProfile: User;
    apiRegistration: ApiRegistration | undefined;
    apiWebhooks: {
        byId: { [id: number]: ApiWebhook },
        allIds: number[],
        isLoading: boolean
    };
    isCreatingApiRegistration: boolean;
    isRevokingApiRegistration: boolean;
}

const initialState: CurrentUserState = {
    isLoading: false,
    isSaving: false,
    userProfile: {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        accountType: UserAccountType.none,
        isDisabled: false,
        claims: {
            clientCodes: [],
            givenName: '',
            surname: '',
            role: '',
        }
    },
    apiRegistration: undefined,
    apiWebhooks: {
        byId: {},
        allIds: [],
        isLoading: false
    },
    isCreatingApiRegistration: false,
    isRevokingApiRegistration: false,
};

export const currentUserReducer: Reducer<CurrentUserState> = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case ActionTypeKeys.LOAD_PROFILE_REQUEST:
            return { ...state, isLoading: true };

        case ActionTypeKeys.LOAD_PROFILE_SUCCESS:
            return {
                ...state,
                userProfile: action.payload,
                isLoading: false,
            };

        case ActionTypeKeys.LOAD_PROFILE_FAILURE:
            return { ...state, isLoading: false };

        case ActionTypeKeys.UPDATE_PROFILE_REQUEST:
            return { ...state, isSaving: true };

        case ActionTypeKeys.UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                userProfile: action.payload,
                isSaving: false
            };

        case ActionTypeKeys.UPDATE_PROFILE_FAILURE:
            return { ...state, isSaving: false };

        case ActionTypeKeys.LOAD_API_REGISTRATION_SUCCESS: {
            return {
                ...state,
                apiRegistration: action.payload
            };
        }

        case ActionTypeKeys.REGISTER_API_REQUEST: {
            return {
                ...state,
                isCreatingApiRegistration: true
            };
        }

        case ActionTypeKeys.REGISTER_API_SUCCESS: {
            return {
                ...state,
                apiRegistration: action.payload,
                isCreatingApiRegistration: false,
            };
        }

        case ActionTypeKeys.REGISTER_API_FAILURE: {
            return {
                ...state,
                isCreatingApiRegistration: false
            };
        }

        case ActionTypeKeys.REVOKE_API_REQUEST: {
            return {
                ...state,
                isRevokingApiRegistration: true
            };
        }

        case ActionTypeKeys.REVOKE_API_SUCCESS: {
            return {
                ...state,
                apiRegistration: undefined,
                isRevokingApiRegistration: false
            };
        }

        case ActionTypeKeys.REVOKE_API_FAILURE: {
            return {
                ...state,
                isRevokingApiRegistration: false
            };
        }

        case ActionTypeKeys.LOAD_API_WEBHOOKS_REQUEST: {
            return {
                ...state,
                apiWebhooks: {
                    ...state.apiWebhooks,
                    isLoading: true
                }
            };
        }

        case ActionTypeKeys.LOAD_API_WEBHOOKS_SUCCESS: {
            const apiWebhooks = Object.assign({}, ...action.payload.map(x => ({ [x.id]: x })));
            const apiWebhookIds = action.payload.map(x => x.id);

            return {
                ...state,
                apiWebhooks: {
                    ...state.apiWebhooks,
                    byId: apiWebhooks,
                    allIds: apiWebhookIds,
                    isLoading: false
                }
            };
        }

        case ActionTypeKeys.LOAD_API_WEBHOOKS_FAILURE: {
            return {
                ...state,
                apiWebhooks: {
                    ...state.apiWebhooks,
                    isLoading: false
                }
            };
        }

        case ActionTypeKeys.UPDATE_API_WEBHOOK_SUCCESS: {
            const apiWebhooks = _.merge({}, state.apiWebhooks.byId, { [action.payload.id]: action.payload });
            const apiWebhookIds = _.union(state.apiWebhooks.allIds, [action.payload.id]);

            return {
                ...state,
                apiWebhooks: {
                    ...state.apiWebhooks,
                    byId: apiWebhooks,
                    allIds: apiWebhookIds
                }
            };
        }

        default:
            return state;
    }
};

export default currentUserReducer;