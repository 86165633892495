import * as React from 'react';

export class FormatHelper {
    public static formatPhoneNumber(phoneNumber: string | undefined): string | undefined {
        const actualNumber = phoneNumber ? phoneNumber.trim() : phoneNumber;

        if (actualNumber) {
            if (actualNumber.length === 10) {
                return FormatHelper.formatTenDigitPhoneNumber(actualNumber);
            }

            if (actualNumber.length === 11) {
                const internationalCode = actualNumber[0];

                if (internationalCode === '1') {
                    return FormatHelper.formatTenDigitPhoneNumber(actualNumber.slice(1, 11));
                }

                return actualNumber[0] + ' ' + FormatHelper.formatTenDigitPhoneNumber(actualNumber.slice(1, 11));
            }
        }

        return actualNumber;
    }

    public static formatAddress(address: string) {
        const formattedAddress = address
            ? address.split('\r\n').map(value => <span key={value} style={{display: 'block'}}>{value}</span>)
            : <div />;

        return formattedAddress;
    }

    public static trim(value: string | undefined): string | undefined {
        if (value && value.trim) {
            return value.trim();
        }

        return value;
    }

    private static formatTenDigitPhoneNumber(phoneNumber: string) {
        return phoneNumber.length === 10
            ? '(' + phoneNumber.slice(0, 3) + ') ' + phoneNumber.slice(3, 6) + '-' + phoneNumber.slice(6, 10)
            : phoneNumber;
    }
}