import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { defineMessages, useIntl } from "react-intl";
import { Form, Header } from "semantic-ui-react";
import { UserProfileEdit } from "../../../models";

interface OwnProps {
}

export type ProfilePropertiesSectionProps =
    & OwnProps;

const m = defineMessages({
    sectionTitle: { id: 'ProfilePropertiesSection.sectionTitle', defaultMessage: 'Profile properties' },
    firstNameLabel: { id: 'ProfilePropertiesSection.firstNameLabel', defaultMessage: 'First name' },
    firstNameRequired: { id: 'ProfilePropertiesSection.firstNameRequired', defaultMessage: 'Please specify a first name.' },
    lastNameLabel: { id: 'ProfilePropertiesSection.lastNameLabel', defaultMessage: 'Last name' },
});

export const ProfilePropertiesSection: React.FC<ProfilePropertiesSectionProps> = (props) => {
    const { formatMessage } = useIntl();
    const { control, formState: { errors }, setValue } = useFormContext<UserProfileEdit>();

    return (
        <>
            <Header content={formatMessage(m.sectionTitle)} />
            <Form.Group widths="equal">
                <Controller
                    name="firstName"
                    control={control}
                    rules={{ required: formatMessage(m.firstNameRequired) }}
                    render={({ field }) => (
                        <Form.Input
                            required
                            error={errors.firstName != null}
                            label={formatMessage(m.firstNameLabel)}
                            value={field.value || ''}
                            onChange={(e, data) => setValue('firstName', data.value)}
                        />
                    )}
                />

                <Controller
                    name="lastName"
                    control={control}
                    render={({ field }) => (
                        <Form.Input
                            label={formatMessage(m.lastNameLabel)}
                            value={field.value || ''}
                            onChange={(e, data) => setValue('lastName', data.value)}
                        />
                    )}
                />
            </Form.Group>
        </>
    );
};