import * as React from 'react';
import { Message, MessageProps } from 'semantic-ui-react';

export interface InformationMessageProps extends MessageProps {
}

class InformationMessage extends React.Component<InformationMessageProps, {}> {
    public render() {
        const {...messageProps} = this.props;
        return (
            <Message info={true} icon="info circle" {...messageProps} />
        );
    }
}

export { InformationMessage };