export class ArrayHelper {
    public static groupBy<TKey, TItem>(list: TItem[], keyGetter: (item: TItem) => TKey) {
        const map = new Map<TKey, TItem[]>();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);

            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }
}