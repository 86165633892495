import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header, Loader } from 'semantic-ui-react';

import { CenteredPageLayout } from '../layouts/CenteredPageLayout';

import { defineMessages, useIntl } from "react-intl";
import { actionCreators as AccountActions } from '../../modules/account/actions';
import { isSessionLoading } from "modules/account/selectors";
import { CompanyLogo } from "components/common";

interface OwnProps {
}

export type SplashScreenProps =
    & OwnProps;

const m = defineMessages({
    title: { id: 'SplashScreen.title', defaultMessage: 'Customer Service Platform' },
    loading: { id: 'SplashScreen.loading', defaultMessage: 'Loading the application...' }
});

export const SplashScreen: React.FC<SplashScreenProps> = (props) => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const isLoading = useSelector(isSessionLoading);

    useEffect(() => {
        dispatch(AccountActions.loadUserSession())
    }, [dispatch]);

    if (isLoading) {
        return (
            <CenteredPageLayout>
                <CompanyLogo />
                <Header as="h2" color="teal">{formatMessage(m.title)}</Header>
    
                <Loader active inline size="big" content={formatMessage(m.loading)} style={{ marginTop: '30px' }} />
            </CenteredPageLayout>
        );
    }

    return <>{props.children}</>;
};