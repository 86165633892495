import moment from 'moment';

const multiPartConfiguration = (): RequestInit => ({
    credentials: 'same-origin',
    headers: {
        // For multipart configurations, the content type needs to remain blank, as it will be auto generated by the browser.
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
        'Accept-Language': moment.locale()
    }
});

const baseConfiguration = (): RequestInit => ({
    credentials: 'same-origin',
    headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
        'Accept-Language': moment.locale()
    }
});

const isDevelopment = process.env.NODE_ENV == null || process.env.NODE_ENV === 'development';

export class ApiError extends Error {
    public readonly statusCode: number;
    constructor(statusCode: number, message?: string | undefined) {
        super(message);
        this.statusCode = statusCode;
    }
}

export async function getVoid(requestUrl: string): Promise<void> {
    const result = await fetchRequest<void>(
        requestUrl,
        {
            ...baseConfiguration(),
            method: 'GET'
        },
        true
    );

    return result;
}

export function getPDF(requestUrl: string): Promise<Blob> {
    return fetchBlob(
        requestUrl,
        {
            method: 'GET',
        },
        'application/pdf'
    );
}

export function getFile(requestUrl: string, contentType: string): Promise<Blob> {
    return fetchBlob(
        requestUrl,
        {
            method: 'GET',
        },
        contentType
    );
}

export async function get<TResponse>(requestUrl: string): Promise<TResponse> {
    const result = await fetchRequest<TResponse>(
        requestUrl,
        {
            ...baseConfiguration(),
            method: 'GET'
        }
    );

    return result;
}

export async function postVoid(requestUrl: string, body: any | undefined): Promise<void> {
    const result = await fetchRequest<void>(
        requestUrl,
        {
            ...baseConfiguration(),
            method: 'POST',
            body: body != null ? JSON.stringify(body) : undefined
        },
        true);

    return result;
}

export async function post<TResponse>(requestUrl: string, body: any | undefined): Promise<TResponse> {
    const result = await fetchRequest<TResponse>(
        requestUrl,
        {
            ...baseConfiguration(),
            method: 'POST',
            body: body != null ? JSON.stringify(body) : undefined
        });

    return result;
}

// tslint:disable-next-line: no-any
export async function postMultiPart<TResponse>(requestUrl: string, body: any, skipDeserialization?: boolean): Promise<TResponse> {

    const bodyFormData = Object.keys(body).reduce((formData, key) => {
        const value = body[key];
        const isFileArray = value instanceof Array && value.every(x => x instanceof File);
        const isObject = value instanceof Object;

        if (isFileArray)
        {
            // We can't pass directly file arrays to the form data. Somehow, we have to append them
            // individually to the same key.
            value.forEach((x:File) => formData.append(key, x));
        } else if (isObject) {
            Object.keys(value).forEach(innerKey => {
                const innerValue = value[innerKey];
                if (innerValue != null) {
                    formData.append(`${key}.${innerKey}`, innerValue);
                }
            });
        } else {
            // We need to make some slight adjustments to propagate the value to the backend.
            // 1. Dates should be sent as ISO strings
            // 2. Null values should be sent as empty strings
            const formattedValue = value instanceof Date ? value.toISOString() : value || '';
            formData.append(key, formattedValue);
        }

        return formData;
    }, new FormData());

    const result = await fetchRequest<TResponse>(
        requestUrl,
        {
            ...multiPartConfiguration(),
            method: 'POST',
            body: bodyFormData
        }, skipDeserialization ?? false);

    return result;
}

export async function httpDeleteVoid(requestUrl: string): Promise<void> {
    const result = await fetchRequest<void>(
        requestUrl,
        {
            ...baseConfiguration(),
            method: 'DELETE'
        },
        true
    );

    return result;
}

export async function httpDelete<TResponse>(requestUrl: string): Promise<TResponse> {
    const result = await fetchRequest<TResponse>(
        requestUrl,
        {
            ...baseConfiguration(),
            method: 'DELETE'
        }
    );

    return result;
}

// tslint:disable-next-line: no-any
export async function put<TResponse>(requestUrl: string, body: any | undefined): Promise<TResponse> {
    const result = await fetchRequest<TResponse>(
        requestUrl,
        {
            ...baseConfiguration(),
            method: 'PUT',
            body: body != null ? JSON.stringify(body) : undefined
        });

    return result;
}

const setAuthorizationHeader = (
    requestConfiguration: RequestInit,
    authHeaderValue: string | null
) => {
    if (authHeaderValue) {
        const authHeaderName = 'Authorization';

        if (!requestConfiguration) {
            requestConfiguration = {};
        }

        if (!requestConfiguration.headers) {
            requestConfiguration.headers = new Headers();
        }

        if (requestConfiguration.headers instanceof Headers) {
            requestConfiguration.headers.append(
                authHeaderName,
                authHeaderValue
            );
        } else if (requestConfiguration.headers instanceof Array) {
            requestConfiguration.headers.push([
                authHeaderName,
                authHeaderValue,
            ]);
        } else {
            requestConfiguration.headers[authHeaderName] = authHeaderValue;
        }
    }
};

async function fetchRequest<TResponse>(requestUrl: string, requestConfiguration: RequestInit, skipDeserialization?: boolean): Promise<TResponse> {
    setAuthorizationHeader(requestConfiguration, sessionStorage.getItem("auth-header"));

    const response = await fetch(requestUrl, requestConfiguration);

    if (response.status >= 200 && response.status < 300) {
        // Skipping deserialization is required for API calls that return void.
        return skipDeserialization
            ? Promise.resolve({} as TResponse)
            : response.json() as Promise<TResponse>;
    }

    let errorMessage = `${response.status} ${response.statusText}`;
    try {
        const error = await response.json();
        errorMessage = error && error.UserMessage ? (isDevelopment ? error.UserMessage + ' DEBUG: ' + error.DebugMessage : error.UserMessage) : errorMessage;
    } catch {
        // response did not contain a json serialized error
    }

    return Promise.reject(new ApiError(response.status, errorMessage));
}

async function fetchBlob(requestUrl: string, requestConfiguration: RequestInit, contentType: string): Promise<Blob> {
    setAuthorizationHeader(requestConfiguration, sessionStorage.getItem("auth-header"));

    const response = await fetch(requestUrl, requestConfiguration);

    if (response.status >= 200 && response.status < 300) {
        var blob = await response.blob();
        return new Blob([blob], { type: contentType });
    }

    let errorMessage = `${response.status} ${response.statusText}`;
    return Promise.reject(new ApiError(response.status, errorMessage));
}