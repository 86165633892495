import React, { useEffect, useState } from "react";
import sumBy from "lodash/sumBy";
import { defineMessages, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { GroupedDefectiveItemCount } from "containers/reporting/types";
import { DefectiveItemQueryFilters, ServiceCallAnalysisService } from "services/ServiceCallAnalysisService";
import * as globalNotification from "../../../../state/ducks/global-notification";
import { DefectiveItemsTable, DefectiveItemsTableRow } from "../DefectiveItemsTable";
const serviceCallAnalysisService = new ServiceCallAnalysisService();

const m = defineMessages({
  groupType: { id: "ReportedDefectiveItemsByItem.GroupType", defaultMessage: "Item" },
});

interface ReportedDefectiveItemsByItemProps {
  filters: DefectiveItemQueryFilters;
}

export const ReportedDefectiveItemsByItem: React.FC<ReportedDefectiveItemsByItemProps> = ({ filters }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  
  const [isLoading, setIsLoading] = useState(false);

  const [groupedDefectiveItemCount, setGroupedDefectiveItemCount] =
    useState<GroupedDefectiveItemCount[]>([]);

    useEffect(() => {
      setIsLoading(true);
  
      serviceCallAnalysisService
        .getDefectiveItemCountByItem(filters)
        .then((response) => response.json() as GroupedDefectiveItemCount[])
        .then((fetchedDefectiveItemCount) => {
          setGroupedDefectiveItemCount(fetchedDefectiveItemCount);
        })
        .catch((error) => {
          dispatch(
            globalNotification.actionCreators.showErrorNotification(
              "Erreur",
              `Une erreur est survenue en tentant de récupérer le nombre d'item défectueux par item (${error}).`
            )
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, [dispatch, filters]);
  
  const itemTotal = sumBy(groupedDefectiveItemCount, x => x.itemCount);

  const rows: DefectiveItemsTableRow[] = groupedDefectiveItemCount.map(groupItemCount => ({
    ...groupItemCount,
    percent: itemTotal > 0 ? (groupItemCount.itemCount / itemTotal) * 100 : 0,
  }))
   
  return (
    <>
    <DefectiveItemsTable
      rows={rows}
      groupType={formatMessage(m.groupType)}
      itemTotal={itemTotal}
      isLoading={isLoading}
    />
    </>
  )
};
