import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedMessage } from 'react-intl';
import { Feed, SemanticICONS, Popup, Icon, List } from 'semantic-ui-react';

import { ShipmentSentUpdate } from '../../../state/models';
import { StringHelper } from '../../../state/utils';

export interface ShipmentSentActivityProps {
    activity: ShipmentSentUpdate;
}

const m = defineMessages({
    summaryFormat: { id: 'ShipmentSentActivity.summary_format', defaultMessage: 'A {carrier} shipment was sent from Jaymar\'s facilities.' },
    trackingNumberFormat: { id: 'ShipmentSentActivity.tracking_number_format', defaultMessage: 'Tracking #{tracking}' }
});

class ShipmentSentActivity extends React.Component<ShipmentSentActivityProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { activity } = this.props;
        const carrierName = activity.shippingCarrier ? activity.shippingCarrier.name : 'UNKNOWN';
        const trackingNumbers = activity.trackingNumbers
            ? activity.trackingNumbers.map(x => {
                const trackingLink = activity.shippingCarrier && StringHelper.hasValue(activity.shippingCarrier.trackingUrl)
                    ? <a target="_blank" href={activity.shippingCarrier.trackingUrl + x}>{x}<Icon name="external" /></a>
                    : x;
                const trackingDescription = <FormattedMessage {...m.trackingNumberFormat} values={{ 'tracking': trackingLink }} />;

                return StringHelper.hasValue(x)
                    ? <List.Item key={x} content={trackingDescription} />
                    : (null);
            })
            : (null);

        return (
            <Feed.Event key={activity.id}>
                <Feed.Label icon="truck" />
                <Feed.Content>
                    <Popup
                        trigger={<Feed.Date content={moment.utc(activity.shippedOn).fromNow()} />}
                        content={moment.utc(activity.shippedOn).format('LL')}
                    />

                    <Feed.Summary>
                        <FormattedMessage {...m.summaryFormat} values={{ 'carrier': carrierName }} />
                    </Feed.Summary>

                    <Feed.Extra text={true}>
                        <List bulleted={true}>
                            {trackingNumbers}
                        </List>

                        {StringHelper.hasValue(activity.note) &&
                            <p style={{ whiteSpace: 'pre-line' }}>{activity.note}</p>
                        }
                    </Feed.Extra>
                </Feed.Content>
            </Feed.Event>
        );
    }
}

const connectedComponent = injectIntl(ShipmentSentActivity);
export { connectedComponent as ShipmentSentActivity };