import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Card, CardProps } from 'semantic-ui-react';
import { LoadingDimmer } from './LoadingDimmer';

interface CommonCardOwnProps extends CardProps {
    title: string;
    subtitle: string;
    isLoading?: boolean;
}

export type CommonCardProps =
    & CommonCardOwnProps
    & WrappedComponentProps;

class CommonCard extends React.Component<CommonCardProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { title, subtitle, isLoading, children, intl, ...cardProps } = this.props;

        return (
            <Card fluid={true} {...cardProps} style={{height: '100%'}}>
                <Card.Content style={{flexGrow: 0}}>
                    <Card.Header content={this.props.title} />
                    <Card.Meta content={this.props.subtitle} />
                </Card.Content>
                <Card.Content>
                    <LoadingDimmer active={this.props.isLoading} />
                    {this.props.children}
                </Card.Content>
            </Card>
        );
    }
}

const connectedComponent = injectIntl(CommonCard);
export { connectedComponent as CommonCard };