import React from "react";
import { UserHelper } from "helpers";
import { defineMessages, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Dropdown, DropdownProps, Icon } from 'semantic-ui-react';
import { useResponsive } from "../../../../utils/responsive";
import { getUserSession, isSessionLoading } from "../../../../modules/account/selectors";

const m = defineMessages({
    myAccount: { id: 'navigation.my_account', defaultMessage: 'My account' },
    disconnect: { id: 'navigation.disconnect', defaultMessage: 'Disconnect' }
});

export const CurrentUserMenuItem: React.FC<DropdownProps> = (props) => {
    const { formatMessage } = useIntl();
    const { isMobile } = useResponsive();

    const isLoading = useSelector(isSessionLoading);
    const userSession = useSelector(getUserSession);
    const userDisplayName = UserHelper.getDisplayName(userSession?.user);

    const trigger = (
        <div>
            <Icon name="user circle" size="large" />
            {!isMobile &&
                <span>{userDisplayName}</span>
            }
        </div>
    );

    return (
        <Dropdown item disabled={isLoading} loading={isLoading} trigger={trigger} {...props}>
            <Dropdown.Menu>
                {isMobile && <Dropdown.Header>{userDisplayName}</Dropdown.Header>}
                <Dropdown.Item as={NavLink} to="/user-profile" text={formatMessage(m.myAccount)} />
                <Dropdown.Item as={NavLink} to="/account/logout" text={formatMessage(m.disconnect)} />
            </Dropdown.Menu>
        </Dropdown>
    );
};