import { User, UserAccountType } from '../state/models';

export class SecurityHelper {
    public static canViewDetailsTab(user: User | undefined): boolean {
        if (!this.isAuthenticated(user)) {
            return false;
        }

        return user!.accountType === UserAccountType.administrator
            || user!.accountType === UserAccountType.customerServiceAgent
            || user!.accountType === UserAccountType.jaymarTechnician
            || user!.accountType === UserAccountType.factoryTechnician;
    }

    public static canViewTechnicianTab(user: User | undefined): boolean {
        return user!.accountType === UserAccountType.jaymarTechnician;
    }

    public static canViewFactoryTab(user: User | undefined): boolean {
        return user!.accountType === UserAccountType.factoryTechnician;
    }

    public static canViewAgentTab(user: User | undefined): boolean {
        if (!this.isAuthenticated(user)) {
            return false;
        }

        return user!.accountType === UserAccountType.administrator
            || user!.accountType === UserAccountType.customerServiceAgent;
    }

    public static canViewInspectionTab(user: User | undefined): boolean {
        if (!this.isAuthenticated(user)) {
            return false;
        }

        return user!.accountType === UserAccountType.jaymarRepresentative;
    }

    public static canExportForms(user: User | undefined): boolean {
        if (!this.isAuthenticated(user)) {
            return false;
        }

        return user!.accountType === UserAccountType.administrator
            || user!.accountType === UserAccountType.customerServiceAgent;
    }

    public static canViewInternalNotes(user: User | undefined): boolean {
        if (!this.isAuthenticated(user)) {
            return false;
        }

        return user!.accountType === UserAccountType.administrator
            || user!.accountType === UserAccountType.customerServiceAgent
            || user!.accountType === UserAccountType.jaymarRepresentative
            || user!.accountType === UserAccountType.jaymarTechnician
            || user!.accountType === UserAccountType.factoryTechnician
            || user!.accountType === UserAccountType.client;
    }

    public static canAddInternalNotes(user: User | undefined): boolean {
        if (!this.isAuthenticated(user)) {
            return false;
        }

        return user!.accountType === UserAccountType.administrator
            || user!.accountType === UserAccountType.customerServiceAgent
            || user!.accountType === UserAccountType.jaymarRepresentative
            || user!.accountType === UserAccountType.jaymarTechnician
            || user!.accountType === UserAccountType.factoryTechnician;
    }

    public static canViewTabs(user: User | undefined): boolean {
        if (!this.isAuthenticated(user)) {
            return false;
        }

        return user!.accountType === UserAccountType.administrator
            || user!.accountType === UserAccountType.customerServiceAgent
            || user!.accountType === UserAccountType.jaymarRepresentative
            || user!.accountType === UserAccountType.jaymarTechnician
            || user!.accountType === UserAccountType.factoryTechnician;
    }

    public static canViewMyServiceCallPage(user: User | undefined): boolean {
        if (!this.isAuthenticated(user)) {
            return false;
        }

        return user!.accountType === UserAccountType.administrator
            || user!.accountType === UserAccountType.customerServiceAgent;
    }

    public static canViewAllTechniciansCalendarPage(user: User | undefined): boolean {
        if (!this.isAuthenticated(user)) {
            return false;
        }

        return user!.accountType === UserAccountType.administrator
            || user!.accountType === UserAccountType.customerServiceAgent;
    }

    public static canViewAdministrationPage(user: User | undefined): boolean {
        if (!this.isAuthenticated(user)) {
            return false;
        }

        return user!.accountType === UserAccountType.administrator;
    }

    private static isAuthenticated(user: User | undefined): boolean {
        return user != null && user.accountType !== UserAccountType.none;
    }
}