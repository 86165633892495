import _ from "lodash";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { defineMessages, useIntl } from "react-intl";
import { Form, Header, Input } from "semantic-ui-react";
import { UserAccountType } from "../../../../../state/models";
import { UserEdit } from "../../models";
import { RolesDropdown } from "./RolesDropdown";

interface OwnProps {
    disabled?: boolean;
    isNewUser?: boolean;
}

export type AuthenticationPropertiesSectionProps =
    & OwnProps;

const m = defineMessages({
    sectionTitle: { id: 'AuthenticationPropertiesSection.sectionTitle', defaultMessage: 'Authentication properties' },
    emailLabel: { id: 'UserProfileProperties.emailLabel', defaultMessage: 'Email' },
    emailRequired: { id: 'UserProfileProperties.emailRequired', defaultMessage: 'Please provide the email address that you will use to login into your account.' },
    passwordLabel: { id: 'AuthenticationPropertiesSection.passwordLabel', defaultMessage: 'Password' },
    passwordRequired: { id: 'AuthenticationPropertiesSection.passwordRequired', defaultMessage: 'Please specify a password for the client.' },
});

export const AuthenticationPropertiesSection: React.FC<AuthenticationPropertiesSectionProps> = (props) => {
    const { formatMessage } = useIntl();
    const { control, formState: { errors }, setValue } = useFormContext<UserEdit>();

    return (
        <>
            <Header content={formatMessage(m.sectionTitle)} />
            <Controller
                name="email"
                control={control}
                rules={{ required: formatMessage(m.emailRequired) }}
                render={({ field }) => (
                    <Form.Input
                        required
                        disabled={props.disabled}
                        error={errors.email != null}
                        label={formatMessage(m.emailLabel)}
                        value={field.value || ''}
                        onChange={(e, data) => setValue('email', data.value)}
                    />
                )}
            />

            <Controller
                control={control}
                name="password"
                rules={{ required: props.isNewUser ? formatMessage(m.passwordRequired) : undefined }}
                render={({ field }) => (
                    <Form.Field error={errors.password != null} required={props.isNewUser} disabled={props.disabled}>
                        <label>{formatMessage(m.passwordLabel)}</label>
                        <Input 
                            type="password"
                            placeholder={!props.isNewUser ? _.repeat('•', 32) : undefined}
                            value={field.value || ''}
                            onChange={(e, data) => setValue('password', data.value)}
                        />
                    </Form.Field>
                )}
            />
        </>
    );
};