import { ActionTypeKeys } from './types';
import { ReportingResultVariation, StatisticsFilters, LabeledValue, DatedValue, TrendsFilters, QualityRatio, DisplayOptions, initialStatisticsFilter, PairedValue, ServiceCallStatus, ClientsAdoptionFilters, Ratio } from '../../models';
import { ReportingService } from '../../../services';
import * as SearchActions from '../search';
import { AppThunk } from '../../store';

export type ActionTypes =
    | LoadOverdueCallCountRequest
    | LoadOverdueCallCountSuccess
    | LoadOverdueCallCountFailure
    | LoadStalledUnassignedCallCountRequest
    | LoadStalledUnassignedCallCountSuccess
    | LoadStalledUnassignedCallCountFailure
    | LoadCallsCreatedRequest
    | LoadCallsCreatedSuccess
    | LoadCallsCreatedFailure
    | LoadCallsClosedRequest
    | LoadCallsClosedSuccess
    | LoadCallsClosedFailure
    | LoadCallsActiveRequest
    | LoadCallsActiveSuccess
    | LoadCallsActiveFailure
    | LoadAverageResolutionTimeRequest
    | LoadAverageResolutionTimeSuccess
    | LoadAverageResolutionTimeFailure
    | LoadProblemsFrequencyRequest
    | LoadProblemsFrequencySuccess
    | LoadProblemsFrequencyFailure
    | LoadDefectiveProductsFrequencyRequest
    | LoadDefectiveProductsFrequencySuccess
    | LoadDefectiveProductsFrequencyFailure
    | LoadDefectiveCoversFrequencyRequest
    | LoadDefectiveCoversFrequencySuccess
    | LoadDefectiveCoversFrequencyFailure
    | LoadCallsRepairsDistributionRequest
    | LoadCallsRepairsDistributionSuccess
    | LoadCallsRepairsDistributionFailure
    | LoadQualityRatiosRequest
    | LoadQualityRatiosSuccess
    | LoadQualityRatiosFailure
    | LoadCallsCreatedPerMonthRequest
    | LoadCallsCreatedPerMonthSuccess
    | LoadCallsCreatedPerMonthFailure
    | LoadCurrentActiveCallsRequest
    | LoadCurrentActiveCallsSuccess
    | LoadCurrentActiveCallsFailure
    | LoadActiveCallsStatusRequest
    | LoadActiveCallsStatusSuccess
    | LoadActiveCallsStatusFailure
    | LoadClientsAdoptionRatioRequest
    | LoadClientsAdoptionRatioSuccess
    | LoadClientsAdoptionRatioFailure
    | LoadTopClientAdoptersRequest
    | LoadTopClientAdoptersSuccess
    | LoadTopClientAdoptersFailure
    | LoadCountriesFilterRequest
    | LoadCountriesFilterSuccess
    | LoadCountriesFilterFailure
    | LoadProvincesFilterRequest
    | LoadProvincesFilterSuccess
    | LoadProvincesFilterFailure
    | SetStatisticsFilters
    | SetTrendsFilters
    | SetTrendsDisplay
    | SetClientsAdoptionFilters
    | SearchActions.SearchClientsRequestAction
    | SearchActions.SearchClientsSuccessAction
    | SearchActions.SearchClientsFailureAction
    | { type: '' };

export interface LoadOverdueCallCountRequest { type: ActionTypeKeys.LOAD_OVERDUE_CALL_COUNT_REQUEST; }
export interface LoadOverdueCallCountSuccess { type: ActionTypeKeys.LOAD_OVERDUE_CALL_COUNT_SUCCESS; payload: number; }
export interface LoadOverdueCallCountFailure { type: ActionTypeKeys.LOAD_OVERDUE_CALL_COUNT_FAILURE; payload: string; }

export interface LoadStalledUnassignedCallCountRequest { type: ActionTypeKeys.LOAD_STALLED_UNASSIGNED_CALL_COUNT_REQUEST; }
export interface LoadStalledUnassignedCallCountSuccess { type: ActionTypeKeys.LOAD_STALLED_UNASSIGNED_CALL_COUNT_SUCCESS; payload: number; }
export interface LoadStalledUnassignedCallCountFailure { type: ActionTypeKeys.LOAD_STALLED_UNASSIGNED_CALL_COUNT_FAILURE; payload: string; }

export interface LoadCallsCreatedRequest { type: ActionTypeKeys.LOAD_CALLS_CREATED_REQUEST; }
export interface LoadCallsCreatedSuccess { type: ActionTypeKeys.LOAD_CALLS_CREATED_SUCCESS; payload: ReportingResultVariation<number>; }
export interface LoadCallsCreatedFailure { type: ActionTypeKeys.LOAD_CALLS_CREATED_FAILURE; payload: string; }

export interface LoadCallsClosedRequest { type: ActionTypeKeys.LOAD_CALLS_CLOSED_REQUEST; }
export interface LoadCallsClosedSuccess { type: ActionTypeKeys.LOAD_CALLS_CLOSED_SUCCESS; payload: ReportingResultVariation<number>; }
export interface LoadCallsClosedFailure { type: ActionTypeKeys.LOAD_CALLS_CLOSED_FAILURE; payload: string; }

export interface LoadCallsActiveRequest { type: ActionTypeKeys.LOAD_CALLS_ACTIVE_REQUEST; }
export interface LoadCallsActiveSuccess { type: ActionTypeKeys.LOAD_CALLS_ACTIVE_SUCCESS; payload: ReportingResultVariation<number>; }
export interface LoadCallsActiveFailure { type: ActionTypeKeys.LOAD_CALLS_ACTIVE_FAILURE; payload: string; }

export interface LoadAverageResolutionTimeRequest { type: ActionTypeKeys.LOAD_AVERAGE_RESOLUTION_TIME_REQUEST; }
export interface LoadAverageResolutionTimeSuccess { type: ActionTypeKeys.LOAD_AVERAGE_RESOLUTION_TIME_SUCCESS; payload: ReportingResultVariation<number>; }
export interface LoadAverageResolutionTimeFailure { type: ActionTypeKeys.LOAD_AVERAGE_RESOLUTION_TIME_FAILURE; payload: string; }

export interface LoadProblemsFrequencyRequest { type: ActionTypeKeys.LOAD_PROBLEMS_FREQUENCY_REQUEST; }
export interface LoadProblemsFrequencySuccess { type: ActionTypeKeys.LOAD_PROBLEMS_FREQUENCY_SUCCESS; payload: ReportingResultVariation<LabeledValue<number>[]>; }
export interface LoadProblemsFrequencyFailure { type: ActionTypeKeys.LOAD_PROBLEMS_FREQUENCY_FAILURE; payload: string; }

export interface LoadDefectiveProductsFrequencyRequest { type: ActionTypeKeys.LOAD_DEFECTIVE_PRODUCTS_FREQUENCY_REQUEST; }
export interface LoadDefectiveProductsFrequencySuccess { type: ActionTypeKeys.LOAD_DEFECTIVE_PRODUCTS_FREQUENCY_SUCCESS; payload: ReportingResultVariation<LabeledValue<number>[]>; }
export interface LoadDefectiveProductsFrequencyFailure { type: ActionTypeKeys.LOAD_DEFECTIVE_PRODUCTS_FREQUENCY_FAILURE; payload: string; }

export interface LoadDefectiveCoversFrequencyRequest { type: ActionTypeKeys.LOAD_DEFECTIVE_COVERS_FREQUENCY_REQUEST; }
export interface LoadDefectiveCoversFrequencySuccess { type: ActionTypeKeys.LOAD_DEFECTIVE_COVERS_FREQUENCY_SUCCESS; payload: ReportingResultVariation<LabeledValue<number>[]>; }
export interface LoadDefectiveCoversFrequencyFailure { type: ActionTypeKeys.LOAD_DEFECTIVE_COVERS_FREQUENCY_FAILURE; payload: string; }

export interface LoadCallsRepairsDistributionRequest { type: ActionTypeKeys.LOAD_CALLS_REPAIRS_DISTRIBUTION_REQUEST; }
export interface LoadCallsRepairsDistributionSuccess { type: ActionTypeKeys.LOAD_CALLS_REPAIRS_DISTRIBUTION_SUCCESS; payload: ReportingResultVariation<LabeledValue<number>[]>; }
export interface LoadCallsRepairsDistributionFailure { type: ActionTypeKeys.LOAD_CALLS_REPAIRS_DISTRIBUTION_FAILURE; payload: string; }

export interface LoadQualityRatiosRequest { type: ActionTypeKeys.LOAD_QUALITY_RATIOS_REQUEST; }
export interface LoadQualityRatiosSuccess { type: ActionTypeKeys.LOAD_QUALITY_RATIOS_SUCCESS; payload: DatedValue<QualityRatio>[]; }
export interface LoadQualityRatiosFailure { type: ActionTypeKeys.LOAD_QUALITY_RATIOS_FAILURE; payload: string; }

export interface LoadCallsCreatedPerMonthRequest { type: ActionTypeKeys.LOAD_CALLS_CREATED_PER_MONTH_REQUEST; }
export interface LoadCallsCreatedPerMonthSuccess { type: ActionTypeKeys.LOAD_CALLS_CREATED_PER_MONTH_SUCCESS; payload: DatedValue<number>[]; }
export interface LoadCallsCreatedPerMonthFailure { type: ActionTypeKeys.LOAD_CALLS_CREATED_PER_MONTH_FAILURE; payload: string; }

export interface LoadCurrentActiveCallsRequest { type: ActionTypeKeys.LOAD_CURRENT_ACTIVE_CALLS_REQUEST; }
export interface LoadCurrentActiveCallsSuccess { type: ActionTypeKeys.LOAD_CURRENT_ACTIVE_CALLS_SUCCESS; payload: number; }
export interface LoadCurrentActiveCallsFailure { type: ActionTypeKeys.LOAD_CURRENT_ACTIVE_CALLS_FAILURE; payload: string; }

export interface LoadActiveCallsStatusRequest { type: ActionTypeKeys.LOAD_ACTIVE_CALLS_STATUS_REQUEST; }
export interface LoadActiveCallsStatusSuccess { type: ActionTypeKeys.LOAD_ACTIVE_CALLS_STATUS_SUCCESS; payload: PairedValue<number, ServiceCallStatus>[]; }
export interface LoadActiveCallsStatusFailure { type: ActionTypeKeys.LOAD_ACTIVE_CALLS_STATUS_FAILURE; payload: string; }

export interface LoadClientsAdoptionRatioRequest { type: ActionTypeKeys.LOAD_CLIENTS_ADOPTION_RATIO_REQUEST; }
export interface LoadClientsAdoptionRatioSuccess { type: ActionTypeKeys.LOAD_CLIENTS_ADOPTION_RATIO_SUCCESS; payload: Ratio<number>; }
export interface LoadClientsAdoptionRatioFailure { type: ActionTypeKeys.LOAD_CLIENTS_ADOPTION_RATIO_FAILURE; payload: string; }

export interface LoadTopClientAdoptersRequest { type: ActionTypeKeys.LOAD_TOP_CLIENT_ADOPTERS_REQUEST; }
export interface LoadTopClientAdoptersSuccess { type: ActionTypeKeys.LOAD_TOP_CLIENT_ADOPTERS_SUCCESS; payload: LabeledValue<number>[]; }
export interface LoadTopClientAdoptersFailure { type: ActionTypeKeys.LOAD_TOP_CLIENT_ADOPTERS_FAILURE; payload: string; }

export interface LoadCountriesFilterRequest { type: ActionTypeKeys.LOAD_COUNTRIES_FILTER_REQUEST; }
export interface LoadCountriesFilterSuccess { type: ActionTypeKeys.LOAD_COUNTRIES_FILTER_SUCCESS; payload: string[]; }
export interface LoadCountriesFilterFailure { type: ActionTypeKeys.LOAD_COUNTRIES_FILTER_FAILURE; payload: string; }

export interface LoadProvincesFilterRequest { type: ActionTypeKeys.LOAD_PROVINCES_FILTER_REQUEST; }
export interface LoadProvincesFilterSuccess { type: ActionTypeKeys.LOAD_PROVINCES_FILTER_SUCCESS; payload: string[]; }
export interface LoadProvincesFilterFailure { type: ActionTypeKeys.LOAD_PROVINCES_FILTER_FAILURE; payload: string; }

export interface SetStatisticsFilters { type: ActionTypeKeys.SET_STATISTICS_FILTERS; payload: StatisticsFilters; }
export interface SetTrendsFilters { type: ActionTypeKeys.SET_TRENDS_FILTERS; payload: TrendsFilters; }
export interface SetTrendsDisplay { type: ActionTypeKeys.SET_TRENDS_DISPLAY; payload: DisplayOptions; }
export interface SetClientsAdoptionFilters { type: ActionTypeKeys.SET_CLIENTS_ADOPTION_FILTERS; payload: ClientsAdoptionFilters; }

const service = new ReportingService();

export const actionCreators = {
    loadOverdueCallCount: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_OVERDUE_CALL_COUNT_REQUEST });

            service.loadOverdueCallCount()
                .then(response => response.json() as number)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.LOAD_OVERDUE_CALL_COUNT_SUCCESS, payload: result });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_OVERDUE_CALL_COUNT_FAILURE, payload: error });
                });
        };
    },

    loadStalledUnassignedCallCount: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_STALLED_UNASSIGNED_CALL_COUNT_REQUEST });

            service.loadStalledUnassignedCallCount()
                .then(response => response.json() as number)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.LOAD_STALLED_UNASSIGNED_CALL_COUNT_SUCCESS, payload: result });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_STALLED_UNASSIGNED_CALL_COUNT_FAILURE, payload: error });
                });
        };
    },

    loadCallsCreated: (startDate: Date, endDate: Date, filters: StatisticsFilters): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_CALLS_CREATED_REQUEST });

            service.loadCallsCreated(startDate, endDate, filters)
                .then(response => response.json() as ReportingResultVariation<number>)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.LOAD_CALLS_CREATED_SUCCESS, payload: result });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_CALLS_CREATED_FAILURE, payload: error });
                });
        };
    },

    loadCallsClosed: (startDate: Date, endDate: Date, filters: StatisticsFilters): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_CALLS_CLOSED_REQUEST });

            service.loadCallsClosed(startDate, endDate, filters)
                .then(response => response.json() as ReportingResultVariation<number>)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.LOAD_CALLS_CLOSED_SUCCESS, payload: result });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_CALLS_CLOSED_FAILURE, payload: error });
                });
        };
    },

    loadCallsActive: (startDate: Date, endDate: Date, filters: StatisticsFilters): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_CALLS_ACTIVE_REQUEST });

            service.loadCallsActive(startDate, endDate, filters)
                .then(response => response.json() as ReportingResultVariation<number>)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.LOAD_CALLS_ACTIVE_SUCCESS, payload: result });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_CALLS_ACTIVE_FAILURE, payload: error });
                });
        };
    },

    loadAverageResolutionTime: (startDate: Date, endDate: Date, filters: StatisticsFilters): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_AVERAGE_RESOLUTION_TIME_REQUEST });

            service.loadAverageResolutionTime(startDate, endDate, filters)
                .then(response => response.json() as ReportingResultVariation<number>)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.LOAD_AVERAGE_RESOLUTION_TIME_SUCCESS, payload: result });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_AVERAGE_RESOLUTION_TIME_FAILURE, payload: error });
                });
        };
    },

    loadProblemsFrequency: (startDate: Date, endDate: Date, filters: StatisticsFilters): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_PROBLEMS_FREQUENCY_REQUEST });

            service.loadProblemsFrequency(startDate, endDate, filters)
                .then(response => response.json() as ReportingResultVariation<LabeledValue<number>[]>)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.LOAD_PROBLEMS_FREQUENCY_SUCCESS, payload: result });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_PROBLEMS_FREQUENCY_FAILURE, payload: error });
                });
        };
    },

    loadDefectiveProductsFrequency: (startDate: Date, endDate: Date, filters: StatisticsFilters): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_DEFECTIVE_PRODUCTS_FREQUENCY_REQUEST });

            service.loadDefectiveProductsFrequency(startDate, endDate, filters)
                .then(response => response.json() as ReportingResultVariation<LabeledValue<number>[]>)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.LOAD_DEFECTIVE_PRODUCTS_FREQUENCY_SUCCESS, payload: result });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_DEFECTIVE_PRODUCTS_FREQUENCY_FAILURE, payload: error });
                });
        };
    },

    loadDefectiveCoversFrequency: (startDate: Date, endDate: Date, filters: StatisticsFilters): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_DEFECTIVE_COVERS_FREQUENCY_REQUEST });

            service.loadDefectiveCoversFrequency(startDate, endDate, filters)
                .then(response => response.json() as ReportingResultVariation<LabeledValue<number>[]>)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.LOAD_DEFECTIVE_COVERS_FREQUENCY_SUCCESS, payload: result });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_DEFECTIVE_COVERS_FREQUENCY_FAILURE, payload: error });
                });
        };
    },

    loadCallsRepairsDistribution: (startDate: Date, endDate: Date, filters: StatisticsFilters): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_CALLS_REPAIRS_DISTRIBUTION_REQUEST });

            service.loadCallsRepairsDistribution(startDate, endDate, filters)
                .then(response => response.json() as ReportingResultVariation<LabeledValue<number>>[])
                .then(result => {
                    dispatch({ type: ActionTypeKeys.LOAD_CALLS_REPAIRS_DISTRIBUTION_SUCCESS, payload: result });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_CALLS_REPAIRS_DISTRIBUTION_FAILURE, payload: error });
                });
        };
    },

    loadQualityRatios: (startDate: Date, endDate: Date, filters: StatisticsFilters): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_QUALITY_RATIOS_REQUEST });

            service.loadQualityRatios(startDate, endDate, filters)
                .then(response => response.json() as DatedValue<QualityRatio>[])
                .then(result => {
                    dispatch({ type: ActionTypeKeys.LOAD_QUALITY_RATIOS_SUCCESS, payload: result });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_QUALITY_RATIOS_FAILURE, payload: error });
                });
        };
    },

    loadCallsCreatedPerMonth: (startDate: Date, endDate: Date, filters: StatisticsFilters): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_CALLS_CREATED_PER_MONTH_REQUEST });

            service.loadCallsCreatedPerMonth(startDate, endDate, filters)
                .then(response => response.json() as DatedValue<QualityRatio>[])
                .then(result => {
                    dispatch({ type: ActionTypeKeys.LOAD_CALLS_CREATED_PER_MONTH_SUCCESS, payload: result });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_CALLS_CREATED_PER_MONTH_FAILURE, payload: error });
                });
        };
    },

    loadCurrentActiveCalls: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_CURRENT_ACTIVE_CALLS_REQUEST });

            service.loadCallsActive(new Date(2001, 0, 1), new Date(), initialStatisticsFilter)
                .then(response => response.json() as ReportingResultVariation<number>)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.LOAD_CURRENT_ACTIVE_CALLS_SUCCESS, payload: result.currentPeriod });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_CURRENT_ACTIVE_CALLS_FAILURE, payload: error });
                });
        };
    },

    loadActiveCallsStatus: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_ACTIVE_CALLS_STATUS_REQUEST });

            service.loadActiveCallsStatus()
                .then(response => response.json() as PairedValue<number, ServiceCallStatus>)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.LOAD_ACTIVE_CALLS_STATUS_SUCCESS, payload: result });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_ACTIVE_CALLS_STATUS_FAILURE, payload: error });
                });
        };
    },

    loadClientsAdoptionRatio: (startDate: Date, endDate: Date): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_CLIENTS_ADOPTION_RATIO_REQUEST });

            service.loadClientsAdoptionRatio(startDate, endDate)
                .then(response => response.json() as Ratio<number>)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.LOAD_CLIENTS_ADOPTION_RATIO_SUCCESS, payload: result });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_CLIENTS_ADOPTION_RATIO_FAILURE, payload: error });
                });
        };
    },

    loadTopClientAdopters: (startDate: Date, endDate: Date): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_TOP_CLIENT_ADOPTERS_REQUEST });

            service.loadTopClientAdopters(startDate, endDate)
                .then(response => response.json() as LabeledValue<number>[])
                .then(result => {
                    dispatch({ type: ActionTypeKeys.LOAD_TOP_CLIENT_ADOPTERS_SUCCESS, payload: result });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_TOP_CLIENT_ADOPTERS_FAILURE, payload: error });
                });
        };
    },

    loadCountriesFilter: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_COUNTRIES_FILTER_REQUEST });

            service.loadCountriesFilter()
                .then(response => response.json() as string[])
                .then(result => {
                    dispatch({ type: ActionTypeKeys.LOAD_COUNTRIES_FILTER_SUCCESS, payload: result });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_COUNTRIES_FILTER_FAILURE, payload: error });
                });
        };
    },

    loadProvincesFilter: (countryCode: string): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_PROVINCES_FILTER_REQUEST });

            service.loadProvincesFilter(countryCode)
                .then(response => response.json() as string[])
                .then(result => {
                    dispatch({ type: ActionTypeKeys.LOAD_PROVINCES_FILTER_SUCCESS, payload: result });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_PROVINCES_FILTER_FAILURE, payload: error });
                });
        };
    },

    setStatisticsFilters: (filters: StatisticsFilters) => {
        return { type: ActionTypeKeys.SET_STATISTICS_FILTERS, payload: filters };
    },

    setTrendsFilters: (filters: TrendsFilters) => {
        return { type: ActionTypeKeys.SET_TRENDS_FILTERS, payload: filters };
    },

    setTrendsDisplay: (options: DisplayOptions) => {
        return { type: ActionTypeKeys.SET_TRENDS_DISPLAY, payload: options };
    },

    setClientsAdoptionFilters: (filters: ClientsAdoptionFilters) => {
        return { type: ActionTypeKeys.SET_CLIENTS_ADOPTION_FILTERS, payload: filters };
    }
};