import BaseService from './baseService';
import { TechnicianReport } from '../state/models';

export class TechnicianService extends BaseService {
    constructor() {
        super('api/technician');
    }
    
    public getReport(repairId: number) {
        return this.get(`/repairs/${repairId}/report`);
    }

    public saveReport(repairId: number, report: TechnicianReport): Promise<any> {
        return this.put(`/repairs/${repairId}/report`, report);
    }
}