import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import * as TechniciansActions from '../../../state/ducks/technicians';
import * as UiActions from '../../../state/ducks/ui';
import { ApplicationState } from '../../../state/ducks';
import { ServiceReportSummaryCard } from '../../../components/service-calls';
import { ServiceCallState } from '../../../state/ducks/service-calls/reducers';
import { getServiceCallState } from '../../../state/ducks/service-calls';
import { TechnicianReport } from '../../../state/models';
import { getTechnicianReport } from '../../../state/ducks/technicians/selectors';

interface TechnicianCompleteStepActions {
    uiActions: typeof UiActions.actionCreators;
    techniciansActions: typeof TechniciansActions.actionCreators;
}

interface TechnicianCompleteStepOwnProps {
    serviceCall: ServiceCallState;
    report: TechnicianReport | undefined;
    isReportLoading: boolean;
}

export type TechnicianCompleteStepProps =
    & TechnicianCompleteStepOwnProps
    & TechnicianCompleteStepActions
    & RouteComponentProps<{ serviceCallId: string, repairId: string }>;

type IntlTechnicianCompleteStepProps = TechnicianCompleteStepProps & WrappedComponentProps;

class TechnicianCompleteStep extends React.Component<IntlTechnicianCompleteStepProps, {}> {
    public componentDidMount() {
        this.props.uiActions.changeTechnicianStep('complete');
    }

    public render() {
        return (
            <div>
                <ServiceReportSummaryCard
                    isLoading={this.props.serviceCall.isLoading || this.props.isReportLoading}
                    serviceCall={this.props.serviceCall.details}
                    report={this.props.report}
                    onSign={this.handleSignature}
                />
            </div>
        );
    }

    private handleSignature = (signature: string): void => {
        const repairId = Number(this.props.match.params.repairId);

        if (this.props.report) {
            this.props.techniciansActions.saveReport(repairId, {
                ...this.props.report,
                clientSignature: signature
            });
        }
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: TechnicianCompleteStepProps): TechnicianCompleteStepOwnProps => {
    const serviceCallId = Number(ownProps.match.params.serviceCallId);
    const repairId = Number(ownProps.match.params.repairId);
    const serviceCallState = getServiceCallState(state, serviceCallId);
    const reportState = getTechnicianReport(state, repairId);

    return {
        serviceCall: serviceCallState,
        isReportLoading: reportState == null || reportState.isLoading,
        report: reportState != null ? reportState.report : undefined
    };
};

const mapDispatchToProps = (dispatch: Dispatch): TechnicianCompleteStepActions => {
    return {
        techniciansActions: bindActionCreators(TechniciansActions.actionCreators, dispatch),
        uiActions: bindActionCreators(UiActions.actionCreators, dispatch),
    };
};

const intlComponent = injectIntl(TechnicianCompleteStep);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as TechnicianCompleteStep };