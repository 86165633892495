import { PageHeader } from "components/common";
import { ServiceCallList, ServiceCallTable } from "components/service-calls";
import React, { useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { ServiceCallsService } from "services";
import { isCurrentUserAgent } from "state/ducks/current-user";
import { PagedResult, ServiceCall, SortDirection, SortOptions } from "state/models";
import { useResponsive } from "utils/responsive";
import * as globalNotification from '../../state/ducks/global-notification';

const serviceCallService = new ServiceCallsService();

const messages = defineMessages({
    pageTitle: {
        id: "StalledUnassignedServiceCallsPage.page_title",
        defaultMessage: "Stalled unassigned service calls",
    },
    pageSubtitle: {
        id: "StalledUnassignedServiceCallsPage.page_subtitle",
        defaultMessage:
            "View the stalled unassigned service calls and take actions to close them.",
    },
});

interface RouteProps {
    page?: string;
}

export const StalledUnassignedServiceCallsPage = () => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const { isMobile } = useResponsive();
    const { page } = useParams<RouteProps>();

    const currentPage = page != null ? Number(page) : undefined;
    const isAgent = useSelector(isCurrentUserAgent);

    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);

    const [pagedServiceCalls, setPagedServiceCalls] = useState<PagedResult<ServiceCall>>(
        PagedResult.empty<ServiceCall>()
    );

    const [sortings, setSortings] = useState<SortOptions | undefined>({
        sortBy: "dateCreated",
        direction: SortDirection.Ascending,
    });

    useEffect(() => {
        setIsLoading(true);

        serviceCallService
            .getStalledUnassignedServiceCalls(currentPage, sortings)
            .then((response) => response.json() as PagedResult<ServiceCall>)
            .then((fetchedPagedServiceCalls) => {
                setPagedServiceCalls(fetchedPagedServiceCalls);
            })
            .catch(error => {        ;
                dispatch(globalNotification.actionCreators.showErrorNotification(
                    'Erreur',
                    `Une erreur est survenue en tentant de récupérer les appels de service non assignés inactifs (${error}).`)
                );
              })
            .finally(() => {
                setIsLoading(false);
            });
    }, [currentPage, dispatch, sortings]);

    const navigateToDetailsPage = (serviceCall: ServiceCall) =>
        history.push(`/service-calls/${serviceCall.id}/details`);

    const navigateToPageNumber = (page: number) =>
        history.push(`/service-calls/stalled-unassigned/${page}`);

    const navigateToFirstPage = () => navigateToPageNumber(1);

    const applySorting = (sortBy: string, direction: SortDirection) => {
        setSortings({ sortBy, direction });

        if (currentPage !== 1) {
            navigateToFirstPage();
        }
    };

    const serviceCallsProps = {
        serviceCalls: pagedServiceCalls,
        sortings: sortings,
        isLoading: isLoading,
        onViewDetails: navigateToDetailsPage,
        onPageChange: navigateToPageNumber,
        onSortData: applySorting,
    };

    return (
        <div>
            <PageHeader
                iconName='phone'
                title={formatMessage(messages.pageTitle)}
                subtitle={formatMessage(messages.pageSubtitle)}
            />

            {isMobile && <ServiceCallList {...serviceCallsProps} />}
            {!isMobile && <ServiceCallTable showClient={isAgent} {...serviceCallsProps} />}
        </div>
    );
};
