import { OrderDetail } from './OrderDetail';

export enum OrderStatus {
    creating = 0,
    verified = 1,
    inProduction = 2,
    partiallyCompleted = 3,
    completed = 4,
    partiallyLoaded = 5,
    loaded = 6,
    shipped = 7,
    billed = 8,
    cancelled = 9
}

export enum OrderType {
    unknown = 0,
    sale = 1,
    stock = 2,
    forProduction = 3,
    freeOfCharge = 4,
    interCompany = 5,
    direct = 6
}

export enum DateRequirement {
    none = 0,
    notAfter = 1,
    exactDate = 2,
    notBefore = 3,
}

export interface Order {
    id: number;
    status: OrderStatus;
    type: OrderType;
    shippedToCountry: string;
    shippedToProvince: string;
    orderedOn: Date;
    requiredOn: Date | undefined;
    shippedOn: Date;
    dateRequirement: DateRequirement;
    currency: string;
    notes: string;
    followUpNotes: string;
    productionNotes: string;
    orderDetails: OrderDetail[];
}