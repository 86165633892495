import * as React from 'react';
import { Divider, Form, Grid, Icon, InputOnChangeData, SemanticICONS } from 'semantic-ui-react';
import { BasicNumericInput } from '../../common';
import { SearchField } from './SearchField';

interface OwnProps {
    fieldId: string;
    label: string;
    value: number | undefined;
    onChange?: (fieldId: string, value: any) => void
}

export type SearchNumericFieldProps =
    & OwnProps;

export class SearchNumericField extends React.Component<SearchNumericFieldProps, {}> {
    public render() {
        return (
            <SearchField fieldId={this.props.fieldId} label={this.props.label}>
                <BasicNumericInput id={this.props.fieldId} value={this.props.value} onNumberChange={this.handleChange} />
            </SearchField>
        );
    }

    private handleChange = (newValue: number) => {
        if (this.props.onChange != null) {
            this.props.onChange(this.props.fieldId, newValue != 0 ? newValue : undefined);
        }
    }
}