import { UserProfileEdit } from '../modules/account/models';
import { BaseService } from './baseService';

export class CurrentUserService extends BaseService {
    constructor() {
        super('api/current-user');
    }

    public loadProfile(): Promise<any> {
        return this.get();
    }

    public updateProfile(userProfile: UserProfileEdit): Promise<any> {
        return this.post('/', { 
            firstName: userProfile.firstName, 
            lastName: userProfile.lastName,
            currentPassword: userProfile.currentPassword,
            newPassword: userProfile.newPassword
        });
    }
}

export default CurrentUserService;