import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Form, Button, TextAreaProps } from 'semantic-ui-react';
import { User, UserAccountType, NoteVisibility } from '../../../state/models';

interface OwnProps {
    currentUser?: User;
    isAdding?: boolean;
    onAdd: (note: string, visibility: NoteVisibility) => void;
}

interface StateProps {
    note: string;
}

export type CreateNoteFormProps =
    & OwnProps
    & WrappedComponentProps;

const m = defineMessages({
    addNoteLabel: { id: 'CreateNoteForm.addNoteLabel', defaultMessage: 'Add a note to the service call' },
    addNotePlaceholder: { id: 'CreateNoteForm.addNotePlaceholder', defaultMessage: 'Enter any additional details regarding the service call.' },
    addPublicNoteButton: { id: 'CreateNoteForm.addPublicNoteButton', defaultMessage: 'Add a public note' },
    addInternalNoteButton: { id: 'CreateNoteForm.addInternalNoteButton', defaultMessage: 'Add an internal note' },
    addNoteButton: { id: 'CreateNoteForm.addNoteButton', defaultMessage: 'Add a note' }
});

class CreateNoteForm extends React.Component<CreateNoteFormProps, StateProps> {
    public constructor(props: CreateNoteFormProps) {
        super(props);

        this.state = { note: '' };
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const isCurrentUserAnEmployee = this.props.currentUser != null && this.props.currentUser.accountType !== UserAccountType.client;

        return (
            <Form>
                <Form.TextArea
                    label={formatMessage(m.addNoteLabel)}
                    disabled={this.props.isAdding}
                    rows={4}
                    value={this.state.note}
                    placeholder={formatMessage(m.addNotePlaceholder)}
                    onChange={this.changeNote}
                />

                <div style={{ textAlign: 'right', marginTop: '5px' }}>
                    {isCurrentUserAnEmployee && this.renderEmployeeControls()}
                    {!isCurrentUserAnEmployee && this.renderClientControls()}
                </div>
            </Form>
        );
    }

    private renderEmployeeControls = () => {
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <Button
                        disabled={this.props.isAdding}
                        loading={this.props.isAdding}
                        content={formatMessage(m.addPublicNoteButton)}
                        onClick={this.addPublicNote}
                    />
                    <Button
                        primary
                        disabled={this.props.isAdding}
                        loading={this.props.isAdding}
                        content={formatMessage(m.addInternalNoteButton)}
                        onClick={this.addInternalNote}
                    />
            </React.Fragment>
        )
    }

    private renderClientControls = () => {
        const { formatMessage } = this.props.intl;

        return (
            <Button
                primary
                disabled={this.props.isAdding}
                loading={this.props.isAdding}
                content={formatMessage(m.addNoteButton)}
                onClick={this.addPublicNote}
            />
        )
    }

    private addPublicNote = () => {
        this.props.onAdd(this.state.note, NoteVisibility.public);
        this.setState({ note: '' });
    }

    private addInternalNote = () => {
        this.props.onAdd(this.state.note, NoteVisibility.internal);
        this.setState({ note: '' });
    }

    private changeNote = (_event: React.FormEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
        this.setState({ note: data.value != null ? data.value as string : '' });
    }
}

const connectedComponent = injectIntl(CreateNoteForm);
export { connectedComponent as CreateNoteForm };