import { CurrentUserService } from '../../../services';
import { PublicApiService } from '../../../services/PublicApiService';
import { User } from '../../models';
import * as globalNotification from '../global-notification';
import { ActionTypeKeys, ApiRegistration, ApiWebhook, WebhookType } from './types';
import { AppThunk } from '../../store';
import { UserProfileEdit } from '../../../modules/account/models';

export type ActionTypes =
    | UpdateProfileRequestAction | UpdateProfileSuccessAction | UpdateProfileFailureAction
    | LoadCurrentUserRequestAction | LoadCurrentUserSuccessAction | LoadCurrentUserFailureAction
    | GetApiRegistrationRequestAction | GetApiRegistrationSuccessAction | GetApiRegistrationFailureAction
    | RegisterApiRequestAction | RegisterApiSuccessAction | RegisterApiFailureAction
    | RevokeApiRequestAction | RevokeApiSuccessAction | RevokeApiFailureAction
    | UpdateApiWebhookRequestAction | UpdateApiWebhookSuccessAction | UpdateApiWebhookFailureAction
    | LoadApiWebhooksRequestAction | LoadApiWebhooksSuccessAction | LoadApiWebhooksFailureAction
    | { type: '' };

export interface UpdateProfileRequestAction { type: ActionTypeKeys.UPDATE_PROFILE_REQUEST; }
export interface UpdateProfileSuccessAction { type: ActionTypeKeys.UPDATE_PROFILE_SUCCESS; payload: User; }
export interface UpdateProfileFailureAction { type: ActionTypeKeys.UPDATE_PROFILE_FAILURE; payload: string; }

export interface LoadCurrentUserRequestAction { type: ActionTypeKeys.LOAD_PROFILE_REQUEST; }
export interface LoadCurrentUserSuccessAction { type: ActionTypeKeys.LOAD_PROFILE_SUCCESS; payload: User; }
export interface LoadCurrentUserFailureAction { type: ActionTypeKeys.LOAD_PROFILE_FAILURE; payload: string; }

export interface GetApiRegistrationRequestAction { type: ActionTypeKeys.LOAD_API_REGISTRATION_REQUEST; }
export interface GetApiRegistrationSuccessAction { type: ActionTypeKeys.LOAD_API_REGISTRATION_SUCCESS; payload: ApiRegistration; }
export interface GetApiRegistrationFailureAction { type: ActionTypeKeys.LOAD_API_REGISTRATION_FAILURE; }

export interface RegisterApiRequestAction { type: ActionTypeKeys.REGISTER_API_REQUEST; }
export interface RegisterApiSuccessAction { type: ActionTypeKeys.REGISTER_API_SUCCESS; payload: ApiRegistration; }
export interface RegisterApiFailureAction { type: ActionTypeKeys.REGISTER_API_FAILURE; }

export interface RevokeApiRequestAction { type: ActionTypeKeys.REVOKE_API_REQUEST; }
export interface RevokeApiSuccessAction { type: ActionTypeKeys.REVOKE_API_SUCCESS; }
export interface RevokeApiFailureAction { type: ActionTypeKeys.REVOKE_API_FAILURE; }

export interface LoadApiWebhooksRequestAction { type: ActionTypeKeys.LOAD_API_WEBHOOKS_REQUEST; }
export interface LoadApiWebhooksSuccessAction { type: ActionTypeKeys.LOAD_API_WEBHOOKS_SUCCESS; payload: ApiWebhook[]; }
export interface LoadApiWebhooksFailureAction { type: ActionTypeKeys.LOAD_API_WEBHOOKS_FAILURE; }

export interface UpdateApiWebhookRequestAction { type: ActionTypeKeys.UPDATE_API_WEBHOOK_REQUEST; payload: ApiWebhook; }
export interface UpdateApiWebhookSuccessAction { type: ActionTypeKeys.UPDATE_API_WEBHOOK_SUCCESS; payload: ApiWebhook; }
export interface UpdateApiWebhookFailureAction { type: ActionTypeKeys.UPDATE_API_WEBHOOK_FAILURE; }

const currentUserService = new CurrentUserService();
const publicApiService = new PublicApiService();

export const actionCreators = {
    updateProfile: (userProfile: UserProfileEdit, onSuccess?: () => void, onError?: () => void): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.UPDATE_PROFILE_REQUEST });

            currentUserService.updateProfile(userProfile)
                .then(response => response.json() as User)
                .then(user => {
                    dispatch({ type: ActionTypeKeys.UPDATE_PROFILE_SUCCESS, payload: user });

                    if (onSuccess != null) {
                        onSuccess();
                    }
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.UPDATE_PROFILE_FAILURE });

                    // TODO: We need to find a better way to handle errors
                    if (error.message === 'InvalidInput' && onError != null) {
                        onError();
                    } else {
                        dispatch(globalNotification.actionCreators.showErrorNotification('Erreur', 'Une erreur est survenue lors de la sauvegarde de votre profil utilisateur.'));
                    }
                });
        };
    },

    loadPublicApiRegistration: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_API_REGISTRATION_REQUEST });

            publicApiService.getCurrentRegistration()
                .then(apiRegistration => {
                    dispatch({ type: ActionTypeKeys.LOAD_API_REGISTRATION_SUCCESS, payload: apiRegistration });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_API_REGISTRATION_FAILURE });
                });
        };
    },

    createApiRegistration: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.REGISTER_API_REQUEST });

            publicApiService.register()
                .then(apiRegistration => {
                    dispatch({ type: ActionTypeKeys.REGISTER_API_SUCCESS, payload: apiRegistration });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.REGISTER_API_FAILURE });
                    dispatch(globalNotification.actionCreators.showErrorNotification('Erreur', 'Une erreur est survenue en tentant de créer votre clé d\'accès à l\'API de Jaymar.'));
                });
        };
    },

    revokeApiRegistration: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.REVOKE_API_REQUEST });

            publicApiService.revoke()
                .then(() => {
                    dispatch({ type: ActionTypeKeys.REVOKE_API_SUCCESS });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.REVOKE_API_FAILURE });
                    dispatch(globalNotification.actionCreators.showErrorNotification('Erreur', 'Une erreur est survenue en tentant de révoquer votre clé d\'accès à l\'API de Jaymar.'));
                });
        };
    },

    loadApiWebhooks: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_API_WEBHOOKS_REQUEST });

            publicApiService.getWebhooks()
                .then(webhooks => {
                    dispatch({ type: ActionTypeKeys.LOAD_API_WEBHOOKS_SUCCESS, payload: webhooks });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_API_WEBHOOKS_FAILURE });
                    dispatch(globalNotification.actionCreators.showErrorNotification('Erreur', 'Une erreur est survenue en tentant de charger les webhooks de votre API.'));
                });
        }
    },

    updateApiWebhook: (type: WebhookType, url: string): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.UPDATE_API_WEBHOOK_REQUEST });

            publicApiService.updateWebhook(type, url)
                .then(webhook => {
                    dispatch({ type: ActionTypeKeys.UPDATE_API_WEBHOOK_SUCCESS, payload: webhook });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.UPDATE_API_WEBHOOK_FAILURE });
                    dispatch(globalNotification.actionCreators.showErrorNotification('Erreur', 'Une erreur est survenue en tentant de mettre à jour un webhook de votre API.'));
                });
        }
    }
};