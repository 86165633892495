import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { TechnicianRepair, AppointmentTimeRange, ServiceCallDefect } from '../../../state/models';
import { Card, Label, List, SemanticCOLORS, Popup, Button } from 'semantic-ui-react';
import { DateHelper, UserHelper } from '../../../helpers';
import { commonMessages, defectTypeMessages, defectCategoryMessages } from '../../../constants';

export interface AppointmentsListOwnProps {
    appointments: TechnicianRepair[];
    onViewDetails: (appointment: TechnicianRepair) => void;
    isLoading?: boolean;
    emptyTitle?: string;
    emptySubtitle?: string;
}

export type AppointmentsListProps =
    & AppointmentsListOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    appointmentTitle: { id: 'AppointmentsList.appointment_title', defaultMessage: 'Service call #{id}' },
    scheduledFor: { id: 'AppointmentsList.scheduled_for', defaultMessage: 'Scheduled for {date}' }
});

class AppointmentsList extends React.Component<AppointmentsListProps, {}> {
    public render() {
        const renderedAppointments = this.props.appointments && this.props.appointments.length > 0
            ? this.props.appointments.map(this.renderAppointment)
            : <p>Empty</p>;

        return (
            <React.Fragment>
                {renderedAppointments}
            </React.Fragment>
        );
    }

    private renderAppointment = (appointment: TechnicianRepair) => {
        const { formatMessage } = this.props.intl;

        const hasCity = appointment.serviceCall && appointment.serviceCall.customer && appointment.serviceCall.customer.address;
        const appointmentCity = hasCity
            ? appointment.serviceCall.customer.address!.city || formatMessage(commonMessages.unknown)
            : formatMessage(commonMessages.unknown);

        const hasAgentAssigned = appointment.serviceCall && appointment.serviceCall.assignedTo;
        const agentAssigned = hasAgentAssigned
            ? UserHelper.getDisplayName(appointment.serviceCall.assignedTo)
            : formatMessage(commonMessages.unknown);

        const hasDefects = appointment.serviceCall && appointment.serviceCall.defects && appointment.serviceCall.defects.length > 0;

        return (
            <Card key={appointment.id} fluid={true}>
                <Card.Content>
                    <Card.Header>
                        {formatMessage(m.appointmentTitle, { id: appointment.serviceCallId })}
                        {this.renderTime(appointment.scheduledForTimeRange)}
                    </Card.Header>
                    <Card.Meta>
                        {appointment.scheduledForDate &&
                            <span>{formatMessage(m.scheduledFor, { date: moment.utc(appointment.scheduledForDate).format('LL') })}</span>
                        }
                    </Card.Meta>
                </Card.Content>
                <Card.Content>
                    <List>
                        {hasCity &&
                            <List.Item>
                                <List.Icon name="marker" />
                                <List.Content>{appointmentCity}</List.Content>
                            </List.Item>
                        }

                        {hasAgentAssigned &&
                            <List.Item>
                                <List.Icon name="doctor" />
                                <List.Content>{agentAssigned}</List.Content>
                            </List.Item>
                        }

                        {hasDefects &&
                            this.renderDefects(appointment.serviceCall.defects)
                        }
                    </List>
                </Card.Content>
                <Card.Content extra={true}>
                    <Button
                        fluid={true}
                        content={formatMessage(commonMessages.view)}
                        onClick={() => this.props.onViewDetails(appointment)}
                    />
                </Card.Content>
            </Card>
        );
    }

    private renderTime(timeRange: AppointmentTimeRange | undefined) {
        if (timeRange != null) {
            const { formatMessage } = this.props.intl;
            const colorMap = new Map<AppointmentTimeRange, SemanticCOLORS>([
                [AppointmentTimeRange.morning, 'orange'],
                [AppointmentTimeRange.afternoon, 'violet'],
                [AppointmentTimeRange.unspecified, 'grey']
            ]);

            const trigger = (
                <Label
                    color={colorMap.get(timeRange)}
                    content={formatMessage(DateHelper.getTimeRangeAbbreviation(timeRange))}
                    style={{ minWidth: 42, textAlign: 'center', float: 'right' }}
                />
            );

            return (
                <Popup
                    trigger={trigger}
                    content={formatMessage(DateHelper.getTimeRangeDescription(timeRange))}
                />
            );
        }

        return (null);
    }

    private renderDefects(defects: ServiceCallDefect[]) {
        const { formatMessage } = this.props.intl;

        if (defects && defects.length > 0) {
            const defect = defects[0].defect;

            const typeMessages = Object.keys(defectTypeMessages).map(key => defectTypeMessages[key]);
            const defectTypeMessage = typeMessages.find(x => x.id === defect.defectType.title);
            const defectType = defectTypeMessage ? formatMessage(defectTypeMessage) : defect.defectType.title;

            const categoryMessages = Object.keys(defectCategoryMessages).map(key => defectCategoryMessages[key]);
            const defectCategoryMessage = categoryMessages.find(x => x.id === defect.defectCategory.title);
            const defectCategory = defectCategoryMessage ? formatMessage(defectCategoryMessage) : defect.defectCategory.title;

            return (
                <List.Item>
                    <List.Icon name="medkit" />
                    <List.Content>
                        <p style={{ marginBottom: 0 }}>{defectType}</p>
                        <p style={{ color: 'rgba(0,0,0,0.4)' }}>{defectCategory}</p>
                    </List.Content>
                </List.Item>
            );
        }

        return (null);
    }
}

const connectedComponent = injectIntl(AppointmentsList);
export { connectedComponent as AppointmentsList };