import { defineMessages, MessageDescriptor } from "@formatjs/intl";
import React, { CSSProperties, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Header, InputOnChangeData } from "semantic-ui-react";
import { ClearableInput } from "../../../../../components/common";

interface OwnProps {
    totalUsersCount: number;
    searchResultsCount: number;
    totalUsersMessage?: MessageDescriptor;
    searchPlaceholder?: string;
    style?: CSSProperties;

    onChange: (searchTerm: string) => void;
    onClear: () => void;   
}

export type UsersSearchControlProps =
    & OwnProps;

const m = defineMessages({
    totalUsersCount: { id: 'UsersSearchControl.totalUsersCount', defaultMessage: '{count} users' },
    searchResultsCount: { id: 'UsersSearchControl.searchResultsCount', defaultMessage: '{count} found' },
    searchPlaceholder: { id: 'UsersSearchControl.searchPlaceholder', defaultMessage: 'Search for users...' },
});

export const UsersSearchControl: React.FC<UsersSearchControlProps> = (props) => {
    const { formatMessage } = useIntl();
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        setSearchTerm(data.value);
        props.onChange(data.value);
    }

    const handleClear = () => {
        setSearchTerm('');
        props.onClear();
    }

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '1em', ...props.style }}>
                <Header size="small" style={{ margin: 0, flexGrow: 1 }}>
                    <Header.Content>{formatMessage(props.totalUsersMessage || m.totalUsersCount, { count: props.totalUsersCount })}</Header.Content>
                    <Header.Subheader>
                        {searchTerm !== ''
                            ? <React.Fragment>{formatMessage(m.searchResultsCount, { count: props.searchResultsCount })}</React.Fragment>
                            : <React.Fragment>&nbsp;</React.Fragment>
                        }
                    </Header.Subheader>
                </Header>

                <div>
                    {props.children}
                </div>
            </div>

            <ClearableInput
                fluid
                onChange={handleSearchChange}
                onClear={handleClear}
                placeholder={props.searchPlaceholder || formatMessage(m.searchPlaceholder)}
                value={searchTerm}
                style={{ marginBottom: '1em' }}
            />
        </>
    );
};