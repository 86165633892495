import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Message, Icon } from 'semantic-ui-react';

interface OwnProps {
}

export type DraftModeWarningProps =
    & OwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'DraftModeWarning.title', defaultMessage: 'Service call in \'Draft\' mode' },
    message: { id: 'DraftModeWarning.message', defaultMessage: 'Your service call is currently in draft mode and is not visible to Jaymar\'s customer service agents. In order to send your service call, please complete the following actions:' },
    pictureRequired: { id: 'DraftModeWarning.pictureRequired', defaultMessage: 'Add atleast one picture of the furniture and its defect' },
});

class DraftModeWarning extends React.Component<DraftModeWarningProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <Message icon={true} warning={true}>
                <Icon name="warning sign" />
                <Message.Content>
                    <Message.Header>{formatMessage(m.title)}</Message.Header>
                    {formatMessage(m.message)}
                    <Message.List>
                        <Message.Item>{formatMessage(m.pictureRequired)}</Message.Item>
                    </Message.List>
                </Message.Content>
            </Message>
        );
    }
}

const intlComponent = injectIntl(DraftModeWarning);
export { intlComponent as DraftModeWarning };