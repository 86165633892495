import * as React from 'react';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import { Grid, List } from 'semantic-ui-react';
import { ServiceCallAttachment } from '../../../state/models';
import { EmptyContentSegment, UploadFileControl } from '../../common';
import { DocumentAttachment } from './DocumentAttachment';

interface DocumentAttachmentsOwnProps {
    documents: ServiceCallAttachment[];
    canUploadDocuments: boolean;
    areDocumentsUploading: boolean;

    onDelete?: (attachmentId: number) => void;
    onUpload?: (documents: FileList) => void;
}

export type DocumentAttachmentsProps =
    & DocumentAttachmentsOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    emptyDocumentTitle: { id: 'ProblemDescriptionCard.empty_document_title', defaultMessage: 'No documents' },
    emptyDocumentDescription: { id: 'ProblemDescriptionCard.empty_document_description', defaultMessage: 'There are no documents associated to this service call.' },
});

class DocumentAttachments extends React.Component<DocumentAttachmentsProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const documents = this.props.documents && this.props.documents.length > 0
            ? this.props.documents.map(x => <DocumentAttachment key={x.id} document={x} onDelete={this.props.onDelete} />)
            : [];

        return (
            <Grid>
                <Grid.Column mobile={16} tablet={8} computer={10}>
                    {documents.length > 0 &&
                        <List divided={true} relaxed={true}>
                            {documents}
                        </List>
                    }
                    {documents.length <= 0 &&
                        <EmptyContentSegment
                            title={formatMessage(m.emptyDocumentTitle)}
                            description={formatMessage(m.emptyDocumentDescription)}
                            basic={true}
                        />
                    }
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={6}>
                    {this.props.canUploadDocuments && this.props.onUpload &&
                        <UploadFileControl
                            onFilesUpload={this.props.onUpload}
                            acceptedFileTypes="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, message/rfc822"
                            isUploading={this.props.areDocumentsUploading}
                        />
                    }
                </Grid.Column>
            </Grid>
        );
    }
}

const connectedComponent = injectIntl(DocumentAttachments);
export { connectedComponent as DocumentAttachments };