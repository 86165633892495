export enum ActionTypeKeys {
    CREATE_REQUEST = 'SERVICE_CALL_CREATE_REQUEST',
    CREATE_SUCCESS = 'SERVICE_CALL_CREATE_SUCCESS',
    CREATE_FAILURE = 'SERVICE_CALL_CREATE_FAILURE',

    UPDATE_REQUEST = 'SERVICE_CALL_UPDATE_REQUEST',
    UPDATE_SUCCESS = 'SERVICE_CALL_UPDATE_SUCCESS',
    UPDATE_FAILURE = 'SERVICE_CALL_UPDATE_FAILURE',

    LOAD_NEW_COUNT_REQUEST = 'SERVICE_CALL_LOAD_NEW_COUNT_REQUEST',
    LOAD_NEW_COUNT_SUCCESS = 'SERVICE_CALL_LOAD_NEW_COUNT_SUCCESS',
    LOAD_NEW_COUNT_FAILURE = 'SERVICE_CALL_LOAD_NEW_COUNT_FAILURE',

    LOAD_AGENTS_WORKLOAD_REQUEST = 'SERVICE_CALL_LOAD_AGENTS_WORKLOAD_REQUEST',
    LOAD_AGENTS_WORKLOAD_SUCCESS = 'SERVICE_CALL_LOAD_AGENTS_WORKLOAD_SUCCESS',
    LOAD_AGENTS_WORKLOAD_FAILURE = 'SERVICE_CALL_LOAD_AGENTS_WORKLOAD_FAILURE',

    LOAD_AGENT_NEXT_ACTIONS_REQUEST = 'SERVICE_CALL_LOAD_AGENT_NEXT_ACTIONS_REQUEST',
    LOAD_AGENT_NEXT_ACTIONS_SUCCESS = 'SERVICE_CALL_LOAD_AGENT_NEXT_ACTIONS_SUCCESS',
    LOAD_AGENT_NEXT_ACTIONS_FAILURE = 'SERVICE_CALL_LOAD_AGENT_NEXT_ACTIONS_FAILURE',

    LOAD_DETAILS_REQUEST = 'SERVICE_CALL_LOAD_DETAILS_REQUEST',
    LOAD_DETAILS_SUCCESS = 'SERVICE_CALL_LOAD_DETAILS_SUCCESS',
    LOAD_DETAILS_FAILURE = 'SERVICE_CALL_LOAD_DETAILS_FAILURE',

    LOAD_ACTIVITIES_REQUEST = 'SERVICE_CALL_LOAD_ACTIVITIES_REQUEST',
    LOAD_ACTIVITIES_SUCCESS = 'SERVICE_CALL_LOAD_ACTIVITIES_SUCCESS',
    LOAD_ACTIVITIES_FAILURE = 'SERVICE_CALL_LOAD_ACTIVITIES_FAILURE',

    UPLOAD_VIDEOS_REQUEST = 'SERVICE_CALL_UPLOAD_VIDEOS_REQUEST',
    UPLOAD_VIDEOS_SUCCESS = 'SERVICE_CALL_UPLOAD_VIDEOS_SUCCESS',
    UPLOAD_VIDEOS_FAILURE = 'SERVICE_CALL_UPLOAD_VIDEOS_FAILURE',

    UPLOAD_PICTURES_REQUEST = 'SERVICE_CALL_UPLOAD_PICTURES_REQUEST',
    UPLOAD_PICTURES_SUCCESS = 'SERVICE_CALL_UPLOAD_PICTURES_SUCCESS',
    UPLOAD_PICTURES_FAILURE = 'SERVICE_CALL_UPLOAD_PICTURES_FAILURE',

    UPLOAD_DOCUMENTS_REQUEST = 'SERVICE_CALL_UPLOAD_DOCUMENTS_REQUEST',
    UPLOAD_DOCUMENTS_SUCCESS = 'SERVICE_CALL_UPLOAD_DOCUMENTS_SUCCESS',
    UPLOAD_DOCUMENTS_FAILURE = 'SERVICE_CALL_UPLOAD_DOCUMENTS_FAILURE',

    DELETE_ATTACHMENT_REQUEST = 'SERVICE_CALL_DELETE_ATTACHMENT_REQUEST',
    DELETE_ATTACHMENT_SUCCESS = 'SERVICE_CALL_DELETE_ATTACHMENT_SUCCESS',
    DELETE_ATTACHMENT_FAILURE = 'SERVICE_CALL_DELETE_ATTACHMENT_FAILURE',

    LOAD_MINE_REQUEST = 'SERVICE_CALLS_LOAD_MY_REQUEST',
    LOAD_MINE_SUCCESS = 'SERVICE_CALLS_LOAD_MY_SUCCESS',
    LOAD_MINE_FAILURE = 'SERVICE_CALLS_LOAD_MY_FAILURE',

    LOAD_STALE_REQUEST = 'SERVICE_CALLS_LOAD_STALE_REQUEST',
    LOAD_STALE_SUCCESS = 'SERVICE_CALLS_LOAD_STALE_SUCCESS',
    LOAD_STALE_FAILURE = 'SERVICE_CALLS_LOAD_STALE_FAILURE',

    LOAD_OPENED_REQUEST = 'SERVICE_CALLS_LOAD_OPENED_REQUEST',
    LOAD_OPENED_SUCCESS = 'SERVICE_CALLS_LOAD_OPENED_SUCCESS',
    LOAD_OPENED_FAILURE = 'SERVICE_CALLS_LOAD_OPENED_FAILURE',

    LOAD_CUSTOM_REQUEST = 'SERVICE_CALLS_LOAD_CUSTOM_REQUEST',
    LOAD_CUSTOM_SUCCESS = 'SERVICE_CALLS_LOAD_CUSTOM_SUCCESS',
    LOAD_CUSTOM_FAILURE = 'SERVICE_CALLS_LOAD_CUSTOM_FAILURE',

    LOAD_HISTORY_REQUEST = 'SERVICE_CALLS_LOAD_HISTORY_REQUEST',
    LOAD_HISTORY_SUCCESS = 'SERVICE_CALLS_LOAD_HISTORY_SUCCESS',
    LOAD_HISTORY_FAILURE = 'SERVICE_CALLS_LOAD_HISTORY_FAILURE',

    ASSIGN_AGENT_REQUEST = 'SERVICE_CALL_ASSIGN_AGENT_REQUEST',
    ASSIGN_AGENT_SUCCESS = 'SERVICE_CALL_ASSIGN_AGENT_SUCCESS',
    ASSIGN_AGENT_FAILURE = 'SERVICE_CALL_ASSIGN_AGENT_FAILURE',

    ASSIGN_REPRESENTATIVE_REQUEST = 'SERVICE_CALL_ASSIGN_REPRESENTATIVE_REQUEST',
    ASSIGN_REPRESENTATIVE_SUCCESS = 'SERVICE_CALL_ASSIGN_REPRESENTATIVE_SUCCESS',
    ASSIGN_REPRESENTATIVE_FAILURE = 'SERVICE_CALL_ASSIGN_REPRESENTATIVE_FAILURE',

    CONFIRM_INSPECTION_COMPLETION_REQUEST = 'SERVICE_CALL_CONFIRM_INSPECTION_COMPLETION_REQUEST',
    CONFIRM_INSPECTION_COMPLETION_SUCCESS = 'SERVICE_CALL_CONFIRM_INSPECTION_COMPLETION_SUCCESS',
    CONFIRM_INSPECTION_COMPLETION_FAILURE = 'SERVICE_CALL_CONFIRM_INSPECTION_COMPLETION_FAILURE',

    CREATE_CREDIT_RETURN_REQUEST = 'SERVICE_CALL_CREATE_CREDIT_RETURN_REQUEST',
    CREATE_CREDIT_RETURN_SUCCESS = 'SERVICE_CALL_CREATE_CREDIT_RETURN_SUCCESS',
    CREATE_CREDIT_RETURN_FAILURE = 'SERVICE_CALL_CREATE_CREDIT_RETURN_FAILURE',

    CONFIRM_CREDIT_RETURN_FURNITURE_RECEPTION_REQUEST = 'SERVICE_CALL_CONFIRM_CREDIT_RETURN_FURNITURE_RECEPTION_REQUEST',
    CONFIRM_CREDIT_RETURN_FURNITURE_RECEPTION_SUCCESS = 'SERVICE_CALL_CONFIRM_CREDIT_RETURN_FURNITURE_RECEPTION_SUCCESS',
    CONFIRM_CREDIT_RETURN_FURNITURE_RECEPTION_FAILURE = 'SERVICE_CALL_CONFIRM_CREDIT_RETURN_FURNITURE_RECEPTION_FAILURE',

    ADD_CREDIT_RETURN_CREDIT_REQUEST = 'SERVICE_CALL_ADD_CREDIT_RETURN_CREDIT_REQUEST',
    ADD_CREDIT_RETURN_CREDIT_SUCCESS = 'SERVICE_CALL_ADD_CREDIT_RETURN_CREDIT_SUCCESS',
    ADD_CREDIT_RETURN_CREDIT_FAILURE = 'SERVICE_CALL_ADD_CREDIT_RETURN_CREDIT_FAILURE',

    CREATE_FACTORY_REPAIRS_REQUEST = 'SERVICE_CALL_CREATE_FACTORY_REPAIRS_REQUEST',
    CREATE_FACTORY_REPAIRS_SUCCESS = 'SERVICE_CALL_CREATE_FACTORY_REPAIRS_SUCCESS',
    CREATE_FACTORY_REPAIRS_FAILURE = 'SERVICE_CALL_CREATE_FACTORY_REPAIRS_FAILURE',

    CREATE_SUBCONTRACTOR_REPAIRS_REQUEST = 'SERVICE_CALL_CREATE_SUBCONTRACTOR_REPAIRS_REQUEST',
    CREATE_SUBCONTRACTOR_REPAIRS_SUCCESS = 'SERVICE_CALL_CREATE_SUBCONTRACTOR_REPAIRS_SUCCESS',
    CREATE_SUBCONTRACTOR_REPAIRS_FAILURE = 'SERVICE_CALL_CREATE_SUBCONTRACTOR_REPAIRS_FAILURE',

    CREATE_TECHNICIAN_REPAIRS_REQUEST = 'SERVICE_CALL_CREATE_TECHNICIAN_REPAIRS_REQUEST',
    CREATE_TECHNICIAN_REPAIRS_SUCCESS = 'SERVICE_CALL_CREATE_TECHNICIAN_REPAIRS_SUCCESS',
    CREATE_TECHNICIAN_REPAIRS_FAILURE = 'SERVICE_CALL_CREATE_TECHNICIAN_REPAIRS_FAILURE',

    CREATE_TECHNICIAN_APPOINTMENT_REQUEST = 'SERVICE_CALL_CREATE_TECHNICIAN_APPOINTMENT_REQUEST',
    CREATE_TECHNICIAN_APPOINTMENT_SUCCESS = 'SERVICE_CALL_CREATE_TECHNICIAN_APPOINTMENT_SUCCESS',
    CREATE_TECHNICIAN_APPOINTMENT_FAILURE = 'SERVICE_CALL_CREATE_TECHNICIAN_APPOINTMENT_FAILURE',

    CONFIRM_TECHNICIAN_REPAIRS_COMPLETION_REQUEST = 'SERVICE_CALL_CONFIRM_TECHNICIAN_REPAIRS_COMPLETION_REQUEST',
    CONFIRM_TECHNICIAN_REPAIRS_COMPLETION_SUCCESS = 'SERVICE_CALL_CONFIRM_TECHNICIAN_REPAIRS_COMPLETION_SUCCESS',
    CONFIRM_TECHNICIAN_REPAIRS_COMPLETION_FAILURE = 'SERVICE_CALL_CONFIRM_TECHNICIAN_REPAIRS_COMPLETION_FAILURE',

    CANCEL_CURRENT_REPAIRS_REQUEST = 'SERVICE_CALL_CANCEL_CURRENT_REPAIRS_REQUEST',
    CANCEL_CURRENT_REPAIRS_SUCCESS = 'SERVICE_CALL_CANCEL_CURRENT_REPAIRS_SUCCESS',
    CANCEL_CURRENT_REPAIRS_FAILURE = 'SERVICE_CALL_CANCEL_CURRENT_REPAIRS_FAILURE',

    ADD_NOTE_REQUEST = 'SERVICE_CALL_ADD_NOTE_REQUEST',
    ADD_NOTE_SUCCESS = 'SERVICE_CALL_ADD_NOTE_SUCCESS',
    ADD_NOTE_FAILURE = 'SERVICE_CALL_ADD_NOTE_FAILURE',

    DELETE_NOTE_REQUEST = 'SERVICE_CALL_DELETE_NOTE_REQUEST',
    DELETE_NOTE_SUCCESS = 'SERVICE_CALL_DELETE_NOTE_SUCCESS',
    DELETE_NOTE_FAILURE = 'SERVICE_CALL_DELETE_NOTE_FAILURE',

    CHANGE_NOTE_VISIBILITY_REQUEST = 'SERVICE_CALL_CHANGE_NOTE_VISIBILITY_REQUEST',
    CHANGE_NOTE_VISIBILITY_SUCCESS = 'SERVICE_CALL_CHANGE_NOTE_VISIBILITY_SUCCESS',
    CHANGE_NOTE_VISIBILITY_FAILURE = 'SERVICE_CALL_CHANGE_NOTE_VISIBILITY_FAILURE',

    ADD_DEFECT_REQUEST = 'ADD_DEFECT_REQUEST',
    ADD_DEFECT_SUCCESS = 'ADD_DEFECT_SUCCESS',
    ADD_DEFECT_FAILURE = 'ADD_DEFECT_FAILURE',

    DELETE_DEFECT_REQUEST = 'DELETE_DEFECT_REQUEST',
    DELETE_DEFECT_SUCCESS = 'DELETE_DEFECT_SUCCESS',
    DELETE_DEFECT_FAILURE = 'DELETE_DEFECT_FAILURE',

    ADD_DEFECTIVE_ITEM_REQUEST = 'ADD_DEFECTIVE_ITEM_REQUEST',
    ADD_DEFECTIVE_ITEM_SUCCESS = 'ADD_DEFECTIVE_ITEM_SUCCESS',
    ADD_DEFECTIVE_ITEM_FAILURE = 'ADD_DEFECTIVE_ITEM_FAILURE',

    DELETE_DEFECTIVE_ITEM_REQUEST = 'DELETE_DEFECTIVE_ITEM_REQUEST',
    DELETE_DEFECTIVE_ITEM_SUCCESS = 'DELETE_DEFECTIVE_ITEM_SUCCESS',
    DELETE_DEFECTIVE_ITEM_FAILURE = 'DELETE_DEFECTIVE_ITEM_FAILURE',

    CHANGE_DEFECTIVE_ITEMS_REQUEST = 'CHANGE_DEFECTIVE_ITEMS_REQUEST',
    CHANGE_DEFECTIVE_ITEMS_SUCCESS = 'CHANGE_DEFECTIVE_ITEMS_SUCCESS',
    CHANGE_DEFECTIVE_ITEMS_FAILURE = 'CHANGE_DEFECTIVE_ITEMS_FAILURE',

    UPDATE_REPAIR_FEES_ESTIMATE_REQUEST = 'SERVICE_CALL_UPDATE_REPAIR_FEES_ESTIMATE_REQUEST',
    UPDATE_REPAIR_FEES_ESTIMATE_SUCCESS = 'SERVICE_CALL_UPDATE_REPAIR_FEES_ESTIMATE_SUCCESS',
    UPDATE_REPAIR_FEES_ESTIMATE_FAILURE = 'SERVICE_CALL_UPDATE_REPAIR_FEES_ESTIMATE_FAILURE',

    CLOSE_REQUEST = 'SERVICE_CALL_CLOSE_REQUEST',
    CLOSE_SUCCESS = 'SERVICE_CALL_CLOSE_SUCCESS',
    CLOSE_FAILURE = 'SERVICE_CALL_CLOSE_FAILURE',

    REOPEN_REQUEST = 'SERVICE_CALL_REOPEN_REQUEST',
    REOPEN_SUCCESS = 'SERVICE_CALL_REOPEN_SUCCESS',
    REOPEN_FAILURE = 'SERVICE_CALL_REOPEN_FAILURE',

    CANCEL_REQUEST = 'SERVICE_CALL_CANCEL_REQUEST',
    CANCEL_SUCCESS = 'SERVICE_CALL_CANCEL_SUCCESS',
    CANCEL_FAILURE = 'SERVICE_CALL_CANCEL_FAILURE',

    LINK_NEW_REQUEST = 'SERVICE_CALL_LINK_NEW_REQUEST',
    LINK_NEW_SUCCESS = 'SERVICE_CALL_LINK_NEW_SUCCESS',
    LINK_NEW_FAILURE = 'SERVICE_CALL_LINK_NEW_FAILURE',

    APPLY_OPENED_FILTERS = 'SERVICE_CALLS_APPLY_OPENED_FILTERS',
    APPLY_HISTORY_FILTERS = 'SERVICE_CALLS_APPLY_HISTORY_FILTERS',
    APPLY_MINE_FILTERS = 'SERVICE_CALLS_APPLY_MINE_FILTERS',
    APPLY_CUSTOM_FILTERS = 'SERVICE_CALLS_APPLY_CUSTOM_FILTERS',

    APPLY_OPENED_SORTINGS = 'SERVICE_CALLS_APPLY_OPENED_SORTINGS',
    APPLY_HISTORY_SORTINGS = 'SERVICE_CALLS_APPLY_HISTORY_SORTINGS',
    APPLY_MINE_SORTINGS = 'SERVICE_CALLS_APPLY_MINE_SORTINGS',
    APPLY_STALE_SORTINGS = 'SERVICE_CALLS_APPLY_STALE_SORTINGS',
    APPLY_CUSTOM_SORTINGS = 'SERVICE_CALLS_APPLY_CUSTOM_SORTINGS',

    LOAD_SUBCONTRACTORS_REQUEST = 'SERVICE_CALLS_LOAD_SUBCONTRACTORS_REQUEST',
    LOAD_SUBCONTRACTORS_SUCCESS = 'SERVICE_CALLS_LOAD_SUBCONTRACTORS_SUCCESS',
    LOAD_SUBCONTRACTORS_FAILURE = 'SERVICE_CALLS_LOAD_SUBCONTRACTORS_FAILURE',

    ADD_SUBCONTRACTOR_BILL_REQUEST = 'SERVICE_CALLS_ADD_SUBCONTRACTOR_BILL_REQUEST',
    ADD_SUBCONTRACTOR_BILL_SUCCESS = 'SERVICE_CALLS_ADD_SUBCONTRACTOR_BILL_SUCCESS',
    ADD_SUBCONTRACTOR_BILL_FAILURE = 'SERVICE_CALLS_ADD_SUBCONTRACTOR_BILL_FAILURE',

    SET_SUBCONTRACTOR_REPAIR_WARRANTY_STATUS_REQUEST = 'SERVICE_CALLS_SET_SUBCONTRACTOR_REPAIR_WARRANTY_STATUS_REQUEST',
    SET_SUBCONTRACTOR_REPAIR_WARRANTY_STATUS_SUCCESS = 'SERVICE_CALLS_SET_SUBCONTRACTOR_REPAIR_WARRANTY_STATUS_SUCCESS',
    SET_SUBCONTRACTOR_REPAIR_WARRANTY_STATUS_FAILURE = 'SERVICE_CALLS_SET_SUBCONTRACTOR_REPAIR_WARRANTY_STATUS_FAILURE',

    SET_SUBCONTRACTOR_APPOINTMENT_DATE_REQUEST = 'SERVICE_CALLS_SET_SUBCONTRACTOR_APPOINTMENT_DATE_REQUEST',
    SET_SUBCONTRACTOR_APPOINTMENT_DATE_SUCCESS = 'SERVICE_CALLS_SET_SUBCONTRACTOR_APPOINTMENT_DATE_SUCCESS',
    SET_SUBCONTRACTOR_APPOINTMENT_DATE_FAILURE = 'SERVICE_CALLS_SET_SUBCONTRACTOR_APPOINTMENT_DATE_FAILURE',

    CONFIRM_FURNITURE_RECEPTION_REQUEST = 'SERVICE_CALLS_CONFIRM_FURNITURE_RECEPTION_REQUEST',
    CONFIRM_FURNITURE_RECEPTION_SUCCESS = 'SERVICE_CALLS_CONFIRM_FURNITURE_RECEPTION_SUCCESS',
    CONFIRM_FURNITURE_RECEPTION_FAILURE = 'SERVICE_CALLS_CONFIRM_FURNITURE_RECEPTION_FAILURE',

    SCHEDULE_FACTORY_REPAIRS_REQUEST = 'SERVICE_CALLS_SCHEDULE_FACTORY_REPAIRS_REQUEST',
    SCHEDULE_FACTORY_REPAIRS_SUCCESS = 'SERVICE_CALLS_SCHEDULE_FACTORY_REPAIRS_SUCCESS',
    SCHEDULE_FACTORY_REPAIRS_FAILURE = 'SERVICE_CALLS_SCHEDULE_FACTORY_REPAIRS_FAILURE',

    CONFIRM_FACTORY_REPAIRS_COMPLETION_REQUEST = 'SERVICE_CALLS_CONFIRM_FACTORY_REPAIRS_COMPLETION_REQUEST',
    CONFIRM_FACTORY_REPAIRS_COMPLETION_SUCCESS = 'SERVICE_CALLS_CONFIRM_FACTORY_REPAIRS_COMPLETION_SUCCESS',
    CONFIRM_FACTORY_REPAIRS_COMPLETION_FAILURE = 'SERVICE_CALLS_CONFIRM_FACTORY_REPAIRS_COMPLETION_FAILURE',
}