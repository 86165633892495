import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';

import SmallEditButton from './SmallEditButton';
import SmallCancelButton from './SmallCancelButton';
import SmallSaveButton from './SmallSaveButton';
import { commonMessages } from '../../constants';

interface EditButtonsOwnProps {
    onEdit?: () => void;
    onCancelEdit?: () => void;
    onSaveEdit?: () => boolean;
}

export type EditButtonsProps =
    & EditButtonsOwnProps
    & WrappedComponentProps;

interface EditButtonsState {
    isEditing: boolean;
}

const m = defineMessages({
});

class EditButtons extends React.Component<EditButtonsProps, EditButtonsState> {
    public constructor(props: EditButtonsProps) {
        super(props);

        this.state = { isEditing: false };
    }

    public render() {
        const { formatMessage } = this.props.intl;

        return !this.state.isEditing
            ? <SmallEditButton title={formatMessage(commonMessages.modify)} onClick={this.startEdit} />
            : (
                <React.Fragment>
                    <SmallCancelButton title={formatMessage(commonMessages.cancel)} onClick={this.cancelEdit} />
                    <SmallSaveButton title={formatMessage(commonMessages.save)} onClick={this.saveEdit} />
                </React.Fragment>
            );
    }

    private startEdit = () => {
        this.setState({ isEditing: true });

        if (this.props.onEdit) {
            this.props.onEdit();
        }
    }

    private cancelEdit = () => {
        this.setState({ isEditing: false });

        if (this.props.onCancelEdit) {
            this.props.onCancelEdit();
        }
    }

    private saveEdit = () => {
        if (this.props.onSaveEdit) {
            const isValid = this.props.onSaveEdit();

            if (isValid) {
                this.setState({ isEditing: false });
            }
        } else {
            this.setState({ isEditing: false });
        }
    }
}

const connectedComponent = injectIntl(EditButtons);
export { connectedComponent as EditButtons };