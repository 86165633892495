import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { OrderDetail } from "../../../../state/models/OrderDetail";
import { Header, Label, Menu } from "semantic-ui-react";
import {
  getItemLocalizedDescription,
  ProductionOrder,
  formatItemId,
} from "../../../../state/models";
import { Order } from "../../../../state/models/Order";
import { QualityControlGallery } from "./QualityControlGallery";
import { OrderItemViewDetails } from "./OrderItemViewDetails";
import { useQualityControlImages } from "./useQualityControlImages";
import { OrderDetailIcon } from "./OrderDetailIcon";
import { commonMessages } from "../../../../constants";

const m = defineMessages({
  itemTitle: { id: "OrderItemView.itemTitle", defaultMessage: "{code} {name}" },
  coverTitle: { id: "OrderItemView.coverTitle", defaultMessage: "Cover: {code} {name}" },
  tabDetails: { id: "OrderItemView.Tabs.Details", defaultMessage: "Details" },
  tabQualityControlPhotos: {
    id: "OrderItemView.Tabs.QualityControlPhotos",
    defaultMessage: "Quality control photos",
  },
});

enum OrderItemViewTab {
  Details,
  QualityControlPhotos,
}

interface OrderItemViewProps {
  order: Order;
  item: OrderDetail;
  productionOrder: ProductionOrder | undefined;
}

export const OrderItemView: React.FC<OrderItemViewProps> = ({
  order,
  item,
  productionOrder,
}) => {
  const { formatMessage, locale } = useIntl();
  const [selectedTab, setSelectedTab] = React.useState(OrderItemViewTab.Details);
  const { medias, isLoading } = useQualityControlImages(productionOrder?.id);
  const photoCount = medias.length;

  const header = (
    <Header size='small' color='blue'>
      <OrderDetailIcon type={item.type} />
      <Header.Content>
        {formatMessage(m.itemTitle, {
          code:
            formatItemId(item.itemId) ||
            formatMessage(commonMessages.notApplicableAbbreviation),
          name: getItemLocalizedDescription(item.item, locale),
        })}
        <Header.Subheader>
          {formatMessage(m.coverTitle, {
            code:
              formatItemId(item.itemCoverId) ||
              formatMessage(commonMessages.notApplicableAbbreviation),
            name: getItemLocalizedDescription(item.itemCover, locale),
          })}
        </Header.Subheader>
      </Header.Content>
    </Header>
  );

  const tabs = (
    <Menu
      pointing
      secondary
      className='tw-mb-8 tw-border-t-0 tw-border-l-0 tw-border-r-0'>
      <Menu.Item
        active={selectedTab === OrderItemViewTab.Details}
        onClick={() => setSelectedTab(OrderItemViewTab.Details)}>
        {formatMessage(m.tabDetails)}
      </Menu.Item>
      <Menu.Item
        active={selectedTab === OrderItemViewTab.QualityControlPhotos}
        onClick={() => setSelectedTab(OrderItemViewTab.QualityControlPhotos)}>
        {formatMessage(m.tabQualityControlPhotos)}
        {!isLoading && <Label content={photoCount} size="small" color={photoCount > 0 ? 'teal' : undefined} />}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {header}
      {tabs}

      {selectedTab === OrderItemViewTab.Details && (
        <OrderItemViewDetails order={order} item={item} productionOrder={productionOrder} />
      )}

      {selectedTab === OrderItemViewTab.QualityControlPhotos && (
        <QualityControlGallery medias={medias} isLoading={isLoading} />
      )}
    </>
  );
};
