import React from "react";
import { Redirect, useLocation } from "react-router";
import { defineMessages, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { LoginForm } from './components/LoginForm';
import { ForgotPasswordLink } from './components/ForgotPasswordLink';
import { LoginEdit } from "../models";
import { actionCreators as AccountActions } from '../actions';
import { isAuthenticated } from "../selectors";
import { AccountPageLayout } from "./components/AccountPageLayout";

const m = defineMessages({
    title: { id: 'LoginHeader.title', defaultMessage: 'Connection to your secure portal' }
});

export const LoginPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const location = useLocation();

    const { from } = (location.state as any) || { from: { pathname: '/' } };

    const handleLogin = (data: LoginEdit) => dispatch(AccountActions.login(data));
    const isUserAuthenticated = useSelector(isAuthenticated);

    if (isUserAuthenticated) {
        return <Redirect to={from} />;
    }

    return (
        <AccountPageLayout title={formatMessage(m.title)}>
            <LoginForm onLogin={handleLogin} />
            <ForgotPasswordLink />
        </AccountPageLayout>
    );
};