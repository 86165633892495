export enum ServiceCallStatus {
    unknown,
    created,
    assignedToAgent,
    awaitingInspection,
    inspected,
    awaitingFactoryRepairs,
    awaitingSubcontractorRepairs,
    awaitingTechnicianRepairs,
    repaired,
    shipped,
    closed,
    draft,
}

export const allServiceCallStatus: ServiceCallStatus[] = [
    ServiceCallStatus.created,
    ServiceCallStatus.assignedToAgent,
    ServiceCallStatus.awaitingInspection,
    ServiceCallStatus.inspected,
    ServiceCallStatus.awaitingFactoryRepairs,
    ServiceCallStatus.awaitingSubcontractorRepairs,
    ServiceCallStatus.awaitingTechnicianRepairs,
    ServiceCallStatus.repaired,
    ServiceCallStatus.shipped,
    ServiceCallStatus.closed,
    ServiceCallStatus.draft,
];