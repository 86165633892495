import { FormattedMessage, defineMessages, MessageDescriptor } from 'react-intl';

export interface ProvinceDescriptor {
    key: string;
    value: string;
    intlMessage: MessageDescriptor;
}

const m = defineMessages({
    ca_qc: { id: 'province-canada.qc', defaultMessage: 'Quebec' },
    ca_on: { id: 'province-canada.on', defaultMessage: 'Ontario' },
    ca_nb: { id: 'province-canada.nb', defaultMessage: 'New Brunswick' },
    ca_ns: { id: 'province-canada.ns', defaultMessage: 'Nova Scotia' },
    ca_pe: { id: 'province-canada.pe', defaultMessage: 'Prince Edward Island' },
    ca_nl: { id: 'province-canada.nl', defaultMessage: 'Newfoundland and Labrador' },
    ca_mb: { id: 'province-canada.mb', defaultMessage: 'Manitoba' },
    ca_sk: { id: 'province-canada.sk', defaultMessage: 'Saskatchewan' },
    ca_ab: { id: 'province-canada.ab', defaultMessage: 'Alberta' },
    ca_bc: { id: 'province-canada.bc', defaultMessage: 'British Columbia' },
    ca_yt: { id: 'province-canada.yt', defaultMessage: 'Yukon' },
    ca_nt: { id: 'province-canada.nt', defaultMessage: 'Northwest Territories' },
    ca_nu: { id: 'province-canada.nu', defaultMessage: 'Nunavut' },

    us_aa: { id: 'province-usa.aa', defaultMessage: 'Armed Forces America' },
    us_ae: { id: 'province-usa.ae', defaultMessage: 'Armed Forces Europe, Middle East, & Canada' },
    us_ak: { id: 'province-usa.ak', defaultMessage: 'Alaska' },
    us_al: { id: 'province-usa.al', defaultMessage: 'Alabama' },
    us_ap: { id: 'province-usa.ap', defaultMessage: 'Armed Forces Pacific' },
    us_ar: { id: 'province-usa.ar', defaultMessage: 'Arkansas' },
    us_as: { id: 'province-usa.as', defaultMessage: 'American Samoa' },
    us_az: { id: 'province-usa.az', defaultMessage: 'Arizona' },
    us_ca: { id: 'province-usa.ca', defaultMessage: 'California' },
    us_co: { id: 'province-usa.co', defaultMessage: 'Colorado' },
    us_ct: { id: 'province-usa.ct', defaultMessage: 'Connecticut' },
    us_dc: { id: 'province-usa.dc', defaultMessage: 'District of Columbia' },
    us_de: { id: 'province-usa.de', defaultMessage: 'Delaware' },
    us_fl: { id: 'province-usa.fl', defaultMessage: 'Florida' },
    us_fm: { id: 'province-usa.fm', defaultMessage: 'Federated States of Micronesia' },
    us_ga: { id: 'province-usa.ga', defaultMessage: 'Georgia' },
    us_gu: { id: 'province-usa.gu', defaultMessage: 'Guam' },
    us_hi: { id: 'province-usa.hi', defaultMessage: 'Hawaii' },
    us_ia: { id: 'province-usa.ia', defaultMessage: 'Iowa' },
    us_id: { id: 'province-usa.id', defaultMessage: 'Idaho' },
    us_il: { id: 'province-usa.il', defaultMessage: 'Illinois' },
    us_in: { id: 'province-usa.in', defaultMessage: 'Indiana' },
    us_ks: { id: 'province-usa.ks', defaultMessage: 'Kansas' },
    us_ky: { id: 'province-usa.ky', defaultMessage: 'Kentucky' },
    us_la: { id: 'province-usa.la', defaultMessage: 'Louisiana' },
    us_ma: { id: 'province-usa.ma', defaultMessage: 'Massachusetts' },
    us_md: { id: 'province-usa.md', defaultMessage: 'Maryland' },
    us_me: { id: 'province-usa.me', defaultMessage: 'Maine' },
    us_mh: { id: 'province-usa.mh', defaultMessage: 'Marshall Islands' },
    us_mi: { id: 'province-usa.mi', defaultMessage: 'Michigan' },
    us_mn: { id: 'province-usa.mn', defaultMessage: 'Minnesota' },
    us_mo: { id: 'province-usa.mo', defaultMessage: 'Missouri' },
    us_mp: { id: 'province-usa.mp', defaultMessage: 'Northern Mariana Islands' },
    us_ms: { id: 'province-usa.ms', defaultMessage: 'Mississippi' },
    us_mt: { id: 'province-usa.mt', defaultMessage: 'Montana' },
    us_nc: { id: 'province-usa.nc', defaultMessage: 'North Carolina' },
    us_nd: { id: 'province-usa.nd', defaultMessage: 'North Dakota' },
    us_ne: { id: 'province-usa.ne', defaultMessage: 'Nebraska' },
    us_nh: { id: 'province-usa.nh', defaultMessage: 'New Hampshire' },
    us_nj: { id: 'province-usa.nj', defaultMessage: 'New Jersey' },
    us_nm: { id: 'province-usa.nm', defaultMessage: 'New Mexico' },
    us_nv: { id: 'province-usa.nv', defaultMessage: 'Nevada' },
    us_ny: { id: 'province-usa.ny', defaultMessage: 'New York' },
    us_oh: { id: 'province-usa.oh', defaultMessage: 'Ohio' },
    us_ok: { id: 'province-usa.ok', defaultMessage: 'Oklahoma' },
    us_or: { id: 'province-usa.or', defaultMessage: 'Oregon' },
    us_pa: { id: 'province-usa.pa', defaultMessage: 'Pennsylvania' },
    us_pr: { id: 'province-usa.pr', defaultMessage: 'Puerto Rico' },
    us_pw: { id: 'province-usa.pw', defaultMessage: 'Palau' },
    us_ri: { id: 'province-usa.ri', defaultMessage: 'Rhode Island' },
    us_sc: { id: 'province-usa.sc', defaultMessage: 'South Carolina' },
    us_sd: { id: 'province-usa.sd', defaultMessage: 'South Dakota' },
    us_tn: { id: 'province-usa.tn', defaultMessage: 'Tennessee' },
    us_tx: { id: 'province-usa.tx', defaultMessage: 'Texas' },
    us_ut: { id: 'province-usa.ut', defaultMessage: 'Utah' },
    us_va: { id: 'province-usa.va', defaultMessage: 'Virginia' },
    us_vi: { id: 'province-usa.vi', defaultMessage: 'Virgin Islands' },
    us_vt: { id: 'province-usa.vt', defaultMessage: 'Vermont' },
    us_wa: { id: 'province-usa.wa', defaultMessage: 'Washington' },
    us_wv: { id: 'province-usa.wv', defaultMessage: 'West Virginia' },
    us_wi: { id: 'province-usa.wi', defaultMessage: 'Wisconsin' },
    us_wy: { id: 'province-usa.wy', defaultMessage: 'Wyoming' },

    au_ct: { id: 'province-au.ct', defaultMessage: 'Australian Capital Territory'},
    au_nsw: { id: 'province-au.nsw', defaultMessage: 'New South Wales'},
    au_nt: { id: 'province-au.nt', defaultMessage: 'Northern Territory'},
    au_qld: { id: 'province-au.qld', defaultMessage: 'Queensland'},
    au_sa: { id: 'province-au.sa', defaultMessage: 'South Australia'},
    au_tas: { id: 'province-au.tas', defaultMessage: 'Tasmania'},
    au_vic: { id: 'province-au.vic', defaultMessage: 'Victoria'},
    au_wa: { id: 'province-au.wa', defaultMessage: 'Western Australia'},
});

let provincesMap: Map<string, ProvinceDescriptor[]> = new Map<string, ProvinceDescriptor[]>();

provincesMap.set('ca', [
    { key: 'qc', value: 'qc', intlMessage: m.ca_qc },
    { key: 'on', value: 'on', intlMessage: m.ca_on },
    { key: 'nb', value: 'nb', intlMessage: m.ca_nb },
    { key: 'ns', value: 'ns', intlMessage: m.ca_ns },
    { key: 'pe', value: 'pe', intlMessage: m.ca_pe },
    { key: 'nl', value: 'nl', intlMessage: m.ca_nl },
    { key: 'mb', value: 'mb', intlMessage: m.ca_mb },
    { key: 'sk', value: 'sk', intlMessage: m.ca_sk },
    { key: 'ab', value: 'ab', intlMessage: m.ca_ab },
    { key: 'bc', value: 'bc', intlMessage: m.ca_bc },
    { key: 'yt', value: 'yt', intlMessage: m.ca_yt },
    { key: 'nt', value: 'nt', intlMessage: m.ca_nt },
    { key: 'nu', value: 'nu', intlMessage: m.ca_nu }
]);

provincesMap.set('us', [
    { key: 'aa', value: 'aa', intlMessage: m.us_aa },
    { key: 'ae', value: 'ae', intlMessage: m.us_ae },
    { key: 'ak', value: 'ak', intlMessage: m.us_ak },
    { key: 'al', value: 'al', intlMessage: m.us_al },
    { key: 'ap', value: 'ap', intlMessage: m.us_ap },
    { key: 'ar', value: 'ar', intlMessage: m.us_ar },
    { key: 'as', value: 'as', intlMessage: m.us_as },
    { key: 'az', value: 'az', intlMessage: m.us_az },
    { key: 'ca', value: 'ca', intlMessage: m.us_ca },
    { key: 'co', value: 'co', intlMessage: m.us_co },
    { key: 'ct', value: 'ct', intlMessage: m.us_ct },
    { key: 'dc', value: 'dc', intlMessage: m.us_dc },
    { key: 'de', value: 'de', intlMessage: m.us_de },
    { key: 'fl', value: 'fl', intlMessage: m.us_fl },
    { key: 'fm', value: 'fm', intlMessage: m.us_fm },
    { key: 'ga', value: 'ga', intlMessage: m.us_ga },
    { key: 'gu', value: 'gu', intlMessage: m.us_gu },
    { key: 'hi', value: 'hi', intlMessage: m.us_hi },
    { key: 'ia', value: 'ia', intlMessage: m.us_ia },
    { key: 'id', value: 'id', intlMessage: m.us_id },
    { key: 'il', value: 'il', intlMessage: m.us_il },
    { key: 'in', value: 'in', intlMessage: m.us_in },
    { key: 'ks', value: 'ks', intlMessage: m.us_ks },
    { key: 'ky', value: 'ky', intlMessage: m.us_ky },
    { key: 'la', value: 'la', intlMessage: m.us_la },
    { key: 'ma', value: 'ma', intlMessage: m.us_ma },
    { key: 'md', value: 'md', intlMessage: m.us_md },
    { key: 'me', value: 'me', intlMessage: m.us_me },
    { key: 'mh', value: 'mh', intlMessage: m.us_mh },
    { key: 'mi', value: 'mi', intlMessage: m.us_mi },
    { key: 'mn', value: 'mn', intlMessage: m.us_mn },
    { key: 'mo', value: 'mo', intlMessage: m.us_mo },
    { key: 'mp', value: 'mp', intlMessage: m.us_mp },
    { key: 'ms', value: 'ms', intlMessage: m.us_ms },
    { key: 'mt', value: 'mt', intlMessage: m.us_mt },
    { key: 'nc', value: 'nc', intlMessage: m.us_nc },
    { key: 'nd', value: 'nd', intlMessage: m.us_nd },
    { key: 'ne', value: 'ne', intlMessage: m.us_ne },
    { key: 'nh', value: 'nh', intlMessage: m.us_nh },
    { key: 'nj', value: 'nj', intlMessage: m.us_nj },
    { key: 'nm', value: 'nm', intlMessage: m.us_nm },
    { key: 'nv', value: 'nv', intlMessage: m.us_nv },
    { key: 'ny', value: 'ny', intlMessage: m.us_ny },
    { key: 'oh', value: 'oh', intlMessage: m.us_oh },
    { key: 'ok', value: 'ok', intlMessage: m.us_ok },
    { key: 'or', value: 'or', intlMessage: m.us_or },
    { key: 'pa', value: 'pa', intlMessage: m.us_pa },
    { key: 'pr', value: 'pr', intlMessage: m.us_pr },
    { key: 'pw', value: 'pw', intlMessage: m.us_pw },
    { key: 'ri', value: 'ri', intlMessage: m.us_ri },
    { key: 'sc', value: 'sc', intlMessage: m.us_sc },
    { key: 'sd', value: 'sd', intlMessage: m.us_sd },
    { key: 'tn', value: 'tn', intlMessage: m.us_tn },
    { key: 'tx', value: 'tx', intlMessage: m.us_tx },
    { key: 'ut', value: 'ut', intlMessage: m.us_ut },
    { key: 'va', value: 'va', intlMessage: m.us_va },
    { key: 'vi', value: 'vi', intlMessage: m.us_vi },
    { key: 'vt', value: 'vt', intlMessage: m.us_vt },
    { key: 'wa', value: 'wa', intlMessage: m.us_wa },
    { key: 'wv', value: 'wv', intlMessage: m.us_wv },
    { key: 'wi', value: 'wi', intlMessage: m.us_wi },
    { key: 'wy', value: 'wy', intlMessage: m.us_wy },
]);

provincesMap.set('au', [
    { key: 'ct', value: 'ct', intlMessage: m.au_ct },
    { key: 'nsw', value: 'nsw', intlMessage: m.au_nsw },
    { key: 'nt', value: 'nt', intlMessage: m.au_nt },
    { key: 'qld', value: 'qld', intlMessage: m.au_qld },
    { key: 'sa', value: 'sa', intlMessage: m.au_sa },
    { key: 'tas', value: 'tas', intlMessage: m.au_tas },
    { key: 'vic', value: 'vic', intlMessage: m.au_vic },
    { key: 'wa', value: 'wa', intlMessage: m.au_wa },
]);

// We can't initialize the map using the "Map<...>([iterable])" constructor, because it isn't supported on IE 11.
export const provincesByCountryMap: Map<string, ProvinceDescriptor[]> = provincesMap;