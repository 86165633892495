import React from 'react';
import moment from 'moment';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, Card, Divider } from 'semantic-ui-react';
import { commonMessages } from '../../../constants';
import { ConfirmButton } from '../../common';

export interface FinalizeCardProps {
    isClosed: boolean;
    canCancel: boolean;
    isServiceCallReopening: boolean;
    isServiceCallClosing: boolean;
    isServiceCallCancelling: boolean;
    isServiceCallLinking: boolean;
    closedOn?: Date;
    serviceCallId?: number;
    onCloseServiceCall: () => void;
    onCancelServiceCall: () => void;
    onReopenServiceCall: () => void;
    onLinkNewServiceCall: () => void;
}

const m = defineMessages({
    title: { id: 'FinalizeServiceCallCard.title', defaultMessage: 'Finalize the service call' },
    subtitle: { id: 'FinalizeServiceCallCard.subtitle', defaultMessage: 'Perform actions to close and finalize the service call.' },
    closeServiceCallButton: { id: 'FinalizeServiceCallCard.close_service_call_button', defaultMessage: 'Close Service Call' },
    reopenServiceCallButton: { id: 'FinalizeServiceCallCard.reopen_service_call_button', defaultMessage: 'Reopen Service Call' },
    linkNewServiceCallButton: { id: 'FinalizeServiceCallCard.link_new_service_call_button', defaultMessage: 'Link New Service Call' },
    serviceCallClosedOnFormat: { id: 'FinalizeServiceCallCard.service_call_closed_on_format', defaultMessage: 'The service call was closed on {date}.' },
    cancelServiceCall: { id: 'FinalizeServiceCallCard.cancel_service_call', defaultMessage: 'Cancel Service Call' },
    confirmCancellationTitle: { id: 'FinalizeServiceCallCard.confirm_cancellation_title', defaultMessage: 'Confirm the service call cancellation' },
    confirmCancellationMessage: { id: 'FinalizeServiceCallCard.confirm_cancellation_message', defaultMessage: 'Are you sure that you want to cancel this service call?' }
});

class FinalizeCard extends React.Component<FinalizeCardProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const delayInDaysForReopening = 7;
        const IdStartValue = 2000000;
        
        const isLegacyServiceCall = this.props.serviceCallId ? (this.props.serviceCallId < IdStartValue) : false;
        const daysEllapsedSinceClosure = this.props.closedOn ? moment.utc().diff(moment.utc(this.props.closedOn), 'days') : 0;
        const canServiceCallBeReopened = this.props.closedOn ? daysEllapsedSinceClosure < delayInDaysForReopening : false;

        return (
            <Card fluid={true} color="teal" style={{ height: '100%' }}>
                <Card.Content style={{ flexGrow: 0 }}>
                    <Card.Header content={formatMessage(m.title)} />
                    <Card.Meta content={formatMessage(m.subtitle)} />
                </Card.Content>
                <Card.Content>
                    {this.props.closedOn != null &&
                        <Button
                            primary={true}
                            disabled={this.props.isServiceCallReopening || !canServiceCallBeReopened}
                            onClick={this.props.onReopenServiceCall}
                            fluid={true}
                            loading={this.props.isServiceCallReopening}
                            icon="redo"
                            content={formatMessage(m.reopenServiceCallButton)}
                        />
                    }
                    {this.props.closedOn == null &&
                        <Button
                            primary={true}
                            disabled={(isLegacyServiceCall) || this.props.isServiceCallClosing}
                            onClick={this.props.onCloseServiceCall}
                            fluid={true}
                            loading={this.props.isServiceCallClosing}
                            icon="check"
                            content={formatMessage(m.closeServiceCallButton)}
                        />
                    }
                    {this.props.isClosed && this.props.closedOn &&
                        <small>{formatMessage(m.serviceCallClosedOnFormat, { date: moment.utc(this.props.closedOn).local().format('LLLL') })}</small>

                    }
                    {this.props.canCancel &&
                        <React.Fragment>
                            <Divider />
                            <ConfirmButton
                                fluid={true}
                                negative={true}
                                disabled={!this.props.canCancel || this.props.isClosed || this.props.isServiceCallCancelling}
                                loading={this.props.isServiceCallCancelling}
                                icon="cancel"
                                content={formatMessage(m.cancelServiceCall)}
                                confirmHeader={formatMessage(m.confirmCancellationTitle)}
                                confirmContent={formatMessage(m.confirmCancellationMessage)}
                                cancelButton={formatMessage(commonMessages.cancel)}
                                confirmButton={<Button negative={true} content={formatMessage(commonMessages.confirm)} />}
                                onConfirm={this.props.onCancelServiceCall}
                            />
                        </React.Fragment>
                    }
                    <Divider />
                    <Button
                        fluid={true}
                        icon="linkify"
                        content={formatMessage(m.linkNewServiceCallButton)}
                        disabled={this.props.isServiceCallLinking}
                        loading={this.props.isServiceCallLinking}
                        onClick={this.props.onLinkNewServiceCall}
                    />
                </Card.Content>
            </Card>
        );
    }
}

const intlComponent = injectIntl(FinalizeCard);
export { intlComponent as FinalizeCard };