import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedNumber, FormattedMessage } from 'react-intl';
import { Card, Form, Label, Grid, Button, Segment, Checkbox } from 'semantic-ui-react';
import { CurrencyInput } from '../../common';
import { ServiceCallFeesEstimate } from '../../../state/models';
import { commonMessages } from '../../../constants';

interface RepairFeesCardOwnProps {
    repairFeesEstimate: ServiceCallFeesEstimate;
    onRepairFeesChange: (repairFees: ServiceCallFeesEstimate) => void;
}

export type RepairFeesCardProps =
    & RepairFeesCardOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'RepairFeesCard.title', defaultMessage: 'Repair fees estimate' },
    subtitle: { id: 'RepairFeesCard.subtitle', defaultMessage: 'Provide an estimate for the costs of repairs' },
    partsCostsLabel: { id: 'RepairFeesCard.parts_costs_label', defaultMessage: 'Parts costs'},
    labourCostsLabel: { id: 'RepairFeesCard.labour_costs_label', defaultMessage: 'Labour costs'},
    shippingCostsLabel: { id: 'RepairFeesCard.shipping_costs_label', defaultMessage: 'Shipping costs'},
    totalLabel: { id: 'RepairFeesCard.total_label', defaultMessage: 'Total'},
});

class RepairFeesCard extends React.Component<RepairFeesCardProps, {}> {
    public render() {
        const { formatMessage, formatNumber } = this.props.intl;
        const { repairFeesEstimate } = this.props;
        const totalFees = repairFeesEstimate ? repairFeesEstimate.partsFees + repairFeesEstimate.labourFees + repairFeesEstimate.shippingFees : 0;

        return (
            <Card color="yellow" fluid={true} style={{height: '100%'}}>
                <Card.Content style={{flexGrow: 0}}>
                    <Card.Header content={formatMessage(m.title)} />
                    <Card.Meta content={formatMessage(m.subtitle)} />
                </Card.Content>
                <Card.Content>
                    {this.props.repairFeesEstimate &&
                        <Form>
                            <Form.Field style={{maxWidth: 300}}>
                                <label>{formatMessage(m.partsCostsLabel)}</label>
                                <CurrencyInput
                                    disabled={this.props.repairFeesEstimate.isApproved}
                                    value={repairFeesEstimate.partsFees} 
                                    currency={repairFeesEstimate.currencyCode} 
                                    onChange={this.handlePartsCostsChange} 
                                />
                            </Form.Field>
                            <Form.Field style={{maxWidth: 300}}>
                                <label>{formatMessage(m.labourCostsLabel)}</label>
                                <CurrencyInput 
                                    disabled={this.props.repairFeesEstimate.isApproved}
                                    value={repairFeesEstimate.labourFees} 
                                    currency={repairFeesEstimate.currencyCode} 
                                    onChange={this.handleLabourCostsChange} 
                                />
                            </Form.Field>
                            <Form.Field style={{maxWidth: 300}}>
                                <label>{formatMessage(m.shippingCostsLabel)}</label>
                                <CurrencyInput 
                                    disabled={this.props.repairFeesEstimate.isApproved}
                                    value={repairFeesEstimate.shippingFees} 
                                    currency={repairFeesEstimate.currencyCode} 
                                    onChange={this.handleShippingCostsChange} 
                                />
                            </Form.Field>

                            <Form.Field style={{maxWidth: 300}}>
                                <label>{formatMessage(m.totalLabel)}</label>
                                <div style={{textAlign: 'right'}}>
                                    <FormattedNumber value={totalFees} style="currency" currency={repairFeesEstimate.currencyCode} />
                                </div>
                            </Form.Field>
                        </Form>
                    }
                </Card.Content>
            </Card>
        );
    }

    private handlePartsCostsChange = (value: number, currencyCode: string) => {
        this.props.onRepairFeesChange({
            ...this.props.repairFeesEstimate,
            partsFees: value,
            currencyCode: currencyCode
        });
    }

    private handleLabourCostsChange = (value: number, currencyCode: string) => {
        this.props.onRepairFeesChange({
            ...this.props.repairFeesEstimate,
            labourFees: value,
            currencyCode: currencyCode
        });
    }

    private handleShippingCostsChange = (value: number, currencyCode: string) => {
        this.props.onRepairFeesChange({
            ...this.props.repairFeesEstimate,
            shippingFees: value,
            currencyCode: currencyCode
        });
    }

}

const connectedComponent = injectIntl(RepairFeesCard);
export { connectedComponent as RepairFeesCard };