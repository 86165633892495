import React from 'react';
import moment from 'moment';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { ApplicationState } from '../../../state/ducks';
import * as FactoryRepairsActions from '../../../state/ducks/factory-repairs';
import * as UiActions from '../../../state/ducks/ui';
import { Step, SemanticICONS } from 'semantic-ui-react';
import { ServiceCallFactorySteps } from '../../../state/ducks/ui/reducers';
import { ServiceCallFactoryRepair, FactoryReport } from '../../../state/models';
import { getFactoryRepairById, getFactoryReportByRepairId } from '../../../state/ducks/factory-repairs/selectors';
import { StringHelper } from '../../../state/utils';

interface ServiceCallFactoryPageActions {
    factoryRepairsActions: typeof FactoryRepairsActions.actionCreators;
    uiActions: typeof UiActions.actionCreators;
}

interface ServiceCallFactoryPageOwnProps {
    currentStepId: ServiceCallFactorySteps;
    factoryRepairs: ServiceCallFactoryRepair;
    factoryReport: FactoryReport;
}

const m = defineMessages({
    prepareStepTitle: { id: 'ServiceCallFactoryPage.prepare_step_title', defaultMessage: 'Prepare' },
    repairStepTitle: { id: 'ServiceCallFactoryPage.repair_step_title', defaultMessage: 'Repair' },
    completeStepTitle: { id: 'ServiceCallFactoryPage.complete_step_title', defaultMessage: 'Complete' },
});

export type ServiceCallFactoryPageProps =
    & ServiceCallFactoryPageOwnProps
    & ServiceCallFactoryPageActions
    & RouteComponentProps<{ serviceCallId: string, repairId: string }>;

type IntlServiceCallFactoryPageProps = ServiceCallFactoryPageProps & WrappedComponentProps;

class ServiceCallFactoryPage extends React.Component<IntlServiceCallFactoryPageProps, {}> {
    public componentDidMount() {
        const repairId = Number(this.props.match.params.repairId);
        this.props.factoryRepairsActions.loadRepair(repairId);
        this.props.factoryRepairsActions.loadReport(repairId);
        this.props.uiActions.changeServiceCallTab('factory');
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const hasRepair = this.props.factoryRepairs != null;
        const hasReport = this.props.factoryReport != null;

        const isPrepareStepComplete = hasRepair && this.props.factoryRepairs.receivedOn != null && moment.utc().isSameOrAfter(this.props.factoryRepairs.receivedOn);
        const isRepairStepEnabled = true;
        const isRepairStepComplete = hasReport && StringHelper.hasValue(this.props.factoryReport.report) && (this.props.factoryReport.hoursTaken > 0 || this.props.factoryReport.minutesTaken > 0);;
        const isCompleteStepEnabled = isRepairStepComplete;
        const isCompleteStepComplete = hasRepair && this.props.factoryRepairs.completedRepairsOn != null;

        return (
            <div>
                <Step.Group widths={3} size="small" style={{ marginBottom: '1rem' }} unstackable={true}>
                    {this.renderStep('prepare', 'factory', formatMessage(m.prepareStepTitle), isPrepareStepComplete, false)}
                    {this.renderStep('repair', 'wrench', formatMessage(m.repairStepTitle), isRepairStepComplete, !isRepairStepEnabled)}
                    {this.renderStep('complete', 'checkmark box', formatMessage(m.completeStepTitle), isCompleteStepComplete, !isCompleteStepEnabled)}
                </Step.Group>
            </div>
        );
    }

    private renderStep(stepId: ServiceCallFactorySteps, icon: SemanticICONS, title: string, isCompleted: boolean, isDisabled: boolean) {
        return (
            <Step
                id={stepId}
                icon={icon}
                title={title}
                disabled={isDisabled}
                active={this.props.currentStepId === stepId}
                completed={isCompleted}
                onClick={() => this.handleStepChange(stepId)}
            />
        );
    }

    private handleStepChange = (stepId: ServiceCallFactorySteps) => {
        const { serviceCallId, repairId } = this.props.match.params;

        this.props.uiActions.changeTechnicianStep(stepId);
        this.props.history.push(`/service-calls/${serviceCallId}/factory-repairs/${repairId}/${stepId}`);
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: ServiceCallFactoryPageProps): ServiceCallFactoryPageOwnProps => {
    const repairId = Number(ownProps.match.params.repairId);

    return {
        currentStepId: state.ui.currentFactoryStepId,
        factoryRepairs: getFactoryRepairById(state, repairId),
        factoryReport: getFactoryReportByRepairId(state, repairId)
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ServiceCallFactoryPageActions => {
    return {
        factoryRepairsActions: bindActionCreators(FactoryRepairsActions.actionCreators, dispatch),
        uiActions: bindActionCreators(UiActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(ServiceCallFactoryPage);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as ServiceCallFactoryPage };