import { DateFilter, isInitialDateFilter, initialDateFilter } from '../../../state/models/filters';
import { SubcontractorBill } from '../../../state/models';

export interface ReportingState {
    subcontractorsBillingFilters: SubcontractorsBillingFilters;
    subcontractorsBills: SubcontractorBill[];
    areSubcontractorsBillsLoading: boolean;
}

export interface SubcontractorFilter {
    subcontractorId: number | undefined;
}

export const initialSubcontractorFilter: SubcontractorFilter = {
    subcontractorId: undefined
};

export interface SubcontractorsBillingFilters {
    dateFilter: DateFilter;
    subcontractorFilter: SubcontractorFilter;
}

export const initialSubcontractorsBillingFilters: SubcontractorsBillingFilters = {
    dateFilter: initialDateFilter,
    subcontractorFilter: initialSubcontractorFilter,
};

export function isInitialSubcontractorsBillingFilters(filters: SubcontractorsBillingFilters) {
    return isInitialDateFilter(filters.dateFilter)
        && filters.subcontractorFilter.subcontractorId == null;
}