export enum ActionTypeKeys {
    LOAD_UPCOMING_REQUEST = 'INSPECTIONS_LOAD_UPCOMING_REQUEST',
    LOAD_UPCOMING_SUCCESS = 'INSPECTIONS_LOAD_UPCOMING_SUCCESS',
    LOAD_UPCOMING_FAILURE = 'INSPECTIONS_LOAD_UPCOMING_FAILURE',

    LOAD_BETWEEN_REQUEST = 'INSPECTIONS_LOAD_BETWEEN_REQUEST',
    LOAD_BETWEEN_SUCCESS = 'INSPECTIONS_LOAD_BETWEEN_SUCCESS',
    LOAD_BETWEEN_FAILURE = 'INSPECTIONS_LOAD_BETWEEN_FAILURE',

    LOAD_UNSCHEDULED_REQUEST = 'INSPECTIONS_LOAD_UNSCHEDULED_REQUEST',
    LOAD_UNSCHEDULED_SUCCESS = 'INSPECTIONS_LOAD_UNSCHEDULED_SUCCESS',
    LOAD_UNSCHEDULED_FAILURE = 'INSPECTIONS_LOAD_UNSCHEDULED_FAILURE',

    LOAD_COMPLETED_REQUEST = 'INSPECTIONS_LOAD_COMPLETED_REQUEST',
    LOAD_COMPLETED_SUCCESS = 'INSPECTIONS_LOAD_COMPLETED_SUCCESS',
    LOAD_COMPLETED_FAILURE = 'INSPECTIONS_LOAD_COMPLETED_FAILURE',

    LOAD_BY_ID_REQUEST = 'INSPECTIONS_LOAD_BY_ID_REQUEST',
    LOAD_BY_ID_SUCCESS = 'INSPECTIONS_LOAD_BY_ID_SUCCESS',
    LOAD_BY_ID_FAILURE = 'INSPECTIONS_LOAD_BY_ID_FAILURE',

    SCHEDULE_REQUEST = 'INSPECTION_SCHEDULE_REQUEST',
    SCHEDULE_SUCCESS = 'INSPECTION_SCHEDULE_SUCCESS',
    SCHEDULE_FAILURE = 'INSPECTION_SCHEDULE_FAILURE',

    SAVE_REPORT_REQUEST = 'INSPECTION_SAVE_REPORT_REQUEST',
    SAVE_REPORT_SUCCESS = 'INSPECTION_SAVE_REPORT_SUCCESS',
    SAVE_REPORT_FAILURE = 'INSPECTION_SAVE_REPORT_FAILURE',

    COMPLETE_REQUEST = 'INSPECTION_COMPLETE_REQUEST',
    COMPLETE_SUCCESS = 'INSPECTION_COMPLETE_SUCCESS',
    COMPLETE_FAILURE = 'INSPECTION_COMPLETE_FAILURE',

    CLOSE_REQUEST = 'INSPECTION_CLOSE_REQUEST',
    CLOSE_SUCCESS = 'INSPECTION_CLOSE_SUCCESS',
    CLOSE_FAILURE = 'INSPECTION_CLOSE_FAILURE',

    SET_CALENDAR_START_DATE = 'INSPECTION_SET_CALENDAR_START_DATE'
}