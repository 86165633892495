import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Card, List, Label, Icon, Segment, Modal } from 'semantic-ui-react';

import { ItemDetailsList } from '../items';
import { Bill, BillDetail, ServiceCallDefectiveItem, Client, OrderInformation, OrderInformationValidationResults, OrderEdit, ValidationResult } from '../../state/models';
import { FormatHelper, StringHelper } from '../../state/utils';
import { ClientQuickOverview } from '../quick-overviews';
import { SmallEditButton, SmallCancelButton, SmallSaveButton } from '../common';
import { OrderInformationForm } from '../service-calls';
import { Link } from 'react-router-dom';

interface BillCardOwnProps {
    orderEdit?: OrderEdit;
    validationResult?: ValidationResult;
    bill?: Bill;
    client?: Client;
    title?: string;
    defectiveItems?: ServiceCallDefectiveItem[];
    numberOfItemsToShow?: number;
    canEdit?: boolean;
    isClientSearchVisible?: boolean;
    isOrderLinkVisible?: boolean;
    onEdit?: (orderInformation: OrderEdit) => ValidationResult;
}

export type BillCardProps =
    & BillCardOwnProps
    & WrappedComponentProps;

interface BillCardState {
    isEditing: boolean;
    orderEdit: OrderEdit;
    validationResult: ValidationResult;
}

const m = defineMessages({
    title: { id: 'OrderCard.title', defaultMessage: 'Order details' },
    billNumberFormat: { id: 'OrderCard.bill_number_format', defaultMessage: 'Bill #{number}' },
    purchaseOrderFormat: { id: 'OrderCard.purchase_order_format', defaultMessage: 'PO #{number}' },
    referenceNumberFormat: { id: 'OrderCard.reference_number_format', defaultMessage: 'Reference #{number}' },
    puchasedOnFormat: { id: 'OrderCard.purchased_on_format', defaultMessage: 'Billed on {date}' },
    coverDescriptionFormat: { id: 'OrderCard.cover_description_format', defaultMessage: 'Cover: {description}' },
    quantityOrderedFormat: { id: 'OrderCard.quantity_ordered_format', defaultMessage: 'Quantity: {quantity}' },
    emptyTitle: { id: 'OrderCard.empty_title', defaultMessage: 'No order details' },
    emptyDescription: { id: 'OrderCard.empty_description', defaultMessage: 'There\'s no bill associated to this service call' },
    clientReferenceNumber: { id: 'OrderCard.client_reference_number', defaultMessage: 'Reference: {number}' },
    clientContactName: { id: 'OrderCard.client_contact_Name', defaultMessage: 'Contact: {name}' }
});

class BillCard extends React.Component<BillCardProps, BillCardState> {
    public constructor(props: BillCardProps) {
        super(props);

        this.state = {
            isEditing: false,
            orderEdit: props.orderEdit || new OrderEdit(),
            validationResult: props.validationResult || new ValidationResult()
        };
    }

    public componentWillReceiveProps(props: BillCardProps) {
        this.setState({
            orderEdit: props.orderEdit || new OrderEdit(),
            validationResult: props.validationResult || new ValidationResult()
        });
    }

    public render() {
        return this.props.bill != null
            ? this.renderBillCard(this.props.bill)
            : this.renderEmptyCard();
    }

    private renderBillCard = (bill: Bill) => {
        const { formatMessage } = this.props.intl;
        const orderLink = this.props.isOrderLinkVisible
            ? (
                <Link to={`/orders/${bill.orderId}/items`} style={{ textDecoration: 'underline' }}>
                    {formatMessage(m.billNumberFormat, { number: bill.id })}
                </Link>
            )
            : <span>{formatMessage(m.billNumberFormat, { number: bill.id })}</span>;

        return (
            <div>
                <Card color="yellow" fluid={true} style={{ height: '100%', marginTop: 0 }}>
                    <Card.Content style={{ flexGrow: 0 }}>
                        {this.renderCardHeader()}
                        <Card.Meta>
                            {orderLink}
                            {StringHelper.hasValue(bill.purchaseOrder) &&
                                <span>
                                    <span>&#9642;</span>
                                    <span>{formatMessage(m.purchaseOrderFormat, { number: bill.purchaseOrder })}</span>
                                </span>
                            }
                            {StringHelper.hasValue(bill.referenceNumber) &&
                                <span>
                                    <span>&#9642;</span>
                                    <span>{formatMessage(m.referenceNumberFormat, { number: bill.referenceNumber })}</span>
                                </span>
                            }
                        </Card.Meta>
                    </Card.Content>
                    {!this.state.isEditing && this.renderCardContent()}
                    {this.state.isEditing && this.renderEditableCardContent()}

                    {bill.tag &&
                        <Card.Content>
                            <Label icon="tag" content={bill.tag} />
                        </Card.Content>
                    }
                    <Card.Content extra={true}>
                        <Icon name="calendar" />
                        {formatMessage(m.puchasedOnFormat, { date: moment(bill.billedOn).format('LL') })}
                    </Card.Content>
                </Card>
            </div>
        );
    }

    private renderEmptyCard = () => {
        const { formatMessage } = this.props.intl;

        return (
            <Card fluid={true} color="yellow" style={{ height: '100%', marginTop: 0 }}>
                <Card.Content style={{ flexGrow: 0 }}>
                    {this.renderCardHeader()}
                </Card.Content>
                <Card.Content>
                    {!this.state.isEditing &&
                        <Segment basic={true} textAlign="center">
                            <h3>{formatMessage(m.emptyTitle)}</h3>
                            {formatMessage(m.emptyDescription)}
                        </Segment>
                    }
                    {this.state.isEditing &&
                        this.renderEditableCardContent()
                    }
                </Card.Content>
            </Card>
        );
    }

    private renderCardHeader() {
        const { formatMessage } = this.props.intl;
        const title = this.props.title || formatMessage(m.title);

        return (
            <Card.Header>
                {title}
                {!this.state.isEditing && this.props.canEdit && <SmallEditButton floated="right" onClick={this.handleEdit} />}
                {this.state.isEditing &&
                    <div style={{ float: 'right' }}>
                        <SmallCancelButton onClick={this.handleCancel} />
                        <SmallSaveButton onClick={this.handleSave} />
                    </div>
                }
            </Card.Header>
        );
    }

    private renderCardContent() {
        const { formatMessage } = this.props.intl;

        return (
            <Card.Content>
                <div style={{ marginBottom: 15 }}>
                    {this.props.client &&
                        <div>
                            <Icon name="building" />
                            <ClientQuickOverview client={this.props.client} />
                        </div>
                    }

                    {this.props.orderEdit != null && this.props.orderEdit.referenceNumber != null &&
                        <div className="meta" style={{ marginLeft: 20 }}>
                            {formatMessage(m.clientReferenceNumber, { number: this.props.orderEdit.referenceNumber })}
                        </div>
                    }

                    {this.props.orderEdit != null && StringHelper.hasValue(this.props.orderEdit.clientContactName) &&
                        <div className="meta" style={{ marginLeft: 20 }}>
                            {formatMessage(m.clientContactName, { name: this.props.orderEdit.clientContactName })}
                        </div>
                    }
                </div>

                {this.props.bill &&
                    <ItemDetailsList
                        billDetails={this.props.bill.billDetails}
                        defectiveItems={this.props.defectiveItems}
                        numberOfItemsToShow={3}
                    />
                }
            </Card.Content>
        );
    }

    private renderEditableCardContent() {
        return (
            <Segment basic={true}>
                <OrderInformationForm
                    order={this.state.orderEdit}
                    validationResult={this.state.validationResult}
                    onChange={this.handleFieldChange}
                    onBillSelected={this.handleBillSelected}
                    isClientSearchVisible={this.props.isClientSearchVisible}
                    onClientSelected={this.handleClientSelected}
                />
            </Segment>
        );
    }

    private handleEdit = () => {
        this.setState({ isEditing: true });
    }

    private handleCancel = () => {
        this.setState({
            orderEdit: this.props.orderEdit || new OrderEdit(),
            validationResult: this.props.validationResult || new ValidationResult(),
            isEditing: false
        });
    }

    private handleSave = () => {
        if (this.props.onEdit) {
            var validationResult = this.props.onEdit(this.state.orderEdit);
            this.setState({ validationResult: validationResult });

            if (!validationResult.isValid) {
                return;
            }
        }

        this.setState({
            isEditing: false,
            validationResult: new ValidationResult()
        });
    }

    private handleBillSelected = (bill: Bill | undefined) => {
        this.setState((current) => ({
            ...current,
            orderEdit: new OrderEdit({
                ...current.orderEdit,
                bill: bill,
                billId: bill ? bill.id : undefined,
            })
        }));
    }

    private handleClientSelected = (client: Client | undefined) => {
        this.setState((current) => ({
            ...current,
            orderEdit: new OrderEdit({
                ...current.orderEdit,
                client: client,
                clientId: client ? client.id : undefined
            })
        }));
    }

    private handleFieldChange = (fieldId: string, value: string) => {
        this.setState((current) => ({
            ...current,
            orderEdit: new OrderEdit({
                ...current.orderEdit,
                [fieldId]: value
            })
        }));
    }
}

const connectedComponent = injectIntl(BillCard);
export { connectedComponent as BillCard };