import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Checkbox, Icon, List } from 'semantic-ui-react';
import { ProductionComponent } from '../../../state/models';
import { ProductionComponentLabel } from '.';

export interface SelectableProductionComponentProps {
    productionComponent: ProductionComponent;
    isCollapsible: boolean;
    isCollapsed: boolean;
    isSelected: boolean;
    children: JSX.Element[];

    onCollapse: () => void;
    onExpand: () => void;
    onSelect: () => void;
    onDeselect: () => void;
}

class SelectableProductionComponent extends React.Component<SelectableProductionComponentProps & WrappedComponentProps, {}> {
    public render() {
        const { productionComponent } = this.props;
        const collapseVisibility = this.props.isCollapsible ? 'visible' : 'hidden';
        const toggleIcon = this.props.isCollapsed ? 'caret right' : 'caret down';

        return (
            <List.Item key={productionComponent.id}>
                <List.Icon>
                    <Icon 
                        name={toggleIcon} 
                        size="large" 
                        link={true}
                        onClick={() => this.handleToggleIconClick(this.props.isCollapsed)}
                        style={{visibility: collapseVisibility}}
                    />
                </List.Icon>
                <List.Content style={{width: '100%', padding: 0}}>
                    <Checkbox 
                        className="bom-item" 
                        label={<ProductionComponentLabel productionComponent={productionComponent} />} 
                        checked={this.props.isSelected}
                        onChange={(e, data) => this.handleItemSelectionChange(data.checked)}
                        style={{width: '100%', padding: 7}} 
                    />

                    {this.props.children.length > 0 &&
                        <List.List style={{width: '100%', padding: '4px 0 0 0'}}>
                            {this.props.children}
                        </List.List>
                    }
                </List.Content>
            </List.Item>
        );
    }

    private handleToggleIconClick = (wasCollapsed: boolean) => {
        if (wasCollapsed) {
            this.props.onExpand();
        } else {
            this.props.onCollapse();
        }
    }

    private handleItemSelectionChange = (isChecked?: boolean) => {
        if (isChecked) {
            this.props.onSelect();
        } else {
            this.props.onDeselect();
        }
    }
}

const intlComponent = injectIntl(SelectableProductionComponent);
export { intlComponent as SelectableProductionComponent };