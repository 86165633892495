import * as React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserAccountType } from '../state/models';
import { isAuthenticated } from 'modules/account/selectors';

interface OwnProps {
    authorizedRoles: UserAccountType[];
}

export type AuthenticatedRouteProps =
    & OwnProps
    & RouteProps;

export const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = (props) => {
    const { authorizedRoles, ...routeProps } = props;

    const location = useLocation();
    const isUserAuthenticated = useSelector(isAuthenticated);

    return (
        isUserAuthenticated
            ? <Route {...routeProps} />
            : <Redirect to={{ pathname: '/account/login', state: { from: location }}} />
    );
};