import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { defineMessages, useIntl } from "react-intl";
import { Button, Divider, Form, TextArea } from "semantic-ui-react";
import { commonMessages } from "../../../../constants";
import { ServiceCallInspection, ServiceCallStatus } from "../../../../state/models";
import { useResponsive } from "../../../../utils/responsive";
import { ConfirmButton, OrDivider } from "../../../common";
import { InspectionReportValidationSummary } from "./InspectionReportValidationSummary";
import { InspectionReportEdit } from './models';

interface OwnProps {
    inspection: ServiceCallInspection | undefined;

    onSave: (inspectionId: number, data: InspectionReportEdit) => void;
    onComplete: (inspectionId: number) => void;
    onCompleteAndClose: (inspectionId: number) => void;
}

export type InspectionReportFormProps =
    & OwnProps;

const m = defineMessages({
    incompleteReport: { id: 'InspectionReportCard.incomplete_report', defaultMessage: 'The inspection report is incomplete' },
    confirmClosureHeader: { id: 'InspectionReportCard.confirm_closure_header', defaultMessage: 'Confirm the service call closure' },
    confirmClosureContent: { id: 'InspectionReportCard.confirm_closure_content', defaultMessage: 'Are you sure you want to close the service call?' },
    confirmClosureButton: { id: 'InspectionReportCard.confirm_closure_button', defaultMessage: 'Yes, close it' },
    completeInspectionButton: { id: 'InspectionReportCard.complete_inspection_button', defaultMessage: 'Complete the inspection' },
    completeInspectionAndCloseServiceCallButton: { id: 'InspectionReportCard.complete_inspection_and_close_button', defaultMessage: 'Close the service call' },
    closeServiceCallButton: { id: 'InspectionReportCard.close_service_call_button', defaultMessage: 'Close the service call' },
});

export const InspectionReportForm: React.FC<InspectionReportFormProps> = (props) => {
    const { formatMessage } = useIntl();
    const { isMobile } = useResponsive();
    const { control, formState: { errors }, setValue, getValues, handleSubmit } = useForm<InspectionReportEdit>({ mode: 'onBlur' });

    const isInspectionComplete = props.inspection ? props.inspection.completedOn != null : false;
    const isServiceCallClosed = props.inspection && props.inspection.serviceCall ? props.inspection.serviceCall.status === ServiceCallStatus.closed : false;

    useEffect(() => {
        if (props.inspection != null) {
            setValue('report', props.inspection.report);
        }
    }, [props.inspection, setValue])

    const save = () => {
        if (props.inspection != null) {
            props.onSave(props.inspection.id, getValues());
        }
    }

    const complete = () => {
        if (props.inspection) {
            props.onComplete(props.inspection.id);
        }
    }

    const completeAndClose = () => {
        if (props.inspection) {
            props.onCompleteAndClose(props.inspection.id);
        }
    }

    return (
        <>
            <Form>
                <Form.Field>
                    <Controller
                        name='report'
                        control={control}
                        rules={{ required: formatMessage(m.incompleteReport) }}
                        render={({ field: { value }}) => (
                            <Form.Field error={errors.report != null}>
                                <TextArea
                                    rows={10}
                                    onChange={(e, data) => setValue('report', data.value as string || '')}
                                    onBlur={save}
                                    value={value}
                                />
                            </Form.Field>
                        )}
                    />
                </Form.Field>
            </Form>

            <InspectionReportValidationSummary errors={errors} />

            <div style={{ textAlign: 'right' }}>
                <Divider />
                {!isMobile &&
                    <>
                        {!isServiceCallClosed &&
                            <ConfirmButton
                                negative={true}
                                confirmHeader={formatMessage(m.confirmClosureHeader)}
                                confirmContent={formatMessage(m.confirmClosureContent)}
                                cancelButton={{ content: formatMessage(commonMessages.cancel) }}
                                confirmButton={{ negative: true, content: formatMessage(m.confirmClosureButton) }}
                                content={isInspectionComplete ? formatMessage(m.closeServiceCallButton) : formatMessage(m.completeInspectionAndCloseServiceCallButton)}
                                onConfirm={handleSubmit(completeAndClose)}
                                style={{ width: '215px' }}
                            />
                        }
                        <Button
                            primary={true}
                            content={isInspectionComplete ? formatMessage(commonMessages.modify) : formatMessage(m.completeInspectionButton)}
                            onClick={handleSubmit(complete)}
                            style={{ width: '215px' }}
                        />
                    </>
                }

                {isMobile &&
                    <>
                        {!isServiceCallClosed &&
                            <React.Fragment>
                                <ConfirmButton
                                    negative
                                    confirmHeader={formatMessage(m.confirmClosureHeader)}
                                    confirmContent={formatMessage(m.confirmClosureContent)}
                                    cancelButton={{ content: formatMessage(commonMessages.cancel) }}
                                    confirmButton={{ negative: true, content: formatMessage(m.confirmClosureButton) }}
                                    content={isInspectionComplete ? formatMessage(m.closeServiceCallButton) : formatMessage(m.completeInspectionAndCloseServiceCallButton)}
                                    onConfirm={handleSubmit(completeAndClose)}
                                    fluid
                                />
                                <OrDivider section={false} />
                            </React.Fragment>
                        }
                        <Button
                            primary={true}
                            content={isInspectionComplete ? formatMessage(commonMessages.modify) : formatMessage(m.completeInspectionButton)}
                            onClick={handleSubmit(complete)}
                            fluid={true}
                        />
                    </>
                }
            </div>
        </>
    );
};