import { ActionTypeKeys } from './types';
import { Bill, Client, Item, ServiceCall, PagedResult, SearchResults } from '../../models';
import { ThunkAction } from 'redux-thunk';
import { ApplicationState } from '..';
import * as globalNotification from '../global-notification';
import { BillsService, ClientsService, ItemsService, ServiceCallsService } from '../../../services';
import { AppThunk } from '../../store';

export type ActionTypes =
    | SearchBillsRequestAction
    | SearchBillsSuccessAction
    | SearchBillsFailureAction
    | SearchClientsRequestAction
    | SearchClientsSuccessAction
    | SearchClientsFailureAction
    | SearchItemsRequestAction
    | SearchItemsSuccessAction
    | SearchItemsFailureAction
    | SearchServiceCallsRequestAction
    | SearchServiceCallsSuccessAction
    | SearchServiceCallsFailureAction
    | { type: '' };

export interface SearchBillsRequestAction { type: ActionTypeKeys.BILLS_SEARCH_REQUEST; }
export interface SearchBillsSuccessAction { type: ActionTypeKeys.BILLS_SEARCH_SUCCESS; payload: Bill[]; }
export interface SearchBillsFailureAction { type: ActionTypeKeys.BILLS_SEARCH_FAILURE; payload: string; }
export interface SearchClientsRequestAction { type: ActionTypeKeys.CLIENTS_SEARCH_REQUEST; }
export interface SearchClientsSuccessAction { type: ActionTypeKeys.CLIENTS_SEARCH_SUCCESS; payload: Client[]; }
export interface SearchClientsFailureAction { type: ActionTypeKeys.CLIENTS_SEARCH_FAILURE; payload: string; }
export interface SearchItemsRequestAction { type: ActionTypeKeys.ITEMS_SEARCH_REQUEST; }
export interface SearchItemsSuccessAction { type: ActionTypeKeys.ITEMS_SEARCH_SUCCESS; payload: Item[]; }
export interface SearchItemsFailureAction { type: ActionTypeKeys.ITEMS_SEARCH_FAILURE; payload: string; }
export interface SearchServiceCallsRequestAction { type: ActionTypeKeys.SERVICE_CALLS_SEARCH_REQUEST; }
export interface SearchServiceCallsSuccessAction { type: ActionTypeKeys.SERVICE_CALLS_SEARCH_SUCCESS; payload: PagedResult<ServiceCall>; }
export interface SearchServiceCallsFailureAction { type: ActionTypeKeys.SERVICE_CALLS_SEARCH_FAILURE; payload: string; }

const billsService = new BillsService();
const clientsService = new ClientsService();
const itemsService = new ItemsService();
const serviceCallsService = new ServiceCallsService();

let lastFindClientTimestamp = 0;
let lastFindBillTimestamp = 0;
let lastFindItemTimestamp = 0;

export const actionCreators = {
    findBill: (clientId: string, searchTerm: string): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.BILLS_SEARCH_REQUEST });

            const findBillTimestamp = Date.now();
            lastFindBillTimestamp = findBillTimestamp;

            billsService.findBill(clientId, searchTerm, findBillTimestamp)
                .then(response => response.json() as SearchResults<Bill>)
                .then(data => {
                    if (data.timestamp === lastFindBillTimestamp) {
                        dispatch({ type: ActionTypeKeys.BILLS_SEARCH_SUCCESS, payload: data.results });
                    }
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.BILLS_SEARCH_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de chercher pour une facture (${error}).`)
                    );
                });
        };
    },

    findClient: (searchTerm: string): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.CLIENTS_SEARCH_REQUEST });

            const findClientTimestamp = Date.now();
            lastFindClientTimestamp = findClientTimestamp;

            clientsService.findClient(searchTerm, findClientTimestamp)
                .then(response => response.json() as SearchResults<Client>)
                .then(data => {
                    if (data.timestamp === lastFindClientTimestamp) {
                        dispatch({ type: ActionTypeKeys.CLIENTS_SEARCH_SUCCESS, payload: data.results });
                    }
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.CLIENTS_SEARCH_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de chercher pour un client (${error}).`)
                    );
                });
        };
    },

    findItem: (searchTerm: string): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.ITEMS_SEARCH_REQUEST });

            const findItemTimestamp = Date.now();
            lastFindItemTimestamp = findItemTimestamp;

            itemsService.findItem(searchTerm, findItemTimestamp)
                .then(response => response.json() as SearchResults<Item>)
                .then(data => {
                    if (data.timestamp === lastFindItemTimestamp) {
                        dispatch({ type: ActionTypeKeys.ITEMS_SEARCH_SUCCESS, payload: data.results });
                    }
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.ITEMS_SEARCH_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de chercher pour un item (${error}).`)
                    );
                });
        };
    },

    findServiceCall: (searchTerm: string, page?: number): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.SERVICE_CALLS_SEARCH_REQUEST });

            serviceCallsService.findServiceCall(searchTerm, page)
                .then(response => response.json() as PagedResult<ServiceCall>)
                .then(serviceCalls => {
                    dispatch({ type: ActionTypeKeys.SERVICE_CALLS_SEARCH_SUCCESS, payload: serviceCalls });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.SERVICE_CALLS_SEARCH_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de chercher un appel de service (${error}).`)
                    );
                });
        };
    },

    findServiceCallWithCriterias: (searchQuery: string, page?: number): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.SERVICE_CALLS_SEARCH_REQUEST });

            serviceCallsService.findServiceCallWithCriterias(searchQuery, page)
                .then(response => response.json() as PagedResult<ServiceCall>)
                .then(results => {
                    dispatch({ type: ActionTypeKeys.SERVICE_CALLS_SEARCH_SUCCESS, payload: results });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.SERVICE_CALLS_SEARCH_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de chercher un appel de service (${error}).`)
                    );
                });
        };
    }
};