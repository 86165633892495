import * as React from 'react';
import { Accordion, Menu, Icon, SemanticICONS } from 'semantic-ui-react';

export interface NavigationSectionProps {
    index: string;
    isActive: boolean;
    title: string;
    icon?: SemanticICONS;
    onClick: (index: string) => void;
}

class NavigationSection extends React.Component<NavigationSectionProps, {}> {
    public render() {
        return (
            <Menu.Item>
                <Accordion.Title 
                    onClick={() => this.props.onClick(this.props.index)}
                    active={this.props.isActive} 
                    index={this.props.index} 
                >
                    <Icon name={this.props.icon} />
                    {this.props.title}
                    <Icon name="dropdown" />
                </Accordion.Title>

                <Accordion.Content active={this.props.isActive}>
                    <Menu.Menu>
                        {this.props.children}
                    </Menu.Menu>
                </Accordion.Content>
            </Menu.Item>
        );
    }
}

export { NavigationSection };