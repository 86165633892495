import { useIntl } from 'react-intl';
import { Icon, List } from 'semantic-ui-react';
import { CustomerInformation } from '../../../../../components/service-calls/CustomerInformation';
import { useSubcontractorRepairDetailsContext } from '../SubcontractorRepairDetailsProvider';

export const CustomerInformationSection = () => {
    const { formatMessage } = useIntl();
    const { repairDetails } = useSubcontractorRepairDetailsContext();

    return (
        <List.Item>
            <Icon name="user circle" size="large" />
            <List.Content>
                <List.Header as="h3" style={{ marginBottom: '1rem' }}>
                    {formatMessage({
                        id: 'SubcontractorRepairPage.CustomerInformationSection.Title',
                        defaultMessage: 'Customer information',
                    })}
                </List.Header>
                <CustomerInformation customer={repairDetails?.customer} />
            </List.Content>
        </List.Item>
    );
};
