import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { defineMessages, useIntl } from "react-intl";
import * as ReportingActions from "../../state/ducks/reporting";
import { ApplicationState } from "../../state/ducks";
import { PageHeader } from "../../components/common";
import { Grid } from "semantic-ui-react";
import { StatisticTile, VerticalBarGraph } from "../../components/reporting";
import { getStatusDescription } from "../../components/service-calls";
import { useHistory } from "react-router";

const m = defineMessages({
    title: { id: "AdministratorDashboardPage.title", defaultMessage: "Dashboard" },
    subtitle: {
        id: "AdministratorDashboardPage.subtitle",
        defaultMessage: "See a quick overview of the after-sales service.",
    },
    overdueCallCountLabel: {
        id: "AdministratorDashboardPage.overdue_call_count_label",
        defaultMessage: "Overdue service calls",
    },
    overdueCallCountTooltip: {
        id: "AdministratorDashboardPage.overdue_call_count_tooltip",
        defaultMessage: "The number of service calls open for 30 business days or more",
    },
    stalledUnassignedCallCountLabel: {
        id: "AdministratorDashboardPage.stalled_unassigned_call_count_label",
        defaultMessage: "Stalled unassigned calls",
    },
    stalledUnassignedCallCountTooltip: {
        id: "AdministratorDashboardPage.stalled_unassigned_call_count_tooltip",
        defaultMessage: "The number of calls unassigned since 48 hours or more",
    },
    activeCallsLabel: {
        id: "AdministratorDashboardPage.active_calls_label",
        defaultMessage: "Active calls",
    },
    activeCallsTooltip: {
        id: "AdministratorDashboardPage.active_calls_tooltip",
        defaultMessage: "The number of calls that are still unresolved.",
    },
    activeCallsStatusLabel: {
        id: "AdministratorDashboardPage.active_calls_status_label",
        defaultMessage: "Active calls distribution",
    },
    activeCallsStatusTooltip: {
        id: "AdministratorDashboardPage.active_calls_status_tooltip",
        defaultMessage: "The distribution of the active service calls according to their status.",
    },
    activeCallsStatusLegend: {
        id: "AdministratorDashboardPage.active_calls_status_legend",
        defaultMessage: "Service calls",
    },
});

export const AdministratorDashboardPage = () => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();

    const overdueCallCount = useSelector(
        (state: ApplicationState) => state.reporting.overdueCallCount
    );

    const stalledUnassignedCallCount = useSelector(
        (state: ApplicationState) => state.reporting.stalledUnassignedCallCount
    );

    const activeCalls = useSelector(
        (state: ApplicationState) => state.reporting.currentActiveCalls
    );

    const isActiveCallsLoading = useSelector(
        (state: ApplicationState) => state.reporting.isCurrentActiveCallsLoading
    );

    const activeCallsStatus = useSelector(
        (state: ApplicationState) => state.reporting.activeCallsStatus
    );

    const isOverdueCallCountLoading = useSelector(
        (state: ApplicationState) => state.reporting.isOverdueCallCountLoading
    );

    const isStalledUnassignedCallCountLoading = useSelector(
        (state: ApplicationState) => state.reporting.isStalledUnassignedCallCountLoading
    );

    const isActiveCallsStatusLoading = useSelector(
        (state: ApplicationState) => state.reporting.isActiveCallsStatusLoading
    );

    useEffect(() => {
        dispatch(ReportingActions.actionCreators.loadOverdueCallCount());
        dispatch(ReportingActions.actionCreators.loadStalledUnassignedCallCount());
        dispatch(ReportingActions.actionCreators.loadCurrentActiveCalls());
        dispatch(ReportingActions.actionCreators.loadActiveCallsStatus());
    }, [dispatch]);

    const navigateToStalledUnassignedCallsPage = () => {
        history.push("service-calls/stalled-unassigned");
    };

    const navigateToOverdueCallsPage = () => {
        history.push(`/service-calls/overdue`);
    }

    return (
        <React.Fragment>
            <PageHeader
                iconName='home'
                title={formatMessage(m.title)}
                subtitle={formatMessage(m.subtitle)}
            />

            <Grid>
                <Grid.Row>
                    <Grid.Column mobile={8} tablet={8} computer={5} largeScreen={4} widescreen={3}>
                        <StatisticTile
                            isLoading={isActiveCallsLoading}
                            value={activeCalls}
                            color='teal'
                            label={formatMessage(m.activeCallsLabel)}
                            labelTooltip={formatMessage(m.activeCallsTooltip)}
                        />
                    </Grid.Column>
                    <Grid.Column mobile={8} tablet={8} computer={5} largeScreen={4} widescreen={3}>
                        <StatisticTile
                            isLoading={isStalledUnassignedCallCountLoading}
                            value={stalledUnassignedCallCount}
                            label={formatMessage(m.stalledUnassignedCallCountLabel)}
                            labelTooltip={formatMessage(m.stalledUnassignedCallCountTooltip)}
                            color={stalledUnassignedCallCount ? "red" : "teal"}
                            onClick={navigateToStalledUnassignedCallsPage}
                        />
                    </Grid.Column>
                    <Grid.Column mobile={8} tablet={8} computer={5} largeScreen={4} widescreen={3}>
                        <StatisticTile
                            isLoading={isOverdueCallCountLoading}
                            value={overdueCallCount}
                            label={formatMessage(m.overdueCallCountLabel)}
                            labelTooltip={formatMessage(m.overdueCallCountTooltip)}
                            color={overdueCallCount ? "red" : "teal"}
                            onClick={navigateToOverdueCallsPage}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={16} computer={8} largeScreen={8} widescreen={8}>
                        <VerticalBarGraph
                            title={formatMessage(m.activeCallsStatusLegend)}
                            label={formatMessage(m.activeCallsStatusLabel)}
                            labelTooltip={formatMessage(m.activeCallsStatusTooltip)}
                            labels={activeCallsStatus.map((x) =>
                                formatMessage(getStatusDescription(x.paired))
                            )}
                            values={activeCallsStatus.map((x) => x.value)}
                            isLoading={isActiveCallsStatusLoading}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </React.Fragment>
    );
};
