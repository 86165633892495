import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Header, Modal, ModalProps } from 'semantic-ui-react';
import { useResponsive } from 'utils/responsive';

import { commonMessages } from '../../constants';
import { CalendarBreak } from '../../state/models';
import { MobileCalendar } from './MobileCalendar';
import { Calendar, CalendarEvent } from './MonthlyCalendar';

interface OwnProps {
    forDate: Date;
    events: CalendarEvent[];
    lockedDates: CalendarBreak[];
    forUserId?: string;

    title?: string;
    subtitle?: string;
    isLoading?: boolean;
    canSelectDate?: boolean;
    selectedDate?: Date;

    onMonthChanged: (date: Date) => void;
    onDateSelected?: (date: Date) => void;
    onCancel: () => void;
}

export type CalendarModalProps =
    & ModalProps
    & OwnProps;

export const CalendarModal: React.FC<CalendarModalProps> = (props) => {
    const { formatMessage } = useIntl();
    const { isMobile } = useResponsive();
    const [highlightedDate, setHighlightedDate] = useState(props.selectedDate);

    useEffect(() => {
        setHighlightedDate(props.selectedDate);
    }, [props.selectedDate])

    const {
        forDate,
        events,
        lockedDates,
        forUserId,
        title,
        subtitle,
        isLoading,
        canSelectDate,
        selectedDate,
        onMonthChanged,
        onDateSelected,
        onCancel,
        ...modalProps
    } = props;

    const confirmDateSelection = () => {
        if (highlightedDate && props.onDateSelected) {
            props.onDateSelected(highlightedDate);
        }
    }

    return (
        <Modal size="fullscreen" {...modalProps}>
            <Modal.Header>
                <Header
                    content={props.title}
                    subheader={props.subtitle}
                />
            </Modal.Header>
            <Modal.Content>
                {!isMobile &&
                    <Calendar
                        forDate={props.forDate}
                        forUserId={props.forUserId}
                        events={props.events}
                        lockedDates={props.lockedDates}
                        isLoading={props.isLoading}
                        canSelectDate={props.canSelectDate}
                        selectedDate={highlightedDate}
                        onMonthChanged={props.onMonthChanged}
                        onDateSelected={setHighlightedDate}
                    />
                }

                {isMobile &&
                    <MobileCalendar
                        forDate={props.forDate}
                        forUserId={props.forUserId}
                        events={props.events}
                        lockedDates={props.lockedDates}
                        isLoading={props.isLoading}
                        canSelectDate={props.canSelectDate}
                        selectedDate={highlightedDate}
                        onMonthChanged={props.onMonthChanged}
                        onDateSelected={setHighlightedDate}
                    />
                }
            </Modal.Content>
            <Modal.Actions>
                <Button content={formatMessage(commonMessages.cancel)} onClick={props.onCancel} />
                <Button
                    primary={true}
                    disabled={highlightedDate == null}
                    content={formatMessage(commonMessages.select)}
                    onClick={confirmDateSelection}
                />
            </Modal.Actions>
        </Modal>
    );
};