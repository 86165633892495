import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Button, Form, Grid, Header, Icon, Image, Label, Modal, ModalProps, Segment } from 'semantic-ui-react';

interface PictureToUpload {
    file: File;
    previewUrl: string;
}

interface UploadPicturesState {
    isPreviewOpen: boolean;
    localPictures: PictureToUpload[];
    pictureThumbnail: PictureToUpload | undefined;
    picturePreview: PictureToUpload | undefined;
}

export interface UploadPicturesProps {
    required?: boolean;
    error?: boolean;
    onLoadPicture?: (file: File) => void;
    onDeletePicture?: (file: File) => void;
}

const m = defineMessages({
    picturePreviewTitle: { id: 'UploadPictures.picture_preview_title', defaultMessage: 'Picture Preview' },
    keepPictureButton: { id: 'UploadPictures.keep_picture_button', defaultMessage: 'Keep Picture' },
    deletePictureButton: { id: 'UploadPictures.delete_picture_button', defaultMessage: 'Delete Picture' },
    uploadPicturesButton: { id: 'UploadPictures.upload_pictures_button', defaultMessage: 'Upload Pictures' },
    uploadPicturesInstructions: { id: 'UploadPictures.upload_pictures_description', defaultMessage: 'Select one or more files from your device.' },
});

class UploadPictures extends React.Component<UploadPicturesProps & WrappedComponentProps, UploadPicturesState> {
    constructor(props: UploadPicturesProps & WrappedComponentProps) {
        super(props);

        this.state = {
            isPreviewOpen: false,
            localPictures: [],
            pictureThumbnail: undefined,
            picturePreview: undefined
        };
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const { isPreviewOpen, picturePreview, pictureThumbnail, localPictures } = this.state;
        const currentThumbnail = pictureThumbnail || (localPictures.length > 0 ? localPictures[0] : undefined);
        const thumbnails = localPictures.map((value) => {
            return (
                <a key={value.previewUrl} onClick={() => this.showPictureThumbnail(value)} style={{ cursor: 'pointer' }}>
                    <Image bordered={true} size="mini" src={value.previewUrl} />
                </a>
            );
        });

        const imageLabel = <Label corner="right" color="teal" icon="magnify" />;

        return (
            <div>
                <Grid columns="equal" className="c-upload-pictures">
                    {currentThumbnail && (
                        <Grid.Column style={{ maxWidth: 300 }} className="c-upload-pictures__preview">
                            <a onClick={() => this.showPicturePreview(currentThumbnail)} style={{ cursor: 'pointer' }}>
                                <Image bordered={true} size="medium" label={imageLabel} src={currentThumbnail.previewUrl} />
                            </a>
                            <Image.Group style={{ margin: '5px 0 0 0' }}>
                                {thumbnails}
                            </Image.Group>
                        </Grid.Column>
                    )}
                    <Grid.Column style={{ maxWidth: 300 }} className="c-upload-pictures__upload">
                        <Segment className={this.props.error ? 'error' : ''} textAlign="center" style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Icon name="cloud upload" color="grey" size="huge" style={{ width: '100%' }} />
                            <p>
                                {formatMessage(m.uploadPicturesInstructions)}
                                {this.props.required && <span style={{ color: '#db2828', margin: '-.2em 0 0 .2em' }}>*</span>}
                            </p>
                            <Form>
                                <label className="ui button primary">
                                    {formatMessage(m.uploadPicturesButton)}
                                    <input
                                        type="file"
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        multiple={true}
                                        onChange={this.handlePicturesChange}
                                    />
                                </label>
                            </Form>
                        </Segment>
                    </Grid.Column>
                </Grid>

                <Modal
                    size="fullscreen"
                    open={isPreviewOpen}
                    onClose={this.closePicturePreview}
                    closeIcon={true}
                >
                    <Header icon="picture" content={formatMessage(m.picturePreviewTitle)} />
                    <Modal.Content image={true}>
                        {picturePreview &&
                            <Image wrapped={true} centered={true} bordered={true} src={picturePreview.previewUrl} />}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative={true} onClick={this.deletePicture} labelPosition="left" icon="trash" content={formatMessage(m.deletePictureButton)} />
                        <Button positive={true} onClick={this.closePicturePreview} labelPosition="left" icon="checkmark" content={formatMessage(m.keepPictureButton)} />
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }

    private showPictureThumbnail = (picture: PictureToUpload) => {
        this.setState((current) => ({ ...current, pictureThumbnail: picture }));
    }

    private showPicturePreview = (picture: PictureToUpload) => {
        this.setState((current) => ({
            ...current,
            isPreviewOpen: true,
            picturePreview: picture
        }));
    }

    private closePicturePreview = () => {
        this.setState((current) => ({ ...current, isPreviewOpen: false }));
    }

    private deletePicture = () => {
        this.setState((current) => {
            const { picturePreview } = current;
            let { localPictures, pictureThumbnail } = current;

            localPictures = localPictures.filter(value => value !== picturePreview);
            pictureThumbnail = localPictures.length > 0 ? localPictures[0] : undefined;

            if (this.props.onDeletePicture && picturePreview) {
                this.props.onDeletePicture(picturePreview.file);
            }

            return { ...current, localPictures, pictureThumbnail };
        });
        this.closePicturePreview();
    }

    private handlePicturesChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { files } = e.currentTarget;

        if (files) {
            for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
                this.loadPicture(files[fileIndex]);
            }
        }
    }

    private loadPicture(file: File) {
        const reader = new FileReader();

        reader.onloadend = () => {
            this.setState((current) => {
                let localPictures = this.state.localPictures;
                localPictures.push({
                    file,
                    previewUrl: reader.result as string
                });

                if (this.props.onLoadPicture) {
                    this.props.onLoadPicture(file);
                }

                return current;
            });
        };

        reader.readAsDataURL(file);
    }
}

const connectedComponent = injectIntl(UploadPictures);
export { connectedComponent as UploadPictures };