import { useFormContext } from 'react-hook-form';
import { Form } from 'semantic-ui-react';
import { SubContractorBillFormValues } from '../SubContractorBillSection/SubcontractorBillModal';
import { BillFileInputField } from './BillFileInputField';
import { ReferenceNumberInputField } from './ReferenceNumberInputField';
import { RepairFeesInputField } from './RepairFeesInputField';
import { ShippingFeesInputField } from './ShippingFeesInputField';
import { TotalField } from './TotalField';

interface BillFormProps {
    formId: string;
    onSubmit: (formValues: SubContractorBillFormValues) => void;
}

export const BillForm = ({ formId, onSubmit }: BillFormProps) => {
    const { handleSubmit } = useFormContext<SubContractorBillFormValues>();

    return (
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
            <BillFileInputField />
            <ReferenceNumberInputField />
            <RepairFeesInputField />
            <ShippingFeesInputField />
            <TotalField />
        </Form>
    );
};
