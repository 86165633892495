import moment from 'moment';
import { WarrantyType, WarrantyStatus } from '../models';

export class WarrantyHelper {
    public static getExpiryDate(warrantyType: WarrantyType, warrantyStartDate?: Date): moment.Moment | undefined {
        if (warrantyStartDate) {
            switch (warrantyType) {
                case WarrantyType.OneYear:
                case WarrantyType.TwoYears:
                case WarrantyType.ThreeYears:
                case WarrantyType.FourYears:
                case WarrantyType.FiveYears:
                    return moment(warrantyStartDate).add(warrantyType as number, 'years');

                case WarrantyType.SpecifiedByLaw:
                    return moment(warrantyStartDate).add(90, 'days');

                case WarrantyType.LifetimeLimited:
                    return moment(warrantyStartDate).add(1000, 'years');

                default:
                    return undefined;
            }
        }

        return undefined;
    }

    public static getStatus(warrantyType: WarrantyType, warrantyStartDate?: Date) {
        const warrantyExpiryDate = WarrantyHelper.getExpiryDate(warrantyType, warrantyStartDate);

        if (warrantyExpiryDate) {
            const isExpired = moment() > warrantyExpiryDate;
            return isExpired
                ? WarrantyStatus.Expired
                : WarrantyStatus.UnderWarranty;
        }

        return WarrantyStatus.None;
    }
}