import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Icon, Popup, List } from 'semantic-ui-react';
import { OrderDetail } from '../../../../state/models/OrderDetail';
import { getItemLocalizedDescription, formatItemId } from '../../../../state/models';
import { StringHelper } from '../../../../state/utils';
import './OrderSummaryListItem.css';
import { commonMessages } from '../../../../constants';
import { DefectIcon } from './DefectIcon';

interface OwnProps {
    orderItem: OrderDetail;
    defectsCount?: number;
    onClick?: (orderItem: OrderDetail) => void;
}

export type OrderSummaryListItemProps =
    & OwnProps
    & WrappedComponentProps;

const m = defineMessages({
    coverDescription: { id: 'OrderSummaryListItem.coverDescription', defaultMessage: 'Cover: {description}' },
    notePopup: { id: 'OrderSummaryListItem.notePopup', defaultMessage: 'This item has manufacturing notes.' },
    itemNotesLabel: { id: 'OrderSummaryListItem.itemNotesLabel', defaultMessage: 'Item notes' },
});

class OrderSummaryListItem extends React.Component<OrderSummaryListItemProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const itemDescription = getItemLocalizedDescription(this.props.orderItem.item, this.props.intl.locale);
        const coverDescription = getItemLocalizedDescription(this.props.orderItem.itemCover, this.props.intl.locale);
        const showDefectIcon = this.props.defectsCount != null && this.props.defectsCount > 0;
        const showNotesIcon = this.props.onClick && StringHelper.hasValue(this.props.orderItem.notes);

        return (
            <div className={`c-order-item ${this.props.onClick ? 'c-order-item--selectable' : ''}`} onClick={this.handleClick}>
                <Icon size="tiny" name="circle" style={{ marginRight: '10px' }} />
                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', flexGrow: 1, marginRight: '10px' }}>
                    <div title={itemDescription} style={{ overflow: 'inherit', textOverflow: 'inherit', lineHeight: '16px' }}>
                        <strong>{formatItemId(this.props.orderItem.itemId) || formatMessage(commonMessages.notApplicableAbbreviation)}</strong> {itemDescription}
                    </div>
                    <div title={coverDescription} style={{ overflow: 'inherit', textOverflow: 'inherit', color: 'rgba(0,0,0,.45)' }}>
                        {formatMessage(m.coverDescription, { description: coverDescription || formatMessage(commonMessages.notApplicableAbbreviation) })}
                    </div>
                </div>
                <div style={{ flexGrow: 0, display: 'flex' }}>
                    {showDefectIcon && <DefectIcon defectsCount={this.props.defectsCount || 0} />}
                    {showNotesIcon && this.renderNoteIcon()}
                </div>
            </div>
        );
    }

    private handleClick = () => {
        if (this.props.onClick) {
            this.props.onClick(this.props.orderItem);
        }
    }

    private renderNoteIcon = () => {
        const { formatMessage } = this.props.intl;

        return (
            <Popup flowing={true} trigger={<Icon fitted={true} size="small" name="sticky note" color="teal" circular={true} inverted={true} />}>
                <p>{formatMessage(m.notePopup)}</p>
                <List>
                    <List.Item>
                        <List.Header content={formatMessage(m.itemNotesLabel)} />
                        <List.Content style={{ whiteSpace: 'pre' }}>{this.props.orderItem.notes}</List.Content>
                    </List.Item>
                </List>
            </Popup>
        );
    }
}

const intlComponent = injectIntl(OrderSummaryListItem);
export { intlComponent as OrderSummaryListItem };