import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Modal, ModalProps, Header, Popup, Button } from 'semantic-ui-react';
import { commonMessages } from '../../../constants';

interface OwnProps {
    onConfirm: () => void;
    onCancel: () => void;
}

export type DraftModeConfirmationModalProps =
    & OwnProps
    & ModalProps
    & WrappedComponentProps;

const m = defineMessages({
    draftModeTitle: { id: 'ProblemDescriptionCard.draftModeTitle', defaultMessage: 'The service call will switch to \'Draft\' mode' },
    draftModeMessage: { id: 'ProblemDescriptionCard.draftModeMessage', defaultMessage: 'If you delete the last picture from this service call, it will switch to draft mode and will only be visible to you. Jaymar\'s customer service agents will no longer be able to view and process this service call.' },
    draftModeConfirmation: { id: 'ProblemDescriptionCard.draftModeConfirmation', defaultMessage: 'Are you sure you want to delete the last picture of this service call and switch to draft mode?' },
    draftModeConfirmationButton: { id: 'ProblemDescriptionCard.draftModeConfirmationButton', defaultMessage: 'Yes, switch to draft mode' },
    pictureRequiredReasonTitle: { id: 'ProblemDescriptionCard.pictureRequiredReasonTitle', defaultMessage: 'Why is a picture required?' },
    pictureRequiredReasonTooltip: { id: 'ProblemDescriptionCard.pictureRequiredReasonTooltip', defaultMessage: 'A picture of the furniture and its defect allows our customer service agents to quickly identify the problem and apply the right fix to correct the situation. This allows us to offer you a better, quicker and overall more efficient service.' }
});

class DraftModeConfirmationModal extends React.Component<DraftModeConfirmationModalProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { intl, ...modalProps } = this.props;

        return (
            <Modal
                closeIcon={true}
                {...modalProps}
            >
                <Header content={formatMessage(m.draftModeTitle)} icon="warning sign" />
                <Modal.Content>
                    <p>{formatMessage(m.draftModeMessage)}</p>
                    <p><strong>{formatMessage(m.draftModeConfirmation)}</strong></p>
                </Modal.Content>
                <Modal.Actions>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Popup trigger={<p style={{ color: 'rgba(0,0,0,.45)', margin: 0, textDecoration: 'underline dotted' }}>{formatMessage(m.pictureRequiredReasonTitle)}</p>}>
                            {formatMessage(m.pictureRequiredReasonTooltip)}
                        </Popup>
                        <div style={{ margin: '0 0 0 auto' }}>
                            <Button content={formatMessage(commonMessages.cancel)} onClick={this.props.onCancel} />
                            <Button primary={true} content={formatMessage(m.draftModeConfirmationButton)} onClick={this.props.onConfirm} />
                        </div>
                    </div>
                </Modal.Actions>
            </Modal>
        );
    }
}

const intlComponent = injectIntl(DraftModeConfirmationModal);
export { intlComponent as DraftModeConfirmationModal };