import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Popup, Button, PopupProps, Divider } from 'semantic-ui-react';

import { commonMessages } from '../../constants';

interface FilterPopupOwnProps extends PopupProps {
    hasFiltersApplied?: boolean;
    onApply: () => void;
    onClose?: () => void;
}

interface FilterPopupState {
    isOpen: boolean;
}

export type FilterPopupProps =
    & FilterPopupOwnProps
    & WrappedComponentProps;

const m = defineMessages({
});

class FilterPopup extends React.Component<FilterPopupProps, FilterPopupState> {
    public constructor(props: FilterPopupProps) {
        super(props);

        this.state = { isOpen: false };
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const { intl, trigger, hasFiltersApplied, onApply, onClose, ...popupProps } = this.props;

        return (
            <Popup 
                className="c-filter-popup"
                trigger={this.props.trigger || this.renderDefaultTrigger()}
                flowing={true}
                position="bottom right"
                on="click"
                open={this.state.isOpen}
                onOpen={this.openPopup}
                onClose={this.closePopup}
                style={{ width: '340px' }}
                closeOnDocumentClick={false}
                {...popupProps}
            >
                <Popup.Content>
                    {this.props.children}

                    <Divider />
                    <Button 
                        primary={true} 
                        fluid={true} 
                        content={formatMessage(commonMessages.apply)}
                        onClick={this.applyFilters}
                    />
                </Popup.Content>
            </Popup>
        );
    }

    private renderDefaultTrigger() {
        const { formatMessage } = this.props.intl;

        return (
            <Button 
                primary={true} 
                positive={this.props.hasFiltersApplied}
                content={formatMessage(commonMessages.filter)}
                icon="filter"
            />
        );
    }

    private openPopup = () => {
        this.setState({ isOpen: true });
    }

    private closePopup = () => {
        this.setState({ isOpen: false });

        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    private applyFilters = () => {
        this.props.onApply();
        this.closePopup();
    }
}

const connectedComponent = injectIntl(FilterPopup);
export { connectedComponent as FilterPopup };