import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import { actionCreators as AppointmentsActions } from '../../../state/ducks/appointments';
import { actionCreators as UiActions } from '../../../state/ducks/ui';

import { ApplicationState } from '../../../state/ducks';
import { TechnicianRepair } from '../../../state/models';
import { getAppointmentsByServiceCall } from '../../../state/ducks/appointments/selectors';
import { AppointmentsTable, AppointmentsList } from '../../../components/service-calls';
import { useResponsive } from '../../../utils/responsive';

const m = defineMessages({
    noAppointmentsTitle: { id: 'AllTechnicianAppointmentsPage.no_appointments_title', defaultMessage: 'No appointments' },
    noAppointmentsSubtitle: { id: 'AllTechnicianAppointmentsPage.no_appointments_subtitle', defaultMessage: 'There are no appointments linked to this service call.' }
});

interface RouteProps {
    serviceCallId: string;
}

export const AllTechnicianAppointmentsPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const { isMobile } = useResponsive();
    const { serviceCallId } = useParams<RouteProps>();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(UiActions.changeServiceCallTab('technician'));
    }, [dispatch]);

    useEffect(() => {
        dispatch(AppointmentsActions.loadByServiceCall(Number(serviceCallId)));
    }, [dispatch, serviceCallId]);

    const appointmentsState = useSelector((state: ApplicationState) => getAppointmentsByServiceCall(state, Number(serviceCallId)));
    const appointments = appointmentsState.appointments || [];
    const areAppointmentsLoading = appointmentsState.isLoading;

    const navigateToTechnicianPage = (appointment: TechnicianRepair) =>
        history.push(`/service-calls/${appointment.serviceCallId}/technician-repairs/${appointment.id}/prepare`);

    return (
        <div>
            {!isMobile &&
                <AppointmentsTable
                    appointments={appointments}
                    isLoading={areAppointmentsLoading}
                    onViewDetails={navigateToTechnicianPage}
                    emptyTitle={formatMessage(m.noAppointmentsTitle)}
                    emptySubtitle={formatMessage(m.noAppointmentsSubtitle)}
                />
            }

            {isMobile &&
                <AppointmentsList
                    appointments={appointments}
                    onViewDetails={navigateToTechnicianPage}
                    emptyTitle={formatMessage(m.noAppointmentsTitle)}
                    emptySubtitle={formatMessage(m.noAppointmentsSubtitle)}
                />
            }
        </div>
    );
};