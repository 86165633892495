import { ActionTypeKeys } from './types';
import { NotificationType, ProductionOrder, ProductionComponent, ItemType } from '../../models';
import { ThunkAction } from 'redux-thunk';
import { ApplicationState } from '..';
import * as globalNotification from '../global-notification';
import { ProductionOrdersService } from '../../../services';
import { AppThunk } from '../../store';

export type ActionTypes =
    | LoadByServiceCallRequestAction | LoadByServiceCallSuccessAction | LoadByServiceCallFailureAction
    | LoadByOrderRequestAction | LoadByOrderSuccessAction | LoadByOrderFailureAction
    | ExpandProductionComponentAction
    | CollapseProductionComponentAction
    | SelectProductionComponentAction
    | DeselectProductionComponentAction
    | ChangeProductionComponentQuantityAction
    | { type: '' };

export interface LoadByServiceCallRequestAction { type: ActionTypeKeys.LOAD_BY_SERVICE_CALL_REQUEST; payload: number; }
export interface LoadByServiceCallSuccessAction { type: ActionTypeKeys.LOAD_BY_SERVICE_CALL_SUCCESS; payload: { serviceCallId: number, productionOrders: ProductionOrder[], productionComponents: ProductionComponent[] }; }
export interface LoadByServiceCallFailureAction { type: ActionTypeKeys.LOAD_BY_SERVICE_CALL_FAILURE; payload: { serviceCallId: number, error: string }; }
export interface LoadByOrderRequestAction { type: ActionTypeKeys.LOAD_BY_ORDER_REQUEST; payload: { orderId: number }; }
export interface LoadByOrderSuccessAction { type: ActionTypeKeys.LOAD_BY_ORDER_SUCCESS; payload: { orderId: number, productionOrders: ProductionOrder[], productionComponents: ProductionComponent[] }; }
export interface LoadByOrderFailureAction { type: ActionTypeKeys.LOAD_BY_ORDER_FAILURE; payload: { error: Error }; }
export interface ExpandProductionComponentAction { type: ActionTypeKeys.EXPAND_CHILDREN; payload: { serviceCallId: number, productionComponentId: number }; }
export interface CollapseProductionComponentAction { type: ActionTypeKeys.COLLAPSE_CHILDREN; payload: { serviceCallId: number, productionComponentId: number }; }
export interface SelectProductionComponentAction { type: ActionTypeKeys.SELECT_COMPONENT; payload: { serviceCallId: number, productionComponentId: number }; }
export interface DeselectProductionComponentAction { type: ActionTypeKeys.DESELECT_COMPONENT; payload: { serviceCallId: number, productionComponentId: number }; }
export interface ChangeProductionComponentQuantityAction { type: ActionTypeKeys.CHANGE_COMPONENT_QUANTITY; payload: { serviceCallId: number, productionComponentId: number, newValue: number }; }

const service = new ProductionOrdersService();

export const actionCreators = {
    loadByOrder: (orderId: number): AppThunk => {
        return (dispatch, getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_BY_ORDER_REQUEST, payload: { orderId } });

            service.getByOrder(orderId)
                .then(response => response.json() as ProductionOrder[])
                .then(productionOrders => {
                    const rootComponents = productionOrders.map(x => x.product).filter(x => x != null);
                    const productionComponents = flattenComponents(rootComponents);

                    dispatch({ type: ActionTypeKeys.LOAD_BY_ORDER_SUCCESS, payload: { orderId, productionOrders, productionComponents } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_BY_ORDER_FAILURE, payload: { orderId, error } });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de récupérer l'ordre de fabrication associé à la commande '${orderId}' (${error}).`)
                    );
                });
        };
    },

    loadByServiceCall: (serviceCallId: number): AppThunk => {
        return (dispatch, getState) => {
            const existingProductionOrders = getState().productionOrders.byServiceCall[serviceCallId];

            if (!existingProductionOrders) {
                dispatch({ type: ActionTypeKeys.LOAD_BY_SERVICE_CALL_REQUEST, payload: serviceCallId });

                service.getByServiceCall(serviceCallId)
                    .then(response => response.json() as ProductionOrder[])
                    .then(productionOrders => {
                        const rootComponents = productionOrders.map(x => x.product);
                        const productionComponents = flattenComponents(rootComponents);

                        dispatch({ type: ActionTypeKeys.LOAD_BY_SERVICE_CALL_SUCCESS, payload: { serviceCallId, productionOrders, productionComponents } });
                    })
                    .catch(error => {
                        dispatch({ type: ActionTypeKeys.LOAD_BY_SERVICE_CALL_FAILURE, payload: { serviceCallId, error } });
                        dispatch(globalNotification.actionCreators.showErrorNotification(
                            'Erreur',
                            `Une erreur est survenue en tentant de récupérer l'ordre de fabrication associé à l'appel de service '${serviceCallId}' (${error}).`)
                        );
                    });
            } else {
                const rootComponents = existingProductionOrders.productionOrders.map(x => x.product);
                const productionComponents = flattenComponents(rootComponents);

                dispatch({
                    type: ActionTypeKeys.LOAD_BY_SERVICE_CALL_SUCCESS,
                    payload: { serviceCallId, productionOrders: existingProductionOrders.productionOrders, productionComponents }
                });
            }
        };
    },

    expandProductionComponent: (serviceCallId: number, productionComponentId: number) => {
        return {
            type: ActionTypeKeys.EXPAND_CHILDREN,
            payload: {
                serviceCallId,
                productionComponentId
            }
        };
    },

    collapseProductionComponent: (serviceCallId: number, productionComponentId: number): AppThunk => {
        return (dispatch, getState) => {
            const rootComponent = getState().productionOrders.byServiceCall[serviceCallId].productionComponentTree[productionComponentId];
            const componentWithChildren = flattenComponents(rootComponent ? [rootComponent.productionComponent] : []);

            componentWithChildren.forEach(x => {
                if (x.children.length > 0) {
                    dispatch({
                        type: ActionTypeKeys.COLLAPSE_CHILDREN,
                        payload: {
                            serviceCallId,
                            productionComponentId: x.id
                        }
                    });
                }
            });
        };
    },

    selectProductionComponent: (serviceCallId: number, productionComponentId: number): AppThunk => {
        return (dispatch, getState) => {
            const rootComponent = getState().productionOrders.byServiceCall[serviceCallId].productionComponentTree[productionComponentId];
            const componentWithChildren = flattenComponents(rootComponent ? [rootComponent.productionComponent] : []);

            componentWithChildren.forEach(x => {
                dispatch({
                    type: ActionTypeKeys.SELECT_COMPONENT,
                    payload: {
                        serviceCallId,
                        productionComponentId: x.id
                    }
                });
            });
        };
    },

    deselectProductionComponent: (serviceCallId: number, productionComponentId: number, deselectChildren?: boolean): AppThunk => {
        return (dispatch, getState) => {
            const rootComponent = getState().productionOrders.byServiceCall[serviceCallId].productionComponentTree[productionComponentId];
            const rootComponentChildren = flattenComponents(rootComponent ? [rootComponent.productionComponent] : []).filter(x => x.id !== rootComponent.productionComponent.id);

            dispatch({
                type: ActionTypeKeys.DESELECT_COMPONENT,
                payload: {
                    serviceCallId,
                    productionComponentId: rootComponent.productionComponent.id
                }
            });

            if (deselectChildren) {
                rootComponentChildren.forEach(x => {
                    dispatch({
                        type: ActionTypeKeys.DESELECT_COMPONENT,
                        payload: {
                            serviceCallId,
                            productionComponentId: x.id
                        }
                    });
                });
            }
        };
    },

    changeProductionComponentQuantity: (serviceCallId: number, productionComponentId: number, newValue: number) => {
        return {
            type: ActionTypeKeys.CHANGE_COMPONENT_QUANTITY,
            payload: {
                serviceCallId,
                productionComponentId,
                newValue
            }
        };
    }
};

function flattenComponents(components: ProductionComponent[]): ProductionComponent[] {
    let flatComponents: ProductionComponent[] = [];

    components.forEach(x => {
        flatComponents.push(x);
        flatComponents.push(...flattenComponents(x.children));
    });

    return flatComponents;
}