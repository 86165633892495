import { Order } from './Order';
import { Item } from './Item';
import { Quantity } from './Quantity';

export enum OrderDetailType {
    fabricate = 1,
    stock = 2,
    miscellaneous = 3
}

export enum OrderDetailStatus {
    notConfigured = 1,
    configured = 2,
    processed = 3,
}

export interface OrderDetail {
    id: number;
    orderId: number;
    order: Order;
    type: OrderDetailType;
    status: OrderDetailStatus;
    itemId: string;
    item: Item;
    itemCoverId: string;
    itemCover: Item;
    quantityOrdered: Quantity;
    quantityShipped: Quantity;
    basePrice: number;
    price: number;
    packageCount: number;
    weight: number;
    volume: number;
    notes: string;
    isCombo: boolean;
    isShowroom: boolean;
    isWeb: boolean;
}