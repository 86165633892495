import { useFormContext } from 'react-hook-form';
import { FormattedNumber, useIntl } from 'react-intl';
import { Form } from 'semantic-ui-react';
import { commonMessages } from '../../../../../constants';
import { SubContractorBillFormValues } from '../SubContractorBillSection/SubcontractorBillModal';

export const TotalField = () => {
    const { formatMessage } = useIntl();

    const { watch } = useFormContext<SubContractorBillFormValues>();
    const [repairFees, shippingFees, currencyCode] = watch([
        'repairFees',
        'shippingFees',
        'currencyCode',
    ]);

    return (
        <Form.Field>
            <label>{formatMessage(commonMessages.total)}</label>
            <div style={{ textAlign: 'right' }}>
                <FormattedNumber
                    value={repairFees + shippingFees}
                    style="currency"
                    currency={currencyCode}
                />
            </div>
        </Form.Field>
    );
};
