import * as React from 'react';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { Card, List } from 'semantic-ui-react';

import { defectCategoryMessages, defectTypeMessages } from '../../../constants';
import { ServiceCallDefect, ServiceCallDefectiveItem } from '../../../state/models';
import { EmptyContentSegment, LoadingDimmer } from '../../common';
import { ItemDetailsListItem } from '../../items';

interface RepairsInformationCardOwnProps {
    defectiveItems: ServiceCallDefectiveItem[];
    defects: ServiceCallDefect[];
    isLoading?: boolean;
}

export type RepairsInformationCardProps =
    & RepairsInformationCardOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'RepairsInformationCard.title', defaultMessage: 'Repairs information' },
    subtitle: { id: 'RepairsInformationCard.subtitle', defaultMessage: 'View the details about the repairs.' },
    defectiveItems: { id: 'RepairsInformationCard.defective_items', defaultMessage: 'Defective items' },
    defects: { id: 'RepairsInformationCard.defects', defaultMessage: 'Defects' },
    requiredParts: { id: 'RepairsInformationCard.required_parts', defaultMessage: 'Required parts' },
    noDefectiveItemsTitle: { id: 'RepairsInformationCard.no_defective_items_title', defaultMessage: 'No defective items' },
    noDefectiveItemsDescription: { id: 'RepairsInformationCard.no_defective_items_description', defaultMessage: 'No defective items were specified for this service call.' },
    noDefectsTitle: { id: 'RepairsInformationCard.no_defects', defaultMessage: 'No defects' },
    noDefectsDescription: { id: 'RepairsInformationCard.no_defects_description', defaultMessage: 'No defects were specified for this service call.' }
});

class RepairsInformationCard extends React.Component<RepairsInformationCardProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const hasDefectiveItems = this.props.defectiveItems && this.props.defectiveItems.length > 0 && this.props.defectiveItems.filter(x => x.itemQuantity > 0).length > 0;
        const hasDefects = this.props.defects && this.props.defects.length > 0;

        return (
            <Card fluid={true} color="teal" style={{ height: '100%' }}>
                <Card.Content style={{ flexGrow: 0 }}>
                    <Card.Header content={formatMessage(m.title)} />
                    <Card.Meta content={formatMessage(m.subtitle)} />
                </Card.Content>
                <Card.Content>
                    <LoadingDimmer active={this.props.isLoading} />
                    <div style={{ marginTop: 0 }}>
                        <h5>{formatMessage(m.defectiveItems)}</h5>
                        {hasDefectiveItems &&
                            <List bulleted={true}>
                                {this.renderDefectiveItems()}
                            </List>
                        }

                        {!hasDefectiveItems &&
                            this.renderEmptyDefectiveItems()
                        }

                        <h5>{formatMessage(m.defects)}</h5>
                        {hasDefects &&
                            <List bulleted={true}>
                                {this.renderDefects(this.props.defects)}
                            </List>
                        }

                        {!hasDefects &&
                            this.renderEmptyDefects()
                        }
                    </div>
                </Card.Content>
            </Card>
        );
    }

    private renderDefectiveItems() {
        return this.props.defectiveItems
            .filter(x => x.itemQuantity > 0)
            .map(x => {
                return (
                    <ItemDetailsListItem
                        key={x.id}
                        item={x.item}
                        itemCover={x.itemCover}
                        itemQuantity={x.itemQuantity}
                        defectiveItemQuantity={x.itemQuantity}
                    />
                );
            });
    }

    private renderDefects(defects: ServiceCallDefect[]) {
        const { formatMessage } = this.props.intl;

        if (defects && defects.length > 0) {
            const defect = defects[0].defect;

            const typeMessages = Object.keys(defectTypeMessages).map(key => defectTypeMessages[key]);
            const defectTypeMessage = typeMessages.find(x => x.id === defect.defectType.title);
            const defectType = defectTypeMessage ? formatMessage(defectTypeMessage) : defect.defectType.title;

            const categoryMessages = Object.keys(defectCategoryMessages).map(key => defectCategoryMessages[key]);
            const defectCategoryMessage = categoryMessages.find(x => x.id === defect.defectCategory.title);
            const defectCategory = defectCategoryMessage ? formatMessage(defectCategoryMessage) : defect.defectCategory.title;

            return (
                <List.Item>
                    <span>{defectType}</span>
                    <span style={{ color: 'rgba(0,0,0,0.4)' }}> ({defectCategory})</span>
                </List.Item>
            );
        }

        return (null);
    }

    private renderEmptyDefectiveItems() {
        const { formatMessage } = this.props.intl;

        return (
            <EmptyContentSegment
                basic={true}
                title={formatMessage(m.noDefectiveItemsTitle)}
                description={formatMessage(m.noDefectiveItemsDescription)}
            />
        );
    }

    private renderEmptyDefects() {
        const { formatMessage } = this.props.intl;

        return (
            <EmptyContentSegment
                basic={true}
                title={formatMessage(m.noDefectsTitle)}
                description={formatMessage(m.noDefectsDescription)}
            />
        );
    }
}

const connectedComponent = injectIntl(RepairsInformationCard);
export { connectedComponent as RepairsInformationCard };