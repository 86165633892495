import React from 'react';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, Card, DropdownItemProps, Form, Grid, List, Segment, Dropdown, Icon } from 'semantic-ui-react';
import { User, ServiceCallInspection, AppointmentTimeRange } from '../../state/models';
import { LoadingDimmer, EmptyContentSegment } from '../common';
import { StringHelper } from '../../state/utils';
import { UserHelper } from '../../helpers';
import { commonMessages } from '../../constants';
import { AgentActionItem } from '.';

export interface PlanInspectionCardProps {
    representatives: User[];
    currentInspection?: ServiceCallInspection;
    isLoading?: boolean;
    isReadOnly?: boolean;
    onAssign: (representativeId: string) => void;
    onComplete: () => void;
}

interface PlanInspectionCardState {
    selectedRepresentativeId?: string;
}

const m = defineMessages({
    title: { id: 'PlanInspectionCard.title', defaultMessage: 'Plan an inspection' },
    subtitle: { id: 'PlanInspectionCard.subtitle', defaultMessage: 'Assign the service call to a Jaymar representative to identify the defects on site.' },
    representativeLabel: { id: 'PlanInspectionCard.representative_label', defaultMessage: 'Jaymar Representative' },
    selectRepresentativeStepTitle: { id: 'PlanInspectionCard.select_representative_step_title', defaultMessage: 'Select a Jaymar representative' },
    selectRepresentativeStepDescription: { id: 'PlanInspectionCard.select_representative_step_description', defaultMessage: 'Select the Jaymar representative that will perform the inspection.' },
    confirmStepTitle: { id: 'PlanInspectionCard.confirm_step_title', defaultMessage: 'Confirm the inspection completion' },
    confirmStepDescription: { id: 'PlanInspectionCard.confirm_step_description', defaultMessage: 'Confirm that the furniture was inspected and that the defects were identified.' }
});

class PlanInspectionCard extends React.Component<PlanInspectionCardProps & WrappedComponentProps, PlanInspectionCardState> {
    public constructor(props: PlanInspectionCardProps & WrappedComponentProps) {
        super(props);

        this.state = { selectedRepresentativeId: props.currentInspection ? props.currentInspection.assignedToId : undefined };
    }

    public componentWillReceiveProps(props: PlanInspectionCardProps & WrappedComponentProps) {
        this.setState({ selectedRepresentativeId: props.currentInspection ? props.currentInspection.assignedToId : undefined });
    }

    public render() {
        const { formatMessage } = this.props.intl;

        const hasRepresentativeAssigned = this.props.currentInspection != null;

        return (
            <Card fluid={true} color="orange">
                <Card.Content style={{ flexGrow: 0 }}>
                    <Card.Header content={formatMessage(m.title)} />
                    <Card.Meta content={formatMessage(m.subtitle)} />
                </Card.Content>
                <Card.Content>
                    <LoadingDimmer active={this.props.isLoading} />
                    <List divided={true} relaxed="very">
                        {this.renderSelectStep(!hasRepresentativeAssigned, hasRepresentativeAssigned)}
                        {this.renderConfirmStep(hasRepresentativeAssigned, false)}
                    </List>
                </Card.Content>
            </Card>
        );
    }

    private renderSelectStep(isEnabled: boolean, isCompleted: boolean) {
        const { formatMessage } = this.props.intl;

        const representativesOptions = this.props.representatives.map((x): DropdownItemProps => ({
            id: x.id,
            icon: UserHelper.getIcon(x.accountType),
            text: UserHelper.getDisplayName(x),
            value: x.id
        }));

        const isRepresentativeSelected = StringHelper.hasValue(this.state.selectedRepresentativeId);

        return (
            <AgentActionItem
                isEnabled={isEnabled}
                isCompleted={isCompleted}
                icon="ambulance"
                title={formatMessage(m.selectRepresentativeStepTitle)}
                description={formatMessage(m.selectRepresentativeStepDescription)}
                completeButtonText={formatMessage(commonMessages.select)}
                onCompleteClick={this.assignRepresentative}
                isCompleteButtonDisabled={!isRepresentativeSelected}
                completedButtonText={formatMessage(commonMessages.selected)}
            >
                <Dropdown
                    label={formatMessage(m.representativeLabel)}
                    loading={this.props.isLoading}
                    search={true}
                    selection={true}
                    options={representativesOptions}
                    fluid={true}
                    deburr={true}
                    disabled={!isEnabled}
                    value={this.state.selectedRepresentativeId}
                    onChange={(e, data) => this.setState({ selectedRepresentativeId: data.value as string })}
                />
            </AgentActionItem>
        );
    }

    private renderConfirmStep(isEnabled: boolean, isCompleted: boolean) {
        const { formatMessage } = this.props.intl;

        return (
            <AgentActionItem
                isEnabled={isEnabled}
                isCompleted={isCompleted}
                icon="check"
                title={formatMessage(m.confirmStepTitle)}
                description={formatMessage(m.confirmStepDescription)}
                completeButtonText={formatMessage(commonMessages.confirm)}
                onCompleteClick={this.props.onComplete}
                completedButtonText={formatMessage(commonMessages.confirmed)}
            />
        );
    }

    private assignRepresentative = () => {
        if (this.state.selectedRepresentativeId != null) {
            this.props.onAssign(this.state.selectedRepresentativeId);
        }
    }
}

const intlComponent = injectIntl(PlanInspectionCard);
export { intlComponent as PlanInspectionCard };