import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Segment } from 'semantic-ui-react';

const m = defineMessages({
    emptyMessageTitle: { id: 'NoServiceCalls.empty_message_title', defaultMessage: 'No service calls' },
    emptyMessageContent: { id: 'NoServiceCalls.empty_message_content', defaultMessage: 'Your recent and ongoing service calls will be displayed here.' },
});

export interface NoServiceCallsProps {}

class NoServiceCalls extends React.Component<NoServiceCallsProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <Segment textAlign="center">
                <h3>{formatMessage(m.emptyMessageTitle)}</h3>
                {formatMessage(m.emptyMessageContent)}
            </Segment>
        );
    }
}

const connectedComponent = injectIntl(NoServiceCalls);
export { connectedComponent as NoServiceCalls };