import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { TechnicianReportDescription } from "containers/reporting/types";
import {
  DefectiveItemQueryFilters,
  ServiceCallAnalysisService,
} from "services/ServiceCallAnalysisService";
import * as globalNotification from "../../../../state/ducks/global-notification";
import orderBy from "lodash/orderBy";
import { usePagination } from "containers/reporting/hooks/usePagination";
import { DescriptionRow } from "../DescriptionRow";

const serviceCallAnalysisService = new ServiceCallAnalysisService();

interface TechnicianReportDescriptionsProps {
  filters: DefectiveItemQueryFilters;
}

export const TechnicianReportDescriptions: React.FC<TechnicianReportDescriptionsProps> = ({ filters }) => {  
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [techicianReportDescriptions, setTechnicianDescriptions] = useState<TechnicianReportDescription[]>([]);

  useEffect(() => {
    setIsLoading(true);

    serviceCallAnalysisService
      .getTechnicianReportDescriptions(filters)
      .then((response) => response.json() as TechnicianReportDescription[])
      .then((fetchedDescriptions) => {
        setTechnicianDescriptions(fetchedDescriptions);
      })
      .catch((error) => {
        dispatch(
          globalNotification.actionCreators.showErrorNotification(
            "Erreur",
            `Une erreur est survenue en tentant de récupérer la description des rapports de technicien (${error}).`
          )
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, filters]);

  const sortedRows = useMemo(
    () =>
      orderBy(
        techicianReportDescriptions,
        (row) => row.serviceCallId,
        "asc"
      ),
    [techicianReportDescriptions]
  );

  const { currentPageRows, Paginator } = usePagination(sortedRows);

  return (
    <>
      {!isLoading &&
        currentPageRows.map((row) => (
          <DescriptionRow serviceCallId={row.serviceCallId} description={row.description} />
        ))}
      {!isLoading && <Paginator />}
    </>
  );
};

