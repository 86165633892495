import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Button, Input, InputOnChangeData, Label } from 'semantic-ui-react';
import { unitMessages } from '../../constants';

const defaultMinutesIncrement = 15;
const defaultMinimumValue = 0;
const defaultMaximumValue = 23;

export interface TimeInputProps {
    hours: number;
    minutes: number;
    minutesIncrement?: number;
    minimumValue?: number;
    maximumValue?: number;
    disabled?: boolean;
    onChange: (hours: number, minutes: number) => void;
}

interface TimeInputState {
    rawHours: string;
    rawMinutes: string;
}

class TimeInput extends React.Component<TimeInputProps & WrappedComponentProps, TimeInputState> {
    public constructor(props: TimeInputProps & WrappedComponentProps) {
        super(props);

        this.state = {
            rawHours: String(props.hours),
            rawMinutes: props.minutes < 10 ? '0' + props.minutes : String(props.minutes)
        };
    }

    public componentWillReceiveProps(props: TimeInputProps & WrappedComponentProps) {
        this.setState({
            rawHours: String(props.hours),
            rawMinutes: props.minutes < 10 ? '0' + props.minutes : String(props.minutes)
        });
    }

    public render() {
        return (
            <div className="c-time-input" style={{ display: 'inline-block' }}>
                <Button icon="minus" size="mini" disabled={this.props.disabled} onClick={this.handleDecrement} />
                <Input
                    className="c-time-input__input-container"
                    pattern="\d{1,2}"
                    value={this.state.rawHours}
                    onChange={this.handleHoursChange}
                    onBlur={this.confirmHoursChange}
                    style={{ padding: 5, width: 30 }}
                />
                <span>:</span>
                <Input
                    className="c-time-input__input-container"
                    pattern="\d{1,2}"
                    value={this.state.rawMinutes}
                    onChange={this.handleMinutesChange}
                    onBlur={this.confirmMinutesChange}
                    style={{ padding: 5, width: 30 }}
                />
                <Button icon="plus" size="mini" disabled={this.props.disabled} onClick={this.handleIncrement} />
            </div>
        );
    }

    private handleHoursChange = (event: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) => {
        this.setState({ rawHours: data.value });

        const maximumValue = this.props.maximumValue || defaultMaximumValue;
        const newValue = Number(data.value);

        if (!isNaN(newValue)) {
            this.props.onChange(Math.min(newValue, maximumValue), this.props.minutes);
        }
    }

    private handleMinutesChange = (event: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) => {
        this.setState({ rawMinutes: data.value });

        const maximumValue = 59;
        const newValue = Number(data.value);

        if (!isNaN(newValue)) {
            this.props.onChange(this.props.hours, Math.min(newValue, maximumValue));
        }
    }

    private confirmHoursChange = () => {
        const newValue = Number(this.state.rawHours);
        if (isNaN(newValue)) {
            this.setState({
                rawHours: String(this.props.hours)
            });
        }
    }

    private confirmMinutesChange = () => {
        const newValue = Number(this.state.rawMinutes);
        if (isNaN(newValue)) {
            this.setState({
                rawMinutes: this.props.minutes < 10 ? '0' + this.props.minutes : String(this.props.minutes)
            });
        }
    }

    private handleIncrement = () => {
        const minutesIncrement = this.props.minutesIncrement || defaultMinutesIncrement;
        const actualIncrement = minutesIncrement - (this.props.minutes % minutesIncrement);
        const maximumValue = this.props.maximumValue || defaultMaximumValue;

        let newHours = this.props.hours;
        let newMinutes = this.props.minutes + actualIncrement;

        if (newHours < maximumValue || (newHours === maximumValue && newMinutes < 60)) {
            if (newMinutes >= 60) {
                newMinutes = newMinutes - 60;
                newHours = newHours + 1;
            }

            this.props.onChange(newHours, newMinutes);
        }
    }

    private handleDecrement = () => {
        const minutesIncrement = this.props.minutesIncrement || defaultMinutesIncrement;
        const actualIncrement = this.props.minutes % minutesIncrement !== 0 ? this.props.minutes % minutesIncrement : minutesIncrement;
        const minimumValue = this.props.minimumValue || 0;

        let newHours = this.props.hours;
        let newMinutes = this.props.minutes - actualIncrement;

        if (newHours > minimumValue || (newHours === minimumValue && newMinutes >= 0)) {
            if (newMinutes < 0) {
                newMinutes = 60 + newMinutes;
                newHours = Math.max(0, newHours - 1);
            }

            this.props.onChange(newHours, newMinutes);
        }
    }
}

const intlComponent = injectIntl(TimeInput);
export { intlComponent as TimeInput };