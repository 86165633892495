import * as React from 'react';
import { Button, ButtonProps } from 'semantic-ui-react';

export interface SmallDeleteButtonProps extends ButtonProps {
    onClick?: () => void;
}

export class SmallDeleteButton extends React.Component<SmallDeleteButtonProps, {}> {
    public render() {
        const { onClick, ...buttonProps } = this.props;

        return (
            <Button 
                basic={true}
                negative={true}
                icon="trash" 
                onClick={this.props.onClick} 
                {...buttonProps} 
            />
        );
    }
}

export default SmallDeleteButton;