import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Step, SemanticICONS } from 'semantic-ui-react';

import * as InspectionsActions from '../../../state/ducks/inspections';
import * as UiActions from '../../../state/ducks/ui';

import { ApplicationState } from '../../../state/ducks';
import { ServiceCallInspectionSteps } from '../../../state/ducks/ui/reducers';
import { ServiceCallInspection } from '../../../state/models';
import { getInspectionById } from '../../../state/ducks/inspections/selectors';

interface ServiceCallInspectionPageActions {
    inspectionActions: typeof InspectionsActions.actionCreators;
    uiActions: typeof UiActions.actionCreators;
}

interface ServiceCallInspectionPageOwnProps {
    currentStepId: ServiceCallInspectionSteps;
    inspection: ServiceCallInspection;
}

export type ServiceCallInspectionPageProps =
    & ServiceCallInspectionPageOwnProps
    & ServiceCallInspectionPageActions
    & RouteComponentProps<{ serviceCallId: string, inspectionId: string }>;

type IntlServiceCallInspectionPageProps = ServiceCallInspectionPageProps & WrappedComponentProps;

const m = defineMessages({
    reviewStepTitle: { id: 'ServiceCallInspectionPage.review_step_title', defaultMessage: 'Review' },
    scheduleStepTitle: { id: 'ServiceCallInspectionPage.schedule_step_title', defaultMessage: 'Schedule' },
    inspectStepTitle: { id: 'ServiceCallInspectionPage.inspect_step_title', defaultMessage: 'Inspect' },
});

class ServiceCallInspectionPage extends React.Component<IntlServiceCallInspectionPageProps, {}> {
    public componentDidMount() {
        const { inspectionId } = this.props.match.params;
        this.props.uiActions.changeServiceCallTab('inspection');
        this.props.inspectionActions.loadById(Number(inspectionId));
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const isScheduleStepComplete = this.props.inspection != null && this.props.inspection.scheduledForDate != null;
        const isInspectStepComplete = this.props.inspection != null && this.props.inspection.completedOn != null;

        return (
            <React.Fragment>
                <Step.Group widths={3} size="small" style={{ marginBottom: '1rem' }} unstackable={true}>
                    {this.renderStep('review', 'clipboard', formatMessage(m.reviewStepTitle), true, false)}
                    {this.renderStep('schedule', 'calendar', formatMessage(m.scheduleStepTitle), isScheduleStepComplete, false)}
                    {this.renderStep('inspect', 'eye', formatMessage(m.inspectStepTitle), isInspectStepComplete, !isScheduleStepComplete)}
                </Step.Group>
            </React.Fragment>
        );
    }

    private renderStep(stepId: ServiceCallInspectionSteps, icon: SemanticICONS, title: string, isCompleted: boolean, isDisabled: boolean) {
        return (
            <Step
                id={stepId}
                icon={icon}
                title={title}
                disabled={isDisabled}
                active={this.props.currentStepId === stepId}
                completed={isCompleted}
                onClick={() => this.handleStepChange(stepId)}
            />
        );
    }

    private handleStepChange = (stepId: ServiceCallInspectionSteps) => {
        const { serviceCallId, inspectionId } = this.props.match.params;

        this.props.uiActions.changeInspectionStep(stepId);
        this.props.history.push(`/service-calls/${serviceCallId}/inspections/${inspectionId}/${stepId}`);
    }
}

const mapStateToProps = (state: ApplicationState, props: ServiceCallInspectionPageProps): ServiceCallInspectionPageOwnProps => {
    const { inspectionId } = props.match.params;

    return {
        currentStepId: state.ui.currentInspectionStepId,
        inspection: getInspectionById(state, Number(inspectionId))
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ServiceCallInspectionPageActions => {
    return {
        inspectionActions: bindActionCreators(InspectionsActions.actionCreators, dispatch),
        uiActions: bindActionCreators(UiActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(ServiceCallInspectionPage);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as ServiceCallInspectionPage };