import { UploadFileControl } from 'components/common';
import { Controller, useFormContext } from 'react-hook-form';
import { Form } from 'semantic-ui-react';
import { UploadMediaFormValues } from './UploadMediaModal';

export const UploadMediaInputField = () => {
    const { control } = useFormContext<UploadMediaFormValues>();

    return (
        <Form.Field>
            <Controller
                control={control}
                name="medias"
                render={({ field: { value, onChange } }) => (
                    <UploadFileControl
                        onFilesUpload={(files) =>
                            onChange([...(value || []), ...Array.from(files)])
                        }
                        acceptedFileTypes="image/*"
                        isUploading={false}
                    />
                )}
            />
        </Form.Field>
    );
};
