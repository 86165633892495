import { ApplicationState } from '..';
import { statisticsMenuItem } from '../../../routes/navigation-items/common';
import { ServiceCallInspection } from '../../models';

export function getUpcomingInspections(state: ApplicationState): ServiceCallInspection[] {
    return state.inspections.upcomingIds.map(x => state.inspections.byId[x]);
}

export function areUpcomingInspectionsLoading(state: ApplicationState): boolean {
    return state.inspections.areUpcomingLoading;
}

export function getUnscheduledInspections(state: ApplicationState): ServiceCallInspection[] {
    return state.inspections.unscheduledIds.map(x => state.inspections.byId[x]);
}

export function areUnscheduledInspectionsLoading(state: ApplicationState): boolean {
    return state.inspections.areUnscheduledLoading;
}

export function getCompletedInspections(state: ApplicationState): ServiceCallInspection[] {
    return state.inspections.completedIds.map(x => state.inspections.byId[x]);
}

export function areCompletedInspectionsLoading(state: ApplicationState): boolean {
    return state.inspections.areCompletedLoading;
}

export function getInspectionById(state: ApplicationState, inspectionId: number): ServiceCallInspection {
    return state.inspections.byId[inspectionId];
}

export function isInspectionLoading(state: ApplicationState, inspectionId: number): boolean {
    return state.inspections.loadingIds.filter(x => x === inspectionId).length > 0;
}

export function getInspectionsForCalendarDate(state: ApplicationState, startDate: Date): ServiceCallInspection[] {
    const inspectionIds = state.inspections.byMonth[startDate.toISOString()];
    return inspectionIds ? inspectionIds.map(x => state.inspections.byId[x]) : [];
}

export function areInspectionsForCalendarDateLoading(state: ApplicationState, startDate: Date): boolean {
    return state.inspections.loadingMonths.filter(x => x === startDate.toISOString()).length > 0;
}

export function getInspectionsCalendarStartDate(state: ApplicationState): Date {
    return state.inspections.calendarStartDate;
}