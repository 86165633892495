import { ActionTypeKeys } from './types';
import { TechnicianReport } from '../../models';
import { ThunkAction } from 'redux-thunk';
import { ApplicationState } from '..';
import * as globalNotification from '../global-notification';
import { TechnicianService } from '../../../services/TechnicianService';
import { AppThunk } from '../../store';

export type ActionTypes =
    | LoadReportRequestAction
    | LoadReportSuccessAction
    | LoadReportFailureAction
    | SaveReportRequestAction
    | SaveReportSuccessAction
    | SaveReportFailureAction
    | { type: '' };

export interface LoadReportRequestAction { type: ActionTypeKeys.LOAD_REPORT_REQUEST; payload: number; }
export interface LoadReportSuccessAction { type: ActionTypeKeys.LOAD_REPORT_SUCCESS; payload: { repairId: number, report: TechnicianReport; }; }
export interface LoadReportFailureAction { type: ActionTypeKeys.LOAD_REPORT_FAILURE; payload: { repairId: number, error: string; }; }
export interface SaveReportRequestAction { type: ActionTypeKeys.SAVE_REPORT_REQUEST; payload: number; }
export interface SaveReportSuccessAction { type: ActionTypeKeys.SAVE_REPORT_SUCCESS; payload: { repairId: number, report: TechnicianReport; }; }
export interface SaveReportFailureAction { type: ActionTypeKeys.SAVE_REPORT_FAILURE; payload: { repairId: number, error: string; }; }

const service = new TechnicianService();

export const actionCreators = {
    loadReport: (repairId: number): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_REPORT_REQUEST });

            service.getReport(repairId)
                .then(response => response.json() as TechnicianReport)
                .then(report => {
                    dispatch({ type: ActionTypeKeys.LOAD_REPORT_SUCCESS, payload: { repairId, report } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_REPORT_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de récupérer le rapport du technicien pour la réparation '${repairId}' (${error}).`)
                    );
                });
        };
    },

    saveReport: (repairId: number, report: TechnicianReport): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.SAVE_REPORT_REQUEST });

            service.saveReport(repairId, report)
                .then(response => response.json() as TechnicianReport)
                .then(() => {
                    dispatch({ type: ActionTypeKeys.SAVE_REPORT_SUCCESS, payload: { repairId, report } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.SAVE_REPORT_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de sauvegarder le rapport du technicien pour la réparation '${repairId}' (${error}).`)
                    );
                });
        };
    }
};