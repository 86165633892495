import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { InspectionReportDescription } from "containers/reporting/types";
import {
  DefectiveItemQueryFilters,
  ServiceCallAnalysisService,
} from "services/ServiceCallAnalysisService";
import * as globalNotification from "../../../../state/ducks/global-notification";
import orderBy from "lodash/orderBy";
import { usePagination } from "containers/reporting/hooks/usePagination";
import { DescriptionRow } from "../DescriptionRow";

const serviceCallAnalysisService = new ServiceCallAnalysisService();

interface InspectionReportDescriptionsProps {
  filters: DefectiveItemQueryFilters;
}

export const InspectionReportDescriptions: React.FC<InspectionReportDescriptionsProps> = ({ filters }) => {  
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [inspectionDescriptions, setInspectionDescriptions] = useState<InspectionReportDescription[]>([]);

  useEffect(() => {
    setIsLoading(true);

    serviceCallAnalysisService
      .getInspectionReportDescriptions(filters)
      .then((response) => response.json() as InspectionReportDescription[])
      .then((fectechedDescriptions) => {
        setInspectionDescriptions(fectechedDescriptions);
      })
      .catch((error) => {
        dispatch(
          globalNotification.actionCreators.showErrorNotification(
            "Erreur",
            `Une erreur est survenue en tentant de récupérer la description des inspections (${error}).`
          )
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, filters]);

  const sortedRows = useMemo(
    () =>
      orderBy(
        inspectionDescriptions,
        (row) => row.serviceCallId,
        "asc"
      ),
    [inspectionDescriptions]
  );

  const { currentPageRows, Paginator } = usePagination(sortedRows);

  return (
    <>
      {!isLoading &&
        currentPageRows.map((row) => (
          <DescriptionRow serviceCallId={row.serviceCallId} description={row.description} />
        ))}
      {!isLoading && <Paginator />}
    </>
  );
};

