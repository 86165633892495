import { ApiRegistration, ApiWebhook, WebhookType } from '../state/ducks/current-user/types';
import { get, httpDelete, post } from '../utils/api';

const endpoint = `/api/public-api`;

export class PublicApiService {
    public getCurrentRegistration(): Promise<ApiRegistration> {
        return get<ApiRegistration>(`${endpoint}/registrations`);
    }

    public register(): Promise<ApiRegistration> {
        return post<ApiRegistration>(`${endpoint}/registrations`, {});
    }

    public revoke(): Promise<void> {
        return httpDelete(`${endpoint}/registrations`);
    }

    public getWebhooks(): Promise<ApiWebhook[]> {
        return get<ApiWebhook[]>(`${endpoint}/webhooks`);
    }

    public updateWebhook(type: WebhookType, url: string): Promise<ApiWebhook> {
        return post<ApiWebhook>(`${endpoint}/webhooks`, { type, url });
    }
}