import React, { CSSProperties } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Dropdown, DropdownItemProps, DropdownProps } from "semantic-ui-react";
import { getCustomerServiceAgents } from "../../../state/ducks/users";
import { FilterOptions } from "../../../state/models";
import { FilterSection } from "./FilterSection";

interface OwnProps {
    filters: FilterOptions;
    onChange: (updatedFilters: Partial<FilterOptions>) => void;
    style?: CSSProperties;
}

export type CustomerServiceAgentSectionProps =
    & OwnProps;

const m = defineMessages({
    filterByAgent: { id: 'FilterPopup.filter_by_agent', defaultMessage: 'Filter by agent' },
    filterByAgentPlaceholder: { id: 'FilterPopup.filter_by_agent_placeholder', defaultMessage: 'Select one or many agents to filter'},
});

export const CustomerServiceAgentSection: React.FC<CustomerServiceAgentSectionProps> = (props) => {
    const { formatMessage } = useIntl();
    const allCustomerServiceAgents = useSelector(getCustomerServiceAgents);
    const agentDropdownOptions = allCustomerServiceAgents.map((x): DropdownItemProps => ({
        id: x.id,
        icon: 'doctor',
        text: `${x.firstName} ${x.lastName}`,
        value: x.email
    }));

    const selectedAgentEmails = (props.filters.selectedAgentsFilter || []).map(selectedAgent => selectedAgent.email);


    const clearAgent = () => props.onChange(({ selectedAgentsFilter: [] }));
    const changeAgent = (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const selectedAgentEmails = data.value as string[];
        const selectedAgents = allCustomerServiceAgents.filter(agent => selectedAgentEmails.find(selectedEmail => agent.email === selectedEmail) != null);
        props.onChange(({ selectedAgentsFilter: selectedAgents }));
    }

    return (
        <FilterSection title={formatMessage(m.filterByAgent)} onClear={clearAgent} style={props.style}>
            <Dropdown 
                fluid
                options={agentDropdownOptions}
                selection={true}
                multiple={true}
                closeOnChange={true}
                placeholder={formatMessage(m.filterByAgentPlaceholder)}
                onChange={changeAgent}
                value={selectedAgentEmails}
            />
        </FilterSection>
    );
};