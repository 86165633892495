import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedMessage, FormattedNumber } from 'react-intl';
import { Feed, Popup, List } from 'semantic-ui-react';

import { PartsReplacedUpdate } from '../../../state/models';
import { StringHelper } from '../../../state/utils';

export interface PartsReplacedActivityProps {
    activity: PartsReplacedUpdate;
}

const m = defineMessages({
    summary: { id: 'PartsReplacedActivity.summary', defaultMessage: 'Some parts were replaced to repair the defect.' },
    partsCostFormat: { id: 'PartsReplacedActivity.parts_cost_format', defaultMessage: 'The replacement cost {amount}' },
    shippingCostFormat: { id: 'PartsReplacedActivity.shipping_cost_format', defaultMessage: 'The shipping cost {amount}' }
});

class PartsReplacedActivity extends React.Component<PartsReplacedActivityProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { activity } = this.props;

        const replacementCost = <FormattedNumber value={activity.replacementCost} style="currency" currency={activity.currencyCode} />;
        const replacementCostDescription = <FormattedMessage {...m.partsCostFormat} values={{ 'amount': replacementCost }} />;
        const shippingCost = <FormattedNumber value={activity.shippingCost} style="currency" currency={activity.currencyCode} />;
        const shippingCostDescription = <FormattedMessage {...m.shippingCostFormat} values={{ 'amount': shippingCost }} />;

        return (
            <Feed.Event key={activity.id}>
                <Feed.Label icon="exchange" />
                <Feed.Content>
                    <Popup
                        trigger={<Feed.Date content={moment.utc(activity.createdOn).fromNow()} />}
                        content={moment.utc(activity.createdOn).format('LL')}
                    />

                    <Feed.Summary>
                        <FormattedMessage {...m.summary} />
                    </Feed.Summary>

                    <Feed.Extra text={true}>
                        <List bulleted={true}>
                            {activity.replacementCost > 0 && <List.Item content={replacementCostDescription} />}
                            {activity.shippingCost > 0 && <List.Item content={shippingCostDescription} />}
                        </List>

                        {StringHelper.hasValue(activity.note) &&
                            <p style={{ whiteSpace: 'pre-line' }}>{activity.note}</p>
                        }
                    </Feed.Extra>
                </Feed.Content>
            </Feed.Event>
        );
    }
}

const connectedComponent = injectIntl(PartsReplacedActivity);
export { connectedComponent as PartsReplacedActivity };