import { ActionTypeKeys } from './types';
import { NotificationType, TechnicianRepair } from '../../models';
import { ThunkAction } from 'redux-thunk';
import { ApplicationState } from '..';
import * as globalNotification from '../global-notification';
import { AppointmentService } from '../../../services';
import { AppThunk } from '../../store';

export type ActionTypes =
    | LoadForTechniciansRequest
    | LoadForTechniciansSuccess
    | LoadForTechniciansFailure
    | LoadMineRequest
    | LoadMineSuccess
    | LoadMineFailure
    | LoadByIdRequest
    | LoadByIdSuccess
    | LoadByIdFailure
    | LoadByServiceCallRequest
    | LoadByServiceCallSuccess
    | LoadByServiceCallFailure
    | LoadPendingRequest
    | LoadPendingSuccess
    | LoadPendingFailure
    | LoadTodayRequest
    | LoadTodaySuccess
    | LoadTodayFailure
    | LoadThisWeekRequest
    | LoadThisWeekSuccess
    | LoadThisWeekFailure
    | LoadCompletedRequest
    | LoadCompletedSuccess
    | LoadCompletedFailure
    | LoadAllMineRequest
    | LoadAllMineSuccess
    | LoadAllMineFailure
    | SetCalendarStartDate
    | { type: '' };

export interface LoadForTechniciansRequest { type: ActionTypeKeys.LOAD_FOR_TECHNICIANS_REQUEST; payload: Date; }
export interface LoadForTechniciansSuccess { type: ActionTypeKeys.LOAD_FOR_TECHNICIANS_SUCCESS; payload: { startDate: Date, results: TechnicianRepair[] }; }
export interface LoadForTechniciansFailure { type: ActionTypeKeys.LOAD_FOR_TECHNICIANS_FAILURE; payload: { startDate: Date, error: string }; }
export interface LoadMineRequest { type: ActionTypeKeys.LOAD_MINE_REQUEST; payload: Date; }
export interface LoadMineSuccess { type: ActionTypeKeys.LOAD_MINE_SUCCESS; payload: { startDate: Date, results: TechnicianRepair[] }; }
export interface LoadMineFailure { type: ActionTypeKeys.LOAD_MINE_FAILURE; payload: { startDate: Date, error: string }; }
export interface LoadByIdRequest { type: ActionTypeKeys.LOAD_BY_ID_REQUEST; payload: number; }
export interface LoadByIdSuccess { type: ActionTypeKeys.LOAD_BY_ID_SUCCESS; payload: { id: number, result: TechnicianRepair }; }
export interface LoadByIdFailure { type: ActionTypeKeys.LOAD_BY_ID_FAILURE; payload: { id: number, error: string }; }
export interface LoadByServiceCallRequest { type: ActionTypeKeys.LOAD_BY_SERVICE_CALL_REQUEST; payload: number; }
export interface LoadByServiceCallSuccess { type: ActionTypeKeys.LOAD_BY_SERVICE_CALL_SUCCESS; payload: { id: number, result: TechnicianRepair[] }; }
export interface LoadByServiceCallFailure { type: ActionTypeKeys.LOAD_BY_SERVICE_CALL_FAILURE; payload: { id: number, error: string }; }
export interface LoadPendingRequest { type: ActionTypeKeys.LOAD_PENDING_REQUEST; }
export interface LoadPendingSuccess { type: ActionTypeKeys.LOAD_PENDING_SUCCESS; payload: TechnicianRepair[]; }
export interface LoadPendingFailure { type: ActionTypeKeys.LOAD_PENDING_FAILURE; payload: { startDate: Date, error: string }; }
export interface LoadTodayRequest { type: ActionTypeKeys.LOAD_TODAY_REQUEST; }
export interface LoadTodaySuccess { type: ActionTypeKeys.LOAD_TODAY_SUCCESS; payload: TechnicianRepair[]; }
export interface LoadTodayFailure { type: ActionTypeKeys.LOAD_TODAY_FAILURE; payload: { startDate: Date, error: string }; }
export interface LoadThisWeekRequest { type: ActionTypeKeys.LOAD_THIS_WEEK_REQUEST; }
export interface LoadThisWeekSuccess { type: ActionTypeKeys.LOAD_THIS_WEEK_SUCCESS; payload: TechnicianRepair[]; }
export interface LoadThisWeekFailure { type: ActionTypeKeys.LOAD_THIS_WEEK_FAILURE; payload: { startDate: Date, error: string }; }
export interface LoadCompletedRequest { type: ActionTypeKeys.LOAD_COMPLETED_REQUEST; }
export interface LoadCompletedSuccess { type: ActionTypeKeys.LOAD_COMPLETED_SUCCESS; payload: TechnicianRepair[]; }
export interface LoadCompletedFailure { type: ActionTypeKeys.LOAD_COMPLETED_FAILURE; payload: { startDate: Date, error: string }; }
export interface LoadAllMineRequest { type: ActionTypeKeys.LOAD_ALL_MINE_REQUEST; }
export interface LoadAllMineSuccess { type: ActionTypeKeys.LOAD_ALL_MINE_SUCCESS; payload: TechnicianRepair[]; }
export interface LoadAllMineFailure { type: ActionTypeKeys.LOAD_ALL_MINE_FAILURE; payload: { startDate: Date, error: string }; }
export interface SetCalendarStartDate { type: ActionTypeKeys.SET_CALENDAR_START_DATE; payload: Date; }

const service = new AppointmentService();

export const actionCreators = {
    loadForTechnicians: (startDate: Date, endDate: Date): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_FOR_TECHNICIANS_REQUEST, payload: startDate });

            service.getForTechnicians(startDate, endDate)
                .then(response => response.json() as TechnicianRepair[])
                .then(results => {
                    dispatch({ type: ActionTypeKeys.LOAD_FOR_TECHNICIANS_SUCCESS, payload: { startDate: startDate, results: results } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_FOR_TECHNICIANS_FAILURE, payload: { startDate: startDate, error: error } });
                });
        };
    },

    loadbyId: (id: number): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_BY_ID_REQUEST, payload: id });

            service.getById(id)
                .then(response => response.json() as TechnicianRepair)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.LOAD_BY_ID_SUCCESS, payload: { id, result } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_BY_ID_FAILURE, payload: { id, error } });
                });
        };
    },

    loadByServiceCall: (id: number): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_BY_SERVICE_CALL_REQUEST, payload: id });

            service.getByServiceCall(id)
                .then(response => response.json() as TechnicianRepair[])
                .then(result => {
                    dispatch({ type: ActionTypeKeys.LOAD_BY_SERVICE_CALL_SUCCESS, payload: { id, result } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_BY_SERVICE_CALL_FAILURE, payload: { id, error } });
                });
        };
    },

    loadMine: (startDate: Date, endDate: Date): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_MINE_REQUEST, payload: startDate });

            service.getMine(startDate, endDate)
                .then(response => response.json() as TechnicianRepair[])
                .then(results => {
                    dispatch({ type: ActionTypeKeys.LOAD_MINE_SUCCESS, payload: { startDate: startDate, results: results } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_MINE_FAILURE, payload: { startDate: startDate, error: error } });
                });
        };
    },

    loadPending: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_PENDING_REQUEST });

            service.getPending()
                .then(response => response.json() as TechnicianRepair[])
                .then(results => {
                    dispatch({ type: ActionTypeKeys.LOAD_PENDING_SUCCESS, payload: results });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_PENDING_FAILURE, payload: error });
                });
        };
    },

    loadToday: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_TODAY_REQUEST });

            service.getTodays()
                .then(response => response.json() as TechnicianRepair[])
                .then(results => {
                    dispatch({ type: ActionTypeKeys.LOAD_TODAY_SUCCESS, payload: results });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_TODAY_FAILURE, payload: error });
                });
        };
    },

    loadThisWeek: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_THIS_WEEK_REQUEST });

            service.getThisWeeks()
                .then(response => response.json() as TechnicianRepair[])
                .then(results => {
                    dispatch({ type: ActionTypeKeys.LOAD_THIS_WEEK_SUCCESS, payload: results });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_THIS_WEEK_FAILURE, payload: error });
                });
        };
    },

    loadCompleted: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_COMPLETED_REQUEST });

            service.getCompleted()
                .then(response => response.json() as TechnicianRepair[])
                .then(results => {
                    dispatch({ type: ActionTypeKeys.LOAD_COMPLETED_SUCCESS, payload: results });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_COMPLETED_FAILURE, payload: error });
                });
        };
    },

    loadAllMine: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_ALL_MINE_REQUEST });

            service.getMine(new Date(2000, 1, 1), new Date(9999, 1, 1))
                .then(response => response.json() as TechnicianRepair[])
                .then(results => {
                    dispatch({ type: ActionTypeKeys.LOAD_ALL_MINE_SUCCESS, payload: results });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_ALL_MINE_FAILURE, payload: error });
                });
        };
    },

    setCalendarStartDate: (startDate: Date) => {
        return {
            type: ActionTypeKeys.SET_CALENDAR_START_DATE,
            payload: startDate
        };
    }
};