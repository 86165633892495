import { Reducer } from 'redux';
import { ActionTypes } from './actions';
import { ActionTypeKeys } from './types';
import { NotificationType, Defect, TechnicianReport } from '../../models';

export interface TechniciansState {
    reports: {
        [repairId: number]: TechnicianReportState
    };
}

export interface TechnicianReportState {
    isLoading: boolean;
    report: TechnicianReport;
}

const initialState: TechniciansState = {
    reports: {}
};

export const techniciansReducer: Reducer<TechniciansState> = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case ActionTypeKeys.LOAD_REPORT_REQUEST:
            return {
                ...state,
                reports: {
                    ...state.reports,
                    [action.payload]: {
                        ...state.reports[action.payload],
                        isLoading: true
                    }
                }
            };

        case ActionTypeKeys.LOAD_REPORT_SUCCESS:
            return {
                ...state,
                reports: {
                    ...state.reports,
                    [action.payload.repairId]: {
                        ...state.reports[action.payload.repairId],
                        report: action.payload.report,
                        isLoading: false
                    }
                }
            };

        case ActionTypeKeys.LOAD_REPORT_FAILURE:
            return {
                ...state,
                reports: {
                    ...state.reports,
                    [action.payload.repairId]: {
                        ...state.reports[action.payload.repairId],
                        isLoading: false
                    }
                }
            };

        case ActionTypeKeys.SAVE_REPORT_SUCCESS:
            return {
                ...state,
                reports: {
                    ...state.reports,
                    [action.payload.repairId]: {
                        ...state.reports[action.payload.repairId],
                        report: action.payload.report
                    }
                }
            };

        default:
            return state;
    }
};

export default techniciansReducer;