import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedMessage } from 'react-intl';
import { Feed, Popup } from 'semantic-ui-react';

import { ServiceCallUpdate, User } from '../../../state/models';
import { UserHelper } from '../../../helpers';

export interface ServiceCallCreatedActivityProps {
    activity: ServiceCallUpdate;
}

const m = defineMessages({
    title: { id: 'ServiceCallRecentActivity.service_call_created', defaultMessage: 'The service call #{serviceCallLink} was created.' },
    titleWithCreatedBy: { id: 'ServiceCallRecentActivity.service_call_created_by', defaultMessage: 'The service call #{serviceCallLink} was created by {name}.' },
});

class ServiceCallCreatedActivity extends React.Component<ServiceCallCreatedActivityProps & WrappedComponentProps, {}> {
    public render() {
        const { activity } = this.props;
        const summaryValues = {
            'serviceCallLink': (<a href={`/service-calls/${activity.serviceCallId}/details`}>{activity.serviceCallId}</a>),
            'name': UserHelper.getDisplayName(this.props.activity.createdBy)
        };

        return (
            <Feed.Event className="meta" key={activity.id}>
                <Feed.Label icon="plus" />
                <Feed.Content>
                    <Popup
                        trigger={<Feed.Date content={moment.utc(activity.createdOn).fromNow()} />}
                        content={moment.utc(activity.createdOn).format('LL')}
                    />

                    <Feed.Summary>
                        {this.props.activity.createdBy && <FormattedMessage {...m.titleWithCreatedBy} values={summaryValues} />}
                        {!this.props.activity.createdBy && <FormattedMessage {...m.title} values={summaryValues} />}
                    </Feed.Summary>
                </Feed.Content>
            </Feed.Event>
        );
    }
}

const connectedComponent = injectIntl(ServiceCallCreatedActivity);
export { connectedComponent as ServiceCallCreatedActivity };