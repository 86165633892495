import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';

import * as SearchActions from '../../state/ducks/search';

import { ApplicationState } from '../../state/ducks';
import { Search, SearchProps, SearchResultProps, Label, SearchResultData, Popup, Icon } from 'semantic-ui-react';
import { Bill } from '../../state/models';
import { debounce, StringHelper } from '../../state/utils';

interface BillSearchControlActions {
    searchActions: typeof SearchActions.actionCreators;
}

interface BillSearchControlOwnProps {
    clientId: string | undefined;
    defaultValue?: Bill;
    value?: Bill;
    onResultMouseOver?: (result: Bill) => void;
    onResultSelected: (result: Bill) => void;
}

interface BillSearchControlConnectedProps {
    isSearching: boolean;
    results: Bill[];
}

interface BillSearchControlState {
    isDebouncing: boolean;
    searchTerm: string | undefined;
}

interface BillSearchResult {
    bill: Bill;
    title: string;
}

export type BillSearchControlProps =
    & BillSearchControlConnectedProps
    & BillSearchControlOwnProps
    & BillSearchControlActions
    & WrappedComponentProps;

const m = defineMessages({
    resultTitleFormat: { id: 'BillSearchControl.result_title_format', defaultMessage: 'Bill #{number}' },
    purchaseOrderFormat: { id: 'BillSearchControl.purchase_order_format', defaultMessage: 'PO #{number}' },
    placeholder: { id: 'BillSearchControl.placeholder', defaultMessage: 'Search for an order by bill number, purchase order, tag, etc.' },
    noResults: { id: 'BillSearchControl.no_results', defaultMessage: 'No orders found' },
    noResultsDescription: { id: 'BillSearchControl.no_results_description', defaultMessage: 'Need help? Contact our customer service at 1-888-529-6271 #2696' }
});

class BillSearchControl extends React.Component<BillSearchControlProps, BillSearchControlState> {
    public constructor(props: BillSearchControlProps) {
        super(props);

        this.state = {
            isDebouncing: false,
            searchTerm: props.value != null ? this.buildSearchResultTitle(props.value) : ''
        };
    }

    public componentWillReceiveProps(props: BillSearchControlProps) {
        if (props.value !== this.props.value) {
            this.setState({
                searchTerm: props.value != null ? this.buildSearchResultTitle(props.value) : ''
            });
        }
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const results = this.props.results.map(this.buildSearchResult);

        // We consider that we are searching while the search is debouncing. This is done
        // in order to prevent the "No results" message from appearing when the user start
        // typing a few characters before the debounce delay is reached.
        const isSearching = this.props.isSearching || this.state.isDebouncing;

        return (
            <Search
                fluid={true}
                results={results}
                defaultValue={this.props.defaultValue ? this.buildSearchResultTitle(this.props.defaultValue) : undefined}
                showNoResults={!isSearching}
                placeholder={formatMessage(m.placeholder)}
                noResultsMessage={formatMessage(m.noResults)}
                noResultsDescription={formatMessage(m.noResultsDescription)}
                resultRenderer={this.renderSearchResult}
                onSearchChange={this.handleSearchChange}
                onResultSelect={this.handleResultSelect}
                loading={isSearching}
                value={this.state.searchTerm}
            />
        );
    }

    private buildSearchResult = (bill: Bill): BillSearchResult => {
        // We need to wrap the bill inside an other object, because the Semantic UI control will append the results directly to the
        // SearchResultProps otherwise, resulting into clashing properties, such as the 'id' property.
        return {
            bill: bill,
            title: this.buildSearchResultTitle(bill)
        };
    }

    private buildSearchResultTitle = (bill: Bill): string => {
        const { formatMessage } = this.props.intl;
        return formatMessage(m.resultTitleFormat, { number: bill.id });
    }

    // tslint:disable-next-line:no-any
    private renderSearchResult = (props: SearchResultProps): React.ReactElement<any> => {
        const { formatMessage } = this.props.intl;
        const bill = props.bill as Bill;
        const openedServiceCalls = bill != null && bill.serviceCalls != null ? bill.serviceCalls.filter(x => x.closedOn == null).length : 0;

        const renderedBillResult = (
            <React.Fragment key={bill.id}>
                {bill.purchaseOrder && <Label content={formatMessage(m.purchaseOrderFormat, { number: bill.purchaseOrder })} />}
                <span className="text">{this.buildSearchResultTitle(bill)}</span>
                {openedServiceCalls > 0 && <Icon circular={true} size="small" color="yellow" inverted={true} name="phone" style={{ float: 'right' }} />}
            </React.Fragment>
        );

        return (
            <React.Fragment key={bill.id}>
                {this.props.onResultMouseOver != null &&
                    <a onMouseOver={() => this.props.onResultMouseOver!(bill)} style={{ color: 'black' }}>
                        {renderedBillResult}
                    </a>
                }

                {this.props.onResultMouseOver == null &&
                    <React.Fragment>
                        {renderedBillResult}
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }

    private performSearch(searchTerm: string) {
        this.props.searchActions.findBill(this.props.clientId || '', searchTerm);
    }

    // tslint:disable-next-line:no-any
    private handleSearchDebouncing = (args?: any) => {
        const data: SearchProps = args[1] as SearchProps;
        this.setState({
            isDebouncing: true,
            searchTerm: data.value as string
        });
    }

    private handleSearchDebounced = () => {
        this.setState({ isDebouncing: false });
    }

    // tslint:disable-next-line:member-ordering
    private handleSearchChange = debounce(
        (_event: React.MouseEvent<HTMLElement>, data: SearchProps) => {
            if (StringHelper.hasValue(data.value)) {
                this.performSearch(data.value as string);
            }
        },
        900,
        this.handleSearchDebouncing,
        this.handleSearchDebounced);

    private handleResultSelect = (_event: React.MouseEvent<HTMLDivElement>, data: SearchResultData) => {
        const bill = data.result.bill as Bill;
        this.setState({ searchTerm: bill ? this.buildSearchResultTitle(bill) : '' });

        if (this.props.onResultSelected) {
            this.props.onResultSelected(bill);
        }
    }
}

const mapStateToProps = (state: ApplicationState, _props: BillSearchControlOwnProps): BillSearchControlConnectedProps => {
    return {
        isSearching: state.search.isSearchingForBills,
        results: state.search.billsSearchResult,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): BillSearchControlActions => {
    return {
        searchActions: bindActionCreators(SearchActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(BillSearchControl);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as BillSearchControl };