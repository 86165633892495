import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';

import { PageHeader } from '../../components/common';
import { FilterPopup } from '../../components/service-calls';
import { actionCreators as ServiceCallsActions } from '../../state/ducks/service-calls/actions';
import { ServiceCallTable, ServiceCallList } from '../../components/service-calls';
import { ServiceCall, FilterOptions, SortDirection } from '../../state/models';
import { useResponsive } from '../../utils/responsive';
import { isCurrentUserAgent } from '../../state/ducks/current-user';
import { areClosedServiceCallsLoading, getClosedServiceCalls, getClosedServiceCallsFilters, getClosedServiceCallsSortings } from '../../state/ducks/service-calls';

const messages = defineMessages({
    pageTitle: { id: 'ServiceCallsHistoryPage.page_title', defaultMessage: 'Service calls history' },
    pageSubtitle: { id: 'ServiceCallsHistoryPage.page_subtitle', defaultMessage: 'View the completed service calls history and their details.' },
});

interface RouteProps {
    page?: string;
}

export const ServiceCallsHistoryPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const { isMobile } = useResponsive();
    const { page } = useParams<RouteProps>();
    const currentPage = page != null ? Number(page) : undefined;
    const history = useHistory();
    const dispatch = useDispatch();

    const isAgent = useSelector(isCurrentUserAgent);
    const isLoading = useSelector(areClosedServiceCallsLoading);
    const serviceCalls = useSelector(getClosedServiceCalls);
    const filters = useSelector(getClosedServiceCallsFilters);
    const sortings = useSelector(getClosedServiceCallsSortings);

    useEffect(() => {
        dispatch(ServiceCallsActions.loadServiceCallsHistory(currentPage, filters, sortings))
    }, [dispatch, currentPage, filters, sortings]);

    const navigateToDetailsPage = (serviceCall: ServiceCall) => history.push(`/service-calls/${serviceCall.id}/details`);
    const navigateToPageNumber = (page: number) => history.push(`/service-calls/history/${page}`);
    const navigateToFirstPage = () => navigateToPageNumber(1);

    const applySorting = (sortBy: string, direction: SortDirection) => {
        dispatch(ServiceCallsActions.applySortingOnMine(sortBy, direction));

        if (currentPage !== 1) {
            navigateToFirstPage();
        }
    }

    const applyFilters = (filters: FilterOptions) => {
        dispatch(ServiceCallsActions.applyFiltersOnMine(filters));

        if (currentPage !== 1) {
            navigateToFirstPage();
        }
    }

    const serviceCallsProps = {
        serviceCalls: serviceCalls,
        sortings: sortings,
        isLoading: isLoading,
        onViewDetails: navigateToDetailsPage,
        onPageChange: navigateToPageNumber,
        onSortData: applySorting
    };

    return (
        <div>
            <PageHeader
                iconName="history"
                title={formatMessage(messages.pageTitle)}
                subtitle={formatMessage(messages.pageSubtitle)}
            />

            <Segment basic textAlign="right" style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}>
                <FilterPopup
                    loading={isLoading}
                    filters={filters}
                    onApply={applyFilters}
                />
            </Segment>

            {isMobile && <ServiceCallList {...serviceCallsProps} /> }
            {!isMobile && <ServiceCallTable showClient={isAgent} {...serviceCallsProps} /> }
        </div>
    );
};