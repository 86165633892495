import { defineMessages } from "@formatjs/intl";
import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { Form, Header } from "semantic-ui-react";
import { ClientsPicker } from "../../../../../components/clients/ClientsPicker";
import { Client } from "../../../../../state/models";
import { ClientEdit } from "../../models/ClientEdit";

interface OwnProps {
    disabled?: boolean;
    clientAccounts: Client[];
    areClientAccountsLoading?: boolean;
}

export type ClientAccountsPropertiesSectionProps =
    & OwnProps;

const m = defineMessages({
    sectionTitle: { id: 'ClientAccountsPropertiesSection.sectionTitle', defaultMessage: 'Client accounts properties' },
    linkedAccountsLabel: { id: 'ClientAccountsPropertiesSection.linkedAccountsLabel', defaultMessage: 'Linked accounts' },
    clientCodeRequired: {id: 'ClientAccountsPropertiesSection.clientCodeRequired', defaultMessage: 'Please select at least one SMJ account linked to this client.' }
});

export const ClientAccountsPropertiesSection: React.FC<ClientAccountsPropertiesSectionProps> = (props) => {
    const { formatMessage } = useIntl();
    const { control, formState: { errors }, setValue } = useFormContext<ClientEdit>();
    const [clientAccounts, setClientAccounts] = useState<Client[]>(props.clientAccounts);

    useEffect(() => setClientAccounts(props.clientAccounts), [props.clientAccounts]);

    const selectClientAccounts = (clients: Client[]) => {
        setValue('clientCodes', clients.map(x => x.id));
        setClientAccounts(clients);
    }
    
    return (
        <>
            <Header content={formatMessage(m.sectionTitle)} />
            <Controller
                name="clientCodes"
                control={control}
                rules={{ required: formatMessage(m.clientCodeRequired) }}
                render={() => (
                    <Form.Field required disabled={props.disabled || props.areClientAccountsLoading} error={errors.clientCodes}>
                        <label>{formatMessage(m.linkedAccountsLabel)}</label>
                        <ClientsPicker 
                            values={clientAccounts}
                            onChange={selectClientAccounts} 
                        />
                    </Form.Field>
                )}
            />
        </>
    );
};