import * as React from 'react';
import { Grid } from 'semantic-ui-react';

interface OwnProps {
    fieldId: string;
    label: string;
}

export type SearchFieldProps =
    & OwnProps;

export class SearchField extends React.Component<SearchFieldProps, {}> {
    public render() {
        return (
            <Grid.Row style={{ paddingTop: 5, paddingBottom: 5 }}>
                <Grid.Column width={6} className="field">
                    <label htmlFor={this.props.fieldId}>{this.props.label}</label>
                </Grid.Column>
                <Grid.Column width={10} className="field">
                    {this.props.children}
                </Grid.Column>
            </Grid.Row>
        );
    }
}