import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Form, InputOnChangeData, Message } from 'semantic-ui-react';

import { Bill, Client, OrderEdit, ValidationResult } from '../../state/models';
import { nameof } from '../../state/utils';
import { ClientSearchControl, BillSearchControl } from '../../containers/search';

export interface OrderInformationFormProps {
    order: OrderEdit;
    validationResult: ValidationResult;
    onChange: (fieldId: string, newValue: string) => void;
    onBillSelected: (bill: Bill | undefined) => void;
    onBillMouseOver?: (bill: Bill) => void;
    onClientSelected: (client: Client | undefined) => void;
    isClientSearchVisible?: boolean;
}

const m = defineMessages({
    searchForBill: { id: 'OrderInformationForm.search_for_bill', defaultMessage: 'Your order' },
    referenceNumber: { id: 'OrderInformationForm.reference_number', defaultMessage: 'Your reference number' },
    referenceNumberPlaceholder: { id: 'OrderInformationForm.reference_number_placeholder', defaultMessage: 'Optional' },
    searchForClient: { id: 'OrderInformationForm.search_for_client', defaultMessage: 'Client' },
    clientContactField: { id: 'OrderInformationForm.client_contact_field', defaultMessage: 'Contact name for the client' },
    clientContactPlaceholder: { id: 'OrderInformationForm.client_contact_placeholder', defaultMessage: 'Optional' },
});

class OrderInformationForm extends React.Component<OrderInformationFormProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { order, validationResult } = this.props;
        const localizedErrorMessages = this.props.validationResult.errorMessages.map(value => formatMessage(value));

        return (
            <Form error={!validationResult.isValid} style={{ maxWidth: 450 }} >
                {this.props.isClientSearchVisible &&
                    <Form.Field
                        required={true}
                        error={validationResult.isFieldInvalid(nameof<OrderEdit>('clientId'))}
                    >
                        <label>{formatMessage(m.searchForClient)}</label>
                        <ClientSearchControl
                            defaultValue={order.client}
                            value={order.client}
                            onResultSelected={this.handleClientSelected}
                        />
                    </Form.Field>
                }

                <Form.Field
                    required={!order.isBillIdOptional}
                    error={validationResult.isFieldInvalid(nameof<OrderEdit>('billId'))}
                >
                    <label>{formatMessage(m.searchForBill)}</label>
                    <BillSearchControl
                        clientId={order.clientId}
                        defaultValue={order.bill}
                        value={order.bill}
                        onResultSelected={this.props.onBillSelected}
                        onResultMouseOver={this.props.onBillMouseOver}
                    />
                </Form.Field>
                <Form.Input
                    id="referenceNumber"
                    label={formatMessage(m.referenceNumber)}
                    placeholder={formatMessage(m.referenceNumberPlaceholder)}
                    value={order.referenceNumber || ''}
                    onChange={this.handleChange}
                />
                {this.props.isClientSearchVisible &&
                    <Form.Input
                        id="clientContactName"
                        label={formatMessage(m.clientContactField)}
                        placeholder={formatMessage(m.clientContactPlaceholder)}
                        value={order.clientContactName || ''}
                        onChange={this.handleChange}
                    />
                }
                <Message error={true} list={localizedErrorMessages} />
            </Form>
        );
    }

    private handleClientSelected = (client: Client | undefined) => {
        if (client == null) {
            this.props.onBillSelected(undefined);
        }

        this.props.onClientSelected(client);
    }

    private handleChange = (e: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) => {
        if (this.props.onChange) {
            this.props.onChange(e.currentTarget.id, data.value);
        }
    }
}

const connectedComponent = injectIntl(OrderInformationForm);
export { connectedComponent as OrderInformationForm };