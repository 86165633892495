import React from "react";
import { LocaleSwitcher } from "../../../modules/i18n";
import { useResponsive } from '../../../utils/responsive';
import { MobileNavigationMenu, NavigationMenu } from "./NavigationMenu";
import { CurrentUserMenuItem } from "./TopBar/CurrentUserMenuItem";
import { MobileNotificationMenuItem } from "./TopBar/NotificationMenuItem/MobileNotificationMenuItem";
import { TopBar } from "./TopBar/TopBar";
import { useNavigationItems } from "../../../routes/navigation-items/utils";
import { ToastContainer } from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';

export const PageLayout: React.FC = () => {
    const { isMobile } = useResponsive();    
    const navigationItems = useNavigationItems();

    return (
        <div>
            <ToastContainer position="top-center" />
            {isMobile &&
                <MobileNavigationMenu items={navigationItems}>
                    <MobileNotificationMenuItem />
                    <LocaleSwitcher />
                    <CurrentUserMenuItem />
                </MobileNavigationMenu>
            }

            {!isMobile &&
                <>
                    <TopBar />
                    <NavigationMenu items={navigationItems} />
                </>
            }
        </div>
    );
};