import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Dimmer, DimmerProps, Loader, Segment, SegmentProps } from 'semantic-ui-react';
import { commonMessages } from '../../constants';

export interface LoadingDimmerProps extends DimmerProps {
    message?: string;
}

class LoadingDimmer extends React.Component<LoadingDimmerProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { intl, ...dimmerProps } = this.props;

        return (
            <Dimmer inverted={true} active={dimmerProps.active}>
                <Loader>{this.props.message || formatMessage(commonMessages.loading)}</Loader>
            </Dimmer>
        );
    }
}

const intlComponent = injectIntl(LoadingDimmer);
export { intlComponent as LoadingDimmer };