import { AttachmentType, MediaType } from '../models';

export class AttachmentHelper {
    public static toMediaType(type: AttachmentType): MediaType {
        if (type === AttachmentType.image || type === AttachmentType.subcontractorRepairImage) {
            return MediaType.image;
        } else if (type === AttachmentType.video) {
            return MediaType.video;
        }

        return MediaType.unknown;
    }
}