import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Card, List } from 'semantic-ui-react';

import { ServiceCallInspection, AppointmentTimeRange, ServiceCall } from '../../../state/models';
import { LoadingDimmer, AddressInformation } from '../../../components/common';
import { DateHelper, UserHelper } from '../../../helpers';
import { commonMessages } from '../../../constants';
import { StringHelper, FormatHelper } from '../../../state/utils';

interface InspectionAppointmentInformationCardOwnProps {
    inspection: ServiceCallInspection | undefined;
    isInspectionLoading: boolean;
    serviceCall: ServiceCall | undefined;
    isServiceCallLoading: boolean;
}

export type InspectionAppointmentInformationCardProps =
    & InspectionAppointmentInformationCardOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'InspectionAppointmentInformationCard.title', defaultMessage: 'Appointment information' },
    subtitle: { id: 'InspectionAppointmentInformationCard.subtitle', defaultMessage: 'View information about the upcoming appointment' },
    scheduledFor: { id: 'InspectionAppointmentInformationCard.scheduled_for', defaultMessage: 'Scheduled for {date}' },
});

class InspectionAppointmentInformationCard extends React.Component<InspectionAppointmentInformationCardProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <Card fluid={true} color="teal" style={{ height: '100%' }}>
                <Card.Content style={{ flexGrow: 0 }}>
                    <Card.Header content={formatMessage(m.title)} />
                    <Card.Meta content={formatMessage(m.subtitle)} />
                </Card.Content>
                <Card.Content>
                    <LoadingDimmer active={this.props.isInspectionLoading} />

                    {this.renderContent()}
                </Card.Content>
            </Card>
        );
    }

    private renderContent() {
        const { formatMessage } = this.props.intl;
        const { inspection, serviceCall } = this.props;
        const customer = serviceCall ? serviceCall.customer : undefined;

        const hasAgentAssigned = serviceCall && serviceCall.assignedTo;
        const agentAssigned = hasAgentAssigned
            ? UserHelper.getDisplayName(serviceCall!.assignedTo)
            : formatMessage(commonMessages.unknown);

        if (inspection == null || serviceCall == null) {
            return (null);
        }

        return (
            <List>
                {inspection.scheduledForDate &&
                    <List.Item>
                        <List.Icon name="calendar" />
                        <List.Content>
                            {formatMessage(m.scheduledFor, { date: moment.utc(inspection.scheduledForDate).format('LL') })}<br />
                            {formatMessage(DateHelper.getTimeRangeDescription(inspection.scheduledForTimeRange || AppointmentTimeRange.unspecified))}
                        </List.Content>
                    </List.Item>
                }

                {inspection.completedOn &&
                    <List.Item>
                        <List.Icon name="calendar check" />
                        <List.Content>{formatMessage(m.scheduledFor, { date: moment.utc(inspection.completedOn).format('LL') })}</List.Content>
                    </List.Item>
                }

                {hasAgentAssigned &&
                    <List.Item>
                        <List.Icon name="doctor" />
                        <List.Content>{agentAssigned}</List.Content>
                    </List.Item>
                }

                {customer && StringHelper.hasValue(customer.name) &&
                    <List.Item icon="user" content={customer.name} />
                }
                {customer && customer.mainPhoneNumber &&
                    <List.Item>
                        <List.Icon name="phone" />
                        <List.Content>
                            <a href={`tel:${customer.mainPhoneNumber}`}>
                                {FormatHelper.formatPhoneNumber(customer.mainPhoneNumber)}
                            </a>
                        </List.Content>
                    </List.Item>
                }

                {customer && customer.otherPhoneNumber &&
                    <List.Item>
                        <List.Icon name="phone" />
                        <List.Content>
                            <a href={`tel:${customer.otherPhoneNumber}`}>
                                {FormatHelper.formatPhoneNumber(customer.otherPhoneNumber)}
                            </a>
                        </List.Content>
                    </List.Item>
                }

                {customer && customer.address &&
                    <List.Item>
                        <List.Icon name="marker" />
                        <List.Content>
                            <AddressInformation
                                address={customer.address}
                                canNavigateTo={true}
                            />
                        </List.Content>
                    </List.Item>
                }
            </List>
        );
    }
}

const connectedComponent = injectIntl(InspectionAppointmentInformationCard);
export { connectedComponent as InspectionAppointmentInformationCard };