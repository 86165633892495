import { Reducer } from 'redux';
import { ActionTypes } from './actions';
import { ActionTypeKeys } from './types';
import { Bill, Client, Item, ServiceCall, PagedServiceCall, PagedResult } from '../../models';

export interface SearchState {
    isSearchingForBills: boolean;
    billsSearchResult: Bill[];
    isSearchingForClients: boolean;
    clientsSearchResult: Client[];
    isSearchingForItems: boolean;
    itemsSearchResult: Item[];
    isSearchingForServiceCalls: boolean;
    serviceCallsSearchResult: ServiceCall[];
    opened: PagedServiceCall;
}

const initialState: SearchState = {
    isSearchingForBills: false,
    billsSearchResult: [],
    isSearchingForClients: false,
    clientsSearchResult: [],
    isSearchingForItems: false,
    itemsSearchResult: [],
    isSearchingForServiceCalls: false,
    serviceCallsSearchResult: [],
    opened: {
        results: PagedResult.empty<ServiceCall>(),
        filters: {},
        isLoading: false
    }
};

export const searchReducer: Reducer<SearchState> = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case ActionTypeKeys.BILLS_SEARCH_REQUEST:
            return {
                ...state,
                billsSearchResult: [],
                isSearchingForBills: true
            };

        case ActionTypeKeys.BILLS_SEARCH_SUCCESS:
            return {
                ...state,
                billsSearchResult: action.payload,
                isSearchingForBills: false,
            };

        case ActionTypeKeys.BILLS_SEARCH_FAILURE:
            return { ...state, isSearchingForBills: false };

        case ActionTypeKeys.CLIENTS_SEARCH_REQUEST:
            return {
                ...state,
                clientsSearchResult: [],
                isSearchingForClients: true
            };

        case ActionTypeKeys.CLIENTS_SEARCH_SUCCESS:
            return {
                ...state,
                clientsSearchResult: action.payload,
                isSearchingForClients: false,
            };

        case ActionTypeKeys.CLIENTS_SEARCH_FAILURE:
            return { ...state, isSearchingForClients: false };

        case ActionTypeKeys.ITEMS_SEARCH_REQUEST:
            return {
                ...state,
                itemsSearchResult: [],
                isSearchingForItems: true
            };

        case ActionTypeKeys.ITEMS_SEARCH_SUCCESS:
            return {
                ...state,
                itemsSearchResult: action.payload,
                isSearchingForItems: false,
            };

        case ActionTypeKeys.ITEMS_SEARCH_FAILURE:
            return { ...state, isSearchingForItems: false };

        case ActionTypeKeys.SERVICE_CALLS_SEARCH_REQUEST:
            return {
                ...state,
                opened: {
                    ...state.opened,
                    isLoading: true
                }
            };

        case ActionTypeKeys.SERVICE_CALLS_SEARCH_SUCCESS:
            return {
                ...state,
                opened: {
                    ...state.opened,
                    results: action.payload,
                    isLoading: false
                }
            };

        case ActionTypeKeys.SERVICE_CALLS_SEARCH_FAILURE:
            return {
                ...state,
                opened: {
                    ...state.opened,
                    isLoading: false
                }
            };

        default:
            return state;
    }
};

export default searchReducer;