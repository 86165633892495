import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../../../state/ducks';
import { FilterSection } from '../../../../../components/common';
import { Form, Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import { SubcontractorFilter, initialSubcontractorFilter } from '../../types';
import { Subcontractor } from '../../../../../state/models';
import { getSubcontractors, areSubcontractorsLoading } from '../../../../../state/ducks/service-calls';
import * as ServiceCallsActions from '../../../../../state/ducks/service-calls/actions';
import { commonMessages } from '../../../../../constants';

interface DispatchProps {
    serviceCallsActions: typeof ServiceCallsActions.actionCreators;
}

interface StoreProps {
    subcontractors: Subcontractor[];
    isLoading: boolean;
}

interface OwnProps {
    filter: SubcontractorFilter;
    onChange: (filters: SubcontractorFilter) => void;
}

export type FilterBySubcontractorSectionProps =
    & StoreProps
    & DispatchProps
    & OwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'FilterBySubcontractorSection.title', defaultMessage: 'Filter by subcontractor' },
    allSubcontractors: { id: 'FilterBySubcontractorSection.allSubcontractors', defaultMessage: 'All subcontractors' },
});

class FilterBySubcontractorSection extends React.Component<FilterBySubcontractorSectionProps, {}> {
    public componentDidMount() {
        this.props.serviceCallsActions.loadSubcontractors();
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const dropdownOptions = this.props.subcontractors.map((x): DropdownItemProps => ({
            text: x.name,
            value: x.id,
            description: x.region
        }));

        return (
            <FilterSection
                title={formatMessage(m.title)}
                onClear={() => this.props.onChange(initialSubcontractorFilter)}
            >
                <Form>
                    <Form.Field>
                        <Dropdown
                            clearable={true}
                            fluid={true}
                            search={true}
                            selection={true}
                            loading={this.props.isLoading}
                            options={dropdownOptions}
                            placeholder={formatMessage(m.allSubcontractors)}
                            value={this.props.filter.subcontractorId || ''}
                            onChange={this.selectSubcontractor}
                        />
                    </Form.Field>
                </Form>
            </FilterSection>
        );
    }

    private selectSubcontractor = (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        this.props.onChange({
            ...this.props.filter,
            subcontractorId: data.value != null && data.value !== '' ? data.value as number : undefined
        });
    }
}

const mapStateToProps = (state: ApplicationState): StoreProps => {
    return {
        subcontractors: getSubcontractors(state),
        isLoading: areSubcontractorsLoading(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    return {
        serviceCallsActions: bindActionCreators(ServiceCallsActions.actionCreators, dispatch),
    };
};

const intlComponent = injectIntl(FilterBySubcontractorSection);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as FilterBySubcontractorSection };