import { useMutation } from '@tanstack/react-query';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { Button, Modal } from 'semantic-ui-react';
import { commonMessages } from '../../../../../constants';
import useApiErrorHandler from '../../../../../utils/ApiErrorHandler';
import { setSubcontractorRepairAppointmentDate } from '../../api';
import { useSubcontractorRepairDetailsContext } from '../SubcontractorRepairDetailsProvider';
import {
    AppointementDateForm,
    AppointmentDateFormValues,
} from './AppointementDateForm';

interface AppointementDateModalProps {
    open: boolean;
    onClose: () => void;
}

export const AppointementDateModal = ({
    open,
    onClose,
}: AppointementDateModalProps) => {
    const { formatMessage } = useIntl();
    const handleError = useApiErrorHandler();

    const { repairDetails, invalidateRepairDetails } =
        useSubcontractorRepairDetailsContext();

    const { isLoading, mutate } = useMutation({
        mutationFn: (formValues: AppointmentDateFormValues) => {
            return setSubcontractorRepairAppointmentDate(
                moment(formValues.appointmentDate).toDate()
            );
        },
        onSuccess: () => {
            invalidateRepairDetails();
            onClose();
        },
        onError: (error) => handleError(error),
    });

    const handleSubmit = (formValues: AppointmentDateFormValues) => {
        if (!repairDetails) return;
        mutate(formValues);
    };

    const formId = 'appointment-date-form';

    return (
        <Modal
            size="small"
            open={open}
            mountNode={document.getElementById('root')}
            onClose={onClose}
        >
            <Modal.Header>
                {formatMessage({
                    id: 'SubcontractorRepairPage.AppointementDateModal.Title',
                    defaultMessage: 'Appointment date',
                })}
            </Modal.Header>
            <Modal.Content>
                <AppointementDateForm
                    initialFormValues={{
                        appointmentDate: repairDetails?.appointmentDate,
                    }}
                    formId={formId}
                    onSubmit={handleSubmit}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button type="button" onClick={onClose}>
                    {formatMessage(commonMessages.cancel)}
                </Button>
                <Button
                    disabled={isLoading}
                    loading={isLoading}
                    primary
                    type="submit"
                    form={formId}
                >
                    {formatMessage({
                        id: 'SubcontractorRepairPage.AppointementDateModal.SubmitButton',
                        defaultMessage: 'Select',
                    })}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
