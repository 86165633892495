import React, { useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Table } from "semantic-ui-react";
import { PageHeader } from "../../../../components/common";
import { Defect } from "../../../../state/models";
import * as api from '../api';
import { DefectsTable } from "./components/DefectsTable";

interface OwnProps {
}

export type ManageDefectsPageProps =
    & OwnProps;

const m = defineMessages({
    title: { id: 'ManageDefectsPage.title', defaultMessage: 'Manage the defects' },
    subtitle: { id: 'ManageDefectsPage.subtitle', defaultMessage: 'Manage the defects that can be associated to a service call.'}
});

export const ManageDefectsPage: React.FC<ManageDefectsPageProps> = (props) => {
    const { formatMessage } = useIntl();
    const [defects, setDefects] = useState<Defect[]>([]);

    useEffect(() => {
        api.loadAll()
            .then(results => setDefects(results));
    }, []);

    return (
        <>
            <PageHeader
                iconName="bug"
                title={formatMessage(m.title)}
                subtitle={formatMessage(m.subtitle)}
            />

            <DefectsTable defects={defects} />
        </>
    );
};