import { DefectiveRatioGroup } from "containers/reporting/types";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Menu } from "semantic-ui-react";
import { useDetailedStatisticsContext } from "../DetailedStatisticsProvider";

const m = defineMessages({
    byItem: {
        id: "DefectiveRatiosGroupSelector.ByItem",
        defaultMessage: "By Item",
    },
    byCover: {
        id: "DefectiveRatiosGroupSelector.ByCover",
        defaultMessage: "By Cover",
    },
    byProvince: {
        id: "DefectiveRatiosGroupSelector.ByProvince",
        defaultMessage: "By Province",
    },
    byClient: {
        id: "DefectiveRatiosGroupSelector.ByClient",
        defaultMessage: "By Client",
    },
    byBillingMonth: {
        id: "DefectiveRatiosGroupSelector.ByBillingMonth",
        defaultMessage: "By Billing Month",
    },
    byProductType: {
        id: "DefectiveRatiosGroupSelector.ByProductType",
        defaultMessage: "By Product Type",
    },
});

export const DefectiveRatiosGroupTypeSelector = () => {
    const { formatMessage } = useIntl();

    const {defectiveRatios: { selectedGroupType, changeSelectedGroupType} } = useDetailedStatisticsContext();

    const groupTypes = [
        { groupType: DefectiveRatioGroup.ByItem, name: formatMessage(m.byItem) },
        { groupType: DefectiveRatioGroup.ByCover, name: formatMessage(m.byCover) },
        { groupType: DefectiveRatioGroup.ByProductType, name: formatMessage(m.byProductType) },
        { groupType: DefectiveRatioGroup.ByProvince, name: formatMessage(m.byProvince) },
        { groupType: DefectiveRatioGroup.ByClient, name: formatMessage(m.byClient) },
        { groupType: DefectiveRatioGroup.ByBillingMonth, name: formatMessage(m.byBillingMonth) },
    ];

    return (
        <Menu pointing secondary color="blue">
            {groupTypes.map((groupType) => (
                <Menu.Item key={groupType.groupType} active={groupType.groupType === selectedGroupType} onClick={() => changeSelectedGroupType(groupType.groupType)}>
                    {groupType.name}
                </Menu.Item>
            ))}
        </Menu>
    );
};
