import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../state/ducks';
import { PageHeader, LoadingDimmer } from '../../../components/common';
import { RouteComponentProps } from 'react-router-dom';
import * as OrdersActions from '../actions';
import * as ProductionOrdersActions from '../../../state/ducks/production-orders/actions';
import { Order, OrderType, OrderStatus } from '../../../state/models/Order';
import { getOrder, isLoadingOrder } from '../selectors';
import { SemanticShorthandItem, Tab, TabPaneProps, TabProps } from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { ProductionOrder } from '../../../state/models';
import { getProductionOrdersByOrderId } from '../../../state/ducks/production-orders';
import { orderTypeMessages, commonMessages, orderStatusMessages } from '../../../constants';

interface DispatchProps {
    ordersActions: typeof OrdersActions.actionCreators;
    productionOrdersActions: typeof ProductionOrdersActions.actionCreators;
}

interface StoreProps {
    order: Order | undefined;
    isLoading: boolean;
}

interface TabPanesProps {
    pane?: SemanticShorthandItem<TabPaneProps>
    menuItem?: any
    render?: () => React.ReactNode
}

export type OrderPageProps =
    & StoreProps
    & DispatchProps
    & RouteComponentProps<{ orderId: string }>
    & WrappedComponentProps;

const m = defineMessages({
    pageTitle: { id: 'OrderDetailsPage.pageTitle', defaultMessage: 'Customer order #{order}' },
    itemsTabTitle: { id: 'OrderDetailsPage.itemsTabTitle', defaultMessage: 'Items' },
    detailsTabTitle: { id: 'OrderDetailsPage.detailsTabTitle', defaultMessage: 'Details' },
    followUpsTabTitle: { id: 'OrderDetailsPage.followUpsTabTitle', defaultMessage: 'Follow-ups' }
});

class OrderPage extends React.Component<OrderPageProps, {}> {
    readonly orderTypeMap: Map<OrderType, MessageDescriptor> = new Map<OrderType, MessageDescriptor>([
        [OrderType.sale, orderTypeMessages.sale],
        [OrderType.stock, orderTypeMessages.stock],
        [OrderType.forProduction, orderTypeMessages.forProduction],
        [OrderType.freeOfCharge, orderTypeMessages.freeOfCharge],
        [OrderType.interCompany, orderTypeMessages.interCompany],
        [OrderType.direct, orderTypeMessages.diret]
    ]);

    readonly orderStatusMap: Map<OrderStatus, MessageDescriptor> = new Map<OrderStatus, MessageDescriptor>([
        [OrderStatus.creating, orderStatusMessages.creating],
        [OrderStatus.verified, orderStatusMessages.verified],
        [OrderStatus.inProduction, orderStatusMessages.inProduction],
        [OrderStatus.partiallyCompleted, orderStatusMessages.partiallyCompleted],
        [OrderStatus.completed, orderStatusMessages.completed],
        [OrderStatus.partiallyLoaded, orderStatusMessages.partiallyLoaded],
        [OrderStatus.loaded, orderStatusMessages.loaded],
        [OrderStatus.shipped, orderStatusMessages.shipped],
        [OrderStatus.billed, orderStatusMessages.billed],
        [OrderStatus.cancelled, orderStatusMessages.cancelled]
    ]);

    public componentDidMount() {
        const orderId = Number(this.props.match.params.orderId);
        this.props.ordersActions.loadOrder(orderId);
        this.props.productionOrdersActions.loadByOrder(orderId);
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const { order } = this.props;
        const orderId = Number(this.props.match.params.orderId);
        const tabPanes: TabPanesProps[] = [
            { menuItem: this.createTab('items', 'boxes', formatMessage(m.itemsTabTitle)) },
            { menuItem: this.createTab('summary', 'clipboard', formatMessage(m.detailsTabTitle), true) },
            { menuItem: this.createTab('follow-ups', 'tasks', formatMessage(m.followUpsTabTitle), true) }
        ];

        const orderSubtitle = order
            ? `${formatMessage(this.orderTypeMap.get(order.type) || commonMessages.unknown)} · ${formatMessage(this.orderStatusMap.get(order.status) || commonMessages.unknown)}`
            : '';

        return (
            <React.Fragment>
                <LoadingDimmer active={this.props.isLoading} />

                <PageHeader
                    iconName="shopping cart"
                    title={formatMessage(m.pageTitle, { order: orderId })}
                    subtitle={orderSubtitle}
                />

                <Tab
                    menu={{ secondary: true, pointing: true }}
                    panes={tabPanes}
                    style={{ marginBottom: '30px' }}
                    onTabChange={this.changeTab}
                    activeIndex={this.props.location.hash}
                />
            </React.Fragment>
        );
    }

    private createTab = (id: string, icon: SemanticICONS, title: string, disabled?: boolean): TabProps => {
        return {
            key: id,
            index: id,
            icon,
            content: title,
            active: this.props.location.pathname.endsWith(id),
            disabled
        };
    }

    private changeTab = (event: React.MouseEvent<HTMLDivElement>, data: TabProps) => {
        this.props.history.push(`/orders/${this.props.match.params.orderId}/${data.activeIndex}`);
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: RouteComponentProps<{ orderId: string }>): StoreProps => {
    const orderId = Number(ownProps.match.params.orderId);

    return {
        order: getOrder(state, orderId),
        isLoading: isLoadingOrder(state, orderId)
    };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    return {
        ordersActions: bindActionCreators(OrdersActions.actionCreators, dispatch),
        productionOrdersActions: bindActionCreators(ProductionOrdersActions.actionCreators, dispatch),
    };
};

const intlComponent = injectIntl(OrderPage);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as OrderPage };