import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Icon, Popup, List } from 'semantic-ui-react';
import { OrderDetail } from '../../../../state/models/OrderDetail';
import { getItemLocalizedDescription, formatItemId, BillDetail } from '../../../../state/models';
import { StringHelper } from '../../../../state/utils';
import './BillSummaryListItem.css';
import { commonMessages } from '../../../../constants';
import { DefectIcon } from './DefectIcon';

interface OwnProps {
    billDetail: BillDetail;
    defectsCount?: number;
}

export type BillSummaryListItemProps =
    & OwnProps
    & WrappedComponentProps;

const m = defineMessages({
    coverDescription: { id: 'BillSummaryListItem.coverDescription', defaultMessage: 'Cover: {description}' },
});

class BillSummaryListItem extends React.Component<BillSummaryListItemProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const itemDescription = getItemLocalizedDescription(this.props.billDetail.item, this.props.intl.locale);
        const coverDescription = getItemLocalizedDescription(this.props.billDetail.itemCover, this.props.intl.locale);
        const showDefectIcon = this.props.defectsCount != null && this.props.defectsCount > 0;

        return (
            <div className="c-bill-row">
                <Icon size="tiny" name="circle" style={{ marginRight: '10px' }} />
                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', flexGrow: 1, marginRight: '10px' }}>
                    <div title={itemDescription} style={{ overflow: 'inherit', textOverflow: 'inherit', lineHeight: '16px' }}>
                        <strong>{formatItemId(this.props.billDetail.itemId) || formatMessage(commonMessages.notApplicableAbbreviation)}</strong> {itemDescription}
                    </div>
                    <div title={coverDescription} style={{ overflow: 'inherit', textOverflow: 'inherit', color: 'rgba(0,0,0,.45)' }}>
                        {formatMessage(m.coverDescription, { description: coverDescription || formatMessage(commonMessages.notApplicableAbbreviation) })}
                    </div>
                </div>
                <div style={{ flexGrow: 0, display: 'flex' }}>
                    {showDefectIcon && <DefectIcon defectsCount={this.props.defectsCount || 0} />}
                </div>
            </div>
        );
    }
}

const intlComponent = injectIntl(BillSummaryListItem);
export { intlComponent as BillSummaryListItem };