import { ValidationResult } from '.';
import { Client } from '../Client';
import { Bill } from '../Bill';
import { StringHelper, nameof } from '../../utils';
import { defineMessages } from 'react-intl';

const m = defineMessages({
    clientIdRequired: { id: 'OrderEdit.client_id_required', defaultMessage: 'The client is required.' },
    billIdRequired: { id: 'OrderEdit.bill_id_required', defaultMessage: 'The bill or PO number is required.' }
});

export class OrderEdit {
    public clientId?: string;
    public client?: Client;
    public billId?: number;
    public bill?: Bill;

    public referenceNumber?: string;
    public clientContactName?: string;

    public isBillIdOptional?: boolean;

    public constructor(init?: Partial<OrderEdit>) {
        Object.assign(this, init);
    }

    public validate(): ValidationResult {
        let result = new ValidationResult();
        
        if (!StringHelper.hasValue(this.clientId)) {
            result.isValid = false;
            result.errorFields.push(nameof<OrderEdit>('clientId'));
            result.errorMessages.push(m.clientIdRequired);
        }

        if (!this.isBillIdOptional && this.billId == null) {
            result.isValid = false;
            result.errorFields.push(nameof<OrderEdit>('billId'));
            result.errorMessages.push(m.billIdRequired);
        }

        return result;
    }
}