import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { Header, Icon, Table } from 'semantic-ui-react';

import { UserHelper } from '../../helpers';
import { CalendarBreak } from '../../state/models';
import { LoadingContainer, LoadingDimmer } from '../common';
import { CalendarControls } from './CalendarControls';
import { CalendarDayCell } from './CalendarDayCell';

interface CalendarOwnProps {
    forDate: Date;
    events: CalendarEvent[];
    lockedDates: CalendarBreak[];
    isLoading?: boolean;
    canSelectDate?: boolean;
    selectedDate?: Date;
    forUserId?: string;

    onMonthChanged: (date: Date) => void;
    onDateSelected?: (date?: Date) => void;
}

interface CalendarState {
    selectedDate: Date | undefined;
}

export interface CalendarEvent {
    on: Date;
    element: JSX.Element;
}

export type MonthlyCalendarProps =
    & CalendarOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    weekHeaderFormat: { id: 'Calendar.week_header_format', defaultMessage: 'Week {weekNumber}' },
    locked: { id: 'Calendar.locked', defaultMessage: 'Locked' },
});

class MonthlyCalendar extends React.Component<MonthlyCalendarProps, CalendarState> {
    public constructor(props: MonthlyCalendarProps) {
        super(props);

        this.state = { selectedDate: props.selectedDate };
    }

    public componentWillReceiveProps(props: MonthlyCalendarProps) {
        this.setState({ selectedDate: props.selectedDate });
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const firstDayOfMonth = moment(this.props.forDate).clone().startOf('month');
        const firstDayOfWeek = firstDayOfMonth.clone().startOf('isoWeek');

        const weekHeaders = _.range(5).map(x => (
            <Table.HeaderCell key={x}>
                {formatMessage(m.weekHeaderFormat, { weekNumber: firstDayOfMonth.clone().add(x, 'weeks').week() })}
            </Table.HeaderCell>
        ));

        const dayRows = _.range(6).map(x => (
            this.renderRow('row' + x, firstDayOfWeek.clone().add(x, 'days').toDate())
        ));

        return (
            <React.Fragment>
                <CalendarControls
                    for={this.props.forDate}
                    onPreviousMonth={() => this.props.onMonthChanged(firstDayOfMonth.clone().add(-1, 'month').toDate())}
                    onNextMonth={() => this.props.onMonthChanged(firstDayOfMonth.clone().add(1, 'month').toDate())}
                />

                <LoadingContainer>
                    <LoadingDimmer active={this.props.isLoading} style={{ verticalAlign: 'top' }} />

                    <Table className="c-calendar" textAlign="center" celled={true} fixed={true}>
                        <Table.Header>
                            <Table.Row>
                                {weekHeaders}
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {dayRows}
                        </Table.Body>
                    </Table>
                </LoadingContainer>
            </React.Fragment>
        );
    }

    private renderRow(key: string, forDate: Date): JSX.Element {
        const { formatMessage } = this.props.intl;
        const days = _.range(5).map(x => {
            const dayMoment = moment(forDate).clone().add(x, 'week');
            const isPastDate = dayMoment.isBefore(moment(), 'day');
            const locks = this.props.lockedDates.filter(y => dayMoment.isSame(y.forDate, 'day'));
            const isLockedForEveryone = this.props.lockedDates.filter(y => dayMoment.isSame(y.forDate, 'day') && y.forUserId == null).length > 0;
            const isLockedForUser = this.props.lockedDates.filter(y => dayMoment.isSame(y.forDate, 'day') && y.forUserId === this.props.forUserId).length > 0;
            const isLocked = isLockedForEveryone || isLockedForUser;

            const events = this.props.events
                .filter(y => dayMoment.isSame(y.on, 'day'))
                .map(y => y.element);

            const hasEvents = events.length > 0;

            return (
                <CalendarDayCell
                    key={dayMoment.toISOString()}
                    disabled={isPastDate}
                    selectable={this.props.canSelectDate && !isLocked}
                    locked={isLocked}
                    selected={this.state.selectedDate ? dayMoment.isSame(this.state.selectedDate, 'day') : false}
                    calendarBreak={locks && locks.length > 0 ? locks[0] : undefined}
                    day={dayMoment.toDate()}
                    style={{ height: '144px', position: 'relative', pointerEvents: 'auto' }}
                    onClick={() => this.handleDayClicked(dayMoment.toDate())}
                >
                    {hasEvents &&
                        <React.Fragment>{events}</React.Fragment>
                    }

                    {isLocked && !hasEvents &&
                        <React.Fragment>
                            <Header className="c-calendar__lock" as="h4" icon={true} style={{ color: 'rgba(0,0,0,.25)', marginTop: '18px', marginBottom: 0 }}>
                                <Icon name="lock" />
                                {locks[0].forUser != null
                                    ? UserHelper.getDisplayName(locks[0].forUser)
                                    : formatMessage(m.locked)}
                            </Header>

                            <p style={{ color: 'rgba(0,0,0,.25)', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                {locks[0].reason}
                            </p>
                        </React.Fragment>
                    }
                </CalendarDayCell>
            );
        });

        return (
            <Table.Row key={key} verticalAlign="top">
                {days}
            </Table.Row>
        );
    }

    private handleDayClicked = (date: Date) => {
        const isLockedForEveryone = this.props.lockedDates.filter(y => moment(date).isSame(y.forDate, 'day') && y.forUserId == null).length > 0;
        const isLockedForUser = this.props.lockedDates.filter(y => moment(date).isSame(y.forDate, 'day') && y.forUserId === this.props.forUserId).length > 0;

        if (this.props.canSelectDate && !isLockedForEveryone && !isLockedForUser) {
            const isAlreadySelected = this.state.selectedDate
                ? moment(this.state.selectedDate).isSame(date, 'day')
                : false;

            this.setState({ selectedDate: isAlreadySelected ? undefined : date });

            if (this.props.onDateSelected) {
                this.props.onDateSelected(isAlreadySelected ? undefined : date);
            }
        }
    }
}

const connectedComponent = injectIntl(MonthlyCalendar);
export { connectedComponent as Calendar };