import { getUserRole } from "modules/account/selectors";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "state/ducks";
import { NavigationItem } from "../../components/layouts/PageLayout/NavigationMenu";
import { UserAccountType } from "../../state/models/User";
import { administratorNavigationItems } from "./administrators";
import { clientNavigationItems } from "./clients";
import { customerServiceAgentNavigationItems } from "./customerServiceAgents";
import { factoryTechnicianNavigationItems } from "./factoryTechnicians";
import { jaymarTechnicianNavigationItems } from "./jaymarTechnicians";
import { representativeNavigationItems } from "./representatives";
import * as ReportingActions from "../../state/ducks/reporting";

export const useNavigationItems = (): NavigationItem[] => {
    const dispatch = useDispatch();
    
    const currentUserRole = useSelector(getUserRole);   

    const overdueCallCount = useSelector(
        (state: ApplicationState) => state.reporting.overdueCallCount
    );

    const activeCalls = useSelector(
        (state: ApplicationState) => state.reporting.currentActiveCalls
    );

    const stalledUnassignedCallCount = useSelector(
        (state: ApplicationState) => state.reporting.stalledUnassignedCallCount
    );

    useEffect(() => {
        if (
            currentUserRole === UserAccountType.administrator ||
            currentUserRole === UserAccountType.customerServiceAgent
        ) {  
            dispatch(ReportingActions.actionCreators.loadOverdueCallCount());          
            dispatch(ReportingActions.actionCreators.loadStalledUnassignedCallCount());
            dispatch(ReportingActions.actionCreators.loadCurrentActiveCalls());
        }
    }, [currentUserRole, dispatch]);


    switch (currentUserRole) {
        case UserAccountType.administrator:            
            return administratorNavigationItems(activeCalls, stalledUnassignedCallCount, overdueCallCount);

        case UserAccountType.client:
            return clientNavigationItems();

        case UserAccountType.customerServiceAgent:
            return customerServiceAgentNavigationItems(activeCalls, stalledUnassignedCallCount, overdueCallCount);

        case UserAccountType.factoryTechnician:
            return factoryTechnicianNavigationItems();

        case UserAccountType.jaymarTechnician:
            return jaymarTechnicianNavigationItems();

        case UserAccountType.jaymarRepresentative:
            return representativeNavigationItems();

        default:
            return [];
    }
};
