import React from 'react';
import { PageLayout } from '../components/layouts';
import { PageContent } from './layout';

export const App: React.FC = (props) => {
    return (
        <div>
            <PageLayout />
            <PageContent children={props.children} />
        </div>
    );
};