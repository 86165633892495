export const allCountries: string = 'ALL';
export const allProvinces: string = 'ALL';

export interface RegionFilter {
    countryCode: string | undefined;
    provinceCode: string | undefined;
    availableCountryCodes: string[];
    areCountryCodesLoading: boolean;
    availableProvinceCodes: string[];
    areProvinceCodesLoading: boolean;
}

export const initialRegionFilter: RegionFilter = {
    countryCode: allCountries,
    provinceCode: allProvinces,
    availableCountryCodes: [],
    areCountryCodesLoading: false,
    availableProvinceCodes: [],
    areProvinceCodesLoading:  false
};

export function isInitialRegionFilter(filter: RegionFilter) {
    return filter.countryCode === initialRegionFilter.countryCode
        && filter.provinceCode === initialRegionFilter.provinceCode;
}