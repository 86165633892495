import { initialDateFilter, DateFilter, isInitialDateFilter } from './DateFilter';

export interface ClientsAdoptionFilters {
    dateFilter: DateFilter;
}

export const initialClientsAdoptionFilter: ClientsAdoptionFilters = {
    dateFilter: initialDateFilter
};

export function isInitialClientsAdoptionFilter(filter: ClientsAdoptionFilters) {
    return isInitialDateFilter(filter.dateFilter);
}