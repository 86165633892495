import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Segment, Table, Popup, Icon } from 'semantic-ui-react';
import { NumberWithDelta } from '.';
import { EmptyContentSegment } from '../common';
import { ReactNode } from 'react';

interface DataTableOwnProps {
    label: string;
    labelTooltip?: string;
    isLoading?: boolean;
    rows: DataRow[];
}

export interface DataRow {
    description: string;
    value: ReactNode;
}

interface StateProps {
    rowsDisplayed: number;
}

export type DataTableProps =
    & DataTableOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    emptyTitle: { id: 'DataTable.empty_title', defaultMessage: 'No results' },
    emptyDescription: { id: 'DataTable.empty_description', defaultMessage: 'There are no results matching the given criterias' },
    moreResults: { id: 'Datatable.moreResults', defaultMessage: 'Display more results' },
});

class DataTable extends React.Component<DataTableProps, StateProps> {
    public constructor(props: DataTableProps) {
        super(props);

        this.state = { rowsDisplayed: 5 };
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const rows = this.props.rows
            .slice(0, this.state.rowsDisplayed)
            .map(this.renderRow);

        const hasMoreResults = this.state.rowsDisplayed < this.props.rows.length;

        return (
            <Segment color="teal" loading={this.props.isLoading}>
                {rows.length > 0 &&
                    <Table basic="very" unstackable={true}>
                        <Table.Body>
                            {rows}
                        </Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.Cell colSpan={2}>
                                    {hasMoreResults &&
                                        <a onClick={this.displayMoreRows} style={{ cursor: 'pointer' }}>
                                            {formatMessage(m.moreResults)}
                                        </a>
                                    }
                                </Table.Cell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                }
                {rows.length <= 0 &&
                    <EmptyContentSegment
                        basic={true}
                        title={formatMessage(m.emptyTitle)}
                        description={formatMessage(m.emptyDescription)}
                    />
                }

                {this.renderLabel()}
            </Segment>
        );
    }

    private renderLabel() {
        return (
            <div style={{ fontSize: '1em', fontWeight: 700, textTransform: 'uppercase', textAlign: 'center' }}>
                {this.props.label}
                {this.props.labelTooltip &&
                    <Popup trigger={<Icon name="question circle outline" />}>
                        {this.props.labelTooltip}
                    </Popup>
                }
            </div>
        );
    }

    private renderRow(row: DataRow) {
        return (
            <Table.Row>
                <Table.Cell>{row.description}</Table.Cell>
                <Table.Cell textAlign="right">{row.value}</Table.Cell>
            </Table.Row>
        );
    }

    private displayMoreRows = () => {
        this.setState((current) => ({
            rowsDisplayed: current.rowsDisplayed + 5
        }));
    }
}

const connectedComponent = injectIntl(DataTable);
export { connectedComponent as DataTable };