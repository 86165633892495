import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { ServiceCall, PagedResult } from '../../state/models';
import { NoServiceCalls, ServiceCallCard } from '.';
import { Segment, Pagination, Icon, PaginationProps } from 'semantic-ui-react';
import { LoadingDimmer } from '../common';

export interface ServiceCallListProps {
    serviceCalls: PagedResult<ServiceCall>;
    currentPage?: number;
    isLoading?: boolean;
    // onCreate?: () => void;
    onViewDetails?: (serviceCall: ServiceCall) => void;
    onPageChange?: (page: number) => void;
}

class ServiceCallList extends React.Component<ServiceCallListProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { serviceCalls } = this.props;
        const currentPage = this.props.currentPage || this.props.serviceCalls.pageIndex;

        const hasServiceCalls = serviceCalls && serviceCalls.results && serviceCalls.results.length > 0;
        const items = hasServiceCalls
            ? serviceCalls.results.map(value => (
                <ServiceCallCard
                    key={value.id}
                    serviceCall={value}
                    onViewDetails={this.handleViewDetails}
                />
            ))
            : <div />;

        return (
            <Segment basic={true}>
                <LoadingDimmer active={this.props.isLoading} />

                {hasServiceCalls &&
                    <div>{items}</div>}
                {!hasServiceCalls && <NoServiceCalls />}

                {serviceCalls.pageCount > 1 &&
                    <div style={{ textAlign: 'center' }}>
                        <Pagination
                            size="tiny"
                            totalPages={serviceCalls.pageCount}
                            ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                            firstItem={null}
                            lastItem={null}
                            prevItem={{ content: <Icon name="angle left" />, icon: true }}
                            nextItem={{ content: <Icon name="angle right" />, icon: true }}
                            onPageChange={this.handlePageChange}
                            defaultActivePage={currentPage}
                            style={{ margin: '15px 0 0 0' }}
                        />
                    </div>
                }
            </Segment>
        );
    }

    private handlePageChange = (event: React.MouseEvent<HTMLAnchorElement>, data: PaginationProps) => {
        if (this.props.onPageChange) {
            this.props.onPageChange(data.activePage as number);
        }
    }

    private handleViewDetails = (serviceCall: ServiceCall) => {
        if (this.props.onViewDetails) {
            this.props.onViewDetails(serviceCall);
        }
    }
}

const connectedComponent = injectIntl(ServiceCallList);
export { connectedComponent as ServiceCallList };