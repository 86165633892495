import * as React from 'react';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { Button, ButtonProps, Dropdown, DropdownProps } from 'semantic-ui-react';

import { ServiceCall } from '../../../state/models';

interface ExportDropdownOwnProps extends DropdownProps {
    serviceCall: ServiceCall;
}

export type ExportDropdownProps =
    & ExportDropdownOwnProps
    & WrappedComponentProps;

interface ExportDropdownState {
    isOpen: boolean;
}

const m = defineMessages({
    export: { id: 'ExportDropdown.export', defaultMessage: 'Export' },
    serviceCallHistory: { id: 'ExportDropdown.service_call_history', defaultMessage: 'Service Call Form' },
    serviceCallDetails: { id: 'ExportDropdown.service_call_details', defaultMessage: 'Technician Repairs Form' },
    subcontractorRepairsForm: { id: 'ExportDropdown.subcontractor_repairs_form', defaultMessage: 'Subcontractor Repairs Form' },
    subcontractorBillingForm: { id: 'ExportDropdown.subcontractor_billing_form', defaultMessage: 'Subcontractor Billing Form' },
    returnMerchandiseAuthorizationForm: { id: 'ExportDropdown.return_merchandise_authorization', defaultMessage: 'Return Merchandise Authorization Form' },
    factoryRepairsForm: { id: 'ExportDropdown.factory_repairs_form', defaultMessage: 'Factory Repairs Form' },
    localRepairsForm: { id: 'ExportDropdown.local_repairs_form', defaultMessage: 'Local Repairs Form' },
    inspectionForm: { id: 'ExportDropdown.inspection_form', defaultMessage: 'Inspection Form' },
    creditReturnForm: { id: 'ExportDropdown.credit_return_form', defaultMessage: 'Credit Return Form' },
    clientCreditForm: { id: 'ExportDropdown.client_credit_form', defaultMessage: 'Client Credit Form' }
});

class ExportDropdown extends React.Component<ExportDropdownProps, ExportDropdownState> {
    public constructor(props: ExportDropdownProps) {
        super(props);

        this.state = { isOpen: false };
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const { serviceCall, ...dropdownProps } = this.props;
        const serviceCallId = serviceCall ? serviceCall.id : 0;

        return (
            <Dropdown
                open={this.state.isOpen}
                onClick={() => this.setState((current) => ({ isOpen: !current.isOpen }))}
                className="icon orange"
                button={true}
                labeled={true}
                icon="file pdf outline"
                direction="left"
                text={formatMessage(m.export)}
                {...dropdownProps}
            >
                <Dropdown.Menu>
                    {this.renderMenuItem(formatMessage(m.serviceCallHistory), `/reports/service-calls-history/${serviceCallId}`)}
                    {this.renderMenuItem(formatMessage(m.localRepairsForm), `/reports/local-repairs/${serviceCallId}`)}
                    {this.renderMenuItem(formatMessage(m.inspectionForm), `/reports/inspection/${serviceCallId}`)}
                    {this.renderMenuItem(formatMessage(m.serviceCallDetails), `/reports/service-calls/${serviceCallId}`)}
                    {this.renderMenuItem(formatMessage(m.subcontractorRepairsForm), `/reports/subcontractor-repairs/${serviceCallId}`)}
                    {this.renderMenuItem(formatMessage(m.subcontractorBillingForm), `/reports/subcontractor-billing/${serviceCallId}`)}
                    {this.renderMenuItem(formatMessage(m.returnMerchandiseAuthorizationForm), `/reports/factory-repairs-rma/${serviceCallId}`)}
                    {this.renderMenuItem(formatMessage(m.factoryRepairsForm), `/reports/factory-repairs/${serviceCallId}`)}
                    {this.renderMenuItem(formatMessage(m.creditReturnForm), `/reports/return-for-credit/${serviceCallId}`)}
                    {this.renderMenuItem(formatMessage(m.clientCreditForm), `/reports/client-credit/${serviceCallId}`)}
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    private renderMenuItem(title: string, baseLink: string) {
        return (
            <Dropdown.Item
                as="a"
                href={`${baseLink}?culture=fr-CA`}
                target="_blank"
                attached={'true'}
            >
                {this.renderLanguageButtons(baseLink)}
                {title}
            </Dropdown.Item>
        );
    }

    private renderLanguageButtons(baseLink: string) {
        return (
            <Button.Group size="mini" style={{ marginRight: 5 }}>
                <Button as="a" target="_blank" href={`${baseLink}?culture=fr-CA`} content="FR" onClick={this.handleLanguageButtonClick} />
                <Button as="a" target="_blank" href={`${baseLink}?culture=en`} content="EN" onClick={this.handleLanguageButtonClick} />
            </Button.Group>
        );
    }

    private handleLanguageButtonClick = (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
        event.stopPropagation();
        this.setState({ isOpen: false });
    }
}

const connectedComponent = injectIntl(ExportDropdown);
export { connectedComponent as ExportDropdown };