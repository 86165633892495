import React from "react";
import { Label, SearchResultProps } from "semantic-ui-react";

interface OwnProps {
    result: SearchResultProps;
}

export type ClientSearchResultProps =
    & OwnProps;

export const ClientSearchResult: React.FC<ClientSearchResultProps> = (props) => {
    return (
        <React.Fragment key={props.result.id}>
            <div title={props.result.title} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <Label content={props.result.label} />
                <span className="text">{props.result.title}</span>
            </div>
            <div title={props.result.description} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <small>{props.result.description}</small>
            </div>
        </React.Fragment>
    );
};