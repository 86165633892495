import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Button, Card, Divider } from 'semantic-ui-react';
import { Defect, ServiceCallDefect } from '../../../state/models';
import { AddDefectModal, DefectDetails } from '.';
import { EmptyContentSegment } from '../../common';

export interface AddDefectCardProps {
    serviceCallDefects: ServiceCallDefect[];
    allDefects: Defect[];
    warrantyStartDate?: Date;
    isReadOnly?: boolean;

    onAddDefect: (defect: Defect) => void;
    onRemoveDefect: (defect: Defect) => void;
}

interface AddDefectCardState {
    isDefectsModalOpen: boolean;
}

const m = defineMessages({
    header: { id: 'DefectsCard.header', defaultMessage: 'Select the defects' },
    subtitle: { id: 'DefectsCard.subtitle', defaultMessage: 'Add defects to the service call to retrieve the warranty status.'},
    addDefect: { id: 'DefectsCard.add_defect', defaultMessage: 'Add Defect' },
    noDefect: { id: 'DefectsCard.no_defect', defaultMessage: 'No Defects' },
    noDefectDescription: { id: 'DefectsCard.no_defect_description', defaultMessage: 'There are no defects associated with this service call.' }
});

class DefectsCard extends React.Component<AddDefectCardProps & WrappedComponentProps, AddDefectCardState> {
    public constructor(props: AddDefectCardProps & WrappedComponentProps) {
        super(props);

        this.state = {
            isDefectsModalOpen: false
        };
    }

    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <Card fluid={true} color="orange" style={{height: '100%'}}>
                <Card.Content style={{flexGrow: 0}}>
                    <Card.Header content={formatMessage(m.header)} />
                    <Card.Meta content={formatMessage(m.subtitle)} />
                </Card.Content>
                <Card.Content style={{maxHeight: 240, overflowY: 'scroll'}}>
                    {this.renderDefectDetails()}
                </Card.Content>
                <Card.Content style={{flexGrow: 0}}>
                    <Button 
                        primary={true} 
                        fluid={true} 
                        disabled={this.props.isReadOnly}
                        icon="add" 
                        content={formatMessage(m.addDefect)}
                        onClick={() => this.setState({ isDefectsModalOpen: true })}
                    />
                    <AddDefectModal
                        isOpen={this.state.isDefectsModalOpen || false}
                        allDefects={this.props.allDefects}
                        onAddDefect={this.props.onAddDefect}
                        onClose={() => this.setState({ isDefectsModalOpen: false })}
                    />
                </Card.Content>
            </Card>
        );
    }

    private renderDefectDetails() {
        const { formatMessage } = this.props.intl;
        const defectsDetails = this.props.serviceCallDefects.map(x => (
            <div key={x.id}>
                <DefectDetails 
                    key={x.id} 
                    defect={x.defect}
                    isReadOnly={this.props.isReadOnly}
                    warrantyStartDate={this.props.warrantyStartDate} 
                    onDelete={this.props.onRemoveDefect}
                />
                <Divider />
            </div>
        ));

        return defectsDetails.length > 0
            ? defectsDetails
            : (
                <EmptyContentSegment 
                    basic={true} 
                    title={formatMessage(m.noDefect)} 
                    description={formatMessage(m.noDefectDescription)}
                />
            );
    }
}

const intlComponent = injectIntl(DefectsCard);
export { intlComponent as DefectsCard };