import moment from 'moment';
import React from 'react';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { Button, Card, Label, LabelProps, List, SemanticCOLORS } from 'semantic-ui-react';

import { commonMessages, defectCategoryMessages, defectTypeMessages } from '../../../constants';
import { UserHelper } from '../../../helpers';
import { ServiceCallDefect, ServiceCallFactoryRepair } from '../../../state/models';
import { LoadingContainer, LoadingDimmer, NoContentSegment } from '../../common';
import { FactoryRepairStatus } from './FactoryRepairStatusLabel';

interface FactoryRepairsListOwnProps {
    repairs: ServiceCallFactoryRepair[];
    isLoading: boolean;
    emptyTitle: string;
    emptySubtitle: string;

    onViewDetails: (repairs: ServiceCallFactoryRepair) => void;
}

export type FactoryRepairsListProps =
    & FactoryRepairsListOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    repairTitle: { id: 'FactoryRepairsList.repair_title', defaultMessage: 'Service call #{id}' },
    scheduledFor: { id: 'FactoryRepairsList.scheduled_for', defaultMessage: 'Scheduled for {date}' },
    repairedOn: { id: 'FactoryRepairsList.repaired_on', defaultMessage: 'Repaired on {date}' },
    awaitingStatus: { id: 'FactoryRepairsList.awaiting_status', defaultMessage: 'Pending' },
    toRepairStatus: { id: 'FactoryRepairsList.to_repair_status', defaultMessage: 'To repair' },
    repairedStatus: { id: 'FactoryRepairsList.repaired_status', defaultMessage: 'Repaired' },
});

class FactoryRepairsList extends React.Component<FactoryRepairsListProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const hasRepairs = this.props.repairs && this.props.repairs.length > 0;
        const repairCards = hasRepairs ? this.props.repairs.map(x => this.renderRepairCard(x)) : (null);

        return (
            <LoadingContainer>
                <LoadingDimmer active={this.props.isLoading} />

                {hasRepairs &&
                    <React.Fragment>
                        {repairCards}
                    </React.Fragment>
                }

                {!hasRepairs &&
                    <NoContentSegment
                        title={this.props.emptyTitle}
                        subtitle={this.props.emptySubtitle}
                    />
                }
            </LoadingContainer>
        );
    }

    private renderRepairCard(repair: ServiceCallFactoryRepair) {
        const { formatMessage } = this.props.intl;

        const hasAgentAssigned = repair.serviceCall && repair.serviceCall.assignedTo;
        const agentAssigned = hasAgentAssigned
            ? UserHelper.getDisplayName(repair.serviceCall.assignedTo)
            : formatMessage(commonMessages.unknown);

        const hasClient = repair.serviceCall && repair.serviceCall.client;

        const hasDefects = repair.serviceCall && repair.serviceCall.defects && repair.serviceCall.defects.length > 0;

        return (
            <Card key={repair.id} fluid={true}>
                <Card.Content>
                    <Card.Header>
                        {formatMessage(m.repairTitle, { id: repair.serviceCallId })}
                        {this.renderRepairStatus(repair)}
                    </Card.Header>
                    <Card.Meta>
                        {repair.scheduledFor && <div>{formatMessage(m.scheduledFor, { date: moment.utc(repair.scheduledFor).format('LL') })}</div>}
                        {repair.completedRepairsOn != null &&
                            <div>{formatMessage(m.repairedOn, { date: moment.utc(repair.completedRepairsOn).format('LL') })}</div>
                        }
                    </Card.Meta>
                </Card.Content>
                <Card.Content>
                    <List>
                        {hasAgentAssigned &&
                            <List.Item>
                                <List.Icon name="doctor" />
                                <List.Content>{agentAssigned}</List.Content>
                            </List.Item>
                        }

                        {hasClient &&
                            <List.Item>
                                <List.Icon name="shopping cart" />
                                <List.Content>{repair.serviceCall.client.name}</List.Content>
                            </List.Item>
                        }

                        {hasDefects &&
                            this.renderDefects(repair.serviceCall.defects)
                        }
                    </List>
                </Card.Content>
                <Card.Content extra={true}>
                    <Button
                        fluid={true}
                        content={formatMessage(commonMessages.view)}
                        onClick={() => this.props.onViewDetails(repair)}
                    />
                </Card.Content>
            </Card>
        );
    }

    private renderDefects(defects: ServiceCallDefect[]) {
        const { formatMessage } = this.props.intl;

        if (defects && defects.length > 0) {
            const defect = defects[0].defect;

            const typeMessages = Object.keys(defectTypeMessages).map(key => defectTypeMessages[key]);
            const defectTypeMessage = typeMessages.find(x => x.id === defect.defectType.title);
            const defectType = defectTypeMessage ? formatMessage(defectTypeMessage) : defect.defectType.title;

            const categoryMessages = Object.keys(defectCategoryMessages).map(key => defectCategoryMessages[key]);
            const defectCategoryMessage = categoryMessages.find(x => x.id === defect.defectCategory.title);
            const defectCategory = defectCategoryMessage ? formatMessage(defectCategoryMessage) : defect.defectCategory.title;

            return (
                <List.Item>
                    <List.Icon name="medkit" />
                    <List.Content>
                        <p style={{ marginBottom: 0 }}>{defectType}</p>
                        <p style={{ color: 'rgba(0,0,0,0.4)' }}>{defectCategory}</p>
                    </List.Content>
                </List.Item>
            );
        }

        return (null);
    }

    private renderRepairStatus(repair: ServiceCallFactoryRepair) {
        const { formatMessage } = this.props.intl;
        const isAwaitingShipment = repair.receivedOn == null;
        const isAwaitingRepairs = repair.completedRepairsOn == null;

        let labelColor: SemanticCOLORS = 'green';
        let labelContent = formatMessage(m.repairedStatus);

        if (isAwaitingShipment) {
            labelColor = 'yellow';
            labelContent = formatMessage(m.awaitingStatus);
        } else if (isAwaitingRepairs) {
            labelColor = 'orange';
            labelContent = formatMessage(m.toRepairStatus);
        }

        return (
            <Label
                {...this.getLabelProps(repair)}
                content={labelContent}
                style={{ textAlign: 'center', float: 'right' }}
            />
        );
    }

    private getStatus(repair: ServiceCallFactoryRepair): FactoryRepairStatus {
        if (repair == null) {
            return FactoryRepairStatus.unknown;
        }

        if (repair.receivedOn == null) {
            return FactoryRepairStatus.awaitingShipment;
        }

        if (repair.completedRepairsOn == null) {
            return FactoryRepairStatus.awaitingRepairs;
        }

        return FactoryRepairStatus.repaired;
    }

    private getLabelProps(repair: ServiceCallFactoryRepair): LabelProps {
        const status = this.getStatus(repair);

        switch (status) {
            case FactoryRepairStatus.awaitingShipment:
                return {
                    color: 'orange',
                    icon: 'truck'
                };

            case FactoryRepairStatus.awaitingRepairs:
                return {
                    color: 'teal',
                    icon: 'wrench'
                };

            case FactoryRepairStatus.repaired:
                return {
                    color: 'green',
                    icon: 'check'
                };

            default:
                return {
                    color: 'grey',
                    icon: 'question'
                };
        }
    }
}

const connectedComponent = injectIntl(FactoryRepairsList);
export { connectedComponent as FactoryRepairsList };