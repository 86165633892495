import { Reducer } from 'redux';
import { ActionTypes } from './actions';
import { ActionTypeKeys } from './types';
import { ProductionOrder, TreeNode, ProductionComponent, ProductionComponentTree, ItemType, Quantity } from '../../models';

export interface ProductionOrderState {
    productionOrders: ProductionOrder[];
    productionComponentTree: ProductionComponentTree;
    selectedComponents: {
        [productionComponentId: number]: SelectedProductionComponentState;
    };
    isLoading: boolean;
}

export interface ProductionOrdersState {
    byServiceCall: {
        [serviceCallId: number]: ProductionOrderState
    };

    byOrderId: {
        [orderId: number]: ProductionOrder[]
    };
}

export interface SelectedProductionComponentState {
    quantity: Quantity;
    productionComponent: ProductionComponent;
}

const initialState: ProductionOrdersState = {
    byServiceCall: {},
    byOrderId: {},
};

export const productionOrdersReducer: Reducer<ProductionOrdersState> = (state: ProductionOrdersState = initialState, action: ActionTypes) => {
    switch (action.type) {
        case ActionTypeKeys.LOAD_BY_SERVICE_CALL_REQUEST:
            return {
                ...state,
                byServiceCall: {
                    ...state.byServiceCall,
                    [action.payload]: {
                        ...state.byServiceCall[action.payload],
                        isLoading: true,
                        productionOrders: []
                    }
                }
            };

        case ActionTypeKeys.LOAD_BY_ORDER_SUCCESS: {
            return {
                ...state,
                byOrderId: {
                    ...state.byOrderId,
                    [action.payload.orderId]: action.payload.productionOrders
                }
            };
        }

        case ActionTypeKeys.LOAD_BY_SERVICE_CALL_SUCCESS:
            let tree: ProductionComponentTree = {};

            action.payload.productionComponents.forEach(x => {
                tree[x.id] = {
                    productionComponent: x,
                    node: {
                        isVisible: true,
                        isCollapsed: x.item.type !== ItemType.finishedProduct,
                        isCollapsible: x.children && x.children.length > 0,
                        isSelected: false
                    }
                };
            });

            return {
                ...state,
                byServiceCall: {
                    ...state.byServiceCall,
                    [action.payload.serviceCallId]: {
                        ...state.byServiceCall[action.payload.serviceCallId],
                        isLoading: false,
                        productionOrders: action.payload.productionOrders,
                        productionComponentTree: tree,
                        selectedComponents: {}
                    }
                },
            };

        case ActionTypeKeys.LOAD_BY_SERVICE_CALL_FAILURE:
            return {
                ...state,
                byServiceCall: {
                    ...state.byServiceCall,
                    [action.payload.serviceCallId]: {
                        ...state.byServiceCall[action.payload.serviceCallId],
                        isLoading: false,
                    }
                }
            };

        case ActionTypeKeys.EXPAND_CHILDREN:
            return {
                ...state,
                byServiceCall: {
                    ...state.byServiceCall,
                    [action.payload.serviceCallId]: {
                        ...state.byServiceCall[action.payload.serviceCallId],
                        productionComponentTree: {
                            ...state.byServiceCall[action.payload.serviceCallId].productionComponentTree,
                            [action.payload.productionComponentId]: {
                                ...state.byServiceCall[action.payload.serviceCallId].productionComponentTree[action.payload.productionComponentId],
                                node: {
                                    ...state.byServiceCall[action.payload.serviceCallId].productionComponentTree[action.payload.productionComponentId].node,
                                    isCollapsed: false
                                }
                            }
                        }
                    }
                }
            };

        case ActionTypeKeys.COLLAPSE_CHILDREN:
            return {
                ...state,
                byServiceCall: {
                    ...state.byServiceCall,
                    [action.payload.serviceCallId]: {
                        ...state.byServiceCall[action.payload.serviceCallId],
                        productionComponentTree: {
                            ...state.byServiceCall[action.payload.serviceCallId].productionComponentTree,
                            [action.payload.productionComponentId]: {
                                ...state.byServiceCall[action.payload.serviceCallId].productionComponentTree[action.payload.productionComponentId],
                                node: {
                                    ...state.byServiceCall[action.payload.serviceCallId].productionComponentTree[action.payload.productionComponentId].node,
                                    isCollapsed: true
                                }
                            }
                        }
                    }
                }
            };

        case ActionTypeKeys.SELECT_COMPONENT:
            const selectedComponent = state.byServiceCall[action.payload.serviceCallId].productionComponentTree[action.payload.productionComponentId].productionComponent;

            return {
                ...state,
                byServiceCall: {
                    ...state.byServiceCall,
                    [action.payload.serviceCallId]: {
                        ...state.byServiceCall[action.payload.serviceCallId],

                        productionComponentTree: {
                            ...state.byServiceCall[action.payload.serviceCallId].productionComponentTree,
                            [action.payload.productionComponentId]: {
                                ...state.byServiceCall[action.payload.serviceCallId].productionComponentTree[action.payload.productionComponentId],
                                node: {
                                    ...state.byServiceCall[action.payload.serviceCallId].productionComponentTree[action.payload.productionComponentId].node,
                                    isSelected: true
                                }
                            }
                        },

                        selectedComponents: {
                            ...state.byServiceCall[action.payload.serviceCallId].selectedComponents,
                            [action.payload.productionComponentId]: {
                                ...state.byServiceCall[action.payload.serviceCallId].selectedComponents[action.payload.productionComponentId],
                                quantity: selectedComponent.quantityRequired,
                                productionComponent: selectedComponent
                            }
                        }
                    }
                }
            };

        case ActionTypeKeys.DESELECT_COMPONENT:
            const { [action.payload.productionComponentId]: deselectedComponent, ...remainingComponents } = state.byServiceCall[action.payload.serviceCallId].selectedComponents;

            return {
                ...state,
                byServiceCall: {
                    ...state.byServiceCall,

                    [action.payload.serviceCallId]: {
                        ...state.byServiceCall[action.payload.serviceCallId],

                        productionComponentTree: {
                            ...state.byServiceCall[action.payload.serviceCallId].productionComponentTree,

                            [action.payload.productionComponentId]: {
                                ...state.byServiceCall[action.payload.serviceCallId].productionComponentTree[action.payload.productionComponentId],

                                node: {
                                    ...state.byServiceCall[action.payload.serviceCallId].productionComponentTree[action.payload.productionComponentId].node,
                                    isSelected: false
                                }
                            }
                        },

                        selectedComponents: remainingComponents
                    }
                }
            };

        case ActionTypeKeys.CHANGE_COMPONENT_QUANTITY:
            return {
                ...state,
                byServiceCall: {
                    ...state.byServiceCall,

                    [action.payload.serviceCallId]: {
                        ...state.byServiceCall[action.payload.serviceCallId],

                        selectedComponents: {
                            ...state.byServiceCall[action.payload.serviceCallId].selectedComponents,

                            [action.payload.productionComponentId]: {
                                ...state.byServiceCall[action.payload.serviceCallId].selectedComponents[action.payload.productionComponentId],
                                quantity: {
                                    ...state.byServiceCall[action.payload.serviceCallId].selectedComponents[action.payload.productionComponentId].quantity,
                                    value: action.payload.newValue
                                }
                            }
                        }
                    }
                }
            };

        default:
            return state;
    }
};

export default productionOrdersReducer;