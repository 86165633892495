import React from 'react';
import moment from 'moment';

import { injectIntl, WrappedComponentProps, defineMessages, FormattedMessage } from 'react-intl';
import { Feed, Popup } from 'semantic-ui-react';

import { InspectionCompletedUpdate, ServiceCallInspection } from '../../../state/models';
import { InspectionReportModal } from '../reports';

interface InspectionCompletedActivityOwnProps {
    activity: InspectionCompletedUpdate;
    inspection: ServiceCallInspection | undefined;
}

export type InspectionCompletedActivityProps =
    & InspectionCompletedActivityOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    inspectionCompleted: { id: 'ServiceCallRecentActivity.inspection_completed', defaultMessage: 'The Jaymar representative has completed the inspection of the furniture.' },
    viewReport: { id: 'InspectionCompletedActivity.view_report', defaultMessage: 'View report' }
});

class InspectionCompletedActivity extends React.Component<InspectionCompletedActivityProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { activity, inspection } = this.props;

        return (
            <Feed.Event key={activity.id}>
                <Feed.Label icon="eye" />
                <Feed.Content>
                    <Popup
                        trigger={<Feed.Date content={moment.utc(activity.createdOn).fromNow()} />}
                        content={moment.utc(activity.createdOn).format('LL')}
                    />

                    <Feed.Summary>
                        <FormattedMessage {...m.inspectionCompleted} />
                    </Feed.Summary>

                    <Feed.Extra text={true}>
                        {inspection != null &&
                            <InspectionReportModal
                                trigger={<a>{formatMessage(m.viewReport)}</a>}
                                inspectionReport={inspection}
                            />
                        }
                    </Feed.Extra>
                </Feed.Content>
            </Feed.Event>
        );
    }
}

const connectedComponent = injectIntl(InspectionCompletedActivity);
export { connectedComponent as InspectionCompletedActivity };