import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { defineMessages, useIntl } from 'react-intl';
import { Button, Card, Form } from 'semantic-ui-react';

import { AppointmentTimeRange, CalendarBreak, ServiceCallInspection } from '../../../state/models';
import { CalendarEvent, CalendarModal } from '../../calendar';
import { LoadingContainer, LoadingDimmer, TimeRangeButton, DatePickerWithoutKeyboard } from '../../common';
import { RepresentativeCalendarEvent } from './RepresentativeCalendarEvent';
import { useResponsive } from '../../../utils/responsive';

const m = defineMessages({
    title: { id: 'InspectionAppointmentCard.title', defaultMessage: 'Schedule the appointment' },
    subtitle: { id: 'InspectionAppointmentCard.subtitle', defaultMessage: 'Select the date and time when the inspection will occur.' },
    appointmentDatePlaceholder: { id: 'InspectionAppointmentCard.appointment_date', defaultMessage: 'YYYY/MM/DD' },
    viewUpcomingAppointments: { id: 'InspectionAppointmentCard.view_upcoming_appointments', defaultMessage: 'View Upcoming Appointments' },
    calendarModalTitle: { id: 'InspectionsCalendarModal.title', defaultMessage: 'Inspections schedule' },
    calendarModalSubtitle: { id: 'InspectionsCalendarModal.subtitle', defaultMessage: 'View the appointments that were scheduled for the service call inspections.' }
});

interface OwnProps {
    inspection: ServiceCallInspection;
    isLoading: boolean;
    isReadOnly: boolean;
    calendarInspections: ServiceCallInspection[];
    calendarBreaks: CalendarBreak[];
    areCalendarInspectionsLoading: boolean;
    onChangeAppointmentDate: (date?: Date) => void;
    onChangeAppointmentTime: (timeRange?: AppointmentTimeRange) => void;
    onChangeCalendarDate: (date: Date) => void;
    onInspectionClicked: (inspection: ServiceCallInspection) => void;
}

export type InspectionAppointmentCardProps =
    & OwnProps;

export const InspectionAppointmentCard: React.FC<InspectionAppointmentCardProps> = (props) => {
    const { formatMessage } = useIntl();
    const { isMobile } = useResponsive();
    const [selectedAppointmentDate, selectAppointmentDate] = useState<Date | undefined>(props.inspection?.scheduledForDate != null ? moment(props.inspection.scheduledForDate).local().toDate() : undefined);
    const [selectedAppointmentTime, selectAppointmentTime] = useState<AppointmentTimeRange | undefined>(props.inspection?.scheduledForTimeRange);
    const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);
    const [calendarModalStartDate, setCalendarModalStartDate] = useState(moment().startOf('day'));

    useEffect(() => {
        selectAppointmentDate(props.inspection?.scheduledForDate != null ? moment(props.inspection.scheduledForDate).local().toDate() : undefined);
        selectAppointmentTime(props.inspection?.scheduledForTimeRange);
    }, [props.inspection]);

    const calendarEvents: CalendarEvent[] = props.calendarInspections
        .filter(x => x.scheduledForDate != null)
        .map(x => ({
            on: moment(x.scheduledForDate).local().startOf('day').toDate(),
            element: <RepresentativeCalendarEvent key={x.id} inspection={x} onClick={props.onInspectionClicked} />
        }));

    const handleCalendarDateChange = (date: Date) => {
        selectAppointmentDate(date);
        setIsCalendarModalOpen(false);

        props.onChangeAppointmentDate(date);
    }

    // tslint:disable-next-line:no-any (using same declaration as underlying event handler)
    const handleDateChange = (date: Date | null): any => {
        selectAppointmentDate(date || undefined);

        props.onChangeAppointmentDate(date || undefined);
    }

    const handleChangeAppointmentsMonth = (date: Date) => {
        setCalendarModalStartDate(moment(date));
        props.onChangeCalendarDate(date);
    }

    const handleTimeChange = (timeRange?: AppointmentTimeRange) => {
        selectAppointmentTime(timeRange);
        props.onChangeAppointmentTime(timeRange);
    }

    const isNotLocked = (date: Date): boolean => {
        const dateMoment = moment(date);
        const weekday = dateMoment.isoWeekday();
        const isSunday = weekday === 7;
        const isLockedForEveryone = props.calendarBreaks.filter(x => dateMoment.isSame(x.forDate, 'day') && x.forUserId == null).length > 0;

        return !isSunday && !isLockedForEveryone;
    }

    return (
        <LoadingContainer>
            <LoadingDimmer active={props.isLoading} />
            <Card fluid={true} color="teal" style={{ height: '100%', marginTop: 0 }}>
                <Card.Content style={{ flexGrow: 0 }}>
                    <Card.Header content={formatMessage(m.title)} />
                    <Card.Meta content={formatMessage(m.subtitle)} />
                </Card.Content>
                <Card.Content>
                    <Form>
                        <Form.Group>
                            <Form.Field disabled={props.isReadOnly}>
                                {isMobile &&
                                    <DatePickerWithoutKeyboard
                                        isClearable={true}
                                        selected={selectedAppointmentDate != null ? moment(selectedAppointmentDate).toDate() : undefined}
                                        dateFormat="YYYY/MM/DD"
                                        filterDate={isNotLocked}
                                        fixedHeight={true}
                                        minDate={new Date()}
                                        placeholderText={formatMessage(m.appointmentDatePlaceholder)}
                                        value={selectedAppointmentDate ? moment(selectedAppointmentDate).format('YYYY/MM/DD') : undefined}
                                        onChange={handleDateChange}
                                    />
                                }
                                {!isMobile &&
                                    <DatePicker
                                        isClearable={true}
                                        selected={selectedAppointmentDate != null ? moment(selectedAppointmentDate).toDate() : undefined}
                                        dateFormat="YYYY/MM/DD"
                                        filterDate={isNotLocked}
                                        fixedHeight={true}
                                        minDate={new Date()}
                                        placeholderText={formatMessage(m.appointmentDatePlaceholder)}
                                        value={selectedAppointmentDate ? moment(selectedAppointmentDate).format('YYYY/MM/DD') : undefined}
                                        onChange={handleDateChange}
                                    />
                                }
                            </Form.Field>
                            <Form.Field disabled={props.isReadOnly}>
                                <TimeRangeButton
                                    timeRange={selectedAppointmentTime}
                                    onChange={handleTimeChange}
                                />
                            </Form.Field>
                        </Form.Group>

                        <Button
                            content={formatMessage(m.viewUpcomingAppointments)}
                            disabled={props.isReadOnly}
                            onClick={() => {
                                handleChangeAppointmentsMonth(moment().startOf('day').startOf('month').toDate());
                                setIsCalendarModalOpen(true);
                                setCalendarModalStartDate(moment().startOf('day'));
                            }}
                        />

                        <CalendarModal
                            open={isCalendarModalOpen}
                            selectedDate={selectedAppointmentDate}
                            forDate={calendarModalStartDate.toDate()}
                            events={calendarEvents}
                            lockedDates={props.calendarBreaks}
                            isLoading={props.areCalendarInspectionsLoading}
                            canSelectDate={true}
                            onMonthChanged={(date: Date) => handleChangeAppointmentsMonth(date)}
                            onClose={() => setIsCalendarModalOpen(false)}
                            onCancel={() => setIsCalendarModalOpen(false)}
                            title={formatMessage(m.calendarModalTitle)}
                            subtitle={formatMessage(m.calendarModalSubtitle)}
                            closeIcon={true}
                            onDateSelected={(date: Date) => handleCalendarDateChange(date)}
                        />
                    </Form>
                </Card.Content>
            </Card>
        </LoadingContainer>
    );
};