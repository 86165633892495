import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Button, Card, Icon } from 'semantic-ui-react';
import moment from 'moment';

import { ServiceCallStatusLabel, LinkedCallsLabel } from '.';
import { ServiceCall } from '../../state/models';
import { StringHelper } from '../../state/utils';
import { commonMessages } from '../../constants';

export interface ServiceCallCardProps {
    serviceCall: ServiceCall;
    onViewDetails?: (serviceCall: ServiceCall) => void;
}

const m = defineMessages({
    lastUpdated: { id: 'ServiceCallCard.last_updated', defaultMessage: 'Last updated {date}' },
    createdOn: { id: 'ServiceCallCard.created_on', defaultMessage: 'Created {date}' },
    billNumber: { id: 'ServiceCallCard.bill_number', defaultMessage: 'Bill #{billNumber}' },
    viewDetailsButton: { id: 'ServiceCallCard.view_details', defaultMessage: 'More Details' },
    noBill: { id: 'ServiceCallCard.no_bill', defaultMessage: 'No bill provided' },
});

class ServiceCallCard extends React.Component<ServiceCallCardProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { serviceCall } = this.props;
        const { customer, bill } = this.props.serviceCall;
        const linkedServiceCallsCount = bill != null && bill.serviceCalls != null
            ? bill.serviceCalls.length
            : 0;

        return (
            <Card fluid={true}>
                <Card.Content>
                    <Card.Header>
                        <a onClick={this.handleViewDetails}>
                            {customer && StringHelper.hasValue(customer.name) ? customer.name : formatMessage(commonMessages.unknown)}
                        </a>
                        {linkedServiceCallsCount > 1 &&
                            <LinkedCallsLabel count={linkedServiceCallsCount} style={{ margin: '0 0 0 10px' }} />
                        }
                        <ServiceCallStatusLabel status={this.props.serviceCall.status} style={{ float: 'right' }} />
                    </Card.Header>
                    <Card.Meta content={serviceCall.billId != null ? formatMessage(m.billNumber, { billNumber: serviceCall.billId }) : formatMessage(m.noBill)} />
                </Card.Content>

                <Card.Content>
                    <Button
                        fluid={true}
                        basic={true}
                        content={formatMessage(m.viewDetailsButton)}
                        onClick={this.handleViewDetails}
                        style={{ margin: '10px 0 0 0' }}
                    />
                </Card.Content>

                <Card.Content extra={true}>
                    <span style={{ display: 'block' }}>
                        <Icon name="clock" />
                        {formatMessage(m.createdOn, { date: moment(serviceCall.createdOn).local().fromNow() })}
                    </span>

                    {serviceCall.updatedOn != null &&
                        <span style={{ display: 'block' }}>
                            <Icon name="clock" />
                            {formatMessage(m.lastUpdated, { date: moment(serviceCall.updatedOn).local().fromNow() })}
                        </span>
                    }
                </Card.Content>
            </Card>
        );
    }

    private handleViewDetails = () => {
        if (this.props.onViewDetails) {
            this.props.onViewDetails(this.props.serviceCall);
        }
    }
}

const connectedComponent = injectIntl(ServiceCallCard);
export { connectedComponent as ServiceCallCard };