import React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages, useIntl } from 'react-intl';
import { Button, Table } from 'semantic-ui-react';
import moment from 'moment';
import { commonMessages } from '../../constants';
import { ServiceCallStatusLabel } from '.';
import { StringHelper } from '../../state/utils';
import { ServiceCall } from '../../state/models';
import { ServiceCallStatusIcon } from './ServiceCallStatusIcon';
import { UserHelper } from '../../helpers';
import { CustomerBillSummary } from './CustomerBillSummary';
import { DateFromNow } from '../common/DateFromNow';
import { useResponsive } from 'utils/responsive';

const m = defineMessages({
    createdTooltip: { id: 'ServiceCallTableRow.createdTooltip', defaultMessage: 'Created by {name} on {date}' }
});

interface OwnProps {
    serviceCall: ServiceCall;
    showClient?: boolean;

    onViewDetails: (serviceCall: ServiceCall) => void;
}

export type ServiceCallTableRowProps =
    & OwnProps;

export const ServiceCallTableRow: React.FC<ServiceCallTableRowProps> = (props) => {
    const { formatMessage } = useIntl();
    const { isLargeMonitor, isWidescreenMonitor } = useResponsive();
    const isLargeResolution = isLargeMonitor || isWidescreenMonitor;
    const { serviceCall } = props;
    const { id, createdOn, updatedOn: lastUpdatedOn } = props.serviceCall;

    const viewDetails = () => props.onViewDetails(props.serviceCall);

    return (
        <Table.Row>
            <Table.Cell>
                {!isLargeResolution &&
                    <ServiceCallStatusIcon key={id} status={serviceCall.status} />
                }
                {isLargeResolution &&
                    <ServiceCallStatusLabel key={id} status={serviceCall.status} />
                }
            </Table.Cell>
            <Table.Cell>
                {serviceCall.id}
            </Table.Cell>
            <Table.Cell style={{ minWidth: 105 }}>
                <CustomerBillSummary 
                    serviceCall={serviceCall} 
                    onClick={viewDetails}
                />
            </Table.Cell>
            {props.showClient &&
                <Table.Cell>
                    <span>{StringHelper.trim(serviceCall.clientId)}</span>
                </Table.Cell>
            }
            <Table.Cell>
                {serviceCall.assignedTo &&
                    <span>{serviceCall.assignedTo.firstName + ' ' + serviceCall.assignedTo.lastName}</span>
                }
            </Table.Cell>
            <Table.Cell>
                {createdOn &&
                    <DateFromNow date={createdOn}>
                        {formatMessage(m.createdTooltip, { name: UserHelper.getDisplayName(serviceCall.createdBy), date: moment.utc(createdOn).format('LL') })}
                    </DateFromNow>
                }
            </Table.Cell>
            <Table.Cell>{lastUpdatedOn && <DateFromNow date={lastUpdatedOn} />}</Table.Cell>
            <Table.Cell textAlign="right">
                <Button onClick={viewDetails}>{formatMessage(commonMessages.view)}</Button>
            </Table.Cell>
        </Table.Row>
    );
};