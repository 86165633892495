import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Accordion, Divider, Icon, SemanticICONS } from 'semantic-ui-react';
import { commonMessages } from '../../../constants';

interface OwnProps {
    icon: SemanticICONS
    title: string;
    isExpanded?: boolean;
    onClear: () => void;
    onClick: () => void;
}

export type SearchSectionHeaderProps =
    & OwnProps
    & WrappedComponentProps;

class SearchSectionHeader extends React.Component<SearchSectionHeaderProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <Accordion.Title active={this.props.isExpanded} onClick={this.props.onClick}>
                <div>
                    <Icon name="dropdown"></Icon>
                    <strong><Icon name={this.props.icon} />{this.props.title}</strong>
                    <a style={{ float: 'right', cursor: 'pointer' }} onClick={this.handleClear}>
                        {formatMessage(commonMessages.clear)}
                    </a>
                </div>
                <Divider clearing={true} />
            </Accordion.Title>
        );
    }

    private handleClear = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.stopPropagation();
        this.props.onClear();
    }
}

const connectedComponent = injectIntl(SearchSectionHeader);
export { connectedComponent as SearchSectionHeader };