import { ThunkAction } from 'redux-thunk';
import { ActionTypeKeys } from '../types';
import { PagedResult, ServiceCall, FilterOptions, ServiceCallNote, ServiceCallUpdate, SortOptions, SortDirection, NoteVisibility } from '../../../models';
import * as globalNotification from '../../global-notification';
import { ApplicationState } from '../..';
import { ServiceCallsService, SubcontractorsService } from '../../../../services';
import { Subcontractor } from '../../../models/Subcontractor';
import { ServiceCallEdit } from '../../../models/edits/ServiceCallEdit';
import { AppThunk } from '../../../store';

export type ServiceCallActionTypes =
    | CreateServiceCallRequestAction | CreateServiceCallSuccessAction | CreateServiceCallFailureAction
    | UpdateServiceCallRequestAction | UpdateServiceCallSuccessAction | UpdateServiceCallFailureAction
    | LoadServiceCallDetailsRequestAction | LoadServiceCallDetailsSuccessAction | LoadServiceCallDetailsFailureAction
    | LoadNewServiceCallsCountRequestAction | LoadNewServiceCallsCountSuccessAction | LoadNewServiceCallsCountFailureAction
    | LoadMyServiceCallsRequestAction | LoadMyServiceCallsSuccessAction | LoadMyServiceCallsFailureAction
    | LoadStaleServiceCallsRequestAction | LoadStaleServiceCallsSuccessAction | LoadStaleServiceCallsFailureAction
    | LoadOpenedServiceCallsRequestAction | LoadOpenedServiceCallsSuccessAction | LoadOpenedServiceCallsFailureAction
    | LoadCustomServiceCallsRequestAction | LoadCustomServiceCallsSuccessAction | LoadCustomServiceCallsFailureAction
    | LoadServiceCallsHistoryRequestAction | LoadServiceCallsHistorySuccessAction | LoadServiceCallsHistoryFailureAction
    | LoadSubcontractorsRequestAction | LoadSubcontractorsSuccessAction | LoadSubcontractorsFailureAction
    | LoadActivitiesRequestAction | LoadActivitiesSuccessAction | LoadActivitiesFailureAction
    | { type: '' };

interface CreateServiceCallRequestAction { type: ActionTypeKeys.CREATE_REQUEST; payload: ServiceCall; }
interface CreateServiceCallSuccessAction { type: ActionTypeKeys.CREATE_SUCCESS; payload: ServiceCall; }
interface CreateServiceCallFailureAction { type: ActionTypeKeys.CREATE_FAILURE; payload: string; }
interface UpdateServiceCallRequestAction { type: ActionTypeKeys.UPDATE_REQUEST; payload: ServiceCall; }
interface UpdateServiceCallSuccessAction { type: ActionTypeKeys.UPDATE_SUCCESS; payload: ServiceCall; }
interface UpdateServiceCallFailureAction { type: ActionTypeKeys.UPDATE_FAILURE; payload: string; }
interface LoadNewServiceCallsCountRequestAction { type: ActionTypeKeys.LOAD_NEW_COUNT_REQUEST; }
interface LoadNewServiceCallsCountSuccessAction { type: ActionTypeKeys.LOAD_NEW_COUNT_SUCCESS; payload: number; }
interface LoadNewServiceCallsCountFailureAction { type: ActionTypeKeys.LOAD_NEW_COUNT_FAILURE; payload: string; }
interface LoadServiceCallDetailsRequestAction { type: ActionTypeKeys.LOAD_DETAILS_REQUEST; payload: number; }
interface LoadServiceCallDetailsSuccessAction { type: ActionTypeKeys.LOAD_DETAILS_SUCCESS; payload: ServiceCall; }
interface LoadServiceCallDetailsFailureAction { type: ActionTypeKeys.LOAD_DETAILS_FAILURE; payload: { id: number, error: string }; }
interface LoadMyServiceCallsRequestAction { type: ActionTypeKeys.LOAD_MINE_REQUEST; }
interface LoadMyServiceCallsSuccessAction { type: ActionTypeKeys.LOAD_MINE_SUCCESS; payload: PagedResult<ServiceCall>; }
interface LoadMyServiceCallsFailureAction { type: ActionTypeKeys.LOAD_MINE_FAILURE; }
interface LoadStaleServiceCallsRequestAction { type: ActionTypeKeys.LOAD_STALE_REQUEST; }
interface LoadStaleServiceCallsSuccessAction { type: ActionTypeKeys.LOAD_STALE_SUCCESS; payload: PagedResult<ServiceCall>; }
interface LoadStaleServiceCallsFailureAction { type: ActionTypeKeys.LOAD_STALE_FAILURE; }
interface LoadOpenedServiceCallsRequestAction { type: ActionTypeKeys.LOAD_OPENED_REQUEST; }
interface LoadOpenedServiceCallsSuccessAction { type: ActionTypeKeys.LOAD_OPENED_SUCCESS; payload: PagedResult<ServiceCall>; }
interface LoadOpenedServiceCallsFailureAction { type: ActionTypeKeys.LOAD_OPENED_FAILURE; }
interface LoadCustomServiceCallsRequestAction { type: ActionTypeKeys.LOAD_CUSTOM_REQUEST; }
interface LoadCustomServiceCallsSuccessAction { type: ActionTypeKeys.LOAD_CUSTOM_SUCCESS; payload: PagedResult<ServiceCall>; }
interface LoadCustomServiceCallsFailureAction { type: ActionTypeKeys.LOAD_CUSTOM_FAILURE; }
interface LoadServiceCallsHistoryRequestAction { type: ActionTypeKeys.LOAD_HISTORY_REQUEST; }
interface LoadServiceCallsHistorySuccessAction { type: ActionTypeKeys.LOAD_HISTORY_SUCCESS; payload: PagedResult<ServiceCall>; }
interface LoadServiceCallsHistoryFailureAction { type: ActionTypeKeys.LOAD_HISTORY_FAILURE; }
interface LoadSubcontractorsRequestAction { type: ActionTypeKeys.LOAD_SUBCONTRACTORS_REQUEST; }
interface LoadSubcontractorsSuccessAction { type: ActionTypeKeys.LOAD_SUBCONTRACTORS_SUCCESS; payload: Subcontractor[]; }
interface LoadSubcontractorsFailureAction { type: ActionTypeKeys.LOAD_SUBCONTRACTORS_FAILURE; payload: string; }
interface LoadActivitiesRequestAction { type: ActionTypeKeys.LOAD_ACTIVITIES_REQUEST; payload: { id: number }; }
interface LoadActivitiesSuccessAction { type: ActionTypeKeys.LOAD_ACTIVITIES_SUCCESS; payload: { id: number, activities: ServiceCallUpdate[] }; }
interface LoadActivitiesFailureAction { type: ActionTypeKeys.LOAD_ACTIVITIES_FAILURE; payload: { id: number, error: string }; }

const serviceCallService = new ServiceCallsService();
const subcontractorsService = new SubcontractorsService();

export const serviceCallActionCreators = {
    createServiceCall: (serviceCall: ServiceCallEdit, onSuccess?: (serviceCall: ServiceCall) => void): AppThunk => {
        return (dispatch) => {
            dispatch({ type: ActionTypeKeys.CREATE_REQUEST });

            serviceCallService.createServiceCall(serviceCall)
                .then(response => response.json() as ServiceCall)
                .then(createdServiceCall => {
                    dispatch({ type: ActionTypeKeys.CREATE_SUCCESS, payload: createdServiceCall });

                    if (onSuccess) {
                        onSuccess(createdServiceCall);
                    }
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.CREATE_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de créer votre appel de service (${error}).`)
                    );
                });
        };
    },

    updateServiceCall: (serviceCall: ServiceCallEdit, onSuccess?: (serviceCall: ServiceCall) => void): AppThunk => {
        return (dispatch) => {
            dispatch({ type: ActionTypeKeys.UPDATE_REQUEST });

            serviceCallService.updateServiceCall(serviceCall)
                .then(response => response.json() as ServiceCall)
                .then(updatedServiceCall => {
                    dispatch({ type: ActionTypeKeys.UPDATE_SUCCESS, payload: updatedServiceCall });

                    if (onSuccess) {
                        onSuccess(updatedServiceCall);
                    }
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.UPDATE_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de mettre à jour votre appel de service (${error}).`)
                    );
                });
        };
    },

    loadOpenedServiceCalls: (page?: number, filters?: FilterOptions, sortings?: SortOptions): AppThunk => {
        return (dispatch) => {
            dispatch({ type: ActionTypeKeys.LOAD_OPENED_REQUEST });

            serviceCallService.getOpenedServiceCalls(page, filters, sortings)
                // tslint:disable-next-line:no-any
                .then(response => response.json() as PagedResult<ServiceCall>)
                .then(serviceCalls => {
                    dispatch({ type: ActionTypeKeys.LOAD_OPENED_SUCCESS, payload: serviceCalls });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_OPENED_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de récupérer les appels de service ouverts (${error}).`)
                    );
                });
        };
    },

    loadOpenedServiceCallsInCustomView: (page?: number, filters?: FilterOptions, sortings?: SortOptions): AppThunk => {
        return (dispatch) => {
            dispatch({ type: ActionTypeKeys.LOAD_CUSTOM_REQUEST });

            serviceCallService.getOpenedServiceCalls(page, filters, sortings)
                .then(response => response.json() as PagedResult<ServiceCall>)
                .then(serviceCalls => {
                    dispatch({ type: ActionTypeKeys.LOAD_CUSTOM_SUCCESS, payload: serviceCalls });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_CUSTOM_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de récupérer les appels de service ouverts (${error}).`)
                    );
                });
        };
    },

    loadMyServiceCalls: (page?: number, filters?: FilterOptions, sortings?: SortOptions): AppThunk => {
        return (dispatch) => {
            dispatch({ type: ActionTypeKeys.LOAD_MINE_REQUEST });

            serviceCallService.getMyServiceCalls(page, filters, sortings)
                .then(response => response.json() as PagedResult<ServiceCall>)
                .then(serviceCalls => {
                    dispatch({ type: ActionTypeKeys.LOAD_MINE_SUCCESS, payload: serviceCalls });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_MINE_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de récupérer vos appels de service (${error}).`)
                    );
                });
        };
    },

    loadStaleServiceCalls: (page?: number, sortings?: SortOptions): AppThunk => {
        return (dispatch) => {
            dispatch({ type: ActionTypeKeys.LOAD_STALE_REQUEST });

            serviceCallService.getStaleServiceCalls(page, sortings)
                .then(response => response.json() as PagedResult<ServiceCall>)
                .then(serviceCalls => {
                    dispatch({ type: ActionTypeKeys.LOAD_STALE_SUCCESS, payload: serviceCalls });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_MINE_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de récupérer les appels de service non modifiés depuis 30 jours (${error}).`)
                    );
                });
        };
    },

    loadServiceCallsHistory: (page?: number, filters?: FilterOptions, sortings?: SortOptions): AppThunk => {
        return (dispatch) => {
            dispatch({ type: ActionTypeKeys.LOAD_HISTORY_REQUEST });

            serviceCallService.getHistory(page, filters, sortings)
                .then(response => response.json() as PagedResult<ServiceCall>)
                .then(serviceCalls => {
                    dispatch({ type: ActionTypeKeys.LOAD_HISTORY_SUCCESS, payload: serviceCalls });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_HISTORY_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de récupérer l'historique de vos appels de service (${error}).`)
                    );
                });
        };
    },

    loadDetails: (serviceCallId: number): AppThunk => {
        return (dispatch) => {
            dispatch({ type: ActionTypeKeys.LOAD_DETAILS_REQUEST, payload: serviceCallId });

            serviceCallService.getDetails(serviceCallId)
                .then(response => response.json() as ServiceCall)
                .then(serviceCall => {
                    dispatch({ type: ActionTypeKeys.LOAD_DETAILS_SUCCESS, payload: serviceCall });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_DETAILS_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de récupérer les détails de l'appel de service (${error}).`)
                    );
                });
        };
    },

    loadSubcontractors: (): AppThunk => {
        return (dispatch) => {
            dispatch({ type: ActionTypeKeys.LOAD_SUBCONTRACTORS_REQUEST });

            subcontractorsService.getSubcontractors()
                .then(response => response.json() as Subcontractor[])
                .then(result => {
                    dispatch({ type: ActionTypeKeys.LOAD_SUBCONTRACTORS_SUCCESS, payload: result });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_SUBCONTRACTORS_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de charger la liste des sous-traitants (${error}).`)
                    );
                });
        };
    },

    loadActivities: (serviceCallId: number): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_ACTIVITIES_REQUEST, payload: { id: serviceCallId } });

            serviceCallService.getActivities(serviceCallId)
                .then(response => response.json() as ServiceCallUpdate[])
                .then(result => {
                    dispatch({ type: ActionTypeKeys.LOAD_ACTIVITIES_SUCCESS, payload: { id: serviceCallId, activities: result } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_ACTIVITIES_FAILURE, payload: { id: serviceCallId, error } });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de charger la liste des activités récentes (${error}).`)
                    );
                });
        };
    }
};