import * as React from 'react';

import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';

import { FilterSection } from '../common';
import { RegionFilter, initialRegionFilter, allCountries, allProvinces } from '../../state/models';
import { countries, commonMessages, provincesByCountryMap } from '../../constants';

interface FilterByRegionSectionOwnProps {
    filter: RegionFilter;
    onChange: (filter: RegionFilter) => void;
    onChangeCountry: (countryCode: string) => void;
}

export type FilterByRegionSectionProps =
    & FilterByRegionSectionOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'FilterByRegionSection.title', defaultMessage: 'Filter by region' },
    titleTooltip: { id: 'FilterByRegionSection.title_tooltip', defaultMessage: 'Only countries and provinces with at least one service call are displayed in the filter options.'},
    allCountries: { id: 'FilterByRegionSection.countries_placeholder', defaultMessage: 'All countries' },
    allProvinces: { id: 'FilterByRegionSection.provinces_placeholder', defaultMessage: 'All provinces' }
});

class FilterByRegionSection extends React.Component<FilterByRegionSectionProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <FilterSection 
                title={formatMessage(m.title)}
                titleTooltip={formatMessage(m.titleTooltip)}
                onClear={this.clearSection}
            >
                {this.renderCountryDropdown()}
                {this.renderProvinceDropdown()}
            </FilterSection>
        );
    }

    private renderCountryDropdown() {
        const { formatMessage } = this.props.intl;

        const allCountriesOption = { key: 'all-countries', value: allCountries, text: formatMessage(m.allCountries), selected: this.props.filter.countryCode === allCountries };
        const countriesOptions = this.props.filter.availableCountryCodes.map(x => {
            const countryInfo = countries.find(y => y.value === x.toLowerCase());
            return {
                key: x,
                value: x,
                flag: countryInfo ? countryInfo.flag : undefined,
                text: countryInfo ? formatMessage(countryInfo.intlMessage) : formatMessage(commonMessages.unknown),
                selected: this.props.filter.countryCode === x
            };
        })
        .sort((a, b) => (a.text as string).localeCompare(b.text as string));

        return (
            <Dropdown
                fluid={true}
                selection={true}
                loading={this.props.filter.areCountryCodesLoading}
                disabled={this.props.filter.areCountryCodesLoading}
                value={this.props.filter.countryCode}
                options={[allCountriesOption, ...countriesOptions]}
                onChange={this.changeCountry}
            />
        );
    }

    private renderProvinceDropdown() {
        const { formatMessage } = this.props.intl;

        const provincesForSelectedCountry = this.props.filter.countryCode != null
            ? provincesByCountryMap.get(this.props.filter.countryCode.toLowerCase())
            : undefined;

        const allProvincesOption = { key: 'all-provinces', value: allProvinces, text: formatMessage(m.allProvinces), selected: this.props.filter.provinceCode === allProvinces };
        const provincesOptions = provincesForSelectedCountry != null
            ? this.props.filter.availableProvinceCodes
                .map(x => provincesForSelectedCountry.find(y => y.value === x.toLowerCase()) || { key: x, value: x, intlMessage: commonMessages.unknown })
                .map(x => ({
                    key: x.key,
                    value: x.value.toUpperCase(),
                    text: formatMessage(x.intlMessage),
                    selected: x.value.toUpperCase() === this.props.filter.provinceCode
                }))
                .sort((a, b) => (a.text as string).localeCompare(b.text as string))
            : [];

        return (
            <Dropdown
                fluid={true}
                selection={true}
                loading={this.props.filter.areProvinceCodesLoading}
                disabled={this.props.filter.areProvinceCodesLoading}
                value={this.props.filter.provinceCode}
                options={[allProvincesOption, ...provincesOptions]}
                onChange={this.changeProvince}
                style={{marginTop: '5px'}}
            />
        );
    }

    private changeCountry = (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        this.props.onChangeCountry(data.value as string);

        this.props.onChange({
            ...this.props.filter,
            countryCode: data.value as string,
            provinceCode: allProvinces
        });
    }

    private changeProvince = (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        this.props.onChange({
            ...this.props.filter,
            provinceCode: data.value as string
        });
    }

    private clearSection = () => {
        this.props.onChange({
            ...this.props.filter,
            countryCode: initialRegionFilter.countryCode,
            provinceCode: initialRegionFilter.provinceCode
        });
    }
}

const connectedComponent = injectIntl(FilterByRegionSection);
export { connectedComponent as FilterByRegionSection };