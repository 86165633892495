import React from "react";
import { Button, List } from "semantic-ui-react";
import { Client } from "../../../state/models";

interface OwnProps {
    client: Client;
    onDelete: () => void;
}

export type ClientListItemProps =
    & OwnProps;

export const ClientListItem: React.FC<ClientListItemProps> = (props) => {
    return (
        <List.Item>
            <List.Content floated="right">
                <Button icon="times" onClick={props.onDelete} />
            </List.Content>

            <List.Icon name="chain" />
            <List.Content>
                <List.Header>{`${props.client.id} - ${props.client.name}`}</List.Header>
                <List.Description>{props.client.alternateName}</List.Description>
            </List.Content>
        </List.Item>
    );
};