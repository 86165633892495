import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

import { ServiceCallInspection } from '../../../state/models';
import { PageHeader, SectionHeader } from '../../../components/common';
import { InspectionTable } from '../../../components/service-calls/inspection';
import { actionCreators as InspectionsActions } from '../../../state/ducks/inspections';
import {
    getUpcomingInspections,
    getUnscheduledInspections,
    areUpcomingInspectionsLoading,
    areUnscheduledInspectionsLoading
} from '../../../state/ducks/inspections/selectors';
import { StatisticTile } from '../../../components/reporting';
import { useResponsive } from '../../../utils/responsive';


const m = defineMessages({
    title: { id: 'RepresentativeDashboardPage.title', defaultMessage: 'Dashboard' },
    subtitle: { id: 'RepresentativeDashboardPage.subtitle', defaultMessage: 'View a quick overview of your upcoming service call inspections.' },
    quickOverviewTitle: { id: 'RepresentativeDashboardPage.quick_overview_title', defaultMessage: 'Quick overview' },
    totalInspectionsLabel: { id: 'RepresentativeDashboardPage.total_inspections_label', defaultMessage: 'Total inspections' },
    upcomingInspectionsLabel: { id: 'RepresentativeDashboardPage.upcoming_inspections_label', defaultMessage: 'Upcoming inspections' },
    inspectionsToScheduleLabel: { id: 'RepresentativeDashboardPage.inspections_to_schedule_label', defaultMessage: 'Inspections to schedule' },
    upcomingInspectionsTitle: { id: 'RepresentativeDashboardPage.upcoming_inspections', defaultMessage: 'Upcoming inspections' },
    inspectionsToScheduleTitle: { id: 'RepresentativeDashboardPage.inspections_to_schedule_title', defaultMessage: 'Inspections to schedule' },
    noUpcomingInspectionsTitle: { id: 'RepresentativeDashboardPage.no_scheduled_inspections_title', defaultMessage: 'No upcoming inspections' },
    noUpcomingInspectionsSubtitle: { id: 'RepresentativeDashboardPage.no_upcoming_inspections_subtitle', defaultMessage: 'There are no upcoming inspections scheduled.' },
    noInspectionsToScheduleTitle: { id: 'RepresentativeDashboardPage.no_inspections_to_schedule_title', defaultMessage: 'No inspections to schedule' },
    noInspectionsToScheduleSubtitle: { id: 'RepresentativeDashboardPage.no_inspections_to_schedule_subtitle', defaultMessage: 'There are no inspections that require to be scheduled for the moment.' }
});

const maxInspections = 10;

export const RepresentativeDashboardPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const { isMobile } = useResponsive();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(InspectionsActions.loadUpcoming());
        dispatch(InspectionsActions.loadUnscheduled());
    }, [dispatch]);

    const upcomingInspections = useSelector(getUpcomingInspections);
    const upcomingInspectionsLoading = useSelector(areUpcomingInspectionsLoading);
    const displayedUpcomingInspections = upcomingInspections.slice(0, maxInspections);

    const unscheduledInspections = useSelector(getUnscheduledInspections);
    const unscheduledInspectionsLoading = useSelector(areUnscheduledInspectionsLoading);
    const displayedUnscheduledInspections = unscheduledInspections.slice(0, maxInspections);

    const totalInspectionsCount = upcomingInspections.length + unscheduledInspections.length;
    const upcomingInspectionsCount = upcomingInspections.length;
    const inspectionsToScheduleCount = unscheduledInspections.length;

    const navigateToInspectionPage = (inspection: ServiceCallInspection) =>
        history.push(`/service-calls/${inspection.serviceCallId}/inspections/${inspection.id}/inspect`);

    const navigateToSchedulingPage = (inspection: ServiceCallInspection) =>
        history.push(`/service-calls/${inspection.serviceCallId}/inspections/${inspection.id}/schedule`);

    return (
        <React.Fragment>
            <PageHeader
                iconName="home"
                title={formatMessage(m.title)}
                subtitle={formatMessage(m.subtitle)}
            />
            <SectionHeader title={formatMessage(m.quickOverviewTitle)} />
            <Grid>
                <Grid.Column mobile={8} tablet={4} computer={4} largeScreen={3} widescreen={2}>
                    <StatisticTile
                        isLoading={upcomingInspectionsLoading || unscheduledInspectionsLoading}
                        color="blue"
                        value={totalInspectionsCount}
                        label={formatMessage(m.totalInspectionsLabel)}
                    />
                </Grid.Column>
                <Grid.Column mobile={8} tablet={4} computer={4} largeScreen={3} widescreen={2}>
                    <StatisticTile
                        isLoading={upcomingInspectionsLoading}
                        color="teal"
                        value={upcomingInspectionsCount}
                        label={formatMessage(m.upcomingInspectionsLabel)}
                        onClick={() => history.push('/representative/my-appointments')}
                    />
                </Grid.Column>
                <Grid.Column mobile={8} tablet={4} computer={4} largeScreen={3} widescreen={2}>
                    <StatisticTile
                        isLoading={unscheduledInspectionsLoading}
                        color="orange"
                        value={inspectionsToScheduleCount}
                        label={formatMessage(m.inspectionsToScheduleLabel)}
                        onClick={() => history.push('/representative/unscheduled-inspections')}
                    />
                </Grid.Column>
            </Grid>

            <Grid columns={2} stackable={true}>
                <Grid.Column mobile={16} tablet={16} widescreen={8} largeScreen={8}>
                    <SectionHeader title={formatMessage(m.upcomingInspectionsTitle)} />
                    {!isMobile &&
                        <InspectionTable
                            inspections={displayedUpcomingInspections}
                            isLoading={upcomingInspectionsLoading}
                            emptyTitle={formatMessage(m.noUpcomingInspectionsTitle)}
                            emptySubtitle={formatMessage(m.noUpcomingInspectionsSubtitle)}
                            isRequestedOnHidden={true}
                            isCompletedOnHidden={true}
                            onViewDetails={navigateToInspectionPage}
                        />
                    }
                    {isMobile &&
                        <InspectionTable
                            inspections={displayedUpcomingInspections}
                            isLoading={upcomingInspectionsLoading}
                            emptyTitle={formatMessage(m.noUpcomingInspectionsTitle)}
                            emptySubtitle={formatMessage(m.noUpcomingInspectionsSubtitle)}
                            isRequestedOnHidden={true}
                            isCompletedOnHidden={true}
                            isViewButtonHidden={true}
                            onViewDetails={navigateToInspectionPage}
                        />
                    }
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} widescreen={8} largeScreen={8}>
                    <SectionHeader title={formatMessage(m.inspectionsToScheduleTitle)} />
                    {!isMobile &&
                        <InspectionTable
                            inspections={displayedUnscheduledInspections}
                            isLoading={unscheduledInspectionsLoading}
                            emptyTitle={formatMessage(m.noInspectionsToScheduleTitle)}
                            emptySubtitle={formatMessage(m.noInspectionsToScheduleSubtitle)}
                            isScheduledForHidden={true}
                            isCompletedOnHidden={true}
                            onViewDetails={navigateToSchedulingPage}
                        />
                    }
                    {isMobile &&
                        <InspectionTable
                            inspections={displayedUnscheduledInspections}
                            isLoading={unscheduledInspectionsLoading}
                            emptyTitle={formatMessage(m.noInspectionsToScheduleTitle)}
                            emptySubtitle={formatMessage(m.noInspectionsToScheduleSubtitle)}
                            isScheduledForHidden={true}
                            isCompletedOnHidden={true}
                            isViewButtonHidden={true}
                            onViewDetails={navigateToSchedulingPage}
                        />
                    }
                </Grid.Column>
            </Grid>
        </React.Fragment>
    );
};