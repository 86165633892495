import moment from 'moment';
import { ApplicationState } from '..';
import { CalendarBreak } from '../../models';

export function getBreaksBetween(state: ApplicationState, startDate: Date, endDate: Date, forUserId?: string): CalendarBreak[] {
    const result = state.calendar.allIds
        .map(x => state.calendar.allBreaks[x])
        .filter(x => moment(x.forDate).isSameOrAfter(startDate, 'day')
            && moment(x.forDate).isSameOrBefore(endDate, 'day')
            && (forUserId == null || x.forUserId === forUserId || x.forUserId == null));

    return result;
}