import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Menu } from 'semantic-ui-react';
import { CompanyLogo } from '../../../components/common';
import { LocaleSwitcher } from '../../../modules/i18n';

export const SubcontractorPageLayout: React.FC = (props) => {
    return (
        <div>
            <div>
                <ToastContainer position="top-center" />
                <Menu style={{ height: '54px' }} fixed="top">
                    <Menu.Item>
                        <CompanyLogo centered size="small" />
                    </Menu.Item>
                    <Menu.Menu position="right">
                        <LocaleSwitcher />
                    </Menu.Menu>
                </Menu>
            </div>
            <div style={{ paddingTop: '80px' }}>{props.children}</div>
        </div>
    );
};
