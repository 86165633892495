import React from "react";
import { Media } from "state/models";
import { MediaGallery } from "components/common";
import { Loader } from "semantic-ui-react";
import { defineMessages, useIntl } from "react-intl";

const m = defineMessages({
  noPhoto: { id: "OrderItemView.NoPhoto", defaultMessage: "There is no photo." },
});

interface QualityControlGalleryProps {
  medias: Media[];
  isLoading: boolean;
}

export const QualityControlGallery: React.FC<QualityControlGalleryProps> = ({
  medias, isLoading,
}) => { 
  const { formatMessage } = useIntl();

  if (isLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", margin: "2rem" }}>
        <Loader active inline />
      </div>
    );
  }

  if (medias.length <= 0) {
    return <p>{formatMessage(m.noPhoto)}</p>;
  }

  return <MediaGallery medias={medias} mediaSize='medium' />;
};
