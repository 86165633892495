import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Button, Modal } from 'semantic-ui-react';
import { commonMessages } from '../../../../../constants';
import {
    ServiceCallStatus,
    SubcontractorRepairWarrantyStatus
} from '../../../../../state/models';
import useApiErrorHandler from '../../../../../utils/ApiErrorHandler';
import { addSubcontractorRepairBill, addSubcontractorRepairInvoice, closeSubcontractorRepairServiceCall } from '../../api';
import { BillForm } from '../BillForm';
import { useSubcontractorRepairDetailsContext } from '../SubcontractorRepairDetailsProvider';

export interface SubContractorBillFormValues {
    invoiceFile?: File;
    referenceNumber: string;
    repairFees: number;
    shippingFees: number;
    currencyCode: string;
    isEnabled: boolean;
}

interface AppointementDateModalProps {
    open: boolean;
    onClose: () => void;
}

export const SubcontractorBillModal = ({
    open,
    onClose,
}: AppointementDateModalProps) => {
    const { formatMessage } = useIntl();
    const queryClient = useQueryClient();
    const handleError = useApiErrorHandler();

    const { repairDetails, invalidateRepairDetails } =
        useSubcontractorRepairDetailsContext();

    const initialFormValues: SubContractorBillFormValues = {
        referenceNumber: repairDetails?.referenceNumber ?? '',
        repairFees: repairDetails?.repairFees ?? 0,
        shippingFees: repairDetails?.shippingFees ?? 0,
        currencyCode: repairDetails?.currencyCode ?? 'CAD',
        invoiceFile: undefined,
        isEnabled: true,
    };

    const formMethods = useForm<SubContractorBillFormValues>({
        defaultValues: initialFormValues,
    });

    const { handleSubmit } = formMethods;

    const canCloseServiceCall =
        repairDetails &&
        repairDetails.status !== ServiceCallStatus.closed &&
        repairDetails.warrantyStatus !==
            SubcontractorRepairWarrantyStatus.UnderWarranty;

    const { isLoading, mutate } = useMutation({
        mutationFn: (input: {
            formValues: SubContractorBillFormValues;
            closeServiceCall?: boolean;
        }) => {
            return addSubcontractorRepairBill(
                    input.formValues.repairFees,
                    input.formValues.shippingFees,
                    input.formValues.currencyCode,
                    input.formValues.referenceNumber
                )
                .then(() => {
                    if (input.formValues.invoiceFile != null) {
                        return addSubcontractorRepairInvoice(
                            input.formValues.invoiceFile
                        );
                    }
                })
                .then(() => {
                    if (input.closeServiceCall) {
                        return closeSubcontractorRepairServiceCall();
                    }
                });
        },
        onSuccess: () => {
            invalidateRepairDetails();
            queryClient.invalidateQueries(['subcontractorRepairInvoice']);
            onClose();
        },
        onError: (error) => handleError(error),
    });

    const handleSave = (formValues: SubContractorBillFormValues) => {
        if (!repairDetails) return;
        mutate({ formValues });
    };

    const handleSaveAndCloseServiceCall = () => {
        handleSubmit((formValues) =>
            mutate({ formValues, closeServiceCall: true })
        )();
    };

    const formId = 'subcontractor-bill-form';

    return (
        <FormProvider {...formMethods}>
            <Modal
                size="small"
                open={open}
                mountNode={document.getElementById('root')}
                onClose={onClose}
            >
                <Modal.Header>
                    {formatMessage({
                        id: 'SubcontractorRepairPage.SubcontractorBillModal.Title',
                        defaultMessage: 'Add your invoice for the repair',
                    })}
                </Modal.Header>
                <Modal.Content>
                    <BillForm formId={formId} onSubmit={handleSave} />
                </Modal.Content>
                <Modal.Actions>
                    <Button type="button" onClick={onClose}>
                        {formatMessage(commonMessages.cancel)}
                    </Button>
                    <Button
                        disabled={isLoading}
                        loading={isLoading}
                        primary
                        type="submit"
                        form={formId}
                    >
                        {formatMessage(commonMessages.save)}
                    </Button>
                    {canCloseServiceCall && (
                        <Button
                            disabled={isLoading}
                            loading={isLoading}
                            primary
                            type="button"
                            onClick={handleSaveAndCloseServiceCall}
                        >
                            {formatMessage({
                                id: 'SubcontractorRepairPage.SubcontractorBillModal.SaveAndCloseServiceCall',
                                defaultMessage: 'Save and close service call',
                            })}
                        </Button>
                    )}
                </Modal.Actions>
            </Modal>
        </FormProvider>
    );
};
