import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Divider, Popup } from 'semantic-ui-react';
import { commonMessages } from '../../../constants';
import { FilterOptions } from '../../../state/models';
import { FilterPopupButton } from './FilterPopupButton';
import { ServiceCallStatusSection } from './ServiceCallStatusSection';
import { CustomerServiceAgentSection } from './CustomerServiceAgentSection';

interface OwnProps {
    filters: FilterOptions | undefined;
    loading?: boolean;
    onApply: (options: FilterOptions) => void;
}

export type FilterPopupProps =
    & OwnProps;

export const FilterPopup: React.FC<FilterPopupProps> = (props) => {
    const { formatMessage } = useIntl();
    const [isOpen, setIsOpen] = useState(false);
    const [filters, setFilters] = useState(props.filters || {});

    const openPopup = () => setIsOpen(true);
    const closePopup = () => {
        setIsOpen(false);
        setFilters(props.filters || {});
    }

    const changeFilters = (updatedFilters: Partial<FilterOptions>) => setFilters({ ...filters, ...updatedFilters });
    const applyFilters = () => {
        setIsOpen(false);
        props.onApply(filters);
    }

    return (
        <Popup 
            trigger={<FilterPopupButton filters={props.filters} loading={props.loading} disabled={props.loading} />} 
            position="bottom right"
            flowing
            on="click"
            open={isOpen}
            onOpen={openPopup}
            onClose={closePopup}
            style={{width: 340}}
        >
            <Popup.Content>
                <ServiceCallStatusSection filters={filters} onChange={changeFilters} />
                <CustomerServiceAgentSection filters={filters} onChange={changeFilters} style={{ marginTop: '15px' }} />

                <Divider />
                <Button primary fluid content={formatMessage(commonMessages.apply)} onClick={applyFilters} />
            </Popup.Content>
        </Popup>
    );
};