import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../../state/ducks';
import { PageHeader } from '../../../../components/common';
import { SubcontractorForm } from './components/SubcontractorForm';
import { SubcontractorEdit } from './models';
import { ValidationResult } from '../../../../state/models';
import { Divider, Button } from 'semantic-ui-react';
import { commonMessages } from '../../../../constants';
import { RouteComponentProps } from 'react-router-dom';
import * as SubcontractorsActions from '../actions';

interface DispatchProps {
    subcontractorsActions: typeof SubcontractorsActions.actionCreators;
}

interface StoreProps {
}

interface StateProps {
    subcontractor: SubcontractorEdit;
    validationResult: ValidationResult;
    isCreating: boolean;
}

export type CreateSubcontractorPageProps =
    & StoreProps
    & DispatchProps
    & RouteComponentProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'CreateSubcontractorPage.title', defaultMessage: 'Create a subcontractor' },
    subtitle: { id: 'CreateSubcontractorPage.subtitle', defaultMessage: 'Add a new subcontractor authorized to perform repairs on behalf of Jaymar.' }
});

class CreateSubcontractorPage extends React.Component<CreateSubcontractorPageProps, StateProps> {
    public constructor(props: CreateSubcontractorPageProps) {
        super(props);

        this.state = {
            subcontractor: new SubcontractorEdit(),
            validationResult: ValidationResult.empty(),
            isCreating: false
        };
    }

    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <PageHeader iconName="id card" title={formatMessage(m.title)} subtitle={formatMessage(m.subtitle)} />
                <SubcontractorForm
                    subcontractor={this.state.subcontractor}
                    validationResult={this.state.validationResult}
                    onChange={this.handleChange}
                />

                <Divider section={true} />
                <div style={{ textAlign: 'right' }}>
                    <Button content={formatMessage(commonMessages.cancel)} onClick={this.cancelChanges} />
                    <Button
                        primary={true}
                        loading={this.state.isCreating}
                        disabled={this.state.isCreating}
                        content={formatMessage(commonMessages.save)}
                        onClick={this.saveChanges}
                    />
                </div>
            </React.Fragment>
        );
    }

    private cancelChanges = () => {
        this.props.history.push(`/administration/subcontractors`);
    }

    private saveChanges = () => {
        if (this.state.subcontractor) {
            const validationResult = this.state.subcontractor.validate();
            this.setState({ validationResult });

            if (validationResult.isValid) {
                this.setState({ isCreating: true });

                this.props.subcontractorsActions.create(
                    this.state.subcontractor,
                    () => this.props.history.push(`/administration/subcontractors`));
            }
        }
    }

    // tslint:disable-next-line: no-any
    private handleChange = (fieldId: string, fieldValue: any) => {
        this.setState((current) => ({
            ...current,
            subcontractor: new SubcontractorEdit({
                ...current.subcontractor,
                [fieldId]: fieldValue
            })
        }));
    }
}

const mapStateToProps = (state: ApplicationState): StoreProps => {
    return {};
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    return {
        subcontractorsActions: bindActionCreators(SubcontractorsActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(CreateSubcontractorPage);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as CreateSubcontractorPage };