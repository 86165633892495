import moment from 'moment';
import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';

import { useSelector } from 'react-redux';
import { defaultLocale, I18nMessages } from '../configureI18n';
import { getCurrentLocale } from '../selectors';

import 'moment/locale/fr';

interface OwnProps {
    messages: I18nMessages;
}

export type I18nProviderProps =
    & OwnProps;

export const I18nProvider: React.FC<I18nProviderProps> = (props) => {
    const currentLocale = useSelector(getCurrentLocale);
    const messagesForCurrentLocale = currentLocale !== defaultLocale ? props.messages[currentLocale] : undefined;

    useEffect(() => {
        moment.locale(currentLocale);
    }, [currentLocale]);

    return (
        <IntlProvider locale={currentLocale} messages={messagesForCurrentLocale} defaultLocale={defaultLocale}>
            {React.Children.only(props.children)}
        </IntlProvider>
    );
};