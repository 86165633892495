import * as React from 'react';
import { Segment, Popup, Icon } from 'semantic-ui-react';
import { Bar, HorizontalBar } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';

export interface HorizontalBarGraphProps {
    values: number[];
    labels: string[];
    label: string;

    labelTooltip?: string;
    title?: string;
    isLoading?: boolean;
    options?: ChartOptions;
}

class HorizontalBarGraph extends React.Component<HorizontalBarGraphProps, {}> {
    public render() {
        const chartData: ChartData = {
            datasets: [{
                label: this.props.title,
                fill: false,
                data: this.props.values,
                backgroundColor: 'rgba(0, 181, 173, 1)',
                borderColor: 'rgba(0, 181, 173, 1)',
                borderWidth: 1
            }],
            labels: this.props.labels,
        };

        const chartOptions: ChartOptions = this.props.options || {
            scales: {
                xAxes: [{
                    ticks: {
                        beginAtZero: true,
                    }
                }]
            }
        };

        return (
            <Segment 
                color="teal" 
                loading={this.props.isLoading}
            >
                <HorizontalBar data={chartData} options={chartOptions} />
                {this.renderLabel()}
            </Segment>
        );
    }

    private renderLabel() {
        return (
            <div style={{fontSize: '1em', fontWeight: 700, textTransform: 'uppercase', textAlign: 'center'}}>
                {this.props.label}
                {this.props.labelTooltip &&
                    <Popup trigger={<Icon name="question circle outline" />}>
                        {this.props.labelTooltip}
                    </Popup>
                }
            </div>
        );
    }
}

export { HorizontalBarGraph };