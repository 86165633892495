export interface Address {
    address1: string | undefined;
    address2: string | undefined;
    city: string | undefined;
    postalCode: string | undefined;
    provinceCode: string | undefined;
    countryCode: string | undefined;
}

export const emptyAddress: Address = {
    address1: '',
    address2: '',
    city: '',
    postalCode: '',
    provinceCode: 'qc',
    countryCode: 'ca',
};