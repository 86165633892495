import { useMutation } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { Button, Modal } from 'semantic-ui-react';
import { commonMessages } from '../../../../../constants';
import useApiErrorHandler from '../../../../../utils/ApiErrorHandler';
import { setSubcontractorRepairReport } from '../../api';
import { useSubcontractorRepairDetailsContext } from '../SubcontractorRepairDetailsProvider';
import { ReportForm, ReportFormValues } from './ReportForm';

interface ReportModalProps {
    open: boolean;
    onClose: () => void;
}

export const ReportModal = ({
    open,
    onClose,
}: ReportModalProps) => {
    const { formatMessage } = useIntl();
    const handleError = useApiErrorHandler();

    const { repairDetails, invalidateRepairDetails } =
        useSubcontractorRepairDetailsContext();

    const { isLoading, mutate } = useMutation({
        mutationFn: (formValues: ReportFormValues) => {
            return setSubcontractorRepairReport(
                formValues.report
            );
        },
        onSuccess: () => {
            invalidateRepairDetails();
            onClose();
        },
        onError: (error) => handleError(error),
    });

    const handleSubmit = (formValues: ReportFormValues) => {
        if (!repairDetails) return;
        mutate(formValues);
    };

    const formId = 'report-form';

    return (
        <Modal
            size="small"
            open={open}
            mountNode={document.getElementById('root')}
            onClose={onClose}
        >
            <Modal.Header>
                {formatMessage({
                    id: 'SubcontractorRepairPage.ReportModal.Title',
                    defaultMessage: 'Repair report',
                })}
            </Modal.Header>
            <Modal.Content>
                <ReportForm
                    initialFormValues={{
                        report: repairDetails?.report,
                    }}
                    formId={formId}
                    onSubmit={handleSubmit}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button type="button" onClick={onClose}>
                    {formatMessage(commonMessages.cancel)}
                </Button>
                <Button
                    disabled={isLoading}
                    loading={isLoading}
                    primary
                    type="submit"
                    form={formId}
                >
                    {formatMessage(commonMessages.save)}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
