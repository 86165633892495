import * as React from 'react';
import * as moment from 'moment';
import { Feed } from 'semantic-ui-react';
import { ServiceCallNote, NoteVisibility, User, UserAccountType } from '../../../state/models';
import { NoteAttachment } from '../attachments';
import { EmptyNotesSegment } from './EmptyNotesSegment';

interface OwnProps {
    notes: ServiceCallNote[];
    currentUser?: User;
    onDelete: (note: ServiceCallNote) => void;
    onChangeVisibility: (note: ServiceCallNote, visibility: NoteVisibility) => void;
}

export type NotesFeedProps =
    & OwnProps;

export class NotesFeed extends React.Component<NotesFeedProps, {}> {
    public render() {
        const hasNotes = this.props.notes.length > 0;

        return (
            <Feed style={{ maxHeight: '300px', overflowY: 'auto' }}>
                {this.props.notes.map(this.renderNote)}

                {!hasNotes && 
                    <EmptyNotesSegment />
                }
            </Feed>
        );
    }

    private renderNote = (note: ServiceCallNote) => {
        const wasCreatedWithin24Hours = moment.utc().add(-1, 'day').isSameOrBefore(moment.utc(note.createdOn));
        const wasCreatedByCurrentUser = this.props.currentUser != null && this.props.currentUser.id === note.createdById;
        const isCurrentUserAnEmployee = this.props.currentUser != null && this.props.currentUser.accountType !== UserAccountType.client;

        return (
            <NoteAttachment
                key={note.id}
                note={note}
                canDelete={wasCreatedByCurrentUser && wasCreatedWithin24Hours && isCurrentUserAnEmployee}
                onDelete={this.props.onDelete}
                showVisibility={isCurrentUserAnEmployee}
                canChangeVisibility={wasCreatedByCurrentUser}
                onChangeVisibility={visibility => this.props.onChangeVisibility(note, visibility)}
            />
        );
    }
}