import _ from 'lodash';
import moment from 'moment';
import { ApplicationState } from '..';
import { getCurrentUser } from '../../../modules/account/selectors';
import { AttachmentType, Client, Customer, emptyCustomer, FilterOptions, PagedResult, ReturnForCredit, ServiceCall, ServiceCallAttachment, ServiceCallDefect, ServiceCallFactoryRepair, ServiceCallInspection, ServiceCallUpdate, SortOptions, Subcontractor, SubcontractorBill, SubcontractorRepair, TechnicianRepair, UserAccountType } from '../../models';
import { WarrantyHelper } from '../../utils';
import { ServiceCallState } from './reducers';

export function getServiceCallState(state: ApplicationState, serviceCallId: number): ServiceCallState {
    return state.serviceCalls.serviceCalls[serviceCallId];
}

export function getRecentServiceCalls(state: ApplicationState): ServiceCall[] {
    return _(state.serviceCalls.opened.results.results)
        .sortBy(x => x.updatedOn)
        .reverse()
        .take(5)
        .value();
}

export function getPictures(state: ApplicationState, serviceCallId: number): ServiceCallAttachment[] {
    const serviceCall = getServiceCallState(state, serviceCallId);

    if (serviceCall && serviceCall.details && !serviceCall.isLoading) {
        return serviceCall.details.attachments.filter(x => x.type === AttachmentType.image);
    }

    return [];
}

export function getSubcontractorRepairPictures(state: ApplicationState, serviceCallId: number): ServiceCallAttachment[] {
    const serviceCall = getServiceCallState(state, serviceCallId);

    if (serviceCall && serviceCall.details && !serviceCall.isLoading) {
        return serviceCall.details.attachments.filter(x => x.type === AttachmentType.subcontractorRepairImage);
    }

    return [];
}

export function getVideos(state: ApplicationState, serviceCallId: number): ServiceCallAttachment[] {
    const serviceCall = getServiceCallState(state, serviceCallId);

    if (serviceCall && serviceCall.details && !serviceCall.isLoading) {
        return serviceCall.details.attachments.filter(x => x.type === AttachmentType.video);
    }

    return [];
}

export function getDocuments(state: ApplicationState, serviceCallId: number): ServiceCallAttachment[] {
    const serviceCall = getServiceCallState(state, serviceCallId);

    if (serviceCall && serviceCall.details && !serviceCall.isLoading) {
        return serviceCall.details.attachments
            .filter(x => x.type === AttachmentType.document || x.type === AttachmentType.subcontractorBill)
            .concat(getSubcontractorBills(state, serviceCallId));
    }

    return [];
}

export function getSubcontractorBills(state: ApplicationState, serviceCallId: number): ServiceCallAttachment[] {
    const serviceCall = getServiceCallState(state, serviceCallId);
    const currentUser = getCurrentUser(state);

    if (currentUser == null || currentUser.accountType === UserAccountType.client) {
        return [];
    }

    if (serviceCall && serviceCall.details && !serviceCall.isLoading) {
        const subcontractorBills = serviceCall.details.subcontractorRepairs
            .map(repair => repair.subcontractorBill)
            .filter(bill => bill?.receiptUri) as SubcontractorBill[];

        const extensionByContenType = {
            ['application/pdf']: ".pdf",
            ['image/png']: ".png",
            ['image/jpeg']: ".jpg",
        } as Record<string, string>;

        const attachments = subcontractorBills.map((bill): ServiceCallAttachment => ({
            id: bill.subcontractorRepairId,
            contentType: bill.receiptContentType,
            name: `Facture - ${serviceCallId} - ${bill.subcontractorRepairId}${extensionByContenType[bill.receiptContentType] ?? ''}`,
            createdOn: bill.billedOn,
            createdBy: serviceCall.details.createdBy,
            serviceCallId: serviceCallId,
            type: AttachmentType.subcontractorBill,
            attachmentUri: bill.receiptUri
        }));

        return attachments;
    }

    return [];
}

export function getCurrentInspection(state: ApplicationState, serviceCallId: number): ServiceCallInspection | undefined {
    const serviceCall = getServiceCallState(state, serviceCallId);

    if (serviceCall && serviceCall.details && !serviceCall.isLoading) {
        const inspections = serviceCall.details.inspections.filter(x => x.completedOn == null);

        if (inspections && inspections.length > 0) {
            return inspections[0];
        }
    }

    return undefined;
}

export function getFactoryRepairs(state: ApplicationState, serviceCallId: number): ServiceCallFactoryRepair[] {
    const serviceCall = getServiceCallState(state, serviceCallId);

    if (serviceCall && serviceCall.details && !serviceCall.isLoading) {
        return serviceCall.details.factoryRepairs;
    }

    return [];
}

export function getCurrentFactoryRepairs(state: ApplicationState, serviceCallId: number): ServiceCallFactoryRepair | undefined {
    const repairs = getFactoryRepairs(state, serviceCallId).filter(x => x.completedRepairsOn == null);

    if (repairs && repairs.length > 0) {
        return repairs[0];
    }

    return undefined;
}

export function getSubcontractorRepairs(state: ApplicationState, serviceCallId: number): SubcontractorRepair[] {
    const serviceCall = getServiceCallState(state, serviceCallId);

    if (serviceCall && serviceCall.details && !serviceCall.isLoading) {
        return serviceCall.details.subcontractorRepairs;
    }

    return [];
}

export function getCurrentSubcontractorRepairs(state: ApplicationState, serviceCallId: number): SubcontractorRepair | undefined {
    const repairs = getSubcontractorRepairs(state, serviceCallId).filter(x => x.subcontractorBill == null);

    if (repairs && repairs.length > 0) {
        return repairs[0];
    }

    return undefined;
}

export function getTechnicianRepairs(state: ApplicationState, serviceCallId: number): TechnicianRepair[] {
    const serviceCall = getServiceCallState(state, serviceCallId);

    if (serviceCall && serviceCall.details && !serviceCall.isLoading) {
        return serviceCall.details.technicianRepairs;
    }

    return [];
}

export function getCurrentTechnicianRepairs(state: ApplicationState, serviceCallId: number): TechnicianRepair | undefined {
    const repairs = getTechnicianRepairs(state, serviceCallId).filter(x => x.completedOn == null);

    if (repairs && repairs.length > 0) {
        return repairs[0];
    }

    return undefined;
}

export function getCreditReturns(state: ApplicationState, serviceCallId: number): ReturnForCredit[] {
    const serviceCall = getServiceCallState(state, serviceCallId);

    if (serviceCall && serviceCall.details && !serviceCall.isLoading) {
        return serviceCall.details.creditReturns;
    }

    return [];
}

export function getCurrentCreditReturn(state: ApplicationState, serviceCallId: number): ReturnForCredit | undefined {
    const creditReturn = getCreditReturns(state, serviceCallId).filter(x => x.creditedOn == null);

    if (creditReturn && creditReturn.length > 0) {
        return creditReturn[0];
    }

    return undefined;
}

export function hasPendingRepairs(state: ApplicationState, serviceCallId: number): boolean {
    const factoryRepairs = getCurrentFactoryRepairs(state, serviceCallId);
    const technicianRepairs = getCurrentTechnicianRepairs(state, serviceCallId);
    const subcontractorRepairs = getCurrentSubcontractorRepairs(state, serviceCallId);

    return factoryRepairs != null || technicianRepairs != null || subcontractorRepairs != null;
}

export function hasCompletedRepairs(state: ApplicationState, serviceCallId: number): boolean {
    const factoryRepairs = getFactoryRepairs(state, serviceCallId).filter(x => x.completedRepairsOn != null);
    const technicianRepairs = getTechnicianRepairs(state, serviceCallId).filter(x => x.completedOn != null);
    const subcontractorRepairs = getSubcontractorRepairs(state, serviceCallId).filter(x => x.subcontractorBill != null);

    return factoryRepairs.length > 0 || technicianRepairs.length > 0 || subcontractorRepairs.length > 0;
}

export function getCustomer(state: ApplicationState, serviceCallId: number): Customer {
    const serviceCall = getServiceCallState(state, serviceCallId);

    if (serviceCall && serviceCall.details && !serviceCall.isLoading) {
        return serviceCall.details.customer;
    }

    return emptyCustomer;
}

export function getClient(state: ApplicationState, serviceCallId: number): Client | undefined {
    const serviceCall = getServiceCallState(state, serviceCallId);

    if (serviceCall && serviceCall.details && !serviceCall.isLoading) {
        return serviceCall.details.client;
    }

    return undefined;
}

export function getDefects(state: ApplicationState, serviceCallId: number): ServiceCallDefect[] {
    const serviceCall = getServiceCallState(state, serviceCallId);

    if (serviceCall && serviceCall.details && !serviceCall.isLoading) {
        return serviceCall.details.defects;
    }

    return [];
}

export function getWarrantyStartDate(state: ApplicationState, serviceCallId: number): Date | undefined {
    const serviceCall = getServiceCallState(state, serviceCallId);

    if (serviceCall && serviceCall.details && !serviceCall.isLoading) {
        return serviceCall.details.bill
            ? serviceCall.details.bill.billedOn
            : undefined;
    }
}

export function getDefectsNotUnderWarranty(state: ApplicationState, serviceCallId: number): ServiceCallDefect[] {
    const allDefects = getDefects(state, serviceCallId);
    const warrantyStartDate = getWarrantyStartDate(state, serviceCallId);

    return allDefects.filter(x => {
        const partsExpiryDate = WarrantyHelper.getExpiryDate(x.defect.partsWarranty, warrantyStartDate);
        const labourExpiryDate = WarrantyHelper.getExpiryDate(x.defect.labourWarranty, warrantyStartDate);

        return partsExpiryDate == null || partsExpiryDate <= moment.utc()
            || labourExpiryDate == null || labourExpiryDate <= moment.utc();
    });
}

export const getServiceCall = (state: ApplicationState, serviceCallId: number): ServiceCall | undefined => state.serviceCalls.serviceCalls[serviceCallId]?.details;

export const getStaleServiceCalls = (state: ApplicationState): PagedResult<ServiceCall> => state.serviceCalls.stale.results;
export const getStaleServiceCallsSorting = (state: ApplicationState): SortOptions | undefined => state.serviceCalls.stale.sortings;
export const areStaleServiceCallsLoading = (state: ApplicationState): boolean => state.serviceCalls.stale.isLoading;

export const getOpenedServiceCalls = (state: ApplicationState): PagedResult<ServiceCall> => state.serviceCalls.opened.results;
export const getOpenedServiceCallsFilters = (state: ApplicationState): FilterOptions => state.serviceCalls.opened.filters;
export const getOpenedServiceCallsSortings = (state: ApplicationState): SortOptions | undefined => state.serviceCalls.opened.sortings;
export const areOpenedServiceCallsLoading = (state: ApplicationState): boolean => state.serviceCalls.opened.isLoading;

export const getMyServiceCalls = (state: ApplicationState): PagedResult<ServiceCall> => state.serviceCalls.mine.results;
export const getMyServiceCallsFilters = (state: ApplicationState): FilterOptions => state.serviceCalls.mine.filters;
export const getMyServiceCallsSortings = (state: ApplicationState): SortOptions | undefined => state.serviceCalls.mine.sortings;
export const areMyServiceCallsLoading = (state: ApplicationState): boolean => state.serviceCalls.mine.isLoading;

export const getClosedServiceCalls = (state: ApplicationState): PagedResult<ServiceCall> => state.serviceCalls.history.results;
export const getClosedServiceCallsFilters = (state: ApplicationState): FilterOptions => state.serviceCalls.history.filters;
export const getClosedServiceCallsSortings = (state: ApplicationState): SortOptions | undefined => state.serviceCalls.history.sortings;
export const areClosedServiceCallsLoading = (state: ApplicationState): boolean => state.serviceCalls.history.isLoading;

export const getCustomServiceCalls = (state: ApplicationState): PagedResult<ServiceCall> => state.serviceCalls.custom.results;
export const getCustomServiceCallsFilters = (state: ApplicationState): FilterOptions => state.serviceCalls.custom.filters;
export const getCustomServiceCallsSortings = (state: ApplicationState): SortOptions | undefined => state.serviceCalls.custom.sortings;
export const areCustomServiceCallsLoading = (state: ApplicationState): boolean => state.serviceCalls.custom.isLoading;

export const getSearchResults = (state: ApplicationState): PagedResult<ServiceCall> => state.search.opened.results;
export const getSearchResultsSortings = (state: ApplicationState): SortOptions | undefined => state.search.opened.sortings;
export const areSearchResultsLoading = (state: ApplicationState): boolean => state.search.opened.isLoading;

export const getSubcontractors = (state: ApplicationState): Subcontractor[] => state.serviceCalls.subcontractors;
export const areSubcontractorsLoading = (state: ApplicationState): boolean => state.serviceCalls.areSubcontractorsLoading;

export const areRecentServiceCallsLoading = (state: ApplicationState): boolean => state.serviceCalls.opened.isLoading;

export const getServiceCallActivities = (state: ApplicationState, serviceCallId: number): ServiceCallUpdate[] => {
    return getServiceCallState(state, serviceCallId)?.activities || [];
}

export const areServiceCallActivitiesLoading = (state: ApplicationState, serviceCallId: number): boolean => {
    const serviceCallState = getServiceCallState(state, serviceCallId);
    return serviceCallState == null || serviceCallState.areActivitiesLoading;
}

export const areServiceCallDocumentsUploading = (state: ApplicationState, serviceCallId: number): boolean => {
    const serviceCallState = getServiceCallState(state, serviceCallId);
    return serviceCallState == null || serviceCallState.areDocumentsUploading;
}

export const areServiceCallMediasUploading = (state: ApplicationState, serviceCallId: number): boolean => {
    const serviceCallState = getServiceCallState(state, serviceCallId);
    return serviceCallState == null || serviceCallState.arePicturesUploading || serviceCallState.areVideosUploading;
}

export const getServiceCallAttachmentsBeingDeleted = (state: ApplicationState, serviceCallId: number): number[] =>
    getServiceCallState(state, serviceCallId)?.isAttachmentDeleting ?? [];

export const isServiceCallCreating = (state: ApplicationState): boolean => state.serviceCalls.isServiceCallCreating;
export const isServiceCallLoading = (state: ApplicationState, serviceCallId: number): boolean => {
    const serviceCallState = getServiceCallState(state, serviceCallId);
    return serviceCallState == null || serviceCallState.isLoading;
}

export const getNewServiceCalls = (state: ApplicationState): number => state.serviceCalls.statistics.new;