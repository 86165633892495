import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Divider, Icon, Popup } from 'semantic-ui-react';

import { commonMessages } from '../../constants';

interface FilterSectionOwnProps {
    title: string;
    titleTooltip?: string;
    onClear?: () => void;
}

export type FilterSectionProps =
    & FilterSectionOwnProps
    & WrappedComponentProps;

const m = defineMessages({
});

class FilterSection extends React.Component<FilterSectionProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <div className="c-filter-popup__section">
                    <strong>
                        {this.props.title}
                        {this.props.titleTooltip && 
                            <Popup trigger={<Icon name="question circle outline" />}>
                                {this.props.titleTooltip}
                            </Popup>
                        }
                    </strong>
                    {this.props.onClear && 
                        <a style={{float: 'right', cursor: 'pointer'}} onClick={this.props.onClear}>
                            {formatMessage(commonMessages.clear)}
                        </a>
                    }
                </div>
                <Divider />
                
                {this.props.children}
            </React.Fragment>
        );
    }
}

const connectedComponent = injectIntl(FilterSection);
export { connectedComponent as FilterSection };