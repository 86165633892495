import { defineMessages } from "@formatjs/intl";
import React, { useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { Button, Divider, Form, Header, Message } from "semantic-ui-react";
import { commonMessages } from "../../../../../constants";
import { User } from "../../../../../state/models";
import { UserEdit } from "../../models";
import { AuthenticationPropertiesSection } from "./AuthenticationPropertiesSection";
import { EmployeeRoleSection } from "./EmployeeRoleSection";
import { ProfilePropertiesSection } from "./ProfilePropertiesSection";

interface OwnProps {
    user: User | undefined;
    isLoading?: boolean;

    onCancel: () => void;
    onSave: (user: UserEdit) => void;
    onDisable?: () => void;
}

export type EmployeeFormProps =
    & OwnProps;

const m = defineMessages({
    disableButton: { id: 'EmployeeForm.disable', defaultMessage: 'Deactivate' }
});

export const EmployeeForm: React.FC<EmployeeFormProps> = (props) => {
    const { formatMessage } = useIntl();
    const formProps = useForm<UserEdit>();

    const hasErrors = Object.keys(formProps.formState.errors).filter(x => (formProps.formState.errors as any)[x] != null).length > 0;
    const errorMessages = Object.keys(formProps.formState.errors).map(x => (formProps.formState.errors as any)[x]?.message);

    useEffect(() => {
        if (props.user != null) {
            formProps.setValue('id', props.user.id);
            formProps.setValue('firstName', props.user.firstName);
            formProps.setValue('lastName', props.user.lastName);
            formProps.setValue('email', props.user.email);
            formProps.setValue('accountType', props.user.accountType);
        }
    }, [props.user]);

    return (
        <>
            <FormProvider {...formProps}>
                <Form loading={props.isLoading} error={hasErrors} style={{ maxWidth: 600 }}>
                    <Message error list={errorMessages} />

                    <ProfilePropertiesSection disabled={props.user?.isDisabled} />
                    <AuthenticationPropertiesSection isNewUser={props.user == null} disabled={props.user?.isDisabled} />
                    <EmployeeRoleSection disabled={props.user?.isDisabled} />
                </Form>

                <Divider />
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ textAlign: 'left', flexGrow: 1}}>
                        {props.user 
                            && !props.user?.isDisabled 
                            && props.onDisable != null
                            && <Button negative content={formatMessage(m.disableButton)} onClick={props.onDisable} />}
                    </div>
                    <div style={{ textAlign: 'right' }}>
                        <Button content={formatMessage(commonMessages.cancel)} onClick={props.onCancel} />
                        <Button 
                            primary 
                            disabled={props.user?.isDisabled || props.isLoading} 
                            content={formatMessage(commonMessages.save)} 
                            onClick={formProps.handleSubmit(props.onSave)} 
                        />
                    </div>
                </div>
            </FormProvider>
        </>
    );
};