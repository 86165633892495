import React from 'react';
import moment from 'moment';

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import * as CalendarActions from '../../state/ducks/calendar';
import * as UsersActions from '../../state/ducks/users';

import { ApplicationState } from '../../state/ducks';
import { PageHeader } from '../../components/common';
import { Calendar, ManageCalendar } from '../../components/calendar';
import { User, CalendarBreak } from '../../state/models';
import { getBreaksBetween } from '../../state/ducks/calendar/selectors';

interface ManageCalendarPageActions {
    calendarActions: typeof CalendarActions.actionCreators;
    usersActions: typeof UsersActions.actionCreators;
}

interface ManageCalendarPageOwnProps {
    jaymarTechnicians: User[];
    factoryTechnicians: User[];
    areUsersLoading: boolean;
    calendarBreaks: CalendarBreak[];
    loadingDates: Date[];
    monthDisplayed: Date;
}

export type ManageCalendarPageProps =
    & ManageCalendarPageOwnProps
    & ManageCalendarPageActions
    & RouteComponentProps<{}>
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'ManageCalendarPage.title', defaultMessage: 'Manage the calendar' },
    subtitle: { id: 'ManageCalendarPage.subtitle', defaultMessage: 'Configure the upcoming holidays and blocked days for the technician appointments.' }
});

class ManageCalendarPage extends React.Component<ManageCalendarPageProps, {}> {
    public componentDidMount() {
        const dateMoment = moment(this.props.monthDisplayed);
        const startDate = dateMoment.clone().startOf('month').startOf('week').toDate();
        const endDate = dateMoment.clone().endOf('month').endOf('week').toDate();

        this.props.usersActions.loadJaymarTechnicians();
        this.props.usersActions.loadFactoryTechnicians();
        this.props.calendarActions.loadLocks(startDate, endDate);
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const employees = this.props.jaymarTechnicians.concat(this.props.factoryTechnicians).sort((a, b) => a.firstName.localeCompare(b.firstName));

        return (
            <React.Fragment>
                <PageHeader
                    iconName="calendar"
                    title={formatMessage(m.title)}
                    subtitle={formatMessage(m.subtitle)}
                />

                <ManageCalendar
                    forDate={this.props.monthDisplayed}
                    events={[]}
                    employees={employees}
                    lockedDates={this.props.calendarBreaks}
                    loadingDates={this.props.loadingDates}
                    onChangeMonth={this.changeMonth}
                    onLock={this.lockDate}
                    onUnlock={this.unlockDate}
                />
            </React.Fragment>
        );
    }

    private changeMonth = (date: Date) => {
        this.props.calendarActions.setCalendarStartDate(date);

        const dateMoment = moment(this.props.monthDisplayed);
        const startDate = dateMoment.clone().startOf('month').startOf('week').toDate();
        const endDate = dateMoment.clone().endOf('month').endOf('week').toDate();

        this.props.calendarActions.loadLocks(startDate, endDate);
    }

    private lockDate = (forDate: Date, forUser: User | undefined, reason: string) => {
        this.props.calendarActions.lock(forDate, forUser ? forUser.id : undefined, reason);
    }

    private unlockDate = (forDate: Date) => {
        this.props.calendarActions.unlock(forDate);
    }
}

const mapStateToProps = (state: ApplicationState): ManageCalendarPageOwnProps => {
    const dateMoment = moment(state.calendar.startDate);
    const startDate = dateMoment.clone().startOf('month').startOf('week').toDate();
    const endDate = dateMoment.clone().endOf('month').endOf('week').toDate();

    return {
        jaymarTechnicians: state.users.technicians,
        factoryTechnicians: state.users.factoryTechnicians,
        areUsersLoading: state.users.areTechniciansLoading || state.users.areFactoryTechniciansLoading,
        calendarBreaks: getBreaksBetween(state, startDate, endDate),
        loadingDates: state.calendar.loadingDates,
        monthDisplayed: state.calendar.startDate
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ManageCalendarPageActions => {
    return {
        calendarActions: bindActionCreators(CalendarActions.actionCreators, dispatch),
        usersActions: bindActionCreators(UsersActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(ManageCalendarPage);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as ManageCalendarPage };