import moment from 'moment';
import React from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl, MessageDescriptor } from 'react-intl';
import { Feed, Popup, SemanticICONS } from 'semantic-ui-react';

import { ServiceCallUpdate } from '../../../state/models';
import { StringHelper } from '../../../state/utils';

export interface DefaultActivityProps {
    icon: SemanticICONS;
    activity: ServiceCallUpdate;
    summaryMessage: MessageDescriptor;
    summaryValues?: { [key: string]: any | JSX.Element };
}

class DefaultActivity extends React.Component<DefaultActivityProps & WrappedComponentProps, {}> {
    public render() {
        const { icon, activity, summaryMessage, summaryValues } = this.props;

        return (
            <Feed.Event key={activity.id}>
                <Feed.Label icon={icon} />
                <Feed.Content>
                    <Popup
                        trigger={<Feed.Date content={moment.utc(activity.createdOn).fromNow()} />}
                        content={moment.utc(activity.createdOn).format('LL')}
                    />
                    <Feed.Summary>
                        <FormattedMessage {...summaryMessage} values={summaryValues} />
                    </Feed.Summary>

                    <Feed.Extra text={true}>
                        {StringHelper.hasValue(activity.note) &&
                            <p style={{ whiteSpace: 'pre-line' }}>
                                {activity.note}
                            </p>
                        }
                    </Feed.Extra>
                </Feed.Content>
            </Feed.Event>
        );
    }
}

const connectedComponent = injectIntl(DefaultActivity);
export { connectedComponent as DefaultActivity };