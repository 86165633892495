import { Reducer } from 'redux';
import _ from 'lodash';

import { ActionTypes } from './actions';
import { ActionTypeKeys } from './types';
import { ServiceCallInspection } from '../../models';

export interface InspectionsState {
    byId: {
        [id: number]: ServiceCallInspection;
    };

    byMonth: {
        [startDate: string]: number[]
    };

    allIds: number[];
    upcomingIds: number[];
    areUpcomingLoading: boolean;
    unscheduledIds: number[];
    areUnscheduledLoading: boolean;
    completedIds: number[];
    areCompletedLoading: boolean;
    loadingIds: number[];
    loadingMonths: string[];
    calendarStartDate: Date;
}

const initialState: InspectionsState = {
    byId: {},
    byMonth: {},

    allIds: [],
    upcomingIds: [],
    areUpcomingLoading: false,
    unscheduledIds: [],
    areUnscheduledLoading: false,
    completedIds: [],
    areCompletedLoading: false,
    loadingIds: [],
    loadingMonths: [],
    calendarStartDate: new Date()
};

export const inspectionsReducer: Reducer<InspectionsState> = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case ActionTypeKeys.LOAD_BY_ID_REQUEST:
            return {
                ...state,
                loadingIds: _.union(state.loadingIds, [action.payload])
            };

        case ActionTypeKeys.LOAD_BY_ID_SUCCESS:
            return {
                ...state,
                byId: _.merge({}, state.byId, { [action.payload.id]: action.payload }),
                allIds: _.union(state.allIds, [action.payload.id]),
                loadingIds: state.loadingIds.filter(x => x !== action.payload.id)
            };

        case ActionTypeKeys.LOAD_BY_ID_FAILURE:
            return {
                ...state,
                loadingIds: state.loadingIds.filter(x => x !== action.payload.inspectionId)
            };

        case ActionTypeKeys.LOAD_BETWEEN_REQUEST:
            return {
                ...state,
                loadingMonths: _.union(state.loadingMonths, [action.payload])
            };

        case ActionTypeKeys.LOAD_BETWEEN_SUCCESS:
            const inspectionsBetween = Object.assign({}, ...action.payload.inspections.map(x => ({ [x.id]: x })));
            const inspectionsBetweenIds = action.payload.inspections.map(x => x.id);

            return {
                ...state,
                byId: _.merge({}, state.byId, inspectionsBetween),
                allIds: _.union(state.allIds, inspectionsBetweenIds),
                byMonth: _.merge({}, state.byMonth, { [action.payload.startDate]: inspectionsBetweenIds }),
                loadingMonths: state.loadingMonths.filter(x => x !== action.payload.startDate)
            };

        case ActionTypeKeys.LOAD_BETWEEN_FAILURE:
            return {
                ...state,
                loadingMonths: state.loadingMonths.filter(x => x !== action.payload.startDate)
            };

        case ActionTypeKeys.LOAD_UPCOMING_REQUEST:
            return {
                ...state,
                areUpcomingLoading: true
            };

        case ActionTypeKeys.LOAD_UPCOMING_SUCCESS:
            const upcomingInspections = Object.assign({}, ...action.payload.map(x => ({ [x.id]: x })));
            const upcomingInspectionsIds = action.payload.map(x => x.id);

            return {
                ...state,
                byId: _.merge({}, state.byId, upcomingInspections),
                allIds: _.union(state.allIds, upcomingInspectionsIds),
                upcomingIds: upcomingInspectionsIds,
                areUpcomingLoading: false
            };

        case ActionTypeKeys.LOAD_UPCOMING_FAILURE:
            return {
                ...state,
                areUpcomingLoading: false
            };

        case ActionTypeKeys.LOAD_UNSCHEDULED_REQUEST:
            return {
                ...state,
                areUnscheduledLoading: true
            };

        case ActionTypeKeys.LOAD_UNSCHEDULED_SUCCESS:
            const unscheduledInspections = Object.assign({}, ...action.payload.map(x => ({ [x.id]: x })));
            const unscheduledInspectionsIds = action.payload.map(x => x.id);

            return {
                ...state,
                byId: _.merge({}, state.byId, unscheduledInspections),
                allIds: _.union(state.allIds, unscheduledInspectionsIds),
                unscheduledIds: unscheduledInspectionsIds,
                areUnscheduledLoading: false
            };

        case ActionTypeKeys.LOAD_UNSCHEDULED_FAILURE:
            return {
                ...state,
                areUnscheduledLoading: false
            };

        case ActionTypeKeys.LOAD_COMPLETED_REQUEST:
            return {
                ...state,
                areCompletedLoading: true
            };

        case ActionTypeKeys.LOAD_COMPLETED_SUCCESS:
            const completedInspections = Object.assign({}, ...action.payload.map(x => ({ [x.id]: x })));
            const completedInspectionsIds = action.payload.map(x => x.id);

            return {
                ...state,
                byId: _.merge({}, state.byId, completedInspections),
                allIds: _.union(state.allIds, completedInspectionsIds),
                completedIds: completedInspectionsIds,
                areCompletedLoading: false
            };

        case ActionTypeKeys.LOAD_COMPLETED_FAILURE:
            return {
                ...state,
                areCompletedLoading: false
            };

        case ActionTypeKeys.SCHEDULE_SUCCESS:
            return {
                ...state,
                byId: _.merge({}, state.byId, { [action.payload.id]: action.payload }),
                unscheduledIds: state.unscheduledIds.filter(x => x !== action.payload.id)
            };

        case ActionTypeKeys.SAVE_REPORT_SUCCESS:
            return {
                ...state,
                byId: _.merge({}, state.byId, { [action.payload.id]: action.payload })
            };

        case ActionTypeKeys.COMPLETE_SUCCESS:
        case ActionTypeKeys.CLOSE_SUCCESS:
            return {
                ...state,
                byId: _.merge({}, state.byId, { [action.payload.id]: action.payload }),
                upcomingIds: state.upcomingIds.filter(x => x !== action.payload.id),
                unscheduledIds: state.unscheduledIds.filter(x => x !== action.payload.id)
            };

        case ActionTypeKeys.SET_CALENDAR_START_DATE:
            return {
                ...state,
                calendarStartDate: action.payload
            };

        default:
            return state;
    }
};

export default inspectionsReducer;