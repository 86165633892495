import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Card, List, Segment } from 'semantic-ui-react';

import { CustomerEdit, Customer } from '../../state/models';
import { SmallCancelButton, SmallEditButton, SmallSaveButton, LoadingContainer, LoadingDimmer, AddressInformation } from '../common';
import { CustomerInformationForm } from '.';
import { FormatHelper } from '../../state/utils';

interface CustomerInformationCardOwnProps {
    customer?: Customer;
    isLoading?: boolean;
    canEdit?: boolean;
    onEdit?: (customer: CustomerEdit) => void;
}

export type CustomerInformationCardProps =
    & CustomerInformationCardOwnProps
    & WrappedComponentProps;

interface CustomerInformationCardState {
    isEditing: boolean;
    customerEdit: CustomerEdit;
}

const m = defineMessages({
    header: { id: 'CustomerInformationCard.header', defaultMessage: 'Customer information' },
    emptyTitle: { id: 'CustomerInformationCard.empty_title', defaultMessage: 'No customer information' },
    emptyDescription: { id: 'CustomerInformationCard.empty_description', defaultMessage: 'There\'s no customer associated to this service call' }
});

class CustomerInformationCard extends React.Component<CustomerInformationCardProps, CustomerInformationCardState> {
    public constructor(props: CustomerInformationCardProps & WrappedComponentProps) {
        super(props);

        this.state = {
            isEditing: false,
            customerEdit: new CustomerEdit(this.props.customer),
        };
    }

    public componentWillReceiveProps(props: CustomerInformationCardProps) {
        if (this.props.customer == null && props.customer != null) {
            this.setState({ customerEdit: new CustomerEdit(props.customer) });
        }
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const { canEdit } = this.props;
        const { isEditing } = this.state;
        
        return (
            <LoadingContainer>
                <LoadingDimmer active={this.props.isLoading} />
                <Card fluid={true} color="teal" style={{height: '100%', marginTop: 0}}>
                    <Card.Content style={{flexGrow: 0}}>
                        <Card.Header>
                            {formatMessage(m.header)}
                            {!isEditing && canEdit && <SmallEditButton floated="right" onClick={this.handleEdit} />}
                            {isEditing &&
                                <div style={{float: 'right'}}>
                                    <SmallCancelButton onClick={this.handleCancel} />
                                    <SmallSaveButton onClick={this.handleSave} />
                                </div>
                            }
                        </Card.Header>
                    </Card.Content>

                    {!isEditing && this.renderContent()}
                    {isEditing && this.renderEditableContent()}
                </Card>
            </LoadingContainer>
        );
    }

    private renderContent() {
        const { formatMessage } = this.props.intl;
        const { customer } = this.props;
        const hasCustomerInformation = customer && (customer.name || customer.mainPhoneNumber || customer.otherPhoneNumber || customer.email || customer.address?.address1);

        return (
            <Card.Content>
                {customer && hasCustomerInformation &&
                    <List style={{padding: '0 14px 14px 14px'}}>
                        {customer.name && 
                            <List.Item>
                                <List.Icon name="user" />
                                <List.Content>{customer.name}</List.Content>
                            </List.Item>
                        }

                        {customer.email &&
                            <List.Item>
                                <List.Icon name="mail" />
                                <List.Content>
                                    <a href={`mailto:${customer.email}`}>
                                        {customer.email}
                                    </a>
                                </List.Content>
                            </List.Item>
                        }

                        {customer.mainPhoneNumber &&
                            <List.Item>
                                <List.Icon name="phone" />
                                <List.Content>
                                    <a href={`tel:${customer.mainPhoneNumber}`}>
                                        {FormatHelper.formatPhoneNumber(customer.mainPhoneNumber)}
                                    </a>
                                </List.Content>
                            </List.Item>
                        }

                        {customer.otherPhoneNumber &&
                            <List.Item>
                                <List.Icon name="phone" />
                                <List.Content>
                                    <a href={`tel:${customer.otherPhoneNumber}`}>
                                        {FormatHelper.formatPhoneNumber(customer.otherPhoneNumber)}
                                    </a>
                                </List.Content>
                            </List.Item>
                        }

                        {customer.address?.address1 &&
                            <List.Item>
                                <List.Icon name="marker" />
                                <List.Content>
                                    {this.props.customer && this.props.customer.address &&
                                        <AddressInformation
                                            address={this.props.customer.address}
                                            canNavigateTo={true}
                                        />
                                    }
                                </List.Content>
                            </List.Item>
                        }
                    </List>
                }
                
                {!hasCustomerInformation &&
                    <Segment basic={true} textAlign="center">
                        <h3>{formatMessage(m.emptyTitle)}</h3>
                        {formatMessage(m.emptyDescription)}
                    </Segment>
                }
            </Card.Content>
        );
    }

    private handleEdit = () => {
        this.setState((current) => ({
            isEditing: true
        }));
    }

    private handleCancel = () => {
        this.setState({
            customerEdit: new CustomerEdit(this.props.customer),
            isEditing: false
        });
    }

    private handleSave = () => {
        if (this.props.onEdit) {
            this.props.onEdit(this.state.customerEdit);
        }

        this.setState({ isEditing: false });
    }

    private handleFieldChange = (customer: CustomerEdit) => {
        this.setState({
            customerEdit: customer
        });
    }

    private renderEditableContent() {
        return (
            <Card.Content>
                <CustomerInformationForm
                    customer={this.state.customerEdit} 
                    hideHeader={true}
                    onChange={this.handleFieldChange}
                />
            </Card.Content>
        );
    }
}

const connectedComponent = injectIntl(CustomerInformationCard);
export { connectedComponent as CustomerInformationCard };