import { defineMessages } from "@formatjs/intl";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { PageHeader } from "../../../../components/common";
import { ApplicationState } from "../../../../state/ducks";
import { getUserById, isUserLoading } from "../selectors";
import { actionCreators as UsersActions } from '../actions';
import { EmployeeForm } from "./components/EmployeeForm";
import { UserEdit } from "../models";
import { UserAccountDisabledWarning } from "./components/UserAccountDisabledWarning";

const m = defineMessages({
    title: { id: 'CreateEmployeePage.title', defaultMessage: 'Create an employee profile' },
    subtitle: { id: 'CreateEmployeePage.subtitle', defaultMessage: 'Create a new account to give access to an employee to the after-sales platform.' },
});

interface RouteProps {
    userId: string;
}

export const CreateEmployeePage: React.FC = (props) => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();

    const createEmployee = (user: UserEdit) => {
        dispatch(UsersActions.createEmployee(user));
        navigateToEmployeesPage();
    }

    const navigateToEmployeesPage = () => history.push(`/administration/employees`);

    return (
        <>
            <PageHeader iconName="id badge" title={formatMessage(m.title)} subtitle={formatMessage(m.subtitle)} />
            <EmployeeForm 
                user={undefined}
                onCancel={navigateToEmployeesPage}
                onSave={createEmployee}
            />
        </>
    );
};