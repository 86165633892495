import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Form } from 'semantic-ui-react';
import {
    SmallDeleteButton,
    UploadFileButton
} from '../../../../../components/common';
import { SubContractorBillFormValues } from '../SubContractorBillSection/SubcontractorBillModal';

export const BillFileInputField = () => {
    const { formatMessage } = useIntl();

    const { control } = useFormContext<SubContractorBillFormValues>();

    return (
        <Controller
            control={control}
            name="invoiceFile"
            render={({ field: { value: invoiceFile, onChange } }) => (
                <Form.Field>
                    <label>
                        {formatMessage({
                            id: 'SubcontractorRepairPage.BillForm.Invoice',
                            defaultMessage: 'Invoice',
                        })}
                    </label>
                    {invoiceFile && (
                        <div>
                            <span>{invoiceFile.name}</span>
                            <SmallDeleteButton
                                as="a"
                                style={{ float: 'right' }}
                                onClick={() => onChange(undefined)}
                            />
                        </div>
                    )}
                    {!invoiceFile && (
                        <div>
                            <UploadFileButton
                                allowMultiple={false}
                                acceptedFileTypes="application/pdf, image/png, image/jpeg"
                                onFilesUpload={(fileList) =>
                                    onChange(fileList[0])
                                }
                            />
                        </div>
                    )}
                </Form.Field>
            )}
        />
    );
};
