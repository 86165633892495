import * as React from 'react';
import * as _ from 'lodash';
import { ServiceCall } from '../../state/models';
import { Header } from 'semantic-ui-react';
import { WrappedComponentProps, injectIntl, defineMessages } from 'react-intl';
import { LinkedCallsLabel } from '.';

interface OwnProps {
    serviceCall: ServiceCall;
    onClick?: () => void;
}

export type CustomerBillSummaryProps =
    & OwnProps
    & WrappedComponentProps;

const m = defineMessages({
    billNumber: { id: 'CustomerBillSummary.billNumber', defaultMessage: 'Bill #{billNumber}' },
    noBillProvided: { id: 'CustomerBillSummary.noBillProvided', defaultMessage: 'No bill provided' },
    referenceNumber: { id: 'CustomerBillSummary.referenceNumber', defaultMessage: 'Reference #{referenceNumber}' },
    unknownCustomer: { id: 'CustomerBillSummary.unknownCustomer', defaultMessage: 'Unknown' },
})

class CustomerBillSummary extends React.Component<CustomerBillSummaryProps> {
    public render() {
        const { formatMessage } = this.props.intl;
        const linkedServiceCallsCount = this.getNumberOfLinkedServiceCalls();

        return (
            <Header as="h4">
                <Header.Content>
                    <a onClick={this.props.onClick} style={{ cursor: 'pointer' }}>{this.getCustomerName()}</a>
                    {linkedServiceCallsCount > 1 && <LinkedCallsLabel count={linkedServiceCallsCount} />}
                </Header.Content>
                <Header.Subheader>
                    <div>{this.getBillNumber()}</div>
                    {this.props.serviceCall.referenceNumber && <div>{formatMessage(m.referenceNumber, { referenceNumber: this.props.serviceCall.referenceNumber })}</div>}
                </Header.Subheader>
            </Header>
        );
    }

    private getNumberOfLinkedServiceCalls() {
        const { serviceCall } = this.props;

        return serviceCall.bill && serviceCall.bill.serviceCalls
            ? serviceCall.bill.serviceCalls.length
            : 0;
    }

    private getCustomerName() {
        const { formatMessage } = this.props.intl;
        const customer = this.props.serviceCall.customer;

        return customer != null && !_.isEmpty(customer.name)
            ? customer.name
            : formatMessage(m.unknownCustomer)
    }

    private getBillNumber() {
        const { formatMessage } = this.props.intl;
        const { serviceCall } = this.props;

        return serviceCall.billId != null 
            ? formatMessage(m.billNumber, { billNumber: serviceCall.billId }) 
            : formatMessage(m.noBillProvided);
    }
}

const intlComponent = injectIntl(CustomerBillSummary);
export { intlComponent as CustomerBillSummary };