import _ from "lodash";
import React, { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { defineMessages, MessageDescriptor, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { SemanticICONS, Step, StepProps } from "semantic-ui-react";
import { StringHelper } from "../../../../../state/utils";
import { isCurrentUserAnEmployee } from "../../../../account/selectors";
import { ServiceCallEdit } from "../../../models";

interface OwnProps {
}

export type CreateServiceCallStepsProps =
    & OwnProps;

const m = defineMessages({
    orderStepTitle: { id: 'CreateServiceCallPage.order_step_title', defaultMessage: 'Order' },
    orderStepSubtitle: { id:    'CreateServiceCallPage.order_step_subtitle', defaultMessage: 'Enter the order information' },
    clientStepTitle: { id: 'CreateServiceCallPage.client_step_title', defaultMessage: 'Your information' },
    clientStepSubtitle: { id: 'CreateServiceCallPage.client_step_subtitle', defaultMessage: 'Enter your contact information' },
    customerStepTitle: { id: 'CreateServiceCallPage.customer_step_title', defaultMessage: 'Customer' },
    customerStepSubtitle: { id: 'CreateServiceCallPage.customer_step_subtitle', defaultMessage: 'Enter the customer contact information' },
    problemStepTitle: { id: 'CreateServiceCallPage.problem_step_title', defaultMessage: 'Problem' },
    problemStepSubtitle: { id: 'CreateServiceCallPage.problem_step_subtitle', defaultMessage: 'Describe the problem' }
});

const stepsOrder: string[] = [
    'order',
    'client',
    'customer',
    'problem',
];

export const CreateServiceCallSteps: React.FC<CreateServiceCallStepsProps> = (props) => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const location = useLocation();

    const navigateToStep = (location: string) => history.push(`/service-calls/create/${location}`);
    const currentStepPath = _.chain(location.pathname).split('/').last().value();
    const currentStepIndex = stepsOrder.findIndex(step => step === currentStepPath);
    const isJaymarEmployee = useSelector(isCurrentUserAnEmployee);

    const { control, formState: { errors } } = useFormContext<ServiceCallEdit>();
    const billId = useWatch({ control, name: 'billId' });
    const clientId = useWatch({ control, name: 'clientId' });
    const isOrderStepComplete = isJaymarEmployee ? clientId != null : billId != null;

    const clientAgentName = useWatch({ control, name: 'clientAgentName' });
    const clientAgentPhoneNumber = useWatch({ control, name: 'clientAgentPhoneNumber' });
    const clientAgentEmail = useWatch({ control, name: 'clientAgentEmail' });
    const isClientStepComplete = StringHelper.hasValue(clientAgentName) && StringHelper.hasValue(clientAgentPhoneNumber) && StringHelper.hasValue(clientAgentEmail);

    const customerName = useWatch({ control, name: 'customerName' });
    const customerAddress = useWatch({ control, name: 'customerAddress.address1' });
    const isCustomerStepComplete = StringHelper.hasValue(customerName) && StringHelper.hasValue(customerAddress);

    const problemDescription = useWatch({ control, name: 'problemDescription' });
    const attachments = useWatch({ control, name: 'attachments' });
    const isProblemStepComplete = isJaymarEmployee
        ? StringHelper.hasValue(problemDescription)
        : StringHelper.hasValue(problemDescription) && attachments != null && attachments.length > 0;

    return (
        <Step.Group fluid widths={isJaymarEmployee ? 3 : 4}>
            <Step
                link
                active={currentStepPath === 'order'}
                completed={isOrderStepComplete}
                icon={{ name: 'cart' }} 
                title={formatMessage(m.orderStepTitle)} 
                description={formatMessage(m.orderStepSubtitle)}
                onClick={() => navigateToStep('order')}
            />

            {!isJaymarEmployee &&
                <Step
                    link
                    active={currentStepPath === 'client'}
                    disabled={currentStepIndex < 1}
                    completed={isClientStepComplete}
                    icon={'user outline'} 
                    title={formatMessage(m.clientStepTitle)} 
                    description={formatMessage(m.clientStepSubtitle)}
                    onClick={() => navigateToStep('client')}
                />
            }

            <Step
                link
                active={currentStepPath === 'customer'}
                disabled={currentStepIndex < 2}
                completed={isCustomerStepComplete}
                icon={'user'} 
                title={formatMessage(m.customerStepTitle)} 
                description={formatMessage(m.customerStepSubtitle)}
                onClick={() => navigateToStep('customer')}
            />

            <Step
                link
                active={currentStepPath === 'problem'}
                disabled={currentStepIndex < 3}
                completed={isProblemStepComplete}
                icon={'first aid'} 
                title={formatMessage(m.problemStepTitle)} 
                description={formatMessage(m.problemStepSubtitle)}
                onClick={() => navigateToStep('problem')}
            />
        </Step.Group>
    );
};