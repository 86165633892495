import * as React from 'react';
import { Divider } from 'semantic-ui-react';

interface OwnProps {
    title: string;
}

export type OrderSectionProps =
    & OwnProps;

const OrderSection: React.StatelessComponent<OrderSectionProps> = (props) => {
    return (
        <Divider horizontal={true} style={{ marginTop: '30px' }}>{props.title}</Divider>
    );
};

export { OrderSection };