import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { Message } from 'semantic-ui-react';

const m = defineMessages({
    linkTitle: { id: 'ForgotPasswordLink.linkTitle', defaultMessage: 'Forgot your password?' }
})

export const ForgotPasswordLink: React.FC = () => {
    const { formatMessage } = useIntl();

    return (
        <Message visible style={{ width: '100%', textAlign: 'center' }}>
            <NavLink to="/account/forgot-password">
                {formatMessage(m.linkTitle)}
            </NavLink>
        </Message>
    );
};