import { BaseService } from './baseService';

export class DefectsService extends BaseService {
    constructor() {
        super('api/defects');
    }

    public getAll(): Promise<any> {
        return this.get();
    }
}

export default DefectsService;