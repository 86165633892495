import * as React from 'react';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { Form, Grid, Message, TextArea, TextAreaProps } from 'semantic-ui-react';

import { ProblemEdit, ValidationResult } from '../../state/models';
import { UploadPictures } from '../after-sales';
import { SectionHeader } from '../common';

interface ProblemInformationFormProps {
    problem: ProblemEdit;
    validationResult: ValidationResult;
    onChange?: (fieldId: string, newValue: string) => void;
    onDeletePicture?: (file: File) => void;
    onLoadPicture?: (file: File) => void;
}

const m = defineMessages({
    problemSectionTitle: { id: 'ProblemInformationForm.problem_section_title', defaultMessage: 'Problem information' },
    problemSectionSubtitle: { id: 'ProblemInformationForm.problem_section_subtitle', defaultMessage: 'Describe in a few words the problem with your Jaymar product. You can also attach pictures of the affected parts.' },
    problemDescription: { id: 'ProblemInformationForm.problem_description', defaultMessage: 'Problem description' },
    problemDescriptionPlaceholder: { id: 'ProblemInformationForm.problem_description_placeholder', defaultMessage: 'Describe the problem in a few words' },
    picturesSectionTitle: { id: 'ProblemInformationForm.pictures_section_title', defaultMessage: 'Pictures of the affected parts' },
    picturesSectionSubtitle: { id: 'ProblemInformationForm.pictures_section_subtitle', defaultMessage: 'Take and upload pictures of the affected parts of your product to help our customer service identify the problem.' },
    uploadPicturesButton: { id: 'ProblemInformationForm.upload_pictures_button', defaultMessage: 'Upload Pictures' },
    uploadPicturesInstructions: { id: 'ProblemInformationForm.upload_pictures_description', defaultMessage: 'Select one or more files from your device.' },
});

class ProblemInformationForm extends React.Component<ProblemInformationFormProps & WrappedComponentProps, {}> {
    private problemDescriptionInput?: TextArea;

    public componentDidMount() {
        if (this.problemDescriptionInput != null) {
            this.problemDescriptionInput.focus();
        }
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const { problem } = this.props;
        const localizedErrorMessages = this.props.validationResult.errorMessages.map(value => formatMessage(value));

        return (
            <div>
                <SectionHeader
                    title={formatMessage(m.problemSectionTitle)}
                    subtitle={formatMessage(m.problemSectionSubtitle)}
                />

                <Form error={!this.props.validationResult.isValid} style={{ maxWidth: 450 }}>
                    <Form.Field required={true} error={this.props.validationResult.isFieldInvalid('description')}>
                        <label>{formatMessage(m.problemDescription)}</label>
                        <TextArea
                            id="description"
                            placeholder={formatMessage(m.problemDescriptionPlaceholder)}
                            value={problem.description}
                            onChange={this.handleChange}
                            ref={(input: TextArea) => { this.problemDescriptionInput = input; }}
                        />
                    </Form.Field>

                    <SectionHeader
                        title={formatMessage(m.picturesSectionTitle)}
                        subtitle={formatMessage(m.picturesSectionSubtitle)}
                    />
                    <Grid columns="equal" style={{ marginTop: '10px' }}>
                        <Grid.Column>
                            <UploadPictures
                                required={this.props.problem.areAttachmentsRequired}
                                error={this.props.validationResult.isFieldInvalid('attachments')}
                                onDeletePicture={this.props.onDeletePicture}
                                onLoadPicture={this.props.onLoadPicture}
                            />
                        </Grid.Column>
                    </Grid>

                    <Message error={true} list={localizedErrorMessages} />
                </Form>
            </div >
        );
    }

    private handleChange = (e: React.FormEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
        if (this.props.onChange) {
            this.props.onChange(e.currentTarget.id, data.value as string);
        }
    }
}

const connectedComponent = injectIntl(ProblemInformationForm);
export { connectedComponent as ProblemInformationForm };