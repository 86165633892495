import { ActionTypeKeys } from './types';
import { NotificationType, Defect } from '../../models';
import { ThunkAction } from 'redux-thunk';
import { ApplicationState } from '..';
import * as globalNotification from '../global-notification';
import { DefectsService } from '../../../services';
import { AppThunk } from '../../store';

export type ActionTypes =
    | LoadDefectsRequestAction
    | LoadDefectsSuccessAction
    | LoadDefectsFailureAction
    | { type: '' };

export interface LoadDefectsRequestAction { type: ActionTypeKeys.DEFECTS_LOAD_REQUEST; }
export interface LoadDefectsSuccessAction { type: ActionTypeKeys.DEFECTS_LOAD_SUCCESS; payload: Defect[]; }
export interface LoadDefectsFailureAction { type: ActionTypeKeys.DEFECTS_LOAD_FAILURE; payload: string; }

const service = new DefectsService();

export const actionCreators = {
    loadDefects: (): AppThunk => {
        return (dispatch, getState) => {
            dispatch({ type: ActionTypeKeys.DEFECTS_LOAD_REQUEST });

            service.getAll()
                .then(response => response.json() as Defect[])
                .then(defects => {
                    dispatch({ type: ActionTypeKeys.DEFECTS_LOAD_SUCCESS, payload: defects });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.DEFECTS_LOAD_FAILURE, payload: error });
                    // TODO: Fix this
                    // dispatch(globalNotificationActions.showErrorNotification(
                    //     'Erreur', 
                    //     `Une erreur est survenue en tentant de récupérer la classification des défectuosités (${error}).`)
                    // );
                });
        };
    },
};