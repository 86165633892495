import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { defineMessages, useIntl } from "react-intl";
import { Form, FormProps } from "semantic-ui-react";
import { ServiceCallEdit } from "../../models";

interface OwnProps {
}

export type ClientInformationFormProps =
    & FormProps
    & OwnProps;

const m = defineMessages({
    nameLabel: { id: 'ClientInformationStep.nameLabel', defaultMessage: 'Your name' },
    nameRequired: { id: 'ClientInformationStep.nameRequired', defaultMessage: 'Please enter your name.' },
    emailLabel: { id: 'ClientInformationStep.emailLabel', defaultMessage: 'Your email' },
    emailRequired: { id: 'ClientInformationStep.emailRequired', defaultMessage: 'Please specify an email adress that our customer service agents can use to contact you.' },
    phoneLabel: { id: 'ClientInformationStep.phoneLabel', defaultMessage: 'Your phone number' },
    phoneRequired: { id: 'ClientInformationStep.phoneRequired', defaultMessage: 'Please specify a phone number that our customer service agents can use to contact you.' }
});

export const ClientInformationForm: React.FC<ClientInformationFormProps> = (props) => {
    const { formatMessage } = useIntl();
    const { ...formProps } = props;
    const { control, formState: { errors }, setValue } = useFormContext<ServiceCallEdit>();

    return (
        <Form {...formProps}>
            <Controller
                name="clientAgentName"
                control={control}
                rules={{ required: formatMessage(m.nameRequired) }}
                render={({ field }) => (
                    <Form.Input
                        required
                        error={errors.clientAgentName != null}
                        label={formatMessage(m.nameLabel)}
                        value={field.value || ''}
                        onChange={(e, data) => setValue('clientAgentName', data.value)}
                    />
                )}
            />

            <Controller
                name="clientAgentEmail"
                control={control}
                rules={{ required: formatMessage(m.emailRequired) }}
                render={({ field }) => (
                    <Form.Input
                        required
                        error={errors.clientAgentEmail != null}
                        label={formatMessage(m.emailLabel)}
                        value={field.value || ''}
                        onChange={(e, data) => setValue('clientAgentEmail', data.value)}
                    />
                )}
            />

            <Controller
                name="clientAgentPhoneNumber"
                control={control}
                rules={{ required: formatMessage(m.phoneRequired) }}
                render={({ field }) => (
                    <Form.Input
                        required
                        error={errors.clientAgentPhoneNumber != null}
                        label={formatMessage(m.phoneLabel)}
                        value={field.value || ''}
                        onChange={(e, data) => setValue('clientAgentPhoneNumber', data.value)}
                    />
                )}
            />
        </Form>
    );
};