import React, { useCallback, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { PageHeader } from "components/common";
import { DefectiveRatiosSection } from "./components/DefectiveRatiosSection";
import {
  DetailedStatisticsContextType,
  DetailedStatisticsProvider,
} from "./components/DetailedStatisticsProvider";
import { DefectiveRatioGroup } from "./types";
import { DetailedStatisticsSalesFilters } from "state/models/filters/DetailedStatisticsSalesFilters";
import { DateFilterType } from "state/models";
import moment from "moment";
import { ServiceCallsSection } from "./components/ServiceCallsSection";

const messages = defineMessages({
  pageTitle: {
    id: "DetailedStatisticsPage.page_title",
    defaultMessage: "Detailed statistics",
  },
  pageSubtitle: {
    id: "DetailedStatisticsPage.page_subtitle",
    defaultMessage: "View the detailed statistics on defective items.",
  },
  tableTitle: {
    id: "DetailedStatisticsPage.table_title",
    defaultMessage: "Items sold from {start} to {end} declared defective",
  },
});

export const DetailedStatisticsPage = () => {
  const { formatMessage } = useIntl();

  const [defectiveRatiosFilters, setDefectiveRatiosFilters] =
    useState<DetailedStatisticsSalesFilters>({
      dateFilter: {
        type: DateFilterType.previousYear,
        customStartDate: moment().startOf("year").toDate(),
        customEndDate: moment().toDate(),
      },
    });

  const [defectiveRatiosSelectedGroupType, setDefectiveRatiosSelectedGroupType] =
    useState<DefectiveRatioGroup>(DefectiveRatioGroup.ByItem);

  const [defectiveRatiosSelectedGroupId, setDefectiveRatiosSelectedGroupId] = useState<
    string | number
  >();

  const changeDefectiveRatiosFilters = useCallback(
    (newFilters: DetailedStatisticsSalesFilters) => {
      setDefectiveRatiosFilters(newFilters);
      setDefectiveRatiosSelectedGroupId(undefined);
    },
    []
  );

  const changeDefectiveRatiosSelectedGroupType = useCallback(
    (newSelectedGroupType: DefectiveRatioGroup) => {
      setDefectiveRatiosSelectedGroupType(newSelectedGroupType);
      setDefectiveRatiosSelectedGroupId(undefined);
    },
    []
  );

  const contextState: DetailedStatisticsContextType = {
    defectiveRatios: {
      filters: defectiveRatiosFilters,
      selectedGroupType: defectiveRatiosSelectedGroupType,
      selectedGroupId: defectiveRatiosSelectedGroupId,
      changeFilters: changeDefectiveRatiosFilters,
      changeSelectedGroupType: changeDefectiveRatiosSelectedGroupType,
      changeSelectedGroupId: setDefectiveRatiosSelectedGroupId,
    },
  };

  return (
    <>
      <PageHeader
        iconName='calculator'
        title={formatMessage(messages.pageTitle)}
        subtitle={formatMessage(messages.pageSubtitle)}
      />

      <DetailedStatisticsProvider value={contextState}>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "3rem" }}>
          <div style={{ flexGrow: 1, flexShrink: 0 }}>
            <DefectiveRatiosSection />
          </div>
          <div style={{ flexGrow: 1 }}>
            <ServiceCallsSection />
          </div>
        </div>
      </DetailedStatisticsProvider>
    </>
  );
};
