import { ApplicationState } from '..';
import { UserAccountType, User } from '../../models';
import { ApiWebhook } from './types';

export const getCurrentUserRole = (state: ApplicationState): UserAccountType => {
    const currentUser = getCurrentUserProfile(state);
    return currentUser?.accountType || UserAccountType.none;
};

export const isCurrentUserAgent = (state: ApplicationState): boolean => 
    getCurrentUserRole(state) === UserAccountType.customerServiceAgent;

export const getCurrentUserProfile = (state: ApplicationState): User | undefined => state.account.userSession?.user;

export function getApiWebhooks(state: ApplicationState): ApiWebhook[] {
    return state.currentUser.apiWebhooks.allIds.map(x => state.currentUser.apiWebhooks.byId[x]);
}

export function isLoadingApiWebhooks(state: ApplicationState): boolean {
    return state.currentUser.apiWebhooks.isLoading;
}