import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Icon, Message, Segment } from "semantic-ui-react";
import { AccountPageLayout } from "./components/AccountPageLayout";

const m = defineMessages({
    title: { id: 'PasswordResetRequestedPage.title', defaultMessage: 'Check your emails to proceed' },
    instructions: { id: 'PasswordResetRequestedPage.instructions', defaultMessage: 'A password reset request has been sent to your email address. Please check your emails to proceed.' }
})

export const ConfirmPasswordResetPage: React.FC = () => {
    const { formatMessage } = useIntl();

    return (
        <AccountPageLayout title={formatMessage(m.title)}>
            <Segment raised style={{ width: '100%' }}>
                <Message size="large" info icon>
                    <Icon name="info circle" />
                    {formatMessage(m.instructions)}
                </Message>
            </Segment>
        </AccountPageLayout>
    );
};