import { Client } from '../Client';

export const allClients: string = 'ALL';

export interface ClientFilter {
    clientCode: string;
    clientsSearchResults: Client[];
    isSearching: boolean;
}

export const initialClientFilter: ClientFilter = {
    clientCode: allClients,
    clientsSearchResults: [],
    isSearching: false
};

export function isInitialClientFilter(filter: ClientFilter) {
    return filter.clientCode == null || filter.clientCode === '';
}