import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useResponsive } from '../../utils/responsive';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';

import { actionCreators as ServiceCallsActions } from '../../state/ducks/service-calls/actions';

import { PageHeader } from '../../components/common';
import { FilterPopup, ServiceCallList, ServiceCallTable } from '../../components/service-calls';
import { ServiceCall, FilterOptions, SortDirection } from '../../state/models';
import { isCurrentUserAgent } from '../../state/ducks/current-user';
import { areCustomServiceCallsLoading, getCustomServiceCalls, getCustomServiceCallsFilters, getCustomServiceCallsSortings } from '../../state/ducks/service-calls';

const m = defineMessages({
    pageTitle: { id: 'ServiceCallsCustomPage.page_title', defaultMessage: 'Ongoing service calls' },
    pageSubtitle: { id: 'ServiceCallsCustomPage.page_subtitle', defaultMessage: 'View the ongoing service calls and take actions to close them.' },
});

interface RouteProps {
    page?: string;
}

export const ServiceCallsCustomPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const { isMobile } = useResponsive();
    const { page } = useParams<RouteProps>();
    const currentPage = page != null ? Number(page) : undefined;
    const history = useHistory();
    const dispatch = useDispatch();

    const isAgent = useSelector(isCurrentUserAgent);
    const isLoading = useSelector(areCustomServiceCallsLoading);
    const serviceCalls = useSelector(getCustomServiceCalls);
    const filters = useSelector(getCustomServiceCallsFilters);
    const sortings = useSelector(getCustomServiceCallsSortings);

    useEffect(() => {
        dispatch(ServiceCallsActions.loadOpenedServiceCallsInCustomView(currentPage, filters, sortings))
    }, [dispatch, currentPage, filters, sortings]);

    const navigateToDetailsPage = (serviceCall: ServiceCall) => history.push(`/service-calls/${serviceCall.id}/details`);
    const navigateToPageNumber = (page: number) => history.push(`/service-calls/custom/${page}`);
    const navigateToFirstPage = () => navigateToPageNumber(1);

    const applySorting = (sortBy: string, direction: SortDirection) => {
        dispatch(ServiceCallsActions.applySortingOnMine(sortBy, direction));

        if (currentPage !== 1) {
            navigateToFirstPage();
        }
    }

    const applyFilters = (filters: FilterOptions) => {
        dispatch(ServiceCallsActions.applyFiltersOnMine(filters));

        if (currentPage !== 1) {
            navigateToFirstPage();
        }
    }

    const serviceCallsProps = {
        serviceCalls: serviceCalls,
        sortings: sortings,
        isLoading: isLoading,
        onViewDetails: navigateToDetailsPage,
        onPageChange: navigateToPageNumber,
        onSortData: applySorting
    };

    return (
        <div>
            <PageHeader
                iconName="phone"
                title={formatMessage(m.pageTitle)}
                subtitle={formatMessage(m.pageSubtitle)}
            />

            <Segment basic textAlign="right" style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}>
                <FilterPopup
                    loading={isLoading}
                    filters={filters}
                    onApply={applyFilters}
                />
            </Segment>

            {isMobile && <ServiceCallList {...serviceCallsProps} /> }
            {!isMobile && <ServiceCallTable showClient={isAgent} {...serviceCallsProps} /> }
        </div>
    );
};