import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../../state/ducks';
import * as SubcontractorsActions from '../actions';
import { Subcontractor } from '../../../../state/models';
import { getAllSubcontractors, areSubcontractorsLoading } from '../selectors';
import { SubcontractorsTable } from './components/SubcontractorsTable';
import { RouteComponentProps } from 'react-router';
import { PageHeader, LoadingDimmer, LoadingContainer } from '../../../../components/common';
import { Divider, Button } from 'semantic-ui-react';
import { commonMessages } from '../../../../constants';
import { ThunkAction } from 'redux-thunk';

interface DispatchProps {
    subcontractorsActions: typeof SubcontractorsActions.actionCreators;
}

interface StoreProps {
    subcontractors: Subcontractor[];
    isLoading: boolean;
}

export type ManageSubcontractorsPageProps =
    & StoreProps
    & DispatchProps
    & RouteComponentProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'ManageSubcontractorsPage.title', defaultMessage: 'Manage subcontractors' },
    subtitle: { id: 'ManageSubcontractorsPage.subtitle', defaultMessage: 'Manage the subcontractors that can perform repairs on behalf of Jaymar.' },
    addButton: { id: 'ManageSubcontractorsPage.addButton', defaultMessage: 'Add a subcontractor' }
});

class ManageSubcontractorsPage extends React.Component<ManageSubcontractorsPageProps, {}> {
    public componentDidMount() {
        this.props.subcontractorsActions.loadAll();
    }

    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <PageHeader iconName="setting" title={formatMessage(m.title)} subtitle={formatMessage(m.subtitle)} />
                <LoadingContainer>
                    <LoadingDimmer active={this.props.isLoading} />

                    <SubcontractorsTable
                        subcontractors={this.props.subcontractors}
                        onModify={this.modifySubcontractor}
                        onDelete={this.deleteSubcontractor}
                    />

                    <Divider section />

                    <div style={{ textAlign: 'right' }}>
                        <Button primary content={formatMessage(m.addButton)} onClick={this.createSubcontractor} />
                    </div>
                </LoadingContainer>
            </React.Fragment>
        );
    }

    private deleteSubcontractor = (subcontractor: Subcontractor) => {
        this.props.subcontractorsActions.delete(subcontractor.id);
    }

    private modifySubcontractor = (subcontractor: Subcontractor) => {
        this.props.history.push(`/administration/subcontractors/${subcontractor.id}`);
    }

    private createSubcontractor = () => {
        this.props.history.push(`/administration/subcontractors/create`);
    }
}

const mapStateToProps = (state: ApplicationState): StoreProps => {
    return {
        subcontractors: getAllSubcontractors(state),
        isLoading: areSubcontractorsLoading(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    return {
        subcontractorsActions: bindActionCreators(SubcontractorsActions.actionCreators, dispatch),
    };
};

const intlComponent = injectIntl(ManageSubcontractorsPage);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as ManageSubcontractorsPage };