import { Reducer } from 'redux';
import { ActionTypes } from './actions';
import { ActionTypeKeys } from './types';

export interface UiState {
    currentServiceCallTabId: ServiceCallTabs;
    currentAgentStepId: ServiceCallAgentSteps;
    currentTechnicianStepId: ServiceCallTechnicianSteps;
    currentFactoryStepId: ServiceCallFactorySteps;
    currentInspectionStepId: ServiceCallInspectionSteps;
}

export type ServiceCallTabs = 'details' | 'agent' | 'technician' | 'factory' | 'inspection';
export type ServiceCallAgentSteps = 'assign' | 'inspect' | 'approve' | 'repair' | 'close';
export type ServiceCallTechnicianSteps = 'prepare' | 'repair' | 'complete';
export type ServiceCallFactorySteps = 'prepare' | 'repair' | 'complete';
export type ServiceCallInspectionSteps = 'review' | 'schedule' | 'inspect';

const initialState: UiState = {
    currentServiceCallTabId: 'details',
    currentAgentStepId: 'assign',
    currentTechnicianStepId: 'prepare',
    currentFactoryStepId: 'prepare',
    currentInspectionStepId: 'review'
};

export const uiReducer: Reducer<UiState> = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case ActionTypeKeys.CHANGE_AGENT_STEP:
            return {
                ...state,
                currentAgentStepId: action.payload
            };

        case ActionTypeKeys.CHANGE_FACTORY_STEP:
            return {
                ...state,
                currentFactoryStepId: action.payload
            };

        case ActionTypeKeys.CHANGE_TECHNICIAN_STEP:
            return {
                ...state,
                currentTechnicianStepId: action.payload
            };

        case ActionTypeKeys.CHANGE_INSPECTION_STEP:
            return {
                ...state,
                currentInspectionStepId: action.payload
            };

        case ActionTypeKeys.CHANGE_SERVICE_CALL_TAB:
            return {
                ...state,
                currentServiceCallTabId: action.payload
            };

        default:
            return state;
    }
};

export default uiReducer;