import React from "react";
import { DeepMap, FieldError } from "react-hook-form";
import { Message, MessageProps } from "semantic-ui-react";

interface OwnProps {
    errors: DeepMap<any, FieldError>;
}

export type FormValidationSummaryProps =
    & MessageProps
    & OwnProps;

export const FormValidationSummary: React.FC<FormValidationSummaryProps> = (props) => {
    const { errors, ...messageProps } = props;
    const hasErrors = Object.keys(errors).filter(x => (errors as any)[x] != null).length > 0;
    const errorMessages = Object.keys(errors).map(x => (errors as any)[x]?.message);

    if (!hasErrors) {
        return <></>;
    }

    return (
        <Message
            error
            list={errorMessages}
            {...messageProps}
        />
    );
};