import React from 'react';
import moment from 'moment';

import { injectIntl, WrappedComponentProps, defineMessages, FormattedMessage, FormattedNumber } from 'react-intl';
import { Feed, Popup, List } from 'semantic-ui-react';

import { JaymarTechnicianUpdate, TechnicianRepair } from '../../../state/models';
import { StringHelper } from '../../../state/utils';
import { TechnicianReportModal } from '../reports';

export interface JaymarTechnicianActivityProps {
    activity: JaymarTechnicianUpdate;
    technicianRepair: TechnicianRepair | undefined;
}

const m = defineMessages({
    summaryWithTechnicianFormat: { id: 'JaymarTechnicianActivity.summary_with_subcontractor', defaultMessage: 'The Jaymar\'s technician {technician} performed repairs on the furniture.' },
    repairCostFormat: { id: 'JaymarTechnicianActivity.repair_cost_format', defaultMessage: 'The technician billed {amount} for the repairs' },
    shippingCostFormat: { id: 'JaymarTechnicianActivity.shipping_cost_format', defaultMessage: 'The technician billed {amount} for the shipping fees' },
    viewReport: { id: 'JaymarTechnicianActivity.view_report', defaultMessage: 'View report' }
});

class JaymarTechnicianActivity extends React.Component<JaymarTechnicianActivityProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { activity, technicianRepair } = this.props;

        const repairsCost = <FormattedNumber value={activity.amountBilled || 0} style="currency" currency={activity.currencyCode} />;
        const repairsCostDescription = <FormattedMessage {...m.repairCostFormat} values={{ 'amount': repairsCost }} />;
        const shippingCost = <FormattedNumber value={activity.shippingAmountBilled || 0} style="currency" currency={activity.currencyCode} />;
        const shippingCostDescription = <FormattedMessage {...m.shippingCostFormat} values={{ 'amount': shippingCost }} />;

        return (
            <Feed.Event key={activity.id}>
                <Feed.Label icon="wrench" />
                <Feed.Content>
                    <Popup
                        trigger={<Feed.Date content={moment.utc(activity.createdOn).fromNow()} />}
                        content={moment.utc(activity.createdOn).format('LL')}
                    />

                    <Feed.Summary>
                        <FormattedMessage {...m.summaryWithTechnicianFormat} values={{ technician: activity.technicianName }} />
                    </Feed.Summary>

                    <Feed.Extra text={true}>
                        <List bulleted={true}>
                            {activity.amountBilled > 0 && <List.Item content={repairsCostDescription} />}
                            {activity.shippingAmountBilled > 0 && <List.Item content={shippingCostDescription} />}
                        </List>

                        {StringHelper.hasValue(activity.note) &&
                            <p style={{ whiteSpace: 'pre-line' }}>{activity.note}</p>
                        }

                        {technicianRepair != null &&
                            <TechnicianReportModal
                                trigger={<a>{formatMessage(m.viewReport)}</a>}
                                technicianRepair={technicianRepair}
                            />
                        }
                    </Feed.Extra>
                </Feed.Content>
            </Feed.Event>
        );
    }
}

const connectedComponent = injectIntl(JaymarTechnicianActivity);
export { connectedComponent as JaymarTechnicianActivity };