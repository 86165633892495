import React from 'react';
import _ from 'lodash';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Dropdown, DropdownProps, DropdownOnSearchChangeData, DropdownItemProps, LabelProps, Label } from 'semantic-ui-react';

interface OwnProps {
    onAddEmail: (email: string) => void;
    onRemoveEmail: (email: string) => void;
}

interface StateProps {
    currentEmail: string;
}

export type MultipleEmailsDropdownProps =
    & DropdownProps
    & OwnProps
    & WrappedComponentProps;

const m = defineMessages({
    enterEmailAddress: { id: 'EmailDropdown.enterEmailAddress', defaultMessage: 'Enter a valid email address' },
    addLabel: { id: 'EmailDropdown.addLabel', defaultMessage: 'Add ' },
    invalidEmail: { id: 'EmailDropdown.invalidEmail', defaultMessage: 'This email address is not valid.' },
});

class MultipleEmailsDropdown extends React.Component<MultipleEmailsDropdownProps, StateProps> {
    private readonly emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    public constructor(props: MultipleEmailsDropdownProps) {
        super(props);

        this.state = { currentEmail: '' };
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const { intl, onAddEmail, onRemoveEmail, ...dropdownProps } = this.props;

        return (
            <Dropdown
                fluid={true}
                selection={true}
                multiple={true}
                search={true}
                allowAdditions={true}
                additionLabel={formatMessage(m.addLabel)}
                onAddItem={this.handleAddItem}
                onSearchChange={this.handleSearchChange}
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                searchQuery={this.state.currentEmail}
                noResultsMessage={formatMessage(m.enterEmailAddress)}
                renderLabel={this.renderLabel}
                {...dropdownProps}
            />
        );
    }

    private handleChange = (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const removedEmails = _.difference(this.props.value as string[], data.value as string[]);
        removedEmails.map(x => this.props.onRemoveEmail(x));
    }

    private renderLabel = (item: DropdownItemProps, index: number, defaultLabelProps: LabelProps) => {
        const { formatMessage } = this.props.intl;
        const email = item.value as string;
        const isValid = this.emailRegex.test(email);

        return isValid
            ? <Label content={email} />
            : <Label title={formatMessage(m.invalidEmail)} color="red" content={email} />;
    }

    private handleSearchChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownOnSearchChangeData) => {
        this.setState({ currentEmail: data.searchQuery });
    }

    private handleAddItem = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        const email = data.value as string;
        if (email != null && email !== '') {
            this.props.onAddEmail(email);
            this.setState({ currentEmail: '' });
        }
    }

    private handleBlur = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        if (this.state.currentEmail !== '') {
            this.props.onAddEmail(this.state.currentEmail);
            this.setState({ currentEmail: '' });
        }
    }
}

const intlComponent = injectIntl(MultipleEmailsDropdown);
export { intlComponent as MultipleEmailsDropdown };