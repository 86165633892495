import { get, httpDelete, post } from '../../../utils/api';
import { Subcontractor } from '../../../state/models';
import { SubcontractorEdit } from './views/models/SubcontractorEdit';

const apiEndpoint = '/api/subcontractors';

export async function loadAll(): Promise<Subcontractor[]> {
    return get<Subcontractor[]>(`${apiEndpoint}`);
}

export async function loadById(subcontractorId: number): Promise<Subcontractor> {
    return get<Subcontractor>(`${apiEndpoint}/${subcontractorId}`);
}

export async function update(subcontractorId: number, subcontractor: SubcontractorEdit): Promise<Subcontractor> {
    return post<Subcontractor>(`${apiEndpoint}/${subcontractorId}`, subcontractor);
}

export async function deleteEntity(subcontractorId: number): Promise<void> {
    return httpDelete(`${apiEndpoint}/${subcontractorId}`);
}

export async function create(subcontractor: SubcontractorEdit): Promise<Subcontractor> {
    return post<Subcontractor>(`${apiEndpoint}`, subcontractor);
}