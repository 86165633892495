import { ThunkAction } from 'redux-thunk';
import { ActionTypeKeys } from '../types';
import { ApplicationState } from '../..';
import { FilterOptions } from '../../../models';

export type FilteringActionTypes =
    | ApplyOpenedServiceCallsFiltersAction
    | ApplyServiceCallsHistoryFiltersAction
    | ApplyMyServiceCallsFiltersAction
    | ApplyCustomFiltersAction
    | { type: '' };

interface ApplyOpenedServiceCallsFiltersAction { type: ActionTypeKeys.APPLY_OPENED_FILTERS; payload: FilterOptions; }
interface ApplyServiceCallsHistoryFiltersAction { type: ActionTypeKeys.APPLY_HISTORY_FILTERS; payload: FilterOptions; }
interface ApplyMyServiceCallsFiltersAction { type: ActionTypeKeys.APPLY_MINE_FILTERS; payload: FilterOptions; }
interface ApplyCustomFiltersAction { type: ActionTypeKeys.APPLY_CUSTOM_FILTERS; payload: FilterOptions; }

export const filteringActionCreators = {
    applyFiltersOnOpened: (filters: FilterOptions) => {
        return { type: ActionTypeKeys.APPLY_OPENED_FILTERS, payload: filters };
    },

    applyFiltersOnHistory: (filters: FilterOptions) => {
        return { type: ActionTypeKeys.APPLY_HISTORY_FILTERS, payload: filters };
    },

    applyFiltersOnMine: (filters: FilterOptions) => {
        return { type: ActionTypeKeys.APPLY_MINE_FILTERS, payload: filters };
    },

    applyFiltersOnCustomView: (filters: FilterOptions) => {
        return { type: ActionTypeKeys.APPLY_CUSTOM_FILTERS, payload: filters };
    }
};