import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Search, SearchResultProps, Label, SearchProps, SearchResultData } from 'semantic-ui-react';
import { Item } from '../../state/models';
import { debounce, StringHelper } from '../../state/utils';

interface SearchItemControlOwnProps {
    results?: Item[];
    isSearching?: boolean;
    defaultValue?: string;
    onResultSelected?: (item: Item) => void;
    onResultMouseOver?: (item: Item) => void;
    onSearch?: (query: string) => void;
}

interface SearchItemControlState {
    isDebouncing: boolean;
}

export type SearchItemControlProps =
    & SearchItemControlOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    placeholder: { id: 'SearchItemControl.placeholder', defaultMessage: 'Search for an item by its code' },
    resultTitle: { id: 'SearchItemControl.result_title', defaultMessage: '{code} - {name}' },
    noResultsMessage: { id: 'SearchItemControl.no_results_message', defaultMessage: 'No item found' },
});

class SearchItemControl extends React.Component<SearchItemControlProps, SearchItemControlState> {
    public constructor(props: SearchItemControlProps) {
        super(props);

        this.state = { isDebouncing: false };
    }

    public render() {
        const { formatMessage } = this.props.intl;

        // We don't want the "No results" to be shown while a search is in progress, or while
        // we're debouncing.
        const isSearching = this.props.isSearching || this.state.isDebouncing;

        return (
            <Search
                fluid={true}
                loading={this.props.isSearching}
                results={this.mapItemsToResults(this.props.results)}
                resultRenderer={this.renderItemSearchResult}
                showNoResults={!isSearching}
                noResultsMessage={formatMessage(m.noResultsMessage)}
                onSearchChange={this.handleSearchChange}
                onResultSelect={this.handleResultSelect}
                placeholder={formatMessage(m.placeholder)} 
            />
        );
    }

    private mapItemsToResults = (items?: Item[]) => {
        const { formatMessage } = this.props.intl;
        
        if (items && items.length > 0) {
            return items.map(item => {
                return {
                    item: item,
                    title: formatMessage(m.resultTitle, { 
                        code: StringHelper.trim(item.id), 
                        name: this.props.intl.locale === 'en' ? StringHelper.trim(item.englishDescription) : StringHelper.trim(item.frenchDescription)
                    })
                };
            });
        }

        return items;
    }

    // tslint:disable-next-line:no-any
    private renderItemSearchResult = (props: SearchResultProps): React.ReactElement<any> => {
        const item = props.item as Item;

        return (
            <a key={item.id} onMouseOver={() => this.handleSearchResultMouseOver(item)}>
                <Label icon="barcode" content={item.id} />
                <Label icon="archive" content={this.props.intl.locale === 'en' ? item.englishDescription : item.frenchDescription} />
            </a>
        );
    }

    private handleSearchDebouncing = () => {
        this.setState((current) => ({ ...current, isDebouncing: true }));
    }

    private handleSearchDebounced = () => {
        this.setState((current) => ({ ...current, isDebouncing: false }));
    }

    // tslint:disable-next-line:member-ordering
    private handleSearchChange = debounce(
        (event: React.MouseEvent<HTMLElement>, data: SearchProps) => {
            if (this.props.onSearch && data.value) {
                this.props.onSearch(data.value);
            }
        },
        250,
        this.handleSearchDebouncing,
        this.handleSearchDebounced);

    private handleSearchResultMouseOver = (item: Item) => {
        if (this.props.onResultMouseOver) {
            this.props.onResultMouseOver(item);
        }
    }

    private handleResultSelect = (event: React.MouseEvent<HTMLDivElement>, data: SearchResultData) => {
        const item = data.result.item as Item;

        if (this.props.onResultSelected) {
            this.props.onResultSelected(item);
        }
    }
}

const connectedComponent = injectIntl(SearchItemControl);
export { connectedComponent as SearchItemControl };