import 'intl';
import 'intl/locale-data/jsonp/fr';
import 'intl/locale-data/jsonp/en';

export type SupportedLocales = 'en' | 'fr';
export const allLocales: SupportedLocales[] = ['en', 'fr'];
export const defaultLocale: SupportedLocales = 'en';

export interface I18nMessages {
    [locale: string]: {
        [id: string]: string;
    };
}

export const i18nMessages = {
    'fr': require('../../assets/i18n/locales/fr.json')
};