import { defineMessages } from "@formatjs/intl";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { SectionHeader } from "../../../components/common";
import { ServiceCallEdit } from "../models";
import { ClientInformationForm } from "./components/ClientInformationForm";
import { FormValidationSummary } from "./components/FormValidationSummary";
import { StepsNavigationDivider } from "./components/StepsNavigationDivider";

interface OwnProps {
}

export type FillClientDetailsPageProps =
    & OwnProps;

const m = defineMessages({
    title: { id: 'ClientInformationStep.sectionTitle', defaultMessage: 'Your contact information' },
    subtitle: { id: 'ClientInformationStep.subtitle', defaultMessage: 'Please provide your information in case we need to contact you for additional information.' },
    nameLabel: { id: 'ClientInformationStep.nameLabel', defaultMessage: 'Your name' },
    emailLabel: { id: 'ClientInformationStep.emailLabel', defaultMessage: 'Your email' },
    phoneLabel: { id: 'ClientInformationStep.phoneLabel', defaultMessage: 'Your phone number' }
});

export const FillClientDetailsPage: React.FC<FillClientDetailsPageProps> = (props) => {
    const { formatMessage } = useIntl();
    const { formState: { errors }, handleSubmit } = useFormContext<ServiceCallEdit>();
    const history = useHistory();

    const navigateToPreviousStep = () => history.push('/service-calls/create/order');
    const navigateToNextStep = () => history.push('/service-calls/create/customer');
    
    return (
        <>
            <SectionHeader title={formatMessage(m.title)} subtitle={formatMessage(m.subtitle)} />

            <FormValidationSummary errors={errors} style={{ maxWidth: '600px' }} />
            <ClientInformationForm style={{ maxWidth: '450px' }} />

            <StepsNavigationDivider onPreviousStepClick={navigateToPreviousStep} onNextStepClick={handleSubmit(navigateToNextStep)} />
        </>
    );
};