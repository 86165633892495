import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Dimmer, Grid, Loader, Message, Segment } from 'semantic-ui-react';

import { ApplicationState } from '../../../state/ducks';
import * as DefectsActions from '../../../state/ducks/defects';
import * as SearchActions from '../../../state/ducks/search';
import * as ServiceCallsActions from '../../../state/ducks/service-calls';
import * as UiActions from '../../../state/ducks/ui';
import * as UserActions from '../../../state/ducks/users';
import { BillDetail, ServiceCall, Defect, User, ServiceCallInspection, Item, ServiceCallDefectiveItem, SelectableDefectiveItem } from '../../../state/models';
import { DefectiveItemsCard, DefectsCard, PlanInspectionCard } from '../../../components/service-calls';
import { InformationMessage, LoadingDimmer, OrDivider } from '../../../components/common';
import { ServiceCallState } from '../../../state/ducks/service-calls/reducers';
import { debounce } from '../../../state/utils';
import { getServiceCallState, getCurrentInspection } from '../../../state/ducks/service-calls';

interface ServiceCallInspectStepActions {
    defectsActions: typeof DefectsActions.actionCreators;
    searchActions: typeof SearchActions.actionCreators;
    serviceCallsActions: typeof ServiceCallsActions.actionCreators;
    uiActions: typeof UiActions.actionCreators;
    userActions: typeof UserActions.actionCreators;
}

interface ServiceCallInspectStepOwnProps {
    serviceCallState: ServiceCallState;
    allDefects: Defect[];
    areDefectsLoading: boolean;
    representatives: User[];
    areRepresentativesLoading: boolean;
    currentInspection: ServiceCallInspection | undefined;
    isSearchingForItems: boolean;
    isChangingDefectiveItems: boolean;
    itemsSearchResults: Item[];
}

export type ServiceCallInspectStepProps =
    & ServiceCallInspectStepOwnProps
    & ServiceCallInspectStepActions
    & RouteComponentProps<{ serviceCallId: string }>;

type IntlServiceCallInspectStepProps = ServiceCallInspectStepProps & WrappedComponentProps;

const m = defineMessages({
    awaitingInspectionTitle: { id: 'ServiceCallInspectStep.awaiting_inspection_title', defaultMessage: 'Upcoming inspection' },
    awaitingInspectionMessage: { id: 'ServiceCallInspectStep.awaiting_inspection_message', defaultMessage: 'This service call is awaiting an inspection from a Jaymar representative.' }
});

class ServiceCallInspectStep extends React.Component<IntlServiceCallInspectStepProps, {}> {
    public componentDidMount() {
        this.props.uiActions.changeAgentStep('inspect');
        this.props.userActions.loadJaymarRepresentatives();

        if (this.props.allDefects.length <= 0 && !this.props.areDefectsLoading) {
            this.props.defectsActions.loadDefects();
        }
    }

    public render() {
        const { formatMessage } = this.props.intl;

        const isLoaded = this.props.serviceCallState != null && this.props.serviceCallState.details != null;
        const bill = isLoaded ? this.props.serviceCallState.details.bill : undefined;
        const warrantyStartDate = isLoaded && bill ? bill.billedOn : undefined;
        const billDetails = isLoaded && bill ? bill.billDetails : [];
        const defectiveItems = isLoaded ? this.props.serviceCallState.details.defectiveItems : [];
        const defects = isLoaded ? this.props.serviceCallState.details.defects : [];
        const hasUpcomingInspection = this.props.currentInspection != null;

        return (
            <div>
                <LoadingDimmer active={!isLoaded} />
                {hasUpcomingInspection &&
                    <InformationMessage
                        header={formatMessage(m.awaitingInspectionTitle)}
                        content={formatMessage(m.awaitingInspectionMessage)}
                    />
                }

                <Grid columns="equal" stackable={true}>
                    <Grid.Column>
                        <DefectiveItemsCard
                            billItems={billDetails}
                            defectiveItems={defectiveItems}
                            isReadOnly={this.props.serviceCallState ? this.props.serviceCallState.isClosed : true}
                            isLoading={this.props.isChangingDefectiveItems}
                            onDefectiveItemChange={this.changeDefectiveItem}
                            isSearchingForItems={this.props.isSearchingForItems}
                            itemsSearchResult={this.props.itemsSearchResults}
                            onSearchForItems={this.searchItem}
                            onSave={this.saveDefectiveItems}
                        />
                    </Grid.Column>

                    <Grid.Column>
                        <DefectsCard
                            serviceCallDefects={defects}
                            allDefects={this.props.allDefects}
                            warrantyStartDate={warrantyStartDate}
                            isReadOnly={this.props.serviceCallState ? this.props.serviceCallState.isClosed : true}
                            onAddDefect={this.addDefect}
                            onRemoveDefect={this.removeDefect}
                        />
                    </Grid.Column>
                </Grid>

                <OrDivider />

                <Grid columns={2} stackable={true}>
                    <Grid.Column>
                        <PlanInspectionCard
                            representatives={this.props.representatives}
                            currentInspection={this.props.currentInspection}
                            isLoading={this.props.areRepresentativesLoading}
                            isReadOnly={this.props.serviceCallState ? this.props.serviceCallState.isClosed : true}
                            onAssign={this.assignRepresentative}
                            onComplete={this.completeInspection}
                        />
                    </Grid.Column>
                </Grid>
            </div>
        );
    }

    private changeDefectiveItem = (item: Item, quantity: number) => {
        this.props.serviceCallsActions.addDefectiveItem(this.props.serviceCallState.details.id, item.id, quantity);
    }

    private saveDefectiveItems = (defectiveItems: SelectableDefectiveItem[]) => {
        const serviceCallId = this.props.serviceCallState.details.id;
        const serviceCallDefectiveItems = defectiveItems.map((x): ServiceCallDefectiveItem => ({
            id: 0,
            billDetailId: x.billDetailId,
            serviceCallId: serviceCallId,
            item: x.item,
            itemId: x.item.id,
            itemCover: x.itemCover,
            itemCoverId: x.itemCover ? x.itemCover.id : '',
            itemQuantity: x.quantityDefective.value,
        }));

        this.props.serviceCallsActions.changeDefectiveItems(serviceCallId, serviceCallDefectiveItems);
    }

    private addDefect = (defect: Defect) => {
        this.props.serviceCallsActions.addDefect(this.props.serviceCallState.details.id, defect);
    }

    private removeDefect = (defect: Defect) => {
        this.props.serviceCallsActions.removeDefect(this.props.serviceCallState.details.id, defect);
    }

    private assignRepresentative = (representativeId: string) => {
        this.props.serviceCallsActions.assignRepresentative(this.props.serviceCallState.details.id, representativeId);
    }

    private completeInspection = () => {
        if (this.props.currentInspection) {
            this.props.serviceCallsActions.confirmInspectionCompletion(this.props.serviceCallState.details.id, this.props.currentInspection.id);
        }
    }

    private searchItem = (searchTerm: string) => {
        this.props.searchActions.findItem(searchTerm);
    }
}

const mapStateToProps = (state: ApplicationState, props: ServiceCallInspectStepProps): ServiceCallInspectStepOwnProps => {
    const { serviceCallId } = props.match.params;
    const serviceCallState = getServiceCallState(state, Number(serviceCallId));

    return {
        serviceCallState: serviceCallState,
        allDefects: state.defects.defects,
        areDefectsLoading: state.defects.isLoading,
        representatives: state.users.representatives,
        areRepresentativesLoading: state.users.areRepresentativesLoading,
        currentInspection: getCurrentInspection(state, Number(serviceCallId)),
        isSearchingForItems: state.search.isSearchingForItems,
        isChangingDefectiveItems: serviceCallState?.isChangingDefectiveItems || false,
        itemsSearchResults: state.search.itemsSearchResult
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ServiceCallInspectStepActions => {
    return {
        defectsActions: bindActionCreators(DefectsActions.actionCreators, dispatch),
        searchActions: bindActionCreators(SearchActions.actionCreators, dispatch),
        serviceCallsActions: bindActionCreators(ServiceCallsActions.actionCreators, dispatch),
        uiActions: bindActionCreators(UiActions.actionCreators, dispatch),
        userActions: bindActionCreators(UserActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(ServiceCallInspectStep);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as ServiceCallInspectStep };