import { defineMessages } from "@formatjs/intl";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { Form, Header } from "semantic-ui-react";
import { UserEdit } from "../../models";

interface OwnProps {
    disabled?: boolean;
}

export type ProfilePropertiesSectionProps =
    & OwnProps;

const m = defineMessages({
    sectionTitle: { id: 'UserProfileProperties.sectionTitle', defaultMessage: 'Profile properties' },
    firstNameLabel: { id: 'UserProfileProperties.firstNameLabel', defaultMessage: 'First name' },
    firstNameRequired: { id: 'UserProfileProperties.firstNameRequired', defaultMessage: 'Please provide a name for this user.' },
    lastNameLabel: { id: 'UserProfileProperties.lastNameLabel', defaultMessage: 'Last name' },
});

export const ProfilePropertiesSection: React.FC<ProfilePropertiesSectionProps> = (props) => {
    const { formatMessage } = useIntl();
    const { control, formState: { errors }, setValue } = useFormContext<UserEdit>();

    return (
        <>
            <Header content={formatMessage(m.sectionTitle)} />
            <Form.Group widths="equal">
                <Controller
                    name="firstName"
                    control={control}
                    rules={{ required: formatMessage(m.firstNameRequired) }}
                    render={({ field }) => (
                        <Form.Input
                            required
                            disabled={props.disabled}
                            error={errors.firstName != null}
                            label={formatMessage(m.firstNameLabel)}
                            value={field.value || ''}
                            onChange={(e, data) => setValue('firstName', data.value)}
                        />
                    )}
                />

                <Controller
                    name="lastName"
                    control={control}
                    render={({ field }) => (
                        <Form.Input
                        disabled={props.disabled}
                            label={formatMessage(m.lastNameLabel)}
                            value={field.value || ''}
                            onChange={(e, data) => setValue('lastName', data.value)}
                        />
                    )}
                />
            </Form.Group>
        </>
    );
};