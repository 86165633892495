import { all, fork, put, select, takeEvery } from "redux-saga/effects";
import { getCurrentUserRole } from "../../state/ducks/current-user";
import { UserAccountType } from "../../state/models";
import { ActionTypeKeys as AccountActionKeys } from '../account/actions';
import { statisticsActionCreators as ServiceCallsActions } from '../../state/ducks/service-calls/actions/statisticsActions';
import { actionCreators as UsersActions } from '../../state/ducks/users/actions';

function* initializeApplication() {
    try {
        const currentUserRole = (yield select(getCurrentUserRole)) as UserAccountType;
        
        switch (currentUserRole) {
            case UserAccountType.customerServiceAgent:
                yield loadCustomerServiceAgentData();
        }
    } catch (e) {
        console.error('An error occurred while initializing the application.');
    }
}

function* loadCustomerServiceAgentData() {
    yield put(ServiceCallsActions.loadNewServiceCallsCount() as any);
    yield put(UsersActions.loadCustomerServiceAgents() as any);
}

function* watchLoginSuccess() { yield takeEvery(AccountActionKeys.LOGIN_SUCCESS, initializeApplication) };
function* watchLoadSessionSuccess() { yield takeEvery(AccountActionKeys.LOAD_USER_SESSION_SUCCESS, initializeApplication) };

function* applicationSagas() {
    yield all([
        fork(watchLoginSuccess),
        fork(watchLoadSessionSuccess),
    ]);
}

export default applicationSagas;