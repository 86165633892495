export class StringHelper {
    public static hasValue(value: string | undefined): boolean {
        // Make sure there's at least one character of non whitespace
        return value != null && /\S/.test(value);
    }

    public static trim(value: string | undefined): string | undefined {
        if (value != null && value.trim) {
            return value.trim();
        }

        return value;
    }
}