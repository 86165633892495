import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Order } from '../../../../state/models/Order';
import { Card, CardProps, List, Icon, Label, Segment } from 'semantic-ui-react';
import { Bill, ServiceCall, ServiceCallDefectiveItem, OrderEdit, ValidationResult, Client } from '../../../../state/models';
import { OrderSummaryListItem } from './OrderSummaryListItem';
import { StringHelper } from '../../../../state/utils';
import { OrderDetail } from '../../../../state/models/OrderDetail';
import { OrderSummaryList } from './OrderSummaryList';
import { ClientQuickOverview } from '../../../../components/quick-overviews';
import { SmallEditButton, SmallCancelButton, SmallSaveButton } from '../../../../components/common';
import { OrderInformationForm } from '../../../../components/service-calls';

interface OwnProps {
    serviceCall: ServiceCall | undefined;
    orderEdit: OrderEdit | undefined;
    isClientSearchVisible?: boolean;
    onItemClick?: (item: OrderDetail) => void;
    onUpdate?: (orderEdit: OrderEdit) => ValidationResult;
}

interface StateProps {
    orderEdit: OrderEdit;
    isEditing: boolean;
    validationResult: ValidationResult;
}

export type OrderSummaryCardProps =
    & CardProps
    & OwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'OrderSummaryCard.title', defaultMessage: 'Bill details' },
    billNumberFormat: { id: 'OrderSummaryCard.billNumberFormat', defaultMessage: 'Bill #{number}' },
    purchaseOrderFormat: { id: 'OrderSummaryCard.purchaseOrderFormat', defaultMessage: 'PO #{number}' },
    referenceNumberFormat: { id: 'OrderSummaryCard.referenceNumberFormat', defaultMessage: 'Reference #{number}' },
    purchasedOn: { id: 'OrderSummaryCard.purchasedOn', defaultMessage: 'Billed on {date}' },
    clientReferenceNumber: { id: 'OrderSummaryCard.clientReferenceNumber', defaultMessage: 'Reference: {number}' },
    clientContactName: { id: 'OrderSummaryCard.clientContactName', defaultMessage: 'Contact: {name}' }
});

class OrderSummaryCard extends React.Component<OrderSummaryCardProps, StateProps> {
    public constructor(props: OrderSummaryCardProps) {
        super(props);

        this.state = {
            orderEdit: this.props.orderEdit || new OrderEdit(),
            isEditing: false,
            validationResult: ValidationResult.empty()
        };
    }

    public componentDidUpdate(prevProps: OrderSummaryCardProps) {
        if (prevProps.orderEdit == null && this.props.orderEdit != null) {
            this.setState({
                orderEdit: this.props.orderEdit
            });
        }
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const { serviceCall } = this.props;
        const bill = this.props.serviceCall ? this.props.serviceCall.bill : undefined;
        const client = this.props.serviceCall ? this.props.serviceCall.client : undefined;

        return (
            <Card color="yellow" fluid={true} style={{ height: '100%', marginTop: 0 }}>
                <Card.Content style={{ flexGrow: 0 }}>
                    {this.renderCardHeader()}
                    {bill &&
                        <Card.Meta>
                            <span>{formatMessage(m.billNumberFormat, { number: bill.id })}</span>

                            {StringHelper.hasValue(bill.purchaseOrder) &&
                                <span>
                                    <span>&#9642;</span>
                                    <span>{formatMessage(m.purchaseOrderFormat, { number: bill.purchaseOrder })}</span>
                                </span>
                            }
                            {StringHelper.hasValue(bill.referenceNumber) &&
                                <span>
                                    <span>&#9642;</span>
                                    <span>{formatMessage(m.referenceNumberFormat, { number: bill.referenceNumber })}</span>
                                </span>
                            }
                        </Card.Meta>
                    }
                </Card.Content>

                {this.state.isEditing && this.renderEditableCardContent()}
                {!this.state.isEditing &&
                    <Card.Content>
                        {client &&
                            <div style={{ marginBottom: '15px' }}>
                                <div>
                                    <Icon name="building" />
                                    <ClientQuickOverview client={client} />
                                </div>

                                {serviceCall && serviceCall.referenceNumber != null &&
                                    <div className="meta" style={{ marginLeft: 20 }}>
                                        {formatMessage(m.clientReferenceNumber, { number: serviceCall.referenceNumber })}
                                    </div>
                                }

                                {serviceCall && StringHelper.hasValue(serviceCall.clientContactName) &&
                                    <div className="meta" style={{ marginLeft: 20 }}>
                                        {formatMessage(m.clientContactName, { name: serviceCall.clientContactName })}
                                    </div>
                                }
                            </div>
                        }
                        {serviceCall &&
                            <OrderSummaryList serviceCall={serviceCall} onItemClick={this.props.onItemClick} />
                        }
                    </Card.Content>
                }

                {bill && bill.tag &&
                    <Card.Content>
                        <Label icon="tag" content={bill.tag} />
                    </Card.Content>
                }

                <Card.Content extra={true}>
                    {bill &&
                        <React.Fragment>
                            <Icon name="calendar" />
                            {formatMessage(m.purchasedOn, { date: moment(bill.billedOn).format('LL') })}
                        </React.Fragment>
                    }
                </Card.Content>
            </Card>
        );
    }

    private renderCardHeader() {
        const { formatMessage } = this.props.intl;
        const title = this.props.title || formatMessage(m.title);

        return (
            <Card.Header>
                {title}
                {!this.state.isEditing && this.props.canEdit && <SmallEditButton floated="right" onClick={this.handleEdit} />}
                {this.state.isEditing &&
                    <div style={{ float: 'right' }}>
                        <SmallCancelButton onClick={this.handleCancel} />
                        <SmallSaveButton onClick={this.handleSave} />
                    </div>
                }
            </Card.Header>
        );
    }

    private renderEditableCardContent() {
        return (
            <Segment basic={true}>
                <OrderInformationForm
                    order={this.state.orderEdit}
                    validationResult={this.state.validationResult}
                    onChange={this.handleFieldChange}
                    onBillSelected={this.handleBillSelected}
                    isClientSearchVisible={this.props.isClientSearchVisible}
                    onClientSelected={this.handleClientSelected}
                />
            </Segment>
        );
    }

    private handleEdit = () => {
        this.setState({ isEditing: true });
    }

    private handleCancel = () => {
        this.setState({
            orderEdit: this.props.orderEdit || new OrderEdit(),
            validationResult: this.props.validationResult || new ValidationResult(),
            isEditing: false
        });
    }

    private handleSave = () => {
        if (this.props.onUpdate) {
            var validationResult = this.props.onUpdate(this.state.orderEdit);
            this.setState({ validationResult: validationResult });

            if (!validationResult.isValid) {
                return;
            }
        }

        this.setState({
            isEditing: false,
            validationResult: new ValidationResult()
        });
    }

    private handleBillSelected = (bill: Bill | undefined) => {
        this.setState((current) => ({
            ...current,
            orderEdit: new OrderEdit({
                ...current.orderEdit,
                bill: bill,
                billId: bill ? bill.id : undefined,
            })
        }));
    }

    private handleClientSelected = (client: Client | undefined) => {
        this.setState((current) => ({
            ...current,
            orderEdit: new OrderEdit({
                ...current.orderEdit,
                client: client,
                clientId: client ? client.id : undefined
            })
        }));
    }

    private handleFieldChange = (fieldId: string, value: string) => {
        this.setState((current) => ({
            ...current,
            orderEdit: new OrderEdit({
                ...current.orderEdit,
                [fieldId]: value
            })
        }));
    }
}

const intlComponent = injectIntl(OrderSummaryCard);
export { intlComponent as OrderSummaryCard };