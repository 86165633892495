import { ReportingState, initialSubcontractorsBillingFilters } from './types';
import { ActionTypes, ActionTypeKeys } from './actions';
import { Reducer } from 'redux';

export const initialState: ReportingState = {
    subcontractorsBillingFilters: initialSubcontractorsBillingFilters,
    subcontractorsBills: [],
    areSubcontractorsBillsLoading: false
};

const reportingReducer: Reducer<ReportingState> = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case ActionTypeKeys.LOAD_SUBCONTRACTORS_BILLS_REQUEST: {
            return {
                ...state,
                areSubcontractorsBillsLoading: true
            };
        }

        case ActionTypeKeys.LOAD_SUBCONTRACTORS_BILLS_SUCCESS: {
            return {
                ...state,
                subcontractorsBills: action.payload,
                areSubcontractorsBillsLoading: false,
            };
        }

        case ActionTypeKeys.LOAD_SUBCONTRACTORS_BILLS_FAILURE: {
            return {
                ...state,
                areSubcontractorsBillsLoading: false,
            };
        }

        case ActionTypeKeys.SET_SUBCONTRACTORS_BILLING_FILTERS: {
            return {
                ...state,
                subcontractorsBillingFilters: action.payload
            };
        }

        default:
            return state;
    }
};

export { reportingReducer };