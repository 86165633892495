import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Grid, Segment } from 'semantic-ui-react';

import { ApplicationState } from '../../../state/ducks';
import * as UiActions from '../../../state/ducks/ui';
import * as ServiceCallsActions from '../../../state/ducks/service-calls';
import { FinalizeCard, StatisticsCard } from '../../../components/service-calls/close';
import { ServiceCallState } from '../../../state/ducks/service-calls/reducers';
import { LoadingDimmer } from '../../../components/common';
import { ServiceCallStatus } from '../../../state/models';

interface ServiceCallCloseStepActions {
    uiActions: typeof UiActions.actionCreators;
    serviceCallsActions: typeof ServiceCallsActions.actionCreators;
}

interface ServiceCallCloseStepOwnProps {
    serviceCallState: ServiceCallState;
    isServiceCallClosing: boolean;
    isServiceCallReopening: boolean;
    isServiceCallCancelling: boolean;
    isServiceCallLinking: boolean;
}

export type ServiceCallCloseStepProps =
    & ServiceCallCloseStepOwnProps
    & ServiceCallCloseStepActions
    & RouteComponentProps<{ serviceCallId: string }>;

type IntlServiceCallCloseStepProps = ServiceCallCloseStepProps & WrappedComponentProps;

class ServiceCallCloseStep extends React.Component<IntlServiceCallCloseStepProps, {}> {

    public componentDidMount() {
        this.props.uiActions.changeAgentStep('close');
    }

    public render() {
        const isServiceCallLoaded = this.props.serviceCallState != null && this.props.serviceCallState.details != null && !this.props.serviceCallState.isLoading;

        return (
            <Segment basic={true} style={{ padding: 0 }}>
                <LoadingDimmer active={!isServiceCallLoaded} />

                <Grid columns="equal" stackable={true}>
                    <Grid.Column>
                        <FinalizeCard
                            isClosed={isServiceCallLoaded ? this.props.serviceCallState.details.status === ServiceCallStatus.closed : false}
                            canCancel={true}
                            closedOn={isServiceCallLoaded ? this.props.serviceCallState.details.closedOn : undefined}
                            serviceCallId={isServiceCallLoaded ? this.props.serviceCallState.details.id : undefined}
                            onReopenServiceCall={this.reopenServiceCall}
                            onCloseServiceCall={this.closeServiceCall}
                            onCancelServiceCall={this.cancelServiceCall}
                            onLinkNewServiceCall={this.linkNewServiceCall}
                            isServiceCallReopening={this.props.isServiceCallReopening}
                            isServiceCallClosing={this.props.isServiceCallClosing}
                            isServiceCallCancelling={this.props.isServiceCallCancelling}
                            isServiceCallLinking={this.props.isServiceCallLinking}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <StatisticsCard
                            daysOpened={3}
                            costOfCall={380}
                        />
                    </Grid.Column>
                </Grid>
            </Segment>
        );
    }

    private reopenServiceCall = () => {
        const { serviceCallId } = this.props.match.params;
        this.props.serviceCallsActions.reopen(Number(serviceCallId), this.onCloseOrReopen);
    }

    private closeServiceCall = () => {
        const { serviceCallId } = this.props.match.params;
        this.props.serviceCallsActions.close(Number(serviceCallId), this.onCloseOrReopen);
    }

    private cancelServiceCall = () => {
        const { serviceCallId } = this.props.match.params;
        this.props.serviceCallsActions.cancel(
            Number(serviceCallId),
            () => this.props.history.push('/service-calls/current/1'));
    }

    private linkNewServiceCall = () => {
        const { serviceCallId } = this.props.match.params;
        this.props.serviceCallsActions.linkNew(
            Number(serviceCallId),
            (serviceCall) => this.props.history.push(`/service-calls/${serviceCall.id}/details`)
        );
    }

    private onCloseOrReopen = () => {
        this.props.history.push(`/service-calls/current/1`);
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: ServiceCallCloseStepProps): ServiceCallCloseStepOwnProps => {
    const { serviceCallId } = ownProps.match.params;

    return {
        serviceCallState: state.serviceCalls.serviceCalls[Number(serviceCallId)],
        isServiceCallClosing: state.serviceCalls.isServiceCallClosing,
        isServiceCallReopening: state.serviceCalls.isServiceCallReopening,
        isServiceCallCancelling: state.serviceCalls.isServiceCallCancelling,
        isServiceCallLinking: state.serviceCalls.isServiceCallLinking,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ServiceCallCloseStepActions => {
    return {
        uiActions: bindActionCreators(UiActions.actionCreators, dispatch),
        serviceCallsActions: bindActionCreators(ServiceCallsActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(ServiceCallCloseStep);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as ServiceCallCloseStep };