import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { PageHeader } from '../../../components/common';
import { FactoryRepairsTable, FactoryRepairsList } from '../../../components/service-calls/factory';

import { actionCreators as FactoryRepairsActions } from '../../../state/ducks/factory-repairs';
import { ServiceCallFactoryRepair } from '../../../state/models';
import { getCompletedFactoryRepairs, areCompletedFactoryRepairsLoading } from '../../../state/ducks/factory-repairs/selectors';
import { useResponsive } from '../../../utils/responsive';

const m = defineMessages({
    pageTitle: { id: 'FactoryHistoryPage.page_title', defaultMessage: 'History' },
    pageSubtitle: { id: 'FactoryHistoryPage.page_subtitle', defaultMessage: 'View all the completed factory repairs and their related service calls.' },
    noRepairsTitle: { id: 'FactoryHistoryPage.no_repairs_title', defaultMessage: 'No repairs' },
    noRepairsSubtitle: { id: 'FactoryHistoryPage.no_repairs_subtitle', defaultMessage: 'There are no past factory repairs.' }
});

export const FactoryHistoryPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const { isMobile } = useResponsive();
    const dispatch = useDispatch();
    const history = useHistory();

    const factoryRepairs = useSelector(getCompletedFactoryRepairs);
    const isLoading = useSelector(areCompletedFactoryRepairsLoading);

    useEffect(() => {
        dispatch(FactoryRepairsActions.loadCompletedRepairs());
    }, [dispatch])

    const navigateToFactoryPage = (repairs: ServiceCallFactoryRepair) => {
        history.push(`/service-calls/${repairs.serviceCallId}/factory-repairs/${repairs.id}/prepare`);
    }

    return (
        <React.Fragment>
            <PageHeader
                iconName="history"
                title={formatMessage(m.pageTitle)}
                subtitle={formatMessage(m.pageSubtitle)}
            />

            {!isMobile &&
                <FactoryRepairsTable
                    repairs={factoryRepairs}
                    isLoading={isLoading}
                    emptyTitle={formatMessage(m.noRepairsTitle)}
                    emptySubtitle={formatMessage(m.noRepairsSubtitle)}
                    onViewDetails={navigateToFactoryPage}
                    isRequestedHeaderHidden={true}
                    isViewButtonHidden={true}
                />
            }

            {isMobile &&
                <FactoryRepairsList
                    repairs={factoryRepairs}
                    isLoading={isLoading}
                    emptyTitle={formatMessage(m.noRepairsTitle)}
                    emptySubtitle={formatMessage(m.noRepairsSubtitle)}
                    onViewDetails={navigateToFactoryPage}
                />
            }
        </React.Fragment>
    );
};