export interface GroupedDefectiveRatio {
  groupId: number | string;
  groupName: string;
  serviceCallCount: number;
  soldItemCount: number;
  serviceCallPercent: number;
}

export enum DefectiveRatioGroup {
  ByItem,
  ByCover,
  ByProvince,
  ByClient,
  ByBillingMonth,
  ByProductType,
}

export interface GroupedDefectiveItemCount {
  groupId: number | string;
  groupName: string;
  itemCount: number;
}

export enum ServiceCallView {
  ReportedDefectiveItemsByItem,
  ReportedDefectiveItemsByCover,
  ReportedDefectiveItemsByProductType,
  ReportedDefectiveItemsByWeeksSinceShipping,
  ReportedDefectiveItemsByWeeksSinceBilling,
  ReportedDefectiveItemsByDefectType,
  ReportedDefectiveItemsByDefectCategory,
  ReportedDefectiveItemsByDefectCause,
  Problems,
  InspectionReportDescriptions,
  TechnicianReportDescriptions,
  FactoryReportDescriptions,
}

export interface ServiceCallProblem {
  serviceCallId: number;
  problem: string;
}

export interface InspectionReportDescription {
    serviceCallId: number;
    description: string;
}

export interface TechnicianReportDescription {
    serviceCallId: number;
    technicianReportId: number;
    description: string;
}

export interface FactoryReportDescription {
    serviceCallId: number;
    factoryReportId: number;
    description: string;
}
