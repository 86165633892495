import { useState } from "react";
import { SortDirection } from "state/models";

export const useColumnSorting = <RowType>(
  startingSortingColumn: keyof RowType,
  startingSortingDirection: SortDirection
) => {
  const [sortingColumn, setSortingColumn] = useState<keyof RowType>(startingSortingColumn);
  const [sortingDirection, setSortingDirection] = useState(startingSortingDirection);

  const reverseSortingDirection = () => {
    setSortingDirection((previousDirection) =>
      previousDirection === SortDirection.Ascending
        ? SortDirection.Descending
        : SortDirection.Ascending
    );
  };

  const handleColumnSorting = (column: keyof RowType) => {
    if (column === sortingColumn) {
      reverseSortingDirection();
    } else {
      setSortingColumn(column);
      setSortingDirection(SortDirection.Ascending);
    }
  };

  return { sortingColumn, sortingDirection, handleColumnSorting };
};
