import { ApplicationState } from "state/ducks";
import { User, UserAccountType } from "../../state/models";
import { UserSession } from "./types";

export const isAuthenticating = (state: ApplicationState): boolean => state.account.isAuthenticating;
export const isAuthenticated = (state: ApplicationState): boolean => state.account.isAuthenticated;
export const isSessionLoading = (state: ApplicationState): boolean => state.account.isSessionLoading;
export const hasFailedAuthentication = (state: ApplicationState): boolean => state.account.hasFailedAuthentication;
export const getUserSession = (state: ApplicationState): UserSession | undefined => state.account.userSession;
export const getUserRole = (state: ApplicationState): UserAccountType => getUserSession(state)?.user?.accountType || UserAccountType.none;
export const getCurrentUser = (state: ApplicationState): User | undefined => getUserSession(state)?.user;

export const isCurrentUserAnEmployee = (state: ApplicationState): boolean => {
    const currentUserRole = getUserRole(state);

    return currentUserRole !== UserAccountType.none
        && currentUserRole !== UserAccountType.client;
}

export const isCurrentUserAClient = (state: ApplicationState): boolean => getUserRole(state) === UserAccountType.client;

export const isCurrentUserASubcontractor = (state: ApplicationState): boolean => {
    const currentUserRole = getUserRole(state);
    return currentUserRole === UserAccountType.subcontractor;
}

export const getCurrentClientId = (state: ApplicationState): string | undefined => {
    const clientCodes = getUserSession(state)?.claims?.clientCodes;
    if (clientCodes != null && clientCodes.length > 0) {
        return clientCodes[0];
    }

    return undefined;
}