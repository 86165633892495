import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedNumber, FormattedMessage } from 'react-intl';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../../state/ducks';
import { PageHeader, LoadingDimmer, EmptyContentSegment } from '../../../../components/common';
import { SubcontractorsBillingFiltersPopup } from './components/SubcontractorsBillingFiltersPopup';
import { initialSubcontractorsBillingFilters, SubcontractorsBillingFilters } from '../types';
import * as ReportingActions from '../actions';
import { getSubcontractorsBillingFilters, areSubcontractorsBillsLoading, getSubcontractorsBills } from '../selectors';
import { getReportingDateRange, SubcontractorBill, SubcontractorRepair } from '../../../../state/models';
import { Table, Button, Popup, TableHeaderCellProps } from 'semantic-ui-react';
import { commonMessages } from '../../../../constants';
import { NavLink } from 'react-router-dom';
import { nameof } from '../../../../state/utils';

interface DispatchProps {
    reportingActions: typeof ReportingActions.actionCreators;
}

interface StoreProps {
    filters: SubcontractorsBillingFilters;
    bills: SubcontractorBill[];
    isLoading: boolean;
}

interface StateProps {
    sortColumn: string;
    sortDirection: 'ascending' | 'descending';
    data: DataRow[];
}

interface DataRow {
    id: number;
    serviceCallId: number;
    subcontractorName: string;
    billedOn: Date;
    referenceNumber: string;
    totalFees: number;
    currencyCode: string;
    receiptUri: string | undefined;
}

export type SubcontractorsBillingPageProps =
    & StoreProps
    & DispatchProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'SubcontractorsBillingPage.title', defaultMessage: 'Subcontractors billing' },
    subtitle: { id: 'SubcontractorsBillingPage.subtitle', defaultMessage: 'View the bills that were sent by the subcontractors for services rendered.' },
    serviceCallHeader: { id: 'SubcontractorsBillingPage.serviceCallHeader', defaultMessage: 'Service #' },
    subcontractorHeader: { id: 'SubcontractorBillingPage.subcontractorHeader', defaultMessage: 'Subcontractor' },
    billingDateHeader: { id: 'SubcontractorBillingPage.billingDateHeader', defaultMessage: 'Billed on' },
    repairFeesHeader: { id: 'SubcontractorBillingPage.repairFeesHeader', defaultMessage: 'Repair fees: {amount}' },
    shippingFeesHeader: { id: 'SubcontractorBillingPage.shippingFeesHeader', defaultMessage: 'Shipping fees: {amount}' },
    totalFeesHeader: { id: 'SubcontractorBillingPage.totalFeesHeader', defaultMessage: 'Total fees' },
    referenceNumberHeader: { id: 'SubcontractorBillingPage.referenceNumberHeader', defaultMessage: 'Reference #' },
    noDataTitle: { id: 'SubcontractorBillingPage.noDataTitle', defaultMessage: 'No bills' },
    noDataDescription: { id: 'SubcontractorBillingPage.noDataDescription', defaultMessage: 'There are no bills for the filters you selected.' },
    noBill: { id: 'SubcontractorBillingPage.noBill', defaultMessage: 'None' },
});

class SubcontractorsBillingPage extends React.Component<SubcontractorsBillingPageProps, StateProps> {
    public constructor(props: SubcontractorsBillingPageProps) {
        super(props);

        this.state = {
            sortColumn: nameof<SubcontractorBill>('billedOn'),
            sortDirection: 'ascending',
            data: props.bills.map(this.buildDataRow),
        };
    }

    public componentDidMount() {
        const dateRange = getReportingDateRange(this.props.filters.dateFilter);
        this.props.reportingActions.loadSubcontractorsBills(dateRange.startDate, dateRange.endDate, this.props.filters);
    }

    public componentDidUpdate(prevProps: SubcontractorsBillingPageProps) {
        if (prevProps.bills !== this.props.bills) {
            let data = _.chain(this.props.bills).map(this.buildDataRow).sortBy([this.state.sortColumn]).value();
            if (this.state.sortDirection === 'descending') {
                data = data.reverse();
            }

            this.setState({ data });
        }
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const hasData = this.state.data.length > 0 || this.props.isLoading;
        const currencies = _.chain(this.props.bills.map(x => x.currencyCode)).uniq().value();
        const totalPerCurrencies = currencies.map(currency => this.props.bills
            .filter(bill => bill.currencyCode === currency)
            .map(x => ({ amount: x.repairFees + x.shippingFees, currency: x.currencyCode }))
            .reduce((total, current) => ({ amount: total.amount + current.amount, currency: current.currency })));

        return (
            <React.Fragment>
                <PageHeader
                    iconName="dollar sign"
                    title={formatMessage(m.title)}
                    subtitle={formatMessage(m.subtitle)}
                />

                <div style={{ textAlign: 'right', marginBottom: '10px' }}>
                    <SubcontractorsBillingFiltersPopup
                        filters={initialSubcontractorsBillingFilters}
                        onApply={this.applyFilters}
                    />
                </div>

                <LoadingDimmer active={this.props.isLoading} />
                {hasData &&
                    <Table sortable={true} celled={true}>
                        <Table.Header>
                            <Table.Row>
                                {this.renderDataHeader(nameof<DataRow>('serviceCallId'), formatMessage(m.serviceCallHeader))}
                                {this.renderDataHeader(nameof<DataRow>('subcontractorName'), formatMessage(m.subcontractorHeader))}
                                {this.renderDataHeader(nameof<DataRow>('billedOn'), formatMessage(m.billingDateHeader))}
                                {this.renderDataHeader(nameof<DataRow>('referenceNumber'), formatMessage(m.referenceNumberHeader))}
                                {this.renderDataHeader(nameof<DataRow>('totalFees'), formatMessage(m.totalFeesHeader))}
                                <Table.HeaderCell collapsing={true} />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.data.map(this.renderDataRow)}
                        </Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan={5} textAlign="right">
                                    {totalPerCurrencies.map(x => (
                                        <p key={x.currency}>
                                            <FormattedNumber value={x.amount} style="currency" currency={x.currency} />
                                        </p>
                                    ))}
                                </Table.HeaderCell>
                                <Table.HeaderCell />
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                }
                {!hasData &&
                    <EmptyContentSegment title={formatMessage(m.noDataTitle)} description={formatMessage(m.noDataDescription)} />
                }
            </React.Fragment>
        );
    }

    private buildDataRow = (bill: SubcontractorBill): DataRow => {
        const { formatMessage } = this.props.intl;
        const subcontractorName = bill.subcontractorRepair.subcontractor ? bill.subcontractorRepair.subcontractor.name : bill.subcontractorRepair.otherSubcontractorName || formatMessage(commonMessages.unknown);
        const totalFees = bill.repairFees + bill.shippingFees;

        return {
            id: bill.id,
            serviceCallId: bill.subcontractorRepair.serviceCallId,
            subcontractorName: subcontractorName,
            billedOn: bill.billedOn,
            referenceNumber: bill.referenceNumber,
            totalFees: totalFees,
            currencyCode: bill.currencyCode,
            receiptUri: bill.receiptUri ? `/api/service-calls/${bill.subcontractorRepair.serviceCallId}/attachments/subcontractor-repair/${bill.subcontractorRepairId}` : undefined
        };
    }

    private renderDataHeader = (propertyName: string, title: string, otherProps: TableHeaderCellProps | undefined = undefined): JSX.Element => {
        return (
            <Table.HeaderCell
                sorted={this.state.sortColumn === propertyName ? this.state.sortDirection : undefined}
                onClick={() => this.sortData(propertyName)}
                {...otherProps}
            >
                {title}
            </Table.HeaderCell>
        );
    }

    private renderDataRow = (row: DataRow): JSX.Element => {
        const { formatMessage } = this.props.intl;

        return (
            <Table.Row key={row.id}>
                <Table.Cell>
                    <NavLink to={`/service-calls/${row.serviceCallId}/details`}>{row.serviceCallId}</NavLink>
                </Table.Cell>
                <Table.Cell>{row.subcontractorName}</Table.Cell>
                <Table.Cell>{moment.utc(row.billedOn).local().format('LL')}</Table.Cell>
                <Table.Cell>{row.referenceNumber}</Table.Cell>
                <Table.Cell textAlign="right">
                    <FormattedNumber value={row.totalFees} style="currency" currency={row.currencyCode} />
                </Table.Cell>
                <Table.Cell>
                    {row.receiptUri && <Button as="a" target="_blank" href={row.receiptUri} fluid={true} content={formatMessage(commonMessages.view)} />}
                    {!row.receiptUri && <Button fluid={true} disabled={true} content={formatMessage(m.noBill)} />}
                </Table.Cell>
            </Table.Row>
        );
    }

    private sortData = (columnName: string): void => {
        if (columnName !== this.state.sortColumn) {
            this.setState((current) => ({
                ...current,
                sortColumn: columnName,
                sortDirection: 'ascending',
                data: _.sortBy(current.data, [columnName])
            }));
        } else {
            this.setState((current) => ({
                ...current,
                data: current.data.reverse(),
                sortDirection: current.sortDirection === 'ascending' ? 'descending' : 'ascending'
            }));
        }
    }

    private applyFilters = (filters: SubcontractorsBillingFilters): void => {
        this.props.reportingActions.setFilters(filters);

        const dateRange = getReportingDateRange(filters.dateFilter);
        this.props.reportingActions.loadSubcontractorsBills(dateRange.startDate, dateRange.endDate, filters);
    }
}

const mapStateToProps = (state: ApplicationState): StoreProps => {
    return {
        filters: getSubcontractorsBillingFilters(state),
        bills: getSubcontractorsBills(state),
        isLoading: areSubcontractorsBillsLoading(state)
    };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    return {
        reportingActions: bindActionCreators(ReportingActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(SubcontractorsBillingPage);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as SubcontractorsBillingPage };