import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Grid } from 'semantic-ui-react';

import { Media } from '../../../state/models';
import { MediaGallery, EmptyContentSegment, UploadFileControl } from '../../common';

interface MediaAttachmentsOwnProps {
    medias: Media[];
    canUpload: boolean;
    isUploading: boolean;

    onDeleteMedia?: (mediaId: number) => void;
    onUploadMedia?: (medias: FileList) => void;
}

export type MediaAttachmentsProps =
    & MediaAttachmentsOwnProps
    & WrappedComponentProps;

const ONE_KILOBYTE = 1000;
const ONE_MEGABYTE = 1000 * ONE_KILOBYTE;

const m = defineMessages({
    emptyImageTitle: { id: 'ProblemDescriptionCard.empty_image_title', defaultMessage: 'No images or videos' },
    emptyImageDescription: { id: 'ProblemDescriptionCard.empty_image_description', defaultMessage: 'There are no images or videos associated to this service call.' },
});

class MediaAttachments extends React.Component<MediaAttachmentsProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { medias } = this.props;

        return (
            <Grid>
                <Grid.Column mobile={16} tablet={8} computer={10}>
                    {medias.length > 0 &&
                        <MediaGallery 
                            canEdit={this.props.canUpload}
                            medias={medias} 
                            mediaSize="small" 
                            onDeleteMedia={this.props.onDeleteMedia}
                        />
                    }
                    {medias.length <= 0 &&
                        <EmptyContentSegment 
                            title={formatMessage(m.emptyImageTitle)} 
                            description={formatMessage(m.emptyImageDescription)}
                            basic={true} 
                        />
                    }
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={6}>
                    {this.props.canUpload && this.props.onUploadMedia &&
                        <UploadFileControl 
                            onFilesUpload={this.props.onUploadMedia} 
                            acceptedFileTypes="image/*, video/*"
                            maximumFileSizeInBytes={100 * ONE_MEGABYTE}
                            isUploading={this.props.isUploading} 
                        />
                    }
                </Grid.Column>
            </Grid>
        );
    }
}

const connectedComponent = injectIntl(MediaAttachments);
export { connectedComponent as MediaAttachments };