import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Form, List, Dropdown, DropdownItemProps, InputOnChangeData, DropdownProps } from 'semantic-ui-react';
import { SmallDeleteButton, MultipleEmailsDropdown, TooltipIcon } from '../../../../../components/common';
import { SubcontractorEdit } from '../models/SubcontractorEdit';
import { PreferredLanguage, ValidationResult } from '../../../../../state/models';
import { nameof } from '../../../../../state/utils';
import { LanguageDropdown } from '../../../../../components/common/LanguageDropdown';

interface OwnProps {
    subcontractor: SubcontractorEdit;
    validationResult: ValidationResult | undefined;
    // tslint:disable-next-line: no-any
    onChange: (fieldId: string, fieldValue: any) => void;
}

export type SubcontractorFormProps =
    & OwnProps
    & WrappedComponentProps;

const m = defineMessages({
    nameLabel: { id: 'SubcontractorForm.nameLabel', defaultMessage: 'Name' },
    regionLabel: { id: 'SubcontractorForm.regionLabel', defaultMessage: 'Region' },
    emailsLabel: { id: 'SubcontractorForm.emailsLabel', defaultMessage: 'Emails' },
    preferredLanguageLabel: { id: 'SubcontractorForm.preferredLanguageLabel', defaultMessage: 'Preferred language' },
    preferredLanguageTooltip: { id: 'SubcontractorForm.preferredLanguageTooltip', defaultMessage: 'The language that will be used when sending automated emails to the subcontractor.' }
});

class SubcontractorForm extends React.Component<SubcontractorFormProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const validationResult = this.props.validationResult || ValidationResult.empty();
        const subcontractorEmails = this.props.subcontractor.emails.map((x): DropdownItemProps => ({
            key: x,
            text: x,
            value: x,
            active: true,
        }));

        return (
            <Form error={!validationResult.isValid} style={{ maxWidth: '450px' }}>
                <Form.Group widths="equal">
                    <Form.Input
                        required
                        id={nameof<SubcontractorEdit>('name')}
                        error={validationResult.isFieldInvalid(nameof<SubcontractorEdit>('name'))}
                        label={formatMessage(m.nameLabel)}
                        value={this.props.subcontractor.name}
                        onChange={this.handleInputChange}
                    />
                    <Form.Input
                        id={nameof<SubcontractorEdit>('region')}
                        error={validationResult.isFieldInvalid(nameof<SubcontractorEdit>('region'))}
                        label={formatMessage(m.regionLabel)}
                        value={this.props.subcontractor.region}
                        onChange={this.handleInputChange}
                    />
                </Form.Group>
                <Form.Field>
                    <label>{formatMessage(m.emailsLabel)}</label>
                    <MultipleEmailsDropdown
                        id={nameof<SubcontractorEdit>('emails')}
                        error={validationResult.isFieldInvalid(nameof<SubcontractorEdit>('emails'))}
                        options={subcontractorEmails}
                        value={this.props.subcontractor.emails}
                        onAddEmail={this.handleAddEmail}
                        onRemoveEmail={this.handleRemoveEmail}
                    />
                </Form.Field>
                <Form.Field>
                    <label>
                        {formatMessage(m.preferredLanguageLabel)}
                        <TooltipIcon content={formatMessage(m.preferredLanguageTooltip)} />
                    </label>
                    <LanguageDropdown
                        fluid={true}
                        selection={true}
                        value={this.props.subcontractor.preferredLanguage || PreferredLanguage.french}
                        onChange={this.handleLanguageChange}
                    />
                </Form.Field>
            </Form>
        );
    }

    private handleAddEmail = (email: string) => {
        this.props.onChange(nameof<SubcontractorEdit>('emails'), [...this.props.subcontractor.emails, email]);
    }

    private handleRemoveEmail = (email: string) => {
        this.props.onChange(nameof<SubcontractorEdit>('emails'), this.props.subcontractor.emails.filter(x => x !== email));
    }

    private handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        this.props.onChange(event.currentTarget.id, data.value);
    }

    private handleLanguageChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        this.props.onChange(nameof<SubcontractorEdit>('preferredLanguage'), data.value as string);
    }
}

const intlComponent = injectIntl(SubcontractorForm);
export { intlComponent as SubcontractorForm };