
import { NavigationItem } from "../../components/layouts/PageLayout/NavigationMenu";
import { afterSalesMenuSection, dashboardMenuItem, representativeAppointments, representativeCalendar, representativeHistory, representativeInspections } from "./common";

export const representativeNavigationItems = (): NavigationItem[] => [
    dashboardMenuItem(),
    {
        ...afterSalesMenuSection(),
        children: [
            representativeInspections(),
            representativeAppointments(),
            representativeCalendar(),
            representativeHistory(),
        ]
    },
];