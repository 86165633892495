import { defineMessages } from "@formatjs/intl";
import React from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useIntl } from "react-intl";
import { Form, FormProps, Input } from "semantic-ui-react";
import { CountriesDropdown, ProvincesDropdown } from "../../../../components/common";
import { ServiceCallEdit } from "../../models";

interface OwnProps {
}

export type CustomerInformationFormProps =
    & FormProps
    & OwnProps;

const m = defineMessages({
    nameLabel: { id: 'CustomerInformationForm.name', defaultMessage: 'Customer name' },
    emailLabel: { id: 'CustomerInformationForm.emailLabel', defaultMessage: 'Customer email' },
    emailPlaceholder: { id: 'CustomerInformationForm.emailPlaceholder', defaultMessage: 'Customer email' },
    addressLabel: { id: 'CustomerInformationForm.address', defaultMessage: 'Address' },
    address1Placeholder: { id: 'CustomerInformationForm.address1_placeholder', defaultMessage: 'Street address' },
    address2Placeholder: { id: 'CustomerInformationForm.address2_placeholder', defaultMessage: 'Apartment, suite, unit, etc.' },
    cityPlaceholder: { id: 'CustomerInformationForm.city_placeholder', defaultMessage: 'City' },
    postalCodePlaceholder: { id: 'CustomerInformationForm.postal_code_placeholder', defaultMessage: 'Zip/Postal code' },
    countryPlaceholder: { id: 'CustomerInformationForm.country_placeholder', defaultMessage: 'Country' },
    provincePlaceholder: { id: 'CustomerInformationForm.province_placeholder', defaultMessage: 'State/Province' },
    mainPhoneNumberLabel: { id: 'CustomerInformationForm.main_phone_number', defaultMessage: 'Main phone number' },
    otherPhoneNumberLabel: { id: 'CustomerInformationForm.other_phone_number', defaultMessage: 'Other phone number' },
});

export const CustomerInformationForm: React.FC<CustomerInformationFormProps> = (props) => {
    const { formatMessage } = useIntl();
    const { control, formState: { errors }, setValue } = useFormContext<ServiceCallEdit>();
    const selectedCountryCode = useWatch({ control, name: 'customerAddress.countryCode' });
    const hasProvincesDefined = ['ca', 'us', 'au'].find(countryCode => countryCode === selectedCountryCode) != null;

    return (
        <Form {...props}>
            <Controller
                name="customerName"
                control={control}
                render={({ field }) => (
                    <Form.Input
                        label={formatMessage(m.nameLabel)}
                        error={errors.customerName != null}
                        value={field.value || ''}
                        onChange={(e, data) => setValue('customerName', data.value)}
                    />
                )}
            />

            <Controller
                name="customerAddress.address1"
                control={control}
                render={({ field }) => (
                    <Form.Input
                        label={formatMessage(m.addressLabel)}
                        error={errors.customerAddress?.address1 != null}
                        placeholder={formatMessage(m.address1Placeholder)}
                        value={field.value || ''}
                        onChange={(e, data) => setValue('customerAddress.address1', data.value)}
                    />
                )}
            />

            <Controller
                name="customerAddress.address2"
                control={control}
                render={({ field }) => (
                    <Form.Input
                        error={errors.customerAddress?.address2 != null}
                        placeholder={formatMessage(m.address2Placeholder)}
                        value={field.value || ''}
                        onChange={(e, data) => setValue('customerAddress.address2', data.value)}
                    />
                )}
            />

            <Form.Group widths="equal">
                <Controller
                    name="customerAddress.city"
                    control={control}
                    render={({ field }) => (
                        <Form.Input
                            error={errors.customerAddress?.city != null}
                            placeholder={formatMessage(m.cityPlaceholder)}
                            value={field.value || ''}
                            onChange={(e, data) => setValue('customerAddress.city', data.value)}
                        />
                    )}
                />

                <Controller
                    name="customerAddress.postalCode"
                    control={control}
                    render={({ field }) => (
                        <Form.Input
                            error={errors.customerAddress?.postalCode != null}
                            placeholder={formatMessage(m.postalCodePlaceholder)}
                            value={field.value || ''}
                            onChange={(e, data) => setValue('customerAddress.postalCode', data.value)}
                        />
                    )}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <Controller
                    name="customerAddress.countryCode"
                    control={control}
                    render={({ field }) => (
                        <Form.Field>
                            <CountriesDropdown
                                clearable
                                error={errors.customerAddress?.countryCode != null}
                                placeholder={formatMessage(m.countryPlaceholder)}
                                value={field.value || ''}
                                onChange={(e, data) => setValue('customerAddress.countryCode', data.value as string || undefined)}
                            />
                        </Form.Field>
                    )}
                />

                <Controller
                    name="customerAddress.provinceCode"
                    control={control}
                    render={({ field }) => (
                        <Form.Field disabled={selectedCountryCode == null || selectedCountryCode === ''}>
                            {hasProvincesDefined &&
                                <ProvincesDropdown
                                    clearable
                                    countryCode={selectedCountryCode}
                                    placeholder={formatMessage(m.provincePlaceholder)}
                                    value={field.value}
                                    onChange={(e, data) => setValue('customerAddress.provinceCode', data.value as string || undefined)}
                                />
                            }
                            {!hasProvincesDefined &&
                                <Input
                                    placeholder={formatMessage(m.provincePlaceholder)}
                                    value={field.value || ''}
                                    onChange={(e, data) => setValue('customerAddress.provinceCode', data.value || undefined)}
                                />
                            }
                        </Form.Field>
                    )}
                />
            </Form.Group>

            <Controller
                name="customerEmail"
                control={control}
                render={({ field }) => (
                    <Form.Input
                        label={formatMessage(m.emailLabel)}
                        error={errors.customerEmail != null}
                        placeholder={formatMessage(m.emailPlaceholder)}
                        value={field.value || ''}
                        onChange={(e, data) => setValue('customerEmail', data.value)}
                    />
                )}
            />

            <Form.Group widths="equal">
                <Controller
                    name="customerMainPhoneNumber"
                    control={control}
                    render={({ field }) => (
                        <Form.Input
                            label={formatMessage(m.mainPhoneNumberLabel)}
                            error={errors.customerMainPhoneNumber != null}
                            placeholder={formatMessage(m.mainPhoneNumberLabel)}
                            value={field.value || ''}
                            onChange={(e, data) => setValue('customerMainPhoneNumber', data.value)}
                        />
                    )}
                />

                <Controller
                    name="customerOtherPhoneNumber"
                    control={control}
                    render={({ field }) => (
                        <Form.Input
                            label={formatMessage(m.otherPhoneNumberLabel)}
                            error={errors.customerOtherPhoneNumber != null}
                            placeholder={formatMessage(m.otherPhoneNumberLabel)}
                            value={field.value || ''}
                            onChange={(e, data) => setValue('customerOtherPhoneNumber', data.value)}
                        />
                    )}
                />
            </Form.Group>
        </Form>
    );
};