import { MediaGallery } from 'components/common';
import { useFormContext } from 'react-hook-form';
import { Media, MediaType } from 'state/models';
import { UploadMediaFormValues } from './UploadMediaModal';

export const MediaUploadPreview = () => {
    const { watch, setValue } = useFormContext<UploadMediaFormValues>();

    const formMedias = watch('medias') || [];

    const medias = formMedias.map(
        (x, index): Media => ({
            id: index,
            type: MediaType.image,
            createdOn: new Date(),
            uri: URL.createObjectURL(x),
        })
    );

    const deleteMedia = (mediaId: number) => {
        setValue(
            'medias',
            formMedias.filter((_, index) => index !== mediaId)
        );
    };

    return <MediaGallery medias={medias} canEdit onDeleteMedia={deleteMedia} />;
};
