import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import { actionCreators as ServiceCallsActions } from '../../state/ducks/service-calls/actions';
import { PageHeader } from '../../components/common';
import { ServiceCall, SortDirection } from '../../state/models';
import { ServiceCallTable, ServiceCallList } from '../../components/service-calls';
import { isCurrentUserAgent } from '../../state/ducks/current-user';
import { getStaleServiceCalls, areStaleServiceCallsLoading, getStaleServiceCallsSorting } from '../../state/ducks/service-calls';
import { useResponsive } from '../../utils/responsive';

const m = defineMessages({
    title: { id: 'StaleServiceCallsPage.title', defaultMessage: 'Stale service calls' },
    subtitle: { id: 'StaleServiceCallsPage.subtitle', defaultMessage: 'View the service calls that haven\'t had any activity in the past 30 days.' },
});

interface RouteProps {
    page?: string;
}

export const StaleServiceCallsPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const { isMobile } = useResponsive();
    const { page } = useParams<RouteProps>();
    const currentPage = page != null ? Number(page) : undefined;
    const history = useHistory();
    const dispatch = useDispatch();

    const isAgent = useSelector(isCurrentUserAgent);
    const isLoading = useSelector(areStaleServiceCallsLoading);
    const serviceCalls = useSelector(getStaleServiceCalls);
    const sortings = useSelector(getStaleServiceCallsSorting);

    useEffect(() => {
        dispatch(ServiceCallsActions.loadStaleServiceCalls(currentPage, sortings))
    }, [dispatch, currentPage, sortings]);

    const navigateToDetailsPage = (serviceCall: ServiceCall) => history.push(`/service-calls/${serviceCall.id}/details`);
    const navigateToPageNumber = (page: number) => history.push(`/service-calls/stale/${page}`);
    const navigateToFirstPage = () => navigateToPageNumber(1);

    const applySorting = (sortBy: string, direction: SortDirection) => {
        dispatch(ServiceCallsActions.applySortingOnStale(sortBy, direction));

        if (currentPage !== 1) {
            navigateToFirstPage();
        }
    }

    const serviceCallsProps = {
        serviceCalls: serviceCalls,
        sortings: sortings,
        isLoading: isLoading,
        onViewDetails: navigateToDetailsPage,
        onPageChange: navigateToPageNumber,
        onSortData: applySorting
    };

    return (
        <React.Fragment>
            <PageHeader
                iconName="archive"
                title={formatMessage(m.title)}
                subtitle={formatMessage(m.subtitle)}
            />

            {isMobile && <ServiceCallList {...serviceCallsProps} />}
            {!isMobile && <ServiceCallTable showClient={isAgent} {...serviceCallsProps} />}
        </React.Fragment>
    );
};