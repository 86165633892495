export enum ActionTypeKeys {
    LOAD_BY_ORDER_REQUEST = 'PRODUCTION_ORDER_LOAD_BY_ORDER_REQUEST',
    LOAD_BY_ORDER_SUCCESS = 'PRODUCTION_ORDER_LOAD_BY_ORDER_SUCCESS',
    LOAD_BY_ORDER_FAILURE = 'PRODUCTION_ORDER_LOAD_BY_ORDER_FAILURE',

    LOAD_BY_SERVICE_CALL_REQUEST = 'PRODUCTION_ORDER_LOAD_BY_SERVICE_CALL_REQUEST',
    LOAD_BY_SERVICE_CALL_SUCCESS = 'PRODUCTION_ORDER_LOAD_BY_SERVICE_CALL_SUCCESS',
    LOAD_BY_SERVICE_CALL_FAILURE = 'PRODUCTION_ORDER_LOAD_BY_SERVICE_CALL_FAILURE',

    EXPAND_CHILDREN = 'PRODUCTION_COMPONENT_EXPAND_CHILDREN',
    COLLAPSE_CHILDREN = 'PRODCTION_COMPONENT_COLLAPSE_CHILDREN',

    SELECT_COMPONENT = 'PRODUCTION_COMPONENT_SELECT',
    DESELECT_COMPONENT = 'PRODUCTION_COMPONENT_DESELECT',
    CHANGE_COMPONENT_QUANTITY = 'PRODUCTION_COMPONENT_CHANQUE_QUANTITY'
}