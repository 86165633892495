import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { defineMessages, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Form, FormProps, TextArea } from "semantic-ui-react";
import { MediaGallery, SectionHeader, UploadFileControl } from "../../../../components/common";
import { Media, MediaType } from "../../../../state/models";
import { isCurrentUserAnEmployee } from "../../../account/selectors";
import { ServiceCallEdit } from "../../models";

interface OwnProps {
}

export type ProblemInformationFormProps =
    & FormProps
    & OwnProps;

const m = defineMessages({
    problemDescription: { id: 'ProblemInformationForm.problem_description', defaultMessage: 'Problem description' },
    problemDescriptionPlaceholder: { id: 'ProblemInformationForm.problem_description_placeholder', defaultMessage: 'Describe the problem in a few words' },
    problemDescriptionRequired: { id: 'ProblemEdit.description_required', defaultMessage: 'The problem description is required.' },
    picturesSectionTitle: { id: 'ProblemInformationForm.pictures_section_title', defaultMessage: 'Pictures of the affected parts' },
    picturesSectionSubtitle: { id: 'ProblemInformationForm.pictures_section_subtitle', defaultMessage: 'Take and upload pictures of the affected parts of your product to help our customer service identify the problem.' },
    attachmentRequired: { id: 'ProblemEdit.attachment_required', defaultMessage: 'Please upload a picture of the furniture and the room where it is located to help our customer service agents diagnose the problem.' }
});

export const ProblemInformationForm: React.FC<ProblemInformationFormProps> = (props) => {
    const { formatMessage } = useIntl();
    const { control, formState: { errors }, setValue } = useFormContext<ServiceCallEdit>();
    const isJaymarEmployee = useSelector(isCurrentUserAnEmployee);

    const attachments = useWatch({ control, name: 'attachments', defaultValue: [] });
    const medias = useMemo(() => attachments.map((x, index): Media => ({
            id: index,
            type: MediaType.image,
            createdOn: new Date(),
            uri: URL.createObjectURL(x)
    })), [attachments]);

    const deleteMedia = (mediaId: number) => {
        const updatedAttachments = _.clone(attachments);
        updatedAttachments.splice(mediaId, 1);
        setValue('attachments', updatedAttachments);
    }

    return (
        <Form {...props}>
            <Controller
                name="problemDescription"
                control={control}
                rules={{ required: formatMessage(m.problemDescriptionRequired) }}
                render={({ field }) => (
                    <Form.Field required error={errors.problemDescription != null}>
                        <label>{formatMessage(m.problemDescription)}</label>
                        <TextArea
                            placeholder={formatMessage(m.problemDescriptionPlaceholder)}
                            value={field.value}
                            onChange={(e, data) => setValue('problemDescription', data.value as string || '')}
                        />
                    </Form.Field>
                )}
            />

            <SectionHeader
                title={formatMessage(m.picturesSectionTitle)}
                subtitle={formatMessage(m.picturesSectionSubtitle)}
            />

            <Controller
                name="attachments"
                control={control}
                rules={{ required: !isJaymarEmployee ? formatMessage(m.attachmentRequired) : undefined }}
                render={({ field }) => (
                    <>
                        <div style={{ maxWidth: '400px' }}>
                            <UploadFileControl
                                required={!isJaymarEmployee}
                                error={errors.attachments != null}
                                onFilesUpload={files => setValue('attachments', [...field.value || [], ...Array.from(files)])}
                                acceptedFileTypes="image/*, video/*"
                                isUploading={false} 
                            />

                            <MediaGallery
                                medias={medias}
                                canEdit
                                onDeleteMedia={deleteMedia}
                            />
                        </div>
                    </>
                )}
            />
        </Form>
    );
}; 