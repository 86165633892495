export enum ActionTypeKeys {
    BILLS_SEARCH_REQUEST = 'BILLS_SEARCH_REQUEST',
    BILLS_SEARCH_SUCCESS = 'BILLS_SEARCH_SUCCESS',
    BILLS_SEARCH_FAILURE = 'BILLS_SEACRH_FAILURE',

    CLIENTS_SEARCH_REQUEST = 'CLIENTS_SEARCH_REQUEST',
    CLIENTS_SEARCH_SUCCESS = 'CLIENTS_SEARCH_SUCCESS',
    CLIENTS_SEARCH_FAILURE = 'CLIENTS_SEACRH_FAILURE',

    ITEMS_SEARCH_REQUEST = 'ITEMS_SEARCH_REQUEST',
    ITEMS_SEARCH_SUCCESS = 'ITEMS_SEARCH_SUCCESS',
    ITEMS_SEARCH_FAILURE = 'ITEMS_SEACRH_FAILURE',

    SERVICE_CALLS_SEARCH_REQUEST = 'SERVICE_CALLS_SEARCH_REQUEST',
    SERVICE_CALLS_SEARCH_SUCCESS = 'SERVICE_CALLS_SEARCH_SUCCESS',
    SERVICE_CALLS_SEARCH_FAILURE = 'SERVICE_CALLS_SEARCH_FAILURE',
}