import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedNumber } from 'react-intl';
import { List, Table, Icon, TableRowProps } from 'semantic-ui-react';
import { OrderDetail } from '../../../../state/models/OrderDetail';
import { OrderDetailIcon } from './OrderDetailIcon';
import { formatQuantity, formatItemId, ProductionOrder } from '../../../../state/models';
import './OrderRow.css';

interface OwnProps {
    currency: string;
    orderDetail: OrderDetail;
    className?: string;
}

export type OrderRowProps =
    & OwnProps
    & TableRowProps
    & WrappedComponentProps;

const m = defineMessages({
});

class OrderRow extends React.Component<OrderRowProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { intl, currency, orderDetail, className, ...rowProps } = this.props;

        return (
            <Table.Row className={`c-order-row ${className}`} {...rowProps}>
                <Table.Cell textAlign="center">
                    <OrderDetailIcon size="large" type={orderDetail.type} />
                </Table.Cell>
                <Table.Cell>{formatItemId(orderDetail.itemId)}</Table.Cell>
                <Table.Cell>{formatItemId(orderDetail.itemCoverId)}</Table.Cell>
                <Table.Cell title={orderDetail.notes}>{orderDetail.notes}</Table.Cell>
                <Table.Cell textAlign="right">{formatQuantity(orderDetail.quantityOrdered)}</Table.Cell>
                <Table.Cell textAlign="right">{orderDetail.packageCount}</Table.Cell>
                <Table.Cell textAlign="right"><FormattedNumber value={orderDetail.quantityOrdered.value * orderDetail.price} style="currency" currency={this.props.currency} /></Table.Cell>
            </Table.Row>
        );
    }
}

const intlComponent = injectIntl(OrderRow);
export { intlComponent as OrderRow };