import moment from 'moment';
import { AppointmentTimeRange } from '../state/models';
import { timeRangeMessages } from '../constants';
import {  MessageDescriptor } from 'react-intl';

export class DateHelper {
    public static getDateOfISOWeek(week: number, year: number) {
        const firstDateOfWeek = new Date(year, 0, 1 + (week - 1) * 7);
        const dayOfWeek = firstDateOfWeek.getDay();
        let startOfWeekIso = firstDateOfWeek;

        if (dayOfWeek <= 4) {
            startOfWeekIso.setDate(firstDateOfWeek.getDate() - firstDateOfWeek.getDay() + 1);
        } else {
            startOfWeekIso.setDate(firstDateOfWeek.getDate() + 8 - firstDateOfWeek.getDay());
        }

        return startOfWeekIso;
    }

    public static getTimeRangeDescription(timeRange: AppointmentTimeRange): MessageDescriptor {
        switch (timeRange) {
            case AppointmentTimeRange.morning:
                return timeRangeMessages.morning;

            case AppointmentTimeRange.afternoon:
                return timeRangeMessages.afternoon;

            default:
                return timeRangeMessages.allDay;
        }
    }

    public static getTimeRangeAbbreviation(timeRange: AppointmentTimeRange): MessageDescriptor {
        switch (timeRange) {
            case AppointmentTimeRange.morning:
                return timeRangeMessages.am;

            case AppointmentTimeRange.afternoon:
                return timeRangeMessages.pm;

            default:
                return timeRangeMessages.notApplicable;
        }
    }

    public static getTimeDescription(hours: number, minutes: number): string {
        const formattedHours = hours < 10 ? '0' + hours : hours;
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

        return `${formattedHours}h${formattedMinutes}`;
    }

    public static sortAscending(a: Date, b: Date): number {
        const aMoment = moment(a);
        const bMoment = moment(b);

        if (aMoment.isBefore(bMoment)) {
            return -1;
        }

        if (aMoment.isAfter(bMoment)) {
            return 1;
        }

        return 0;
    }

    public static sortDescending(a: Date, b: Date): number {
        const aMoment = moment(a);
        const bMoment = moment(b);

        if (aMoment.isBefore(bMoment)) {
            return 1;
        }

        if (aMoment.isAfter(bMoment)) {
            return -1;
        }

        return 0;
    }

    public static isWeekday(date: Date): boolean {
        const dateMoment = moment(date);
        const weekday = dateMoment.isoWeekday();
        return weekday !== 6 && weekday !== 7;
    }
}