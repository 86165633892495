import { initialDateFilter, DateFilter, isInitialDateFilter, DateFilterType } from './DateFilter';
import { RegionFilter, initialRegionFilter, isInitialRegionFilter } from './RegionFilter';
import { ClientFilter, initialClientFilter, isInitialClientFilter } from './ClientFilter';

export interface TrendsFilters {
    dateFilter: DateFilter;
    regionFilter: RegionFilter;
    clientFilter: ClientFilter;
}

export const initialTrendsFilter: TrendsFilters = {
    dateFilter: {...initialDateFilter, type: DateFilterType.thisYear},
    regionFilter: initialRegionFilter,
    clientFilter: initialClientFilter
};

export function isInitialTrendsFilter(filter: TrendsFilters) {
    return filter.dateFilter.type === DateFilterType.thisYear
        && isInitialRegionFilter(filter.regionFilter)
        && isInitialClientFilter(filter.clientFilter);
}