import { ActionTypeKeys } from './types';
import { ThunkAction } from 'redux-thunk';
import { ApplicationState } from '..';
import * as globalNotification from '../global-notification';
import { CalendarService } from '../../../services';
import { CalendarBreak } from '../../models';
import { AppThunk } from '../../store';

export type ActionTypes =
    | GetLocksRequest
    | GetLocksSuccess
    | GetLocksFailure
    | LockRequest
    | LockSuccess
    | LockFailure
    | UnlockRequest
    | UnlockSuccess
    | UnlockFailure
    | SetCalendarStartDate
    | { type: '' };

export interface GetLocksRequest { type: ActionTypeKeys.LOAD_LOCKS_REQUEST; }
export interface GetLocksSuccess { type: ActionTypeKeys.LOAD_LOCKS_SUCCESS; payload: CalendarBreak[]; }
export interface GetLocksFailure { type: ActionTypeKeys.LOAD_LOCKS_FAILURE; payload: string; }

export interface LockRequest { type: ActionTypeKeys.LOCK_REQUEST; payload: Date; }
export interface LockSuccess { type: ActionTypeKeys.LOCK_SUCCESS; payload: { forDate: Date, calendarBreak: CalendarBreak }; }
export interface LockFailure { type: ActionTypeKeys.LOCK_FAILURE; payload: { forDate: Date, error: string }; }

export interface UnlockRequest { type: ActionTypeKeys.UNLOCK_REQUEST; payload: Date; }
export interface UnlockSuccess { type: ActionTypeKeys.UNLOCK_SUCCESS; payload: Date; }
export interface UnlockFailure { type: ActionTypeKeys.UNLOCK_FAILURE; payload: { forDate: Date, error: string }; }

export interface SetCalendarStartDate { type: ActionTypeKeys.SET_CALENDAR_START_DATE; payload: Date; }

const service = new CalendarService();

export const actionCreators = {
    loadLocks: (startDate: Date, endDate: Date, forUserId?: string): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_LOCKS_REQUEST });

            service.getLocks(startDate, endDate, forUserId)
                .then(breaks => {
                    dispatch({ type: ActionTypeKeys.LOAD_LOCKS_SUCCESS, payload: breaks });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_LOCKS_FAILURE });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de récupérer l'ensemble des plages de non disponibilité du calendrier (${error}).`)
                    );
                });
        };
    },

    lock: (forDate: Date, forUserId: string | undefined, reason: string): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOCK_REQUEST, payload: forDate });

            service.lock(forDate, forUserId, reason)
                .then(calendarBreak => {
                    dispatch({ type: ActionTypeKeys.LOCK_SUCCESS, payload: { forDate: forDate, calendarBreak: calendarBreak } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOCK_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de verrouiller une plage horaire dans le calendrier (${error}).`)
                    );
                });
        };
    },

    unlock: (forDate: Date): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.UNLOCK_REQUEST, payload: forDate });

            service.unlock(forDate)
                .then(() => {
                    dispatch({ type: ActionTypeKeys.UNLOCK_SUCCESS, payload: forDate });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.UNLOCK_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de déverrouiller une plage horaire dans le calendrier (${error}).`)
                    );
                });
        };
    },

    setCalendarStartDate: (startDate: Date) => {
        return {
            type: ActionTypeKeys.SET_CALENDAR_START_DATE,
            payload: startDate
        };
    }
};