import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Table } from "semantic-ui-react";
import { SortDirection } from "state/models";
import { DefectiveItemsTableRow } from "./DefectiveItemsTable";

const m = defineMessages({
  itemCount: {
    id: "DefectiveItemsTable.Headers.ItemCount",
    defaultMessage: "Count",
  },
  percent: {
    id: "DefectiveItemsTable.Headers.Percent",
    defaultMessage: "%",
  },
});

interface TableHeadersProps {
  groupType: string;
  sortingColumn: keyof DefectiveItemsTableRow;
  sortingDirection: SortDirection;
  onColumnSorting: (columnName: keyof DefectiveItemsTableRow) => void;
}

export const TableHeaders: React.FC<TableHeadersProps> = ({
  groupType,
  sortingColumn,
  sortingDirection,
  onColumnSorting,
}) => {
  const { formatMessage } = useIntl();

  const direction = sortingDirection === SortDirection.Descending ? "descending" : "ascending";

  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell
          sorted={sortingColumn === "groupName" ? direction : undefined}
          onClick={() => onColumnSorting("groupName")}>
          {groupType}
        </Table.HeaderCell>

        <Table.HeaderCell
          collapsing
          sorted={sortingColumn === "itemCount" ? direction : undefined}
          onClick={() => onColumnSorting("itemCount")}
          textAlign='right'>
          {formatMessage(m.itemCount)}
        </Table.HeaderCell>

        <Table.HeaderCell
          collapsing
          sorted={sortingColumn === "percent" ? direction : undefined}
          onClick={() => onColumnSorting("percent")}
          textAlign='right'>
          {formatMessage(m.percent)}
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};
