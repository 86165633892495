import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';
import { Card, Checkbox, List, Icon, Input, Segment, Form, Divider, Button, SemanticICONS, Grid, DropdownItemProps, Label, CheckboxProps } from 'semantic-ui-react';
import { Item, BomItem, ItemType, ProductionOrder, ProductionComponent, Quantity, ProductionComponentTree } from '../../../state/models';
import { EmptyContentSegment, OrDivider } from '../../common';
import { ProductionComponentsDropdown, SelectableProductionComponent, SelectedProductionComponent, ViewBillOfMaterialsModal } from '.';
import { SelectedProductionComponentState } from '../../../state/ducks/production-orders/reducers';

export interface BillOfMaterialsCardProps {
    productionOrders: ProductionOrder[];
    productionComponentsTree: ProductionComponentTree;
    selectedComponents: SelectedProductionComponentState[];
    isReadOnly?: boolean;

    onExpandProductionComponent: (productionComponentId: number) => void;
    onCollapseProductionComponent: (productionComponentId: number) => void;
    onSelectProductionComponent: (productionComponentId: number) => void;
    onDeselectProductionComponent: (productionComponentId: number, deselectChildren?: boolean) => void;
    onProductionComponentQuantityChanged: (productionComponentId: number, newValue: number) => void;
}

interface BillOfMaterialsCardState {
    rootComponents: ProductionComponent[];
}

interface ItemTypeContent {
    icon: SemanticICONS;
    description: MessageDescriptor;
}

const m = defineMessages({
    title: { id: 'BillOfMaterialsCard.title', defaultMessage: 'Bill of materials' },
    subtitle: { id: 'BillOfMaterialsCard.subtitle', defaultMessage: 'Select the parts required for the repairs.' },
    quickSelection: { id: 'BillOfMaterialsCard.quick_selection', defaultMessage: 'Quick selection' },
    selectedParts: { id: 'BillOfMaterialsCard.selected_parts', defaultMessage: 'Selected parts' },
    emptyTitle: { id: 'BillOfMaterialsCard.empty_title', defaultMessage: 'Bill of materials unavailable' },
    emptyDescription: { id: 'BillOfMaterialsCard.empty_description', defaultMessage: 'Select the defective items in the inspection step to view the parts available for the repairs.' },
    emptySelectedPartsTitle: { id: 'BillOfMaterialsCard.empty_selected_parts_title', defaultMessage: 'No parts selected' },
    emptySelectedPartsDescription: { id: 'BillOfMaterialsCard.empty_selected_parts_description', defaultMessage: 'Select the parts required to repair the defects.' },
    viewBillOfMaterials: { id: 'BillOfMaterialsCard.view_bill_of_materials', defaultMessage: 'View Bill of Materials' },
});

class BillOfMaterialsCard extends React.Component<BillOfMaterialsCardProps & WrappedComponentProps, BillOfMaterialsCardState> {
    public constructor(props: BillOfMaterialsCardProps & WrappedComponentProps) {
        super(props);

        this.state = {
            rootComponents: this.props.productionOrders && this.props.productionOrders.length > 0
                ? this.props.productionOrders.map(x => x.product)
                : []
        };
    }

    public componentWillReceiveProps(props: BillOfMaterialsCardProps & WrappedComponentProps) {
        this.setState({
            rootComponents: props.productionOrders && props.productionOrders.length > 0
            ? props.productionOrders.map(x => x.product)
            : []
        });
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const selectedComponents = this.props.selectedComponents.map(x => (
            <SelectedProductionComponent 
                key={x.productionComponent.id} 
                productionComponent={x.productionComponent}
                quantity={x.quantity}
                onQuantityChanged={(newValue) => this.props.onProductionComponentQuantityChanged(x.productionComponent.id, newValue)}
                onDeselect={() => this.props.onDeselectProductionComponent(x.productionComponent.id)}
            />
        ));

        const products = this.props.productionOrders && this.props.productionOrders.length > 0
            ? this.props.productionOrders.map(x => x.product)
            : [];

        const hasDefectiveProducts = products.length > 0;
        const hasSelectedComponents = selectedComponents && selectedComponents.length > 0;

        return (
            <Card fluid={true} color="blue" style={{height: '100%'}}>
                <Card.Content style={{flexGrow: 0}}>
                    <Card.Header>{formatMessage(m.title)}</Card.Header>
                    <Card.Meta>{formatMessage(m.subtitle)}</Card.Meta>
                </Card.Content>
                {hasDefectiveProducts && 
                    <Card.Content>
                        <Grid>
                            <Grid.Column width={16} largeScreen={8} widescreen={8}>
                                <Form>
                                    <Form.Field>
                                        <label>{formatMessage(m.quickSelection)}</label>
                                        <ProductionComponentsDropdown 
                                            products={products}
                                            onSelectProductionComponent={this.props.onSelectProductionComponent}
                                            onDeselectProductionComponent={this.props.onDeselectProductionComponent}
                                            disabled={this.props.isReadOnly}
                                        />
                                    </Form.Field>

                                    <OrDivider />

                                    <ViewBillOfMaterialsModal
                                        trigger={<Button primary={true} disabled={this.props.isReadOnly} fluid={true} icon="sitemap" content={formatMessage(m.viewBillOfMaterials)} />}
                                        productionComponents={this.state.rootComponents}
                                        productionComponentsTree={this.props.productionComponentsTree}
                                        onCollapseProductionComponent={this.props.onCollapseProductionComponent}
                                        onExpandProductionComponent={this.props.onExpandProductionComponent}
                                        onDeselectProductionComponent={this.props.onDeselectProductionComponent}
                                        onSelectProductionComponent={this.props.onSelectProductionComponent}
                                    />
                                </Form>
                            </Grid.Column>
                            <Grid.Column width={16} largeScreen={8} widescreen={8}>
                                <Form>
                                    <Form.Field>
                                        <label>{formatMessage(m.selectedParts)}</label>
                                        {hasSelectedComponents &&
                                            <List>
                                                {selectedComponents}
                                            </List>
                                        }

                                        {!hasSelectedComponents &&
                                            <EmptyContentSegment
                                                title={formatMessage(m.emptySelectedPartsTitle)}
                                                description={formatMessage(m.emptySelectedPartsDescription)}
                                            />
                                        }
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                        </Grid>
                    </Card.Content>
                }
                {!hasDefectiveProducts &&
                    <Card.Content>
                        <EmptyContentSegment
                            basic={true}
                            title={formatMessage(m.emptyTitle)}
                            description={formatMessage(m.emptyDescription)}
                        />
                    </Card.Content>
                }
            </Card>
        );
    }
}

const connectedComponent = injectIntl(BillOfMaterialsCard);
export { connectedComponent as BillOfMaterialsCard };