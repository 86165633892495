import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { ApplicationState } from '../../../state/ducks';
import * as FactoryRepairsActions from '../../../state/ducks/factory-repairs';
import * as UiActions from '../../../state/ducks/ui';
import { FactoryReportCard } from '../../../components/service-calls/factory';
import { FactoryReport, ServiceCallFactoryRepair } from '../../../state/models';
import { getFactoryReportByRepairId, getFactoryRepairById } from '../../../state/ducks/factory-repairs/selectors';
import { Message } from 'semantic-ui-react';

interface FactoryRepairStepActions {
    factoryRepairsActions: typeof FactoryRepairsActions.actionCreators;
    uiActions: typeof UiActions.actionCreators;
}

interface FactoryRepairStepOwnProps {
    repair: ServiceCallFactoryRepair;
    report: FactoryReport;
}

export type FactoryRepairStepProps =
    & FactoryRepairStepOwnProps
    & FactoryRepairStepActions
    & RouteComponentProps<{ serviceCallId: string, repairId: string }>;

type IntlFactoryRepairStepProps = FactoryRepairStepProps & WrappedComponentProps;

const m = defineMessages({
    reportDisabledTitle: { id: 'FactoryRepairStep.report_disabled_title', defaultMessage: 'Service report is in read-only mode' },
    reportDisabledMessage: { id: 'FactoryRepairStep.report_disabled_message', defaultMessage: 'The service report cannot be modified becase the repairs have already been completed.' }
});

class FactoryRepairStep extends React.Component<IntlFactoryRepairStepProps, {}> {
    public componentDidMount() {
        this.props.uiActions.changeFactoryStep('repair');
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const isLoading = this.props.repair == null || this.props.report == null;
        const isRepaired = this.props.repair && this.props.repair.completedRepairsOn != null;

        return (
            <React.Fragment>
                {isRepaired &&
                    <Message
                        icon="info circle"
                        info={true}
                        header={formatMessage(m.reportDisabledTitle)}
                        content={formatMessage(m.reportDisabledMessage)}
                    />
                }

                <FactoryReportCard
                    isLoading={isLoading}
                    isDisabled={isRepaired}
                    factoryReport={this.props.report}
                    onSave={this.saveReport}
                />
            </React.Fragment>
        );
    }

    private saveReport = (report: FactoryReport): void => {
        const { repairId } = this.props.match.params;
        this.props.factoryRepairsActions.saveReport(Number(repairId), report);
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: FactoryRepairStepProps): FactoryRepairStepOwnProps => {
    const repairId = Number(ownProps.match.params.repairId);

    return {
        repair: getFactoryRepairById(state, repairId),
        report: getFactoryReportByRepairId(state, repairId)
    };
};

const mapDispatchToProps = (dispatch: Dispatch): FactoryRepairStepActions => {
    return {
        factoryRepairsActions: bindActionCreators(FactoryRepairsActions.actionCreators, dispatch),
        uiActions: bindActionCreators(UiActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(FactoryRepairStep);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as FactoryRepairStep };