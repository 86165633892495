import moment from 'moment';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Header, Icon, List } from 'semantic-ui-react';
import { commonMessages } from '../../../../../constants';
import { useSubcontractorRepairDetailsContext } from '../SubcontractorRepairDetailsProvider';
import { AppointementDateModal } from './AppointementDateModal';

export const AppointementDateSection = () => {
    const { formatMessage } = useIntl();

    const { repairDetails } = useSubcontractorRepairDetailsContext();

    const appointmentDate = repairDetails?.appointmentDate;

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleSelectAppointmentDateRequest = () => {
        setIsModalOpen(true);
    };

    return (
        <>
            <List.Item>
                <List.Content floated="right">
                    {appointmentDate ? (
                        <Button
                            positive
                            fluid
                            icon="check"
                            content={formatMessage(commonMessages.modify)}
                            onClick={handleSelectAppointmentDateRequest}
                        />
                    ) : (
                        <Button
                            primary
                            fluid
                            content={formatMessage({
                                id: 'SubcontractorRepairPage.AppointementDateSection.SelectAnAppointmentDate',
                                defaultMessage: 'Select an appointment date',
                            })}
                            onClick={handleSelectAppointmentDateRequest}
                        />
                    )}
                </List.Content>
                <Icon name="calendar" size="large" />
                <List.Content>
                    <List.Header as="h3" style={{ marginBottom: '1rem' }}>
                        {formatMessage({
                            id: 'SubcontractorRepairPage.AppointementDateSection.Title',
                            defaultMessage: 'Appointement date',
                        })}
                    </List.Header>
                    <Header as="h4">
                        {appointmentDate ? (
                            moment(appointmentDate).local().format('LL')
                        ) : (
                            formatMessage(commonMessages.notSpecified)
                        )}
                    </Header>
                </List.Content>
            </List.Item>
            <AppointementDateModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            />
        </>
    );
};
