import { FormProvider, useForm } from 'react-hook-form';
import { Form } from 'semantic-ui-react';
import { ReportInputField } from './ReportInputField';

export interface ReportFormValues {
    report?: string | null;
}

interface ReportFormProps {
    formId: string;
    initialFormValues: ReportFormValues | undefined;
    onSubmit: (formValues: ReportFormValues) => void;
}

export const ReportForm = ({
    formId,
    initialFormValues,
    onSubmit,
}: ReportFormProps) => {
    const formMethods = useForm<ReportFormValues>({
        defaultValues: initialFormValues,
    });

    const { handleSubmit } = formMethods;

    return (
        <FormProvider {...formMethods}>
            <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <ReportInputField />
            </Form>
        </FormProvider>
    );
};
