import * as React from 'react';
import moment from 'moment';

import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Table, TableCellProps, Icon, Popup } from 'semantic-ui-react';
import { LoadingDimmer, LoadingContainer } from '../common';
import { CalendarBreak } from '../../state/models';
import { UserHelper } from '../../helpers';

interface CalendarDayCellOwnProps extends TableCellProps {
    day: Date;
    locked?: boolean;
    selected?: boolean;
    loading?: boolean;
    calendarBreak?: CalendarBreak;
}

export type CalendarDayCellProps =
    & CalendarDayCellOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    locked: { id: 'CalendarDayCell.locked', defaultMessage: 'Locked for everyone' },
    lockedForFormat: { id: 'CalendarDayCell.locked_for_format', defaultMessage: 'Locked for {name}' }
});

class CalendarDayCell extends React.Component<CalendarDayCellProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { children, day, locked, selected, loading, calendarBreak, intl, ...tableCellProps } = this.props;
        const dayMoment = moment(day);
        const hasUserInformation = this.props.calendarBreak && this.props.calendarBreak.forUser;

        return (
            <Table.Cell className={this.getStyle()} {...tableCellProps}>
                <LoadingContainer>
                    <LoadingDimmer active={this.props.loading} />

                    <h4 style={{ textAlign: 'left', margin: '0 0 5px 5px' }}>
                        {dayMoment.format('ddd D')}

                        {this.props.locked &&
                            <Popup trigger={<Icon name="lock" style={{ float: 'right' }} />}>
                                {hasUserInformation &&
                                    <React.Fragment>
                                        <span>{formatMessage(m.lockedForFormat, { name: UserHelper.getDisplayName(this.props.calendarBreak!.forUser) })}</span>
                                        <p style={{ color: 'rgba(0,0,0,.25)', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                            {this.props.calendarBreak!.reason}
                                        </p>
                                    </React.Fragment>
                                }

                                {!hasUserInformation &&
                                    <span>{formatMessage(m.locked)}</span>
                                }
                            </Popup>
                        }
                    </h4>

                    {this.props.children}
                </LoadingContainer>
            </Table.Cell>
        );
    }

    private getStyle(): string {
        const dayMoment = moment(this.props.day);
        const isToday = dayMoment.isSame(moment(), 'day');
        const isPast = dayMoment.isBefore(moment(), 'day');

        let style = ['c-calendar__day'];

        if (this.props.selectable) {
            style.push('c-calendar__day--selectable');
        }

        if (this.props.loading) {
            style.push('c-calendar__day--loading');
        }

        if (this.props.selected) {
            style.push('c-calendar__day--selected');
        }

        if (this.props.locked) {
            style.push('c-calendar__day--locked');
        }

        if (isToday) {
            style.push('c-calendar__day--today');
        } else if (isPast) {
            style.push('c-calendar__day--past');
        }

        return style.join(' ');
    }
}

const connectedComponent = injectIntl(CalendarDayCell);
export { connectedComponent as CalendarDayCell };