import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedMessage, FormattedNumber } from 'react-intl';
import { Feed, SemanticICONS, Popup, Icon, List } from 'semantic-ui-react';

import { ServiceCallUpdate, AccountingUpdate, AccountingTransactionType } from '../../../state/models';
import { StringHelper } from '../../../state/utils';

export interface AccountingActivityProps {
    activity: AccountingUpdate;
}

const m = defineMessages({
    accountCredited: { id: 'AccountingActivity.account_credited', defaultMessage: 'A credit for the amount of {amount} was applied to the account.' },
    accountBilled: { id: 'AccountingActivity.account_billed', defaultMessage: 'A bill for the amount of {amount} was added to the account.' },
    amountCredited: { id: 'AccountingActivity.amount_credited', defaultMessage: 'Amount credited: {amount}' },
    amountBilled: { id: 'AccountingActivity.amount_billed', defaultMessage: 'Amount billed: {amount}' },
    shippingCredited: { id: 'AccountingActivity.shipping_credited', defaultMessage: 'Shipping fees credited: {amount}' },
    shippingBilled: { id: 'AccountingActivity.shipping_billed', defaultMessage: 'Shipping fees billed: {amount}' }
});

class AccountingActivity extends React.Component<AccountingActivityProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { activity } = this.props;

        const isBilled = activity.transactionType === AccountingTransactionType.billed;
        const accountingAmount = <FormattedNumber value={activity.amount + activity.shippingAmount} style="currency" currency={activity.currencyCode} />;
        const amount = <FormattedNumber value={activity.amount} style="currency" currency={activity.currencyCode} />;
        const shipping = <FormattedNumber value={activity.shippingAmount} style="currency" currency={activity.currencyCode} />;

        const accountingMessage = isBilled ? m.accountBilled : m.accountCredited;
        const amountMessage = isBilled ? m.amountBilled : m.amountCredited;
        const shippingMessage = isBilled ? m.shippingBilled : m.shippingCredited;
        const hasAmountAndShippingFees = activity.amount > 0 && activity.shippingAmount > 0;

        return (
            <Feed.Event key={activity.id}>
                <Feed.Label icon="dollar" />
                <Feed.Content>
                    <Popup
                        trigger={<Feed.Date content={moment.utc(activity.createdOn).fromNow()} />}
                        content={moment.utc(activity.createdOn).format('LL')}
                    />

                    <Feed.Summary>
                        <FormattedMessage {...accountingMessage} values={{ 'amount': accountingAmount }} />
                    </Feed.Summary>

                    <Feed.Extra text={true}>
                        {hasAmountAndShippingFees &&
                            <List bulleted={true}>
                                <List.Item content={<FormattedMessage {...amountMessage} values={{ 'amount': amount }} />} />
                                <List.Item content={<FormattedMessage {...shippingMessage} values={{ 'amount': shipping }} />} />
                            </List>
                        }
                        {StringHelper.hasValue(activity.description) &&
                            <p style={{ whiteSpace: 'pre-line' }}>
                                {activity.description}
                            </p>}
                    </Feed.Extra>
                </Feed.Content>
            </Feed.Event>
        );
    }
}

const connectedComponent = injectIntl(AccountingActivity);
export { connectedComponent as AccountingActivity };