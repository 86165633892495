import moment from 'moment';
import React from 'react';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { Card, List } from 'semantic-ui-react';

import { UserHelper } from '../../../helpers';
import { ServiceCall, ServiceCallFactoryRepair } from '../../../state/models';
import { LoadingDimmer } from '../../common';

interface FactoryRepairsInformationOwnProps {
    isLoading: boolean;
    factoryRepairs?: ServiceCallFactoryRepair;
    serviceCall?: ServiceCall;
}

export type FactoryRepairsInformationProps =
    & FactoryRepairsInformationOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'FactoryRepairsInformationCard.title', defaultMessage: 'Request information' },
    subtitle: { id: 'FactoryRepairsInformationCard.subtitle', defaultMessage: 'View the details about the factory repairs request.' },
    customerServiceLabel: { id: 'FactoryRepairsInforamtionCard.customer_service_label', defaultMessage: 'Customer service' },
    requestedOn: { id: 'FactoryRepairsInformationCard.requested_on_label', defaultMessage: 'Requested on' },
    receivedOn: { id: 'FactoryRepairsInformationCard.received_on_label', defaultMessage: 'Received on' },
    completedOn: { id: 'FactoryRepairsInformationCard.completed_on_label', defaultMessage: 'Completed on' },
    notYetReceived: { id: 'FactoryRepairsInformationCard.not_yet_received_label', defaultMessage: 'Not yet received' },
    clientLabel: { id: 'FactoryRepairsInformationCard.client_label', defaultMessage: 'Client' }
});

class FactoryRepairsInformationCard extends React.Component<FactoryRepairsInformationProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const hasClient = this.props.serviceCall != null && this.props.serviceCall.client != null;

        return (
            <Card fluid={true} color="teal" style={{ height: '100%' }}>
                <Card.Content style={{ flexGrow: 0 }}>
                    <Card.Header content={formatMessage(m.title)} />
                    <Card.Meta content={formatMessage(m.subtitle)} />
                </Card.Content>
                <Card.Content>
                    <LoadingDimmer active={this.props.isLoading} />

                    {this.props.serviceCall && this.props.factoryRepairs &&
                        <List style={{ marginTop: 0 }}>
                            <List.Item
                                icon="doctor"
                                header={formatMessage(m.customerServiceLabel)}
                                content={UserHelper.getDisplayName(this.props.serviceCall.assignedTo)}
                            />
                            {hasClient &&
                                <List.Item
                                    icon="shopping cart"
                                    header={formatMessage(m.clientLabel)}
                                    content={this.props.serviceCall.client.name}
                                />
                            }
                            <List.Item
                                icon="calendar"
                                header={formatMessage(m.requestedOn)}
                                content={moment.utc(this.props.factoryRepairs.requestedOn).format('LL')}
                            />
                            <List.Item>
                                <List.Icon name="factory" />
                                <List.Content>
                                    <List.Header content={formatMessage(m.receivedOn)} />
                                    <List.Description>
                                        {this.props.factoryRepairs.receivedOn &&
                                            <span>{moment.utc(this.props.factoryRepairs.receivedOn).format('LL')}</span>
                                        }
                                        {!this.props.factoryRepairs.receivedOn &&
                                            <span>{formatMessage(m.notYetReceived)}</span>
                                        }
                                    </List.Description>
                                </List.Content>
                            </List.Item>
                            {this.props.factoryRepairs.completedRepairsOn &&
                                <List.Item
                                    icon="wrench"
                                    header={formatMessage(m.completedOn)}
                                    content={moment.utc(this.props.factoryRepairs.completedRepairsOn).format('LL')}
                                />
                            }
                        </List>
                    }
                </Card.Content>
            </Card>
        );
    }
}

const connectedComponent = injectIntl(FactoryRepairsInformationCard);
export { connectedComponent as FactoryRepairsInformationCard };