import { Address } from './Address';
import { ClientEmail } from './ClientEmail';

export enum ClientStatus {
    unknown = 0,
    active = 1,
    inactive = 2,
    closed = 3
}

export enum PreferredLanguage {
    unknown = 0,
    french = 1,
    english = 2
}

export interface Client {
    id: string;
    name: string;
    alternateName: string;
    status: ClientStatus;
    address: Address;
    phoneNumber: string;
    normalizedPhoneNumber: number;
    faxNumber: string;
    normalizedFaxNumber: number;
    emails: ClientEmail[];
    preferredLanguage: PreferredLanguage;
}

export function getCultureCode(language: PreferredLanguage): string | undefined {
    switch (language) {
        case PreferredLanguage.french:
            return 'fr';

        case PreferredLanguage.english:
            return 'en';

        default:
            return undefined;
    }
}