import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Table, Button, Icon, Label } from 'semantic-ui-react';

import { LoadingContainer, LoadingDimmer, NoContentSegment } from '../../common';
import { ServiceCallInspection, ServiceCallDefect } from '../../../state/models';
import { InspectionStatusLabel } from './InspectionStatusLabel';
import { defectTypeMessages, defectCategoryMessages, commonMessages } from '../../../constants';

interface InspectionTableOwnProps {
    inspections: ServiceCallInspection[];
    isLoading: boolean;
    emptyTitle: string;
    emptySubtitle: string;
    isRequestedOnHidden?: boolean;
    isScheduledForHidden?: boolean;
    isCompletedOnHidden?: boolean;
    isViewButtonHidden?: boolean;
    onViewDetails: (inspection: ServiceCallInspection) => void;
}

export type InspectionTableProps =
    & InspectionTableOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    idHeader: { id: 'InspectionTable.id_header', defaultMessage: '#' },
    requestedOnHeader: { id: 'InspectionTable.requested_on_header', defaultMessage: 'Requested on' },
    scheduledForHeader: { id: 'InspectionTable.scheduled_for_header', defaultMessage: 'Scheduled for' },
    completedOnHeader: { id: 'InspectionTable.completed_on_header', defaultMessage: 'Completed on' },
    defectHeader: { id: 'InspectionTable.defect_header', defaultMessage: 'Defect' }
});

class InspectionTable extends React.Component<InspectionTableProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const hasInspections = this.props.inspections && this.props.inspections.length > 0;
        const rows = hasInspections ? this.props.inspections.map(x => this.renderRow(x)) : (null);

        return (
            <LoadingContainer>
                <LoadingDimmer active={this.props.isLoading} />

                {hasInspections &&
                    <Table basic="very" unstackable={true}>
                        <Table.Header>
                            <Table.HeaderCell collapsing={true} />
                            <Table.HeaderCell collapsing={true}>{formatMessage(m.idHeader)}</Table.HeaderCell>
                            {!this.props.isRequestedOnHidden && <Table.HeaderCell>{formatMessage(m.requestedOnHeader)}</Table.HeaderCell>}
                            {!this.props.isScheduledForHidden && <Table.HeaderCell>{formatMessage(m.scheduledForHeader)}</Table.HeaderCell>}
                            {!this.props.isCompletedOnHidden && <Table.HeaderCell>{formatMessage(m.completedOnHeader)}</Table.HeaderCell>}
                            <Table.HeaderCell>{formatMessage(m.defectHeader)}</Table.HeaderCell>
                            <Table.HeaderCell collapsing={true} />
                        </Table.Header>
                        <Table.Body>
                            {rows}
                        </Table.Body>
                    </Table>
                }

                {!hasInspections &&
                    <NoContentSegment
                        title={this.props.emptyTitle}
                        subtitle={this.props.emptySubtitle}
                    />
                }
            </LoadingContainer>
        );
    }

    private renderRow(inspection: ServiceCallInspection) {
        const { formatMessage } = this.props.intl;
        const hasDefects = inspection.serviceCall && inspection.serviceCall.defects && inspection.serviceCall.defects.length > 0;

        // We want to display a warning on past inspections that were scheduled, but that have not yet been completed.
        const isIncompleteInspection = inspection.completedOn == null
            && inspection.scheduledForDate != null
            && moment.utc(inspection.scheduledForDate).isBefore(moment().utc());

        return (
            <Table.Row key={inspection.id} warning={isIncompleteInspection}>
                <Table.Cell>
                    <InspectionStatusLabel inspection={inspection} />
                </Table.Cell>
                <Table.Cell><a style={{ cursor: 'pointer' }} onClick={() => this.props.onViewDetails(inspection)}>{inspection.serviceCallId}</a></Table.Cell>
                {!this.props.isRequestedOnHidden &&
                    <Table.Cell><span>{moment.utc(inspection.requestedOn).format('LL')}</span></Table.Cell>
                }
                {!this.props.isScheduledForHidden &&
                    <Table.Cell><span>{moment.utc(inspection.scheduledForDate).format('LL')}</span></Table.Cell>
                }
                {!this.props.isCompletedOnHidden &&
                    <Table.Cell><span>{moment.utc(inspection.completedOn).format('LL')}</span></Table.Cell>
                }
                <Table.Cell>
                    {hasDefects &&
                        this.renderDefects(inspection.serviceCall.defects)
                    }
                </Table.Cell>
                {!this.props.isViewButtonHidden &&
                    <Table.Cell>
                        <Button
                            content={formatMessage(commonMessages.view)}
                            onClick={() => this.props.onViewDetails(inspection)}
                        />
                    </Table.Cell>
                }
            </Table.Row>
        );
    }

    private renderDefects(defects: ServiceCallDefect[]) {
        const { formatMessage } = this.props.intl;

        if (defects && defects.length > 0) {
            const defect = defects[0].defect;

            const typeMessages = Object.keys(defectTypeMessages).map(key => defectTypeMessages[key]);
            const defectTypeMessage = typeMessages.find(x => x.id === defect.defectType.title);
            const defectType = defectTypeMessage ? formatMessage(defectTypeMessage) : defect.defectType.title;

            const categoryMessages = Object.keys(defectCategoryMessages).map(key => defectCategoryMessages[key]);
            const defectCategoryMessage = categoryMessages.find(x => x.id === defect.defectCategory.title);
            const defectCategory = defectCategoryMessage ? formatMessage(defectCategoryMessage) : defect.defectCategory.title;

            return (
                <p>
                    <span>{defectType}</span>
                    <span style={{ color: 'rgba(0,0,0,0.4)' }}> ({defectCategory})</span>
                </p>
            );
        }

        return (null);
    }
}

const connectedComponent = injectIntl(InspectionTable);
export { connectedComponent as InspectionTable };