import * as React from 'react';
import { Dropdown, Icon, SemanticICONS, SemanticCOLORS, Label } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

export interface MobileNavigationLinkProps {
    title: string;
    to: string;
    exact?: boolean;
    icon?: SemanticICONS;
    labelColor?: SemanticCOLORS;
    labelContent?: React.ReactNode;
}

class MobileNavigationLink extends React.Component<MobileNavigationLinkProps, {}> {
    public render() {
        return (
            <Dropdown.Item as={NavLink} exact={this.props.exact} to={this.props.to}>
                <span>
                    {this.props.icon && <Icon name={this.props.icon}/>}
                    {this.props.title}
                    {this.props.labelContent && 
                        <Label 
                            color={this.props.labelColor}
                            circular={true}
                            size="tiny"
                            className="c-navigation-menu__label"
                            content={this.props.labelContent}
                        />
                    }
                </span>
            </Dropdown.Item>
        );
    }
}

export { MobileNavigationLink };