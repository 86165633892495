import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { ApplicationState } from '../../../state/ducks';
import { Grid } from 'semantic-ui-react';
import { AppointmentsCard } from '../../../components/service-calls';
import { TechnicianRepair } from '../../../state/models';
import * as AppointmentsActions from '../../../state/ducks/appointments';
import { getTodaysAppointments, getThisWeeksAppointments } from '../../../state/ducks/appointments/selectors';

interface TechnicianDashboardPageActions {
    appointmentsActions: typeof AppointmentsActions.actionCreators;
}

interface TechnicianDashboardPageOwnProps {
    todaysAppointments: TechnicianRepair[];
    areTodaysAppointmentsLoading: boolean;
    thisWeeksAppointments: TechnicianRepair[];
    areThisWeeksAppointmentsLoading: boolean;
}

export type TechnicianDashboardPageProps =
    & TechnicianDashboardPageOwnProps
    & TechnicianDashboardPageActions
    & RouteComponentProps<{}>
    & WrappedComponentProps;

const m = defineMessages({
    todayCardTitle: { id: 'TechnicianDashboardPage.today_card_title', defaultMessage: 'Today\'s appointments' },
    todayCardSubtitle: { id: 'TechnicianDashboardPage.today_card_subtitle', defaultMessage: 'View the appointments you have scheduled for today.' },
    thisWeekCardTitle: { id: 'TechnicianDashboardPage.this_weeks_card_title', defaultMessage: 'This week\'s appointments' },
    thisWeekCardSubtitle: { id: 'TechnicianDashboardPage.this_weeks_card_subtitle', defaultMessage: 'View the appointments you have scheduled for this week.' },
    noAppointments: { id: 'TechnicianDashboardPage.today_card_empty_title', defaultMessage: 'No appointments' },
    todayCardEmptySubtitle: { id: 'TechnicianDashboardPage.today_card_empty_subtitle', defaultMessage: 'There are no appointments scheduled for today.' },
    thisWeekCardEmptySubtitle: { id: 'TechnicianDashboardPage.this_week_card_empty_subtitle', defaultMessage: 'There are no appointments scheduled for this week.' }
});

class TechnicianDashboardPage extends React.Component<TechnicianDashboardPageProps, {}> {
    public componentWillMount() {
        this.props.appointmentsActions.loadToday();
        this.props.appointmentsActions.loadThisWeek();
    }

    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <Grid columns={2} stackable={true}>
                <Grid.Column>
                    <AppointmentsCard
                        title={formatMessage(m.todayCardTitle)}
                        subtitle={formatMessage(m.todayCardSubtitle)}
                        emptyTitle={formatMessage(m.noAppointments)}
                        emptySubtitle={formatMessage(m.todayCardEmptySubtitle)}
                        appointments={this.props.todaysAppointments}
                        areAppointmentsLoading={this.props.areTodaysAppointmentsLoading}
                        onViewDetails={this.navigateToTechnicianPage}
                    />
                </Grid.Column>
                <Grid.Column>
                    <AppointmentsCard
                        title={formatMessage(m.thisWeekCardTitle)}
                        subtitle={formatMessage(m.thisWeekCardSubtitle)}
                        emptyTitle={formatMessage(m.noAppointments)}
                        emptySubtitle={formatMessage(m.thisWeekCardEmptySubtitle)}
                        appointments={this.props.thisWeeksAppointments}
                        areAppointmentsLoading={this.props.areThisWeeksAppointmentsLoading}
                        onViewDetails={this.navigateToTechnicianPage}
                    />
                </Grid.Column>
            </Grid>
        );
    }

    private navigateToTechnicianPage = (appointment: TechnicianRepair) => {
        this.props.history.push(`/service-calls/${appointment.serviceCallId}/technician-repairs/${appointment.id}/prepare`);
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        todaysAppointments: getTodaysAppointments(state),
        areTodaysAppointmentsLoading: state.appointments.todaysAppointments.isLoading,
        thisWeeksAppointments: getThisWeeksAppointments(state),
        areThisWeeksAppointmentsLoading: state.appointments.thisWeeksAppointments.isLoading
    };
};

const mapDispatchToProps = (dispatch: Dispatch): TechnicianDashboardPageActions => {
    return {
        appointmentsActions: bindActionCreators(AppointmentsActions.actionCreators, dispatch),
    };
};

const intlComponent = injectIntl(TechnicianDashboardPage);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as TechnicianDashboardPage };