import { ApplicationState } from '../../../state/ducks';
import { Subcontractor } from '../../../state/models';

export const getSubcontractorById = (state: ApplicationState, id: number): Subcontractor | undefined => state.administration.subcontractors.data.byId[id];

export const getAllSubcontractors = (state: ApplicationState) => {
    return state.administration.subcontractors.data.allIds
        .map(x => getSubcontractorById(state, x))
        .filter(x => x != null) as Subcontractor[];
};

export const areSubcontractorsLoading = (state: ApplicationState): boolean => state.administration.subcontractors.loading.all;

export const isSubcontractorLoading = (state: ApplicationState, id: number): boolean => state.administration.subcontractors.loading.ids.find(x => x === id) != null;