import React from "react";
import { useIntl } from "react-intl";
import { Table } from "semantic-ui-react";
import { defectCategoryMessages, defectCauseMessages, defectTypeMessages } from "../../../../../constants";
import { Defect } from "../../../../../state/models";

interface OwnProps {
    defect: Defect;
}

export type DefectsTableRowProps =
    & OwnProps;

export const DefectsTableRow: React.FC<DefectsTableRowProps> = (props) => {
    const { formatMessage } = useIntl();

    const typeMessages = Object.keys(defectTypeMessages).map(key => defectTypeMessages[key]);
    const defectTypeMessage = typeMessages.find(x => x.id === props.defect.defectType.title);
    const defectType = defectTypeMessage ? formatMessage(defectTypeMessage) : props.defect.defectType.title;

    const categoryMessages = Object.keys(defectCategoryMessages).map(key => defectCategoryMessages[key]);
    const defectCategoryMessage = categoryMessages.find(x => x.id === props.defect.defectCategory.title);
    const defectCategory = defectCategoryMessage ? formatMessage(defectCategoryMessage) : props.defect.defectCategory.title;

    const causeMessages = Object.keys(defectCauseMessages).map(key => defectCauseMessages[key]);
    const defectCauseMessage = causeMessages.find(x => x.id === props.defect.title);
    const defectCause = defectCauseMessage ? formatMessage(defectCauseMessage) : props.defect.title;

    return (
        <Table.Row>
            <Table.Cell>{props.defect.id}</Table.Cell>
            <Table.Cell>{defectCategory}</Table.Cell>
            <Table.Cell>{defectType}</Table.Cell>
            <Table.Cell>{defectCause}</Table.Cell>
        </Table.Row>
    );
};