import * as React from "react";
import { injectIntl, WrappedComponentProps, defineMessages } from "react-intl";
import {
  Header,
  Image,
  Modal,
  SemanticSIZES,
  Button,
  Icon,
} from "semantic-ui-react";
import { Media, MediaType } from "../../../state/models";
import { DateHelper } from "../../../helpers";
import { PictureThumbnail } from "./PictureThumbnail";
import { VideoThumbnail } from "./VideoThumbnail";

export interface MediaGalleryProps {
  medias: Media[];
  mediaSize?: SemanticSIZES;
  canEdit?: boolean;
  onDeleteMedia?: (mediaId: number) => void;
}

interface PictureGalleryState {
  isPreviewOpen: boolean;
  selectedMedia?: Media;
}

const m = defineMessages({
  previewPictureTitle: {
    id: "MediaGallery.previewpicture_title",
    defaultMessage: "Picture Preview",
  },
  deletePicture: { id: "MediaGallery.delete_picture", defaultMessage: "Delete Picture" },
  keepPicture: { id: "MediaGallery.keep_picture", defaultMessage: "Keep Picture" },
  previewVideoTitle: {
    id: "MediaGallery.previewvideo_title",
    defaultMessage: "Video Preview",
  },
  deleteVideo: { id: "MediaGallery.delete_video", defaultMessage: "Delete Video" },
  keepVideo: { id: "MediaGallery.keep_video", defaultMessage: "Keep Video" },
});

class MediaGallery extends React.Component<
  MediaGalleryProps & WrappedComponentProps,
  PictureGalleryState
> {
  constructor(props: MediaGalleryProps & WrappedComponentProps) {
    super(props);

    this.state = {
      isPreviewOpen: false,
      selectedMedia: props.medias.length > 0 ? props.medias[0] : undefined,
    };
  }

  public render() {
    const { formatMessage } = this.props.intl;
    const { isPreviewOpen, selectedMedia } = this.state;
    const pictureSize = this.props.mediaSize || "small";
    const videoSize = this.props.mediaSize || "small";

    const images = this.props.medias.filter((x) => x.type === MediaType.image);
    const videos = this.props.medias.filter((x) => x.type === MediaType.video);

    const imagesThumbnail = images
      ? this.getSortedMedias(images)
          .map((image) => {
            return (
              <PictureThumbnail
                key={image.id}
                thumbnailSize={pictureSize}
                picture={image}
                onClick={this.showPicturePreview}
                disabled={image.isDisabled}
              />
            );
          })
      : undefined;

    const videosThumbnail = videos
      ? this.getSortedMedias(videos)
          .map((video) => {
            return (
              <VideoThumbnail
                key={video.id}
                thumbnailSize={videoSize}
                video={video}
                onClick={this.showVideoPreview}
                disabled={video.isDisabled}
              />
            );
          })
      : undefined;

    const previewTitle =
      selectedMedia && selectedMedia.type === MediaType.image
        ? formatMessage(m.previewPictureTitle)
        : formatMessage(m.previewVideoTitle);

    const deleteMessage =
      selectedMedia && selectedMedia.type === MediaType.image
        ? formatMessage(m.deletePicture)
        : formatMessage(m.deleteVideo);

    const keepMessage =
      selectedMedia && selectedMedia.type === MediaType.image
        ? formatMessage(m.keepPicture)
        : formatMessage(m.keepVideo);

    return (
      <div>
        <Image.Group>
          {imagesThumbnail}
          {videosThumbnail}
        </Image.Group>
        <Modal
          className='c-media-gallery'
          size='fullscreen'
          open={isPreviewOpen}
          onClose={this.handleCloseMediaPreview}
          closeIcon={true}>
          <Header icon='picture' content={previewTitle} />
          <Modal.Content image={true}>
            <Icon
              className='c-media-gallery__previous'
              name='chevron left'
              size='huge'
              link={true}
              onClick={() => this.previousMedia(selectedMedia)}
            />
            {selectedMedia &&
              selectedMedia.type === MediaType.image &&
              this.renderImage(selectedMedia)}
            {selectedMedia &&
              selectedMedia.type === MediaType.video &&
              this.renderVideo(selectedMedia)}
            <Icon
              className='c-media-gallery__next'
              name='chevron right'
              size='huge'
              link={true}
              onClick={() => this.nextMedia(selectedMedia)}
            />
          </Modal.Content>

          {this.props.canEdit && (
            <Modal.Actions>
              <Button
                negative={true}
                onClick={() => this.handleDeleteMedia(selectedMedia)}
                labelPosition='left'
                icon='trash'
                content={deleteMessage}
              />
              <Button
                positive={true}
                onClick={this.handleCloseMediaPreview}
                labelPosition='left'
                icon='checkmark'
                content={keepMessage}
              />
            </Modal.Actions>
          )}
        </Modal>
      </div>
    );
  }

  private getSortedMedias = (medias: Media[]) => {
    const mediasWithCreationDate = medias.filter((media) => media.createdOn);
    const mediasWithoutCreationDate = medias.filter((media) => media.createdOn == null);

    return [
      ...mediasWithCreationDate.sort((a, b) =>
        DateHelper.sortDescending(a.createdOn!, b.createdOn!)
      ),
      ...mediasWithoutCreationDate,
    ];
  };

  private renderVideo = (media: Media) => {
    return (
      <video
        controls={true}
        src={media.uri}
        style={{ margin: "0 auto", height: "auto", maxWidth: "100%" }}
      />
    );
  };

  private renderImage = (media: Media) => {
    return <Image wrapped={true} centered={true} bordered={true} src={media.uri} />;
  };

  private showPicturePreview = (picture: Media) => {
    this.setState((current) => ({
      ...current,
      selectedMedia: picture,
      isPreviewOpen: true,
    }));
  };

  private showVideoPreview = (video: Media) => {
    this.setState((current) => ({
      ...current,
      selectedMedia: video,
      isPreviewOpen: true,
    }));
  };

  private previousMedia = (media: Media | undefined) => {
    if (media) {
      const mediaIndex = this.props.medias.findIndex((x) => x.id === media.id);

      if (mediaIndex > 0) {
        this.setState({ selectedMedia: this.props.medias[mediaIndex - 1] });
      } else if (mediaIndex === 0) {
        this.setState({ selectedMedia: this.props.medias[this.props.medias.length - 1] });
      }
    }
  };

  private nextMedia = (media: Media | undefined) => {
    if (media) {
      const mediaIndex = this.props.medias.findIndex((x) => x.id === media.id);

      if (mediaIndex === this.props.medias.length - 1) {
        this.setState({ selectedMedia: this.props.medias[0] });
      } else if (mediaIndex >= 0) {
        this.setState({ selectedMedia: this.props.medias[mediaIndex + 1] });
      }
    }
  };

  private handleDeleteMedia = (media?: Media) => {
    if (this.props.canEdit && this.props.onDeleteMedia && media) {
      this.props.onDeleteMedia(media.id);
    }

    this.handleCloseMediaPreview();
  };

  private handleCloseMediaPreview = () => {
    this.setState({ isPreviewOpen: false });
  };
}

const connectedComponent = injectIntl(MediaGallery);
export { connectedComponent as MediaGallery };
