export enum ActionTypeKeys {
    LOAD_LOCKS_REQUEST = 'CALENDAR_LOAD_LOCKS_REQUEST',
    LOAD_LOCKS_SUCCESS = 'CALENDAR_LOAD_LOCKS_SUCCESS',
    LOAD_LOCKS_FAILURE = 'CALENDAR_LOAD_LOCKS_FAILURE',

    LOCK_REQUEST = 'CALENDAR_LOCK_REQUEST',
    LOCK_SUCCESS = 'CALENDAR_LOCK_SUCCESS',
    LOCK_FAILURE = 'CALENDAR_LOCK_FAILURE',

    UNLOCK_REQUEST = 'CALENDAR_UNLOCK_REQUEST',
    UNLOCK_SUCCESS = 'CALENDAR_UNLOCK_SUCCESS',
    UNLOCK_FAILURE = 'CALENDAR_UNLOCK_FAILURE',

    SET_CALENDAR_START_DATE = 'CALENDAR_SET_START_DATE'
}