import React from 'react';
import moment from 'moment';

import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Modal, List } from 'semantic-ui-react';
import { ServiceCallInspection } from '../../../state/models';
import { UserHelper } from '../../../helpers';

interface InspectionReportModalOwnProps {
    trigger: React.ReactNode;
    inspectionReport: ServiceCallInspection;
}

export type InspectionReportModalProps =
    & InspectionReportModalOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'InspectionReportModal.title', defaultMessage: 'Inspection report' },
    completedOn: { id: 'InspectionReportModal.completed_on', defaultMessage: 'Completed on' },
    inspectedBy: { id: 'InspectionReportModal.inspected_by', defaultMessage: 'Inspected by' },
    report: { id: 'InspectionReportModal.report', defaultMessage: 'Report details' }
});

class InspectionReportModal extends React.Component<InspectionReportModalProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <Modal trigger={this.props.trigger} closeIcon={true} size="fullscreen">
                <Modal.Header>{formatMessage(m.title)}</Modal.Header>
                <Modal.Content scrolling={true}>
                    <List relaxed={true}>
                        <List.Item>
                            <List.Icon name="user" size="large" verticalAlign="top" />
                            <List.Content>
                                <List.Header content={formatMessage(m.inspectedBy)} />
                                <List.Description>{UserHelper.getDisplayName(this.props.inspectionReport.assignedTo)}</List.Description>
                            </List.Content>
                        </List.Item>

                        <List.Item>
                            <List.Icon name="calendar check" size="large" verticalAlign="top" />
                            <List.Content>
                                <List.Header content={formatMessage(m.completedOn)} />
                                <List.Description>{moment.utc(this.props.inspectionReport.completedOn).format('LL')}</List.Description>
                            </List.Content>
                        </List.Item>

                        <List.Item>
                            <List.Icon name="pencil" size="large" verticalAlign="top" />
                            <List.Content>
                                <List.Header content={formatMessage(m.report)} />
                                <List.Description>
                                    <p style={{ whiteSpace: 'pre-line' }}>
                                        {this.props.inspectionReport.report}
                                    </p>
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    </List>
                </Modal.Content>
            </Modal>
        );
    }
}

const connectedComponent = injectIntl(InspectionReportModal);
export { connectedComponent as InspectionReportModal };