import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Modal, Form, Button, CheckboxProps, ModalProps, DropdownProps, TextAreaProps } from 'semantic-ui-react';
import { User } from '../../state/models';
import { commonMessages } from '../../constants';
import { UserHelper } from '../../helpers';

interface CalendarLockModalOwnProps extends ModalProps {
    users: User[];
    onCancel: () => void;
    onLock: (lockType: LockType, employee: User | undefined, reason: string) => void;
}

interface CalendarLockModalState {
    lockType: LockType;
    employeeSelected: User | undefined;
    reason: string;
}

export enum LockType {
    everyone,
    employee
}

export type CalendarLockModalProps =
    & CalendarLockModalOwnProps
    & WrappedComponentProps;

// Verrouiller une plage horaire
const m = defineMessages({
    title: { id: 'CalendarLockModal.title', defaultMessage: 'Lock a time slot' },
    forEveryone: { id: 'CalendarLockModal.for_everyone', defaultMessage: 'For everyone' },
    forEmployee: { id: 'CalendarLockModal.for_employee', defaultMessage: 'For employee' },
    employeePlaceholder: { id: 'CalendarLockModal.employee_placeholder', defaultMessage: 'Select an employee' },
    reasonLabel: { id: 'CalendarLockModal.reasonLabel', defaultMessage: 'Reason (optional)' },
    lock: { id: 'CalendarLockModal.lock', defaultMessage: 'Lock' }
});

class CalendarLockModal extends React.Component<CalendarLockModalProps, CalendarLockModalState> {
    public constructor(props: CalendarLockModalProps) {
        super(props);

        this.state = { 
            lockType: LockType.everyone,
            employeeSelected: undefined,
            reason: '',
        };
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const { users, intl, onCancel, onLock, ...modalProps } = this.props;
        const selectableUsers = users.map(x => ({
            text: UserHelper.getDisplayName(x),
            value: x.id,
            active: this.state.employeeSelected != null && this.state.employeeSelected.id === x.id
        }));

        return (
            <Modal size="tiny" {...modalProps}>
                <Modal.Header>{formatMessage(m.title)}</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Radio 
                            value={LockType.everyone} 
                            checked={this.state.lockType === LockType.everyone} 
                            onChange={this.handleLockTypeChange} 
                            label={formatMessage(m.forEveryone)} 
                        />
                        <Form.Radio 
                            value={LockType.employee} 
                            checked={this.state.lockType === LockType.employee} 
                            onChange={this.handleLockTypeChange} 
                            label={formatMessage(m.forEmployee)} 
                        />
                        <Form.Dropdown
                            disabled={this.state.lockType !== LockType.employee}
                            placeholder={formatMessage(m.employeePlaceholder)}
                            defaultValue={this.state.employeeSelected ? this.state.employeeSelected.id : undefined}
                            options={selectableUsers}
                            selection={true}
                            onChange={this.handleEmployeeSelected}
                        />
                        <Form.TextArea
                            label={formatMessage(m.reasonLabel)}
                            value={this.state.reason}
                            onChange={this.handleReasonChange}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button content={formatMessage(commonMessages.cancel)} onClick={() => this.props.onCancel()} />
                    <Button 
                        primary={true} 
                        disabled={this.state.lockType === LockType.employee && this.state.employeeSelected == null} 
                        content={formatMessage(m.lock)} 
                        onClick={this.handleLock} 
                    />
                </Modal.Actions>
            </Modal>
        );
    }

    private handleLock = () => {
        this.props.onLock(
            this.state.lockType, 
            this.state.lockType === LockType.employee ? this.state.employeeSelected : undefined,
            this.state.reason
        );
    }

    private handleLockTypeChange = (_event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        this.setState({ lockType: data.value as LockType });
    }

    private handleEmployeeSelected = (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const employeeSelected = this.props.users.find(x => x.id === data.value);
        this.setState({ employeeSelected: employeeSelected });
    }

    private handleReasonChange = (_event: React.FormEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
        this.setState({ reason: data.value as string });
    }
}

const connectedComponent = injectIntl(CalendarLockModal);
export { connectedComponent as CalendarLockModal };