import _ from "lodash";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Checkbox, Header, Table, TableRowProps } from "semantic-ui-react";
import { UserHelper } from "../../../../../../helpers";
import { User } from "../../../../../../state/models";
import styles from './ClientsTableRow.module.css';

interface OwnProps {
    user: User;
    isLoading?: boolean;
}

export type ClientsTableRowProps =
    & TableRowProps
    & OwnProps;

const m = defineMessages({
    inactiveUserLabel: { id: 'ClientsTableRow.inactiveUserLabel', defaultMessage: 'Disabled' }
});

export const ClientsTableRow: React.FC<ClientsTableRowProps> = (props) => {
    const { user, isLoading, ...tableRowProps } = props;
    const { formatMessage } = useIntl();
    const classNames = props.user.isDisabled ? `${styles.clickableRow} ${styles.disabled}` : styles.clickableRow;
    const clientCodes = user.claims?.clientCodes || [];
    const mainClientCode = clientCodes.length > 0 ? clientCodes[0] : '';
    const linkedClientCodes = _.join(_.drop(clientCodes, 1), ", ");

    return (
        <Table.Row className={classNames} disabled={isLoading} {...tableRowProps}>
            <Table.Cell>
                <Header disabled={isLoading} size="small">
                    <Header.Content>{UserHelper.getDisplayName(user)}</Header.Content>
                    <Header.Subheader>{formatMessage(UserHelper.getRoleDescription(user.accountType))}</Header.Subheader>
                </Header>
            </Table.Cell>

            <Table.Cell>{user.email}</Table.Cell>
            <Table.Cell>{mainClientCode}</Table.Cell>
            <Table.Cell className={styles.ellipsis} title={linkedClientCodes} style={{ maxWidth: '125px' }}>{linkedClientCodes}</Table.Cell>
            <Table.Cell>{user.isDisabled && formatMessage(m.inactiveUserLabel)}</Table.Cell>
        </Table.Row>
    );
};