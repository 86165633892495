import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedMessage, FormattedNumber } from 'react-intl';
import { Feed, SemanticICONS, Popup, Icon, List } from 'semantic-ui-react';

import { ServiceCallUpdate, SubcontractorUpdate, ActivityType, SubcontractorAssignedUpdate } from '../../../state/models';
import { StringHelper } from '../../../state/utils';

export interface SubcontractorAssignedActivityProps {
    activity: SubcontractorAssignedUpdate;
}

const m = defineMessages({
    summary: { id: 'SubcontractorAssignedActivity.summary', defaultMessage: 'A subcontractor was assigned to this service call.' },
    summaryWithSubcontractorFormat: { id: 'SubcontractorAssignedActivity.summary_with_subcontractor', defaultMessage: 'The subcontractor \'{subcontractor}\' was assigned to this service call.' },
});

class SubcontractorAssignedActivity extends React.Component<SubcontractorAssignedActivityProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { activity } = this.props;
        const { subcontractorName } = activity;
        const hasSubcontractorName = subcontractorName && StringHelper.hasValue(subcontractorName);

        return (
            <Feed.Event key={activity.id}>
                <Feed.Label icon="id card outline" />
                <Feed.Content>
                    <Popup
                        trigger={<Feed.Date content={moment.utc(activity.createdOn).fromNow()} />}
                        content={moment.utc(activity.createdOn).format('LL')}
                    />

                    <Feed.Summary>
                        {hasSubcontractorName && <FormattedMessage {...m.summaryWithSubcontractorFormat} values={{ subcontractor: subcontractorName }} />}
                        {!hasSubcontractorName && <FormattedMessage {...m.summary} />}
                    </Feed.Summary>
                </Feed.Content>
            </Feed.Event>
        );
    }
}

const connectedComponent = injectIntl(SubcontractorAssignedActivity);
export { connectedComponent as SubcontractorAssignedActivity };