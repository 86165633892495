import React from "react";
import { Controller, FieldPathValue, useFormContext, Validate } from "react-hook-form";
import { defineMessages, useIntl } from "react-intl";
import { Form, Header } from "semantic-ui-react";
import { PasswordInput } from "../../../../../components/common";
import { StringHelper } from "../../../../../state/utils";
import { UserEdit } from "../../../../administration/users/models";
import { UserProfileEdit } from "../../../models";

interface OwnProps {
}

export type PasswordChangeSectionProps =
    & OwnProps;

const m = defineMessages({
    sectionTitle: { id: 'PasswordChangeSection.sectionTitle', defaultMessage: 'Change your password' },
    sectionSubtitle: { id: 'PasswordChangeSection.sectionSubtitle', defaultMessage: 'Choose a complex password with a minimum of 8 characters.' },
    currentPassword: { id: 'UserProfilePage.current_password', defaultMessage: 'Current password' },
    currentPasswordRequired: { id: 'UserProfilePage.currentPasswordRequired', defaultMessage: 'You need to specify your current password before you can specify a new one.' },
    newPassword: { id: 'UserProfilePage.new_password', defaultMessage: 'New password' },
    newPasswordMinimumLength: { id: 'UserProfilePage.newPasswordMinimumLength', defaultMessage: 'Your new password must have at least 8 characters.' },
    newPasswordRequired: { id: 'UserProfilePage.newPasswordRequired', defaultMessage: 'Your new password is required.' },
    newPasswordSpecialCharacter: { id: 'UserProfilePage.newPasswordSpecialCharacter', defaultMessage: 'Your new password must have at least one special character (e.g. !@#$%^&).' },
    newPasswordConfirmation: { id: 'UserProfilePage.new_password_confirmation', defaultMessage: 'New password confirmation' },
    passwordConfirmationRequired: { id: 'UserProfilePage.passwordConfirmationRequired', defaultMessage: 'Please re-enter your new password in the password confirmation field.' },
    passwordConfirmationInvalid: { id: 'UserProfilePage.passwordConfirmationInvalid', defaultMessage: 'The password confirmation does not match the new password.' },
});

export const PasswordChangeSection: React.FC<PasswordChangeSectionProps> = (props) => {
    const { formatMessage } = useIntl();
    const { control, formState: { errors }, setValue, getValues } = useFormContext<UserProfileEdit>();

    const isCurrentPasswordRequired: Validate<string | undefined> = (value: string | undefined): string | undefined => {
        const isNewPasswordSet = StringHelper.hasValue(getValues('newPassword'));
        const isCurrentPasswordSet = StringHelper.hasValue(value);

        return isNewPasswordSet && !isCurrentPasswordSet
            ? formatMessage(m.currentPasswordRequired)
            : undefined;
    }

    const isNewPasswordRequired: Validate<string | undefined> = (value: string | undefined): string | undefined => {
        const isCurrentPasswordSet = StringHelper.hasValue(getValues('currentPassword'));
        const isNewPasswordSet = StringHelper.hasValue(value);

        return isCurrentPasswordSet && !isNewPasswordSet
            ? formatMessage(m.newPasswordRequired)
            : undefined;
    }

    const isPasswordConfirmationRequired: Validate<string | undefined> = (value: string | undefined): string | undefined => {
        const newPassword = getValues('newPassword');
        const isConfirmationRequired = StringHelper.hasValue(newPassword);
        const isConfirmationSet = StringHelper.hasValue(value);

        return isConfirmationRequired && !isConfirmationSet
            ? formatMessage(m.passwordConfirmationRequired)
            : undefined;
    }

    const isPasswordConfirmationSameAsNewPassword: Validate<string | undefined> = (value: string | undefined): string | undefined => {
        const newPassword = getValues('newPassword');
        const doesConfirmationMatchesNewPassword = value === newPassword;

        return !doesConfirmationMatchesNewPassword
            ? formatMessage(m.passwordConfirmationInvalid)
            : undefined;
    }

    return (
        <>
            <Header content={formatMessage(m.sectionTitle)} subheader={formatMessage(m.sectionSubtitle)} />
            <Controller
                name="currentPassword"
                control={control}
                rules={{ validate: {
                    requiredIfNewPasswordIsSet: isCurrentPasswordRequired
                }}}
                render={({ field }) => (
                    <Form.Field error={errors.currentPassword != null}>
                        <label>{formatMessage(m.currentPassword)}</label>
                        <PasswordInput
                            onChange={(e, data) => setValue('currentPassword', data.value)}
                            placeholder={formatMessage(m.currentPassword)}
                        />
                    </Form.Field>
                )}
            />

            <Controller
                name="newPassword"
                control={control}
                rules={{
                    minLength: { value: 8, message: formatMessage(m.newPasswordMinimumLength) },
                    pattern: { value: /(?=.*[!@#\$%\^&])/, message: formatMessage(m.newPasswordSpecialCharacter)},
                    validate: {
                        required: isNewPasswordRequired
                    }
                }}
                render={({ field }) => (
                    <Form.Field error={errors.newPassword != null}>
                        <label>{formatMessage(m.newPassword)}</label>
                        <PasswordInput
                            onChange={(e, data) => setValue('newPassword', data.value)}
                            placeholder={formatMessage(m.newPassword)}
                            showPasswordRequirements
                        />
                    </Form.Field>
                )}
            />

            <Controller
                name="newPasswordConfirmation"
                control={control}
                rules={{ validate: {
                    required: isPasswordConfirmationRequired,
                    matches: isPasswordConfirmationSameAsNewPassword
                }}}
                render={({ field }) => (
                    <Form.Field error={errors.newPasswordConfirmation != null}>
                        <label>{formatMessage(m.newPasswordConfirmation)}</label>
                        <PasswordInput
                            onChange={(e, data) => setValue('newPasswordConfirmation', data.value)}
                            placeholder={formatMessage(m.newPasswordConfirmation)}
                        />
                    </Form.Field>
                )}
            />
        </>
    );
};