import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Input, InputOnChangeData } from 'semantic-ui-react';

export interface SearchServiceCallControlProps {
    onSearch: (query: string) => void;
}

interface SearchServiceCallControlState {
    searchTerm: string;
}

const m = defineMessages({
    placeholder: { id: 'SearchServiceCallControl.placeholder', defaultMessage: 'Search service call' },
});

class SearchServiceCallControl extends React.Component<SearchServiceCallControlProps & WrappedComponentProps, SearchServiceCallControlState> {
    public constructor(props: SearchServiceCallControlProps & WrappedComponentProps) {
        super(props);

        this.state = { searchTerm: '' };
    }

    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <Input 
                placeholder={formatMessage(m.placeholder)}
                value={this.state.searchTerm}
                onChange={this.handleChange}
                action={{ icon: 'search', onClick: () => this.props.onSearch(this.state.searchTerm) }}
            />
        );
    }

    private handleChange = (_event: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) => {
        this.setState({ searchTerm: data.value });
    }
}

const connectedComponent = injectIntl(SearchServiceCallControl);
export { connectedComponent as SearchServiceCallControl };