import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { PageHeader } from '../../../components/common';
import { AppointmentsTable, AppointmentsList } from '../../../components/service-calls';
import { actionCreators as AppointmentsActions } from '../../../state/ducks/appointments';
import { TechnicianRepair } from '../../../state/models';
import { getPendingAppointments } from '../../../state/ducks/appointments/selectors';
import { useResponsive } from 'utils/responsive';

const m = defineMessages({
    pageTitle: { id: 'TechnicianAppointmentsPage.page_title', defaultMessage: 'My appointments' },
    pageSubtitle: { id: 'TechnicianAppointmentsPage.page_subtitle', defaultMessage: 'View all your upcoming appointments and their related service call.' },
    noAppointmentsTitle: { id: 'TechnicianAppointmentsPage.no_appointments_title', defaultMessage: 'No upcoming appointments' },
    noAppointmentsSubtitle: { id: 'TechnicianAppointmentsPage.no_appointments_subtitle', defaultMessage: 'You have no upcoming service call appointments.' }
});

export const TechnicianAppointmentsPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const { isMobile } = useResponsive();
    const dispatch = useDispatch();
    const history = useHistory();
    
    useEffect(() => {
        dispatch(AppointmentsActions.loadPending());
    }, [dispatch]);

    const appointments = useSelector(getPendingAppointments);

    const navigateToTechnicianPage = (appointment: TechnicianRepair) => 
        history.push(`/service-calls/${appointment.serviceCallId}/technician-repairs/${appointment.id}/prepare`);

    return (
        <div>
            <PageHeader
                iconName="car"
                title={formatMessage(m.pageTitle)}
                subtitle={formatMessage(m.pageSubtitle)}
            />

            {!isMobile &&
                <AppointmentsTable
                    appointments={appointments}
                    onViewDetails={navigateToTechnicianPage}
                    emptyTitle={formatMessage(m.noAppointmentsTitle)}
                    emptySubtitle={formatMessage(m.noAppointmentsSubtitle)}
                />
            }

            {isMobile &&
                <AppointmentsList
                    appointments={appointments}
                    onViewDetails={navigateToTechnicianPage}
                    emptyTitle={formatMessage(m.noAppointmentsTitle)}
                    emptySubtitle={formatMessage(m.noAppointmentsSubtitle)}
                />
            }
        </div>
    );
};