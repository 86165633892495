import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { DropdownProps, Form, Input, InputOnChangeData } from 'semantic-ui-react';
import { CountriesDropdown, ProvincesDropdown, SectionHeader } from '../common';
import { CustomerEdit, Address } from '../../state/models';

interface CustomerInformationFormOwnProps {
    customer: CustomerEdit;
    hideHeader?: boolean;
    onChange: (customer: CustomerEdit) => void;
}

export type CustomerInformationFormProps =
    & CustomerInformationFormOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    sectionTitle: { id: 'CustomerInformationForm.section_title', defaultMessage: 'Customer information' },
    sectionSubtitle: { id: 'CustomerInformationForm.section_subtitle', defaultMessage: 'Provide the contact information for the customer that is having the issue.' },
    name: { id: 'CustomerInformationForm.name', defaultMessage: 'Customer name' },
    emailLabel: { id: 'CustomerInformationForm.emailLabel', defaultMessage: 'Customer email' },
    emailPlaceholder: { id: 'CustomerInformationForm.emailPlaceholder', defaultMessage: 'Customer email' },
    address: { id: 'CustomerInformationForm.address', defaultMessage: 'Address' },
    address1Placeholder: { id: 'CustomerInformationForm.address1_placeholder', defaultMessage: 'Street address' },
    address2Placeholder: { id: 'CustomerInformationForm.address2_placeholder', defaultMessage: 'Apartment, suite, unit, etc.' },
    cityPlaceholder: { id: 'CustomerInformationForm.city_placeholder', defaultMessage: 'City' },
    postalCodePlaceholder: { id: 'CustomerInformationForm.postal_code_placeholder', defaultMessage: 'Zip/Postal code' },
    countryPlaceholder: { id: 'CustomerInformationForm.country_placeholder', defaultMessage: 'Country' },
    provincePlaceholder: { id: 'CustomerInformationForm.province_placeholder', defaultMessage: 'State/Province' },
    mainPhoneNumber: { id: 'CustomerInformationForm.main_phone_number', defaultMessage: 'Main phone number' },
    otherPhoneNumber: { id: 'CustomerInformationForm.other_phone_number', defaultMessage: 'Other phone number' },
});

class CustomerInformationForm extends React.Component<CustomerInformationFormProps, {}> {
    private customerNameInput?: Input;

    public componentDidMount() {
        if (this.customerNameInput != null) {
            this.customerNameInput.focus();
        }
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const { customer } = this.props;
        const hasProvincesDefined = ['ca', 'us', 'au'].find(countryCode => countryCode === customer.address?.countryCode) != null;

        return (
            <div>
                {!this.props.hideHeader &&
                    <SectionHeader
                        title={formatMessage(m.sectionTitle)}
                        subtitle={formatMessage(m.sectionSubtitle)}
                    />
                }
                <Form style={{ maxWidth: 450 }}>
                    <Form.Field>
                        <label>{formatMessage(m.name)}</label>
                        <Input
                            id="name"
                            placeholder={formatMessage(m.name)}
                            ref={(input: Input) => { this.customerNameInput = input; }}
                            value={customer.name || ''}
                            onChange={this.handleChange}
                        />
                    </Form.Field>
                    <Form.Input
                        id="address1"
                        value={customer.address?.address1 || ''}
                        label={formatMessage(m.address)}
                        placeholder={formatMessage(m.address1Placeholder)}
                        onChange={this.handleAddressChange}
                    />
                    <Form.Input
                        id="address2"
                        value={customer.address?.address2 || ''}
                        placeholder={formatMessage(m.address2Placeholder)}
                        onChange={this.handleAddressChange}
                    />
                    <Form.Group>
                        <Form.Input
                            id="city"
                            value={customer.address?.city || ''}
                            placeholder={formatMessage(m.cityPlaceholder)}
                            onChange={this.handleAddressChange}
                            width={8}
                        />
                        <Form.Input
                            id="postalCode"
                            value={customer.address?.postalCode || ''}
                            placeholder={formatMessage(m.postalCodePlaceholder)}
                            onChange={this.handleAddressChange}
                            width={8}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field width={16}>
                            <CountriesDropdown
                                fluid={true}
                                id="countryCode"
                                value={customer.address?.countryCode}
                                placeholder={formatMessage(m.countryPlaceholder)}
                                onChange={this.handleCountryChange}
                            />
                        </Form.Field>
                        <Form.Field width={16}>
                            {hasProvincesDefined &&
                                <ProvincesDropdown
                                    clearable
                                    countryCode={customer.address?.countryCode}
                                    placeholder={formatMessage(m.provincePlaceholder)}
                                    value={customer.address?.provinceCode}
                                    onChange={(e, data) => this.handleProvinceChange(data.value as string || '')}
                                />
                            }
                            {!hasProvincesDefined &&
                                <Input
                                    placeholder={formatMessage(m.provincePlaceholder)}
                                    value={customer.address?.provinceCode}
                                    onChange={(e, data) => this.handleProvinceChange(data.value as string || '')}
                                />
                            }
                        </Form.Field>
                    </Form.Group>
                    <Form.Field>
                        <label>{formatMessage(m.emailLabel)}</label>
                        <Input
                            id="email"
                            placeholder={formatMessage(m.emailPlaceholder)}
                            value={customer.email || ''}
                            onChange={this.handleChange}
                        />
                    </Form.Field>
                    <Form.Group>
                        <Form.Input
                            id="mainPhoneNumber"
                            value={customer.mainPhoneNumber || ''}
                            onChange={this.handleChange}
                            label={formatMessage(m.mainPhoneNumber)}
                            placeholder={formatMessage(m.mainPhoneNumber)}
                            width={8}
                        />
                        <Form.Input
                            id="otherPhoneNumber"
                            value={customer.otherPhoneNumber || ''}
                            onChange={this.handleChange}
                            label={formatMessage(m.otherPhoneNumber)}
                            placeholder={formatMessage(m.otherPhoneNumber)}
                            width={8}
                        />
                    </Form.Group>
                </Form>
            </div>
        );
    }

    private handleAddressChange = (e: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) => {
        const fieldId = e.currentTarget.id;
        const fieldValue = data.value as string;

        this.props.onChange(new CustomerEdit({
            ...this.props.customer,
            address: {
                ...this.props.customer.address,
                [fieldId]: fieldValue
            }
        }));
    }

    private handleCountryChange = (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const country = data.value as string;
        this.props.onChange(new CustomerEdit({
            ...this.props.customer,
            address: {
                ...this.props.customer.address,
                countryCode: country
            }
        }));
    }

    private handleProvinceChange = (province: string) => {
        this.props.onChange(new CustomerEdit({
            ...this.props.customer,
            address: {
                ...this.props.customer.address,
                provinceCode: province
            }
        }));
    }

    private handleChange = (e: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) => {
        this.props.onChange(new CustomerEdit({
            ...this.props.customer,
            [e.currentTarget.id]: data.value
        }));
    }
}

const connectedCustomerInformationForm = injectIntl(CustomerInformationForm);
export { connectedCustomerInformationForm as CustomerInformationForm };