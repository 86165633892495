import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Feed, Popup, Modal, Header } from 'semantic-ui-react';

import { NoteAddedUpdate } from '../../../state/models';
import { StringHelper } from '../../../state/utils';
import { UserHelper } from '../../../helpers';

export interface NoteAddedActivityProps {
    activity: NoteAddedUpdate;
}

const m = defineMessages({
    title: { id: 'NoteAddedActivity.title', defaultMessage: '{author} added a note to the service call.' },
    readMore: { id: 'NoteAddedActivity.read_more', defaultMessage: 'Read more' }
});

class NoteAddedActivity extends React.Component<NoteAddedActivityProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { activity } = this.props;

        return (
            <Feed.Event key={activity.id}>
                <Feed.Label icon="pencil" />
                <Feed.Content>
                    <Popup
                        trigger={<Feed.Date content={moment.utc(activity.createdOn).fromNow()} />}
                        content={moment.utc(activity.createdOn).format('LL')}
                    />

                    <Feed.Summary>
                        {formatMessage(m.title, { author: UserHelper.getDisplayName(activity.createdBy) })}
                    </Feed.Summary>

                    {StringHelper.hasValue(activity.note) &&
                        <Feed.Extra text={true}>
                            {this.renderNoteSummary()}
                            {this.renderNoteContent()}
                        </Feed.Extra>
                    }
                </Feed.Content>
            </Feed.Event>
        );
    }

    private renderNoteSummary() {
        return (
            <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                {this.props.activity.note}
            </div>
        );
    }

    private renderNoteContent() {
        const { formatMessage } = this.props.intl;

        return (
            <Modal closeIcon={true} trigger={<a>{formatMessage(m.readMore)}</a>}>
                <Modal.Header>
                    {formatMessage(m.title, { author: UserHelper.getDisplayName(this.props.activity.createdBy) })}
                </Modal.Header>
                <Modal.Content scrolling={true}>
                    <Modal.Description>
                        <p style={{ whiteSpace: 'pre-line' }}>
                            {this.props.activity.note}
                        </p>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        );
    }
}

const connectedComponent = injectIntl(NoteAddedActivity);
export { connectedComponent as NoteAddedActivity };