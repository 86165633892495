import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { ServiceCallInspection } from '../../../state/models';
import { Label, LabelProps, Popup } from 'semantic-ui-react';
import { commonMessages } from '../../../constants';

interface InspectionStatusLabelOwnProps {
    inspection: ServiceCallInspection;
}

export type InspectionStatusLabelProps =
    & InspectionStatusLabelOwnProps
    & WrappedComponentProps;

export enum InspectionStatus {
    unknown,
    toSchedule,
    toInspect,
    inspected,
    incomplete
}

const m = defineMessages({
    toSchedule: { id: 'InspectionStatusLabel.to_schedule', defaultMessage: 'Schedule the inspection with the client' },
    toInspect: { id: 'InspectionStatusLabel.to_inspect', defaultMessage: 'Inspect the furniture' },
    inspected: { id: 'InspectionStatusLabel.inspected', defaultMessage: 'Inspection completed' },
    incomplete: { id: 'InspectionStatusLabel.incomplete', defaultMessage: 'The inspection report is missing from this inspection' }
});

class InspectionStatusLabel extends React.Component<InspectionStatusLabelProps, {}> {
    public render() {
        return (
            <Popup
                content={this.getLabelTooltip(this.props.inspection)}
                trigger={this.renderLabel()}
            />
        );
    }

    private renderLabel() {
        return (
            <Label
                className="c-icon-label"
                {...this.getLabelProps(this.props.inspection)}
                circular={true}
            />
        );
    }

    private getStatus(inspection: ServiceCallInspection): InspectionStatus {
        if (inspection == null) {
            return InspectionStatus.unknown;
        }

        if (inspection.completedOn != null) {
            return InspectionStatus.inspected;
        }

        if (inspection.scheduledForDate == null) {
            return InspectionStatus.toSchedule;
        }

        if (moment.utc(inspection.scheduledForDate).isBefore(moment.utc())) {
            return InspectionStatus.incomplete;
        }

        return InspectionStatus.toInspect;
    }

    private getLabelTooltip(inspection: ServiceCallInspection): string {
        const { formatMessage } = this.props.intl;
        const status = this.getStatus(inspection);

        switch (status) {
            case InspectionStatus.toSchedule:
                return formatMessage(m.toSchedule);

            case InspectionStatus.toInspect:
                return formatMessage(m.toInspect);

            case InspectionStatus.inspected:
                return formatMessage(m.inspected);

            case InspectionStatus.incomplete:
                return formatMessage(m.incomplete);

            default:
                return formatMessage(commonMessages.unknown);
        }

    }

    private getLabelProps(inspection: ServiceCallInspection): LabelProps {
        const status = this.getStatus(inspection);

        switch (status) {
            case InspectionStatus.toSchedule:
                return {
                    color: 'orange',
                    icon: 'calendar alternate'
                };

            case InspectionStatus.toInspect:
                return {
                    color: 'teal',
                    icon: 'car'
                };

            case InspectionStatus.inspected:
                return {
                    color: 'green',
                    icon: 'check'
                };

            case InspectionStatus.incomplete:
                return {
                    color: 'yellow',
                    icon: 'warning'
                };

            default:
                return {
                    color: 'grey',
                    icon: 'question'
                };
        }
    }
}

const connectedComponent = injectIntl(InspectionStatusLabel);
export { connectedComponent as InspectionStatusLabel };