import React, { useMemo, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { ServiceCallView, DefectiveRatioGroup } from "containers/reporting/types";
import { Header } from "semantic-ui-react";
import { DefectiveItemQueryFilters } from "services/ServiceCallAnalysisService";
import { getReportingDateRange } from "state/models";
import { ReportedDefectiveItemsByItem } from "../ReportedDefectiveItemsByItem";
import { useDetailedStatisticsContext } from "../DetailedStatisticsProvider";
import { ViewSelector } from "./ViewSelector";
import { ReportedDefectiveItemsByCover } from "../ReportedDefectiveItemsByCover";
import { ReportedDefectiveItemsByProductType } from "../ReportedDefectiveItemsByProductType";
import { ReportedDefectiveItemsByWeeksSinceShipping } from "../ReportedDefectiveItemsByWeeksSinceShipping";
import { ReportedDefectiveItemsByDefectType } from "../ReportedDefectiveItemsByDefectType";
import { ReportedDefectiveItemsByDefectCategory } from "../ReportedDefectiveItemsByDefectCategory";
import { ReportedDefectiveItemsByDefectCause } from "../ReportedDefectiveItemsByDefectCause";
import { ServiceCallProblems } from "../ServiceCallProblems";
import { ReportedDefectiveItemsByWeeksSinceBilling } from "../ReportedDefectiveItemsByWeeksSinceBilling";
import { InspectionReportDescriptions } from "../InspectionReportDescriptions";
import { TechnicianReportDescriptions } from "../TechnicianReportDescriptions";
import { FactoryReportDescriptions } from "../FactoryReportDescriptions";

const m = defineMessages({
  selectedServiceCalls: { id: "ServiceCallsSection.Title", defaultMessage: "Selected service calls" },
});


export const ServiceCallsSection = () => {
  const { formatMessage } = useIntl();

  const [selectedView, setSelectedView] = useState<ServiceCallView>(
    ServiceCallView.ReportedDefectiveItemsByItem
  );

  const {
    defectiveRatios: { selectedGroupType, selectedGroupId, filters: defectiveRatiosFilters },
  } = useDetailedStatisticsContext();

  const { startDate, endDate } = useMemo(
    () => getReportingDateRange(defectiveRatiosFilters.dateFilter),
    [defectiveRatiosFilters.dateFilter]
  );

  const getFilterForGroupType = (groupType: DefectiveRatioGroup) =>
    selectedGroupType === groupType ? selectedGroupId : undefined;

  const defectiveItemQueryFilters: DefectiveItemQueryFilters = {
    startDate,
    endDate,
    itemId: getFilterForGroupType(DefectiveRatioGroup.ByItem) as string,
    itemCoverId: getFilterForGroupType(DefectiveRatioGroup.ByCover) as string,
    productTypeId: getFilterForGroupType(DefectiveRatioGroup.ByProductType) as string,
    clientId: getFilterForGroupType(DefectiveRatioGroup.ByClient) as string,
    provinceCode: getFilterForGroupType(DefectiveRatioGroup.ByProvince) as string,
    billingMonth: getFilterForGroupType(DefectiveRatioGroup.ByBillingMonth) as number,
  };

  const componentByView = {
    [ServiceCallView.ReportedDefectiveItemsByItem]: () => (
      <ReportedDefectiveItemsByItem filters={defectiveItemQueryFilters} />
    ),
    [ServiceCallView.ReportedDefectiveItemsByCover]: () => (
      <ReportedDefectiveItemsByCover filters={defectiveItemQueryFilters} />
    ),
    [ServiceCallView.ReportedDefectiveItemsByProductType]: () => (
      <ReportedDefectiveItemsByProductType filters={defectiveItemQueryFilters} />
    ),
    [ServiceCallView.ReportedDefectiveItemsByWeeksSinceShipping]: () => (
      <ReportedDefectiveItemsByWeeksSinceShipping filters={defectiveItemQueryFilters} />
    ),
    [ServiceCallView.ReportedDefectiveItemsByWeeksSinceBilling]: () => (
      <ReportedDefectiveItemsByWeeksSinceBilling filters={defectiveItemQueryFilters} />
    ),
    [ServiceCallView.ReportedDefectiveItemsByDefectType]: () => (
      <ReportedDefectiveItemsByDefectType filters={defectiveItemQueryFilters} />
    ),
    [ServiceCallView.ReportedDefectiveItemsByDefectCategory]: () => (
      <ReportedDefectiveItemsByDefectCategory filters={defectiveItemQueryFilters} />
    ),
    [ServiceCallView.ReportedDefectiveItemsByDefectCause]: () => (
      <ReportedDefectiveItemsByDefectCause filters={defectiveItemQueryFilters} />
    ),
    [ServiceCallView.Problems]: () => (
      <ServiceCallProblems filters={defectiveItemQueryFilters} />
    ),
    [ServiceCallView.InspectionReportDescriptions]: () => (
      <InspectionReportDescriptions filters={defectiveItemQueryFilters} />
    ),
    [ServiceCallView.TechnicianReportDescriptions]: () => (
      <TechnicianReportDescriptions filters={defectiveItemQueryFilters} />
    ),
    [ServiceCallView.FactoryReportDescriptions]: () => (
      <FactoryReportDescriptions filters={defectiveItemQueryFilters} />
    ),
  };

  return (
    <>
      <div style={{ marginBottom: "1.5rem" }}>
        <Header>{formatMessage(m.selectedServiceCalls)}</Header>
        <ViewSelector selectedView={selectedView} onChange={setSelectedView} />
      </div>
      {componentByView[selectedView] && componentByView[selectedView]()}
    </>
  );
};
