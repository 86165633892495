export enum ActionTypeKeys {
    LOAD_OVERDUE_CALL_COUNT_REQUEST = 'LOAD_OVERDUE_CALL_COUNT_REQUEST',
    LOAD_OVERDUE_CALL_COUNT_SUCCESS = 'LOAD_OVERDUE_CALL_COUNT_SUCCESS',
    LOAD_OVERDUE_CALL_COUNT_FAILURE = 'LOAD_OVERDUE_CALL_COUNT_FAILURE',

    LOAD_STALLED_UNASSIGNED_CALL_COUNT_REQUEST = 'LOAD_STALLED_UNASSIGNED_CALL_COUNT_REQUEST',
    LOAD_STALLED_UNASSIGNED_CALL_COUNT_SUCCESS = 'LOAD_STALLED_UNASSIGNED_CALL_COUNT_SUCCESS',
    LOAD_STALLED_UNASSIGNED_CALL_COUNT_FAILURE = 'LOAD_STALLED_UNASSIGNED_CALL_COUNT_FAILURE',

    LOAD_CALLS_CREATED_REQUEST = 'REPORTING_LOAD_CALLS_CREATED_REQUEST',
    LOAD_CALLS_CREATED_SUCCESS = 'REPORTING_LOAD_CALLS_CREATED_SUCCESS',
    LOAD_CALLS_CREATED_FAILURE = 'REPORTING_LOAD_CALLS_CREATED_FAILURE',

    LOAD_CALLS_CLOSED_REQUEST = 'REPORTING_LOAD_CALLS_CLOSED_REQUEST',
    LOAD_CALLS_CLOSED_SUCCESS = 'REPORTING_LOAD_CALLS_CLOSED_SUCCESS',
    LOAD_CALLS_CLOSED_FAILURE = 'REPORTING_LOAD_CALLS_CLOSED_FAILURE',

    LOAD_CALLS_ACTIVE_REQUEST = 'REPORTING_LOAD_CALLS_ACTIVE_REQUEST',
    LOAD_CALLS_ACTIVE_SUCCESS = 'REPORTING_LOAD_CALLS_ACTIVE_SUCCESS',
    LOAD_CALLS_ACTIVE_FAILURE = 'REPORTING_LOAD_CALLS_ACTIVE_FAILURE',

    LOAD_AVERAGE_RESOLUTION_TIME_REQUEST = 'REPORTING_LOAD_AVERAGE_RESOLUTION_TIME_REQUEST',
    LOAD_AVERAGE_RESOLUTION_TIME_SUCCESS = 'REPORTING_LOAD_AVERAGE_RESOLUTION_TIME_SUCCESS',
    LOAD_AVERAGE_RESOLUTION_TIME_FAILURE = 'REPORTING_LOAD_AVERAGE_RESOLUTION_TIME_FAILURE',

    LOAD_PROBLEMS_FREQUENCY_REQUEST = 'REPORTING_LOAD_PROBLEMS_FREQUENCY_REQUEST',
    LOAD_PROBLEMS_FREQUENCY_SUCCESS = 'REPORTING_LOAD_PROBLEMS_FREQUENCY_SUCCESS',
    LOAD_PROBLEMS_FREQUENCY_FAILURE = 'REPORTING_LOAD_PROBLEMS_FREQUENCY_FAILURE',

    LOAD_DEFECTIVE_PRODUCTS_FREQUENCY_REQUEST = 'REPORTING_LOAD_DEFECTIVE_PRODUCTS_FREQUENCY_REQUEST',
    LOAD_DEFECTIVE_PRODUCTS_FREQUENCY_SUCCESS = 'REPORTING_LOAD_DEFECTIVE_PRODUCTS_FREQUENCY_SUCCESS',
    LOAD_DEFECTIVE_PRODUCTS_FREQUENCY_FAILURE = 'REPORTING_LOAD_DEFECTIVE_PRODUCTS_FREQUENCY_FAILURE',

    LOAD_DEFECTIVE_COVERS_FREQUENCY_REQUEST = 'REPORTING_LOAD_DEFECTIVE_COVERS_FREQUENCY_REQUEST',
    LOAD_DEFECTIVE_COVERS_FREQUENCY_SUCCESS = 'REPORTING_LOAD_DEFECTIVE_COVERS_FREQUENCY_SUCCESS',
    LOAD_DEFECTIVE_COVERS_FREQUENCY_FAILURE = 'REPORTING_LOAD_DEFECTIVE_COVERS_FREQUENCY_FAILURE',

    LOAD_CALLS_REPAIRS_DISTRIBUTION_REQUEST = 'REPORTING_LOAD_CALLS_REPAIRS_DISTRIBUTION_REQUEST',
    LOAD_CALLS_REPAIRS_DISTRIBUTION_SUCCESS = 'REPORTING_LOAD_CALLS_REPAIRS_DISTRIBUTION_SUCCESS',
    LOAD_CALLS_REPAIRS_DISTRIBUTION_FAILURE = 'REPORTING_LOAD_CALLS_REPAIRS_DISTRIBUTION_FAILURE',

    LOAD_QUALITY_RATIOS_REQUEST = 'REPORTING_LOAD_QUALITY_RATIOS_REQUEST',
    LOAD_QUALITY_RATIOS_SUCCESS = 'REPORTING_LOAD_QUALITY_RATIOS_SUCCESS',
    LOAD_QUALITY_RATIOS_FAILURE = 'REPORTING_LOAD_QUALITY_RATIOS_FAILURE',

    LOAD_CALLS_CREATED_PER_MONTH_REQUEST = 'REPORTING_LOAD_CALLS_CREATED_PER_MONTH_REQUEST',
    LOAD_CALLS_CREATED_PER_MONTH_SUCCESS = 'REPORTING_LOAD_CALLS_CREATED_PER_MONTH_SUCCESS',
    LOAD_CALLS_CREATED_PER_MONTH_FAILURE = 'REPORTING_LOAD_CALLS_CREATED_PER_MONTH_FAILURE',

    LOAD_CURRENT_ACTIVE_CALLS_REQUEST = 'REPORTING_LOAD_CURRENT_ACTIVE_CALLS_REQUEST',
    LOAD_CURRENT_ACTIVE_CALLS_SUCCESS = 'REPORTING_LOAD_CURRENT_ACTIVE_CALLS_SUCCESS',
    LOAD_CURRENT_ACTIVE_CALLS_FAILURE = 'REPORTING_LOAD_CURRENT_ACTIVE_CALLS_FAILURE',

    LOAD_ACTIVE_CALLS_STATUS_REQUEST = 'REPORTING_LOAD_ACTIVE_CALLS_STATUS_REQUEST',
    LOAD_ACTIVE_CALLS_STATUS_SUCCESS = 'REPORTING_LOAD_ACTIVE_CALLS_STATUS_SUCCESS',
    LOAD_ACTIVE_CALLS_STATUS_FAILURE = 'REPORTING_LOAD_ACTIVE_CALLS_STATUS_FAILURE',

    LOAD_CLIENTS_ADOPTION_RATIO_REQUEST = 'REPORTING_LOAD_CLIENTS_ADOPTION_RATIO_REQUEST',
    LOAD_CLIENTS_ADOPTION_RATIO_SUCCESS = 'REPORTING_LOAD_CLIENTS_ADOPTION_RATIO_SUCCESS',
    LOAD_CLIENTS_ADOPTION_RATIO_FAILURE = 'REPORTING_LOAD_CLIENTS_ADOPTION_RATIO_FAILURE',

    LOAD_TOP_CLIENT_ADOPTERS_REQUEST = 'REPORTING_LOAD_TOP_CLIENT_ADOPTERS_REQUEST',
    LOAD_TOP_CLIENT_ADOPTERS_SUCCESS = 'REPORTING_LOAD_TOP_CLIENT_ADOPTERS_SUCCESS',
    LOAD_TOP_CLIENT_ADOPTERS_FAILURE = 'REPORTING_LOAD_TOP_CLIENT_ADOPTERS_FAILURE',

    LOAD_COUNTRIES_FILTER_REQUEST = 'REPORTING_LOAD_COUNTRIES_FILTER_REQUEST',
    LOAD_COUNTRIES_FILTER_SUCCESS = 'REPORTING_LOAD_COUNTRIES_FILTER_SUCCESS',
    LOAD_COUNTRIES_FILTER_FAILURE = 'REPORTING_LOAD_COUNTRIES_FILTER_FAILURE',

    LOAD_PROVINCES_FILTER_REQUEST = 'REPORTING_LOAD_PROVINCES_FILTER_REQUEST',
    LOAD_PROVINCES_FILTER_SUCCESS = 'REPORTING_LOAD_PROVINCES_FILTER_SUCCESS',
    LOAD_PROVINCES_FILTER_FAILURE = 'REPORTING_LOAD_PROVINCES_FILTER_FAILURE',

    SET_STATISTICS_FILTERS = 'REPORTING_SET_STATISTICS_FILTERS',
    SET_TRENDS_FILTERS = 'REPORTING_SET_TRENDS_FILTERS',
    SET_TRENDS_DISPLAY = 'REPORTING_SET_TRENDS_DISPLAY',
    SET_CLIENTS_ADOPTION_FILTERS = 'REPORTING_SET_CLIENTS_ADOPTION_FILTERS'
}