import { DefectiveItemsTableRow } from "./components/DefectiveItemsTable";
import { GroupedDefectiveItemCount } from "./types";

export const getDefectiveItemsTableRow = (
  groupedDefectiveItemCount: GroupedDefectiveItemCount[],
  groupName: (itemCount: GroupedDefectiveItemCount) => string | number,
  itemTotal: number
) : DefectiveItemsTableRow[] =>
  groupedDefectiveItemCount.map((group) => ({
    groupId: group.groupId,
    groupName: groupName(group),
    itemCount: group.itemCount,
    percent: itemTotal > 0 ? (group.itemCount / itemTotal) * 100 : 0,
  }));
