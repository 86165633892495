import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { SemanticCOLORS, Label, Popup, LabelProps } from 'semantic-ui-react';

export interface LinkedCallsLabelProps extends LabelProps {
    count: number;
}

const m = defineMessages({
    tooltip: { id: 'LinkedCallsLabel.tooltip', defaultMessage: 'There are {count} other service calls associated to this bill.' }
});

class LinkedCallsLabel extends React.Component<LinkedCallsLabelProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <Popup 
                trigger={this.renderLabel()} 
                content={formatMessage(m.tooltip, { count: this.props.count })}
                flowing={true}
            />
        );
    }

    private renderLabel() {
        const { count, intl, ...labelProps } = this.props;

        return (
            <Label 
                {...labelProps}
                content={this.props.count}
                color={this.getColor(this.props.count)}
                size="small"
                circular={true}
            />
        );
    }

    private getColor(count: number): SemanticCOLORS {
        if (count >= 10) {
            return 'black';
        } else if (count >= 5) {
            return 'red';
        } else if (count >= 3) {
            return 'orange';
        } else if (count >= 2) {
            return 'yellow';
        }

        return 'grey';
    }
}

const connectedComponent = injectIntl(LinkedCallsLabel);
export { connectedComponent as LinkedCallsLabel };