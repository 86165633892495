import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Card, Checkbox, Form, Grid, CheckboxProps } from 'semantic-ui-react';
import { BillDetail, Item, ServiceCallDefectiveItem, SelectableDefectiveItem, getItemLocalizedDescription } from '../../../state/models';
import { NumericInput, LoadingDimmer } from '../../common';
import { StringHelper, debounce } from '../../../state/utils';
import { SearchItemControl } from '../../after-sales/SearchItemControl';
import { commonMessages } from '../../../constants';

export interface DefectiveItemsCardProps {
    billItems: BillDetail[];
    defectiveItems: ServiceCallDefectiveItem[];
    isReadOnly?: boolean;
    isLoading?: boolean;

    itemsSearchResult?: Item[];
    isSearchingForItems?: boolean;
    onSearchForItems?: (searchTerm: string) => void;
    onDefectiveItemChange: (item: Item, quantity: number) => void;
    onSave: (defectiveItems: SelectableDefectiveItem[]) => void;
}

interface DefectiveItemsCardState {
    selectedItem?: Item;
    defectiveItems: SelectableDefectiveItem[];
}

const m = defineMessages({
    title: { id: 'DefectiveItemsCard.title', defaultMessage: 'Select the defective items' },
    subtitle: { id: 'DefectiveItemsCard.subtitle', defaultMessage: 'Select the items from the order where defects are present.'},
    coverDescriptionFormat: { id: 'DefectiveItemsCard.cover_description_format', defaultMessage: 'Cover: {description}' },
    noBillItemsTitle: { id: 'DefectiveItemsCard.no_bill_items_title', defaultMessage: 'No order details' },
    noBillItemsDescription: { id: 'DefectiveItemsCard.no_bill_items_description', defaultMessage: 'There\'s no order associated to this service call' },
    addItem: { id: 'DefectiveItemsCard.add_item', defaultMessage: 'Add Defective Item' }
});

class DefectiveItemsCard extends React.Component<DefectiveItemsCardProps & WrappedComponentProps, DefectiveItemsCardState> {
    public constructor(props: DefectiveItemsCardProps & WrappedComponentProps) {
        super(props);

        this.state = {
            defectiveItems: props.billItems.length > 0
                ? props.billItems.filter(x => x.item != null).map(x => this.mapBillItemToDefectiveItem(x, props.defectiveItems))
                : props.defectiveItems.map(this.mapDefectiveItemToSelectableDefectiveItem)
        };
    }

    public componentWillReceiveProps(newProps: DefectiveItemsCardProps & WrappedComponentProps) {
        this.setState({
            defectiveItems: newProps.billItems.length > 0
                ? newProps.billItems.filter(x => x.item != null).map(x => this.mapBillItemToDefectiveItem(x, newProps.defectiveItems))
                : newProps.defectiveItems.map(this.mapDefectiveItemToSelectableDefectiveItem)
        });
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const hasBill = this.props.billItems && this.props.billItems.length > 0;

        return (
            <Card fluid={true} color="orange" style={{height: '100%'}}>
                <Card.Content style={{flexGrow: 0}}>
                    <Card.Header content={formatMessage(m.title)} />
                    <Card.Meta content={formatMessage(m.subtitle)} />
                </Card.Content>
                <Card.Content>
                    <LoadingDimmer 
                        active={this.props.isLoading}
                        message={formatMessage(commonMessages.saving)}
                    />
                    <Form>
                        {this.renderDefectiveItems()}
                    </Form>
                </Card.Content>
                {!hasBill &&
                    <Card.Content style={{flexGrow: 0}}>
                        <Form>
                            <Form.Field>
                                <SearchItemControl
                                    isSearching={this.props.isSearchingForItems} 
                                    results={this.props.itemsSearchResult}
                                    onResultSelected={(item) => this.setState({ selectedItem: item }, () => this.addItem())}
                                    onSearch={this.props.onSearchForItems} 
                                />
                            </Form.Field>
                        </Form>
                    </Card.Content>
                }
            </Card>
        );
    }

    private renderDefectiveItems() {
        return this.state.defectiveItems
            .filter(x => StringHelper.hasValue(x.item.id))
            .sort((a, b) => this.sortItems(a, b))
            .map(x => this.renderDefectiveItem(x));
    }

    private sortItems(a: SelectableDefectiveItem, b: SelectableDefectiveItem): number {
        var sortByCode = a.item.id.localeCompare(b.item.id);
        if (sortByCode !== 0) {
            return sortByCode;
        }

        return a.billDetailId - b.billDetailId;
    }

    private renderDefectiveItem(defectiveItem: SelectableDefectiveItem) {
        const { item, itemCover } = defectiveItem;

        return (
            <Form.Field key={this.getItemId(defectiveItem)}>
                <Grid stackable={true}>
                    <Grid.Row>
                        <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={10} widescreen={12}>
                            <Checkbox
                                disabled={this.props.isReadOnly}
                                label={this.renderItemLabel(item, itemCover)}
                                checked={defectiveItem.quantityDefective.value > 0}
                                onChange={(_event, data: CheckboxProps) => this.changeDefectiveItemQuantity(defectiveItem, data.checked ? defectiveItem.quantityOrdered.value : 0)}
                            />
                        </Grid.Column>

                        <Grid.Column textAlign="right" mobile={16} tablet={16} computer={16} largeScreen={6} widescreen={4}>
                            <NumericInput 
                                value={defectiveItem.quantityDefective.value}
                                unit={defectiveItem.quantityDefective.unit}
                                maximumValue={defectiveItem.quantityOrdered.value}
                                onChange={(value) => this.changeDefectiveItemQuantity(defectiveItem, value)}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form.Field>
        );
    }

    private getItemId(selectableItem: SelectableDefectiveItem): string | number {
        if (selectableItem.billDetailId > 0) {
            return selectableItem.billDetailId;
        } else if (StringHelper.hasValue(selectableItem.id)) {
            return selectableItem.id;
        } else if (selectableItem.item != null) {
            return selectableItem.item.id;
        }

        return 0;
    }

    private changeDefectiveItemQuantity = (defectiveItem: SelectableDefectiveItem, newQuantity: number) => {
        // Exclude the defective item from the array. We are doing this to update the item, and read it to the array later on.
        let defectiveItems = this.state.defectiveItems.filter(x => x.billDetailId !== defectiveItem.billDetailId
            || (x.billDetailId === 0 && StringHelper.trim(x.item.id) !== StringHelper.trim(defectiveItem.item.id)));
        defectiveItem.quantityDefective.value = newQuantity;
        defectiveItems = defectiveItems.concat(defectiveItem);

        this.setState({ defectiveItems: defectiveItems });
        this.saveChanges(defectiveItems);
    }

    // tslint:disable-next-line:member-ordering
    private saveChanges: (defectiveItems: SelectableDefectiveItem[]) => void = 
        debounce((defectiveItems: SelectableDefectiveItem[]) => this.props.onSave(defectiveItems), 1000);

    private addItem = () => {
        if (this.state.selectedItem != null) {
            const isAlreadyAdded = this.state.defectiveItems.filter(x => x.item.id === this.state.selectedItem!.id).length > 0;

            if (!isAlreadyAdded) {
                const defectiveItem: SelectableDefectiveItem = {
                    id: this.state.selectedItem.id,
                    billDetailId: 0,
                    item: this.state.selectedItem,
                    itemCover: undefined,
                    quantityDefective: { value: 1, unit: 'EA' },
                    quantityOrdered: { value: 99, unit: 'EA' }
                };

                let newDefectiveItems = this.state.defectiveItems.concat(defectiveItem);
    
                this.props.onSave(newDefectiveItems);
            }
        }
    }

    private renderItemLabel(item: Item, itemCover?: Item) {
        const { formatMessage, locale } = this.props.intl;

        return (
            <label>
                {this.renderItemIdentifier(item)}
                {getItemLocalizedDescription(item, locale)}
                {itemCover != null && 
                    <small className="meta" style={{display: 'block'}}>
                        {formatMessage(m.coverDescriptionFormat, { description: getItemLocalizedDescription(itemCover, locale) })}
                    </small>
                }
            </label>
        );
    }

    private mapBillItemToDefectiveItem = (billItem: BillDetail, defectiveItems: ServiceCallDefectiveItem[]): SelectableDefectiveItem => {
        const item = billItem.item;
        const itemCover = billItem.itemCover;

        // Try to find the matching defective item based on:
        // 1. The bill detail identifier (ideal case) OR
        // 2. The item code and item cover code (not ideal, because there can be duplicates)
        // 3. The item code only (for parts and accessories that don't have item covers)
        const defectiveItem = defectiveItems.filter(x => x.billDetailId === billItem.id 
            || (x.billDetailId === 0 && itemCover != null && StringHelper.trim(x.itemId) === StringHelper.trim(item.id) && StringHelper.trim(x.itemCoverId) === StringHelper.trim(itemCover.id))
            || (x.billDetailId === 0 && StringHelper.trim(x.itemId) === StringHelper.trim(item.id)));

        const defectiveItemCount = defectiveItem.length > 0 ? defectiveItem[0].itemQuantity : 0;

        return {
            id: billItem.itemId,
            billDetailId: billItem.id,
            item: item,
            itemCover: billItem.itemCover,
            quantityOrdered: billItem.quantityOrdered,
            quantityDefective: { value: defectiveItemCount, unit: billItem.quantityOrdered.unit },
        };
    }

    private mapDefectiveItemToSelectableDefectiveItem = (defectiveItem: ServiceCallDefectiveItem): SelectableDefectiveItem => {
        return {
            id: defectiveItem.itemId,
            billDetailId: 0,
            item: defectiveItem.item,
            itemCover: defectiveItem.itemCover,
            quantityDefective: { value: defectiveItem.itemQuantity, unit: 'EA' },
            quantityOrdered: { value: 99, unit: 'EA' }
        };
    }

    private renderItemIdentifier(item: Item) {
        const hasModelAndType = item.productModel 
            && StringHelper.hasValue(item.productModel.id) 
            && item.productType
            && StringHelper.hasValue(item.productType.id);

        const itemIdentifier = hasModelAndType
            ? item.productModel!.id + '-' + item.productType!.id
            : item.id;

        return hasModelAndType
            ? <strong style={{display: 'inline-block', marginRight: 5}}>{itemIdentifier}</strong>
            : (null);
    }
}

const intlComponent = injectIntl(DefectiveItemsCard);
export { intlComponent as DefectiveItemsCard };