import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { StatisticsFilters, DateFilter, RegionFilter, initialDateFilter, initialRegionFilter, DateFilterType } from '../../state/models';
import { FilterPopup } from '../common';
import { FilterByDateSection } from './FilterByDateSection';
import { FilterByRegionSection } from './FilterByRegionSection';
import { FilterByClientSection } from './FilterByClientSection';
import { initialClientFilter, ClientFilter } from '../../state/models/filters/ClientFilter';

interface StatisticsFiltersPopupOwnProps {
    filters: StatisticsFilters;
    onApply: (filters: StatisticsFilters) => void;
    onChangeCountry: (countryCode: string) => void;
    onSearchForClient: (query: string) => void;
}

interface StatisticsFiltersPopupState {
    appliedFilters: StatisticsFilters;
    pendingFilters: StatisticsFilters;
}

export type StatisticsFiltersPopupProps =
    & StatisticsFiltersPopupOwnProps
    & WrappedComponentProps;

class StatisticsFiltersPopup extends React.Component<StatisticsFiltersPopupProps, StatisticsFiltersPopupState> {
    public constructor(props: StatisticsFiltersPopupProps) {
        super(props);

        this.state = { 
            appliedFilters: props.filters,
            pendingFilters: props.filters,
        };
    }

    public componentWillReceiveProps(props: StatisticsFiltersPopupProps) {
        this.setState((current) => ({ 
            ...current,
            appliedFilters: props.filters,
            pendingFilters: {
                ...current.pendingFilters,
                regionFilter: {
                    ...current.pendingFilters.regionFilter,
                    areCountryCodesLoading: props.filters.regionFilter.areCountryCodesLoading,
                    areProvinceCodesLoading: props.filters.regionFilter.areProvinceCodesLoading,
                    availableCountryCodes: props.filters.regionFilter.availableCountryCodes,
                    availableProvinceCodes: props.filters.regionFilter.availableProvinceCodes
                },
                clientFilter: {
                    ...current.pendingFilters.clientFilter,
                    isSearching: props.filters.clientFilter.isSearching,
                    clientsSearchResults: props.filters.clientFilter.clientsSearchResults
                }
            }
        }));
    }

    public render() {
        const hasFiltersApplied = this.state.appliedFilters.dateFilter.type !== initialDateFilter.type
            || this.state.appliedFilters.regionFilter.countryCode !== initialRegionFilter.countryCode
            || this.state.appliedFilters.regionFilter.provinceCode !== initialRegionFilter.provinceCode
            || this.state.appliedFilters.clientFilter.clientCode !== initialClientFilter.clientCode;

        return (
            <FilterPopup hasFiltersApplied={hasFiltersApplied} onApply={this.applyFilters} onClose={this.resetPopupFilters}>
                <FilterByDateSection 
                    filter={this.state.pendingFilters.dateFilter} 
                    availableFilters={[DateFilterType.thisMonth, DateFilterType.thisYear, DateFilterType.custom]}
                    onChange={this.changeDateFilter}
                />
                <FilterByRegionSection 
                    filter={this.state.pendingFilters.regionFilter} 
                    onChange={this.changeRegionFilter} 
                    onChangeCountry={this.props.onChangeCountry} 
                />
                <FilterByClientSection
                    filter={this.state.pendingFilters.clientFilter}
                    onChange={this.changeClientFilter}
                    onSearch={this.props.onSearchForClient}
                />
            </FilterPopup>
        );
    }

    private changeDateFilter = (filter: DateFilter) => {
        this.setState((current) => ({
            ...current,
            pendingFilters: {
                ...current.pendingFilters,
                dateFilter: filter
            }
        }));
    }

    private changeRegionFilter = (filter: RegionFilter) => {
        this.setState((current) => ({
            ...current,
            pendingFilters: {
                ...current.pendingFilters,
                regionFilter: filter
            }
        }));
    }

    private changeClientFilter = (filter: ClientFilter) => {
        this.setState((current) => ({
            ...current,
            pendingFilters: {
                ...current.pendingFilters,
                clientFilter: filter
            }
        }));
    }

    private resetPopupFilters = () => {
        this.setState((current) => ({ pendingFilters: current.appliedFilters }));

        // Empty the client search results, so next time we click on the dropdown there won't be any default values
        this.setState((current) => ({
            ...current,
            pendingFilters: {
                ...current.pendingFilters,
                clientFilter: {
                    ...current.pendingFilters.clientFilter,
                    clientsSearchResults: current.pendingFilters.clientFilter.clientsSearchResults.filter(x => x.id === current.pendingFilters.clientFilter.clientCode),
                }
            }
        }));
    }

    private applyFilters = () => {
        this.setState((current) => ({ appliedFilters: current.pendingFilters }));
        this.props.onApply(this.state.pendingFilters);
    }
}

const connectedComponent = injectIntl(StatisticsFiltersPopup);
export { connectedComponent as StatisticsFiltersPopup };