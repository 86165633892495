import { ActionTypeKeys } from './types';
import { NotificationType } from '../../models';

export type ActionTypes = 
    | ShowNotificationAction
    | ClearNotificationAction
    | { type: '' };

export interface ShowNotificationAction {
    type: ActionTypeKeys.SHOW;
    payload: {
        title: string,
        message?: string,
        type: NotificationType
    };
}

export interface ClearNotificationAction {
    type: ActionTypeKeys.CLEAR;
}

export const actionCreators = {
    showSuccessNotification: (title: string, message?: string) => {
        return {
            type: ActionTypeKeys.SHOW,
            payload: {
                title,
                message,
                type: NotificationType.success
            }
        };
    },

    showErrorNotification: (title: string, message?: string) => {
        return {
            type: ActionTypeKeys.SHOW,
            payload: {
                title,
                message,
                type: NotificationType.error
            }
        };
    },

    clearNotification: () => {
        return { type: ActionTypeKeys.CLEAR };
    }
};