import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

import * as InspectionsActions from '../../../state/ducks/inspections';
import * as ServiceCallsActions from '../../../state/ducks/service-calls';
import * as UiActions from '../../../state/ducks/ui';

import { ApplicationState } from '../../../state/ducks';
import { ServiceCallInspection, ServiceCall, ServiceCallAttachment } from '../../../state/models';
import { InspectionReportCard, InspectionInformationCard, InspectionPhotoUploadCard } from '../../../components/service-calls/inspection';
import { getInspectionById, isInspectionLoading } from '../../../state/ducks/inspections/selectors';
import { getServiceCallState, getPictures, getVideos } from '../../../state/ducks/service-calls';
import { InspectionAppointmentInformationCard } from '../../../components/service-calls/inspection/InspectionAppointmentInformationCard';
import { InspectionReportEdit } from '../../../components/service-calls/inspection/InspectionReportCard/models';

interface InspectionInspectStepActions {
    inspectionsActions: typeof InspectionsActions.actionCreators;
    serviceCallsActions: typeof ServiceCallsActions.actionCreators;
    uiActions: typeof UiActions.actionCreators;
}

interface InspectionInspectStepOwnProps {
    inspection: ServiceCallInspection;
    isInspectionLoading: boolean;
    serviceCall: ServiceCall | undefined;
    isServiceCallLoading: boolean;
    uploadedPictures: ServiceCallAttachment[];
    arePicturesUploading: boolean;
    uploadedVideos: ServiceCallAttachment[];
    areVideosUploading: boolean;
    deletingAttachmentIds: number[];
}

export type InspectionInspectStepProps =
    & InspectionInspectStepOwnProps
    & InspectionInspectStepActions
    & RouteComponentProps<{ serviceCallId: string, inspectionId: string }>;

type IntlInspectionInspectStepProps = InspectionInspectStepProps & WrappedComponentProps;

class InspectionInspectStep extends React.Component<IntlInspectionInspectStepProps, {}> {
    public componentDidMount() {
        this.props.uiActions.changeInspectionStep('inspect');
    }

    public render() {
        return (
            <React.Fragment>
                <Grid columns={2} stackable={true}>
                    <Grid.Column>
                        <InspectionAppointmentInformationCard
                            inspection={this.props.inspection}
                            isInspectionLoading={this.props.isInspectionLoading}
                            serviceCall={this.props.serviceCall}
                            isServiceCallLoading={this.props.isServiceCallLoading}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <InspectionInformationCard
                            isLoading={this.props.isServiceCallLoading}
                            defectiveItems={this.props.serviceCall ? this.props.serviceCall.defectiveItems : []}
                            defects={this.props.serviceCall ? this.props.serviceCall.defects : []}
                        />
                    </Grid.Column>
                </Grid>

                <InspectionPhotoUploadCard
                    medias={this.props.uploadedPictures.concat(this.props.uploadedVideos)}
                    isUploading={this.props.arePicturesUploading || this.props.areVideosUploading}
                    deletingMediaIds={this.props.deletingAttachmentIds}
                    onUploadMedia={this.uploadMedia}
                    onDeleteMedia={this.deleteMedia}
                />

                <InspectionReportCard
                    inspection={this.props.inspection}
                    isLoading={this.props.isInspectionLoading}
                    onSave={this.saveReport}
                    onComplete={this.completeInspection}
                    onCompleteAndClose={this.completeAndCloseInspection}
                />
            </React.Fragment>
        );
    }

    private saveReport = (inspectionId: number, data: InspectionReportEdit): void => {
        this.props.inspectionsActions.saveReport(inspectionId, data.report);
    }

    private completeInspection = (inspectionId: number): void => {
        this.props.inspectionsActions.complete(inspectionId);
        this.props.history.push('/');
    }

    private completeAndCloseInspection = (inspectionId: number): void => {
        this.props.inspectionsActions.close(inspectionId);
        this.props.history.push('/');
    }

    private uploadMedia = (files: FileList): void => {
        if (files && files.length > 0 && this.props.serviceCall) {
            if (files[0].type.includes('video')) {
                this.props.serviceCallsActions.uploadVideos(
                    this.props.serviceCall.id,
                    Array.from(files)
                );
            } else {
                this.props.serviceCallsActions.uploadPictures(
                    this.props.serviceCall.id,
                    Array.from(files)
                );
            }
        }
    }

    private deleteMedia = (mediaId: number): void => {
        if (this.props.serviceCall) {
            this.props.serviceCallsActions.deleteAttachment(
                this.props.serviceCall.id,
                mediaId
            );
        }
    }
}

const mapStateToProps = (state: ApplicationState, props: InspectionInspectStepProps): InspectionInspectStepOwnProps => {
    const serviceCallId = Number(props.match.params.serviceCallId);
    const inspectionId = Number(props.match.params.inspectionId);
    const serviceCallState = getServiceCallState(state, serviceCallId);

    return {
        inspection: getInspectionById(state, inspectionId),
        isInspectionLoading: isInspectionLoading(state, inspectionId),
        serviceCall: serviceCallState ? serviceCallState.details : undefined,
        isServiceCallLoading: serviceCallState ? serviceCallState.isLoading : false,
        uploadedPictures: getPictures(state, serviceCallId),
        uploadedVideos: getVideos(state, serviceCallId),
        arePicturesUploading: serviceCallState ? serviceCallState.arePicturesUploading : false,
        areVideosUploading: serviceCallState ? serviceCallState.areVideosUploading : false,
        deletingAttachmentIds: serviceCallState ? serviceCallState.isAttachmentDeleting : []
    };
};

const mapDispatchToProps = (dispatch: Dispatch): InspectionInspectStepActions => {
    return {
        inspectionsActions: bindActionCreators(InspectionsActions.actionCreators, dispatch),
        serviceCallsActions: bindActionCreators(ServiceCallsActions.actionCreators, dispatch),
        uiActions: bindActionCreators(UiActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(InspectionInspectStep);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as InspectionInspectStep };