import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { List, Label, Icon, LabelProps, Popup } from 'semantic-ui-react';

export interface HasItemDefectLabelProps extends LabelProps {
    itemsQuantity?: number;
}

const m = defineMessages({
    tooltip: { 
        id: 'HasItemDefectLabel.tooltip', 
        // tslint:disable-next-line:max-line-length
        defaultMessage: 'A defect was identified on {count, plural, one {this product} other {{count} of these products}}.'
    }
});

class HasItemDefectLabel extends React.Component<HasItemDefectLabelProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const itemsQuantity = this.props.itemsQuantity || 1;

        return (
            <Popup 
                trigger={this.renderLabel()} 
                content={formatMessage(m.tooltip, { count: this.props.itemsQuantity })}
                flowing={true}
            />
        );
    }

    private renderLabel() {
        const { itemsQuantity, intl, ...labelProps } = this.props;

        return (
            <Label {...labelProps} circular={true} size="small" color="red">
                <Icon name="first aid" style={{margin: 0}} />
            </Label>
        );
    }
}

const connectedComponent = injectIntl(HasItemDefectLabel);
export { connectedComponent as HasItemDefectLabel };