import * as React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Modal, Header, Button } from 'semantic-ui-react';
import { commonMessages } from '../../constants';

interface SignatureModalOwnProps {
    height?: number;
    trigger?: React.ReactNode;
    isOpen?: boolean;
    defaultSignature?: string;
    onClose: () => void;
    onSign: (signatureBase64Data: string) => void;
}

export type SignatureModalProps =
    & SignatureModalOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'SignatureModal.title', defaultMessage: 'Your signature' },
    subtitle: { id: 'SignatureModal.subtitle', defaultMessage: 'Please apply your signature if the repairs are at your satisfaction.'},
    clear: { id: 'SignatureModal.clear', defaultMessage: 'Clear' }
});

class SignatureModal extends React.Component<SignatureModalProps, {}> {
    private signatureCanvas: any;

    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <Modal trigger={this.props.trigger} size="fullscreen" open={this.props.isOpen} closeIcon={true} onClose={this.props.onClose}>
                <Modal.Header>
                    <Header 
                        content={formatMessage(m.title)}
                        subheader={formatMessage(m.subtitle)}
                    />
                </Modal.Header>
                <Modal.Content>
                    <SignatureCanvas 
                        ref={(ref: any) => { this.signatureCanvas = ref; }} 
                        canvasProps={{
                            height: this.props.height || 300,
                            className: 'signature-canvas'
                        }} 
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button secondary={true} content={formatMessage(m.clear)} floated="left" onClick={() => this.signatureCanvas.clear()} />
                    <Button content={formatMessage(commonMessages.cancel)} onClick={this.props.onClose} />
                    <Button 
                        primary={true} 
                        content={formatMessage(commonMessages.confirm)} 
                        onClick={() => this.handleConfirmSignature()}
                    />
                </Modal.Actions>
            </Modal>
        );
    }

    private handleConfirmSignature = () => {
        const signature = !this.signatureCanvas.isEmpty()
            ? this.signatureCanvas.getCanvas().toDataURL()
            : '';

        this.props.onSign(signature);
    }
}

const connectedComponent = injectIntl(SignatureModal);
export { connectedComponent as SignatureModal };