import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { SemanticICONS, Step } from 'semantic-ui-react';

import { ApplicationState } from '../../../state/ducks';
import * as UiActions from '../../../state/ducks/ui';
import { ServiceCallAgentSteps } from '../../../state/ducks/ui/reducers';
import { ServiceCallState } from '../../../state/ducks/service-calls/reducers';
import { hasPendingRepairs, hasCompletedRepairs } from '../../../state/ducks/service-calls';

type ServiceCallAgentPageProps =
    & ServiceCallAgentPageOwnProps
    & ServiceCallAgentPageActions
    & RouteComponentProps<{ serviceCallId: string }>;

type IntlServiceCallAgentPageProps = ServiceCallAgentPageProps & WrappedComponentProps;

interface ServiceCallAgentPageOwnProps {
    currentStepId: ServiceCallAgentSteps;
    serviceCall: ServiceCallState;
    hasPendingRepairs: boolean;
    hasCompletedRepairs: boolean;
}

interface ServiceCallAgentPageActions {
    uiActions: typeof UiActions.actionCreators;
}

const m = defineMessages({
    assignStepTitle: { id: 'ServiceCallAgentPage.assign_step_title', defaultMessage: 'Assign' },
    inspectStepTitle: { id: 'ServiceCallAgentPage.inspect_step_title', defaultMessage: 'Inspect' },
    approveStepTitle: { id: 'ServiceCallAgentPage.approve_step_title', defaultMessage: 'Approve' },
    repairStepTitle: { id: 'ServiceCallAgentPage.repair_step_title', defaultMessage: 'Repair' },
    closeStepTitle: { id: 'ServiceCallAgentPage.close_step_title', defaultMessage: 'Close' }
});

class ServiceCallAgentPage extends React.Component<IntlServiceCallAgentPageProps, {}> {
    public componentDidMount() {
        this.props.uiActions.changeServiceCallTab('agent');
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const { serviceCall } = this.props;
        const assignStepId = 'assign';
        const inspectStepId = 'inspect';
        const approveStepId = 'approve';
        const repairStepId = 'repair';
        const closeStepId = 'close';

        const isServiceCallLoaded = serviceCall && !serviceCall.isLoading;
        const isAssignStepComplete = isServiceCallLoaded ? serviceCall.details.assignedTo != null : false;
        const isInspectStepComplete = isServiceCallLoaded
            ? serviceCall.details.defectiveItems.length > 0 && serviceCall.details.defects.length > 0 && !serviceCall.details.inspections.some(x => x.completedOn == null)
            : false;
        const isApproveStepComplete = isServiceCallLoaded && serviceCall.details.estimatedFees
            ? serviceCall.details.estimatedFees.isApproved || (serviceCall.details.estimatedFees.partsFees === 0 && serviceCall.details.estimatedFees.labourFees === 0 && serviceCall.details.estimatedFees.shippingFees === 0)
            : false;
        const isRepairStepComplete = !this.props.hasPendingRepairs && this.props.hasCompletedRepairs;
        const isCloseStepComplete = isServiceCallLoaded ? this.props.serviceCall.isClosed : false;

        return (
            <div>
                <Step.Group widths={5} size="small" style={{ marginBottom: '1rem' }}>
                    {this.renderStep(assignStepId, 'add user', formatMessage(m.assignStepTitle), isAssignStepComplete)}
                    {this.renderStep(inspectStepId, 'eye', formatMessage(m.inspectStepTitle), isInspectStepComplete)}
                    {this.renderStep(approveStepId, 'conversation', formatMessage(m.approveStepTitle), isApproveStepComplete)}
                    {this.renderStep(repairStepId, 'wrench', formatMessage(m.repairStepTitle), isRepairStepComplete)}
                    {this.renderStep(closeStepId, 'checkmark box', formatMessage(m.closeStepTitle), isCloseStepComplete)}
                </Step.Group>
            </div>
        );
    }

    private renderStep(stepId: ServiceCallAgentSteps, icon: SemanticICONS, title: string, isCompleted
        : boolean) {
        return (
            <Step
                id={stepId}
                icon={icon}
                title={title}
                active={this.props.currentStepId === stepId}
                completed={isCompleted}
                onClick={() => this.handleStepChange(stepId)}
            />
        );
    }

    private handleStepChange = (stepId: ServiceCallAgentSteps) => {
        const { serviceCallId } = this.props.match.params;

        this.props.uiActions.changeAgentStep(stepId);
        this.props.history.push(`/service-calls/${serviceCallId}/agent/${stepId}`);
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: ServiceCallAgentPageProps): ServiceCallAgentPageOwnProps => {
    const { serviceCallId } = ownProps.match.params;

    return {
        currentStepId: state.ui.currentAgentStepId,
        serviceCall: state.serviceCalls.serviceCalls[Number(serviceCallId)],
        hasPendingRepairs: hasPendingRepairs(state, Number(serviceCallId)),
        hasCompletedRepairs: hasCompletedRepairs(state, Number(serviceCallId))
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ServiceCallAgentPageActions => {
    return {
        uiActions: bindActionCreators(UiActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(ServiceCallAgentPage);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as ServiceCallAgentPage };