import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Accordion, Divider, Grid, Icon, SemanticICONS } from 'semantic-ui-react';
import { SearchSectionHeader } from './SearchSectionHeader';

interface OwnProps {
    icon: SemanticICONS
    title: string;
    isExpandedByDefault?: boolean;
    onClear: () => void;
}

interface StateProps {
    isExpanded: boolean;
}

export type SearchSectionProps =
    & OwnProps;

export class SearchSection extends React.Component<SearchSectionProps, StateProps> {
    public constructor(props: SearchSectionProps) {
        super(props);

        this.state = { isExpanded: props.isExpandedByDefault || false };
    }

    public render() {
        return (
            <React.Fragment>
                <SearchSectionHeader 
                    isExpanded={this.state.isExpanded}
                    icon={this.props.icon} 
                    title={this.props.title} 
                    onClear={this.props.onClear}
                    onClick={this.toggleSection}
                />

                <Accordion.Content active={this.state.isExpanded}>
                    <Grid style={{ marginTop: 0, marginBottom: 15 }}>
                        {this.props.children}
                    </Grid>
                </Accordion.Content>
            </React.Fragment>
        );
    }

    private toggleSection = () => {
        this.setState((current) => ({
            isExpanded: !current.isExpanded
        }));
    }
}