import React from 'react';
import moment from 'moment';

import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { ServiceCallNote, NoteVisibility, User, ServiceCallStatus, UserAccountType } from '../../../state/models';
import { Divider } from 'semantic-ui-react';
import { StringHelper } from '../../../state/utils';
import { ApplicationState } from '../../../state/ducks';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import * as NotesActions from '../../../modules/notes/actions';
import { getCurrentUserProfile } from '../../../state/ducks/current-user';
import { getServiceCallState } from '../../../state/ducks/service-calls';
import { getNotesByServiceCall, areNotesSaving, areNotesLoading } from '../../../modules/notes/selectors';
import { CreateNoteForm, NotesFeed } from '../notes';

interface OwnProps {
    serviceCallId: number;
}

interface DispatchProps {
    notesActions: typeof NotesActions.actionCreators;
}

interface StoreProps {
    currentUser: User | undefined;
    notes: ServiceCallNote[];
    isAdding: boolean;
    isLoading: boolean;
    isReadOnly: boolean;
}

export type NoteAttachmentsProps =
    & DispatchProps
    & OwnProps
    & StoreProps
    & WrappedComponentProps;

const m = defineMessages({
    addNote: { id: 'ProblemDescriptionCard.add_note', defaultMessage: 'Add Note' },
    emptyNoteTitle: { id: 'ProblemDescriptionCard.empty_note_title', defaultMessage: 'No internal notes' },
    emptyNoteDescription: { id: 'ProblemDescriptionCard.empty_note_description', defaultMessage: 'There are no internal notes associated to this service call.' },
});

class NoteAttachments extends React.Component<NoteAttachmentsProps, {}> {
    public componentDidMount() {
        this.props.notesActions.loadServiceCallNotes(this.props.serviceCallId);
    }

    public render() {
        return (
            <React.Fragment>
                <CreateNoteForm
                    currentUser={this.props.currentUser}
                    isAdding={this.props.isAdding}
                    onAdd={this.addNote}
                />

                <Divider />
                <NotesFeed
                    notes={this.props.notes || []}
                    currentUser={this.props.currentUser}
                    onDelete={this.handleDeleteNote}
                    onChangeVisibility={this.handleChangeNoteVisibility}
                />
            </React.Fragment>
        );
    }

    private addNote = (note: string, visibility: NoteVisibility) => {
        if (StringHelper.hasValue(note)) {
            this.props.notesActions.addNote(
                this.props.serviceCallId,
                note,
                visibility,
                () => this.setState({ note: '' })
            );
        }
    }

    private handleDeleteNote = (note: ServiceCallNote) => {
        this.props.notesActions.deleteNote(this.props.serviceCallId, note.id);
    }

    private handleChangeNoteVisibility = (note: ServiceCallNote, visibility: NoteVisibility) => {
        this.props.notesActions.changeVisibility(this.props.serviceCallId, note.id, visibility);
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): StoreProps => {
    const serviceCallState = getServiceCallState(state, ownProps.serviceCallId);

    return {
        currentUser: getCurrentUserProfile(state),
        notes: getNotesByServiceCall(state, ownProps.serviceCallId),
        isAdding: areNotesSaving(state),
        isLoading: areNotesLoading(state),
        isReadOnly: serviceCallState.details == null || serviceCallState.details.status === ServiceCallStatus.closed
    };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    return {
        notesActions: bindActionCreators(NotesActions.actionCreators, dispatch),
    };
};

const intlComponent = injectIntl(NoteAttachments);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as NoteAttachments };