import { ForgotPasswordEdit } from "../../../models";
import React from "react";
import { FieldErrors } from "react-hook-form";
import { Message, MessageProps } from 'semantic-ui-react';

interface OwnProps {
    errors: FieldErrors<ForgotPasswordEdit>;
}

export type ForgotPasswordValidationSummaryProps =
    & MessageProps
    & OwnProps;

export const ForgotPasswordValidationSummary: React.FC<ForgotPasswordValidationSummaryProps> = (props) => {
    const { errors, ...messageProps } = props;

    const errorMessages = [
        props.errors.email?.message,
    ].filter(x => x != null);

    if (errorMessages.length <= 0) {
        return <></>;
    }

    return (
        <Message error list={errorMessages} {...messageProps} />
    );
};