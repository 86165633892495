import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { List } from 'semantic-ui-react';

import { ClientContactEdit, ValidationResult } from '../../state/models';
import { EmptyContentSegment } from '../common';
import { FormatHelper } from '../../state/utils';
import { ContactClientForm } from './ContactClientForm';

interface ContactClientTabOwnProps {
    client: ClientContactEdit | undefined;
    validationResult: ValidationResult;
    isEditing: boolean;
    onChange: (client: ClientContactEdit) => void;
}

export type ContactClientTabProps =
    & ContactClientTabOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    emptyClientTitle: { id: 'ContactClientTab.emptyClientTitle', defaultMessage: 'No client information' },
    emptyClientDescription: { id: 'ContactClientTab.emptyClientDescription', defaultMessage: 'There\'s no client information associated to this service call' }
});

class ContactClientTab extends React.Component<ContactClientTabProps, {}> {
    public render() {
        return this.props.isEditing
            ? this.renderEditableContent()
            : this.renderContent();
    }

    private renderContent = () => {
        const { client } = this.props;
        const hasClientInformation = client && (client.name || client.email || client.phoneNumber);

        if (!hasClientInformation) {
            return this.renderEmptyContent();
        }

        return (
            <List style={{ padding: '0 14px 14px 14px' }}>
                <List.Item>
                    <List.Icon name="user outline" />
                    <List.Content>{client!.name}</List.Content>
                </List.Item>

                <List.Item>
                    <List.Icon name="mail" />
                    <List.Content>
                        <a href={`mailto:${client!.email}`}>
                            {client!.email}
                        </a>
                    </List.Content>
                </List.Item>

                <List.Item>
                    <List.Icon name="phone" />
                    <List.Content>
                        <a href={`tel:${client!.phoneNumber}`}>
                            {FormatHelper.formatPhoneNumber(client!.phoneNumber)}
                        </a>
                    </List.Content>
                </List.Item>
            </List>
        );
    }

    private renderEditableContent = () => {
        return (
            <ContactClientForm
                client={this.props.client || new ClientContactEdit()}
                validationResult={this.props.validationResult}
                onChange={this.handleChange}
            />
        );
    }

    private renderEmptyContent = () => {
        const { formatMessage } = this.props.intl;

        return (
            <EmptyContentSegment
                basic={true}
                title={formatMessage(m.emptyClientTitle)}
                description={formatMessage(m.emptyClientDescription)}
            />
        );
    }

    private handleChange = (fieldId: string, newValue: string) => {
        this.props.onChange(new ClientContactEdit({
            ...this.props.client,
            [fieldId]: newValue
        }));
    }
}

const connectedComponent = injectIntl(ContactClientTab);
export { connectedComponent as ContactClientTab };