import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import * as ReportingActions from '../../state/ducks/reporting';

import { ApplicationState } from '../../state/ducks';
import { PageHeader } from '../../components/common';
import { ClientsAdoptionFiltersPopup, HorizontalBarGraph, VerticalBarGraph, PieChartGraph } from '../../components/reporting';
import { ClientsAdoptionFilters, getReportingDateRange, Ratio, LabeledValue } from '../../state/models';
import { Grid } from 'semantic-ui-react';

interface ClientsAdoptionPageActions {
    reportingActions: typeof ReportingActions.actionCreators;
}

interface ClientsAdoptionPageConnectedProps {
    filters: ClientsAdoptionFilters;
    clientsAdoptionRatio: Ratio<number>;
    isClientsAdoptionRatioLoading: boolean;
    topClientAdopters: LabeledValue<number>[];
    isTopClientAdoptersLoading: boolean;
}

export type ClientsAdoptionPageProps =
    & ClientsAdoptionPageConnectedProps
    & ClientsAdoptionPageActions
    & RouteComponentProps<{}>
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'ClientsAdoptionPage.title', defaultMessage: 'Clients adoption' },
    subtitle: { id: 'ClientsAdoptionPage.subtitle', defaultMessage: 'View how well the clients are adopting the platform.' },
    clientsLabel: { id: 'ClientsAdoptionPage.clientsLabel', defaultMessage: 'Clients' },
    jaymarLabel: { id: 'ClientsAdoptionPage.jaymarLabel', defaultMessage: 'Jaymar' },
    adoptionRatioTitle: { id: 'ClientsAdoptionPage.adoptionRatioTitle', defaultMessage: 'Calls created by clients' },
    adoptionRatioTooltip: { id: 'ClientsAdoptionPage.adoptionRatioTooltip', defaultMessage: 'The number of service calls created by Jaymar\'s clients compared to the number of calls created by Jaymar\'s employees.' },
    topAdoptersLegend: { id: 'ClientsAdoptionPage.topAdoptersLegend', defaultMessage: 'Service calls created' },
    topAdoptersTitle: { id: 'ClientsAdoptionPage.topAdoptersTitle', defaultMessage: 'Top adopters' },
    topAdoptersTooltip: { id: 'ClientsAdoptionPage.topAdoptersTooltip', defaultMessage: 'The clients that have created the most number of service calls with the platform.' }
});

class ClientsAdoptionPage extends React.Component<ClientsAdoptionPageProps, {}> {
    public componentDidMount() {
        this.loadStatistics(this.props.filters);
    }

    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <PageHeader
                    iconName="users"
                    title={formatMessage(m.title)}
                    subtitle={formatMessage(m.subtitle)}
                />

                <div style={{ textAlign: 'right', marginBottom: '10px' }}>
                    <ClientsAdoptionFiltersPopup
                        filters={this.props.filters}
                        onApply={this.applyFilters}
                    />
                </div>

                <Grid>
                    <Grid.Column mobile={16} tablet={16} computer={8} largeScreen={8} widescreen={8}>
                        <PieChartGraph
                            title={formatMessage(m.adoptionRatioTitle)}
                            label={formatMessage(m.adoptionRatioTitle)}
                            labelTooltip={formatMessage(m.adoptionRatioTooltip)}
                            labels={[formatMessage(m.clientsLabel), formatMessage(m.jaymarLabel)]}
                            values={[this.props.clientsAdoptionRatio.value, this.props.clientsAdoptionRatio.total - this.props.clientsAdoptionRatio.value]}
                        />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={8} widescreen={8}>
                        <VerticalBarGraph
                            title={formatMessage(m.topAdoptersLegend)}
                            label={formatMessage(m.topAdoptersTitle)}
                            labelTooltip={formatMessage(m.topAdoptersTooltip)}
                            labels={this.props.topClientAdopters.map(x => x.label)}
                            values={this.props.topClientAdopters.map(x => x.value)}
                            isLoading={this.props.isTopClientAdoptersLoading}
                        />
                    </Grid.Column>
                </Grid>
            </React.Fragment>
        );
    }

    private applyFilters = (filters: ClientsAdoptionFilters) => {
        this.props.reportingActions.setClientsAdoptionFilters(filters);
        this.loadStatistics(filters);
    }

    private loadStatistics(filters: ClientsAdoptionFilters) {
        const dateRange = getReportingDateRange(filters.dateFilter);

        this.props.reportingActions.loadClientsAdoptionRatio(dateRange.startDate, dateRange.endDate);
        this.props.reportingActions.loadTopClientAdopters(dateRange.startDate, dateRange.endDate);
    }
}

const mapStateToProps = (state: ApplicationState): ClientsAdoptionPageConnectedProps => {
    return {
        filters: state.reporting.clientsAdoptionFilters,
        clientsAdoptionRatio: state.reporting.clientsAdoptionRatio,
        isClientsAdoptionRatioLoading: state.reporting.isClientsAdoptionRatioLoading,
        topClientAdopters: state.reporting.topClientAdopters,
        isTopClientAdoptersLoading: state.reporting.isTopClientAdoptersLoading
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ClientsAdoptionPageActions => {
    return {
        reportingActions: bindActionCreators(ReportingActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(ClientsAdoptionPage);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as ClientsAdoptionPage };