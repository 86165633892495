import { defineMessages } from "@formatjs/intl";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { PageHeader } from "../../../components/common";
import { UserProfileEdit } from "../models";
import { getCurrentUser } from "../selectors";
import { UserProfileForm } from "./components/UserProfileForm";
import * as api from '../api';
import useApiErrorHandler from "../../../utils/ApiErrorHandler";

interface OwnProps {
}

export type UserProfilePageProps =
    & OwnProps;

const m = defineMessages({
    title: { id: 'UserProfilePage.title', defaultMessage: 'Your account settings' },
    subtitle: { id: 'UserProfilePage.subtitle', defaultMessage: 'Manage your account settings for the after-sales platform.' }
});

export const UserProfilePage: React.FC<UserProfilePageProps> = (props) => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const handleError = useApiErrorHandler();
    const currentUser = useSelector(getCurrentUser);
    const [isSaving, setIsSaving] = useState(false);

    const navigateToHomePage = () => history.push('/');
    const saveProfile = (user: UserProfileEdit) => {
        setIsSaving(true);

        api.updateProfile(user)
            .then(navigateToHomePage)
            .catch(error => handleError(error))
            .finally(() => setIsSaving(false));
    }

    return (
        <>
            <PageHeader iconName="settings" title={formatMessage(m.title)} subtitle={formatMessage(m.subtitle)} />

            <UserProfileForm
                user={currentUser}
                isSaving={isSaving}
                onSave={saveProfile}
                onCancel={navigateToHomePage}
            />
        </>
    );
};