import { AppThunk } from '../../state/store';
import { LoginEdit } from './models';
import * as api from './api';
import { UserSession } from './types';

export enum ActionTypeKeys {
    LOGIN_REQUEST = 'ACCOUNT_LOGIN_REQUEST',
    LOGIN_SUCCESS = 'ACCOUNT_LOGIN_SUCCESS',
    LOGIN_FAILURE = 'ACCOUNT_LOGIN_FAILURE',

    LOGOUT_REQUEST = 'ACCOUNT_LOGOUT_REQUEST',
    LOGOUT_SUCCESS = 'ACCOUNT_LOGOUT_SUCCESS',
    LOGOUT_FAILURE = 'ACCOUNT_LOGOUT_FAILURE',

    LOAD_USER_SESSION_REQUEST = 'ACCOUNT_LOAD_USER_SESSION_REQUEST',
    LOAD_USER_SESSION_SUCCESS = 'ACCOUNT_LOAD_USER_SESSION_SUCCESS',
    LOAD_USER_SESSION_FAILURE = 'ACCOUNT_LOAD_USER_SESSION_FAILURE',

    REQUEST_PASSWORD_RESET_REQUEST = 'ACCOUNT_REQUEST_PASSWORD_RESET_REQUEST',
    REQUEST_PASSWORD_RESET_SUCCESS = 'ACCOUNT_REQUEST_PASSWORD_RESET_SUCCESS',
    REQUEST_PASSWORD_RESET_FAILURE = 'ACCOUNT_REQUEST_PASSWORD_RESET_FAILURE',
}

export interface LoginRequest { type: ActionTypeKeys.LOGIN_REQUEST; payload: { credentials: LoginEdit }; }
export interface LoginSuccess { type: ActionTypeKeys.LOGIN_SUCCESS; }
export interface LoginFailure { type: ActionTypeKeys.LOGIN_FAILURE; payload: { error: Error }; }

export interface LogoutRequest { type: ActionTypeKeys.LOGOUT_REQUEST; }
export interface LogoutSuccess { type: ActionTypeKeys.LOGOUT_SUCCESS; }
export interface LogoutFailure { type: ActionTypeKeys.LOGOUT_FAILURE; }

export interface LoadUserSessionRequest { type: ActionTypeKeys.LOAD_USER_SESSION_REQUEST; }
export interface LoadUserSessionSuccess { type: ActionTypeKeys.LOAD_USER_SESSION_SUCCESS; payload: { session: UserSession }; }
export interface LoadUserSessionFailure { type: ActionTypeKeys.LOAD_USER_SESSION_FAILURE; payload: { error: Error }; }

export type ActionTypes = 
    | LoginRequest | LoginSuccess | LoginFailure
    | LogoutRequest | LogoutSuccess | LogoutFailure
    | LoadUserSessionRequest | LoadUserSessionSuccess | LoadUserSessionFailure;

export const actionCreators = {
    login: (credentials: LoginEdit): AppThunk => {
        return (dispatch) => {
            dispatch({ type: ActionTypeKeys.LOGIN_REQUEST, payload: { credentials } });

            api.login(credentials)
                .then(() => {
                    dispatch({ type: ActionTypeKeys.LOGIN_SUCCESS });
                    dispatch(actionCreators.loadUserSession());
                })
                .catch(error => dispatch({ type: ActionTypeKeys.LOGIN_FAILURE, payload: { error }}));
        };
    },

    logout: (): AppThunk => {
        return (dispatch) => {
            dispatch({ type: ActionTypeKeys.LOGOUT_REQUEST });

            api.logout()
                .then(() => dispatch({ type: ActionTypeKeys.LOGOUT_SUCCESS }))
                .catch(error => dispatch({ type: ActionTypeKeys.LOGOUT_FAILURE, payload: { error }}));
        }
    },

    loadUserSession: (): AppThunk => {
        return (dispatch) => {
            dispatch({ type: ActionTypeKeys.LOAD_USER_SESSION_REQUEST });

            api.loadUserSession()
                .then(session => dispatch({ type: ActionTypeKeys.LOAD_USER_SESSION_SUCCESS, payload: { session } }))
                .catch(error => dispatch({ type: ActionTypeKeys.LOAD_USER_SESSION_FAILURE, payload: { error }}));
        }
    },
}