import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Icon, SemanticICONS, Popup, SemanticCOLORS } from 'semantic-ui-react';

interface NumberWithDeltaOwnProps {
    currentValue: number;
    previousValue: number;
    isIncreaseGood?: boolean;
    style?: React.CSSProperties;
}

export type NumberWithDeltaProps =
    & NumberWithDeltaOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    increaseTooltip: { id: 'NumberWithDelta.increase_tooltip', defaultMessage: 'An increase of {delta} compared to the previous period ({previousValue}).' },
    decreaseTooltip: { id: 'NumberWithDelta.decrease_tooltip', defaultMessage: 'A decrease of {delta} compared to the previous period ({previousValue}).'}
});

class NumberWithDelta extends React.Component<NumberWithDeltaProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;

        const delta = this.props.currentValue - this.props.previousValue;
        const hasCurrentValueIncreased = delta > 0;

        const deltaIcon: SemanticICONS = hasCurrentValueIncreased ? 'caret up' : 'caret down';
        const deltaTooltip = formatMessage(hasCurrentValueIncreased ? m.increaseTooltip : m.decreaseTooltip, { delta: Math.abs(delta), previousValue: this.props.previousValue });
        const deltaIconColor: SemanticCOLORS = (hasCurrentValueIncreased && this.props.isIncreaseGood) || (!hasCurrentValueIncreased && !this.props.isIncreaseGood)
            ? 'green'
            : 'red';

        return (
            <React.Fragment>
                <Popup trigger={this.renderNumberWithDelta(deltaIcon, deltaIconColor, this.props.currentValue)}>
                    {deltaTooltip}
                </Popup>
            </React.Fragment>
        );
    }

    private renderNumberWithDelta(icon: SemanticICONS, iconColor: SemanticCOLORS, currentValue: number) {
        return (
            <span style={{whiteSpace: 'nowrap', ...this.props.style}}>
                {currentValue}<Icon name={icon} color={iconColor} size="large" />
            </span>
        );
    }
}

const connectedComponent = injectIntl(NumberWithDelta);
export { connectedComponent as NumberWithDelta };