import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon, Label, Segment } from "semantic-ui-react";
import { getUnreadNotifications } from "../../../../../state/ducks/notifications/selectors";
import { actionCreators as NotificationsActions } from '../../../../../state/ducks/notifications/actions';

const GetNotificationsDelayInMilliseconds = 120 * 1000;

export const NotificationMenuTrigger: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(NotificationsActions.loadUnread());

        const notificationsTimer = window.setInterval(() => dispatch(NotificationsActions.loadUnread()), GetNotificationsDelayInMilliseconds);
        return () => clearInterval(notificationsTimer);
    }, [dispatch]);

    const unreadNotifications = useSelector(getUnreadNotifications);
    const unreadNotificationsCount = unreadNotifications.length > 99 
        ? '99+'
        : String(unreadNotifications.length);

    return (
        <Segment basic={true} style={{display: 'inline-block',  padding: 0, margin: 0}}>
            <Icon name="bell outline" size="large" />
            {unreadNotifications.length > 0 && 
                <Label circular floating content={unreadNotificationsCount} color="red" size="mini" />
            }
        </Segment>
    );
};