import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { ModalProps, Modal, Header, Form, Grid, Button, DropdownProps, Accordion } from 'semantic-ui-react';
import { commonMessages } from '../../../constants';
import { nameof } from '../../../state/utils';
import { ClientSearchControl } from '../../../containers/search';
import { Client, User } from '../../../state/models';
import { SearchSection } from './SearchSection';
import { SearchInputField } from './SearchInputField';
import { SearchNumericField } from './SearchNumericField';
import { SearchField } from './SearchField';
import { CountriesDropdown, ProvincesDropdown } from '../../common';
import { UserHelper } from '../../../helpers';

export enum ServiceCallSearchStatus {
    all,
    open,
    closed
}

export interface AdvancedSearchQuery {
    currentUser?: User;
    serviceCallId?: number;
    serviceCallStatus: ServiceCallSearchStatus;
    clientCode?: string;
    clientName?: string;
    clientReferenceNumber?: string;
    billNumber?: number;
    modelCode?: string;
    coverCode?: string;
    consumerName?: string;
    consumerPhoneNumber?: string;
    countryCode?: string;
    provinceCode?: string;
    [key: string]: any;
}

const initialSearchQuery: AdvancedSearchQuery = {
    serviceCallStatus: ServiceCallSearchStatus.all
};

interface AdvancedSearchModalOwnProps extends ModalProps {
    onSearch: (query: AdvancedSearchQuery) => void;
    onCancel: () => void;
}

interface AdvancedSearchModalState {
    query: AdvancedSearchQuery;
}

export type AdvancedSearchModalProps =
    & AdvancedSearchModalOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'AdvancedSearchModal.title', defaultMessage: 'Advanced search' },
    subtitle: { id: 'AdvancedSearchModal.subtitle', defaultMessage: 'Search for service calls using specific criterias.' },
    serviceCallHeader: { id: 'AdvancedSearchModal.service_call_header', defaultMessage: 'Search by service call' },
    serviceCallNumber: { id: 'AdvancedSearchModal.service_call_number', defaultMessage: 'Service call number' },
    serviceCallStatus: { id: 'AdvancedSearchModal.service_call_status', defaultMessage: 'Status' },
    openStatus: { id: 'AdvancedSearchModal.open_status', defaultMessage: 'Open' },
    closedStatus: { id: 'AdvancedSearchModal.closed_status', defaultMessage: 'Closed' },
    allStatus: { id: 'AdvancedSearchModal.all_status', defaultMessage: 'All' },
    orderHeader: { id: 'AdvancedSearchModal.order_header', defaultMessage: 'Search by order' },
    regionHeader: { id: 'AdvancedSearchModal.regionHeader', defaultMessage: 'Search by region' },
    clientLabel: { id: 'AdvancedSearchModal.clientLabel', defaultMessage: 'Client' },
    clientReferenceNumber: { id: 'AdvancedSearchModal.client_reference_number', defaultMessage: 'Reference number' },
    billNumber: { id: 'AdvancedSearchModal.bill_number', defaultMessage: 'Bill number' },
    productHeader: { id: 'AdvancedSearchModal.product_header', defaultMessage: 'Search by product' },
    modelCode: { id: 'AdvancedSearchModal.model_code', defaultMessage: 'Model' },
    coverCode: { id: 'AdvancedSearchModal.cover_code', defaultMessage: 'Cover' },
    consumerHeader: { id: 'AdvancedSearchModal.consumer_header', defaultMessage: 'Search by consumer' },
    consumerName: { id: 'AdvancedSearchModal.consumer_name', defaultMessage: 'Name' },
    consumerPhone: { id: 'AdvancedSearchModal.consumer_phone', defaultMessage: 'Phone' },
    countryLabel: { id: 'AdvancedSearchModal.countryLabel', defaultMessage: 'Country' },
    provinceLabel: { id: 'AdvancedSearchModal.provinceLabel', defaultMessage: 'Province/State' }
});

class AdvancedSearchModal extends React.Component<AdvancedSearchModalProps, AdvancedSearchModalState> {
    public constructor(props: AdvancedSearchModalProps) {
        super(props);

        this.state = { query: initialSearchQuery };
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const { intl, onSearch, onCancel, ...modalProps } = this.props;
        const { query } = this.state;
        const isEmployee = UserHelper.isEmployee(this.props.currentUser);

        return (
            <Modal {...modalProps}>
                <Modal.Header>
                    <Header
                        icon="search"
                        content={formatMessage(m.title)}
                        subheader={formatMessage(m.subtitle)}
                    />
                </Modal.Header>
                <Modal.Content>
                    <Form onSubmit={this.handleSearch}>
                        <Accordion exclusive={false}>
                            <SearchSection icon="phone" isExpandedByDefault={true} title={formatMessage(m.serviceCallHeader)} onClear={this.clearServiceCallFields}>
                                <SearchNumericField fieldId={nameof<AdvancedSearchQuery>('serviceCallId')} label={formatMessage(m.serviceCallNumber)} value={query.serviceCallId} onChange={this.handleFieldChange} />
                                <SearchField fieldId={nameof<AdvancedSearchQuery>('serviceCallStatus')} label={formatMessage(m.serviceCallStatus)}>
                                    <Form.Group inline={true}>
                                        {this.renderRadioElement(formatMessage(m.openStatus), ServiceCallSearchStatus.open)}
                                        {this.renderRadioElement(formatMessage(m.closedStatus), ServiceCallSearchStatus.closed)}
                                        {this.renderRadioElement(formatMessage(m.allStatus), ServiceCallSearchStatus.all)}
                                    </Form.Group>
                                </SearchField>
                            </SearchSection>

                            <SearchSection icon="shopping cart" title={formatMessage(m.orderHeader)} onClear={this.clearOrderFields}>
                                {isEmployee && this.renderClientDropdownField()}
                                <SearchInputField fieldId={nameof<AdvancedSearchQuery>('clientReferenceNumber')} label={formatMessage(m.clientReferenceNumber)} value={query.clientReferenceNumber} onChange={this.handleFieldChange} />
                                <SearchNumericField fieldId={nameof<AdvancedSearchQuery>('billNumber')} label={formatMessage(m.billNumber)} value={query.billNumber} onChange={this.handleFieldChange} />
                            </SearchSection>

                            {isEmployee &&
                                <SearchSection icon="globe" title={formatMessage(m.regionHeader)} onClear={this.clearRegionFields}>
                                    <SearchField fieldId={nameof<AdvancedSearchQuery>('countryCode')} label={formatMessage(m.countryLabel)}>
                                        <CountriesDropdown
                                            id={nameof<AdvancedSearchQuery>('countryCode')}
                                            value={query.countryCode || ''}
                                            onChange={this.handleCountryChange}
                                        />
                                    </SearchField>
                                    <SearchField fieldId={nameof<AdvancedSearchQuery>('provinceCode')} label={formatMessage(m.provinceLabel)}>
                                        <ProvincesDropdown 
                                            id={nameof<AdvancedSearchQuery>('provinceCode')}
                                            disabled={query.countryCode == null || query.countryCode === ''}
                                            selectedCountry={query.countryCode || ''}
                                            value={query.provinceCode || ''}
                                            onChange={this.handleProvinceChange}
                                        />
                                    </SearchField>
                                </SearchSection>
                            }

                            <SearchSection icon="user" title={formatMessage(m.consumerHeader)} onClear={this.clearConsumerFields}>
                                <SearchInputField fieldId={nameof<AdvancedSearchQuery>('consumerName')} label={formatMessage(m.consumerName)} value={query.consumerName || ''} onChange={this.handleFieldChange} />
                                <SearchInputField fieldId={nameof<AdvancedSearchQuery>('consumerPhoneNumber')} label={formatMessage(m.consumerPhone)} value={query.consumerPhoneNumber || ''} onChange={this.handleFieldChange} />
                            </SearchSection>

                            <SearchSection icon="archive" title={formatMessage(m.productHeader)} onClear={this.clearProductFields}>
                                <SearchInputField fieldId={nameof<AdvancedSearchQuery>('modelCode')} label={formatMessage(m.modelCode)} value={query.modelCode || ''} onChange={this.handleFieldChange} />
                                <SearchInputField fieldId={nameof<AdvancedSearchQuery>('coverCode')} label={formatMessage(m.coverCode)} value={query.coverCode || ''} onChange={this.handleFieldChange} />
                            </SearchSection>

                            <input type="submit" hidden={true} />
                        </Accordion>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button content={formatMessage(commonMessages.cancel)} onClick={this.handleCancel} />
                    <Button
                        primary={true}
                        content={formatMessage(commonMessages.search)}
                        onClick={this.handleSearch}
                    />
                </Modal.Actions>
            </Modal>
        );
    }

    private renderClientDropdownField() {
        const { formatMessage } = this.props.intl;

        return (
            <Grid.Row style={{ paddingTop: 5, paddingBottom: 5 }}>
                <Grid.Column width={6} className="field">
                    <label>{formatMessage(m.clientLabel)}</label>
                </Grid.Column>
                <Grid.Column width={10} className="field">
                    <Form.Field>
                        <ClientSearchControl
                            value={undefined}
                            onResultSelected={this.handleClientChange}
                        />
                    </Form.Field>
                </Grid.Column>
            </Grid.Row>
        )
    }

    private handleCountryChange = (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        this.setState((current) => ({
            query: {
                ...current.query,
                countryCode: data.value != null ? data.value as string : undefined,
                provinceCode: undefined
            }
        }));
    }

    private handleProvinceChange = (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        this.handleFieldChange(nameof<AdvancedSearchQuery>('provinceCode'), data.value);
    }

    private handleClientChange = (result: Client | undefined) => {
        this.setState((current) => ({
            query: {
                ...current.query,
                clientCode: result != null ? result.id : undefined
            }
        }))
    }

    private renderRadioElement(label: string, value: ServiceCallSearchStatus) {
        return (
            <Form.Radio
                label={label}
                value={value}
                checked={this.state.query.serviceCallStatus === value}
                onChange={(_event, data) => this.handleFieldChange('serviceCallStatus', data.value as ServiceCallSearchStatus)}
            />
        );
    }

    // tslint:disable-next-line:no-any
    private handleFieldChange = (fieldId: string, value: any) => {
        this.setState((current) => ({
            query: {
                ...current.query,
                [fieldId]: value
            }
        }));
    }

    private handleCancel = () => {
        this.props.onCancel();
        this.setState({ query: initialSearchQuery });
    }

    private handleSearch = () => {
        this.props.onSearch(this.state.query);
        this.setState({ query: initialSearchQuery });
    }

    private clearServiceCallFields = () => {
        this.setState((current) => ({
            query: {
                ...current.query,
                serviceCallId: undefined,
                serviceCallStatus: ServiceCallSearchStatus.all
            }
        }));
    }

    private clearOrderFields = () => {
        this.setState((current) => ({
            query: {
                ...current.query,
                clientCode: '',
                clientReferenceNumber: '',
                billNumber: undefined
            }
        }));
    }

    private clearRegionFields = () => {
        this.setState((current) => ({
            query: {
                ...current.query,
                countryCode: undefined,
                provinceCode: undefined,
            }
        }));
    }

    private clearProductFields = () => {
        this.setState((current) => ({
            query: {
                ...current.query,
                modelCode: undefined,
                coverCode: undefined
            }
        }));
    }

    private clearConsumerFields = () => {
        this.setState((current) => ({
            query: {
                ...current.query,
                consumerName: '',
                consumerPhoneNumber: ''
            }
        }));
    }
}

const connectedComponent = injectIntl(AdvancedSearchModal);
export { connectedComponent as AdvancedSearchModal };