import { useMutation } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';
import {
    ServiceCallStatus,
    SubcontractorRepairWarrantyStatus,
} from '../../../../../state/models';
import useApiErrorHandler from '../../../../../utils/ApiErrorHandler';
import { closeSubcontractorRepairServiceCall } from '../../api';
import { useSubcontractorRepairDetailsContext } from '../SubcontractorRepairDetailsProvider';

export const CloseServiceCallSection = () => {
    const { formatMessage } = useIntl();

    const { repairDetails, hasBillDetails, invalidateRepairDetails } =
        useSubcontractorRepairDetailsContext();

    const handleError = useApiErrorHandler();

    const { isLoading, mutate } = useMutation({
        mutationFn: () =>
            closeSubcontractorRepairServiceCall(),
        onSuccess: () => {
            invalidateRepairDetails();
        },
        onError: (error) => handleError(error),
    });

    if (
        !repairDetails ||
        !hasBillDetails ||
        repairDetails.status === ServiceCallStatus.closed ||
        repairDetails.warrantyStatus ===
            SubcontractorRepairWarrantyStatus.UnderWarranty
    ) {
        return null;
    }

    return (
        <div>
            <Button
                primary
                floated="right"
                onClick={() => mutate()}
                loading={isLoading}
                disabled={isLoading}
            >
                {formatMessage({
                    id: 'SubcontractorRepairPage.CloseServiceCallSection.SaveAndCloseServiceCall',
                    defaultMessage: 'Save and close service call',
                })}
            </Button>
        </div>
    );
};
