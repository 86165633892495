import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedNumber } from 'react-intl';
import { Card, List, Form } from 'semantic-ui-react';

import { commonMessages } from '../../../constants';
import { PreferredLanguage, ReturnForCredit } from '../../../state/models';
import { AgentActionItem } from '..';
import { CurrencyInput } from '../../common';
import { SendEmailModal } from '../../after-sales';

interface ReturnForCreditCardOwnProps {
    serviceCallId: number;
    clientEmails: string[];
    clientDefaultLanguage?: PreferredLanguage;
    returnForCredit: ReturnForCredit | undefined;
    isExecutingStep: boolean;
    onSendNotification: (email: string | undefined, locale: string | undefined) => void;
    onConfirmReception: () => void;
    onAddCredit: (amountCredited: number, shippingFeesCredited: number, currencyCode: string) => void;
}

interface ReturnForCreditCardState {
    amountCredited: number;
    shippingFeesCredited: number;
    currencyCode: string;
    isEmailModalOpen: boolean;
}

export type ReturnForCreditCardProps =
    & ReturnForCreditCardOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'ReturnForCreditCard.title', defaultMessage: 'Return for credit' },
    subtitle: { id: 'ReturnForCreditCard.subtitle', defaultMessage: 'Send a notification to the client for the furniture\'s return.' },
    notifyStepTitle: { id: 'ReturnForCreditCard.notify_step_title', defaultMessage: 'Request the furniture\'s shipment' },
    notifyStepDescription: { id: 'ReturnForCreditCard.notify_step_description', defaultMessage: 'Notify the client that the furniture needs to be returned to Jaymar\'s factory.' },
    confirmReceptionStepTitle: { id: 'ReturnForCreditCard.confirm_reception_step_title', defaultMessage: 'Confirm the furniture\'s reception' },
    confirmReceptionStepDescription: { id: 'ReturnForCreditCard.confirm_reception_step_description', defaultMessage: 'Confirm that the furniture was received at Jaymar\'s warehouse.' },
    creditStepTitle: { id: 'ReturnForCreditCard.credit_step_title', defaultMessage: 'Specify the amount credited' },
    creditStepDescription: { id: 'ReturnForCreditCard.credit_step_description', defaultMessage: 'Specify the amount that is credited for the furniture\'s return.' },
    amountCreditedLabel: { id: 'ReturnForCreditCard.amount_credited', defaultMessage: 'Amount credited' },
    shippingFeesCreditedLabel: { id: 'ReturnForCreditCard.shipping_fees_credited', defaultMessage: 'Shipping fees credited' },
    totalLabel: { id: 'ReturnForCreditCard.total_label', defaultMessage: 'Total' },
    emailAttachmentDescription: { id: 'ReturnForCreditCard.email_attachmente_description', defaultMessage: 'Credit return authorization'},
    emailModalTitle: { id: 'ReturnForCreditCard.email_modal_title', defaultMessage: 'Send a confirmation email' },
    emailModalSubtitle: { id: 'ReturnForCreditCard.email_modal_subtitle', defaultMessage: 'Send automatically an email message to the client to notify him that the furniture needs to be shipped to Jaymar\' factory to apply the credit.'}
});

class ReturnForCreditCard extends React.Component<ReturnForCreditCardProps, ReturnForCreditCardState> {
    public constructor(props: ReturnForCreditCardProps) {
        super(props);

        this.state = {
            amountCredited: props.returnForCredit ? props.returnForCredit.amountCredited : 0,
            shippingFeesCredited: props.returnForCredit ? props.returnForCredit.shippingFeesCredited : 0,
            currencyCode: props.returnForCredit ? props.returnForCredit.currencyCode : 'CAD',
            isEmailModalOpen: false
        };
    }

    public componentWillReceiveProps(props: ReturnForCreditCardProps) {
        if (this.props.returnForCredit == null && props.returnForCredit != null) {
            this.setState({
                amountCredited: props.returnForCredit ? props.returnForCredit.amountCredited : 0,
                shippingFeesCredited: props.returnForCredit ? props.returnForCredit.shippingFeesCredited : 0,
                currencyCode: props.returnForCredit ? props.returnForCredit.currencyCode : 'CAD'
           });
        }
    }
    
    public render() {
        const { formatMessage } = this.props.intl;
        const isWaitingToReceiveShipment = this.props.returnForCredit != null && this.props.returnForCredit.receivedOn == null;
        const isWaitingForRepairs = this.props.returnForCredit != null && this.props.returnForCredit.receivedOn != null;

        const isNotificationRequired = !isWaitingToReceiveShipment && !isWaitingForRepairs;
        const isFurnitureReceptionRequired = isWaitingToReceiveShipment;
        const isRepairCompletionRequired = isWaitingForRepairs;

        return (
            <Card fluid={true} color="blue" style={{height: '100%'}}>
                <Card.Content style={{flexGrow: 0}}>
                    <Card.Header>{formatMessage(m.title)}</Card.Header>
                    <Card.Meta>{formatMessage(m.subtitle)}</Card.Meta>
                </Card.Content>
                <Card.Content>
                    <List divided={true} relaxed="very">
                        {this.renderNotifyStep(isNotificationRequired, isFurnitureReceptionRequired || isRepairCompletionRequired)}
                        {this.renderConfirmReceptionStep(isFurnitureReceptionRequired, isRepairCompletionRequired)}
                        {this.renderCreditStep(isRepairCompletionRequired, false)}
                    </List>
                </Card.Content>
            </Card>
        );
    }

    private renderNotifyStep(isEnabled: boolean, isCompleted: boolean) {
        const { formatMessage } = this.props.intl;

        return (
            <AgentActionItem 
                isEnabled={isEnabled} 
                isCompleted={isCompleted} 
                icon="send" 
                title={formatMessage(m.notifyStepTitle)}
                description={formatMessage(m.notifyStepDescription)}
                completeButtonText={formatMessage(commonMessages.notify)}
                isCompleteButtonLoading={this.props.isExecutingStep}
                isCompleteButtonDisabled={this.props.isExecutingStep}
                onCompleteClick={() => this.setState({ isEmailModalOpen: true })}
                completedButtonText={formatMessage(commonMessages.notified)}
            >
                <SendEmailModal 
                    previewUri={`/email-templates/credit-return-requested`}
                    attachmentsPreviewUris={[{
                        filename: `Retour-Credit-${this.props.serviceCallId}.pdf`,
                        previewUri: `/reports/return-for-credit/${this.props.serviceCallId}`,
                        description: formatMessage(m.emailAttachmentDescription)
                    }]}
                    title={formatMessage(m.emailModalTitle)}
                    subtitle={formatMessage(m.emailModalSubtitle)}
                    size="tiny"
                    defaultEmails={this.props.clientEmails}
                    defaultLanguage={this.props.clientDefaultLanguage}
                    open={this.state.isEmailModalOpen}
                    onClose={() => this.setState({ isEmailModalOpen: false })}
                    onCancel={() => this.setState({ isEmailModalOpen: false })}
                    onSend={this.sendEmail}
                    onSendManually={this.sendEmailManually}
                />
            </AgentActionItem>
        );
    }

    private renderConfirmReceptionStep(isEnabled: boolean, isCompleted: boolean) {
        const { formatMessage } = this.props.intl;

        return (
            <AgentActionItem 
                isEnabled={isEnabled} 
                isCompleted={isCompleted} 
                icon="truck" 
                title={formatMessage(m.confirmReceptionStepTitle)}
                description={formatMessage(m.confirmReceptionStepDescription)}
                isCompleteButtonLoading={this.props.isExecutingStep}
                isCompleteButtonDisabled={this.props.isExecutingStep}
                completeButtonText={formatMessage(commonMessages.confirm)}
                onCompleteClick={this.props.onConfirmReception}
                completedButtonText={formatMessage(commonMessages.confirmed)}
            />
        );
    }

    private renderCreditStep(isEnabled: boolean, isCompleted: boolean) {
        const { formatMessage } = this.props.intl;
        const areCreditControlsVisible = isCompleted || isEnabled;

        return (
            <AgentActionItem 
                isEnabled={isEnabled} 
                isCompleted={isCompleted} 
                icon="dollar" 
                title={formatMessage(m.creditStepTitle)}
                description={formatMessage(m.creditStepDescription)}
                completeButtonText={formatMessage(commonMessages.add)}
                isCompleteButtonLoading={this.props.isExecutingStep}
                isCompleteButtonDisabled={this.props.isExecutingStep}
                onCompleteClick={this.addCredit}
                completedButtonText={formatMessage(commonMessages.added)}
            >
                {areCreditControlsVisible &&
                    <Form>
                        <Form.Field disabled={!isEnabled}>
                            <label>{formatMessage(m.amountCreditedLabel)}</label>
                            <CurrencyInput 
                                value={this.state.amountCredited}
                                currency={this.state.currencyCode}
                                onChange={this.changeAmountCredited}
                            />
                        </Form.Field>

                        <Form.Field disabled={!isEnabled}>
                            <label>{formatMessage(m.shippingFeesCreditedLabel)}</label>
                            <CurrencyInput 
                                value={this.state.shippingFeesCredited}
                                currency={this.state.currencyCode}
                                onChange={this.changeShippingFeesCredited}
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>{formatMessage(m.totalLabel)}</label>
                            <div style={{textAlign: 'right'}}>
                                <FormattedNumber 
                                    value={this.state.amountCredited + this.state.shippingFeesCredited} 
                                    style="currency" 
                                    currency={this.state.currencyCode}
                                />
                            </div>
                        </Form.Field>
                    </Form>
                }
            </AgentActionItem>
        );
    }

    private changeAmountCredited = (value: number, currency: string ) => {
        this.setState({
            amountCredited: value,
            currencyCode: currency
        });
    }

    private changeShippingFeesCredited = (value: number, currency: string ) => {
        this.setState({
            shippingFeesCredited: value,
            currencyCode: currency
        });
    }

    private addCredit = () => {
        this.props.onAddCredit(
            this.state.amountCredited, 
            this.state.shippingFeesCredited,
            this.state.currencyCode
        );
    }

    private sendEmail = (email: string, locale: string) => {
        this.setState({ isEmailModalOpen: false });
        this.props.onSendNotification(email, locale);
    }

    private sendEmailManually = () => {
        this.setState({ isEmailModalOpen: false });
        this.props.onSendNotification(undefined, undefined);
    }
}

const connectedComponent = injectIntl(ReturnForCreditCard);
export { connectedComponent as ReturnForCreditCard };