import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Message } from "semantic-ui-react";
import { InternalLink } from "../../../../../components/common/InternalLink";

interface OwnProps {
    onReactivate: () => void;
}

export type UserAccountDisabledWarningProps =
    & OwnProps;

const m = defineMessages({
    title: { id: 'UserAccountDisabledWarning.title', defaultMessage: 'User account is disabled' },
    description: { id: 'UserAccountDisabledWarning.description', defaultMessage: 'This user account has been disabled. Do you wish to <a>reactivate</a> it?'}
})

export const UserAccountDisabledWarning: React.FC<UserAccountDisabledWarningProps> = (props) => {
    const { formatMessage } = useIntl();

    return (
        <Message 
            warning
            icon="warning circle"
            header={formatMessage(m.title)}
            content={formatMessage(m.description, { a: chunks => <InternalLink onClick={props.onReactivate}>{chunks}</InternalLink> })}
        />
    );
};