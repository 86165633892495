import * as React from 'react';
import { List, Icon, SemanticICONS, Button } from 'semantic-ui-react';
import { useResponsive } from 'utils/responsive';

interface OwnProps {
    isEnabled: boolean;
    isCompleted: boolean;
    icon: SemanticICONS;
    title: string;
    description: string;
    completeButtonText: string;
    isCompleteButtonDisabled?: boolean;
    isCompleteButtonLoading?: boolean;
    onCompleteClick: () => void;
    isCompletedButtonDisabled?: boolean;
    isCompletedButtonLoading?: boolean;
    completedButtonText: string;
    onCompletedClick?: () => void;
}

export type AgentActionItemProps =
    & OwnProps;

export const AgentActionItem: React.FC<AgentActionItemProps> = (props) => {
    const { isEnabled } = props;
    const { isLargeMonitor, isWidescreenMonitor } = useResponsive();
    const isLargeResolution = isLargeMonitor || isWidescreenMonitor;

    const renderStepButton = () => {
        return (
            props.isCompleted
                ? (
                    <Button 
                        positive
                        fluid
                        icon="check"
                        disabled={props.onCompletedClick == null || props.isCompletedButtonDisabled} 
                        loading={props.isCompletedButtonLoading}
                        content={props.completedButtonText} 
                        onClick={props.onCompletedClick} 
                        style={{minWidth: 124}} 
                    />
                )
                : (
                    <Button 
                        primary={true} 
                        fluid={true} 
                        disabled={!props.isEnabled || props.isCompleteButtonDisabled} 
                        loading={props.isCompleteButtonLoading}
                        content={props.completeButtonText} 
                        onClick={props.onCompleteClick} 
                        style={{minWidth: 124}}
                    />
                )
        );
    }

    return (
        <List.Item disabled={!isEnabled}>
            {isLargeResolution &&
                <List.Content floated="right">
                    {renderStepButton()}
                </List.Content>
            }
            <Icon name={props.icon} size="large" />
            <List.Content>
                <List.Header>{props.title}</List.Header>
                <List.Description>
                    <p>{props.description}</p>
                    {props.children}
                </List.Description>
            </List.Content>
            {!isLargeResolution &&
                <List.Content>
                    <div style={{marginTop: 10}}>
                        {renderStepButton()}
                    </div>
                </List.Content>
            }
        </List.Item>
    );
}