import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Checkbox, Header, Table, TableRowProps } from "semantic-ui-react";
import { UserHelper } from "../../../../../../helpers";
import { User } from "../../../../../../state/models";
import styles from './UsersTableRow.module.css';

interface OwnProps {
    user: User;
    isLoading?: boolean;
}

export type UsersTableRowProps =
    & TableRowProps
    & OwnProps;

const m = defineMessages({
    inactiveUserLabel: { id: 'UsersTableRow.inactiveUserLabel', defaultMessage: 'Disabled' }
});

export const UsersTableRow: React.FC<UsersTableRowProps> = (props) => {
    const { user, isLoading, ...tableRowProps } = props;
    const { formatMessage } = useIntl();

    return (
        <Table.Row className={props.user.isDisabled ? styles.disabled : undefined} disabled={isLoading} {...tableRowProps}>
            <Table.Cell>
                <Header size="small">
                    <Header.Content>{UserHelper.getDisplayName(user)}</Header.Content>
                    <Header.Subheader>{formatMessage(UserHelper.getRoleDescription(user.accountType))}</Header.Subheader>
                </Header>
            </Table.Cell>

            <Table.Cell>{user.email}</Table.Cell>
            <Table.Cell>{user.isDisabled && formatMessage(m.inactiveUserLabel)}</Table.Cell>
        </Table.Row>
    );
};