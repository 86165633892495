import { Address, emptyAddress } from '../Address';
import { ValidationResult } from '.';

export class CustomerEdit {
    public name?: string;
    public email?: string;
    public address: Address = emptyAddress;
    public mainPhoneNumber?: string;
    public otherPhoneNumber?: string;

    public constructor(init?: Partial<CustomerEdit>) {
        Object.assign(this, init);
    }

    public validate(): ValidationResult {
        let result = new ValidationResult();

        return result;
    }
}