import { ForgotPasswordEdit } from "modules/account/models/ForgotPasswordEdit";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { defineMessages, useIntl } from "react-intl";
import { Form, Segment } from 'semantic-ui-react';
import { ForgotPasswordValidationSummary } from "./ForgotPasswordValidationSummary";

interface OwnProps {
    loading?: boolean;
    onResetPassword: (data: ForgotPasswordEdit) => void;
}

export type ForgotPasswordFormProps =
    & OwnProps;

const m = defineMessages({
    emailPlaceholder: { id: 'ForgotPasswordForm.emailPlaceholder', defaultMessage: 'Email address associated to your account' },
    emailRequiredValidation: { id: 'ForgotPasswordForm.emailRequiredValidation', defaultMessage: 'Please provide the email address associated to your account.' },
    resetPasswordButton: { id: 'ForgotPasswordForm.resetPasswordButton', defaultMessage: 'Reset your password'}
})

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = (props) => {
    const { formatMessage } = useIntl();
    const { control, formState: { errors }, setValue, handleSubmit } = useForm<ForgotPasswordEdit>();

    return (
        <>
            <Segment raised style={{ width: '100%' }}>
                <Form size="large">
                    <Controller 
                        name={'email'}
                        control={control}
                        rules={{ required: formatMessage(m.emailRequiredValidation) }}
                        render={(props) => (
                            <Form.Input 
                                error={errors.email != null}
                                icon="user" 
                                iconPosition="left" 
                                placeholder={formatMessage(m.emailPlaceholder)}
                                onChange={(e, data) => setValue('email', data.value)}
                            />
                        )}
                    />

                    <Form.Button 
                        fluid 
                        disabled={props.loading}
                        loading={props.loading}
                        size="large" 
                        color="teal" 
                        content={formatMessage(m.resetPasswordButton)} 
                        onClick={handleSubmit(props.onResetPassword)}
                    />
                </Form>
            </Segment>

            <ForgotPasswordValidationSummary errors={errors} />
        </>
    );
};