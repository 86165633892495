import { SupportedLocales } from './configureI18n';
import { ActionTypeKeys } from './types';

export type ActionTypes = 
    | ChangeLocaleAction
    | { type: '' };

export interface ChangeLocaleAction {
    type: ActionTypeKeys.CHANGE_LOCALE;
    payload: SupportedLocales;
}

export const actionCreators = {
    changeLocale: (locale: string) => ({
        type: ActionTypeKeys.CHANGE_LOCALE,
        payload: locale
    })
};