import { IndexAccessibleMessages } from '.';
import { defineMessages } from 'react-intl';

export const countriesMessages = defineMessages({
    af: { id: 'country.af', defaultMessage: 'Afghanistan' },
    ax: { id: 'country.ax', defaultMessage: 'Aland Islands' },
    al: { id: 'country.al', defaultMessage: 'Albania' },
    dz: { id: 'country.dz', defaultMessage: 'Algeria' },
    as: { id: 'country.as', defaultMessage: 'American Samoa' },
    ad: { id: 'country.ad', defaultMessage: 'Andorra' },
    ao: { id: 'country.ao', defaultMessage: 'Angola' },
    ai: { id: 'country.ai', defaultMessage: 'Anguilla' },
    ag: { id: 'country.ag', defaultMessage: 'Antigua' },
    ar: { id: 'country.ar', defaultMessage: 'Argentina' },
    am: { id: 'country.am', defaultMessage: 'Armenia' },
    aw: { id: 'country.aw', defaultMessage: 'Aruba' },
    au: { id: 'country.au', defaultMessage: 'Australia' },
    at: { id: 'country.at', defaultMessage: 'Austria' },
    az: { id: 'country.az', defaultMessage: 'Azerbaijan' },
    bs: { id: 'country.bs', defaultMessage: 'Bahamas' },
    bh: { id: 'country.bh', defaultMessage: 'Bahrain' },
    bd: { id: 'country.bd', defaultMessage: 'Bangladesh' },
    bb: { id: 'country.bb', defaultMessage: 'Barbados' },
    by: { id: 'country.by', defaultMessage: 'Belarus' },
    be: { id: 'country.be', defaultMessage: 'Belgium' },
    bz: { id: 'country.bz', defaultMessage: 'Belize' },
    bj: { id: 'country.bj', defaultMessage: 'Benin' },
    bm: { id: 'country.bm', defaultMessage: 'Bermuda' },
    bt: { id: 'country.bt', defaultMessage: 'Bhutan' },
    bo: { id: 'country.bo', defaultMessage: 'Bolivia' },
    ba: { id: 'country.ba', defaultMessage: 'Bosnia' },
    bw: { id: 'country.bw', defaultMessage: 'Botswana' },
    bv: { id: 'country.bv', defaultMessage: 'Bouvet Island' },
    br: { id: 'country.br', defaultMessage: 'Brazil' },
    vg: { id: 'country.vg', defaultMessage: 'British Virgin Islands' },
    bn: { id: 'country.bn', defaultMessage: 'Brunei' },
    bg: { id: 'country.bg', defaultMessage: 'Bulgaria' },
    bf: { id: 'country.bf', defaultMessage: 'Burkina Faso' },
    mm: { id: 'country.mm', defaultMessage: 'Burma' },
    bi: { id: 'country.bi', defaultMessage: 'Burundi' },
    tc: { id: 'country.tc', defaultMessage: 'Caicos Islands' },
    kh: { id: 'country.kh', defaultMessage: 'Cambodia' },
    cm: { id: 'country.cm', defaultMessage: 'Cameroon' },
    ca: { id: 'country.ca', defaultMessage: 'Canada' },
    cv: { id: 'country.cv', defaultMessage: 'Cape Verde' },
    ky: { id: 'country.ky', defaultMessage: 'Cayman Islands' },
    cf: { id: 'country.cf', defaultMessage: 'Central African Republic' },
    td: { id: 'country.td', defaultMessage: 'Chad' },
    cl: { id: 'country.cl', defaultMessage: 'Chile' },
    cn: { id: 'country.cn', defaultMessage: 'China' },
    cx: { id: 'country.cx', defaultMessage: 'Christmas Island' },
    cc: { id: 'country.cc', defaultMessage: 'Cocos Islands' },
    co: { id: 'country.co', defaultMessage: 'Colombia' },
    km: { id: 'country.km', defaultMessage: 'Comoros' },
    cg: { id: 'country.cg', defaultMessage: 'Congo Brazzaville' },
    cd: { id: 'country.cd', defaultMessage: 'Congo' },
    ck: { id: 'country.ck', defaultMessage: 'Cook Islands' },
    cr: { id: 'country.cr', defaultMessage: 'Costa Rica' },
    ci: { id: 'country.ci', defaultMessage: 'Cote Divoire' },
    hr: { id: 'country.hr', defaultMessage: 'Croatia' },
    cu: { id: 'country.cu', defaultMessage: 'Cuba' },
    cy: { id: 'country.cy', defaultMessage: 'Cyprus' },
    cz: { id: 'country.cz', defaultMessage: 'Czech Republic' },
    dk: { id: 'country.dk', defaultMessage: 'Denmark' },
    dj: { id: 'country.dj', defaultMessage: 'Djibouti' },
    dm: { id: 'country.dm', defaultMessage: 'Dominica' },
    do: { id: 'country.do', defaultMessage: 'Dominican Republic' },
    ec: { id: 'country.ec', defaultMessage: 'Ecuador' },
    eg: { id: 'country.eg', defaultMessage: 'Egypt' },
    sv: { id: 'country.sv', defaultMessage: 'El Salvador' },
    gb: { id: 'country.gb', defaultMessage: 'England' },
    gq: { id: 'country.gq', defaultMessage: 'Equatorial Guinea' },
    er: { id: 'country.er', defaultMessage: 'Eritrea' },
    ee: { id: 'country.ee', defaultMessage: 'Estonia' },
    et: { id: 'country.et', defaultMessage: 'Ethiopia' },
    eu: { id: 'country.eu', defaultMessage: 'European Union' },
    fk: { id: 'country.fk', defaultMessage: 'Falkland Islands' },
    fo: { id: 'country.fo', defaultMessage: 'Faroe Islands' },
    fj: { id: 'country.fj', defaultMessage: 'Fiji' },
    fi: { id: 'country.fi', defaultMessage: 'Finland' },
    fr: { id: 'country.fr', defaultMessage: 'France' },
    gf: { id: 'country.gf', defaultMessage: 'French Guiana' },
    pf: { id: 'country.pf', defaultMessage: 'French Polynesia' },
    tf: { id: 'country.tf', defaultMessage: 'French Territories' },
    ga: { id: 'country.ga', defaultMessage: 'Gabon' },
    gm: { id: 'country.gm', defaultMessage: 'Gambia' },
    ge: { id: 'country.ge', defaultMessage: 'Georgia' },
    de: { id: 'country.de', defaultMessage: 'Germany' },
    gh: { id: 'country.gh', defaultMessage: 'Ghana' },
    gi: { id: 'country.gi', defaultMessage: 'Gibraltar' },
    gr: { id: 'country.gr', defaultMessage: 'Greece' },
    gl: { id: 'country.gl', defaultMessage: 'Greenland' },
    gd: { id: 'country.gd', defaultMessage: 'Grenada' },
    gp: { id: 'country.gp', defaultMessage: 'Guadeloupe' },
    gu: { id: 'country.gu', defaultMessage: 'Guam' },
    gt: { id: 'country.gt', defaultMessage: 'Guatemala' },
    gw: { id: 'country.gw', defaultMessage: 'Guinea-Bissau' },
    gn: { id: 'country.gn', defaultMessage: 'Guinea' },
    gy: { id: 'country.gy', defaultMessage: 'Guyana' },
    ht: { id: 'country.ht', defaultMessage: 'Haiti' },
    hm: { id: 'country.hm', defaultMessage: 'Heard Island' },
    hn: { id: 'country.hn', defaultMessage: 'Honduras' },
    hk: { id: 'country.hk', defaultMessage: 'Hong Kong' },
    hu: { id: 'country.hu', defaultMessage: 'Hungary' },
    is: { id: 'country.is', defaultMessage: 'Iceland' },
    in: { id: 'country.in', defaultMessage: 'India' },
    io: { id: 'country.io', defaultMessage: 'Indian Ocean Territory' },
    id: { id: 'country.id', defaultMessage: 'Indonesia' },
    ir: { id: 'country.ir', defaultMessage: 'Iran' },
    iq: { id: 'country.iq', defaultMessage: 'Iraq' },
    ie: { id: 'country.ie', defaultMessage: 'Ireland' },
    il: { id: 'country.il', defaultMessage: 'Israel' },
    it: { id: 'country.it', defaultMessage: 'Italy' },
    jm: { id: 'country.jm', defaultMessage: 'Jamaica' },
    jp: { id: 'country.jp', defaultMessage: 'Japan' },
    jo: { id: 'country.jo', defaultMessage: 'Jordan' },
    kz: { id: 'country.kz', defaultMessage: 'Kazakhstan' },
    ke: { id: 'country.ke', defaultMessage: 'Kenya' },
    ki: { id: 'country.ki', defaultMessage: 'Kiribati' },
    kw: { id: 'country.kw', defaultMessage: 'Kuwait' },
    kg: { id: 'country.kg', defaultMessage: 'Kyrgyzstan' },
    la: { id: 'country.la', defaultMessage: 'Laos' },
    lv: { id: 'country.lv', defaultMessage: 'Latvia' },
    lb: { id: 'country.lb', defaultMessage: 'Lebanon' },
    ls: { id: 'country.ls', defaultMessage: 'Lesotho' },
    lr: { id: 'country.lr', defaultMessage: 'Liberia' },
    ly: { id: 'country.ly', defaultMessage: 'Libya' },
    li: { id: 'country.li', defaultMessage: 'Liechtenstein' },
    lt: { id: 'country.lt', defaultMessage: 'Lithuania' },
    lu: { id: 'country.lu', defaultMessage: 'Luxembourg' },
    mo: { id: 'country.mo', defaultMessage: 'Macau' },
    mk: { id: 'country.mk', defaultMessage: 'Macedonia' },
    mg: { id: 'country.mg', defaultMessage: 'Madagascar' },
    mw: { id: 'country.mw', defaultMessage: 'Malawi' },
    my: { id: 'country.my', defaultMessage: 'Malaysia' },
    mv: { id: 'country.mv', defaultMessage: 'Maldives' },
    ml: { id: 'country.ml', defaultMessage: 'Mali' },
    mt: { id: 'country.mt', defaultMessage: 'Malta' },
    mh: { id: 'country.mh', defaultMessage: 'Marshall Islands' },
    mq: { id: 'country.mq', defaultMessage: 'Martinique' },
    mr: { id: 'country.mr', defaultMessage: 'Mauritania' },
    mu: { id: 'country.mu', defaultMessage: 'Mauritius' },
    yt: { id: 'country.yt', defaultMessage: 'Mayotte' },
    mx: { id: 'country.mx', defaultMessage: 'Mexico' },
    fm: { id: 'country.fm', defaultMessage: 'Micronesia' },
    md: { id: 'country.md', defaultMessage: 'Moldova' },
    mc: { id: 'country.mc', defaultMessage: 'Monaco' },
    mn: { id: 'country.mn', defaultMessage: 'Mongolia' },
    me: { id: 'country.me', defaultMessage: 'Montenegro' },
    ms: { id: 'country.ms', defaultMessage: 'Montserrat' },
    ma: { id: 'country.ma', defaultMessage: 'Morocco' },
    mz: { id: 'country.mz', defaultMessage: 'Mozambique' },
    na: { id: 'country.na', defaultMessage: 'Namibia' },
    nr: { id: 'country.nr', defaultMessage: 'Nauru' },
    np: { id: 'country.np', defaultMessage: 'Nepal' },
    an: { id: 'country.an', defaultMessage: 'Netherlands Antilles' },
    nl: { id: 'country.nl', defaultMessage: 'Netherlands' },
    nc: { id: 'country.nc', defaultMessage: 'New Caledonia' },
    pg: { id: 'country.pg', defaultMessage: 'New Guinea' },
    nz: { id: 'country.nz', defaultMessage: 'New Zealand' },
    ni: { id: 'country.ni', defaultMessage: 'Nicaragua' },
    ne: { id: 'country.ne', defaultMessage: 'Niger' },
    ng: { id: 'country.ng', defaultMessage: 'Nigeria' },
    nu: { id: 'country.nu', defaultMessage: 'Niue' },
    nf: { id: 'country.nf', defaultMessage: 'Norfolk Island' },
    kp: { id: 'country.kp', defaultMessage: 'North Korea' },
    mp: { id: 'country.mp', defaultMessage: 'Northern Mariana Islands' },
    no: { id: 'country.no', defaultMessage: 'Norway' },
    om: { id: 'country.om', defaultMessage: 'Oman' },
    pk: { id: 'country.pk', defaultMessage: 'Pakistan' },
    pw: { id: 'country.pw', defaultMessage: 'Palau' },
    ps: { id: 'country.ps', defaultMessage: 'Palestine' },
    pa: { id: 'country.pa', defaultMessage: 'Panama' },
    py: { id: 'country.py', defaultMessage: 'Paraguay' },
    pe: { id: 'country.pe', defaultMessage: 'Peru' },
    ph: { id: 'country.ph', defaultMessage: 'Philippines' },
    pn: { id: 'country.pn', defaultMessage: 'Pitcairn Islands' },
    pl: { id: 'country.pl', defaultMessage: 'Poland' },
    pt: { id: 'country.pt', defaultMessage: 'Portugal' },
    pr: { id: 'country.pr', defaultMessage: 'Puerto Rico' },
    qa: { id: 'country.qa', defaultMessage: 'Qatar' },
    re: { id: 'country.re', defaultMessage: 'Reunion' },
    ro: { id: 'country.ro', defaultMessage: 'Romania' },
    ru: { id: 'country.ru', defaultMessage: 'Russia' },
    rw: { id: 'country.rw', defaultMessage: 'Rwanda' },
    sh: { id: 'country.sh', defaultMessage: 'Saint Helena' },
    kn: { id: 'country.kn', defaultMessage: 'Saint Kitts and Nevis' },
    lc: { id: 'country.lc', defaultMessage: 'Saint Lucia' },
    pm: { id: 'country.pm', defaultMessage: 'Saint Pierre' },
    vc: { id: 'country.vc', defaultMessage: 'Saint Vincent' },
    ws: { id: 'country.ws', defaultMessage: 'Samoa' },
    sm: { id: 'country.sm', defaultMessage: 'San Marino' },
    gs: { id: 'country.gs', defaultMessage: 'Sandwich Islands' },
    st: { id: 'country.st', defaultMessage: 'Sao Tome' },
    sa: { id: 'country.sa', defaultMessage: 'Saudi Arabia' },
    sn: { id: 'country.sn', defaultMessage: 'Senegal' },
    cs: { id: 'country.cs', defaultMessage: 'Serbia' },
    rs: { id: 'country.rs', defaultMessage: 'Serbia' },
    sc: { id: 'country.sc', defaultMessage: 'Seychelles' },
    sl: { id: 'country.sl', defaultMessage: 'Sierra Leone' },
    sg: { id: 'country.sg', defaultMessage: 'Singapore' },
    sk: { id: 'country.sk', defaultMessage: 'Slovakia' },
    si: { id: 'country.si', defaultMessage: 'Slovenia' },
    sb: { id: 'country.sb', defaultMessage: 'Solomon Islands' },
    so: { id: 'country.so', defaultMessage: 'Somalia' },
    za: { id: 'country.za', defaultMessage: 'South Africa' },
    kr: { id: 'country.kr', defaultMessage: 'South Korea' },
    es: { id: 'country.es', defaultMessage: 'Spain' },
    lk: { id: 'country.lk', defaultMessage: 'Sri Lanka' },
    sd: { id: 'country.sd', defaultMessage: 'Sudan' },
    sr: { id: 'country.sr', defaultMessage: 'Suriname' },
    sj: { id: 'country.sj', defaultMessage: 'Svalbard' },
    sz: { id: 'country.sz', defaultMessage: 'Swaziland' },
    se: { id: 'country.se', defaultMessage: 'Sweden' },
    ch: { id: 'country.ch', defaultMessage: 'Switzerland' },
    sy: { id: 'country.sy', defaultMessage: 'Syria' },
    tw: { id: 'country.tw', defaultMessage: 'Taiwan' },
    tj: { id: 'country.tj', defaultMessage: 'Tajikistan' },
    tz: { id: 'country.tz', defaultMessage: 'Tanzania' },
    th: { id: 'country.th', defaultMessage: 'Thailand' },
    tl: { id: 'country.tl', defaultMessage: 'Timorleste' },
    tg: { id: 'country.tg', defaultMessage: 'Togo' },
    tk: { id: 'country.tk', defaultMessage: 'Tokelau' },
    to: { id: 'country.to', defaultMessage: 'Tonga' },
    tt: { id: 'country.tt', defaultMessage: 'Trinidad' },
    tn: { id: 'country.tn', defaultMessage: 'Tunisia' },
    tr: { id: 'country.tr', defaultMessage: 'Turkey' },
    tm: { id: 'country.tm', defaultMessage: 'Turkmenistan' },
    tv: { id: 'country.tv', defaultMessage: 'Tuvalu' },
    ug: { id: 'country.ug', defaultMessage: 'Uganda' },
    ua: { id: 'country.ua', defaultMessage: 'Ukraine' },
    ae: { id: 'country.ae', defaultMessage: 'United Arab Emirates' },
    us: { id: 'country.us', defaultMessage: 'United States' },
    uy: { id: 'country.uy', defaultMessage: 'Uruguay' },
    um: { id: 'country.um', defaultMessage: 'Us Minor Islands' },
    vi: { id: 'country.vi', defaultMessage: 'Us Virgin Islands' },
    uz: { id: 'country.uz', defaultMessage: 'Uzbekistan' },
    vu: { id: 'country.vu', defaultMessage: 'Vanuatu' },
    va: { id: 'country.va', defaultMessage: 'Vatican City' },
    ve: { id: 'country.ve', defaultMessage: 'Venezuela' },
    vn: { id: 'country.vn', defaultMessage: 'Vietnam' },
    wf: { id: 'country.wf', defaultMessage: 'Wallis and Futuna' },
    eh: { id: 'country.eh', defaultMessage: 'Western Sahara' },
    ye: { id: 'country.ye', defaultMessage: 'Yemen' },
    zm: { id: 'country.zm', defaultMessage: 'Zambia' },
    zw: { id: 'country.zw', defaultMessage: 'Zimbabwe' },
}) as IndexAccessibleMessages;

export const countries = [
    { key: 'ca', value: 'ca', flag: 'ca', intlMessage: countriesMessages.ca },
    { key: 'us', value: 'us', flag: 'us', intlMessage: countriesMessages.us },
    { key: 'af', value: 'af', flag: 'af', intlMessage: countriesMessages.af },
    { key: 'ax', value: 'ax', flag: 'ax', intlMessage: countriesMessages.ax },
    { key: 'al', value: 'al', flag: 'al', intlMessage: countriesMessages.al },
    { key: 'dz', value: 'dz', flag: 'dz', intlMessage: countriesMessages.dz },
    { key: 'as', value: 'as', flag: 'as', intlMessage: countriesMessages.as },
    { key: 'ad', value: 'ad', flag: 'ad', intlMessage: countriesMessages.ad },
    { key: 'ao', value: 'ao', flag: 'ao', intlMessage: countriesMessages.ao },
    { key: 'ai', value: 'ai', flag: 'ai', intlMessage: countriesMessages.ai },
    { key: 'ag', value: 'ag', flag: 'ag', intlMessage: countriesMessages.ag },
    { key: 'ar', value: 'ar', flag: 'ar', intlMessage: countriesMessages.ar },
    { key: 'am', value: 'am', flag: 'am', intlMessage: countriesMessages.am },
    { key: 'aw', value: 'aw', flag: 'aw', intlMessage: countriesMessages.aw },
    { key: 'au', value: 'au', flag: 'au', intlMessage: countriesMessages.au },
    { key: 'at', value: 'at', flag: 'at', intlMessage: countriesMessages.at },
    { key: 'az', value: 'az', flag: 'az', intlMessage: countriesMessages.az },
    { key: 'bs', value: 'bs', flag: 'bs', intlMessage: countriesMessages.bs },
    { key: 'bh', value: 'bh', flag: 'bh', intlMessage: countriesMessages.bh },
    { key: 'bd', value: 'bd', flag: 'bd', intlMessage: countriesMessages.bd },
    { key: 'bb', value: 'bb', flag: 'bb', intlMessage: countriesMessages.bb },
    { key: 'by', value: 'by', flag: 'by', intlMessage: countriesMessages.by },
    { key: 'be', value: 'be', flag: 'be', intlMessage: countriesMessages.be },
    { key: 'bz', value: 'bz', flag: 'bz', intlMessage: countriesMessages.bz },
    { key: 'bj', value: 'bj', flag: 'bj', intlMessage: countriesMessages.bj },
    { key: 'bm', value: 'bm', flag: 'bm', intlMessage: countriesMessages.bm },
    { key: 'bt', value: 'bt', flag: 'bt', intlMessage: countriesMessages.bt },
    { key: 'bo', value: 'bo', flag: 'bo', intlMessage: countriesMessages.bo },
    { key: 'ba', value: 'ba', flag: 'ba', intlMessage: countriesMessages.ba },
    { key: 'bw', value: 'bw', flag: 'bw', intlMessage: countriesMessages.bw },
    { key: 'bv', value: 'bv', flag: 'bv', intlMessage: countriesMessages.bv },
    { key: 'br', value: 'br', flag: 'br', intlMessage: countriesMessages.br },
    { key: 'vg', value: 'vg', flag: 'vg', intlMessage: countriesMessages.vg },
    { key: 'bn', value: 'bn', flag: 'bn', intlMessage: countriesMessages.bn },
    { key: 'bg', value: 'bg', flag: 'bg', intlMessage: countriesMessages.bg },
    { key: 'bf', value: 'bf', flag: 'bf', intlMessage: countriesMessages.bf },
    { key: 'mm', value: 'mm', flag: 'mm', intlMessage: countriesMessages.mm },
    { key: 'bi', value: 'bi', flag: 'bi', intlMessage: countriesMessages.bi },
    { key: 'tc', value: 'tc', flag: 'tc', intlMessage: countriesMessages.tc },
    { key: 'kh', value: 'kh', flag: 'kh', intlMessage: countriesMessages.kh },
    { key: 'cm', value: 'cm', flag: 'cm', intlMessage: countriesMessages.cm },
    { key: 'cv', value: 'cv', flag: 'cv', intlMessage: countriesMessages.cv },
    { key: 'ky', value: 'ky', flag: 'ky', intlMessage: countriesMessages.ky },
    { key: 'cf', value: 'cf', flag: 'cf', intlMessage: countriesMessages.cf },
    { key: 'td', value: 'td', flag: 'td', intlMessage: countriesMessages.td },
    { key: 'cl', value: 'cl', flag: 'cl', intlMessage: countriesMessages.cl },
    { key: 'cn', value: 'cn', flag: 'cn', intlMessage: countriesMessages.cn },
    { key: 'cx', value: 'cx', flag: 'cx', intlMessage: countriesMessages.cx },
    { key: 'cc', value: 'cc', flag: 'cc', intlMessage: countriesMessages.cc },
    { key: 'co', value: 'co', flag: 'co', intlMessage: countriesMessages.co },
    { key: 'km', value: 'km', flag: 'km', intlMessage: countriesMessages.km },
    { key: 'cg', value: 'cg', flag: 'cg', intlMessage: countriesMessages.cg },
    { key: 'cd', value: 'cd', flag: 'cd', intlMessage: countriesMessages.cd },
    { key: 'ck', value: 'ck', flag: 'ck', intlMessage: countriesMessages.ck },
    { key: 'cr', value: 'cr', flag: 'cr', intlMessage: countriesMessages.cr },
    { key: 'ci', value: 'ci', flag: 'ci', intlMessage: countriesMessages.ci },
    { key: 'hr', value: 'hr', flag: 'hr', intlMessage: countriesMessages.hr },
    { key: 'cu', value: 'cu', flag: 'cu', intlMessage: countriesMessages.cu },
    { key: 'cy', value: 'cy', flag: 'cy', intlMessage: countriesMessages.cy },
    { key: 'cz', value: 'cz', flag: 'cz', intlMessage: countriesMessages.cz },
    { key: 'dk', value: 'dk', flag: 'dk', intlMessage: countriesMessages.dk },
    { key: 'dj', value: 'dj', flag: 'dj', intlMessage: countriesMessages.dj },
    { key: 'dm', value: 'dm', flag: 'dm', intlMessage: countriesMessages.dm },
    { key: 'do', value: 'do', flag: 'do', intlMessage: countriesMessages.do },
    { key: 'ec', value: 'ec', flag: 'ec', intlMessage: countriesMessages.ec },
    { key: 'eg', value: 'eg', flag: 'eg', intlMessage: countriesMessages.eg },
    { key: 'sv', value: 'sv', flag: 'sv', intlMessage: countriesMessages.sv },
    { key: 'gb', value: 'gb', flag: 'gb', intlMessage: countriesMessages.gb },
    { key: 'gq', value: 'gq', flag: 'gq', intlMessage: countriesMessages.gq },
    { key: 'er', value: 'er', flag: 'er', intlMessage: countriesMessages.er },
    { key: 'ee', value: 'ee', flag: 'ee', intlMessage: countriesMessages.ee },
    { key: 'et', value: 'et', flag: 'et', intlMessage: countriesMessages.et },
    { key: 'eu', value: 'eu', flag: 'eu', intlMessage: countriesMessages.eu },
    { key: 'fk', value: 'fk', flag: 'fk', intlMessage: countriesMessages.fk },
    { key: 'fo', value: 'fo', flag: 'fo', intlMessage: countriesMessages.fo },
    { key: 'fj', value: 'fj', flag: 'fj', intlMessage: countriesMessages.fj },
    { key: 'fi', value: 'fi', flag: 'fi', intlMessage: countriesMessages.fi },
    { key: 'fr', value: 'fr', flag: 'fr', intlMessage: countriesMessages.fr },
    { key: 'gf', value: 'gf', flag: 'gf', intlMessage: countriesMessages.gf },
    { key: 'pf', value: 'pf', flag: 'pf', intlMessage: countriesMessages.pf },
    { key: 'tf', value: 'tf', flag: 'tf', intlMessage: countriesMessages.tf },
    { key: 'ga', value: 'ga', flag: 'ga', intlMessage: countriesMessages.ga },
    { key: 'gm', value: 'gm', flag: 'gm', intlMessage: countriesMessages.gm },
    { key: 'ge', value: 'ge', flag: 'ge', intlMessage: countriesMessages.ge },
    { key: 'de', value: 'de', flag: 'de', intlMessage: countriesMessages.de },
    { key: 'gh', value: 'gh', flag: 'gh', intlMessage: countriesMessages.gh },
    { key: 'gi', value: 'gi', flag: 'gi', intlMessage: countriesMessages.gi },
    { key: 'gr', value: 'gr', flag: 'gr', intlMessage: countriesMessages.gr },
    { key: 'gl', value: 'gl', flag: 'gl', intlMessage: countriesMessages.gl },
    { key: 'gd', value: 'gd', flag: 'gd', intlMessage: countriesMessages.gd },
    { key: 'gp', value: 'gp', flag: 'gp', intlMessage: countriesMessages.gp },
    { key: 'gu', value: 'gu', flag: 'gu', intlMessage: countriesMessages.gu },
    { key: 'gt', value: 'gt', flag: 'gt', intlMessage: countriesMessages.gt },
    { key: 'gw', value: 'gw', flag: 'gw', intlMessage: countriesMessages.gw },
    { key: 'gn', value: 'gn', flag: 'gn', intlMessage: countriesMessages.gn },
    { key: 'gy', value: 'gy', flag: 'gy', intlMessage: countriesMessages.gy },
    { key: 'ht', value: 'ht', flag: 'ht', intlMessage: countriesMessages.ht },
    { key: 'hm', value: 'hm', flag: 'hm', intlMessage: countriesMessages.hm },
    { key: 'hn', value: 'hn', flag: 'hn', intlMessage: countriesMessages.hn },
    { key: 'hk', value: 'hk', flag: 'hk', intlMessage: countriesMessages.hk },
    { key: 'hu', value: 'hu', flag: 'hu', intlMessage: countriesMessages.hu },
    { key: 'is', value: 'is', flag: 'is', intlMessage: countriesMessages.is },
    { key: 'in', value: 'in', flag: 'in', intlMessage: countriesMessages.in },
    { key: 'io', value: 'io', flag: 'io', intlMessage: countriesMessages.io },
    { key: 'id', value: 'id', flag: 'id', intlMessage: countriesMessages.id },
    { key: 'ir', value: 'ir', flag: 'ir', intlMessage: countriesMessages.ir },
    { key: 'iq', value: 'iq', flag: 'iq', intlMessage: countriesMessages.iq },
    { key: 'ie', value: 'ie', flag: 'ie', intlMessage: countriesMessages.ie },
    { key: 'il', value: 'il', flag: 'il', intlMessage: countriesMessages.il },
    { key: 'it', value: 'it', flag: 'it', intlMessage: countriesMessages.it },
    { key: 'jm', value: 'jm', flag: 'jm', intlMessage: countriesMessages.jm },
    { key: 'jp', value: 'jp', flag: 'jp', intlMessage: countriesMessages.jp },
    { key: 'jo', value: 'jo', flag: 'jo', intlMessage: countriesMessages.jo },
    { key: 'kz', value: 'kz', flag: 'kz', intlMessage: countriesMessages.kz },
    { key: 'ke', value: 'ke', flag: 'ke', intlMessage: countriesMessages.ke },
    { key: 'ki', value: 'ki', flag: 'ki', intlMessage: countriesMessages.ki },
    { key: 'kw', value: 'kw', flag: 'kw', intlMessage: countriesMessages.kw },
    { key: 'kg', value: 'kg', flag: 'kg', intlMessage: countriesMessages.kg },
    { key: 'la', value: 'la', flag: 'la', intlMessage: countriesMessages.la },
    { key: 'lv', value: 'lv', flag: 'lv', intlMessage: countriesMessages.lv },
    { key: 'lb', value: 'lb', flag: 'lb', intlMessage: countriesMessages.lb },
    { key: 'ls', value: 'ls', flag: 'ls', intlMessage: countriesMessages.ls },
    { key: 'lr', value: 'lr', flag: 'lr', intlMessage: countriesMessages.lr },
    { key: 'ly', value: 'ly', flag: 'ly', intlMessage: countriesMessages.ly },
    { key: 'li', value: 'li', flag: 'li', intlMessage: countriesMessages.li },
    { key: 'lt', value: 'lt', flag: 'lt', intlMessage: countriesMessages.lt },
    { key: 'lu', value: 'lu', flag: 'lu', intlMessage: countriesMessages.lu },
    { key: 'mo', value: 'mo', flag: 'mo', intlMessage: countriesMessages.mo },
    { key: 'mk', value: 'mk', flag: 'mk', intlMessage: countriesMessages.mk },
    { key: 'mg', value: 'mg', flag: 'mg', intlMessage: countriesMessages.mg },
    { key: 'mw', value: 'mw', flag: 'mw', intlMessage: countriesMessages.mw },
    { key: 'my', value: 'my', flag: 'my', intlMessage: countriesMessages.my },
    { key: 'mv', value: 'mv', flag: 'mv', intlMessage: countriesMessages.mv },
    { key: 'ml', value: 'ml', flag: 'ml', intlMessage: countriesMessages.ml },
    { key: 'mt', value: 'mt', flag: 'mt', intlMessage: countriesMessages.mt },
    { key: 'mh', value: 'mh', flag: 'mh', intlMessage: countriesMessages.mh },
    { key: 'mq', value: 'mq', flag: 'mq', intlMessage: countriesMessages.mq },
    { key: 'mr', value: 'mr', flag: 'mr', intlMessage: countriesMessages.mr },
    { key: 'mu', value: 'mu', flag: 'mu', intlMessage: countriesMessages.mu },
    { key: 'yt', value: 'yt', flag: 'yt', intlMessage: countriesMessages.yt },
    { key: 'mx', value: 'mx', flag: 'mx', intlMessage: countriesMessages.mx },
    { key: 'fm', value: 'fm', flag: 'fm', intlMessage: countriesMessages.fm },
    { key: 'md', value: 'md', flag: 'md', intlMessage: countriesMessages.md },
    { key: 'mc', value: 'mc', flag: 'mc', intlMessage: countriesMessages.mc },
    { key: 'mn', value: 'mn', flag: 'mn', intlMessage: countriesMessages.mn },
    { key: 'me', value: 'me', flag: 'me', intlMessage: countriesMessages.me },
    { key: 'ms', value: 'ms', flag: 'ms', intlMessage: countriesMessages.ms },
    { key: 'ma', value: 'ma', flag: 'ma', intlMessage: countriesMessages.ma },
    { key: 'mz', value: 'mz', flag: 'mz', intlMessage: countriesMessages.mz },
    { key: 'na', value: 'na', flag: 'na', intlMessage: countriesMessages.na },
    { key: 'nr', value: 'nr', flag: 'nr', intlMessage: countriesMessages.nr },
    { key: 'np', value: 'np', flag: 'np', intlMessage: countriesMessages.np },
    { key: 'an', value: 'an', flag: 'an', intlMessage: countriesMessages.an },
    { key: 'nl', value: 'nl', flag: 'nl', intlMessage: countriesMessages.nl },
    { key: 'nc', value: 'nc', flag: 'nc', intlMessage: countriesMessages.nc },
    { key: 'pg', value: 'pg', flag: 'pg', intlMessage: countriesMessages.pg },
    { key: 'nz', value: 'nz', flag: 'nz', intlMessage: countriesMessages.nz },
    { key: 'ni', value: 'ni', flag: 'ni', intlMessage: countriesMessages.ni },
    { key: 'ne', value: 'ne', flag: 'ne', intlMessage: countriesMessages.ne },
    { key: 'ng', value: 'ng', flag: 'ng', intlMessage: countriesMessages.ng },
    { key: 'nu', value: 'nu', flag: 'nu', intlMessage: countriesMessages.nu },
    { key: 'nf', value: 'nf', flag: 'nf', intlMessage: countriesMessages.nf },
    { key: 'kp', value: 'kp', flag: 'kp', intlMessage: countriesMessages.kp },
    { key: 'mp', value: 'mp', flag: 'mp', intlMessage: countriesMessages.mp },
    { key: 'no', value: 'no', flag: 'no', intlMessage: countriesMessages.no },
    { key: 'om', value: 'om', flag: 'om', intlMessage: countriesMessages.om },
    { key: 'pk', value: 'pk', flag: 'pk', intlMessage: countriesMessages.pk },
    { key: 'pw', value: 'pw', flag: 'pw', intlMessage: countriesMessages.pw },
    { key: 'ps', value: 'ps', flag: 'ps', intlMessage: countriesMessages.ps },
    { key: 'pa', value: 'pa', flag: 'pa', intlMessage: countriesMessages.pa },
    { key: 'py', value: 'py', flag: 'py', intlMessage: countriesMessages.py },
    { key: 'pe', value: 'pe', flag: 'pe', intlMessage: countriesMessages.pe },
    { key: 'ph', value: 'ph', flag: 'ph', intlMessage: countriesMessages.ph },
    { key: 'pn', value: 'pn', flag: 'pn', intlMessage: countriesMessages.pn },
    { key: 'pl', value: 'pl', flag: 'pl', intlMessage: countriesMessages.pl },
    { key: 'pt', value: 'pt', flag: 'pt', intlMessage: countriesMessages.pt },
    { key: 'pr', value: 'pr', flag: 'pr', intlMessage: countriesMessages.pr },
    { key: 'qa', value: 'qa', flag: 'qa', intlMessage: countriesMessages.qa },
    { key: 're', value: 're', flag: 're', intlMessage: countriesMessages.re },
    { key: 'ro', value: 'ro', flag: 'ro', intlMessage: countriesMessages.ro },
    { key: 'ru', value: 'ru', flag: 'ru', intlMessage: countriesMessages.ru },
    { key: 'rw', value: 'rw', flag: 'rw', intlMessage: countriesMessages.rw },
    { key: 'sh', value: 'sh', flag: 'sh', intlMessage: countriesMessages.sh },
    { key: 'kn', value: 'kn', flag: 'kn', intlMessage: countriesMessages.kn },
    { key: 'lc', value: 'lc', flag: 'lc', intlMessage: countriesMessages.lc },
    { key: 'pm', value: 'pm', flag: 'pm', intlMessage: countriesMessages.pm },
    { key: 'vc', value: 'vc', flag: 'vc', intlMessage: countriesMessages.vc },
    { key: 'ws', value: 'ws', flag: 'ws', intlMessage: countriesMessages.ws },
    { key: 'sm', value: 'sm', flag: 'sm', intlMessage: countriesMessages.sm },
    { key: 'gs', value: 'gs', flag: 'gs', intlMessage: countriesMessages.gs },
    { key: 'st', value: 'st', flag: 'st', intlMessage: countriesMessages.st },
    { key: 'sa', value: 'sa', flag: 'sa', intlMessage: countriesMessages.sa },
    { key: 'sn', value: 'sn', flag: 'sn', intlMessage: countriesMessages.sn },
    { key: 'cs', value: 'cs', flag: 'cs', intlMessage: countriesMessages.cs },
    { key: 'rs', value: 'rs', flag: 'rs', intlMessage: countriesMessages.rs },
    { key: 'sc', value: 'sc', flag: 'sc', intlMessage: countriesMessages.sc },
    { key: 'sl', value: 'sl', flag: 'sl', intlMessage: countriesMessages.sl },
    { key: 'sg', value: 'sg', flag: 'sg', intlMessage: countriesMessages.sg },
    { key: 'sk', value: 'sk', flag: 'sk', intlMessage: countriesMessages.sk },
    { key: 'si', value: 'si', flag: 'si', intlMessage: countriesMessages.si },
    { key: 'sb', value: 'sb', flag: 'sb', intlMessage: countriesMessages.sb },
    { key: 'so', value: 'so', flag: 'so', intlMessage: countriesMessages.so },
    { key: 'za', value: 'za', flag: 'za', intlMessage: countriesMessages.za },
    { key: 'kr', value: 'kr', flag: 'kr', intlMessage: countriesMessages.kr },
    { key: 'es', value: 'es', flag: 'es', intlMessage: countriesMessages.es },
    { key: 'lk', value: 'lk', flag: 'lk', intlMessage: countriesMessages.lk },
    { key: 'sd', value: 'sd', flag: 'sd', intlMessage: countriesMessages.sd },
    { key: 'sr', value: 'sr', flag: 'sr', intlMessage: countriesMessages.sr },
    { key: 'sj', value: 'sj', flag: 'sj', intlMessage: countriesMessages.sj },
    { key: 'sz', value: 'sz', flag: 'sz', intlMessage: countriesMessages.sz },
    { key: 'se', value: 'se', flag: 'se', intlMessage: countriesMessages.se },
    { key: 'ch', value: 'ch', flag: 'ch', intlMessage: countriesMessages.ch },
    { key: 'sy', value: 'sy', flag: 'sy', intlMessage: countriesMessages.sy },
    { key: 'tw', value: 'tw', flag: 'tw', intlMessage: countriesMessages.tw },
    { key: 'tj', value: 'tj', flag: 'tj', intlMessage: countriesMessages.tj },
    { key: 'tz', value: 'tz', flag: 'tz', intlMessage: countriesMessages.tz },
    { key: 'th', value: 'th', flag: 'th', intlMessage: countriesMessages.th },
    { key: 'tl', value: 'tl', flag: 'tl', intlMessage: countriesMessages.tl },
    { key: 'tg', value: 'tg', flag: 'tg', intlMessage: countriesMessages.tg },
    { key: 'tk', value: 'tk', flag: 'tk', intlMessage: countriesMessages.tk },
    { key: 'to', value: 'to', flag: 'to', intlMessage: countriesMessages.to },
    { key: 'tt', value: 'tt', flag: 'tt', intlMessage: countriesMessages.tt },
    { key: 'tn', value: 'tn', flag: 'tn', intlMessage: countriesMessages.tn },
    { key: 'tr', value: 'tr', flag: 'tr', intlMessage: countriesMessages.tr },
    { key: 'tm', value: 'tm', flag: 'tm', intlMessage: countriesMessages.tm },
    { key: 'tv', value: 'tv', flag: 'tv', intlMessage: countriesMessages.tv },
    { key: 'ug', value: 'ug', flag: 'ug', intlMessage: countriesMessages.ug },
    { key: 'ua', value: 'ua', flag: 'ua', intlMessage: countriesMessages.ua },
    { key: 'ae', value: 'ae', flag: 'ae', intlMessage: countriesMessages.ae },
    { key: 'uy', value: 'uy', flag: 'uy', intlMessage: countriesMessages.uy },
    { key: 'um', value: 'um', flag: 'um', intlMessage: countriesMessages.um },
    { key: 'vi', value: 'vi', flag: 'vi', intlMessage: countriesMessages.vi },
    { key: 'uz', value: 'uz', flag: 'uz', intlMessage: countriesMessages.uz },
    { key: 'vu', value: 'vu', flag: 'vu', intlMessage: countriesMessages.vu },
    { key: 'va', value: 'va', flag: 'va', intlMessage: countriesMessages.va },
    { key: 've', value: 've', flag: 've', intlMessage: countriesMessages.ve },
    { key: 'vn', value: 'vn', flag: 'vn', intlMessage: countriesMessages.vn },
    { key: 'wf', value: 'wf', flag: 'wf', intlMessage: countriesMessages.wf },
    { key: 'eh', value: 'eh', flag: 'eh', intlMessage: countriesMessages.eh },
    { key: 'ye', value: 'ye', flag: 'ye', intlMessage: countriesMessages.ye },
    { key: 'zm', value: 'zm', flag: 'zm', intlMessage: countriesMessages.zm },
    { key: 'zw', value: 'zw', flag: 'zw', intlMessage: countriesMessages.zw },
];