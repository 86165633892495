import * as React from 'react';
import { Dropdown, Menu } from 'semantic-ui-react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { MobileNavigationLink } from './MobileNavigationLink';
import { MobileNavigationSection } from './MobileNavigationSection';
import { NavigationItem } from '.';

export interface MobileNavigationMenuProps {
    items: NavigationItem[];
}

class MobileNavigationMenu extends React.Component<MobileNavigationMenuProps & WrappedComponentProps, {}> {
    public render() {
        const menu = this.props.items.map((x, index) => this.renderMenuItem(x, index > 0));

        return (
            <Menu fixed="top" className="c-navigation-menu c-navigation-menu--mobile">
                <Dropdown item={true} icon="sidebar">
                    <Dropdown.Menu>
                        {menu}
                    </Dropdown.Menu>
                </Dropdown>

                <Menu.Menu position="right">
                    {this.props.children}
                </Menu.Menu>
            </Menu>
        );
    }

    private renderMenuItem = (item: NavigationItem, renderDivider?: boolean): React.ReactNode => {
        const { formatMessage } = this.props.intl;

        if (item.children && item.children.length > 0) {
            const renderedChildren = item.children.map(x => this.renderMenuItem(x, false));

            return (
                <React.Fragment key={item.key}>
                    {renderDivider && <Dropdown.Divider />}
                    <MobileNavigationSection
                        key={item.key}
                        title={formatMessage(item.title)}
                    >
                        {renderedChildren}
                    </MobileNavigationSection>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                {renderDivider && <Dropdown.Divider />}
                <MobileNavigationLink
                    key={item.key}
                    exact={item.exact}
                    to={item.to || ''}
                    icon={item.icon}
                    title={formatMessage(item.title)} 
                    labelColor={item.labelColor}
                    labelContent={item.labelContent}
                />
            </React.Fragment>
        );
    }
}

const connectedComponent = injectIntl(MobileNavigationMenu);
export { connectedComponent as MobileNavigationMenu };