import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

import { ApplicationState } from '../../../state/ducks';
import * as ServiceCallsActions from '../../../state/ducks/service-calls';
import * as UiActions from '../../../state/ducks/ui';
import * as UsersActions from '../../../state/ducks/users';
import { AssignAgentCard } from '../../../components/service-calls';
import { User, ServiceCall } from '../../../state/models';
import { ServiceCallState } from '../../../state/ducks/service-calls/reducers';
import { getServiceCallState } from '../../../state/ducks/service-calls';
import { getCurrentUserProfile } from '../../../state/ducks/current-user';

export type ServiceCallAssignStepProps =
    & ServiceCallAssignStepOwnProps
    & ServiceCallAssignStepActions
    & RouteComponentProps<{ serviceCallId: string }>;

type IntlServiceCallAssignStepProps = ServiceCallAssignStepProps & WrappedComponentProps;

interface ServiceCallAssignStepOwnProps {
    serviceCallState: ServiceCallState;
    currentUser: User | undefined;
    agents: User[];
    areAgentsLoading: boolean;
    currentAgentAssigned: User | undefined;
}

interface ServiceCallAssignStepActions {
    serviceCallsActions: typeof ServiceCallsActions.actionCreators;
    uiActions: typeof UiActions.actionCreators;
    usersActions: typeof UsersActions.actionCreators;
}

class ServiceCallAssignStep extends React.Component<IntlServiceCallAssignStepProps, {}> {
    public componentDidMount() {
        this.props.uiActions.changeAgentStep('assign');

        if (this.props.agents.length <= 0 && !this.props.areAgentsLoading) {
            this.props.usersActions.loadCustomerServiceAgents();
        }
    }

    public render() {
        return (
            <Grid columns={2} stackable={true}>
                <Grid.Column>
                    <AssignAgentCard
                        currentUser={this.props.currentUser}
                        agents={this.props.agents}
                        areAgentsLoading={this.props.areAgentsLoading}
                        currentAgentAssigned={this.props.currentAgentAssigned}
                        onAssignAgent={this.assignAgent}
                        isReadOnly={this.props.serviceCallState ? this.props.serviceCallState.isClosed : true}
                    />
                </Grid.Column>
            </Grid>
        );
    }

    private assignAgent = (agentEmail: string) => {
        const { serviceCallId } = this.props.match.params;
        this.props.serviceCallsActions.assignAgent(Number(serviceCallId), agentEmail);
        this.props.history.push(`/service-calls/${serviceCallId}/agent/inspect`);
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: ServiceCallAssignStepProps): ServiceCallAssignStepOwnProps => {
    const { serviceCallId } = ownProps.match.params;
    const serviceCallState = getServiceCallState(state, Number(serviceCallId));
    const currentAgentAssigned = serviceCallState && !serviceCallState.isLoading && serviceCallState.details ? serviceCallState.details.assignedTo : undefined;

    return {
        serviceCallState: serviceCallState,
        currentUser: getCurrentUserProfile(state),
        agents: state.users.customerServiceAgents,
        areAgentsLoading: state.users.areCustomerServiceAgentsLoading,
        currentAgentAssigned
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ServiceCallAssignStepActions => {
    return {
        serviceCallsActions: bindActionCreators(ServiceCallsActions.actionCreators, dispatch),
        uiActions: bindActionCreators(UiActions.actionCreators, dispatch),
        usersActions: bindActionCreators(UsersActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(ServiceCallAssignStep);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as ServiceCallAssignStep };