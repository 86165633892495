import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router";
import { actionCreators as AccountActions } from '../actions';

export const LogoutPage: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(AccountActions.logout())
    }, [dispatch]);

    return (
        <Redirect to="/account/login" />
    );
};