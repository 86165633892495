import * as React from 'react';

import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import { List } from 'semantic-ui-react';

import { DateHelper } from '../../../helpers';
import { ServiceCallFactoryRepair, ServiceCallInspection, SubcontractorRepair, TechnicianRepair } from '../../../state/models';
import { FactoryReportModal, InspectionReportModal, TechnicianReportModal } from '../reports';
import { SubcontractorReportModal } from '../reports/SubcontractorReportModal';
import { ServiceReportListItem } from './ServiceReportListItem';

interface ServiceReportAttachmentsOwnProps {
    inspections: ServiceCallInspection[];
    technicianRepairs: TechnicianRepair[];
    factoryRepairs: ServiceCallFactoryRepair[];
    subcontractorRepairs: SubcontractorRepair[];
}

interface ReportInfo {
    completedOn: Date;
    element: JSX.Element;
}

export type ServiceReportAttachmentsProps =
    & ServiceReportAttachmentsOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    completedOnFormat: { id: 'ServiceReportAttachments.completed_on_format', defaultMessage: 'Completed on {date}' },
    inspectionReportTitle: { id: 'ServiceReportAttachments.inspection_report_title', defaultMessage: 'Inspection report' },
    technicianReportTitle: { id: 'ServiceReportAttachments.technician_report_title', defaultMessage: 'Technician report' },
    factoryReportTitle: { id: 'ServiceReportAttachments.factory_report_title', defaultMessage: 'Factory report' },
    subcontractorReportTitle: { id: 'ServiceReportAttachments.subcontractor_report_title', defaultMessage: 'Sub-contractor report' }
});

class ServiceReportAttachments extends React.Component<ServiceReportAttachmentsProps, {}> {
    public render() {
        const inspectionReports = this.props.inspections.map((x): ReportInfo => ({ completedOn: x.completedOn || new Date(), element: this.renderInspectionReport(x) }));
        const technicianReports = this.props.technicianRepairs.map((x): ReportInfo => ({ completedOn: x.completedOn || new Date(), element: this.renderTechnicianReport(x) }));
        const factoryReports = this.props.factoryRepairs.map((x): ReportInfo => ({ completedOn: x.completedRepairsOn || new Date(), element: this.renderFactoryReport(x) }));
        const subcontractorReports = this.props.subcontractorRepairs.map((x): ReportInfo => ({ completedOn: x.reportDate ? new Date(x.reportDate) : new Date(), element: this.renderSubcontractorReport(x) }))
        const reports = [...inspectionReports, ...technicianReports, ...factoryReports, ...subcontractorReports].sort((a, b) => DateHelper.sortDescending(a.completedOn, b.completedOn));

        return (
            <List divided={true} relaxed={true} selection={true}>
                {reports.map(x => x.element)}
            </List>
        );
    }

    private renderSubcontractorReport = (report: SubcontractorRepair): JSX.Element => {
        const { formatMessage } = this.props.intl;
        const key = 'subcontractorrepair' + report.id;

        const trigger = (
            <ServiceReportListItem
                key={key}
                icon="id card outline"
                title={formatMessage(m.subcontractorReportTitle)}
                completedOn={report.reportDate}
            />
        );

        return (
            <SubcontractorReportModal
                key={key}
                trigger={trigger}
                subcontractorRepair={report}
            />
        );
    }

    private renderInspectionReport = (report: ServiceCallInspection): JSX.Element => {
        const { formatMessage } = this.props.intl;
        const key = 'inspection' + report.id;

        if (report.completedOn == null) {
            return <React.Fragment />;
        }

        const trigger = (
            <ServiceReportListItem
                key={key}
                icon="eye"
                title={formatMessage(m.inspectionReportTitle)}
                completedOn={report.completedOn}
            />
        );

        return (
            <InspectionReportModal
                key={key}
                trigger={trigger}
                inspectionReport={report}
            />
        );
    }

    private renderTechnicianReport = (repair: TechnicianRepair): JSX.Element => {
        const { formatMessage } = this.props.intl;
        const key = 'technician' + repair.id;

        if (repair.completedOn == null) {
            return <React.Fragment />;
        }

        const trigger = (
            <ServiceReportListItem
                key={key}
                icon="wrench"
                title={formatMessage(m.technicianReportTitle)}
                completedOn={repair.completedOn}
            />
        );

        return (
            <TechnicianReportModal
                key={key}
                trigger={trigger}
                technicianRepair={repair}
            />
        );
    }

    private renderFactoryReport = (repair: ServiceCallFactoryRepair): JSX.Element => {
        const { formatMessage } = this.props.intl;
        const key = 'factory' + repair.id;

        if (repair.completedRepairsOn == null) {
            return <React.Fragment />;
        }

        const trigger = (
            <ServiceReportListItem
                key={key}
                icon="factory"
                title={formatMessage(m.factoryReportTitle)}
                completedOn={repair.completedRepairsOn}
            />
        );

        return (
            <FactoryReportModal
                key={key}
                trigger={trigger}
                factoryRepair={repair}
            />
        );
    }
}

const connectedComponent = injectIntl(ServiceReportAttachments);
export { connectedComponent as ServiceReportAttachments };