import BaseService from "./baseService";

export class QualityControlImageService extends BaseService {
  constructor() {
    super("api/quality-control-images");
  }

  getNamesByProductionItemId(productionItemId: number): Promise<any> {
    return this.get(`/by-production-item/${productionItemId}`);
  }
}
