import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { List, Label, Icon } from 'semantic-ui-react';
import { Item, getItemLocalizedDescription } from '../../state/models';
import { HasItemDefectLabel } from '../service-calls';
import { StringHelper } from '../../state/utils';

export interface ItemDetailsListItemProps {
    item: Item | undefined;
    itemCover?: Item;
    itemQuantity?: number;
    hasDefect?: boolean;
    defectiveItemQuantity?: number;
}

const m = defineMessages({
    coverDescriptionFormat: { id: 'ItemDetailsListItem.cover_description_format', defaultMessage: 'Cover: {description}' },
    quantityOrderedFormat: { id: 'ItemDetailsListItem.quantity_ordered_format', defaultMessage: 'Quantity: {quantity}' },
});

class ItemDetailsListItem extends React.Component<ItemDetailsListItemProps & WrappedComponentProps, {}> {
    public render() {
        const { item, itemCover, itemQuantity } = this.props;
        const { formatMessage, locale } = this.props.intl;
        const hasDefect = this.props.hasDefect || false;

        return (
            <List.Item>
                {item != null &&
                    <React.Fragment>
                        {this.renderItemIdentifier(item)}
                        {getItemLocalizedDescription(item, locale)}
                    </React.Fragment>
                }
                {hasDefect && <HasItemDefectLabel itemsQuantity={this.props.defectiveItemQuantity} style={{ margin: '0 0 0 3px' }} />}
                {/* <Icon name="star" circular={true} inverted={true} size="small" color="yellow" /> */}
                {itemCover != null &&
                    <small className="meta" style={{ display: 'block' }}>
                        {formatMessage(m.coverDescriptionFormat, { description: getItemLocalizedDescription(itemCover, locale) })}
                    </small>}
                {itemQuantity && itemQuantity > 1 &&
                    <span className="meta" style={{ display: 'block' }}>
                        {formatMessage(m.quantityOrderedFormat, { quantity: itemQuantity })}
                    </span>}
            </List.Item>
        );
    }

    private renderItemIdentifier(item: Item) {
        const hasModelAndType = item.productModel
            && StringHelper.hasValue(item.productModel.id)
            && item.productType
            && StringHelper.hasValue(item.productType.id);

        const itemIdentifier = hasModelAndType
            ? item.productModel!.id + '-' + item.productType!.id
            : item.id;

        return hasModelAndType
            ? <strong style={{ display: 'inline-block', marginRight: 5 }}>{itemIdentifier}</strong>
            : (null);
    }
}

const connectedComponent = injectIntl(ItemDetailsListItem);
export { connectedComponent as ItemDetailsListItem };