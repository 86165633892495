import { User } from '../../../state/models';
import * as api from './api';
import { UserEdit } from './models';
import { AppThunk } from '../../../state/store';
import { UsersFilters } from './types';
import { ClientEdit } from './models/ClientEdit';

export const LOAD_ALL_REQUEST = 'USERS_LOAD_ALL_REQUEST';
export const LOAD_ALL_SUCCESS = 'USERS_LOAD_ALL_SUCCESS';
export const LOAD_ALL_FAILURE = 'USERS_LOAD_ALL_FAILURE';

export const LOAD_BY_ID_REQUEST = 'USERS_LOAD_BY_ID_REQUEST';
export const LOAD_BY_ID_SUCCESS = 'USERS_LOAD_BY_ID_SUCCESS';
export const LOAD_BY_ID_FAILURE = 'USERS_LOAD_BY_ID_FAILURE';

export const CREATE_EMPLOYEE_REQUEST = 'USERS_CREATE_EMPLOYEE_REQUEST';
export const CREATE_EMPLOYEE_SUCCESS = 'USERS_CREATE_EMPLOYEE_SUCCESS';
export const CREATE_EMPLOYEE_FAILURE = 'USERS_CREATE_EMPLOYEE_FAILURE';

export const CREATE_CLIENT_REQUEST = 'USERS_CREATE_CLIENT_REQUEST';
export const CREATE_CLIENT_SUCCESS = 'USERS_CREATE_CLIENT_SUCCESS';
export const CREATE_CLIENT_FAILURE = 'USERS_CREATE_CLIENT_FAILURE';

export const UPDATE_REQUEST = 'USERS_UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'USERS_UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'USERS_UPDATE_FAILURE';

export const DISABLE_USER_REQUEST = 'USERS_DISABLE_REQUEST';
export const DISABLE_USER_SUCCESS = 'USERS_DISABLE_SUCCESS';
export const DISABLE_USER_FAILURE = 'USERS_DISABLE_FAILURE';

export const ENABLE_USER_REQUEST = 'USERS_ENABLE_REQUEST';
export const ENABLE_USER_SUCCESS = 'USERS_ENABLE_SUCCESS';
export const ENABLE_USER_FAILURE = 'USERS_ENABLE_FAILURE';

export const SET_FILTERS = 'USERS_SET_FILTERS';

export interface LoadAllRequestAction { type: typeof LOAD_ALL_REQUEST; payload: {}; }
export interface LoadAllSuccessAction { type: typeof LOAD_ALL_SUCCESS; payload: { users: User[] }; }
export interface LoadAllFailureAction { type: typeof LOAD_ALL_FAILURE; payload: { error: Error }; }

export interface LoadByIdRequestAction { type: typeof LOAD_BY_ID_REQUEST; payload: { userId: string }; }
export interface LoadByIdSuccessAction { type: typeof LOAD_BY_ID_SUCCESS; payload: { user: User }; }
export interface LoadByIdFailureAction { type: typeof LOAD_BY_ID_FAILURE; payload: { userId: string, error: Error }; }

export interface CreateEmployeeRequestAction { type: typeof CREATE_EMPLOYEE_REQUEST; payload: { user: UserEdit }; }
export interface CreateEmployeeSuccessAction { type: typeof CREATE_EMPLOYEE_SUCCESS; payload: { user: User }; }
export interface CreateEmployeeFailureAction { type: typeof CREATE_EMPLOYEE_FAILURE; payload: { error: Error }; }

export interface CreateClientRequestAction { type: typeof CREATE_CLIENT_REQUEST; payload: { user: ClientEdit }; }
export interface CreateClientSuccessAction { type: typeof CREATE_CLIENT_SUCCESS; payload: { user: User }; }
export interface CreateClientFailureAction { type: typeof CREATE_CLIENT_FAILURE; payload: { error: Error }; }

export interface UpdateRequestAction { type: typeof UPDATE_REQUEST; payload: { user: UserEdit }; }
export interface UpdateSuccessAction { type: typeof UPDATE_SUCCESS; payload: { user: User }; }
export interface UpdateFailureAction { type: typeof UPDATE_FAILURE; payload: { user: User, error: Error }; }

export interface DisableUserRequestAction { type: typeof DISABLE_USER_REQUEST; payload: { userId: string }; }
export interface DisableUserSuccessAction { type: typeof DISABLE_USER_SUCCESS; payload: { user: User }; }
export interface DisableUserFailureAction { type: typeof DISABLE_USER_FAILURE; payload: { userId: string, error: Error }; }

export interface EnableUserRequestAction { type: typeof ENABLE_USER_REQUEST; payload: { userId: string }; }
export interface EnableUserSuccessAction { type: typeof ENABLE_USER_SUCCESS; payload: { user: User }; }
export interface EnableUserFailureAction { type: typeof ENABLE_USER_FAILURE; payload: { userId: string, error: Error }; }

export interface ChangeFiltersAction { type: typeof SET_FILTERS; payload: { filters: UsersFilters }; }

export type UsersActionTypes =
    | LoadAllRequestAction | LoadAllSuccessAction | LoadAllFailureAction
    | LoadByIdRequestAction | LoadByIdSuccessAction | LoadByIdFailureAction
    | CreateEmployeeRequestAction | CreateEmployeeSuccessAction | CreateEmployeeFailureAction
    | CreateClientRequestAction | CreateClientSuccessAction | CreateClientFailureAction
    | UpdateRequestAction | UpdateSuccessAction | UpdateFailureAction
    | DisableUserRequestAction | DisableUserSuccessAction | DisableUserFailureAction
    | EnableUserRequestAction | EnableUserSuccessAction | EnableUserFailureAction
    | ChangeFiltersAction
    | { type: '' };

export const actionCreators = {
    loadAll: (): AppThunk => {
        return (dispatch) => {
            dispatch({ type: LOAD_ALL_REQUEST });

            api.loadAll()
                .then(users => dispatch({ type: LOAD_ALL_SUCCESS, payload: { users } }))
                .catch(error => dispatch({ type: LOAD_ALL_FAILURE, payload: { error } }));
        };
    },

    loadById: (userId: string): AppThunk => {
        return (dispatch) => {
            dispatch({ type: LOAD_BY_ID_REQUEST, payload: { userId } });

            api.loadById(userId)
                .then(user => dispatch({ type: LOAD_BY_ID_SUCCESS, payload: { user }}))
                .catch(error => dispatch({ type: LOAD_BY_ID_FAILURE, payload: { userId, error }}));
        };
    },

    createEmployee: (user: UserEdit): AppThunk => {
        return (dispatch) => {
            dispatch({ type: CREATE_EMPLOYEE_REQUEST, payload: { user } });

            api.createEmployee(user)
                .then(result => dispatch({ type: CREATE_EMPLOYEE_SUCCESS, payload: { user: result } }))
                .catch(error => dispatch({ type: CREATE_EMPLOYEE_FAILURE, payload: { error } }));
        };
    },

    createClientAccount: (user: ClientEdit): AppThunk => {
        return (dispatch) => {
            dispatch({ type: CREATE_CLIENT_REQUEST, payload: { user } });

            api.createClientAccount(user)
                .then(result => dispatch({ type: CREATE_CLIENT_SUCCESS, payload: { user: result }}))
                .catch(error => dispatch({ type: CREATE_CLIENT_FAILURE, payload: { error }}));
        }

    },

    update: (user: UserEdit): AppThunk => {
        return (dispatch) => {
            dispatch({ type: UPDATE_REQUEST, payload: { user } });

            api.updateUser(user)
                .then(result => dispatch({ type: UPDATE_SUCCESS, payload: { user: result } }))
                .catch(error => dispatch({ type: UPDATE_FAILURE, payload: { user, error } }));
        };
    },

    updateClientAccount: (client: ClientEdit): AppThunk => {
        return (dispatch) => {
            dispatch({ type: UPDATE_REQUEST, payload: { user: client } });

            api.updateClientAccount(client)
                .then(result => dispatch({ type: UPDATE_SUCCESS, payload: { user: result } }))
                .catch(error => dispatch({ type: UPDATE_FAILURE, payload: { user: client, error } }));
        }
    },

    disable: (userId: string): AppThunk => {
        return (dispatch) => {
            dispatch({ type: DISABLE_USER_REQUEST, payload: { userId }});

            api.disableUser(userId)
                .then(user => dispatch({ type: DISABLE_USER_SUCCESS, payload: { user }}))
                .catch(error => dispatch({ type: DISABLE_USER_FAILURE, payload: { userId, error }}));
        }
    },

    enable: (userId: string): AppThunk => {
        return (dispatch) => {
            dispatch({ type: ENABLE_USER_REQUEST, payload: { userId }});

            api.enableUser(userId)
                .then(user => dispatch({ type: ENABLE_USER_SUCCESS, payload: { user }}))
                .catch(error => dispatch({ type: ENABLE_USER_FAILURE, payload: { userId, error }}));
        }
    },

    setFilters: (filters: UsersFilters) => {
        return { type: SET_FILTERS, payload: { filters } };
    }
};
