import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedMessage } from 'react-intl';
import { ServiceCall, User, TechnicianRepair } from '../../state/models';
import { List } from 'semantic-ui-react';
import { UserHelper } from '../../helpers';
import { StringHelper, FormatHelper } from '../../state/utils';
import { countriesMessages } from '../../constants';

interface AppointmentQuickOverviewOwnProps {
    appointment: TechnicianRepair;
    onServiceCallClicked: (appointment: TechnicianRepair) => void;
}

export type AppointmentQuickOverviewProps =
    & AppointmentQuickOverviewOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'AppointmentQuickOverview.title', defaultMessage: 'Service call #{serviceCallLink}' }
});

class AppointmentQuickOverview extends React.Component<AppointmentQuickOverviewProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { serviceCall, technician } = this.props.appointment;
        const customer = serviceCall ? serviceCall.customer : undefined;
        const serviceCallLink = {'serviceCallLink': (<a style={{ cursor: 'pointer' }} onClick={() => this.props.onServiceCallClicked(this.props.appointment)}>{serviceCall.id}</a>)};

        return (
            <div>
                <h3><FormattedMessage {...m.title} values={serviceCallLink} /></h3>
                <List>
                    {technician && 
                        <List.Item icon="wrench" content={UserHelper.getDisplayName(technician)} />
                    }
                    {customer && StringHelper.hasValue(customer.name) && 
                        <List.Item icon="user" content={customer.name} />
                    }
                    {customer && StringHelper.hasValue(customer.mainPhoneNumber) &&
                        <List.Item icon="phone" content={customer.mainPhoneNumber} />
                    }
                    {customer && StringHelper.hasValue(customer.otherPhoneNumber) &&
                        <List.Item icon="phone" content={customer.otherPhoneNumber} />
                    }
                    {customer && customer.address && 
                        <List.Item>
                            <List.Icon name="marker" />
                            <List.Content>
                                {FormatHelper.formatAddress(customer.address?.address1 || '')}
                                <span style={{display: 'block'}}>{customer.address?.address2 || ''}</span>
                                <span style={{display: 'block'}}>
                                    {customer.address?.city || ''} {customer.address?.provinceCode && <span>({customer.address.provinceCode.trim().toUpperCase()})</span>}</span>
                                <span style={{display: 'block'}}>{customer.address?.postalCode && customer.address.postalCode.toUpperCase()}</span>
                                <span style={{display: 'block'}}>{customer.address?.countryCode && formatMessage(countriesMessages[customer.address.countryCode])}</span>
                            </List.Content>
                        </List.Item>
                    }
                </List>
            </div>
        );
    }
}

const connectedComponent = injectIntl(AppointmentQuickOverview);
export { connectedComponent as AppointmentQuickOverview };