import { defineMessages } from "@formatjs/intl";
import React from "react";
import { useIntl } from "react-intl";
import { Table } from "semantic-ui-react";
import { Defect } from "../../../../../state/models";
import { DefectsTableRow } from "./DefectsTableRow";

interface OwnProps {
    defects: Defect[];
}

export type DefectsTableProps =
    & OwnProps;

const m = defineMessages({
    idHeader: { id: 'DefectsTable.idHeader', defaultMessage: 'ID' },
    categoryHeader: { id: 'DefectsTable.categoryHeader', defaultMessage: 'Category' },
    typeHeader: { id: 'DefectsTable.typeHeader', defaultMessage: 'Type' },
    causeHeader: { id: 'DefectsTable.causeHeader', defaultMessage: 'Cause' },
});

export const DefectsTable: React.FC<DefectsTableProps> = (props) => {
    const { formatMessage } = useIntl();

    return (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{formatMessage(m.idHeader)}</Table.HeaderCell>
                    <Table.HeaderCell>{formatMessage(m.categoryHeader)}</Table.HeaderCell>
                    <Table.HeaderCell>{formatMessage(m.typeHeader)}</Table.HeaderCell>
                    <Table.HeaderCell>{formatMessage(m.causeHeader)}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {props.defects.map(defect => <DefectsTableRow key={defect.id} defect={defect} />)}
            </Table.Body>
        </Table>
    );
};