import { UserAccountType, User } from '../state/models';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { userRoleMessages } from '../constants';
import { SemanticICONS } from 'semantic-ui-react';

export class UserHelper {
    private static readonly userAccountTypeDescription: Map<UserAccountType, MessageDescriptor> = new Map<UserAccountType, MessageDescriptor>([
        [UserAccountType.customerServiceAgent, userRoleMessages.customerServiceAgent],
        [UserAccountType.client, userRoleMessages.client],
        [UserAccountType.administrator, userRoleMessages.administrator],
        [UserAccountType.jaymarRepresentative, userRoleMessages.jaymarRepresentative],
        [UserAccountType.jaymarTechnician, userRoleMessages.jaymarTechnician],
        [UserAccountType.factoryTechnician, userRoleMessages.factoryTechnician]
    ]);

    private static readonly userIconsMap: Map<UserAccountType, SemanticICONS> = new Map<UserAccountType, SemanticICONS>([
        [UserAccountType.customerServiceAgent, 'user'],
        [UserAccountType.client, 'shop'],
        [UserAccountType.administrator, 'protect'],
        [UserAccountType.jaymarRepresentative, 'ambulance'],
        [UserAccountType.jaymarTechnician, 'wrench'],
        [UserAccountType.factoryTechnician, 'factory']
    ]);

    public static getRoleDescription(accountType: UserAccountType): MessageDescriptor {
        return this.userAccountTypeDescription.get(accountType)
            || userRoleMessages.unknown;
    }

    public static getDisplayName(user?: User): string {
        if (user) {
            return user.firstName + ' ' + user.lastName;
        }

        return '';
    }

    public static getIcon(accountType: UserAccountType): SemanticICONS {
        return this.userIconsMap.get(accountType)
            || 'question';
    }

    public static isEmployee(user: User | undefined) {
        if (user == null) {
            return false;
        }

        return user.accountType !== UserAccountType.client && user.accountType !== UserAccountType.none;
    }
}