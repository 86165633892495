import { User } from '.';

export enum NoteVisibility {
    internal = 0,
    public = 1,
}

export interface ServiceCallNote {
    id: number;
    serviceCallId: number;
    createdOn: Date;
    createdById: string;
    createdBy: User;
    content: string;
    visibility: NoteVisibility;
}