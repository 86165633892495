import { NavigationItem } from "../../components/layouts/PageLayout/NavigationMenu";
import { afterSalesMenuSection, createServiceCallMenuItem, currentServiceCallsMenuItem, dashboardMenuItem, serviceCallsHistoryMenuItem } from "./common";

export const clientNavigationItems = (): NavigationItem[] => [
    dashboardMenuItem(),
    {
        ...afterSalesMenuSection(),
        children: [
            createServiceCallMenuItem(),
            currentServiceCallsMenuItem(),
            serviceCallsHistoryMenuItem(),
        ]
    },
];