import moment from 'moment';
import React from 'react';

import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import { Icon, List, ListItemProps, SemanticICONS } from 'semantic-ui-react';

interface ServiceReportListItemOwnProps extends ListItemProps {
    icon: SemanticICONS;
    title: string;
    completedOn: Date;
}

export type ServiceReportListItemProps =
    & ServiceReportListItemOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    completedOnFormat: { id: 'ServiceReportListItem.completed_on_format', defaultMessage: 'Completed on {date}' }
});

class ServiceReportListItem extends React.Component<ServiceReportListItemProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { icon, title, completedOn, intl, ...listItemProps } = this.props;

        return (
            <List.Item {...listItemProps}>
                <List.Content floated="left">
                    <Icon name={this.props.icon} size="large" color="grey" />
                </List.Content>
                <List.Content verticalAlign="middle">
                    <List.Header style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                        {this.props.title}
                    </List.Header>
                    {this.props.completedOn && (
                        <List.Description>
                            {formatMessage(m.completedOnFormat, { date: moment.utc(this.props.completedOn).format('LL') })}
                        </List.Description>
                    )}
                </List.Content>
            </List.Item>
        );
    }
}

const connectedComponent = injectIntl(ServiceReportListItem);
export { connectedComponent as ServiceReportListItem };