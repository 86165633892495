import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Button, Grid, Segment } from "semantic-ui-react";
import { ApplicationState } from "../../../state/ducks";
import { PageHeader, SectionHeader } from "../../../components/common";
import { Bar } from "react-chartjs-2";
import { ChartData, ChartOptions } from "chart.js";
import * as ServiceCallsActions from "../../../state/ducks/service-calls";
import { FilterOptions, ServiceCallStatus } from "../../../state/models";
import { StatisticTile } from "../../../components/reporting";
import { getCurrentUser } from "../../../modules/account/selectors";
import * as ReportingActions from "../../../state/ducks/reporting";

const m = defineMessages({
  title: { id: "AgentDashboardPage.title", defaultMessage: "Dashboard" },
  subtitle: {
    id: "AgentDashboardPage.subtitle",
    defaultMessage:
      "See a quick overview of the service calls and the actions available to close them.",
  },
  quickOverviewTitle: {
    id: "AgentDashboardPage.quick_overview_title",
    defaultMessage: "Quick overview",
  },
  actionsToBeTaken: {
    id: "AgentDashboardPage.actions_to_be_taken",
    defaultMessage: "Actions to take",
  },
  agentsWorkload: {
    id: "AgentDashboardPage.agents_workload",
    defaultMessage: "Agents workload",
  },
  newCallsLabel: {
    id: "AgentDashboardPage.new_calls_label",
    defaultMessage: "{count, plural, one {New service call} other {New service calls}}",
  },
  toAssignLabel: { id: "AgentDashboardPage.to_assign_label", defaultMessage: "To assign" },
  overdueCallCountLabel: {
    id: "AgentDashboardPage.overdue_call_count_label",
    defaultMessage: "Overdue service calls",
  },
  overdueCallCountTooltip: {
      id: "AgentDashboardPage.overdue_call_count_tooltip",
      defaultMessage: "The number of service calls open for 30 business days or more",
  },
  stalledUnassignedCallCountLabel: { id: "AgentDashboardPage.stalled_unassigned_call_count_label", defaultMessage: "Stalled unassigned calls" },
  stalledUnassignedCallCountTooltip: {
    id: "AgentDashboardPage.stalled_unassigned_call_count_tooltip",
    defaultMessage: "The number of calls unassigned since 48 hours or more",
},
  toInspectLabel: { id: "AgentDashboardPage.to_inspect_label", defaultMessage: "To inspect" },
  toApproveLabel: {
    id: "AgentDashboardPage.to_approve_label",
    defaultMessage: "To approve repair fees",
  },
  toRepairLabel: { id: "AgentDashboardPage.to_repair_label", defaultMessage: "To repair" },
  toCloseLabel: { id: "AgentDashboardPage.to_close_label", defaultMessage: "To close" },
  createServiceCall: {
    id: "AgentDashboardPage.create_service_call",
    defaultMessage: "Create Service Call",
  },
  pendingCalls: { id: "AgentDashboardPage.pending_calls", defaultMessage: "Pending calls" },
});

export const AgentDashboardPage = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const overdueCallCount = useSelector(
    (state: ApplicationState) => state.reporting.overdueCallCount
);

  const stalledUnassignedCallCount = useSelector(
    (state: ApplicationState) => state.reporting.stalledUnassignedCallCount
  );

  const isOverdueCallCountLoading = useSelector(
    (state: ApplicationState) => state.reporting.isOverdueCallCountLoading
  );

  const isStalledUnassignedCallCountLoading = useSelector(
    (state: ApplicationState) => state.reporting.isStalledUnassignedCallCountLoading
  );

  const stats = useSelector((state: ApplicationState) => state.serviceCalls.statistics);

  const customerServiceAgents = useSelector(
    (state: ApplicationState) => state.users.customerServiceAgents
  );

  const currentAgent = useSelector(getCurrentUser);

  useEffect(() => {
    dispatch(ReportingActions.actionCreators.loadOverdueCallCount());
    dispatch(ReportingActions.actionCreators.loadStalledUnassignedCallCount());
    dispatch(ServiceCallsActions.actionCreators.loadAgentsWorkload());
    dispatch(ServiceCallsActions.actionCreators.loadAgentNextActions());
  }, [dispatch]);

  const chartData: ChartData = {
    datasets: [
      {
        label: "# d'appels par statut",
        data: [65, 59, 38, 12, 45, 0],
        backgroundColor: "rgba(0, 181, 173, 0.6)",
        borderColor: "rgba(0, 181, 173, 1)",
        borderWidth: 1,
      },
    ],
    labels: ["Nouveau", "Ouvert", "Inspection", "Inspecté", "Réparation", "Réparé"],
  };

  const chartOptions: ChartOptions = {
    scales: {
      xAxes: [
        {
          ticks: { autoSkip: false },
        },
      ],

      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const renderAgentsWorkload = () => {
    var agentsWorkload = stats.agentsWorkload.map((x) => {
      const agentName = x.agent.firstName + " " + x.agent.lastName;

      return (
        <Grid.Column
          key={x.agent.id}
          mobile={8}
          tablet={4}
          computer={4}
          largeScreen={3}
          widescreen={2}>
          <StatisticTile
            isLoading={stats.isAgentsWorkloadLoading}
            onClick={() => handleWorkloadClick(x.agent.email)}
            value={x.callsAssigned}
            label={agentName}
            color='teal'
          />
        </Grid.Column>
      );
    });

    return agentsWorkload;
  };

  const handleNewCallsClick = () => {
    const filters: FilterOptions = {
      selectedServiceCallStatusFilter: [ServiceCallStatus.created],
    };

    dispatch(ServiceCallsActions.actionCreators.applyFiltersOnCustomView(filters));
    history.push("/service-calls/custom");
  };

  const handleToAssignCallsClick = () => {
    const filters: FilterOptions = {
      selectedServiceCallStatusFilter: [ServiceCallStatus.created],
    };

    dispatch(ServiceCallsActions.actionCreators.applyFiltersOnCustomView(filters));
    history.push("/service-calls/custom");
  };

  const handleToInspectCallsClick = () => {
    const filters: FilterOptions = {
      selectedServiceCallStatusFilter: [ServiceCallStatus.assignedToAgent],
      selectedAgentsFilter: currentAgent ? [currentAgent] : [],
    };

    dispatch(ServiceCallsActions.actionCreators.applyFiltersOnCustomView(filters));
    history.push("/service-calls/custom");
  };

  const handleToApproveCallsClick = () => {
    const filters: FilterOptions = {
      selectedServiceCallStatusFilter: [ServiceCallStatus.inspected],
      selectedAgentsFilter: currentAgent ? [currentAgent] : [],
    };

    dispatch(ServiceCallsActions.actionCreators.applyFiltersOnCustomView(filters));
    history.push("/service-calls/custom");
  };

  const handleToRepairCallsClick = () => {
    const filters: FilterOptions = {
      selectedServiceCallStatusFilter: [
        ServiceCallStatus.awaitingFactoryRepairs,
        ServiceCallStatus.awaitingSubcontractorRepairs,
        ServiceCallStatus.awaitingTechnicianRepairs,
      ],
      selectedAgentsFilter: currentAgent ? [currentAgent] : [],
    };

    dispatch(ServiceCallsActions.actionCreators.applyFiltersOnCustomView(filters));
    history.push("/service-calls/custom");
  };

  const handleToCloseCallsClick = () => {
    const filters: FilterOptions = {
      selectedServiceCallStatusFilter: [ServiceCallStatus.repaired],
      selectedAgentsFilter: currentAgent ? [currentAgent] : [],
    };

    dispatch(ServiceCallsActions.actionCreators.applyFiltersOnCustomView(filters));
    history.push("/service-calls/custom");
  };

  const handleCreateServiceCall = () => {
    history.push("/client/service-calls/create");
  };

  const handleWorkloadClick = (agentEmail: string) => {
    const selectedAgent = customerServiceAgents.find((x) => x.email === agentEmail);

    const filters: FilterOptions = {
      selectedAgentsFilter: selectedAgent ? [selectedAgent] : [],
    };

    dispatch(ServiceCallsActions.actionCreators.applyFiltersOnCustomView(filters));
    history.push("/service-calls/custom");
  };

  const navigateToStalledUnassignedCallsPage = () => {
    history.push("service-calls/stalled-unassigned");
  };

  const navigateToOverdueCallsPage = () => {
    history.push(`/service-calls/overdue`);
  }

  return (
    <div>
      <PageHeader
        iconName='home'
        title={formatMessage(m.title)}
        subtitle={formatMessage(m.subtitle)}
      />

      <SectionHeader title={formatMessage(m.quickOverviewTitle)} />

      <Grid>
        <Grid.Column mobile={8} tablet={4} computer={4} largeScreen={3} widescreen={2}>
          <StatisticTile
            isLoading={stats.isNewLoading}
            value={stats.new}
            onClick={handleNewCallsClick}
            label={formatMessage(m.newCallsLabel, { count: stats.new })}
            color='teal'
          />
        </Grid.Column>

        <Grid.Column mobile={8} tablet={4} computer={4} largeScreen={3} widescreen={2}>
          <StatisticTile
            isLoading={isStalledUnassignedCallCountLoading}
            value={stalledUnassignedCallCount}
            label={formatMessage(m.stalledUnassignedCallCountLabel)}
            labelTooltip={formatMessage(m.stalledUnassignedCallCountTooltip)}
            color={stalledUnassignedCallCount ? "red" : "teal"}
            onClick={navigateToStalledUnassignedCallsPage}
          />
        </Grid.Column>

        <Grid.Column mobile={8} tablet={8} computer={5} largeScreen={4} widescreen={3}>
            <StatisticTile
                isLoading={isOverdueCallCountLoading}
                value={overdueCallCount}
                label={formatMessage(m.overdueCallCountLabel)}
                labelTooltip={formatMessage(m.overdueCallCountTooltip)}
                color={overdueCallCount ? "red" : "teal"}
                onClick={navigateToOverdueCallsPage}
            />
        </Grid.Column>

        <Grid.Column mobile={8} tablet={4} computer={4} largeScreen={3} widescreen={2}>
          <Button
            fluid={true}
            primary={true}
            content={formatMessage(m.createServiceCall)}
            onClick={handleCreateServiceCall}
            icon='plus'
            style={{ height: "130px" }}
          />
        </Grid.Column>
      </Grid>

      <Segment color='teal'>
        <Bar data={chartData} options={chartOptions} height={110} />
      </Segment>

      <SectionHeader title={formatMessage(m.actionsToBeTaken)} />

      <Grid>
        <Grid.Column mobile={8} tablet={4} computer={4} largeScreen={3} widescreen={2}>
          <StatisticTile
            isLoading={stats.areActionsLoading}
            value={stats.actions.toAssign}
            label={formatMessage(m.toAssignLabel)}
            color='green'
            onClick={handleToAssignCallsClick}
          />
        </Grid.Column>

        <Grid.Column mobile={8} tablet={4} computer={4} largeScreen={3} widescreen={2}>
          <StatisticTile
            isLoading={stats.areActionsLoading}
            value={stats.actions.toInspect}
            label={formatMessage(m.toInspectLabel)}
            color='orange'
            onClick={handleToInspectCallsClick}
          />
        </Grid.Column>

        <Grid.Column mobile={8} tablet={4} computer={4} largeScreen={3} widescreen={2}>
          <StatisticTile
            isLoading={stats.areActionsLoading}
            value={stats.actions.toApprove}
            label={formatMessage(m.toApproveLabel)}
            color='yellow'
            onClick={handleToApproveCallsClick}
          />
        </Grid.Column>

        <Grid.Column mobile={8} tablet={4} computer={4} largeScreen={3} widescreen={2}>
          <StatisticTile
            isLoading={stats.areActionsLoading}
            value={stats.actions.toRepair}
            label={formatMessage(m.toRepairLabel)}
            color='blue'
            onClick={handleToRepairCallsClick}
          />
        </Grid.Column>

        <Grid.Column mobile={8} tablet={4} computer={4} largeScreen={3} widescreen={2}>
          <StatisticTile
            isLoading={stats.areActionsLoading}
            value={stats.actions.toClose}
            label={formatMessage(m.toCloseLabel)}
            color='teal'
            onClick={handleToCloseCallsClick}
          />
        </Grid.Column>
      </Grid>

      <SectionHeader title={formatMessage(m.pendingCalls)} />
      <Grid>
        <Grid.Column mobile={8} tablet={4} computer={4} largeScreen={3} widescreen={2}>
          <StatisticTile
            isLoading={stats.areActionsLoading}
            value={stats.actions.awaitingAppointment || 0}
            label='Prise de rendez-vous'
            color='orange'
          />
        </Grid.Column>

        <Grid.Column mobile={8} tablet={4} computer={4} largeScreen={3} widescreen={2}>
          <StatisticTile
            isLoading={stats.areActionsLoading}
            value={stats.actions.awaitingFeesApproval || 0}
            label='Approbation des frais'
            color='yellow'
          />
        </Grid.Column>

        <Grid.Column mobile={8} tablet={4} computer={4} largeScreen={3} widescreen={2}>
          <StatisticTile
            isLoading={stats.areActionsLoading}
            value={stats.actions.awaitingFurniture || 0}
            label='Réception du meuble'
            color='blue'
          />
        </Grid.Column>

        <Grid.Column mobile={8} tablet={4} computer={4} largeScreen={3} widescreen={2}>
          <StatisticTile
            isLoading={stats.areActionsLoading}
            value={stats.actions.awaitingRepairs || 0}
            label='En réparation'
            color='blue'
          />
        </Grid.Column>
      </Grid>

      <SectionHeader title={formatMessage(m.agentsWorkload)} />
      <Grid>{renderAgentsWorkload()}</Grid>
    </div>
  );
};
