import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Table, Label, SemanticCOLORS, Popup } from 'semantic-ui-react';
import { DateHelper } from '../../../helpers';
import { TechnicianRepair, AppointmentTimeRange } from '../../../state/models';
import { AppointmentQuickOverview } from '../../quick-overviews';

interface AppointmentWeekOwnProps {
    weekNumber: number;
    year: number;
    appointments: TechnicianRepair[];
    canSelectDate?: boolean;
    selectedDate?: moment.Moment;
    onDateSelected?: (date: moment.Moment) => void;
    onServiceCallClicked: (appointment: TechnicianRepair) => void;
}

interface AppointmentTimeContent {
    labelColor: SemanticCOLORS;
    labelContent: string;
}

export type AppointmentWeekProps =
    & AppointmentWeekOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    weekNumberFormat: { id: 'AppointmentWeek.week_number_format', defaultMessage: 'Week {number}' },
    unknownCity: { id: 'AppointmentWeek.unknown_city', defaultMessage: 'Unknown' }
});

const currentDateStyle = { color: '#2185d0' };

class AppointmentWeek extends React.Component<AppointmentWeekProps, {}> {
    public constructor(props: AppointmentWeekProps) {
        super(props);

        this.state = {};
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const currentDay = moment().startOf('day');
        const firstDayOfWeek = moment(DateHelper.getDateOfISOWeek(this.props.weekNumber, this.props.year));

        let renderedDays = [];

        for (var dayIncrement = 0; dayIncrement < 7; dayIncrement++) {
            const dayOfWeek = firstDayOfWeek.clone().add(dayIncrement, 'day');
            const renderedDay = this.renderDayRow(dayOfWeek, dayOfWeek.isSame(currentDay), dayOfWeek.isBefore(currentDay));
            renderedDays.push(renderedDay);
        }

        return (
            <Table celled={true} selectable={this.props.canSelectDate} stackable={false}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell colSpan={3} textAlign="center">
                            {formatMessage(m.weekNumberFormat, { number: this.props.weekNumber })}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {renderedDays}
                </Table.Body>
            </Table>
        );
    }

    private renderDayRow(date: moment.Moment, isCurrent: boolean, isPast: boolean) {
        const dayStyle = isCurrent ? currentDateStyle : undefined;
        const isSunday = date.toDate().getDay() === 0;

        if (isSunday) {
            return (null);
        }

        const renderedAppointments = this.props.appointments
            .filter(x => x.scheduledForDate && date.isSame(moment(x.scheduledForDate).startOf('day')))
            .map(this.renderAppointment);

        return (
            <Table.Row
                key={date.toDate().toISOString()}
                onClick={() => this.handleDateSelected(date)}
                active={this.props.selectedDate && this.props.canSelectDate ? date.isSame(this.props.selectedDate) : undefined}
            >
                <Table.Cell collapsing={true} disabled={isPast}>
                    <div style={dayStyle}>
                        {date.format('ddd')}
                        <h2>{date.date()}</h2>
                    </div>
                </Table.Cell>
                <Table.Cell verticalAlign="top" style={{ padding: 2 }}>
                    {renderedAppointments}
                </Table.Cell>
            </Table.Row>
        );
    }

    private renderAppointment = (appointment: TechnicianRepair) => {
        const { formatMessage } = this.props.intl;
        const timeContent = this.getTimeContent(appointment);
        const appointmentCity = appointment.serviceCall.customer.address && appointment.serviceCall.customer.address.city
            ? appointment.serviceCall.customer.address.city
            : formatMessage(m.unknownCity);

        const appointmentInfo = (
            <div style={{ marginBottom: 3 }}>
                <Label
                    className="fluid"
                    horizontal={true}
                    color={timeContent.labelColor}
                    content={timeContent.labelContent + ' - ' + appointmentCity}
                    style={{ cursor: 'pointer' }}
                />
            </div>
        );

        return (
            <Popup
                key={appointment.id}
                trigger={appointmentInfo}
                content={this.renderAppointmentOverview(appointment)}
                size="large"
                flowing={true}
                on="click"
            />
        );
    }

    private renderAppointmentOverview(appointment: TechnicianRepair) {
        return (
            <AppointmentQuickOverview
                appointment={appointment}
                onServiceCallClicked={this.props.onServiceCallClicked}
            />
        );
    }

    private handleDateSelected = (date: moment.Moment) => {
        if (this.props.onDateSelected) {
            this.props.onDateSelected(date);
        }
    }

    private getTimeContent(appointment: TechnicianRepair): AppointmentTimeContent {
        if (appointment.scheduledForTimeRange) {
            if (appointment.scheduledForTimeRange === AppointmentTimeRange.morning) {
                return {
                    labelContent: 'AM',
                    labelColor: 'orange'
                };
            } else {
                return {
                    labelContent: 'PM',
                    labelColor: 'violet'
                };
            }
        }

        return {
            labelContent: 'N/A',
            labelColor: 'grey'
        };
    }
}

const connectedComponent = injectIntl(AppointmentWeek);
export { connectedComponent as AppointmentWeek };