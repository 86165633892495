import { NavigationItem } from "../../components/layouts/PageLayout/NavigationMenu";
import {
    administrationMenuSection,
    afterSalesMenuSection,
    calendarMenuItem,
    calendarsMenuSection,
    clientsAdoptionMenuItem,
    createServiceCallMenuItem,
    currentServiceCallsMenuItem,
    dashboardMenuItem,
    detailedStatisticsMenuItem,
    factoryCalendarMenuItem,
    myServiceCallsMenuItem,
    overdueServiceCallsMenuItem,
    reportsMenuSection,
    serviceCallsHistoryMenuItem,
    stalledUnassignedServiceCallsMenuItem,
    statisticsMenuItem,
    subcontractorsBillingMenuItem,
    subcontractorsMenuItem,
    techniciansCalendarMenuItem,
    trendsMenuItem,
    usersMenuItem,
} from "./common";

export const administratorNavigationItems = (
    activeCallsCount: number,
    stalledUnassignedServiceCallsCount: number,
    overdueCallsCount: number
): NavigationItem[] => [
        dashboardMenuItem(),
        {
            ...afterSalesMenuSection(),
            children: [
                createServiceCallMenuItem(),
                myServiceCallsMenuItem(),
                currentServiceCallsMenuItem(activeCallsCount || undefined),
                stalledUnassignedServiceCallsMenuItem(stalledUnassignedServiceCallsCount || undefined),
                overdueServiceCallsMenuItem(overdueCallsCount || undefined),
                serviceCallsHistoryMenuItem(),
            ],
        },
        {
            ...reportsMenuSection(),
            children: [
                statisticsMenuItem(),
                detailedStatisticsMenuItem(),
                trendsMenuItem(),
                clientsAdoptionMenuItem(),
                subcontractorsBillingMenuItem(),
            ],
        },
        {
            ...calendarsMenuSection(),
            children: [techniciansCalendarMenuItem(), factoryCalendarMenuItem()],
        },
        {
            ...administrationMenuSection(),
            children: [usersMenuItem(), subcontractorsMenuItem(), calendarMenuItem()],
        },
    ];
