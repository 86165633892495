import React from 'react';
import _ from 'lodash';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';

import * as SearchActions from '../../state/ducks/search';

import { ApplicationState } from '../../state/ducks';
import { Search, SearchProps, SearchResultProps, Label, SearchResultData } from 'semantic-ui-react';
import { Client } from '../../state/models';
import { debounce, StringHelper } from '../../state/utils';

interface ClientSearchControlActions {
    searchActions: typeof SearchActions.actionCreators;
}

interface ClientSearchControlOwnProps {
    defaultValue?: Client;
    value?: Client;
    placeholder?: string;
    noResultsMessage?: string;
    onResultSelected: (result: Client | undefined) => void;
}

interface ClientSearchControlConnectedProps {
    isSearching: boolean;
    results: Client[];
}

interface ClientSearchControlState {
    isDebouncing: boolean;
    searchTerm: string | undefined;
}

interface ClientSearchResult {
    client: Client;
    title: string;
}

export type ClientSearchControlProps =
    & ClientSearchControlConnectedProps
    & ClientSearchControlOwnProps
    & ClientSearchControlActions
    & WrappedComponentProps;

const m = defineMessages({
    placeholder: { id: 'ClientSearchControl.placeholder', defaultMessage: 'Search for a client by its identifier or name' },
    noResults: { id: 'ClientSearchControl.no_results', defaultMessage: 'No clients found' },
    legalNameLabel: { id: 'ClientSearchControl.legalNameLabel', defaultMessage: 'Legal name: {name}' },
});

class ClientSearchControl extends React.Component<ClientSearchControlProps, ClientSearchControlState> {
    public constructor(props: ClientSearchControlProps) {
        super(props);

        this.state = {
            isDebouncing: false,
            searchTerm: props.value != null ? this.buildSearchResultTitle(props.value) : ''
        };
    }

    public componentWillReceiveProps(props: ClientSearchControlProps) {
        if (props.value !== this.props.value) {
            this.setState({
                searchTerm: props.value != null ? this.buildSearchResultTitle(props.value) : ''
            });
        }
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const results = this.props.results.map(this.buildSearchResult);

        // We consider that we are searching while the search is debouncing. This is done
        // in order to prevent the "No results" message from appearing when the user start
        // typing a few characters before the debounce delay is reached.
        const isSearching = this.props.isSearching || this.state.isDebouncing;

        return (
            <Search
                fluid={true}
                results={results}
                defaultValue={this.props.defaultValue ? this.buildSearchResultTitle(this.props.defaultValue) : undefined}
                showNoResults={!isSearching}
                noResultsMessage={this.props.noResultsMessage || formatMessage(m.noResults)}
                placeholder={this.props.placeholder || formatMessage(m.placeholder)}
                resultRenderer={this.renderSearchResult}
                onSearchChange={this.handleSearchChange}
                onResultSelect={this.handleResultSelect}
                loading={isSearching}
                value={this.state.searchTerm}
            />
        );
    }

    private buildSearchResult = (client: Client): ClientSearchResult => {
        // We need to wrap the client inside an other object, because the Semantic UI control will append the results directly to the
        // SearchResultProps otherwise, resulting into clashing properties, such as the 'id' property.
        return {
            client: client,
            title: this.buildSearchResultTitle(client)
        };
    }

    private buildSearchResultTitle = (client: Client): string => {
        return `${client.id} - ${client.name}`;
    }

    // tslint:disable-next-line:no-any
    private renderSearchResult = (props: SearchResultProps): React.ReactElement<any> => {
        const { formatMessage } = this.props.intl;
        const client = props.client as Client;
        const hasAlternameName = _.trim(client.name) !== _.trim(client.alternateName);

        return (
            <React.Fragment key={client.id}>
                <div title={client.name} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <Label content={client.id} />
                    <span className="text">{client.name}</span>
                </div>
                {hasAlternameName &&
                    <div title={client.alternateName} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <small>{formatMessage(m.legalNameLabel, { name: client.alternateName })}</small>
                    </div>
                }
            </React.Fragment>
        );
    }

    private performSearch(searchTerm: string) {
        this.props.searchActions.findClient(searchTerm);
    }

    // tslint:disable-next-line:no-any
    private handleSearchDebouncing = (args?: any) => {
        const data: SearchProps = args[1] as SearchProps;
        this.setState({
            isDebouncing: true,
            searchTerm: data.value as string
        });
    }

    // tslint:disable-next-line:no-any
    private handleSearchDebounced = () => {
        this.setState({ isDebouncing: false });
    }

    // tslint:disable-next-line:member-ordering
    private handleSearchChange = debounce(
        (_event: React.MouseEvent<HTMLElement>, data: SearchProps) => {
            if (StringHelper.hasValue(data.value)) {
                this.performSearch(data.value as string);
            } else {
                this.props.onResultSelected(undefined);
            }
        },
        900,
        this.handleSearchDebouncing,
        this.handleSearchDebounced);

    private handleResultSelect = (_event: React.MouseEvent<HTMLDivElement>, data: SearchResultData) => {
        const client = data.result.client as Client;
        this.setState({ searchTerm: client ? this.buildSearchResultTitle(client) : '' });

        if (this.props.onResultSelected) {
            this.props.onResultSelected(client);
        }
    }
}

const mapStateToProps = (state: ApplicationState, _props: ClientSearchControlOwnProps): ClientSearchControlConnectedProps => {
    return {
        isSearching: state.search.isSearchingForClients,
        results: state.search.clientsSearchResult,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ClientSearchControlActions => {
    return {
        searchActions: bindActionCreators(SearchActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(ClientSearchControl);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as ClientSearchControl };