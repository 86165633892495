import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedMessage, FormattedNumber } from 'react-intl';
import { Feed, SemanticICONS, Popup, Icon, List } from 'semantic-ui-react';

import { ServiceCallUpdate, SubcontractorUpdate, ActivityType } from '../../../state/models';
import { StringHelper } from '../../../state/utils';

export interface SubcontractorActivityProps {
    activity: SubcontractorUpdate;
}

const m = defineMessages({
    summary: { id: 'SubcontractorActivity.summary', defaultMessage: 'A subcontractor performed repairs on the furniture.' },
    summaryWithSubcontractorFormat: { id: 'SubcontractorActivity.summary_with_subcontractor', defaultMessage: 'The subcontractor \'{subcontractor}\' performed repairs on the furniture.' },
    summaryWithClientSubcontractor: { id: 'SubcontractorActivity.summary_with_client_subcontrator', defaultMessage: 'The client\'s subcontractor performed repairs on the furniture.' },
    repairCostFormat: { id: 'SubcontractorActivity.repair_cost_format', defaultMessage: 'The subcontractor billed {amount} for the repairs' },
    shippingCostFormat: { id: 'SubcontractorActivity.shipping_cost_format', defaultMessage: 'The subcontractor billed {amount} for the shipping fees' },
    billReferenceNumberFormat: { id: 'SubcontractorActivity.bill_reference_format', defaultMessage: 'Reference #{billNumber}' },
    billReferenceDateFormat: { id: 'SubcontractorActivity.bill_reference_date_format', defaultMessage: 'Billed on {billDate}' }
});

class SubcontractorActivity extends React.Component<SubcontractorActivityProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { activity } = this.props;
        const { subcontractorName } = activity;
        const hasSubcontractorName = subcontractorName && StringHelper.hasValue(subcontractorName);
        const repairsCost = <FormattedNumber value={activity.repairCost} style="currency" currency={activity.currencyCode} />;
        const repairsCostDescription = <FormattedMessage {...m.repairCostFormat} values={{ 'amount': repairsCost }} />;
        const shippingCost = <FormattedNumber value={activity.shippingCost} style="currency" currency={activity.currencyCode} />;
        const shippingCostDescription = <FormattedMessage {...m.shippingCostFormat} values={{ 'amount': shippingCost }} />;

        return (
            <Feed.Event key={activity.id}>
                <Feed.Label icon="wrench" />
                <Feed.Content>
                    <Popup
                        trigger={<Feed.Date content={moment.utc(activity.createdOn).fromNow()} />}
                        content={moment.utc(activity.createdOn).format('LL')}
                    />

                    <Feed.Summary>
                        {hasSubcontractorName && <FormattedMessage {...m.summaryWithSubcontractorFormat} values={{ subcontractor: subcontractorName }} />}
                        {!hasSubcontractorName && activity.type !== ActivityType.repairedByClientSubcontractor && <FormattedMessage {...m.summary} />}
                        {!hasSubcontractorName && activity.type === ActivityType.repairedByClientSubcontractor && <FormattedMessage {...m.summaryWithClientSubcontractor} />}
                    </Feed.Summary>

                    <Feed.Extra text={true}>
                        <List bulleted={true}>
                            {activity.repairCost > 0 &&
                                <List.Item content={repairsCostDescription} />}
                            {activity.shippingCost > 0 &&
                                <List.Item content={shippingCostDescription} />}
                            {activity.billedOn &&
                                <List.Item content={formatMessage(m.billReferenceDateFormat, { 'billDate': moment.utc(activity.billedOn).format('LL') })} />}
                            {activity.subcontractorBillReference &&
                                <List.Item content={formatMessage(m.billReferenceNumberFormat, { 'billNumber': activity.subcontractorBillReference })} />}
                            {activity.receiptUri &&
                                <List.Item content={activity.receiptUri} />
                            }
                        </List>

                        {StringHelper.hasValue(activity.note) &&
                            <p style={{ whiteSpace: 'pre-line' }}>{activity.note}</p>
                        }
                    </Feed.Extra>
                </Feed.Content>
            </Feed.Event>
        );
    }
}

const connectedComponent = injectIntl(SubcontractorActivity);
export { connectedComponent as SubcontractorActivity };