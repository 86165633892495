import * as React from 'react';
import { Segment, Statistic, SemanticCOLORS, Icon, Popup } from 'semantic-ui-react';
import { StatisticProps } from 'semantic-ui-react/dist/commonjs/views/Statistic/Statistic';

export interface StatisticTileProps extends StatisticProps {
    color?: SemanticCOLORS;
    unitLabel?: string;
    labelTooltip?: string;
    isLoading?: boolean;
    onClick?: () => void;
    compact?: boolean;
}

class StatisticTile extends React.Component<StatisticTileProps, {}> {
    public render() {
        let classNames = ['c-statistic'];
        if (this.props.onClick) {
            classNames.push('c-statistic--clickable');
        }

        if (this.props.compact) {
            classNames.push('c-statistic--compact');
        }

        const { color, compact, unitLabel, labelTooltip, isLoading, variation, variationTooltip, isVariationDecreasing, isVariationBad, onClick, size, value, label, children, ...statisticProps } = this.props;

        return (
            <a className={classNames.join(' ')} onClick={this.props.onClick}>
                <Segment className="c-statistic__container" loading={this.props.isLoading} color={this.props.color}>
                    <Statistic size={this.props.size || 'small'} style={{ margin: 0 }} {...statisticProps}>
                        <Statistic.Value className="c-statistic__value">
                            {this.props.value}
                            {this.props.unitLabel &&
                                <small className="c-statistic__unit" style={{ fontSize: '55%', textTransform: 'none', marginLeft: '5px' }}>
                                    {this.props.unitLabel}
                                </small>
                            }
                        </Statistic.Value>
                        <Statistic.Label className="c-statistic__label">
                            {this.props.label}
                            {this.props.labelTooltip &&
                                <Popup trigger={<Icon name="question circle outline" />}>
                                    {this.props.labelTooltip}
                                </Popup>
                            }
                        </Statistic.Label>
                    </Statistic>

                    {this.props.children &&
                        <div style={{ textAlign: 'right', marginTop: '5px' }}>
                            {this.props.children}
                        </div>
                    }
                </Segment>
            </a>
        );
    }
}

export { StatisticTile };