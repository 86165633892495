import _ from 'lodash';
import moment from 'moment';

import { Reducer } from 'redux';
import { ActionTypes } from './actions';
import { ActionTypeKeys } from './types';
import { CalendarBreak } from '../../models';

export interface CalendarState {
    allBreaks: {
        [id: number]: CalendarBreak
    };
    allIds: number[];
    startDate: Date;
    loadingDates: Date[];
}

const initialState: CalendarState = {
    allBreaks: {},
    allIds: [],
    startDate: new Date(),
    loadingDates: []
};

export const notificationsReducer: Reducer<CalendarState> = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case ActionTypeKeys.LOAD_LOCKS_SUCCESS:
            const breaks = Object.assign({}, ...action.payload.map(x => ({ [x.id]: x })));
            const breakIds = action.payload.map(x => x.id);

            return {
                ...state,
                allBreaks: _.merge({}, state.allBreaks, breaks),
                allIds: _.union(state.allIds, breakIds)
            };

        case ActionTypeKeys.LOCK_REQUEST:
        case ActionTypeKeys.UNLOCK_REQUEST:
            return {
                ...state,
                loadingDates: _.union(state.loadingDates, [action.payload])
            };

        case ActionTypeKeys.LOCK_FAILURE:
        case ActionTypeKeys.UNLOCK_FAILURE:
            return {
                ...state,
                loadingDates: state.loadingDates.filter(x => !moment(x).isSame(action.payload.forDate, 'day'))
            };

        case ActionTypeKeys.LOCK_SUCCESS:
            return {
                ...state,
                allBreaks: _.merge({}, state.allBreaks, { [action.payload.calendarBreak.id]: action.payload.calendarBreak }),
                allIds: _.union(state.allIds, [action.payload.calendarBreak.id]),
                loadingDates: state.loadingDates.filter(x => !moment(x).isSame(action.payload.forDate, 'day'))
            };

        case ActionTypeKeys.UNLOCK_SUCCESS:
            const remainingBreaks = state.allIds.map(x => state.allBreaks[x]).filter(x => !moment(x.forDate).isSame(action.payload, 'day'));

            return {
                ...state,
                allBreaks: Object.assign({}, ...remainingBreaks.map(x => ({ [x.id]: x }))),
                allIds: remainingBreaks.map(x => x.id),
                loadingDates: state.loadingDates.filter(x => !moment(x).isSame(action.payload, 'day'))
            };

        case ActionTypeKeys.SET_CALENDAR_START_DATE:
            return {
                ...state,
                startDate: action.payload
            };

        default:
            return state;
    }
};

export default notificationsReducer;