import { combineReducers } from 'redux';
import { AdministrationState } from './types';
import { subcontractorsReducer } from './subcontractors/reducer';
import { usersReducer } from './users/reducer';

const administrationReducer = combineReducers<AdministrationState>({
    subcontractors: subcontractorsReducer,
    users: usersReducer,
});

export { administrationReducer };