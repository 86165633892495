import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { ApplicationState } from '../../../state/ducks';
import { ServiceCallState } from '../../../state/ducks/service-calls/reducers';
import { ServiceCallFactoryRepair, ServiceCallAttachment, ProblemEdit, ValidationResult } from '../../../state/models';
import { getServiceCallState, getPictures, getVideos, getDocuments, getFactoryRepairs } from '../../../state/ducks/service-calls';

import * as UiActions from '../../../state/ducks/ui';
import * as ServiceCallsActions from '../../../state/ducks/service-calls';
import { Grid } from 'semantic-ui-react';
import { AppointmentInformationCard, RepairsInformationCard, ProblemInformationCard } from '../../../components/service-calls';
import { FactoryRepairsInformationCard } from '../../../components/service-calls/factory';
import { getFactoryRepairById } from '../../../state/ducks/factory-repairs/selectors';
import { ServiceCallEdit } from '../../../state/models/edits/ServiceCallEdit';

interface FactoryPrepareStepActions {
    serviceCallsActions: typeof ServiceCallsActions.actionCreators;
    uiActions: typeof UiActions.actionCreators;
}

interface FactoryPrepareStepOwnProps {
    serviceCall: ServiceCallState;
    factoryRepairs: ServiceCallFactoryRepair;
    areFactoryRepairsLoading: boolean;
    uploadedDocuments: ServiceCallAttachment[];
    uploadedPictures: ServiceCallAttachment[];
    uploadedVideos: ServiceCallAttachment[];
    areDocumentsUploading: boolean;
    arePicturesUploading: boolean;
}

export type FactoryPrepareStepProps =
    & FactoryPrepareStepOwnProps
    & FactoryPrepareStepActions
    & RouteComponentProps<{ serviceCallId: string, repairId: string }>;

type IntlFactoryPrepareStepProps = FactoryPrepareStepProps & WrappedComponentProps;

class FactoryPrepareStep extends React.Component<IntlFactoryPrepareStepProps, {}> {
    public componentDidMount() {
        this.props.uiActions.changeFactoryStep('prepare');
    }

    public render() {
        const isLoading = this.props.serviceCall == null || this.props.serviceCall.isLoading || !this.props.factoryRepairs;
        const details = this.props.serviceCall != null && !this.props.serviceCall.isLoading ? this.props.serviceCall.details : undefined;
        const serviceCallEdit = details ? ServiceCallEdit.fromEntity(details) : undefined;

        return (
            <Grid stackable={true}>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <FactoryRepairsInformationCard
                            isLoading={isLoading}
                            factoryRepairs={this.props.factoryRepairs}
                            serviceCall={details}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <RepairsInformationCard
                            isLoading={isLoading}
                            defectiveItems={details ? details.defectiveItems : []}
                            defects={details ? details.defects : []}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <ProblemInformationCard
                            serviceCallId={Number(this.props.match.params.serviceCallId)}
                            serviceCall={details}
                            isServiceCallLoading={!this.props.serviceCall || this.props.serviceCall.isLoading}
                            medias={this.props.uploadedPictures.concat(this.props.uploadedVideos)}
                            documents={this.props.uploadedDocuments}
                            canEdit={true}
                            onEdit={this.handleProblemEdit}
                            onUploadMedia={this.handleFilesUpload}
                            areMediasUploading={this.props.arePicturesUploading}
                            areDocumentsUploading={this.props.areDocumentsUploading}
                            onDeleteAttachment={this.handleDeletePicture}
                            onUploadDocuments={this.handleDocumentsUpload}
                            problemEdit={serviceCallEdit ? serviceCallEdit.problem : undefined}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    private handleProblemEdit = (problemEdit: ProblemEdit): ValidationResult => {
        const validationResult = problemEdit.validate();

        if (validationResult.isValid) {
            var serviceCallEdit = ServiceCallEdit.fromEntity(this.props.serviceCall.details);
            serviceCallEdit.problem = problemEdit;

            this.props.serviceCallsActions.updateServiceCall(serviceCallEdit);
        }

        return validationResult;
    }

    private handleFilesUpload = (files: FileList): void => {
        if (files && files.length > 0) {
            if (files[0].type.includes('video')) {
                this.props.serviceCallsActions.uploadVideos(
                    this.props.serviceCall.details.id,
                    Array.from(files)
                );
            } else {
                this.props.serviceCallsActions.uploadPictures(
                    this.props.serviceCall.details.id,
                    Array.from(files)
                );
            }
        }
    }

    private handleDocumentsUpload = (files: FileList): void => {
        if (files && files.length > 0) {
            this.props.serviceCallsActions.uploadDocuments(
                this.props.serviceCall.details.id,
                Array.from(files)
            );
        }
    }

    private handleDeletePicture = (pictureId: number): void => {
        this.props.serviceCallsActions.deleteAttachment(
            this.props.serviceCall.details.id,
            pictureId
        );
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: FactoryPrepareStepProps): FactoryPrepareStepOwnProps => {
    const serviceCallId = Number(ownProps.match.params.serviceCallId);
    const repairId = Number(ownProps.match.params.repairId);
    const serviceCallState = getServiceCallState(state, serviceCallId);

    return {
        serviceCall: serviceCallState,
        factoryRepairs: getFactoryRepairById(state, repairId),
        areFactoryRepairsLoading: false,
        uploadedPictures: getPictures(state, serviceCallId),
        uploadedVideos: getVideos(state, serviceCallId),
        uploadedDocuments: getDocuments(state, serviceCallId),
        arePicturesUploading: serviceCallState ? serviceCallState.arePicturesUploading : false,
        areDocumentsUploading: serviceCallState ? serviceCallState.areDocumentsUploading : false,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): FactoryPrepareStepActions => {
    return {
        serviceCallsActions: bindActionCreators(ServiceCallsActions.actionCreators, dispatch),
        uiActions: bindActionCreators(UiActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(FactoryPrepareStep);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as FactoryPrepareStep };