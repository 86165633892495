import * as React from "react";
import { Button, Grid, Icon, SemanticICONS, SemanticCOLORS } from "semantic-ui-react";

export interface MobileNavigationButtonProps {
    iconName?: SemanticICONS,
    iconColor?: SemanticCOLORS,
    title: string,
    description: string,
    buttonText: string,
    disabled?: boolean,
    onClick?: () => void
}

export class MobileNavigationButton extends React.Component<MobileNavigationButtonProps & React.HTMLAttributes<Button>, {}> {
    public render() {
        return (
            <Button
                basic
                style={this.props.style}
                disabled={this.props.disabled}
                onClick={this.props.onClick}
                className="o-navigation-button o-navigation-button--mobile">
                <Grid textAlign="left" verticalAlign="middle">
                    <Grid.Column width={4}>
                        <Icon
                            className="o-navigation-button__icon"
                            name={this.props.iconName}
                            color={this.props.iconColor}
                            size="huge"
                            style={{ margin: "0" }} />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="left">
                        <h3 className="o-navigation-button__title" style={{ minHeight: 0 }}>{this.props.title}</h3>
                        <p className="o-navigation-button__description">{this.props.description}</p>
                    </Grid.Column>
                </Grid>
                
            </Button>
        );
    }
}

export default MobileNavigationButton;