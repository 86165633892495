import { get, getFile, httpDeleteVoid, postMultiPart, postVoid } from '../../../utils/api';
import { SubcontractorRepairDetails } from './types';

const apiBaseUrl = 'api/subcontractor-repairs';

export const getSubcontractorRepair = (): Promise<SubcontractorRepairDetails> =>
    get<SubcontractorRepairDetails>(`${apiBaseUrl}/details`);

export const closeSubcontractorRepairServiceCall = (): Promise<void> =>
    postVoid(`${apiBaseUrl}/service-call/close`, {});

export const setSubcontractorRepairReport = (
    report: string | null | undefined
): Promise<void> => postVoid(`${apiBaseUrl}/report`, report);

export const setSubcontractorRepairAppointmentDate = (
    appointmentDate: Date | undefined
): Promise<void> => postVoid(`${apiBaseUrl}/appointment`, appointmentDate);

export const addSubcontractorRepairBill = (
    repairFees: number,
    shippingFees: number,
    currencyCode: string,
    referenceNumber: string
) =>
    postVoid(`${apiBaseUrl}/bill`, {
        repairFees,
        shippingFees,
        currencyCode,
        referenceNumber,
    });

export const addSubcontractorRepairInvoice = (invoice: File) =>
    postMultiPart<void>(
        `${apiBaseUrl}/invoice`,
        {
            invoice: [invoice],
        },
        true
    );

export const getSubcontractorRepairInvoice = (contentType: string): Promise<Blob> =>
    getFile(`${apiBaseUrl}/invoice`, contentType);

export const uploadSubcontractorRepairPictures = (attachments: File[]) => {
    return postMultiPart<void>(`${apiBaseUrl}/pictures`, { attachments }, true);
};

export const getSubcontractorRepairPicture = (attachmentId: number, contentType: string): Promise<Blob> =>
    getFile(`${apiBaseUrl}/pictures/${attachmentId}`, contentType);

export const deleteSubcontractorRepairPicture = (attachmentId: number): Promise<void> =>
    httpDeleteVoid(`${apiBaseUrl}/pictures/${attachmentId}`);