import { ThunkAction } from 'redux-thunk';
import { Subcontractor } from '../../../state/models';
import { ApplicationState } from '../../../state/ducks';
import * as api from './api';
import { SubcontractorEdit } from './views/models';
import { Action } from 'redux';
import { AppThunk } from '../../../state/store';

export enum ActionTypeKeys {
    CREATE_REQUEST = 'SUBCONTRACTORS_CREATE_REQUEST',
    CREATE_SUCCESS = 'SUBCONTRACTORS_CREATE_SUCCESS',
    CREATE_FAILURE = 'SUBCONTRACTORS_CREATE_FAILURE',

    DELETE_REQUEST = 'SUBCONTRACTORS_DELETE_REQUEST',
    DELETE_SUCCESS = 'SUBCONTRACTORS_DELETE_SUCCESS',
    DELETE_FAILURE = 'SUBCONTRACTORS_DELETE_FAILURE',

    LOAD_ALL_REQUEST = 'SUBCONTRACTORS_LOAD_ALL_REQUEST',
    LOAD_ALL_SUCCESS = 'SUBCONTRACTORS_LOAD_ALL_SUCCESS',
    LOAD_ALL_FAILURE = 'SUBCONTRACTORS_LOAD_ALL_FAILURE',

    LOAD_BY_ID_REQUEST = 'SUBCONTRACTORS_LOAD_BY_ID_REQUEST',
    LOAD_BY_ID_SUCCESS = 'SUBCONTRACTORS_LOAD_BY_ID_SUCCESS',
    LOAD_BY_ID_FAILURE = 'SUBCONTRACTORS_LOAD_BY_ID_FAILURE',

    UPDATE_REQUEST = 'SUBCONTRACTORS_UPDATE_REQUEST',
    UPDATE_SUCCESS = 'SUBCONTRACTORS_UPDATE_SUCCESS',
    UPDATE_FAILURE = 'SUBCONTRACTORS_UPDATE_FAILURE',
}

export interface CreateRequestAction { type: ActionTypeKeys.CREATE_REQUEST; payload: { subcontractor: SubcontractorEdit }; }
export interface CreateSuccessAction { type: ActionTypeKeys.CREATE_SUCCESS; payload: { subcontractor: Subcontractor }; }
export interface CreateFailureAction { type: ActionTypeKeys.CREATE_FAILURE; payload: { error: Error }; }

export interface DeleteRequestAction { type: ActionTypeKeys.DELETE_REQUEST; payload: { subcontractorId: number }; }
export interface DeleteSuccessAction { type: ActionTypeKeys.DELETE_SUCCESS; payload: { subcontractorId: number }; }
export interface DeleteFailureAction { type: ActionTypeKeys.DELETE_FAILURE; payload: { subcontractorId: number, error: Error }; }

export interface LoadAllRequestAction { type: ActionTypeKeys.LOAD_ALL_REQUEST; }
export interface LoadAllSuccessAction { type: ActionTypeKeys.LOAD_ALL_SUCCESS; payload: { subcontractors: Subcontractor[] }; }
export interface LoadAllFailureAction { type: ActionTypeKeys.LOAD_ALL_FAILURE; payload: { error: Error }; }

export interface LoadByIdRequestAction { type: ActionTypeKeys.LOAD_BY_ID_REQUEST; payload: { subcontractorId: number }; }
export interface LoadByIdSuccessAction { type: ActionTypeKeys.LOAD_BY_ID_SUCCESS; payload: { subcontractorId: number, subcontractor: Subcontractor }; }
export interface LoadByIdFailureAction { type: ActionTypeKeys.LOAD_BY_ID_FAILURE; payload: { subcontractorId: number, error: Error }; }

export interface UpdateRequestAction { type: ActionTypeKeys.UPDATE_REQUEST; payload: { subcontractorId: number }; }
export interface UpdateSuccessAction { type: ActionTypeKeys.UPDATE_SUCCESS; payload: { subcontractorId: number, subcontractor: Subcontractor }; }
export interface UpdateFailureAction { type: ActionTypeKeys.UPDATE_FAILURE; payload: { subcontractorId: number, error: Error }; }

export type SubcontractorsActionTypes =
    | CreateRequestAction | CreateSuccessAction | CreateFailureAction
    | DeleteRequestAction | DeleteSuccessAction | DeleteFailureAction
    | LoadAllRequestAction | LoadAllSuccessAction | LoadAllFailureAction
    | LoadByIdRequestAction | LoadByIdSuccessAction | LoadByIdFailureAction;

export const actionCreators = {
    loadAll: (): AppThunk => {
        return (dispatch) => {
            dispatch({ type: ActionTypeKeys.LOAD_ALL_REQUEST });

            api.loadAll()
                .then(subcontractors => dispatch({ type: ActionTypeKeys.LOAD_ALL_SUCCESS, payload: { subcontractors } }))
                .catch(error => dispatch({ type: ActionTypeKeys.LOAD_ALL_FAILURE, payload: { error } }));
        };
    },

    loadById: (subcontractorId: number): AppThunk => {
        return (dispatch) => {
            dispatch({ type: ActionTypeKeys.LOAD_BY_ID_REQUEST, payload: { subcontractorId } });

            api.loadById(subcontractorId)
                .then(subcontractor => dispatch({ type: ActionTypeKeys.LOAD_BY_ID_SUCCESS, payload: { subcontractorId, subcontractor } }))
                .catch(error => dispatch({ type: ActionTypeKeys.LOAD_BY_ID_FAILURE, payload: { subcontractorId, error } }));
        };
    },

    delete: (subcontractorId: number): AppThunk => {
        return (dispatch) => {
            dispatch({ type: ActionTypeKeys.DELETE_REQUEST, payload: { subcontractorId } });

            api.deleteEntity(subcontractorId)
                .then(_result => dispatch({ type: ActionTypeKeys.DELETE_SUCCESS, payload: { subcontractorId } }))
                .catch(error => dispatch({ type: ActionTypeKeys.DELETE_FAILURE, payload: { subcontractorId, error } }));
        };
    },

    update: (subcontractorId: number, subcontractor: SubcontractorEdit): AppThunk => {
        return (dispatch) => {
            dispatch({ type: ActionTypeKeys.UPDATE_REQUEST, payload: { subcontractorId } });

            api.update(subcontractorId, subcontractor)
                .then(result => dispatch({ type: ActionTypeKeys.UPDATE_SUCCESS, payload: { subcontractorId, subcontractor: result } }))
                .catch(error => dispatch({ type: ActionTypeKeys.UPDATE_FAILURE, payload: { subcontractorId, error } }));
        };
    },

    create: (subcontractor: SubcontractorEdit, onSuccess?: () => void): AppThunk => {
        return (dispatch) => {
            dispatch({ type: ActionTypeKeys.CREATE_REQUEST, payload: { subcontractor } });

            api.create(subcontractor)
                .then(result => dispatch({ type: ActionTypeKeys.CREATE_SUCCESS, payload: { subcontractor: result } }))
                .then(_ => {
                    if (onSuccess) {
                        onSuccess();
                    }
                })
                .catch(error => dispatch({ type: ActionTypeKeys.CREATE_FAILURE, payload: { error } }));
        };
    },
};