
import { RepairType } from '../../../../components/service-calls';
import { CustomerServiceService } from '../../../../services';
import { Defect, ReturnForCredit, ServiceCall, ServiceCallDefectiveItem, ServiceCallFactoryRepair, ServiceCallFeesEstimate, ServiceCallInspection, SubcontractorBill, TechnicianAppointment, TechnicianRepair, User } from '../../../models';
import { SubcontractorRepair, SubcontractorRepairWarrantyStatus } from '../../../models/SubcontractorRepair';
import { AppThunk } from '../../../store';
import { StringHelper } from '../../../utils';
import * as globalNotification from '../../global-notification';
import { ActionTypeKeys } from '../types';

export type CustomerServiceActionTypes =
    | AssignAgentRequestAction
    | AssignAgentSuccessAction
    | AssignAgentFailureAction
    | AssignRepresentativeRequestAction
    | AssignRepresentativeSuccessAction
    | AssignRepresentativeFailureAction
    | ConfirmInspectionCompletionRequestAction
    | ConfirmInspectionCompletionSuccessAction
    | ConfirmInspectionCompletionFailureAction
    | CreateCreditReturnRequestAction
    | CreateCreditReturnSuccessAction
    | CreateCreditReturnFailureAction
    | ConfirmFurnitureReceptionForCreditReturnRequestAction
    | ConfirmFurnitureReceptionForCreditReturnSuccessAction
    | ConfirmFurnitureReceptionForCreditReturnFailureAction
    | AddCreditForCreditReturnRequestAction
    | AddCreditForCreditReturnSuccessAction
    | AddCreditForCreditReturnFailureAction
    | CreateFactoryRepairsRequestAction
    | CreateFactoryRepairsSuccessAction
    | CreateFactoryRepairsFailureAction
    | ConfirmFurnitureReceptionRequestAction
    | ConfirmFurnitureReceptionSuccessAction
    | ConfirmFurnitureReceptionFailureAction
    | ScheduleFactoryRepairsRequestAction
    | ScheduleFactoryRepairsSuccessAction
    | ScheduleFactoryRepairsFailureAction
    | ConfirmFactoryRepairsCompletionRequestAction
    | ConfirmFactoryRepairsCompletionSuccessAction
    | ConfirmFactoryRepairsCompletionFailureAction
    | CreateSubcontractorRepairsRequestAction
    | CreateSubcontractorRepairsSuccessAction
    | CreateSubcontractorRepairsFailureAction
    | CreateTechnicianRepairsRequestAction
    | CreateTechnicianRepairsSuccessAction
    | CreateTechnicianRepairsFailureAction
    | CreateTechnicianAppointmentRequestAction
    | CreateTechnicianAppointmentSuccessAction
    | CreateTechnicianAppointmentFailureAction
    | ConfirmTechnicianRepairsCompletionRequestAction
    | ConfirmTechnicianRepairsCompletionSuccessAction
    | ConfirmTechnicianRepairsCompletionFailureAction
    | SetSubcontractorRepairWarrantyStatusRequest
    | SetSubcontractorRepairWarrantyStatusSuccess
    | SetSubcontractorRepairWarrantyStatusFailure
    | SetSubcontractorAppointmentRequest
    | SetSubcontractorAppointmentSuccess
    | SetSubcontractorAppointmentFailure
    | AddSubcontractorBillRequestAction
    | AddSubcontractorBillSuccessAction
    | AddSubcontractorBillFailureAction
    | CancelCurrentRepairsRequestAction
    | CancelCurrentRepairsSuccessAction
    | CancelCurrentRepairsFailureAction
    | AddDefectRequestAction
    | AddDefectSuccessAction
    | AddDefectFailureAction
    | DeleteDefectRequestAction
    | DeleteDefectSuccessAction
    | DeleteDefectFailureAction
    | AddDefectiveItemRequestAction
    | AddDefectiveItemSuccessAction
    | AddDefectiveItemFailureAction
    | DeleteDefectiveItemRequestAction
    | DeleteDefectiveItemSuccessAction
    | DeleteDefectiveItemFailureAction
    | ChangeDefectiveItemsRequestAction
    | ChangeDefectiveItemsSuccessAction
    | ChangeDefectiveItemsFailureAction
    | UpdateRepairFeesEstimateRequestAction
    | UpdateRepairFeesEstimateSuccessAction
    | UpdateRepairFeesEstimateFailureAction
    | CloseRequestAction
    | CloseSuccessAction
    | CloseFailureAction
    | ReopenRequestAction
    | ReopenSuccessAction
    | ReopenFailureAction
    | CancelRequestAction
    | CancelSuccessAction
    | CancelFailureAction
    | LinkNewRequestAction
    | LinkNewSuccessAction
    | LinkNewFailureAction
    | { type: '' };

interface AssignAgentRequestAction { type: ActionTypeKeys.ASSIGN_AGENT_REQUEST; }
interface AssignAgentSuccessAction { type: ActionTypeKeys.ASSIGN_AGENT_SUCCESS; payload: { serviceCallId: number, agent: User }; }
interface AssignAgentFailureAction { type: ActionTypeKeys.ASSIGN_AGENT_FAILURE; payload: string; }

interface AssignRepresentativeRequestAction { type: ActionTypeKeys.ASSIGN_REPRESENTATIVE_REQUEST; }
interface AssignRepresentativeSuccessAction { type: ActionTypeKeys.ASSIGN_REPRESENTATIVE_SUCCESS; payload: { serviceCallId: number, inspection: ServiceCallInspection }; }
interface AssignRepresentativeFailureAction { type: ActionTypeKeys.ASSIGN_REPRESENTATIVE_FAILURE; payload: string; }

interface ConfirmInspectionCompletionRequestAction { type: ActionTypeKeys.CONFIRM_INSPECTION_COMPLETION_REQUEST; }
interface ConfirmInspectionCompletionSuccessAction { type: ActionTypeKeys.CONFIRM_INSPECTION_COMPLETION_SUCCESS; payload: { serviceCallId: number, inspection: ServiceCallInspection }; }
interface ConfirmInspectionCompletionFailureAction { type: ActionTypeKeys.CONFIRM_INSPECTION_COMPLETION_FAILURE; payload: string; }

interface CreateCreditReturnRequestAction { type: ActionTypeKeys.CREATE_CREDIT_RETURN_REQUEST; payload: { serviceCallId: number }; }
interface CreateCreditReturnSuccessAction { type: ActionTypeKeys.CREATE_CREDIT_RETURN_SUCCESS; payload: { serviceCallId: number, creditReturn: ReturnForCredit }; }
interface CreateCreditReturnFailureAction { type: ActionTypeKeys.CREATE_CREDIT_RETURN_FAILURE; payload: { serviceCallId: number, error: string }; }

interface ConfirmFurnitureReceptionForCreditReturnRequestAction { type: ActionTypeKeys.CONFIRM_CREDIT_RETURN_FURNITURE_RECEPTION_REQUEST; payload: { serviceCallId: number }; }
interface ConfirmFurnitureReceptionForCreditReturnSuccessAction { type: ActionTypeKeys.CONFIRM_CREDIT_RETURN_FURNITURE_RECEPTION_SUCCESS; payload: { serviceCallId: number, creditReturn: ReturnForCredit }; }
interface ConfirmFurnitureReceptionForCreditReturnFailureAction { type: ActionTypeKeys.CONFIRM_CREDIT_RETURN_FURNITURE_RECEPTION_FAILURE; payload: { serviceCallId: number, error: string }; }

interface AddCreditForCreditReturnRequestAction { type: ActionTypeKeys.ADD_CREDIT_RETURN_CREDIT_REQUEST; payload: { serviceCallId: number }; }
interface AddCreditForCreditReturnSuccessAction { type: ActionTypeKeys.ADD_CREDIT_RETURN_CREDIT_SUCCESS; payload: { serviceCallId: number, creditReturn: ReturnForCredit }; }
interface AddCreditForCreditReturnFailureAction { type: ActionTypeKeys.ADD_CREDIT_RETURN_CREDIT_FAILURE; payload: { serviceCallId: number, error: string }; }

interface CreateFactoryRepairsRequestAction { type: ActionTypeKeys.CREATE_FACTORY_REPAIRS_REQUEST; payload: { serviceCallId: number }; }
interface CreateFactoryRepairsSuccessAction { type: ActionTypeKeys.CREATE_FACTORY_REPAIRS_SUCCESS; payload: { serviceCallId: number, factoryRepair: ServiceCallFactoryRepair }; }
interface CreateFactoryRepairsFailureAction { type: ActionTypeKeys.CREATE_FACTORY_REPAIRS_FAILURE; payload: { serviceCallId: number, error: string }; }

interface ConfirmFurnitureReceptionRequestAction { type: ActionTypeKeys.CONFIRM_FURNITURE_RECEPTION_REQUEST; payload: { serviceCallId: number }; }
interface ConfirmFurnitureReceptionSuccessAction { type: ActionTypeKeys.CONFIRM_FURNITURE_RECEPTION_SUCCESS; payload: { serviceCallId: number, factoryRepair: ServiceCallFactoryRepair }; }
interface ConfirmFurnitureReceptionFailureAction { type: ActionTypeKeys.CONFIRM_FURNITURE_RECEPTION_FAILURE; payload: { serviceCallId: number, error: string }; }

interface ScheduleFactoryRepairsRequestAction { type: ActionTypeKeys.SCHEDULE_FACTORY_REPAIRS_REQUEST; payload: { serviceCallId: number }; }
interface ScheduleFactoryRepairsSuccessAction { type: ActionTypeKeys.SCHEDULE_FACTORY_REPAIRS_SUCCESS; payload: { serviceCallId: number, factoryRepair: ServiceCallFactoryRepair }; }
interface ScheduleFactoryRepairsFailureAction { type: ActionTypeKeys.SCHEDULE_FACTORY_REPAIRS_FAILURE; payload: { serviceCallId: number, error: string }; }

interface ConfirmFactoryRepairsCompletionRequestAction { type: ActionTypeKeys.CONFIRM_FACTORY_REPAIRS_COMPLETION_REQUEST; payload: { serviceCallId: number }; }
interface ConfirmFactoryRepairsCompletionSuccessAction { type: ActionTypeKeys.CONFIRM_FACTORY_REPAIRS_COMPLETION_SUCCESS; payload: { serviceCallId: number, factoryRepair: ServiceCallFactoryRepair }; }
interface ConfirmFactoryRepairsCompletionFailureAction { type: ActionTypeKeys.CONFIRM_FACTORY_REPAIRS_COMPLETION_FAILURE; payload: { serviceCallId: number, error: string }; }

interface CreateSubcontractorRepairsRequestAction { type: ActionTypeKeys.CREATE_SUBCONTRACTOR_REPAIRS_REQUEST; payload: { serviceCallId: number }; }
interface CreateSubcontractorRepairsSuccessAction { type: ActionTypeKeys.CREATE_SUBCONTRACTOR_REPAIRS_SUCCESS; payload: { serviceCallId: number, subcontractorRepair: SubcontractorRepair }; }
interface CreateSubcontractorRepairsFailureAction { type: ActionTypeKeys.CREATE_SUBCONTRACTOR_REPAIRS_FAILURE; payload: { serviceCallId: number, error: string }; }

interface CreateTechnicianRepairsRequestAction { type: ActionTypeKeys.CREATE_TECHNICIAN_REPAIRS_REQUEST; payload: { serviceCallId: number } }
interface CreateTechnicianRepairsSuccessAction { type: ActionTypeKeys.CREATE_TECHNICIAN_REPAIRS_SUCCESS; payload: { serviceCallId: number, technicianRepair: TechnicianRepair }; }
interface CreateTechnicianRepairsFailureAction { type: ActionTypeKeys.CREATE_TECHNICIAN_REPAIRS_FAILURE; payload: { serviceCallId: number, error: string; } }

interface CreateTechnicianAppointmentRequestAction { type: ActionTypeKeys.CREATE_TECHNICIAN_APPOINTMENT_REQUEST; payload: number; }
interface CreateTechnicianAppointmentSuccessAction { type: ActionTypeKeys.CREATE_TECHNICIAN_APPOINTMENT_SUCCESS; payload: { serviceCallId: number, technicianRepairs: TechnicianRepair[] }; }
interface CreateTechnicianAppointmentFailureAction { type: ActionTypeKeys.CREATE_TECHNICIAN_APPOINTMENT_FAILURE; payload: { serviceCallId: number, error: string }; }

interface ConfirmTechnicianRepairsCompletionRequestAction { type: ActionTypeKeys.CONFIRM_TECHNICIAN_REPAIRS_COMPLETION_REQUEST; }
interface ConfirmTechnicianRepairsCompletionSuccessAction { type: ActionTypeKeys.CONFIRM_TECHNICIAN_REPAIRS_COMPLETION_SUCCESS; payload: { serviceCallId: number, technicianRepair: TechnicianRepair }; }
interface ConfirmTechnicianRepairsCompletionFailureAction { type: ActionTypeKeys.CONFIRM_TECHNICIAN_REPAIRS_COMPLETION_FAILURE; payload: string; }

interface CancelCurrentRepairsRequestAction { type: ActionTypeKeys.CANCEL_CURRENT_REPAIRS_REQUEST; payload: number; }
interface CancelCurrentRepairsSuccessAction { type: ActionTypeKeys.CANCEL_CURRENT_REPAIRS_SUCCESS; payload: { serviceCallId: number }; }
interface CancelCurrentRepairsFailureAction { type: ActionTypeKeys.CANCEL_CURRENT_REPAIRS_FAILURE; payload: { serviceCallId: number, error: string }; }

interface SetSubcontractorRepairWarrantyStatusRequest { type: ActionTypeKeys.SET_SUBCONTRACTOR_REPAIR_WARRANTY_STATUS_REQUEST; payload: { serviceCallId: number, repairId: number }; }
interface SetSubcontractorRepairWarrantyStatusSuccess { type: ActionTypeKeys.SET_SUBCONTRACTOR_REPAIR_WARRANTY_STATUS_SUCCESS; payload: SubcontractorRepair; }
interface SetSubcontractorRepairWarrantyStatusFailure { type: ActionTypeKeys.SET_SUBCONTRACTOR_REPAIR_WARRANTY_STATUS_FAILURE; payload: { serviceCallId: number, error: string; }; }
interface SetSubcontractorAppointmentRequest { type: ActionTypeKeys.SET_SUBCONTRACTOR_APPOINTMENT_DATE_REQUEST; payload: { serviceCallId: number, repairId: number }; }
interface SetSubcontractorAppointmentSuccess { type: ActionTypeKeys.SET_SUBCONTRACTOR_APPOINTMENT_DATE_SUCCESS; payload: SubcontractorRepair; }
interface SetSubcontractorAppointmentFailure { type: ActionTypeKeys.SET_SUBCONTRACTOR_APPOINTMENT_DATE_FAILURE; payload: { serviceCallId: number, error: string; }; }

interface AddSubcontractorBillRequestAction { type: ActionTypeKeys.ADD_SUBCONTRACTOR_BILL_REQUEST; }
interface AddSubcontractorBillSuccessAction { type: ActionTypeKeys.ADD_SUBCONTRACTOR_BILL_SUCCESS; payload: { serviceCallId: number, subcontractorRepairId: number; bill: SubcontractorBill; }; }
interface AddSubcontractorBillFailureAction { type: ActionTypeKeys.ADD_SUBCONTRACTOR_BILL_FAILURE; }

interface AddDefectRequestAction { type: ActionTypeKeys.ADD_DEFECT_REQUEST; }
interface AddDefectSuccessAction { type: ActionTypeKeys.ADD_DEFECT_SUCCESS; payload: { serviceCallId: number; defect: Defect }; }
interface AddDefectFailureAction { type: ActionTypeKeys.ADD_DEFECT_FAILURE; payload: string; }

interface DeleteDefectRequestAction { type: ActionTypeKeys.DELETE_DEFECT_REQUEST; }
interface DeleteDefectSuccessAction { type: ActionTypeKeys.DELETE_DEFECT_SUCCESS; payload: { serviceCallId: number; defect: Defect }; }
interface DeleteDefectFailureAction { type: ActionTypeKeys.DELETE_DEFECT_FAILURE; payload: string; }

interface AddDefectiveItemRequestAction { type: ActionTypeKeys.ADD_DEFECTIVE_ITEM_REQUEST; }
interface AddDefectiveItemSuccessAction { type: ActionTypeKeys.ADD_DEFECTIVE_ITEM_SUCCESS; payload: { serviceCallId: number, defectiveItem: ServiceCallDefectiveItem }; }
interface AddDefectiveItemFailureAction { type: ActionTypeKeys.ADD_DEFECTIVE_ITEM_FAILURE; payload: string; }

interface DeleteDefectiveItemRequestAction { type: ActionTypeKeys.DELETE_DEFECTIVE_ITEM_REQUEST; }
interface DeleteDefectiveItemSuccessAction { type: ActionTypeKeys.DELETE_DEFECTIVE_ITEM_SUCCESS; payload: { serviceCallId: number, itemId: string }; }
interface DeleteDefectiveItemFailureAction { type: ActionTypeKeys.DELETE_DEFECTIVE_ITEM_FAILURE; payload: string; }

interface ChangeDefectiveItemsRequestAction { type: ActionTypeKeys.CHANGE_DEFECTIVE_ITEMS_REQUEST; payload: number; }
interface ChangeDefectiveItemsSuccessAction { type: ActionTypeKeys.CHANGE_DEFECTIVE_ITEMS_SUCCESS; payload: { serviceCallId: number, defectiveItems: ServiceCallDefectiveItem[] }; }
interface ChangeDefectiveItemsFailureAction { type: ActionTypeKeys.CHANGE_DEFECTIVE_ITEMS_FAILURE; payload: { serviceCallId: number, error: string }; }

interface UpdateRepairFeesEstimateRequestAction { type: ActionTypeKeys.UPDATE_REPAIR_FEES_ESTIMATE_REQUEST; }
interface UpdateRepairFeesEstimateSuccessAction { type: ActionTypeKeys.UPDATE_REPAIR_FEES_ESTIMATE_SUCCESS; payload: { serviceCallId: number, repairFeesEstimate: ServiceCallFeesEstimate }; }
interface UpdateRepairFeesEstimateFailureAction { type: ActionTypeKeys.UPDATE_REPAIR_FEES_ESTIMATE_FAILURE; payload: string; }

interface CloseRequestAction { type: ActionTypeKeys.CLOSE_REQUEST; }
interface CloseSuccessAction { type: ActionTypeKeys.CLOSE_SUCCESS; payload: ServiceCall; }
interface CloseFailureAction { type: ActionTypeKeys.CLOSE_FAILURE; payload: string; }

interface ReopenRequestAction { type: ActionTypeKeys.REOPEN_REQUEST; }
interface ReopenSuccessAction { type: ActionTypeKeys.REOPEN_SUCCESS; payload: ServiceCall; }
interface ReopenFailureAction { type: ActionTypeKeys.REOPEN_FAILURE; payload: string; }

interface CancelRequestAction { type: ActionTypeKeys.CANCEL_REQUEST; }
interface CancelSuccessAction { type: ActionTypeKeys.CANCEL_SUCCESS; payload: number; }
interface CancelFailureAction { type: ActionTypeKeys.CANCEL_FAILURE; payload: string; }

interface LinkNewRequestAction { type: ActionTypeKeys.LINK_NEW_REQUEST; }
interface LinkNewSuccessAction { type: ActionTypeKeys.LINK_NEW_SUCCESS; payload: ServiceCall; }
interface LinkNewFailureAction { type: ActionTypeKeys.LINK_NEW_FAILURE; payload: string; }

const customerServiceService = new CustomerServiceService();

export const customerServiceActionCreators = {
    assignAgent: (serviceCallId: number, agentEmail: string): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.ASSIGN_AGENT_REQUEST });

            customerServiceService.assignAgent(serviceCallId, agentEmail)
                .then(response => response.json() as User)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.ASSIGN_AGENT_SUCCESS, payload: { serviceCallId: serviceCallId, agent: result } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.ASSIGN_AGENT_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant d'assigner un agent du service après-vente à l'appel (${error}).`)
                    );
                });
        };
    },

    assignRepresentative: (serviceCallId: number, representativeId: string): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.ASSIGN_REPRESENTATIVE_REQUEST });

            customerServiceService.assignRepresentative(serviceCallId, representativeId)
                .then(response => response.json() as ServiceCallInspection)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.ASSIGN_REPRESENTATIVE_SUCCESS, payload: { serviceCallId: serviceCallId, inspection: result } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.ASSIGN_REPRESENTATIVE_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de planifier une inspection avec un représentant Jaymar (${error}).`)
                    );
                });
        };
    },

    confirmInspectionCompletion: (serviceCallId: number, inspectionId: number): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.CONFIRM_INSPECTION_COMPLETION_REQUEST });

            customerServiceService.confirmInspectionCompletion(serviceCallId, inspectionId)
                .then(response => response.json() as ServiceCallInspection)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.CONFIRM_INSPECTION_COMPLETION_SUCCESS, payload: { serviceCallId: serviceCallId, inspection: result } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.CONFIRM_INSPECTION_COMPLETION_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de confirmer la complétion d'une inspection (${error}).`)
                    );
                });
        };
    },

    createCreditReturnRequest: (serviceCallId: number, email: string | undefined, locale: string | undefined): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.CREATE_CREDIT_RETURN_REQUEST, payload: { serviceCallId: serviceCallId } });

            customerServiceService.createCreditReturnRequest(serviceCallId, email, locale)
                .then(response => response.json() as ReturnForCredit)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.CREATE_CREDIT_RETURN_SUCCESS, payload: { serviceCallId: serviceCallId, creditReturn: result } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.CREATE_CREDIT_RETURN_FAILURE, payload: { serviceCallId: serviceCallId, error: error } });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de créer une requête pour un retour crédit (${error}).`)
                    );
                });
        };
    },

    confirmFurnitureReceptionForCreditReturn: (serviceCallId: number, creditReturnId: number): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.CONFIRM_CREDIT_RETURN_FURNITURE_RECEPTION_REQUEST, payload: { serviceCallId: serviceCallId } });

            customerServiceService.confirmFurnitureReceptionForCreditReturn(serviceCallId, creditReturnId)
                .then(response => response.json() as ReturnForCredit)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.CONFIRM_CREDIT_RETURN_FURNITURE_RECEPTION_SUCCESS, payload: { serviceCallId: serviceCallId, creditReturn: result } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.CONFIRM_CREDIT_RETURN_FURNITURE_RECEPTION_FAILURE, payload: { serviceCallId: serviceCallId, error: error } });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de confirmer la réception du meuble pour un retour crédit (${error}).`)
                    );
                });

        };
    },

    addCreditForCreditReturn: (serviceCallId: number, creditReturnId: number, amountCredited: number, shippingFeesCredited: number, currencyCode: string): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.ADD_CREDIT_RETURN_CREDIT_REQUEST, payload: { serviceCallId: serviceCallId } });

            customerServiceService.addCreditForCreditReturn(serviceCallId, creditReturnId, amountCredited, shippingFeesCredited, currencyCode)
                .then(response => response.json() as ReturnForCredit)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.ADD_CREDIT_RETURN_CREDIT_SUCCESS, payload: { serviceCallId: serviceCallId, creditReturn: result } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.ADD_CREDIT_RETURN_CREDIT_FAILURE, payload: { serviceCallId: serviceCallId, error: error } });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant d'ajouter un crédit pour un retour crédit (${error}).`)
                    );
                });
        };
    },

    createFactoryRepairsRequest: (serviceCallId: number, email: string | undefined, locale: string | undefined): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.CREATE_FACTORY_REPAIRS_REQUEST, payload: { serviceCallId: serviceCallId } });

            customerServiceService.createFactoryRepairsRequest(serviceCallId, email, locale)
                .then(response => response.json() as ServiceCallFactoryRepair)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.CREATE_FACTORY_REPAIRS_SUCCESS, payload: { serviceCallId: serviceCallId, factoryRepair: result } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.CREATE_FACTORY_REPAIRS_FAILURE, payload: { serviceCallId: serviceCallId, error: error } });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de créer une requête pour une réparation en usine (${error}).`)
                    );
                });
        };
    },

    confirmFurnitureReception: (serviceCallId: number, factoryRepairsId: number): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.CONFIRM_FURNITURE_RECEPTION_REQUEST, payload: { serviceCallId: serviceCallId } });

            customerServiceService.confirmFurnitureReceptionForFactoryRepairs(serviceCallId, factoryRepairsId)
                .then(response => response.json() as ServiceCallFactoryRepair)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.CONFIRM_FURNITURE_RECEPTION_SUCCESS, payload: { serviceCallId: serviceCallId, factoryRepair: result } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.CONFIRM_FURNITURE_RECEPTION_FAILURE, payload: { serviceCallId: serviceCallId, error: error } });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de confirmer la réception du meuble (${error}).`)
                    );
                });

        };
    },

    scheduleFactoryRepairs: (serviceCallId: number, factoryRepairsId: number, scheduledFor: Date): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.SCHEDULE_FACTORY_REPAIRS_REQUEST, payload: { serviceCallId: serviceCallId } });

            customerServiceService.scheduleFactoryRepairs(serviceCallId, factoryRepairsId, scheduledFor)
                .then(response => response.json() as ServiceCallFactoryRepair)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.SCHEDULE_FACTORY_REPAIRS_SUCCESS, payload: { serviceCallId: serviceCallId, factoryRepair: result } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.SCHEDULE_FACTORY_REPAIRS_FAILURE, payload: { serviceCallId: serviceCallId, error: error } });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de cédulér la réparation en usine (${error}).`)
                    );
                });
        };
    },

    confirmFactoryRepairsCompletion: (serviceCallId: number, factoryRepairsId: number): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.CONFIRM_FACTORY_REPAIRS_COMPLETION_REQUEST, payload: { serviceCallId: serviceCallId } });

            customerServiceService.confirmFactoryRepairsCompletion(serviceCallId, factoryRepairsId)
                .then(response => response.json() as ServiceCallFactoryRepair)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.CONFIRM_FACTORY_REPAIRS_COMPLETION_SUCCESS, payload: { serviceCallId: serviceCallId, factoryRepair: result } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.CONFIRM_FACTORY_REPAIRS_COMPLETION_FAILURE, payload: { serviceCallId: serviceCallId, error: error } });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de compléter la réparation en usine (${error}).`)
                    );
                });

        };
    },

    createSubcontractorRepairsRequest: (serviceCallId: number, subcontractorId: number | undefined, otherSubcontractorName: string | undefined, email: string | undefined, locale: string | undefined): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.CREATE_SUBCONTRACTOR_REPAIRS_REQUEST, payload: { serviceCallId: serviceCallId } });

            customerServiceService.createSubcontractorRepairsRequest(serviceCallId, subcontractorId, otherSubcontractorName, email, locale)
                .then(response => response.json() as SubcontractorRepair)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.CREATE_SUBCONTRACTOR_REPAIRS_SUCCESS, payload: { serviceCallId: serviceCallId, subcontractorRepair: result } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.CREATE_SUBCONTRACTOR_REPAIRS_FAILURE, payload: { serviceCallId: serviceCallId, error: error } });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de créer une requête pour une réparation par un sous-traitant (${error}).`)
                    );
                });
        };
    },

    createTechnicianRepairsRequest: (serviceCallId: number, technicianId: string): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.CREATE_TECHNICIAN_REPAIRS_REQUEST, payload: { serviceCallId: serviceCallId } });

            customerServiceService.createTechnicianRepairsRequest(serviceCallId, technicianId)
                .then(response => response.json() as TechnicianRepair)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.CREATE_TECHNICIAN_REPAIRS_SUCCESS, payload: { serviceCallId: serviceCallId, technicianRepair: result } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.CREATE_TECHNICIAN_REPAIRS_FAILURE, payload: { serviceCallId: serviceCallId, error: error } });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de créer une requête pour une réparation par un technicien (${error}).`)
                    );
                });
        };
    },

    saveTechnicianAppointments: (serviceCallId: number, technicianRepairsId: number, appointments: TechnicianAppointment[]): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.CREATE_TECHNICIAN_APPOINTMENT_REQUEST, payload: serviceCallId });

            customerServiceService.saveTechnicianAppointments(serviceCallId, technicianRepairsId, appointments)
                .then(response => response.json() as TechnicianRepair[])
                .then(result => {
                    dispatch({ type: ActionTypeKeys.CREATE_TECHNICIAN_APPOINTMENT_SUCCESS, payload: { serviceCallId: serviceCallId, technicianRepairs: result } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.CREATE_TECHNICIAN_APPOINTMENT_FAILURE, payload: { serviceCallId: serviceCallId, error: error } });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de créer un rendez-vous pour une réparation par un technicien (${error}).`)
                    );
                });
        };
    },

    confirmTechnicianRepairsCompletion: (serviceCallId: number, technicianRepairsId: number): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.CONFIRM_TECHNICIAN_REPAIRS_COMPLETION_REQUEST });

            customerServiceService.confirmTechnicianRepairsCompletion(serviceCallId, technicianRepairsId)
                .then(response => response.json() as TechnicianRepair)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.CONFIRM_TECHNICIAN_REPAIRS_COMPLETION_SUCCESS, payload: { serviceCallId: serviceCallId, technicianRepair: result } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.CONFIRM_TECHNICIAN_REPAIRS_COMPLETION_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de confirmer la complétion des réparations du technicien (${error}).`)
                    );
                });
        };
    },

    cancelCurrentRepairs: (serviceCallId: number, repairType: RepairType): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.CANCEL_CURRENT_REPAIRS_REQUEST, payload: serviceCallId });

            customerServiceService.cancelCurrentRepairs(serviceCallId, repairType)
                .then(response => response.json())
                .then(_result => {
                    dispatch({ type: ActionTypeKeys.CANCEL_CURRENT_REPAIRS_SUCCESS, payload: { serviceCallId: serviceCallId } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.CANCEL_CURRENT_REPAIRS_FAILURE, payload: { serviceCallId, error } });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de canceller la réparation courante (${error}).`)
                    );
                });
        };
    },

    addDefect: (serviceCallId: number, defect: Defect): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.ADD_DEFECT_REQUEST });

            customerServiceService.addDefect(serviceCallId, defect.id)
                .then(response => response.json())
                .then(result => {
                    dispatch({ type: ActionTypeKeys.ADD_DEFECT_SUCCESS, payload: { serviceCallId, defect } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.ADD_DEFECT_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant d'ajouter la défectuosité à l'appel de service (${error}).`)
                    );
                });
        };
    },

    removeDefect: (serviceCallId: number, defect: Defect): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.DELETE_DEFECT_REQUEST });

            customerServiceService.removeDefect(serviceCallId, defect.id)
                .then(response => response.json())
                .then(result => {
                    dispatch({ type: ActionTypeKeys.DELETE_DEFECT_SUCCESS, payload: { serviceCallId, defect } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.DELETE_DEFECT_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant d'enlever la défectuosité de l'appel de service (${error}).`)
                    );
                });
        };
    },

    addDefectiveItem: (serviceCallId: number, itemCode: string, quantity?: number): AppThunk => {
        return (dispatch, _getState) => {
            if (StringHelper.hasValue(itemCode)) {
                dispatch({ type: ActionTypeKeys.ADD_DEFECTIVE_ITEM_REQUEST });

                customerServiceService.addDefectiveItem(serviceCallId, itemCode, quantity)
                    .then(response => response.json() as ServiceCallDefectiveItem)
                    .then(defectiveItem => {
                        dispatch({ type: ActionTypeKeys.ADD_DEFECTIVE_ITEM_SUCCESS, payload: { serviceCallId: serviceCallId, defectiveItem: defectiveItem } });
                    })
                    .catch(error => {
                        dispatch({ type: ActionTypeKeys.ADD_DEFECTIVE_ITEM_FAILURE, payload: error });
                        dispatch(globalNotification.actionCreators.showErrorNotification(
                            'Erreur',
                            `Une erreur est survenue en tentant d'ajouter une défectuosité à l'item '${itemCode}' pour l'appel de service (${error}).`)
                        );
                    });
            }
        };
    },

    changeDefectiveItems: (serviceCallId: number, defectiveItems: ServiceCallDefectiveItem[]): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.CHANGE_DEFECTIVE_ITEMS_REQUEST, payload: serviceCallId });

            customerServiceService.changeDefectiveItems(serviceCallId, defectiveItems)
                .then(response => response.json() as ServiceCallDefectiveItem[])
                .then(result => {
                    dispatch({ type: ActionTypeKeys.CHANGE_DEFECTIVE_ITEMS_SUCCESS, payload: { serviceCallId: serviceCallId, defectiveItems: result } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.CHANGE_DEFECTIVE_ITEMS_FAILURE, payload: { serviceCallId: serviceCallId, error: error } });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de mettre à jour les articles défectueux pour l'appel de service (${error}).`)
                    );
                });
        };
    },

    removeDefectiveItem: (serviceCallId: number, itemCode: string): AppThunk => {
        return (dispatch, _getState) => {
            if (StringHelper.hasValue(itemCode)) {
                dispatch({ type: ActionTypeKeys.DELETE_DEFECTIVE_ITEM_REQUEST });

                customerServiceService.removeDefectiveItem(serviceCallId, itemCode)
                    .then(response => response.json())
                    .then(result => {
                        dispatch({ type: ActionTypeKeys.DELETE_DEFECTIVE_ITEM_SUCCESS, payload: { serviceCallId: serviceCallId, itemId: itemCode } });
                    })
                    .catch(error => {
                        dispatch({ type: ActionTypeKeys.DELETE_DEFECTIVE_ITEM_FAILURE, payload: error });
                        dispatch(globalNotification.actionCreators.showErrorNotification(
                            'Erreur',
                            `Une erreur est survenue en tentant d'enlever une défectuosité à l'item '${itemCode}' pour l'appel de service (${error}).`)
                        );
                    });
            }
        };
    },

    updateRepairFeesEstimate: (serviceCallId: number, repairFeesEstimate: ServiceCallFeesEstimate): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.UPDATE_REPAIR_FEES_ESTIMATE_REQUEST });

            customerServiceService.updateEstimatedFees(serviceCallId, repairFeesEstimate)
                .then(response => response.json() as ServiceCallFeesEstimate)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.UPDATE_REPAIR_FEES_ESTIMATE_SUCCESS, payload: { serviceCallId: serviceCallId, repairFeesEstimate: result } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.UPDATE_REPAIR_FEES_ESTIMATE_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de mettre à jour les frais de réparation (${error}).`)
                    );
                });
        };
    },

    close: (serviceCallId: number, onClose: () => void): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.CLOSE_REQUEST });
            customerServiceService.close(serviceCallId)
                .then(response => response.json() as ServiceCall)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.CLOSE_SUCCESS, payload: result });
                })
                .then(onClose)
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.CLOSE_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de fermer l'appel de service (${error}).`)
                    );
                });
        };
    },

    reopen: (serviceCallId: number, onReopen: () => void): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.REOPEN_REQUEST });

            customerServiceService.reopen(serviceCallId)
                .then(response => response.json() as ServiceCall)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.REOPEN_SUCCESS, payload: result });
                })
                .then(onReopen)
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.REOPEN_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de rouvrir l'appel de service (${error}).`)
                    );
                });
        };
    },

    cancel: (serviceCallId: number, onCancel?: () => void): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.CANCEL_REQUEST });

            customerServiceService.cancel(serviceCallId)
                .then(response => response.json())
                .then(result => {
                    dispatch({ type: ActionTypeKeys.CANCEL_SUCCESS, payload: serviceCallId });

                    if (onCancel) {
                        onCancel();
                    }
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.CANCEL_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant d'annuler l'appel de service (${error}).`)
                    );
                });
        };
    },

    linkNew: (serviceCallId: number, onLinked?: (newServiceCall: ServiceCall) => void): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LINK_NEW_REQUEST });

            customerServiceService.linkNew(serviceCallId)
                .then(response => response.json() as ServiceCall)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.LINK_NEW_SUCCESS, payload: result });

                    if (onLinked) {
                        onLinked(result);
                    }
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LINK_NEW_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de lier un nouveal appel de service (${error}).`)
                    );
                });
        };
    },

    setSubcontractorAppointmentDate: (serviceCallId: number, subcontractorRepairId: number, appointmentDate: Date | undefined): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.SET_SUBCONTRACTOR_APPOINTMENT_DATE_REQUEST, payload: { serviceCallId: serviceCallId, repairId: subcontractorRepairId } });

            customerServiceService.setSubcontractorAppointmentDate(serviceCallId, subcontractorRepairId, appointmentDate)
                .then(response => response.json() as SubcontractorRepair)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.SET_SUBCONTRACTOR_APPOINTMENT_DATE_SUCCESS, payload: result });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.SET_SUBCONTRACTOR_APPOINTMENT_DATE_FAILURE, payload: { serviceCallId, error } });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de mettre à jour la date de rendez-vous du sous-traitant (${error}).`)
                    );
                });
        };
    },

    setSubcontractorRepairWarrantyStatus: (serviceCallId: number, subcontractorRepairId: number, warrantyStatus: SubcontractorRepairWarrantyStatus): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.SET_SUBCONTRACTOR_REPAIR_WARRANTY_STATUS_REQUEST, payload: { serviceCallId: serviceCallId, repairId: subcontractorRepairId } });

            customerServiceService.setSubcontractorRepairWarrantyStatus(serviceCallId, subcontractorRepairId, warrantyStatus)
                .then(response => response.json() as SubcontractorRepair)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.SET_SUBCONTRACTOR_REPAIR_WARRANTY_STATUS_SUCCESS, payload: result });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.SET_SUBCONTRACTOR_REPAIR_WARRANTY_STATUS_FAILURE, payload: { serviceCallId, error } });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de mettre à jour le statut de garantie de cette réparation (${error}).`)
                    );
                });
        };
    },

    addSubcontractorBill: (serviceCallId: number, subcontractorRepairId: number, repairFees: number, shippingFees: number, currencyCode: string, referenceNumber: string, invoice?: File): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.ADD_SUBCONTRACTOR_BILL_REQUEST });

            customerServiceService.addBill(serviceCallId, subcontractorRepairId, repairFees, shippingFees, currencyCode, referenceNumber)
                .then(response => response.json() as SubcontractorBill)
                .then(result => {
                    dispatch({ type: ActionTypeKeys.ADD_SUBCONTRACTOR_BILL_SUCCESS, payload: { serviceCallId: serviceCallId, subcontractorRepairId: subcontractorRepairId, bill: result } });

                    if (invoice != null) {
                        customerServiceService.addInvoice(serviceCallId, subcontractorRepairId, invoice)
                            .then(response => response.json())
                            .catch(error => {
                                dispatch(globalNotification.actionCreators.showErrorNotification(
                                    'Erreur',
                                    `Une erreur est survenue en tentant d'ajouter le document de facturation du sous-traitant (${error}).`)
                                );
                            });
                    }
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.ADD_SUBCONTRACTOR_BILL_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant d'ajouter la facture du sous-traitant (${error}).`)
                    );
                });
        };
    },
};