import moment from 'moment';
import React from 'react';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { Feed, List, Popup } from 'semantic-ui-react';

import { DateHelper, UserHelper } from '../../../helpers';
import { AppointmentTimeRange, InspectionUpdate } from '../../../state/models';

export interface InspectionScheduledActivityProps {
    activity: InspectionUpdate;
}

const m = defineMessages({
    summary: { id: 'InspectionScheduledActivity.summary', defaultMessage: 'A Jaymar representative scheduled a meeting to inspect the furniture.' },
    withTechnicianFormat: { id: 'InspectionScheduledActivity.scheduled_with', defaultMessage: 'With the representative {name}' },
    scheduledForFormat: { id: 'InspectionScheduledActivity.scheduled_for_format', defaultMessage: 'Scheduled for {date}, {time}' },
});

class InspectionScheduledActivity extends React.Component<InspectionScheduledActivityProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { activity } = this.props;
        const { inspection } = this.props.activity;

        return (
            <Feed.Event key={activity.id}>
                <Feed.Label icon="calendar" />
                <Feed.Content>
                    <Popup
                        trigger={<Feed.Date content={moment.utc(activity.createdOn).fromNow()} />}
                        content={moment.utc(activity.createdOn).format('LL')}
                    />

                    <Feed.Summary>
                        {formatMessage(m.summary)}
                    </Feed.Summary>

                    <Feed.Extra text={true}>
                        <List bulleted={true}>
                            {inspection && inspection.scheduledForDate &&
                                <List.Item
                                    content={formatMessage(m.scheduledForFormat, {
                                        date: moment.utc(inspection.scheduledForDate).format('LL'),
                                        time: formatMessage(DateHelper.getTimeRangeDescription(inspection.scheduledForTimeRange || AppointmentTimeRange.unspecified)).toLowerCase()
                                    })}
                                />
                            }
                            {inspection && <List.Item content={formatMessage(m.withTechnicianFormat, { name: UserHelper.getDisplayName(inspection.assignedTo) })} />}
                        </List>
                    </Feed.Extra>
                </Feed.Content>
            </Feed.Event>
        );
    }
}

const connectedComponent = injectIntl(InspectionScheduledActivity);
export { connectedComponent as InspectionScheduledActivity };