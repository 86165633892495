
import * as React from 'react';
import { Button, Dropdown, DropdownItemProps, ButtonProps } from 'semantic-ui-react';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';

export interface ButtonWithOptionsProps extends ButtonProps {
    options: DropdownItemProps[];
    color?: SemanticCOLORS;
}

class ButtonWithOptions extends React.Component<ButtonWithOptionsProps, {}> {
    public render() {
        const { options, color, ...buttonProps } = this.props;

        return (
            <Button.Group className="c-dropdown-button" color={color}>
                <Button {...buttonProps} />
                <Dropdown 
                    className="icon" 
                    floating={true} 
                    button={true} 
                    options={options} 
                />
            </Button.Group>
        );
    }
}

export { ButtonWithOptions };