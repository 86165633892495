import { FormProvider, useForm } from 'react-hook-form';
import { Form } from 'semantic-ui-react';
import { AppointementDateInputField } from './AppointementDateInputField';

export interface AppointmentDateFormValues {
    appointmentDate?: string | null;
}

interface AppointementDateFormProps {
    formId: string;
    initialFormValues: AppointmentDateFormValues | undefined;
    onSubmit: (formValues: AppointmentDateFormValues) => void;
}

export const AppointementDateForm = ({
    formId,
    initialFormValues,
    onSubmit,
}: AppointementDateFormProps) => {
    const formMethods = useForm<AppointmentDateFormValues>({
        defaultValues: initialFormValues,
    });

    const { handleSubmit } = formMethods;

    return (
        <FormProvider {...formMethods}>
            <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <AppointementDateInputField />
            </Form>
        </FormProvider>
    );
};
