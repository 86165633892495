import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Dropdown } from 'semantic-ui-react';

import { Notification } from '../../../../../state/models';
import { NotificationMenuTrigger } from './NotificationMenuTrigger';
import { useDispatch, useSelector } from 'react-redux';
import { getUnreadNotifications } from '../../../../../state/ducks/notifications/selectors';
import { actionCreators as NotificationsActions } from '../../../../../state/ducks/notifications/actions';
import { NotificationDropdownItem } from './NotificationDropdownItem';
import { useHistory } from 'react-router';

const m = defineMessages({
    notifications: { id: 'NotificationDropdown.notifications', defaultMessage: 'Notifications' },
    noNotifications: { id: 'NotificationDropdown.no_notifications', defaultMessage: 'No new notifications' },
    markAllAsRead: { id: 'NotificationDropdown.mark_all_as_read', defaultMessage: 'Mark all as read' },
    showAllNotifications: { id: 'NotificationDropdown.display_all', defaultMessage: 'Show all notifications' }
});

export const NotificationMenuItem: React.FC = () => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();

    const unreadNotifications = useSelector(getUnreadNotifications);

    const viewAllNotifications = () => history.push('/notifications');
    const markAllNotificationsAsRead = () => dispatch(NotificationsActions.readAll());
    const markNotificationAsRead = (notification: Notification) => {
        if (notification.referenceId != null) {
            history.push(`/service-calls/${notification.referenceId}/details`);
        }

        dispatch(NotificationsActions.read(notification));
    }

    return (
        <Dropdown item trigger={<NotificationMenuTrigger />}>
            <Dropdown.Menu style={{ width: 320 }}>
                <Dropdown.Header>
                    {formatMessage(m.notifications)}

                    {unreadNotifications.length > 0 &&
                        <a style={{ float: 'right', cursor: 'pointer' }} onClick={markAllNotificationsAsRead}>
                            {formatMessage(m.markAllAsRead)}
                        </a>
                    }
                </Dropdown.Header>
                <Dropdown.Divider />

                {unreadNotifications.map(notification => (
                    <NotificationDropdownItem 
                        key={notification.id}
                        notification={notification}
                        onRead={markNotificationAsRead}
                    />
                ))}

                {unreadNotifications.length <= 0 &&
                    <Dropdown.Item disabled text={formatMessage(m.noNotifications)} style={{ textAlign: 'center' }} />
                }

                <Dropdown.Divider style={{ marginBottom: 0 }} />
                <Dropdown.Item
                    as="a"
                    exact="true"
                    to="/notifications"
                    onClick={viewAllNotifications}
                    style={{ textAlign: 'center' }}
                >
                    <span style={{ textTransform: 'uppercase', fontSize: '11px', fontWeight: 'bold', color: '#4183c4' }}>
                        {formatMessage(m.showAllNotifications)}
                    </span>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};