import React, { useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { PageHeader } from "../../../../components/common";
import { ApplicationState } from "../../../../state/ducks";
import { getUserById, isUserLoading } from "../selectors";
import { actionCreators as UsersActions } from '../actions';
import { UserEdit } from "../models";
import { UserAccountDisabledWarning } from "./components/UserAccountDisabledWarning";
import { ClientForm } from "./components/ClientForm";
import { ClientEdit } from "../models/ClientEdit";
import { Client } from "../../../../state/models";
import * as api from '../api';

const m = defineMessages({
    title: { id: 'CreateClientPage.title', defaultMessage: 'Create a client profile' },
    subtitle: { id: 'CreateClientPage.subtitle', defaultMessage: 'Create a new client account to allow them to access the after-sales platform.' },
});

export const CreateClientPage: React.FC = (props) => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();

    const createClient = (user: ClientEdit) => {
        dispatch(UsersActions.createClientAccount(user));
        navigateToClientsPage();
    }

    const navigateToClientsPage = () => history.push(`/administration/clients`);

    return (
        <>
            <PageHeader iconName="user" title={formatMessage(m.title)} subtitle={formatMessage(m.subtitle)} />

            <ClientForm
                user={undefined}
                clientAccounts={[]}
                onSave={createClient}
                onCancel={navigateToClientsPage}
            />
        </>
    );
};