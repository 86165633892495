import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { DateFilter, initialDateFilter, DateFilterType, ClientsAdoptionFilters } from '../../state/models';
import { FilterPopup } from '../common';
import { FilterByDateSection } from './FilterByDateSection';

interface ClientsAdoptionFiltersPopupOwnProps {
    filters: ClientsAdoptionFilters;
    onApply: (filters: ClientsAdoptionFilters) => void;
}

interface ClientsAdoptionFiltersPopupState {
    appliedFilters: ClientsAdoptionFilters;
    pendingFilters: ClientsAdoptionFilters;
}

export type ClientsAdoptionFiltersPopupProps =
    & ClientsAdoptionFiltersPopupOwnProps
    & WrappedComponentProps;

class ClientsAdoptionFiltersPopup extends React.Component<ClientsAdoptionFiltersPopupProps, ClientsAdoptionFiltersPopupState> {
    public constructor(props: ClientsAdoptionFiltersPopupProps) {
        super(props);

        this.state = {
            appliedFilters: props.filters,
            pendingFilters: props.filters,
        };
    }

    public render() {
        const hasFiltersApplied = this.state.appliedFilters.dateFilter.type !== initialDateFilter.type;

        return (
            <FilterPopup hasFiltersApplied={hasFiltersApplied} onApply={this.applyFilters} onClose={this.resetPopupFilters}>
                <FilterByDateSection
                    filter={this.state.pendingFilters.dateFilter}
                    availableFilters={[DateFilterType.today, DateFilterType.thisWeek, DateFilterType.thisMonth, DateFilterType.thisYear, DateFilterType.custom]}
                    onChange={this.changeDateFilter}
                />
            </FilterPopup>
        );
    }

    private changeDateFilter = (filter: DateFilter) => {
        this.setState((current) => ({
            ...current,
            pendingFilters: {
                ...current.pendingFilters,
                dateFilter: filter
            }
        }));
    }

    private resetPopupFilters = () => {
        this.setState((current) => ({ pendingFilters: current.appliedFilters }));
    }

    private applyFilters = () => {
        this.setState((current) => ({ appliedFilters: current.pendingFilters }));
        this.props.onApply(this.state.pendingFilters);
    }
}

const connectedComponent = injectIntl(ClientsAdoptionFiltersPopup);
export { connectedComponent as ClientsAdoptionFiltersPopup };