import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { TechnicianRepair, AppointmentTimeRange, ServiceCallInspection, ServiceCall } from '../../../state/models';
import { CalendarEventLabel } from '../../calendar/CalendarEventLabel';
import { commonMessages, timeRangeMessages } from '../../../constants';
import { SemanticCOLORS, Popup, SemanticICONS } from 'semantic-ui-react';
import { AppointmentQuickOverview, InspectionQuickOverview } from '../../quick-overviews';

interface RepresentativeCalendarEventOwnProps {
    inspection: ServiceCallInspection;
    onClick: (inspection: ServiceCallInspection) => void;
}

export type RepresentativeCalendarEventProps =
    & RepresentativeCalendarEventOwnProps
    & WrappedComponentProps;

const m = defineMessages({
});

class RepresentativeCalendarEvent extends React.Component<RepresentativeCalendarEventProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { inspection } = this.props;
        const appointmentIcon: SemanticICONS | undefined = inspection.completedOn != null ? 'check' : undefined;

        const trigger = (
            <CalendarEventLabel
                color={this.getAppointmentColor(inspection)}
                icon={appointmentIcon}
                content={this.getAppointmentCity(inspection)}
                detail={this.getAppointmentTimeRange(inspection)}
                style={{ cursor: 'pointer' }}
            />
        );

        return (
            <Popup
                trigger={trigger}
                content={<InspectionQuickOverview inspection={this.props.inspection} onViewDetailsClick={this.props.onClick} />}
                on="click"
            />
        );
    }

    private getAppointmentTimeRange(inspection: ServiceCallInspection): string | undefined {
        const { formatMessage } = this.props.intl;

        if (inspection.scheduledForTimeRange === AppointmentTimeRange.morning) {
            return formatMessage(timeRangeMessages.am);
        } else if (inspection.scheduledForTimeRange === AppointmentTimeRange.afternoon) {
            return formatMessage(timeRangeMessages.pm);
        }

        return undefined;
    }

    private getAppointmentCity(inspection: ServiceCallInspection): string {
        const { formatMessage } = this.props.intl;

        return inspection.serviceCall && inspection.serviceCall.customer && inspection.serviceCall.customer.address && inspection.serviceCall.customer.address.city
            ? inspection.serviceCall.customer.address.city
            : formatMessage(commonMessages.unknown);
    }

    private getAppointmentColor(inspection: ServiceCallInspection): SemanticCOLORS | undefined {
        if (inspection.completedOn != null) {
            return 'green';
        } else if (inspection.scheduledForTimeRange === AppointmentTimeRange.morning) {
            return 'orange';
        } else if (inspection.scheduledForTimeRange === AppointmentTimeRange.afternoon) {
            return 'violet';
        }

        return undefined;
    }
}

const connectedComponent = injectIntl(RepresentativeCalendarEvent);
export { connectedComponent as RepresentativeCalendarEvent };