import React, {  } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Card } from 'semantic-ui-react';

import { ServiceCallInspection } from '../../../../state/models';
import { LoadingContainer, LoadingDimmer } from '../../../common';
import { InspectionReportForm } from './InspectionReportForm';
import { InspectionReportEdit } from './models';

const m = defineMessages({
    title: { id: 'InspectionReportCard.title', defaultMessage: 'Inspection report' },
    subtitle: { id: 'InspectionReportCard.subtitle', defaultMessage: 'Fill the inspection report and mark it as complete to send it to the customer service agent.' },
    incompleteReport: { id: 'InspectionReportCard.incomplete_report', defaultMessage: 'The inspection report is incomplete' },
    completeInspectionButton: { id: 'InspectionReportCard.complete_inspection_button', defaultMessage: 'Complete the inspection' },
    completeInspectionAndCloseServiceCallButton: { id: 'InspectionReportCard.complete_inspection_and_close_button', defaultMessage: 'Close the service call' },
    closeServiceCallButton: { id: 'InspectionReportCard.close_service_call_button', defaultMessage: 'Close the service call' },
    confirmClosureHeader: { id: 'InspectionReportCard.confirm_closure_header', defaultMessage: 'Confirm the service call closure' },
    confirmClosureContent: { id: 'InspectionReportCard.confirm_closure_content', defaultMessage: 'Are you sure you want to close the service call?' },
    confirmClosureButton: { id: 'InspectionReportCard.confirm_closure_button', defaultMessage: 'Yes, close it' },
});

interface OwnProps {
    inspection: ServiceCallInspection;
    isLoading: boolean;

    onSave: (inspectionId: number, data: InspectionReportEdit) => void;
    onComplete: (inspectionId: number) => void;
    onCompleteAndClose: (inspectionId: number) => void;
}

export type InspectionReportCardProps =
    & OwnProps;

export const InspectionReportCard: React.FC<InspectionReportCardProps> = (props) => {
    const { formatMessage } = useIntl();

    return (
        <LoadingContainer>
            <LoadingDimmer active={props.isLoading} />
            <Card fluid={true} color="teal" style={{ height: '100%' }}>
                <Card.Content style={{ flexGrow: 0 }}>
                    <Card.Header content={formatMessage(m.title)} />
                    <Card.Meta content={formatMessage(m.subtitle)} />
                </Card.Content>
                <Card.Content>
                    <InspectionReportForm
                        inspection={props.inspection}
                        onSave={props.onSave}
                        onComplete={props.onComplete}
                        onCompleteAndClose={props.onCompleteAndClose}
                    />
                </Card.Content>
            </Card>
        </LoadingContainer>
    );
};