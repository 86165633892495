import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Button, Icon, Input, List } from 'semantic-ui-react';
import { ProductionComponent, Quantity } from '../../../state/models';
import { ItemTypeIcon } from '../../items';
import { NumericInput, SmallDeleteButton } from '../../common';

export interface SelectedProductionComponentProps {
    productionComponent: ProductionComponent;
    quantity: Quantity;
    isReadOnly?: boolean;
    onQuantityChanged: (newValue: number) => void;
    onDeselect?: () => void;
}

class SelectedProductionComponent extends React.Component<SelectedProductionComponentProps & WrappedComponentProps, {}> {
    public render() {
        const { productionComponent } = this.props;
        
        return (
            <List.Item key={productionComponent.id} className="bom-item" style={{padding: 7, marginBottom: 3}}>
                <List.Content floated="right">
                    {this.props.onDeselect && 
                        <SmallDeleteButton 
                            basic={false} 
                            disabled={this.props.isReadOnly}
                            size="mini" 
                            style={{marginRight: 10}}
                            onClick={this.props.onDeselect}
                        />
                    }
                    <NumericInput
                        value={this.props.quantity.value}
                        unit={this.props.quantity.unit}
                        disabled={this.props.isReadOnly}
                        onChange={this.props.onQuantityChanged}
                    />
                </List.Content>
                <List.Content>
                    <div style={{marginTop: 7}}>
                        <ItemTypeIcon itemType={productionComponent.item.type} style={{marginRight: 5}} />
                        <small className="meta" style={{marginRight: 5}}>
                            {productionComponent.item.id}
                        </small>
                        {productionComponent.item.frenchDescription}
                    </div>
                </List.Content>
            </List.Item>
        );
    }
}

const intlComponent = injectIntl(SelectedProductionComponent);
export { intlComponent as SelectedProductionComponent };