import * as React from 'react';
import { Input, InputProps, Label, Dropdown, InputOnChangeData, DropdownProps } from 'semantic-ui-react';

export interface CurrencyInputProps {
    value: number;
    disabled?: boolean;
    currency?: string;
    triggerEventOnKeyStroke?: boolean;
    onChange?: (value: number, currency: string ) => void;
}

const options = [
    { key: 'CAD', text: 'CAD', value: 'CAD' },
    { key: 'USD', text: 'USD', value: 'USD' },
    { key: 'EUR', text: 'EUR', value: 'EUR' },
  ];  

interface CurrencyInputState {
    rawValue: string;
    currency: string;
}

class CurrencyInput extends React.Component<CurrencyInputProps, CurrencyInputState> {
    public constructor(props: CurrencyInputProps) {
        super(props);

        this.state = {
            rawValue: String(this.props.value),
            currency: props.currency || 'CAD'
        };
    }

    public render() {
        return (
            <Input 
                labelPosition="right" 
                type="text" 
                pattern="\d*\.?\d*"
                disabled={this.props.disabled}
                onBlur={this.confirmValueChange}
                onChange={this.handleValueChange}
                value={this.state.rawValue}
            >
                <Label basic={true}>$</Label>
                <input />
                <Label style={{padding: 0}}>
                    <Dropdown 
                        value={this.state.currency} 
                        options={options}
                        disabled={this.props.disabled}
                        style={{padding: 11}}
                        onChange={this.handleCurrencyChange}
                    />
                </Label>
            </Input>
        );
    }

    private handleValueChange = (event: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) => {
        this.setState({ rawValue: data.value });

        if (this.props.triggerEventOnKeyStroke) {
            if (!data.value.endsWith('.') && !data.value.endsWith('.0')) {
                const newValue = Number(data.value);
    
                if (!isNaN(newValue) && this.props.onChange) {
                    this.props.onChange(newValue, this.state.currency);
                }
            }
        }
    }

    private confirmValueChange = () => {
        const newValue = Number(this.state.rawValue);

        if (!isNaN(newValue) && this.props.onChange) {
            this.props.onChange(newValue, this.state.currency);
        } else {
            this.setState({ rawValue: String(this.props.value) });
        }
    }

    private handleCurrencyChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        this.setState({ currency: data.value as string });

        if (this.props.onChange) {
            this.props.onChange(this.props.value, data.value as string);
        }
    }
}

export { CurrencyInput };