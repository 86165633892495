import { ApplicationState } from '..';
import { Notification } from '../../models';

export function getAllNotifications(state: ApplicationState): Notification[] {
    return state.notifications.allIds.map(x => state.notifications.byId[x]);
}

export function getUnreadNotifications(state: ApplicationState): Notification[] {
    return state.notifications.unread.map(x => state.notifications.byId[x]);
}
