import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { actionCreators as InspectionsActions } from '../../../state/ducks/inspections';

import { PageHeader } from '../../../components/common';
import { ServiceCallInspection } from '../../../state/models';
import { getCompletedInspections, areCompletedInspectionsLoading } from '../../../state/ducks/inspections/selectors';
import { InspectionTable } from '../../../components/service-calls/inspection';
import { useResponsive } from '../../../utils/responsive';

const m = defineMessages({
    title: { id: 'RepresentativeHistoryPage.title', defaultMessage: 'History' },
    subtitle: { id: 'RepresentativeHistoryPage.subtitle', defaultMessage: 'View your completed service call inspections.' },
    noInspectionsTitle: { id: 'RepresentativeHistoryPage.no_inspections_title', defaultMessage: 'No prior inspections' },
    noInspectionsSubtitle: { id: 'RepresentativeHistoryPage.no_inspections_subtitle', defaultMessage: 'There are no completed inspections.' },
});

export const RepresentativeHistoryPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const { isMobile } = useResponsive();
    const dispatch = useDispatch();
    const history = useHistory();

    const inspections = useSelector(getCompletedInspections);
    const isLoading = useSelector(areCompletedInspectionsLoading);

    const navigateToInspectionPage = (inspection: ServiceCallInspection) => 
        history.push(`/service-calls/${inspection.serviceCallId}/inspections/${inspection.id}/inspect`);

    useEffect(() => {
        dispatch(InspectionsActions.loadCompleted());
    }, [dispatch]);

    const commonProps = {
        inspections: inspections,
        isLoading: isLoading,
        emptyTitle: formatMessage(m.noInspectionsTitle),
        emptySubtitle: formatMessage(m.noInspectionsSubtitle),
        isScheduledForHidden: true,
        onViewDetails: navigateToInspectionPage
    };

    return (
        <>
            <PageHeader
                iconName="history"
                title={formatMessage(m.title)}
                subtitle={formatMessage(m.subtitle)}
            />

            {isMobile && <InspectionTable isViewButtonHidden={true} {...commonProps} />}
            {!isMobile && <InspectionTable {...commonProps} />}
        </>
    );
};