import { Reducer } from 'redux';
import { ActionTypes } from './actions';
import { ActionTypeKeys } from './types';
import { NotificationType, Defect, TechnicianRepair } from '../../models';

export interface AppointmentsState {
    allAppointments: {
        [startDate: string]: TechniciansAppointmentsState;
    };

    myAppointments: {
        [startDate: string]: TechniciansAppointmentsState;
    };

    appointmentsById: {
        [id: number]: TechnicianAppointmentState;
    };

    appointmentsByServiceCall: {
        [id: number]: TechniciansAppointmentsState;
    };

    allMyAppointments: TechniciansAppointmentsState;
    pendingAppointments: TechniciansAppointmentsState;
    completedAppointments: TechniciansAppointmentsState;
    todaysAppointments: TechniciansAppointmentsState;
    thisWeeksAppointments: TechniciansAppointmentsState;

    calendarStartDate: Date;
}

export interface TechniciansAppointmentsState {
    appointments: TechnicianRepair[];
    isLoading: boolean;
}

export interface TechnicianAppointmentState {
    appointment: TechnicianRepair;
    isLoading: boolean;
}

const initialState: AppointmentsState = {
    allAppointments: {},
    myAppointments: {},
    appointmentsById: {},
    appointmentsByServiceCall: {},
    allMyAppointments: {
        appointments: [],
        isLoading: false
    },
    pendingAppointments: {
        appointments: [],
        isLoading: false
    },
    completedAppointments: {
        appointments: [],
        isLoading: false
    },
    todaysAppointments: {
        appointments: [],
        isLoading: false
    },
    thisWeeksAppointments: {
        appointments: [],
        isLoading: false
    },
    calendarStartDate: new Date()
};

export const defectReducer: Reducer<AppointmentsState> = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case ActionTypeKeys.LOAD_MINE_REQUEST:
            return {
                ...state,
                myAppointments: {
                    ...state.myAppointments,
                    [action.payload.toISOString()]: {
                        ...state.myAppointments[action.payload.toISOString()],
                        isLoading: true
                    }
                }
            };

        case ActionTypeKeys.LOAD_MINE_SUCCESS:
            return {
                ...state,
                myAppointments: {
                    ...state.myAppointments,
                    [action.payload.startDate.toISOString()]: {
                        ...state.myAppointments[action.payload.startDate.toISOString()],
                        appointments: action.payload.results,
                        isLoading: false,
                    }
                }
            };

        case ActionTypeKeys.LOAD_MINE_FAILURE:
            return {
                ...state,
                myAppointments: {
                    ...state.myAppointments,
                    [action.payload.startDate.toISOString()]: {
                        ...state.myAppointments[action.payload.startDate.toISOString()],
                        isLoading: false
                    }
                }
            };

        case ActionTypeKeys.LOAD_BY_ID_REQUEST:
            return {
                ...state,
                appointmentsById: {
                    ...state.appointmentsById,
                    [action.payload]: {
                        ...state.appointmentsById[action.payload],
                        isLoading: true
                    }
                }
            };

        case ActionTypeKeys.LOAD_BY_ID_SUCCESS:
            return {
                ...state,
                appointmentsById: {
                    ...state.appointmentsById,
                    [action.payload.id]: {
                        ...state.appointmentsById[action.payload.id],
                        appointment: action.payload.result,
                        isLoading: false
                    }
                }
            };

        case ActionTypeKeys.LOAD_BY_ID_FAILURE:
            return {
                ...state,
                appointmentsById: {
                    ...state.appointmentsById,
                    [action.payload.id]: {
                        ...state.appointmentsById[action.payload.id],
                        isLoading: false
                    }
                }
            };

        case ActionTypeKeys.LOAD_BY_SERVICE_CALL_REQUEST:
            return {
                ...state,
                appointmentsByServiceCall: {
                    ...state.appointmentsByServiceCall,
                    [action.payload]: {
                        ...state.appointmentsByServiceCall[action.payload],
                        isLoading: true
                    }
                }
            };

        case ActionTypeKeys.LOAD_BY_SERVICE_CALL_SUCCESS:
            return {
                ...state,
                appointmentsByServiceCall: {
                    ...state.appointmentsByServiceCall,
                    [action.payload.id]: {
                        ...state.appointmentsByServiceCall[action.payload.id],
                        appointments: action.payload.result,
                        isLoading: false
                    }
                }
            };

        case ActionTypeKeys.LOAD_BY_SERVICE_CALL_FAILURE:
            return {
                ...state,
                appointmentsByServiceCall: {
                    ...state.appointmentsByServiceCall,
                    [action.payload.id]: {
                        ...state.appointmentsByServiceCall[action.payload.id],
                        isLoading: false
                    }
                }
            };

        case ActionTypeKeys.LOAD_ALL_MINE_REQUEST:
            return {
                ...state,
                allMyAppointments: {
                    ...state.allMyAppointments,
                    isLoading: true
                }
            };

        case ActionTypeKeys.LOAD_ALL_MINE_SUCCESS:
            return {
                ...state,
                allMyAppointments: {
                    ...state.allMyAppointments,
                    appointments: action.payload,
                    isLoading: false
                }
            };

        case ActionTypeKeys.LOAD_ALL_MINE_FAILURE:
            return {
                ...state,
                allMyAppointments: {
                    ...state.allMyAppointments,
                    appointments: [],
                    isLoading: false
                }
            };

        case ActionTypeKeys.LOAD_PENDING_REQUEST:
            return {
                ...state,
                pendingAppointments: {
                    ...state.pendingAppointments,
                    isLoading: true
                }
            };

        case ActionTypeKeys.LOAD_PENDING_SUCCESS:
            return {
                ...state,
                pendingAppointments: {
                    ...state.pendingAppointments,
                    appointments: action.payload,
                    isLoading: false
                }
            };

        case ActionTypeKeys.LOAD_PENDING_FAILURE:
            return {
                ...state,
                pendingAppointments: {
                    ...state.pendingAppointments,
                    appointments: [],
                    isLoading: false
                }
            };

        case ActionTypeKeys.LOAD_TODAY_REQUEST:
            return {
                ...state,
                todaysAppointments: {
                    ...state.todaysAppointments,
                    isLoading: true
                }
            };

        case ActionTypeKeys.LOAD_TODAY_SUCCESS:
            return {
                ...state,
                todaysAppointments: {
                    ...state.todaysAppointments,
                    appointments: action.payload,
                    isLoading: false
                }
            };

        case ActionTypeKeys.LOAD_TODAY_FAILURE:
            return {
                ...state,
                todaysAppointments: {
                    ...state.todaysAppointments,
                    appointments: [],
                    isLoading: false
                }
            };

        case ActionTypeKeys.LOAD_THIS_WEEK_REQUEST:
            return {
                ...state,
                thisWeeksAppointments: {
                    ...state.thisWeeksAppointments,
                    isLoading: true
                }
            };

        case ActionTypeKeys.LOAD_THIS_WEEK_SUCCESS:
            return {
                ...state,
                thisWeeksAppointments: {
                    ...state.thisWeeksAppointments,
                    appointments: action.payload,
                    isLoading: false
                }
            };

        case ActionTypeKeys.LOAD_THIS_WEEK_FAILURE:
            return {
                ...state,
                thisWeeksAppointments: {
                    ...state.thisWeeksAppointments,
                    appointments: [],
                    isLoading: false
                }
            };

        case ActionTypeKeys.LOAD_COMPLETED_REQUEST:
            return {
                ...state,
                completedAppointments: {
                    ...state.completedAppointments,
                    isLoading: true
                }
            };

        case ActionTypeKeys.LOAD_COMPLETED_SUCCESS:
            return {
                ...state,
                completedAppointments: {
                    ...state.completedAppointments,
                    appointments: action.payload,
                    isLoading: false
                }
            };

        case ActionTypeKeys.LOAD_COMPLETED_FAILURE:
            return {
                ...state,
                completedAppointments: {
                    appointments: [],
                    isLoading: false
                }
            };

        case ActionTypeKeys.LOAD_FOR_TECHNICIANS_REQUEST:
            return {
                ...state,
                allAppointments: {
                    ...state.allAppointments,
                    [action.payload.toISOString()]: {
                        ...state.allAppointments[action.payload.toISOString()],
                        isLoading: true
                    }
                }
            };

        case ActionTypeKeys.LOAD_FOR_TECHNICIANS_SUCCESS:
            return {
                ...state,
                allAppointments: {
                    ...state.allAppointments,
                    [action.payload.startDate.toISOString()]: {
                        ...state.allAppointments[action.payload.startDate.toISOString()],
                        appointments: action.payload.results,
                        isLoading: false,
                    }
                }
            };

        case ActionTypeKeys.LOAD_FOR_TECHNICIANS_FAILURE:
            return {
                ...state,
                allAppointments: {
                    ...state.allAppointments,
                    [action.payload.startDate.toISOString()]: {
                        ...state.allAppointments[action.payload.startDate.toISOString()],
                        isLoading: false
                    }
                }
            };

        case ActionTypeKeys.SET_CALENDAR_START_DATE:
            return {
                ...state,
                calendarStartDate: action.payload
            };

        default:
            return state;
    }
};

export default defectReducer;