import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Form } from 'semantic-ui-react';
import { CurrencyInput } from '../../../../../components/common';
import { SubContractorBillFormValues } from '../SubContractorBillSection/SubcontractorBillModal';

export const ShippingFeesInputField = () => {
    const { formatMessage } = useIntl();
    const { control, watch, setValue } = useFormContext<SubContractorBillFormValues>();
    const [isEnabled, currencyCode] = watch(['isEnabled', 'currencyCode']);

    return (
        <Controller
            control={control}
            name="shippingFees"
            render={({
                field: { value, onChange }
            }) => (
                <Form.Field disabled={!isEnabled}>
                    <label>
                        {formatMessage({
                            id: 'SubcontractorRepairPage.BillForm.ShippingFees',
                            defaultMessage: 'Shipping fees',
                        })}
                    </label>
                    <CurrencyInput
                        value={value}
                        currency={currencyCode}
                        onChange={(value, currencyCode) => {
                            onChange(value);
                            setValue('currencyCode', currencyCode);
                        }}
                    />
                </Form.Field>
            )}
        />
    );
};
