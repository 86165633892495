export interface Defect {
    id: number;
    defectCategory: DefectCategory;
    defectType: DefectType;
    title: string;
    partsWarranty: WarrantyType;
    labourWarranty: WarrantyType;
}

export interface DefectCategory {
    id: number;
    title: string;
}

export interface DefectType {
    id: number;
    title: string;
}

export enum WarrantyType {
    None,
    OneYear,
    TwoYears,
    ThreeYears,
    FourYears,
    FiveYears,
    LifetimeLimited,
    SpecifiedByLaw,
    NotApplicable
}

export enum WarrantyStatus {
    None,
    Expired,
    UnderWarranty
}