import { ItemType, ItemStatus, ItemAcquisitionType, ProductModel, ProductType } from '.';

export interface Item {
    id: string;
    status: ItemStatus;
    type: ItemType;
    acquisitionType: ItemAcquisitionType;
    frenchDescription: string;
    englishDescription: string;
    productTypeId: string;
    productType: ProductType;
    productModelId: string;
    productModel: ProductModel;
    unitOfMeasurement: string;
}

export function getItemLocalizedDescription(item: Item, locale: string) {
    if (item == null) {
        return '';
    }

    if (locale === 'en') {
        return item.englishDescription;
    }

    return item.frenchDescription;
}

export function formatItemId(itemId: string): string {
    if (itemId && itemId.length > 3) {
        const productId = itemId.slice(0, itemId.length - 3);
        const typeId = itemId.slice(itemId.length - 3, itemId.length);
        return `${productId}-${typeId}`;
    }

    return itemId;
}