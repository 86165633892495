import moment from 'moment';
import React from 'react';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { Grid } from 'semantic-ui-react';

import { InformationMessage } from '../../../components/common';
import { CustomerInformationCard } from '../../../components/service-calls';
import { InspectionAppointmentCard } from '../../../components/service-calls/inspection';
import { ApplicationState } from '../../../state/ducks';
import * as CalendarActions from '../../../state/ducks/calendar';
import { getBreaksBetween } from '../../../state/ducks/calendar/selectors';
import * as InspectionsActions from '../../../state/ducks/inspections';
import {
    areInspectionsForCalendarDateLoading,
    getInspectionById,
    getInspectionsForCalendarDate,
    isInspectionLoading,
} from '../../../state/ducks/inspections/selectors';
import { getServiceCallState } from '../../../state/ducks/service-calls';
import * as UiActions from '../../../state/ducks/ui';
import { AppointmentTimeRange, CalendarBreak, ServiceCall, ServiceCallInspection } from '../../../state/models';

interface InspectionScheduleStepActions {
    calendarActions: typeof CalendarActions.actionCreators;
    inspectionsActions: typeof InspectionsActions.actionCreators;
    uiActions: typeof UiActions.actionCreators;
}

interface InspectionScheduleStepOwnProps {
    serviceCall: ServiceCall | undefined;
    isServiceCallLoading: boolean;
    inspection: ServiceCallInspection;
    isInspectionLoading: boolean;
    calendarInspections: ServiceCallInspection[];
    lockedDates: CalendarBreak[];
    areCalendarInspectionsLoading: boolean;
    calendarStartDate: Date;
}

export type InspectionScheduleStepProps =
    & InspectionScheduleStepOwnProps
    & InspectionScheduleStepActions
    & RouteComponentProps<{ serviceCallId: string, inspectionId: string }>;

type IntlInspectionScheduleStepProps = InspectionScheduleStepProps & WrappedComponentProps;

const m = defineMessages({
    readOnlyTitle: { id: 'InspectionScheduleStep.read_only_title', defaultMessage: 'Inspection completed' },
    readOnlyContent: { id: 'InspectionScheduleStep.read_only_content', defaultMessage: 'This inspection cannot be rescheduled because it has already been marked as completed.' }
});

class InspectionScheduleStep extends React.Component<IntlInspectionScheduleStepProps, {}> {
    public componentDidMount() {
        this.props.uiActions.changeInspectionStep('schedule');

        const calendarStartDate = moment(this.props.calendarStartDate).startOf('month').startOf('week').startOf('day').toDate();
        const calendarEndDate = moment(this.props.calendarStartDate).endOf('month').endOf('week').startOf('day').toDate();
        this.props.calendarActions.loadLocks(calendarStartDate, calendarEndDate);
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const isComplete = this.props.inspection && this.props.inspection.completedOn != null;

        return (
            <React.Fragment>
                {isComplete &&
                    <InformationMessage
                        header={formatMessage(m.readOnlyTitle)}
                        content={formatMessage(m.readOnlyContent)}
                    />
                }
                <Grid columns="equal" stackable={true}>
                    <Grid.Row>
                        <Grid.Column>
                            <CustomerInformationCard
                                customer={this.props.serviceCall ? this.props.serviceCall.customer : undefined}
                                isLoading={this.props.isServiceCallLoading}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <InspectionAppointmentCard
                                inspection={this.props.inspection}
                                isLoading={this.props.isInspectionLoading}
                                isReadOnly={isComplete}
                                calendarInspections={this.props.calendarInspections}
                                calendarBreaks={this.props.lockedDates.filter(x => x.forUserId == null)}
                                areCalendarInspectionsLoading={this.props.areCalendarInspectionsLoading}
                                onChangeAppointmentDate={this.changeAppointmentDate}
                                onChangeAppointmentTime={this.changeAppointmentTime}
                                onChangeCalendarDate={this.changeCalendarStartDate}
                                onInspectionClicked={this.navigateToInspection}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </React.Fragment>
        );
    }

    private changeAppointmentDate = (date?: Date) => {
        const { inspectionId } = this.props.match.params;
        this.props.inspectionsActions.schedule(Number(inspectionId), date, this.props.inspection.scheduledForTimeRange);
    }

    private changeAppointmentTime = (timeRange?: AppointmentTimeRange) => {
        const { inspectionId } = this.props.match.params;
        this.props.inspectionsActions.schedule(
            Number(inspectionId),
            this.props.inspection.scheduledForDate,
            timeRange);
    }

    private changeCalendarStartDate = (date: Date) => {
        const startDate = moment(date).startOf('month').startOf('week').startOf('day').toDate();
        const endDate = moment(date).endOf('month').endOf('week').startOf('day').toDate();

        this.props.inspectionsActions.setCalendarStartDate(date);
        this.props.inspectionsActions.loadBetween(startDate, endDate);
        this.props.calendarActions.loadLocks(startDate, endDate);
    }

    private navigateToInspection = (inspection: ServiceCallInspection) => {
        this.props.history.push(`/service-calls/${inspection.serviceCallId}/inspections/${inspection.id}/review`);
    }
}

const mapStateToProps = (state: ApplicationState, props: InspectionScheduleStepProps): InspectionScheduleStepOwnProps => {
    const serviceCallId = Number(props.match.params.serviceCallId);
    const inspectionId = Number(props.match.params.inspectionId);
    const serviceCallState = getServiceCallState(state, serviceCallId);
    const calendarStartDate = moment(state.inspections.calendarStartDate).startOf('month').startOf('week').startOf('day').toDate();
    const calendarEndDate = moment(state.inspections.calendarStartDate).endOf('month').endOf('week').startOf('day').toDate();

    return {
        serviceCall: serviceCallState ? serviceCallState.details : undefined,
        isServiceCallLoading: serviceCallState ? serviceCallState.isLoading : false,
        inspection: getInspectionById(state, inspectionId),
        isInspectionLoading: isInspectionLoading(state, inspectionId),
        calendarInspections: getInspectionsForCalendarDate(state, calendarStartDate),
        lockedDates: getBreaksBetween(state, calendarStartDate, calendarEndDate),
        areCalendarInspectionsLoading: areInspectionsForCalendarDateLoading(state, calendarStartDate),
        calendarStartDate: state.inspections.calendarStartDate
    };
};

const mapDispatchToProps = (dispatch: Dispatch): InspectionScheduleStepActions => {
    return {
        calendarActions: bindActionCreators(CalendarActions.actionCreators, dispatch),
        inspectionsActions: bindActionCreators(InspectionsActions.actionCreators, dispatch),
        uiActions: bindActionCreators(UiActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(InspectionScheduleStep);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as InspectionScheduleStep };