import _ from "lodash";
import React, { useMemo, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Table } from "semantic-ui-react";
import { User } from "../../../../../../state/models";
import { ClientsTableRow } from "./ClientsTableRow";

interface OwnProps {
    users: User[];
    loadingUserIds?: string[];
    onClick: (user: User) => void;
}

export type ClientsTableProps =
    & OwnProps;

const m = defineMessages({
    displayNameHeader: { id: 'ClientsTable.displayNameHeader', defaultMessage: 'User' },
    emailHeader: { id: 'ClientsTable.emailHeader', defaultMessage: 'Email' },
    clientCodeHeader: { id: 'ClientsTable.clientCodeHeader', defaultMessage: 'Client code' },
    linkedAccountsHeader: { id: 'ClientsTable.linkedAccountsHeader', defaultMessage: 'Linked accounts' },
    statusHeader: { id: 'ClientsTable.statusHeader', defaultMessage: 'Status' },
})

type SortDirection = 'ascending' | 'descending';

export const ClientsTable: React.FC<ClientsTableProps> = (props) => {
    const { formatMessage } = useIntl();
    const [sortBy, setSortBy] = useState<(keyof User)>('firstName');
    const [sortDirection, setSortDirection] = useState<SortDirection>('ascending');

    const displayedUsers = useMemo<User[]>(() => {
        return sortDirection === 'ascending' 
            ? _.sortBy(props.users, [sortBy])
            : _.sortBy(props.users, [sortBy]).reverse();

    }, [props.users, sortBy, sortDirection]);

    const getSortDirection = (column: keyof User): SortDirection | undefined => sortBy === column ? sortDirection : undefined;

    const sortColumn = (column: keyof User) => {
        if (sortBy !== column) {
            setSortBy(column);
            setSortDirection('ascending');
        } else {
            setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
        }
    }

    return (
        <>
            <Table sortable selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell sorted={getSortDirection('firstName')} onClick={() => sortColumn('firstName')} content={formatMessage(m.displayNameHeader)} />
                        <Table.HeaderCell sorted={getSortDirection('email')} onClick={() => sortColumn('email')} content={formatMessage(m.emailHeader)} />
                        <Table.HeaderCell content={formatMessage(m.clientCodeHeader)} />
                        <Table.HeaderCell content={formatMessage(m.linkedAccountsHeader)} />
                        <Table.HeaderCell collapsing sorted={getSortDirection('isDisabled')} onClick={() => sortColumn('isDisabled')} content={formatMessage(m.statusHeader)} />
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {displayedUsers.map(user => (
                        <ClientsTableRow 
                            key={user.id} 
                            user={user} 
                            isLoading={props.loadingUserIds?.find(id => id === user.id) != null}
                            onClick={() => props.onClick(user)}
                        />
                    ))}
                </Table.Body>
            </Table>
        </>
    );
};