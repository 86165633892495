import { ApplicationState } from '..';
import { FactoryReport, ServiceCallFactoryRepair } from '../../models';
import { FactoryRepairsLoadingState } from './reducers';

export function getFactoryRepairById(state: ApplicationState, repairId: number): ServiceCallFactoryRepair {
    return state.factoryRepairs.repairsById[repairId];
}

export function getPendingFactoryRepairs(state: ApplicationState): ServiceCallFactoryRepair[] {
    return !arePendingFactoryRepairsLoading(state)
        ? state.factoryRepairs.pendingRepairs || []
        : [];
}

export function getFactoryRepairsByServiceCall(state: ApplicationState, serviceCallId: number): ServiceCallFactoryRepair[] {
    const repairsState = state.factoryRepairs.repairsByServiceCall[serviceCallId];
    return repairsState?.repairs || [];
}

export function areFactoryRepairsByServiceCallLoading(state: ApplicationState, serviceCallId: number): boolean {
    const repairsState = state.factoryRepairs.repairsByServiceCall[serviceCallId];
    return repairsState?.isLoading;
}

export function getFactoryRepairsByStartDate(state: ApplicationState, startDate: Date): FactoryRepairsLoadingState {
    const emptyRepairs = {
        isLoading: false,
        repairs: []
    };

    return state.factoryRepairs.repairsByStartDate[startDate.toISOString()] || emptyRepairs;
}

export function arePendingFactoryRepairsLoading(state: ApplicationState): boolean {
    return state.factoryRepairs.arePendingRepairsLoading;
}

export function getCompletedFactoryRepairs(state: ApplicationState): ServiceCallFactoryRepair[] {
    return !areCompletedFactoryRepairsLoading(state)
        ? state.factoryRepairs.completedRepairs || []
        : [];
}

export function getRepairsForToday(state: ApplicationState): FactoryRepairsLoadingState {
    return state.factoryRepairs.todaysRepairs;
}

export function getRepairsForThisWeek(state: ApplicationState): FactoryRepairsLoadingState {
    return state.factoryRepairs.thisWeeksRepairs;
}

export function areCompletedFactoryRepairsLoading(state: ApplicationState): boolean {
    return state.factoryRepairs.areCompletedRepairsLoading;
}

export function getFactoryReportByRepairId(state: ApplicationState, repairId: number): FactoryReport {
    const emptyReport: FactoryReport = {
        factoryRepairId: repairId,
        hoursTaken: 0,
        minutesTaken: 0,
        report: '',
        createdBy: undefined
    };

    return state.factoryRepairs.reportsByRepairId[repairId] || emptyReport;
}