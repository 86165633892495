import moment from 'moment';
import React from 'react';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { Feed, List, Popup } from 'semantic-ui-react';

import { DateHelper, UserHelper } from '../../../helpers';
import { JaymarTechnicianScheduledUpdate } from '../../../state/models';

export interface TechnicianScheduledActivityProps {
    activity: JaymarTechnicianScheduledUpdate;
}

const m = defineMessages({
    summary: { id: 'TechnicianRescheduledActivity.summary', defaultMessage: 'A meeting with a Jaymar technician was moved to a new date.' },
    withTechnicianFormat: { id: 'TechnicianRescheduledActivity.technician', defaultMessage: 'With the technician {name}' },
    scheduledForFormat: { id: 'TechnicianRescheduledActivity.scheduled_for_format', defaultMessage: 'Moved to {date}, {time}' },
});

class TechnicianRescheduledActivity extends React.Component<TechnicianScheduledActivityProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { activity } = this.props;

        return (
            <Feed.Event key={activity.id}>
                <Feed.Label icon="calendar outline" />
                <Feed.Content>
                    <Popup
                        trigger={<Feed.Date content={moment.utc(activity.createdOn).fromNow()} />}
                        content={moment.utc(activity.createdOn).format('LL')}
                    />

                    <Feed.Summary>
                        {formatMessage(m.summary)}
                    </Feed.Summary>

                    <Feed.Extra text={true}>
                        <List bulleted={true}>
                            {activity.technician && <List.Item content={formatMessage(m.withTechnicianFormat, { name: UserHelper.getDisplayName(activity.technician) })} />}
                            <List.Item
                                content={formatMessage(m.scheduledForFormat, {
                                    date: moment.utc(activity.scheduledForDate).format('LL'),
                                    time: formatMessage(DateHelper.getTimeRangeDescription(activity.scheduledForTime)).toLowerCase()
                                })}
                            />
                        </List>
                    </Feed.Extra>
                </Feed.Content>
            </Feed.Event>
        );
    }
}

const connectedComponent = injectIntl(TechnicianRescheduledActivity);
export { connectedComponent as TechnicianRescheduledActivity };