import { UserClaims } from '.';

export interface User {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    accountType: UserAccountType;
    isDisabled: boolean;
    claims: UserClaims;
}

export enum UserAccountType {
    none,
    client,
    customerServiceAgent,
    administrator,
    jaymarRepresentative,
    jaymarTechnician,
    factoryTechnician,
    subcontractor
}

export const authenticatedUsers: UserAccountType[] = [
    UserAccountType.administrator,
    UserAccountType.client,
    UserAccountType.customerServiceAgent,
    UserAccountType.jaymarRepresentative,
    UserAccountType.jaymarTechnician,
    UserAccountType.factoryTechnician
];

export const technicians: UserAccountType[] = [
    UserAccountType.administrator,
    UserAccountType.jaymarTechnician
];

export const agents: UserAccountType[] = [
    UserAccountType.administrator,
    UserAccountType.customerServiceAgent
];

export const employees: UserAccountType[] = [
    UserAccountType.administrator,
    UserAccountType.customerServiceAgent,
    UserAccountType.jaymarRepresentative,
    UserAccountType.jaymarTechnician,
    UserAccountType.factoryTechnician
];

export const factoryTechnicians: UserAccountType[] = [
    UserAccountType.administrator,
    UserAccountType.factoryTechnician
];

export const representatives: UserAccountType[] = [
    UserAccountType.jaymarRepresentative
];