import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Order } from '../../../../state/models/Order';
import { OrderSummaryListItem } from './OrderSummaryListItem';
import { OrderDetail } from '../../../../state/models/OrderDetail';
import { ServiceCallDefectiveItem, ServiceCall } from '../../../../state/models';
import { BillSummaryListItem } from './BillSummaryListItem';

interface OwnProps {
    serviceCall: ServiceCall;
    onItemClick?: (orderItem: OrderDetail) => void;
}

interface StateProps {
    itemsToShow: number;
}

export type OrderSummaryListProps =
    & OwnProps
    & WrappedComponentProps;

const m = defineMessages({
    showMoreItems: { id: 'OrderSummaryList.showMoreItems', defaultMessage: 'Show {count, plural, one {one more item} other {{count} more items}}' },
    showLessItems: { id: 'OrderSummaryList.showLessItems', defaultMessage: 'Show less items' }
});

class OrderSummaryList extends React.Component<OrderSummaryListProps, StateProps> {
    private readonly defaultItemsToShow = 3;

    public constructor(props: OrderSummaryListProps) {
        super(props);

        this.state = { itemsToShow: 3 };
    }

    public render() {
        const { itemsToShow } = this.state;
        const { bill, defectiveItems } = this.props.serviceCall;
        const order = bill ? bill.order : undefined;

        if (order == null) {
            return (null);
        }

        const totalItemsCount = bill.billDetails.length;

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {bill.billDetails.slice(0, Math.min(this.state.itemsToShow, totalItemsCount)).map(x => {
                    const orderItem = order.orderDetails.find(y => y.id === x.orderDetailId);
                    const defectiveItem = defectiveItems.find(y => y.billDetailId === x.id);

                    if (orderItem != null) {
                        return (
                            <OrderSummaryListItem
                                key={x.id}
                                orderItem={orderItem}
                                defectsCount={defectiveItem ? defectiveItem.itemQuantity : 0}
                                onClick={this.props.onItemClick}
                            />
                        );
                    } else {
                        return (
                            <BillSummaryListItem
                                key={x.id}
                                billDetail={x}
                                defectsCount={defectiveItem ? defectiveItem.itemQuantity : 0}
                            />
                        );
                    }
                })}

                {itemsToShow < totalItemsCount && this.renderShowAllItemsLink()}
                {itemsToShow > this.defaultItemsToShow && itemsToShow >= totalItemsCount && this.renderShowLessItemsLink()}
            </div>
        );
    }

    private renderShowAllItemsLink = () => {
        const { formatMessage } = this.props.intl;
        const { order } = this.props.serviceCall.bill;
        const totalItemsCount = order.orderDetails.length;
        const itemsRemaining = totalItemsCount - this.state.itemsToShow;
        const linkText = formatMessage(m.showMoreItems, { count: itemsRemaining });

        return <a style={{ margin: '0 0 0 18px' }} onClick={() => this.setState({ itemsToShow: totalItemsCount })}>{linkText}</a>;
    }

    private renderShowLessItemsLink = () => {
        const { formatMessage } = this.props.intl;
        const { order } = this.props.serviceCall.bill;
        const totalItemsCount = order.orderDetails.length;
        const linkText = formatMessage(m.showLessItems);

        return <a style={{ margin: '0 0 0 18px' }} onClick={() => this.setState({ itemsToShow: Math.min(totalItemsCount, this.defaultItemsToShow) })}>{linkText}</a>;
    }
}

const intlComponent = injectIntl(OrderSummaryList);
export { intlComponent as OrderSummaryList };