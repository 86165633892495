import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Button, ButtonProps, Confirm } from 'semantic-ui-react';
import { commonMessages } from '../../constants/messages';

export interface SmallConfirmDeleteButtonProps extends ButtonProps {
    confirmationTitle: string;
    confirmationMessage: string;
    onClick: () => void;
}

interface SmallConfirmDeleteButtonState {
    isDeleteConfirmationShown: boolean;
}

class SmallConfirmDeleteButton extends React.Component<SmallConfirmDeleteButtonProps & WrappedComponentProps, SmallConfirmDeleteButtonState> {
    public constructor(props: SmallConfirmDeleteButtonProps & WrappedComponentProps) {
        super(props);

        this.state = {
            isDeleteConfirmationShown: false
        };
    }
    public render() {
        const { formatMessage } = this.props.intl;
        const { onClick, confirmationTitle, confirmationMessage, ...buttonProps } = this.props;

        return (
            <Confirm
                trigger={<Button basic={true} negative={true} icon="trash" onClick={() => this.setState({ isDeleteConfirmationShown: true })} {...buttonProps} />}
                open={this.state.isDeleteConfirmationShown}
                header={this.props.confirmationTitle}
                content={this.props.confirmationMessage}
                confirmButton={<Button negative={true} content={formatMessage(commonMessages.delete)} />}
                cancelButton={formatMessage(commonMessages.cancel)}
                onCancel={this.closeConfirmationModal}
                onConfirm={this.confirmDelete}
            />
        );
    }

    private confirmDelete = () => {
        this.props.onClick();
        this.closeConfirmationModal();
    }

    private closeConfirmationModal = () => {
        this.setState({
            isDeleteConfirmationShown: false
        });
    }
}

const intlComponent = injectIntl(SmallConfirmDeleteButton);
export { intlComponent as SmallConfirmDeleteButton };