import { defineMessages } from "@formatjs/intl";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { PageHeader } from "../../../../components/common";
import { ApplicationState } from "../../../../state/ducks";
import { getUserById, isUserLoading } from "../selectors";
import { actionCreators as UsersActions } from '../actions';
import { EmployeeForm } from "./components/EmployeeForm";
import { UserEdit } from "../models";
import { UserAccountDisabledWarning } from "./components/UserAccountDisabledWarning";

const m = defineMessages({
    title: { id: 'ModifyEmployeePage.title', defaultMessage: 'Modify an employee profile' },
    subtitle: { id: 'ModifyEmployeePage.subtitle', defaultMessage: 'Modify the settings and properties of an employee account.' },
});

interface RouteProps {
    userId: string;
}

export const ModifyEmployeePage: React.FC = (props) => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams<RouteProps>();

    const user = useSelector((state: ApplicationState) => getUserById(state, params.userId));
    const isLoading = useSelector((state: ApplicationState) => isUserLoading(state, params.userId));

    const reactivateEmployeeAccount = () => dispatch(UsersActions.enable(user?.id || ''));
    const deactivateEmployeeAccount = () => dispatch(UsersActions.disable(user?.id || ''));
    const updateEmployee = (user: UserEdit) => {
        dispatch(UsersActions.update(user));
        navigateToEmployeesPage();
    }

    useEffect(() => {
        dispatch(UsersActions.loadById(params.userId));

    }, [dispatch, params.userId]);

    const navigateToEmployeesPage = () => history.push(`/administration/employees`);

    return (
        <>
            <PageHeader iconName="id badge" title={formatMessage(m.title)} subtitle={formatMessage(m.subtitle)} />
            {user?.isDisabled && <UserAccountDisabledWarning onReactivate={reactivateEmployeeAccount} />}

            <EmployeeForm 
                user={user}
                isLoading={isLoading}
                onCancel={navigateToEmployeesPage}
                onSave={updateEmployee}
                onDisable={deactivateEmployeeAccount}
            />
        </>
    );
};