import { defineMessages } from 'react-intl';
import { ValidationResult } from '.';
import { StringHelper, nameof } from '../../utils';

const m = defineMessages({
    descriptionRequired: { id: 'ProblemEdit.description_required', defaultMessage: 'The problem description is required.' },
    attachmentRequired: { id: 'ProblemEdit.attachment_required', defaultMessage: 'Please upload a picture of the furniture and the room where it is located to help our customer service agents diagnose the problem.' }
});

export class ProblemEdit {
    public description?: string;
    public attachments: File[] = [];
    public areAttachmentsRequired: boolean = true;
    public overrideAttachmentsRequired: boolean = false;

    public constructor(init?: Partial<ProblemEdit>) {
        Object.assign(this, init);
    }

    public validate(): ValidationResult {
        let result = new ValidationResult();

        if (!StringHelper.hasValue(this.description)) {
            result.isValid = false;
            result.errorFields.push(nameof<ProblemEdit>('description'));
            result.errorMessages.push(m.descriptionRequired);
        }

        if (this.areAttachmentsRequired && !this.overrideAttachmentsRequired && this.attachments.length <= 0) {
            result.isValid = false;
            result.errorFields.push(nameof<ProblemEdit>('attachments'));
            result.errorMessages.push(m.attachmentRequired);
        }

        return result;
    }
}