import { BaseService } from './baseService';

export class ProductionOrdersService extends BaseService {
    constructor() {
        super('api/production-orders');
    }

    public getByOrder(orderId: number): Promise<any> {
        return this.get(`/by-order/${orderId}`);
    }

    public getByServiceCall(serviceCallId: number): Promise<any> {
        return this.get(`/by-service-call/${serviceCallId}`);
    }
}

export default ProductionOrdersService;