import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Card, Form, Icon, Label, List, Menu, Message, Segment, Tab, TextAreaProps } from 'semantic-ui-react';

import { useDispatch, useSelector } from 'react-redux';
import { SmallCancelButton, SmallEditButton, SmallSaveButton } from '../../../../../components/common';
import { DocumentAttachments, MediaAttachments, NoteAttachments, ServiceReportAttachments } from '../../../../../components/service-calls/attachments';
import { DraftModeConfirmationModal } from '../../../../../components/service-calls/draft';
import { ApplicationState } from '../../../../../state/ducks';
import { Media, ProblemEdit, ServiceCall, ServiceCallAttachment, ServiceCallStatus, ValidationResult } from '../../../../../state/models';
import { AttachmentHelper } from '../../../../../state/utils';
import { getNotesByServiceCall } from '../../../../notes/selectors';

import { useResponsive } from 'utils/responsive';
import { isCurrentUserAClient, isCurrentUserAnEmployee } from '../../../../account/selectors';
import { actionCreators as NotesActions } from '../../../../notes/actions';

const m = defineMessages({
    header: { id: 'ProblemDescriptionCard.header', defaultMessage: 'Problem description' },
    emptyTitle: { id: 'ProblemDescriptionCard.empty_title', defaultMessage: 'No description' },
    emptyDescription: { id: 'ProblemDescriptionCard.empty_description', defaultMessage: 'No additional information about the problem was provided for this service call' },
    problemDescription: { id: 'ProblemInformationCard.problem_description', defaultMessage: 'Problem description' },
    problemDescriptionPlaceholder: { id: 'ProblemInformationCard.problem_description_placeholder', defaultMessage: 'Describe the problem in a few words' },
    imagesAndVideosTab: { id: 'ProblemDescriptionCard.images_and_videos_tab', defaultMessage: 'Images & Videos' },
    documentsTab: { id: 'ProblemDescriptionCard.documents_tab', defaultMessage: 'Documents' },
    internalNotesTab: { id: 'ProblemDescriptionCard.internal_notes_tab', defaultMessage: 'Notes' },
    serviceReportsTab: { id: 'ProblemDescriptionCard.service_reports_tab', defaultMessage: 'Reports' },
});

interface OwnProps {
    serviceCallId: number;
    serviceCall: ServiceCall | undefined;
    isServiceCallLoading: boolean;
    problemEdit?: ProblemEdit;
    validationResult?: ValidationResult;
    currentUserId?: string;

    medias?: ServiceCallAttachment[];
    documents?: ServiceCallAttachment[];

    areMediasUploading?: boolean;
    areDocumentsUploading?: boolean;
    areLinkedCallsDisplayed?: boolean;
    isAttachmentDeleting?: number[];

    canEdit?: boolean;
    onEdit?: (problemEdit: ProblemEdit) => ValidationResult;
    onUploadMedia?: (files: FileList) => void;
    onDeleteAttachment?: (attachmentId: number) => void;
    onUploadDocuments?: (files: FileList) => void;
}

export type ProblemInformationCardProps =
    & OwnProps;

export const ProblemInformationCard: React.FC<ProblemInformationCardProps> = (props) => {
    const { formatMessage } = useIntl();
    const { isMobile } = useResponsive();
    const dispatch = useDispatch();

    const [isEditing, setIsEditing] = useState(false);
    const [problemEdit, setProblemEdit] = useState(props.problemEdit || new ProblemEdit());
    const [validationResult, setValidationResult] = useState(props.validationResult || new ValidationResult());
    const [attachmentToDelete, setAttachmentToDelete] = useState<number | undefined>(undefined);
    const [isDraftConfirmationVisible, setIsDraftConfirmationVisible] = useState(false);

    const closeDraftConfirmation = () => setIsDraftConfirmationVisible(false);

    const isEmployee = useSelector(isCurrentUserAnEmployee);
    const isClient = useSelector(isCurrentUserAClient);

    const notes = useSelector((state: ApplicationState) => getNotesByServiceCall(state, props.serviceCallId));
    const displayedNotes = notes.filter(note => props.areLinkedCallsDisplayed || note.serviceCallId === props.serviceCallId);
    const displayedMedias = (props.medias || []).filter(media => props.areLinkedCallsDisplayed || media.serviceCallId === props.serviceCallId);
    const displayedDocuments = (props.documents || []).filter(document => props.areLinkedCallsDisplayed || document.serviceCallId === props.serviceCallId);

    useEffect(() => {
        dispatch(NotesActions.loadServiceCallNotes(props.serviceCallId));
    }, [dispatch, props.serviceCallId]);

    const renderEditControls = () => {
        if (!isEditing && props.canEdit) {
            return (
                <SmallEditButton floated="right" onClick={handleEdit} />
            );
        }

        if (isEditing) {
            return (
                <div style={{ float: 'right' }}>
                    <SmallCancelButton onClick={handleCancel} />
                    <SmallSaveButton onClick={handleSave} />
                </div>
            );
        }

        return <React.Fragment />
    }

    const renderDocumentsAttachments = () => {
        return (
            <DocumentAttachments
                documents={displayedDocuments}
                canUploadDocuments={props.canEdit || false}
                areDocumentsUploading={props.areDocumentsUploading || false}
                onDelete={props.onDeleteAttachment}
                onUpload={props.onUploadDocuments}
            />
        );
    }

    const renderInternalNotes = () => {
        return props.serviceCall
            ? <NoteAttachments serviceCallId={props.serviceCall.id} />
            : (null);
    }

    const renderMediaAttachments = () => {
        const medias: Media[] = displayedMedias.map(attachment => ({
                id: attachment.id,
                createdOn: attachment.createdOn,
                type: AttachmentHelper.toMediaType(attachment.type),
                isDisabled: props.isAttachmentDeleting
                    ? props.isAttachmentDeleting.find(x => x === attachment.id) !== undefined
                    : false,
                uri: `/api/service-calls/${attachment.serviceCallId}/attachments/${attachment.id}`
            }));

        return (
            <MediaAttachments
                medias={medias}
                canUpload={props.canEdit || false}
                isUploading={props.areMediasUploading || false}
                onUploadMedia={props.onUploadMedia}
                onDeleteMedia={handleDeleteMedia}
            />
        );
    }

    const inspectionsWithReports = props.serviceCall ? props.serviceCall.inspections.filter(x => x.completedOn != null) : [];
    const technicianRepairsWithReports = props.serviceCall ? props.serviceCall.technicianRepairs.filter(x => x.completedOn != null && x.technicianReport != null) : [];
    const factoryRepairsWithReports = props.serviceCall ? props.serviceCall.factoryRepairs.filter(x => x.completedRepairsOn != null && x.factoryReport != null) : [];
    const subcontractorRepairsWithReports = props.serviceCall ? props.serviceCall.subcontractorRepairs.filter(x => x.report) : [];

    const renderServiceReports = () => {
        return (
            <ServiceReportAttachments
                inspections={inspectionsWithReports}
                technicianRepairs={technicianRepairsWithReports}
                factoryRepairs={factoryRepairsWithReports}
                subcontractorRepairs={subcontractorRepairsWithReports}
            />
        );
    }

    const getReportsCount = (): number => inspectionsWithReports.length + technicianRepairsWithReports.length + factoryRepairsWithReports.length + subcontractorRepairsWithReports.length;

    const mediasCount = displayedMedias.length;
    const documentsCount = displayedDocuments.length;
    const serviceReportsCount = getReportsCount();

    const imagesMenuItem = (
        <Menu.Item key="images">
            <Icon name="file image outline" />
            {!isMobile &&
                <span>{formatMessage(m.imagesAndVideosTab)}</span>
            }
            <Label content={mediasCount} size="small" color={mediasCount > 0 ? 'teal' : undefined} />
        </Menu.Item>
    );

    const documentsMenuItem = (
        <Menu.Item key="documents">
            <Icon name="file pdf outline" />
            {!isMobile &&
                <span>{formatMessage(m.documentsTab)}</span>
            }
            <Label content={documentsCount} size="small" color={documentsCount > 0 ? 'teal' : undefined} />
        </Menu.Item>
    );

    const serviceReportsMenuItem = (
        <Menu.Item key="reports">
            <Icon name="wrench" />
            {!isMobile &&
                <span>{formatMessage(m.serviceReportsTab)}</span>
            }
            <Label content={serviceReportsCount} size="small" color={serviceReportsCount > 0 ? 'teal' : undefined} />
        </Menu.Item>
    );

    const internalNotesMenuItem = (
        <Menu.Item key="internal-notes">
            <Icon name="sticky note outline" />
            {!isMobile &&
                <span>{formatMessage(m.internalNotesTab)}</span>
            }
            <Label content={displayedNotes.length} size="small" color={displayedNotes.length > 0 ? 'teal' : undefined} />
        </Menu.Item>
    );

    const panes = [
        { menuItem: internalNotesMenuItem, render: () => <Tab.Pane basic attached={false} style={{ padding: 0 }}>{renderInternalNotes()}</Tab.Pane> },
        { menuItem: imagesMenuItem, render: () => <Tab.Pane basic attached={false} style={{ padding: 0 }}>{renderMediaAttachments()}</Tab.Pane> },
        { menuItem: documentsMenuItem, render: () => <Tab.Pane basic attached={false} style={{ padding: 0 }}>{renderDocumentsAttachments()}</Tab.Pane> },
        { menuItem: serviceReportsMenuItem, render: () => <Tab.Pane basic attached={false} style={{ padding: 0 }}>{renderServiceReports()}</Tab.Pane> }
    ];

    const renderContent = () => {
        const description = props.problemEdit ? props.problemEdit.description : '';

        return (
            <Card.Content>
                {description &&
                    <List style={{ padding: '0 14px 14px 14px' }}>
                        <List.Item>
                            <pre style={{ fontFamily: 'Lato, "Helvetica Neue", Arial, Helvetica, sans-serif', whiteSpace: 'pre-wrap' }}>
                                {description}
                            </pre>
                        </List.Item>
                    </List>
                }

                {!description &&
                    <Segment basic={true} textAlign="center">
                        <h3>{formatMessage(m.emptyTitle)}</h3>
                        {formatMessage(m.emptyDescription)}
                    </Segment>
                }
            </Card.Content>
        );
    }

    const renderEditableContent = () => {
        const localizedErrorMessages = validationResult.errorMessages.map(value => formatMessage(value));

        return (
            <Card.Content>
                <Form error={!validationResult.isValid}>
                    <Form.TextArea
                        id="description"
                        required={true}
                        rows={6}
                        label={formatMessage(m.problemDescription)}
                        placeholder={formatMessage(m.problemDescriptionPlaceholder)}
                        value={problemEdit.description}
                        onChange={handleDescriptionChange}
                    />
                    <Message error={true} list={localizedErrorMessages} />
                </Form>
            </Card.Content>
        );
    }

    const handleDeleteMedia = (attachmentId: number): void => {
        const isLastMedia = props.medias && props.medias.length <= 1;
        const isNewServiceCall = props.serviceCall && props.serviceCall.status === ServiceCallStatus.created;

        // We display the draft warning message only if the agent hasn't yet assigned himself/herself on it.
        if (isClient && isLastMedia && isNewServiceCall) {
            setIsDraftConfirmationVisible(true);
            setAttachmentToDelete(attachmentId);
        } else if (props.onDeleteAttachment) {
            props.onDeleteAttachment(attachmentId);
        }
    }

    const confirmDraftMode = (): void => {
        if (props.onDeleteAttachment && attachmentToDelete) {
            props.onDeleteAttachment(attachmentToDelete);
        }

        setIsDraftConfirmationVisible(false);
        setAttachmentToDelete(undefined);
    }

    const handleDescriptionChange = (_e: React.FormEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
        setProblemEdit(new ProblemEdit({
            ...problemEdit,
            description: data.value ? data.value as string : ''
        }));
    }

    const handleEdit = () => {
        setIsEditing(true);
    }

    const handleCancel = () => {
        setIsEditing(false);
        setProblemEdit(props.problemEdit || new ProblemEdit());
        setValidationResult(props.validationResult || new ValidationResult());
    }

    const handleSave = () => {
        if (props.onEdit) {
            const validationResult = props.onEdit(problemEdit);
            setValidationResult(validationResult);
            setIsEditing(!validationResult.isValid);
        } else {
            setIsEditing(false);
        }
    }

    return (
        <>
            <Card fluid color="orange">
                <Card.Content>
                    <Card.Header>
                        {formatMessage(m.header)}

                        {isEmployee && renderEditControls()}
                    </Card.Header>
                </Card.Content>

                {!isEditing && renderContent()}
                {isEditing && renderEditableContent()}

                <Card.Content>
                    <Tab
                        className="c-tab"
                        menu={{ secondary: true, className: 'c-tab__menu c-tab__menu--wrapped' }}
                        panes={panes}
                        defaultActiveIndex={0}
                        style={{ marginTop: '16px' }}
                    />
                </Card.Content>
            </Card>

            <DraftModeConfirmationModal
                open={isDraftConfirmationVisible}
                onConfirm={confirmDraftMode}
                onClose={closeDraftConfirmation}
                onCancel={closeDraftConfirmation}
            />
        </>
    );
};