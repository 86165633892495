export class PagedResult<T> {
    itemsPerPage: number = 10;
    pageCount: number = 0;
    pageIndex: number = 1;
    hasPreviousPage: boolean = false;
    hasNextPage: boolean = false;
    results: T[] = [];

    public static empty<T>(): PagedResult<T> {
        return new PagedResult<T>();
    }
    
    public constructor(init?: Partial<PagedResult<T>>) {
        Object.assign(this, init);
    }
}