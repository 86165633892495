import { Reducer } from 'redux';
import { OrdersState } from './types';
import { ActionTypeKeys, ActionTypes } from './actions';
import _ from 'lodash';

const initialState: OrdersState = {
    byId: {},
    allIds: [],
    loadingIds: [],
};

const ordersReducer: Reducer<OrdersState> = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case ActionTypeKeys.LOAD_REQUEST: {
            return {
                ...state,
                loadingIds: _.union(state.loadingIds, [action.payload.orderId])
            };
        }

        case ActionTypeKeys.LOAD_SUCCESS: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.order.id]: action.payload.order
                },
                allIds: _.union(state.allIds, [action.payload.order.id]),
                loadingIds: state.loadingIds.filter(x => x !== action.payload.order.id)
            };
        }

        case ActionTypeKeys.LOAD_FAILURE: {
            return {
                ...state,
                loadingIds: state.loadingIds.filter(x => x !== action.payload.orderId)
            };
        }

        default:
            return state;
    }
};

export { ordersReducer };