import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { List, Modal } from 'semantic-ui-react';
import { Bill, BillDetail, ServiceCallDefectiveItem } from '../../state/models';
import { ItemDetailsListItem } from '.';

export interface ItemDetailsListProps {
    billDetails: BillDetail[];
    defectiveItems?: ServiceCallDefectiveItem[];
    numberOfItemsToShow?: number;
}

interface ItemDetailsListState {
    isShowingAllItems: boolean;
}

interface BillDetailsWithDefects {
    billDetails: BillDetail;
    hasDefects: boolean;
}

const m = defineMessages({
    showMoreItemsFormat: { id: 'ItemDetailsList.show_more_items', defaultMessage: 'Show {count, plural, one {one more item} other {{count} more items}}' } ,
    showLessItems: { id: 'ItemDetailsList.show_less_items', defaultMessage: 'Show less items' }
});

class ItemDetailsList extends React.Component<ItemDetailsListProps & WrappedComponentProps, ItemDetailsListState> {
    public constructor(props: ItemDetailsListProps & WrappedComponentProps) {
        super(props);

        this.state = { isShowingAllItems: false };
    }

    public render() {
        const allBillDetails = this.buildBillDetails(this.props.billDetails).sort(this.sortByDefects);
        const numberOfItemsToShow = this.state.isShowingAllItems ? allBillDetails.length : this.props.numberOfItemsToShow || 3;
        const billDetailsPreview = allBillDetails.slice(0, Math.min(allBillDetails.length, numberOfItemsToShow));
        const showAllItemsLink = allBillDetails.length > (this.props.numberOfItemsToShow || 3);

        return billDetailsPreview.length > 0
            ? (
                <div>
                    <List bulleted={true}>{this.renderBillItems(billDetailsPreview)}</List>
                    {showAllItemsLink && this.renderShowAllItemsLink(allBillDetails.length - numberOfItemsToShow)}
                </div>
            )
            : (null);
    }

    private toggleShowAllItems = () => {
        this.setState((current) => ({
            ...current,
            isShowingAllItems: !current.isShowingAllItems
        }));
    }

    private renderShowAllItemsLink = (itemsRemaining: number) => {
        if (this.props.numberOfItemsToShow) {
            const { formatMessage } = this.props.intl;
            const { isShowingAllItems } = this.state;

            const linkText = isShowingAllItems
                ? formatMessage(m.showLessItems)
                : formatMessage(m.showMoreItemsFormat, { count: itemsRemaining });

            return <a style={{margin: '0 0 0 18px'}} onClick={() => this.toggleShowAllItems()}>{linkText}</a>;
        }

        return (null);
    }

    private renderBillItems = (billDetails: BillDetailsWithDefects[]) => {
        const billItems = billDetails.length > 0
            ? billDetails.map(x => {
                if (x.billDetails.item != null) {
                    const defectiveItem = this.props.defectiveItems
                        ? this.props.defectiveItems.filter(defect => defect.item != null && x.billDetails.item != null && defect.item.id === x.billDetails.item.id)
                        : [];

                    const hasDefect = defectiveItem != null && defectiveItem.length > 0;
                    const defectiveItemQuantity = hasDefect && defectiveItem ? defectiveItem[0].itemQuantity : undefined;

                    return (
                        <ItemDetailsListItem 
                            key={x.billDetails.item.id} 
                            item={x.billDetails.item} 
                            itemCover={x.billDetails.itemCover} 
                            itemQuantity={x.billDetails.quantityOrdered.value}
                            hasDefect={hasDefect}
                            defectiveItemQuantity={defectiveItemQuantity}
                        />
                    );
                }

                return (null);
            })
            : (null);

        return billItems;
    }

    private buildBillDetails = (billDetails?: BillDetail[]) => {
        const details = billDetails && billDetails.length > 0
            ? billDetails.filter(x => x.item != null)
            : [];

        return details
            ? details.map(x => {
                const defects = this.props.defectiveItems
                    ? this.props.defectiveItems.filter(defect => defect.item != null && x.item != null && defect.item.id === x.item.id) 
                    : [];

                return ({
                    billDetails: x, 
                    hasDefects: defects.length > 0
                });
            })
            : [];
    }

    private sortByDefects = (a: BillDetailsWithDefects, b: BillDetailsWithDefects) => {
        if (a.hasDefects && !b.hasDefects) {
            return -1;
        }

        if (!a.hasDefects && b.hasDefects) {
            return 1;
        }

        return 0;
    }
}

const connectedComponent = injectIntl(ItemDetailsList);
export { connectedComponent as ItemDetailsList };