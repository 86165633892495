import { ServiceCallNote } from '../../state/models';
import { ApplicationState } from '../../state/ducks';

export const areNotesLoading = (state: ApplicationState): boolean => state.notes.isLoading;

export const areNotesSaving = (state: ApplicationState): boolean => state.notes.isSaving;

export const getNoteById = (state: ApplicationState, noteId: number): ServiceCallNote | undefined => state.notes.byId[noteId];

export const getNotesByServiceCall = (state: ApplicationState, serviceCallId: number): ServiceCallNote[] => {
    const noteIds = state.notes.byServiceCallId[serviceCallId] || [];
    return noteIds.map(x => getNoteById(state, x)).filter(x => x != null) as ServiceCallNote[];
};

export const countNotes = (state: ApplicationState, serviceCallId: number): number => getNotesByServiceCall(state, serviceCallId).length;