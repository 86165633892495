import * as React from 'react';
import { Form, InputOnChangeData } from 'semantic-ui-react';
import { SearchField } from './SearchField';

interface OwnProps {
    fieldId: string;
    label: string;
    value: string | undefined;
    onChange?: (fieldId: string, value: any) => void
}

export type SearchInputFieldProps =
    & OwnProps;

export class SearchInputField extends React.Component<SearchInputFieldProps, {}> {
    public render() {
        return (
            <SearchField fieldId={this.props.fieldId} label={this.props.label}>
                <Form.Input id={this.props.fieldId} value={this.props.value} onChange={this.handleChange} />
            </SearchField>
        );
    }

    private handleChange = (_event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        if (this.props.onChange != null) {
            this.props.onChange(this.props.fieldId, data.value);
        }
    }
}