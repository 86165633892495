import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { TechnicianRepair, AppointmentTimeRange } from '../../../state/models';
import { Card, Table, SemanticCOLORS, Label, Popup, Button } from 'semantic-ui-react';
import { NoContentSegment, LoadingDimmer } from '../../common';
import { DateHelper } from '../../../helpers';
import { commonMessages } from '../../../constants';

interface AppointmentsCardOwnProps {
    appointments: TechnicianRepair[];
    areAppointmentsLoading?: boolean;
    title: string;
    subtitle: string;
    emptyTitle: string;
    emptySubtitle: string;
    onViewDetails: (appointment: TechnicianRepair) => void;
}

export type AppointmentsCardProps =
    & AppointmentsCardOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    dateHeader: { id: 'AppointmentsCard.date_header', defaultMessage: 'Date' },
    cityHeader: { id: 'AppointmentsCard.city_header', defaultMessage: 'City' },
});

class AppointmentsCard extends React.Component<AppointmentsCardProps, {}> {
    public render() {
        const hasAppointments = this.props.appointments.length > 0;

        return (
            <Card fluid={true} color="teal" style={{ height: '100%' }}>
                <Card.Content style={{ flexGrow: 0 }}>
                    <Card.Header content={this.props.title} />
                    <Card.Meta content={this.props.subtitle} />
                </Card.Content>
                <Card.Content>
                    <LoadingDimmer active={this.props.areAppointmentsLoading} />

                    {hasAppointments &&
                        this.renderAppointments()
                    }

                    {!hasAppointments &&
                        this.renderEmptyContent()
                    }
                </Card.Content>
            </Card>
        );
    }

    private renderAppointments() {
        const { formatMessage } = this.props.intl;
        const rows = this.props.appointments.map(x => this.renderAppointment(x));

        return (
            <Table basic="very" unstackable={true}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell collapsing={true} />
                        <Table.HeaderCell>{formatMessage(m.dateHeader)}</Table.HeaderCell>
                        <Table.HeaderCell>{formatMessage(m.cityHeader)}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows}
                </Table.Body>
            </Table>
        );
    }

    private renderAppointment(appointment: TechnicianRepair) {
        const { formatMessage } = this.props.intl;
        const appointmentCity = appointment.serviceCall && appointment.serviceCall.customer && appointment.serviceCall.customer.address
            ? appointment.serviceCall.customer.address.city || formatMessage(commonMessages.unknown)
            : formatMessage(commonMessages.unknown);

        return (
            <Table.Row key={appointment.id}>
                <Table.Cell>{this.renderTime(appointment.scheduledForTimeRange)}</Table.Cell>
                <Table.Cell>
                    {appointment.scheduledForDate &&
                        <a onClick={() => this.props.onViewDetails(appointment)}>
                            {moment.utc(appointment.scheduledForDate).format('LL')}
                        </a>
                    }
                </Table.Cell>
                <Table.Cell>
                    <a onClick={() => this.props.onViewDetails(appointment)}>
                        {appointmentCity}
                    </a>
                </Table.Cell>
            </Table.Row>
        );
    }

    private renderEmptyContent() {
        return (
            <NoContentSegment
                basic={true}
                title={this.props.emptyTitle}
                subtitle={this.props.emptySubtitle}
            />
        );
    }

    private renderTime(timeRange: AppointmentTimeRange | undefined) {
        if (timeRange != null) {
            const { formatMessage } = this.props.intl;
            const colorMap = new Map<AppointmentTimeRange, SemanticCOLORS>([
                [AppointmentTimeRange.morning, 'orange'],
                [AppointmentTimeRange.afternoon, 'violet'],
                [AppointmentTimeRange.unspecified, 'grey']
            ]);

            const trigger = (
                <Label
                    color={colorMap.get(timeRange)}
                    content={formatMessage(DateHelper.getTimeRangeAbbreviation(timeRange))}
                    style={{ minWidth: 42, textAlign: 'center' }}
                />
            );

            return (
                <Popup
                    trigger={trigger}
                    content={formatMessage(DateHelper.getTimeRangeDescription(timeRange))}
                />
            );
        }

        return (null);
    }
}

const connectedComponent = injectIntl(AppointmentsCard);
export { connectedComponent as AppointmentsCard };