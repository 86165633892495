import React from "react";
import { Menu } from 'semantic-ui-react';
import { LocaleSwitcher } from "../../../../modules/i18n/components";
import { CurrentUserMenuItem } from "./CurrentUserMenuItem";
import { NotificationMenuItem } from "./NotificationMenuItem";
import styles from './TopBar.module.css';

export const TopBar: React.FC = () => {
    return (
        <Menu className={styles.fixedHeight} fixed="top">
            <Menu.Menu position="right">
                <NotificationMenuItem />
                <LocaleSwitcher />
                <CurrentUserMenuItem />
            </Menu.Menu>
        </Menu>
    );
};