import { Address, emptyAddress } from '../Address';
import { ValidationResult } from '.';
import { defineMessages } from 'react-intl';
import { StringHelper, nameof } from '../../utils';

const m = defineMessages({
    nameRequired: { id: 'ClientEdit.nameRequired', defaultMessage: 'Please provide your full name.' },
    emailRequired: { id: 'ClientEdit.emailRequired', defaultMessage: 'Please provide an email address that can be used by our customer service agents to contact you.' },
    emailInvalid: { id: 'ClientEdit.emailInvalid', defaultMessage: 'Please provide a valid email address.' },
    phoneRequired: { id: 'ClientEdit.phoneRequired', defaultMessage: 'Please provide a phone number that can be used by our customer service agents to contact you.' }
});

export class ClientContactEdit {
    public name?: string;
    public email?: string;
    public phoneNumber?: string;

    public constructor(init?: Partial<ClientContactEdit>) {
        Object.assign(this, init);
    }

    public validate(): ValidationResult {
        let result = new ValidationResult();

        if (!StringHelper.hasValue(this.name)) {
            result.isValid = false;
            result.errorFields.push(nameof<ClientContactEdit>('name'));
            result.errorMessages.push(m.nameRequired);
        }

        if (!StringHelper.hasValue(this.email)) {
            result.isValid = false;
            result.errorFields.push(nameof<ClientContactEdit>('email'));
            result.errorMessages.push(m.emailRequired);
        } else {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            var isEmailValid = re.test(this.email!.toLowerCase());

            if (!isEmailValid) {
                result.isValid = false;
                result.errorFields.push(nameof<ClientContactEdit>('email'));
                result.errorMessages.push(m.emailInvalid);
            }
        }

        if (!StringHelper.hasValue(this.phoneNumber)) {
            result.isValid = false;
            result.errorFields.push(nameof<ClientContactEdit>('phoneNumber'));
            result.errorMessages.push(m.phoneRequired);
        }

        return result;
    }
}