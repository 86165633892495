import { ActionTypeKeys } from './types';
import { Notification } from '../../models';
import { ThunkAction } from 'redux-thunk';
import { ApplicationState } from '..';
import * as globalNotification from '../global-notification';
import NotificationsService from '../../../services/NotificationsService';
import { AppThunk } from '../../store';

export type ActionTypes =
    | LoadAllRequest
    | LoadAllSuccess
    | LoadAllFailure
    | LoadUnreadRequest
    | LoadUnreadSuccess
    | LoadUnreadFailure
    | ReadRequest
    | ReadSuccess
    | ReadFailure
    | ReadAllRequest
    | ReadAllSuccess
    | ReadAllFailure
    | { type: '' };

export interface LoadAllRequest { type: ActionTypeKeys.LOAD_ALL_REQUEST; }
export interface LoadAllSuccess { type: ActionTypeKeys.LOAD_ALL_SUCCESS; payload: Notification[]; }
export interface LoadAllFailure { type: ActionTypeKeys.LOAD_ALL_FAILURE; payload: string; }

export interface LoadUnreadRequest { type: ActionTypeKeys.LOAD_UNREAD_REQUEST; }
export interface LoadUnreadSuccess { type: ActionTypeKeys.LOAD_UNREAD_SUCCESS; payload: Notification[]; }
export interface LoadUnreadFailure { type: ActionTypeKeys.LOAD_UNREAD_FAILURE; payload: string; }

export interface ReadRequest { type: ActionTypeKeys.READ_REQUEST; }
export interface ReadSuccess { type: ActionTypeKeys.READ_SUCCESS; payload: number; }
export interface ReadFailure { type: ActionTypeKeys.READ_FAILURE; payload: string; }

export interface ReadAllRequest { type: ActionTypeKeys.READ_ALL_REQUEST; }
export interface ReadAllSuccess { type: ActionTypeKeys.READ_ALL_SUCCESS; }
export interface ReadAllFailure { type: ActionTypeKeys.READ_ALL_FAILURE; payload: string; }

const service = new NotificationsService();

export const actionCreators = {
    loadAll: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_ALL_REQUEST });

            service.getAll()
                .then(notifications => {
                    dispatch({ type: ActionTypeKeys.LOAD_ALL_SUCCESS, payload: notifications });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_ALL_FAILURE });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de récupérer l'ensemble des notifications (${error}).`)
                    );
                });
        };
    },

    loadUnread: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_UNREAD_REQUEST });

            service.getUnread()
                .then(notifications => {
                    dispatch({ type: ActionTypeKeys.LOAD_UNREAD_SUCCESS, payload: notifications });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_UNREAD_FAILURE });
                });
        };
    },

    read: (notification: Notification): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.READ_REQUEST });

            service.markAsRead(notification.id)
                .then(() => {
                    dispatch({ type: ActionTypeKeys.READ_SUCCESS, payload: notification.id });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.READ_FAILURE });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de marquer une notification comme étant lue (${error}).`)
                    );
                });
        };
    },

    readAll: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.READ_ALL_REQUEST });

            service.markAllAsRead()
                .then(() => {
                    dispatch({ type: ActionTypeKeys.READ_ALL_SUCCESS });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.READ_ALL_FAILURE });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de marquer toutes les notifications comme étant lues (${error}).`)
                    );
                });
        };
    },
};