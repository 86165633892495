import React from 'react';
import { useIntl } from 'react-intl';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import { provincesByCountryMap } from '../../constants/provinces';

interface OwnProps {
    countryCode?: string;
}

export type ProvincesDropdownProps =
    & DropdownProps
    & OwnProps;

export const ProvincesDropdown: React.FC<ProvincesDropdownProps> = (props) => {
    const { formatMessage } = useIntl();
    const { countryCode, ...dropdownProps } = props;

    const countryProvinces = provincesByCountryMap.get(props.countryCode || '') || [];
    const countryProvincesOptions: DropdownItemProps[] = countryProvinces.map(province => ({
        text: formatMessage(province.intlMessage),
        value: province.value,
    }));

    return (
        <Dropdown
            search
            selection
            options={countryProvincesOptions}
            {...dropdownProps}
        />
    );
};