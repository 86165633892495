import { BaseService } from './baseService';

export class SubcontractorsService extends BaseService {
    constructor() {
        super('api/service-calls/subcontractors');
    }

    getSubcontractors(): Promise<any> {
        return this.get('/');
    }
}