import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import * as UiActions from '../../../state/ducks/ui';

import { ApplicationState } from '../../../state/ducks';

interface InspectionReviewStepActions {
    uiActions: typeof UiActions.actionCreators;
}

interface InspectionReviewStepOwnProps {
}

export type InspectionReviewStepProps =
    & InspectionReviewStepOwnProps
    & InspectionReviewStepActions
    & RouteComponentProps<{}>
    & WrappedComponentProps;

class InspectionReviewStep extends React.Component<InspectionReviewStepProps, {}> {
    public componentDidMount() {
        this.props.uiActions.changeInspectionStep('review');
    }

    public render() {
        return (
            <div>Review</div>
        );
    }
}

const mapStateToProps = (state: ApplicationState): InspectionReviewStepOwnProps => {
    return {
    };
};

const mapDispatchToProps = (dispatch: Dispatch): InspectionReviewStepActions => {
    return {
        uiActions: bindActionCreators(UiActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(InspectionReviewStep);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as InspectionReviewStep };