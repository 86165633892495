import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Header, Modal, Button } from 'semantic-ui-react';
import { AppointmentCalendar } from '.';
import { TechnicianRepair } from '../../../state/models';
import { commonMessages } from '../../../constants';
import { Calendar, CalendarEvent } from '../../calendar';
import { TechnicianCalendarEvent } from '../technician';

interface AppointmentCalendarModalOwnProps {
    trigger?: React.ReactNode;
    forDate: Date;
    appointments: TechnicianRepair[];
    areAppointmentsLoading?: boolean;
    isOpen?: boolean;
    onDateSelected: (date: Date) => void;
    onMonthChanged: (date: Date) => void;
    onClose: () => void;
    onServiceCallClicked: (appointment: TechnicianRepair) => void;
}

interface AppointmentCalendarModalState {
    selectedDate?: Date;
}

export type AppointmentCalendarModalProps =
    & AppointmentCalendarModalOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'AppointmentCalendarModal.title', defaultMessage: 'Technicians appointments' },
    subtitle: { id: 'AppointmentCalendarModal.subtitle', defaultMessage: 'View all the technicians appointments' },
});

class AppointmentCalendarModal extends React.Component<AppointmentCalendarModalProps, AppointmentCalendarModalState> {
    public constructor(props: AppointmentCalendarModalProps) {
        super(props);

        this.state = {};
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const calendarEvents: CalendarEvent[] = this.props.appointments
            .filter(x => x.scheduledForDate != null)
            .map(x => ({
                on: moment(x.scheduledForDate).local().startOf('day').toDate(),
                element: <TechnicianCalendarEvent key={x.id} repair={x} onRepairClicked={this.props.onServiceCallClicked} />
            }));

        return (
            <Modal trigger={this.props.trigger} size="fullscreen" open={this.props.isOpen} closeIcon={true} onClose={this.props.onClose}>
                <Modal.Header>
                    <Header
                        content={formatMessage(m.title)}
                        subheader={formatMessage(m.subtitle)}
                    />
                </Modal.Header>
                <Modal.Content>
                    <Calendar
                        forDate={this.props.forDate}
                        events={calendarEvents}
                        lockedDates={[]}
                        isLoading={false}
                        canSelectDate={true}
                        onMonthChanged={this.props.onMonthChanged}
                        onDateSelected={(date) => this.setState({ selectedDate: date })}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button content={formatMessage(commonMessages.cancel)} onClick={this.props.onClose} />
                    <Button
                        primary={true}
                        disabled={this.state.selectedDate == null}
                        content={formatMessage(commonMessages.select)}
                        onClick={() => this.props.onDateSelected(this.state.selectedDate || new Date())}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

const connectedComponent = injectIntl(AppointmentCalendarModal);
export { connectedComponent as AppointmentCalendarModal };