import { SubcontractorsBillingFilters, SubcontractorFilter } from './types';
import { ThunkAction } from 'redux-thunk';
import { loadSubcontractorsBills } from './api';
import { ApplicationState } from '../../../state/ducks';
import { SubcontractorBill } from '../../../state/models';
import { AppThunk } from '../../../state/store';

export enum ActionTypeKeys {
    LOAD_SUBCONTRACTORS_BILLS_REQUEST = 'REPORTING_LOAD_SUBCONTRACTORS_BILLS_REQUEST',
    LOAD_SUBCONTRACTORS_BILLS_SUCCESS = 'REPORTING_LOAD_SUBCONTRACTORS_BILLS_SUCCESS',
    LOAD_SUBCONTRACTORS_BILLS_FAILURE = 'REPORTING_LOAD_SUBCONTRACTORS_BILLS_FAILURE',
    SET_SUBCONTRACTORS_BILLING_FILTERS = 'REPORTING_SET_SUBCONTRACTORS_BILLING_FILTERS'
}

export interface LoadSubcontractorsBillsRequestAction { type: ActionTypeKeys.LOAD_SUBCONTRACTORS_BILLS_REQUEST; }
export interface LoadSubcontractorsBillsSuccessAction { type: ActionTypeKeys.LOAD_SUBCONTRACTORS_BILLS_SUCCESS; payload: SubcontractorBill[]; }
export interface LoadSubcontractorsBillsFailureAction { type: ActionTypeKeys.LOAD_SUBCONTRACTORS_BILLS_FAILURE; payload: Error; }

export interface SetSubcontractorsBillingFiltersAction { type: ActionTypeKeys.SET_SUBCONTRACTORS_BILLING_FILTERS; payload: SubcontractorsBillingFilters; }

export type ActionTypes =
    | LoadSubcontractorsBillsRequestAction | LoadSubcontractorsBillsSuccessAction | LoadSubcontractorsBillsFailureAction
    | SetSubcontractorsBillingFiltersAction
    | { type: '' };

export const actionCreators = {
    loadSubcontractorsBills: (startDate: Date, endDate: Date, filters: SubcontractorsBillingFilters): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_SUBCONTRACTORS_BILLS_REQUEST });

            loadSubcontractorsBills(startDate, endDate, filters)
                .then(bills => {
                    dispatch({ type: ActionTypeKeys.LOAD_SUBCONTRACTORS_BILLS_SUCCESS, payload: bills });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_SUBCONTRACTORS_BILLS_FAILURE, payload: error });
                });
        };
    },

    setFilters: (filters: SubcontractorsBillingFilters): SetSubcontractorsBillingFiltersAction => {
        return {
            type: ActionTypeKeys.SET_SUBCONTRACTORS_BILLING_FILTERS,
            payload: filters
        };
    },
};