import { DefectiveRatioGroup } from "containers/reporting/types";
import React from "react";
import { DetailedStatisticsSalesFilters } from "state/models/filters/DetailedStatisticsSalesFilters";

export interface DetailedStatisticsContextType {
  defectiveRatios: {
    filters: DetailedStatisticsSalesFilters;
    selectedGroupType: DefectiveRatioGroup;
    selectedGroupId?: string | number;
    changeFilters: (newFilters: DetailedStatisticsSalesFilters) => void;
    changeSelectedGroupId: (newSelectedGroupId: number | string | undefined) => void;
    changeSelectedGroupType: (newSelectedGroupType: DefectiveRatioGroup) => void;
  };
}

const DetailedStatisticsContext = React.createContext<DetailedStatisticsContextType | null>(
  null
);

interface DetailedStatisticsProviderProps {
  value: DetailedStatisticsContextType;
  children?: React.ReactNode;
}

export const DetailedStatisticsProvider: React.FC<DetailedStatisticsProviderProps> = ({
  value,
  children,
}) => {
  return (
    <DetailedStatisticsContext.Provider value={value}>
      {children}
    </DetailedStatisticsContext.Provider>
  );
};

export const useDetailedStatisticsContext = (): DetailedStatisticsContextType =>
  React.useContext(DetailedStatisticsContext) as DetailedStatisticsContextType;
