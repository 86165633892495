export interface ServiceCallFeesEstimate {
    id: number;
    serviceCallId: number;
    partsFees: number;
    labourFees: number;
    shippingFees: number;
    currencyCode: string;
    approvedOn?: Date;
    isApproved: boolean;
}

export const emptyServiceCallFeesEstimate: ServiceCallFeesEstimate = {
    id: 0,
    serviceCallId: 0,
    partsFees: 0,
    labourFees: 0,
    shippingFees: 0,
    currencyCode: 'CAD',
    isApproved: false
};