import React from "react";
import { Form, Label } from "semantic-ui-react";
import { FormattedNumber, defineMessages, useIntl } from "react-intl";
import { formatQuantity, ProductionOrder } from "../../../../state/models";
import { Order } from "state/models/Order";
import { OrderDetail } from "state/models/OrderDetail";
import { commonMessages } from "../../../../constants";
import { OrderSection } from "./OrderSection";
import { StringHelper } from "state/utils";
import { OrderItemViewStatusLabel } from "./OrderItemViewStatusLabel";

const m = defineMessages({
  showroomTag: { id: "OrderItemView.showroomTag", defaultMessage: "Showroom" },
  comboTag: { id: "OrderItemView.comboTag", defaultMessage: "Combo" },
  webTag: { id: "OrderItemView.webTag", defaultMessage: "Web" },
  notesSection: { id: "OrderItemView.notesSection", defaultMessage: "Notes" },
  itemNotesLabel: { id: "OrderItemView.itemNotesLabel", defaultMessage: "Item notes" },
  orderNotesLabel: { id: "OrderItemView.orderNotesLabel", defaultMessage: "Order notes" },
  productionSection: { id: "OrderItemView.productionSection", defaultMessage: "Production" },
  productionOrderLabel: {
    id: "OrderItemView.productionOrderLabel",
    defaultMessage: "Production order",
  },
  lotLabel: { id: "OrderItemView.lotLabel", defaultMessage: "Lot" },
  statusLabel: { id: "OrderItemView.statusLabel", defaultMessage: "Status" },
  billingSection: { id: "OrderItemView.billingSection", defaultMessage: "Billing" },
  quantityLabel: { id: "OrderItemView.quantityLabel", defaultMessage: "Quantity" },
  priceLabel: { id: "OrderItemView.priceLabel", defaultMessage: "Unit price" },
  totalPriceLabel: { id: "OrderItemView.totalPriceLabel", defaultMessage: "Total price" },
  noNotesMessage: { id: "OrderItemView.noNotesMessage", defaultMessage: "No notes" },
});

interface OrderItemViewDetailsProps {
  order: Order;
  item: OrderDetail;
  productionOrder: ProductionOrder | undefined;
}

export const OrderItemViewDetails: React.FC<OrderItemViewDetailsProps> = ({
  order,
  item,
  productionOrder,
}) => {
  const { formatMessage } = useIntl();

  const noNotes = (
    <p style={{ color: "rgba(0,0,0,.45)", margin: 0 }}>{formatMessage(m.noNotesMessage)}</p>
  );

  const notApplicable = (
    <p style={{ color: "rgba(0,0,0,.45)", margin: 0 }}>
      {formatMessage(commonMessages.notApplicableAbbreviation)}
    </p>
  );

  const noteSection = (
    <>
      <OrderSection title={formatMessage(m.notesSection)} />
      <Form>
        <Form.Group widths='equal'>
          <Form.Field>
            <label>{formatMessage(m.itemNotesLabel)}</label>
            {StringHelper.hasValue(item.notes) ? (
              <p style={{ whiteSpace: "pre" }}>{item.notes}</p>
            ) : (
              noNotes
            )}
          </Form.Field>
          <Form.Field>
            <label>{formatMessage(m.orderNotesLabel)}</label>
            {StringHelper.hasValue(order.notes) ? (
              <p style={{ whiteSpace: "pre" }}>{order.notes}</p>
            ) : (
              noNotes
            )}
          </Form.Field>
        </Form.Group>
      </Form>
    </>
  );

  const productionSection = (
    <>
      <OrderSection title={formatMessage(m.productionSection)} />
      <Form>
        <Form.Group widths='equal'>
          <Form.Field>
            <label>{formatMessage(m.productionOrderLabel)}</label>
            {productionOrder ? <p>{productionOrder.id}</p> : notApplicable}
          </Form.Field>
          <Form.Field>
            <label>{formatMessage(m.lotLabel)}</label>
            {productionOrder && productionOrder.lot ? (
              <p>{productionOrder.lot.name}</p>
            ) : (
              notApplicable
            )}
          </Form.Field>
          <Form.Field>
            <label>{formatMessage(m.statusLabel)}</label>
            <OrderItemViewStatusLabel item={item} />
          </Form.Field>
        </Form.Group>
      </Form>
    </>
  );

  const billingSection = (
    <>
      <OrderSection title={formatMessage(m.billingSection)} />
      <Form>
        <Form.Group widths='equal'>
          <Form.Field>
            <label>{formatMessage(m.quantityLabel)}</label>
            {formatQuantity(item.quantityOrdered)}
          </Form.Field>
          <Form.Field>
            <label>{formatMessage(m.priceLabel)}</label>
            <FormattedNumber value={item.price} style='currency' currency={order.currency} />
          </Form.Field>
          <Form.Field>
            <label>{formatMessage(m.totalPriceLabel)}</label>
            <FormattedNumber
              value={item.price * item.quantityOrdered.value}
              style='currency'
              currency={order.currency}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </>
  );

  return (
    <>
      <Label.Group style={{ paddingLeft: "35px" }}>
        {item.isShowroom && <Label content={formatMessage(m.showroomTag)} />}
        {item.isCombo && <Label content={formatMessage(m.comboTag)} />}
        {item.isWeb && <Label content={formatMessage(m.webTag)} />}
      </Label.Group>
      {noteSection}
      {productionSection}
      {billingSection}
    </>
  );
};
