import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';
import { Label, LabelProps, SemanticCOLORS, Popup, List, SemanticICONS } from 'semantic-ui-react';
import { WarrantyType, WarrantyStatus } from '../../state/models';
import { WarrantyHelper } from '../../state/utils';

export interface WarrantyStatusLabelProps extends LabelProps {
    warrantyType: WarrantyType;
    warrantyStartDate?: Date;
}

const m = defineMessages({
    warrantyStartDate: { id: 'WarrantyStatusLabel.warranty_start_date', defaultMessage: 'Warranty start date' },
    warrantyType: { id: 'WarrantyStatusLabel.warranty_type', defaultMessage: 'Warranty type' },
    expiresOn: { id: 'WarrantyStatusLabel.expires_on', defaultMessage: 'Expires on' },
    expiredSince: { id: 'WarrantyStatusLabel.expired_since', defaultMessage: 'Expired on' },
    underWarranty: { id: 'WarrantyStatusLabel.under_warranty', defaultMessage: 'Under warranty' },
    warrantyExpired: { id: 'WarrantyStatusLabel.warranty_expired', defaultMessage: 'Expired' },
    notUnderWarranty: { id: 'WarrantyStatusLabel.no_under_warranty', defaultMessage: 'No warranty' },
    warrantyLengthInYears: { id: 'WarrantyStatusLabel.warranty_length_in_years', defaultMessage: '{years, plural, one {1 year} other {{years} years}}' },
    warrantyLifetimeLength: { id: 'WarrantyStatusLabel.warranty_lifetime_length', defaultMessage: 'Limited lifetime' },
    warrantySpecifiedByLaw: { id: 'WarrantyStatusLabel.warranty_specified_by_law', defaultMessage: 'Consumer Protection Act (90 days)' },
    noWarranty: { id: 'WarrantyStatusLabel.no_warranty', defaultMessage: 'This defect type is not covered by Jaymar\'s warranty' }
});

interface WarrantyStatusContent {
    description: MessageDescriptor;
    icon: SemanticICONS;
    color: SemanticCOLORS;
}

class WarrantyStatusLabel extends React.Component<WarrantyStatusLabelProps & WrappedComponentProps, {}> {
    readonly noWarranty: WarrantyStatusContent = {
        description: m.notUnderWarranty,
        icon: 'dont',
        color: 'red'
    };

    readonly warrantyStatusMap: Map<WarrantyStatus, WarrantyStatusContent> = new Map<WarrantyStatus, WarrantyStatusContent>([
        [WarrantyStatus.None, this.noWarranty],
        [WarrantyStatus.Expired, { description: m.warrantyExpired, icon: 'calendar times', color: 'red' }],
        [WarrantyStatus.UnderWarranty, { description: m.underWarranty, icon: 'check', color: 'green' }]
    ]);

    public render() {
        return (
            <Popup
                trigger={this.renderWarrantyStatusLabel()}
                content={this.renderWarrantyStatusInformation()}
                flowing={true}
            />
        );
    }

    private renderWarrantyStatusLabel() {
        const { formatMessage } = this.props.intl;
        const { warrantyType, warrantyStartDate, intl, ...labelProps } = this.props;

        const warrantyStatus = WarrantyHelper.getStatus(warrantyType, warrantyStartDate);
        const warrantyStatusContent = this.warrantyStatusMap.get(warrantyStatus) || this.noWarranty;

        return (
            <Label
                icon={warrantyStatusContent.icon}
                content={formatMessage(warrantyStatusContent.description)}
                color={warrantyStatusContent.color}
                {...labelProps}
            />
        );
    }

    private renderWarrantyStatusInformation() {
        const { formatMessage } = this.props.intl;
        const { warrantyType, warrantyStartDate } = this.props;
        const warrantyExpiryDate = WarrantyHelper.getExpiryDate(warrantyType, warrantyStartDate);
        const warrantyTypeDescription = this.getWarrantyTypeDescription();
        const doesExpire = this.props.warrantyType !== WarrantyType.LifetimeLimited;
        const isExpired = warrantyExpiryDate ? moment() > warrantyExpiryDate : true;

        return (
            <List>
                {warrantyStartDate &&
                    <List.Item header={formatMessage(m.warrantyStartDate)} content={moment(warrantyStartDate).format('LL')} />}
                <List.Item header={formatMessage(m.warrantyType)} content={warrantyTypeDescription} />
                {warrantyExpiryDate && !isExpired && doesExpire &&
                    <List.Item header={formatMessage(m.expiresOn)} content={moment(warrantyExpiryDate).add(1, 'day').format('LL')} />}
                {warrantyExpiryDate && isExpired &&
                    <List.Item header={formatMessage(m.expiredSince)} content={moment(warrantyExpiryDate).add(1, 'day').format('LL')} />}
            </List>
        );
    }

    private getWarrantyTypeDescription(): string {
        const { formatMessage } = this.props.intl;
        const { warrantyType } = this.props;

        switch (warrantyType) {
            case WarrantyType.OneYear:
            case WarrantyType.TwoYears:
            case WarrantyType.ThreeYears:
            case WarrantyType.FourYears:
            case WarrantyType.FiveYears:
                return formatMessage(m.warrantyLengthInYears, { years: warrantyType as number });

            case WarrantyType.SpecifiedByLaw:
                return formatMessage(m.warrantySpecifiedByLaw);

            case WarrantyType.LifetimeLimited:
                return formatMessage(m.warrantyLifetimeLength);

            default:
                return formatMessage(m.noWarranty);
        }
    }
}

const connectedComponent = injectIntl(WarrantyStatusLabel);
export { connectedComponent as WarrantyStatusLabel };