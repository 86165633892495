import { RepairType } from '../components/service-calls';
import { ServiceCallDefectiveItem, ServiceCallFeesEstimate, SubcontractorRepairWarrantyStatus, TechnicianAppointment } from '../state/models';
import { BaseService } from './baseService';

export class CustomerServiceService extends BaseService {
    constructor() {
        super('api/service-calls');
    }

    public assignAgent(serviceCallId: number, agentEmail: string) {
        const serviceUrl = this.getServiceUrl(serviceCallId, 'agent');
        return this.post(serviceUrl, agentEmail);
    }

    public assignRepresentative(serviceCallId: number, representativeId: string) {
        const serviceUrl = this.getServiceUrl(serviceCallId, 'representative');
        return this.post(serviceUrl, representativeId);
    }

    public confirmInspectionCompletion(serviceCallId: number, inspectionId: number) {
        const serviceUrl = this.getServiceUrl(serviceCallId, `inspection/${inspectionId}/complete`);
        return this.post(serviceUrl, {});
    }

    public createSubcontractorRepairsRequest(serviceCallId: number, subcontractorId: number | undefined, otherSubcontractorName: string | undefined, email: string | undefined, locale: string | undefined) {
        const serviceUrl = this.getServiceUrl(serviceCallId, 'subcontractor');
        return this.post(serviceUrl, {
            subcontractorId: subcontractorId,
            otherSubcontractorName: otherSubcontractorName,
            email,
            locale
        });
    }

    public createTechnicianRepairsRequest(serviceCallId: number, technicianId: string) {
        const serviceUrl = this.getServiceUrl(serviceCallId, 'technician-repairs');
        return this.post(serviceUrl, technicianId);
    }

    public saveTechnicianAppointments(serviceCallId: number, technicianRepairsId: number, appointments: TechnicianAppointment[]) {
        const serviceUrl = this.getServiceUrl(serviceCallId, `technician-repairs/${technicianRepairsId}/appointment`);
        return this.post(serviceUrl, appointments);
    }

    public confirmTechnicianRepairsCompletion(serviceCallId: number, technicianRepairsId: number) {
        const serviceUrl = this.getServiceUrl(serviceCallId, `technician-repairs/${technicianRepairsId}/complete`);
        return this.post(serviceUrl, {});
    }

    public scheduleFactoryRepairs(serviceCallId: number, factoryRepairsId: number, scheduledFor: Date) {
        const serviceUrl = this.getServiceUrl(serviceCallId, `factory-repairs/${factoryRepairsId}/schedule-for/${scheduledFor.toISOString()}`);
        return this.post(serviceUrl, {});
    }

    public confirmFurnitureReceptionForFactoryRepairs(serviceCallId: number, factoryRepairsId: number) {
        const serviceUrl = this.getServiceUrl(serviceCallId, `factory-repairs/${factoryRepairsId}`);
        return this.post(serviceUrl, {});
    }

    public confirmFactoryRepairsCompletion(serviceCallId: number, factoryRepairsId: number) {
        const serviceUrl = this.getServiceUrl(serviceCallId, `factory-repairs/${factoryRepairsId}/complete`);
        return this.put(serviceUrl, {});
    }

    public setSubcontractorAppointmentDate(serviceCallId: number, subcontractorRepairId: number, appointmentDate: Date | undefined) {
        const serviceUrl = this.getServiceUrl(serviceCallId, `subcontractor/${subcontractorRepairId}/appointment`);
        return this.post(serviceUrl, appointmentDate);
    }

    public setSubcontractorRepairWarrantyStatus(serviceCallId: number, subcontractorRepairId: number, warrantyStatus: SubcontractorRepairWarrantyStatus) {
        const serviceUrl = this.getServiceUrl(serviceCallId, `subcontractor/${subcontractorRepairId}/warranty-status`);
        return this.post(serviceUrl, warrantyStatus);
    }

    public addBill(serviceCallId: number, subcontractorRepairId: number, repairFees: number, shippingFees: number, currencyCode: string, referenceNumber: string) {
        const serviceUrl = this.getServiceUrl(serviceCallId, `subcontractor/${subcontractorRepairId}/bill`);
        return this.post(serviceUrl, {
            repairFees,
            shippingFees,
            currencyCode,
            referenceNumber
        });
    }

    public addInvoice(serviceCallId: number, subcontractorRepairId: number, invoice: File) {
        const serviceUrl = this.getServiceUrl(serviceCallId, `subcontractor/${subcontractorRepairId}/invoice`);

        let formData = new FormData();
        formData.append('invoice', invoice);

        return this.postFormData(serviceUrl, formData);
    }

    public createCreditReturnRequest(serviceCallId: number, email: string | undefined, locale: string | undefined) {
        const serviceUrl = this.getServiceUrl(serviceCallId, 'credit-return');
        return this.post(serviceUrl, { email, locale });
    }

    public confirmFurnitureReceptionForCreditReturn(serviceCallId: number, creditReturnId: number) {
        const serviceUrl = this.getServiceUrl(serviceCallId, `credit-return/${creditReturnId}`);
        return this.post(serviceUrl, {});
    }

    public addCreditForCreditReturn(serviceCallId: number, creditReturnId: number, amountCredited: number, shippingFeesCredited: number, currencyCode: string) {
        const serviceUrl = this.getServiceUrl(serviceCallId, `credit-return/${creditReturnId}/credit`);
        return this.post(serviceUrl, {
            creditReturnId,
            amountCredited,
            shippingFeesCredited,
            currencyCode
        });
    }

    public createFactoryRepairsRequest(serviceCallId: number, email: string | undefined, locale: string | undefined) {
        const serviceUrl = this.getServiceUrl(serviceCallId, 'factory-repairs');
        return this.post(serviceUrl, { email, locale });
    }

    public cancelCurrentRepairs(serviceCallId: number, repairType: RepairType) {
        const serviceUrl = this.getServiceUrl(serviceCallId, 'cancel-repairs');
        return this.post(serviceUrl, {});
    }

    public addDefect(serviceCallId: number, defectId: number): Promise<any> {
        const serviceUrl = this.getServiceUrl(serviceCallId, 'defects');
        return this.put(serviceUrl, {
            serviceCallId,
            defectId
        });
    }

    public removeDefect(serviceCallId: number, defectId: number): Promise<any> {
        const serviceUrl = this.getServiceUrl(serviceCallId, `defects/${defectId}`);
        return this.delete(serviceUrl, {});
    }

    public addDefectiveItem(serviceCallId: number, itemId: string, quantity?: number): Promise<any> {
        const serviceUrl = this.getServiceUrl(serviceCallId, 'defective-items');

        return this.put(serviceUrl, {
            serviceCallId,
            itemId,
            quantity
        });
    }

    public changeDefectiveItems(serviceCallId: number, defectiveItems: ServiceCallDefectiveItem[]): Promise<any> {
        const serviceUrl = this.getServiceUrl(serviceCallId, 'defective-items');
        const defectiveItemsModel = defectiveItems.map(x => ({
            ...x,
            serviceCall: undefined,
            item: undefined,
            itemCover: undefined
        }));

        return this.put(serviceUrl, defectiveItemsModel);
    }

    public removeDefectiveItem(serviceCallId: number, itemId: string): Promise<any> {
        const serviceUrl = this.getServiceUrl(serviceCallId, `defective-items/${itemId}`);
        return this.delete(serviceUrl, {});
    }

    public close(serviceCallId: number): Promise<any> {
        const serviceUrl = this.getServiceUrl(serviceCallId, 'close');
        return this.post(serviceUrl, {});
    }

    public reopen(serviceCallId: number): Promise<any> {
        const serviceUrl = this.getServiceUrl(serviceCallId, 'reopen');
        return this.post(serviceUrl, {});
    }

    public cancel(serviceCallId: number): Promise<any> {
        const serviceUrl = this.getServiceUrl(serviceCallId, 'cancel');
        return this.delete(serviceUrl, {});
    }

    public linkNew(serviceCallId: number): Promise<any> {
        const serviceUrl = this.getServiceUrl(serviceCallId, 'link-new');
        return this.post(serviceUrl, {});
    }

    public updateEstimatedFees(serviceCallId: number, estimatedFees: ServiceCallFeesEstimate): Promise<any> {
        const serviceUrl = this.getServiceUrl(serviceCallId, 'fees');
        return this.put(serviceUrl, estimatedFees);
    }

    private getServiceUrl(serviceCallId: number, url: string): string {
        return `/${serviceCallId}/customer-service/${url}`;
    }
}

export default CustomerServiceService;