import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { defineMessages, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Form, FormProps } from "semantic-ui-react";
import { TooltipIcon } from "../../../../components/common";
import { BillSearchControl, ClientSearchControl } from "../../../../containers/search";
import { Bill, Client } from "../../../../state/models";
import { isCurrentUserAnEmployee } from "../../../account/selectors";
import { ServiceCallEdit } from "../../models";

interface OwnProps {
    selectedBill: Bill | undefined;
    selectedClient: Client | undefined;
    isLoadingBill?: boolean;

    onBillSelected: (bill: Bill) => void;
    onBillMouseOver: (bill: Bill) => void;
    onClientSelected: (client: Client | undefined) => void;
}

export type OrderInformationFormProps =
    & FormProps
    & OwnProps;

const m = defineMessages({
    clientBillLabel: { id: 'OrderInformationForm.search_for_bill', defaultMessage: 'Your order' },
    employeeBillLabel: { id: 'OrderInformationForm.employeeBillLabel', defaultMessage: 'Bill number' },
    billRequired: { id: 'OrderInformationForm.billRequired', defaultMessage: 'Please enter your Jaymar bill number or your PO number.' },
    referenceNumber: { id: 'OrderInformationForm.reference_number', defaultMessage: 'Your reference number' },
    referenceNumberPlaceholder: { id: 'OrderInformationForm.reference_number_placeholder', defaultMessage: 'Optional' },
    searchForClient: { id: 'OrderInformationForm.search_for_client', defaultMessage: 'Client' },
    clientContactField: { id: 'OrderInformationForm.client_contact_field', defaultMessage: 'Contact name for the client' },
    clientContactPlaceholder: { id: 'OrderInformationForm.client_contact_placeholder', defaultMessage: 'Optional' }, 
    clientLabel: { id: 'OrderInformationForm.clientLabel', defaultMessage: 'Client' },
    clientRequired: { id: 'OrderInformationForm.clientRequired', defaultMessage: 'Please link a client to this service call.' },
    clientTooltip: { id: 'OrderInformationForm.clientTooltip', defaultMessage: 'If you already have the Jaymar bill number, you can leave this field blank and specify the bill number in the next field. The appropriate client will automatically be selected.' }
});

export const OrderInformationForm: React.FC<OrderInformationFormProps> = (props) => {
    const { formatMessage } = useIntl();
    const { selectedBill, isLoadingBill, onBillSelected, onBillMouseOver, ...formProps } = props;
    const { control, formState: { errors }, setValue } = useFormContext<ServiceCallEdit>();
    const isJaymarEmployee = useSelector(isCurrentUserAnEmployee);

    const selectBill = (bill: Bill) => {
        setValue('billId', bill.id);
        setValue('clientId', bill.clientId);
        props.onBillSelected(bill);
    }

    const selectClient = (client: Client | undefined) => {
        setValue('billId', undefined);
        setValue('clientId', client?.id || '');
        props.onClientSelected(client);
    }

    return (
        <Form {...formProps}>
            {isJaymarEmployee &&
                <Controller
                    name="clientId"
                    control={control}
                    rules={{ required: formatMessage(m.clientRequired) }}
                    render={() => (
                        <Form.Field required error={errors.clientId != null}>
                            <label>
                                {formatMessage(m.clientLabel)}
                                <TooltipIcon content={formatMessage(m.clientTooltip)} style={{ marginLeft: '2px'}} />
                            </label>
                            <ClientSearchControl
                                value={props.selectedClient}
                                onResultSelected={selectClient}
                            />
                        </Form.Field>
                    )}
                />

            }
            <Controller
                name="billId"
                control={control}
                rules={{ required: isJaymarEmployee ? false : formatMessage(m.billRequired) }}
                render={() => (
                    <Form.Field required={!isJaymarEmployee} error={errors.billId != null}>
                        <label>{isJaymarEmployee ? formatMessage(m.employeeBillLabel) : formatMessage(m.clientBillLabel)}</label>
                        <BillSearchControl
                            clientId={undefined}
                            value={props.selectedBill}
                            onResultSelected={selectBill}
                            onResultMouseOver={onBillMouseOver}
                        />
                    </Form.Field>
                )}
            />

            <Controller
                name="referenceNumber"
                control={control}
                render={() => (
                    <Form.Input 
                        label={formatMessage(m.referenceNumber)}
                        placeholder={formatMessage(m.referenceNumberPlaceholder)}
                        onChange={(e, data) => setValue('referenceNumber', data.value)}
                    />
                )}
            />
        </Form>
    );
};