import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Icon, Label, LabelProps, Popup } from 'semantic-ui-react';

export interface PurchaseLabelProps extends LabelProps {

}

const m = defineMessages({
    description: { id: 'PurchaseLabel.description', defaultMessage: 'This item is purchasable from a third party supplier.' }
});

class PurchaseLabel extends React.Component<PurchaseLabelProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <Popup trigger={this.renderLabel()} content={formatMessage(m.description)} />
        );
    }

    private renderLabel() {
        const { intl, ...labelProps } = this.props;

        return (
            <Label horizontal={true} color="yellow" style={{margin: '0', minWidth: 0}} {...labelProps}>
                <Icon name="dollar" style={{margin: 0}} />
            </Label>
        );
    }
}

const intlComponent = injectIntl(PurchaseLabel);
export { intlComponent as PurchaseLabel };