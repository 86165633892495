import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Dimmer, Loader, Table, Button, Label, SemanticCOLORS, Popup } from 'semantic-ui-react';

import { TechnicianRepair, Defect, AppointmentTimeRange, ServiceCallDefect } from '../../../state/models';
import { commonMessages, defectTypeMessages, defectCategoryMessages } from '../../../constants';
import { LoadingContainer, NoContentSegment, LoadingDimmer } from '../../common';
import { UserHelper, DateHelper } from '../../../helpers';

interface AppointmentsTableOwnProps {
    appointments: TechnicianRepair[];
    onViewDetails: (appointment: TechnicianRepair) => void;
    isLoading?: boolean;
    emptyTitle?: string;
    emptySubtitle?: string;
}

export type AppointmentsTableProps =
    & AppointmentsTableOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    idHeader: { id: 'AppointmentsTable.id_header', defaultMessage: '#' },
    dateHeader: { id: 'AppointmentsTable.date_header', defaultMessage: 'Date' },
    cityHeader: { id: 'AppointmentsTable.city_header', defaultMessage: 'City' },
    agentHeader: { id: 'AppointmentsTable.agent_header', defaultMessage: 'Agent' },
    defectHeader: { id: 'AppointmentsTable.defect_header', defaultMessage: 'Defect' },
    noAppointmentsTitle: { id: 'AppointmentsTable.no_appointments_title', defaultMessage: 'No appointments' },
    noAppointmentsSubtitle: { id: 'AppointmentsTable.no_appointments_subtitle', defaultMessage: 'There are no service call appointments.' }
});

class AppointmentsTable extends React.Component<AppointmentsTableProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const hasAppointments = this.props.appointments && this.props.appointments.length > 0;
        const rows = this.props.appointments.map(x => this.renderRow(x));

        return (
            <LoadingContainer>
                <LoadingDimmer active={this.props.isLoading} />

                {hasAppointments &&
                    <Table basic="very">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell collapsing={true}>{formatMessage(m.idHeader)}</Table.HeaderCell>
                                <Table.HeaderCell collapsing={true} />
                                <Table.HeaderCell>{formatMessage(m.dateHeader)}</Table.HeaderCell>
                                <Table.HeaderCell>{formatMessage(m.cityHeader)}</Table.HeaderCell>
                                <Table.HeaderCell>{formatMessage(m.agentHeader)}</Table.HeaderCell>
                                <Table.HeaderCell>{formatMessage(m.defectHeader)}</Table.HeaderCell>
                                <Table.HeaderCell collapsing={true} />
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {rows}
                        </Table.Body>
                    </Table>
                }

                {!hasAppointments &&
                    <NoContentSegment
                        title={this.props.emptyTitle || formatMessage(m.noAppointmentsTitle)}
                        subtitle={this.props.emptySubtitle || formatMessage(m.noAppointmentsSubtitle)}
                    />
                }
            </LoadingContainer>
        );
    }

    private renderRow(appointment: TechnicianRepair) {
        const { formatMessage } = this.props.intl;

        const appointmentCity = appointment.serviceCall && appointment.serviceCall.customer && appointment.serviceCall.customer.address
            ? appointment.serviceCall.customer.address.city || formatMessage(commonMessages.unknown)
            : formatMessage(commonMessages.unknown);

        const agentAssigned = appointment.serviceCall
            ? UserHelper.getDisplayName(appointment.serviceCall.assignedTo)
            : '';

        const hasDefects = appointment.serviceCall && appointment.serviceCall.defects && appointment.serviceCall.defects.length > 0;

        return (
            <Table.Row key={appointment.id}>
                <Table.Cell>
                    <a style={{ cursor: 'pointer' }} onClick={() => this.props.onViewDetails(appointment)}>
                        {appointment.serviceCallId}
                    </a>
                </Table.Cell>
                <Table.Cell>
                    {this.renderTime(appointment.scheduledForTimeRange)}
                </Table.Cell>
                <Table.Cell>
                    {appointment.scheduledForDate &&
                        <span>{moment.utc(appointment.scheduledForDate).format('LL')}</span>
                    }
                </Table.Cell>
                <Table.Cell>{appointmentCity}</Table.Cell>
                <Table.Cell>{agentAssigned}</Table.Cell>
                <Table.Cell>
                    {hasDefects &&
                        this.renderDefects(appointment.serviceCall.defects)
                    }
                </Table.Cell>
                <Table.Cell>
                    <Button
                        content={formatMessage(commonMessages.view)}
                        onClick={() => this.props.onViewDetails(appointment)}
                    />
                </Table.Cell>
            </Table.Row>
        );
    }

    private renderDefects(defects: ServiceCallDefect[]) {
        const { formatMessage } = this.props.intl;

        if (defects && defects.length > 0) {
            const defect = defects[0].defect;

            const typeMessages = Object.keys(defectTypeMessages).map(key => defectTypeMessages[key]);
            const defectTypeMessage = typeMessages.find(x => x.id === defect.defectType.title);
            const defectType = defectTypeMessage ? formatMessage(defectTypeMessage) : defect.defectType.title;

            const categoryMessages = Object.keys(defectCategoryMessages).map(key => defectCategoryMessages[key]);
            const defectCategoryMessage = categoryMessages.find(x => x.id === defect.defectCategory.title);
            const defectCategory = defectCategoryMessage ? formatMessage(defectCategoryMessage) : defect.defectCategory.title;

            return (
                <p>
                    <span>{defectType}</span>
                    <span style={{ color: 'rgba(0,0,0,0.4)' }}> ({defectCategory})</span>
                </p>
            );
        }

        return (null);
    }

    private renderTime(timeRange: AppointmentTimeRange | undefined) {
        if (timeRange != null) {
            const { formatMessage } = this.props.intl;
            const colorMap = new Map<AppointmentTimeRange, SemanticCOLORS>([
                [AppointmentTimeRange.morning, 'orange'],
                [AppointmentTimeRange.afternoon, 'violet'],
                [AppointmentTimeRange.unspecified, 'grey']
            ]);

            const trigger = (
                <Label
                    color={colorMap.get(timeRange)}
                    content={formatMessage(DateHelper.getTimeRangeAbbreviation(timeRange))}
                    style={{ minWidth: 42, textAlign: 'center' }}
                />
            );

            return (
                <Popup
                    trigger={trigger}
                    content={formatMessage(DateHelper.getTimeRangeDescription(timeRange))}
                />
            );
        }

        return (null);
    }
}

const connectedComponent = injectIntl(AppointmentsTable);
export { connectedComponent as AppointmentsTable };