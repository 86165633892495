import { createStore, applyMiddleware, combineReducers, Action, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk, { ThunkAction } from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { ApplicationState } from './ducks';
import administrationReducer from '../modules/administration';
import appointmentsReducer from './ducks/appointments';
import calendarReducer from './ducks/calendar';
import currentUserReducer from './ducks/current-user';
import customerServiceReducer from '../modules/customer-service';
import defectsReducer from './ducks/defects';
import factoryRepairsReducer from './ducks/factory-repairs';
import globalNotificationReducer from './ducks/global-notification';
import i18nReducer from '../modules/i18n';
import inspectionsReducer from './ducks/inspections';
import notesReducer from '../modules/notes';
import notificationsReducer from './ducks/notifications';
import ordersReducer from '../modules/orders';
import productionOrdersReducer from './ducks/production-orders';
import reportingReducer from './ducks/reporting';
import searchReducer from './ducks/search';
import serviceCallsReducer from './ducks/service-calls';
import techniciansReducer from './ducks/technicians';
import uiReducer from './ducks/ui';
import usersReducer from './ducks/users';
import accountReducer from '../modules/account';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import applicationSagas from '../modules/application/sagas';
import { clientsReducer } from '../modules/clients/reducer';

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, ApplicationState, unknown, Action>;

const rootReducer = (history: History) => combineReducers({
    router: connectRouter(history),
    administration: administrationReducer,
    account: accountReducer,
    appointments: appointmentsReducer,
    calendar: calendarReducer,
    clients: clientsReducer,
    currentUser: currentUserReducer,
    customerService: customerServiceReducer,
    defects: defectsReducer,
    factoryRepairs: factoryRepairsReducer,
    globalNotification: globalNotificationReducer,
    i18n: i18nReducer,
    inspections: inspectionsReducer,
    notes: notesReducer,
    notifications: notificationsReducer,
    orders: ordersReducer,
    productionOrders: productionOrdersReducer,
    reporting: reportingReducer,
    search: searchReducer,
    serviceCalls: serviceCallsReducer,
    technicians: techniciansReducer,
    ui: uiReducer,
    users: usersReducer
});

function* rootSaga() {
    yield all([
        fork(applicationSagas)
    ]);
}

export default function configureStore(history: History, initialState: ApplicationState): Store<ApplicationState> {
    const composeEnhancer = composeWithDevTools({});
    const sagaMiddleware = createSagaMiddleware();

    const store = createStore(
        rootReducer(history),
        initialState as any, /* TODO: Remove this once https://github.com/reduxjs/redux/pull/4078 is merged and released */
        composeEnhancer(
            applyMiddleware(
                routerMiddleware(history),
                thunk,
                sagaMiddleware,
            )
        )
    );

    sagaMiddleware.run(rootSaga);

    return store;
}