import React from "react";

interface DescriptionRowProps {
  serviceCallId: number;
  description: string;
}

export const DescriptionRow: React.FC<DescriptionRowProps> = ({
  serviceCallId,
  description,
}) => {
  return (
    <div style={{ marginBottom: "1rem", width: "100%", display: "flex" }}>
      <div style={{ color: "#898787", fontSize: "0.8rem", width: "5rem", flexShrink: 0 }}>
        {serviceCallId}
      </div>
      <div style={{ maxHeight: "3rem", overflow: "auto" }}>{description}</div>
    </div>
  );
};
