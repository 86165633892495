import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Card, List, TextArea, Form, Grid, Checkbox, Button, TextAreaProps, CheckboxProps, Divider, Message } from 'semantic-ui-react';
import { TimeInput, LoadingContainer, LoadingDimmer } from '../../common';
import { TechnicianRepairsStatus, TechnicianReport } from '../../../state/models';
import { debounce } from '../../../state/utils';
import { commonMessages } from '../../../constants';
import { TechnicianReportValidation, TechnicianReportValidator } from '../../../state/validators';

interface ServiceReportCardOwnProps {
    isLoading: boolean;
    isDisabled: boolean;
    technicianReport?: TechnicianReport;
    onSave: (technicianReport: TechnicianReport) => void;
    onNext: (technicianReport: TechnicianReport) => TechnicianReportValidation;
    onValidate: (technicianReport: TechnicianReport) => TechnicianReportValidation;
}

interface ServiceReportCardState {
    technicianReport: TechnicianReport;
    validationResult: TechnicianReportValidation | undefined;
}

export type ServiceReportCardProps =
    & ServiceReportCardOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'ServiceReportCard.title', defaultMessage: 'Service report' },
    subtitle: { id: 'ServiceReportCard.subtitle', defaultMessage: 'Write the details about the work that was performed.' },
    repairStatusLabel: { id: 'ServiceReportCard.repair_status_label', defaultMessage: 'Repair status' },
    complete: { id: 'ServiceReportCard.complete', defaultMessage: 'Complete' },
    incomplete: { id: 'ServiceReportCard.incomplete', defaultMessage: 'Incomplete' },
    partsRequired: { id: 'ServiceReportCard.parts_required', defaultMessage: 'Parts required' },
    labourTime: { id: 'ServiceReportCard.labour_time', defaultMessage: 'Labour time' },
    incompleteReport: { id: 'ServiceReportCard.incomplete_report', defaultMessage: 'The service report is incomplete' }
});

class ServiceReportCard extends React.Component<ServiceReportCardProps, ServiceReportCardState> {
    public constructor(props: ServiceReportCardProps) {
        super(props);

        this.state = {
            technicianReport: props.technicianReport || {
                report: '',
                repairsStatus: TechnicianRepairsStatus.unknown,
                hoursTaken: 0,
                minutesTaken: 0,
                clientSignature: ''
            },
            validationResult: undefined
        };
    }

    public componentWillReceiveProps(props: ServiceReportCardProps) {
        if (props.technicianReport) {
            this.setState({
                technicianReport: props.technicianReport
            });
        }
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const { validationResult } = this.state;

        return (
            <React.Fragment>
                <Card fluid={true} color="teal" style={{height: '100%'}}>
                    <Card.Content style={{flexGrow: 0}}>
                        <Card.Header content={formatMessage(m.title)} />
                        <Card.Meta content={formatMessage(m.subtitle)} />
                    </Card.Content>
                    <Card.Content>
                        <LoadingDimmer active={this.props.isLoading} />
                        <Form error={validationResult && !validationResult.isValid()}>
                            <Grid stackable={true}>
                                <Grid.Row>
                                    <Grid.Column width={11}>
                                        <Form.Field disabled={this.props.isDisabled} error={validationResult && !validationResult.isReportValid}>
                                            <TextArea
                                                rows={10}
                                                onChange={this.handleReportChange}
                                                value={this.state.technicianReport.report || ''}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column width={5}>
                                        <Form.Field error={validationResult && !validationResult.isRepairStatusValid}>
                                            <label>{formatMessage(m.repairStatusLabel)}</label>
                                            {this.renderRepairStatusCheckbox(formatMessage(m.complete), TechnicianRepairsStatus.complete, this.props.isDisabled)}
                                            {this.renderRepairStatusCheckbox(formatMessage(m.incomplete), TechnicianRepairsStatus.incomplete, this.props.isDisabled)}
                                            {this.renderRepairStatusCheckbox(formatMessage(m.partsRequired), TechnicianRepairsStatus.partsRequired, this.props.isDisabled)}
                                        </Form.Field>

                                        <Form.Field disabled={this.props.isDisabled} error={validationResult && !validationResult.isLabourTimeValid}>
                                            <label>{formatMessage(m.labourTime)}</label>
                                            <TimeInput 
                                                hours={this.state.technicianReport.hoursTaken} 
                                                minutes={this.state.technicianReport.minutesTaken} 
                                                onChange={this.handleTimeChange} 
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            {validationResult && 
                                <Message
                                    error={true} 
                                    icon="times circle"
                                    header={formatMessage(m.incompleteReport)}
                                    list={validationResult.errors.map(x => formatMessage(x))}
                                />
                            }
                        </Form>
                    </Card.Content>
                </Card>

                <div style={{textAlign: 'right'}}>
                    <Divider />
                    <Button
                        primary={true}
                        disabled={this.state.validationResult && !this.state.validationResult.isValid()}
                        content={formatMessage(commonMessages.next)}
                        onClick={() => this.handleNextClick()}
                    />
                </div>
            </React.Fragment>
        );
    }

    private renderRepairStatusCheckbox(label: string, value: TechnicianRepairsStatus, isDisabled: boolean) {
        return (
            <Form.Checkbox
                radio={true}
                label={label}
                value={value}
                disabled={isDisabled}
                checked={this.state.technicianReport.repairsStatus === value}
                onChange={this.handleStatusChange}
            />
        );
    }

    private handleStatusChange = (_event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        if (data.checked) {
            this.setState(
                (current) => ({ 
                    technicianReport: {
                        ...current.technicianReport,
                        repairsStatus: data.value as TechnicianRepairsStatus
                    }
                }),
                () => this.validateAndSaveChanges(this.state.technicianReport));
        }
    }

    private handleReportChange = (_event: React.FormEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
        this.setState(
            (current) => ({
                technicianReport: {
                    ...current.technicianReport,
                    report: data.value as string || ''
                }
            }),
            () => this.validateAndSaveChanges(this.state.technicianReport));
    }

    private handleTimeChange = (hours: number, minutes: number) => {
        this.setState(
            (current) => ({ 
                technicianReport: {
                    ...current.technicianReport,
                    hoursTaken: hours,
                    minutesTaken: minutes
                }
            }), 
            () => this.validateAndSaveChanges(this.state.technicianReport));
    }

    private handleNextClick = () => {
        var validationResult = this.props.onNext(this.state.technicianReport);
        this.setState({ validationResult: validationResult });
    }

    private validateAndSaveChanges(report: TechnicianReport): void {
        const validationResult = this.props.onValidate(report);

        // If the report wasn't previously validated, don't display the validation result.
        // This ensures that if the report is empty initially, we won't display a bunch of
        // errors as soon as the user enters a single character.
        if (this.state.validationResult != null) {
            this.setState({ validationResult: validationResult });
        }

        this.saveChanges(report);
    }
    
    // tslint:disable-next-line:member-ordering
    private saveChanges: (report: TechnicianReport) => void = 
        debounce((report: TechnicianReport) => this.props.onSave(report), 1000);
}

const connectedComponent = injectIntl(ServiceReportCard);
export { connectedComponent as ServiceReportCard };