import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Card, Tab, Menu, Icon, Label } from 'semantic-ui-react';

import { Customer, ClientContact, CustomerEdit, ClientContactEdit, ValidationResult, ServiceCall } from '../../state/models';
import { ContactClientTab } from './ContactClientTab';
import { ContactCustomerTab } from './ContactCustomerTab';
import { EditButtons } from '../common';
import { UserHelper } from '../../helpers';

interface ContactInformationCardOwnProps {
    serviceCall: ServiceCall | undefined;
    customer: Customer | undefined;
    client: ClientContact | undefined;
    canEdit?: boolean;
    onEdit: (customerEdit: CustomerEdit, clientEdit: ClientContactEdit) => ValidationResult[];
}

interface ContactInformationCardState {
    isEditing: boolean;
    customerEdit: CustomerEdit;
    customerValidation: ValidationResult;
    clientEdit: ClientContactEdit;
    clientValidation: ValidationResult;
}

export type ContactInformationCardProps =
    & ContactInformationCardOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'ContactInformationCard.title', defaultMessage: 'Contact information' },
    customerTab: { id: 'ContactInformationCard.customer_tab', defaultMessage: 'Customer' },
    clientTab: { id: 'ContactInformationCard.client_tab', defaultMessage: 'Client' },
    emptyCustomerTitle: { id: 'ContactInformationCard.emptyCustomerTitle', defaultMessage: 'No customer information' },
    emptyCustomerDescription: { id: 'ContactInformationCard.emptyCustomerDescription', defaultMessage: 'There\'s no customer associated to this service call' },
    emptyClientTitle: { id: 'ContactInformationCard.emptyClientTitle', defaultMessage: 'No client information' },
    emptyClientDescription: { id: 'ContactInformationCard.emptyClientDescription', defaultMessage: 'There\'s no client information associated to this service call' },
    createdInformation: { id: 'ContactInformationCard.createdInformation', defaultMessage: 'Created by {name} on {date}' }
});

class ContactInformationCard extends React.Component<ContactInformationCardProps, ContactInformationCardState> {
    public constructor(props: ContactInformationCardProps) {
        super(props);

        this.state = {
            isEditing: false,
            customerEdit: new CustomerEdit(this.props.customer),
            customerValidation: ValidationResult.empty(),
            clientEdit: new ClientContactEdit(this.props.client),
            clientValidation: ValidationResult.empty()
        };
    }

    public componentWillReceiveProps(props: ContactInformationCardProps) {
        if (this.props.client == null && props.client != null) {
            this.setState({ clientEdit: new CustomerEdit(props.client) });
        }

        if (this.props.customer == null && props.customer != null) {
            this.setState({ customerEdit: new CustomerEdit(props.customer) });
        }
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const customerMenuItem = (
            <Menu.Item key="customer">
                <Icon name="user" />
                <span>{formatMessage(m.customerTab)}</span>
                {this.state.customerValidation.errorMessages.length > 0 &&
                    <Label content={this.state.customerValidation.errorMessages.length} circular={true} size="small" color="red" />
                }
            </Menu.Item>
        );

        const clientMenuItem = (
            <Menu.Item key="client">
                <Icon name="building" />
                <span>{formatMessage(m.clientTab)}</span>
                {this.state.clientValidation.errorMessages.length > 0 &&
                    <Label content={this.state.clientValidation.errorMessages.length} circular={true} size="small" color="red" />
                }
            </Menu.Item>
        );

        const contactTabs = [
            {
                menuItem: customerMenuItem, render: () => (
                    <ContactCustomerTab
                        customer={this.state.customerEdit}
                        validationResult={this.state.customerValidation}
                        isEditing={this.state.isEditing}
                        onChange={(customerEdit) => this.setState({ customerEdit })}
                    />
                )
            },
            {
                menuItem: clientMenuItem, render: () => (
                    <ContactClientTab
                        client={this.state.clientEdit}
                        validationResult={this.state.clientValidation}
                        isEditing={this.state.isEditing}
                        onChange={(contactEdit) => this.setState({ clientEdit: contactEdit })}
                    />
                )
            }
        ];

        return (
            <Card fluid={true} color="teal" style={{ height: '100%', marginTop: 0 }}>
                <Card.Content style={{ flexGrow: 0 }}>
                    <Card.Header>
                        {formatMessage(m.title)}

                        <div style={{ float: 'right' }}>
                            <EditButtons
                                onEdit={() => this.setState({ isEditing: true })}
                                onSaveEdit={this.saveChanges}
                                onCancelEdit={this.cancelChanges}
                            />
                        </div>
                    </Card.Header>
                </Card.Content>

                <Card.Content>
                    <Tab menu={{ secondary: true }} panes={contactTabs} />
                </Card.Content>

                {this.props.serviceCall &&
                    <Card.Content extra={true}>
                        <Icon name="calendar" />
                        {formatMessage(m.createdInformation, { date: moment(this.props.serviceCall.createdOn).local().format('LL'), name: UserHelper.getDisplayName(this.props.serviceCall.createdBy) })}
                    </Card.Content>
                }
            </Card>
        );
    }

    private saveChanges = (): boolean => {
        var validationResults = this.props.onEdit(this.state.customerEdit, this.state.clientEdit);
        var customerValidation = validationResults[0];
        var clientValidation = validationResults[1];
        this.setState({ customerValidation, clientValidation });

        if (customerValidation.isValid && clientValidation.isValid) {
            this.setState({ isEditing: false });
            return true;
        }

        return false;
    }

    private cancelChanges = () => {
        this.setState({
            isEditing: false,
            customerEdit: new CustomerEdit(this.props.customer),
            customerValidation: ValidationResult.empty(),
            clientEdit: new ClientContactEdit(this.props.client),
            clientValidation: ValidationResult.empty()
        });
    }
}

const connectedComponent = injectIntl(ContactInformationCard);
export { connectedComponent as ContactInformationCard };