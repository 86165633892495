import * as React from 'react';
import { Segment, Header, Icon } from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';

interface OwnProps {
    title: string;
    subtitle?: string;
    icon?: SemanticICONS;
}

export type EmptyPlaceholderProps =
    & OwnProps;

class EmptyPlaceholder extends React.Component<EmptyPlaceholderProps, {}> {
    public render() {
        return (
            <Segment basic={true} placeholder={true}>
                <Header icon={this.props.icon != null} style={{ color: 'rgba(0,0,0,.45)' }}>
                    {this.props.icon && <Icon name={this.props.icon} />}
                    {this.props.title}
                    {this.props.subtitle &&
                        <Header.Subheader>
                            {this.props.subtitle}
                        </Header.Subheader>
                    }
                </Header>
                {this.props.children}
            </Segment>
        );
    }
}

export { EmptyPlaceholder };