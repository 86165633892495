import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Form } from 'semantic-ui-react';
import { ReportFormValues } from './ReportForm';

export const ReportInputField = () => {
    const { formatMessage } = useIntl();
    const { control } = useFormContext<ReportFormValues>();

    return (
        <Controller
            control={control}
            name="report"
            render={({ field: { value, onChange } }) => (
                <Form.TextArea
                    rows={5}
                    label={formatMessage({
                        id: 'SubcontractorRepairPage.ReportInputField.Report',
                        defaultMessage: 'Report',
                    })}
                    value={value ?? ''}
                    onChange={(event, data) => onChange(data.value)}
                />
            )}
        />
    );
};
