import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Icon } from "semantic-ui-react";
import { OrderDetail, OrderDetailStatus } from "state/models/OrderDetail";
import { commonMessages } from "../../../../constants";

const m = defineMessages({
  notConfiguredStatusDescription: {
    id: "OrderItemView.notConfiguredStatusDescription",
    defaultMessage: "Not configured",
  },
  configuredStatusDescription: {
    id: "OrderItemView.configuredStatusDescription",
    defaultMessage: "Configured",
  },
  processedStatusDescription: {
    id: "OrderItemView.processedStatusDescription",
    defaultMessage: "Processed",
  },
});

interface OrderItemViewStatusLabelProps {
  item: OrderDetail;
}

export const OrderItemViewStatusLabel: React.FC<OrderItemViewStatusLabelProps> = ({
  item,
}) => {
  const { formatMessage } = useIntl();

  switch (item.status) {
    case OrderDetailStatus.notConfigured: {
      return (
        <React.Fragment>
          <Icon
            name='clipboard outline'
            size='small'
            circular={true}
            inverted={true}
            color='orange'
          />{" "}
          {formatMessage(m.notConfiguredStatusDescription)}
        </React.Fragment>
      );
    }

    case OrderDetailStatus.configured: {
      return (
        <React.Fragment>
          <Icon
            name='clipboard check'
            size='small'
            circular={true}
            inverted={true}
            color='blue'
          />{" "}
          {formatMessage(m.configuredStatusDescription)}
        </React.Fragment>
      );
    }

    case OrderDetailStatus.processed: {
      return (
        <React.Fragment>
          <Icon name='check' size='small' circular={true} inverted={true} color='green' />{" "}
          {formatMessage(m.processedStatusDescription)}
        </React.Fragment>
      );
    }

    default:
      return (
        <React.Fragment>
          <Icon name='question' size='small' circular={true} inverted={true} color='red' />{" "}
          {formatMessage(commonMessages.unknown)}
        </React.Fragment>
      );
  }
};
