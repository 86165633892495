import * as React from 'react';
import moment from 'moment';

import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Grid, Button } from 'semantic-ui-react';

interface CalendarControlsOwnProps {
    for: Date;
    onPreviousMonth: () => void;
    onNextMonth: () => void;
}

export type CalendarControlsProps =
    & CalendarControlsOwnProps
    & WrappedComponentProps;

const m = defineMessages({
});

class CalendarControls extends React.Component<CalendarControlsProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const forMoment = moment(this.props.for);

        return (
            <Grid columns={3}>
                <Grid.Column>
                    <Button icon="chevron left" onClick={this.props.onPreviousMonth} />
                </Grid.Column>

                <Grid.Column>
                    <h2 style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                        {forMoment.format('MMMM, YYYY')}
                    </h2>
                </Grid.Column>

                <Grid.Column textAlign="right">
                    <Button icon="chevron right" onClick={this.props.onNextMonth} />
                </Grid.Column>
            </Grid>
        );
    }
}

const connectedComponent = injectIntl(CalendarControls);
export { connectedComponent as CalendarControls };