import React, { useMemo, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import deburr from "lodash/deburr";
import get from "lodash/get";
import orderBy from "lodash/orderBy";
import { SortDirection } from "state/models";
import { Loader, Table } from "semantic-ui-react";
import { TableHeaders } from "./TableHeaders";
import { usePagination } from "containers/reporting/hooks/usePagination";
import { useColumnSorting } from "containers/reporting/hooks/useColumnSorting";

const m = defineMessages({
  total: { id: "DefectiveItemsTable.Total", defaultMessage: "Total: {itemTotal}" },
});

export interface DefectiveItemsTableRow {
  groupId: string | number;
  groupName: string | number;
  itemCount: number;
  percent: number;
}

interface DefectiveItemsTableProps {
  rows: DefectiveItemsTableRow[];
  groupType: string;
  itemTotal: number;
  isLoading: boolean;
}

export const DefectiveItemsTable: React.FC<DefectiveItemsTableProps> = ({
  rows,
  groupType,
  itemTotal,
  isLoading,
}) => {
  const { formatMessage, formatNumber } = useIntl();

  const { sortingColumn, sortingDirection, handleColumnSorting } =
    useColumnSorting<DefectiveItemsTableRow>("percent", SortDirection.Descending);

  const sortedRows = useMemo(
    () =>
      orderBy(
        rows,
        (row) => {
          const columnValue = get(row, sortingColumn);
          return typeof columnValue === "string" ? deburr(columnValue) : columnValue;
        },
        sortingDirection === SortDirection.Descending ? "desc" : "asc"
      ),
    [rows, sortingColumn, sortingDirection]
  );

  const { currentPageRows, Paginator } = usePagination(sortedRows);

  const loader = isLoading && (
    <Table.Row>
      <Table.Cell colSpan='4'>
        <div style={{ display: "flex", justifyContent: "center", margin: "1rem" }}>
          <Loader active inline />
        </div>
      </Table.Cell>
    </Table.Row>
  );

  const tableRows =
    !isLoading &&
    currentPageRows.map((row) => (
      <Table.Row key={row.groupId}>
        <Table.Cell>{row.groupName}</Table.Cell>
        <Table.Cell textAlign='right' collapsing>
          {row.itemCount}
        </Table.Cell>
        <Table.Cell textAlign='right' collapsing>
          {formatNumber(row.percent / 100, {
            style: "percent",
            maximumFractionDigits: 1,
          })}
        </Table.Cell>
      </Table.Row>
    ));

  return (
    <>
      <div>{formatMessage(m.total, { itemTotal })}</div>
      <Table sortable striped selectable size='small'>
        <TableHeaders
          groupType={groupType}
          sortingColumn={sortingColumn}
          sortingDirection={sortingDirection}
          onColumnSorting={handleColumnSorting}
        />
        <Table.Body>
          {loader}
          {tableRows}
        </Table.Body>
      </Table>
      {!isLoading && <Paginator />}
    </>
  );
};
