import React from 'react';
import { Input, InputProps } from 'semantic-ui-react';
import { PasswordRequirementsPopup } from './PasswordRequirementsPopup';

interface OwnProps {
    showPasswordRequirements?: boolean;
}

export type PasswordInputProps =
    & InputProps
    & OwnProps;

export const PasswordInput: React.FC<PasswordInputProps> = (props) => {
    const { showPasswordRequirements, ...inputProps } = props;
    const inputComponent = (
        <Input type="password"
            icon="lock"
            iconPosition="left"
            {...inputProps}
        />
    );

    return showPasswordRequirements
        ? <PasswordRequirementsPopup trigger={inputComponent} />
        : inputComponent;
};