import { NavigationItem } from "../../components/layouts/PageLayout/NavigationMenu";
import { afterSalesMenuSection, dashboardMenuItem, techniciansAppointments, techniciansCalendar, techniciansHistory } from "./common";

export const jaymarTechnicianNavigationItems = (): NavigationItem[] => [
    dashboardMenuItem(),
    {
        ...afterSalesMenuSection(),
        children: [
            techniciansAppointments(),
            techniciansCalendar(),
            techniciansHistory(),
        ]
    },
];