import { Reducer } from 'redux';
import { ActionTypes } from './actions';
import { ActionTypeKeys } from './types';
import { NotificationType } from '../../models';

export interface GlobalNotificationState {
    title: string;
    isVisible: boolean;
    message?: string;
    type?: NotificationType;
}

export const initialState: GlobalNotificationState = {
    title: '',
    isVisible: false
};

export const globalNotificationReducer: Reducer<GlobalNotificationState> = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case ActionTypeKeys.SHOW:
            return {
                title: action.payload.title,
                message: action.payload.message,
                type: action.payload.type,
                isVisible: true
            };

        case ActionTypeKeys.CLEAR:
            return initialState;

        default:
            return state;
    }
};

export default globalNotificationReducer;