import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Grid, Button } from 'semantic-ui-react';
import { AppointmentWeek } from '.';
import { TechnicianRepair } from '../../../state/models';
import { LoadingDimmer, LoadingContainer } from '../../common';

interface AppointmentCalendarOwnProps {
    currentDate: moment.Moment;
    appointments: TechnicianRepair[];
    areAppointmentsLoading?: boolean;
    canSelectDate?: boolean;
    onDateSelected?: (date?: moment.Moment) => void;
    onMonthChanged: (date: moment.Moment) => void;
    onServiceCallClicked: (appointment: TechnicianRepair) => void;
}

interface AppointmentCalendarState {
    selectedDate?: moment.Moment;
}

export type AppointmentCalendarProps =
    & AppointmentCalendarOwnProps
    & WrappedComponentProps;

class AppointmentCalendar extends React.Component<AppointmentCalendarProps, AppointmentCalendarState> {
    public constructor(props: AppointmentCalendarProps) {
        super(props);

        this.state = {};
    }

    public render() {
        return (
            <React.Fragment>
                {this.renderCalendarHeader()}

                <LoadingContainer>
                    <LoadingDimmer active={this.props.areAppointmentsLoading} />
                    <Grid columns={5} stackable={true} className="c-calendar">
                        {this.renderWeeks()}
                    </Grid>
                </LoadingContainer>
            </React.Fragment>
        );
    }

    private renderCalendarHeader() {
        return (
            <Grid columns={3}>
                <Grid.Column>
                    <Button icon="chevron left" onClick={this.previousMonth} />
                </Grid.Column>

                <Grid.Column>
                    <h2 style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                        {this.props.currentDate.format('MMMM, YYYY')}
                    </h2>
                </Grid.Column>

                <Grid.Column textAlign="right">
                    <Button icon="chevron right" onClick={this.nextMonth} />
                </Grid.Column>
            </Grid>
        );
    }

    private renderWeeks() {
        const firstDayOfMonth = this.props.currentDate.clone().startOf('month');

        let calendarWeeks = [];

        for (var weekIncrement = 0; weekIncrement < 5; weekIncrement++) {
            const incrementedDate = firstDayOfMonth.clone().add(weekIncrement, 'week');

            calendarWeeks.push((
                <Grid.Column key={incrementedDate.week()} className="c-calendar__week">
                    <AppointmentWeek
                        weekNumber={incrementedDate.week()}
                        year={incrementedDate.year()}
                        appointments={this.props.appointments}
                        onDateSelected={this.selectDate}
                        selectedDate={this.state.selectedDate}
                        canSelectDate={this.props.canSelectDate}
                        onServiceCallClicked={this.props.onServiceCallClicked}
                    />
                </Grid.Column>
            ));
        }

        return calendarWeeks;
    }

    private previousMonth = () => {
        this.setState({ selectedDate: undefined });

        if (this.props.onDateSelected) {
            this.props.onDateSelected(undefined);
        }

        const newValue = moment(this.props.currentDate).clone().add(-1, 'month').startOf('month').startOf('day');
        this.props.onMonthChanged(newValue);
    }

    private nextMonth = () => {
        this.setState({ selectedDate: undefined });

        if (this.props.onDateSelected) {
            this.props.onDateSelected(undefined);
        }

        const newValue = moment(this.props.currentDate).clone().endOf('month').startOf('day').add(1, 'day');
        this.props.onMonthChanged(newValue);
    }

    private selectDate = (date: moment.Moment) => {
        const { selectedDate } = this.state;
        const isSelectedDateBeforeToday = date.isBefore(moment().startOf('day'));

        if (isSelectedDateBeforeToday) {
            return;
        }

        if (selectedDate) {
            const isDateAlreadySelected = selectedDate.isSame(date);
            const newSelectedDate = isDateAlreadySelected ? undefined : date;

            this.setState({ selectedDate: newSelectedDate });

            if (this.props.onDateSelected) {
                this.props.onDateSelected(newSelectedDate);
            }
        } else {
            this.setState({ selectedDate: date });

            if (this.props.onDateSelected) {
                this.props.onDateSelected(date);
            }
        }
    }
}

const connectedComponent = injectIntl(AppointmentCalendar);
export { connectedComponent as AppointmentCalendar };