import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Message, MessageProps } from 'semantic-ui-react';

interface UnauthorizedMessageOwnProps extends MessageProps {
}

export type UnauthorizedMessageProps =
    & UnauthorizedMessageOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'UnauthorizedMessage.title', defaultMessage: 'Unauthorized' },
    message: { id: 'UnauthorizedMessage.message', defaultMessage: 'You do not have the required authorization to access this page.' }
});

class UnauthorizedMessage extends React.Component<UnauthorizedMessageProps, {}> {
    public render() {
        const { intl, ...messageProps } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <Message 
                icon="lock" 
                negative={true} 
                header={formatMessage(m.title)} 
                content={formatMessage(m.message)} 
                {...messageProps} 
            />
        );
    }
}

const connectedComponent = injectIntl(UnauthorizedMessage);
export { connectedComponent as UnauthorizedMessage };