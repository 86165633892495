import { SubcontractorBill } from '../../../state/models';
import { get } from '../../../utils/api';
import { SubcontractorsBillingFilters } from './types';

const reportingEndpoint = '/api/reporting';

export async function loadSubcontractorsBills(startDate: Date, endDate: Date, filters: SubcontractorsBillingFilters): Promise<SubcontractorBill[]> {
    return get<SubcontractorBill[]>(`${reportingEndpoint}/subcontractors-bills/between/${startDate.toISOString()}/${endDate.toISOString()}${getFiltersQueryString(filters)}`);
}

function getFiltersQueryString(filters: SubcontractorsBillingFilters) {
    if (filters == null) {
        return '';
    }

    return `?subcontractorId=${filters.subcontractorFilter.subcontractorId ?? ''}`;
}