import { useMutation } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Button, Form, Modal } from 'semantic-ui-react';
import useApiErrorHandler from 'utils/ApiErrorHandler';
import { uploadSubcontractorRepairPictures } from '../../api';
import { useSubcontractorRepairDetailsContext } from '../SubcontractorRepairDetailsProvider';
import { MediaUploadPreview } from './MediaUploadPreview';
import { UploadMediaInputField } from './UploadMediaInputField';

export interface UploadMediaFormValues {
    medias: File[];
}

interface UploadMediasModalProps {
    open: boolean;
    onClose: () => void;
}

export const UploadMediaModal = ({ open, onClose }: UploadMediasModalProps) => {
    const { formatMessage } = useIntl();
    const handleError = useApiErrorHandler();

    const { repairDetails, invalidateRepairDetails } =
        useSubcontractorRepairDetailsContext();

    const formMethods = useForm<UploadMediaFormValues>();
    const { handleSubmit, reset, watch } = formMethods;

    const formMedias = watch('medias');
    const hasMedia = (formMedias || []).length > 0;

    const { isLoading, mutate } = useMutation({
        mutationFn: uploadSubcontractorRepairPictures,
        onSuccess: () => {
            invalidateRepairDetails();
            handleClose();
        },
        onError: (error) => handleError(error),
    });

    const handleOnSubmit = ({
        medias: attachements,
    }: UploadMediaFormValues) => {
        if (!repairDetails) return;

        if (attachements && attachements.length > 0) {
            mutate(attachements);
        }
    };

    const handleClose = () => {
        reset();
        onClose();
    };

    return (
        <Modal
            size="small"
            open={open}
            mountNode={document.getElementById('root')}
            onClose={handleClose}
        >
            <Modal.Header>
                {formatMessage({
                    id: 'SubcontractorRepairPage.UploadMediaModal.Title',
                    defaultMessage: 'Add pictures',
                })}
            </Modal.Header>
            <Modal.Content>
                <FormProvider {...formMethods}>
                    <Form onSubmit={handleSubmit(handleOnSubmit)}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '16px',
                            }}
                        >
                            <UploadMediaInputField />
                            <MediaUploadPreview />
                        </div>
                    </Form>
                </FormProvider>
            </Modal.Content>
            <Modal.Actions>
                <Button type="button" onClick={handleClose}>
                    {formatMessage({
                        id: 'SubcontractorRepairPage.UploadMediaModal.Close',
                        defaultMessage: 'Close',
                    })}
                </Button>
                <Button
                    disabled={!hasMedia || isLoading}
                    loading={isLoading}
                    primary
                    type="button"
                    onClick={handleSubmit(handleOnSubmit)}
                >
                    {formatMessage({
                        id: 'SubcontractorRepairPage.UploadMediaModal.AddButton',
                        defaultMessage: 'Add',
                    })}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
