import React, { useEffect, useState } from "react";
import * as queryString from 'query-string';
import * as api from '../api';

import { defineMessages, useIntl } from "react-intl";
import { ResetPasswordEdit } from "../models";
import { AccountPageLayout } from "./components/AccountPageLayout";
import { PasswordRequirementsInformation } from "./components/ResetPasswordForm/PasswordRequirementsInformation";
import { ResetPasswordForm } from "./components/ResetPasswordForm/ResetPasswordForm";
import { useHistory } from "react-router";
import { ResetPasswordGenericError } from "./components/ResetPasswordForm/ResetPasswordGenericError";

const m = defineMessages({
    title: { id: 'ResetPasswordPage.title', defaultMessage: 'Choose a new password' }
})

export const ResetPasswordPage: React.FC = (props) => {
    const { formatMessage } = useIntl();
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const history = useHistory();
    const queryParams = queryString.parse(history.location.search);

    useEffect(() => {
        const hasRequiredSecurityParameters = queryParams != null && queryParams.email != null && queryParams.token != null;

        if (!hasRequiredSecurityParameters) {
            history.push(`/acount/forgot-password`);
        }

    }, [history, queryParams]);

    const resetPassword = (data: ResetPasswordEdit) => {
        data.confirmationToken = queryParams?.token as string || '';
        data.email = queryParams?.email as string || '';

        setIsLoading(true);

        api.resetPassword(data)
            .then(() => history.push('/account/login'))
            .catch(() => setHasError(true))
            .finally(() => setIsLoading(false));
    }

    return (
        <AccountPageLayout title={formatMessage(m.title)}>
            <PasswordRequirementsInformation style={{ width: '100%'}} />
            <ResetPasswordForm 
                loading={isLoading}
                onResetPassword={resetPassword}
            />

            {hasError && <ResetPasswordGenericError />}
        </AccountPageLayout>
    );
};