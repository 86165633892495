import { defineMessages } from "@formatjs/intl";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router";
import { Divider } from "semantic-ui-react";
import { FillClientDetailsPage, FillCustomerDetailsPage, FillOrderDetailsPage, FillProblemDetailsPage } from ".";
import { PageHeader } from "../../../components/common";
import { AuthenticatedRoute } from "../../../routes/AuthenticatedRoute";
import { authenticatedUsers } from "../../../state/models";
import { getCurrentClientId, getCurrentUser } from "../../account/selectors";
import { ServiceCallEdit } from "../models";
import { CreateServiceCallSteps } from "./components/CreateServiceCallSteps";

interface OwnProps {
}

export type CreateServiceCallPageProps =
    & OwnProps;

const m = defineMessages({
    title: { id: 'CreateServiceCallPage.title', defaultMessage: 'Create a new service call' },
    subtitle: { id: 'CreateServiceCallPage.subtitle', defaultMessage: 'Create a service call to notify our customer service about a problem with your Jaymar product.' }
});

export const CreateServiceCallPage: React.FC<CreateServiceCallPageProps> = (props) => {
    const { formatMessage } = useIntl();

    const location = useLocation();
    const currentClientId = useSelector(getCurrentClientId);
    const formProps = useForm<ServiceCallEdit>({
        defaultValues: {
            'clientId': currentClientId
        }
    });

    if (location.pathname.endsWith('/service-calls/create')) {
        return <Redirect to="/service-calls/create/order" />;
    }

    return (
        <>
            <PageHeader iconName="add" title={formatMessage(m.title)} subtitle={formatMessage(m.subtitle)} />
            <FormProvider {...formProps}>
                <CreateServiceCallSteps />
                <Divider section />

                <AuthenticatedRoute authorizedRoles={authenticatedUsers} path="/service-calls/create/order" component={FillOrderDetailsPage} />
                <AuthenticatedRoute authorizedRoles={authenticatedUsers} path="/service-calls/create/client" component={FillClientDetailsPage} />
                <AuthenticatedRoute authorizedRoles={authenticatedUsers} path="/service-calls/create/customer" component={FillCustomerDetailsPage} />
                <AuthenticatedRoute authorizedRoles={authenticatedUsers} path="/service-calls/create/problem" component={FillProblemDetailsPage} />
            </FormProvider>
        </>
    );
};