import * as React from 'react';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { Popup, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';

import { commonMessages, timeRangeMessages } from '../../../constants';
import { AppointmentTimeRange, TechnicianRepair } from '../../../state/models';
import { CalendarEventLabel } from '../../calendar/CalendarEventLabel';
import { AppointmentQuickOverview } from '../../quick-overviews';

interface TechnicianCalendarEventOwnProps {
    repair: TechnicianRepair;
    onRepairClicked: (repair: TechnicianRepair) => void;
}

export type TechnicianCalendarEventProps =
    & TechnicianCalendarEventOwnProps
    & WrappedComponentProps;

const m = defineMessages({
});

class TechnicianCalendarEvent extends React.Component<TechnicianCalendarEventProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { repair } = this.props;
        const appointmentIcon: SemanticICONS | undefined = repair.completedOn != null ? 'check' : undefined;

        const trigger = (
            <CalendarEventLabel
                color={this.getAppointmentColor(repair)}
                icon={appointmentIcon}
                content={this.getAppointmentCity(repair)}
                detail={this.getAppointmentTimeRange(repair)}
                style={{ cursor: 'pointer' }}
            />
        );

        return (
            <Popup
                trigger={trigger}
                content={<AppointmentQuickOverview appointment={repair} onServiceCallClicked={this.props.onRepairClicked} />}
                on="click"
            />
        );
    }

    private getAppointmentTimeRange(repair: TechnicianRepair): string | undefined {
        const { formatMessage } = this.props.intl;

        if (repair.scheduledForTimeRange === AppointmentTimeRange.morning) {
            return formatMessage(timeRangeMessages.am);
        } else if (repair.scheduledForTimeRange === AppointmentTimeRange.afternoon) {
            return formatMessage(timeRangeMessages.pm);
        }

        return undefined;
    }

    private getAppointmentCity(repair: TechnicianRepair): string {
        const { formatMessage } = this.props.intl;

        return repair.serviceCall.customer && repair.serviceCall.customer.address && repair.serviceCall.customer.address.city
            ? repair.serviceCall.customer.address.city
            : formatMessage(commonMessages.unknown);
    }

    private getAppointmentColor(repair: TechnicianRepair): SemanticCOLORS | undefined {
        if (repair.completedOn != null) {
            return 'green';
        } else if (repair.scheduledForTimeRange === AppointmentTimeRange.morning) {
            return 'orange';
        } else if (repair.scheduledForTimeRange === AppointmentTimeRange.afternoon) {
            return 'violet';
        }

        return undefined;
    }
}

const connectedComponent = injectIntl(TechnicianCalendarEvent);
export { connectedComponent as TechnicianCalendarEvent };