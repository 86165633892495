import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Button, Container, Header, Modal, Segment } from 'semantic-ui-react';
import { SelectDefectForm } from './SelectDefectForm';
import { Defect } from '../../../state/models';
import { commonMessages } from '../../../constants';

export interface AddDefectModalProps {
    isOpen: boolean;
    allDefects: Defect[];
    onAddDefect: (defect: Defect) => void;
    onClose: () => void;
}

interface AddDefectModalState {
    selectedDefect?: Defect;
}

const m = defineMessages({
    title: { id: 'AddDefectModal.title', defaultMessage: 'Add a defect' },
    subtitle: { id: 'AddDefectModal.subtitle', defaultMessage: 'Add a defect to the service call to retrieve the warranty status.'}
});

class AddDefectModal extends React.Component<AddDefectModalProps & WrappedComponentProps, AddDefectModalState> {
    public constructor(props: AddDefectModalProps & WrappedComponentProps) {
        super(props);

        this.state = {};
    }

    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <Modal size="tiny" open={this.props.isOpen}>
                <Modal.Header>
                    <Header 
                        content={formatMessage(m.title)}
                        subheader={formatMessage(m.subtitle)}
                    />
                </Modal.Header>
                <Modal.Description>
                    <Segment basic={true}>
                        <SelectDefectForm 
                            allDefects={this.props.allDefects} 
                            onDefectSelected={defect => this.setState({ selectedDefect: defect })}
                            onDefectCleared={() => this.setState({ selectedDefect: undefined })}
                            selectedDefect={this.state.selectedDefect}
                        />
                    </Segment>
                </Modal.Description>
                <Modal.Actions>
                    <Button 
                        labelPosition="left" 
                        icon="cancel" 
                        content={formatMessage(commonMessages.cancel)} 
                        onClick={() => this.closeModal()} 
                    />
                    <Button 
                        primary={true} 
                        disabled={this.state.selectedDefect === undefined}
                        onClick={() => this.addDefect()}
                        labelPosition="left" 
                        icon="add" 
                        content={formatMessage(commonMessages.add)}
                    />
                </Modal.Actions>
            </Modal>
        );
    }

    private closeModal() {
        this.setState({ selectedDefect: undefined });

        this.props.onClose();
    }

    private addDefect() {
        if (this.state.selectedDefect) {
            this.props.onAddDefect(this.state.selectedDefect);
            this.closeModal();
        }
    }
}

const intlComponent = injectIntl(AddDefectModal);
export { intlComponent as AddDefectModal };