import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Label, LabelProps } from 'semantic-ui-react';

interface CalendarEventLabelOwnProps extends LabelProps {
}

export type CalendarEventLabelProps =
    & CalendarEventLabelOwnProps
    & WrappedComponentProps;

const m = defineMessages({
});

class CalendarEventLabel extends React.Component<CalendarEventLabelProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { intl, ...labelProps } = this.props;

        return (
            <Label
                className="c-calendar__event"
                {...labelProps}
            />
        );
    }
}

const connectedComponent = injectIntl(CalendarEventLabel);
export { connectedComponent as CalendarEventLabel };