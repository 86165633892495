import * as React from 'react';
import { Dropdown } from 'semantic-ui-react';

export interface MobileNavigationSectionProps {
    title: string;
}

class MobileNavigationSection extends React.Component<MobileNavigationSectionProps, {}> {
    public render() {
        return (
            <React.Fragment>
                <Dropdown.Header>
                    {this.props.title}
                </Dropdown.Header>
                
                {this.props.children}
            </React.Fragment>
        );
    }
}

export { MobileNavigationSection };