import * as React from 'react';
import moment from 'moment';
import _ from 'lodash';

import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Table, Icon, Header } from 'semantic-ui-react';
import { CalendarDayCell } from './CalendarDayCell';
import { CalendarControls } from './CalendarControls';
import { LoadingContainer, LoadingDimmer } from '../common';
import { CalendarBreak, User } from '../../state/models';
import { CalendarEvent } from './MonthlyCalendar';
import { CalendarLockModal, LockType } from './CalendarLockModal';
import { UserHelper } from '../../helpers';

interface ManageCalendarOwnProps {
    forDate: Date;
    events: CalendarEvent[];
    employees: User[];
    lockedDates: CalendarBreak[];
    loadingDates: Date[];
    isLoading?: boolean;
    onChangeMonth: (date: Date) => void;
    onLock: (date: Date, employee: User | undefined, reason: string) => void;
    onUnlock: (date: Date) => void;
}

interface ManageCalendarState {
    isModalOpen: boolean;
    selectedDate: Date | undefined;
}

export type ManageCalendarProps =
    & ManageCalendarOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    weekHeaderFormat: { id: 'ManageCalendar.week_header_format', defaultMessage: 'Week {weekNumber}' },
    locked: { id: 'ManageCalendar.locked', defaultMessage: 'Locked' },
    unlock: { id: 'ManageCalendar.unlock', defaultMessage: 'Unlock' }
});

class ManageCalendar extends React.Component<ManageCalendarProps, ManageCalendarState> {
    public constructor(props: ManageCalendarProps) {
        super(props);

        this.state = {
            isModalOpen: false,
            selectedDate: undefined
        };
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const firstDayOfMonth = moment(this.props.forDate).clone().startOf('month');
        const firstDayOfWeek = firstDayOfMonth.clone().startOf('isoWeek');

        const weekHeaders = _.range(5).map(x => (
            <Table.HeaderCell key={x}>
                {formatMessage(m.weekHeaderFormat, { weekNumber: firstDayOfMonth.clone().add(x, 'weeks').week() })}
            </Table.HeaderCell>
        ));

        const dayRows = _.range(6).map(x => (
            this.renderRow('row' + x, firstDayOfWeek.clone().add(x, 'days').toDate())
        ));

        return (
            <React.Fragment>
                <CalendarControls
                    for={this.props.forDate}
                    onPreviousMonth={() => this.props.onChangeMonth(firstDayOfMonth.clone().add(-1, 'month').toDate())}
                    onNextMonth={() => this.props.onChangeMonth(firstDayOfMonth.clone().add(1, 'month').toDate())}
                />

                <LoadingContainer>
                    <LoadingDimmer active={this.props.isLoading} style={{ verticalAlign: 'top' }} />

                    <Table className="c-calendar c-calendar--manageable" textAlign="center" celled={true} fixed={true}>
                        <Table.Header>
                            <Table.Row>
                                {weekHeaders}
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {dayRows}
                        </Table.Body>
                    </Table>

                    <CalendarLockModal
                        users={this.props.employees}
                        open={this.state.isModalOpen}
                        onClose={() => this.setState({ isModalOpen: false })}
                        onCancel={() => this.setState({ isModalOpen: false })}
                        onLock={this.handleLock}
                    />
                </LoadingContainer>
            </React.Fragment>
        );
    }

    private renderRow(key: string, forDate: Date): JSX.Element {
        const { formatMessage } = this.props.intl;
        const days = _.range(5).map(x => {
            const dayMoment = moment(forDate).clone().add(x, 'week');
            const isPastDate = dayMoment.isBefore(moment(), 'day');
            const locks = this.props.lockedDates.filter(y => dayMoment.isSame(y.forDate, 'day'));
            const isLocked = locks.length > 0;
            const isLoading = this.props.loadingDates.filter(y => dayMoment.isSame(y, 'day')).length > 0;

            return (
                <CalendarDayCell
                    key={dayMoment.toISOString()}
                    selectable={true}
                    disabled={isPastDate || isLoading}
                    locked={isLocked}
                    loading={isLoading}
                    day={dayMoment.toDate()}
                    style={{ height: '144px', maxHeight: '144px', cursor: 'pointer' }}
                    onClick={() => this.handleDayClick(dayMoment.toDate(), isLocked)}
                >
                    {isLocked &&
                        <React.Fragment>
                            <Header className="c-calendar__lock" as="h4" icon={true} style={{ color: 'rgba(0,0,0,.25)', marginTop: '18px', marginBottom: 0 }}>
                                <Icon name="lock" />
                                {locks[0].forUser != null
                                    ? UserHelper.getDisplayName(locks[0].forUser)
                                    : formatMessage(m.locked)
                                }
                            </Header>

                            <Header className="c-calendar__unlock" as="h4" icon={true} style={{ color: 'rgba(0,0,0,.25)', marginTop: '18px', marginBottom: 0 }}>
                                <Icon name="unlock" />
                                {formatMessage(m.unlock)}
                            </Header>

                            <p style={{ color: 'rgba(0,0,0,.25)', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                {locks[0].reason}
                            </p>
                        </React.Fragment>
                    }
                </CalendarDayCell>
            );
        });

        return (
            <Table.Row key={key} verticalAlign="top">
                {days}
            </Table.Row>
        );
    }

    private handleDayClick = (date: Date, isLocked: boolean) => {
        if (!isLocked) {
            this.setState({
                isModalOpen: true,
                selectedDate: date
            });
        } else {
            this.props.onUnlock(date);
        }
    }

    private handleLock = (lockType: LockType, employee: User | undefined, reason: string) => {
        if (this.state.selectedDate != null) {
            this.props.onLock(this.state.selectedDate, employee, reason);
        }

        this.setState({ isModalOpen: false });
    }
}

const connectedComponent = injectIntl(ManageCalendar);
export { connectedComponent as ManageCalendar };