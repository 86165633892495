import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { ServiceCall } from '../../../state/models';
import { Table, StrictTableProps } from 'semantic-ui-react';
import { RecentServiceCallRow } from './RecentServiceCallRow';
import { NavLink } from 'react-router-dom';

interface OwnProps {
    serviceCalls: ServiceCall[];
    onClick: (serviceCall: ServiceCall) => void;
}

export type RecentServiceCallsTableProps =
    & OwnProps
    & StrictTableProps
    & WrappedComponentProps;

const m = defineMessages({
    serviceCallNumberHeader: { id: 'RecentServiceCallsTable.serviceCallNumberHeader', defaultMessage: '#' },
    customerHeader: { id: 'RecentServiceCallsTable.customerHeader', defaultMessage: 'Customer' },
    lastUpdatedOnHeader: { id: 'RecentServiceCallsTable.lastUpdatedOnHeader', defaultMessage: 'Last updated on' },
    viewAllCallsLink: { id: 'RecentServiceCallsTable.viewAllCallsLink', defaultMessage: 'View all service calls' },
});

class RecentServiceCallsTable extends React.Component<RecentServiceCallsTableProps> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { serviceCalls, onClick, ...tableProps } = this.props;

        return (
            <React.Fragment>
                <Table basic="very" selectable singleLine fixed style={{ cursor: 'pointer' }} {...tableProps}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell collapsing style={{ minWidth: '100px'}} />
                            <Table.HeaderCell style={{ width: '100px' }}>{formatMessage(m.serviceCallNumberHeader)}</Table.HeaderCell>
                            <Table.HeaderCell>{formatMessage(m.customerHeader)}</Table.HeaderCell>
                            <Table.HeaderCell>{formatMessage(m.lastUpdatedOnHeader)}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.renderRows()}
                    </Table.Body>
                </Table>

                <div style={{ textAlign: 'center'}}>
                    <NavLink to="/service-calls/current">{formatMessage(m.viewAllCallsLink)}</NavLink>
                </div>
            </React.Fragment>
        );
    }

    private renderRows() {
        return this.props.serviceCalls.map(x => (
            <RecentServiceCallRow 
                key={x.id} 
                serviceCall={x} 
                onClick={() => this.props.onClick(x)} 
            />
        ));
    }
}

const intlComponent = injectIntl(RecentServiceCallsTable);
export { intlComponent as RecentServiceCallsTable };