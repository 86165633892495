import React, { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { ForgotPasswordEdit } from "../models";
import { AccountPageLayout } from "./components/AccountPageLayout/AccountPageLayout";
import { ForgotPasswordForm } from "./components/ForgotPasswordForm";
import * as api from '../api';
import { useHistory } from "react-router";

interface OwnProps {
}

export type ForgotPasswordPageProps =
    & OwnProps;

const m = defineMessages({
    title: { id: 'ForgotPasswordPage.title', defaultMessage: 'Password reset request'}
})

export const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = () => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const handlePasswordReset = (data: ForgotPasswordEdit) => {
        setLoading(true);

        api.requestPasswordReset(data.email)
            .then(() => history.push(`/account/forgot-password/confirm`))
            .finally(() => setLoading(false));
    };

    return (
        <AccountPageLayout title={formatMessage(m.title)}>
            <ForgotPasswordForm 
                loading={loading}
                onResetPassword={handlePasswordReset} 
            />
        </AccountPageLayout>
    );
};