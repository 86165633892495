import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedMessage } from 'react-intl';
import { Button, Header, List, Modal } from 'semantic-ui-react';
import { ProductionComponent, ProductionOrder, ProductionComponentTree } from '../../../state/models';
import { SelectableProductionComponent } from '.';

export interface ViewBillOfMaterialsModalProps {
    productionComponents: ProductionComponent[];
    productionComponentsTree: ProductionComponentTree;
    trigger?: React.ReactNode;

    onExpandProductionComponent: (productionComponentId: number) => void;
    onCollapseProductionComponent: (productionComponentId: number) => void;
    onSelectProductionComponent: (productionComponentId: number) => void;
    onDeselectProductionComponent: (productionComponentId: number, deselectChildren?: boolean) => void;
}

const m = defineMessages({
    title: { id: 'ViewBillOfMaterialsModal.title', defaultMessage: 'Bill of materials' },
    subtitle: { id: 'ViewBillOfMaterialsModal.subtitle', defaultMessage: 'Select the items from the bill of materials required to repair the defects associated to this service call.' }
});

class ViewBillOfMaterialsModal extends React.Component<ViewBillOfMaterialsModalProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <Modal 
                trigger={this.props.trigger} 
                closeIcon={true} 
                size="fullscreen"
            >
                <Modal.Header>
                    <Header content={formatMessage(m.title)} subheader={formatMessage(m.subtitle)} />
                </Modal.Header>
                <Modal.Description style={{padding: 15}}>
                    <List>
                        {this.renderProducts(this.props.productionComponents)}
                    </List>
                </Modal.Description>
            </Modal>
        );
    }

    private renderProducts(products: ProductionComponent[]): JSX.Element[] {
        return products.map(x => this.renderSelectableComponent(x));
    }

    private renderSelectableComponent(component: ProductionComponent): JSX.Element {
        const treeNode = this.props.productionComponentsTree[component.id];
        const areChildrenVisible = treeNode && treeNode.node.isCollapsible && !treeNode.node.isCollapsed;
        const children = component.children && component.children.length > 0 && areChildrenVisible
            ? component.children.map(x => this.renderSelectableComponent(x))
            : [];

        return (
            <SelectableProductionComponent
                key={component.id}
                productionComponent={component}
                isCollapsible={treeNode && treeNode.node.isCollapsible}
                isCollapsed={treeNode && treeNode.node.isCollapsed}
                isSelected={treeNode && treeNode.node.isSelected}
                children={children}
                onExpand={() => this.props.onExpandProductionComponent(component.id)}
                onCollapse={() => this.props.onCollapseProductionComponent(component.id)}
                onSelect={() => this.props.onSelectProductionComponent(component.id)}
                onDeselect={() => this.props.onDeselectProductionComponent(component.id, true)}
            />
        );
    }
}

const intlComponent = injectIntl(ViewBillOfMaterialsModal);
export { intlComponent as ViewBillOfMaterialsModal };