import moment from 'moment';
import React from 'react';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { Button, Table } from 'semantic-ui-react';

import { commonMessages, defectCategoryMessages, defectTypeMessages } from '../../../constants';
import { UserHelper } from '../../../helpers';
import { ServiceCallDefect, ServiceCallFactoryRepair } from '../../../state/models';
import { LoadingContainer, LoadingDimmer, NoContentSegment } from '../../common';
import { FactoryRepairStatusLabel } from './FactoryRepairStatusLabel';

interface FactoryRepairsTableOwnProps {
    repairs: ServiceCallFactoryRepair[];
    isLoading: boolean;
    emptyTitle: string;
    emptySubtitle: string;
    isAgentHeaderHidden?: boolean;
    isRequestedHeaderHidden?: boolean;
    isReceivedHeaderHidden?: boolean;
    isCompletedHeaderHidden?: boolean;
    isViewButtonHidden?: boolean;

    onViewDetails: (repairs: ServiceCallFactoryRepair) => void;
}

export type FactoryRepairsTableProps =
    & FactoryRepairsTableOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    idHeader: { id: 'FactoryRepairsTable.id_header', defaultMessage: '#' },
    requestedOnHeader: { id: 'FactoryRepairsTable.requested_on_header', defaultMessage: 'Requested on' },
    scheduledForHeader: { id: 'FactoryRepairsTable.scheduled_for_header', defaultMessage: 'Scheduled for' },
    receivedOnHeader: { id: 'FactoryRepairsTable.received_on_header', defaultMessage: 'Received on' },
    completedOnHeader: { id: 'FactoryRepairsTable.completed_on_header', defaultMessage: 'Completed on' },
    agentHeader: { id: 'FactoryRepairsTable.agent_header', defaultMessage: 'Agent' },
    clientHeader: { id: 'FactoryRepairsTable.client_header', defaultMessage: 'Client' },
    defectHeader: { id: 'FactoryRepairsTable.defect_header', defaultMessage: 'Defect' }
});

class FactoryRepairsTable extends React.Component<FactoryRepairsTableProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const hasRepairs = this.props.repairs && this.props.repairs.length > 0;
        const rows = hasRepairs ? this.props.repairs.map(x => this.renderRow(x)) : (null);

        return (
            <LoadingContainer>
                <LoadingDimmer active={this.props.isLoading} />

                {hasRepairs &&
                    <Table basic="very" unstackable={true}>
                        <Table.Header>
                            <Table.HeaderCell collapsing={true} />
                            <Table.HeaderCell collapsing={true}>{formatMessage(m.idHeader)}</Table.HeaderCell>
                            {!this.props.isRequestedHeaderHidden && <Table.HeaderCell>{formatMessage(m.requestedOnHeader)}</Table.HeaderCell>}
                            {!this.props.isReceivedHeaderHidden && <Table.HeaderCell>{formatMessage(m.scheduledForHeader)}</Table.HeaderCell>}
                            {!this.props.isCompletedHeaderHidden && <Table.HeaderCell>{formatMessage(m.completedOnHeader)}</Table.HeaderCell>}
                            {!this.props.isAgentHeaderHidden && <Table.HeaderCell>{formatMessage(m.agentHeader)}</Table.HeaderCell>}
                            <Table.HeaderCell>{formatMessage(m.clientHeader)}</Table.HeaderCell>
                            <Table.HeaderCell>{formatMessage(m.defectHeader)}</Table.HeaderCell>
                            {!this.props.isViewButtonHidden && <Table.HeaderCell collapsing={true} />}
                        </Table.Header>
                        <Table.Body>
                            {rows}
                        </Table.Body>
                    </Table>
                }

                {!hasRepairs &&
                    <NoContentSegment
                        title={this.props.emptyTitle}
                        subtitle={this.props.emptySubtitle}
                    />
                }
            </LoadingContainer>
        );
    }

    private renderRow(repairs: ServiceCallFactoryRepair) {
        const { formatMessage } = this.props.intl;

        const agentAssigned = repairs.serviceCall
            ? UserHelper.getDisplayName(repairs.serviceCall.assignedTo)
            : '';

        const hasDefects = repairs.serviceCall && repairs.serviceCall.defects && repairs.serviceCall.defects.length > 0;

        return (
            <Table.Row key={repairs.id}>
                <Table.Cell>
                    <FactoryRepairStatusLabel repair={repairs} />
                </Table.Cell>
                <Table.Cell>
                    <a style={{ cursor: 'pointer' }} onClick={() => this.props.onViewDetails(repairs)}>
                        {repairs.serviceCallId}
                    </a>
                </Table.Cell>
                {!this.props.isRequestedHeaderHidden &&
                    <Table.Cell>
                        <span>{moment.utc(repairs.requestedOn).format('LL')}</span>
                    </Table.Cell>
                }
                {!this.props.isReceivedHeaderHidden &&
                    <Table.Cell>
                        {repairs.receivedOn &&
                            <span>{moment.utc(repairs.scheduledFor || repairs.receivedOn).format('LL')}</span>
                        }
                    </Table.Cell>
                }
                {!this.props.isCompletedHeaderHidden &&
                    <Table.Cell>
                        {repairs.completedRepairsOn &&
                            <span>{moment.utc(repairs.completedRepairsOn).format('LL')}</span>
                        }
                    </Table.Cell>
                }
                {!this.props.isAgentHeaderHidden &&
                    <Table.Cell>{agentAssigned}</Table.Cell>
                }
                <Table.Cell>
                    {repairs.serviceCall && repairs.serviceCall.client &&
                        <span>{repairs.serviceCall.client.name}</span>
                    }
                </Table.Cell>
                <Table.Cell>
                    {hasDefects &&
                        this.renderDefects(repairs.serviceCall.defects)
                    }
                </Table.Cell>
                {!this.props.isViewButtonHidden &&
                    <Table.Cell>
                        <Button
                            content={formatMessage(commonMessages.view)}
                            onClick={() => this.props.onViewDetails(repairs)}
                        />
                    </Table.Cell>
                }
            </Table.Row>
        );
    }

    private renderDefects(defects: ServiceCallDefect[]) {
        const { formatMessage } = this.props.intl;

        if (defects && defects.length > 0) {
            const defect = defects[0].defect;

            const typeMessages = Object.keys(defectTypeMessages).map(key => defectTypeMessages[key]);
            const defectTypeMessage = typeMessages.find(x => x.id === defect.defectType.title);
            const defectType = defectTypeMessage ? formatMessage(defectTypeMessage) : defect.defectType.title;

            const categoryMessages = Object.keys(defectCategoryMessages).map(key => defectCategoryMessages[key]);
            const defectCategoryMessage = categoryMessages.find(x => x.id === defect.defectCategory.title);
            const defectCategory = defectCategoryMessage ? formatMessage(defectCategoryMessage) : defect.defectCategory.title;

            return (
                <p>
                    <span>{defectType}</span>
                    <span style={{ color: 'rgba(0,0,0,0.4)' }}> ({defectCategory})</span>
                </p>
            );
        }

        return (null);
    }
}

const connectedComponent = injectIntl(FactoryRepairsTable);
export { connectedComponent as FactoryRepairsTable };