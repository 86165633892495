import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';
import { Popup, Icon, IconProps } from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { OrderDetailType } from '../../../../state/models/OrderDetail';
import { commonMessages } from '../../../../constants';

interface OwnProps {
    type: OrderDetailType;
}

export type OrderDetailIconProps =
    & IconProps
    & OwnProps
    & WrappedComponentProps;

const m = defineMessages({
    fabricate: { id: 'OrderDetailIcon.fabricate', defaultMessage: 'Fabricate' },
    stock: { id: 'OrderDetailIcon.stock', defaultMessage: 'Stock' },
    miscellaneous: { id: 'OrderDetailIcon.miscellaneous', defaultMessage: 'Miscellaneous' }
});

interface IconDetails {
    icon: SemanticICONS;
    tooltip: MessageDescriptor;
}

class OrderDetailIcon extends React.Component<OrderDetailIconProps, {}> {
    private readonly iconMap: Map<OrderDetailType, IconDetails> = new Map<OrderDetailType, IconDetails>([
        [OrderDetailType.fabricate, { icon: 'box' as SemanticICONS, tooltip: m.fabricate }],
        [OrderDetailType.stock, { icon: 'warehouse' as SemanticICONS, tooltip: m.stock }],
        [OrderDetailType.miscellaneous, { icon: 'setting' as SemanticICONS, tooltip: m.miscellaneous }]
    ]);

    public render() {
        const { formatMessage } = this.props.intl;
        const { intl, type, ...iconProps } = this.props;

        const unknownIcon: IconDetails = { icon: 'question', tooltip: commonMessages.unknown };
        const detailIcon = this.iconMap.get(type) || unknownIcon;
        const icon = <Icon name={detailIcon.icon} {...iconProps} />;

        return (
            <Popup trigger={icon} content={formatMessage(detailIcon.tooltip)} />
        );
    }
}

const intlComponent = injectIntl(OrderDetailIcon);
export { intlComponent as OrderDetailIcon };