import React from 'react';
import moment from 'moment';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { ApplicationState } from '../../../state/ducks';
import * as AppointmentsActions from '../../../state/ducks/appointments';
import { PageHeader } from '../../../components/common';
import { AppointmentCalendar } from '../../../components/service-calls';
import { TechnicianRepair } from '../../../state/models';
import { getAppointmentsState, getMyAppointments } from '../../../state/ducks/appointments/selectors';

interface TechnicianCalendarPageActions {
    appointmentsActions: typeof AppointmentsActions.actionCreators;
}

interface TechnicianCalendarPageOwnProps {
    appointments: TechnicianRepair[];
    areAppointmentsLoading: boolean;
    selectedCalendarDate: Date;
}

export type TechnicianCalendarPageProps =
    & TechnicianCalendarPageOwnProps
    & TechnicianCalendarPageActions
    & RouteComponentProps<{}>
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'TechnicianCalendarPage.title', defaultMessage: 'My calendar' },
    subtitle: { id: 'TechnicianCalendarPage.subtitle', defaultMessage: 'View your upcoming service call appointments.' }
});

class TechnicianCalendarPage extends React.Component<TechnicianCalendarPageProps, {}> {
    public componentDidMount() {
        const startDate = moment(this.props.selectedCalendarDate).startOf('month').startOf('week').startOf('day').toDate();
        const endDate = moment(this.props.selectedCalendarDate).endOf('month').endOf('week').startOf('day').toDate();

        this.props.appointmentsActions.loadMine(startDate, endDate);
    }

    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <PageHeader
                    iconName="calendar"
                    title={formatMessage(m.title)}
                    subtitle={formatMessage(m.subtitle)}
                />

                <AppointmentCalendar
                    currentDate={moment(this.props.selectedCalendarDate)}
                    appointments={this.props.appointments}
                    areAppointmentsLoading={this.props.areAppointmentsLoading}
                    onMonthChanged={this.changeCalendarStartDate}
                    onServiceCallClicked={this.navigateToServiceCall}
                />
            </React.Fragment>
        );
    }

    private changeCalendarStartDate = (date: moment.Moment) => {
        const startDate = date.clone().startOf('month').startOf('week').startOf('day').toDate();
        const endDate = date.clone().endOf('month').endOf('week').startOf('day').toDate();

        this.props.appointmentsActions.setCalendarStartDate(date.toDate());
        this.props.appointmentsActions.loadMine(startDate, endDate);
    }

    private navigateToServiceCall = (appointment: TechnicianRepair) => {
        this.props.history.push(`/service-calls/${appointment.serviceCallId}/technician-repairs/${appointment.id}/prepare`);
    }
}

const mapStateToProps = (state: ApplicationState) => {
    const selectedCalendarDate = moment(state.appointments.calendarStartDate).startOf('month').startOf('week').startOf('day').toDate();
    const appointmentsState = getAppointmentsState(state, selectedCalendarDate);

    return {
        appointments: getMyAppointments(state, selectedCalendarDate),
        areAppointmentsLoading: appointmentsState ? appointmentsState.isLoading : false,
        selectedCalendarDate: state.appointments.calendarStartDate,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): TechnicianCalendarPageActions => {
    return {
        appointmentsActions: bindActionCreators(AppointmentsActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(TechnicianCalendarPage);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as TechnicianCalendarPage };