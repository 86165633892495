import { useQuery } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';
import { commonMessages } from '../../../../../constants';
import { getSubcontractorRepairInvoice } from '../../api';

interface InvoiceLinkProps {
    contentType: string;
}

export const InvoiceLink = ({ contentType }: InvoiceLinkProps) => {
    const { formatMessage } = useIntl();

    const { data } = useQuery(
        ['subcontractorRepairInvoice', contentType],
        () => getSubcontractorRepairInvoice(contentType),
        {
            refetchOnWindowFocus: false,
            retry: 0,
        }
    );

    const invoiceUrl = data ? URL.createObjectURL(data) : undefined;

    if (!invoiceUrl) return null;

    return (
        <Button
            size="tiny"
            as="a"
            target="_blank"
            href={invoiceUrl}
            content={formatMessage(commonMessages.view)}
        />
    );
};
