import moment from 'moment';
import React from 'react';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import { Icon, List } from 'semantic-ui-react';

import { AttachmentType, ServiceCallAttachment } from '../../../state/models';
import { SmallConfirmDeleteButton } from '../../common';

export interface DocumentAttachmentProps {
    document: ServiceCallAttachment;
    onDelete?: (attachmentId: number) => void;
}

const m = defineMessages({
    confirmDeleteTitle: { id: 'DocumentAttachment.confirm_delete_title', defaultMessage: 'Delete this document?' },
    confirmDeleteMessage: { id: 'DocumentAttachment.confirm_delete_message', defaultMessage: 'Are you sure you want to delete the document \'{name}\'?' },
    addedFormat: { id: 'DocumentAttachment.added_format', defaultMessage: 'Added on {date}' }
});

class DocumentAttachment extends React.Component<DocumentAttachmentProps & WrappedComponentProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const { document } = this.props;

        const documentName = document.name || document.id;
        const documentLink = document.type === AttachmentType.subcontractorBill
            ? `/api/service-calls/${document.serviceCallId}/attachments/subcontractor-repair/${document.id}`
            : `/api/service-calls/${document.serviceCallId}/attachments/${document.id}`;

        const canDelete = this.props.onDelete != null && this.props.document.type !== AttachmentType.subcontractorBill;

        return (
            <List.Item key={document.id}>
                <List.Content floated="left">
                    <a href={documentLink} target="_blank">
                        {this.renderDocumentIcon(document)}
                    </a>
                </List.Content>
                <List.Content floated="right">
                    {canDelete &&
                        <SmallConfirmDeleteButton
                            basic={false}
                            confirmationTitle={formatMessage(m.confirmDeleteTitle)}
                            confirmationMessage={formatMessage(m.confirmDeleteMessage, { name: documentName })}
                            onClick={() => this.props.onDelete!(document.id)}
                        />
                    }
                </List.Content>
                <List.Content as="a" href={documentLink} target="_blank" verticalAlign="middle">
                    <List.Header style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{documentName}</List.Header>
                    <List.Description>{formatMessage(m.addedFormat, { date: moment.utc(document.createdOn).format('LL') })}</List.Description>
                </List.Content>

            </List.Item>
        );
    }

    private renderDocumentIcon(document: ServiceCallAttachment) {
        switch (document.contentType) {
            case 'application/pdf':
                return (
                    <Icon
                        name="file pdf outline"
                        size="big"
                        color="red"
                    />
                );

            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return (
                    <Icon
                        name="file word outline"
                        size="big"
                        color="blue"
                    />
                );

            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return (
                    <Icon
                        name="file excel outline"
                        size="big"
                        color="green"
                    />
                );

            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                return (
                    <Icon
                        name="file powerpoint outline"
                        size="big"
                        color="orange"
                    />
                );

            case 'image/jpeg':
            case 'image/png':
                return (
                    <Icon
                        name="file image"
                        size="big"
                        color="grey"
                    />
                )

            default:
                return (
                    <Icon
                        name="file outline"
                        size="big"
                        color="grey"
                    />
                );
        }
    }
}

const intlComponent = injectIntl(DocumentAttachment);
export { intlComponent as DocumentAttachment };

