import { defineMessages } from 'react-intl';
import { NavigationItem } from '../../components/layouts/PageLayout/NavigationMenu';

export const m = defineMessages({
    dashboard: { id: 'navigation.dashboard', defaultMessage: 'Dashboard' },
    afterSalesService: { id: 'navigation.after-sales_service', defaultMessage: 'After-sales service' },
    createServiceCall: { id: 'navigation.create_service_call', defaultMessage: 'Create service call' },
    myServiceCalls: { id: 'navigation.my_service_calls', defaultMessage: 'My service calls' },
    overdueServiceCalls: { id: 'navigation.overdue_service_calls', defaultMessage: 'Overdue service calls' },
    stalledUnassignedServiceCalls: { id: 'navigation.stalled_unassigned_service_calls', defaultMessage: 'Stalled unassigned service calls' },
    serviceCalls: { id: 'navigation.service_calls', defaultMessage: 'Service calls' },
    history: { id: 'navigation.history', defaultMessage: 'History' },
    administration: { id: 'navigation.administration', defaultMessage: 'Administration' },
    reporting: { id: 'PageLayout.reporting', defaultMessage: 'Reporting' },
    search: { id: 'navigation.search', defaultMessage: 'Search...' },
    calendars: { id: 'navigation.calendars', defaultMessage: 'Calendars' },
    techniciansCalendar: { id: 'navigation.technicians_calendar', defaultMessage: 'Technicians appointments' },
    factoryCalendar: { id: 'navigation.factory_calendar', defaultMessage: 'Factory repairs' },

    myAppointments: { id: 'PageLayout.my-appointments', defaultMessage: 'My appointments' },
    myRepairs: { id: 'PageLayout.my-repairs', defaultMessage: 'My repairs' },
    myCalendar: { id: 'PageLayout.my-calendar', defaultMessage: 'My calendar' },
    unscheduledInspections: { id: 'PageLayout.unscheduled_inspections', defaultMessage: 'Inspections to schedule' },
    users: { id: 'PageLayout.users', defaultMessage: 'Manage users' },
    subcontractors: { id: 'PageLayout.subcontractors', defaultMessage: 'Manage subcontractors' },
    calendar: { id: 'PageLayout.calendar', defaultMessage: 'Manage calendar' },
    statistics: { id: 'PageLayout.statistics', defaultMessage: 'Statistics' },
    detailedStatistics: { id: 'PageLayout.detailed_statistics', defaultMessage: 'Detailed statistics' },
    trends: { id: 'PageLayout.trends', defaultMessage: 'Trends' },
    clientsAdoption: { id: 'PageLayout.clientsAdoption', defaultMessage: 'Clients adoption' },
    staleServiceCalls: { id: 'PageLayout.staleServiceCalls', defaultMessage: 'Stale service calls ' },
    subcontractorsBilling: { id: 'PageLayout.subcontractorsBilling', defaultMessage: 'Subcontractors billing' },
});

export const navigationSections = {
    administration: 'administration',
    afterSalesService: 'afterSalesService',
    calendars: 'calendars',
    reporting: 'reporting'
};

export const dashboardMenuItem = (): NavigationItem => ({ key: 'dashboard', exact: true, to: '/', icon: 'home', title: m.dashboard });

export const afterSalesMenuSection = (): NavigationItem => ({ key: navigationSections.afterSalesService, icon: 'phone', title: m.afterSalesService });
export const createServiceCallMenuItem = (): NavigationItem => ({ key: 'afterSalesService.create', to: '/client/service-calls/create', title: m.createServiceCall });
export const overdueServiceCallsMenuItem = (label?: React.ReactNode): NavigationItem => ({ key: 'afterSalesService.overdue', to: '/service-calls/overdue', title: m.overdueServiceCalls, labelColor: 'red', labelContent: label });
export const stalledUnassignedServiceCallsMenuItem = (label?: React.ReactNode): NavigationItem => ({ key: 'afterSalesService.stalled-unassigned', to: '/service-calls/stalled-unassigned', title: m.stalledUnassignedServiceCalls, labelColor: 'red', labelContent: label });
export const currentServiceCallsMenuItem = (label?: React.ReactNode): NavigationItem => ({ key: 'afterSalesService.current', to: '/service-calls/current', title: m.serviceCalls, labelColor: 'violet', labelContent: label });
export const staleServiceCallsMenuItem = (): NavigationItem => ({ key: navigationSections.afterSalesService + '.stale-service-calls', to: '/service-calls/stale', title: m.staleServiceCalls });
export const myServiceCallsMenuItem = (): NavigationItem => ({ key: 'afterSalesService.mine', to: '/service-calls/mine', title: m.myServiceCalls });
export const serviceCallsHistoryMenuItem = (): NavigationItem => ({ key: 'afterSalesService.history', to: '/service-calls/history', title: m.history });

export const representativeInspections = (): NavigationItem => ({ key: 'afterSalesService.inspections-to-schedule', to: '/representative/unscheduled-inspections', title: m.unscheduledInspections });
export const representativeAppointments = (): NavigationItem => ({ key: 'afterSalesService.my-appointments', to: '/representative/my-appointments', title: m.myAppointments });
export const representativeCalendar = (): NavigationItem => ({ key: 'afterSalesService.my-calendar', to: '/representative/my-calendar', title: m.myCalendar });
export const representativeHistory = (): NavigationItem => ({ key: 'afterSalesService.history', to: '/representative/history', title: m.history });

export const techniciansAppointments = (): NavigationItem => ({ key: 'afterSalesService.my-appointments', to: '/technicians/my-appointments', title: m.myAppointments });
export const techniciansCalendar = (): NavigationItem => ({ key: 'afterSalesService.my-calendar', to: '/technicians/my-calendar', title: m.myCalendar });
export const techniciansHistory = (): NavigationItem => ({ key: 'afterSalesService.history', to: '/technicians/history', title: m.history });

export const factoryAppointments = (): NavigationItem => ({ key: 'afterSalesService.my-appointments', to: '/factory/my-repairs', title: m.myRepairs });
export const factoryCalendar = (): NavigationItem => ({ key: 'afterSalesService.my-calendar', to: '/factory/my-calendar', title: m.myCalendar });
export const factoryHistory = (): NavigationItem => ({ key: 'afterSalesService.history', to: '/factory/history', title: m.history });

export const reportsMenuSection = (): NavigationItem => ({ key: navigationSections.reporting, icon: 'chart bar', title: m.reporting });
export const statisticsMenuItem = (): NavigationItem => ({ key: navigationSections.reporting + '.statistics', to: '/reporting/statistics', title: m.statistics });
export const detailedStatisticsMenuItem = (): NavigationItem => ({ key: navigationSections.reporting + '.detailed-statistics', to: '/reporting/detailed-statistics', title: m.detailedStatistics });
export const trendsMenuItem = (): NavigationItem => ({ key: navigationSections.reporting + '.trends', to: '/reporting/trends', title: m.trends });
export const clientsAdoptionMenuItem = (): NavigationItem => ({ key: navigationSections.reporting + '.client-adoption', to: '/reporting/clients-adoption', title: m.clientsAdoption });
export const subcontractorsBillingMenuItem = (): NavigationItem => ({ key: navigationSections.reporting + '.subcontractors-billing', to: '/reporting/subcontractors-billing', title: m.subcontractorsBilling });

export const calendarsMenuSection = (): NavigationItem => ({ key: navigationSections.calendars, icon: 'calendar', title: m.calendars });
export const techniciansCalendarMenuItem = (): NavigationItem => ({ key: 'afterSalesService.calendar', to: '/technicians/calendar', title: m.techniciansCalendar });
export const factoryCalendarMenuItem = (): NavigationItem => ({ key: 'afterSalesService.factoryCalendar', to: '/factory/my-calendar', title: m.factoryCalendar });

export const administrationMenuSection = (): NavigationItem => ({ key: navigationSections.administration, icon: 'setting', title: m.administration });
export const usersMenuItem = (): NavigationItem => ({ key: navigationSections.administration + '.users', to: '/administration/clients', title: m.users });
export const subcontractorsMenuItem = (): NavigationItem => ({ key: navigationSections.administration + '.subcontractors', to: '/administration/subcontractors', title: m.subcontractors });
export const calendarMenuItem = (): NavigationItem => ({ key: navigationSections.administration + '.calendar', to: '/administration/calendar', title: m.calendar });