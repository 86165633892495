import BaseService from "./baseService";
import queryString from "query-string";

export interface DefectiveItemQueryFilters {
  startDate: Date;
  endDate: Date;
  itemId?: string;
  itemCoverId?: string;
  productTypeId?: string;
  clientId?: string;
  provinceCode?: string;
  billingMonth?: number;
}

export class ServiceCallAnalysisService extends BaseService {
  constructor() {
    super("api/service-calls/analysis");
  }

  getFilterQueryString(filters: DefectiveItemQueryFilters) {
    return queryString.stringify({
      ...filters,
      startDate: filters.startDate.toISOString(),
      endDate: filters.endDate.toISOString(),
    });
  }

  getDefectiveItemCountByItem(filters: DefectiveItemQueryFilters): Promise<any> {
    return this.get(`/defective-item-count/by-item?${this.getFilterQueryString(filters)}`);
  }

  getDefectiveItemCountByCover(filters: DefectiveItemQueryFilters): Promise<any> {
    return this.get(`/defective-item-count/by-cover?${this.getFilterQueryString(filters)}`);
  }

  getDefectiveItemCountByProductType(filters: DefectiveItemQueryFilters): Promise<any> {
    return this.get(
      `/defective-item-count/by-product-type?${this.getFilterQueryString(filters)}`
    );
  }

  getDefectiveItemCountByWeeksSinceShipping(filters: DefectiveItemQueryFilters): Promise<any> {
    return this.get(
      `/defective-item-count/by-weeks-since-shipping?${this.getFilterQueryString(filters)}`
    );
  }

  getDefectiveItemCountByWeeksSinceBilling(filters: DefectiveItemQueryFilters): Promise<any> {
    return this.get(
      `/defective-item-count/by-weeks-since-billing?${this.getFilterQueryString(filters)}`
    );
  }

  getDefectiveItemCountByDefectType(filters: DefectiveItemQueryFilters): Promise<any> {
    return this.get(
      `/defective-item-count/by-defect-type?${this.getFilterQueryString(filters)}`
    );
  }

  getDefectiveItemCountByDefectCategory(filters: DefectiveItemQueryFilters): Promise<any> {
    return this.get(
      `/defective-item-count/by-defect-category?${this.getFilterQueryString(filters)}`
    );
  }

  getDefectiveItemCountByDefectCause(filters: DefectiveItemQueryFilters): Promise<any> {
    return this.get(
      `/defective-item-count/by-defect-cause?${this.getFilterQueryString(filters)}`
    );
  }

  getProblems(filters: DefectiveItemQueryFilters): Promise<any> {
    return this.get(`/problems?${this.getFilterQueryString(filters)}`);
  }

  getInspectionReportDescriptions(filters: DefectiveItemQueryFilters): Promise<any> {
    return this.get(`/inspection-report-descriptions?${this.getFilterQueryString(filters)}`);
  }

  getTechnicianReportDescriptions(filters: DefectiveItemQueryFilters): Promise<any> {
    return this.get(`/technician-report-descriptions?${this.getFilterQueryString(filters)}`);
  }

  getFactoryReportDescriptions(filters: DefectiveItemQueryFilters): Promise<any> {
    return this.get(`/factory-report-descriptions?${this.getFilterQueryString(filters)}`);
  }
}
