import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Confirm, ButtonProps, Button, SemanticShorthandItem, ModalContentProps, ModalHeaderProps } from 'semantic-ui-react';

interface ConfirmButtonOwnProps extends ButtonProps {
    cancelButton?: SemanticShorthandItem<ButtonProps>;
    confirmButton?: SemanticShorthandItem<ButtonProps>;
    confirmContent?: SemanticShorthandItem<ModalContentProps>;
    confirmHeader?: SemanticShorthandItem<ModalHeaderProps>;

    onConfirm: () => void;
    onCancel?: () => void;
}

interface ConfirmButtonState {
    isOpen: boolean;
}

export type ConfirmButtonProps =
    & ConfirmButtonOwnProps
    & WrappedComponentProps;

const m = defineMessages({
});

class ConfirmButton extends React.Component<ConfirmButtonProps, ConfirmButtonState> {
    public constructor(props: ConfirmButtonProps) {
        super(props);

        this.state = { isOpen: false };
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const { intl, cancelButton, confirmButton, confirmContent, confirmHeader, onConfirm, onCancel, ...buttonProps } = this.props;

        return (
            <Confirm
                trigger={<Button onClick={this.openModal} {...buttonProps} />}
                open={this.state.isOpen}
                cancelButton={this.props.cancelButton}
                confirmButton={this.props.confirmButton}
                content={this.props.confirmContent}
                header={this.props.confirmHeader}
                onConfirm={this.confirm}
                onCancel={this.cancel}
            />
        );
    }

    private confirm = () => {
        this.props.onConfirm();
        this.closeModal();
    }

    private cancel = () => {
        if (this.props.onCancel) {
            this.props.onCancel();
        }

        this.closeModal();
    }
    
    private openModal = () => {
        this.setState({ isOpen: true });
    }

    private closeModal = () => {
        this.setState({ isOpen: false });
    }
}

const connectedComponent = injectIntl(ConfirmButton);
export { connectedComponent as ConfirmButton };