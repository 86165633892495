import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedNumber } from 'react-intl';
import { List, Table, TableProps } from 'semantic-ui-react';
import { OrderRow } from './OrderRow';
import { OrderDetail } from '../../../../state/models/OrderDetail';
import _ from 'lodash';
import { Order } from '../../../../state/models/Order';
import { ProductionOrder, formatQuantity, Quantity } from '../../../../state/models';

interface OwnProps {
    order: Order;
    defaultSelectedRow?: OrderDetail;
    onSelectionChange: (item: OrderDetail | undefined) => void;
}

export type OrderTableProps =
    & OwnProps
    & TableProps
    & WrappedComponentProps;

interface StateProps {
    selectedRow: OrderDetail | undefined;
}

const m = defineMessages({
    itemHeader: { id: 'OrderTable.itemHeader', defaultMessage: 'Item' },
    coverHeader: { id: 'OrderTable.coverHeader', defaultMessage: 'Cover' },
    quantityHeader: { id: 'OrderTable.quantityHeader', defaultMessage: 'Qty' },
    quantityHeaderTooltip: { id: 'OrderTable.quantityHeaderTooltip', defaultMessage: 'Quantity' },
    priceHeader: { id: 'OrderTable.priceHeader', defaultMessage: 'Total price' },
    packageCountHeader: { id: 'OrderTable.packageCountHeader', defaultMessage: 'Pkg' },
    packageCountHeaderTooltip: { id: 'OrderTable.packageCountHeaderTooltip', defaultMessage: 'Number of packages' },
    notesHeader: { id: 'OrderTable.notesHeader', defaultMessage: 'Item notes' }
});

class OrderTable extends React.Component<OrderTableProps, StateProps> {
    public constructor(props: OrderTableProps) {
        super(props);

        this.state = { selectedRow: props.defaultSelectedRow };
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const { intl, order, onSelectionChange, ...tableProps } = this.props;

        return (
            <Table basic="very" fixed={true} selectable={true} singleLine={true} {...tableProps}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell style={{ width: '25px' }} />
                        <Table.HeaderCell style={{ width: '100px' }}>{formatMessage(m.itemHeader)}</Table.HeaderCell>
                        <Table.HeaderCell style={{ width: '100px' }}>{formatMessage(m.coverHeader)}</Table.HeaderCell>
                        <Table.HeaderCell>{formatMessage(m.notesHeader)}</Table.HeaderCell>
                        <Table.HeaderCell style={{ width: '70px' }} title={formatMessage(m.quantityHeaderTooltip)}>{formatMessage(m.quantityHeader)}</Table.HeaderCell>
                        <Table.HeaderCell style={{ width: '70px' }} title={formatMessage(m.packageCountHeaderTooltip)}>{formatMessage(m.packageCountHeader)}</Table.HeaderCell>
                        <Table.HeaderCell style={{ width: '115px' }}>{formatMessage(m.priceHeader)}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.props.order.orderDetails.map(this.renderRow)}
                </Table.Body>
                <Table.Footer fullWidth={true}>
                    <Table.Row style={{ background: '#f9fafb' }}>
                        <Table.HeaderCell colSpan={4} />
                        <Table.HeaderCell textAlign="right">{this.renderTotalQuantity()}</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">{this.renderTotalPackages()}</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">{this.renderTotalPrice()}</Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        );
    }

    private renderRow = (detail: OrderDetail) => {
        return (
            <OrderRow
                key={detail.id}
                orderDetail={detail}
                currency={this.props.order.currency}
                className={this.state.selectedRow != null && this.state.selectedRow.id === detail.id ? 'c-order-row--selected' : ''}
                onClick={() => this.toggleRow(detail)}
            />
        );
    }

    private toggleRow = (detail: OrderDetail) => {
        if (this.state.selectedRow != null && this.state.selectedRow.id === detail.id) {
            this.setState({ selectedRow: undefined });
            this.props.onSelectionChange(undefined);
        } else {
            this.setState({ selectedRow: detail });
            this.props.onSelectionChange(detail);
        }
    }

    private renderTotalQuantity = () => {
        const totals = _.chain(this.props.order.orderDetails)
            .map(x => x.quantityOrdered)
            .groupBy(quantity => quantity.unit)
            .map((quantities, unit): Quantity => ({
                value: _.sumBy(quantities, quantity => quantity.value),
                unit: unit !== 'null' ? unit : ''
            }))
            .value();

        return (
            <React.Fragment>
                {totals.map(total => <p key={total.unit}>{formatQuantity(total)}</p>)}
            </React.Fragment>
        );
    }

    private renderTotalPackages = () => {
        const total = _.chain(this.props.order.orderDetails).sumBy(x => x.packageCount).value();
        return <span>{total}</span>;

    }

    private renderTotalPrice = () => {
        const total = _.chain(this.props.order.orderDetails).sumBy(x => x.quantityOrdered.value * x.price).value();
        return <FormattedNumber value={total} style="currency" currency={this.props.order.currency} />;
    }
}

const intlComponent = injectIntl(OrderTable);
export { intlComponent as OrderTable };