import { defineMessages } from "@formatjs/intl";
import _, { DebounceSettings } from "lodash";
import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { Search, SearchProps, SearchResultData, SearchResultProps } from "semantic-ui-react";
import * as api from '../../../modules/clients/api';
import { Client } from "../../../state/models";
import useApiErrorHandler from "../../../utils/ApiErrorHandler";
import { ClientSearchResult } from "./ClientSearchResult";

interface OwnProps {
    onResultSelected: (client: Client | undefined) => void;
}

export type ClientSearchProps =
    & SearchProps
    & OwnProps;

const debounceDelayInMilliseconds: number = 600;
const debounceOptions: DebounceSettings = {};

const m = defineMessages({
    noResults: { id: 'ClientSearchControl.no_results', defaultMessage: 'No clients found' },
})

export const ClientSearch: React.FC<ClientSearchProps> = (props) => {
    const { onSearchChange, onReseltSelected, ...searchProps } = props;
    const { formatMessage } = useIntl();
    const handleError = useApiErrorHandler();
    const [isSearching, setIsSearching] = useState(false);
    const [searchResults, setSearchResults] = useState<Client[]>([]);
    let lastSearchTimestamp = 0;

    const performSearch = (searchTerm: string) => {
        lastSearchTimestamp = Date.now();

        api.search(searchTerm, lastSearchTimestamp)
            .then(response => {
                if (response.timestamp === lastSearchTimestamp) {
                    setSearchResults(response.results)
                    setIsSearching(false);
                }
                setSearchResults(response.results);
                setIsSearching(false);
            })
            .catch((error) => handleError(error))
            .finally(() => setIsSearching(false));
    }

    const delayedSearch = useCallback(_.debounce(performSearch, debounceDelayInMilliseconds, debounceOptions), []);

    const handleSearchChange = (event: React.MouseEvent<HTMLElement>, data: SearchProps) => {
        setIsSearching(true);
        delayedSearch(data.value || '');

        if (onSearchChange != null) {
            onSearchChange(event, data);
        }
    }

    const handleResultSelect = (event: React.MouseEvent<HTMLDivElement>, data: SearchResultData) => {
        const client = searchResults.find(result => result.id === data.result.label);
        props.onResultSelected(client);
    }

    const displayedResults = searchResults?.map((result): SearchResultProps => ({
            label: result.id,
            title: result.name,
            description: result.alternateName
        })) || [];

    const renderResult = (result: SearchResultProps) => <ClientSearchResult result={result} />

    return (
        <Search
            fluid
            showNoResults={!isSearching}
            results={displayedResults}
            noResultsMessage={formatMessage(m.noResults)}
            resultRenderer={renderResult}
            onSearchChange={handleSearchChange}
            onResultSelect={handleResultSelect}
            loading={isSearching}
            {...searchProps}
        />
    );
};