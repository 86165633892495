import React from 'react';
import moment from 'moment';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Table, List } from 'semantic-ui-react';

import { LoadingContainer, LoadingDimmer } from '../common';
import { Notification } from '../../state/models';
import { NotificationHelper } from '../../helpers';

interface NotificationListOwnProps {
    isLoading: boolean;
    notifications: Notification[];
    onClick: (notification: Notification) => void;
}

export type NotificationListProps =
    & NotificationListOwnProps
    & WrappedComponentProps;

const m = defineMessages({
});

class NotificationList extends React.Component<NotificationListProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const hasNotifications = this.props.notifications && this.props.notifications.length > 0;

        return (
            <LoadingContainer>
                <LoadingDimmer active={this.props.isLoading} />

                {hasNotifications &&
                    <List divided={true} selection={true}>
                        {this.props.notifications.map(x => this.renderNotificationItem(x))}
                    </List>
                }
            </LoadingContainer>
        );
    }

    private renderNotificationItem(notification: Notification) {
        const { formatMessage } = this.props.intl;
        const isRead = notification.readOn != null;
        const className = isRead ? 'c-notification c-notification--read' : 'c-notification c-notification--unread';

        return (
            <List.Item className={className} onClick={() => this.props.onClick(notification)}>
                <List.Icon name="circle" color="red" className="c-notification__icon" />
                <List.Content>
                    <List.Header className="c-notification__title">
                        {formatMessage(NotificationHelper.getDescription(notification), { referenceId: notification.referenceId })}
                    </List.Header>
                    <List.Description>{moment.utc(notification.createdOn).fromNow()}</List.Description>
                </List.Content>
            </List.Item>
        );
    }
}

const connectedComponent = injectIntl(NotificationList);
export { connectedComponent as NotificationList };