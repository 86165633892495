import React from 'react';
import moment from 'moment';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import { Card, List, Form, Button } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { commonMessages } from '../../../constants';
import { ServiceCallFactoryRepair, CalendarBreak, PreferredLanguage } from '../../../state/models';
import { AgentActionItem } from '..';
import { DateHelper } from '../../../helpers';
import { FactoryCalendarEvent } from '../factory';
import { CalendarModal, CalendarEvent } from '../../calendar';
import { SendEmailModal } from '../../after-sales';

export interface FactoryRepairsCardProps {
    serviceCallId: number;
    lockedDates: CalendarBreak[];
    clientEmails: string[];
    clientDefaultLanguage?: PreferredLanguage;
    factoryRepairs: ServiceCallFactoryRepair[];
    areFactoryRepairsLoading: boolean;
    isExecutingStep: boolean;
    currentFactoryRepair: ServiceCallFactoryRepair | undefined;
    onSendNotification: (email: string | undefined, locale: string | undefined) => void;
    onChangeCalendarDate: (date: Date) => void;
    onScheduleRepairs: (date: Date) => void;
    onConfirmReception: () => void;
    onConfirmRepairs: () => void;
    onRepairClicked: (repair: ServiceCallFactoryRepair) => void;
}

interface FactoryRepairsCardState {
    scheduledRepairsDate?: Date;
    isCalendarModalOpen?: boolean;
    calendarModalStartDate: Date;
    isEmailModalOpen?: boolean;
}

const m = defineMessages({
    title: { id: 'FactoryRepairsCard.title', defaultMessage: 'Factory repairs' },
    subtitle: { id: 'FactoryRepairsCard.subtitle', defaultMessage: 'Send a notification to the client for the factory repairs.' },
    notifyStepTitle: { id: 'FactoryRepairsCard.notify_step_title', defaultMessage: 'Request the furniture\'s shipment' },
    notifyStepDescription: { id: 'FactoryRepairsCard.notify_step_description', defaultMessage: 'Notify the client that the furniture needs to be repaired in Jaymar\'s factory.' },
    planRepairsStepTitle: { id: 'FactoryRepairsCard.plan_repairs_step_title', defaultMessage: 'Schedule the factory repairs (optional)' },
    planRepairsStepDescription: { id: 'FactoryRepairsCard.plan_repairs_step_description', defaultMessage: 'Specify the date when the repairs are expected to start for the furniture.' },
    confirmReceptionStepTitle: { id: 'FactoryRepairsCard.confirm_reception_step_title', defaultMessage: 'Confirm the furniture\'s reception' },
    confirmReceptionStepDescription: { id: 'FactoryRepairsCard.confirm_reception_step_description', defaultMessage: 'Confirm that the furniture was received at Jaymar\'s warehouse.' },
    confirmRepairsStepTitle: { id: 'FactoryRepairsCard.confirm_repairs_step_title', defaultMessage: 'Confirm the repairs completion' },
    confirmRepairsStepDescription: { id: 'FactoryRepairsCard.confirm_repairs_step_description', defaultMessage: 'Confirm that the furniture\'s defects have all been repaired.' },
    repairsScheduledDatePlaceholder: { id: 'FactoryRepairsCard.repairs_scheduled_date_placeholder', defaultMessage: 'YYYY/MM/DD' },
    viewUpcomingRepairs: { id: 'FactoryRepairsCard.view_upcoming_repairs', defaultMessage: 'View Calendar' },
    modalTitle: { id: 'FactoryCalendarModal.title', defaultMessage: 'Factory repairs' },
    modalSubtitle: { id: 'FactoryCalendarModal.subtitle', defaultMessage: 'View the schedule for the factory repairs.' },
    emailModalTitle: { id: 'FactoryRepairsCard.email_modal_title', defaultMessage: 'Send a confirmation email' },
    emailModalSubtitle: { id: 'FactoryRepairsCard.email_modal_subtitle', defaultMessage: 'Send automatically an email message to the client to notify him that the furniture needs to be shipped to Jaymar\' factory.' },
    emailAttachmentDescription: { id: 'FactoryRepairsCard.email_attachment_description', defaultMessage: 'Return merchandise authorization form' }
});

class FactoryRepairsCard extends React.Component<FactoryRepairsCardProps & WrappedComponentProps, FactoryRepairsCardState> {
    public constructor(props: FactoryRepairsCardProps & WrappedComponentProps) {
        super(props);

        this.state = {
            scheduledRepairsDate: props.currentFactoryRepair && props.currentFactoryRepair.scheduledFor ? moment(props.currentFactoryRepair.scheduledFor).local().toDate() : undefined,
            calendarModalStartDate: moment().startOf('day').toDate(),
        };
    }

    public componentWillReceiveProps(props: FactoryRepairsCardProps & WrappedComponentProps) {
        this.setState({
            scheduledRepairsDate: props.currentFactoryRepair && props.currentFactoryRepair.scheduledFor ? moment(props.currentFactoryRepair.scheduledFor).local().toDate() : undefined,
        });
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const isWaitingToReceiveShipment = this.props.currentFactoryRepair != null && this.props.currentFactoryRepair.receivedOn == null;
        const isWaitingForRepairs = this.props.currentFactoryRepair != null && this.props.currentFactoryRepair.receivedOn != null;

        const isNotificationRequired = !isWaitingToReceiveShipment && !isWaitingForRepairs;
        const isSchedulingRequired = this.props.currentFactoryRepair != null && this.props.currentFactoryRepair.scheduledFor == null;
        const isScheduled = !isSchedulingRequired && this.props.currentFactoryRepair != null && this.props.currentFactoryRepair.scheduledFor != null;
        const isFurnitureReceptionRequired = !isNotificationRequired && isWaitingToReceiveShipment;
        const isRepairCompletionRequired = isWaitingForRepairs;

        return (
            <Card fluid={true} color="blue" style={{ height: '100%' }}>
                <Card.Content style={{ flexGrow: 0 }}>
                    <Card.Header>{formatMessage(m.title)}</Card.Header>
                    <Card.Meta>{formatMessage(m.subtitle)}</Card.Meta>
                </Card.Content>
                <Card.Content>
                    <List divided={true} relaxed="very">
                        {this.renderNotifyStep(isNotificationRequired, this.props.currentFactoryRepair != null)}
                        {this.renderScheduleStep(!isNotificationRequired, isScheduled)}
                        {this.renderConfirmReceptionStep(isFurnitureReceptionRequired, isRepairCompletionRequired)}
                        {this.renderConfirmRepairsStep(isRepairCompletionRequired, false)}
                    </List>
                </Card.Content>
            </Card>
        );
    }

    private renderNotifyStep(isEnabled: boolean, isCompleted: boolean) {
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <AgentActionItem
                    isEnabled={isEnabled}
                    isCompleted={isCompleted}
                    icon="send"
                    title={formatMessage(m.notifyStepTitle)}
                    description={formatMessage(m.notifyStepDescription)}
                    completeButtonText={formatMessage(commonMessages.notify)}
                    isCompleteButtonLoading={this.props.isExecutingStep}
                    isCompleteButtonDisabled={this.props.isExecutingStep}
                    onCompleteClick={() => this.setState({ isEmailModalOpen: true })}
                    completedButtonText={formatMessage(commonMessages.notified)}
                />

                <SendEmailModal
                    previewUri={`/email-templates/return-merchandise-authorization`}
                    attachmentsPreviewUris={[{
                        filename: `RMA-${this.props.serviceCallId}.pdf`,
                        previewUri: `/reports/factory-repairs-rma/${this.props.serviceCallId}`,
                        description: formatMessage(m.emailAttachmentDescription)
                    }]}
                    title={formatMessage(m.emailModalTitle)}
                    subtitle={formatMessage(m.emailModalSubtitle)}
                    size="tiny"
                    defaultEmails={this.props.clientEmails}
                    defaultLanguage={this.props.clientDefaultLanguage}
                    open={this.state.isEmailModalOpen}
                    onClose={() => this.setState({ isEmailModalOpen: false })}
                    onCancel={() => this.setState({ isEmailModalOpen: false })}
                    onSend={this.sendEmail}
                    onSendManually={this.sendEmailManually}
                />
            </React.Fragment>
        );
    }

    private sendEmail = (email: string, locale: string) => {
        this.setState({ isEmailModalOpen: false });
        this.props.onSendNotification(email, locale);
    }

    private sendEmailManually = () => {
        this.setState({ isEmailModalOpen: false });
        this.props.onSendNotification(undefined, undefined);
    }

    private renderScheduleStep(isEnabled: boolean, isCompleted: boolean) {
        const { formatMessage } = this.props.intl;
        const areControlsVisible = isCompleted || isEnabled;
        const calendarEvents: CalendarEvent[] = this.props.factoryRepairs != null
            ? this.props.factoryRepairs
                .filter(x => x.scheduledFor != null)
                .map(x => ({
                    on: moment(x.scheduledFor).local().startOf('day').toDate(),
                    element: <FactoryCalendarEvent key={x.id} factoryRepair={x} onClick={this.props.onRepairClicked} />
                }))
            : [];

        return (
            <AgentActionItem
                isEnabled={isEnabled}
                isCompleted={isCompleted}
                icon="calendar"
                title={formatMessage(m.planRepairsStepTitle)}
                description={formatMessage(m.planRepairsStepDescription)}
                completeButtonText={formatMessage(commonMessages.schedule)}
                isCompletedButtonDisabled={this.state.scheduledRepairsDate == null || this.props.isExecutingStep}
                isCompleteButtonLoading={this.props.isExecutingStep}
                completedButtonText={formatMessage(commonMessages.modify)}
                onCompleteClick={this.handleScheduleRepairs}
                onCompletedClick={this.handleScheduleRepairs}
            >
                {areControlsVisible &&
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <DatePicker
                                    selected={this.state.scheduledRepairsDate != null ? moment(this.state.scheduledRepairsDate).toDate() : undefined}
                                    dateFormat="YYYY/MM/DD"
                                    filterDate={DateHelper.isWeekday}
                                    fixedHeight={true}
                                    minDate={new Date()}
                                    placeholderText={formatMessage(m.repairsScheduledDatePlaceholder)}
                                    value={this.state.scheduledRepairsDate ? moment(this.state.scheduledRepairsDate).format('YYYY/MM/DD') : undefined}
                                    onChange={this.handleDateChange}
                                />
                            </Form.Field>
                            <Form.Field>
                                {this.renderCalendarModalTrigger()}
                                <CalendarModal
                                    open={this.state.isCalendarModalOpen}
                                    selectedDate={this.state.scheduledRepairsDate}
                                    forDate={this.state.calendarModalStartDate}
                                    events={calendarEvents}
                                    lockedDates={this.props.lockedDates}
                                    isLoading={this.props.areFactoryRepairsLoading}
                                    canSelectDate={true}
                                    onMonthChanged={this.handleChangeRepairsMonth}
                                    onClose={() => this.setState({ isCalendarModalOpen: false })}
                                    onCancel={() => this.setState({ isCalendarModalOpen: false })}
                                    title={formatMessage(m.modalTitle)}
                                    subtitle={formatMessage(m.modalSubtitle)}
                                    closeIcon={true}
                                    onDateSelected={(date: Date) => this.setState({ scheduledRepairsDate: date, isCalendarModalOpen: false })}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                }
            </AgentActionItem>
        );
    }

    private renderConfirmReceptionStep(isEnabled: boolean, isCompleted: boolean) {
        const { formatMessage } = this.props.intl;

        return (
            <AgentActionItem
                isEnabled={isEnabled}
                isCompleted={isCompleted}
                icon="truck"
                title={formatMessage(m.confirmReceptionStepTitle)}
                description={formatMessage(m.confirmReceptionStepDescription)}
                completeButtonText={formatMessage(commonMessages.confirm)}
                isCompleteButtonLoading={this.props.isExecutingStep}
                isCompleteButtonDisabled={this.props.isExecutingStep}
                onCompleteClick={this.props.onConfirmReception}
                completedButtonText={formatMessage(commonMessages.confirmed)}
            />
        );
    }

    private renderConfirmRepairsStep(isEnabled: boolean, isCompleted: boolean) {
        const { formatMessage } = this.props.intl;

        return (
            <AgentActionItem
                isEnabled={isEnabled}
                isCompleted={isCompleted}
                icon="check"
                title={formatMessage(m.confirmRepairsStepTitle)}
                description={formatMessage(m.confirmRepairsStepDescription)}
                completeButtonText={formatMessage(commonMessages.confirm)}
                isCompleteButtonDisabled={this.props.isExecutingStep}
                isCompleteButtonLoading={this.props.isExecutingStep}
                onCompleteClick={this.props.onConfirmRepairs}
                completedButtonText={formatMessage(commonMessages.confirmed)}
            />
        );
    }

    private renderCalendarModalTrigger = () => {
        const { formatMessage } = this.props.intl;

        return (
            <Button
                icon="calendar"
                onClick={() => {
                    this.handleChangeRepairsMonth(moment().startOf('day').startOf('month').toDate());
                    this.setState({ isCalendarModalOpen: true, calendarModalStartDate: moment().startOf('day').toDate() });
                }}
            />
        );
    }

    // tslint:disable-next-line:no-any (using same declaration as underlying event handler)
    private handleDateChange = (date: Date | null, _event: React.SyntheticEvent<any> | undefined): any => {
        this.setState({ scheduledRepairsDate: date || undefined });
    }

    private handleChangeRepairsMonth = (date: Date) => {
        this.setState({ calendarModalStartDate: date });
        this.props.onChangeCalendarDate(date);
    }

    private handleScheduleRepairs = () => {
        if (this.state.scheduledRepairsDate) {
            this.props.onScheduleRepairs(this.state.scheduledRepairsDate);
        }
    }
}

const intlComponent = injectIntl(FactoryRepairsCard);
export { intlComponent as FactoryRepairsCard };