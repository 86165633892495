import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { Notification } from '../../state/models';
import { ApplicationState } from '../../state/ducks';
import * as NotificationsActions from '../../state/ducks/notifications';
import { getAllNotifications } from '../../state/ducks/notifications/selectors';
import { PageHeader } from '../../components/common';
import { NotificationList } from '../../components/navigation';

interface NotificationsPageActions {
    notificationsActions: typeof NotificationsActions.actionCreators;
}

interface NotificationsPageOwnProps {
    notifications: Notification[];
}

export type NotificationsPageProps =
    & NotificationsPageOwnProps
    & NotificationsPageActions
    & RouteComponentProps<{}>
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'NotificationsPage.title', defaultMessage: 'Notifications' },
    subtitle: { id: 'NotificationsPage.subtitle', defaultMessage: 'View all your recent notifications.' }
});

class NotificationsPage extends React.Component<NotificationsPageProps, {}> {
    public componentWillMount() {
        this.props.notificationsActions.loadAll();
    }

    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <PageHeader
                    iconName="bell"
                    title={formatMessage(m.title)}
                    subtitle={formatMessage(m.subtitle)}
                />

                <NotificationList
                    isLoading={false}
                    notifications={this.props.notifications}
                    onClick={this.onNotificationClick}
                />
            </React.Fragment>
        );
    }

    private onNotificationClick = (notification: Notification) => {
        if (notification.referenceId != null) {
            this.props.history.push(`/service-calls/${notification.referenceId}/details`);
        }

        this.props.notificationsActions.read(notification);
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        notifications: getAllNotifications(state)
    };
};

const mapDispatchToProps = (dispatch: Dispatch): NotificationsPageActions => {
    return {
        notificationsActions: bindActionCreators(NotificationsActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(NotificationsPage);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as NotificationsPage };