import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Grid, Message } from 'semantic-ui-react';

import { SectionHeader, InformationMessage } from '../common';
import { Bill, Client, OrderEdit, ValidationResult } from '../../state/models';
import { BillCard } from '../cards';
import { OrderInformationForm } from '.';

interface OrderInformationStepOwnProps {
    order: OrderEdit;
    validationResult: ValidationResult;
    isClientSearchVisible?: boolean;
    billOverview?: Bill;
    isBillRequired?: boolean;

    onChange: (fieldId: string, newValue: string) => void;
    onClientSelected: (client: Client | undefined) => void;
    onBillSelected?: (bill: Bill | undefined) => void;
    onBillMouseOver?: (bill: Bill) => void;
}

export type OrderInformationStepProps =
    & OrderInformationStepOwnProps
    & WrappedComponentProps;

interface OrderInformationStepState {
    billOverview?: Bill;
}

const m = defineMessages({
    sectionTitle: { id: 'OrderInformationForm.section_title', defaultMessage: 'Order information' },
    sectionSubtitle: { id: 'OrderInformationForm.section_subtitle', defaultMessage: 'Enter your Jaymar bill number to help us retrieve the information about your Jaymar product.' },
    callOpenedWarningTitle: { id: 'OrderInformationForm.callOpenedWarningTitle', defaultMessage: 'Service call already in progress' },
    callOpenedWarningDescription: { id: 'OrderInformationForm.callOpenedWarningDescription', defaultMessage: 'A service call has already been opened for this invoice. You can modify it or create a new one if you wish.' },
    callOpenedFormat: { id: 'OrderInformationForm.callOpenedFormat', defaultMessage: 'Service call #{number}' }
});

class OrderInformationStep extends React.Component<OrderInformationStepProps, OrderInformationStepState> {
    public constructor(props: OrderInformationStepProps) {
        super(props);

        this.state = { billOverview: props.billOverview };
    }

    public componentWillReceiveProps(props: OrderInformationStepProps) {
        this.setState({ billOverview: props.billOverview });
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const { billOverview } = this.state;
        const openedServiceCalls = billOverview != null && billOverview.serviceCalls != null ? billOverview.serviceCalls.filter(x => x.closedOn == null) : [];

        return (
            <div>
                <SectionHeader
                    title={formatMessage(m.sectionTitle)}
                    subtitle={formatMessage(m.sectionSubtitle)}
                />

                <Grid stackable={true} columns={2}>
                    <Grid.Column>
                        <OrderInformationForm
                            order={this.props.order}
                            validationResult={this.props.validationResult}
                            onChange={this.props.onChange}
                            onBillSelected={this.handleBillSelected}
                            onBillMouseOver={this.handleBillMouseOver}
                            isClientSearchVisible={this.props.isClientSearchVisible}
                            onClientSelected={this.props.onClientSelected}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        {billOverview &&
                            <React.Fragment>
                                {openedServiceCalls.length > 0 &&
                                    <Message warning={true}>
                                        <Message.Header>{formatMessage(m.callOpenedWarningTitle)}</Message.Header>
                                        <Message.Content>
                                            {formatMessage(m.callOpenedWarningDescription)}
                                            <ul>
                                                {openedServiceCalls.map(x => <li key={x.id}><a href={`/service-calls/${x.id}/details`}>{formatMessage(m.callOpenedFormat, { number: x.id })}</a></li>)}
                                            </ul>
                                        </Message.Content>
                                    </Message>
                                }
                                <BillCard bill={billOverview} />
                            </React.Fragment>
                        }
                    </Grid.Column>
                </Grid>
            </div>
        );
    }

    private handleBillMouseOver = (bill: Bill) => {
        this.setState((current) => ({
            ...current,
            billOverview: bill
        }));
    }

    private handleBillSelected = (bill: Bill | undefined) => {
        this.setState((current) => ({
            ...current,
            billOverview: bill
        }));

        if (this.props.onBillSelected) {
            this.props.onBillSelected(bill);
        }
    }
}

const connectedComponent = injectIntl(OrderInformationStep);
export { connectedComponent as OrderInformationStep };