import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../state/ducks';
import { UserAccountType } from '../../state/models';
import { AgentDashboardPage } from './agent';
import { ClientDashboardPage } from './client';
import { getCurrentUserRole } from '../../state/ducks/current-user';
import { TechnicianDashboardPage } from '.';
import { FactoryDashboardPage } from './factory';
import { RepresentativeDashboardPage } from './representative';
import { AdministratorDashboardPage } from '../administration';

interface DashboardPageActions {
}

interface DashboardPageOwnProps {
    currentUserRole: UserAccountType;
}

export type DashboardPageProps =
    & DashboardPageOwnProps
    & DashboardPageActions
    & RouteComponentProps<{}>;

class DashboardPage extends React.Component<DashboardPageProps, {}> {
    public render() {
        const { currentUserRole, ...dashboardProps } = this.props;

        switch (this.props.currentUserRole) {
            case UserAccountType.administrator:
                return <AdministratorDashboardPage />;

            case UserAccountType.customerServiceAgent:
                return <AgentDashboardPage />;

            case UserAccountType.client:
                return <ClientDashboardPage {...dashboardProps} />;

            case UserAccountType.jaymarTechnician:
                return <TechnicianDashboardPage {...dashboardProps} />;

            case UserAccountType.factoryTechnician:
                return <FactoryDashboardPage {...dashboardProps} />;

            case UserAccountType.jaymarRepresentative:
                return <RepresentativeDashboardPage {...dashboardProps} />;

            default:
                return (null);
        }
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        currentUserRole: getCurrentUserRole(state)
    };
};

const mapDispatchToProps = (dispatch: Dispatch): DashboardPageActions => {
    return {
    };
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
export { connectedComponent as DashboardPage };