import { BaseService } from './baseService';
import { ServiceCall, User, FilterOptions } from '../state/models';

export class StatisticsService extends BaseService {
    constructor() {
        super('api/service-calls/statistics');
    }

    getNew(): Promise<any> {
        return this.get('/new');
    }

    getAgentsWorkload(): Promise<any> {
        return this.get('/agents-workload');
    }

    getAgentNextActions(): Promise<any> {
        return this.get('/actions');
    }
}