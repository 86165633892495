import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { SubcontractorsBillingFilters, isInitialSubcontractorsBillingFilters, SubcontractorFilter } from '../../types';
import { FilterPopup } from '../../../../../components/common';
import { FilterByDateSection } from '../../../../../components/reporting';
import { DateFilterType, DateFilter } from '../../../../../state/models';
import { FilterBySubcontractorSection } from './FilterBySubcontractorSection';

interface OwnProps {
    filters: SubcontractorsBillingFilters;
    onApply: (filters: SubcontractorsBillingFilters) => void;
}

interface StateProps {
    appliedFilters: SubcontractorsBillingFilters;
    pendingFilters: SubcontractorsBillingFilters;
}

export type SubcontractorsBillingFiltersPopupProps =
    & OwnProps
    & WrappedComponentProps;

const m = defineMessages({
});

class SubcontractorsBillingFiltersPopup extends React.Component<SubcontractorsBillingFiltersPopupProps, StateProps> {
    public constructor(props: SubcontractorsBillingFiltersPopupProps) {
        super(props);

        this.state = {
            appliedFilters: props.filters,
            pendingFilters: props.filters,
        };
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const hasFiltersApplied = !isInitialSubcontractorsBillingFilters(this.state.appliedFilters);

        return (
            <FilterPopup hasFiltersApplied={hasFiltersApplied} onApply={this.applyFilters} onClose={this.resetPopupFilters}>
                <FilterByDateSection
                    filter={this.state.pendingFilters.dateFilter}
                    availableFilters={[DateFilterType.thisMonth, DateFilterType.lastMonth, DateFilterType.thisYear, DateFilterType.custom]}
                    onChange={this.changeDateFilter}
                />
                <FilterBySubcontractorSection
                    filter={this.state.pendingFilters.subcontractorFilter}
                    onChange={this.changeSubcontractorFilter}
                />
            </FilterPopup>
        );
    }

    private changeDateFilter = (filter: DateFilter) => {
        this.setState((current) => ({
            ...current,
            pendingFilters: {
                ...current.pendingFilters,
                dateFilter: filter
            }
        }));
    }

    private changeSubcontractorFilter = (filter: SubcontractorFilter) => {
        this.setState((current) => ({
            ...current,
            pendingFilters: {
                ...current.pendingFilters,
                subcontractorFilter: filter
            }
        }));
    }

    private resetPopupFilters = () => {
        this.setState((current) => ({ pendingFilters: current.appliedFilters }));
    }

    private applyFilters = () => {
        this.setState((current) => ({ appliedFilters: current.pendingFilters }));
        this.props.onApply(this.state.pendingFilters);
    }
}

const intlComponent = injectIntl(SubcontractorsBillingFiltersPopup);
export { intlComponent as SubcontractorsBillingFiltersPopup };