import React from 'react';
import moment from 'moment';

import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Popup } from 'semantic-ui-react';

interface RelativeDateOwnProps {
    date: Date;
}

export type RelativeDateProps =
    & RelativeDateOwnProps
    & WrappedComponentProps;

class RelativeDate extends React.Component<RelativeDateProps, {}> {
    public render() {
        return (
            <Popup
                trigger={moment.utc(this.props.date).fromNow()}
                content={moment.utc(this.props.date).format('LL')}
            />
        );
    }
}

const connectedComponent = injectIntl(RelativeDate);
export { connectedComponent as RelativeDate };