import React from 'react';
import { Input, InputProps, Icon } from 'semantic-ui-react';

interface OwnProps {
    onClear: () => void;
}

export type ClearableInputProps =
    & InputProps
    & OwnProps;

export const ClearableInput: React.FC<ClearableInputProps> = (props) => {
    const { onClear, ...inputProps } = props;

    return (
        <Input icon={<Icon name="close" link onClick={props.onClear} />} {...inputProps} />
    );
};