import moment from 'moment';
import React from 'react';

import { defineMessages, useIntl } from 'react-intl';
import { List, Modal } from 'semantic-ui-react';
import {
    SubcontractorRepair
} from '../../../state/models';

const m = defineMessages({
    title: {
        id: 'SubcontractorReportModal.title',
        defaultMessage: 'Subcontractor report',
    },
    reportDate: {
        id: 'SubcontractorReportModal.report_date',
        defaultMessage: 'Date',
    },
    report: {
        id: 'SubcontractorReportModal.report',
        defaultMessage: 'Report details',
    },
    subcontractorName: {
        id: 'SubcontractorReportModal.subcontractor_name',
        defaultMessage: 'Subcontractor',
    },
});

interface SubcontractorReportModalProps {
    trigger: React.ReactNode;
    subcontractorRepair: SubcontractorRepair;
}

export const SubcontractorReportModal = ({
    trigger,
    subcontractorRepair,
}: SubcontractorReportModalProps) => {
    const { formatMessage } = useIntl();

    const subContractorName = subcontractorRepair.otherSubcontractorName ?? subcontractorRepair.subcontractor?.name;

    return (
        <Modal trigger={trigger} closeIcon={true} size="fullscreen">
            <Modal.Header>{formatMessage(m.title)}</Modal.Header>
            <Modal.Content scrolling={true}>
                <List relaxed={true}>
                    {subContractorName && (
                        <List.Item>
                            <List.Icon
                                name="id card outline"
                                size="large"
                                verticalAlign="top"
                            />
                            <List.Content>
                                <List.Header
                                    content={formatMessage(m.subcontractorName)}
                                />
                                <List.Description>
                                    {subContractorName}
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    )}
                    {subcontractorRepair.reportDate && (
                        <List.Item>
                            <List.Icon
                                name="calendar check"
                                size="large"
                                verticalAlign="top"
                            />
                            <List.Content>
                                <List.Header
                                    content={formatMessage(m.reportDate)}
                                />
                                <List.Description>
                                    {moment
                                        .utc(subcontractorRepair.reportDate)
                                        .format('LL')}
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    )}

                    <React.Fragment>
                        <List.Item>
                            <List.Icon
                                name="pencil"
                                size="large"
                                verticalAlign="top"
                            />
                            <List.Content>
                                <List.Header
                                    content={formatMessage(m.report)}
                                />
                                <List.Description>
                                    <p style={{ whiteSpace: 'pre-line' }}>
                                        {subcontractorRepair.report}
                                    </p>
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    </React.Fragment>
                </List>
            </Modal.Content>
        </Modal>
    );
};
