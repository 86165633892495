import { Client, SearchResults } from '../../state/models';
import { get, httpDelete, post } from '../../utils/api';

const apiEndpoint = '/api/clients';

export async function loadById(clientId: string): Promise<Client> {
    return get<Client>(`${apiEndpoint}/${clientId}`);
}

export async function search(searchTerm: string, timestamp?: number): Promise<SearchResults<Client>> {
    return get<SearchResults<Client>>(`${apiEndpoint}/find/${searchTerm}?timestamp=${timestamp || Date.now()}`);
}