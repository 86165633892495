import * as _ from 'lodash';
import { Reducer, combineReducers } from 'redux';
import { UsersState } from './types';
import { UsersActionTypes, LOAD_ALL_SUCCESS, LOAD_ALL_REQUEST, LOAD_ALL_FAILURE, UPDATE_SUCCESS, UPDATE_REQUEST, UPDATE_FAILURE, LOAD_BY_ID_REQUEST, LOAD_BY_ID_SUCCESS, LOAD_BY_ID_FAILURE, CREATE_EMPLOYEE_SUCCESS, ENABLE_USER_REQUEST, DISABLE_USER_REQUEST, SET_FILTERS, CREATE_CLIENT_SUCCESS } from './actions';
import { UserAccountType, User } from '../../../state/models';

const initialState: UsersState = {
    byId: {},
    allIds: [],
    isLoading: false,
    loadingIds: [],
    filters: {
        areDisabledAccountsHidden: true
    }
};

export const usersReducer: Reducer<UsersState> = (state = initialState, action: UsersActionTypes) => {
    switch (action.type) {
        case LOAD_ALL_REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case LOAD_ALL_SUCCESS: {
            const allUsers = Object.assign({}, ...action.payload.users.map(x => ({ [x.id]: x })));
            const allUserIds = action.payload.users.map(x => x.id);

            return {
                ...state,
                byId: allUsers,
                allIds: allUserIds,
                isLoading: false,
            };
        }

        case LOAD_ALL_FAILURE: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case LOAD_BY_ID_REQUEST: {
            return {
                ...state,
                loadingIds: _.union(state.loadingIds, [action.payload.userId])
            };
        }

        case LOAD_BY_ID_SUCCESS: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.user.id]: action.payload.user
                },
                allIds: _.union(state.allIds, [action.payload.user.id]),
                loadingIds: state.loadingIds.filter(x => x !== action.payload.user.id)
            };
        }

        case LOAD_BY_ID_FAILURE: {
            return {
                ...state,
                loadingIds: state.loadingIds.filter(x => x !== action.payload.userId)
            };
        }

        case DISABLE_USER_REQUEST: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.userId]: {
                        ...state.byId[action.payload.userId],
                        isDisabled: true,
                    },
                },
            };
        }

        case ENABLE_USER_REQUEST: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.userId]: {
                        ...state.byId[action.payload.userId],
                        isDisabled: false
                    },
                },
            };
        }

        case CREATE_EMPLOYEE_SUCCESS:
        case CREATE_CLIENT_SUCCESS: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.user.id]: action.payload.user
                },
                allIds: _.union(state.allIds, [action.payload.user.id])
            };
        }
        
        case UPDATE_REQUEST: {
            return {
                ...state,
                loadingIds: _.union(state.loadingIds, [action.payload.user.id || ''])
            };
        }

        case UPDATE_SUCCESS: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.user.id]: action.payload.user
                },
                allIds: _.union(state.allIds, [action.payload.user.id]),
                loadingIds: state.loadingIds.filter(x => x !== action.payload.user.id)
            };
        }

        case UPDATE_FAILURE: {
            return {
                ...state,
                loadingIds: state.loadingIds.filter(x => x !== action.payload.user.id)
            };
        }

        case SET_FILTERS: {
            return {
                ...state,
                filters: action.payload.filters
            };
        }

        default:
            return state;
    }
};