import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Checkbox, Grid, Segment } from 'semantic-ui-react';
import { LoadingDimmer, SectionHeader } from '../../components/common';
import { ContactInformationCard, ProblemInformationCard, ServiceCallRecentActivity } from '../../components/service-calls';
import { DraftModeWarning } from '../../components/service-calls/draft';
import { getCurrentUser, isCurrentUserAClient, isCurrentUserAnEmployee } from '../../modules/account/selectors';
import { OrderSummaryCard } from '../../modules/orders/views/components/OrderSummaryCard';
import { ApplicationState } from '../../state/ducks';
import { actionCreators as ServiceCallsActions, areServiceCallActivitiesLoading, areServiceCallDocumentsUploading, areServiceCallMediasUploading, getDocuments, getPictures, getServiceCall, getServiceCallActivities, getServiceCallAttachmentsBeingDeleted, getSubcontractorRepairPictures, getVideos, isServiceCallLoading } from '../../state/ducks/service-calls';
import { actionCreators as UiActions } from '../../state/ducks/ui';
import { ClientContactEdit, CustomerEdit, OrderEdit, ProblemEdit, ServiceCallStatus, ValidationResult } from '../../state/models';
import { ServiceCallEdit } from '../../state/models/edits/ServiceCallEdit';
import { OrderDetail } from '../../state/models/OrderDetail';

const m = defineMessages({
    recentActivity: { id: 'ServiceCallDetailsPage.recent_activity', defaultMessage: 'Recent activity' },
    displayLinkedCalls: { id: 'ServiceCallDetailsPage.displayLinkedCalls', defaultMessage: 'Display details from linked service calls' }
});

interface RouteProps {
    serviceCallId: string;
}

export const ServiceCallDetailsPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const routeProps = useParams<RouteProps>();

    const [areLinkedCallsDisplayed, setAreLinkedCallsDisplayed] = useState(true);

    const serviceCallId = Number(routeProps.serviceCallId);
    const serviceCall = useSelector((state: ApplicationState) => getServiceCall(state, serviceCallId));
    const isLoadingServiceCall = useSelector((state: ApplicationState) => isServiceCallLoading(state, serviceCallId));
    const isLoadingActivities = useSelector((state: ApplicationState) => areServiceCallActivitiesLoading(state, serviceCallId));

    const currentUser = useSelector(getCurrentUser);
    const isEmployee = useSelector(isCurrentUserAnEmployee);
    const isClient = useSelector(isCurrentUserAClient);

    const serviceCallActivities = useSelector((state: ApplicationState) => getServiceCallActivities(state, serviceCallId));
    const serviceCallDocuments = useSelector((state: ApplicationState) => getDocuments(state, serviceCallId));
    const serviceCallPictures = useSelector((state: ApplicationState) => getPictures(state, serviceCallId));
    const serviceCallSubcontractorRepairPictures = useSelector((state: ApplicationState) => getSubcontractorRepairPictures(state, serviceCallId));
    const serviceCallVideos = useSelector((state: ApplicationState) => getVideos(state, serviceCallId));
    const serviceCallMedias = [...serviceCallPictures, ...serviceCallSubcontractorRepairPictures, ...serviceCallVideos];

    const isUploadingDocuments = useSelector((state: ApplicationState) => areServiceCallDocumentsUploading(state, serviceCallId));
    const isUploadingMedias = useSelector((state: ApplicationState) => areServiceCallMediasUploading(state, serviceCallId));
    const serviceCallAttachmentsBeingDeleted = useSelector((state: ApplicationState) => getServiceCallAttachmentsBeingDeleted(state, serviceCallId));

    const isServiceCallInDraftMode = serviceCall?.status === ServiceCallStatus.draft;
    const isServiceCallEditable = serviceCall ? serviceCall.status !== ServiceCallStatus.closed : false;

    const serviceCallEdit = serviceCall != null ? ServiceCallEdit.fromEntity(serviceCall) : undefined;
    if (serviceCallEdit != null) {
        serviceCallEdit.order.isBillIdOptional = isEmployee;
        serviceCallEdit.problem.areAttachmentsRequired = false;
    }

    useEffect(() => {
        const serviceCallId = Number(routeProps.serviceCallId);
        dispatch(ServiceCallsActions.loadDetails(serviceCallId));
        dispatch(ServiceCallsActions.loadActivities(serviceCallId));
    }, [dispatch, routeProps.serviceCallId]);

    useEffect(() => {
        UiActions.changeServiceCallTab('details');
    }, []);

    const handleContactEdit = (customerEdit: CustomerEdit, clientEdit: ClientContactEdit): ValidationResult[] => {
        const customerValidation = customerEdit.validate();
        const clientValidation = !isEmployee ? clientEdit.validate() : ValidationResult.empty();

        if (customerValidation.isValid && clientValidation.isValid && serviceCall != null) {
            const serviceCallEdit = ServiceCallEdit.fromEntity(serviceCall);
            serviceCallEdit.customer = customerEdit;
            serviceCallEdit.client = clientEdit;

            dispatch(ServiceCallsActions.updateServiceCall(serviceCallEdit));
        }

        return [customerValidation, clientValidation];
    }

    const handleOrderItemClick = (orderItem: OrderDetail) => history.push(`/orders/${orderItem.orderId}/items/${orderItem.id}`);

    const handleOrderEdit = (orderEdit: OrderEdit): ValidationResult => {
        const validationResult = orderEdit.validate();

        if (validationResult.isValid && serviceCall != null) {
            const serviceCallEdit = ServiceCallEdit.fromEntity(serviceCall);
            serviceCallEdit.order = orderEdit;

            dispatch(ServiceCallsActions.updateServiceCall(serviceCallEdit));
        }

        return validationResult;
    }

    const handleProblemEdit = (problemEdit: ProblemEdit): ValidationResult => {
        const validationResult = problemEdit.validate();

        if (validationResult.isValid && serviceCall != null) {
            const serviceCallEdit = ServiceCallEdit.fromEntity(serviceCall);
            serviceCallEdit.problem = problemEdit;

            dispatch(ServiceCallsActions.updateServiceCall(serviceCallEdit));
        }

        return validationResult;
    }

    const handleMediaUpload = (files: FileList): void => {
        if (files && files.length > 0 && serviceCall != null) {
            if (files[0].type.includes('video')) {
                dispatch(ServiceCallsActions.uploadVideos(serviceCall.id, Array.from(files)));
            } else {
                dispatch(ServiceCallsActions.uploadPictures(serviceCall.id, Array.from(files)));
            }
        }
    }

    const handleDocumentsUpload = (files: FileList): void => {
        if (files && files.length > 0 && serviceCall != null) {
            dispatch(ServiceCallsActions.uploadDocuments(serviceCall.id, Array.from(files)));
        }
    }

    const handleDeleteAttachment = (mediaId: number): void => {
        if (serviceCall != null) {
            dispatch(ServiceCallsActions.deleteAttachment(serviceCall.id, mediaId));
        }
    }

    return (
        <Segment basic style={{ padding: 0 }}>
            {isEmployee &&
                <div style={{ textAlign: 'right', marginBottom: '1rem' }}>
                    <Checkbox
                        toggle
                        checked={areLinkedCallsDisplayed}
                        disabled={isLoadingServiceCall}
                        onChange={(e, data) => setAreLinkedCallsDisplayed(data.checked ?? false)}
                        label={formatMessage(m.displayLinkedCalls)}
                    />
                </div>
            }

        {isServiceCallInDraftMode && <DraftModeWarning />}

        <Grid stackable columns="equal">
            <Grid.Row>
                <Grid.Column>
                    <LoadingDimmer active={isLoadingServiceCall} />
                    <ContactInformationCard
                        serviceCall={serviceCall}
                        customer={serviceCall?.customer}
                        client={serviceCall?.clientContact}
                        canEdit={isServiceCallEditable}
                        onEdit={handleContactEdit}
                    />
                </Grid.Column>

                <Grid.Column>
                    <LoadingDimmer active={isLoadingServiceCall} />
                    <OrderSummaryCard
                        serviceCall={serviceCall}
                        orderEdit={serviceCallEdit ? serviceCallEdit.order : undefined}
                        canEdit={isServiceCallEditable}
                        isClientSearchVisible={isEmployee}
                        onItemClick={!isClient ? handleOrderItemClick : undefined}
                        onUpdate={handleOrderEdit}
                    />
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column>
                    <LoadingDimmer active={isLoadingServiceCall} />
                    <ProblemInformationCard
                        serviceCallId={serviceCallId}
                        serviceCall={serviceCall}
                        areLinkedCallsDisplayed={areLinkedCallsDisplayed}
                        currentUserId={currentUser?.id}
                        isServiceCallLoading={isLoadingServiceCall}
                        documents={serviceCallDocuments}
                        medias={serviceCallMedias}
                        canEdit={isServiceCallEditable}
                        onEdit={handleProblemEdit}
                        onUploadMedia={handleMediaUpload}
                        areMediasUploading={isUploadingMedias}
                        areDocumentsUploading={isUploadingDocuments}
                        isAttachmentDeleting={serviceCallAttachmentsBeingDeleted}
                        onDeleteAttachment={handleDeleteAttachment}
                        onUploadDocuments={handleDocumentsUpload}
                        problemEdit={serviceCallEdit?.problem}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>

        <SectionHeader title={formatMessage(m.recentActivity)} />
        <ServiceCallRecentActivity
            serviceCall={serviceCall}
            areLinkedCallsDisplayed={areLinkedCallsDisplayed}
            isLoading={isLoadingActivities}
            updates={serviceCallActivities}
        />
    </Segment>
    );
};