import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { ApplicationState } from '../../../state/ducks';
import { Grid } from 'semantic-ui-react';
import { AppointmentInformationCard, RepairsInformationCard, ProblemInformationCard } from '../../../components/service-calls';
import * as UiActions from '../../../state/ducks/ui';
import * as ServiceCallsActions from '../../../state/ducks/service-calls';
import { TechnicianRepair, ServiceCallAttachment, ProblemEdit, ValidationResult } from '../../../state/models';
import { ServiceCallState } from '../../../state/ducks/service-calls/reducers';
import { getServiceCallState, getPictures, getDocuments, getVideos } from '../../../state/ducks/service-calls';
import { getAppointmentById } from '../../../state/ducks/appointments/selectors';
import { ServiceCallEdit } from '../../../state/models/edits/ServiceCallEdit';

interface TechnicianPrepareStepActions {
    serviceCallsActions: typeof ServiceCallsActions.actionCreators;
    uiActions: typeof UiActions.actionCreators;
}

interface TechnicianPrepareStepOwnProps {
    serviceCall: ServiceCallState;
    appointment: TechnicianRepair | undefined;
    isAppointmentLoading: boolean;
    uploadedDocuments: ServiceCallAttachment[];
    uploadedPictures: ServiceCallAttachment[];
    uploadedVideos: ServiceCallAttachment[];
    areDocumentsUploading: boolean;
    arePicturesUploading: boolean;
}

export type TechnicianPrepareStepProps =
    & TechnicianPrepareStepOwnProps
    & TechnicianPrepareStepActions
    & RouteComponentProps<{ serviceCallId: string, repairId: string }>;

type IntlTechnicianPrepareStepProps = TechnicianPrepareStepProps & WrappedComponentProps;

class TechnicianPrepareStep extends React.Component<IntlTechnicianPrepareStepProps, {}> {
    public componentDidMount() {
        this.props.uiActions.changeTechnicianStep('prepare');
    }

    public render() {
        const serviceCall = this.props.serviceCall && !this.props.serviceCall.isLoading ? this.props.serviceCall.details : undefined;
        const isLoading = this.props.serviceCall && this.props.serviceCall.isLoading;
        const serviceCallEdit = serviceCall ? ServiceCallEdit.fromEntity(serviceCall) : undefined;

        return (
            <Grid stackable={true}>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <AppointmentInformationCard
                            isLoading={isLoading}
                            serviceCall={serviceCall}
                            appointment={this.props.appointment}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <RepairsInformationCard
                            isLoading={isLoading}
                            defectiveItems={serviceCall ? serviceCall.defectiveItems : []}
                            defects={serviceCall ? serviceCall.defects : []}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <ProblemInformationCard
                            serviceCallId={Number(this.props.match.params.serviceCallId)}
                            serviceCall={serviceCall}
                            isServiceCallLoading={!this.props.serviceCall || this.props.serviceCall.isLoading}
                            medias={this.props.uploadedPictures.concat(this.props.uploadedVideos)}
                            documents={this.props.uploadedDocuments}
                            canEdit={true}
                            onEdit={this.handleProblemEdit}
                            onUploadMedia={this.handleFilesUpload}
                            areMediasUploading={this.props.arePicturesUploading}
                            areDocumentsUploading={this.props.areDocumentsUploading}
                            onDeleteAttachment={this.handleDeletePicture}
                            onUploadDocuments={this.handleDocumentsUpload}
                            problemEdit={serviceCallEdit ? serviceCallEdit.problem : undefined}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    private handleProblemEdit = (problemEdit: ProblemEdit): ValidationResult => {
        const validationResult = problemEdit.validate();

        if (validationResult.isValid) {
            var serviceCallEdit = ServiceCallEdit.fromEntity(this.props.serviceCall.details);
            serviceCallEdit.problem = problemEdit;

            this.props.serviceCallsActions.updateServiceCall(serviceCallEdit);
        }

        return validationResult;
    }

    private handleFilesUpload = (files: FileList): void => {
        if (files && files.length > 0) {
            if (files[0].type.includes('video')) {
                this.props.serviceCallsActions.uploadVideos(
                    this.props.serviceCall.details.id,
                    Array.from(files)
                );
            } else {
                this.props.serviceCallsActions.uploadPictures(
                    this.props.serviceCall.details.id,
                    Array.from(files)
                );
            }
        }
    }

    private handleDocumentsUpload = (files: FileList): void => {
        if (files && files.length > 0) {
            this.props.serviceCallsActions.uploadDocuments(
                this.props.serviceCall.details.id,
                Array.from(files)
            );
        }
    }

    private handleDeletePicture = (pictureId: number): void => {
        this.props.serviceCallsActions.deleteAttachment(
            this.props.serviceCall.details.id,
            pictureId
        );
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: TechnicianPrepareStepProps): TechnicianPrepareStepOwnProps => {
    const repairId = Number(ownProps.match.params.repairId);
    const serviceCallId = Number(ownProps.match.params.serviceCallId);
    const serviceCallState = getServiceCallState(state, serviceCallId);
    const appointmentState = getAppointmentById(state, repairId);

    return {
        serviceCall: serviceCallState,
        appointment: appointmentState != null ? appointmentState.appointment : undefined,
        isAppointmentLoading: appointmentState != null ? appointmentState.isLoading : true,
        uploadedPictures: getPictures(state, serviceCallId),
        uploadedVideos: getVideos(state, serviceCallId),
        uploadedDocuments: getDocuments(state, serviceCallId),
        arePicturesUploading: serviceCallState ? serviceCallState.arePicturesUploading : false,
        areDocumentsUploading: serviceCallState ? serviceCallState.areDocumentsUploading : false,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): TechnicianPrepareStepActions => {
    return {
        serviceCallsActions: bindActionCreators(ServiceCallsActions.actionCreators, dispatch),
        uiActions: bindActionCreators(UiActions.actionCreators, dispatch),
    };
};

const intlComponent = injectIntl(TechnicianPrepareStep);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as TechnicianPrepareStep };