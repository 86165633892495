import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { actionCreators as InspectionsActions } from '../../../state/ducks/inspections';

import { ServiceCallInspection } from '../../../state/models';
import { InspectionTable } from '../../../components/service-calls/inspection';
import { PageHeader } from '../../../components/common';
import { getUnscheduledInspections, areUnscheduledInspectionsLoading } from '../../../state/ducks/inspections/selectors';
import { useResponsive } from '../../../utils/responsive';

const m = defineMessages({
    title: { id: 'RepresentativeUnscheduledInspectionsPage.title', defaultMessage: 'Inspections to schedule' },
    subtitle: { id: 'RepresentativeUnscheduledInspectionsPage.subtitle', defaultMessage: 'View the service call inspections that needs to be scheduled with the client.' },
    noInspectionsToScheduleTitle: { id: 'RepresentativeUnscheduledInspectionsPage.no_inspections_to_schedule_title', defaultMessage: 'No inspections to schedule' },
    noInspectionsToScheduleSubtitle: { id: 'RepresentativeUnscheduledInspectionsPage.no_inspections_to_schedule_subtitle', defaultMessage: 'There are no inspections that require to be scheduled for the moment.' }
});

export const RepresentativeUnscheduledInspectionsPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const { isMobile } = useResponsive();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(InspectionsActions.loadUnscheduled());
    }, [dispatch]);

    const inspections = useSelector(getUnscheduledInspections);
    const isLoading = useSelector(areUnscheduledInspectionsLoading);

    const navigateToSchedulingPage = (inspection: ServiceCallInspection) => 
        history.push(`/service-calls/${inspection.serviceCallId}/inspections/${inspection.id}/schedule`);

    const commonProps = {
        inspections: inspections,
        isLoading: isLoading,
        emptyTitle: formatMessage(m.noInspectionsToScheduleTitle),
        emptySubtitle: formatMessage(m.noInspectionsToScheduleSubtitle),
        isScheduledForHidden: true,
        isCompletedOnHidden: true,
        onViewDetails: navigateToSchedulingPage
    };

    return (
        <React.Fragment>
            <PageHeader
                iconName="calendar"
                title={formatMessage(m.title)}
                subtitle={formatMessage(m.subtitle)}
            />

            {isMobile && <InspectionTable isViewButtonHidden={true} {...commonProps} /> }
            {!isMobile && <InspectionTable {...commonProps} /> }
        </React.Fragment>
    );
};