import { useIntl } from "react-intl";
import { Header } from "semantic-ui-react";

export const ServiceCallClosedMessage = () => {
    const { formatMessage } = useIntl();

    return (
        <Header
            as="h2"
            content={formatMessage({
                id: 'SubcontractorRepairPage.ServiceCallClosedMessage.Header',
                defaultMessage:
                    "The service call associated with this repair is closed.",
            })}
        />
    );
};
