import { hasFailedAuthentication, isAuthenticating } from "modules/account/selectors";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { defineMessages, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Form, Segment } from "semantic-ui-react";
import { LoginEdit } from '../../../models';
import { LoginValidationSummary } from "./LoginValidationSummary";

interface OwnProps {
    onLogin: (data: LoginEdit) => void;
}

export type LoginFormProps =
    & OwnProps;

const m = defineMessages({
    emailPlaceholder: { id: 'LoginForm.emailPlaceholder', defaultMessage: 'Email' },
    emailRequiredValidation: { id: 'LoginForm.emailRequiredValidation', defaultMessage: 'Enter your email address.' },
    passwordPlaceholder: { id: 'LoginForm.passwordPlaceholder', defaultMessage: 'Password' },
    passwordRequiredValidation: { id: 'LoginForm.passwordRequiredValidation', defaultMessage: 'Enter your password.'},
    loginButton: { id: 'LoginForm.loginButton', defaultMessage: 'Login' },
    authenticationFailedValidation: { id: 'LoginForm.authenticationFailedValidation', defaultMessage: 'The username or password is invalid.'}
});

export const LoginForm: React.FC<LoginFormProps> = (props) => {
    const { formatMessage } = useIntl();
    const { control, formState: { errors }, setValue, handleSubmit } = useForm<LoginEdit>();

    const isUserAuthenticating = useSelector(isAuthenticating);
    const hasUserFailedAuthentication = useSelector(hasFailedAuthentication);
    if (hasUserFailedAuthentication) {
        errors.username = { message: formatMessage(m.authenticationFailedValidation), type: "validate" };
        errors.password = { type: "validate" }
    }

    return (
        <>
            <Segment raised style={{ width: '100%' }}>
                <Form size="large">
                    <Controller 
                        name={'username'}
                        control={control}
                        rules={{ required: formatMessage(m.emailRequiredValidation) }}
                        render={(props) => (
                            <Form.Input 
                                error={errors.username != null}
                                icon="user" 
                                iconPosition="left" 
                                placeholder={formatMessage(m.emailPlaceholder)}
                                onChange={(e, data) => setValue('username', data.value)}
                            />
                        )}
                    />

                    <Controller 
                        name={'password'}
                        control={control}
                        rules={{ required: formatMessage(m.passwordRequiredValidation) }}
                        render={(props) => (
                            <Form.Input
                                error={errors.password != null}
                                icon="lock" 
                                iconPosition="left" 
                                type="password" 
                                placeholder={formatMessage(m.passwordPlaceholder)}
                                onChange={(e, data) => setValue('password', data.value)}
                            />
                        )}
                    />
                    
                    <Form.Button
                        fluid
                        disabled={isUserAuthenticating}
                        loading={isUserAuthenticating}
                        size="large"
                        color="teal"
                        content={formatMessage(m.loginButton)}
                        onClick={handleSubmit(props.onLogin)}
                    />
                </Form>
            </Segment>

            <LoginValidationSummary errors={errors} style={{ width: '100%' }} />
        </>
    );
};