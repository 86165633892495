import { ActionTypeKeys } from './types';
import { ServiceCallTabs, ServiceCallAgentSteps, ServiceCallTechnicianSteps, ServiceCallFactorySteps, ServiceCallInspectionSteps } from './reducers';
import { ApplicationState } from '..';

export type ActionTypes = 
    | ChangeServiceCallTab
    | ChangeAgentStep
    | ChangeFactoryStep
    | ChangeTechnicianStep
    | ChangeInspectionStep
    | { type: '' };

interface ChangeServiceCallTab { type: ActionTypeKeys.CHANGE_SERVICE_CALL_TAB; payload: ServiceCallTabs; }
interface ChangeAgentStep { type: ActionTypeKeys.CHANGE_AGENT_STEP; payload: ServiceCallAgentSteps; }
interface ChangeFactoryStep { type: ActionTypeKeys.CHANGE_FACTORY_STEP; payload: ServiceCallFactorySteps; }
interface ChangeTechnicianStep { type: ActionTypeKeys.CHANGE_TECHNICIAN_STEP; payload: ServiceCallTechnicianSteps; }
interface ChangeInspectionStep { type: ActionTypeKeys.CHANGE_INSPECTION_STEP; payload: ServiceCallInspectionSteps; }

export const actionCreators = {
    changeAgentStep: (stepId: ServiceCallAgentSteps) => {
        return { type: ActionTypeKeys.CHANGE_AGENT_STEP, payload: stepId };
    },

    changeFactoryStep: (stepId: ServiceCallFactorySteps) => {
        return { type: ActionTypeKeys.CHANGE_FACTORY_STEP, payload: stepId };
    },

    changeTechnicianStep: (stepId: ServiceCallTechnicianSteps) => {
        return { type: ActionTypeKeys.CHANGE_TECHNICIAN_STEP, payload: stepId };
    },

    changeInspectionStep: (stepId: ServiceCallInspectionSteps) => {
        return { type: ActionTypeKeys.CHANGE_INSPECTION_STEP, payload: stepId };
    },

    changeServiceCallTab: (tabId: ServiceCallTabs) => {
        return { type: ActionTypeKeys.CHANGE_SERVICE_CALL_TAB, payload: tabId };
    }
};