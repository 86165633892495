import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { ServiceCall, FactoryReport } from '../../../state/models';
import { Card, Grid, Divider, List, Button } from 'semantic-ui-react';
import { LoadingDimmer } from '../../common';
import { commonMessages } from '../../../constants';

interface FactoryReportSummaryCardOwnProps {
    isLoading?: boolean;
    isCompleting: boolean;
    isComplete: boolean;
    serviceCall: ServiceCall | undefined;
    report: FactoryReport | undefined;
    onComplete: (report: FactoryReport) => void;
}

export type FactoryReportSummaryCardProps =
    & FactoryReportSummaryCardOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'FactoryReportSummaryCard.title', defaultMessage: 'Service report summary' },
    subtitle: { id: 'FactoryReportSummaryCard.subtitle', defaultMessage: 'Review the work performed and mark the repairs as complete.'},
    problemSummary: { id: 'FactoryReportSummaryCard.problem_summary', defaultMessage: 'Problem summary' },
    workPerformed: { id: 'FactoryReportSummaryCard.work_performed', defaultMessage: 'Work performed' },
    labourTime: { id: 'FactoryServiceReportSummaryCard.labour_time', defaultMessage: 'Labour time' }
});

class FactoryReportSummaryCard extends React.Component<FactoryReportSummaryCardProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;

        return (
            <Card fluid={true} color="teal" style={{height: '100%'}}>
                <Card.Content style={{flexGrow: 0}}>
                    <Card.Header content={formatMessage(m.title)} />
                    <Card.Meta content={formatMessage(m.subtitle)} />
                </Card.Content>
                <Card.Content>
                    <LoadingDimmer active={this.props.isLoading} />
                    {!this.props.isLoading && this.props.serviceCall &&
                        <div>
                            <h5>{formatMessage(m.problemSummary)}</h5>
                            {this.props.serviceCall.problemDescription}
                            <Divider />
                            
                            {this.props.report &&
                                <React.Fragment>
                                    <h5>{formatMessage(m.workPerformed)}</h5>
                                    <p>{this.props.report.report}</p>
                                    <Divider />

                                    <Grid columns={2} stackable={true}>
                                        <Grid.Column>
                                            <List>
                                                <List.Item 
                                                    icon="clock" 
                                                    header={formatMessage(m.labourTime)}
                                                    content={this.getLabourTimeDescription(this.props.report.hoursTaken, this.props.report.minutesTaken)}
                                                />
                                            </List>
                                        </Grid.Column>
                                        <Grid.Column textAlign="right">
                                            <Button
                                                primary={true}
                                                disabled={this.props.isCompleting || this.props.isComplete}
                                                loading={this.props.isCompleting}
                                                icon="check"
                                                content={formatMessage(commonMessages.confirm)}
                                                onClick={() => this.props.onComplete(this.props.report!)}
                                            />
                                        </Grid.Column>
                                    </Grid>
                                </React.Fragment>
                            }
                        </div>
                    }

                </Card.Content>
            </Card>
        );
    }

    private getLabourTimeDescription(hours: number, minutes: number): string {
        const formattedHours = hours < 10 ? '0' + hours : hours;
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        
        return `${formattedHours}h${formattedMinutes}`;
    }
}

const connectedComponent = injectIntl(FactoryReportSummaryCard);
export { connectedComponent as FactoryReportSummaryCard };