import moment from 'moment';
import React from 'react';
import { defineMessages, FormattedNumber, injectIntl, WrappedComponentProps } from 'react-intl';
import { Card, Dropdown, DropdownItemProps, Form, Input, List } from 'semantic-ui-react';
import { AgentActionItem } from '..';
import { commonMessages } from '../../../constants';
import { SubcontractorRepair, SubcontractorRepairWarrantyStatus } from '../../../state/models';
import { Subcontractor } from '../../../state/models/Subcontractor';
import { StringHelper } from '../../../state/utils';
import { SendEmailModal } from '../../after-sales';
import { CurrencyInput, SmallDeleteButton, UploadFileButton } from '../../common';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
export interface SubcontractorRepairsCardProps {
    serviceCallId: number;
    subcontractors: Subcontractor[];
    currentRepair?: SubcontractorRepair;
    isExecutingStep: boolean;
    onAssign: (subcontractorId: number | undefined, otherSubcontractorName: string | undefined, email: string | undefined, locale: string | undefined) => void;
    onAddBill: (repairFees: number, shippingFees: number, currencyCode: string, referenceNumber: string, bill?: File) => void;
    onChangeAppointmentDate: (date: Date | undefined) => void;
    onChangeWarrantyStatus: (warrantyStatus: SubcontractorRepairWarrantyStatus) => void;
}
interface SubcontractorRepairsCardState {
    selectedSubcontractorId?: number;
    otherSubcontractorName?: string;
    appointmentDate: Date | undefined;
    repairFees: number;
    shippingFees: number;
    currencyCode: string;
    isAddBillModalOpen: boolean;
    referenceNumber: string;
    invoice?: File;
    isEmailModalOpen: boolean;
    warrantyStatus: SubcontractorRepairWarrantyStatus;
}

const m = defineMessages({
    title: { id: 'SubcontractorRepairsCard.title', defaultMessage: 'Repairs by a subcontractor' },
    subtitle: { id: 'SubcontractorRepairsCard.subtitle', defaultMessage: 'Assign the repairs to a subcontractor.' },
    selectSubcontractorLabel: { id: 'SubcontractorRepairsCard.select_subcontractor_label', defaultMessage: 'Select subcontractor' },
    notifySubcontractor: { id: 'SubcontractorRepairsCard.notify_subcontractor', defaultMessage: 'Assign to Subcontractor' },
    requestedOnFormat: { id: 'SubcontractorRepairsCard.requested_on_format', defaultMessage: 'Requested on {date}' },
    pendingRepairs: { id: 'SubcontractorRepairsCard.pending_repairs', defaultMessage: 'Repairs assigned to' },
    addBill: { id: 'SubcontractorRepairsCard.add_bill', defaultMessage: 'Add Bill' },
    repairFeesLabel: { id: 'AddBillModal.repair_fees', defaultMessage: 'Repair fees' },
    shippingFeesLabel: { id: 'AddBillModal.shipping_fees', defaultMessage: 'Shipping fees' },
    totalLabel: { id: 'AddBillModal.total', defaultMessage: 'Total' },
    others: { id: 'SubcontractorRepairsCard.others', defaultMessage: 'OTHER' },
    selectStepTitle: { id: 'SubcontractorRepairsCard.select_step_title', defaultMessage: 'Select a subcontractor' },
    selectStepDescription: { id: 'SubcontractorRepairsCard.select_step_description', defaultMessage: 'Select the subcontractor that will perform the repairs.' },
    subcontractorPlaceholder: { id: 'SubcontractorRepairsCard.subcontractor_placeholder', defaultMessage: 'Choose a subcontractor' },
    warrantyStepTitle: { id: 'SubcontractorRepairsCard.warranty_step_title', defaultMessage: 'Warranty' },
    warrantyStepDescription: { id: 'SubcontractorRepairsCard.warranty_step_description', defaultMessage: 'Specify whether the repair is under warranty' },
    otherSubcontractorPlaceholder: { id: 'SubcontractorRepairsCard.other_subcontractor_placeholder', defaultMessage: 'Specify the subcontractor\'s name' },
    billingStepTitle: { id: 'SubcontractorRepairsCard.billing_step_title', defaultMessage: 'Add the subcontractor\'s invoice' },
    billingStepDescription: { id: 'SubcontractorRepairsCard.billing_step_description', defaultMessage: 'Add the invoice that the subcontractor issued for the repairs.' },
    referenceNumber: { id: 'SubcontractorRepairsCard.reference_number', defaultMessage: 'Reference number' },
    bill: { id: 'SubcontractorRepairsCard.bill', defaultMessage: 'Invoice' },
    emailAttachmentDescription: { id: 'SubcontractorRepairsCard.email_attachment_description', defaultMessage: 'Subcontractor repairs form' },
    emailModalTitle: { id: 'SubcontractorRepairsCard.email_modal_title', defaultMessage: 'Send a confirmation email' },
    emailModalSubtitle: { id: 'SubcontractorRepairsCard.email_modal_subtitle', defaultMessage: 'Send automatically an email message to the subcontractor to notify him that repairs are requested for the furniture.' },
    appointmentStepTitle: { id: 'SubcontractorRepairsCard.appointmentStepTitle', defaultMessage: 'Set the appointment date (optional)' },
    appointmentStepDescription: { id: 'SubcontractorRepairsCard.appointmentStepDescription', defaultMessage: 'If the subcontractor provided it, you can specify the appointment date with the customer.' },
    appointmentDatePlaceholder: { id: 'SubcontractorRepairsCard.appointmentDatePlaceholder', defaultMessage: 'Appointment date (optional)' }
});

class SubcontractorRepairsCard extends React.Component<SubcontractorRepairsCardProps & WrappedComponentProps, SubcontractorRepairsCardState> {
    private readonly otherSubcontractor: DropdownItemProps = {
        id: 0,
        text: this.props.intl.formatMessage(m.others),
        value: 0
    };

    public constructor(props: SubcontractorRepairsCardProps & WrappedComponentProps) {
        super(props);

        this.state = {
            selectedSubcontractorId: props.currentRepair ? props.currentRepair.subcontractorId : undefined,
            otherSubcontractorName: props.currentRepair ? props.currentRepair.otherSubcontractorName : undefined,
            appointmentDate: props.currentRepair ? props.currentRepair.appointmentDate : undefined,
            isAddBillModalOpen: false,
            repairFees: 0,
            shippingFees: 0,
            currencyCode: 'CAD',
            referenceNumber: '',
            isEmailModalOpen: false,
            warrantyStatus: props.currentRepair?.warrantyStatus ?? SubcontractorRepairWarrantyStatus.Unknown,
        };
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const isSubcontractorSelected = this.props.currentRepair != null
            && (this.props.currentRepair.subcontractor != null || StringHelper.hasValue(this.props.currentRepair.otherSubcontractorName));
        const hasPendingRepairs = this.props.currentRepair != null && this.props.currentRepair.subcontractorBill == null;
        const hasAppointmentDateSpecified = this.props.currentRepair != null && this.props.currentRepair.appointmentDate != null;
        const isWarrantyStatusSpecified = this.props.currentRepair != null && this.props.currentRepair.warrantyStatus !== SubcontractorRepairWarrantyStatus.Unknown;

        return (
            <Card fluid={true} color="blue" style={{ height: '100%' }}>
                <Card.Content style={{ flexGrow: 0 }}>
                    <Card.Header>{formatMessage(m.title)}</Card.Header>
                    <Card.Meta>{formatMessage(m.subtitle)}</Card.Meta>
                </Card.Content>
                <Card.Content>
                    <List divided={true} relaxed="very">
                        {this.renderSelectStep(!isSubcontractorSelected, hasPendingRepairs)}
                        {this.renderWarrantyStep(true, isWarrantyStatusSpecified)}
                        {this.renderAppointmentStep(isSubcontractorSelected, hasAppointmentDateSpecified)}
                        {this.renderBillStep(isSubcontractorSelected, false)}
                    </List>
                </Card.Content>
            </Card>
        );
    }

    private renderSelectStep(isEnabled: boolean, isCompleted: boolean) {
        const { formatMessage } = this.props.intl;

        let subcontractorsOptions = this.props.subcontractors.map((x): DropdownItemProps => ({
            id: x.id,
            text: x.name,
            description: x.region,
            value: x.id
        }));

        subcontractorsOptions = [this.otherSubcontractor, ...subcontractorsOptions];

        const isOtherSubcontractorSelected = this.state.selectedSubcontractorId != null && this.state.selectedSubcontractorId === this.otherSubcontractor.value;
        const isConfirmButtonEnabled = this.state.selectedSubcontractorId != null
            && (this.state.selectedSubcontractorId !== this.otherSubcontractor.value || StringHelper.hasValue(this.state.otherSubcontractorName));

        const selectedSubcontractor = this.state.selectedSubcontractorId ? this.props.subcontractors.find(x => x.id === this.state.selectedSubcontractorId) : undefined;

        return (
            <AgentActionItem
                isEnabled={isEnabled}
                isCompleted={isCompleted}
                icon="id card outline"
                title={formatMessage(m.selectStepTitle)}
                description={formatMessage(m.selectStepDescription)}
                completeButtonText={formatMessage(commonMessages.select)}
                onCompleteClick={() => this.setState({ isEmailModalOpen: true })}
                isCompleteButtonLoading={this.props.isExecutingStep}
                isCompleteButtonDisabled={this.props.isExecutingStep}
                isCompletedButtonDisabled={!isConfirmButtonEnabled}
                completedButtonText={formatMessage(commonMessages.selected)}
            >
                <div>
                    <Dropdown
                        search={true}
                        selection={true}
                        options={subcontractorsOptions}
                        placeholder={formatMessage(m.subcontractorPlaceholder)}
                        fluid={true}
                        deburr={true}
                        onChange={(_event, data) => this.setState({ selectedSubcontractorId: data.value as number })}
                        disabled={!isEnabled}
                        value={this.state.selectedSubcontractorId}
                    />

                    {isOtherSubcontractorSelected &&
                        <Input
                            placeholder={formatMessage(m.otherSubcontractorPlaceholder)}
                            fluid={true}
                            style={{ marginTop: 5 }}
                            onChange={(_event, data) => this.setState({ otherSubcontractorName: data.value as string })}
                        />
                    }
                </div>

                <SendEmailModal
                    previewUri={`/email-templates/subcontractor-repair-requested`}
                    attachmentsPreviewUris={[{
                        filename: `Service-sous-traitant-${this.props.serviceCallId}.pdf`,
                        previewUri: `/reports/subcontractor-repairs/${this.props.serviceCallId}`,
                        description: formatMessage(m.emailAttachmentDescription)
                    }]}
                    defaultEmails={selectedSubcontractor ? selectedSubcontractor.emails : undefined}
                    defaultLanguage={selectedSubcontractor ? selectedSubcontractor.preferredLanguage : undefined}
                    title={formatMessage(m.emailModalTitle)}
                    subtitle={formatMessage(m.emailModalSubtitle)}
                    size="tiny"
                    open={this.state.isEmailModalOpen}
                    onClose={() => this.setState({ isEmailModalOpen: false })}
                    onCancel={() => this.setState({ isEmailModalOpen: false })}
                    onSend={this.sendEmail}
                    onSendManually={this.sendEmailManually}
                />
            </AgentActionItem>
        );
    }

    private renderWarrantyStep(isEnabled: boolean, isCompleted: boolean) {
        const { formatMessage } = this.props.intl;

        const warrantyOptions: DropdownItemProps[] = [
            {
                id: SubcontractorRepairWarrantyStatus.OutOfWarranty,
                text: formatMessage({
                    id: 'SubcontractorRepairWarrantyStatus.OutOfWarranty',
                    defaultMessage: 'Out of warranty',
                }),
                value: SubcontractorRepairWarrantyStatus.OutOfWarranty,
            },
            {
                id: SubcontractorRepairWarrantyStatus.UnderWarranty,
                text: formatMessage({
                    id: 'SubcontractorRepairWarrantyStatus.UnderWarranty',
                    defaultMessage: 'Under warranty',
                }),
                value: SubcontractorRepairWarrantyStatus.UnderWarranty,
            },
        ];

        return (
            <AgentActionItem
                isEnabled={isEnabled}
                isCompleted={isCompleted}
                icon="id card outline"
                title={formatMessage(m.warrantyStepTitle)}
                description={formatMessage(m.warrantyStepDescription)}
                completeButtonText={formatMessage(commonMessages.select)}
                completedButtonText={formatMessage(commonMessages.modify)}
                onCompleteClick={() => this.props.onChangeWarrantyStatus(this.state.warrantyStatus)}
                onCompletedClick={() => this.props.onChangeWarrantyStatus(this.state.warrantyStatus)}
                isCompleteButtonLoading={this.props.isExecutingStep}
                isCompleteButtonDisabled={this.props.isExecutingStep}
            >
                <div>
                    <Dropdown
                        search={true}
                        selection={true}
                        options={warrantyOptions}
                        fluid={true}
                        deburr={true}
                        onChange={(_event, data) => this.setState({ warrantyStatus: data.value as number })}
                        disabled={!isEnabled}
                        value={this.state.warrantyStatus || SubcontractorRepairWarrantyStatus.OutOfWarranty}
                    />
                </div>
            </AgentActionItem>
        );
    }

    private renderAppointmentStep(isEnabled: boolean, isCompleted: boolean) {
        const { formatMessage } = this.props.intl;

        return (
            <AgentActionItem
                isEnabled={isEnabled}
                isCompleted={isCompleted}
                completeButtonText={formatMessage(commonMessages.select)}
                completedButtonText={formatMessage(commonMessages.modify)}
                icon="calendar"
                title={formatMessage(m.appointmentStepTitle)}
                description={formatMessage(m.appointmentStepDescription)}
                onCompleteClick={() => this.props.onChangeAppointmentDate(this.state.appointmentDate)}
                onCompletedClick={() => this.props.onChangeAppointmentDate(this.state.appointmentDate)}
                isCompleteButtonDisabled={this.state.appointmentDate == null || this.props.isExecutingStep}
                isCompletedButtonDisabled={this.props.isExecutingStep}
                isCompleteButtonLoading={this.props.isExecutingStep}
                isCompletedButtonLoading={this.props.isExecutingStep}
            >
                <Form>
                    <Form.Field>
                        <DatePicker
                            selected={this.state.appointmentDate != null ? moment(this.state.appointmentDate).toDate() : undefined}
                            dateFormat="YYYY/MM/DD"
                            fixedHeight={true}
                            placeholderText={formatMessage(m.appointmentDatePlaceholder)}
                            value={this.state.appointmentDate != null ? moment(this.state.appointmentDate).format('YYYY/MM/DD') : undefined}
                            onChange={(date) => this.setState({ appointmentDate: date as (Date | null) || undefined })}
                        />
                        <a onClick={() => this.setState({ appointmentDate: undefined })} style={{ float: 'right', marginTop: '10px' }}>
                            {formatMessage(commonMessages.clear)}
                        </a>
                    </Form.Field>
                </Form>

            </AgentActionItem>
        );
    }

    private renderBillStep(isEnabled: boolean, isCompleted: boolean) {
        const { formatMessage } = this.props.intl;
        const areBillControlsVisible = isCompleted || isEnabled;

        return (
            <AgentActionItem
                isEnabled={isEnabled}
                isCompleted={isCompleted}
                icon="dollar"
                title={formatMessage(m.billingStepTitle)}
                description={formatMessage(m.billingStepDescription)}
                completeButtonText={formatMessage(commonMessages.add)}
                isCompleteButtonDisabled={this.props.isExecutingStep}
                isCompleteButtonLoading={this.props.isExecutingStep}
                onCompleteClick={this.addBill}
                completedButtonText={formatMessage(commonMessages.added)}
            >
                {areBillControlsVisible &&
                    <Form>
                        <Form.Field>
                            <label>{formatMessage(m.bill)}</label>
                            {this.state.invoice &&
                                <div>
                                    <span>{this.state.invoice.name}</span>
                                    <SmallDeleteButton as="a" style={{ float: 'right' }} onClick={() => this.setState({ invoice: undefined })} />
                                </div>
                            }
                            {!this.state.invoice &&
                                <div>
                                    <UploadFileButton
                                        allowMultiple={false}
                                        acceptedFileTypes="application/pdf, image/png, image/jpeg"
                                        onFilesUpload={this.addBillDocument}
                                    />
                                </div>
                            }
                        </Form.Field>
                        <Form.Input disabled={!isEnabled} label={formatMessage(m.referenceNumber)} />
                        <Form.Field disabled={!isEnabled}>
                            <label>{formatMessage(m.repairFeesLabel)}</label>
                            <CurrencyInput
                                value={this.state.repairFees}
                                currency={this.state.currencyCode}
                                onChange={this.changeRepairFees}
                            />
                        </Form.Field>

                        <Form.Field disabled={!isEnabled}>
                            <label>{formatMessage(m.shippingFeesLabel)}</label>
                            <CurrencyInput
                                value={this.state.shippingFees}
                                currency={this.state.currencyCode}
                                onChange={this.changeShippingFees}
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>{formatMessage(m.totalLabel)}</label>
                            <div style={{ textAlign: 'right' }}>
                                <FormattedNumber
                                    value={this.state.repairFees + this.state.shippingFees}
                                    style="currency"
                                    currency={this.state.currencyCode}
                                />
                            </div>
                        </Form.Field>
                    </Form>
                }
            </AgentActionItem>
        );
    }

    private changeRepairFees = (value: number, currency: string) => {
        this.setState({
            repairFees: value,
            currencyCode: currency
        });
    }

    private changeShippingFees = (value: number, currency: string) => {
        this.setState({
            shippingFees: value,
            currencyCode: currency
        });
    }

    private addBillDocument = (files: FileList) => {
        if (files.length > 0) {
            this.setState({ invoice: files[0] });
        }
    }

    private addBill = () => {
        this.props.onAddBill(
            this.state.repairFees,
            this.state.shippingFees,
            this.state.currencyCode,
            this.state.referenceNumber,
            this.state.invoice);
    }

    private sendEmail = (email: string, locale: string) => {
        this.setState({ isEmailModalOpen: false });
        this.props.onAssign(
            this.state.selectedSubcontractorId,
            this.state.selectedSubcontractorId === this.otherSubcontractor.value ? this.state.otherSubcontractorName : undefined,
            email,
            locale);
    }

    private sendEmailManually = () => {
        this.setState({ isEmailModalOpen: false });
        this.props.onAssign(
            this.state.selectedSubcontractorId,
            this.state.selectedSubcontractorId === this.otherSubcontractor.value ? this.state.otherSubcontractorName : undefined,
            undefined,
            undefined);
    }
}

const intlComponent = injectIntl(SubcontractorRepairsCard);
export { intlComponent as SubcontractorRepairsCard };

