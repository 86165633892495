import * as React from 'react';
import { Icon, Message, SemanticICONS } from 'semantic-ui-react';
import { NotificationType } from '../../state/models';

export interface GlobalNotificationProps {
    title: string;
    isVisible?: boolean;
    message?: string;
    type?: NotificationType;
    onClose: () => {};
}

export class GlobalNotification extends React.Component<GlobalNotificationProps, {}> {
    public render() {
        let messageBody = this.props.message
            ? <p>{this.props.message}</p>
            : (null);

        if (this.props.isVisible) {
            return (
                <div>
                    <Message icon={true} onDismiss={this.props.onClose} className={this.getNotificationType()}>
                        <Icon name={this.getNotificationIcon()} />
                        <Message.Content>
                            <Message.Header>{this.props.title}</Message.Header>
                            {messageBody}
                        </Message.Content>
                    </Message>
                </div>
            );
        }

        return (null);
    }

    private getNotificationType(): string {
        switch (this.props.type) {
            case NotificationType.information:
                return 'info';

            case NotificationType.success:
                return 'success';

            case NotificationType.warning:
                return 'warning';

            case NotificationType.error:
                return 'error';

            default:
                return '';
        }
    }

    private getNotificationIcon(): SemanticICONS {
        switch (this.props.type) {
            case NotificationType.information:
                return 'info circle';

            case NotificationType.success:
                return 'check circle';

            case NotificationType.warning:
                return 'exclamation triangle';

            case NotificationType.error:
                return 'remove circle';

            default:
                return 'question circle';
         }
    }
}

export default GlobalNotification;