import { defineMessages } from "@formatjs/intl";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button, Checkbox } from "semantic-ui-react";
import { PageHeader } from "../../../../components/common";
import { User } from "../../../../state/models";
import { getAllEmployees } from "../selectors";
import { searchUsers } from "../utils";
import { UsersNavigationTab } from "./components/UsersNavigationTab";
import { UsersSearchControl } from "./components/UsersSearchControl";
import { UsersTable } from "./components/UsersTable/UsersTable";
import { actionCreators as UsersActions } from '../actions';
import { UsersFilterPopup } from "./components/UsersFilterPopup";
import { commonMessages } from "../../../../constants";
import { UsersFilters } from "../types";

const m = defineMessages({
    title: { id: 'ManageEmployeesPage.title', defaultMessage: 'Manage employees' },
    subtitle: { id: 'ManageEmployeesPage.subtitle', defaultMessage: 'Manage the employees that have access to the after-sales platform.' },
    employeesCount: { id: 'ManageEmployeesPage.employeesCount', defaultMessage: '{count} employees' },
    addEmployeeButton: { id: 'ManageEmployeesPage.addEmployeeButton', defaultMessage: 'Add an employee' },
    searchEmployeePlaceholder: { id: 'ManageEmployeesPage.searchEmployeePlaceholder', defaultMessage: 'Search for an employee' }
})

export const ManageEmployeesPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();

    const allEmployees = useSelector(getAllEmployees);
    const [displayedEmployees, setDisplayedEmployees] = useState<User[]>([]);

    useEffect(() => {
        setDisplayedEmployees(allEmployees);
    }, [allEmployees]);

    useEffect(() => {
        dispatch(UsersActions.loadAll());
    }, [dispatch]);

    const searchEmployees = (searchTerm: string) => {
        const searchResults = searchUsers(allEmployees, searchTerm);
        setDisplayedEmployees(searchResults);
    }

    const clearSearchResults = () => setDisplayedEmployees(allEmployees);
    const navigateToDetailsPage = (user: User) => history.push(`/administration/employees/${user.id}`);
    const navigateToCreatePage = () => history.push(`/administration/employees/create`);
    const applyFilters = (filters: UsersFilters) => dispatch(UsersActions.setFilters(filters));

    return (
        <>
            <PageHeader iconName="users" title={formatMessage(m.title)} subtitle={formatMessage(m.subtitle)} />
            <UsersNavigationTab />

            <UsersSearchControl 
                totalUsersCount={allEmployees.length}
                totalUsersMessage={m.employeesCount}
                searchResultsCount={displayedEmployees.length}
                searchPlaceholder={formatMessage(m.searchEmployeePlaceholder)}
                onChange={searchEmployees}
                onClear={clearSearchResults}
                style={{ marginTop: '1em' }}
            >
                <UsersFilterPopup 
                    trigger={<Button icon="filter" content={formatMessage(commonMessages.filter)} style={{ marginRight: '1em' }} />}
                    onApply={applyFilters}
                />
                <Button primary content={formatMessage(m.addEmployeeButton)} onClick={navigateToCreatePage} />
            </UsersSearchControl>

            <UsersTable 
                users={displayedEmployees}
                onClick={navigateToDetailsPage}
            />
        </>
    );
};