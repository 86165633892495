import { Order } from '../../state/models/Order';
import { ThunkAction } from 'redux-thunk';
import { ApplicationState } from '../../state/ducks';
import * as api from './api';
import * as globalNotification from '../../state/ducks/global-notification';
import { AppThunk } from '../../state/store';

export enum ActionTypeKeys {
    LOAD_REQUEST = 'ORDERS_LOAD_REQUEST',
    LOAD_SUCCESS = 'ORDERS_LOAD_SUCCESS',
    LOAD_FAILURE = 'ORDERS_LOAD_FAILURE',
}

export interface LoadOrderRequest { type: ActionTypeKeys.LOAD_REQUEST; payload: { orderId: number }; }
export interface LoadOrderSuccess { type: ActionTypeKeys.LOAD_SUCCESS; payload: { order: Order }; }
export interface LoadOrderFailure { type: ActionTypeKeys.LOAD_FAILURE; payload: { orderId: number, error: Error }; }

export type ActionTypes =
    | LoadOrderRequest | LoadOrderSuccess | LoadOrderFailure
    | { type: '' };

export const actionCreators = {
    loadOrder: (orderId: number): AppThunk => {
        return (dispatch) => {
            dispatch({ type: ActionTypeKeys.LOAD_REQUEST, payload: { orderId: orderId } });

            api.loadById(orderId)
                .then(order => {
                    dispatch({ type: ActionTypeKeys.LOAD_SUCCESS, payload: { order } });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_FAILURE, payload: { error } });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        `Une erreur est survenue en tentant de charger les détails de la commande ${orderId} (${error}).`)
                    );
                });
        };
    },
};