import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { CommonCard, EmptyContentSegment } from '../../common';
import { ServiceCallDefect, ServiceCallDefectiveItem, Item } from '../../../state/models';
import { List } from 'semantic-ui-react';
import { defectTypeMessages, defectCategoryMessages } from '../../../constants';
import { ItemDetailsListItem } from '../../items';

interface InspectionInformationCardOwnProps {
    defectiveItems: ServiceCallDefectiveItem[];
    defects: ServiceCallDefect[];
    isLoading?: boolean;
}

export type InspectionInformationCardProps =
    & InspectionInformationCardOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'InspectionInformationCard.title', defaultMessage: 'Inspection information' },
    subtitle: { id: 'InspectionInformationCard.subtitle', defaultMessage: 'View information about the furniture and defects to be inspected.' },
    defectiveItems: { id: 'InspectionInformationCard.defective_items', defaultMessage: 'Defective items' },
    defects: { id: 'InspectionInformationCard.defects', defaultMessage: 'Defects' },
    noDefectiveItemsTitle: { id: 'InspectionInformationCard.no_defective_items_title', defaultMessage: 'No defective items' },
    noDefectiveItemsDescription: { id: 'InspectionInformationCard.no_defective_items_description', defaultMessage: 'No defective items were specified for this service call.' },
});

class InspectionInformationCard extends React.Component<InspectionInformationCardProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const hasDefectiveItems = this.props.defectiveItems && this.props.defectiveItems.length > 0 && this.props.defectiveItems.filter(x => x.itemQuantity > 0).length > 0;
        const hasDefects = this.props.defects && this.props.defects.length > 0;

        return (
            <CommonCard 
                title={formatMessage(m.title)} 
                subtitle={formatMessage(m.subtitle)} 
                isLoading={this.props.isLoading}
                color="teal"
            >
                <div style={{marginTop: 0}}>
                    <h5>{formatMessage(m.defectiveItems)}</h5>
                    {hasDefectiveItems &&
                        <List bulleted={true}>
                            {this.renderDefectiveItems()}
                        </List>
                    }

                    {!hasDefectiveItems &&
                        this.renderEmptyDefectiveItems()
                    }

                    {hasDefects && 
                        <React.Fragment>
                            <h5>{formatMessage(m.defects)}</h5>
                            <List bulleted={true}>
                                {this.renderDefects(this.props.defects)}
                            </List>
                        </React.Fragment>
                    }
                </div>
            </CommonCard>
        );
    }

    private renderDefectiveItems() {
        return this.props.defectiveItems
            .filter(x => x.itemQuantity > 0)
            .map(x => {
                return (
                    <ItemDetailsListItem 
                        key={x.id}
                        item={x.item}
                        itemCover={x.itemCover}
                        defectiveItemQuantity={x.itemQuantity}
                    />
                );
            });
    }

    private renderDefects(defects: ServiceCallDefect[]) {
        const { formatMessage } = this.props.intl;

        if (defects && defects.length > 0) {
            const defect = defects[0].defect;

            const typeMessages = Object.keys(defectTypeMessages).map(key => defectTypeMessages[key]);
            const defectTypeMessage = typeMessages.find(x => x.id === defect.defectType.title);
            const defectType = defectTypeMessage ? formatMessage(defectTypeMessage) : defect.defectType.title;

            const categoryMessages = Object.keys(defectCategoryMessages).map(key => defectCategoryMessages[key]);
            const defectCategoryMessage = categoryMessages.find(x => x.id === defect.defectCategory.title);
            const defectCategory = defectCategoryMessage ? formatMessage(defectCategoryMessage) : defect.defectCategory.title;

            return (
                <List.Item>
                    <span>{defectType}</span>
                    <span style={{color: 'rgba(0,0,0,0.4)'}}> ({defectCategory})</span>
                </List.Item>
            );
        }

        return (null);
    }

    private renderEmptyDefectiveItems() {
        const { formatMessage } = this.props.intl;

        return (
            <EmptyContentSegment 
                basic={true}
                title={formatMessage(m.noDefectiveItemsTitle)}
                description={formatMessage(m.noDefectiveItemsDescription)}
            />
        );
    }
}

const connectedComponent = injectIntl(InspectionInformationCard);
export { connectedComponent as InspectionInformationCard };