import { Reducer } from 'redux';
import { ActionTypes } from './actions';
import { ActionTypeKeys } from './types';
import { Notification } from '../../models';
import _ from 'lodash';

export interface NotificationsState {
    byId: {
        [id: number]: Notification
    };
    allIds: number[];
    unread: number[];
}

const initialState: NotificationsState = {
    byId: {},
    allIds: [],
    unread: []
};

export const notificationsReducer: Reducer<NotificationsState> = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case ActionTypeKeys.LOAD_ALL_SUCCESS:
            const allNotifications = Object.assign({}, ...action.payload.map(x => ({ [x.id]: x })));

            return {
                ...state,
                byId: allNotifications,
                allIds: action.payload.map(x => x.id)
            };

        case ActionTypeKeys.LOAD_UNREAD_SUCCESS:
            const unreadNotifications = Object.assign({}, ...action.payload.map(x => ({ [x.id]: x })));
            const unreadIds = action.payload.map(x => x.id);

            return {
                ...state,
                byId: _.merge({}, state.byId, unreadNotifications),
                allIds: _.union(state.allIds, unreadIds),
                unread: unreadIds
            };

        case ActionTypeKeys.READ_SUCCESS: {
            return {
                ...state,
                unread: state.unread.filter(x => x !== action.payload)
            };
        }

        case ActionTypeKeys.READ_ALL_SUCCESS:
            return {
                ...state,
                unread: []
            };

        default:
            return state;
    }
};

export default notificationsReducer;