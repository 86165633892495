import React from 'react';
import moment from 'moment';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { ApplicationState } from '../../../state/ducks';
import { PageHeader } from '../../../components/common';
import { Calendar, CalendarEvent } from '../../../components/calendar';
import { FactoryCalendarEvent } from '../../../components/service-calls/factory';

import * as FactoryRepairsActions from '../../../state/ducks/factory-repairs';
import { ServiceCallFactoryRepair, CalendarBreak, User, UserAccountType } from '../../../state/models';
import { getFactoryRepairsByStartDate } from '../../../state/ducks/factory-repairs/selectors';
import { getBreaksBetween } from '../../../state/ducks/calendar/selectors';
import { getCurrentUser } from '../../../modules/account/selectors';

interface FactoryCalendarPageActions {
    factoryRepairsActions: typeof FactoryRepairsActions.actionCreators;
}

interface FactoryCalendarPageOwnProps {
    repairs: ServiceCallFactoryRepair[];
    areRepairsLoading: boolean;
    selectedCalendarDate: Date;
    lockedDates: CalendarBreak[];
    currentUser: User | undefined;
}

export type FactoryCalendarPageProps =
    & FactoryCalendarPageOwnProps
    & FactoryCalendarPageActions
    & RouteComponentProps<{}>
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'FactoryCalendarPage.title', defaultMessage: 'Repairs schedule' },
    subtitle: { id: 'FactoryCalendarPage.subtitle', defaultMessage: 'View the factory repairs schedule.' }
});

class FactoryCalendarPage extends React.Component<FactoryCalendarPageProps, {}> {
    public componentDidMount() {
        const startDate = moment(this.props.selectedCalendarDate).startOf('month').startOf('week').startOf('day').toDate();
        const endDate = moment(this.props.selectedCalendarDate).endOf('month').endOf('week').startOf('day').toDate();

        this.props.factoryRepairsActions.loadBetween(startDate, endDate);
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const calendarEvents: CalendarEvent[] = this.props.repairs
            .filter(x => x.completedRepairsOn != null || x.scheduledFor != null || x.receivedOn != null)
            .map(x => ({
                on: moment(x.completedRepairsOn || x.scheduledFor || x.receivedOn).local().startOf('day').toDate(),
                element: <FactoryCalendarEvent key={x.id} factoryRepair={x} onClick={this.navigateToFactoryPage} />
            }));

        return (
            <React.Fragment>
                <PageHeader
                    iconName="calendar"
                    title={formatMessage(m.title)}
                    subtitle={formatMessage(m.subtitle)}
                />

                <Calendar
                    forDate={this.props.selectedCalendarDate}
                    events={calendarEvents}
                    lockedDates={this.props.lockedDates}
                    isLoading={this.props.areRepairsLoading}
                    canSelectDate={false}
                    onMonthChanged={this.changeCalendarStartDate}
                />
            </React.Fragment>
        );
    }

    private changeCalendarStartDate = (date: Date) => {
        const startDate = moment(date).startOf('month').startOf('week').startOf('day').toDate();
        const endDate = moment(date).endOf('month').endOf('week').startOf('day').toDate();

        this.props.factoryRepairsActions.setCalendarStartDate(date);
        this.props.factoryRepairsActions.loadBetween(startDate, endDate);
    }

    private navigateToFactoryPage = (repairs: ServiceCallFactoryRepair) => {
        if (this.props.currentUser != null && this.props.currentUser.accountType === UserAccountType.factoryTechnician) {
            this.props.history.push(`/service-calls/${repairs.serviceCallId}/factory-repairs/${repairs.id}/prepare`);
        } else {
            this.props.history.push(`/service-calls/${repairs.serviceCallId}/details`);
        }
    }
}

const mapStateToProps = (state: ApplicationState): FactoryCalendarPageOwnProps => {
    const factoryRepairsCalendarDate = moment(state.factoryRepairs.calendarStartDate).startOf('month').startOf('week').startOf('day').toDate();
    const factoryRepairsState = getFactoryRepairsByStartDate(state, factoryRepairsCalendarDate);

    const dateMoment = moment(state.factoryRepairs.calendarStartDate);
    const startDate = dateMoment.clone().startOf('month').startOf('week').startOf('day').toDate();
    const endDate = dateMoment.clone().endOf('month').endOf('week').startOf('day').toDate();

    return {
        repairs: factoryRepairsState.repairs || [],
        areRepairsLoading: factoryRepairsState.isLoading,
        selectedCalendarDate: state.factoryRepairs.calendarStartDate,
        lockedDates: getBreaksBetween(state, startDate, endDate),
        currentUser: getCurrentUser(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch): FactoryCalendarPageActions => {
    return {
        factoryRepairsActions: bindActionCreators(FactoryRepairsActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(FactoryCalendarPage);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as FactoryCalendarPage };