import { defineMessages } from "@formatjs/intl";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { Form } from "semantic-ui-react";
import { UserAccountType } from "../../../../../state/models";
import { UserEdit } from "../../models";
import { RolesDropdown } from "./RolesDropdown";

interface OwnProps {
    disabled?: boolean;
}

export type EmployeeRoleSectionProps =
    & OwnProps;

const m = defineMessages({
    accountTypeLabel: { id: 'EmployeeRoleSection.accountTypeLabel', defaultMessage: 'Account type' },
    accountTypeRequired: { id: 'EmployeeRoleSection.accountTypeRequired', defaultMessage: 'Select the role to associate to this employee account.' },
});

export const EmployeeRoleSection: React.FC<EmployeeRoleSectionProps> = (props) => {
    const { formatMessage } = useIntl();
    const { control, formState: { errors }, setValue } = useFormContext<UserEdit>();

    return (
        <>
            <Controller
                control={control}
                name="accountType"
                rules={{ required: formatMessage(m.accountTypeRequired) }}
                render={({ field }) => (
                    <Form.Field required error={errors.accountType} disabled={props.disabled}>
                        <label>{formatMessage(m.accountTypeLabel)}</label>
                        <RolesDropdown
                            value={field.value}
                            onChange={(e, data) => setValue('accountType', data.value as UserAccountType)}
                        />
                    </Form.Field>
                )}
            />
        </>
    );
};