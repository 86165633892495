import React from 'react';
import moment from 'moment';

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { SemanticICONS, Step } from 'semantic-ui-react';

import { ApplicationState } from '../../../state/ducks';
import * as UiActions from '../../../state/ducks/ui';
import * as AppointmentsActions from '../../../state/ducks/appointments';
import * as TechniciansActions from '../../../state/ducks/technicians';
import { ServiceCallTechnicianSteps } from '../../../state/ducks/ui/reducers';
import { TechnicianAppointmentState } from '../../../state/ducks/appointments/reducers';
import { getAppointmentById } from '../../../state/ducks/appointments/selectors';
import { TechnicianReportState } from '../../../state/ducks/technicians/reducers';
import { getTechnicianReport } from '../../../state/ducks/technicians/selectors';
import { TechnicianReportValidator } from '../../../state/validators';

type ServiceCallTechnicianPageProps =
    & ServiceCallTechnicianPageOwnProps
    & ServiceCallTechnicianPageActions
    & RouteComponentProps<{ serviceCallId: string, repairId: string }>;

type IntlServiceCallTechnicianPageProps = ServiceCallTechnicianPageProps & WrappedComponentProps;

interface ServiceCallTechnicianPageOwnProps {
    currentStepId: ServiceCallTechnicianSteps;
    appointmentState: TechnicianAppointmentState;
    reportState: TechnicianReportState;
}

interface ServiceCallTechnicianPageActions {
    uiActions: typeof UiActions.actionCreators;
    appointmentsActions: typeof AppointmentsActions.actionCreators;
    techniciansActions: typeof TechniciansActions.actionCreators;
}

const m = defineMessages({
    prepareStepTitle: { id: 'ServiceCallTechnicianPage.prepare_step_title', defaultMessage: 'Prepare' },
    repairStepTitle: { id: 'ServiceCallTechnicianPage.repair_step_title', defaultMessage: 'Repair' },
    completeStepTitle: { id: 'ServiceCallTechnicianPage.complete_step_title', defaultMessage: 'Complete' },
});

class ServiceCallTechnicianPage extends React.Component<IntlServiceCallTechnicianPageProps, {}> {
    public componentDidMount() {
        const repairId = Number(this.props.match.params.repairId);
        this.props.uiActions.changeServiceCallTab('technician');
        this.props.appointmentsActions.loadbyId(repairId);
        this.props.techniciansActions.loadReport(repairId);
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const { appointmentState, reportState } = this.props;
        const hasAppointment = appointmentState != null && !appointmentState.isLoading && appointmentState.appointment != null;
        const hasReport = reportState != null && !reportState.isLoading && reportState.report != null;

        const isRepairStepComplete = hasAppointment && hasReport && TechnicianReportValidator.validateRepairStep(reportState.report).isValid();
        const isCompleteStepComplete = hasAppointment && hasReport && TechnicianReportValidator.validateCompleteStep(reportState.report).isValid();
        const isPrepareStepComplete = hasAppointment &&
            (moment.utc().isSameOrAfter(appointmentState.appointment.scheduledForDate)
                || isRepairStepComplete
                || isCompleteStepComplete);

        return (
            <div>
                <Step.Group widths={3} size="small" style={{ marginBottom: '1rem' }} unstackable={true}>
                    {this.renderStep('prepare', 'truck', formatMessage(m.prepareStepTitle), isPrepareStepComplete, false)}
                    {this.renderStep('repair', 'wrench', formatMessage(m.repairStepTitle), isRepairStepComplete, false)}
                    {this.renderStep('complete', 'checkmark box', formatMessage(m.completeStepTitle), isCompleteStepComplete, false)}
                </Step.Group>
            </div>
        );
    }

    private renderStep(stepId: ServiceCallTechnicianSteps, icon: SemanticICONS, title: string, isCompleted: boolean, isDisabled: boolean) {
        return (
            <Step
                id={stepId}
                icon={icon}
                title={title}
                disabled={isDisabled}
                active={this.props.currentStepId === stepId}
                completed={isCompleted}
                onClick={() => this.handleStepChange(stepId)}
            />
        );
    }

    private handleStepChange = (stepId: ServiceCallTechnicianSteps) => {
        const { serviceCallId, repairId } = this.props.match.params;

        this.props.uiActions.changeTechnicianStep(stepId);
        this.props.history.push(`/service-calls/${serviceCallId}/technician-repairs/${repairId}/${stepId}`);
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: ServiceCallTechnicianPageProps): ServiceCallTechnicianPageOwnProps => {
    const repairId = Number(ownProps.match.params.repairId);

    return {
        currentStepId: state.ui.currentTechnicianStepId,
        appointmentState: getAppointmentById(state, repairId),
        reportState: getTechnicianReport(state, repairId)
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ServiceCallTechnicianPageActions => {
    return {
        uiActions: bindActionCreators(UiActions.actionCreators, dispatch),
        appointmentsActions: bindActionCreators(AppointmentsActions.actionCreators, dispatch),
        techniciansActions: bindActionCreators(TechniciansActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(ServiceCallTechnicianPage);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as ServiceCallTechnicianPage };