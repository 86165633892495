import React, { useState } from "react";
import { FilterPopup } from "components/common";
import { FilterByDateSection } from "../../../../components/reporting/FilterByDateSection";
import { DateFilter, DateFilterType } from "state/models";
import { DetailedStatisticsSalesFilters } from "state/models/filters/DetailedStatisticsSalesFilters";

interface DetailedStatisticsSalesFiltersPopupProps {
  filters: DetailedStatisticsSalesFilters;
  onApply: (newFilters: DetailedStatisticsSalesFilters) => void;
}

export const DetailedStatisticsSalesFiltersPopup: React.FC<
  DetailedStatisticsSalesFiltersPopupProps
> = ({ filters, onApply }) => {
  const [dateFilter, setDateFilter] = useState<DateFilter>(filters.dateFilter);

  const applyFilters = () => {
    onApply({
      dateFilter: dateFilter,
    });
  };

  return (
    <FilterPopup hasFiltersApplied={false} onApply={applyFilters}>
      <FilterByDateSection
        filter={dateFilter}
        defaultFilterType={DateFilterType.previousYear}
        availableFilters={[
          DateFilterType.thisYear,
          DateFilterType.previousYear,
          DateFilterType.custom,
        ]}
        onChange={setDateFilter}
      />
    </FilterPopup>
  );
};
