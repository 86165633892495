import { BaseService } from './baseService';
import { StringHelper } from '../state/utils';

export class BillsService extends BaseService {
    constructor() {
        super('api/bills');
    }

    public findBill(clientId: string, searchTerm: string, timestamp?: number): Promise<any> {
        const searchUrl = StringHelper.hasValue(clientId)
            ? `/find/${clientId}/${searchTerm}`
            : `/find/${searchTerm}`;

        if (timestamp) {
            return this.get(`${searchUrl}?timestamp=${timestamp}`);
        }
        return this.get(searchUrl);
    }
}

export default BillsService;