import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { ApplicationState } from '../../../state/ducks';
import * as UiActions from '../../../state/ducks/ui';
import * as ServiceCallsActions from '../../../state/ducks/service-calls';
import { Grid } from 'semantic-ui-react';
import { DefectsNotUnderWarrantyCard, RepairFeesCard, RequestApprovalCard } from '../../../components/service-calls';
import { Client, Defect, ServiceCallDefect, ServiceCallFeesEstimate, emptyServiceCallFeesEstimate, Customer } from '../../../state/models';
import { getServiceCallState, getClient, getDefects, getCustomer, getDefectsNotUnderWarranty, getWarrantyStartDate } from '../../../state/ducks/service-calls';

interface ServiceCallApprovePageActions {
    uiActions: typeof UiActions.actionCreators;
    serviceCallsActions: typeof ServiceCallsActions.actionCreators;
}

interface ServiceCallApprovePageOwnProps {
    customer: Customer;
    client: Client | undefined;
    defectsNotUnderWarranty: ServiceCallDefect[];
    warrantyStartDate: Date | undefined;
    repairFeesEstimate: ServiceCallFeesEstimate;
}

export type ServiceCallApprovePageProps =
    & ServiceCallApprovePageOwnProps
    & ServiceCallApprovePageActions
    & RouteComponentProps<{ serviceCallId: string }>;

type IntlServiceCallApprovePageProps = ServiceCallApprovePageProps & WrappedComponentProps;

class ServiceCallApprovePage extends React.Component<IntlServiceCallApprovePageProps, {}> {
    public componentDidMount() {
        this.props.uiActions.changeServiceCallTab('agent');
        this.props.uiActions.changeAgentStep('approve');
    }

    public render() {
        const { repairFeesEstimate } = this.props;
        const repairFees = repairFeesEstimate != null
            ? repairFeesEstimate.partsFees + repairFeesEstimate.labourFees + repairFeesEstimate.shippingFees
            : 0;

        return (
            <Grid columns="equal" stackable={true}>
                <Grid.Row>
                    <Grid.Column>
                        <DefectsNotUnderWarrantyCard
                            defects={this.props.defectsNotUnderWarranty.map(x => x.defect)}
                            warrantyStartDate={this.props.warrantyStartDate}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <RepairFeesCard
                            repairFeesEstimate={repairFeesEstimate}
                            onRepairFeesChange={this.handleRepairFeesChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <RequestApprovalCard
                            repairFees={repairFees}
                            currencyCode={repairFeesEstimate ? repairFeesEstimate.currencyCode : 'CAD'}
                            client={this.props.client}
                            customer={this.props.customer}
                            areFeesApproved={repairFeesEstimate ? repairFeesEstimate.isApproved : false}
                            onApproveFees={this.handleApproveFees}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    private handleRepairFeesChange = (repairFees: ServiceCallFeesEstimate) => {
        const serviceCallId = this.props.match.params.serviceCallId;
        this.props.serviceCallsActions.updateRepairFeesEstimate(Number(serviceCallId), repairFees);
    }

    private handleApproveFees = (isApproved: boolean) => {
        const serviceCallId = this.props.match.params.serviceCallId;
        this.props.serviceCallsActions.updateRepairFeesEstimate(Number(serviceCallId), {
            ...this.props.repairFeesEstimate,
            isApproved: isApproved
        });
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: ServiceCallApprovePageProps): ServiceCallApprovePageOwnProps => {
    const serviceCallId = ownProps.match.params.serviceCallId;
    const serviceCall = getServiceCallState(state, Number(serviceCallId));
    const isLoaded = serviceCall && !serviceCall.isLoading && serviceCall.details;

    return {
        client: getClient(state, Number(serviceCallId)),
        customer: getCustomer(state, Number(serviceCallId)),
        defectsNotUnderWarranty: getDefectsNotUnderWarranty(state, Number(serviceCallId)),
        warrantyStartDate: getWarrantyStartDate(state, Number(serviceCallId)),
        repairFeesEstimate: isLoaded ? serviceCall.details.estimatedFees || emptyServiceCallFeesEstimate : emptyServiceCallFeesEstimate
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ServiceCallApprovePageActions => {
    return {
        uiActions: bindActionCreators(UiActions.actionCreators, dispatch),
        serviceCallsActions: bindActionCreators(ServiceCallsActions.actionCreators, dispatch)
    };
};

const intlComponent = injectIntl(ServiceCallApprovePage);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as ServiceCallApprovePage };