import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Card, Divider } from 'semantic-ui-react';

import { Defect } from '../../../state/models';
import { DefectDetails } from '..';
import { EmptyContentSegment } from '../../common';

interface DefectsNotUnderWarrantyCardOwnProps {
    defects: Defect[];
    warrantyStartDate?: Date;
}

export type DefectsNotUnderWarrantyCardProps =
    & DefectsNotUnderWarrantyCardOwnProps
    & WrappedComponentProps;

const m = defineMessages({
    title: { id: 'DefectsNotUnderWarrantyCard.title', defaultMessage: 'Defects not under warranty' },
    subtitle: { id: 'DefectsNotUnderWarrantyCard.subtitle', defaultMessage: 'View the defects that are not covered by Jaymar\'s warranty.' },
    noDefectsTitle: { id: 'DefectsNotUnderWarrantyCard.no_defects_title', defaultMessage: 'No defects' },
    noDefectsDescription: { id: 'DefectsNotUnderWarrantyCard.no_defects_description', defaultMessage: 'There are no defects associated to this service call, or all defects are covered by Jaymar\'s warranty.' }
});

class DefectsNotUnderWarrantyCard extends React.Component<DefectsNotUnderWarrantyCardProps, {}> {
    public render() {
        const { formatMessage } = this.props.intl;
        const renderedDefects = this.props.defects.length > 0
            ? this.props.defects.map(x => this.renderDefect(x))
            : <EmptyContentSegment basic={true} title={formatMessage(m.noDefectsTitle)} description={formatMessage(m.noDefectsDescription)} />;

        return (
            <Card color="yellow" fluid={true} style={{height: '100%'}}>
                <Card.Content style={{flexGrow: 0}}>
                    <Card.Header content={formatMessage(m.title)} />
                    <Card.Meta content={formatMessage(m.subtitle)} />
                </Card.Content>
                <Card.Content style={{maxHeight: 240, overflowY: 'scroll'}}>
                    {renderedDefects}
                </Card.Content>
            </Card>
        );
    }

    private renderDefect(defect: Defect) {
        return (
            <div key={defect.id}>
                <DefectDetails
                    defect={defect}
                    warrantyStartDate={this.props.warrantyStartDate}
                    isReadOnly={true}
                />
                <Divider />
            </div>
        );
    }
}

const connectedComponent = injectIntl(DefectsNotUnderWarrantyCard);
export { connectedComponent as DefectsNotUnderWarrantyCard };