import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Message, MessageProps } from "semantic-ui-react";

interface OwnProps {
}

export type PasswordRequirementsInformationProps =
    & MessageProps
    & OwnProps;

const m = defineMessages({
    title: { id: 'PasswordRequirementsInformation.title', defaultMessage: 'The new password must:' },
    minimum8Characters: { id: 'PasswordRequirementsInformation.minimum8Characters', defaultMessage: 'Contain at least 8 characters' },
    minimum1SpecialCharacter: { id: 'PasswordRequirementsInformation.minimum1SpecialCharacter', defaultMessage: 'Contain at least 1 special character (e.g. -+_!@#$%^&*.,?()[])' },
});

export const PasswordRequirementsInformation: React.FC<PasswordRequirementsInformationProps> = (props) => {
    const { formatMessage } = useIntl();
    const { ...messageProps } = props;

    return (
        <Message info {...messageProps}>
            <Message.Header>{formatMessage(m.title)}</Message.Header>
            <Message.List>
                <Message.Item>{formatMessage(m.minimum8Characters)}</Message.Item>
                <Message.Item>{formatMessage(m.minimum1SpecialCharacter)}</Message.Item>
            </Message.List>
        </Message>
    );
};