import { ThunkAction } from 'redux-thunk';
import { ActionTypeKeys } from './types';
import { User } from '../../models';
import * as globalNotification from '../global-notification';
import { UsersService } from '../../../services';
import { AppThunk } from '../../store';

export type ActionTypes =
    | LoadCustomerServiceAgentsRequestAction
    | LoadCustomerServiceAgentsSuccessAction
    | LoadCustomerServiceAgentsFailureAction
    | LoadJaymarRepresentativesRequestAction
    | LoadJaymarRepresentativesSuccessAction
    | LoadJaymarRepresentativesFailureAction
    | LoadJaymarTechniciansRequestAction
    | LoadJaymarTechniciansSuccessAction
    | LoadJaymarTechniciansFailureAction
    | LoadFactoryTechniciansRequestAction
    | LoadFactoryTechniciansSuccessAction
    | LoadFactoryTechniciansFailureAction
    | { type: '' };

export interface LoadCustomerServiceAgentsRequestAction { type: ActionTypeKeys.LOAD_AGENTS_REQUEST; }
export interface LoadCustomerServiceAgentsSuccessAction { type: ActionTypeKeys.LOAD_AGENTS_SUCCESS; payload: User[]; }
export interface LoadCustomerServiceAgentsFailureAction { type: ActionTypeKeys.LOAD_AGENTS_FAILURE; payload: string; }
export interface LoadJaymarRepresentativesRequestAction { type: ActionTypeKeys.LOAD_REPRESENTATIVES_REQUEST; }
export interface LoadJaymarRepresentativesSuccessAction { type: ActionTypeKeys.LOAD_REPRESENTATIVES_SUCCESS; payload: User[]; }
export interface LoadJaymarRepresentativesFailureAction { type: ActionTypeKeys.LOAD_REPRESENTATIVES_FAILURE; payload: string; }
export interface LoadJaymarTechniciansRequestAction { type: ActionTypeKeys.LOAD_TECHNICIANS_REQUEST; }
export interface LoadJaymarTechniciansSuccessAction { type: ActionTypeKeys.LOAD_TECHNICIANS_SUCCESS; payload: User[]; }
export interface LoadJaymarTechniciansFailureAction { type: ActionTypeKeys.LOAD_TECHNICIANS_FAILURE; payload: string; }
export interface LoadFactoryTechniciansRequestAction { type: ActionTypeKeys.LOAD_FACTORY_TECHNICIANS_REQUEST; }
export interface LoadFactoryTechniciansSuccessAction { type: ActionTypeKeys.LOAD_FACTORY_TECHNICIANS_SUCCESS; payload: User[]; }
export interface LoadFactoryTechniciansFailureAction { type: ActionTypeKeys.LOAD_FACTORY_TECHNICIANS_FAILURE; payload: string; }

const service = new UsersService();

export const actionCreators = {
    loadCustomerServiceAgents: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_AGENTS_REQUEST });

            service.loadCustomerServiceAgents()
                .then(response => response.json() as User[])
                .then(data => {
                    dispatch({ type: ActionTypeKeys.LOAD_AGENTS_SUCCESS, payload: data });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_AGENTS_FAILURE, payload: error });
                    // TODO: Reenable this
                    // dispatch(globalNotification.actionCreators.showErrorNotification(
                    //     'Erreur', 
                    //     'Une erreur est survenue en tentant de charger la liste des agents du service après-vente.')
                    // );
                });
        };
    },

    loadJaymarRepresentatives: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_REPRESENTATIVES_REQUEST });

            service.getJaymarRepresentatives()
                .then(response => response.json() as User[])
                .then(data => {
                    dispatch({ type: ActionTypeKeys.LOAD_REPRESENTATIVES_SUCCESS, payload: data });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_REPRESENTATIVES_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        'Une erreur est survenue en tentant de charger la liste des représentants Jaymar.')
                    );
                });
        };
    },

    loadJaymarTechnicians: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_TECHNICIANS_REQUEST });

            service.getJaymarTechnicians()
                .then(response => response.json() as User[])
                .then(data => {
                    dispatch({ type: ActionTypeKeys.LOAD_TECHNICIANS_SUCCESS, payload: data });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_TECHNICIANS_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        'Une erreur est survenue en tentant de charger la liste des techniciens Jaymar.')
                    );
                });
        };
    },

    loadFactoryTechnicians: (): AppThunk => {
        return (dispatch, _getState) => {
            dispatch({ type: ActionTypeKeys.LOAD_FACTORY_TECHNICIANS_REQUEST });

            service.getFactoryTechnicians()
                .then(response => response.json() as User[])
                .then(data => {
                    dispatch({ type: ActionTypeKeys.LOAD_FACTORY_TECHNICIANS_SUCCESS, payload: data });
                })
                .catch(error => {
                    dispatch({ type: ActionTypeKeys.LOAD_FACTORY_TECHNICIANS_FAILURE, payload: error });
                    dispatch(globalNotification.actionCreators.showErrorNotification(
                        'Erreur',
                        'Une erreur est survenue en tentant de charger la liste des techniciens en usine.')
                    );
                });
        };
    }
};