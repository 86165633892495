import * as queryString from 'query-string';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Container, Header, List } from 'semantic-ui-react';
import { AppointementDateSection } from '../components/AppointementDateSection/AppointementDateSection';
import { CloseServiceCallSection } from '../components/CloseServiceCallSection';
import { CustomerInformationSection } from '../components/CustomerInformationSection';
import { MediaSection } from '../components/MediaSection.tsx';
import { ReportSection } from '../components/ReportSection';
import { SubContractorBillSection } from '../components/SubContractorBillSection';
import { SubcontractorRepairDetailsProvider } from '../components/SubcontractorRepairDetailsProvider';
import { SubcontractorPageLayout } from '../SubcontractorPageLayout';

export const SubcontractorRepairPage = () => {
    const { formatMessage } = useIntl();

    const history = useHistory();
    const queryParams = queryString.parse(history.location.search);

    if (queryParams.token) {
        sessionStorage.setItem("auth-header", `Bearer ${queryParams.token}`);
    }

    return (
        <SubcontractorPageLayout>
            <Container>
                <SubcontractorRepairDetailsProvider>
                    <Header
                        as="h2"
                        content={formatMessage({
                            id: 'SubcontractorRepairPage.Header',
                            defaultMessage:
                                'Please setup an appointment for a local repair with the Jaymar consumer as soon as possible.',
                        })}
                        subheader={formatMessage({
                            id: 'SubcontractorRepairPage.Subheader',
                            defaultMessage:
                                'On this page you can inform us of the appointment date, enter your repair report and then add your invoice.',
                        })}
                    />
                    <List divided={true} relaxed="very">
                        <CustomerInformationSection />
                        <AppointementDateSection />
                        <ReportSection />
                        <MediaSection />
                        <SubContractorBillSection />
                    </List>
                    <CloseServiceCallSection />
                </SubcontractorRepairDetailsProvider>
            </Container>
        </SubcontractorPageLayout>
    );
};
