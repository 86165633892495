import { defineMessages } from "@formatjs/intl";
import React from "react";
import { useIntl } from "react-intl";
import { Message } from "semantic-ui-react";
import { ServiceCall, ServiceCallStatus } from "../../../../state/models";

interface OwnProps {
    serviceCalls: ServiceCall[];
}

export type ExistingServiceCallWarningProps =
    & OwnProps;

const m = defineMessages({
    callOpenedWarningTitle: { id: 'OrderInformationForm.callOpenedWarningTitle', defaultMessage: 'Service call already in progress' },
    callOpenedWarningDescription: { id: 'OrderInformationForm.callOpenedWarningDescription', defaultMessage: 'A service call has already been opened for this invoice. You can modify it or create a new one if you wish.' },
    callOpenedFormat: { id: 'OrderInformationForm.callOpenedFormat', defaultMessage: 'Service call #{number}' }
});

export const ExistingServiceCallWarning: React.FC<ExistingServiceCallWarningProps> = (props) => {
    const { formatMessage } = useIntl();
    const openedServiceCalls = props.serviceCalls.filter(serviceCall => serviceCall.status != ServiceCallStatus.closed);

    if (openedServiceCalls.length === 0) {
        return <></>;
    }

    return (
        <Message warning>
            <Message.Header>{formatMessage(m.callOpenedWarningTitle)}</Message.Header>
            <Message.Content>
                {formatMessage(m.callOpenedWarningDescription)}

                <ul>
                    {openedServiceCalls.map(x => (
                        <li key={x.id}>
                            <a href={`/service-calls/${x.id}/details`}>
                                {formatMessage(m.callOpenedFormat, { number: x.id })}
                            </a>
                        </li>
                    ))}
                </ul>
            </Message.Content>
        </Message>
    );
};