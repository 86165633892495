import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';
import { GlobalNotification } from '../../components/common';
import * as GlobalNotificationActions from '../../state/ducks/global-notification';
import { GlobalNotificationState } from '../../state/ducks/global-notification';
import { ApplicationState } from '../../state/ducks';

type PageContentProps = GlobalNotificationState
    & PageContentActions
    & RouteComponentProps<{}>;

interface PageContentActions {
    globalNotification: typeof GlobalNotificationActions.actionCreators;
}

class PageContent extends React.Component<PageContentProps, {}> {
    constructor(props: PageContentProps) {
        super(props);

        this.props.history.listen((location, action) => {
            this.props.globalNotification.clearNotification();
        });
    }

    public render() {
        return (
            <div className="o-page">
                <GlobalNotification
                    title={this.props.title}
                    message={this.props.message}
                    isVisible={this.props.isVisible}
                    type={this.props.type}
                    onClose={this.props.globalNotification.clearNotification}
                />

                <Segment raised={true}>
                    {this.props.children}
                </Segment>
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => state.globalNotification;

const mapDispatchToProps = (dispatch: Dispatch): PageContentActions => {
    return {
        globalNotification: bindActionCreators(GlobalNotificationActions.actionCreators, dispatch),
    };
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(PageContent);
const routedComponent = withRouter(connectedComponent);
export { routedComponent as PageContent };